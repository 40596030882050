import _ from 'lodash'
import moment from 'moment'

import { 
    updateWorkoutSchedule,
    createWorkoutSchedule, 
    getWorkoutSchedulesByTeamId, 
    getWorkoutScheduleEnrollments, 
    createWorkoutScheduleEnrollment,
    createWorkoutScheduleAssignment,
    getWorkoutAssignmentsByScheduleId
} from '../services/workoutSchedulesService'

import { getUserById } from '../services/usersService'

export const resolveWorkoutScheduleAssignments = async (scheduleId, viewingAssignments, forceRefresh) => {
    let assignments = viewingAssignments
    if(!viewingAssignments.find(a => a.workoutScheduleId == scheduleId) || forceRefresh)
        assignments = await getWorkoutAssignmentsByScheduleId(scheduleId)
    assignments.filter(a => scheduleId == a.workoutScheduleId)
    assignments.forEach(assignment => {
        assignment.start = assignment.assignedAt
        assignment.title = assignment.workoutName
        assignment.end = moment(assignment.assignedAt).add(1, 'hour')
    })
    return _.uniqBy(assignments, 'id')
}

export const createNewWorkoutSchedule = async (teamId, scheduleName, currentGroupMembers, scheduleId, athletes) => {
    const scheduleJSON = {
        id: scheduleId,
        teamId: teamId,
        name: scheduleName
    }
    const newSchedule = scheduleId ? await updateWorkoutSchedule(scheduleJSON) : await createWorkoutSchedule(scheduleJSON)
    await createNewWorkoutScheduleEnrollments(newSchedule.id, currentGroupMembers.filter(member => !member.enrollmentId), athletes)
    return newSchedule
}

const createNewWorkoutScheduleEnrollments = async (scheduleId, currentGroupMembers, athletes) => {
    let allMembers = currentGroupMembers.find(m => m.id == 'allAthletes')
    currentGroupMembers = allMembers ? [...currentGroupMembers, ...athletes].filter(a => a.id != 'allAthletes') : currentGroupMembers
    await Promise.all(currentGroupMembers.map(async member => {
        if(member.participants){
            // THIS IS A GROUP, CREATE ENROLLMENTS FOR ALL
            await Promise.all(member.participants.map(async groupParticipant => {
                const enrollmentJSON = { 
                    userId: groupParticipant.id, groupId: member.id, workoutScheduleId: scheduleId 
                }
                await createWorkoutScheduleEnrollment(enrollmentJSON)
            }))
        }
        else {
            const enrollmentJSON = { userId: member.id, workoutScheduleId: scheduleId }
            await createWorkoutScheduleEnrollment(enrollmentJSON)
        }
    }))
}

export const createWorkoutScheduleAssignments = async (domain, startDay, assignedAt, workoutScheduleId, notificationReminder, workoutEntity) => {
    if(workoutEntity.weeks){
        let dayCount = -1
        await Promise.all(Object.values(workoutEntity.weeks).map(async (workouts, weekIndex) => {
            await Promise.all(workouts.map(async (workout, index) => {
                dayCount = dayCount + 1 // increment so assignments are on consecutive days
                if(!workout.isRest && (startDay - 1 <= dayCount)){
                    let assignedDay = moment(assignedAt).add((dayCount-startDay+1), 'days')
                    const assignmentJSON = await buildAssignmentJSON(
                        assignedDay, workoutScheduleId, workout, workoutEntity
                    )
                    const newAssignment = await createWorkoutScheduleAssignment(assignmentJSON)
                    if(notificationReminder && notificationReminder !== 0){
                        const scheduledNotifications = await handleScheduledNotifications(
                            workoutScheduleId, newAssignment, assignedDay, notificationReminder, domain
                        )
                    }
                }
            }))
        }))
    }
    else{
        const assignmentJSON = await buildAssignmentJSON(
            assignedAt, workoutScheduleId, workoutEntity
        )
        const newAssignment = await createWorkoutScheduleAssignment(assignmentJSON)
        if(notificationReminder && notificationReminder !== 0){
            const scheduledNotifications = await handleScheduledNotifications(
                workoutScheduleId, newAssignment, assignedAt, notificationReminder, domain
            )
        }
        return newAssignment
    }
}

export const resolveWorkoutScheduleOptions = async teamId => {
    const schedules = await getWorkoutSchedulesByTeamId(teamId)
    await Promise.all(schedules.map(async schedule => {
        schedule.value = schedule.id
        schedule.label = schedule.name
        schedule.enrollments = await getWorkoutScheduleEnrollments(schedule.id)
    }))
    return schedules
}

export const resolveStartDayOptions = async workoutProgram => {
    // NOT USING THIS RIGHT NOW
    let activitiesLength = 0
    return activitiesLength
}

// HELPERS YAYYY
export const resolveWorkoutScheduleInfo = async (workoutSchedule, domain) => {
    const scheduleEnrollments = await getWorkoutScheduleEnrollments(workoutSchedule.id)
    let currentGroupMembers = []
    await Promise.all(scheduleEnrollments.map(async ({ id, userId }) => {
        try{
            const user = await getUserById(userId, domain)
            user.enrollmentId = id
            user.name = `${user.nameFirst || user.firstName} ${user.nameLast || user.lastName}`
            currentGroupMembers.push(user)
        }
        catch(e){
            console.log('error getting user => ', e)
        }
    }))
    return {
        scheduleName: workoutSchedule.name,
        currentGroupMembers: currentGroupMembers
    }
}

const handleScheduledNotifications = async (workoutScheduleId, assignment, assignedAt, notificationReminder, domain) => {
    let enrollments = await getWorkoutScheduleEnrollments(workoutScheduleId)
    await Promise.all(enrollments.map(async enrollment => {
        const messageBody = await buildMessageBody((enrollment, assignment, assignedAt, notificationReminder, domain))
    }))
}

const buildAssignmentJSON = (assignedAt, workoutScheduleId, workout, workoutProgram) => {
    return{
        workoutId: workout.id,
        workoutName: workout.name,
        workoutScheduleId: workoutScheduleId,
        assignedAt: new Date(assignedAt).getTime(),
        workoutProgramId: workoutProgram ? workoutProgram.id : null,
        workoutProgramName: workoutProgram ? workoutProgram.name : null
    }
}

const buildMessageBody = (enrollment = {}, assignment = {}, assignedAt, notificationReminder, domain) => {
    let activationTime = notificationReminder == 1 ? new Date(assignedAt).setHours(7, 30) :
        new Date(assignedAt).setHours(14)

    return {
        domain: domain,
        isSent: "false",
        parentType: "team",
        sendToType: 'user',
        type: 'pushNotification',
        userId: enrollment.userId,
        sendTo: enrollment.userId,
        parentId: enrollment.teamId,
        sendingByUser: 'Single User',
        activationTime: activationTime,
        createdAt: new Date().getTime(),
        workoutId: assignment.workoutId,
        message: `Your coach has assigned the workout ${assignment.title} for today`,
    }
}
