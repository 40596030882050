import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PageWrapper from "../../../containers/nav/pageWrapper";
import TableComponent from "./Table";
import WhoopLogo from "../../../assets/images/whoop_logo.png";
import HRVLogo from "../../../assets/images/hrv_logo.png";
import ShadowContainer from "../../../components/shadowContainer";
import { AccountCircle } from "@material-ui/icons";
import { VictoryChart, VictoryLabel, VictoryStack, VictoryBar, VictoryAxis, VictoryTheme, VictoryPie } from "victory";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { WhoopIcon, WhoopConnectedIcon, CalendarIcon, BackIcon, HeartBeatIcon, BackArrow, ForwardArrow } from "../icons";
import moment from "moment";
import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import { isEmpty } from "lodash";
import SpeedoMeter from './SpeedoMeter/index'
const styles = () => ({
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    "& svg": {
      fill: "#A0A7AB",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  whoop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whoopStatus: {
    backgroundColor: "#F3F3F3",
    width: 79,
    height: "24px",
    borderRadius: "6px",
    textAlign: "center",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
  },
  status: {
    fontSize: "9px",
    width: "48px",
    height: "10px",
    paddingRight: "5px",
    paddingTop: "2px",
    marginRight: "5px",
    fontWeight: "600",
  },
  connectedIcon: {
    backgroundColor: "#19EC06",
    height: "14px",
    width: "14px",
    borderRadius: "50%",
    textAlign: "center",
    display: "flex",
    color: "#fff",
    // fontSize: "10px",
    justifyContent: "center",
    alignItems: "center",
    margin: "2px 4px 2px 2px",
    "& svg": {
      fontSize: "10px",
    },
  },
  hrvArea: {
    width: '112px',
    height: '33px',
    border: '1.5px solid #D8D8D8',
    boxSizing: 'border-box',
    borderRadius: '100px',
    marginTop: '6px',
    "& svg": {
      height: "14px !important",
      width: "14px !important"
    },
  },

  speedoMeterArea: {
    "& svg": {
      height: "130px",
      width: "325px"
    },
  },
  hrvContainer:{
    "& svg": {
      height: "14px",
      width: "14px"
    },
  }
});

const getGraphData = (habitResults) => {
  const dates = Object.keys(habitResults).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const months = dates.reduce((obj, item) => {
    const month = moment(item).format('MMMM');
    if (!obj[month]) {
      obj[month] = 0;
    }
    obj[month] += 1;
    return obj;
  }, {});
  let tickValues = dates.reduce(
    (array, item) => {
      array.push(habitResults[item].answeredHabits + habitResults[item].unansweredHabits);
      return array;
    },
    [0],
  );
  tickValues = [...new Set(tickValues)].sort();
  const min = tickValues[0],
    max = tickValues[tickValues.length - 1];
  tickValues = [];
  let increement = max > 20 ? 5 : max > 10 ? 4 : 2
  for (let i = min; i <= max; i += increement) {
    tickValues.push(i);
  }
  if (max % 2 !== 0) {
    tickValues.push(max + 1);
  }
  const answeredData = dates.map((item) => {
    return {
      x: moment(item).format('D'),
      y: habitResults[item].answeredHabits,
    };
  });
  const unansweredData = dates.map((item) => {
    return {
      x: moment(item).format('D'),
      y: habitResults[item].unansweredHabits,
    };
  });

  const labelsPosition = Object.keys(months).reduce((array, item, index) => {
    const data = months[item] * (index + 1) * (months[item] > 8 ? 12 : 13) + index * 20;
    array.push(array.reduce((a, b) => a + b, 0) + data);
    return array;
  }, []);

  return { answeredData, unansweredData, months, tickValues, labelsPosition };
};

const StackedBarGraph = ({ habitResults }) => {
  const { answeredData, unansweredData, months, tickValues, labelsPosition } = getGraphData(habitResults);

  return (
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 15 }}
          fixLabelOverlap={true}
          height={200}
          padding={{ top: 10, left: 30, bottom: 50, right: 20 }}
        >
          <VictoryStack colorScale={['#222127', '#ADB4B9']}>
            <VictoryBar barRatio={1} barWidth={5} data={answeredData} style={{ data: { zIndex: 100 } }} />
            <VictoryBar
              barRatio={1}
              barWidth={5}
              style={{ data: { zIndex: 100 } }}
              cornerRadius={{ topLeft: 2.5, topRight: 2.5 }}
              data={unansweredData}
            />
          </VictoryStack>
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'none' },
              grid: { stroke: "lightgrey", strokeWidth: 0.5, zIndex: 1 },
            }}
            tickValues={tickValues}
          />
          {Object.keys(months).map((item, index) => (
            <VictoryLabel
              key={index}
              text={item}
              style={{ fill: "lightgrey", fontSize: 14, textAlign: 'center' }}
              labelPlacement={'parallel'}
              x={labelsPosition[index]}
              y={190}
            />
          ))}
          <VictoryAxis
            padding={{ top: 20, bottom: 60 }}
            tickLabelComponent={<VictoryLabel dy={-7} />}
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'none' },
              tickLabels: { padding: 15, fontWeight: '700' },
              grid: { stroke: 'none' },
            }}
          />
        </VictoryChart>
  );
};

const Athlete = (props) => {
  const {
    athleteColumns,
    habits,
    athleteDetails,
    classes,
    percentage,
    whoopData,
    handleOnBack,
    percentageByDate,
    graphData,
    hrvData,
    handleForwardHrv,
    handleBackHrv,
    currentHrvData,
    currentHrvIndex
  } = props;

  const today = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
  let todayWhoopData = whoopData && whoopData[0] ? whoopData[0] : {}; // whoopData.filter(data => data.date === today);
  let todayHrvData = currentHrvData  ? currentHrvData : {};
  let currentDate = moment().format("YYYY-MM-DDT00:00:00[Z]");
  let athleteFullName = `${athleteDetails.firstName || '' } ${athleteDetails.lastName || ''}`
  return (
    <PageWrapper
    title={"My Athletes"}
      {...props}
    >
      <div className="row mt-2">
        <div className="col-md-5">
        <div style={{ display: "flex", alignItems: "center" }}>
          <BackIcon style={{ height: "18px", width:'10px', marginRight: "20px", cursor: "pointer"}} onClick={
            () => handleOnBack()
          }/>
          <span className={classes.athleteImgBox}>
            {athleteDetails.avatarUrl ? (
              <img src={athleteDetails.avatarUrl} />
            ) : (
              <AccountCircle />
            )}
          </span>
          {athleteFullName}
        </div>        
        </div>
        <div className={`${classes.calendarSection} col-md-7 d-flex justify-content-start`}>
              <CalendarIcon className="mr-1" />
              Today, {`${moment().format("MMM Do")}`}
            </div>

      
      </div>
      <div className="row">
        <div className="col-md-4">
          <ShadowContainer>
            <div style={{ padding: "23px 0px", textAlign: "left", margin: 15 }}>
              <p>HABITS</p>
              {!isEmpty(graphData) ? <StackedBarGraph habitResults={graphData}/> : null}
            </div>
          </ShadowContainer>
        </div>
        <div className="col-md-4">
          {Object.keys(todayWhoopData).length  ? (
          <ShadowContainer style={{}}>
          <div style={{ padding: 26, textAlign: "left", margin: 15 }}>
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img src={WhoopLogo} width={"100"} height={"33"} />
                  <div className={classes.whoopStatus}>
                    <div className={classes.connectedIcon}>
                      <WhoopConnectedIcon />
                    </div>
                    <label className={classes.status}>Connected</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-end">
                  <img src={HRVLogo} width={"34"} height={"25"} />
                  <div className="d-flex flex-column justify-content-center align-items-center pl-2">
                    <div className={classes.hrvText}>
                      {" "}
                      <p className="pb-0 m-0">
                        {todayWhoopData.hrv ? Number(todayWhoopData.hrv).toFixed(0) : 0}
                      </p>
                    </div>
                    <label
                      style={{ color: "rgba(9, 19, 41, 0.5)", fontSize: 10 }}
                    >
                      HRV
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pt-4">
                <CircularProgressbarWithChildren
                  value={todayWhoopData.recoveryScore}
                  strokeWidth={5}
                  styles={buildStyles({
                    // textColor: "red",
                    pathColor: "#19EC06",
                    // trailColor: "gold"
                  })}
                >
                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                  <div style={{ fontSize: 12, marginTop: -5 }}>
                    <strong
                      style={{
                        color: "#091329",
                        fontWeight: "bold",
                        fontSize: "23px",
                      }}
                    >
                      {todayWhoopData.recoveryScore ?  todayWhoopData.recoveryScore : 0}%
                    </strong>
                  </div>
                  <p
                    className="mb-0"
                    style={{ color: "rgba(9, 19, 41, 0.5)", fontSize: 10 }}
                  >
                    RECOVERY
                  </p>
                </CircularProgressbarWithChildren>
              </div>
              <div className="col-md-6 d-flex align-items-end pt-4">
                <CircularProgressbarWithChildren
                  value={todayWhoopData.dailyStrain ? Number(todayWhoopData.dailyStrain).toFixed(1) * 10 : 0}
                  strokeWidth={5}
                >
                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                  <div style={{ fontSize: 12, marginTop: -5 }}>
                    <strong
                      style={{
                        color: "#091329",
                        fontWeight: "bold",
                        fontSize: "23px",
                      }}
                    >
                      {todayWhoopData.dailyStrain ? Number(todayWhoopData.dailyStrain).toFixed(1) : 0}
                    </strong>
                  </div>
                  <p
                    className="mb-0"
                    style={{ color: "rgba(9, 19, 41, 0.5)", fontSize: 10 }}
                  >
                    DAY STRAIN
                  </p>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
        </ShadowContainer>
          ) : (
        <ShadowContainer>
        <div style={{ padding: "32px 0px", margin: 15 }}>
          <p className='mb-0' style={{color: '#A0A7AB'}}>{todayHrvData.date === currentDate ? 'Today,' : '' } {`${moment(todayHrvData.date).format("MMM D")}`}</p>
            <div className={`${classes.hrvContainer} col-12 d-flex justify-content-center`}>
            {/* <BackArrow className="cursor-pointer" height="14px" disabled={currentHrvIndex === hrvData.length - 1} onClick={() => handleBackHrv()} /> */}
            <div  className={classes.hrvArea}>
              <HeartBeatIcon />
              <span style={{ color:'#333333', fontSize: '14px', fontWeight: 'bold', marginLeft:'6px' }}>
                HRV {todayHrvData.hrv ? todayHrvData.hrv.toFixed(0) : 0 }
                </span>
            </div>
            {/* <ForwardArrow className="cursor-pointer" height="14px"  disabled={currentHrvIndex === 0} onClick={() => handleForwardHrv()} /> */}
            </div>
            <div style={{ }} className={classes.speedoMeterArea}>
            <SpeedoMeter  size={20} score={todayHrvData.energyScore} totalValue={10} todayHrvData={todayHrvData}/>
            </div>
        </div>
      </ShadowContainer>
          )}

        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <ShadowContainer>
            <div style={{ padding: "15px 0px", textAlign: "left", margin: 15 }}>
              <p style={{ fontWeight: 700, fontSize: 13, margin: "5px 0px" }}>
                Habit Adherence
              </p>
              <p style={{ fontWeight: 400, fontSize: 13, margin: "5px 0px" }}>
                (Past week)
              </p>
              <p style={{ fontWeight: 700, fontSize: 20, margin: "5px 0px" }}>
                {(percentage || 0).toFixed(0)}%
              </p>
            </div>
          </ShadowContainer>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TableComponent
            columns={athleteColumns}
            data={habits}
            pageSize={(habits || []).length}
            totalCount={(habits || []).length}
            currentPage={0}
            handlePageChange={() => {}}
            handleClick={() => {}}
          />
        </div>
        <div className="col-md-12" style={{}}>
            <Table
              className={classes.tableCurved}
              aria-label="simple table"
              size={"medium"}
            >
              <TableHead>
                <TableRow
                  hover={false}
                  className={classes.tableRow}
                  tabIndex={-1}
                  onClick={() => {}}
                >
                  <TableCell
                    style={{
                      width: "15vw",
                      overflowWrap: "break-word",
                      padding: "10px 10px",
                      borderBottom: 0,
                      fontWeight: "500",
                      color: "#222127",
                      fontSize: "14px"
                    }}
                    align={"left"}
                  >
                    Average
                  </TableCell>
                  <TableCell
                    style={{
                      width: "30vw",
                      overflowWrap: "break-word",
                      padding: "10px 10px",
                      borderBottom: 0,
                    }}
                    align={"left"}
                  ></TableCell>
                  <TableCell
                    style={{
                      width: "15vw",
                      overflowWrap: "break-word",
                      padding: "10px 10px",
                      borderBottom: 0,
                    }}
                    align={"left"}
                  >
                    {/* {(percentage || 0).toFixed(0)}% */}
                  </TableCell>
                  {Object.keys(percentageByDate).map((item, index) => {
                    return (
                      <TableCell
                        style={{
                          width: "4vw",
                          overflowWrap: "break-word",
                          padding: "20px 10px",
                          borderBottom: 0,
                          textAlign: 'end',
                          fontWeight: "500",
                          color: "#222127",
                          fontSize: "14px"
                        }}
                        align={"left"}
                      >
                        {(percentageByDate[item].percentage || 0).toFixed(0)}%
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </Table>
          </div>
      </div>
    </PageWrapper>
  );
};

export default withStyles(styles)(Athlete);
