import React from "react";
import Downshift from "downshift";
import { Paper, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { InputField } from "maxone-react-ui";
import { mapboxAccessToken } from "../../../../utils/commonUtil";
import { isEmpty, isEqual } from "lodash";

const styles = () => ({
  container: {
    padding: "6px 12px",
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: 700,
  },
  subtitle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

class LocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.location.place_name || "",
      suggestions: [],
      location: props.location,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props;
    if (!isEqual(prevProps.location, location) && !isEmpty(location)) {
      this.setState({ location, searchText: location.place_name });
    }
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ suggestions: [] });
    }
  };

  handleChange = async (value) => {
    this.setState({ searchText: value }, () => {
      this.getSuggestionList();
    });
  };

  getSuggestionList = async () => {
    const { searchText } = this.state;
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?types=place%2Cpostcode%2Caddress%2Ccountry%2Cregion%2Cdistrict%2Clocality%2Cneighborhood&access_token=${mapboxAccessToken}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          suggestions: (data.features || []).map(({ place_name, center }) => ({
            place_name,
            center,
          })),
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  handleLocationSelect = (location) => {
    this.props.handleLocationSelect(location);
    this.setState({ location: location, suggestions: [] });
  };

  renderInput = () => {
    const { searchText } = this.state;

    return (
      <InputField
        required
        id="location"
        label="Add Location"
        value={searchText || ""}
        onChange={(value) => this.handleChange(value)}
      />
    );
  };

  renderSuggestion = ({ classes, suggestion, index }) => {
    const { location } = this.state;
    const isSelected =
      suggestion.place_name.localeCompare(location.place_name) === 0;
    const places = suggestion.place_name.split(", ");
    const title = places.shift();
    const subtitle = places.join(", ");
    return (
      <MenuItem
        key={index}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
          height: 49,
        }}
        onClick={() => this.handleLocationSelect(suggestion)}
      >
        <div className={classes.container}>
          <div className={classes.title}>{title}</div>
          <div className={classes.address}>{subtitle}</div>
        </div>
      </MenuItem>
    );
  };

  render() {
    const { suggestions } = this.state;
    const { classes } = this.props;
    return (
      <div ref={this.wrapperRef}>
        <Downshift id="downshift-simple">
          {() => (
            <div>
              {this.renderInput({
                classes,
              })}
              {suggestions.length ? (
                <Paper square>
                  {suggestions.map((suggestion, index) =>
                    this.renderSuggestion({
                      classes,
                      suggestion,
                      index,
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
        <div className={classes.divider} />
      </div>
    );
  }
}

export default withStyles(styles)(LocationInput);
