import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import moment from "moment"

import EnhancedTableHeader from '../../components/enhancedTableHeader'
import EnhancedTableToolbar from '../../components/enhancedTableToolbar'

import { API } from "aws-amplify";

import OrganizationModal from './organizationModal.js';

import logo from "../../assets/images/logo-dark.png"
import PageWrapper from '../nav/pageWrapper';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginLeft:35,
    float:"left"
  },
});

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class AdminOrganizations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 5,
      open: false,
      headers:[
        { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created' },
      ]
    };
  }

  async componentDidMount() {
    var data = await this.getOrganizations()
    console.log('Data ', data)
    this.setState({data})
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClose = (org) => {
    if(org.id){
      //replace updated user in data
      var data =this.state.data
      var element =  data.find(function(d) {
          return d.id === org.id
      })
      var index = data.indexOf(element)
      data.splice(index, 1, org)
      this.setState({data})
    }
    this.setState({ open: false });
  };

  handleOpen = event => {
      this.setState({ open: true, viewingEvent: event });
  };

  getOrganizations() {
    return API.get("admin", `/admin/organizations`);
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data = [], order, orderBy, selected, rowsPerPage, page, headers, open = false, viewingEvent = {} } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <PageWrapper title="Organizations" {...this.props}>
      <div >
      <Grid container spacing={0}>
        <Grid item xs={12} sm={9} style={{justify:"left"}}>
          <h1 className={classes.header}></h1>
        </Grid>
        <Grid item xs={12} sm={3} style={{paddingLeft:50, paddingRight:50}}>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} title={'Organizations'} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              headers={headers}
            />
            <TableBody>
              {data
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, i) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={() => this.handleOpen(n,i)}
                      role="checkbox"
                      tabIndex={-1}
                      key={`${n.id}${i}`}
                      selected={isSelected}
                    >
                      <TableCell component="th" scope="row" >
                        {n.id}
                      </TableCell>
                      <TableCell component="th" scope="row" >
                        {n.name}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">{n.type}</TableCell>
                      {
                        n.isActive
                        ?
                        <TableCell component="th" scope="row" padding="none">Yes</TableCell>
                        :
                        <TableCell component="th" scope="row" padding="none">No</TableCell>
                      }
                      {
                        n.createdAt
                        ?
                        <TableCell component="th" scope="row" padding="none">{moment(n.createdAt, "YYYYMMDD").fromNow() || ""}</TableCell>
                        :
                        <TableCell component="th" scope="row" padding="none">{"No Data"}</TableCell>
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
      <OrganizationModal close={this.handleClose} open={open} organization={viewingEvent} />
      </div>
      </PageWrapper>
    );
  }
}

AdminOrganizations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminOrganizations);
