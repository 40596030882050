import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash"
import { API } from "aws-amplify";
// Material UI
import {
  Grid
} from "@material-ui/core";
import { PrimaryButton, InputField } from 'maxone-react-ui'
import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import { biabClient } from '../../clients'
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
});

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      open: false,
      account: {}
    };
  }


  componentDidMount() {
    const { appContextList, currentTeam } = this.props;
    this.getStripeAccount(currentTeam.organizationId, currentTeam.id).then((account) => {
      if(account){
        if(!account.external_accounts.total_count){
          this.setState({account, showAddDepositInfo: true, tabValue:4})
        }
        else {
          this.setState({account})
        }
      }
    }).catch((e) => {
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  handleChangeInput = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  getStripeAccount = (organizationId, teamId) => {
    return API.get("subscriptions", `/organizations/${organizationId}/team/${teamId}/accounts`);
  }

  saveExternalAccount = async(accountId, accountInfo) => {
    try {
        return await API.post("subscriptions", `/accounts/${accountId}/external`, {body: {accountInfo}});
    }
    catch(e){ return false }
}

  async saveBankInfo() {
    let { currentTeam } = this.props;
    this.setState({ isLoading: true });
    let externalAccount = this.state.account;
    const currency = externalAccount.default_currency;
    const country = externalAccount.country;
    const object = "bank_account";

    try {
      let returned = await this.saveExternalAccount(externalAccount.id, {
        currency,
        country,
        object,
        routing_number: this.state.routingNumber,
        account_number: this.state.accountNumber,
        metadata: {
          organizationId: currentTeam.id,
          tenantId: currentTeam.tenantId
        }
      })
      console.log('returned ', returned)
      if (returned.error) {
        console.log("ERROR  ", returned.error)
        this.setState({ isLoading: false, open: true, message: returned.error.message })
        this.hideSnackBar();
        return
      }
      let account = await this.getStripeAccount(currentTeam.organizationId, currentTeam.id);
      console.log('account ', account)
      this.setState({ account })
      if (account.external_accounts.total_count) {
        this.setState({ showAddDepositInfo: false })
      }
      this.setState({ isLoading: false, open: true, message: `Successfully saved bank info` });
      this.hideSnackBar();

    } catch (e) {
      console.log("ERROR  ", e)
      this.setState({ isLoading: false, open: true, message: 'Oops, something went wrong!' })
      this.hideSnackBar();
    }
  }

  hideSnackBar() {
    setTimeout(() => this.setState({ open: false }), 5000)
  }

  async verify() {
    let { currentTeam } = this.props;
    let data = {
      origin: window.location.origin,
      path: window.location.pathname,
      teamId: currentTeam.id
    }
    let link = await this.getOnboardingLink(currentTeam.organizationId, data)
    console.log('link ', link)
    if (link.url) {
      window.open(link.url, '_blank');
    }
    else {
      this.setState({ open: true, message: 'Oops, something went wrong!' })
      this.hideSnackBar();
    }
  }

  getOnboardingLink = async (organizationId, data) => {
    try {
      return await API.post("subscriptions", `/organizations/${organizationId}/accounts/onboarding`, { body: data });
    }
    catch (e) {
      return false
    }
  }

  render() {
    const { classes, theme, appContext, userContext, tenant } = this.props;
    const { isLoading, account } = this.state;
    return (
      <PageWrapper {...this.props} title={"Bank Information"} isPageLoading={isLoading}>
        <div className={"settingsOuterContainer"}>
          <div>
            <Grid container spacing={8} justify="center"
              alignItems="center" alignContent={"center"}>
              <Grid item xs={12}>
                {!!account && !!account.requirements &&
                <React.Fragment>                
                  {
                    !!account.requirements.currently_due.length &&
                    <Grid container alignItems="center" direction="row" xs={12} sm={12} md={12} style={{ marginLeft: 5, backgroundColor: "#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4, padding: 5 }} >
                      <WarningIcon color="error" style={{ marginLeft: 15, marginRight: 15 }} />
                      <p style={{ textAlign: "left", color: "red", fontSize: 16, fontWeight: 'bold', marginTop: 20, marginRight: 15 }}> Payouts have been disabled for this account until the account has been verified  </p>
                      <PrimaryButton style={{ marginRight: 20, marginLeft: 20 }} text={'Verify'} height={30} width={65} onClick={() => this.verify()} />
                    </Grid>
                  }
                  {
                    !account.external_accounts.total_count &&
                    <Grid container alignItems="center" direction="row" xs={12} sm={12} md={12} style={{ marginLeft: 5, backgroundColor: "#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4, padding: 5 }} >
                      <WarningIcon color="error" style={{ marginLeft: 15, marginRight: 15 }} />
                      <p style={{ textAlign: "left", color: "red", fontSize: 16, fontWeight: 'bold', marginTop: 20, marginRight: 15 }}> Add a bank account to enable payouts  </p>
                    </Grid>
                  }
                  <Grid container
                  spacing={16}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  style={{ marginLeft: 5, marginTop: 20, backgroundColor: "#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4, }}
                >
                  <p style={{ textAlign: "left", fontWeight: 500, fontSize: 20, marginTop: 25, marginLeft: 10 }}>Deposit Information</p>

                  {
                    !!account.external_accounts.total_count
                    &&
                    <Grid container
                      spacing={16}
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginLeft: 5 }}
                    >
                      <Grid item xs={12}>
                        <p style={{ textAlign: "left", fontWeight: 500, marginTop: 10, marginLeft: 5 }}><b>Bank / Acct. Number:</b> {account.external_accounts.data[0].bank_name} x{this.state.account.external_accounts.data[0].last4}</p>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 15 }}>
                        {
                          !this.state.showAddDepositInfo
                          &&
                          <PrimaryButton
                            style={{ marginRight: 20, marginLeft: 20 }}
                            text={'Update Bank Info'}
                            borderColor={'#1354F9'}
                            backgroundColor={'#1354F9'}
                            height={44} width={157} onClick={() => this.setState({ showAddDepositInfo: true })} />
                        }
                      </Grid>
                    </Grid>
                  }
                  {
                    this.state.showAddDepositInfo &&
                    <Grid container
                      spacing={16}
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginLeft: 5 }}
                    >
                      <Grid item xs={12}>
                        <div style={{ maxWidth: 400 }}>
                          <InputField
                            id="routingNumber"
                            label="Routing Number"
                            value={this.state.routingNumber}
                            onChange={(value) => this.handleChangeInput('routingNumber', value)}
                            style={{ width: '100%' }}
                            rightIcon={this.state.routingNumber && this.state.routingNumber.length === 9 ? <CheckCircleIcon style={{ color: "#27AE60" }} /> : this.state.routingNumber && this.state.routingNumber.length > 9 ? <WarningIcon color="error" /> : null}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ maxWidth: 400 }}>
                          <InputField
                            id="accountNumber"
                            label="Account Number"
                            value={this.state.accountNumber}
                            onChange={(value) => this.handleChangeInput('accountNumber', value)}
                            style={{ width: '100%' }}
                            rightIcon={this.state.accountNumber ? <CheckCircleIcon style={{ color: "#27AE60" }} /> : null}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ maxWidth: 400 }}>
                          <InputField
                            id="accountNumberConfirmed"
                            label="Re-confirm Account Number"
                            value={this.state.accountNumberConfirmed}
                            onChange={(value) => this.handleChangeInput('accountNumberConfirmed', value)}
                            rightIcon={this.state.accountNumberConfirmed && (this.state.accountNumberConfirmed === this.state.accountNumber) ? <CheckCircleIcon style={{ color: "#27AE60" }} /> : this.state.accountNumberConfirmed ? <WarningIcon color="error" /> : null}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </Grid>
                      <Grid container spacing={16} style={{ marginTop: 10, marginBottom: 15, padding: 10 }}>
                        <Grid item >
                          <PrimaryButton
                            style={{ marginRight: 20, marginLeft: 20 }}
                            text={'Save Bank Info'}
                            borderColor={'#1354F9'}
                            backgroundColor={'#1354F9'}
                            disabled={!this.state.routingNumber || this.state.routingNumber.length !== 9 || !this.state.accountNumber || !this.state.accountNumberConfirmed || (this.state.accountNumber !== this.state.accountNumberConfirmed)}
                            height={44} width={157} onClick={() => this.saveBankInfo()} />
                        </Grid>

                        <Grid item >
                          {
                            account.external_accounts.total_count
                              ?
                              <PrimaryButton
                                style={{ marginRight: 20, marginLeft: 20 }}
                                text={'Cancel'}
                                borderColor={'#DDE2E5'}
                                backgroundColor={'#F8F8F8'}
                                textColor={'black'}
                                height={44} width={157} onClick={() => this.setState({ showAddDepositInfo: false, accountNumber: "", accountNumberConfirmed: "", routingNumber: "" })} />
                              : null
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </React.Fragment>
                }
              </Grid>
            </Grid>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    currentTeam: currentTeam || {},
    appContextList: userContext.appContextList,
    userContext: userContext,
    appContext: appContext
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Settings));
