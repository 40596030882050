import React, { Component, Linking } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Papa from "papaparse";
import { API, Auth, Storage } from "aws-amplify";
import _ from "lodash";
// import { graphql, compose } from 'react-apollo'
import { withStyles } from "@material-ui/core/styles";
import { Button, Hidden } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ShareIcon from "@material-ui/icons/Share";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { getLocalizedString } from "../../../utils/locale-utils";
import "../roster.css";
import TrueLacrosseUserModal from "../../truelacrosse/trueLacrosseUserModal";
import TrueLacrosseAddAthleteModal from "../../truelacrosse/trueLacrosseAddAthletesModal";
import { Checkbox } from "@material-ui/core";
import ContextService from "../../../services/context";
import { analyticsService } from "../../../services/analyticsService";
import PageWrapper from "../../nav/pageWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import { GoalIcon } from "../../../components/icons";
import { ROUTE_PATH } from "../../../routeUtil";
import FEATURES from "../../../features";
import { tenantMap, downloadFileFromUrl } from "../../../utils/commonUtil";
import SearchInput from "../../../components/searchInput";
import TabularDesign from "../../../components/tabularDesign";
import ThemeButton from "../../../components/themeButton";
import AddAthleteModal from "./addAthleteModal";
import AddWCSAthleteModal from "./addWCSAthleteModal";
import { userClient, teamClient, hrvClient } from "../../../clients";
import classnames from "classnames";
import SelectTeamsModal from "../../selectTeamsModal";
import AthleteImporter from "../../seImporter/athleteImporter";
import moment from "moment";

const contextService = new ContextService();

// import {
//     registerUser,
//     createConvo,
//     createConvoLink,
//     updateConvoLink
// } from '../chat/chat/graphql/mutations'

// const convoList = {}

// var ses = new AWS.SES();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  modalInviteButton: {
    width: 100,
    height: 40,
    marginRight: 10,
    backgroundColor: "#27AE60",
    color: theme.product.name == "MaxOne" ? "black" : "white",
    color: theme.palette.secondary.isDarkMode ? "#ffffff" : "black",
  },
  addUserButton: {
    height: 40,
    color: "white",
    backgroundColor: "#1354F9",
  },
  plusButton: {
    backgroundColor: "#1354F9",
    color: "white",
    outlineColor: "transparent",
    outlineStyle: "none",
  },
  textButton: {
    color: "white",
    height: "40px",
    marginRight: "3px",
    backgroundColor: "#1354F9",
  },
  pageTitle: {
    textAlign: "left",
    marginLeft: 50,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: `100%`,
  },
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    "& svg": {
      fill: "#A0A7AB",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  lastNameSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "nowrap",
  },
  checkboxRoot: {
    width: "16px",
    height: "16px",
    "& svg": {
      fill: "#A0A7AB",
    },
  },
  checkboxChecked: {
    "& svg": {
      fill: "#1354F9",
    },
  },
  checkBoxContainer: {
    width: "32px",
    position: "relative",
    overflow: "visible !important",
  },
  athleteList: {
    "& .roundCommandButton": {
      color: "#DDE2E5",
    },
  },
  searchBox: {
    height: "44px",
    width: "300px",
  },
  editUser: {
    paddingRight: "30px !important",
  },
  rightSection: {
    display: "flex",
    padding: "10px",
    textAlign: "right",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  generateCSVBtn: {
    marginRight: "10px",
  },
  pageInfo: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "right",
    color: "#5B6062",
    marginLeft: "auto",
  },
});

const ACTION_ITEMS = Object.freeze({
  ADD_TO_TEAM: "ADD_TO_TEAM",
  DELETE_FROM_TEAM: "DELETE_FROM_TEAM",
  TRANSFER_TO_TEAM: "TRANSFER_TO_TEAM",
});

class AthleteRoster extends Component {
  constructor(props) {
    super(props);
    this.typingTimeout = null;
    this.fields = [
      {
        displayName: "",
        value: "avatar",
      },
      {
        displayName: "Last Name",
        value: "lastName",
      },
      {
        displayName: "First Name",
        value: "firstName",
      },
      {
        displayName: "Grade Year",
        value: "graduationYear",
      },
      {
        displayName: "Birthdate",
        value: "birthday",
      },
      {
        displayName: "Date Joined",
        value: "joinedDate",
      },
    ];
    this.actionItems = [
      {
        isDirectorFeature: true,
        displayName: "Add to Team",
        value: ACTION_ITEMS.ADD_TO_TEAM,
      },
      {
        isDirectorFeature: true,
        displayName: "Transfer to Team",
        value: ACTION_ITEMS.TRANSFER_TO_TEAM,
      },
      {
        displayName: "Remove From Team",
        color: "#F93F3F",
        value: ACTION_ITEMS.DELETE_FROM_TEAM,
      },
    ];
    this.state = {
      selectedUserIds: new Set(),
      currentPage: 0,
      value: 0,
      email: "",
      csvType: 2,
      contact: "",
      open: false,
      openWCS: false,
      openTrueLacrosse: false,
      wcsAthletes: [],
      trueLacrosseAthletes: [],
      wcsSendInviteOpen: false,
      trueLacrosseSendInviteOpen: false,
      sendingInvites: false,
      sentInfo: false,
      athletes: [],
      contacts: [],
      nameLast: "",
      nameFirst: "",
      phoneNumber: "",
      errorMessage: "",
      searchText: null,
      selectedAthlete: null,
      viewAthlete: {},
      activityResults: [],
      showAthleteModal: false,
      leaderboardResults: [],
      invalidPhoneNumber: false,
      rosterFilterBy: "graduationYear",
      isAthleteHistoryModalOpen: false,
      //Temp fix to prevent 413 error
      athleteDataFetchBatches: 3,
      loadingChildComponent: false,
      loadingChildMessage: "",
      loadingMessage: "hang tight while we grab your athletes...",
      athletePerPageLimit:
        props.tenant == tenantMap.wcs || props.tenant == tenantMap.truelacrosse
          ? 10000
          : 20,
      isLoading: true,
      actionItems: [],
      allSelected: false,
    };
  }

  async getTrueLacrosseAthletes(currentBatch, totalBatches) {
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
      };
      return API.get(
        "truelacrosse",
        `/truelacrosse/athletes?batchCount=${totalBatches}&currentBatch=${currentBatch}`
      );
    });
  }

  deleteRole = (roleId) => {
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
      };
      return API.del("users", `/users/roles/${roleId}`);
    });
  };

  getRoles = (athleteId) => {
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
      };
      return API.get("users", `/users/${athleteId}/roles`);
    });
  };

  getAthletes(id) {
    const teamId = id ? id : this.props.currentTeam.id;
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
      };
      return API.get("programs", `/programs/${teamId}/players`);
    });
  }

  getOrganizationAthletes(id) {
    const orgId = id ? id : this.props.currentTeam.organizationId;
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
      };
      return API.get("organizations", `/organizations/${orgId}/athletes`);
    });
  }

  async sendLoginInfo(organization, userIds) {
    const { currentTeam } = this.props;
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
      };
      if (window.location.href.toLowerCase().includes("wcs")) {
        return API.post("wcs", `/wcs/invites`, {
          body: {
            sentBy: organization,
            sendToType: "team",
            userType: "athlete",
            sendTo: this.props.currentTeam.id,
            userIds: userIds,
          },
        });
      } else {
        return API.post("truelacrosse", `/truelacrosse/invites`, {
          body: {
            sentBy: organization,
            sendToType: "team",
            userType: "athlete",
            sendTo: this.props.currentTeam.id,
          },
        });
      }
    });
  }

  signupAthlete(contact) {
    const { currentTeam } = this.props;
    return Auth.currentSession().then((auth) => {
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken,
        },
        body: { contact, currentTeam, tenant: currentTeam.tenant },
      };
      return API.post("users", `/users/athlete`, options);
    });
  }

  getGroups() {
    const { currentTeam } = this.props;
    return API.get("groups", `/programs/${currentTeam.id}/groups`);
  }

  saveGroup(group) {
    return API.put("groups", `/groups/${group.id}`, {
      body: group,
    });
  }
  getOrganization(organizationId) {
    return API.get("organizations", `/organizations/${organizationId}`);
  }

  async componentDidMount() {
    const { userContext, appContext, tenant } = this.props;
    const { athleteDataFetchBatches } = this.state;
    //Set applicable action items
    let actionItems = (this.actionItems || []).filter(
      (item) =>
        (appContext.isOwner && item.isDirectorFeature) ||
        !item.isDirectorFeature
    );
    this.setState({ actionItems: actionItems });
    this.fetchAthletes();
    if (tenant == tenantMap.wcs) {
      // // if WCS
      const wcsOrgId = this.props.currentTeam.organizationId.split("-")[1];
      let wcsAthletes = [];
      let students = localStorage.getItem(`@wcs:students-${wcsOrgId}:`, null);
      console.log("students ===== ", students);
      if (students && JSON.parse(students).length) {
        wcsAthletes = JSON.parse(students);
      } else {
        wcsAthletes = await this.getOrganizationAthletes(
          this.props.currentTeam.organizationId
        );
        localStorage.setItem(
          `@wcs:students-${wcsOrgId}:`,
          JSON.stringify(wcsAthletes)
        );
      }
      this.setState({
        wcsAthletes: wcsAthletes,
      });
    } else if (tenant == tenantMap.truelacrosse) {
      // // if TrueLacrosse
      let trueLacrosseAthletes = [];
      let requestBatches = [];
      //Fetch truelacrosse user in parallel
      for (let i = 1; i <= athleteDataFetchBatches; i++) {
        requestBatches.push(
          this.getTrueLacrosseAthletes(i, athleteDataFetchBatches)
        );
      }
      let responses = await Promise.all(requestBatches);
      trueLacrosseAthletes = responses.flat();
      this.setState({
        trueLacrosseAthletes: trueLacrosseAthletes,
      });
    }
  }

  async downloadAthleteReport(athlete) {
    const { currentTeam } = this.props;
    const report = await hrvClient.createHRVReportByUserIdandTeamId(
      athlete.id,
      currentTeam.id,
      currentTeam.name,
      moment().subtract(14, "days").format(),
      moment().format()
    );
    if (report && report.data && report.data.s3Link) {
      window.open(report.data.s3Link, "_blank");
    }
  }

  fetchAthletes = async (page = null) => {
    let { currentTeam, enableFullScreenLoader } = this.props;
    let {
      currentPage,
      athletePerPageLimit,
      searchTerm,
      originalCount,
    } = this.state;
    if (page !== null) {
      this.setState({
        loadingChildComponent: true,
        loadingChildMessage: "Loading...",
      });
    }
    let queryParams = {
      "teams.team-role.keyword": `${currentTeam.id}-ATHLETE`,
      limit: athletePerPageLimit,
      offset: (page !== null ? page : currentPage) * athletePerPageLimit,
      fields: "firstName,lastName,graduationYear",
    };
    if (searchTerm) {
      queryParams.q = searchTerm;
    }
    try {
      let {
        data: { total: athletesCount, results: athletes },
      } = await teamClient.searchMembersByTeamId(currentTeam.id, queryParams);
      (athletes || []).forEach((athlete) => {
        athlete.joinedDate =
          (
            ((athlete.teams || {}).results || []).find(
              (item) => item.id == currentTeam.id
            ) || {}
          ).joinedDate || "";
        if (!!athlete.joinedDate) {
          athlete.joinedDate = moment(athlete.joinedDate).format("YYYY-MM-DD");
        }
      });
      this.setState({
        athletesCount,
        originalCount: searchTerm ? originalCount : athletesCount,
        athletes,
        currentPage: page,
        totalPages: Math.ceil(athletesCount / athletePerPageLimit),
        selectedUserIds: new Set(),
      });
    } catch (e) {
      console.log(e);
    }
    enableFullScreenLoader(false);
    this.setState({
      isLoading: false,
      loadingMessage: "",
      loadingChildComponent: false,
    });
  };

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  toggleInviteModal = () => {
    // if(window.location.href.toLowerCase().includes('vnn'))
    //     return window.open('https://teammate.getvnn.com', '_blank')
    const { userContext } = this.props;
    console.log("userContext ", userContext);
    if (window.location.href.toLowerCase().includes("wcs")) {
      console.log("WCS True");
      this.setState({ openWCS: !this.state.openWCS });
      return;
    }
    if (window.location.href.toLowerCase().includes("truelacrosse")) {
      console.log("WCS True");
      this.setState({ openTrueLacrosse: !this.state.openTrueLacrosse });
      return;
    }
    console.log("WCS False");
    if (!this.state.open) {
      const { currentTeam } = this.props;
      analyticsService.trackEvent("Account Added User", {
        program_id: currentTeam.id,
        user_type: "athlete",
      });
    }

    this.setState({ open: !this.state.open });
  };

  toggleUserSelection = (userId) => {
    let { selectedUserIds } = this.state;
    let clonedUserIds = new Set(selectedUserIds);
    if (clonedUserIds.has(userId)) {
      clonedUserIds.delete(userId);
    } else {
      clonedUserIds.add(userId);
    }
    this.setState({ selectedUserIds: clonedUserIds });
  };

  cancelInviteModal = () => {
    this.setState({
      open: false,
      contacts: [],
    });
  };

  removeContactFromList = (contact) => {
    this.setState({ contacts: _.without(this.state.contacts, contact) });
  };

  editContact = (contact) => {
    this.setState({
      nameFirst: contact.nameFirst,
      nameLast: contact.nameLast,
      phoneNumber: contact.phoneNumber ? contact.phoneNumber : "",
      email: contact.email ? contact.email : "",
      contacts: _.without(this.state.contacts, contact),
      value: 0,
    });
  };

  handleNewContact = async () => {
    const {
      nameFirst = "",
      nameLast = "",
      phoneNumber = "",
      email = "",
      contacts,
    } = this.state;
    let strippedNumber = "";

    if (email != "" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      return this.setState({
        errorMessage: "Oops, invalid email.",
        invalidPhoneNumber: false,
        message: "",
      });

    if (phoneNumber) {
      strippedNumber = phoneNumber.replace(/[^0-9\.]+/g, "");
      if (strippedNumber.match(/[^$,.\d]/))
        return this.setState({
          invalidPhoneNumber: true,
          message: "invalid characters",
        });
      if (strippedNumber.length < 10)
        return this.setState({
          invalidPhoneNumber: true,
          message: "did you forget an area code?",
        });
      if (strippedNumber.length > 10) {
        if (strippedNumber.split("")[0] == "1")
          strippedNumber = strippedNumber.substr(1);
        else
          return this.setState({
            invalidPhoneNumber: true,
            message: "did you add too many numbers?",
          });
      }
    }

    if (!nameFirst || nameFirst === "")
      return this.setState({
        errorMessage: "Oops, please enter a first name.",
        invalidPhoneNumber: false,
        message: "",
      });

    if (!nameLast || nameFirst === "")
      return this.setState({
        errorMessage: "Oops, Please enter a last name.",
        invalidPhoneNumber: false,
        message: "",
      });

    if (!email)
      return this.setState({
        errorMessage: "Oops, you need to enter an email for this athlete.",
        invalidPhoneNumber: false,
        message: "",
      });

    if (email.length === 0 && phoneNumber.length === 0)
      return this.setState({
        errorMessage: "Oops, please enter an Email or a Phone Number.",
        invalidPhoneNumber: false,
        message: "",
      });

    const contactJSON = {
      nameFirst: nameFirst,
      nameLast: nameLast,
      phoneNumber: strippedNumber === "" ? null : strippedNumber,
      email: email === "" ? null : email,
    };

    this.setState({
      value: 0,
      email: "",
      message: "",
      nameLast: "",
      nameFirst: "",
      phoneNumber: "",
      errorMessage: "",
      invalidPhoneNumber: false,
      contacts: [...contacts, contactJSON],
    });

    return contactJSON;
  };

  handleInviteSend = async () => {
    const { nameFirst, nameLast, email, phoneNumber } = this.state;
    var contacts = this.state.contacts;

    if (nameFirst || nameLast || email || phoneNumber) {
      const newAthleteContact = await this.handleNewContact();
      contacts.push(newAthleteContact);
    }

    const added = await Promise.all(
      contacts.map(async (contact) => {
        try {
          const newUser = await this.signupAthlete(contact);
          return newUser;
        } catch (e) {
          alert(e.message);
        }
      })
    );

    this.setState(
      {
        open: false,
        contacts: [],
      },
      this.fetchAthletes
    );
  };

  handleAddWCSAthlete = async (user) => {
    console.log("user ===> ", user);
    const { contacts } = this.state;
    this.setState({ contacts: [...contacts, user] });
  };

  handleAddTrueLacrosseAthlete = async (user) => {
    console.log("user ===> ", user);
    const { contacts } = this.state;
    this.setState({ contacts: [...contacts, user] });
  };

  handleSubmitWCSAthletes = async () => {
    const { currentTeam } = this.props;
    const { contacts } = this.state;
    console.log("currentTeam ", currentTeam);
    let added = [];
    await this.asyncForEach(contacts, async (user) => {
      const roleJSON = {
        legacyId: 0,
        role: "player",
        parentId: currentTeam,
        userId: user.id,
        tenant: "wcs",
        tenantId: "wcs",
      };
      console.log("roleJSON ", roleJSON);
      const newRole = await this.createRole(roleJSON);
      added.push(user);
    });
    this.setState(
      { openWCS: false, wcsSendInviteOpen: true },
      this.fetchAthletes
    );
  };

  handleSubmitTrueLacrosseAthletes = async () => {
    const { currentTeam } = this.props;
    const { contacts } = this.state;
    let added = [];
    await this.asyncForEach(contacts, async (user) => {
      const roleJSON = {
        legacyId: 0,
        role: "player",
        parentId: currentTeam,
        userId: user.id,
        tenant: "truelacrosse",
        tenantId: "truelacrosse",
      };
      console.log("roleJSON ", roleJSON);
      const newRole = await this.createRole(roleJSON);
      added.push(user);
    });
    this.setState(
      {
        contacts: [],
        openTrueLacrosse: false,
        trueLacrosseSendInviteOpen: true,
      },
      this.fetchAthletes
    );
  };

  createRole(role) {
    return API.post("users", `/users/${role.userId}/roles`, {
      body: role,
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTabValueChange = (event, value) => {
    this.setState({ value });
  };

  handleRosterTypeChange = (event) => {
    this.setState({
      csvType: parseInt(event.target.value),
    });
  };

  handleView = (athlete) => {
    if (window.location.href.toLowerCase().includes("truelacrosse")) {
      this.setState({ showAthleteModal: true, viewAthlete: athlete });
      return;
    }
    this.props.history.push("/profile", { user: athlete, role: "athlete" });
  };

  handleDelete = async (userIds) => {
    const { currentTeam, enableFullScreenLoader, allSelected } = this.props;
    this.setState({ showAthleteModal: false });
    enableFullScreenLoader(true, "Deleting...");
    try {
      // const roles = await this.getRoles(athlete.id);
      // const currentRole = _.find(roles, role => role.parentId === currentTeam.id);
      // const deletedRole = await this.deleteRole(currentRole.id);
      // const groups = await this.getGroups()
      // await Promise.all(groups.map(async group => {
      //     let athletePresent = _.find(group.participants, p => p.id == athlete.id)
      //     group.participants = _.without(group.participants, athletePresent)
      //     group = await this.saveGroup(group)
      // }))
      await teamClient.removeAthletesFromTeam(userIds, currentTeam.id);
      analyticsService.trackEvent("Account Removed User", {
        user_id: userIds,
        program_id: "",
        team_id: currentTeam.id,
        organization_id: currentTeam.organizationId,
        user_type: "athlete",
        sport: currentTeam.sport,
      });
    } catch (e) {
      console.log(e);
    }
    enableFullScreenLoader(false);
    this.fetchAthletes(0);
    this.setState({ allSelected: false });
    // const refreshedAthletes = await this.handleRosterRefresh();
  };

  handleSendLoginInfo = async () => {
    this.setState({ sendingInvites: true });
    const { currentTeam } = this.props;
    let { contacts, athletes } = this.state;
    contacts = [...new Set([...contacts, ...athletes])];
    const organization = await this.getOrganization(currentTeam.organizationId);
    const sentInfo = await this.sendLoginInfo(
      organization,
      contacts.map((item) => item.id)
    );
    console.log("sentInfo ---> ", sentInfo);
    this.setState({ sentInfo, contacts: [] });
  };

  selectCsv = (event) => {
    const { contacts } = this.state;
    const csv = event.target.files[0];

    if (!csv)
      return this.setState({
        errorMessage: "Oops, we had trouble with that file. Try again!",
      });
    Papa.parse(csv, {
      dynamicTyping: true,
      complete: (results) => {
        let csvData = [];

        // first entry is label row
        // better way to do this?
        if (
          _.first(results.data)[0].toLowerCase().includes("first") ||
          _.first(results.data)[2].toLowerCase().includes("firstname")
        )
          csvData = _.without(results.data, results.data[0]);
        else csvData = results.data;

        this.addCsvPlayers(csvData);
      },
    });
  };

  // cycle through parsed csv and
  // display information in UI
  addCsvPlayers = (csvData) => {
    _.each(csvData, (singlePlayerArray) => {
      this.addPlayerFromCsv(singlePlayerArray);
    });
  };

  addPlayerFromCsv = (playerArray) => {
    const { csvType, contacts } = this.state;
    // 0 = Hudl roster CSV
    // 1 = TeamSnap roster CSV
    // 2 = Personal roster CSV

    // set up player JSON based on CSV type
    const player = {};

    player.nameFirst =
      csvType === 0
        ? playerArray[2]
        : csvType === 1 || csvType === 2
        ? playerArray[0]
        : "";
    player.nameLast =
      csvType === 0
        ? playerArray[3]
        : csvType === 1 || csvType === 2
        ? playerArray[1]
        : "";
    player.email =
      csvType === 0
        ? playerArray[4]
        : csvType === 1
        ? playerArray[9]
        : csvType === 2
        ? playerArray[3]
        : "";
    player.phoneNumber =
      csvType === 0
        ? `${playerArray[5]}`
        : csvType === 1
        ? `${playerArray[10]}`
        : csvType === 2
        ? `${playerArray[2]}`
        : "";

    // show new player JSON in UI
    this.setState({ contacts: [...contacts, player] });
  };

  handleSelect = (athlete) => {
    this.setState({
      selectedAthlete: athlete == this.state.selectedAthlete ? null : athlete,
    });
  };

  searchAthletes = (searchTerm, timeOut = 0) => {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.setState(
      {
        searchTerm,
      },
      () => {
        this.typingTimeout = setTimeout(() => {
          this.fetchAthletes(0);
        }, timeOut || 1000);
      }
    );
  };

  handleFilter = (filterValue) => (event) => {
    this.setState({
      rosterFilterBy: filterValue,
    });
  };

  navigateToGoal = (athlete) => {
    let { history } = this.props;
    history.push(ROUTE_PATH.ATHLETE_GOALS, { userId: athlete.id });
  };

  toggleSelectTeamModal = () => {
    let { selectModalOpen, selectedActionItem, actionItems } = this.state;
    let teamSelectTitle = "";
    let teamSelectSubHeading = "";
    if (!selectModalOpen) {
      teamSelectTitle = "Add Athletes to..";
      teamSelectSubHeading = "Search and select the team to add athletes to";
      if (
        actionItems[selectedActionItem].value == ACTION_ITEMS.TRANSFER_TO_TEAM
      ) {
        teamSelectTitle = "Transfer Athletes to..";
        teamSelectSubHeading =
          "Search and select the team to transfer athletes to";
      }
    } else {
      teamSelectTitle = "";
      teamSelectSubHeading = "";
    }
    this.setState({
      selectModalOpen: !selectModalOpen,
      teamSelectTitle,
      teamSelectSubHeading,
    });
  };

  handleTeamSelection = async (teamIds) => {
    let { selectedActionItem, selectedUserIds, actionItems } = this.state;
    let { currentTeam, tenant, enableFullScreenLoader } = this.props;
    let userIds = Array.from(selectedUserIds);
    try {
      this.toggleSelectTeamModal();
      enableFullScreenLoader(
        true,
        "Please wait while we process your request..."
      );
      if (actionItems[selectedActionItem].value == ACTION_ITEMS.ADD_TO_TEAM) {
        await teamClient.addAthletesToTeam(userIds, teamIds[0], tenant);
      } else if (
        actionItems[selectedActionItem].value == ACTION_ITEMS.TRANSFER_TO_TEAM
      ) {
        await teamClient.switchAthletesToTeam(
          userIds,
          currentTeam.id,
          teamIds[0],
          tenant
        );
      }
    } catch (e) {
      console.log(e);
    }
    this.fetchAthletes(0);
    this.setState({ selectedActionItem: "" });
    enableFullScreenLoader(false);
  };

  selectActionItem = (selectedOption) => {
    this.setState({ selectedActionItem: selectedOption });
  };

  applyBulkOption = () => {
    let {
      selectedActionItem,
      selectedUserIds,
      actionItems,
      allSelected,
    } = this.state;
    switch (actionItems[selectedActionItem].value) {
      case ACTION_ITEMS.DELETE_FROM_TEAM:
        this.handleDelete(Array.from(selectedUserIds));
        break;
      case ACTION_ITEMS.ADD_TO_TEAM:
      case ACTION_ITEMS.TRANSFER_TO_TEAM:
        this.toggleSelectTeamModal();
    }
  };

  generateCSVInfo = async () => {
    let { enableFullScreenLoader, currentTeam } = this.props;
    try {
      enableFullScreenLoader(
        true,
        "Please wait while we process your request..."
      );
      let {
        data: { message },
      } = await teamClient.getAthleteCsvByTeamId(currentTeam.id);
      await downloadFileFromUrl(message, `${currentTeam.name}_athletes.csv`);
    } catch (e) {
      console.log(e);
    }
    enableFullScreenLoader(false);
  };
  handlefetchAthletes = () => {
    this.setState(
      {
        isLoading: true,
        loadingMessage: "hang tight while we grab your athletes...",
        searchTerm: "",
      },
      () => this.fetchAthletes()
    );
  };

  toggleSelectAllOnPage = () => {
    let { selectedUserIds, allSelected, athletes } = this.state;
    let clonedUserIds = new Set(selectedUserIds);
    athletes.forEach((athlete) => {
      if (allSelected) {
        if (clonedUserIds.has(athlete.id)) {
          clonedUserIds.delete(athlete.id);
        }
      } else {
        if (!clonedUserIds.has(athlete.id)) {
          clonedUserIds.add(athlete.id);
        }
      }
    });
    this.setState({
      selectedUserIds: clonedUserIds,
      allSelected: !allSelected,
    });
  };

  render() {
    const {
      open,
      email,
      value,
      message,
      csvType,
      athletes = [],
      nameLast,
      contacts,
      nameFirst,
      isLoading,
      phoneNumber,
      rosterFilterBy,
      loadingMessage,
      selectedAthlete,
      wcsSendInviteOpen,
      trueLacrosseSendInviteOpen,
      invalidPhoneNumber,
      activityResults = [],
      showAthleteModal,
      viewAthlete,
      loadingChildComponent,
      loadingChildMessage,
      currentPage,
      totalPages,
      selectedActionItem,
      selectedUserIds,
      searchTerm,
      teamSelectTitle,
      selectModalOpen,
      teamSelectSubHeading,
      athletesCount,
      athletePerPageLimit,
      originalCount,
      actionItems,
      allSelected,
    } = this.state;
    const {
      classes,
      currentTeam,
      tenant,
      appContext,
      userContext,
      tenantFeatures,
      history,
      enableFullScreenLoader,
    } = this.props;
    const isTrueLacrosse = tenant == tenantMap.truelacrosse;
    const isGoalFeatureAvailable = tenantFeatures.has(FEATURES.GOALS);

    return (
      <PageWrapper
        title={getLocalizedString("ATHLETES")}
        {...this.props}
        isPageLoading={isLoading}
        loadingMessage={loadingMessage}
      >
        <BodyWrapper
          removePadding={true}
          isLoading={loadingChildComponent}
          showLoadingChild={true}
          loadingMessage1={loadingChildMessage}
        >
          <div className={"athletes-roster-container"}>
            <div className={classes.searchBarContainer}>
              <div
                className={classnames(
                  classes.topSearchSection,
                  "row align-items-center "
                )}
              >
                <div className={"col-md-5 pr-0"}>
                  <SearchInput
                    placeholder={"Search Athlete"}
                    customClass={classes.searchBox}
                    searchTerm={searchTerm}
                    handleSearch={this.searchAthletes}
                  />
                </div>
                <div
                  className={classnames(
                    classes.rightSection,
                    "col-md-7 pl-0 d-flex align-items-center justify-content-end"
                  )}
                >
                  <div className="mr-2">
                    <ThemeButton
                      width={"150px"}
                      textColor={"#ffffff"}
                      color={"#1354F9"}
                      height={"40px"}
                      theme="leftAlignIcon"
                      iconName="Update"
                      onClick={() => this.handlefetchAthletes()}
                      text={"Update"}
                      position="left"
                    />
                  </div>
                  <div className="mr-2">
                    <AthleteImporter history={history} tenant={tenant} />
                  </div>
                  <ThemeButton
                    overrideContainerClassName={classes.generateCSVBtn}
                    width={"202px"}
                    textColor={"#ffffff"}
                    color={"#1354F9"}
                    height={"40px"}
                    theme="small"
                    iconName="Download"
                    disabled={!originalCount}
                    onClick={this.generateCSVInfo}
                    text={"Export Athlete Information"}
                  />
                  {(!appContext.isStaff ||
                    (appContext.isStaff &&
                      contextService.isStaffPermitted(
                        currentTeam,
                        "canInviteUsers"
                      ))) &&
                  (window.location.href.toLowerCase().includes("wcs") ||
                    window.location.href
                      .toLowerCase()
                      .includes("truelacrosse")) ? (
                    <span
                      className={"titleButtonContainer"}
                      style={{ marginRight: 10 }}
                    >
                      <Button
                        mini
                        title="Send Invites"
                        variant="fab"
                        className={classes.plusButton}
                        onClick={() =>
                          window.location.href.toLowerCase().includes("wcs")
                            ? this.setState({
                                wcsSendInviteOpen: true,
                                sentInfo: false,
                              })
                            : this.setState({
                                trueLacrosseSendInviteOpen: true,
                                sentInfo: false,
                              })
                        }
                      >
                        <ShareIcon
                          className={"icon"}
                          style={{ color: "white" }}
                        />
                      </Button>
                    </span>
                  ) : null}
                  {tenant !== "coachup" &&
                  (!appContext.isStaff ||
                    (appContext.isStaff &&
                      contextService.isStaffPermitted(
                        currentTeam,
                        "canInviteUsers"
                      ))) ? (
                    <span className={"titleButtonContainer"}>
                      <Button
                        mini
                        title="Add Athletes"
                        variant="fab"
                        className={classes.plusButton}
                        onClick={this.toggleInviteModal}
                      >
                        <AddIcon
                          className={"icon"}
                          style={{ color: "white" }}
                        />
                      </Button>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <TabularDesign
              currentPage={currentPage}
              totalPages={totalPages}
              changeCurrentPage={(page) => this.fetchAthletes(page - 1)}
              selectedActionItem={selectedActionItem}
              selectActionItem={this.selectActionItem}
              actionItems={actionItems}
              actionDisabled={!selectedUserIds.size}
              applyBulkOption={this.applyBulkOption}
              footerTxt={
                !isLoading && (
                  <div className={classes.pageInfo}>
                    Showing
                    <span className={"font-weight-bold"}>
                      {" "}
                      {currentPage * athletePerPageLimit +
                        Math.min(athletes.length, 1)}{" "}
                      - {currentPage * athletePerPageLimit + athletes.length}{" "}
                    </span>{" "}
                    of{" "}
                    <span>
                      {athletesCount}
                      {!!searchTerm && `(total ${originalCount})`}
                    </span>
                  </div>
                )
              }
            >
              <React.Fragment>
                <thead>
                  <tr className={classes.tableHead}>
                    <th className={classes.checkBoxContainer}>
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checkboxChecked,
                        }}
                        onChange={() => this.toggleSelectAllOnPage()}
                        checked={allSelected}
                      />
                    </th>
                    {this.fields.map((field) => {
                      return (
                        <th className={`header-${field.value}`}>
                          {field.displayName}
                        </th>
                      );
                    })}
                    <th className={classnames(classes.editUser, "text-right")}>
                      Edit User
                    </th>
                  </tr>
                </thead>
                <tbody className={classes.athleteList}>
                  {athletes.map((athlete, index) => (
                    <tr key={index} className={classes.rowSelected}>
                      <td
                        className={classnames(
                          classes.checkBoxContainer,
                          "checkBoxContainer"
                        )}
                      >
                        <Checkbox
                          classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checkboxChecked,
                          }}
                          onChange={() => this.toggleUserSelection(athlete.id)}
                          checked={selectedUserIds.has(athlete.id)}
                        />
                      </td>
                      {this.fields.map((field) => {
                        return (
                          <td className={classes.td}>
                            {field.value == "avatar" ? (
                              <span className={classes.athleteImgBox}>
                                {athlete.avatarUrl ? (
                                  <img src={athlete.avatarUrl} />
                                ) : (
                                  <AccountCircle />
                                )}
                              </span>
                            ) : (
                              <span>{athlete[field.value]}</span>
                            )}
                          </td>
                        );
                      })}
                      <td className={classnames(classes.td, "text-right")}>
                        {tenant === "ownit" && (
                          <IconButton
                            title="Download Athlete Report"
                            onClick={() => this.downloadAthleteReport(athlete)}
                            variant="contained"
                            className={"roundCommandButton"}
                          >
                            <FileDownloadIcon className={"styledIcon"} />
                          </IconButton>
                        )}
                        {isGoalFeatureAvailable && (
                          <IconButton
                            title="View Goal"
                            onClick={() => this.navigateToGoal(athlete)}
                            variant="contained"
                            className={"roundCommandButton"}
                          >
                            <GoalIcon className={"styledIcon"} />
                          </IconButton>
                        )}
                        {!appContext.isStaff ||
                        (appContext.isStaff &&
                          contextService.isStaffPermitted(
                            currentTeam,
                            "canInviteUsers"
                          )) ? (
                          <IconButton
                            title="Delete Athlete"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you wish to delete this athlete?"
                                )
                              )
                                this.handleDelete([athlete.id]);
                            }}
                            aria-label="delete"
                            variant="contained"
                            className={"roundCommandButton"}
                          >
                            <DeleteIcon className={"styledIcon"} />
                          </IconButton>
                        ) : null}

                        <IconButton
                          title="View Athlete Details"
                          onClick={() => this.handleView(athlete)}
                          aria-label="delete"
                          variant="contained"
                          className={"roundCommandButton"}
                        >
                          <EditIcon className={"styledIcon"} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </React.Fragment>
            </TabularDesign>
            <div className={"rosterOuterContainer"}>
              <div>
                <AddAthleteModal
                  open={open}
                  value={value}
                  csvType={csvType}
                  message={message}
                  selectCsv={this.selectCsv}
                  send={this.handleInviteSend}
                  push={this.handleNewContact}
                  close={this.toggleInviteModal}
                  cancel={this.cancelInviteModal}
                  handleChange={this.handleChange}
                  errorMessage={this.state.errorMessage}
                  invalidPhoneNumber={invalidPhoneNumber}
                  edit={(contact) => this.editContact(contact)}
                  handleTabValueChange={this.handleTabValueChange}
                  handleRosterTypeChange={this.handleRosterTypeChange}
                  remove={(contact) => this.removeContactFromList(contact)}
                />
                <AddWCSAthleteModal
                  open={this.state.openWCS}
                  value={value}
                  wcsAthletes={this.state.wcsAthletes}
                  classes={classes}
                  message={message}
                  contacts={contacts}
                  currentTeam={currentTeam}
                  selectCsv={this.selectCsv}
                  send={this.handleSubmitWCSAthletes}
                  push={(athlete) => this.handleAddWCSAthlete(athlete)}
                  close={this.toggleInviteModal}
                  cancel={() => this.setState({ openWCS: !this.state.openWCS })}
                  handleChange={this.handleChange}
                  errorMessage={this.state.errorMessage}
                  invalidPhoneNumber={invalidPhoneNumber}
                  edit={(contact) => this.editContact(contact)}
                  handleTabValueChange={this.handleTabValueChange}
                  handleRosterTypeChange={this.handleRosterTypeChange}
                  remove={(contact) => this.removeContactFromList(contact)}
                />
                <TrueLacrosseAddAthleteModal
                  open={this.state.openTrueLacrosse}
                  value={value}
                  trueLacrosseAthletes={this.state.trueLacrosseAthletes}
                  email={email}
                  classes={classes}
                  tenant={tenant}
                  csvType={csvType}
                  message={message}
                  nameLast={nameLast}
                  contacts={contacts}
                  nameFirst={nameFirst}
                  phoneNumber={phoneNumber}
                  currentTeam={currentTeam}
                  selectCsv={this.selectCsv}
                  send={this.handleSubmitTrueLacrosseAthletes}
                  push={(athlete) => this.handleAddTrueLacrosseAthlete(athlete)}
                  close={this.toggleInviteModal}
                  cancel={() =>
                    this.setState({
                      openTrueLacrosse: !this.state.openTrueLacrosse,
                    })
                  }
                  handleChange={this.handleChange}
                  errorMessage={this.state.errorMessage}
                  invalidPhoneNumber={invalidPhoneNumber}
                  edit={(contact) => this.editContact(contact)}
                  handleTabValueChange={this.handleTabValueChange}
                  handleRosterTypeChange={this.handleRosterTypeChange}
                  remove={(contact) => this.removeContactFromList(contact)}
                />
              </div>
              {showAthleteModal && (
                <TrueLacrosseUserModal
                  close={() =>
                    this.setState({ showAthleteModal: false, viewAthlete: {} })
                  }
                  open={showAthleteModal}
                  user={viewAthlete}
                  type={null}
                  deleteUser={() => this.handleDelete([viewAthlete.id])}
                  keepUser={() => console.log("keepUser")}
                />
              )}

              {selectModalOpen && (
                <SelectTeamsModal
                  btnTxt={
                    actionItems[selectedActionItem].value ==
                    ACTION_ITEMS.TRANSFER_TO_TEAM
                      ? "Transfer Now"
                      : null
                  }
                  title={teamSelectTitle}
                  open={true}
                  save={this.handleTeamSelection}
                  close={this.toggleSelectTeamModal}
                  allowOnlyOneTeam={true}
                  subHeading={teamSelectSubHeading}
                />
              )}

              <Dialog
                open={wcsSendInviteOpen || trueLacrosseSendInviteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() =>
                  this.setState({
                    wcsSendInviteOpen: false,
                    trueLacrosseSendInviteOpen: false,
                    sendingInvites: false,
                    sentInfo: false,
                  })
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  {!this.state.sendingInvites && !this.state.sentInfo
                    ? `Send Athletes ${
                        isTrueLacrosse ? "& Parents" : ""
                      } Their Login Info?`
                    : "Login Info"}
                </DialogTitle>
                <DialogContent>
                  {!this.state.sendingInvites && !this.state.sentInfo ? (
                    <DialogContentText id="alert-dialog-slide-description">
                      {wcsSendInviteOpen
                        ? 'Some of your athletes may have been onboarded using OneRoster and may not have their login info yet. Would you like to send these users their login info?  Pressing "SEND INFO" will send a text/email to the athletes with their username and temporary password. This will not send info to users who have already logged in.'
                        : 'Your athletes & parents have been onboarded using the csv uploading process. Simply click "Send Info" & they will receive a text/email with their account credentials to sign into the True Lacrosse app. If you do not want to send them their information yet, click close.'}
                    </DialogContentText>
                  ) : null}
                  {this.state.sendingInvites && !this.state.sentInfo ? (
                    <DialogContentText id="alert-dialog-slide-description">
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: 15,
                          marginBottom: 15,
                        }}
                      >
                        <CircularProgress
                          variant="indeterminate"
                          size={100}
                          color={"primary"}
                        />
                        <h3> Sending Login Info to Athletes...</h3>
                      </div>
                    </DialogContentText>
                  ) : null}
                  {this.state.sendingInvites && this.state.sentInfo ? (
                    <DialogContentText id="alert-dialog-slide-description">
                      <p>
                        <b>
                          Login info completed sending for athletes
                          {isTrueLacrosse ? " & parents" : ""}
                        </b>
                      </p>
                      <p>
                        Successfully Sent{isTrueLacrosse ? " Athletes" : ""}:{" "}
                        {this.state.sentInfo.successCount || 0}
                      </p>
                      {isTrueLacrosse && (
                        <p>
                          Successfully Sent Parents:{" "}
                          {this.state.sentInfo.parentSuccessCount || 0}
                        </p>
                      )}
                      <p>
                        {" "}
                        Existing Logins (These users already have logins):{" "}
                        {this.state.sentInfo.existsCount || 0}
                      </p>
                      {this.state.sentInfo.failureCount ? (
                        <p>
                          Failed Sending: {this.state.sentInfo.failureCount}
                        </p>
                      ) : null}
                      {this.state.sentInfo.failureCount ? (
                        <p>
                          Note: The most common reason for sending failure is
                          invalid email. Check these users:
                        </p>
                      ) : null}
                      {this.state.sentInfo.failures ? (
                        <ul>
                          {this.state.sentInfo.failures.map((contact) => (
                            <li>
                              {contact.nameFirst} {contact.nameLast}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </DialogContentText>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      this.setState({
                        wcsSendInviteOpen: false,
                        trueLacrosseSendInviteOpen: false,
                        sendingInvites: false,
                        sentInfo: false,
                      })
                    }
                  >
                    CLOSE
                  </Button>
                  {!this.state.sendingInvites && !this.state.sentInfo ? (
                    <Button
                      onClick={() => this.handleSendLoginInfo()}
                      color="primary"
                    >
                      SEND INFO
                    </Button>
                  ) : null}
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </BodyWrapper>
      </PageWrapper>
    );
  }
}

AthleteRoster.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    session: { userContext, appContext },
    tenant,
  } = state;
  const currentTeam = _.find(
    userContext.appContextList,
    (c) => c.id === appContext.id
  );
  return {
    appContext: appContext,
    currentTeam: currentTeam || {},
    userContext: userContext,
    currentUser: userContext.user,
    tenantFeatures: tenant.features,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(AthleteRoster));
