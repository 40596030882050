import React, { Component } from 'react'

import './styles.css'

import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

import FilterList from '@material-ui/icons/FilterList'
import ChipData from '../../../../components/chipData'
import { Modal, ClickAwayListener } from '@material-ui/core'
import StyledButton from '../../../../components/styledButton'
import SearchOverlay from '../../../../components/searchOverlay'
import BorderedInput from '../../../../components/borderedInput'
import OptionsSelector from '../../../../components/optionsSelector'

import { 
    resolveContext,
    resolveRecipientList,
    resolveFilteredRecipientList,
    resolveSelectedRecipientsList
} from '../../../../utils/messageUtils.js'


export default class AddGroupModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedRecipients: [],
            currentGroupMembers: [],
            loadingRecipients: false,
            recipientSearchString: '',
            potentialRecipientsList: [],
            isSearchingRecipients: false,
            currentRecipientCategory: 'all'
        }
    }

    handleCloseSearch = () => {
        this.setState({ isSearchingRecipients: false })
    }

    render(){
        const { loadingRecipients } = this.state
        const { 
            open, 
            isSaving,
            groupName,
            currentTeam,
            handleGroupSave,
            handleNameChange,
            toggleGroupModal,
            currentGroupMembers, 
            closeRecipientFilter,
            handleRecipientClick, 
            handleCategoryChange, 
            recipientSearchString, 
            toggleRecipientFilter, 
            isSearchingRecipients, 
            handleRecipientSearch,
            potentialRecipientsList, 
            currentRecipientCategory, 
        } = this.props

        return (
            <Modal open={open}>
                <div className={'addGroupModalContainer'}>
                    <div className={'inputContainer'}>
                        <p className={'textInputLabel'}>Group Name:</p>
                        <BorderedInput 
                            value={groupName} 
                            onChange={handleNameChange}
                        />
                    </div>
                    <ClickAwayListener onClickAway={closeRecipientFilter}>
                        <div className={'inputContainer'}>
                            <p className={'textInputLabel'}>Add Members:</p>
                                <BorderedInput
                                    loading={loadingRecipients}
                                    value={recipientSearchString}
                                    searching={isSearchingRecipients}
                                    onChange={handleRecipientSearch}
                                    onAdornmentClick={toggleRecipientFilter}
                                    adornment={<FilterList className={'icon'} />}
                                    placeholder={'Start typing to search by name'}
                                />
                                {isSearchingRecipients && 
                                    <SearchOverlay 
                                        currentTeam={currentTeam}
                                        close={toggleRecipientFilter}
                                        searchValue={recipientSearchString}
                                        recipients={potentialRecipientsList}
                                        selectedRecipients={currentGroupMembers}
                                        activeCategory={currentRecipientCategory}
                                        onClickRecipient={r => handleRecipientClick(r)}
                                        onClick={category => handleCategoryChange(category)}
                                    />
                                }
                        </div>
                    </ClickAwayListener>
                    <div className={'groupMembersContainer'}>
                        {currentGroupMembers.map((member, i) => {
                            return <ChipData key={i} recipient={member} handleDelete={() => handleRecipientClick(member)} />
                        })}
                    </div>                       
                    <div className={'addGroupModalFooter'}>
                        <StyledButton
                            width={100}
                            title={'Cancel'}
                            icon={<CancelIcon />} 
                            backgroundColor={'#F93F3F'} 
                            onClick={() => toggleGroupModal()} 
                        />
                        <StyledButton
                            width={100}
                            disabled={!groupName}
                            backgroundColor={'#27AE60'} 
                            onClick={handleGroupSave}   
                            icon={isSaving ? null : <SaveIcon />} 
                            title={isSaving ? 'Saving...' : 'Save'}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}