import React from 'react'
import './styles.css'
import { Modal } from '@material-ui/core'
import IconButton from '../../iconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { CircularProgress } from '@material-ui/core'

export default function DeleteConfirmationModal(props){
    const { 
        open, 
        toggle, 
        isLoading, 
        date = null, 
        confirmDelete, 
        name = 'this item', 
        confirmationText = 'Remove Workout Program' 
    } = props

    return(
        <Modal open={open} onClose={toggle}>
            <div className={'deleteConfirmationModalContainer'}>
                <div className={'content'}>
                    <p className={'modalTitle'}>Are You Sure?</p>
                    <p>
                        Please confirm that you wish to remove <span style={{ fontWeight: 'bold' }}>"{name}"</span>{date && <span> from <span style={{ fontWeight: 'bold' }}>{date}</span></span>}
                    </p>
                </div>
                <div className={'footer'}>
                    <IconButton
                        color={'white'}
                        text={'Cancel'}
                        onClick={toggle}
                        position={'left'}
                        textColor={'black'}
                        icon={<CancelIcon style={{ color: 'gray', width: 25, paddingLeft: 5 }} />}
                    />
                    <IconButton
                        color={'red'}
                        position={'left'}
                        onClick={confirmDelete}
                        text={isLoading ? 'Deleting...' : confirmationText}
                        icon={
                            isLoading ? <CircularProgress style={{ width: 20, height: 20, color: 'white' }} /> : 
                            <CancelIcon style={{ color: 'white', width: 25, paddingLeft: 5 }} />
                        }
                    />
                </div>
            </div>
        </Modal>
    )
}
