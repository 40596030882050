import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PageWrapper from '../nav/pageWrapper';
import BodyWrapper from "../../components/bodyWrapper";
import { getLocalizedString } from '../../utils/locale-utils';
import ThemeButton from '../../components/themeButton';
import { PlayCircleIcon, LargeGroupsIcon, MeetingIcon, OneOnOneIcon, SmallGroupsIcon } from '../../components/icons';
import { InputField } from 'maxone-react-ui';
import { disconnectService } from '../../actions/externalService';
import { connect } from 'react-redux';
import zoomLogo from "../../assets/images/zoom-blue.png"
import uplift from "../../assets/images/uplift.png"
import { getThemeByTenant } from '../../themes'
import ModalContainer from '../../components/modalContainer';
import { OAuthButton } from 'maxone-react-ui';
import { PlusIcon } from '../../components/icons';
import { SERVICE_OAUTH_ERROR, SERVICES as SERVICE_NAME } from "../../constants";
import { upliftClient, organizationClient } from '../../clients';
import { refreshAppContext } from '../../actions/session'
import Cookies from 'universal-cookie';
import VideoModal from '../../components/videoModal';
const styles = {
    head: {
        fontSize: "26px",
        lineHeight: "120%",
        marginBottom: "30px"
    },
    logoImage: {
        maxWidth: "94px",
        maxHeight: "40px"
    },
    libraryBox: {
        cursor: "pointer",
        flexDirection: "column",
        padding: "15px 20px 30px",
        display: 'flex',
        width: "521px",
        height: "521px",
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        borderRadius: "8px",        
        filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06))",
        "&:first-child":{
            marginRight: "30px"
        }
    },
    videoThumbnail: {
        height: "270px",
        borderRadius: "8px",
        position: "relative",
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        "& img": {
            height: "100%",
            width: "100%",
            borderRadius: "8px"
        },
        "& svg": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "54px",
            width: "54px",
            color: "#FFFFFF"
        }
    },
    caseStudyBox: {
        width:"100%",
        maxWidth: "230px",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        padding: "9px 11px",
        display: "flex",
        flexDirection: "row",
        "&:first-child":{
            marginRight:"5px"
        }
    },
    subContent1: {
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "110%",
        color: "#0B0B0B",
        marginBottom: '9px'
    },
    subContent2: {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "150%",
        color: "#75797B",
        marginBottom: '9px'
    },
    boxHeaderSubContent: {
        "& img": {
            marginRight: '15px'
        }
    },
    boxHeader: {
        marginBottom: "10px"
    },
    flexCenterElemnt: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    boxFooter: {
        marginTop: "30px"
    },
    caseStudyBoxImage: {
        "& svg": {
            maxWidth: "99px",
            height: "85px",
            width: "100%"
        }
    },
    connectSumText:{
        width:'calc(100% - 273px)'
    },
    serviceLogoImage:{
        marginLeft: "15px",
        marginRight: "0px"
    },
    rightBtn:{
        marginLeft: "auto"
    },
    title:{
        marginTop:"10px",
        marginBottom:"20px"
    }
};

const SERVICES = [
    {
        name: SERVICE_NAME.UPLIFT,
        dashImg: "https://cdn.gomaxone.com/diamond/upliftimgv2.png",
        icon: uplift,
        subBtn:[
            {
                name:"1 on 1 Training",
                message:"Single Athlete",
                icon: <OneOnOneIcon />
            },
            {
                name:"Small Groups",
                message:"2 to 12 Athletes",
                icon: <SmallGroupsIcon />
            }
        ]
    },
    {
        name: SERVICE_NAME.ZOOM,
        dashImg: "https://cdn.gomaxone.com/diamond/zoomImgv2.png",
        icon: zoomLogo,
        subBtn:[
            {
                name:"Large Groups",
                message:"13+ Athletes",
                icon: <LargeGroupsIcon />
            },
            {
                name:"Team Meetings",
                message:"13+ Athletes",
                icon: <MeetingIcon />
            }
        ]
    },
];

const INTEREST_EXPIRE_COOKIE = 'iec-da'
const cookies = new Cookies();
class GettingStarted extends Component {
    constructor(props) {
        super(props);
        this.upliftVideoUrl = "http://cdn.gomaxone.com/diamond/maxoneUplift.mp4";
        this.state = {
            openLearnMoreModal: false,
            openConnectModal: false,
            showConflictModal: false,
            isUpliftEnabledByOrg: false,
            isLoading: true,
            inviteOrgModalEnabled:false,
            isChildLoading:false
        }
    }

    componentDidMount() {
        let { currentUser, currentTeam } = this.props;
        //Check if it was redirected bcz of conflict
        let errorInfo = sessionStorage.getItem(SERVICE_OAUTH_ERROR);
        if (!!errorInfo) {
            sessionStorage.removeItem(SERVICE_OAUTH_ERROR)
            this.setState({ showConflictModal: true })
        }
        //Used for uplift inital email
        organizationClient.getOrganizationById(currentTeam.organizationId).then(({ data: { services } }) => {
                if((services||[]).includes(SERVICE_NAME.UPLIFT)){
                    this.setState({isUpliftEnabledByOrg: true});
                }
            }
        )
        .catch(e => console.log(e)).finally(()=>{
            this.setState({isLoading: false})
        });
        const isUpliftInterestBtnDisabled = cookies.get(INTEREST_EXPIRE_COOKIE);
        this.setState({ userEmail: currentUser.email, isUpliftInterestBtnDisabled: false })
    }

    toggleConflictModal = () => {
        const { showConflictModal } = this.state;
        this.setState({ showConflictModal: !showConflictModal })
    }

    toggleLearnModalContainer = () => {
        const { openLearnMoreModal } = this.state;
        this.setState({ openLearnMoreModal: !openLearnMoreModal })
    }

    toggleConnectModal = (serviceName) => {
        const { openConnectModal } = this.state;
        this.setState({ openConnectModal: !openConnectModal, selectedServiceName: !openConnectModal? serviceName: "" })
    }

    connectUplift = async () => {
        let { userEmail } = this.state;
        let { currentUser } = this.props;        
        //Try linking account first, if fails redirect to preregisteration url
        try{
           this.toggleConnectModal()
           this.setState({isChildLoading: true});
           let { data } = await upliftClient.linkAccount(currentUser.id, userEmail);          
           //Refresh appContext
           this.props.refreshAppContext();
        }
        catch(e){
            if(!!e && !!e.response && e.response.status == 404){
                this.toggleConnectModal(SERVICE_NAME.UPLIFT)
                try{
                    //Send user to preregistration page
                    let { data: { url }} = await upliftClient.preRegisterUserByEmail(userEmail);
                    window.open(url, '_blank');
                }
                catch(e){
                    this.toggleConnectModal()
                }
            }
        }
        this.setState({isChildLoading: false});
    }

    disconnectService = (serviceName) => {
        let { currentUser, disconnectService} = this.props;
        disconnectService(serviceName, currentUser.id)   
    }

    handleServiceBtn = async (serviceName) =>{
        let { isZoomConnected, currentUser, isUpliftConnected, currentTeam } = this.props;
        let { isUpliftEnabledByOrg } = this.state;
        if(serviceName == SERVICE_NAME.UPLIFT){
            //If false send to preregister link
            if(!isUpliftEnabledByOrg){
                try{
                    this.setState({isChildLoading: true});
                    const { data: { director } } = await upliftClient.notifyInterestByOrganizationId(currentTeam.organizationId, currentUser.id);
                    this.setState({ directorNotifiedUplift: director });
                    //set date and time in local storage to have 2 week check
                    var expire = new Date();
                    expire.setTime(expire.getTime() + 3600000*24*14);
                    cookies.set(INTEREST_EXPIRE_COOKIE, INTEREST_EXPIRE_COOKIE, { path: '/', expires: expire});
                    this.setState({isUpliftInterestBtnDisabled: true})
                    this.toggleOrgInvModal();
                }
                catch(e){
                    console.log(e)
                }
                this.setState({isChildLoading: false});
            }
            else {
                if(isUpliftConnected){
                    this.disconnectService(serviceName);
                }
                else{
                    this.toggleConnectModal(serviceName)
                }
            }
        }
        else {
            if(!isZoomConnected){
                sessionStorage.setItem("redirectUrl", window.location.pathname);
                this.toggleConnectModal(serviceName)
            }
            else {
                //Disconnect
                this.disconnectService(serviceName);
            }
        }
    }

    handleChange = (key, value) => {
        this.setState({[key]: value})
    }

    toggleOrgInvModal = () =>{
        let { inviteOrgModalEnabled } = this.state
        this.setState({inviteOrgModalEnabled: !inviteOrgModalEnabled})
    }

    openScheduleLink = () => {
        const win = window.open("https://meetings.hubspot.com/luke359/uplift-demo", '_blank');
        if (win != null) {
          win.focus();
        }
    }

    render() {
        const { classes, loadingMessage, tenant, isZoomConnected, isUpliftConnected } = this.props;
        const { openLearnMoreModal, openConnectModal, showConflictModal, selectedServiceName, userEmail, isUpliftEnabledByOrg, isLoading, inviteOrgModalEnabled, isChildLoading, directorNotifiedUplift, isUpliftInterestBtnDisabled } = this.state
        const tenantLogo = getThemeByTenant(tenant).image.logo;
        const serviceStatus = {[SERVICE_NAME.ZOOM]:isZoomConnected, [SERVICE_NAME.UPLIFT]:isUpliftConnected}
        return (
            <PageWrapper title={getLocalizedString("GETTING_STARTED")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <BodyWrapper enableSubContainerStyle={true} showLoadingChild={true} isLoading={isChildLoading}>
                    <div className={classes.head}>
                        Live Training Options
                    </div>
                    <div className={classnames(classes.contentLibraryBoxes, "d-flex justify-content-start")}>
                    {SERVICES.map(service =>  <div className={classes.libraryBox}>
                            <div className={classnames(classes.boxHeader, classes.flexCenterElemnt)}>
                                <div className={classnames(classes.boxHeaderSubContent, classes.flexCenterElemnt, "w-100")}>
                                    <div className="d-flex align-items-center">
                                        <img className={classes.logoImage} src={tenantLogo} />
                                        <PlusIcon />
                                        <img className={classnames(classes.logoImage, classes.serviceLogoImage)} src={service.icon} />
                                    </div>
                                    <ThemeButton
                                        onClick={()=> this.handleServiceBtn(service.name)}
                                        width={"110px"}
                                        textColor={"#ffffff"}
                                        color={serviceStatus[service.name] ? "#F93F3F":"#27AE60"}
                                        height={"30px"}
                                        theme="small"
                                        text={(service.name == SERVICE_NAME.UPLIFT && !isUpliftEnabledByOrg) ? "Learn More": serviceStatus[service.name]? "Disconnect":"Let's Go"}
                                        disabled={isUpliftInterestBtnDisabled && service.name == SERVICE_NAME.UPLIFT && !isUpliftEnabledByOrg}
                                    />
                                </div>
                            </div>
                            <div className={classes.boxVideo}>
                                <div className={classes.videoThumbnail} style={{ backgroundImage: `url(${service.dashImg})` }}>

                                </div>
                            </div>
                            <div className={classnames(classes.boxFooter, classes.flexCenterElemnt)}>
                              {service.subBtn.map(subBtn =>  <div className={classes.caseStudyBox}>
                                    <div className={classes.caseStudyBoxContent}>
                                        <div className={classes.subContent1}>
                                            {subBtn.name}
                                            </div>
                                        <div className={classes.subContent2}>
                                            {subBtn.message}
                                        </div>
                                    </div>
                                    <div className={classes.caseStudyBoxImage}>
                                        {subBtn.icon}
                                    </div>
                                </div>)}
                            </div>
                        </div>)}
                    </div>
                </BodyWrapper>
                  {/* Conflict Modal */}
                <ModalContainer
                    width={"480px"}
                    open={showConflictModal}
                    onClose={this.toggleConflictModal}
                    heading={"We ran into an issue"}
                    rightBtn={
                        <ThemeButton
                            overrideContainerClassName={classes.rightBtn}
                            position="left"
                            width={"92px"}
                            textColor={"#0B0B0B"}
                            color={"#ffffff"}
                            height={"44px"}
                            theme="small"
                            iconName="Cancel"
                            onClick={this.toggleConflictModal}
                            text={"Close"}
                        />}                       
                > 
                <div className={classes.conflictInfo}>
                    It looks like your Zoom account is already in use by another coach. In order to use this Zoom account, it must be disconnected from the other coach account.
                </div>
                    </ModalContainer>                
                {/* Connect to Zoom Modal */}
                <ModalContainer
                    width={"800px"}
                    open={openConnectModal}
                    onClose={this.toggleConnectModal}
                    heading={selectedServiceName == SERVICE_NAME.UPLIFT? 'Connect to Uplift': 'Connect to Zoom'}
                    useDefaultLeftBtn={true}
                    defaultLeftBtnProps={{
                        iconName: null,
                        onClick:this.toggleConnectModal
                    }}
                >
                    <div className={"d-flex justify-content-between"}>
                        <div className={classes.connectSumText}>
                            {selectedServiceName == SERVICE_NAME.UPLIFT ?
                                `Connect with Uplift`:
                                `Click the button to the right to connect your Zoom account. Once connected, you will be able to add Zoom meetings directly to calendar events and workouts.`
                            }
                            {selectedServiceName == SERVICE_NAME.UPLIFT &&
                                <InputField value={userEmail} label={"Enter uplift email"} onChange={value => this.handleChange('userEmail', value)} />                                      
                            }
                        </div>                      
                        {selectedServiceName == SERVICE_NAME.UPLIFT ?
                            <ThemeButton
                                width={"196px"}
                                textColor={"#ffffff"}
                                color={"#296ED2"}
                                height={"86px"}
                                theme="small"
                                onClick={this.connectUplift}
                                text={"Connect"}
                            />
                            :
                            <OAuthButton
                                text={'Connect'}
                                service={'zoom'}
                                onAuthenticationError={data => console.log(data)}
                                onAuthenticationSuccess={token => console.log(token)}
                                backgroundColor={"#296ED2"}
                                width={"196px"}
                                height={"86px"}
                            />
                        }
                    </div>
                </ModalContainer>
                {/* */}
                {/*Learn More Modal*/}
                <VideoModal
                  videoUrl={this.upliftVideoUrl}
                  isVideoBoxOpen={inviteOrgModalEnabled}
                  toggleVideoModel={this.toggleOrgInvModal}
                >
                    <div className="summary">
                     Uplift provides in-person training & coaching from anywhere for one-on-one and small group training. Once Uplift is integrated into your MaxOne account, you’ll have access to the following live coaching tools:
                     <div className={classes.title}>
                        <div>1. Smart annotations with AI technology</div>
                        <div>2. Athlete spotlight for teaching and instructing</div>
                        <div>3. Live Replay for real time playback</div>
                      </div>
                    <div className={classnames(classes.btns,"d-flex justify-content-between")}>
                        <ThemeButton
                            width={"140px"}
                            color={"#1354F9"}
                            height={"44px"}
                            theme="small"
                            text="Schedule Demo"
                            onClick={this.openScheduleLink}
                            iconName={"Calendar"}
                        />
                    </div>
                    </div>
                </VideoModal>
                {/* */}
            </PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        isZoomConnected: appContext.isZoomConnected,
        currentUser: userContext.user,
        isUpliftConnected: appContext.isUpliftConnected
    };
}

export default withStyles(styles)(connect(mapStateToProps, { disconnectService, refreshAppContext })(GettingStarted));
