import { API } from 'aws-amplify';

export async function getTeamsByOrganization(organizationId) {
    return API.get('programs', `/programs/organization/${organizationId}`);
}

export async function getGroupsForOrganization(organizationId){
    return API.get('groups', `/organizations/${organizationId}/groups`);
}

export async function getFullOrganizationRoster(organizationId){
    return API.get('organizations', `/organizations/${organizationId}/fullRoster`)
}

export async function getTenantOrganizations(tenantId){
    return API.get('organizations', `/organizations/tenant/${tenantId}`)
}

export async function saveOrganization(organization){
    return API.post('organizations', `/organizations`, {body: organization})
}
