import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CircularProgress } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { setUserContext, setAppContext } from '../../actions/session';
import { withStyles } from '@material-ui/core/styles';
import Amplify from "aws-amplify";
import { withOAuth } from "aws-amplify-react";
import { OAuthButton, initServices } from 'maxone-react-ui';
import { testId } from '../../utils/test-utils';

import coachupConfig from "../../config-coachup";
import { parse } from 'qs';
import { tenantMap } from '../../utils/commonUtil';
const logo = require("../../../src/assets/images/M1SELogo.png")
import "./style.css";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding:"30px"
    },
    menu: {
        width: 500,
        padding: 50
    },
    modal: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: 300,
        marginTop: 30
    },
    root: {
        flexGrow: 1,
    },
    header: {
        fontWeight: 100,
        fontSize: 45,
        marginBottom: '-15px'
    },
    subHeader: {
        fontWeight: 100,
    },
    image: {
        maxWidth:200,
        maxHeight:200
    }
});

class SEAdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingMessage: "",    
            message: "",
            errorMessage: "",
        };
    }

    componentDidMount() {
        initServices();

        const { location: { search }, history, tenant } = this.props;
        const { auth_code: code } = parse(search, { ignoreQueryPrefix: true });

        console.log('Auth code', code, 'tenant', tenant);

        //TODO: Not sure if this is needed
        if (code && tenant === tenantMap.se) {
            return history.push(`/oauth?code=${code}`);
        }
        if(!!this.inputElement){
            this.inputElement.onClick();       
        }
        this.setState({
            isLoading: false            
        })
    }

    handleError = err => {
        console.log('error logging in -> ', err)
        this.setState({
            errorMessage: 'Oops, something went wrong! Try again?'
        })
    }

    handleSuccess = async data => {
        console.log('SUCCESS => ', data)
    }

    render() {
        const { classes, tenant } = this.props;
        const { isLoading, loadingMessage } = this.state;
        return (
            <div className="seLogin d-md-flex align-items-md-center justify-content-md-center">
                {
                    isLoading
                    &&
                    <div className={"loadingOverlay"}>
                        <CircularProgress  />
                        <h2>{loadingMessage}</h2>
                    </div>
                }
                {tenant === tenantMap.se ?
                    <div
                        style={{
                            width: '0%',
                            height: "0%"
                        }}
                        className={'d-none'}
                        {...testId('signup-button')}
                    >
                        <OAuthButton
                            text={'Sign up'}
                            tenant={tenant}
                            backgroundColor={'#102B52'}
                            onAuthenticationError={data => this.handleError(data)}
                            onAuthenticationSuccess={token => this.handleSuccess(token)}
                            ref={input => { console.log(input); this.inputElement = input }}
                        />
                    </div> : null}
            </div>
        );
    }
}

SEAdminLogin.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withOAuth(SEAdminLogin)));
