import React, { Component } from 'react'

import './styles.css'

// DIFF TABS HERE
import SignupTab from './tabs/signupTab'
import VerifyPhoneTab from './tabs/verifyPhoneTab'

const signupTabs = [
    { id: 1, title: 'Create an Account' },
    { id: 2, title: 'Verify your phone number' }
]

export default class SignupForm extends Component{
    constructor(props){
        super(props)
    }

    resolveTab = tab => {
        const {
            signupData:{
                email,
                password,
                nameLast,
                nameFirst,
                phoneNumber = "",
                isPhoneNumberValid,
                username,
                verificationCode,
                orgSize
            }, 
            signupData,   
            currentTab,                        
            isLoading,
            handleChange,
            passwordError,
            existingUsername,
            handleTabChange,            
            handleConfirmUser,
            handleCreateAccount,
            handleResendVerificationCode,
            isVerificationCodeValid                          
        } = this.props

        let isPasswordValid = (password||"").length >= 8;
        const isContinueDisabled = !orgSize || !username || !email || !currentTab || !password || !nameLast || !nameFirst || !isPhoneNumberValid || !isPasswordValid;
        let isValidEmail = !email || email.toString().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      
        const phoneNumberErrorStateEnabled = phoneNumber && !isPhoneNumberValid;
        switch(tab.id){
            case 1:
                return (
                    <SignupTab
                        key={1}
                        title={tab.title}
                        isLoading={isLoading}
                        open={currentTab === tab.id}
                        signupData={signupData}
                        isValidEmail={isValidEmail}
                        isPasswordValid={isPasswordValid}
                        existingUsername={existingUsername}
                        passwordError={passwordError}
                        isContinueDisabled={isContinueDisabled}
                        handleCreateAccount={handleCreateAccount}
                        onClick={() => handleTabChange(tab.id)}
                        handleChange={(property, value) => handleChange(property, value)}
                        phoneNumberErrorStateEnabled={phoneNumberErrorStateEnabled}
                        orgSize={orgSize}
                    />
                )
            case 2:
                return (
                    <VerifyPhoneTab
                        key={2}
                        email={email}
                        title={tab.title}
                        isLoading={isLoading}
                        open={currentTab === tab.id}
                        phoneNumber={phoneNumber}
                        handleChange={handleChange}
                        handleResendVerificationCode={handleResendVerificationCode}
                        onClick={() => handleConfirmUser()}
                        verificationCode={verificationCode}
                        isVerificationCodeValid={isVerificationCodeValid}
                    />
                )           
            default:
                return null
        }
    }

    render(){
        return(
            <div className={'signupFormOuterContainer'}>
                {signupTabs.map(tab => {
                    return this.resolveTab(tab)
                })}
            </div>
        )
    }
}
