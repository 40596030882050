import React from "react";
import ModalContainer from "../../../components/modalContainer";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PrimaryButton, BorderedButton } from "../../../components/v2/buttons";
import { colors } from "../../../components/v2/utils/theme-utils";

const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "center",
    "&>svg": {
      fontSize: "42px",
    },
  },
  okButtonWrapper: {
    "& > div:first-child": {
      width: "100%",
    },
  },
  okButton: {
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    border: `2px solid ${colors.PRIMARY} !important`,
    color: `${colors.PRIMARY} !important`,
    "&:hover": {
      backgroundColor: "transparent",
      border: `2px solid ${colors.PRIMARY} !important`,
    },
    "&:focus": {
      backgroundColor: "transparent",
      border: `2px solid ${colors.PRIMARY} !important`,
    },
  },
  heading: {
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
  },
});

const ConfirmationPopup = ({
  currentAction,
  handleClose,
  classes,
  eventDescription,
  icon: Icon,
  handleConfirmation,
}) => {
  return (
    <ModalContainer
      open={true}
      showFooter={false}
      showCloseIcon={false}
      onClose={handleClose}
      width={currentAction === "deleteTag" ? "400px" : "300px"}
      customPadding={25}
      backgroundColor={"#fff"}
      heading={""}
      customButton={""}
    >
      <Grid container spacing={currentAction === "deleteTag" ? 22 : 24}>
        <Grid item xs={12}>
          {currentAction === "deleteTag" ? (
            <div className={`${classes.heading} d-flex justify-content-center`}>
              Delete Tag
            </div>
          ) : null}

          {Icon ? (
            <div className={`${classes.header} d-flex justify-content-center`}>
              <Icon />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} className={"d-flex justify-content-center"}>
          <p
            style={{
              textAlign: "center",
              marginBottom: currentAction === "deleteTag" ? "20px" : "0px",
            }}
          >
            {eventDescription}
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.okButtonWrapper} d-flex justify-content-center`}
        >
          <PrimaryButton
            onClick={handleConfirmation}
            style={{ height: 52 }}
            className={classes.okButton}
          >
            OK
          </PrimaryButton>

          {currentAction === "deleteTag" ? (
            <div style={{ marginLeft: 20 }}>
              <BorderedButton onClick={handleClose} style={{ height: 52 }}>
                Cancel
              </BorderedButton>
            </div>
          ) : null}
        </Grid>
        {["edit", "delete", "cancel"].indexOf(currentAction) > -1 ? (
          <Grid
            item
            xs={12}
            className={`${classes.okButtonWrapper} d-flex justify-content-center`}
          >
            <a
              style={{
                color: colors.PRIMARY,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              Don't notify anyone
            </a>
          </Grid>
        ) : null}
      </Grid>
    </ModalContainer>
  );
};

export default withStyles(styles)(ConfirmationPopup);
