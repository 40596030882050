import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PageWrapper from '../nav/pageWrapper';
import BodyWrapper from "../../components/bodyWrapper";
import { getLocalizedString } from '../../utils/locale-utils';
import ThemeButton from '../../components/themeButton';
import { connect } from 'react-redux';
import DropDownWithSearch from "../../components/dropDownWithSearch"
import { organizationClient, dynamicLibraryClient, activityClient } from "../../clients"
import DeleteIcon from '@material-ui/icons/Delete';
import Cell from '../../components/editableTableCell'
import { formatValueAsSlug } from '../../utils/format-utils'
import { refreshAppContext } from '../../actions/session'
import {
    Checkbox
} from '@material-ui/core';

const LIBRARY_TYPE = Object.freeze({
    "ORGANIZATION": "ORGANIZATION",
    "TENANT": "TENANT"
})

const styles = {
    subHead: {
        fontWeight: "600",
        fontSize: "26px",
        lineHeight: "120%",
        marginBottom: "5px",
        color: "#75797B"
    },
    description: {
        color: "#0B0B0B",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom: "10px"
    },
    box: {
        flexDirection: "column",
        padding: "15px 20px 30px",
        display: 'flex',
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06))",
        "&:first-child": {
            marginBottom: "30px"
        }
    },
    rightBtn: {
        marginLeft: "auto"
    },
    libraryInfo: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        "& tr": {
            background: "#ffffff",
            height: "52px",
            "& th": {
                padding: "11px 0px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "14px",
                color: "#A0A7AB"
            }
        },
        "& tr th,tr td": {
            borderBottom: "1px solid #DDE2E5",
            maxWidth: "250px",
            paddingRight: "10px",
            overflow: "hidden",
            wordBreak: "break-all"
        },
        "& tr th:first-child,tr td:first-child": {
            paddingLeft: "16px",
            paddingRight: "16px"
        },
        "& tr th:last-child,tr td:last-child": {
            paddingRight: "16px"
        },
        "& tr:last-child": {
            borderBottom: "0px",
            "& td": {
                borderBottom: "0px"
            }
        },
        "& tr th": {
            textAlign: "left"
        }
    },
    td: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#0B0B0B",
        textAlign: "left"
    },
    tableHead: {
        height: "36px !important"
    },
    libraryTableContainer: {
        border: "1px solid #DDE2E5",
        borderRadius: "5px",
        padding: "1px"
    },
    deleteSection: {
        maxWidth: "50px !important",
        width: "50px",
        "& svg":{
            color:"#DDE2E5"
        }
    },
    btnSection:{
        marginTop:"22px"
    },
    orgSelector:{
        marginTop:"5px",
        marginBottom:"18px"
    },
    serviceIcon:{
        cursor:"pointer"
    },
    libraryName:{
        color: "#0B0B0B",
        paddingLeft:"0px",
        outline:"none"
    }
};

class DyanmicLibrary extends Component {
    constructor(props) {
        super(props);
        this.fields = [
            {
                displayName: "Training Libary Name",
                value: "label",
                isEditable: true
            },
            {
                displayName: "Activities",
                value: "activityCount"
            },
            {
                displayName: "Athlete",
                value: "hideForAthletes",
                isBoolean: true,
                isReverse:true
            },
            {
                displayName: "Hide",
                value: "hideForCoaches",
                isBoolean: true
            }
        ]
        this.state = {
            menuItemData: [],
            totalOrgs: 0,
            currentPage: -1,
            isLoading: true,
            tenantLibraryMap: {},
            orgLibraryMap: {},
            loadingMessage: "Fetching libaries...",
            clonedTenantLibraryMap: {},
            clonedOrgLibraryMap: {},
            newLibraryCounter:{[LIBRARY_TYPE.ORGANIZATION]:0, [LIBRARY_TYPE.TENANT]: 0}
        }
    }

    componentDidMount() {
        Promise.all([this.getDynamicLibrary(),
        this.getOrgs()]).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    getOrgs = async () => {
        const offset = 20;
        let { tenant } = this.props;
        let menuItemData = [], results = [], totalOrgs = 0;
        let { menuItemData: existingMenuItemData, currentPage } = this.state;

        currentPage = currentPage + 1;
        let params = {
            limit: offset,
            offset: currentPage * offset,
            tenant
        }
        try {
            ({ data: { results, total: totalOrgs } } = await organizationClient.searchOrganizations(params));
            menuItemData = results.map(item => { return { value: item.id, displayValue: item.name } });
        }
        catch (ex) {
            console.log(ex)
        }
        this.setState({ totalOrgs, menuItemData: existingMenuItemData.concat(menuItemData), currentPage });
    }

    getDynamicLibrary = async (orgId) => {
        let { tenant } = this.props;
        let { tenantLibraryMap, orgLibraryMap, orgLibraryType, orgLibraryId, tenantLibraryId } = this.state
        this.setState({ isChildLoading: true });
        let libraries=[];
        let distinctCategories = [];
        let categoryCountMap = {}
        try {
            if (orgId) {    
                ([{ data:{ meta :{ distinctCategories }}}, { data: { libraries, id: orgLibraryId, type:orgLibraryType }}] = await Promise.all([
                    activityClient.searchActivities({limit:0,tenantId:tenant, "organizationId.keyword": orgId }),
                    dynamicLibraryClient.getDynamicLibraryByParentId("ORGANIZATION", orgId)
                ]));

                distinctCategories.forEach(item => {
                    categoryCountMap[item.slug] = item.total;
                });

                orgLibraryMap = {};
                if(orgLibraryType == "TENANT"){
                    orgLibraryId = "DEFAULT"
                }
                libraries.forEach((library) => {
                    orgLibraryMap[library.value] = library;
                    orgLibraryMap[library.value].activityCount = categoryCountMap[library.value]||0;
                })
            }
            else {
                ([{ data:{ meta :{ distinctCategories }}}, { data: { libraries, id: tenantLibraryId }}] = await Promise.all([
                    activityClient.searchActivities({limit:0,tenantId:tenant}),
                    dynamicLibraryClient.getDynamicLibraryByParentId("TENANT", tenant)
                ]));
                tenantLibraryMap = {};
                distinctCategories.forEach(item => {
                    categoryCountMap[item.slug] = item.total;
                });

                libraries.forEach((library) => {
                    tenantLibraryMap[library.value] = library;
                    tenantLibraryMap[library.value].activityCount = categoryCountMap[library.value]||0;
                });
            }
        }
        catch (e) {
            console.log(e)
        }        
        this.setState({ isChildLoading: false, tenantLibraryId, orgLibraryType, orgLibraryId,
            tenantLibraryMap, clonedTenantLibraryMap: tenantLibraryMap, orgLibraryMap, clonedOrgLibraryMap: orgLibraryMap });
    }


    addALibrary = (type) => {
        let { clonedTenantLibraryMap, clonedOrgLibraryMap, newLibraryCounter } = this.state;        
        if(type == LIBRARY_TYPE.TENANT){
            clonedTenantLibraryMap = {...clonedTenantLibraryMap, [`customLibrary${newLibraryCounter[type]}`]:{isNew: true}}
        }
        else{
            clonedOrgLibraryMap = {...clonedOrgLibraryMap, [`customLibrary${newLibraryCounter[type]}`]:{isNew: true}}
        }
        this.setState({ clonedOrgLibraryMap, clonedTenantLibraryMap, newLibraryCounter:{...newLibraryCounter, [type]:newLibraryCounter[type] + 1}})
    }

    selectOrg = (value) => {
        this.setState({"selectedOrgId": value, isChildLoading: true}, () => this.getDynamicLibrary(value))
    }

    deleteLibrary = (type, library) => {
        let { clonedTenantLibraryMap, clonedOrgLibraryMap } = this.state
        if(type == LIBRARY_TYPE.TENANT){
            clonedTenantLibraryMap = { ...clonedTenantLibraryMap };
            delete clonedTenantLibraryMap[library];
            this.setState({ clonedTenantLibraryMap });
        }
        else {
            clonedOrgLibraryMap = { ...clonedOrgLibraryMap };
            delete clonedOrgLibraryMap[library];
            this.setState({ clonedOrgLibraryMap })
        }
       
    }

    discardChanges = (type) => {
        let { tenantLibraryMap, orgLibraryMap } = this.state;
        this.setState({ clonedTenantLibraryMap: tenantLibraryMap, clonedOrgLibraryMap: orgLibraryMap });
    }

    handleLibraryChange = (type, library, field, value) => {
        let { clonedTenantLibraryMap, clonedOrgLibraryMap } = this.state;  
        if (type == LIBRARY_TYPE.TENANT) {
            clonedTenantLibraryMap = {...clonedTenantLibraryMap};
            clonedTenantLibraryMap[library] = {...clonedTenantLibraryMap[library], [field]: value}
            this.setState({clonedTenantLibraryMap})
        }
        else {
            clonedOrgLibraryMap = {...clonedOrgLibraryMap};
            clonedOrgLibraryMap[library] = {...clonedOrgLibraryMap[library], [field]: value};
            this.setState({clonedOrgLibraryMap})
        }
    }

    saveData = async (type) => {
        let { clonedTenantLibraryMap, clonedOrgLibraryMap, tenantLibraryId, orgLibraryId, selectedOrgId } = this.state;
        let { refreshAppContext, enableFullScreenLoader } = this.props;
        let { tenant } = this.props;
        let libraryMap = type == LIBRARY_TYPE.TENANT ? clonedTenantLibraryMap : clonedOrgLibraryMap;
        let libraries = [];
        Object.keys(libraryMap).forEach(libraryValue => {
            let value = libraryValue;
            //If it is new library generated by user then generate value using slug
            if (libraryMap[libraryValue].isNew) {
                value = formatValueAsSlug(libraryMap[libraryValue].label);
                delete libraryMap[libraryValue].isNew;
            }
            libraries.push({ ...libraryMap[libraryValue], value })
        })
        try {
            enableFullScreenLoader(true, "Saving Libraries...");
            if (type == LIBRARY_TYPE.TENANT) {                
                if (tenantLibraryId == "DEFAULT") {
                    await dynamicLibraryClient.createDynamicLibrary(tenant, LIBRARY_TYPE.TENANT, libraries);
                }
                else {
                    await dynamicLibraryClient.updateDynamicLibraryById(tenantLibraryId, libraries)
                }
                this.getDynamicLibrary();
            }
            else {                
                if (orgLibraryId == "DEFAULT") {
                    await dynamicLibraryClient.createDynamicLibrary(selectedOrgId, LIBRARY_TYPE.ORGANIZATION, libraries);
                }
                else {
                    await dynamicLibraryClient.updateDynamicLibraryById(orgLibraryId, libraries)
                }
            }
            enableFullScreenLoader(false);
            // refreshAppContext() //I seriously don't know why it is here
        }
        catch (e) {
            console.log(e)
        }
        enableFullScreenLoader(false);
    }

    render() {
        const { classes, tenant, tenantName } = this.props;
        const { selectedOrgId, menuItemData, totalOrgs, isLoading, isChildLoading, clonedTenantLibraryMap, clonedOrgLibraryMap, loadingMessage } = this.state
        const tenantLibraryList = Object.keys(clonedTenantLibraryMap).map((libraryValue) => clonedTenantLibraryMap[libraryValue].label);
        const orgLibraryList = Object.keys(clonedOrgLibraryMap).map((libraryValue) => clonedOrgLibraryMap[libraryValue].label);
        const disableTenantLibrarySave = tenantLibraryList.some(item => !item || (tenantLibraryList.indexOf(item) != tenantLibraryList.lastIndexOf(item)));
        const disableOrgLibrarySave = !selectedOrgId || orgLibraryList.some(item => !item || (orgLibraryList.indexOf(item) != orgLibraryList.lastIndexOf(item)));
        return (
            <PageWrapper title={getLocalizedString("EDIT_TRAINING_LIBRARIES")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <BodyWrapper showLoadingChild={true} isLoading={isChildLoading}>
                    <div className={classnames("d-flex justify-content-start flex-column")}>
                        <div className={classes.box}>
                            <div className={classes.subHead}>
                                {(tenantName||"").captialize()} Default Training Libraries
                            </div>
                            <div className={classes.description}>
                                Below are the training libraries associated with your app. You are able to rename or add more libraries in order to provide a more customized experience to your customers. Keep in mind, once they are changed, this will be reflected on all organizations inside your app (unless you defined specific libraries for an organization in the second section of this page). Additionally, you're able to determine if you'd like all users or just coaches within the organization to have access.
                            </div>
                            <GenerateTable
                                fields={this.fields}
                                type={LIBRARY_TYPE.TENANT}
                                classes={classes}
                                dataMap={clonedTenantLibraryMap}
                                deleteLibrary={this.deleteLibrary}
                                handleLibraryChange={this.handleLibraryChange} />
                            <div className={classnames(classes.btnSection, "d-flex justify-content-end")}>
                                <ThemeButton
                                    position="left"
                                    width={"100px"}
                                    textColor={"#ffffff"}
                                    color={"#1354F9"}
                                    height={"44px"}
                                    theme="small"
                                    title={disableTenantLibrarySave? "Library name can't be same or empty": ""}
                                    disabled={disableTenantLibrarySave}
                                    onClick={()=>this.saveData(LIBRARY_TYPE.TENANT)}
                                    text={"SAVE"}
                                />
                                <ThemeButton
                                    position="left"
                                    width={"260px"}
                                    textColor={"#ffffff"}
                                    color={"#1354F9"}
                                    height={"44px"}
                                    theme="small"                                   
                                    iconName="Plus"
                                    onClick={()=>this.addALibrary(LIBRARY_TYPE.TENANT)}
                                    text={"ADD NEW TRAINING LIBRARY"}
                                />
                            </div>
                        </div>
                        <div className={classes.box}>
                            <div className={classes.subHead}>
                                Organization Specific Training Libraries
                            </div>
                            <div className={classes.orgSelector}>
                                <DropDownWithSearch
                                    enableInfiniteLoading={true}
                                    loadMore={this.getOrgs}
                                    value={selectedOrgId}
                                    hasMore={menuItemData.length < totalOrgs}
                                    placeholder={"Select an organization"}
                                    onChange={(value) => {
                                        this.selectOrg(value)
                                    }}
                                    menuItemData={menuItemData}
                                    dropdownHeight={"201px"}
                                />
                            </div>
                            {!!selectedOrgId && <GenerateTable
                                type={LIBRARY_TYPE.ORGANIZATION}
                                fields={this.fields}
                                classes={classes}
                                dataMap={clonedOrgLibraryMap}
                                deleteLibrary={this.deleteLibrary}
                                handleLibraryChange={this.handleLibraryChange} />}
                                                        <div className={classnames(classes.btnSection, "d-flex justify-content-end")}>
                                <ThemeButton
                                    position="left"
                                    width={"100px"}
                                    textColor={"#ffffff"}
                                    color={"#1354F9"}
                                    height={"44px"}
                                    theme="small"      
                                    title={disableTenantLibrarySave? "Library name can't be same or empty": ""}     
                                    disabled={disableOrgLibrarySave}
                                    onClick={()=>this.saveData(LIBRARY_TYPE.ORGANIZATION)}
                                    text={"SAVE"}
                                />
                                <ThemeButton
                                    position="left"
                                    width={"260px"}
                                    textColor={"#ffffff"}
                                    color={"#1354F9"}
                                    height={"44px"}
                                    theme="small"
                                    iconName="Plus"
                                    disabled={!selectedOrgId}
                                    onClick={()=>this.addALibrary(LIBRARY_TYPE.ORGANIZATION)}
                                    text={"ADD NEW TRAINING LIBRARY"}
                                />
                            </div>
                        </div>
                    </div>
                </BodyWrapper>
            </PageWrapper>
        );
    }
}

function GenerateTable({ classes, dataMap, deleteLibrary, handleLibraryChange, type, fields }) {
    return (
        <div className={classes.libraryTableContainer}>
            <table className={classes.libraryInfo}>
                <thead>
                    <tr className={classes.tableHead}>
                        {fields.map((field) => { return <th>{field.displayName}</th> })}
                        <th className={classes.deleteSection}></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(dataMap).map((library, index) => (
                        <tr key={index}>
                            {fields.map((field) => {
                                return (
                                    field.isEditable ? <Cell                                
                                    enableEditIcon={true}
                                    overrideHeadClass={classes.libraryName}                                   
                                    placeholder={dataMap[library][field.value] || ""}
                                    value={dataMap[library][field.value] || ""}
                                    onChange={(value) => handleLibraryChange(type, library, field.value, value)} />
                                    :
                                    <td className={classes.td}>
                                        {field.isBoolean ?
                                            <Checkbox
                                                classes={{
                                                    root: classes.checkboxRoot,
                                                    checked: classes.checkboxChecked
                                                }}
                                                onChange={() => handleLibraryChange(type, library, field.value, !dataMap[library][field.value])}
                                                checked={field.isReverse? !dataMap[library][field.value]: dataMap[library][field.value]}
                                            />
                                            :
                                            <span>                                               
                                                {dataMap[library][field.value]}
                                            </span>
                                        }
                                    </td>
                                )
                            })}
                            <td className={classnames(classes.td, classes.deleteSection)}>
                                {!dataMap[library].isDefault && !dataMap[library].activityCount && <span className={classes.serviceIcon} onClick={() => deleteLibrary(type, library)}><DeleteIcon /></span>}
                            </td>
                        </tr>))
                    }
                </tbody>
            </table>
        </div>
    )
}

function mapStateToProps(state) {
    const { session: { userContext, appContext }, tenant } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        currentUser: userContext.user,
        tenantName: tenant.name
    };
}

export default withStyles(styles)(connect(mapStateToProps, { refreshAppContext })(DyanmicLibrary));