import React, { Component } from 'react'
import { getTotalUsersByMessage } from '../../../../utils/messageUtils';

import _ from 'lodash'

import moment from 'moment'
import momentTZ from 'moment-timezone'

import './styles.css'
import InfiniteScroll from 'react-infinite-scroller';
import parse from 'html-react-parser';
export default class MessageList extends Component {
    render() {
        const {
            sent,
            messages,
            scheduled,
            onClickMessage,
            viewingMessage,
            page,
            loadMessages,
            hasMoreMessages
        } = this.props

        let messageList = (messages||[]).sort(function(a,b){
            return (scheduled ?  new Date(b.activationTime) - new Date(a.activationTime): new Date(b.sentAt || b.createdAt) - new Date(a.sentAt || a.createdAt));
        });
        return (
            <div className={'messageListOuterContainer'} ref={(ref) => this.scrollParentRef = ref}>
                <InfiniteScroll
                    pageStart={page}
                    loadMore={loadMessages}
                    hasMore={hasMoreMessages}
                    useWindow={false}
                    getScrollParent={() => this.scrollParentRef}
                    loader={<div className="moreMessageLoader">Loading ...</div>}
                >
                    {messageList.map((message, i) => {
                        if (message) {
                            let isViewingMessage = message == viewingMessage
                            let timeToDisplay = message.activationTime || message.sentAt || message.sendDate || message.createdAt
                            let messageType = message.messageType || message.type;
                            if(typeof message.timezone !== 'string' ) message.timezone = 'America/New_York'; // Fixes an issue where sometimes the messagetimezone becomes a boolean
                            let convertedTime = momentTZ.tz(timeToDisplay, message.timezone || momentTZ.tz.guess()).format('lll');
                            return (
                                <div
                                    key={i}
                                    onClick={() => onClickMessage(message)}
                                    className={isViewingMessage ? 'messageContentContainer viewingMessage' : 'messageContentContainer'}
                                >
                                    <div>
                                        <div className={'messageContentRow'}>
                                            <p className={'messagePrimaryText'}>Type: <span className={'messageHighlightText'}>{message.messageType || message.type}</span></p>
                                            <p className={'messagePrimaryText'}>Recipients: <span className={'messageHighlightText'}>{getTotalUsersByMessage(message)} Total</span></p>
                                        </div>
                                        <div className={'messageBodyContainer'}>
                                           {messageType.toLowerCase() == 'email'? <p>{parse(message.message)}</p>:
                                           <p>{message.message}</p>}
                                        </div>
                                    </div>
                                    <p className={'messagePrimaryText'}>
                                        {!!scheduled ? 'Scheduled to send' : !!sent ? 'Sent On' : 'Created'}: <span className={'messageHighlightText'}>{message.matchUserTimezone ? convertedTime  : moment(timeToDisplay).format('lll')}</span>
                                    </p>
                                </div>
                            )
                        }
                    })}
                </InfiniteScroll>
            </div>
        )
    }
}
