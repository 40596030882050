import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../loaderWithMessage';
import classnames from 'classnames'
const styles = {
    process: {
        padding: "30px",
        textAlign: "left",
        fontFamily: "Poppins !important",
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "120%"
    },
    removePadding:{
        padding:0
    },
    padding15:{
        padding:'15px'
    },
    subContainer:{
        margin:"30px 15px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        minHeight:"calc(100% - 90px)"
    },
    enableFullScreenLoader:{
        
    }
};

function BodyWrapper(props) {
    let { classes, loadingMessage1, loadingMessage2, removePadding, enableSubContainerStyle, isLoading=false, showLoadingChild = false, enableFullScreenLoader } = props;
    return (
        <React.Fragment>
            {isLoading && <Loader message={loadingMessage1} message2={loadingMessage2} />}
            {(!isLoading||showLoadingChild) && <div className={classnames(classes.process, {[classes.removePadding]:removePadding}, {[classes.subContainer]:enableSubContainerStyle}, 
                {[classes.enableFullScreenLoader]:enableFullScreenLoader},{[classes.padding15]:classes.padding15}
                )}>{props.children}</div>}
        </React.Fragment>
        );
}

BodyWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BodyWrapper);