import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ImgWithFallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      errored: false,
    };
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: this.props.fallbackSrc,
        errored: true,
      });
    }
  }

  render() {
    const { src } = this.state;
    const {
      src: _1,
      fallbackSrc: _2,
      ...props
    } = this.props;

    return (
      <img
        src={src}
        onError={this.onError}
        {...props}
      />
    );
  }
}

ImgWithFallback.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export default ImgWithFallback;