import { combineReducers } from "redux";
import {
  GET_GROUPS_BY_TEAM_ID_LOADING,
  GET_GROUPS_BY_TEAM_ID_SUCCESS,
  GET_GROUPS_BY_TEAM_ID_ERROR,
  CREATE_GROUP_LOADING,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  UPDATE_GROUP_BY_ID_LOADING,
  UPDATE_GROUP_BY_ID_SUCCESS,
  UPDATE_GROUP_BY_ID_ERROR,
  DELETE_GROUP_BY_ID_LOADING,
  DELETE_GROUP_BY_ID_SUCCESS,
  DELETE_GROUP_BY_ID_ERROR,
} from "../actions/group-actions";

const groups = (state = { loading: false, list: [], error: "" }, action) => {
  switch (action.type) {
    case GET_GROUPS_BY_TEAM_ID_LOADING: {
      return {
        ...state,
        loading: true,
        list: state.list,
        error: "",
      };
    }
    case GET_GROUPS_BY_TEAM_ID_SUCCESS: {
      const { results } = action.data;
      return {
        ...state,
        loading: false,
        list: results,
        error: "",
      };
    }

    case GET_GROUPS_BY_TEAM_ID_ERROR: {
      alert("Error in getting list of groups.");
      return {
        ...state,
        loading: false,
        list: state.list,
        error: "No groups Found",
      };
    }
  }
  return state;
};

const groupsByTeamId = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_GROUPS_BY_TEAM_ID_LOADING: {
      return {
        ...state,
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_GROUPS_BY_TEAM_ID_SUCCESS: {
      const { results, teamId } = action.data;
      return {
        ...state,
        loading: false,
        data: {
            ...state.data,
            [teamId]: results
        },
        error: "",
      };
    }

    case GET_GROUPS_BY_TEAM_ID_ERROR: {
      alert("Error in getting list of groups.");
      return {
        ...state,
        loading: false,
        data: state.data,
        error: "No groups Found",
      };
    }
  }
  return state;
};

const saveGroup = (
  state = { loading: false, group: {}, error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_GROUP_LOADING: {
      return {
        ...state,
        loading: true,
        group: {},
        error: "",
      };
    }
    case CREATE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        group: action.data,
        error: "",
      };
    }

    case CREATE_GROUP_ERROR: {
      alert("Error in creating group.");
      return {
        ...state,
        loading: false,
        group: {},
        error: "No group Found",
      };
    }
  }
  return state;
};

const updateGroup = (
  state = { loading: false, group: {}, error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_GROUP_BY_ID_LOADING: {
      return {
        ...state,
        loading: true,
        group: {},
        error: "",
      };
    }
    case UPDATE_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        group: action.data,
        error: "",
      };
    }

    case UPDATE_GROUP_BY_ID_ERROR: {
      alert("Error in updating group.");
      return {
        ...state,
        loading: false,
        group: {},
        error: "No group Found",
      };
    }
  }
  return state;
};

const deleteGroup = (state = { loading: false, id: "", error: "" }, action) => {
  switch (action.type) {
    case DELETE_GROUP_BY_ID_LOADING: {
      return {
        ...state,
        loading: true,
        id: "",
        error: "",
      };
    }
    case DELETE_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        id: action.data,
        error: "",
      };
    }

    case DELETE_GROUP_BY_ID_ERROR: {
      alert("Error in deleting group.");
      return {
        ...state,
        loading: false,
        id: "",
        error: "No group Found",
      };
    }
  }
  return state;
};

export default combineReducers({
  groups,
  saveGroup,
  updateGroup,
  deleteGroup,
  groupsByTeamId
});
