import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button } from "@material-ui/core";
import ModalContainer from '../../components/modalContainer';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { CheckMarkIcon } from '../../components/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { dynamicLibraryClient, marketplaceClient, contentCopyClient } from '../../clients';
import { ActivityTypes } from '../../constants';

import ThemeButton from '../../components/themeButton';
import FloatingSelectWithCustomChild from '../../components/floatingSelectWithCustomChild';

const styles = {
    loadingContainer: {
        textAlign: 'center',
        paddingTop: 30,
        paddingBottom: 30,
    },
    activitiesMapContainer: {
        height: 245,
        overflowY: 'scroll',
        backgroundColor: '#FFF',
        border: '1px solid #DDE2E5',
        borderRadius: 4
    },
    activityName: {
        opacity: 0.3,
        paddingLeft: 10,
        fontSize: 14
    }
};

/**
 * How often to poll for progress during an upload
 *
 * @type {Number}
 */
const POLL_INTERVAL_MS = 3000;

const DISCARD_KEY = 'thisisthediscardkey';

class ProductCopyModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            organizationCategoryMap: {},
            teamUploadProgress: { },
            processIds: [ ]
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    /**
     * Returns the list of mapped organization dynamic training libraries for the provided teams
     *
     * @param {Object} teams List of teams
     * @return {Promise<Object>}
     */
    getLibraryMapByTeams = async (teams) => {
        const organizationIds = [ ...new Set(teams.map(({ organizationId }) => organizationId)) ];
        const data = await Promise.all(
            organizationIds.map(id => dynamicLibraryClient.getDynamicLibraryByParentId('ORGANIZATION', id))
        );

        return organizationIds.reduce((list, id, index) => {
            list[id] = (data[index] || {}).data.libraries;
            return list;
        }, {});
    };

    getActivitiesByProduct = async ({ id }) => {
        const { data: { results } } = await marketplaceClient.getActivitiesByProductId(id);
        return results;
    };

    mapActivityCategoriesByOrganizationMap = async (libraryMap, activities, index = 0) => {
        const { organizationCategoryMap } = this.state;
        const organizationId = Object.keys(libraryMap).sort()[index];
        const library = libraryMap[organizationId];
        if (!organizationCategoryMap[organizationId]) {
            organizationCategoryMap[organizationId] = {};
        }
        activities.forEach(({ category }) => {
            const orgCategory = library.find(({ value }) => value === category);
            if (orgCategory) {
                organizationCategoryMap[organizationId][category] = category;
            } else {
                organizationCategoryMap[organizationId][category] = null;
            }
        });
        this.setState({ organizationCategoryMap });
        if (Object.keys(libraryMap)[index + 1]) {
            return this.mapActivityCategoriesByOrganizationMap(libraryMap, activities, index + 1);
        }
    };

    beginCopy = async (product, teams) => {
        try {
            this.setState({ teamUploadProgress: {}, organizationCategoryMap: {}, processIds: [], loading: true, product, teams });
            const [ activities, libraryMap ] = await Promise.all(
                [
                    this.getActivitiesByProduct(product),
                    this.getLibraryMapByTeams(teams)
                ]
            );
            this.setState({ activities, libraryMap, teams, product });
            await this.mapActivityCategoriesByOrganizationMap(libraryMap, activities);
            await this.validateMappedActivities(product, teams, activities, libraryMap);
        } catch (e) {
            console.log(e);
            this.showError('There was an error saving this program to your team(s). Please try again later.')
        }
    };

    validateMappedActivities = async (product, teams, activities, libraryMap) => {
        this.setState({ missingMap: null, loading: true });
        const { organizationCategoryMap, categoryValue } = this.state;
        const orgsMissingMap = [];
        Object.keys(organizationCategoryMap).forEach(orgId => {
            const categories = Object.keys(organizationCategoryMap[orgId]);
            categories.forEach(category => {
                if (!organizationCategoryMap[orgId][category] && !categoryValue) {
                    orgsMissingMap.push({ orgId, category });
                }
            })
        });

        this.setState({ loading: false });

        if (orgsMissingMap.length) {
            return this.setState({ missingMap: { ...orgsMissingMap[0] } });
        }

        product.activities = activities;

        const chunk = _.chunk(teams, 25);
        for (let i = 0; i < chunk.length; i++) {
            await Promise.all(
                chunk[i].map(team => this.copyProductToTeam(product, team))
            );
        }

        await this.pollForUpdates();
    };

    copyProductToTeam = async (product, team) => {
        const { organizationCategoryMap, categoryValue } = this.state;
        const { userContext: { user } } = this.props;
        const { activities = [], workouts = [], workoutPrograms = [] } = product;

        const data = activities
            .map(({ id, category }) => {
                const map = organizationCategoryMap[team.organizationId];
                let mappedCategory = map[category];
                if (!mappedCategory) {
                    mappedCategory = categoryValue;
                }
                if (mappedCategory === DISCARD_KEY) {
                    return null;
                }
                return { type: 'activity', id, teamId: team.id, category: mappedCategory };
            })
            .filter(activity => !!activity)
            .concat(workouts.map(id => ({ type: 'workout', id, teamId: team.id })))
            .concat(workoutPrograms.map(id => ({ type: 'workout_program', id, teamId: team.id })));

        const { teamUploadProgress } = this.state;
        const { data: { id, url } } = await contentCopyClient.createProcessByUserId(user.id);

        teamUploadProgress[id] = {
            team,
            progress: 0
        };

        this.setState({ teamUploadProgress });

        const { processIds } = this.state;
        processIds.push(id);
        this.setState({ processIds: [ ...new Set(processIds) ] });

        await fetch(url, {
            method: 'put',
            body: Buffer.from(JSON.stringify(data)),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        try {
            await contentCopyClient.beginProcessById(id);
        } catch (e) {
            console.log(`Error beginning process by ID ${id}`);
        }
    };

    pollForUpdates = async () => {
        const { processIds } = this.state;
        await Promise.all(
            processIds.map(id => this.pollForUpdateByProcessId(id))
        )
    };

    pollForUpdateByProcessId = async (id, index = 0, retried = false) => {
        const { teamUploadProgress } = this.state;
        const { data: { current = 0, total = 0, status } } = await contentCopyClient.getProcessById(id);
        teamUploadProgress[id].progress = Math.ceil((current / total) * 100) || 0;

        // If we've tried more than 5 times and the process still hasn't started,
        // we can probably assume there was an issue, so try to kick it off again. If it's
        // still failing, remove from the queue. There's no saving it :/
        if (status === 'NOT_STARTED' && index > 5 && !retried) {
            try {
                await contentCopyClient.beginProcessById(id);
            } catch (e) {}
            retried = true;
        } else if ((status === 'NOT_STARTED' && retried && index >= 10) || status === 'ERROR') {
            delete teamUploadProgress[index];
        }

        this.setState({ teamUploadProgress });

        if (status === 'RUNNING' || (status === 'NOT_STARTED' && index <= 10)) {
            setTimeout(() => this.pollForUpdateByProcessId(id, index + 1, retried), POLL_INTERVAL_MS);
        }
    };

    showError = (message) => {
        this.setState({ loading: false, error: message });
    };

    handleSelectedCategory = (value) => {
        this.setState({ categoryValue: value });
    };

    render() {

        const { open, close, classes } = this.props;
        const {
            loading,
            product,
            teamUploadProgress,
            error,
            missingMap,
            activities = [],
            libraryMap = {},
            categoryValue,
            teams
        } = this.state;

        const uploads = Object.values(teamUploadProgress);
        const totalProgress = Math.round((uploads.reduce((total, { progress }) => Math.round(total + progress), 0) * 100) / (uploads.length * 100));

        let headerText;
        if (!loading && !error) {
            headerText = `Adding ${product.name}`;
        }

        const activitiesToMap = missingMap && activities.filter(({ category }) => {
            return category === missingMap.category;
        });

        return (
            <ModalContainer
                customPadding={'30px'}
                showCloseIcon={!loading && totalProgress === 100}
                showFooter={!loading && totalProgress === 100}
                heading={headerText}
                open={open}
                close={close}
                width={'630px'}
                onClose={close}
                useDefaultLeftBtn={false}
                rightBtn={
                    <ThemeButton
                        width={'86px'}
                        textColor={'#ffffff'}
                        color={'#27AE60'}
                        height={'44px'}
                        theme={'small'}
                        onClick={close}
                        text={'Finish'}
                    />}
            >
                {loading && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}
                {(!loading && error) && (
                    <div style={{ marginBottom: 20 }}>
                        {error}
                    </div>
                )}
                {(!loading && !error && !activitiesToMap) && (
                    <div>
                        Uploading content: {totalProgress || 0}%
                        <LinearProgress variant={'determinate'} value={totalProgress || 0} style={{ marginTop: 10 }} />
                    </div>
                )}
                {(!loading && !error && activitiesToMap) && (
                    <div>
                        <p>
                            One or more of the activities you are attempting to add uses a Training Library your organization does not utilize. To continue, please choose a library to map these activities to.
                        </p>
                        <div className={classes.activitiesMapContainer}>
                            {activitiesToMap.map(({ name, category }, index) => {
                                const activityCategory = ActivityTypes.find(({ actual }) => actual === category);
                                return (
                                    <div key={index} className={classes.activityName}>
                                        {name} {activityCategory && `- ${activityCategory.displayed}`}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row" style={{ marginTop: 20 }}>
                            <div className="col-6">
                                <FloatingSelectWithCustomChild
                                    dropDownWidth={'100%'}
                                    value={categoryValue || null}
                                    label={'NEW Training Library'}
                                    onChange={value => this.handleSelectedCategory(value)}
                                    dropDownLabel={'NEW Training Library'}
                                    menuItemData={[
                                        {
                                            displayValue: 'Discard',
                                            value: DISCARD_KEY
                                        },
                                    ].concat((libraryMap[missingMap.orgId] || []).map(({ value, label }) => ({
                                        displayValue: label,
                                        value
                                    })))}
                                    showDropDownBorder={true}
                                />
                            </div>
                            <div className={'col-6'}>
                                <ThemeButton
                                    width={'86px'}
                                    textColor={'#ffffff'}
                                    color={'#27AE60'}
                                    height={'47px'}
                                    theme={'small'}
                                    onClick={() => this.validateMappedActivities(product, teams, activities, libraryMap)}
                                    text={'Apply'}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </ModalContainer>
        )
    }
}

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    return {
        appContext: appContext,
        userContext: userContext
    };
};

export default withStyles(styles)(connect(mapStateToProps)(ProductCopyModal));