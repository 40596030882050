import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PasswordInput from '../../components/passwordInput';
import { analyticsService, currentTimestamp } from "../../services/analyticsService";

import { setUserContext, setAppContext } from '../../actions/session';
import ContextService from '../../services/context';

import { withStyles } from '@material-ui/core/styles';

import { Auth, API } from "aws-amplify";

import MetaTags from 'react-meta-tags';

import "./athlete.css";

import { getThemeByTenant } from '../../themes';
const theme = getThemeByTenant();
const logo = theme.image.loginLogo;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 500,
    padding: 50
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth:500
  },
  button: {
    width: 300,
    marginTop:10
  },
  root: {
    flexGrow: 1,
    margin:20
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginBottom:'-15px'
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom:'-35px'
  },
  textField: {
    flexGrow: 1,
    width:"100%"
  },
  formControl: {
    margin: 8,
    minWidth: 120,
    width:"100%",
    marginTop:16,
    marginLeft:-2
  },
  selectEmpty: {
    marginTop: 8 * 2,
  },
});


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={["+", /[1-9]/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class AthleteSignup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmationCode: "",
      nameFirst: "",
      nameLast: "",
      newUser: null,
      inviteCode: null,
      inviteCodeQuery: "",
      inviteCodeSubmitted: false,
      eventOrProgramName: "",
      graduationYear: "",
      gender: "",
      weight: "",
      height: "",
      parentEmail: "",
      emergencyPhone: "+1",
      phoneNumber: "+1",
      showConfirmation:false
    };
  }

  async componentDidMount(){
    if( this.props.location.search){
      var query = this.props.location.search.substring(1).toLowerCase()
      this.handleInviteCodeSubmit(query)
    }
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.username.length > 0 &&
      this.state.nameFirst.length > 0 &&
      this.state.nameLast.length > 0 &&
      this.state.phoneNumber.length > 0

    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    var phoneNumber = null
    console.log("phoneNumber", phoneNumber)
    var attributes = {
      "email": this.state.email,
      "given_name": this.state.nameFirst,
      "family_name": this.state.nameLast
    }
    if(this.state.phoneNumber){
      phoneNumber = this.state.phoneNumber.replace(/[-\/\\^$*?.() |[\]{}]/g , '')
    }
    if(phoneNumber.length === 11){
      attributes.phone_number = "+" + phoneNumber
    }
    try {
      const newUser = await Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        'attributes': attributes
      });

      this.setState({
        newUser
      });
      let user = this.state.newUser;
      var trackingParam = {
        id: user.id,
        first_name: user.nameFirst,
        last_name: user.nameLast,
        email: user.emailAddress,
        username: user.username,
        program: user.tenant
      };
      analyticsService.initWithUser(trackingParam)
      analyticsService.trackEvent("Signed Up",{user_type: "athlete"});

    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  setSessionInfo = async (username) => {
    const contextService = new ContextService();
    const { userContext, appContext } = await contextService.buildUserContext(username);

    this.props.setUserContext(userContext);
    this.props.setAppContext(appContext);
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    var phoneNumber = this.state.phoneNumber.replace(/[-\/\\^$*+?.() |[\]{}]/g , '')
    console.log("phoneNumber", phoneNumber)

    try {
      await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);
      var result = await Auth.signIn(this.state.username, this.state.password);
    } catch (e) {
      this.setState({ isLoading: false });
    }

    try{
      var newUser = {}
      if(this.state.nameFirst && this.state.nameFirst !== ""){
        newUser.nameFirst = this.state.nameFirst
      }
      if(this.state.nameLast && this.state.nameLast !== ""){
        newUser.nameLast = this.state.nameLast
      }
      if(this.state.username && this.state.username !== ""){
        newUser.username = this.state.username
      }
      if(phoneNumber && phoneNumber !== "1"){
        newUser.phoneNumber = phoneNumber
      }
      if(this.state.email && this.state.email !== ""){
        newUser.email = this.state.email
      }
      if(this.state.graduationYear && this.state.graduationYear !== ""){
        newUser.graduationYear = this.state.graduationYear
      }
      if(this.state.height && this.state.height !== ""){
        newUser.height = this.state.height
      }
      if(this.state.weight && this.state.weight !== ""){
        newUser.weight = this.state.weight
      }
      if(this.state.parentName && this.state.parentName !== ""){
        newUser.parentName = this.state.parentName
      }
      if(this.state.parentEmail && this.state.parentEmail !== ""){
        newUser.parentEmail = this.state.parentEmail
      }
      if(this.state.emergencyPhone && this.state.emergencyPhone !== "1"){
        newUser.emergencyPhone = this.state.emergencyPhone.replace(/[-\/\\^$*+?.() |[\]{}]/g , '')
      }
      if(this.state.birthday && this.state.birthday !== ""){
        newUser.birthday = this.state.birthday
      }
      if(this.state.gender && this.state.gender !== ""){
        newUser.gender = this.state.gender
      }
      var user = await this.createUser(newUser)

      var programId = ""
      if(this.state.events && this.state.events.length > 0){
        programId = this.state.events[0].programId
        const eventRoleJSON = {
            eventId: this.state.events[0].id,
            userId: user.id,
            role:"athlete"
        }
        try{
          var eventRole = await this.createEventEnrollment(eventRoleJSON)
        }
        catch(e){
          console.log('e ', e)
        }
      }
      else{
        programId = this.state.programs[0].id
      }

      const roleJSON = {
          parentId: programId,
          userId: user.id,
          role: "player"
      }
      try{
        var role = await this.createRole(roleJSON)
      }
      catch(e){
        console.log('e ', e)
      }
      try{
        await this.setSessionInfo(user.username)
      }
      catch(e){
      }
      this.props.userHasAuthenticated(true);
    }
    catch(e){
      console.log('e ', e)
      this.setState({ isLoading: false });
    }
  }

  handleInviteCodeSubmit = async event => {
    var inviteCode = ""
    if(typeof event === "string"){
      inviteCode = event
    }
    else{
      event.preventDefault()
      inviteCode = this.state.inviteCode
    }
    this.setState({errorMessage: ""})

    this.setState({ isLoading: true });
    var programs = []
    var events = []

    // First try to find code in Programs
    try{
      programs = await this.getProgramByAthleteCode(inviteCode);
      if(programs.length > 0){
        this.setState({eventOrProgramName: programs[0].name, programs})
      }
    }
    catch(e){
      console.log('e ', e)
    }
    // Then check in Events
    try{
      events = await this.getEventByInviteCode(inviteCode)
      if(events.length > 0){
        this.setState({eventOrProgramName: events[0].name, events})
      }
    }
    catch(e){
      console.log('e ', e)
    }

    if(events && programs && events.length === 0 && programs.length === 0){
      this.setState({errorMessage: "Oops, we couldn\'t find a program or event that matches that code."})
    }
    else{
      this.setState({inviteCodeSubmitted:true})
    }
  }

  getProgramByAthleteCode(inviteCode) {
    return API.get("programs", `/programs/playerCode/${inviteCode}`);
  }
  getEventByInviteCode(inviteCode) {
    return API.get("events", `/events/code/${inviteCode}`);
  }

  createUser(user) {
    return API.post("users", "/users", {
      body: user
    });
  }

  createRole(role) {
      return API.post("users", `/users/${role.userId}/roles`, {
          body: role
      });
  }

  createEventEnrollment(role) {
      return API.post("users", `/users/${role.userId}/eventEnrollments`, {
          body: role
      });
  }

  renderConfirmationForm() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <Grid container spacing={8} style={{paddingLeft:50, paddingRight:50}}>
        <Grid item xs={12}>
          <img className={classes.image} src={logo}  />
          <h1 className={classes.header}>WELCOME</h1>
          <h4 className={classes.subHeader}>{`Enter the sign up code you received in your email`}</h4>
        </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="username1"
              style={{maxWidth:500}}
              label="Username"
              className={classes.textField}
              value={this.state.username}
              onChange={this.handleChange('username')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
              <PasswordInput
                  id="password"
                  style={{maxWidth:500}}
                  label="Password"
                  type="password"
                  autoComplete="password"
                  className={classes.textField}
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  margin="normal"
              />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="confirmationCode"
              style={{maxWidth:500}}
              label="Confirmation Code"
              className={classes.textField}
              value={this.state.confirmationCode}
              onChange={this.handleChange('confirmationCode')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" color="primary" type="submit" className={classes.button}>
              Submit
            </Button>
          </Grid>
          </Grid>
        </form>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const heights = [
      "3 ft. 0 in.", "3 ft. 1 in.", "3 ft. 2 in.", "3 ft. 3 in.", "3 ft. 4 in.", "3 ft. 5 in.", "3 ft. 6 in.","3 ft. 7 in.","3 ft. 8 in.","3 ft. 9 in.","3 ft. 10 in.","3 ft. 11 in.",
      "4 ft. 0 in.", "4 ft. 1 in.", "4 ft. 2 in.", "4 ft. 3 in.", "4 ft. 4 in.", "4 ft. 5 in.", "4 ft. 6 in.","4 ft. 7 in.","4 ft. 8 in.","4 ft. 9 in.","4 ft. 10 in.","4 ft. 11 in.",
      "5 ft. 0 in.", "5 ft. 1 in.", "5 ft. 2 in.", "5 ft. 3 in.", "5 ft. 4 in.", "5 ft. 5 in.", "5 ft. 6 in.","5 ft. 7 in.","5 ft. 8 in.","5 ft. 9 in.","5 ft. 10 in.","5 ft. 11 in.",
      "6 ft. 0 in.", "6 ft. 1 in.", "6 ft. 2 in.", "6 ft. 3 in.", "6 ft. 4 in.", "6 ft. 5 in.", "6 ft. 6 in.","6 ft. 7 in.","6 ft. 8 in.","6 ft. 9 in.","6 ft. 10 in.","6 ft. 11 in.",
      "7 ft. 0 in.", "7 ft. 1 in.", "7 ft. 2 in.", "7 ft. 3 in.", "7 ft. 4 in.", "7 ft. 5 in.", "7 ft. 6 in.","7 ft. 7 in.","7 ft. 8 in.","7 ft. 9 in.","7 ft. 10 in.","7 ft. 11 in."
    ]
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <MetaTags>
          <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </MetaTags>
        <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
          <Grid item xs={12}>
            <img className={classes.image} src={logo}  />
            <h1 className={classes.header}>ATHLETE REGISTRATION</h1>
            <h4 className={classes.subHeader}>{`Cool, you're signing up for ${this.state.eventOrProgramName}`}</h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nameFirst"
              label="Athlete's First Name"
              className={classes.textField}
              value={this.state.nameFirst}
              onChange={this.handleChange('nameFirst')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nameLast"
              label="Athlete's Last Name"
              className={classes.textField}
              value={this.state.nameLast}
              onChange={this.handleChange('nameLast')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="username"
              label="Athlete's Username"
              className={classes.textField}
              value={this.state.username}
              onChange={this.handleChange('username')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="email"
              label="Athlete's Email"
              type="email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="formatted-text-mask-input">{`Athlete's Phone Number`}</InputLabel>
              <Input
                id="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleChange('phoneNumber')}
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            <h4 style={{fontWeight:100, fontSize:12, float:'left', marginTop:-5}}>{`* Coaches may send event or program information to this number`}</h4>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="graduationYear-simple">{`Athlete's Graduation Year`}</InputLabel>
              <Select
                id="graduationYear"
                className={classes.textField}
                value={this.state.graduationYear}
                onChange={this.handleChange("graduationYear")}
                inputProps={{
                  name: 'graduationYear',
                  id: 'graduationYear-simple',
                }}
              >
              <MenuItem value={2018}>2018</MenuItem>
              <MenuItem value={2019}>2019</MenuItem>
              <MenuItem value={2020}>2020</MenuItem>
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
              <MenuItem value={2026}>2026</MenuItem>
              <MenuItem value={2027}>2027</MenuItem>
              <MenuItem value={2028}>2028</MenuItem>
              <MenuItem value={2029}>2029</MenuItem>
              <MenuItem value={2030}>2030</MenuItem>
              <MenuItem value={2031}>2031</MenuItem>
              <MenuItem value={2032}>2032</MenuItem>
              <MenuItem value={2033}>2033</MenuItem>
              <MenuItem value={2034}>2034</MenuItem>
              <MenuItem value={2035}>2035</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              style={{marginTop:16}}
              id="birthday"
              label="Athlete's Birthday"
              type="date"
              defaultValue="2017-05-24"
              className={classes.textField}
              onChange={this.handleChange('birthday')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="gender-simple">{`Athlete's Gender`}</InputLabel>
                <Select
                  id="gender"
                  className={classes.textField}
                  value={this.state.gender}
                  onChange={this.handleChange("gender")}
                  inputProps={{
                    name: 'gender',
                    id: 'gender-simple',
                  }}
                >
                <MenuItem value={"None"}>None</MenuItem>
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="weight"
              label="Athlete's Weight"
              className={classes.textField}
              value={this.state.weight}
              onChange={this.handleChange("weight")}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="height-simple">{`Athlete's Height`}</InputLabel>
                <Select
                  id="height"
                  className={classes.textField}
                  value={this.state.height}
                  onChange={this.handleChange("height")}
                  inputProps={{
                    name: 'height',
                    id: 'height-simple',
                  }}
                >
                {heights.map(
                  (height, i) =>
                    <MenuItem key={height} value={height}>{height}</MenuItem>
                    )
                  }
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="parentName"
              label="Parent's Name"
              className={classes.textField}
              value={this.state.parentName}
              onChange={this.handleChange('parentName')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="parentEmail"
              label="Parent's Email"
              type="email"
              className={classes.textField}
              value={this.state.parentEmail}
              onChange={this.handleChange('parentEmail')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="formatted-text-mask-input">Emergency Contact Phone Number</InputLabel>
              <Input
                id="emergencyPhone"
                value={this.state.emergencyPhone}
                onChange={this.handleChange('emergencyPhone')}
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <PasswordInput
              id="password"
              label="Password"
              type="password"
              className={classes.textField}
              value={this.state.password}
              onChange={this.handleChange('password')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <h4 style={{fontWeight:100, fontSize:12, float:'left'}}>By signing up for {theme.product.name} you agree to our <a href="https://www.gomaxone.com/terms-of-service/">Terms of Use.</a></h4>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" color="primary" className={classes.button} type="submit" >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderInviteForm(){
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleInviteCodeSubmit}>
      <MetaTags>
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </MetaTags>
      <Grid container spacing={8} style={{paddingLeft:50, paddingRight:50}}>
        <Grid item xs={12}>
          <img className={classes.image} src={logo}  />
          <h1 className={classes.header}>WELCOME</h1>
          <h4 className={classes.subHeader}>{`Let's get to work.`}</h4>
          <h4 className={classes.subHeader} style={{color:"red"}}>{this.state.errorMessage}</h4>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="inviteCode"
            label="Invite Code"
            className={classes.textField}
            value={this.state.inviteCode}
            onChange={this.handleChange('inviteCode')}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="primary" className={classes.button} type="submit" >
            {`Let's Go`}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} style={{marginTop:10, marginBottom:10}}>
          <br />
        </Grid>
        <Grid item xs={12} sm={12}>
          <a href="/signup/parent">{`I'm a parent`}</a> or <a href="http://www.gomaxone.com/pricing/">{`I don't have an invite code`}</a>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button style={{marginTop:20}} variant="contained" onClick={() => this.setState({showConfirmation: true})}>Already have an invite code? </Button>
        </Grid>
      </Grid>
    </form>
    );
  }

  render() {
    return (
      <div className="AthleteSignup">
        {
          !this.state.inviteCodeSubmitted
          ? this.renderInviteForm()
          :
          <div>
          {this.state.newUser === null && !this.state.showConfirmation
            ? this.renderForm()
            : this.renderConfirmationForm()}
          </div>
        }
      </div>
    );
  }
}

AthleteSignup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AthleteSignup));
