import React from 'react'
import './styles.css'
import { PrimaryButton, InputField } from 'maxone-react-ui'
import CheckIcon from '@material-ui/icons/Check'
import { testId } from '../../../../../utils/test-utils';

export default function VerifyPhoneTab(props){
    const { open, phoneNumber, title, email, onClick, handleChange, handleResendVerificationCode, isLoading, verificationCode, isVerificationCodeValid } = props
    return(
        open ?
            <div className={'currentTabContainer'}>
                <div className={'row multiItemRowContainer tabContentContainer'}>
                    <div className={'col-lg-4 columnContainer tabContentLeftContainer'}>
                        <p className={'stepNumberText'}>Step 2 of 2</p>
                        <p className={'stepTitleText'}>Verify Your Phone Number</p>
                        <p className={'hideMediumDown stepDescriptionText'}>To create you account we require you to confirm your phone number and associate it to your account.</p>
                    </div>
                    <div className={'col-lg-8 columnContainer tabContentRightContainer'}>
                        <p className={'detailText'}>We sent a verification code to <span className={'boldedText'}>{phoneNumber}</span></p>
                        <div className={'marginSpacer20'}/>
                        <div className={'multiItemRowContainer row'}>
                            <div className="col-lg-6" {...testId(`verification-code-container`)}>
                                <InputField label={'Enter Verification Code'} onChange={value => handleChange('verificationCode', value)} />
                            </div>
                            <div className="col-lg-6 pt-3 pt-lg-0" {...testId(`verify-button-container`)}>
                                <PrimaryButton
                                    height={58}
                                    disabled={!verificationCode}
                                    multiline={true}
                                    loading={!!isLoading}
                                    text={'Verify Phone Number'}
                                    onClick={onClick}
                                    secondaryText={'Next: Setup Complete'}
                                    width={window.innerWidth < 576 ? '100%' : 275}
                                    leftIcon={<CheckIcon style={{ color: 'white' }} />}
                                />
                            </div>                      
                        </div>
                        {!isVerificationCodeValid && <p className='smallGrayDetailText smallErrorDetailText'>Verification code is not valid.</p>}                       
                        <div className={'marginSpacer20'}/>                   
                        <p className={'smallDetailText'}>Didn't receive your code?</p>
                        <p className={'smallLinkText'} onClick={() =>handleResendVerificationCode()}>Request another one.</p>
                    </div>
                </div>
                <div className={'marginSpacer20'}/>
            </div>
        :
            <div className={'closedTabOuterContainer borderTop'}>
                <p className={'closedTabTitle'}>Next Step: <span className={'boldedText'}>{title}</span></p>
            </div>
    )
}
