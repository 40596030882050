import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RightPointerIcon from '../../icons/RightPointerIcon';
// import downArrowIcon from '../../icons/DownArrowIcon';

import './index.scss';

const DropDown = ({
	selectOptions,
	selectValues,
	tagSearchChange,
	tagSearchActive,
}) => {
	const [selectValue, setSelectValue] = useState('all');

	useEffect(() => {
		if (!tagSearchActive) {
			setSelectValue('all');
		}
	}, [tagSearchActive]);
	return (
		<div className='drop-down-component'>
			<RightPointerIcon className='pointer-icon' />
			<select
				className='dropdown'
				onChange={(e) => {
					setSelectValue(e.target.value);
					tagSearchChange(e);
				}}
				value={selectValue}>
				<option value='all'>all</option>
				{selectOptions.map((option, index) => {
					return <option value={selectValues[index]}>{option}</option>;
				})}
				<option value='untagged'>untagged</option>
			</select>
		</div>
	);
};

DropDown.propTypes = {
	selectOptions: PropTypes.array,
	selectValues: PropTypes.array,
	tagSearchActive: PropTypes.bool,
};

DropDown.defaultProps = {
	selectOptions: ['one', 'two', 'three'],
	selectValues: ['one', 'two', 'three'],
	tagSearchActive: true,
};

export default DropDown;
