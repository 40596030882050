import React from 'react';
import * as availableIcons from '../icons';
import IconButton from "../iconButton";

const btnTheme = {
    small:{
        textFontSize:"14px",
        iconSize:"16px",
        buttonPadding:"0px 8px",
        leftIconMargin:"4px",
        rightIconMargin:"4px",
        fontWeight:"500",
        removeIconPadding:true
    },
    small2:{
        textFontSize:"16px",
        iconSize:"16px",
        buttonPadding:"0px",
        leftIconMargin:"4px",
        rightIconMargin:"4px",
        fontWeight:"600",
        removeIconPadding:true
    },
    leftAlignIcon:{
        textFontSize:"14px",
        iconSize:"25px",
        buttonPadding:"0px 8px",
        leftIconMargin:"4px",
        rightIconMargin:"4px",
        fontWeight:"500",
        removeIconPadding:true
    },
    default:{
        buttonPadding:"0px",
        iconSize:"20px",
        textFontSize:"18px",
        leftIconMargin:"4px",
        rightIconMargin:"12px",
        fontWeight:"600",
        removeIconPadding:true
    },
    leftAlign:{
        buttonPadding:"20px",
        iconSize:"20px",
        textFontSize:"18px",
        leftIconMargin:"4px",
        rightIconMargin:"-6px",
        fontWeight:"600",
        removeIconPadding:false
    }
}
const ThemeButton = ({
    color = "#27AE60",
    width = "315px",
    height = "52px",
    textColor="#ffffff",
    disableColor="#DDE2E5",
    position="right",
    onClick,
    disabled,
    text,
    iconName,
    iconColor,
    overrideContainerClassName,
    title,
    id,
    theme="default" }) => {
    const btnThemeDetails = btnTheme[theme];
    return <IconButton
        overrideContainerClassName={overrideContainerClassName}
        removeIconPadding={btnThemeDetails.removeIconPadding}
        buttonPadding={btnThemeDetails.buttonPadding}
        position={position}
        width={width}
        height={height}
        color={color}
        onClick={onClick}
        disabled={disabled}
        textColor={textColor}
        disableColor={disableColor}
        text={text}
        title={title}
        fontWeight={btnThemeDetails.fontWeight}
        textFontSize={btnThemeDetails.textFontSize}
        id={id}
        icon={iconName ? getIconByName(iconName, {
            style: {
                marginLeft: position=="right" ? btnThemeDetails.rightIconMargin:"0px",
                marginRight: position=="left" ? btnThemeDetails.leftIconMargin:"0px",
                width: btnThemeDetails.iconSize,
                height: btnThemeDetails.iconSize,
                fill: iconColor || textColor
            }
        }) : null}
    />
}

function getIconByName(name, props){
    let iconName = name + "Icon";
    let Icon = null;
    const allIcons = Object.keys(availableIcons);
    if (allIcons.includes(iconName)) {
        Icon = availableIcons[iconName];
    }
    return <Icon {...props} />
}


export default ThemeButton;