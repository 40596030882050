import React, { Component } from "react";
import { connect } from 'react-redux';
import ModalContainer from '../../components/modalContainer';
import { withStyles } from '@material-ui/core/styles';
import SearchInput from '../../components/searchInput';
import classnames from 'classnames';
import { CheckMarkIcon } from '../../components/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { workoutClient } from '../../clients'
import { getTeamLogo } from '../../utils/commonUtil.js'
import InfiniteScroll from 'react-infinite-scroller';
import { InputField } from 'maxone-react-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import DatePicker from "react-datepicker";
import { scoreTypesMap } from '../../constants.js'
import moment from 'moment';
import { goalsClient } from '../../clients';
import { validateScoreOrGoalValue, getScoreTypeHelperTxt, getResultDescriptionTxt,formatMeasureValueForSaving, formatMeasureValueForDisplaying } from '../../utils/activityUtils'

let styles = {
    topSectionAdditionalContent: {
        marginTop: "6px"
    },
    subHeadTxt: {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom: "12px"
    },
    activityCard: {
        verticalAlign: "top",
        cursor: "pointer",
        width: "277px",
        height: "61px",
        marginBottom: "15px",
        display: "inline-block",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        padding: "0 12px",
        "&.selectedTeamSquare": {
            border: "2px solid #27AE60"
        },
        "&:nth-child(even)": {
            marginLeft: "14px",
        }
    },
    activityName: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        color: "#0B0B0B",
        marginLeft: "10px"
    },
    activityTitleLmt:{
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        overflow: "hidden"
    },
    searchBox: {
        height: "44px",
        marginBottom: "17px"
    },
    activityImage: {
        minWidth: "36px",
        minHeight: "36px",
        maxWidth: "36px",
        maxHeight: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px"
    },
    checkMark: {
        border: "1px solid #DDE2E5",
        color: "#DDE2E5",
        "& svg": {
            width: "20px",
            height: "20px"
        }
    },
    checkMarkFilledContainer: {
        color: "white",
        border: "1px solid #27AE60",
        background: "#27AE60",
        color: "white",
        "& svg": {
            width: "20px",
            height: "20px"
        }
    },
    activityCardContainer: {
        height: "390px",
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "20px",
        justifyContent: "space-between",
        position: "relative"
    },
    marketplaceModalPaper: {
        marginRight: "-30px",
        marginLeft: "-30px",
        marginBottom: "-30px",
        background: "#F8F8F8",
        paddingLeft: "30px",
        paddingRight: "30px"
    },
    activitySelectedTxt: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%"
    },
    inputDarkBorder: {
        border: "2px solid #A0A7AB"
    },
    activityFilters: {
        paddingBottom: "20px"
    },
    filterSelect: {
        border: "1px solid #DDE2E5",
        borderRadius: "5px",
        fontSize: "11px",
        padding: "5px",
        marginRight: "5px",
        cursor: "pointer",
        marginBottom:"5px"
    },
    filterSelected: {
        background: "#27AE60",
        color: "white",
        borderColor: "transparent",     
    },
    fetchingMore: {
        marginLeft: "15px",
        fontSize: "14px"
    },
    loadingChild: {
        backgroundColor: "rgba(255,255,255,0.5)",
        position: "absolute"
    },
    selectedActivityContainer: {
        height: "60px",
        borderRadius: "5px",
        position: "relative",
        background: "white",
        marginBottom: "10px",
        border: "1px solid lightgray"
    },
    selectedActivityImgContainer: {
        width: "98px",
        height: "100%",
        overflow: "hidden",
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
        marginRight: "12px",
        "& img": {
            width: "100%",
            height: "100%"
        }
    },
    titleContainer: {
        lineHeight: "120%"
    },
    delIcon: {
        marginLeft: "auto",
        marginRight: "10px",
        "& svg": {
            color: "#DDE2E5",
            cursor: "pointer"
        }
    },
    activityTitle: {
        fontWeight: 500,
        fontSize: "18px",
        color: "#222127"
    },
    activitySubCategory: {
        fontSize: "14px",
        color: "#222127",
        opacity: 0.6
    },
    goalInputs: {
        "& .react-datepicker-wrapper":{
            width:"100%",
            marginBottom:"24px"
        },
        "& .react-datepicker__input-container":{
            width: "100%"
        },
        "& .react-datepicker-popper":{
            zIndex:2,        
        }
    },
    input: {
        marginBottom: "24px",
        "&:last-child": {
            marginBottom: "0px"
        },
        "& .m1-base-input-field": {
            height: "50px"
        }
    },
    inputField: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px",
        marginBottom: "4px"
    },
    goalType:{
        fontSize: "12px",
        color:"rgba(34, 33, 39, 0.6)"
    },
    helperTxt:{
        color:"#F93F3F",
        fontSize:"10px",
        marginTop: "-20px",
        marginBottom: "5px"
    }
}

const CATEGORIES = Object.freeze(
    {
        "DRILL": "Skill Development",
        "EXERCISE": "Strength & Conditioning",
        "EDUCATION": "Education Materials",
        "COACH": "Coaching Resources"
    }
);

const STEPS = Object.freeze(
    {
        "ADD_ACTIVITY": "ADD_ACTIVITY",
        "ADD_GOAL": "ADD_GOAL"
    }
);

class AddGoalModal extends Component {
    constructor(props) {
        super(props);
        this.typingTimeout = null;
        this.state = {
            selectedActivityId: null,
            filteredActivities: [],
            currentPage: 0,
            activityPerPage: 20,
            totalPages: 0,
            isLoading: false,
            searchTerm: "",
            loadingChild: false,
            currentStep: STEPS.ADD_ACTIVITY,
            validationData:{},
            loadingMessage:""
        }
    }

    componentDidMount = () => {
        let {selectedGoal} = this.props;
        if(!!selectedGoal){
            //Format the values to display them
            let { value, startingMeasure, currentMeasure, scoreType, activityId, activityName, startingMeasureDate, currentMeasureDate, activityImage, id} = selectedGoal;                     
            this.setState({...selectedGoal, value, startingMeasure, currentMeasure, startingMeasureDate, currentMeasureDate,  currentStep: STEPS.ADD_GOAL, 
                disableStartingMeasure: !!id && !!startingMeasure,
                selectedActivity:{ 
                title: activityName, scoreType,  id:activityId, media:{thumbnail:activityImage}}
            });
        }
        else {
            this.setState({isLoading : true, loadingMessage:"Loading Activities..."});
            this.getActivities();
        }
    }

    validate =  (value,field) =>{
        let { validationData, selectedActivity } = this.state;
        const scoreType = ((selectedActivity||{}).scoreType||"").replace("_","");     
        let isValid = validateScoreOrGoalValue(scoreType, value);
        //Goal cannot be null
        if(field === "value" && !value){
            isValid = false;
        }
        this.setState({validationData: {...validationData, [field]: isValid}})
    }

    getActivities = async (pageNumber) => {
        let { currentPage, activityPerPage, filteredActivities: existingActivities, id,  value, currentMeasureDate, activityName, scoreType} = this.state;
        let { searchTerm, activeQuickFilter } = this.state;
        let { currentTeam } = this.props;
        let queryParams = {
            sort: "name.asc",
            limit: activityPerPage,
            offset: (pageNumber !== undefined ? pageNumber : currentPage) * activityPerPage,
            scoreType: "DISTANCE||TIME||SHORTEST_TIME||SHORTESTTIME||COUNT||MPH||WEIGHT"
        };
        if (activeQuickFilter) {
            queryParams.category = activeQuickFilter
        }
        if (searchTerm) {
            queryParams.q = searchTerm;
        }
        let { data: { meta, total, results: filteredActivities } } = await workoutClient.searchActivitiesByTeamId(currentTeam.id, queryParams);
        this.setState({ filteredActivities: pageNumber ? (existingActivities || []).concat(filteredActivities) : filteredActivities, isLoading: false, loadingChild: false, currentPage: pageNumber || 0, totalPages: Math.floor(total / activityPerPage) })
    }

    select = (activity) => {
        let { selectedActivityId } = this.state;
        this.setState({ selectedActivityId: activity.id == selectedActivityId ? null : activity.id });
    }

    handleSearch = (searchTerm, timeOut = 0) => {
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }
        this.setState({
            searchTerm
        }, () => {
            this.typingTimeout = setTimeout(() => {
                this.setState({ loadingChild: true, selectedActivityId: null });
                this.getActivities(0);
            }, (timeOut || 1000))
        });
    }

    selectQuickFilter = (filterName) => {
        let { activeQuickFilter } = this.state;
        this.setState({ activeQuickFilter: filterName == activeQuickFilter ? null : filterName, currentPage: 1, loadingChild: true, selectedActivityId: null }, () => this.getActivities(0));
    }

    handleNext = async () => {        
        let { currentStep, selectedActivity, selectedActivityId, filteredActivities, value, currentMeasure, currentMeasureDate, startingMeasure, startingMeasureDate, id } = this.state;
        let { save, userId, currentTeamId } = this.props;
        let scoreType = "";
        if (currentStep == STEPS.ADD_ACTIVITY) {
            this.setState({isLoading: true, loadingMessage:"Fetching info..."});
            let existingGoalData = {};
            selectedActivity =  filteredActivities.find(activity => activity.id == selectedActivityId);
            scoreType = (selectedActivity.scoreType||"").replace("_","")
            try{
                let { data: { results: fetchedData, total } } = await goalsClient.searchGoalsByTeamId(currentTeamId, {activityId:selectedActivityId, userId});
                let item = fetchedData[0]||{};
                if(item.activityId === selectedActivityId){
                    item.value = formatMeasureValueForDisplaying(scoreType, item.value||"");
                    if(item.startingMeasure){
                      item.startingMeasure = formatMeasureValueForDisplaying(scoreType, item.startingMeasure);
                    }
                    if(item.currentMeasure){
                      item.currentMeasure = formatMeasureValueForDisplaying(scoreType, item.currentMeasure);
                    }
                    if(item.startingMeasureDate){
                      item.startingMeasureDate = moment(item.startingMeasureDate);                        
                    }
                    if(item.currentMeasureDate){
                      item.currentMeasureDate = moment(item.currentMeasureDate);               
                    }
                    existingGoalData = item;    
                }
            }
            catch(e)
            {
                console.log("exception")
                console.log(e)
            }
            this.setState({ isLoading: false, currentStep: STEPS.ADD_GOAL, selectedActivity,
                id: existingGoalData.id,
                value: existingGoalData.value,
                startingMeasure: existingGoalData.startingMeasure,
                currentMeasure: existingGoalData.currentMeasure,
                currentMeasureDate: existingGoalData.currentMeasureDate,
                startingMeasureDate: existingGoalData.startingMeasureDate
             });
        }
        else {
            scoreType = (selectedActivity.scoreType||"").replace("_","");
            let {newFormat: formattedGoalValue} = formatMeasureValueForSaving(scoreType, value);
            let {newFormat: formattedCurrentMesaure} = formatMeasureValueForSaving(scoreType, currentMeasure);
            let {newFormat: formattedStartingMesaure} = formatMeasureValueForSaving(scoreType, startingMeasure);
            save(selectedActivity.id, formattedGoalValue, formattedCurrentMesaure, currentMeasureDate, formattedStartingMesaure, id, startingMeasureDate);
        }
    }

    handleBack = () => {
        this.setState({ currentStep: STEPS.ADD_ACTIVITY, selectedActivity: null });
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    toggleCalendar = () => {
        let { isDateSelectorOpen } = this.state;
        this.setState({
            isDateSelectorOpen: !isDateSelectorOpen
        })
    }

    render() {
        const { closePopup, classes, currentTeam, libraries } = this.props
        const { searchTerm, filteredActivities, isLoading, selectedActivityId, activeQuickFilter, currentPage, totalPages, loadingChild, currentStep, selectedActivity,
            startingMeasure, currentMeasureDate,  startingMeasureDate, currentMeasure, value, id, validationData, loadingMessage, disableStartingMeasure} = this.state;
        const teamLogo = getTeamLogo(currentTeam)
        const scoreType = ((selectedActivity||{}).scoreType||"").toLowerCase().replace("_","");
        let measureTypeHelperTxt = "";
        if(scoreType != "mph" && scoreType != "count"){
            measureTypeHelperTxt = ` (${getScoreTypeHelperTxt(scoreType)})`;
        }
        const goalHelperTxt = getResultDescriptionTxt(true, scoreType, true) + measureTypeHelperTxt;
        const measureHelperTxt = getResultDescriptionTxt(false, scoreType, true) + measureTypeHelperTxt;       
        const isCurrentMeasureInvalid = currentMeasure && currentMeasureDate && moment(currentMeasureDate).isAfter(moment(), 'day')    
        const isStartingMeasureInvalid = startingMeasure && startingMeasureDate && currentMeasureDate && moment(startingMeasureDate).isAfter(moment(currentMeasureDate), 'day')    
        const isSaveDisabled = !value || isCurrentMeasureInvalid || isStartingMeasureInvalid || Object.values(validationData).some(item => item === false);
        return (
            <ModalContainer
                customPadding={"30px"}
                heading={`Add a Goal`}
                open={true}
                width={"630px"}
                onClose={closePopup}
                useDefaultLeftBtn={true}
                useDefaultRightBtn={true}
                defaultLeftBtnProps={{ onClick: closePopup }}
                defaultRightBtnProps={{
                    disabled: currentStep == STEPS.ADD_GOAL ? isSaveDisabled : !selectedActivityId,
                    onClick: () => this.handleNext(),
                    text: currentStep == STEPS.ADD_GOAL ? (id ? "Update Goal" :"Add Goal") : "Next",
                    iconName: currentStep == STEPS.ADD_GOAL && !id ? "Plus" : null
                }}
                additionalTopSection={
                    <div className={classes.topSectionAdditionalContent}>
                        {currentStep == STEPS.ADD_GOAL ?
                            <div className={classnames(classes.selectedActivityContainer, "d-flex justify-content-start align-items-center")}>
                                <div className={classnames(classes.selectedActivityImgContainer, "d-flex justify-content-center align-items-center")}>
                                    <img src={(selectedActivity.media || {}).thumbnail || teamLogo} />
                                </div>
                                <div className={classes.titleContainer}>
                                    <div className={classnames(classes.activityTitle, classes.activityTitleLmt)}>{selectedActivity.title}</div>
                                    <div className={classes.goalType}>GOAL TYPE: {scoreTypesMap[scoreType]}</div>
                                </div>
                                {!id && <div className={classes.delIcon} onClick={this.handleBack}>
                                    <DeleteIcon />
                                </div>}
                            </div> :
                            <React.Fragment>
                                <div className={classes.subHeadTxt}>
                                    Search and select activity to add a goal
                                </div>
                                <SearchInput placeholder={"Search Activity"} customClass={classnames(classes.searchBox, { [classes.inputDarkBorder]: !!searchTerm })} searchTerm={searchTerm} handleSearch={this.handleSearch} />
                                <div className={classnames(classes.activityFilters, "d-flex align-items-center flex-wrap")}>
                                    {libraries.map(category => <div onClick={() => this.selectQuickFilter(category.value)} className={classnames(classes.filterSelect, { [classes.filterSelected]: category.value == activeQuickFilter })}>{category.name}</div>
                                    )}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                }
            >
                <div className={classes.marketplaceModalPaper}>
                    {isLoading ?
                        <div className={classes.activityCardContainer}>
                            <div className={"contentLoadingOverlay"}>
                                <CircularProgress />
                                <h2>{loadingMessage}</h2>
                            </div>
                        </div>
                        :
                        <div className={classes.activityCardContainer} ref={(ref) => this.scrollParentRef = ref}>
                            {loadingChild &&
                                <div className={classnames("contentLoadingOverlay", classes.loadingChild)}>
                                    <CircularProgress />
                                </div>
                            }
                            {currentStep == STEPS.ADD_GOAL ?
                                <div className={classnames(classes.goalInputs, "goalInputsContainer")}>
                                    <div className={classes.input}>
                                        <div className={classes.inputField}>Goal</div>
                                        <InputField error={validationData.value != null && !validationData.value} value={value} label={goalHelperTxt} onBlur={()=>this.validate(value, 'value')} onChange={value => this.handleChange('value', value)} />                                      
                                    </div>
                                    <div className={classes.input}>
                                        <div className={classes.inputField}>Latest Measure</div>
                                        <InputField  error={validationData.currentMeasure != null && !validationData.currentMeasure} value={currentMeasure} label={measureHelperTxt} onBlur={()=>this.validate(currentMeasure, 'currentMeasure')} onChange={value => this.handleChange('currentMeasure', value)} />
                                    </div>

                                    <DatePicker 
                                    disabled={currentMeasure == null}
                                    selected={currentMeasureDate} 
                                    onChange={date => this.handleChange('currentMeasureDate', date)}
                                        popperPlacement="top-start"
                                        customInput={<CalendarInput disabled={currentMeasure == null} error = {isCurrentMeasureInvalid} classes={classes} label={"Latest Measure Date"}/>}
                                        popperModifiers={{
                                            offset: {
                                              enabled: true,
                                              offset: "160px, 0px"
                                            },
                                            flip: {
                                                enabled: false
                                              },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false
                                            }                                       
                                          }}
                                    />
                                    {isCurrentMeasureInvalid && <div className={classes.helperTxt}>Latest measure date cannot be greater than today's date</div>}
                                    <div className={classes.input}>
                                        <div className={classes.inputField}>Starting Measure</div>
                                        <InputField disabled={disableStartingMeasure} error={validationData.startingMeasure != null && !validationData.startingMeasure} value={startingMeasure} label={measureHelperTxt} onBlur={()=>this.validate(startingMeasure, 'startingMeasure')} onChange={value => this.handleChange('startingMeasure', value)} />
                                    </div>
                                    <DatePicker 
                                    disabled={startingMeasure == null}
                                    selected={startingMeasureDate} 
                                    onChange={date => this.handleChange('startingMeasureDate', date)}
                                        popperPlacement="top-start"
                                        customInput={<CalendarInput disabled={startingMeasure == null} error = {isStartingMeasureInvalid} classes={classes} label={"Starting Measure Date"}/>}
                                        popperModifiers={{
                                            offset: {
                                              enabled: true,
                                              offset: "160px, 0px"
                                            },
                                            flip: {
                                                enabled: false
                                              },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false
                                            }                                       
                                          }}
                                    />
                                    {isStartingMeasureInvalid && <div className={classes.helperTxt}>Starting measure date cannot be greater than Current measure date</div>}
                                </div> :
                                <InfiniteScroll
                                    pageStart={currentPage}
                                    loadMore={() => this.getActivities(currentPage + 1)}
                                    hasMore={currentPage < totalPages}
                                    useWindow={false}
                                    getScrollParent={() => this.scrollParentRef}
                                    loader={<div className={classes.fetchingMore}>Loading ...</div>}>
                                    {filteredActivities.map(activity => {
                                        return (
                                            <div onClick={() => this.select(activity)} key={activity.id} className={classnames(classes.activityCard, { 'selectedTeamSquare': selectedActivityId == activity.id })}>
                                                <div className={"d-flex align-items-center h-100"}>
                                                    {selectedActivityId == activity.id ? <div className={classnames(classes.activityImage, classes.checkMarkFilledContainer)}><CheckMarkIcon /></div> :
                                                        <img className={classes.activityImage} src={(activity.media || {}).thumbnail || teamLogo} />}
                                                    <div className={classes.activityName}>
                                                        <div className={classes.activityTitleLmt}>{activity.title}</div>
                                                        <div className={classes.goalType}>GOAL TYPE: {activity.scoreType.replace("_"," ")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </InfiniteScroll>
                            }
                        </div>}
                </div>
            </ModalContainer>
        )
    }
}

const CalendarInput = ({ value, onClick, classes, error, disabled, label }) => (
    <div className={classes.input}>
        <div className={classes.inputField}>{label}</div>
        <div onClick={onClick} className={"position-relative"}>
            <InputField disabled={disabled} value={value} label={'Select a date'} error={error}/>
        </div>
    </div>
);

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = (userContext.appContextList || []).find(c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        appContext: appContext,
        libraries: appContext.libraries || []
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AddGoalModal));