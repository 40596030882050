import React, { Component } from 'react'

import StyledButton from '../../../../components/styledButton'

import MessageList from '../../components/messageList'
import ViewingMessage from '../../components/viewingMessage'
import EmptyListState from '../../components/emptyListState'
import { getActivity } from '../../../../services/activitiesService'

import {
    resolveMessageString,
    resolveMessageRecipients
} from '../../../../utils/messageUtils.js'
import { getLocalizedString } from '../../../../utils/locale-utils'
import './styles.css'

export default class ScheduledScreen extends Component{
    constructor(props){
        super(props)
        this.state = {
            viewingMessage: null,
            viewingMessageString: '',
            viewingMessageRecipients: []
        }
    }
    getActivity(activityId){
        return API.get("activities", `/activities/${activityId}`,{
            queryStringParameters: {
                includeTaggingData:true
          }});
    }
    handleMessageSelect = async message => {
        const string = await resolveMessageString(message.message)
        const allUsers = this.props.allUsers;
        const recipients = await resolveMessageRecipients(message.recipients, message.recipientIdAndRoleList, allUsers)
        if(message.activityId || (message.meta && message.meta.activityId)){
          try{
            const activityId = (message.meta && message.meta.activityId) ? message.meta.activityId : message.activityId;
            const activity = await getActivity(activityId)
            if(!message.meta){
              message.meta = {};
            }
            if(activity.name) message.meta.activityName = activity.name
          }
          catch(e){
            console.log('error ', e)
          }
        }
        this.setState({
            viewingMessage: message,
            viewingMessageString: string,
            viewingMessageRecipients: recipients
        })
    }

    deleteMessage = () => {
        const { viewingMessage } = this.state
        const { handleDeleteMessage } = this.props
        handleDeleteMessage(viewingMessage)
        this.setState({ viewingMessage: null })
    }

    render(){
        const {
            messages = [],
            onPressCompose,
            handleEditMessage,
        } = this.props

        const {
            viewingMessage,
            viewingMessageString,
            viewingMessageRecipients
        } = this.state

        return(
            <div className={'scheduledScreenOuterContainer'}>
                <div className={'scheduledTitleContainer'}>
                    <p className={'titleText'}>{getLocalizedString("SCHEDULED")}</p>
                </div>
                {messages.length ?
                    <div className={'scheduledScreenInnerContainer'}>
                        <MessageList
                            scheduled
                            messages={messages}
                            viewingMessage={viewingMessage}
                            onClickMessage={message => this.handleMessageSelect(message)}
                        />
                        <ViewingMessage
                            scheduled
                            message={viewingMessage}
                            string={viewingMessageString}
                            recipients={viewingMessageRecipients}
                            editMessage={m => handleEditMessage(m)}
                            deleteMessage={() => this.deleteMessage()}
                            emptyBody={'Select a scheduled message to get started'}
                        />
                    </div>
                :
                    <EmptyListState
                        title={'No Scheduled Messages'}
                        onPressCompose={onPressCompose}
                        body={'When you have scheduled messages you will be able to find them here. Get started by composing a new message.'}
                    />
                }
            </div>
        )
    }
}
