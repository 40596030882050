import React, { Component } from "react";
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { Auth, API } from "aws-amplify";
import { WhiteLabelUserClient, WhiteLabelAuthenticationClient  } from 'maxone-api-client';
import { getEnvironment, getApiUrl, getRegion } from '../../utils/environmentUtils'

import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { analyticsService, currentTimestamp } from "../../services/analyticsService";

import _ from "lodash"
import { createUser } from '../../actions/user';
import { setUserContext, setAppContext, setNewCoachSession } from '../../actions/session';
import ContextService from '../../services/context';
import "./signup.css";

import MetaTags from 'react-meta-tags';
import PageWrapper from '../nav/pageWrapper';

import {
    registerUser,
    createConvo,
    createConvoLink,
    updateConvoLink
} from '../chat/chat/graphql/mutations'

import { getThemeByTenant } from '../../themes';
import { SPORTS, SIZES } from '../../constants'
import PasswordInput from '../../components/passwordInput';
const theme = getThemeByTenant();
const logo = theme.image.loginLogo;

const ENV = getEnvironment();
const REGION = getRegion();
const API_URL = getApiUrl(ENV, REGION);
import { userClient } from '../../clients';


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    menu: {
        width: 500,
        padding: 50
    },
    modal: {
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 500
    },
    button: {
        marginRight: 8,
    },
    instructions: {
        marginTop: 8,
        marginBottom: 8,
    },
    root: {
        flexGrow: 1,
        margin: 20
    },
    header: {
        fontWeight: 100,
        fontSize: 45,
        marginBottom: '-15px'
    },
    subHeader: {
        fontWeight: 100,
    },
    image: {
        width: 100,
        marginBottom: '-35px'
    },
    textField: {
        flexGrow: 1,
        width: '80%'
    },
    selectField: {
        flexGrow: 1,
        width: '80%',
        textAlign: 'left'
    }
});

const types = ['Club', 'School', 'Trainer', 'League', 'Other'];
const atHomeWorkoutProgram = {
    maxone: {
        Softball: 'AXFA58e1ne', Soccer: 'KTsnYBR37Q', Football: 'bKihrsHPvy', Volleyball: 'mJncvt2IBP', Basketball: 'nLH0Fh7gIF', Baseball: 'pRIyxiMkIm'
    },
    spellman: 'YmzkbUibqGAqeUt7YddEXQ',
    aoc: {
        Volleyball: 'nt8nwfzzgw73VkRu2gyYUd'
    }
}

const convoList = {};

class CoachSignup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nameFirst: "",
            nameLast: "",
            email: "",
            username: "",
            phoneNumber: "",
            password: "",
            errorMessage: "",
            showSecondStep: false,
            newUser: null,
            organizationType: "Club",
            organizationName: "",
            programSport: "",
            organizationSize: "",
            programName: "",
            confirmationCode: "",
            showConfirmation: false,
            activeStep: 0,
            skipped: new Set(),
            isLoading: false
        }
    }

    componentDidMount = async () => {
        let { tenant } = this.props;
        this.setState({programSport: tenant === 'aoc' ? 'Volleyball' : '' })
    }

    validateCoachForm() {
        let isValidEmail = this.state.email.toString().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        return (
            this.state.nameFirst.length > 0 &&
            this.state.nameLast.length > 0 &&
            this.state.email.length > 0 && isValidEmail !== null &&
            this.state.username.length > 0 &&
            this.state.phoneNumber.trim().length > 0 && this.state.phoneNumber.replace(/-/g, '') &&
            this.state.password.length > 0
        )
    }

    validateProgramForm() {
        return (
            this.state.organizationType.length > 0 &&
            this.state.organizationName.length > 0 &&
            this.state.programSport.length > 0
        )
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }
    getSteps() {
        return ['Coach', 'Organization & Team', 'Finish'];
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return this.renderFirstStep();
            case 1:
                return this.renderSecondStep();
            case 2:
                return this.renderFinal();
            default:
                return 'Unknown step';
        }
    }

    isStepOptional = step => {
        return false;
    };

    handleNext = () => {
        const { activeStep } = this.state;
        if (activeStep === 0) {
            this.handleSubmit()
        }
        else if (activeStep === 1) {
            this.handleProgramSubmit()
        }
        else if (activeStep === 2) {
            this.completeAll()
        }
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    handleCancel = () => {
        // event.preventDefault();
        this.setState({ isLoading: true });
        this.props.history.push("/signup");
    };

    handleSkip = () => {
        const { activeStep } = this.state;
        if (!this.isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        this.setState(state => {
            const skipped = new Set(state.skipped.values());
            skipped.add(activeStep);
            return {
                activeStep: state.activeStep + 1,
                skipped,
            };
        });
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    isStepSkipped(step) {
        return this.state.skipped.has(step);
    }

    async createUser(user) {
        var data = await API.post("users", "/users", {
            body: user
        }).then(data => { return data });
        return data
    }

    async getUserByUsername(username) {
        var data = await API.get("users", `/users/username/${username}`).then(data => { return data });
        return data
    }

    async createProgram(program) {

        var data = await API.post("programs", "/programs", {
            body: program
        }).then(data => { return data });
        return data
    }

    async createSchedule(program, schedule) {
        var data = await API.post("schedules", `/teams/${program.id}/schedules`, {
            body: schedule
        }).then(data => { return data });
        return data
    }

    async createOrganization(organization) {
        var data = await API.post("organizations", "/organizations", {
            body: organization
        }).then(data => { return data });
        return data;
    }

    async createRole(role) {
        var data = await API.post("users", `/users/${role.userId}/roles`, {
            body: role
        }).then(data => { return data });
        return data;
    }

    async createCognitoUser(user) {
        var data = await API.post("admin", `/admin/users/create`, {
            body: user
        }).then(data => { return data });
        return data;
    }

    handleChange = name => event => {
        let value = event.target.value
        if (name === 'phoneNumber') {
            if (value.length === 4 && value.substr(3, 1) !== "-") {
                value = `${value.substr(0, 3)}-${value.substr(3, 1)}`
            } else if (value.length === 8 && value.substr(7, 1) !== "-") {
                value = `${value.substr(0, 7)}-${value.substr(7, 1)}`
            }
        }
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = async () => {
        const { activeStep, username, phoneNumber, email, nameFirst, nameLast, password } = this.state;
        const { tenant } = this.props;
        this.setState({ isLoading: true });
        
        try {

            // check if username exists yet, if no then continue.
            let usernameExists = await this.getUserByUsername(username)
            console.log('usernameExists ', usernameExists)
            if (usernameExists && usernameExists.length){
              alert(`Username already exists.  Please choose a new username.`)
              this.setState({ isLoading: false });
              return
            }
            if(tenant !== "kickid"){
              var user = {
                  username: this.state.username,
                  phoneNumber: phoneNumber.trim().replace(/-/g, ''),
                  email: this.state.email,
                  nameFirst: this.state.nameFirst,
                  nameLast: this.state.nameLast,
                  tenant: tenant
              }

              // create a user record with JSON
              var cognitoUser = await this.createCognitoUser({...user, password: password})
              console.log('cognitoUser ', cognitoUser)
              var newUser = await this.createUser(user)
              console.log('newUser ', newUser)
              user.tenant = tenant;
              user.id = newUser.id;
              this.setState({
                  newUser:user,
                  activeStep: activeStep + 1,
              });
            }
            else{
              var user = {
                  username,
                  phoneNumber: phoneNumber.trim().replace(/-/g, ''),
                  email,
                  nameFirst,
                  nameLast,
                  tenant: tenant
              }
              // create a user record with JSON
              var { data, status } = await userClient.createUser(tenant, nameFirst, nameLast, email, phoneNumber, null, password, username, "01-21-1994", null)
              console.log('data ', data)
              console.log('status ', status)
              if (!!data) {
                  user.tenant = tenant;
                  user.id = data.id;
                  console.log('new user = ', user)
                this.setState({
                    newUser:user,
                    activeStep: activeStep + 1,
                });
              }
            }

        } catch (e) {
          console.log('e ', e)
          if(e === "No current user"){
            alert(`You must be logged in as an Admin to create users.   Please log in at ${API_URL}/login before proceeding.`)
          }
        }

        this.setState({ isLoading: false });
    }

    async completeAll() {
        try {
            // var signing = await Auth.signIn(this.state.username, this.state.password);
            // await this.setSessionInfo(this.state.username);
            await this.impersonate(this.state.newUser)
            this.props.history.push("/")
            // authenticate then send 'em home
            // this.props.userHasAuthenticated(true);
            // this.props.history.push("/")
            // if (this.state.tenant === 'spellman')
            //     this.props.setNewCoachSession(true)
        }
        catch (e) {
            console.log('e ', e)
        }
    }

    setSessionInfo = async (username) => {
        const contextService = new ContextService();
        const { userContext, appContext } = await contextService.buildUserContext(username);

        this.props.setUserContext(userContext);
        this.props.setAppContext(appContext);
    }

    async impersonate(user) {
      let { tenant } = this.props;
      this.setState({isLoading: true, loadingMessage:`Impersonating ${user.username}.... `})
      var username = user.username;
      try{
        var context = await Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken
            },
            body:{
              username: username,
              tenant: tenant,
              platform: "web"
            }
          }
          return API.post("auth", `/auth/userContext`, options);
        })
        this.props.setUserContext(context.userContext);
        this.props.setAppContext(context.appContext);
        this.setState({isLoading:false})
      }
      catch(e){
        console.log('Error ==>', e)
        this.setState({isLoading:false})
      }
    }

    handleProgramSubmit = async () => {
        let { tenant } = this.props;
        let { newUser, activeStep } = this.state;
        this.setState({
            isLoading: true,
            activeStep: activeStep + 1,
        });

        try {
            // build org JSON
            const organizationJSON = {
                type: this.state.organizationType,
                name: this.state.organizationName,
                tenant: tenant,
                tenantId: tenant,
                size: this.state.organizationSize
            }
            // await Auth.signIn('anonymous', 'Anonymous1').then(signIn => { }).catch(err => console.log("error", err));

            // create new organization row
            // before program and role
            const organization = await this.createOrganization(organizationJSON)
            //
            // build program JSON
            const programJSON = {
                name: this.state.organizationName,
                sport: this.state.programSport,
                organizationId: organization.id,
                tenant: tenant,
                tenantId: tenant
            }

            // create new program row
            const program = await this.createProgram(programJSON)
            const requestPromises = [];
            if (atHomeWorkoutProgram[tenant]) {
                console.log(atHomeWorkoutProgram[tenant][program.sport])
                if (['spellman'].indexOf(tenant) > -1) {
                    const product = await this.getProduct(atHomeWorkoutProgram[tenant])
                    if(product)requestPromises.push(this.copyProduct(product, program))
                }
                else if (atHomeWorkoutProgram[tenant][program.sport]) {
                    const product = await this.getProduct(atHomeWorkoutProgram[tenant][program.sport])
                    if(product) requestPromises.push(this.copyProduct(product, program))

                }
            }

            // build role JSON

            const scheduleJSON = {
                name: program.customName,
                visibility: 'everyone',
                color: '#dce775',
            }

            requestPromises.push(this.createSchedule(program, scheduleJSON));

            const orgRoleJSON = {
                parentId: organization.id,
                userId: newUser.id,
                role: "owner"
            }

            const programRoleJSON = {
                parentId: program.id,
                userId: newUser.id,
                role: "coach"
            }

            // create new role row
            requestPromises.push(this.createRole(orgRoleJSON))
            requestPromises.push(this.createRole(programRoleJSON))
            requestPromises.push(this.sendNewAccountCreationEmail(tenant, newUser, program, organization));
            await Promise.all(requestPromises);
            // create default conversations
            // await this.launchNewGroupConversation({ name: `All Coaches` }, [request], program.id)
            // await this.launchNewGroupConversation({ name: `${program.name} ${program.sport}` }, [request], program.id)

            var trackingParam = {
                id: newUser.id,
                first_name: newUser.nameFirst,
                last_name: newUser.nameLast,
                email: this.state.email,
                username: newUser.username,
                program: tenant,
                user_type: "coach",
                tenant: tenant,
                estimated_athletes: this.state.organizationSize
            };
            var orgTrackingParam = {
                id: organization.id,
                name: organization.name,
                tenant: tenant,
                size: this.state.organizationSize
            };
            console.log('trackingParam ', trackingParam)
            analyticsService.initWithUser(trackingParam)
            analyticsService.trackEvent("Account Created", trackingParam);
            analyticsService.trackEvent("Organization Created", orgTrackingParam);


        } catch (e) {
          console.log('Error => ')
          console.log(e)
            // alert(e.message);
            this.setState({ isLoading: false });
        }
        // finally{
        //     await Auth.signOut().then(signOut => { })
        //     .catch(err => console.log("error", err));
        // }

        this.setState({ isLoading: false });
    }

    sendNewAccountCreationEmail = async (tenant, user, program, organization) => {
        return API.post("messages", `/accountCreation/email`, {
            body: {
                tenant,
                user,
                program,
                organization
            }
        })
    }

    // NEW CONVERSATION LOGIC START
    launchNewGroupConversation = (group, chipData, programId) => {
        let resolveFn
        const promise = new Promise((resolve, reject) => {
            resolveFn = resolve
        })
        const me = this.props.data ? this.props.data.getUser : {};
        const chatName = group.name;
        this.props.createConvo({
            variables: {
                input: { name: chatName ? chatName : "", teamId: programId ? programId : "" }
            },
            optimisticResponse: {
                createConvo: {
                    id: '-1',
                    name: chatName,
                    createdAt: '',
                    teamId: programId,
                    __typename: 'Conversation',
                    associated: {
                        __typename: 'ModelConvoLinkConnection',
                        items: []
                    }
                }
            },
            update: async (proxy, { data: { createConvo } }) => {
                if (createConvo.id === '-1' || convoList[`${createConvo.id}`]) return;

                convoList[`${createConvo.id}`] = true;
                const links = await Promise.all(chipData.map(async user => {
                    return this.linkNewConversation(createConvo.id, user.id, chatName, programId);
                }))

                const promises = links.map(c => this.updateToReadyConversation(c, chatName, programId));
                const convoLinks = await Promise.all(promises);

                resolveFn({
                    conversation: convoLinks[0].conversation,
                    conversationName: chatName
                })
            }
        })
        return promise
    }

    getProduct = async (productId) => {
      try{
        let products = await API.get("products", `/products/${productId}`)
        return products;
      }
      catch(e){
        return null
      }
    }

    copyProduct(product, program) {
        return API.post("products", `/products/copynew`, {
            body: {
                product: product,
                teamIds: [program.id],
                avoidCreatingTag: true
            }
        })
    }

    linkNewConversation = (convoId, userId, chatName, programId) => {
        let resolveFn
        const promise = new Promise((resolve, reject) => {
            resolveFn = resolve
        })
        createConvoLink({
            variables: { convoId, userId, name: chatName },
            optimisticResponse: {
                createConvoLink: {
                    __typename: 'ConvoLink',
                    id: '-1',
                    status: 'PENDING',
                    name: chatName,
                    conversation: {
                        __typename: 'Conversation',
                        id: convoId,
                        name: chatName,
                        teamId: programId,
                        createdAt: '',
                        associated: {
                            __typename: 'ModelConvoLinkConnection',
                            items: []
                        }
                    }
                }
            },
            update: async (proxy, { data: { createConvoLink } }) => {
                if (createConvoLink.id === '-1') {
                    return
                }
                resolveFn(createConvoLink)
            }
        })
        return promise
    }

    updateToReadyConversation = (convoLink, chatName, programId) => {
        let resolveFn
        const promise = new Promise((resolve, reject) => {
            resolveFn = resolve
        })

        this.props.updateConvoLink({
            variables: { id: convoLink.id },
            optimisticResponse: {
                updateConvoLink: {
                    __typename: 'ConvoLink',
                    id: convoLink.id,
                    name: chatName,
                    convoLinkUserId: '-1',
                    status: 'CONFIRMING',
                    conversation: {
                        __typename: 'Conversation',
                        id: convoLink.conversation.id,
                        name: chatName,
                        teamId: programId,
                        createdAt: '',
                        associated: {
                            __typename: 'ModelConvoLinkConnection',
                            items: []
                        }
                    }
                }
            },
            update: async (proxy, { data: { updateConvoLink } }) => {
                if (updateConvoLink.status === 'READY') {
                    resolveFn(updateConvoLink)
                }
            }
        })
        return promise
    }
    // NEW CONVERSATION LOGIC END

    renderFinal() {
        const { classes } = this.props;
        const { isLoading } = this.state;

        return (
            <Paper className={"paperForm"} style={{ overflow: "hidden", minHeight: 650, boxShadow: 'none', borderRadius: 0, paddingTop: 0 }}>
                <form onSubmit={this.handleConfirmationSubmit}>
                    <MetaTags>
                        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                        <meta http-equiv="Pragma" content="no-cache" />
                        <meta http-equiv="Expires" content="0" />
                    </MetaTags>
                    <Grid container spacing={8} justify="center" style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <Grid item xs={12}>
                            <img className={classes.image} src={logo} alt="logo" />
                            <h1 className={classes.header}>COMPLETE</h1>
                            <h4 className={classes.subHeader}>{`New coach and organization has been created.`}</h4>
                        </Grid>
                        <Grid item xs={8}>
                            {isLoading ? <CircularProgress />
                                : <Button
                                    variant="contained"
                                    onClick={this.handleNext}
                                    className={classes.button}
                                    color="primary"
                                >
                                    Impersonate User
                      </Button>}
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        );
    }

    renderFirstStep() {
        const { classes } = this.props;

        return (
            <Paper className={"paperForm"} style={{ overflow: "hidden", boxShadow: 'none', borderRadius: 0, paddingTop: 0 }}>
                <Grid item xs={12}>
                    <img className={classes.image} src={logo} alt="logo" />
                    <h1 className={classes.header}>Coach Signup</h1>
                </Grid>
                <form onSubmit={this.handleSubmit}>
                    {
                        this.state.errorMessage !== "" ? <Typography style={{ color: "red" }}>{this.state.errorMessage}</Typography> : null
                    }
                    <Grid container justify="center" spacing={8}>
                        <Grid item xs={8}>
                            <TextField
                                id="nameFirst"
                                label="First name"
                                className={classes.textField}
                                value={this.state.nameFirst}
                                onChange={this.handleChange("nameFirst")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="nameLast"
                                label="Last name"
                                className={classes.textField}
                                value={this.state.nameLast}
                                onChange={this.handleChange("nameLast")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="username"
                                label="Username"
                                className={classes.textField}
                                value={this.state.username}
                                onChange={this.handleChange("username")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="email"
                                label="Email"
                                type="email"
                                className={classes.textField}
                                value={this.state.email}
                                onChange={this.handleChange("email")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="phoneNumber"
                                label="Cell Phone Number"
                                className={classes.textField}
                                value={this.state.phoneNumber}
                                onChange={this.handleChange("phoneNumber")}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <PasswordInput
                                id="password"
                                label="Password"
                                className={classes.textField}
                                value={this.state.password}
                                onChange={this.handleChange("password")}
                                type="password"
                                helperText={'Password must be a minimum of 8 characters.'}
                                autoComplete="password"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={8} style={{ marginBottom: 25 }}>
                            <Button
                                variant="contained"
                                onClick={this.handleCancel}
                                className={classes.button}
                            >Cancel</Button>
                            <Button
                                variant="contained"
                                onClick={this.handleNext}
                                className={classes.button}
                                color="primary"
                                disabled={!this.validateCoachForm()}
                            >Next</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }

    renderSecondStep() {
        const { classes, tenant } = this.props;
        const { organizationType } = this.state;
        let nameLabel = `What is the name of your ${organizationType !== "" ? organizationType.toLowerCase() : "club"}?`;

        return (
            <Paper className={"paperForm"} style={{ overflow: "hidden", minHeight: 650, boxShadow: 'none', borderRadius: 0, paddingTop: 0 }} >
                <Grid item xs={12}>
                    <h3 variant="headline" component="h3">Where do you coach?</h3>
                </Grid>
                <form onSubmit={this.handleProgramSubmit}>
                    {
                        this.state.errorMessage !== ""
                            ?
                            <Typography style={{ color: "red" }}>{this.state.errorMessage}</Typography>
                            :
                            null
                    }
                    <Grid container justify="center" spacing={8}>
                        <Grid item xs={8}>
                            <FormControl className={classes.selectField}>
                                <InputLabel htmlFor="organizationType">What type of organization is this?</InputLabel>
                                <Select
                                    value={this.state.organizationType}
                                    onChange={this.handleChange("organizationType")}
                                    input={<Input id="organizationType" />}
                                >
                                    {types.map(type => (
                                        <MenuItem
                                            key={type}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {tenant !== 'aoc' ? <Grid item xs={8}>
                            <FormControl className={classes.selectField}>
                                <InputLabel htmlFor="programSport">Size of the Organization</InputLabel>
                                <Select
                                    value={this.state.organizationSize}
                                    onChange={this.handleChange("organizationSize")}
                                    input={<Input id="organizationSize" />}
                                >
                                    {SIZES.map(size => (
                                        <MenuItem
                                            key={size.id}
                                            value={size.id}
                                        >
                                            {size.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> : null}
                        <Grid item xs={8}>
                            <TextField
                                id="organizationName"
                                label={nameLabel}
                                className={classes.textField}
                                value={this.state.organizationName}
                                onChange={this.handleChange("organizationName")}
                                margin="normal"
                            />
                        </Grid>
                        {tenant !== 'aoc' ? <Grid item xs={8}>
                            <FormControl className={classes.selectField}>
                                <InputLabel htmlFor="programSport">What sport do you coach?</InputLabel>
                                <Select
                                    value={this.state.programSport}
                                    onChange={this.handleChange("programSport")}
                                    input={<Input id="programSport" />}
                                >
                                    {SPORTS.map(sport => (
                                        <MenuItem
                                            key={sport}
                                            value={sport}
                                        >
                                            {sport}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> : null}
                        <Grid item xs={8} style={{ marginBottom: 20 }}>
                            <Button
                                variant="contained"
                                onClick={this.handleBack}
                                className={classes.button}
                            >
                                Back
                                            </Button>
                            <Button
                                variant="contained"
                                onClick={this.handleNext}
                                className={classes.button}
                                color="primary"
                                disabled={!this.validateProgramForm()}
                            >
                                Next
                                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }

    render() {
        const { classes } = this.props;
        const { showSecondStep } = this.state;
        const steps = this.getSteps();
        const { activeStep } = this.state;
        return (
            <PageWrapper title={""} {...this.props}>

                    <Grid container spacing={24} direction="row" justify="flex-start" alignItems="center" style={{ paddingTop: 15, marginLeft: 15, marginRight: 15 }}>
                        <Hidden smUp>
                            <Grid item xs={12} style={{ marginTop: 60 }}>_</Grid>
                        </Hidden>
                        <Grid item xs={12} style={{marginRight: 20}}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const props = {};
                                    const labelProps = {};

                                    return (
                                        <Step key={label} {...props}>
                                            <StepLabel {...labelProps}>
                                                <Hidden only={'xs'}>{label.toString()}</Hidden>
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                            {this.getStepContent(activeStep)}
                        </Grid>
                    </Grid>

            </PageWrapper>
        )
    }
}

CoachSignup.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        default: state.default,
        user: state.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    createNewUser: (user) => dispatch(createUser(user)),
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext)),
    setNewCoachSession: (newCoachSession) => dispatch(setNewCoachSession(newCoachSession))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CoachSignup));
