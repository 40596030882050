import React, { Component } from 'react'
import './styles.css'
import IconButton from '../iconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class ListCard extends Component {
    constructor(props){
        super(props)
        this.state = { isDeleting: false }
    }

    toggleDeleteConfirm = () => {
        const { isDeleting } = this.state
        this.setState({ isDeleting: !isDeleting })
    }

    handleEdit = () => {
        const { handleEdit } = this.props
        handleEdit()
    }

    handleDelete = () => {
        const { handleDelete } = this.props
        this.toggleDeleteConfirm()
        handleDelete()
    }

    render(){
        const { isDeleting } = this.state
        const { add, title, loading, subTitle, details, onClick } = this.props
        return(
            <div onClick={onClick ? onClick : null} className={add ? 'listCardOuterContainer addCard' : isDeleting ? 'listCardOuterContainer deletingCardOuterContainer' : 'listCardOuterContainer notDeleting'}>
                {add ? 
                    <div className={'listCardAddContainer'}>
                        <AddIcon className={'icon'} />
                        <p className={'addCardTitle'}>{title}</p>
                    </div>
                :
                loading ?
                    <div className={'contentLoadingOverlay'}>
                        <CircularProgress className={'loadingCircle'} />
                        <p className={'loadingCardTitle'}>{title}</p>
                    </div>
                :
                    <div className={'listCardContentContainer'}>
                        <div className={'listCardTitleContainer'}>
                            <p className={'cardTitle'} title={title}>{title}</p>
                            {!isDeleting && <p className={'cardSubTitle'}>{subTitle}</p>}
                        </div>
                        {isDeleting ? 
                            <div className={'listCardConfirmationContainer'}>
                                <p className={'confirmationText'}>Are you sure?</p>
                                <div className={'confirmationButtonContainer'}>
                                    <IconButton width={'40%'} text={'No'} color={'lightgrey'} textColor={'black'} onClick={this.toggleDeleteConfirm} />
                                    <IconButton width={'40%'} text={'Yes'} color={'red'} textColor={'white'} onClick={this.handleDelete} />
                                </div>
                            </div>
                        :
                            <div className={'listCardBottomContainer'}>
                                <EditIcon onClick={this.handleEdit} className={'editIcon'}/>
                                <p className={'cardDetails'}>{details}</p>
                                <DeleteIcon onClick={this.toggleDeleteConfirm} className={'deleteIcon'}/>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}