import React from 'react';
// import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SingleActivity from '../SingleActivity';
import './index.scss';
import RightPointerIcon from '../../icons/RightPointerIcon';
import NextArrowIcon from '../../icons/NextArrowIcon';
import BackArrowIcon from '../../icons/BackArrowIcon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function ActivityList(props) {
	const {
		listOfActivities: { total, results },
		title = 'Untagged',
		deleteSingleActivity,
		routeId,
		deleteStatus,
		//amit tags
		modalTags,
		editActivitySaveFunction,
		currentActivityToState,
		currentTeamId,
		workoutModalOnChangeFunction,
		addTag,
		removeTag,
		resetActivity,
		currentActivity,
		handleFileSelect,
		handleVideoUrlChange,
		removeActivityVideo,
		handleThumbnailSelect,
		addToDeleteList,
		multiDeleteActive,
		activityViewerOnClick,
		activityScoreOnClick,
		isGoalSetter,
		cancelUpload,
		displaySuccessOverlay,
		uploadingThumbnailFile,
		videoFileUploadProgress,
		thumbnailUploadProgress,
		overlayMessage,
		uploadingVideoFile,
		invalidVideoUrl,
	} = props;
	const settings = {
		arrows: true,
		lazyLoad: true,
		accessibility: true,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 3,
		nextArrow: <NextArrowIcon className='arrow-icon' />,
		prevArrow: <BackArrowIcon className='arrow-icon' />,
		className: 'slick-slider',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<div className='m1-activity-list'>
			<div className='top-bar'>
				<RightPointerIcon className='icon' />
				<h2>
					{title}
					<span>
						{total} {results.length > 1 ? 'activities' : 'activity'}
					</span>
				</h2>
			</div>
			<div className='carousel'>
				<div>
					<Slider {...settings}>
						{results.map((activity) => {
							return (
								<div className='single-activity'>
									<SingleActivity
										activity={activity}
										deleteSingleActivity={deleteSingleActivity}
										multiDeleteActive={multiDeleteActive}
										addToDeleteList={addToDeleteList}
										activityViewerOnClick={activityViewerOnClick}
										activityScoreOnClick={activityScoreOnClick}
										isGoalSetter={isGoalSetter}
										routeId={routeId}
										deleteStatus={deleteStatus}
										//amit props
										currentActivity={currentActivity}
										modalTags={modalTags}
										editActivitySaveFunction={editActivitySaveFunction}
										currentActivityToState={currentActivityToState}
										currentTeamId={currentTeamId}
										workoutModalOnChangeFunction={workoutModalOnChangeFunction}
										addTag={addTag}
										removeTag={removeTag}
										resetActivity={resetActivity}
										handleFileSelect={handleFileSelect}
										handleVideoUrlChange={handleVideoUrlChange}
										removeActivityVideo={removeActivityVideo}
										handleThumbnailSelect={handleThumbnailSelect}
										cancelUpload={cancelUpload}
										displaySuccessOverlay={displaySuccessOverlay}
										uploadingThumbnailFile={uploadingThumbnailFile}
										videoFileUploadProgress={videoFileUploadProgress}
										thumbnailUploadProgress={thumbnailUploadProgress}
										overlayMessage={overlayMessage}
										uploadingVideoFile={uploadingVideoFile}
										invalidVideoUrl={invalidVideoUrl}
									/>
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
		</div>
	);
}

SingleActivity.propTypes = {};

SingleActivity.defaultProps = {};
