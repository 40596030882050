import { API } from 'aws-amplify';
import { messagesClient } from '../clients';


export async function getMessagesWithPagination(entityId, page = 0, size = 20){
    return API.get('messages', `/programs/${entityId}/paginatedMessages?page=${page}&size=${size}`)
}

export async function getDraftMessages(entityId){
    return API.get('messages', `/programs/${entityId}/paginatedMessages?isDraft=true`)
}

export async function getMessages(entityId){
    return API.get('messages', `/programs/${entityId}/messages`)
}

export async function getScheduledMessages(teamId){
    const { data: { results } } = await messagesClient.getScheduledMessagesByTeamId(teamId);
    console.log('results ', results)
    return results;
    // return API.get('scheduledMessages', `/team/${teamId}/scheduled`);
}

export async function sendMessage(message){
    if(message.type === 'email')
        return API.post("messages", `/messages/email`, { body: message })
    else if(message.type === 'sms')
        return API.post('messages', `/messages/sms`, { body: message })
}

export async function deleteMessage(messageId){
    return API.del('messages', `/messages/${messageId}`);
}

export async function deleteScheduledMessage(messageId){
    return API.del('scheduledMessages', `/scheduled/${messageId}`);
}

export async function saveMessage(message){
    return API.post('messages', `/messages/draft`, { body:message })
}

export async function scheduleMessage({ id, activationTime, isDraft, senderId, parentId, type, message, title, tenant, externalUrl, activityId, recipients, matchUserTimezone, selectedTimezone }){
    if (id) {
        await messagesClient.updateScheduledMessageById(id, activationTime, isDraft, senderId, parentId, type, message, title, tenant, externalUrl, activityId, recipients, selectedTimezone, matchUserTimezone);
    } else {
        await messagesClient.createScheduledMessage(activationTime, isDraft, senderId, parentId, type, message, title, tenant, externalUrl, activityId, recipients, selectedTimezone, matchUserTimezone);
    }
    return getScheduledMessages(parentId);
}

export async function sendPushNotification(message){
    return API.post('messages', `/messages/pushNotification`, { body: message })
}
