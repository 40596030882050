import React, { Component } from 'react'
import { connect } from 'react-redux'
import Amplify, {API, Auth} from "aws-amplify"
import ContextService from '../../services/context'
import { logLastLogIn } from '../../utils/log-utils'
import {isMobile, isTablet} from 'react-device-detect'
// import LoadingGif from '../../assets/images/app/loading.gif'
import CircularProgress from '@material-ui/core/CircularProgress';
import { authClient } from '../../clients'
import { setUserContext, setAppContext, setUserAccessToken, refreshAppContext } from '../../actions/session'
import { getEnvironment, getRegion } from '../../utils/environmentUtils'
import { analyticsService, currentTimestamp } from "../../services/analyticsService"
import { getTenant, tenantMap } from '../../utils/commonUtil';
import { ROUTE_PATH } from "../../routeUtil";
import { SE_SESSION_KEY, SERVICE_OAUTH_ERROR } from "../../constants";
import { setAuthenticatedUserById } from '../../actions/user-actions';
import { setCurrentTeamById } from '../../actions/team-actions';

import './styles.css'
const ENV = getEnvironment();
const region = getRegion();


class oAuthLoading extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount = async () => {
        const { history, setUserAccessToken, tenant, refreshAppContext, currentUser, users: { loggedInUserId } } = this.props
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const service = urlParams.get('service');
        let redirectUrl = sessionStorage.getItem("redirectUrl");

        if (service) {
            return;
        }

        try {
            const { data } = await authClient.exchangeOAuthCode(tenant, code, 'web');
            const { access_token } = data;
            const isSE = tenant === tenantMap.se;
            if (isSE) {
                if (access_token) {
                    if (isSE) {
                        sessionStorage.setItem(SE_SESSION_KEY, access_token);
                        setUserAccessToken(access_token);
                    }
                    if(redirectUrl){
                        this.props.history.push(redirectUrl);
                    }
                    else{
                        this.props.history.push(isSE ? ROUTE_PATH.SE_ADMIN : '/');
                    }
                }
                else {
                    alert("Something went wrong");
                    this.props.history.push(isSE ? ROUTE_PATH.SE_ADMIN_LOGIN : '/');
                }
            }
            else {
                const loginData = await authClient.loginByOAuthAccessToken(tenant, access_token)
                let appContext;
                try {
                    await Auth.signIn(loginData.data.user.username, loginData.data.key);
                    appContext = await this.setSessionInfo(loginData.data.user.username, tenant);
                } catch (e) {
                    await Auth.signIn(loginData.data.user.emailAddress, loginData.data.key);
                    appContext = await this.setSessionInfo(loginData.data.user.emailAddress, tenant);
                }
                if (!appContext) {
                    throw new Error(`App context not set`);
                }
                this.props.userHasAuthenticated(true);
                if (appContext.isOwner || appContext.isHeadCoach || appContext.isCoach) {
                    this.props.history.push('/athletes')
                } else if (appContext.isAthlete) {
                    //TODO: What is this doing here?
                    alert()
                } else {
                    this.props.history.push('/')
                }
            }
        }
        catch(e){
            console.log('error signing in => ', e)
            history.push('/login')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const urlParams = new URLSearchParams(window.location.search);
        const service = urlParams.get('service');
        const code = urlParams.get('code');

        const { users: { loggedInUserId } } = this.props;
        const { users: { loggedInUserId: prevLoggedInUserId } } = prevProps;

        if (code && service && !prevLoggedInUserId && loggedInUserId) {
            this.exchangeTokenWithService();
        }
    }

    exchangeTokenWithService = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const service = urlParams.get('service');
        const code = urlParams.get('code');
        const redirectUrl = sessionStorage.getItem('redirectUrl');

        const { history, refreshAppContext, users: { loggedInUserId } } = this.props;

        try {
            await authClient.exchangeOAuthCodeByService(service, code, loggedInUserId, 'web');
            refreshAppContext(); // Is this needed?
        } catch (e) {
            sessionStorage.setItem(SERVICE_OAUTH_ERROR, 'conflict');
        } finally {
            history.push(redirectUrl || '/');
        }
    };

    setSessionInfo = async (username, tenant) => {
        const contextService = new ContextService();
        const {userContext, appContext, setAuthenticatedUserById, setCurrentTeamById} = await contextService.buildUserContext(username);

        this.props.setUserContext(userContext);
        this.props.setAppContext(appContext);

        // Set the authenticated user and current team in Redux
        setAuthenticatedUserById(userContext.user.id);
        setCurrentTeamById(appContext.id);

        if(appContext.isAthlete || isMobile || isTablet) {
            const theme = getThemeByTenant();
            const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
            const appUrl = theme.product.appUrl ? theme.product.appUrl : "m1://"
            const productName = theme.product.name ? theme.product.name : 'MaxOne'
            if(window.confirm(`Open the ${productName} app`)) {
                window.location = appUrl;
                return null;
            }
        }
        const { user, roles, appContextList } = userContext
        logLastLogIn(user, tenant);
        const currentTeam = _.find(appContextList, c => appContext && c && c.id === appContext.id) || {};
        if (user && roles && roles[0]) {
            let userTraits = await analyticsService.getUserTraits(user, roles, currentTeam, appContext, userContext);
            analyticsService.initWithUser(userTraits);
            analyticsService.trackEvent("Signed In")
        }
        return appContext
    }

    render(){
        return(
            <div className={'oAuthLoadingScreenContainer'}>
                <CircularProgress />
                <p>Hang tight, signing you in...</p>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { session: { userContext, appContext }, tenant:{ id }, users } = state;
    return {
        tenant: id,
        userContext,
        currentUser: (userContext||{}).user||{},
        users
    }
}

const mapDispatchToProps = {
    setAppContext,
    setUserContext,
    refreshAppContext,
    setUserAccessToken,
    setAuthenticatedUserById,
    setCurrentTeamById
}

export default connect(mapStateToProps, mapDispatchToProps)(oAuthLoading);
