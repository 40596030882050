import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import AWS from "aws-sdk";
import { API, Storage } from "aws-amplify";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../../components/confirmationDialog';
import { resetLeaderboardPoints } from '../../services/leaderboardService'
import { getLocalizedString } from '../../utils/locale-utils';

import PageWrapper from '../nav/pageWrapper';

const styles = theme => ({
  circleProgressColor: {
    color: theme.palette.primary.main,
  },
});

class PointsLeaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      leaderboard: { leaderboard: [] },
      openResetPointConfirmationDialog: false
    };
  }

  async componentWillReceiveProps(props, nextProps) {
    if (this.state.currentTeam && props.currentTeam && props.currentTeam.id !== this.state.currentTeam)
      if (props.currentTeam.id !== this.state.currentTeam) {
        await this.componentDidMount()  // Force an update of the component when team changes
      }
  }

  async componentDidMount() {
    this.setState({ currentTeam: this.props.currentTeam })
    await this.getLeaderboardPoints();
  }

  getLeaderboardPoints = async () => {
    this.setState({ isLoading: true })
    var leaderboard = await this.getPointsLeaderboards();
    if (!!leaderboard.results) {
      leaderboard.results = leaderboard.results.sort((a, b) => b.teamPoints - a.teamPoints);
    }
    this.setState({
      isLoading: false,
      leaderboard: leaderboard
    })
  }

  toggleResetPointConfirmationDialog = () => {
    let { openResetPointConfirmationDialog } = this.state;
    this.setState({ openResetPointConfirmationDialog: !openResetPointConfirmationDialog })
  }

  handleResetPointConfirmation = async (userResponse) => {
    if (userResponse) {
      const { currentTeam } = this.props;
      this.setState({ openResetPointConfirmationDialog: false });
      try {
        this.setState({ isLoading: true });
        let response = await resetLeaderboardPoints(currentTeam.id);
        //Reseting leaderboard will take time
        alert("Resetting your Points Leaderboard. This may take up to 30 seconds in which you will need to refresh your page. Thank you for your patience.")
      }
      catch (e) {
        console.log(e);
      }
      finally {
        this.setState({ isLoading: false });
      }
    }
    else {
      this.setState({ openResetPointConfirmationDialog: false });
    }
  }

  async getPointsLeaderboards() {
    const { currentTeam } = this.props;
    return API.get("leaderboards", `/leaderboards/team/${currentTeam.id}/points`, {
      queryStringParameters: {
        limit: 100
      }
    });
  }

  Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
      c = array[i++];
      switch (c >> 4) {
        case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c);
          break;
        case 12: case 13:
          // 110x xxxx   10xx xxxx
          char2 = array[i++];
          out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
          break;
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = array[i++];
          char3 = array[i++];
          out += String.fromCharCode(((c & 0x0F) << 12) |
            ((char2 & 0x3F) << 6) |
            ((char3 & 0x3F) << 0));
          break;
      }
    }

    return out;
  }

  render() {
    const { classes } = this.props;
    const { leaderboard, isLoading, openResetPointConfirmationDialog } = this.state
    return (
      <PageWrapper title={getLocalizedString("POINTS_LEADERS")} {...this.props} isPageLoading={isLoading} loadingMessage={"loading your leaderboards..."}>
        <div style={{ height: '100%' }}>
          <ConfirmationDialog
            className={"toggleConfirmationContainer"}
            open={openResetPointConfirmationDialog}
            content={"Are you sure you want to reset the point leaderboard?"}
            buttonClassName={"toggleConfirmationButton"}
            handleClose={this.handleResetPointConfirmation}
          />
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 10, alignItems: "center", justifyContent: "flex-end", display: "flex" }}>
              <Button variant="contained" style={{ color: "white", outline: "none" }} color="primary" onClick={this.toggleResetPointConfirmationDialog}>Reset Points</Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: 10 }}>
              <Paper>
                {
                  leaderboard.results
                    ?
                    <div>
                      <Grid container spacing={8} style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                        </Grid>
                        <Grid item xs={10} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          {getLocalizedString("ATHLETE")}
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          Total
                    </Grid>
                      </Grid>
                      <Divider />
                      {
                        leaderboard.results.map((result, i) => {
                          return (
                            <div key={i}>
                              <Grid container spacing={8} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                <Grid item xs={1} style={{ textAlign: 'center' }}>
                                  {i + 1}
                                </Grid>
                                <Grid item xs={10} style={{ textAlign: 'left' }}>
                                  {result.player.nameFirst} {result.player.nameLast}
                                </Grid>
                                <Grid item xs={1} style={{ textAlign: 'left' }}>
                                  {result.teamPoints || 0}
                                </Grid>
                              </Grid>
                              <Divider />
                            </div>
                          )
                        })
                      }
                    </div>
                    :
                    <div style={{ paddingTop: 20, paddingBottom: 20, textAlign: "center" }}>
                      There are no results.
                  </div>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>
      </PageWrapper>
    )
  }

}

PointsLeaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    currentTeam: currentTeam || {}
  };
}

export default withStyles(styles)(connect(mapStateToProps)(PointsLeaderboard));
