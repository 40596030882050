import _ from 'lodash'

import { 
    getGuardians,
    getCoachesForTeam,
    getAthletesForTeam
} from '../services/usersService'
import { ALL_USERS_GROUPS } from '../constants' 
const isCoachup = window.location.href.toLowerCase().includes('coachup')

export async function resolveUserList(team, category, coaches = [], athletes = [], parents = []){
    let coachList = []
    let parentList = []
    let athleteList = []
    let positionList = []
    let filteredAthletes = athletes
    let filteredCoaches = coaches

    let returnValues = { 
        coachList: coaches, 
        parentList: parents,
        athleteList: athletes, 
    }

    switch (category){
        case 'athletes':
            returnValues.recipients = filteredAthletes
            return returnValues
        case 'coaches':
            returnValues.recipients = filteredCoaches
            return returnValues
        case 'parents':
            returnValues.recipients = parents
            return returnValues
        default: 
            if(athletes.length > 0){
                athleteList = athletes;
                coachList = coaches;
                parentList = parents;
            }
            else {
                coachList = await getCoachesForTeam(team.id)
                athleteList = await getAthletesForTeam(team.id)
                await Promise.all(athleteList.filter(a => a).map(async a => { 
                    let guardians = await getGuardians(a.id)
                    guardians.filter(g => g).map(g => g.athlete = a )
                    parentList = parentList.concat(guardians)  
                }))
                let formattedCoachList = new Map();
                let formattedAthleteList = new Map();
                let formattedParentList = new Map();
                coachList.forEach(element => {
                    if(element && element.id && element.id != ALL_USERS_GROUPS.allCoaches.id){
                        element.secondaryText = 'Coach';
                        element.name = `${element.nameFirst} ${element.nameLast}`;
                        formattedCoachList.set(element.id, element);
                    }
                });
                athleteList.forEach(element => {
                    if(element && element.id && element.id != ALL_USERS_GROUPS.allAthletes.id){
                        element.secondaryText = 'Athlete';
                        element.name = `${element.nameFirst} ${element.nameLast}`;
                        formattedAthleteList.set(element.id, element);
                    }
                });
                parentList.forEach(element => {
                    if(element && element.id && element.id != ALL_USERS_GROUPS.allParents.id){
                        element.secondaryText = 'Parent';
                        element.name = `${element.nameFirst} ${element.nameLast}`;
                        formattedParentList.set(element.id, element);
                    }
                });
                coachList = [...formattedCoachList.values()];
                athleteList = [...formattedAthleteList.values()];
                parentList = [...formattedParentList.values()];
            }
            
            if(!athleteList.some(a => a && a.id == ALL_USERS_GROUPS.allAthletes.id)){
                let allAthletes = {...ALL_USERS_GROUPS.allAthletes};
                allAthletes.secondaryText = `${athleteList.length} Athletes`
                athleteList.push(allAthletes)
            }
            if(!coachList.some(c => c && c.id == ALL_USERS_GROUPS.allCoaches.id)){
                let allCoaches = {...ALL_USERS_GROUPS.allCoaches};
                allCoaches.secondaryText = `${coachList.length} Coaches`
                coachList.push(allCoaches)
            }
            if(!parentList.some(p => p && p.id == ALL_USERS_GROUPS.allParents.id)){
                let allParents = {...ALL_USERS_GROUPS.allParents};
                allParents.secondaryText = `${parentList.length} Parents`
                parentList.push(allParents)
            }

            coachList = isCoachup ? [] : _.orderBy(_.uniqBy(coachList, 'name'), ['orderOnTop','name'])
            parentList = isCoachup ? [] : _.orderBy(_.uniqBy(parentList, 'name'), ['orderOnTop','name']) 

            return { 
                coachList: coachList, 
                parentList: parentList, 
                athleteList: _.orderBy(athleteList, ['orderOnTop','name']), 
                recipients: _.orderBy(athleteList.concat(parentList, coachList), ['orderOnTop','name']),
            }
    }
}

export async function resolveFilteredRecipientList(data){
    const { coaches, athletes, parents, value, currentRecipientCategory } = data
    let returnValue = []
    let potentialRecipientsList = []
    if(!value || value == ''){
        switch(currentRecipientCategory){
            case 'coaches':
                returnValue = coaches
                return _.orderBy(returnValue, ['orderOnTop','name'])
            case 'athletes':
                returnValue = athletes
                return _.orderBy(returnValue, ['orderOnTop','name'])
            case 'parents':
                returnValue = parents
                return _.orderBy(returnValue, ['orderOnTop','name'])
            default:
                returnValue = coaches.concat(athletes, parents)
                return _.orderBy(returnValue, ['orderOnTop','name'])
        }
    }

    if(currentRecipientCategory == 'coaches')
        potentialRecipientsList = coaches
    if(currentRecipientCategory == 'athletes')
        potentialRecipientsList = athletes
    if(currentRecipientCategory == 'parents')
        potentialRecipientsList = parents
    if(currentRecipientCategory == 'all')
        potentialRecipientsList = coaches.concat(athletes, parents)

    let filteredList = potentialRecipientsList.filter(r => 
        r && r.name.toLowerCase().includes(value.toLowerCase()) || 
        r && r.athlete && r.athlete.nameFirst.toLowerCase().includes(value.toLowerCase()) ||
        r && r.athlete && r.athlete.nameLast.toLowerCase().includes(value.toLowerCase())
    )

    returnValue = filteredList

    return returnValue
}

export async function resolveGroupMembers(group, athletes, coaches, parents){
    let groupMembers = []
    await Promise.all(group.participants.map(participant => {
        let foundPlayer = athletes.find(a => a && a.id == participant.id) ? athletes.find(a => a && a.id == participant.id) :
            coaches.find(c => c && c.id == participant.id) ? coaches.find(c => c && c.id == participant.id) :
            parents.find(p => p && p.id == participant.id) ? parents.find(p => p && p.id == participant.id) :
            null
        if(foundPlayer)
            groupMembers.push(foundPlayer)
    }))
    return groupMembers
} 

export async function resolveGroupParticipantsForSaving(members, athletes, coaches, parents){
    let participants = members
    const allAthletes = members.some(a => a && a.id == ALL_USERS_GROUPS.allAthletes.id)
    const allCoaches = members.some(c => c && c.id == ALL_USERS_GROUPS.allCoaches.id)
    const allParents = members.some(c => c && c.id == ALL_USERS_GROUPS.allParents.id)
    if(allAthletes){
        participants = participants.concat(athletes).filter(p => p.id != ALL_USERS_GROUPS.allAthletes.id)
    }
    if(allCoaches){
        participants = participants.concat(coaches).filter(p => p.id != ALL_USERS_GROUPS.allCoaches.id);
    }
    if(allParents){
        participants = participants.concat(parents).filter(p => p.id != ALL_USERS_GROUPS.allParents.id);
    }
    return _.uniqBy(participants,"id");
}