import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import spellmanLogo from '../assets/images/themes/grDrive/icon.png';
import homeImage from '../assets/images/themes/grDrive/grdrive-welcome.png'; // swap out for gr drive

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'grdrive',
    name: 'Grand Rapids Drive',
    appUrl: 'm1-grdrive://',
    domain: 'grdrive.gomaxone.com',
    link: 'https://apps.apple.com/us/app/grdrive/id1474027971',
    onAppleAppStore: 'https://apps.apple.com/us/app/grdrive-performance/id1474027971',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.grdrive'
  },
  image: {
    home: homeImage,
    logo: spellmanLogo,
    loginLogo: spellmanLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#006bb6"
    },
    secondary: {
      main: "#ed174c"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  }

});
