import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CircularProgress } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { SE_SESSION_KEY } from "../../constants";
import SESignupForm from '../newSignUpForm';
import { sportsEngineClient } from '../../clients'
import { ROUTE_PATH } from '../../routeUtil';
import classnames from 'classnames';
import { testId } from '../../utils/test-utils';
import "./style.css";

const styles = theme => ({
    orgSelector: {
    },
    logoMarginTop:{
        marginTop: "90px",
    },
    logoMarginBottom:{
        marginBottom:"50px"
    },
    seLogo: {           
        textAlign:"center",
        "& img": {
            marginTop:'30px',   
            marginBottom: "16px",
            maxWidth: "396px",
            width: "100%"
        },
        "& svg":{
            marginTop:'30px',   
            marginBottom: "20px",
        }
    },
    content: {
        maxWidth: "484px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        textAlign: "center",
        color: "#27292A"
    },
    orgContainer: {
        maxWidth: "808px",
        paddingTop: "30px",
        paddingBottom: "8px",
        background: "#FFFFFF",
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.16)",
        borderRadius: "8px",
        marginBottom: "50px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontSize: "20px",
        color: "#27292A",
        width: "100vw"
    },
    head: {
        marginLeft: "30px",
        lineHeight: "140%",
        letterSpacing: "0.01em",
        marginBottom: "20px"
    },
    org: {
        padding: "12px 30px",
        textAlign: "left",
        "& img": {
            width: "64px",
            height: "64px",
            border: "1px solid #A0A7AB",
            boxSizing: "border-box",
            marginRight: "12px",
            borderRadius: "50%"
        },
        "&:hover": {
            background: "#EDEFF0",
            cursor: "pointer"
        }
    },
    orgName: {
        fontWeight: "600",
        lineHeight: "120%"
    },
    orgSelected:{
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        maxWidth:"484px",
        alignSelf: "center",
        paddingTop: "50px",
        paddingBottom: "72px",
        paddingLeft: "5px",
        paddingRight: "5px"
    },
    orgSelectedHead:{
        fontWeight: "600",
        fontSize: "26px",
        lineHeight: "120%",
        color: "#0B0B0B",
        textAlign:"center"
    },
    orgSelectedTxt:{
        textAlign: "center",
        color: "#27292A",
        marginTop:"16px",
        marginBottom:"20px"
    },
    accountBtn:{
        width:"312px",
        height:"50px",
        background:"#27AE60",
        borderRadius:"8px",
        marginRight:"12px",
        padding:"15px 20px",
        color: "white",
        cursor:"pointer",
        "& svg":{
            color:"white",
            width:"20px",
            height:"20px"
        }
    }

});

class SEOrgSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingMessage: "Loading...",
            message: "",
            errorMessage: "",
            selectedOrgName: "",
            userInfo: {},
            step: 1
        };
    }


    componentDidMount() {
        const { history } = this.props;
        let access_token = sessionStorage.getItem(SE_SESSION_KEY);
        sportsEngineClient.getUserByAccessToken(access_token).then(({ data, status }) => {
            let selectedOrgName = "";
            if(!!data.organizations && (data.organizations||[]).length > 0){
                selectedOrgName = data.organizations[0].name;
            }
            this.setState({ isLoading: false, userInfo: data, showSignupContent: true, selectedOrgName });
        }).catch((e) => {
            // alert("Something went wrong. Please try again.");
            this.clearSessionDataAndReLogin(history);
            console.log("exception")
            console.log(e)
        });

    }

    clearSessionDataAndReLogin(history) {
        sessionStorage.removeItem(SE_SESSION_KEY);
        history.push(ROUTE_PATH.SE_ADMIN_LOGIN);
    }


    render() {
        const { classes, userHasAuthenticated, history } = this.props;
        const { isLoading, loadingMessage, selectedOrgName, showSignupContent, userInfo } = this.state;
        return (
            <div className="seOrgSelector d-md-flex align-items-md-center justify-content-md-center">
                {
                    isLoading
                        ?
                        <div className={"loadingOverlay"}>
                            <CircularProgress  />
                            <h2>{loadingMessage}</h2>
                        </div>
                        :
                        showSignupContent ?
                            <SESignupForm userInfo={userInfo} history={history} selectedOrgName={selectedOrgName} userHasAuthenticated={userHasAuthenticated} /> : null
                }
            </div>
        );
    }
}

SEOrgSelector.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SEOrgSelector);
