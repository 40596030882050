export const Tabs = [
    'Drafts',
    'Scheduled',
    'Sent'
]

export const RecipientCategories = [
    'all',
    'athletes',
    'coaches',
    'parents',
    'groups'
]