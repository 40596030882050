import _ from 'lodash';
import Cropper from 'react-cropper';
import React, { Component } from "react";
import { s3Upload } from "../../libs/awsLib";
import { Button } from '@material-ui/core';
import 'cropperjs/dist/cropper.css';

import "./imageCropperModal.css";
import LegacyModalContainer from '../modalContainer/legacyModalContainer';

class ImageCropperModal extends Component {
    state = { };

    dataURItoBlob = dataURI => {
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }

    crop = async () => {
        const { fileName } = this.props;
        const dataUrl = this.refs.cropper.getCroppedCanvas().toDataURL();
        const blobData = this.dataURItoBlob(dataUrl);
        const file = new File([blobData], fileName)
        const attachment = file ? await s3Upload(file, fileName) : null;
        this.props.getCroppedAttachment(attachment);
    }

    cancel = () => {
        this.props.close()
    }

    render() {
        const { open, title, attachment, close } = this.props;
        return(
            <div>
                <LegacyModalContainer open={open} onClose={close} containerClassName={"imageCropperModalPaper"}>
                    <div className={"modalHeaderContainer"}>
                        <h2 className={"modalHeaderText"}>{title}</h2>
                    </div>
                    <Cropper
                        ref='cropper'
                        src={attachment}
                        aspectRatio={10 / 10}
                        style={{ height: '80%', width: '80%' }}
                    />
                    <div>
                    </div>
                    <div className={"modalFooterContainer"}>
                        <div>
                            <Button
                                type="submit"
                                variant="outlined"
                                className={"outlinedButton"}
                                value={1}
                                onClick={this.cancel}>
                                Cancel
                                </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className={"filledButton"}
                                onClick={this.crop}>
                                Save
                                </Button>
                        </div>
                    </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

export default ImageCropperModal;
