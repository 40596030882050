
import React, { Component, Linking } from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import SwipeableViews from 'react-swipeable-views';
import fileDownload from 'js-file-download';
import QRCode from 'qrcode.react';
// import { graphql, compose } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "../roster.css";
import LegacyModalContainer from '../../../components/modalContainer/legacyModalContainer';

const styles = {

}

class AddAthleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getInviteMethod = () => {
        return this.props.method === 0 ? "Email" : "Text"
    }

    handleRemoveClick = contact => {
        this.props.remove(contact);
    }

    handleEditClick = contact => {
        this.props.edit(contact);
    }

    handleCsvRosterDownload() {
        fileDownload(['First Name', 'Last Name', 'Phone Number', 'Email'], "MaxOne_Roster.csv");
    }

    render() {
        const { classes, value, csvType, close, currentTeam = {}, tenantName, appContext } = this.props;        
        return (
            <LegacyModalContainer open={this.props.open} onClose={close} containerClassName={"rosterPaper"}>
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>Add Athletes</h2>
                </div>
                <div className={"outerModalContainer"}>

                    <Grid container direction="column" justify="center" alignItems="center" style={{ border: '1px solid primary' }}>
                        <Grid item style={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                textColor="primary"
                                indicatorColor="primary"
                                className={"tabContainer"}
                                onChange={this.props.handleTabValueChange}
                                style={value == 1 ? { marginTop: -15 } : {}}
                            >
                                {/* <Tab label="Manual" value={0} /> */}
                                <Tab label="Invite Code" value={0} />
                                {/* {window.location.href.toLowerCase().includes('vnn') ? null : <Tab label="Upload" value={1} />} */}
                            </Tabs>
                        </Grid>
                        {
                            value
                                ?
                                <Grid item style={{ width: '100%' }}>
                                    {!window.location.href.toLowerCase().includes('vnn') && <p style={{ marginBottom: 0, marginTop: 10 }}>
                                        Before uploading please make sure your roster is saved as a .csv file.
                                        <a target="_blank" href={"https://help.gomaxone.com/hc/en-us/articles/360004867871-4b-Uploading-CSV-Roster-Files"}>
                                            {`What is a .csv file?`}
                                        </a>
                                    </p>}
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, marginTop: 10, color: 'black' }}>
                                        Please remind your athletes to download the {tenantName} app onto their Android or Apple phones
                                        and then enroll in your team using one of the two methods below.
                                    </p>
                                </Grid>
                        }

                        <Grid item style={{ width: '100%' }}>
                            <SwipeableViews index={value}>                         
                                <div>
                                    <div className={"codeContainer"} >
                                        <div className={"codeOptionContainer"}>
                                            <h3>Scan</h3>
                                            <p>Have athletes scan from your screen</p>
                                        </div>
                                        <div className={"codeOptionContainer"}>
                                            <h3>Type</h3>
                                            <p>Send the following code to your athletes</p>
                                        </div>
                                    </div>
                                    <div className={"codeContainer"} >
                                        <div className={"codeOptionContainer"}>
                                            <QRCode
                                                value={currentTeam.playerCode}
                                                size={180}
                                            />
                                        </div>
                                        <div className={"codeOptionContainer"}>
                                            <h2>{currentTeam.playerCode}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className={"upload"}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={csvType === 2}
                                                    onChange={this.props.handleRosterTypeChange}
                                                    color="primary"
                                                    value={"2"}
                                                />
                                            }
                                            label="My Own"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={csvType === 0}
                                                    onChange={this.props.handleRosterTypeChange}
                                                    color="primary"
                                                    value={"0"}
                                                />
                                            }
                                            label="Hudl"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={csvType === 1}
                                                    onChange={this.props.handleRosterTypeChange}
                                                    color="primary"
                                                    value={"1"}
                                                />
                                            }
                                            label="TeamSnap"
                                        />
                                    </FormGroup>
                                    <br />
                                    <div className={"buttonContainer"}>
                                        <input
                                            multiple
                                            type="file"
                                            className={"input"}
                                            id="raised-button-file"
                                            onChange={this.props.selectCsv}
                                        />
                                        <label style={{ marginBottom: 0 }} htmlFor="raised-button-file">
                                            <Button
                                                component="span"
                                                color="secondary"
                                                variant="contained"
                                                className={"filledButton"}
                                            >
                                                Upload
                                                </Button>
                                        </label>
                                        {csvType === 2
                                            ?
                                            <div>
                                                <Button
                                                    component="span"
                                                    variant="contained"
                                                    className={"filledButton"}
                                                    onClick={this.handleCsvRosterDownload}>
                                                    Download Template
                                                </Button>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>
                            </SwipeableViews>
                        </Grid>
                    </Grid>
                    <div className={"modalFooterContainer"}>
                        <div>
                            <Button
                                type="submit"
                                className={"cancelButton"}
                                onClick={this.props.cancel}
                            >
                                Cancel
                                </Button>
                            {!appContext.isCoach ? <Button
                                type="submit"
                                className={classes.modalInviteButton}
                                onClick={this.props.send}
                            >
                                Invite
                                </Button> : null}
                        </div>
                    </div>
                </div>
            </LegacyModalContainer>
        )
    }
}


function mapStateToProps(state) {
    const { session: { userContext, appContext }, tenant } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        currentUser: userContext.user,
        tenantName: tenant.name,
        appContext: appContext
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AddAthleteModal));