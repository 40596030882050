import React, { Component } from "react";
import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import SwipeableViews from 'react-swipeable-views';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import MaskedInput from 'react-text-mask';

import shortid from "shortid";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    textField: {
        flexGrow: 1,
        width:'100%'
    },
    selectField: {
        flexGrow: 1,
        width:'100%',
        textAlign: 'left',
        paddingTop: '5px'
    },
    button: {
        width: 80,
        height: 40,
        borderRadius: 0,
        marginRight: 10,
        zIndex: 99
    }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={["+", /[1-9]/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class OrganizationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            type: "",
            addressStreet: "",
            addressCity: "",
            addressState: "",
            addressZip: "",
        };
    }

    async componentWillReceiveProps(nextProps){
      console.log('nextprops === ', nextProps)
      this.setState({
        name: nextProps.organization.name ? nextProps.organization.name : "",
        type: nextProps.organization.type ? nextProps.organization.type : "",
        addressStreet: nextProps.organization.addressStreet ? nextProps.organization.addressStreet : "",
        addressCity: nextProps.organization.addressCity ? nextProps.organization.addressCity : "",
        addressState: nextProps.organization.addressState ? nextProps.organization.addressState : "",
        addressZip: nextProps.organization.addressZip ? nextProps.organization.addressZip : ""
      })
    }

    createOrganization = async organization => {

      console.log('Organization ', organization)
      if(organization.id){
          await API.post("organizations", `/organizations`, {
              body: organization
          });
      }

      this.props.close(organization);
    }

    handleSubmit = () => {
        const { organization } = this.props;

        var organizationJson = {
          name: this.state.name,
          type: this.state.type,
          id: organization.id,
        }
        if(this.state.addressStreet){
          organizationJson.addressStreet = this.state.addressStreet
        }
        if(this.state.addressCity){
          organizationJson.addressCity = this.state.addressCity
        }
        if(this.state.addressState){
          organizationJson.addressState = this.state.addressState
        }
        if(this.state.addressZip){
          organizationJson.addressZip = this.state.addressZip
        }

        this.createOrganization(organizationJson);
    }

    handleChange = name => organization => {
        console.log('name ', name)
        console.log('organization ', organization)
        this.setState({
            [name]: organization.target.value
        });
    };

    handleTabValueChange = (organization, value) => {
        console.log('HERE IS THE VALUE', value);
        this.setState({ value });
    };

    render() {
        const { classes, open, close, organization } = this.props;
        const { value } = this.state;

        return (
            <LegacyModalContainer
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={close}
                containerClassName={"paper"}>
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>
                        Organization
                    </h2>
                </div>
                {this.state.errorMessage !== ""?<Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>:null}
                <div  className={"formContainer"}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabValueChange}
                    className={"tabContainer"}>
                    <Tab label="Basic Info" value={0} />
                </Tabs>
                <SwipeableViews
                    axis={'x-reverse'}
                    index={value}
                    onChangeIndex={this.handleChangeIndex}>
                    <div dir={"right"}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className={"inputSectionTitle"}>
                                <h2>Organization</h2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    className={classes.textField}
                                    value={this.state.name}
                                    onChange={this.handleChange("name")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="type"
                                    label="Type"
                                    className={classes.textField}
                                    value={this.state.type}
                                    onChange={this.handleChange("type")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="addressStreet"
                                    label="Street"
                                    className={classes.textField}
                                    value={this.state.addressStreet}
                                    onChange={this.handleChange("addressStreet")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="addressCity"
                                    label="City"
                                    className={classes.textField}
                                    value={this.state.addressCity}
                                    onChange={this.handleChange("addressCity")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="addressState"
                                    label="State"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.addressState}
                                    onChange={this.handleChange("addressState")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="addressZip"
                                    label="Zip Code"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.addressZip}
                                    onChange={this.handleChange("addressZip")}
                                    margin={"dense"}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </SwipeableViews>
                </div>
                <div className={"modalFooterContainer"}>
                    <div>
                        <IconButton
                            title="Delete organization"
                            onClick={this.deleteOrganization}
                            aria-label="edit"
                            variant="contained">
                            <DeleteIcon className={"styledIcon"} />
                        </IconButton>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={"outlinedButton"}
                            onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={"filledButton"}
                            onClick={this.handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </LegacyModalContainer>
        );
    }
}

OrganizationModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrganizationModal);
