import React from "react";
import { FaUsers } from "react-icons/fa";
import { Avatar, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchInput from "../../../components/searchInput";
import { colors } from "../../../components/v2/utils/theme-utils";
import ShadowRoundContainer from "./shared/ShadowRoundContainer";

const styles = () => ({
  container: {
    flex: 1,
  },
  searchBox: {
    height: 48,
    borderRadius: 4,
  },
});

const getOrgName = (currentTeam, groupByOrgId) => {
  let orgName = "";
  if (currentTeam.organizationName) {
    orgName = currentTeam.organizationName;
    return orgName;
  } else if ((groupByOrgId[currentTeam.organizationId] || []).length) {
    orgName = (groupByOrgId[currentTeam.organizationId] || [])[0]
      .organizationName;
    return orgName;
  }
};

const Teams = ({
  classes,
  currentTeam,
  searchValue,
  groupByOrgId,
  handleSearch,
  handleSelectTeam,
  selectedTeamsById,
}) => {
  return (
    <ShadowRoundContainer
      shadowContainerStyle={{
        overflowY: "scroll",
        borderRadius: 16,
        width: "100%",
        height: "60vh",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 15,
        }}
      >
        <div
          style={{
            fontWeight: 500,
            fontSize: 19,
            lineHeight: 1.5,
            color: colors.BRAND_GREY,
          }}
        >
          My Teams Calendar
        </div>
      </div>
      <div
        style={{
          padding: "6.5px 15px",
          backgroundColor: colors.WHITE,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "6.5px 0px",
          }}
        >
          <div style={{ flex: 0.22 }}>
            {currentTeam.logo ? (
              <Avatar src={currentTeam.logo} />
            ) : (
              <Avatar>
                <FaUsers />
              </Avatar>
            )}
          </div>
          <div style={{ flex: 0.58 }}>
            <div
              style={{
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 1.5,
                color: colors.GREY_40,
              }}
            >
              {getOrgName(currentTeam, groupByOrgId)}
            </div>
            <div
              style={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: 1.5,
                color: colors.BRAND_GREY,
              }}
            >
              {currentTeam.customName}
            </div>
          </div>
          <div style={{ flex: 0.2 }}>
            <Switch
              checked={selectedTeamsById[currentTeam.id] ? true : false}
              onChange={() => handleSelectTeam(currentTeam)}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "0px 15px 15px 15px" }}>
        <SearchInput
          placeholder={"Search Teams"}
          customClass={classes.searchBox}
          searchTerm={searchValue}
          handleSearch={(value) => handleSearch(value)}
        />
      </div>
      {Object.values(groupByOrgId || {}).map((item, index) => {
        item.sort((a, b) => a.customName.localeCompare(b.customName));
        return (
          <div key={index}>
            <div
              style={{
                height: 38,
                paddingLeft: 15,
                display: "flex",
                alignItems: "center",
                backgroundColor: colors.GREY_20,
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  lineHeight: 1.5,
                  color: colors.BRAND_GREY,
                }}
              >
                {item[0].organizationName}
              </div>
            </div>
            <div
              style={{
                padding: "15px 6.5px",
                backgroundColor: colors.WHITE,
              }}
            >
              {item.map((team, teamIndex) => {
                return (
                  <div
                    key={teamIndex}
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "7px 0px"
                    }}
                  >
                    <div style={{ flex: 0.15 }}>
                      {team.logo ? (
                        <Avatar src={team.logo} />
                      ) : (
                        <Avatar>
                          <FaUsers />
                        </Avatar>
                      )}
                    </div>
                    <div style={{ flex: 0.65 }}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: 1.5,
                          color: colors.BRAND_GREY,
                        }}
                      >
                        {team.customName || team.name}
                      </div>
                    </div>
                    <div style={{ flex: 0.2 }}>
                      <Switch
                        checked={selectedTeamsById[team.id] ? true : false}
                        onChange={() => handleSelectTeam(team)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </ShadowRoundContainer>
  );
};

export default withStyles(styles)(Teams);
