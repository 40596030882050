import React, { Component } from 'react'

import './styles.css'

export default class MessageTab extends Component {
    render(){
        const {
            name,
            count,
            onClick,
            active = false
        } = this.props

        return(
            <div
                onClick={onClick} 
                className={
                    active ? 'activeMessageTabOuterContainer messageTabOuterContainer' : 
                    'messageTabOuterContainer' 
                }
            >
                <p className={'messageTabText'}>{name}</p>
                {count > 0 && 
                    <div 
                        className={
                            active ? 'messageCountContainer activemessageCountContainer' : 
                            'messageCountContainer' 
                        }
                    >
                        <p>{count}</p>
                    </div>
                }
            </div>
        )
    }
}