export default {
  "graphqlEndpoint": "https://7554vgwmwngefbi2vdkoqandoi.appsync-api.us-east-1.amazonaws.com/graphql",
  "region": "us-east-1",
  "authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "apiKey": "da2-onx5mric2fg6vcu6kjgavc2toy",
}

// ﻿export default {
//     "graphqlEndpoint": "https://lw6upo7gnjb3leryisve54zxc4.appsync-api.us-east-1.amazonaws.com/graphql",
//     "region": "us-east-1",
//     "authenticationType": "API_KEY",
//     "apiKey": "da2-xkzdf7r2prh5bf4eeezuwf3wa4"
// }
