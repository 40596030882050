import React, { Component } from 'react'
import './styles.css'
import { Modal } from '@material-ui/core'
import StyledButton from '../../styledButton'
import EmailIcon from '@material-ui/icons/Email'
import DeleteIcon from '@material-ui/icons/Delete'

export default class WarningModal extends Component {
    render() {
        const { open, close, handleContinue, text, secondaryText } = this.props

        return(
            <div>
                <Modal open={open} onClose={close}>
                    <div className={'warningModalContainer'}>
                        <p className={'warningModalText'}>{text}</p>
                        <div className={'warningModalButtonContainer'}>
                            <StyledButton
                                width={'100%'}
                                icon={<DeleteIcon />} 
                                backgroundColor={'#F93F3F'} 
                                title={'Discard Message and Proceed'}
                                onClick={() => { close(); handleContinue() }} 
                            />
                            <StyledButton
                                width={'100%'}
                                onClick={close}   
                                icon={<EmailIcon />} 
                                backgroundColor={'#27AE60'} 
                                title={'Continue Editing my Message'}
                            />
                        </div>
                        <p className={'warningModalSecondaryText'}>{secondaryText}</p>
                    </div>
                </Modal>
            </div>
        )
    }
}