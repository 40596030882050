import React, { Component } from 'react'
import './styles.css'
import classnames from 'classnames'

class ToggleButton extends React.Component {
    constructor(props) {
        super(props);
    }

    toggle = () => {
        const { onChange, value } = this.props;
        let returnValue = { value: !value, target: { value: !value } };
        onChange(returnValue);
    }

    render() {
        const { textFontSize, buttonTextPadding, buttonHeight, buttonEnabledText, buttonDisabledText, value: enabled } = this.props;
        const buttonStyle = { paddingLeft: buttonTextPadding, paddingRight: buttonTextPadding }
        return (
            <div style={{ height: buttonHeight, fontSize: textFontSize }} className={'toggleButtonContainer'}>
                <div onClick={this.toggle} type="button" className={classnames("toggleButton", { "active activeLeft": enabled })} style={{ ...buttonStyle }}>{buttonEnabledText}</div>
                <div className={"separator"} />
                <div onClick={this.toggle} type="button" className={classnames("toggleButton", { "active activeRight": !enabled })} style={buttonStyle}>{buttonDisabledText}</div>
            </div>
        )
    }
}

ToggleButton.defaultProps = {
    textFontSize: "14px",
    buttonTextPadding: "24px",
    buttonHeight: "48px",
    buttonEnabledText: "Yes",
    buttonDisabledText: "No",
    value: true,
    onChange: () => { }
}

export default ToggleButton