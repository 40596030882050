import React from "react";
import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PageWrapper from "../../../containers/nav/pageWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import ShadowRoundedContainer from "../components/shared/ShadowRoundContainer";
import moment from "moment";
import { LocationIcon, BanIcon } from "../icons";
import { colors } from "../../../components/v2/utils/theme-utils";
import Teams from "./Teams";
import EventDetail from "../containers/EventDetail";
import { Edit } from "@material-ui/icons";
import InfiniteScroll from "react-infinite-scroller";
import { PrimaryButton } from "../../../components/v2/buttons";
import { isEmpty, isString } from "lodash";
import { formatDate } from "../../../utils/date-utils";
import { isJson } from "../../../utils/commonUtil";
import Calendar from "./Calendar";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const styles = () => ({
  timelineContainer: {
    height: "90vh",
    overflow: "auto",
  },
  dayContainer: {
    paddingBottom: "2px",
    color: "#818181",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "24px",
    width: "14px",
    lineHeight: "14px",
    fontSize: "12px",
    fontWeight: "700",
    marginLeft: "-8px",
  },
  labelArea: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "100px",
    justifyContent: "center",
    fontSize: "14px",
    minWidth: "100px",
    height: "20px",
  },
  editIcon: {
    marginLeft: "10px",
    "& svg": {
      height: "16.5px",
      width: "16.5px",
    },
  },
  titleSection: {
    color: "#222127",
    fontSize: "16px",
    height: "19px",
    marginBottom: "3px",
    lineHeight: "19px",
  },
  location: {
    "& svg": {
      height: "14px",
      width: "10px",
    },
  },
  cancelledEvent: {
    "& svg": {
      height: 10,
      width: 10,
    },
  },
});

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const ListItem = ({ classes, list, date, handleChange, selectedTeamsById }) => {
  return (list || []).map((item, index) => {
    const color = (item.tag || {}).color
      ? hexToRgb((item.tag || {}).color)
      : [];
      
    const {
      location,
      eventType,
      when: { end_time, object, start_time },
      status,
    } = item;
    const place = isJson(location) ? JSON.parse(location) : {};
    const team =
      (item.teams || []).find((item) => selectedTeamsById[item.id]) ||
      (item.teams || [])[0] ||
      {};
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 15,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            flex: 0.15,
            height: 153,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              flex: 0.5,
              display: "flex",
              borderLeft: `1px solid ${colors.GREY_40}`,
              justifyContent: "space-around",
            }}
          />

          <div>
            {index === 0 ? (
              <div>
                <div
                  style={{
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: 1.5,
                    color: colors.GREY_50,
                    textAlign: "center",
                    paddingBottom: 2,
                  }}
                >
                  {moment(date).format("ddd").toUpperCase()}
                </div>
                <div
                  style={{
                    width: 35,
                    height: 35,
                    display: "flex",
                    borderRadius: 17.5,
                    backgroundColor: colors.GREY_50,
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: 12,
                      lineHeight: 1.5,
                      color: colors.WHITE,
                    }}
                  >
                    {moment(date).format("D")}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              flex: 0.5,
              display: "flex",
              justifyContent: "space-around",
              borderLeft: `1px solid ${colors.GREY_40}`,
            }}
          />
        </div>
        <div
          style={{ display: "flex", flex: 0.85, margin: 7 }}
          onClick={() => {
            if (status !== "cancelled") {
              handleChange("selectedEvent", item);
            }
          }}
        >
          <ShadowRoundedContainer
            shadowContainerStyle={{
              cursor: "pointer",
              borderRadius: 16,
              width: "100%",
            }}
          >
            <div style={{ padding: 15 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {item.rsvp ? (
                  <div
                    style={{
                      width: 4,
                      marginRight: 10,
                      backgroundColor:
                        item.rsvp === "Yes" ? colors.SUCCESS : colors.ERROR,
                    }}
                  ></div>
                ) : null}
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        lineHeight: 1.5,
                        color: colors.BLACK,
                      }}
                    >
                      {object === "timespan"
                        ? `${moment(start_time * 1000).format(
                            "hh:mm A"
                          )} - ${moment(end_time * 1000).format("hh:mm A")}`
                        : eventType === "tbd"
                        ? "TBD"
                        : "All Day"}
                    </div>

                    <div>
                      {!isEmpty(item.tag) ? (
                        <Chip
                          style={{
                            backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.4)`,
                            color: colors.GREY_90,
                            marginRight: 10,
                          }}
                          label={(item.tag || {}).name}
                        />
                      ) : null}
                      {status !== "cancelled" ? (
                        <Edit style={{ fontSize: 20, color: colors.PRIMARY }} />
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: 1.5,
                        color: colors.BLACK,
                      }}
                    >
                      {item.title}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        color: colors.GREY_90,
                        paddingRight: 10,
                      }}
                    >
                      {(item.teams || []).length > 1 ? (
                        <span>
                          {team.customName || team.name}
                          <b> +{(item.teams || []).length - 1}</b>
                        </span>
                      ) : (
                        <span>{team.customName || team.name}</span>
                      )}
                    </div>
                  </div>
                  {status === "cancelled" ? (
                    <div
                      className={classes.cancelledEvent}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <BanIcon width={12} height={12} />
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: 10,
                          lineHeight: 1.5,
                          color: colors.ERROR,

                          paddingLeft: 5,
                        }}
                      >
                        Event cancelled
                      </div>
                    </div>
                  ) : null}
                  {(place || {}).place_name ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: 5,
                        alignItems: "center",
                      }}
                      className={classes.location}
                    >
                      <LocationIcon
                        width={12}
                        height={12}
                        fill={colors.GREY_40}
                      />
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 10,
                          lineHeight: 1.5,
                          color: colors.GREY_40,
                          paddingLeft: 5,
                        }}
                      >
                        {(place || {}).place_name}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </ShadowRoundedContainer>
        </div>
      </div>
    );
  });
};

const EventsList = ({
  classes,
  events,
  hasMore,
  loadData,
  handleChange,
  grouppedEvents,
  selectedTeamsById,
}) => {
  const scrollRef = useBottomScrollListener(loadData);
  return (
    <div ref={scrollRef} className={classes.timelineContainer}>
      <div
        style={{
          flex: 0.9,
          flexDirection: "row",
          paddingHorizontal: 15,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Object.keys(grouppedEvents).map((event, index) => {
          return (
            <div>
              <div
                style={{
                  padding: "5px 10px",
                  margin: "20px 0px",
                  backgroundColor: colors.GREY_20,
                  borderRadius: 5,
                  display: "flex"
                }}
                key={index}
              >
                {event}
              </div>
              {Object.keys(grouppedEvents[event]).map((item, index) => {
                if (isString(grouppedEvents[event][item])) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingHorizontal: 15,
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 0.15,
                          height: 109,
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flex: 0.5,
                            display: "flex",
                            borderLeft: `1px solid ${colors.GREY_40}`,
                            justifyContent: "space-around",
                          }}
                        />

                        <div>
                          {index === 0 ? (
                            <div>
                              <div
                                style={{
                                  fontWeight: 700,
                                  fontSize: 12,
                                  lineHeight: 1.5,
                                  color: colors.GREY_50,
                                  textAlign: "center",
                                  paddingBottom: 2,
                                }}
                              >
                                {moment(item).format("ddd").toUpperCase()}
                              </div>
                              <div
                                style={{
                                  width: 35,
                                  height: 35,
                                  display: "flex",
                                  borderRadius: 17.5,
                                  backgroundColor: colors.GREY_50,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: 4,
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 12,
                                    lineHeight: 1.5,
                                    color: colors.WHITE,
                                  }}
                                >
                                  {moment(item).format("D")}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            flex: 0.5,
                            display: "flex",
                            justifyContent: "space-around",
                            borderLeft: `1px solid ${colors.GREY_40}`,
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", flex: 0.85, margin: 7 }}>
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: colors.GREY_50,
                          }}
                        >
                          {grouppedEvents[event][item]}
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <>
                    {/* <div>{formatDate(item, "MMMM")}</div> */}
                    <ListItem
                      key={index}
                      classes={classes}
                      date={item}
                      list={grouppedEvents[event][item] || []}
                      handleChange={handleChange}
                      selectedTeamsById={selectedTeamsById}
                    />
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Timeline = (props) => {
  const {
    offset,
    events,
    hasMore,
    classes,
    history,
    loadData,
    startDate,
    searchValue,
    currentTeam,
    handleCreate,
    groupByOrgId,
    handleSearch,
    handleChange,
    setStartDate,
    selectedEvent,
    grouppedEvents,
    handleSelectTeam,
    selectedTeamsById,
    loadingChildMessage,
    loadingChildComponent,
  } = props;
  return (
    <>
      <PageWrapper
        title={"Calendar"}
        secondaryAction={
          <PrimaryButton style={{ width: 150 }} onClick={handleCreate}>
            Create Event
          </PrimaryButton>
        }
        {...props}
      >
        <BodyWrapper
          removePadding={true}
          isLoading={loadingChildComponent}
          showLoadingChild={true}
          loadingMessage1={loadingChildMessage}
          enableSubContainerStyle={false}
        >
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <EventsList
                    offset={offset}
                    events={events}
                    hasMore={hasMore}
                    classes={classes}
                    loadData={loadData}
                    handleChange={handleChange}
                    grouppedEvents={grouppedEvents}
                    selectedTeamsById={selectedTeamsById}
                  />
                </div>
                <div className="col-xs-12 col-md-4">
                  <div style={{ marginBottom: 20 }}>
                    <Calendar
                      startDate={startDate}
                      setStartDate={setStartDate}
                    />
                  </div>
                  <div>
                    <Teams
                      searchValue={searchValue}
                      currentTeam={currentTeam}
                      groupByOrgId={groupByOrgId}
                      handleSearch={handleSearch}
                      handleSelectTeam={handleSelectTeam}
                      selectedTeamsById={selectedTeamsById}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BodyWrapper>
      </PageWrapper>
      {Object.keys(selectedEvent).length ? (
        <EventDetail
          visible={true}
          history={history}
          event={selectedEvent}
          handleClose={() => handleChange("selectedEvent", {})}
        />
      ) : null}
    </>
  );
};
export default withStyles(styles)(Timeline);
