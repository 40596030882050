import React, { Component } from "react";
import { Chip } from "@material-ui/core";
import moment from "moment";
import AthleteComponent from "../components/Athlete";
import { athleteColumns } from "../constants";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import {
  habitResultClient,
  userClient,
  serviceIntegrationClient,
  hrvClient
} from "../../../clients";
import {
  SleepIcon,
  PaperIcon,
  RunningIcon,
  GlassIcon,
  HealthKitIcon,
  ManIcon,
  FlowerIcon,
  LeafIcon,
  CheckIcon,
  CrossIcon,
} from "../icons";

const icons = {
  Bed: <SleepIcon style={{ width: 42, height: 16 }} />,
  CupWater: <GlassIcon style={{ width: 42, height: 16 }} />,
  Nutrition: <PaperIcon style={{ width: 42, height: 16 }} />,
  Mindset: <FlowerIcon style={{ width: 42, height: 16 }} />,
  Running: <RunningIcon style={{ width: 42, height: 16 }} />,
  SelfCare: <ManIcon style={{ width: 42, height: 16 }} />,
  Leaf: <LeafIcon style={{ width: 42, height: 16 }} />,
  Immune: <HealthKitIcon style={{ width: 42, height: 16 }} />,
};

const styles = () => ({
  icons: {
    "& svg": {
      height: "16px !important",
    },
  },
});
class Athlete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentage: 0,
      athleteColumns: [],
      habits: [],
      athleteDetails: {},
      whoopData: [],
      percentageByDate: {},
      graphData: {},
      hrvData:[],
      currentHrvData:{},
      currentHrvIndex:0
    };
  }

  componentDidMount = () => {
    this.getAthleteData();
    this.getAthleteDetails();
  };

  getAthleteData = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    let habits = [];
    const today = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const startDate = moment()
      .subtract(6, "days")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const {data: { results: hrvData } }= await hrvClient.getHRVListByUserId(id, startDate, today);
    const { data } = await habitResultClient.getHomePageDataByUserId(
      id,
      startDate,
      today
    );
    let { data: graphData } = await habitResultClient.getHomePageDataByUserId(
      id,
      moment().subtract(13, "days").format("YYYY-MM-DDTHH:mm:ss[Z]"),
      today
    );
    graphData = this.proccessGraphData(
      graphData[moment().format("YYYY-MM-DD")]
    );
    const {
      data: { results: whoopData },
    } = await serviceIntegrationClient.getServiceDataByUserId(
      "WHOOP",
      id,
      startDate,
      today
    );
    // let { percentage, data: todayData } = this.procceedResultsData(
    //   data[moment().format("YYYY-MM-DD")]
    // );
    let {
      data: todayData,
      percentageByDate,
      averagePercentage,} = this.procceedResultsData(data[moment().format("YYYY-MM-DD")])
    Object.values(todayData).forEach((element) => {
      habits.push(element);
    });
    let currentHrvData =hrvData && hrvData.length ? hrvData[0] : {} 
    this.setState({ habits, percentage: averagePercentage, whoopData, percentageByDate,graphData, hrvData, currentHrvData  }, () =>
      this.setColumns(habits)
    );
  };

  getAthleteDetails = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    userClient
      .getUserById(id, "ownit")
      .then(({ data }) => {
        this.setState({ athleteDetails: data });
      })
      .catch((e) => {
        this.setState({ athleteDetails: {} });
      });
  };

  setColumns = (habits) => {
    const { classes } = this.props;
    athleteColumns.forEach((item) => {
      habits.forEach((habit) => {
        if (habit.resultsByDate[item.field]) {
          return (item.render = (row) => {
            const res = row.resultsByDate[item.field] || {};
            return (
              <div className={classes.icons}>
                {res.answer === true ? (
                  <CheckIcon />
                ) : res.answer === false ? (
                  <CrossIcon />
                ) : (
                  ""
                )}
              </div>
            );
          });
        }
      });
      switch (item.field) {
        case "controllable":
          return (item.render = (item) => {
            const icon = icons[(item.controllable || {}).icon];
            console.log("icon", icon);
            return (
              <Chip avatar={icon} label={(item.controllable || {}).name} />
            );
          });
        case "habit":
          return (item.render = (item) => {
            return (item.habit || {}).title;
          });
        case "habitResults":
          return (item.render = (item) => {
            return <div>{`${(item.habit.percentage || 0).toFixed(0)}%`}</div>;
          });
      }
    });
    this.setState({ athleteColumns });
  };

  procceedResultsData1 = (data) => {
    let percentage = 0;
    Object.values(data).forEach((element) => {
      let answeredHabits = 0;
      let unansweredHabits = 0;
      element.results.forEach((result) => {
        if (result.answer) {
          answeredHabits += 1;
        } else {
          unansweredHabits += 1;
        }
      });
      element.resultsByDate = element.results
        ? element.results.reduce((obj, result) => {
            const date = ((result.dueDate || "").split("T") || [])[0];
            obj[date] = result || {};
            return obj;
          }, {})
        : {};
      element.habit.percentage = Math.floor(
        (answeredHabits / (answeredHabits + unansweredHabits)) * 100
      );
      element.habit.answeredHabits = answeredHabits;
      element.habit.unansweredHabits = unansweredHabits;
    });
    percentage = Object.values(data).reduce((totalPercent, item) => {
      totalPercent += item.habit.percentage;
      return totalPercent;
    }, 0);
    if (Object.values(data).length) {
      percentage = percentage / Object.values(data).length;
    }
    return { percentage, data };
  };

  proccessGraphData = (data) => {
    let graphData = {};
    Object.values(data).forEach((element) => {
      element.resultsByDate = (element.results || []).reduce((obj, result) => {
        const date = ((result.dueDate || "").split("T") || [])[0];
        obj[date] = result || {};
        return obj;
      }, {});
    });
    Array.from({ length: 13 }, (_, i) =>
      moment().subtract(i, "days").format("YYYY-MM-DD")
    ).forEach((item) => {
      if (!graphData[item]) {
        graphData[item] = {
          answeredHabits: 0,
          unansweredHabits: 0,
        };
      }
      Object.values(data)
        .filter((element) => !isEmpty(element.resultsByDate[item]))
        .forEach((element) => {
          if (element.resultsByDate[item].answer) {
            graphData[item].answeredHabits += 1;
          } else {
            graphData[item].unansweredHabits += 1;
          }
        });
    });
    return graphData;
  };

  handleOnBack = () => {
    this.props.history.goBack();
  }
  handleBackHrv = () => {
      const { hrvData, currentHrvIndex } = this.state;  
      let currentIndex = currentHrvIndex >= hrvData.length - 1 ? hrvData.length - 1 : currentHrvIndex + 1;
      let data = hrvData[currentIndex];
      this.setState({currentHrvData: data, currentHrvIndex: currentIndex})
  }
  handleForwardHrv = () => {
    const { hrvData, currentHrvIndex } = this.state;
    let currentIndex =  currentHrvIndex <= 0 ? 0 : currentHrvIndex - 1;
    let data =hrvData[currentIndex];
    this.setState({currentHrvData: data, currentHrvIndex: currentIndex})
}
  procceedResultsData = (data) => {
    const percentageByDate = {};
    Object.values(data).forEach((element) => {
      let answeredHabits = 0;
      let unansweredHabits = 0;
      element.results.forEach((result) => {
        if (result.answer) {
          answeredHabits += 1;
        } else {
          unansweredHabits += 1;
        }
      });
      element.resultsByDate = element.results
        ? element.results.reduce((obj, result) => {
            const date = ((result.dueDate || "").split("T") || [])[0];
            obj[date] = result || {};
            return obj;
          }, {})
        : {};
      element.habit.percentage = Math.floor(
        (answeredHabits / (answeredHabits + unansweredHabits)) * 100
      );
      element.habit.answeredHabits = answeredHabits;
      element.habit.unansweredHabits = unansweredHabits;
    });

    Array.from({ length: 7 }, (_, i) =>
      moment().subtract(i, "days").format("YYYY-MM-DD")
    ).forEach((item) => {
      if (!percentageByDate[item]) {
        percentageByDate[item] = {
          answeredHabits: 0,
          unansweredHabits: 0,
          percentage: 0,
        };
      }
      Object.values(data)
        .filter((element) => !isEmpty(element.resultsByDate[item]))
        .forEach((element) => {
          if (element.resultsByDate[item].answer) {
            percentageByDate[item].answeredHabits += 1;
          } else {
            percentageByDate[item].unansweredHabits += 1;
          }
        });
      percentageByDate[item].percentage = Math.floor(
        (percentageByDate[item].answeredHabits /
          (percentageByDate[item].answeredHabits +
            percentageByDate[item].unansweredHabits)) *
          100
      );
    });

    let averagePercentage = Object.values(data).reduce((acc, item) => {
      acc += item.habit.percentage;
      return acc
    }, 0);
    averagePercentage = averagePercentage / Object.values(data).length;

    return { data, percentageByDate, averagePercentage };
  };

  render() {
    const {
      athleteColumns,
      habits,
      athleteDetails,
      percentage,
      whoopData,
      percentageByDate,
      graphData,
      hrvData,
      currentHrvData,
      currentHrvIndex
    } = this.state;
    const { handleLogout } = this.props;
    return (
      <AthleteComponent
        percentage={percentage}
        athleteColumns={athleteColumns}
        habits={habits}
        athleteDetails={athleteDetails}
        handleLogout={handleLogout}
        whoopData={whoopData}
        graphData={graphData}
        handleOnBack={() => this.handleOnBack()}
        percentageByDate={percentageByDate}
        hrvData={hrvData}
        currentHrvData={currentHrvData}
        currentHrvIndex={currentHrvIndex}
        handleForwardHrv={() => this.handleForwardHrv()}
        handleBackHrv={() => this.handleBackHrv()}
        
      />
    );
  }
}

export default withStyles(styles)(Athlete);
