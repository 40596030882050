export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGatewayChat: {
    REGION: "us-east-1",
    URL: "https://t0501nqbmi.execute-api.us-east-1.amazonaws.com/prod"
  },
  "apiGatewayActivities":{REGION: "us-east-1", URL:"https://gxp8z6hvbb.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayAuth":{REGION: "us-east-1", URL:"https://cjbi7ztfa8.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayAdmin":{REGION: "us-east-1", URL:"https://jgs1uebmad.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayBilling":{REGION: "us-east-1", URL:"https://q3snvjg9k5.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayEvents":{REGION: "us-east-1", URL:"https://oirbe1w625.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayFeedItems":{REGION: "us-east-1", URL:"https://vvx5uvp8ci.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayGroups":{REGION: "us-east-1", URL:"https://osbxcnw2jl.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayLeaderboards":{REGION: "us-east-1", URL:"https://xq4e9tavok.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayMessages":{REGION: "us-east-1", URL:"https://wz9yw39kqi.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayOrganizations":{REGION: "us-east-1", URL:"https://i2gxr73rvf.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayProducts":{REGION: "us-east-1", URL:"https://u4v6rfixd3.execute-api.us-east-1.amazonaws.com/prod" },
  apiGatewayPrograms: {
    REGION: "us-east-1",
    URL: "https://rqxq6itvw7.execute-api.us-east-1.amazonaws.com/prod"
  },
  "apiGatewaySchedules":{REGION: "us-east-1", URL:"https://fj8p0fhi7j.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayUsers":{REGION: "us-east-1", URL:"https://gw1jo2fpo4.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayWorkoutPrograms":{REGION: "us-east-1", URL:"https://s556ajrg86.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayWorkouts":{REGION: "us-east-1", URL:"https://o0n0wglpeg.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayWorkoutSchedules":{REGION: "us-east-1", URL:"https://eoirzbjce9.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayWorkoutSessions":{REGION: "us-east-1", URL:"https://x3d14tlqr4.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayTags":{REGION: "us-east-1", URL:"https://pwv7ls3xdl.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayTaggings":{REGION: "us-east-1", URL:"https://z2be81kj29.execute-api.us-east-1.amazonaws.com/prod" },
  "apiGatewayVnn": {REGION: "us-east-1",URL:"https://sbi3i2kyz2.execute-api.us-east-1.amazonaws.com/ops"},
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_vVfBbvViH",
    APP_CLIENT_ID: "5nqasit6a60qfgibejnonldugq",
    IDENTITY_POOL_ID: "us-east-1:9c2be610-ca5a-4bab-a152-b950357779a3"
  }
}
