import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BanIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M13 12.1667H6.75L8.41667 10.5H13V12.1667ZM3 12.1667V10.0833L9.56667 3.54167C9.725 3.375 9.99167 3.375 10.1583 3.54167L11.625 5.00833C11.7917 5.175 11.7917 5.43333 11.625 5.6L5.05833 12.1667H3ZM13.8333 0.5H2.16667C1.24167 0.5 0.5 1.24167 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.24167 14.75 0.5 13.8333 0.5Z"
      fill="#CED3D6"
    />
  </SvgIcon>
);

export default BanIcon;
