import { groupClient } from "../clients";

export const GET_GROUPS_BY_TEAM_ID_LOADING = "GET_GROUPS_BY_TEAM_ID_LOADING";
export const GET_GROUPS_BY_TEAM_ID_SUCCESS = "GET_GROUPS_BY_TEAM_ID_SUCCESS";
export const GET_GROUPS_BY_TEAM_ID_ERROR = "GET_GROUPS_BY_TEAM_ID_ERROR";

export const CREATE_GROUP_LOADING = "CREATE_GROUP_LOADING";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

export const UPDATE_GROUP_BY_ID_LOADING = "UPDATE_GROUP_BY_ID_LOADING";
export const UPDATE_GROUP_BY_ID_SUCCESS = "UPDATE_GROUP_BY_ID_SUCCESS";
export const UPDATE_GROUP_BY_ID_ERROR = "UPDATE_GROUP_BY_ID_ERROR";

export const DELETE_GROUP_BY_ID_LOADING = "DELETE_GROUP_BY_ID_LOADING";
export const DELETE_GROUP_BY_ID_SUCCESS = "DELETE_GROUP_BY_ID_SUCCESS";
export const DELETE_GROUP_BY_ID_ERROR = "DELETE_GROUP_BY_ID_ERROR";

export const getGroupsByTeamId = (teamId) => async (dispatch) => {
  try {
    dispatch({ type: GET_GROUPS_BY_TEAM_ID_LOADING });
    const { data } = await groupClient.getGroupsByTeamId(teamId);
    dispatch({ type: GET_GROUPS_BY_TEAM_ID_SUCCESS, data: { teamId, ...data } });
  } catch (e) {
    console.warn(`Error getting groups by team ID ${teamId}`, e);
    dispatch({ type: GET_GROUPS_BY_TEAM_ID_ERROR });
  }
};

export const createGroup = (name, parentId, participants, modifiers) => async (
  dispatch
) => {
  try {
    dispatch({ type: CREATE_GROUP_LOADING });
    const { data } = await groupClient.createGroup(
      name,
      parentId,
      participants,
      modifiers
    );
    dispatch({ type: CREATE_GROUP_SUCCESS, data });
  } catch (e) {
    console.warn(`Error creating group`, e);
    dispatch({ type: CREATE_GROUP_ERROR });
  }
};

export const updateGroupById = (
  id,
  name,
  parentId,
  participants,
  modifiers
) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GROUP_BY_ID_LOADING });
    const { data } = await groupClient.updateGroupById(
      id,
      name,
      parentId,
      participants,
      modifiers
    );
    dispatch({ type: UPDATE_GROUP_BY_ID_SUCCESS, data });
  } catch (e) {
    console.warn(`Error update group by ID ${id}`, e);
    dispatch({ type: UPDATE_GROUP_BY_ID_ERROR });
  }
};

export const deleteGroupById = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_GROUP_BY_ID_LOADING });
    await groupClient.deleteGroupById(id);
    dispatch({ type: DELETE_GROUP_BY_ID_SUCCESS, data: id });
  } catch (e) {
    console.warn(`Error deleting group by ID ${id}`, e);
    dispatch({ type: DELETE_GROUP_BY_ID_ERROR });
  }
};

export const addUserToGroup = (userId, groupId) => async (dispatch) => {
  try {
    await groupClient.addUserToGroup(userId, groupId);
  } catch (e) {
    console.warn(`Error adding user ${userId} to group ${groupId}`, e);
  }
};
