import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

import { Auth } from "aws-amplify";

import { setUserContext, setAppContext } from "../../actions/session";
import { setAuthenticatedUserById } from "../../actions/user-actions";
import { setCurrentTeamById } from "../../actions/team-actions";

import { getThemeByTenant } from "../../themes";
import { authClient } from "../../clients";
import { getLocalizedString } from "../../utils/locale-utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./forgotPassword.css";
import ToastMessage from "../../components/toastMessage";
import PasswordInput from "../../components/passwordInput";
import MetaTags from "react-meta-tags";
import ModalContainer from "../../components/modalContainer";
import { setSessionInfo } from "../../utils/sessionUtils";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  menu: {
    width: 500,
    padding: 50,
  },
  modal: {
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: 300,
    marginTop: 10,
  },
  paper: {
    padding: 8 * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    fontWeight: 100,
    fontSize: 45,
    marginBottom: "-15px",
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom: "-35px",
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: null,
      isLoading: false,
      username: "",
      password: "",
      show: false,
      user: null,
      newPassword: "",
      newPasswordConfirm: "",
      passwordError: false,
      isLoadingPassword: false,
      code: "",
      message: "",
      errorMessage: "",
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const username = urlParams.get("resetusername");
    if (username) {
      this.setState({ show: true, username });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let { tenant } = this.props;
    this.setState({ isLoading: true });
    this.setState({ errorMessage: "" });

    try {
      const firstTrimUsername = this.state.username.trimStart();
      const username = firstTrimUsername.trimEnd();
      authClient
        .forgotPasswordByEmail({ username, tenant })
        .then(({ data, status }) => {
          this.setState({
            show: true,
            message: `A code will be sent to your phone number. Please enter code to proceed`,
          });
        })
        .catch((e) => {
          this.setState({ isToastOpen: true });
          console.log("Error ", e);
        });
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("e ", e);
      this.setState({ isLoading: false });
    }
  };

  handleNewPasswordSubmit = async (event) => {
    const { tenant } = this.props;
    event.preventDefault();

    this.setState({ isLoading: true, errorMessage: "" });
    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      this.setState({ passwordError: true, isLoading: false });
      return;
    }
    try {
      const firstTrimUsername = this.state.username.trimStart();
      const username = firstTrimUsername.trimEnd();
      await authClient
        .changePasswordByCode(
          tenant,
          username,
          null,
          this.state.code,
          this.state.newPassword
        )
        .then(async (data) => {
          await Auth.signIn(username, this.state.newPassword);
          try {
            await setSessionInfo(username, this.props);
          } catch (e) {
            console.log();
            console.log(e);
          }
          this.props.userHasAuthenticated(true);
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            message: "",
            errorMessage: "Invalid verification code",
          });
        });
    } catch (e) {
      console.log("Exception");
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  closeToast = () => {
    this.setState({ isToastOpen: false });
  };

  render() {
    var {
      newPassword,
      newPasswordConfirm,
      isLoading,
      isToastOpen,
    } = this.state;

    const { classes } = this.props;
    const logo = getThemeByTenant().image.loginLogo;

    return (
      <div className="ForgotPassword">
        <MetaTags>
          <meta
            http-equiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </MetaTags>
        {isLoading && (
          <div className={"loadingOverlay"}>
            <CircularProgress />
          </div>
        )}
        <Grid container spacing={24}>
          <Grid item xs={12} style={{ justify: "center" }}>
            <img className={classes.image} src={logo} alt="logo" />
            <h1 className={classes.header}>
              FORGOT YOUR <br /> PASSWORD?
            </h1>
            <h4 className={classes.subHeader}>
              No problem! Just give us your username and we'll <br /> send a
              reset code to your email.
            </h4>
            <form onSubmit={this.handleSubmit}>
              <Grid item xs={12}>
                <TextField
                  id="username"
                  label="Username"
                  className={classes.textField}
                  value={this.state.username}
                  onChange={this.handleChange("username")}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {getLocalizedString("RESET")}
                </Button>
              </Grid>
              {/* <Grid item xs={12} style={{marginTop:15}}>
              <Typography variant="subheading">
                Forgot <a href="/forgot/username">username</a> too?
              </Typography>
            </Grid> */}
              <Grid item xs={12} style={{ marginTop: 45 }}>
                <Typography variant="subheading">
                  <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                  {getLocalizedString("ALREADY_HAVE_CODE")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => this.setState({ show: true })}
                  className={classes.button}
                >
                  {getLocalizedString("ENTER_RESET_CODE")}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <div className={"toastMessageContainer"}>
          <ToastMessage
            open={isToastOpen}
            close={this.closeToast}
            text={`Oops, your account doesn't exit`}
          />
        </div>

        <ModalContainer
          showFooter={false}
          customPadding={"30px"}
          heading={getLocalizedString("NEW_PASSWORD")}
          open={this.state.show}
          width={"630px"}
          onClose={() => this.setState({ show: false })}
        >
          <div className={"forgot-password-modal-container"}>
            {this.state.message !== "" ? (
              <Typography>{this.state.message}</Typography>
            ) : this.state.errorMessage !== "" ? (
              <Typography style={{ color: "red" }}>
                {this.state.errorMessage}
              </Typography>
            ) : null}
            <form onSubmit={this.handleNewPasswordSubmit}>
              <Grid item xs={12}>
                <TextField
                  id="code"
                  label="Enter Code"
                  className={"textfield"}
                  value={this.state.code}
                  onChange={this.handleChange("code")}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="username2"
                  label="Username"
                  className={"textfield"}
                  value={this.state.username}
                  onChange={this.handleChange("username")}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  id="newPassword"
                  label={getLocalizedString("NEW_PASSWORD")}
                  className={"textfield"}
                  value={this.state.newPassword}
                  type="password"
                  autoComplete="password"
                  onChange={this.handleChange("newPassword")}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  id="newPasswordConfirm"
                  label={getLocalizedString("CONFIRM_NEW_PASSWORD")}
                  className={"textfield"}
                  value={this.state.newPasswordConfirm}
                  onChange={this.handleChange("newPasswordConfirm")}
                  type="password"
                  autoComplete="password"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={newPassword !== newPasswordConfirm}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={"button"}
                >
                  {getLocalizedString("SUBMIT")}
                </Button>
              </Grid>
            </form>
          </div>
        </ModalContainer>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext)),
  setAuthenticatedUserById: (userId) =>
    dispatch(setAuthenticatedUserById(userId)),
  setCurrentTeamById: (teamId) => dispatch(setCurrentTeamById(teamId)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
