import React, { useState }  from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Item from './Item'
import '../index.scss';

const paddingLeft = 17
const paddingRight = 8

const Component = (props) => {
    const { node, level, handleTeamChange } = props
    const { children, selected } = node
    const [collapsed, setCollapsed] = useState(true)
    return (
        <div className={classNames(`level-${level}`, { expanded: !collapsed }, { selected: selected && collapsed })}>
            <div className={`list-item-container`}>
                <Item {...node} collapsed={collapsed} setCollapsed={setCollapsed} handleTeamChange={handleTeamChange}/>
                {
                    !collapsed && (children || []) ?
                        <div style={{ paddingLeft: ((level + 1) * paddingLeft), paddingRight: ((level + 1) * paddingRight) }}>
                            {(children || []).map((item, index) => {
                                return (
                                    <Component node={item} key={index} level={level + 1} handleTeamChange={handleTeamChange}/>
                                )
                            })}
                        </div>
                        : null
                }
            </div>
        </div>
    )
};

Component.propTypes = {
    node: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        subTitle: PropTypes.string,
        type: PropTypes.string,
        handleChange: PropTypes.func,
        children: PropTypes.array,
        selected: PropTypes.bool,
        leftIcon: PropTypes.string,
    }),
    level: PropTypes.number,
    collapsed: PropTypes.bool,
    selected: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        subTitle: PropTypes.string,
        type: PropTypes.string,
        handleChange: PropTypes.func,
        children: PropTypes.array,
        collapsed: PropTypes.bool,
        selected: PropTypes.bool,
        leftIcon: PropTypes.string,
    }))
};

Component.defaultProps = {
    node: {
        url: "https://cdn.gomaxone.com/tenant/m1/logo.png",
        title: "",
        subTitle: "",
        type: "",
        handleChange: () => { },
        children: [],
        collapsed: true,
        selected: false,
        leftIcon: null,
    },
    level: 0,
    collapsed: true,
    selected: false,
    children: []
};

export default Component;