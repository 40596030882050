/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import PageWrapper from '../../../../containers/nav/pageWrapper';
import ActivityList from '../ActivityList';
import ActivityTopBar from '../ActivityTopBar';
import SingleActivity from '../SingleActivity';
import Loader from '../../../loaderWithMessage/index';
import ActivityScoreModal from '../../modals/ActivityScoreModal';
import ActivityViewerModal from '../../modals/ActivityViewerModal';
import { activityClient } from '../../../../clients';
import { tagClient } from '../../../../clients';

//amit imports
import {
	saveActivity,
	getFileTypeSupportStatus,
	uploadActivityAttachment,
} from '../../../../utils/activityUtils';
import { FileType, toBase64 } from '../../../../utils/commonUtil';
import urlParser from '../../../../js-video-url-parser';

import './index.scss';

// import bootstrap from 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap-grid.min.css';

function ActivityPage(props) {
	const {
		teams: { currentTeamId },
		match: {
			params: { id },
		},
		libraries,
	} = props;
	const _ = require('lodash');
	const categoryData = libraries.find((item) => item.value === id) || {};
	let defaultActivity = {
		id: shortid.generate(),
		title: null,
		media: {
			type: null,
			source: null,
			url: null,
			thumbnail: null,
		},
		description: null,
		tags: [
			{
				id: null,
				label: null,
				slug: null,
			},
			{
				id: null,
				label: null,
				slug: null,
			},
		],
		isDeleted: null,
		hasLeaderboard: null,
		category: null,
		scoreType: null,
		isPercentOfMax: null,
		percentOfMaxActivityId: null,
	};

	const [tags, setTags] = useState([]);
	const [taggedActivities, setTaggedActivities] = useState({});
	const [loading, setLoading] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState('loading activities');
	//this state keeps track of the slugs to make tag searches easier
	const [slugs, setSlugs] = useState([]);
	//search bar state
	const [activitySearchActive, setActivitySearchActive] = useState(false);
	const [tagSearchActive, setTagSearchActive] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	//state for multi delete
	const [multiDeleteActive, setMultiDeleteActive] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState(false);
	const [deleteList, setDeleteList] = useState([]);
	//modal state
	const [activityScoreModalOpen, setActivityScoreModalOpen] = useState(false);
	const [activityViewerModalOpen, setActivityViewerModalOpen] = useState(false);
	const [isGoal, setIsGoal] = useState();
	//amit states
	const [activity, setActivity] = useState(defaultActivity);
	const [modalTags, setModalTags] = useState([]);
	const [uploadingVideoFile, setUploadingVideoFile] = useState(null);
	const [videoFileUploadProgress, setVideoFileUploadProgress] = useState(0);
	const [cancelUpload, setCancelUpload] = useState(null);
	const [uploadingThumbnailFile, setUploadingThumbnailFile] = useState(null);
	const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
	const [invalidVideoUrl, setInvalidVideoUrl] = useState(null);

	/*
	getting and setting activity lists and tags
	*/
	const getSlugs = async () => {
		setTaggedActivities({});
		setTags([]);
		setSlugs([]);
		setLoadingMessage('loading activities');
		setLoading(true);
		//getting all activity slugs
		const {
			data: {
				meta: { distinctTags },
			},
		} = await activityClient.searchActivities({
			'teamId.keyword': currentTeamId,
			"category.keyword": id,
			limit: 0,
		});

		const justSlugs = distinctTags.map(({ slug }) => slug);
		setSlugs(justSlugs.sort());

		const chunkedSlugs = _.chunk(justSlugs.sort(), 3);

		//getting all possible tags and slugs
		const {
			data: { results },
		} = await tagClient.getAvailableTagsByTeamId(currentTeamId);

		const orderedTags = justSlugs.map((slug) => {
			return results.find((element) => element.slug === slug).label;
		});

		setTags([...orderedTags]);

		//searches are done in batches for lazy loading
		await getActivitiesByTagBatch(chunkedSlugs);
		setLoading(false);
		setTagSearchActive(false)
		setActivitySearchActive(false)
		setSearchResults([])

	};

	const getActivitiesByTagBatch = async (tags, index = 0) => {
		const batchOfActivities = tags[index]
			? await Promise.all(tags[index].map((tag) => getActivitiesByTag(tag)))
			: null;

		const activities =
			batchOfActivities !== null
				? batchOfActivities.reduce(
					(list, { data: { total, results } }, activitiesIndex) => {
						const slug = tags[index][activitiesIndex];
						list[slug] = {
							...list[slug],
							total,
							results,
						};
						return list;
					},
					{}
				)
				: [];

		setTaggedActivities((prevActivities) => {
			return { ...prevActivities, ...activities };
		});
		setLoading(false);


		if (tags[index + 1]) {
			return getActivitiesByTagBatch(tags, index + 1);
		} else {
			const untagged = await getUntagged();
			if (untagged) {
				const labeledUntagged = { untagged: untagged };
				setTaggedActivities((prevActivities) => {
					return { ...prevActivities, ...labeledUntagged };
				});
				setLoading(false);

			}
		}
	};

	const getActivitiesByTag = async (tag, offset = 0, limit = 20, list = []) => {
		const activities = await activityClient.searchActivities({
			'tags.slug.keyword': tag !== 'untagged' ? `"${tag}"` : 'untagged',
			offset,
			limit,
			"category.keyword": id,
			'teamId.keyword': currentTeamId,
		});

		let data = { data: { results: list, total: list.length } };
		if (activities) {
			list = list.concat(activities.data.results);
			if (list.length < activities.data.total) {
				return await getActivitiesByTag(tag, list.length, limit, list);
			}
			data = {
				data: { results: list, total: list.length, meta: activities.meta },
			};
		}
		return data;
	};

	async function getUntagged(list = []) {
		const untagged = await activityClient.searchActivities({
			offset: list.length,
			limit: 50,
			'tags.slug.keyword': 'untagged',
			"category.keyword": id,
			'teamId.keyword': currentTeamId,
		});
		if (untagged.data.total) {
			list = list.concat(untagged.data.results);
			if (list.length < untagged.data.total) {
				return await getUntagged(list, untagged.data.total);
			} else {
				return { results: list, total: list.length };
			}
		} else {
			return null;
		}
	}
	/*
	deleting activities
	*/
	const deleteSingleActivity = async (id) => {
		setLoadingMessage('deleting activity');
		setLoading(true);
		await activityClient.deleteActivityById(id);
		getSlugs();
	};

	function toggleMultiDelete() {
		if (multiDeleteActive) {
			setMultiDeleteActive(false);
			setDeleteStatus("cleared")
			setDeleteList([])
		} else {
			setMultiDeleteActive(true);
			setDeleteStatus("adding activities")
		}
	}

	function addToDeleteList(id) {
		let newDeleteList = deleteList;
		if (newDeleteList.includes(id)) {
			newDeleteList = newDeleteList.filter((item) => {
				return item !== id;
			});
		} else {
			newDeleteList.push(id);
		}
		setDeleteList([...newDeleteList]);
	}

	function addAllToDeleteList() {
		const allIds =
			tagSearchActive || activitySearchActive
				? searchResults.map(result => result.id)
				: Object.values(taggedActivities)
						.map((value) => value.results.map((activity) => activity.id))
						.flat();
		setDeleteList(allIds);
		setDeleteStatus('delete all');
	}

	async function deleteMultipleActivities() {
		setMultiDeleteActive(false);
		const message =
			deleteList.length === 1 ? 'deleting activity' : 'deleting activities';
		setLoadingMessage(message);
		setLoading(true);
		await Promise.all(
			(deleteList || []).map((id) => activityClient.deleteActivityById(id))
		);
		getSlugs();
		setDeleteList([]);
		setDeleteStatus(false)
	}

	function cancelDelete(){
		setActivitySearchActive(false)
		setTagSearchActive(false)
		setSearchResults([])
		//this is a work around that clears the the search box
		setLoadingMessage('deleting activities');
		setLoadingMessage('')
	}

	/*
	search bar functions and search
	*/
	const tagSearchChange = async (event) => {
		if (event.target.value === 'all') {
			setTagSearchActive(false);
		} else {
			setActivitySearchActive(false);
			setTagSearchActive(true);
			const searchedActivities = await getSearchedActivities('tag', event.target.value);
			setSearchResults(searchedActivities)
		}
	};

	const searchSubmit = async (event = null, value) => {
		if (event) { event.preventDefault() }
		if (value === '') {
			setActivitySearchActive(false);
		} else {
			const searchedActivities = await getSearchedActivities('activity', value);
			setSearchResults(searchedActivities)
			setTagSearchActive(false);
			setActivitySearchActive(true);
		}
	};

	const getSearchedActivities = async (typeOfSearch, word) => {
		const { data: { results = [] } } =
			typeOfSearch === 'activity'
				? await activityClient.searchActivities({
					offset: 0,
					limit: 100,
					q: word,
					"category.keyword": id,
					'teamId.keyword': currentTeamId,
				})
				: await getActivitiesByTag(word, 0, 100);
		return results
	};

	/*
	workout activity modal functions
	*/

	function resetActivity() {
		setModalTags([]);
		defaultActivity.id = shortid.generate();
		setActivity(defaultActivity);
	}

	const workoutModalOnChangeFunction = (name) => (event) => {
		let clonedActivity = { ...activity };
		if (name == 'description') {
			clonedActivity[name] = event;
		}
		if (name === 'isCoachesOnly') {
			clonedActivity[name] = !event.target.value;
		} else {
			clonedActivity[name] = event && event.target ? event.target.value : event;
		}
		setActivity(clonedActivity);
	};

	async function newActivitySaveFunction(
		addingAnother = false,
		modalOpen = null
	) {
		setLoadingMessage('creating activity');
		setLoading(true);
		const currentTeam = { id: currentTeamId };
		await saveActivity(
			{ ...activity, isNewActivity: true },
			null,
			currentTeam,
			modalTags
		);
		await getSlugs();
		resetActivity();
		if (addingAnother) {
			modalOpen();
		}
	}

	function currentActivityToState(incomingActivity) {
		const editedIncomingActivity = {
			...incomingActivity,
			name: incomingActivity.title,
			scoreType: incomingActivity.scoreType.toLowerCase(),
			videoType: incomingActivity.media ? incomingActivity.media.type : null,
			videoUrl: incomingActivity.media ? incomingActivity.media.url : null,
			thumbnail: incomingActivity.media
				? incomingActivity.media.thumbnail
				: null,
			source: incomingActivity.media ? incomingActivity.media.source : null,
			isCoachesOnly: incomingActivity.isCoachesOnly ? true : false,
		};
		setActivity(editedIncomingActivity);
		const currentModalTags = incomingActivity.tags.map((tag) => tag.label);
		setModalTags(currentModalTags);
	}

	async function editActivitySaveFunction() {
		setLoadingMessage('saving activity');
		setLoading(true);
		const currentTeam = { id: currentTeamId };
		await saveActivity(
			{ ...activity, isNewActivity: false },
			null,
			currentTeam,
			modalTags
		);
		getSlugs();
		resetActivity();
	}

	function addTag(tag) {
		const newModalTags = modalTags.concat([{ name: tag }]);
		setModalTags(newModalTags);
	}

	function removeTag(tag) {
		if (tag) {
			const index = modalTags.indexOf(tag);
			let newModalTags = modalTags;
			newModalTags.splice(index, 1);
			setModalTags([...newModalTags]);
		}
	}

	const handleVideoUrlChange = (name) => async (e) => {
		const videoUrl = e.target.value;
		const video = await urlParser.parse(videoUrl);
		if (
			video &&
			(video.provider == 'vimeo' ||
				video.provider == 'youtube' ||
				video.provider == 'wistia')
		) {
			const activityInstance = {
				...activity,
				videoId: video.id,
				videoType: video.provider,
				videoUrl: videoUrl,
				invalidVideoUrl: false,
			};
			setActivity(activityInstance);
			setInvalidVideoUrl(false);
		} else {
			const failedActivityInstance = {
				...activity,
				invalidVideoUrl: true,
				videoId: '',
			};
			setActivity(failedActivityInstance);
			setInvalidVideoUrl(true);
		}
	};

	const removeActivityVideo = () => {
		const activityInstance = {
			...activity,
			videoId: null,
			videoType: null,
			videoName: null,
			attachment: null,
			videoUrl: null,
			invalidVideoUrl: false,
		};
		setActivity(activityInstance);
		setInvalidVideoUrl(false);
	};

	const handleThumbnailSelect = async (e) => {
		e.preventDefault();
		if (e.target.files.length) {
			let file = e.target.files[0];
			setUploadingThumbnailFile(true)
			// this.setState({ uploadingThumbnailFile: true, activity });
			const thumbnailBase64 = await toBase64(file);
			const activityInstance = {
				...activity,
				thumbnailName: file.name,
				thumbnailBase64: thumbnailBase64,
				thumbnailUploadProgress: 0,
				uploadingThumbnailFile: false,
			};
			setUploadingThumbnailFile(false);
			setThumbnailUploadProgress(0);
			setActivity(activityInstance);
		}
	};

	const handleFileSelect = async (e) => {
		e.preventDefault();
		let { activityInstance = {} } = activity;
		if (e.target.files.length) {
			let file = e.target.files[0];
			let fileName = file.name;
			const fileTypeSupportStatus = getFileTypeSupportStatus(fileName);
			if (!fileTypeSupportStatus.isSupported) {
				if (fileTypeSupportStatus.fileType == FileType.VIDEO) {
					alert(
						'Sorry, we do not support this video type. Supported video formats are MP4, WebM, OGG'
					);
				} else {
					alert(
						'Selected file type is not supported.\n(Currently only accept Video, Image or PDF file.)'
					);
				}
				return;
			}
			// here is a edge case fix:
			// if this is originally a video file (or pdf file), now you/
			// need to tell the program it is not a video file
			activity.videoType = null;
			activity.videoId = null;
			activity.videoName = file.name;
			activity.fileObject = file;
			const objectURL = URL.createObjectURL(file);
			activity.attachment = objectURL;
			// console.log(' fileTypeSupportStatus.fileType', fileTypeSupportStatus.fileType)
			// console.log(' FileType.VIDEO', FileType.VIDEO)
			// if (fileTypeSupportStatus.fileType == FileType.VIDEO) {
			//     activity.videoType = 'coach';
			//     this.setState({
			//         activity: activity
			//     });
			// }
			// else {
			const id = activity.id || shortid.generate();
			console.log('id ', id)
			console.log('activity ', activity)
			console.log('activityInstance ', activityInstance)
			setUploadingVideoFile(true)
			const { url, mediaId, thumbnail } = await uploadActivityAttachment(
				file,
				id,
				currentTeamId,
				(progress) => {
					if (!cancelUpload) {
						setVideoFileUploadProgress(progress);
					}
				}
			);
			if (!cancelUpload) {
				activity.attachment = url;
				activity.mediaId = mediaId;
				if(thumbnail){
					activity.thumbnail = thumbnail;
				}
			}
			setCancelUpload(false);
			setActivity(activity);
			setUploadingVideoFile(false);
			setVideoFileUploadProgress(0);
			// }
		}
	};

	const cancelUploadFunction = () => {
		setCancelUpload(true);
		setUploadingVideoFile(false);
		// this.setState({ cancelUpload: true, uploadingVideoFile: false });
	};

	const activityScoreOnClick = (activity) => {
		setActivity(activity);
		setActivityScoreModalOpen(true);
	};
	const activityViewerOnClick = (activity) => {
		setActivity(activity);
		setActivityViewerModalOpen(true);
	};

	const activityScoreClose = () => {
		setActivity(defaultActivity);
		setActivityScoreModalOpen(false);
	};

	const activityViewerClose = () => {
		setActivity(defaultActivity);
		setActivityViewerModalOpen(false);
	};

	const isGoalSetter = (value) => {
		setIsGoal(value);
	};

	/*
	use effects
	*/
	useEffect(() => {
		getSlugs();
	}, []);

	useEffect(() => {
		setTaggedActivities({});
		setTags([]);
		setActivitySearchActive(false);
		setTagSearchActive(false);
		setSearchResults([]);
		getSlugs();
		setDeleteList([]);
		setDeleteStatus(false);
		setMultiDeleteActive(false)
	}, [id]);

	/*
	render conditionals
	*/
	const bodyRenderFunction = () => {
		//normal show all render
		if (loading) {
			return <Loader isActive={true} message={loadingMessage} />;
		} else if (!tagSearchActive && !activitySearchActive) {
			return (
				<div className='activity-lists'>
					{Object.keys(taggedActivities).length ? (
						Object.entries(taggedActivities).map(([tag, activities]) => {
							return (
								<ActivityList
									title={tag}
									listOfActivities={activities}
									deleteSingleActivity={deleteSingleActivity}
									multiDeleteActive={multiDeleteActive}
									addToDeleteList={addToDeleteList}
									activityViewerOnClick={activityViewerOnClick}
									activityScoreOnClick={activityScoreOnClick}
									isGoalSetter={isGoalSetter}
									routeId={id}
									deleteStatus={deleteStatus}
									//amit props
									currentActivity={activity}
									modalTags={modalTags}
									editActivitySaveFunction={editActivitySaveFunction}
									currentActivityToState={currentActivityToState}
									currentTeamId={currentTeamId}
									workoutModalOnChangeFunction={workoutModalOnChangeFunction}
									addTag={addTag}
									removeTag={removeTag}
									resetActivity={resetActivity}
									handleFileSelect={handleFileSelect}
									handleVideoUrlChange={handleVideoUrlChange}
									removeActivityVideo={removeActivityVideo}
									handleThumbnailSelect={handleThumbnailSelect}
									cancelUpload={cancelUploadFunction}
									displaySuccessOverlay={'displaySuccessOverlay'}
									uploadingThumbnailFile={uploadingThumbnailFile}
									videoFileUploadProgress={videoFileUploadProgress}
									thumbnailUploadProgress={thumbnailUploadProgress}
									overlayMessage={'overlayMessage'}
									uploadingVideoFile={uploadingVideoFile}
									invalidVideoUrl={invalidVideoUrl}
								/>
							);
						})
					) : (
						<h2 className='no-activities'>
							Hey coach, add some activities for your athletes...
						</h2>
					)}
				</div>
			);
		}
		//search by tag or activity render
		else {
			return (
				<div className='search-result-activities'>
					{Object.keys(searchResults).length ? (
						searchResults.map((searchedActivity) => {
							return (
								<div className='search-item'>
									<SingleActivity
										activity={searchedActivity}
										deleteSingleActivity={deleteSingleActivity}
										multiDeleteActive={multiDeleteActive}
										addToDeleteList={addToDeleteList}
										activityViewerOnClick={activityViewerOnClick}
										activityScoreOnClick={activityScoreOnClick}
										isGoalSetter={isGoalSetter}
										routeId={id}
										deleteStatus={deleteStatus}
										//amit props
										currentActivity={activity}
										modalTags={modalTags}
										editActivitySaveFunction={editActivitySaveFunction}
										currentActivityToState={currentActivityToState}
										currentTeamId={currentTeamId}
										workoutModalOnChangeFunction={workoutModalOnChangeFunction}
										addTag={addTag}
										removeTag={removeTag}
										resetActivity={resetActivity}
										handleFileSelect={handleFileSelect}
										handleVideoUrlChange={handleVideoUrlChange}
										removeActivityVideo={removeActivityVideo}
										handleThumbnailSelect={handleThumbnailSelect}
										cancelUpload={cancelUploadFunction}
										displaySuccessOverlay={'displaySuccessOverlay'}
										uploadingThumbnailFile={uploadingThumbnailFile}
										videoFileUploadProgress={videoFileUploadProgress}
										thumbnailUploadProgress={thumbnailUploadProgress}
										overlayMessage={'overlayMessage'}
										uploadingVideoFile={uploadingVideoFile}
										invalidVideoUrl={invalidVideoUrl}
									/>
								</div>
							);
						})
					) : (
						<h2>No activities found!</h2>
					)}
				</div>
			);
		}
	};

	return (
		<div className='activity-page'>
			<PageWrapper
				title={(categoryData.name || 'Activities').captialize()}
				{...props}>
				<div className='activity-search-bars'>
					<ActivityTopBar
						selectOptions={tags}
						selectValues={slugs}
						tagSearchChange={tagSearchChange}
						searchSubmit={searchSubmit}
						tagSearchActive={tagSearchActive}
						toggleMultiDelete={toggleMultiDelete}
						multiDeleteActive={multiDeleteActive}
						deleteList={deleteList}
						deleteMultipleActivities={deleteMultipleActivities}
						routeId={id}
						addAllToDeleteList={addAllToDeleteList}
						loadingMessage={loadingMessage}
						cancelDelete={cancelDelete}
						//amit params
						currentTeamId={currentTeamId}
						workoutModalOnChangeFunction={workoutModalOnChangeFunction}
						workoutModalSaveFunction={newActivitySaveFunction}
						removeTag={removeTag}
						addTag={addTag}
						activity={activity}
						modalTags={modalTags}
						resetActivity={resetActivity}
						handleFileSelect={handleFileSelect}
						handleVideoUrlChange={handleVideoUrlChange}
						removeActivityVideo={removeActivityVideo}
						handleThumbnailSelect={handleThumbnailSelect}
						cancelUpload={cancelUploadFunction}
						displaySuccessOverlay={'displaySuccessOverlay'}
						uploadingThumbnailFile={uploadingThumbnailFile}
						videoFileUploadProgress={videoFileUploadProgress}
						thumbnailUploadProgress={thumbnailUploadProgress}
						overlayMessage={'overlayMessage'}
						uploadingVideoFile={uploadingVideoFile}
						invalidVideoUrl={invalidVideoUrl}
					/>
				</div>
				{bodyRenderFunction()}
				<ActivityScoreModal
					open={activityScoreModalOpen}
					closeFunction={activityScoreClose}
					activity={activity}
					isGoal={isGoal}
				/>
				<ActivityViewerModal
					open={activityViewerModalOpen}
					activity={activity}
					deleteFunction={deleteSingleActivity}
					closeFunction={activityViewerClose}
					//amit props
					currentActivity={activity}
					modalTags={modalTags}
					editActivitySaveFunction={editActivitySaveFunction}
					currentActivityToState={currentActivityToState}
					currentTeamId={currentTeamId}
					workoutModalOnChangeFunction={workoutModalOnChangeFunction}
					addTag={addTag}
					removeTag={removeTag}
					resetActivity={resetActivity}
					handleFileSelect={handleFileSelect}
					handleVideoUrlChange={handleVideoUrlChange}
					removeActivityVideo={removeActivityVideo}
					handleThumbnailSelect={handleThumbnailSelect}
					cancelUpload={cancelUploadFunction}
					displaySuccessOverlay={'displaySuccessOverlay'}
					uploadingThumbnailFile={uploadingThumbnailFile}
					videoFileUploadProgress={videoFileUploadProgress}
					thumbnailUploadProgress={thumbnailUploadProgress}
					overlayMessage={'overlayMessage'}
					uploadingVideoFile={uploadingVideoFile}
					invalidVideoUrl={invalidVideoUrl}
				/>
			</PageWrapper>
		</div>
	);
}

ActivityPage.propTypes = {};

ActivityPage.defaultProps = {};

const mapStateToProps = ({ teams, session: { appContext } }) => {
	return { teams, libraries: appContext.libraries || [] };
};

export default connect(mapStateToProps)(ActivityPage);
