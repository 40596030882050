import { API } from 'aws-amplify'
import { WorkoutScheduleClient } from 'maxone-api-client'
import { getEnvironment } from 'maxone-react-ui';
import { getRegion } from '../utils/environmentUtils';

const ENV = window.location.host.includes("maxone-dev") ? "dev" :
    window.location.host.includes("maxone-tst") ? "tst" :
    window.location.host.includes("gomaxone-stg") || window.location.host.includes("maxone-stg") ? "stg" : "ops"

const client = new WorkoutScheduleClient({ environment: getEnvironment().toLowerCase(), region: getRegion() });

export const getWorkoutScheduleEnrollments = async workoutScheduleId => {
    const result = await client.getWorkoutScheduleEnrollments(workoutScheduleId)
    return result
}

export const deleteWorkoutSchedule = async scheduleId => {
    const result = await client.deleteWorkoutSchedule(scheduleId)
    return result
}

export const createWorkoutSchedule = async schedule => {
    const result = await client.createWorkoutSchedule(schedule)
    return result
}

export const updateWorkoutSchedule = async schedule => {
    const result = await client.updateWorkoutSchedule(schedule)
    return result
}

export const deleteWorkoutScheduleEnrollment = async enrollmentId => {
    const result = await client.deleteWorkoutScheduleEnrollment(enrollmentId)
    return result
}

export const createWorkoutScheduleEnrollment = async enrollment => {
    const result = await client.createWorkoutScheduleEnrollment(enrollment)
    return result
}

export const createWorkoutScheduleAssignment = async assignment => {
    const result = await client.createWorkoutScheduleAssignment(assignment)
    return result
}

export const updateWorkoutScheduleAssignment = async assignment => {
    const result = await client.updateWorkoutScheduleAssignment(assignment)
    return result
}

export const deleteWorkoutScheduleAssignmentById = async assignmentId => {
    const result = await client.deleteWorkoutScheduleAssignmentById(assignmentId)
    return result
}

export const getWorkoutAssignmentsByScheduleId = async scheduleId => {
    const results = await client.getWorkoutAssignmentsByScheduleId(scheduleId)
    return results
}

export const getWorkoutSchedulesByTeamId = async teamId => {
    const results = await client.getWorkoutSchedulesByTeamId(teamId)
    return results
}
