import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import USAHockeyLogo from '../assets/images/themes/usaHockey/usahockey-logo.png';
import homeImage from '../assets/images/themes/usaHockey/usahockey-welcome.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'tph',
    name: 'USA Hockey',
    appUrl: 'm1-tph://',
    domain: 'tph.gomaxone.com',
    link: 'https://apps.apple.com/us/app/tph/id1507185594', // replace with real link
    onAppleAppStore: 'https://apps.apple.com/us/app/tph/id1507185594', // replace with real link
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.tph' // replace with real link
  },
  image: {
    home: homeImage,
    logo: USAHockeyLogo,
    loginLogo: USAHockeyLogo,
    longLogo: USAHockeyLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#003876"
    },
    secondary: {
      main: "#BE311A",
      isDarkMode: true,
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  }

});
