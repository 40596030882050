
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let SmallGroups = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="265.62" height="192.86" viewBox="0 0 265.62 192.86" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="_3" data-name="3">
      <g>
        <g>
          <path d="M234.86,41.66A20.83,20.83,0,1,0,214,20.83,20.82,20.82,0,0,0,234.86,41.66Z"/>
          <path d="M184,57.1a20.83,20.83,0,1,0-20.83-20.83A20.83,20.83,0,0,0,184,57.1Z"/>
          <path d="M31.53,41.66A20.83,20.83,0,1,0,10.69,20.83,20.82,20.82,0,0,0,31.53,41.66Z"/>
          <path d="M82.09,57.1A20.83,20.83,0,1,0,61.26,36.27,20.83,20.83,0,0,0,82.09,57.1Z"/>
          <circle cx="132.81" cy="50.39" r="20.83"/>
        </g>
        <path d="M148.59,75h-7.27a20.4,20.4,0,0,1-17,0H117a15.4,15.4,0,0,0-15.47,15.88l1,36.52a16.4,16.4,0,0,0,10.75,14.89v39.94a10.59,10.59,0,0,0,10.59,10.59h17.92a10.59,10.59,0,0,0,10.58-10.59V142.33a16.4,16.4,0,0,0,10.76-14.89l.94-36.52A15.39,15.39,0,0,0,148.59,75Z"/>
        <g>
          <path d="M199.31,60.94h-7.13a20,20,0,0,1-16.68,0h-7.12a15.07,15.07,0,0,0-13.8,8.82,20.29,20.29,0,0,1,14.41,20l-.23,8.9a140,140,0,0,0,15.08.81A135.06,135.06,0,0,0,214,96.18l.5-19.68A15.08,15.08,0,0,0,199.31,60.94Z"/>
          <path d="M96.35,89.79a20.25,20.25,0,0,1,14.06-19.91,15.08,15.08,0,0,0-13.85-8.94H89.44a20,20,0,0,1-16.68,0H65.63A15.09,15.09,0,0,0,50.47,76.5L51,96.18A135.06,135.06,0,0,0,81.1,99.5a139.54,139.54,0,0,0,15.48-.85Z"/>
          <path d="M45.88,75.47A20.28,20.28,0,0,1,59.94,55.56a15.07,15.07,0,0,0-13.85-8.93H39a20,20,0,0,1-16.68,0H15.16A15.07,15.07,0,0,0,0,62.18l.5,19.69a135.06,135.06,0,0,0,30.12,3.32,142,142,0,0,0,15.48-.85Z"/>
          <path d="M250.45,46.39h-7.12a20.12,20.12,0,0,1-16.68,0h-7.12a15.07,15.07,0,0,0-13.8,8.82,20.29,20.29,0,0,1,14.41,20l-.23,8.91A142.66,142.66,0,0,0,235,85a135.06,135.06,0,0,0,30.12-3.32l.5-19.69A15.08,15.08,0,0,0,250.45,46.39Z"/>
        </g>
      </g>
    </g>
  </g>
</SvgIcon>);

export default SmallGroups;
