import React, { Component } from "react";
import {
    Typography,
    Card,
    CardMedia,
    Collapse,
    IconButton
} from '@material-ui/core';
import { fetchWistiaVideoThumbnail, fetchVimeoVideoThumbnail, isVideo } from '../../utils/commonUtil'
import classNames from 'classnames';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ContextService from '../../services/context';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { GoalIcon } from '../../components/icons';
import FEATURES from '../../features';

const contextService = new ContextService()
export default class ActivityCard extends Component {
    state = { expanded: false };

    componentDidMount = async () => {
        this.loadThumbnail();
    };

    loadThumbnail = async () => {
        this.setState({ thumbnailLoading: true });
        if (this.props.activity.videoType === "vimeo") {
            const res = await fetchVimeoVideoThumbnail(this.props.activity);
            if (this.props.activity.videoId === '501056614') {
                console.log(this.props.activity, res);
            }
            this.setState({ vimeo_thumb: res });
        }

        if (this.props.activity.videoType === "wistia") {
            const res = await fetchWistiaVideoThumbnail(this.props.activity);
            this.setState({ wistia_thumb: res });
        }
        this.setState({ thumbnailLoading: false });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { activity } = this.props;
        const { thumbnailLoading, vimeo_thumb, wistia_thumb } = this.state;
        if ((activity.videoType === 'vimeo' || activity.videoType === 'wistia') && !thumbnailLoading && !vimeo_thumb && !wistia_thumb) {
            this.loadThumbnail();
        }
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleDeleteClick() {
        this.props.delete(this.props.activity);
    }

    handleViewClick = () => {
        const { isSliding } = this.props;
        if(!isSliding) this.props.view(this.props.activity);
    }

    handleEditClick() {
        this.props.edit(this.props.activity);
    }

    handleRecordClick(isGoal){
        this.props.recordResults(this.props.activity, isGoal)
    }

    getVimeoThumbnail = async (vid) => {
        return "https://i.vimeocdn.com/video/721904228_295x166.webp"
    }

    getCardThumbnail = () => {
        const {  teamLogo, logo, activity } = this.props;
        const { wistia_thumb , vimeo_thumb} = this.state;
        let thumbnailImage = teamLogo || logo;
        if(!!activity.thumbnail || !!activity.thumbnailBase64){
            thumbnailImage = activity.thumbnailBase64 || activity.thumbnail;
        }
        else if (!!activity.attachment && (activity.attachment.includes('png') || activity.attachment.includes('jpg'))){
            thumbnailImage = activity.attachment;
        }
        else if(!!activity.videoType){
            switch(activity.videoType){
                case 'youtube': thumbnailImage = `http://img.youtube.com/vi/${activity.videoId}/0.jpg`; break;
                case 'vimeo': thumbnailImage = vimeo_thumb; break;
                case 'wistia':  thumbnailImage = wistia_thumb; break;
            }
        }        
        return thumbnailImage;
    }

    render() {
        const { currentTeam, teamLogo, appContext, userContext, logo, included, activity, tenantFeatures = new Set() } = this.props;
        // see componentDidMount for how vimeo_thumb is retrieved
        let hasActivityEditPermission = !appContext.isGuardian && !appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditActivities')
        let hasGoalPermission = tenantFeatures.has(FEATURES.GOALS);
        return (
            <div className={"content-box"}>
                <Card className={included ? classNames("activityListingCard", "includedActivity") : "activityListingCard"}>
                    <div className={included ? "selectedOverlay" : null}>
                        <div>
                            {appContext.isAdmin || userContext.isImpersonating &&
                                <textarea
                                    disabled={true}
                                    value={activity.id}
                                    ref={(textarea) => this.textArea = textarea}
                                />
                            }
                            <CardMedia
                                image={this.getCardThumbnail()}
                                className={"media"}
                                onClick={this.handleViewClick}
                            />
                            <div className={"contentActionContainer"}>
                                <Typography style={{ color: 'white', fontSize: '0.75em', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 10 }} variant="headline" component="h2">
                                    {activity.name}
                                </Typography>
                                {appContext.isOwner || appContext.isHeadCoach || appContext.isCoach|| appContext.isAdmin ?
                                <IconButton
                                    onClick={this.handleExpandClick}
                                    aria-expanded={this.state.expanded}
                                    aria-label="Show more">
                                    {this.state.expanded ? <ExpandLessIcon style={{ color: "white" }} /> : <ExpandMoreIcon style={{ color: "white" }} />}
                                </IconButton>
                                :
                                null}
                            </div>
                        </div>
                        <Collapse
                            in={this.state.expanded}
                            className={"descriptionContainer"}
                        >
                            {hasActivityEditPermission && hasGoalPermission &&  activity.scoreType != 'participation' &&
                               <Tooltip title="Goal"> 
                                    <IconButton onClick={() => this.handleRecordClick(true)}>
                                        <GoalIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {hasActivityEditPermission &&
                             <Tooltip title={"Delete"}> 
                                <IconButton
                                    onClick={() => { if(window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteClick() }}
                                    aria-label="Delete"
                                    className={"deleteIcon"}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            }
                              {!appContext.isGuardian && (activity.category !== 'education' && activity.category != 'coach') ?
                              <Tooltip title={"Record Score"}> 
                                <IconButton
                                    onClick={() => this.handleRecordClick()}
                                    aria-label="Record"                                   
                                >
                                    <AssignmentIcon />
                                </IconButton>
                                </Tooltip> 
                            :null}
                            {hasActivityEditPermission &&
                            <Tooltip title={"Edit"}> 
                                <IconButton
                                    onClick={() => this.handleEditClick()}
                                    aria-label="Edit"
                                    className={"editIcon"}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            }
                        </Collapse>
                    </div>
                </Card>
            </div>
        )
    }
}