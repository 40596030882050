import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LocationIcon = (props) => (
  <SvgIcon width="12" height="12" viewBox="0 0 10 14" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99999 0.212891C2.28733 0.212891 0.0848999 2.41532 0.0848999 5.12798C0.0848999 5.91814 0.496363 7.03175 1.09764 8.19476C2.42261 10.7572 4.62995 13.6065 4.62995 13.6065C4.71866 13.721 4.85534 13.7879 4.99999 13.7879C5.14463 13.7879 5.28132 13.721 5.37002 13.6065C5.37002 13.6065 7.57736 10.7572 8.90233 8.19476C9.50361 7.03175 9.91507 5.91814 9.91507 5.12798C9.91507 2.41532 7.71265 0.212891 4.99999 0.212891ZM4.99999 3.02151C3.83745 3.02151 2.89352 3.96544 2.89352 5.12798C2.89352 6.29051 3.83745 7.23444 4.99999 7.23444C6.16252 7.23444 7.10645 6.29051 7.10645 5.12798C7.10645 3.96544 6.16252 3.02151 4.99999 3.02151Z" fill="#CED3D6" />
  </SvgIcon>
);

export default LocationIcon;

