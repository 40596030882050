import React, { Component } from "react";
import Header from "./header";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
class PageWrapper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      title,
      handleLogout,
      history,
      userContext,
      appContext,
      currentTeam,
      removePadding,
      setChildRef,
      fullWidth,
      loadingMessage,
      isPageLoading = false,
      canEditProfile = true,
      isInBeta = false,
      secondaryAction,
    } = this.props;
    return (
      <div
        className="pageContainer"
        style={removePadding ? {} : { padding: "15px" }}
      >
        <Header
          title={
            appContext.isAdmin || userContext.isImpersonating
              ? `${title} - ${currentTeam.id}`
              : title
          }
          handleLogout={handleLogout}
          history={history}
          fullWidth={fullWidth}
          canEditProfile={canEditProfile}
          isInBeta={isInBeta}
          secondaryAction={secondaryAction}
        />
        <div className="pageSubContainer" ref={setChildRef}>
          {isPageLoading ? (
            <div className={"contentLoadingOverlay"}>
              <CircularProgress />
              <h2>{loadingMessage}</h2>
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    session: { userContext, appContext },
  } = state;
  const currentTeam = _.find(
    userContext.appContextList,
    (c) => c.id === appContext.id
  );
  return {
    userContext,
    appContext,
    currentTeam: currentTeam || {},
  };
};

export default connect(mapStateToProps)(PageWrapper);
