import React, { Component } from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { Modal } from '@material-ui/core';
import IconButton from '../../components/iconButton'
import { getIconByName } from '../../utils/commonUtil';

const styles = {

}

class VideoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activateVideoBox: false
        }
    }

    componentWillReceiveProps(props) {
        if(props.isVideoBoxOpen && !this.props.isVideoBoxOpen){
            setTimeout(()=>{
                this.setState({activateVideoBox:true});
            },100);
        }
    }

    toggleVideoModel = () => {
        let { toggleVideoModel } = this.props;
        let { activateVideoBox } = this.state;
        if (!activateVideoBox) {
            toggleVideoModel();
            setTimeout(() => {
                this.setState({ activateVideoBox: true });
            }, 100)
        }
        else {
            this.setState({ activateVideoBox: false });
            setTimeout(() => {
                toggleVideoModel();
            }, 400);
        }
    }

    render() {
        const { videoUrl, isVideoBoxOpen } = this.props;
        const { activateVideoBox } = this.state;
        return (
            <Modal
                open={isVideoBoxOpen}
                onBackdropClick={() => this.toggleVideoModel()}>
                <div className={classnames('dashVideoContainer', { active: activateVideoBox })}>
                    <div className="closeIconContainer" onClick={() => this.toggleVideoModel()}>
                        {getIconByName("TimesCircle")}
                    </div>
                    <div className="dashVideoInnerContainer">
                        <div className="videoPlayer">
                            <ReactPlayer
                                controls={true}
                                className='react-player'
                                url={videoUrl}
                            />
                        </div>
                    {this.props.children}
                    </div>
                </div>

            </Modal>
        )
    }
}

export default withStyles(styles)(VideoModal)