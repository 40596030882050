import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from "lodash"
import shortid from "shortid";
import Vimeo from 'react-vimeo';
import { API, Auth } from "aws-amplify";
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import urlParser from "../../js-video-url-parser";
import WistiaVideo from "../../containers/video/wistia";
import PDF from 'react-pdf-js-infinite';
import classNames from 'classnames';
import { analyticsService, currentTimestamp } from '../../services/analyticsService';
import AthleteResultModal from '../activities/athleteResultModal'
import {
    Button,
    Grid,
    TextField,
    Modal,
    Typography,
    Card,
    Hidden,
    CardMedia,
    Collapse,
    IconButton,
    withStyles,
    Input,
    MenuItem,
    Select,
    CircularProgress,
    InputAdornment
} from '@material-ui/core';

import {
    saveActivity,
    getFileTypeSupportStatus,
    uploadActivityAttachment,
    resolveFilteredActivities,
    resolveActivitiesWithFilter,
    correctLegacyActivityData,
    getActivityGrouping
} from '../../utils/activityUtils'

import { FileType, isVideo } from '../../utils/commonUtil'

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LabelIcon from '@material-ui/icons/Label';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import logo from "../../assets/images/brand/logo-dark@2x.png";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PageWrapper from '../nav/pageWrapper';
import { Editor, EditorState, ContentState, convertFromRaw, convertFromHTML } from 'draft-js';
import VideoPlayer from '../activities/videoPlayer'; 

import "./resources.css";

// SLICK CAROUSEL //
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// ////////////// //

import ImageCropperModal from '../../components/modals/imageCropperModal'
import NewWorkoutActivityModal from '../../components/modals/newWorkoutActivityModal'
import NewActivityModule from '../newActivityModule';
import ContextService from '../../services/context';
import { getActivitiesByCategory } from '../../services/activitiesService'
import { getLocalizedString } from '../../utils/locale-utils';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import ActivityCard from '../activities/activityCard';
import { getLinkDecorator } from '../../components/draftjsEntities';
const contextService = new ContextService()

const styles = theme => ({
    plusButton: {
        backgroundColor: "#1354F9",
        color: theme.palette.primary.isDarkMode ? "#ffffff" : 'black'
    },
    textButton: {
        color: theme.palette.primary.isDarkMode ? "#ffffff" : 'black',
        height: '40px',
        marginRight: '3px',
        backgroundColor: "#1354F9",
    },
    button: {
        width: 50,
        position: 'absolute',
        top: 80,
        right: 20,
    },
    pageTitle: {
        textAlign: 'left',
        marginLeft: 60
    },
    activitiesContainer: {
        paddingLeft: 40,
        paddingRight: 40
    }
});

function NextArrow(props) {
    const { className, style, onClick } = props
    return (
        <IconButton
            style={{ ...style }}
            onClick={onClick}
        >
            <ChevronRightIcon style={{ fontSize: '2em' }} />
        </IconButton>
    );
};

function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <IconButton
            style={{ ...style }}
            onClick={onClick}
        >
            <ChevronLeftIcon style={{ fontSize: '2em' }} />
        </IconButton>
    );
};

class CoachResources extends NewActivityModule {
    constructor(props) {
        super(props);

        this.state = {
            tag: {},
            files: [],
            score: null,
            open: false,
            activity: {},
            minutes: null,
            seconds: null,
            teamLogo: null,
            isSliding: false,
            isLoading: false,
            activityTags: [],
            loadingTags: false,
            overlayMessage: '',
            filterableTags: [],
            activitiesToDelete: [],
            activeLabelFilter: 'all',
            isCropperModalOpen: false,
            uploadingVideoFile: false,
            isActivityModalOpen: false,
            thumbnailUploadProgress: 0,
            videoFileUploadProgress: 0,
            isDeletingActivities: false,
            isActivityModalOpen: false,
            taggedActivityGroupings: [],
            displaySuccessOverlay: false,
            uploadingThumbnailFile: false,
            filteredTaggedActivityGroupings: [],
            filteredAthletes: [],
            athleteResultModalOpen: false,
            athletes:[]
        };
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true, loadingMessage: 'grabbing your resources...' })
        const teamLogo = (this.props.currentTeam.tenant == 'vnn' || this.props.currentTeam.tenant == 'm1vnn') && this.props.currentTeam.organization && this.props.currentTeam.organization.logoUrl ? this.props.currentTeam.organization.logoUrl :
            this.props.currentTeam.logo && this.props.currentTeam.logo.includes('https') ?
                this.props.currentTeam.logo :
                this.props.currentTeam.logo ?
                    `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${this.props.currentTeam.legacyId ?
                        this.props.currentTeam.legacyId : this.props.currentTeam.id}/${this.props.currentTeam.logo}` : null;
        this.setState({ teamLogo });
        this.refreshActivities();
    }

    refreshActivities = async () => {
        const { currentTeam } = this.props;
        const { activeLabelFilter } = this.state;
        const coachResources = await getActivitiesByCategory(currentTeam.id, 'coach');

        await coachResources.map(async (activity, i) => {
            correctLegacyActivityData(activity)
        });

        let { filterableTags, taggedActivityGroupings, filteredTaggedActivityGroupings } = getActivityGrouping(coachResources, activeLabelFilter);
        this.setState({
            isLoading: false,
            loadingMessage: null,
            activities: coachResources,
            isActivityModalOpen: false,
            filterableTags: filterableTags,
            filteredActivities: coachResources,
            taggedActivityGroupings,
            filteredTaggedActivityGroupings
        })
    }



    deleteActivity = activityId => {
        return API.del("activities", `/activities/${activityId}`)
    }

    handleDelete = async activity => {
        const deletedActivity = await this.deleteActivity(activity.id);
        this.setState({ isLoading: true, loadingMessage: 'refreshing your activities...' })
        this.refreshActivities()
    }

    handleMassDelete = async () => {
        const { activitiesToDelete } = this.state
        this.setState({
            isLoading: true,
            activitiesToDelete: [],
            isDeletingActivities: false,
            loadingMessage: `deleting ${activitiesToDelete.length} activities...`
        })
        await Promise.all(activitiesToDelete.map(async activity => {
            const deletedActivity = await this.deleteActivity(activity.id);
        }))
        this.refreshActivities()
    }

    handleSelectActivity = activity => {
        const { isDeletingActivities, activitiesToDelete } = this.state
        const { currentTeam } = this.props;
        if (isDeletingActivities) {
            let included = _.find(activitiesToDelete, activity)
            this.setState({
                activitiesToDelete:
                    included ? _.without(activitiesToDelete, included) :
                        [...activitiesToDelete, activity]
            })
        }
        else {
            this.setState({
                open: true,
                activity: activity
            });
            analyticsService.trackEvent('Activity View', {
                programID: currentTeam.id,
                activitySlug: activity.name,
                activityLibrary: 'Coaching Resources',
                date: currentTimestamp
            })
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            activity: {}
        });
    };

    getCroppedAttachment = async (attachment) => {
        this.state.files.push({ type: 'thumbnail', file: attachment });
        this.setState({ isCropperModalOpen: false });
    }

    filterByLabel = event => {
        const { taggedActivityGroupings } = this.state;
        let filteredActivities = null;
        let newFilterLabel = event.target.value;
        let filteredTaggedActivityGroupings = null;

        if (newFilterLabel != 'all') {
            filteredTaggedActivityGroupings = _.filter(taggedActivityGroupings, group => group.label.toLowerCase() == newFilterLabel.toLowerCase());
        }

        this.setState({
            activeLabelFilter: newFilterLabel,
            filteredTaggedActivityGroupings: filteredTaggedActivityGroupings ? filteredTaggedActivityGroupings : taggedActivityGroupings
        })
    }

    searchActivities = event => {
        const { activities, filteredActivities, category } = this.state;
        this.setState({ activeLabelFilter: 'all' });
        if (event.target.value) {
            this.setState({
                searchString: event.target.value,
                filteredActivities: _.filter(filteredActivities, a => a.name && a.name.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchString: null,
                filteredActivities: _.filter(activities, a => a.category == 'coach')
            })
        }
    }

    handleSwipe = () => {
        this.setState({ isSliding: true });
    }

    handleAfterChange = () => {
        this.setState({ isSliding: false })
    }

    activityIncluded = activity => {
        return _.find(this.state.activitiesToDelete, a => a.id === activity.id);
    }

    /* ######################################################* Activity Edit Modal Methods START ######################################################*/
    //TODO: Move to activityModule
    handleActivitySave = async (addingAnother) => {
        const { currentTeam, currentUser } = this.props;
        const { activity, activityTags, activeLabelFilter } = this.state;
        let clonedActivity = {...activity};
        this.setState({ displaySuccessOverlay: true, overlayMessage: (clonedActivity.isNewActivity ? "Saving Activity" : "Updating Activity") })
        clonedActivity.id = await saveActivity(clonedActivity, currentUser, currentTeam, _.uniq(activityTags), false, this.uploadingFile);
        let newActivities = this.state.activities || [];
        let isNewActivity = clonedActivity.isNewActivity;
        if (clonedActivity.category == 'coach') {
            if (isNewActivity) {
                clonedActivity.isNewActivity = false;
                newActivities = newActivities.concat(clonedActivity);
            }
            else {
                let existingActivityIndex = newActivities.findIndex(element => element.id === clonedActivity.id);
                newActivities[existingActivityIndex] = clonedActivity;
            }

            let { filterableTags, taggedActivityGroupings, filteredTaggedActivityGroupings } = getActivityGrouping(newActivities, activeLabelFilter);
            this.setState({ activities: newActivities, filterableTags, taggedActivityGroupings, filteredTaggedActivityGroupings });
        }
        this.setState({ overlayMessage: isNewActivity ? "Activity Created" : "Activity Updated" })
        setTimeout(() => {
            this.toggleActivityModal(null, addingAnother);
        }, 2000);
    }

    /* ######################################################* Activity Edit Modal Methods END ######################################################*/

    render() {
        const { classes, appContext, userContext, currentTeam,  tenantFeatures } = this.props;
        const {
            fileName,
            teamLogo,
            isSliding,
            isLoading,
            attachment,
            loadingTags,
            activityTags,
            searchString,
            open = false,
            activity = {},
            loadingMessage,
            overlayMessage,
            activities = [],
            activeLabelFilter,
            uploadingVideoFile,
            isCropperModalOpen,
            isActivityModalOpen,
            filterableTags = [],
            isDeletingActivities,
            displaySuccessOverlay,
            uploadingThumbnailFile,
            activitiesToDelete = [],
            filteredActivities = [],
            filteredTaggedActivityGroupings,
            videoFileUploadProgress,
            thumbnailUploadProgress,
            invalidVideoUrl,
            userResults,
            savingActivityResults,
            isLoadingResultData,
            athleteSearchString,
            athleteResultModalOpen,
            filteredAthletes
        } = this.state;

        const carouselSettings = {
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 5,
            onSwipe: this.handleSwipe,
            afterChange: this.handleAfterChange,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <PageWrapper title={getLocalizedString("COACHING_RESOURCES")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <div className={"activityListOuterContainer"}>                    
                    <React.Fragment>
                        <ImageCropperModal
                            fileName={fileName}
                            attachment={attachment}
                            close={this.toggleModal}
                            open={isCropperModalOpen}
                            title={"Customize Avatar"}
                            getCroppedAttachment={this.getCroppedAttachment}
                        />
                        <div className={"pageTitleContainer"}>
                            <h1 className={"pageTitle"}></h1>
                            <TextField
                                id="search"
                                className={"searchTextField"}
                                onChange={this.searchActivities}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Select
                                onChange={this.filterByLabel}
                                className={"searchTextField"}
                                input={<Input id="scoreType" />}
                                value={activeLabelFilter ? activeLabelFilter : ""}
                            >
                                <MenuItem value={'all'}>
                                    {'all'}
                                </MenuItem>
                                {filterableTags.map((tag, index) => (
                                    <MenuItem
                                        key={index}
                                        value={tag.label}>
                                        {tag.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <div className={"titleButtonContainer"}>
                                <Button
                                    mini
                                    variant="fab"
                                    aria-label="delete"
                                    title="Add Activity"
                                    style={{ color: 'white' }}
                                    className={classes.plusButton}
                                    onClick={() => this.setState({ isDeletingActivities: !isDeletingActivities })}
                                >
                                    <DeleteIcon className={"icon"} />
                                </Button>
                                <Button
                                    mini
                                    variant="fab"
                                    aria-label="add"
                                    title="Add Activity"
                                    className={classes.plusButton}
                                    style={{ color: 'white', marginLeft: 10 }}
                                    onClick={() => this.toggleActivityModal()}
                                >
                                    <AddIcon className={"icon"} />
                                </Button>
                            </div>
                        </div>
                        <div className={"selectContainer"}>
                            {isDeletingActivities &&
                                <div className={"deleteButtonContainer"}>
                                    {activitiesToDelete.length ?
                                        <Button
                                            aria-label="add"
                                            variant="contained"
                                            title="Select Teams"
                                            className={classes.textButton}
                                            onClick={() => this.handleMassDelete()}
                                        >
                                            Delete {activitiesToDelete.length} Resource{activitiesToDelete.length == 1 ? '' : 's'}
                                        </Button>
                                        :
                                        <p style={{ margin: 0 }}>
                                            Select some resources to delete...
                            </p>
                                    }
                                </div>
                            }
                            {!searchString && filteredTaggedActivityGroupings.length ? _.orderBy(filteredTaggedActivityGroupings, ['id', group => group.label.toLowerCase()]).map((group, index) => {
                                if (group.activities.length) {
                                    return <Grid
                                        container
                                        key={index}
                                        spacing={0}
                                        style={{ paddingBottom: 10 }}
                                    >
                                        <Grid item xs={12} style={{ textAlign: 'left', backgroundColor: '#28272C', padding: 0, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <LabelIcon style={{ color: 'white' }} />
                                            <h2 style={{ fontSize: '1.1em', color: 'white', paddingLeft: 5 }}>
                                                {
                                                    group.label
                                                        ?
                                                        group.label.toLowerCase().replace(/-/g, ' ').split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
                                                        :
                                                        "Unlabeled"
                                                }
                                                <span style={{ color: 'gray', fontSize: '0.8em', fontWeight: 'lighter', marginLeft: 5 }}>
                                                    {group.activities.length ? `${group.activities.length} activit${group.activities.length == 1 ? 'y' : 'ies'}` : '0 activities'}
                                                </span>
                                            </h2>
                                        </Grid>
                                        <Grid item xs={12} className={group.activities.length ? "left-align-slick" : "center-align-slick"}>
                                            <Slider
                                                arrows={true}
                                                infinite={false}
                                                speed={300}
                                                slidesToShow={5}
                                                slidesToScroll={3}
                                                //slidesToShow={group.activities.length > 5 ? 5 : group.activities.length}
                                                //slidesToScroll={group.activities.length > 5 ? 5 : group.activities.length}
                                                prevArrow={<PrevArrow />}
                                                nextArrow={<NextArrow />}
                                                onSwipe={this.handleSwipe}
                                                afterChange={this.handleAfterChange}
                                                responsive={[
                                                    {
                                                        breakpoint: 1024,
                                                        settings: {
                                                            slidesToShow: 3,
                                                            slidesToScroll: 3,
                                                        }
                                                    },
                                                    {
                                                        breakpoint: 768,
                                                        settings: {
                                                            slidesToShow: 2,
                                                            slidesToScroll: 2
                                                        }
                                                    },
                                                    {
                                                        breakpoint: 600,
                                                        settings: {
                                                            slidesToShow: 1,
                                                            slidesToScroll: 1
                                                        }
                                                    },
                                                ]}
                                                className={group.activities.length > 5 ? "multipleElements" : null}
                                            >
                                                {group.activities.length ? _.orderBy(group.activities, 'name').map((activity, index) => {
                                                    return <div key={index}>
                                                        <ActivityCard
                                                            logo={logo}
                                                            teamLogo={teamLogo}
                                                            activity={activity}
                                                            isSliding={isSliding}
                                                            view={this.handleSelectActivity}
                                                            appContext={appContext}
                                                            userContext={userContext}
                                                            currentTeam={currentTeam}
                                                            delete={this.handleDelete}
                                                            edit={() => this.toggleActivityModal(activity)}
                                                            included={this.activityIncluded(activity)}
                                                            recordResults={this.toggleAthleteResultModal}
                                                            tenantFeatures={tenantFeatures}
                                                        />
                                                    </div>
                                                }) :
                                                    <h2>Add some resources for your coaches...</h2>}
                                            </Slider>
                                        </Grid>
                                    </Grid>
                                }
                                return null
                            })
                                :
                                filteredActivities.length ?
                                    <Grid
                                        container
                                        spacing={16}
                                    >
                                        {filteredActivities.map((activity, index) => {
                                            return <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                key={index}
                                            >
                                                <ActivityCard
                                                    teamLogo={teamLogo}
                                                    activity={activity}
                                                    view={this.handleSelectActivity}
                                                    appContext={appContext}
                                                    userContext={userContext}
                                                    currentTeam={currentTeam}
                                                    delete={this.handleDelete}
                                                    edit={() => this.toggleActivityModal(activity)}
                                                    included={this.activityIncluded(activity)}
                                                    recordResults={this.toggleAthleteResultModal}
                                                    tenantFeatures={tenantFeatures}
                                                />
                                            </Grid>
                                        })}
                                    </Grid>
                                    :
                                    <h2>Add some resources for your coaches...</h2>
                            }
                        </div>
                        <ActivityModal
                            activity={activity}
                            close={this.handleClose}
                            open={open}
                            appContext={appContext}
                            delete={this.handleDelete}
                            edit={() => this.toggleActivityModal(activity)}
                        />
                        <NewWorkoutActivityModal
                            tags={activityTags}
                            activity={activity}
                            activities={activities}
                            loadingTags={loadingTags}
                            open={isActivityModalOpen}
                            save={this.handleActivitySave}
                            addTag={this.handleActivityTag}
                            overlayMessage={overlayMessage}
                            currentTeam={this.props.currentTeam}
                            close={() => this.toggleActivityModal(open ? activity : null)}
                            handleFileSelect={this.handleFileSelect}
                            uploadingVideoFile={uploadingVideoFile}
                            removeActivityVideo={this.removeActivityVideo}
                            removeTag={tag => this.removeActivityTag(tag)}
                            displaySuccessOverlay={displaySuccessOverlay}
                            handleVideoUrlChange={this.handleVideoUrlChange}
                            uploadingThumbnailFile={uploadingThumbnailFile}
                            videoFileUploadProgress={videoFileUploadProgress}
                            thumbnailUploadProgress={thumbnailUploadProgress}
                            handleThumbnailSelect={this.handleThumbnailSelect}
                            handleChange={name => this.handleActivityChange(name)}
                            cancelUpload={this.cancelUpload}
                            invalidVideoUrl={invalidVideoUrl}
                        />
                         <AthleteResultModal
                            isGoal = {true}
                            activity={activity}
                            userResults={userResults}
                            athletes={_.uniqBy(filteredAthletes, 'id')}
                            open={athleteResultModalOpen}
                            searchString={athleteSearchString}
                            searchAthletes={this.searchAthletes}
                            close={this.toggleAthleteResultModal}
                            savingActivityResults={savingActivityResults}
                            saveActivityResults={this.saveActivityResults}
                            handleActivityResultChange={(athleteId) => this.handleActivityResultChange(athleteId)}
                            isLoading={isLoadingResultData}
                        />
                    </React.Fragment>
                </div>
            </PageWrapper>
        );
    }
}


//TODO: Move activity modal of team and director screen to common place.
class ActivityModal extends Component {
    constructor(props){
        super(props);
        this.decorator = getLinkDecorator();
        this.state = { editorState: EditorState.createEmpty(this.decorator), loaded: false };
    }
  

    componentWillReceiveProps(nextProps) {
        // this is to catch legacy descriptions and format them
        if (nextProps.activity.description && nextProps.activity.description.split('{').length == 1 && !this.state.loaded) {
            const descriptionToRender = `<div>${nextProps.activity.description}</div>`
            const blocksFromHTML = convertFromHTML(descriptionToRender);
            let newState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            this.setState({
                editorState: EditorState.createWithContent(newState, this.decorator),
                loaded: true
            })
        }
        // this is for editing descriptions created with out new text editor
        else if (nextProps.activity.description && !this.state.loaded) {
            try {
                let newState = convertFromRaw(JSON.parse(nextProps.activity.description))
                this.setState({
                    editorState: EditorState.createWithContent(newState, this.decorator),
                    loaded: true
                })
            }
            catch (e) {

            }
        }
    }

    handleDeleteClick() {
        this.props.delete(this.props.activity);
    }

    handleEditClick() {
        this.props.edit();
    }

    render() {
        const { activity, open, appContext, currentTeam } = this.props;
        const showVideoPlayer = !!activity && (isVideo(activity.attachment) || (!!activity.videoType && (activity.legacyVideoId || activity.videoId)));
        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"listViewActivityModalPaper"}>
                        <div className={"modalContent"}>
                            <div className={"modalHeaderContainer"}>
                                <h2 className={"modalHeaderText"}>{activity.name}</h2>
                            </div>
                            <div>
                                {showVideoPlayer
                                    ? <VideoPlayer activity={activity} />
                                    : activity.attachment && activity.attachment.includes('.pdf') ?
                                        <div>
                                            <form className="fileDownloadButton" method="get" action={activity.attachment}>
                                                <button type="submit">Download</button>
                                            </form>
                                            <div className={"pdfContainer"}>
                                                <PDF style={{ width: '100%', height: '100%' }} scale={0.6} file={activity.attachment} />
                                            </div>
                                        </div>
                                        : <div className={"imageContainer"}>
                                            <img className={"image"} src={activity.attachment ? activity.attachment : logo} />
                                        </div>}
                            </div>
                            <div id={"content"}>
                                <div className={"editor"} onClick={this.focus}>
                                    <Editor
                                        ref={"editor"}
                                        editorState={this.state.editorState}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    <div className={"modalFooterContainer"}>
                        {!appContext.isGuardian && !appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditActivities') ?
                            <div>
                                <IconButton
                                    title="Delete Event"
                                    onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteClick() }}
                                    aria-label="edit"
                                    variant="contained">
                                    <DeleteIcon className={"editIcon"} />
                                </IconButton>
                                <IconButton
                                    onClick={() => this.handleEditClick()}
                                    aria-label="Edit"
                                    className={"editIcon"}
                                >
                                    <EditIcon />
                                </IconButton>
                            </div>
                            : null}
                        <div>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                className={"outlinedButton"}
                                onClick={this.props.close}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

CoachResources.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { session: { userContext, appContext }, tenant } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        currentTeam: currentTeam||{},
        currentUser: userContext.user,
        appContext: appContext,
        userContext: userContext,
        tenantFeatures: tenant.features
    };
}

export default withStyles(styles)(connect(mapStateToProps)(CoachResources));
