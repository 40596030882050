import React, { Component } from 'react'
import {
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    formControl: {
        border: "1px solid #DDE2E5",
        borderRadius: "4px",
        height: "48px",
        paddingLeft: "15px",
        flexGrow: 1,
        width: "100%",
        textAlign: "left",
        marginBottom: "24px",
        fontFamily: 'Poppins',
        backgroundColor: '#FFF'
    },
    labelRoot: {
        top: "17px",
        transform: "translate(0, 0px) scale(1)",
        left: "15px",
        color: "#0B0B0B",
        opacity: "0.3",
        fontFamily: 'Poppins',
        fontSize:'14px'
    },
    labelFocused: {
        transform: "translate(0, 1.5px) scale(0.75)",
        top: "4px",
        color: "#0B0B0B !important",
        opacity: "0.3"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily:"Poppins"
    },
    icon: {
        top: -3
    },
    selectMenu: {
        '&:focus': {
            background: "transparent"
        }
    },
});


const FloatingSelect = (props) => {
    const { value, onChange, overrideContainerCss, classes, selectInput, label, required, disabled, overrideLabelCss } = props
    return (
        <FormControl disabled={disabled} className={classNames(classes.formControl, overrideContainerCss)}>
            <InputLabel
            disabled={disabled}
            shrink={!!value}
             classes={{
                root: classNames(classes.labelRoot, overrideLabelCss),
                shrink: classes.labelFocused
            }}>
                {label}
            </InputLabel>
            <Select
                required={required}
                disableUnderline
                value={value}
                onChange={onChange}
                input={selectInput}
                classes={{
                    root: classes.root,
                    icon: classes.icon,
                    selectMenu: classes.selectMenu
                }}
            >
                {props.children}
            </Select>
        </FormControl>
    )
}

export default withStyles(styles)(FloatingSelect);