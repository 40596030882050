import React, { Component } from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import EditIcon from '@material-ui/icons/Edit';

import EnhancedTableHeader from '../../components/enhancedTableHeader'
import EnhancedTableToolbar from '../../components/enhancedTableToolbar'
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';

import { API } from "aws-amplify";

import moment from 'moment'
import logo from "../../assets/images/logo-dark.png"
import PageWrapper from '../nav/pageWrapper';

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginLeft:35,
    float:"left"
  },
});

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class AdminInvoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      showEditModal:false,
      page: 0,
      isLoading:false,
      loadingMessage:"Loading Invoices...",
      rowsPerPage: 5,
      invoice:{items:{}, organization:{name:""}},
      headers:[
        { id: 'organization.name', numeric: false, disablePadding: false, label: 'Organization' },
        { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
        { id: 'dueAt', numeric: false, disablePadding: false, label: 'Due' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created' },
        { id: 'paidAt', numeric: false, disablePadding: false, label: 'Status' },
        { id: '', numeric: false, disablePadding: false, label: '' },
      ]
    };
  }

  async componentDidMount() {
    this.setState({isLoading:true})
    var data = await this.getInvoices()
    console.log('Data ', data)
    this.setState({data, isLoading:false})

  }

  toggleEditModal = () =>{
    this.setState({showEditModal: !this.state.showEditModal})
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    const { selected, data } = this.state;
    // const selectedIndex = data.indexOf(id);
    this.setState({ selected: id });
  };

  handleEditClick = (invoice) => {
    console.log('Invoice ==== ', invoice)
    this.setState({invoice: invoice, showEditModal: true })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getInvoices() {
    return API.get("admin", `/admin/invoices`);
  }

  isSelected = id => this.state.selected === id;

  render() {
    const { classes } = this.props;
    const { data = [], order, orderBy, selected, rowsPerPage, page, headers, isLoading, loadingMessage } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <PageWrapper title="Invoices" {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
        <div >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={9} style={{justify:"left"}}>
              <h1 className={classes.header}></h1>
            </Grid>
            <Grid item xs={12} sm={3} style={{paddingLeft:50, paddingRight:50}}>
            </Grid>
          </Grid>
          <Paper className={classes.root}>
            <EnhancedTableToolbar numSelected={0} title={'Invoices'} />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHeader
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  headers={headers}
                />
                <TableBody>
                  {data
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n, i) => {
                      const isSelected = this.isSelected(n.id);
                      return (
                        <TableRow
                          hover
                          onClick={event => this.handleClick(event, n.id)}
                          role="checkbox"
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell component="th" scope="row">
                            {n.organization && n.organization.name ? n.organization.name : "No Org Name"}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {n.amount}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {moment(n.dueAt).format('L')}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {moment(n.createdAt).fromNow()}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {
                              n.paidDate !== "true"
                              ?
                              <p>open</p>
                              :
                              <p>closed</p>
                            }
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Button onClick={() => this.handleEditClick(n)}>View</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        <EditModal
          invoice={this.state.invoice}
          open={this.state.showEditModal}
          save={this.handleSave}
          sendInvoice={this.handleSendInvoice}
          close={this.toggleEditModal}
          />
      </PageWrapper>
    );
  }
}

class EditModal extends Component {
    state = { activityId: null, value: 0, invoice:{items:{}, organization:{name:""}}}
//     state = { activityId: null, value: 0, invoice:{amount: 300, canceledAt: null,
// createdAt: 1509526800000,
// deletedAt: null,
// dueAt: 1512086400000,
// id: "vHEjcgcQ-bZ",
// internalId: "201700208",
// isPaid: false,
// items:
// {0:
// {description: null,
// entityId: 344,
// entityType: "organization",
// legacyId: 208,
// price: "300.00",
// product: "teams-1",
// type: "subscription"}},
// legacyId: 208,
// legacyOrganizationId: 344,
// migratedAt: 1544231855214,
// net: 30,
// notes: "Your program(s): Manistique Emeralds Football",
// organization:{
// address: {country: "USA", address2: null, city: " Mani", address1: "100 N. Cedar St.", postalCode: "49854"},
// billing:
// {contacts:
// {'0':
// {emailAddress: "stephanie.robison21@gmail.com",
// isDefault: true,
// name: "Dennis Beaman",
// phone: "(906) 450-1997"}},
// isAutomatedBilling: false,
// isLockedOut: true,},
// createdAt: 1484941568000,
// id: "60j_iNmJluCA",
// isActive: true,
// legacyId: 344,
// logo: null,
// migratedAt: 1543356309056,
// name: "Manistique Emeralds",
// subscription: {renewalDate: 1543622400000, plan: "teams-1", startDate: 1480464000000, lastChargedDate: null, price: "300.00"},
// type: "School",
// updatedAt: 1514714400000,
// },
// organizationId: "60j_iNmJluCA",
// paidAt: null,
// reminders:
// {'0': {sentAt: 1512898200000},
// '1': {sentAt: 1513503000000},
// '2': {sentAt: 1514107800000},
// '3': {sentAt: 1514626200000}},
// type: "subscription",
// updatedAt: 1514626200000} };

    componentWillReceiveProps(nextProps) {
        // this is to catch legacy descriptions and format them
        // this.setState({editorState: EditorState.createEmpty()})
        // if(nextProps.activity.description && nextProps.activity.description.split('{').length == 1 && this.state.activityId != nextProps.activity.id) { // this is to catch legacy descriptions and format them
        //     const descriptionToRender = `<div>${nextProps.activity.description}</div>`
        //     const blocksFromHTML = convertFromHTML(descriptionToRender);
        //     let newState = ContentState.createFromBlockArray(
        //       blocksFromHTML.contentBlocks,
        //       blocksFromHTML.entityMap
        //     );
        //     this.setState({
        //         editorState: EditorState.createWithContent(newState),
        //         activityId: nextProps.activity.id
        //     })
        // }
        // else if(nextProps.activity.description && this.state.activityId != nextProps.activity.id) { // this is for editing descriptions created with our new text editor
        //     let newState = convertFromRaw(JSON.parse(nextProps.activity.description))
        if(nextProps.invoice){
          this.setState({
              invoice: nextProps.invoice
          })
        }
        // }
    }

    handleSendInvoiceClick() {
        this.props.sendInvoice(this.state.invoice);
    }

    handleSaveClick() {
        this.props.save();
    }

    handleTabValueChange = (product, value) => {
        this.setState({ value });
    };

    handleChange = name => event => {
        var invoice = this.state.invoice;
        invoice[name] = event.target.value
        this.setState({
            invoice
        });
    };

    handleChangeProduct = (key) => event => {
      console.log('key, ', key)
      console.log('event, ', event)
      var invoice = this.state.invoice;
      invoice.items[key].product = event.target.value
        this.setState({
            invoice
        });
    };
    handleChangeProductPrice = (key) => event => {
      console.log('key, ', key)
      console.log('event, ', event)
      var invoice = this.state.invoice;
      invoice.items[key].price = event.target.value
        this.setState({
            invoice
        });
    };
    handleChangeProductEntity= (key) => event => {
      var invoice = this.state.invoice;
      invoice.items[key].entityType = event.target.value
        this.setState({
            invoice
        });
    };

    render() {
        const { value, invoice } = this.state;
        const { open } = this.props;
        var keys = Object.keys(invoice.items)
        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"listViewActivityModalPaper"}>              
                      <div className={"modalContent"}>
                          <div className={"modalHeaderContainer"}>
                              <h2 className={"modalHeaderText"}>Invoice {invoice.id}</h2>
                          </div>
                      <Grid container spacing={8} style={{width:'100%', marginTop:15}}>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                id="invoice.organization.name"
                                label="Organization"
                                style={{width:"100%", flexGrow:1}}
                                value={invoice.organization.name ? invoice.organization.name : ""}
                                disabled={true}
                                // onChange={this.handleChange("type")}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                id="type"
                                label="Type"
                                style={{width:"100%", flexGrow:1}}
                                value={invoice.type ? invoice.type : ""}
                                disabled={true}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                id="dueAt"
                                label="Due Date"
                                style={{width:"100%", flexGrow:1}}
                                value={invoice.dueAt ? moment(invoice.dueAt).format("L") : ""}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                id="paidAt"
                                label="Paid Date"
                                style={{width:"100%", flexGrow:1}}
                                disabled={true}
                                value={invoice.paidAt && invoice.paidAt !== '0'  ? moment(invoice.paidAt) : ""}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="notes"
                                label="Notes"
                                style={{width:"100%", flexGrow:1}}
                                value={invoice.notes ? invoice.notes : ""}
                                onChange={this.handleChange("notes")}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="amount"
                                label="Total Amount"
                                style={{width:"100%", flexGrow:1}}
                                value={invoice.amount ? invoice.amount : ""}
                                onChange={this.handleChange("amount")}
                                margin={"dense"}
                            />
                        </Grid>
                        <p> <b>items:</b></p>
                        <Grid item xs={12} sm={12}>
                        {
                          keys.map(key => {
                            return(
                              <Grid container spacing={8} key={key}>
                                <Grid item xs={2}>
                                  <TextField
                                      id="entityType"
                                      label="Item"
                                      style={{width:"100%", flexGrow:1}}
                                      value={invoice.items[key].entityType ? invoice.items[key].entityType : ""}
                                      onChange={this.handleChangeProductEntity(key)}
                                      margin={"dense"}
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <TextField
                                      id="product"
                                      label="Product"
                                      style={{width:"100%", flexGrow:1}}
                                      value={invoice.items[key].product ? invoice.items[key].product : ""}
                                      onChange={this.handleChangeProduct(key)}
                                      margin={"dense"}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                      id="price"
                                      label="Price"
                                      style={{width:"100%", flexGrow:1}}
                                      value={invoice.items[key].price ? invoice.items[key].price : ""}
                                      onChange={this.handleChangeProductPrice(key)}
                                      margin={"dense"}
                                  />
                                </Grid>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                      </Grid>
                      </div>
                      <div className={"modalFooterContainer"}>
                          <div>
                              <div>
                                <Button  onClick={() => this.props.close()}>close</Button>
                                <Button  onClick={() => this.handleCancelInvoiceClick()}>Mark As Canceled</Button>
                                <Button  onClick={() => this.handlePaidInvoiceClick()}>Mark As Paid</Button>
                                <Button  onClick={() => this.handleSendInvoiceClick()}>Send Invoice</Button>
                                <Button type="contained" onClick={() => this.handleSaveClick()}>Save</Button>
                              </div>
                          </div>
                      </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

AdminInvoices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminInvoices);
