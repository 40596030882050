import React, { Component } from 'react'
import BorderedInput from '../borderedInput'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import { ClickAwayListener } from '@material-ui/core'
import classnames from 'classnames';
import './styles.css'

export default class EditableText extends Component {
    // constructor(props){
    //     super(props)
    //     this.state = { 
    //         isOpen: false,
    //         inputValue: props.value || ''
    //     }
    // }

    // componentWillReceiveProps(props){
    //     const { inputValue } = this.state
    //     if((!inputValue || inputValue == '') && props.value){
    //         this.setState({ inputValue: props.value })
    //     }
    // }

    // toggleOpen = () => {
    //     const { value } = this.props
    //     const { isOpen } = this.state
    //     this.setState({ 
    //         isOpen: !isOpen,
    //         inputValue: value
    //     })
    // }

    // handleDoneClick = () => {
    //     const { onDone } = this.props
    //     const { inputValue } = this.state
    //     onDone(inputValue)
    //     this.toggleOpen()
    // }

    // handleChange = event => {
    //     this.setState({ inputValue: event.target.value })
    // }

    render(){
        // const { isOpen, inputValue } = this.state
        const { value, placeholder, isSmall, isLargeEdit, handleChange, className } = this.props
        return(
            // <ClickAwayListener onClickAway={() => this.handleDoneClick()}>
                <div className={classnames(isSmall ? 'smallEditingTextContainer' : 'editingTextContainer', className)}>
                    <input value={value} placeholder={placeholder} onChange={handleChange}/>
                </div>
            // </ClickAwayListener>
        )
    }
}