import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../index.scss";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { getIconByName } from "../../../icons/utils";
import { colors } from "../../../utils/theme-utils";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  headerItem: {
    minHeight: 44,
    backgroundColor: "transparent",
    padding: `7px 15px !important`,
    "& p": {
      fontSize: 14,
      lineHeight: 1.2,
      fontFamily: "Poppins",
      fontWeight: 700,
      textTransform: "uppercase",
      color: `${colors.WHITE} !important`,
    },
    "& svg": {
      fontSize: 24,
      marginRight: `0px !important`,
      color: `${colors.WHITE} !important`,
    },
  },
  defaultItem: {
    backgroundColor: "transparent",
    padding: `7px 15px !important`,
    "& p": {
      fontSize: 13,
      fontFamily: "Poppins",
      fontWeight: 500,
      color: `${colors.GREY_40} !important`,
    },
    "& svg": {
      fontSize: 22,
      color: `${colors.GREY_40} !important`,
    },
    "&:hover": {
      backgroundColor: "transparent",
      "& p,svg": {
        color: theme.palette.secondary.isDarkMode
          ? "white !important"
          : `${theme.palette.secondary.main} !important`,
      },
    },
  },
  disableItem: {
    "& p": {
      color: `${colors.GREY_60} !important`,
    },
    "& svg": {
      color: `${colors.GREY_60} !important`,
    },
    "&:hover": {
      "& p,svg": {
        color: `${colors.GREY_60} !important`,
      }
    }
  },
  selectedItem: {
    padding: `11px 15px !important`,
    backgroundColor: theme.palette.secondary.main,
    "& p,svg": {
      color: theme.palette.secondary.isDarkMode
        ? "white !important"
        : "#28272C !important",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.90)",
      "& p,svg": {
        color: "white !important",
      },
    },
  },
  closed: {
    justifyContent: "center",
    "& svg": {
      marginRight: `0px !important`,
    },
  },
  betaItem: {
    width: 50,
    background: `${colors.GREY_40} !important`,
    borderRadius: `20px`,
    textAlign: "center",
    marginLeft: 10,
  },
  displayInline: {
    display: "inline-flex",
  },
  middleIcon: {
    "& svg": {
      height: "18px !important",
      width: "18px !important",
      marginRight: "45px !important",
    },
  },
  centerIcon: {
    "& svg": {
      width: "18px !important",
      height: "18px !important",
      marginRight: "25px !important",
    },
  },
  iconNextToLabel2: {
    "& svg": {
      width: "18px !important",
      height: "18px !important",
      marginRight: "5px !important",
    },
  },
  icons: {},
});

const Component = ({
  leftIcon,
  rightIcon,
  middleIcon,
  label,
  header,
  disable,
  classes,
  selected,
  isOpen,
  handleClick,
  isInBeta,
  handleClickMiddleIcon,
  leftCornerIcon,
  handleLeftCornerIconClick,
  isInEditMode,
  isEditable,
  iconNextToLabel,
  handleClickIconNextToLabel,
}) => {
  let LeftIcon, RightIcon, MiddleIcon, LeftCornerIcon, IconNextToLabel;
  if (leftIcon) {
    LeftIcon = getIconByName(leftIcon);
  }
  if (rightIcon) {
    RightIcon = getIconByName(rightIcon);
  }
  if (middleIcon) {
    MiddleIcon = getIconByName(middleIcon);
  }
  if (leftCornerIcon) {
    LeftCornerIcon = getIconByName(leftCornerIcon);
  }
  if (iconNextToLabel) {
    IconNextToLabel = getIconByName(iconNextToLabel);
  }
  return (
    <ListItem
      disableGutters={true}
      button={true}
      className={classNames(
        { [classes.selectedItem]: selected && !header },
        { [classes.defaultItem]: !selected && !header },
        { [classes.closed]: !isOpen },
        { [classes.headerItem]: header },
        { [classes.disableItem]: disable },
      )}
      onClick={!isEditable ? handleClick : null}
    >
      {leftCornerIcon && isOpen ? (
        <ListItemIcon onClick={handleLeftCornerIconClick}>
          <LeftCornerIcon />
        </ListItemIcon>
      ) : null}
      {leftIcon ? (
        <ListItemIcon>
          <LeftIcon className={classNames({ closed: !isOpen })} />
        </ListItemIcon>
      ) : null}
      {isOpen ? (
        <ListItemText
          onClick={isEditable ? handleClick : null}
          className={"no-gutters"}
          disableTypography
          primary={label && <Typography>{label}</Typography>}
        />
      ) : null}
      {iconNextToLabel && isOpen ? (
        <div className={classes.iconNextToLabel2}>
          <ListItemIcon onClick={handleClickIconNextToLabel}>
            <IconNextToLabel />
          </ListItemIcon>
        </div>
      ) : null}
      {middleIcon && isOpen ? (
        <div
          className={iconNextToLabel ? classes.centerIcon : classes.middleIcon}
        >
          <ListItemIcon onClick={handleClickMiddleIcon}>
            <MiddleIcon />
          </ListItemIcon>
        </div>
      ) : null}
      {rightIcon && isOpen ? (
        <ListItemIcon onClick={isEditable ? handleClick : null}>
          <RightIcon />
        </ListItemIcon>
      ) : null}
      {isInBeta && isOpen ? <div className={classes.betaItem}>Beta</div> : null}
    </ListItem>
  );
};

Component.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  handleClick: PropTypes.func,
  isInBeta: PropTypes.bool,
};

Component.defaultProps = {
  isOpen: true,
  header: false,
  label: "",
  selected: false,
  leftIcon: "",
  rightIcon: "",
  handleClick: () => { },
  isInBeta: false,
  isInEditMode: false,
};

export default withStyles(styles)(Component);
