import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import './index.scss';

const Component = (props) => {
    const { chidren, handleClick } = props;
    return (
        <IconButton onClick={handleClick} {...props}>
            {chidren}
        </IconButton>
    )
};

Component.propTypes = {
    icon: PropTypes.node,
    handleClick: PropTypes.func 
};

Component.defaultProps = {
    icon: null
};

export default Component;