import { API } from "aws-amplify";

export async function saveMultipleTags(tagsData) {
    //Sample request
    // tagsData = {
    //     parentId:"123", (activityId or workoutId)
    //     parentType:"activity" or "workout",
    //     labels:["test","test1"]
    // }
    return API.post('taggings', `/taggings/multi`, {
        body: tagsData
    });
}

export async function getTagsByParentId(parentId){
    return API.get("tags",`/tags/parent/${parentId}`);
}