import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';

import { API } from "aws-amplify";
import _ from "lodash"

import { withStyles } from '@material-ui/core/styles';

import logo from "../../assets/images/logo-dark.png"

import "./notifications.css";
import PageWrapper from '../nav/pageWrapper';

const styles = theme => ({

});

class AdminPushNotifcations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: null,
            userId: null,
            message: null,
            activityId: null,
            externalUrl: null,
            sendingFrom: null,
            sendingByUser: null,
            sendingBySport: null,
            pushOuterContainer: null,
            includeGuardians: 'false'
        };
    }

    async componentDidMount(){
        // console.log('admin push notifications did mount');
    }

    getGroups() {
        const { currentTeam } = this.props;
        return API.get("groups", `/programs/${currentTeam.id}/groups`);
    }

    hideSnackBar(){
        setTimeout(() => this.setState({open:false}), 2000);
    }

    sendNotification(){
        const { sendingBySport, sendingByUser, organizationId, sendingFrom, message, title, activityId, externalUrl, userId, includeGuardians } = this.state;
        return API.post("messages", `/messages/pushNotification`, {
            body: {
                userId: userId,
                message: message,
                title: title || "",
                activityId: activityId,
                externalUrl: externalUrl,
                sendingFrom: sendingFrom,
                sendingByUser: sendingByUser,
                sendingBySport: sendingBySport,
                organizationId: organizationId,
                onlyGuardians: sendingByUser == 'Parents',
                includeGuardians: includeGuardians == 'true' ? true : false
            }
        });
    }

    handleNotificationSend =  async () => {
        try{
            const sentNotification = await this.sendNotification()
        }
        catch(e){
            console.log('issue sending notifications ==> ', e)
        }

        this.setState({
            open: true,
            title: null,
            userId: null,
            message: null,
            activityId: null,
            externalUrl: null,
            sendingFrom: null,
            sendingByUser: null,
            sendingBySport: null,
            pushOuterContainer: null,
            includeGuardians: 'false',
            successMessage: 'sent - your notifications might take a moment to appear'
        })

        this.hideSnackBar();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    render() {
        const { classes, theme } = this.props;
        const { sendingBySport, sendingByUser, organizationId, sendingFrom, title, message, activityId, externalUrl, userId, includeGuardians } = this.state;

        const whereFromOptions = ['MaxOne', 'One Softball', 'VNN'];
        const userTypeOptions = ['Athletes', 'Parents', 'Coaches', 'Single User'];
        const sportOptions = ['Baseball', 'Basketball', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Other'];

        return (
            <PageWrapper title={"Notification"} {...this.props}>
            <div className="pushOuterContainer">
                <div className={"pageTitleContainer"} >
                    <h1 className={"pageTitle"}></h1>
                    <div className={"titleButtonContainer"}>
                        <Button
                            mini
                            title="Send"
                            variant="fab"
                            color="primary"
                            aria-label="add"
                            style={{color: 'white'}}
                            onClick={() => this.handleNotificationSend()}
                        >
                            <SendIcon className={"icon"} />
                        </Button>
                    </div>
                </div>  
                <Snackbar
                    open={this.state.open}
                    TransitionComponent={this.state.Transition}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.successMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            aria-label="Close"
                            onClick={() => this.setState({open:false, successMessage:""})}
                        >
                        <CloseIcon />
                        </IconButton>
                    ]}
                />
                <div className={"innerContainer"}>
                <h3 style={{ textAlign: 'left' }}>Where are we sending from?</h3>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={"selectField"}>
                                <Select
                                    value={sendingFrom ? sendingFrom : ''}
                                    onChange={this.handleChange("sendingFrom")}
                                    input={<Input id="sendingFrom"/>}>
                                    {whereFromOptions.map(option => (
                                    <MenuItem
                                        key={option}
                                        value={option}>
                                        {option}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <h3 style={{ textAlign: 'left' }}>Who are we sending to?</h3>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={"selectField"}>
                                <InputLabel>
                                    By User Type
                                </InputLabel>
                                <Select
                                    value={sendingByUser ? sendingByUser : ''}
                                    onChange={this.handleChange("sendingByUser")}
                                    input={<Input id="userType"/>}>
                                    {userTypeOptions.map(option => (
                                    <MenuItem
                                        key={option}
                                        value={option}>
                                        {option}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {sendingByUser == 'Single User' && 
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="userId"
                                label="User ID"
                                className={"textField"}
                                value={userId ? userId : ""}
                                onChange={this.handleChange("userId")}
                                margin={"dense"}
                            />
                        </Grid>
                        }
                        {sendingByUser != 'Single User' &&
                        <Grid item xs={12} sm={6}>
                            <FormControl className={"selectField"}>
                                <InputLabel>
                                    By Sport
                                </InputLabel>
                                <Select
                                    value={sendingBySport ? sendingBySport : ''}
                                    onChange={this.handleChange("sendingBySport")}
                                    input={<Input id="sport"/>}>
                                    {sportOptions.map(option => (
                                    <MenuItem
                                        key={option}
                                        value={option}>
                                        {option}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}
                        {sendingByUser != 'Single User' &&
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="organizationId"
                                label="Organization ID"
                                className={"textField"}
                                value={organizationId ? organizationId : ""}
                                onChange={this.handleChange("organizationId")}
                                margin={"dense"}
                            />
                        </Grid>}
                        {sendingByUser == 'Athletes' ?
                        <Grid item xs={12} sm={6}>
                            <FormControl className={"selectField"}>
                                <InputLabel>
                                    Include Guardians
                                </InputLabel>
                                <Select
                                    value={includeGuardians}
                                    onChange={this.handleChange("includeGuardians")}
                                    input={<Input id="includeGuardians"/>}>
                                    <MenuItem
                                        value={'false'}>
                                        false
                                    </MenuItem>
                                    <MenuItem
                                        value={'true'}>
                                        true
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        :null}
                    </Grid>
                    <h3 style={{ textAlign: 'left' }}>What are we sending them?</h3>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="title"
                                label="Title"
                                className={"textField"}
                                value={title ? title : ""}
                                onChange={this.handleChange("title")}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="message"
                                label="Message"
                                className={"textField"}
                                value={message ? message : ""}
                                onChange={this.handleChange("message")}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="activityId"
                                label="Source Activity ID"
                                className={"textField"}
                                value={activityId ? activityId : ""}
                                onChange={this.handleChange("activityId")}
                                margin={"dense"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="externalUrl"
                                label="External Url"
                                className={"textField"}
                                helperText={'including `http://` or `https://`'}
                                value={externalUrl ? externalUrl : ""}
                                onChange={this.handleChange("externalUrl")}
                                margin={"dense"}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            </PageWrapper>
        );
    }
}

AdminPushNotifcations.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AdminPushNotifcations));
