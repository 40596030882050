import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { testId } from '../../utils/test-utils';
import { PencilIcon } from '../../components/icons'
import classnames from 'classnames';
let styles = {
    td:{
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#75797B"
    },
    input:{
        padding: "12px 20px",
        border:"none",
        width:"100%",
        boxSizing: "border-box",
        "&::placeholder": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "150%",
            color: "#F93F3F",
            opacity: 1
          }
    },
    div:{
        padding: "12px 20px",
    },
    editIcon:{
        width:"20px",
        height:"20px",
        color:"#DDE2E5"
    }
};

class Cell extends React.Component {
    constructor(props) {
        super(props);
        this.state = { overrideEditIcon: false };
    }

    overrideEditIcon = () => {
        let { overrideEditIcon } = this.state;
        this.setState({overrideEditIcon: !overrideEditIcon}, ()=>{
            if(!overrideEditIcon){
                this.inputRef.focus();
            }   
        })        
    }

    render() {
        const { value, onChange, classes, onBlur, placeholder, key, testId: tId = '', enableEditIcon, overrideHeadClass } = this.props;
        const { overrideEditIcon } = this.state;
        return (
            <td className={classes.td} key={key} {...testId(tId)}>
                {enableEditIcon && !overrideEditIcon? <React.Fragment><span className={classnames(classes.input, overrideHeadClass)}>{value}</span><PencilIcon className={classes.editIcon} onClick={this.overrideEditIcon}/></React.Fragment>:
                    <input placeholder={placeholder} className={classnames(classes.input, overrideHeadClass)} ref={(ref) => this.inputRef = ref} value={value} onBlur={()=> {if(!!onBlur) { onBlur() } if(enableEditIcon){this.overrideEditIcon()}}} onChange={e => onChange(e.target.value)} />
                }
            </td>)
    }
}

export default withStyles(styles)(Cell);