import React, { Component } from 'react'
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
const styles = {
    iconButtonContainer: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        height: "40px",
        display: "flex",
        padding: "0 5px",
        cursor: "pointer",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "space-between",
        "& p": {
            margin: "0",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "110%"
        },
        "&:nth-child(2)": {
            marginLeft: "5px"
        },
    },
    disabled:{
        cursor:"default",
        pointerEevents: "none"
    }
};

class IconButton extends Component {
    render() {
        const { classes,
            icon,
            onClick,
            position = 'right',
            text,
            color = '#ADB4B9',
            textColor = 'white',
            width = null,
            height = null,
            overrideContainerClassName,
            removeIconPadding,
            textFontSize=null,
            buttonPadding,
            disableColor,
            disabled,
            fontWeight="600",
            title,
            id } = this.props
            const centerButtonContent = (!icon || removeIconPadding);
        return (
            <div
                title={title}
                onClick={disabled? null : onClick}
                id={id}
                className={classnames(classes.iconButtonContainer, {[classes.disabled]:disabled}, overrideContainerClassName)}
                style={{
                    minWidth: width,
                    background: disabled? disableColor: color,
                    padding: buttonPadding || (!icon ? 0 : '0 5px'),
                    display: centerButtonContent ? 'flex' : null,
                    alignItems: centerButtonContent ? 'center' : null,
                    justifyContent: centerButtonContent ? 'center' : null,
                    border: (color == 'white' || color == "#ffffff") ? '1px solid lightgray' : null,
                    height: height
                }}
            >
                {position == 'left' && icon}
                <p style={{
                    paddingRight: centerButtonContent ? 0 : 10,
                    color: textColor,
                    fontSize:textFontSize,
                    fontWeight:fontWeight
                }}
                className={'iconButtonText'}>{text}</p>
                {position == 'right' && icon}
            </div>
        )
    }
}

export default withStyles(styles)(IconButton);