import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const WhoopConnectedIcon = (props) => (
  <SvgIcon width="9" height="7" viewBox="0 0 9 7" {...props}>
    <path
      d="M2.91865 6.8142L0.223329 4.1718C0.148011 4.09751 0.110352 4.002 0.110352 3.88527C0.110352 3.76854 0.148011 3.67303 0.223329 3.59875L0.820497 3.0257C0.895816 2.9408 0.987274 2.89835 1.09487 2.89835C1.21323 2.89835 1.31545 2.9408 1.40153 3.0257L3.20917 4.80852L7.08269 0.988184C7.16877 0.903288 7.26561 0.86084 7.37321 0.86084C7.49156 0.86084 7.5884 0.903288 7.66372 0.988184L8.26089 1.56123C8.33621 1.63552 8.37387 1.73103 8.37387 1.84776C8.37387 1.96449 8.33621 2.06 8.26089 2.13428L3.49968 6.8142C3.42436 6.89909 3.32753 6.94154 3.20917 6.94154C3.09081 6.94154 2.99397 6.89909 2.91865 6.8142Z"
      fill="white"
    />
  </SvgIcon>
);

export default WhoopConnectedIcon;
