import React, { Component } from "react";
import { connect } from "react-redux";
import ModalContainer from "../../components/modalContainer";
import { withStyles } from "@material-ui/core/styles";
import SearchInput from "../../components/searchInput";
import classnames from "classnames";
import { CheckMarkIcon } from "../../components/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import { organizationClient, teamClient, tenantClient } from "../../clients";
import { getThemeByTenant } from "../../themes";
const appLogo = getThemeByTenant().image.logo;

let styles = {
  topSectionAdditionalContent: {
    marginTop: "6px",
  },
  subHeadTxt: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    marginBottom: "12px",
  },
  teamCard: {
    verticalAlign: "top",
    cursor: "pointer",
    width: "277px",
    height: "61px",
    marginBottom: "15px",
    display: "inline-block",
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    padding: "0 12px",
    marginLeft: "15px",
    "&.selectedTeamSquare": {
      border: "2px solid #27AE60",
    },
  },
  teamName: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "120%",
    color: "#0B0B0B",
    marginLeft: "10px",
  },
  searchBox: {
    height: "44px",
    marginBottom: "17px",
  },
  teamImage: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
  },
  checkMark: {
    border: "1px solid #DDE2E5",
    color: "#DDE2E5",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  checkMarkFilledContainer: {
    color: "white",
    border: "1px solid #27AE60",
    background: "#27AE60",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  teamCardContainer: {
    height: "443px",
    overflow: "scroll",
    paddingTop: "20px",
    paddingBottom: "20px",
    justifyContent: "space-between",
    marginLeft: "-16px",
  },
  marketplaceModalPaper: {
    marginRight: "-30px",
    marginLeft: "-30px",
    marginBottom: "-30px",
    background: "#F8F8F8",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  teamSelectedTxt: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
  },
  inputDarkBorder: {
    border: "2px solid #A0A7AB",
  },
};
class SelectTeamsModal extends Component {
  constructor() {
    super();
    this.state = {
      teams: [],
      currentOrganization: {},
      currentTenant: {},
      filteredTeams: [],
      selectedTeamIdsForDownload: new Set(),
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.fetchOrganizationAndTeams();
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) {
      this.setState({ selectedTeamIdsForDownload: new Set() });
    }
  }

  fetchOrganizationAndTeams = async () => {
    // Querying for organization, teams, and tenant to get the proper logo to display
    const [organization, teams] = await Promise.all([
      this.getCurrentOrganization(),
      this.getTeams(),
    ]);
    const { data: tenant } = await tenantClient.getTenantById(
      organization.tenant
    );

    let uniqueTeams = _.uniqBy(teams, "id");
    this.setState({
      currentOrganization: organization,
      currentTenant: tenant,
      teams: uniqueTeams,
      filteredTeams: uniqueTeams,
      isLoading: false,
    });
  };

  getTeams = async (searchTerm = "") => {
    const { currentTeam } = this.props;
    const queryOptions = {
      q: searchTerm,
      organizationId: currentTeam.organizationId,
      limit: 100,
    };

    const {
      data: { results },
    } = await teamClient.searchTeams(queryOptions);

    return results;
  };

  getCurrentOrganization = async () => {
    const { currentTeam } = this.props;
    const { data: organization } = await organizationClient.getOrganizationById(
      currentTeam.organizationId
    );

    return organization;
  };

  handleSearch = async (searchTerm = "") => {
    const { teams } = this.state;
    const filteredTeams = searchTerm
      ? await this.getTeams(searchTerm)
      : [...teams];
    this.setState({ searchTerm, filteredTeams });
  };

  // We want to debounce the search so that we don't make too many requests to the API
  debounceChangeHandler = _.debounce((value) => {
    this.handleSearch(value);
  }, 300);

  select = (team) => {
    const { allowOnlyOneTeam } = this.props;
    const { selectedTeamIdsForDownload } = this.state;
    let newSelectedTeamIds = new Set(selectedTeamIdsForDownload);
    if (newSelectedTeamIds.has(team.id)) {
      newSelectedTeamIds.delete(team.id);
    } else {
      if (allowOnlyOneTeam) {
        newSelectedTeamIds = new Set([team.id]);
      } else {
        newSelectedTeamIds.add(team.id);
      }
    }
    this.setState({ selectedTeamIdsForDownload: newSelectedTeamIds });
  };

  bulkSelect = (deselectAll) => {
    const { teams } = this.state;
    let selectedTeamIdsForDownload;
    if (deselectAll) {
      selectedTeamIdsForDownload = new Set();
    } else {
      selectedTeamIdsForDownload = new Set(teams.map((team) => team.id));
    }
    this.setState({ selectedTeamIdsForDownload });
  };

  render() {
    const {
      open,
      close,
      save,
      classes,
      title,
      productPrice,
      allowOnlyOneTeam,
      subHeading,
      btnTxt,
    } = this.props;
    const {
      searchTerm,
      filteredTeams,
      isLoading,
      selectedTeamIdsForDownload,
      teams,
      currentOrganization,
      currentTenant,
    } = this.state;
    return (
      <ModalContainer
        customPadding={"30px"}
        heading={title}
        open={open}
        width={"630px"}
        onClose={close}
        centerElement={
          selectedTeamIdsForDownload.size ? (
            <span className={classes.teamSelectedTxt}>
              {selectedTeamIdsForDownload.size}{" "}
              {`Team`.pluralize("Teams", selectedTeamIdsForDownload.size)}{" "}
              Selected
            </span>
          ) : null
        }
        useDefaultLeftBtn={true}
        useDefaultRightBtn={true}
        defaultLeftBtnProps={{ onClick: close }}
        defaultRightBtnProps={{
          disabled: !selectedTeamIdsForDownload.size,
          onClick: () =>
            save(
              Array.from(selectedTeamIdsForDownload),
              teams.filter((team) => selectedTeamIdsForDownload.has(team.id))
            ),
          text: productPrice
            ? `$${productPrice * selectedTeamIdsForDownload.size} Buy`
            : btnTxt || "Add Now",
        }}
        additionalTopSection={
          <div className={classes.topSectionAdditionalContent}>
            <div className={classes.subHeadTxt}>
              {subHeading || `Search and select teams for uploading`}
            </div>
            <SearchInput
              placeholder={"Search Teams"}
              customClass={classnames(classes.searchBox, {
                [classes.inputDarkBorder]: !!searchTerm,
              })}
              searchTerm={searchTerm}
              handleSearch={this.debounceChangeHandler}
            />
          </div>
        }
      >
        <div className={classes.marketplaceModalPaper}>
          {isLoading ? (
            <div className={classes.teamCardContainer}>
              <div className={"contentLoadingOverlay"}>
                <CircularProgress />
                <h2>Loading Teams...</h2>
              </div>
            </div>
          ) : (
            <div className={classes.teamCardContainer}>
              {filteredTeams.length > 1 && !allowOnlyOneTeam && (
                <div
                  onClick={() =>
                    this.bulkSelect(
                      selectedTeamIdsForDownload.size === teams.length
                    )
                  }
                  className={classnames(classes.teamCard, {
                    selectedTeamSquare:
                      selectedTeamIdsForDownload.size === teams.length,
                  })}
                >
                  <div className={"d-flex align-items-center h-100"}>
                    <div
                      className={classnames(
                        classes.teamImage,
                        classes.checkMark,
                        {
                          [classes.checkMarkFilledContainer]:
                            selectedTeamIdsForDownload.size === teams.length,
                        }
                      )}
                    >
                      <CheckMarkIcon />
                    </div>
                    <div className={classes.teamName}>
                      {selectedTeamIdsForDownload.size === teams.length
                        ? "Deselect all teams"
                        : "Select All Teams"}
                    </div>
                  </div>
                </div>
              )}
              {filteredTeams.map((team) => {
                return (
                  <div
                    onClick={() => this.select(team)}
                    key={team.id}
                    className={classnames(classes.teamCard, {
                      selectedTeamSquare: selectedTeamIdsForDownload.has(
                        team.id
                      ),
                    })}
                  >
                    <div className={"d-flex align-items-center h-100"}>
                      {selectedTeamIdsForDownload.has(team.id) ? (
                        <div
                          className={classnames(
                            classes.teamImage,
                            classes.checkMarkFilledContainer
                          )}
                        >
                          <CheckMarkIcon />
                        </div>
                      ) : (
                        <img
                          className={classes.teamImage}
                          src={
                            team.logo ||
                            currentOrganization.logo ||
                            currentTenant.logo ||
                            appLogo
                          }
                          alt={getTeamName(team)}
                        />
                      )}
                      <div className={classes.teamName}>
                        {getTeamName(team)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ModalContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    session: { userContext, appContext },
  } = state;
  const currentTeam = _.find(
    userContext.appContextList,
    (c) => c.id === appContext.id
  );
  return {
    currentTeam: currentTeam || {},
  };
}

export default withStyles(styles)(connect(mapStateToProps)(SelectTeamsModal));

function getTeamName(team) {
  return team.customName ? team.customName : `${team.name} ${team.sport}`;
}
