import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import logo from '../assets/images/themes/osb/icon-114x114.png';
import homeImage from '../assets/images/OSBWelcome.jpg';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'osb',
    name: 'One Softball',
    appUrl: 'm1-osb://',
    domain: 'app.onesoftball.com',
    link: 'https://itunes.apple.com/us/app/one-softball/id1331116192?mt=8',
    onAppleAppStore: 'https://apps.apple.com/us/app/one-softball/id1331116192',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.osb&hl=en'
  },
  image: {
    home: homeImage,
    logo: logo,
    loginLogo: logo
  },
  palette: {
    type:"light",
    primary: {
      main:"#FC6605"
    },
    secondary: {
      main: "#16B55A"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type:"dark",
    primary: {
      main:"#FC6605"
    },
    secondary: green,
    error: red,
  }

});
