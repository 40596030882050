import classNames from "classnames";
import React, { Component } from "react";

import "./styles.css";

export default class StyledButton extends Component {
  render() {
    const {
      icon,
      width,
      title,
      height,
      onClick,
      disabled,
      textColor,
      className,
      backgroundColor,
    } = this.props;

    return (
      <a
        disabled={disabled}
        variant={"contained"}
        className={classNames(["styledButton", className])}
        onClick={disabled ? null : onClick}
        style={{
          display: "flex",
          alignItems: "center",
          height: height,
          width: width ? width : null,
          opacity: disabled ? 0.4 : 1,
          justifyContent: "space-between",
          backgroundColor: backgroundColor ? backgroundColor : "white",
          color:
            backgroundColor == "white"
              ? "black"
              : textColor
              ? textColor
              : "white",
          border:
            backgroundColor && backgroundColor !== "white"
              ? "none"
              : "1px solid lightgray",
        }}
      >
        <p className={"styledButtonText"}>{title}</p>
        {icon && icon}
      </a>
    );
  }
}
