import { SESSION_SET_USER_CONTEXT, SESSION_SET_APP_CONTEXT, SESSION_LOGOUT, NEW_COACH_SESSION, SET_ACCESS_TOKEN } from '../types/session';
import ContextService from '../services/context';
import { enableFullScreenLoader } from './loading';

export const setUserContext = (userContext) => {
  return {
    type: SESSION_SET_USER_CONTEXT,
    payload: userContext
  };
}

export const setAppContext = (appContext) => {
  return {
    type: SESSION_SET_APP_CONTEXT,
    payload: appContext
  };
}

export const setNewCoachSession = (newCoachSession) => {
  return {
    type: NEW_COACH_SESSION,
    payload: newCoachSession
  };
}

export const setUserAccessToken = (accessToken) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload: accessToken
  };
}

export const logout = () => {
  return {
    type: SESSION_LOGOUT,
  };
}

export const refreshAppContext = () => {
  return async dispatch => {
    try {
      dispatch(enableFullScreenLoader(true, "Refreshing App Data"));
      const contextService = new ContextService();
      //Used to update nav data and update service connection status
      const appContext = await contextService.buildAppContext();
      dispatch(setAppContext(appContext));
      dispatch(enableFullScreenLoader(false));
    }
    catch (ex) {
      dispatch(enableFullScreenLoader(false));
    }
  }
}

export const refreshUserContext = () => {
  return async dispatch => {
    try {
      dispatch(enableFullScreenLoader(true, "Refreshing User Data"));
      const contextService = new ContextService();  
      //Used to update nav data and update service connection status
      const { userContext, appContext } = await contextService.buildUserContext();
      dispatch(setAppContext(appContext));
      dispatch(setUserContext(userContext));
      dispatch(enableFullScreenLoader(false));
    }
    catch (ex) {
      dispatch(enableFullScreenLoader(false));
    }
  }
}