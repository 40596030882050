import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ProgressStep from '../progressStep';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import SportsEngineBtn from '../../../components/themeButton';
import { testId } from '../../../utils/test-utils';
import { ReactComponent as MultiLibraryIcon } from '../../../assets/images/graphicMultipleLibraries.svg';
import { ReactComponent as OneLibraryIcon } from '../../../assets/images/graphicOneLibrary.svg';

const styles = {
    contentLibraryBoxes: {
    },
    serviceButtonContainer: {
        marginTop: "30px"
    },
    libraryBox: {
        cursor: "pointer",
        flexDirection: "column",
        padding: "30px",
        display: 'flex',
        width: "535px",
        height: "352px",
        background: "#FFFFFF",
        borderRadius: "8px",
        marginRight: "30px",
        filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06))",
        "& svg": {
            margin:"12px 0px",
            width: "475px",
            height: "180px"
        },
    },
    isNotActive: {
        opacity: 0.3
    },
    libraryHead: {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "120%",
        textAlign: "left",
        color: "#27292A",
        textAlign:"center"
    },
    linkActive: {
        color: "#1354F9"
    },
    subInfoHead: {
        fontWeight: "600"
    },
    headerAdditionalInfo: {
        marginLeft: "110px",
        textAlign: "left",
        paddingBottom: "30px",
        marginTop: "10px",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#27292A"
    },
    libraryTitle: {
        fontSize: "26px",
        lineHeight: "120%",
        textAlign: "center"
    }
};

class SelectContentLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGroupActive: null
        }
    }
    toggleContentLibrary = (isGroupActive) => {
        this.setState({ isGroupActive: isGroupActive })
    }

    render() {
        const { classes, selectContentLibrary, goBack, currentStep, totalSteps } = this.props;
        const { isGroupActive } = this.state;
        const oneLibraryLinkNotActive = (isGroupActive !== null && isGroupActive === false);
        const multiLibraryLinkNotActive = (isGroupActive !== null && isGroupActive === true);
        return (
            <React.Fragment>
                <HeaderWrapper>
                    <ProgressStep stepDetails={{
                        "title": "Content Library Creation",
                        "nextStep": "Generate Groups & Teams"
                    }} currentStep={currentStep} totalSteps={totalSteps} />
                    <div className={classes.headerAdditionalInfo}>
                        Select from <span className="font-weight-bold">Option A</span> or <span className="font-weight-bold">Option B </span> below to decide how you want to structure and distribute training libraries (activity and drill libraries, workouts, leaderboards etc.) to your athletes. This will determine how your MaxOne account is set up moving forward.
                    </div>
                </HeaderWrapper>
                <BodyWrapper>
                    <div className={classes.importInfo}>
                        <div className={classnames(classes.contentLibraryBoxes, "d-flex justify-content-start")}>
                            <div onClick={() => this.toggleContentLibrary(true)} className={classnames(classes.libraryBox, { [classes.isNotActive]: oneLibraryLinkNotActive })} {...testId(`single-team-button`)}>
                                <div className={classnames(classes.libraryTitle, { [classes.linkActive]: isGroupActive === true })}>
                                    Compete & Train Together
                                </div>
                                <OneLibraryIcon />
                                <div className={classnames(classes.libraryHead)}>                                
                                    I want <span className={classnames("font-weight-bold")}>ONE SHARED TRAINING LIBRARY</span> for 
                                    <span className={classnames("font-weight-bold")}>&nbsp;ALL MY ATHLETES</span>
                                </div>
                            </div>
                            <div onClick={() => this.toggleContentLibrary(false)} className={classnames(classes.libraryBox, { [classes.isNotActive]: multiLibraryLinkNotActive })}>
                                <div className={classnames(classes.libraryTitle, { [classes.linkActive]: isGroupActive === false })}>
                                    Compete & Train Separately
                                </div>
                                <MultiLibraryIcon />
                                <div className={classnames(classes.libraryHead)}>                               
                                    I want <span className={classnames("font-weight-bold")}>UNIQUE TRAINING LIBRARIES</span> for 
                                    <span className={classnames("font-weight-bold")}>&nbsp;GROUPS, GENDER, DIFFERENT SPORTS, ETC.</span><br />                                
                                </div>
                            </div>
                        </div>
                        <div className={classnames(classes.serviceButtonContainer, "d-flex align-items-center justify-content-between")}>
                            <div className="d-flex justify-content-start" onClick={goBack}>
                                <SportsEngineBtn
                                    position="left"
                                    width={"127px"}
                                    height={"50px"}
                                    iconName="ChevronLeft"
                                    onClick={goBack}
                                    disabled={false}
                                    text="Go Back"
                                    color={"#ffffff"}
                                    textColor={"#5B6062"}
                                />
                            </div>
                            <div className="d-flex align-self-end"  {...testId(`submit-button-container`)}>
                                <SportsEngineBtn
                                    disabled={isGroupActive == null}
                                    width={"281px"}
                                    height={"50px"}
                                    iconName="ChevronRight"
                                    onClick={() => selectContentLibrary(isGroupActive)}
                                    text={"Proceed to Next Step"}
                                />
                            </div>
                        </div>
                    </div>
                </BodyWrapper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SelectContentLibrary);