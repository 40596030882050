import { API } from "aws-amplify";
import moment from 'moment';

export function logLastLogIn(user, tenant) {
    try {
        let currentAppName = tenant.toUpperCase();
        user.apps = user.Apps || {};
        user.tenant = user.tenant || user.tenantId || tenant;
        user.apps[currentAppName] = {
            lastLoginDate: moment().valueOf()
        }
        API.put("users", `/users/${user.id}`, {
            body: user
        });
    }
    catch (e) {
        console.log(e)
    }
}