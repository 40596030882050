import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import _ from "lodash";
import {
    Chip,
    Grid,
    Divider,
    List,
    ListItemText,
    ListItem,
    ListSubheader,
    ListItemIcon,
    Modal,
    Avatar,
    Drawer,
    Button,
    TextField,
    IconButton,
    Tooltip
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './chat.css'

const SideList = ({
  getMenuProps,
  getItemProps,
  selectedItem,
  conversations,
  editGroup,
  deleteGroup,
  currentTeam
}) => {
  const selItemId = selectedItem ? selectedItem.id : null;
  // var uniqueConversations = [...conversations.filter((v, i, a) => a.indexOf(v) === i);
  const items = _.filter(_get(conversations, "items", []), c => c.conversation);
  let renderedItems = [];
  let coachGroup = _.find(items, item => item.conversation.name == 'All Coaches');
  let teamGroup = currentTeam ? _.find(items, item => item.conversation.name == currentTeam.customName) : '';
  _.each(items, item => {
    if(currentTeam && item.conversation.name != 'All Coaches' && item.conversation.name != currentTeam.customName){
      renderedItems.push(item);
    }
  })
  renderedItems = _.orderBy(renderedItems, item => item.conversation.name);
  if(coachGroup) renderedItems.unshift(coachGroup);
  if(teamGroup) renderedItems.unshift(teamGroup);
  return (
    <div className="section sidelist">
      <div {...getMenuProps()} className="list-group list-group-flush">
        {renderedItems.length ? (
          renderedItems.map((item, index) => (
            <button
              type="button"
              {...getItemProps({
                key: item.conversation.id + index,
                index,
                item
              })}
              className={
                "list-group-item list-group-item-action" +
                (selItemId === item.conversation.id ? " active" : "")
              }
              style={{
                border: 0,
                paddingLeft:25,
                display: 'flex',
                fontSize: '0.8em',
                alignItems: 'center',
                backgroundColor: '#28272C',
                justifyContent: 'space-between',
                color: selItemId === item.id ? 'white' : 'darkgrey',
              }}
            >
              # {item.name ? item.name : item.conversation.name}
              {selItemId === item.id && item.conversation.name != 'All Coaches' && currentTeam && item.conversation.name != currentTeam.customName ?
                <div>
                  {/* we will see you soon, friend */}
                  {/* <EditIcon style={{ color: 'white', width: '0.7em', height: '0.7em', cursor: 'pointer', marginRight: 10 }} onClick={() => editGroup()}/> */}
                  <DeleteIcon style={{ color: 'white', width: '0.7em', height: '0.7em', cursor: 'pointer' }} onClick={() => deleteGroup()}/>
                </div>
              : null}
            </button>
          ))
        ) : (
          <div className="p-4" style={{ color: 'white', backgroundColor: '#28272C', textAlign: 'center' }}>
            Loading conversations...
          </div>
        )}
      </div>
    </div>
  );
};
SideList.propTypes = {
  getMenuProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  conversations: PropTypes.object
};

export default SideList;
