import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConversationBar from './ConversationBar'
import { InputBarWithData } from './InputBar'
import MessagePane from './MessagePane'
import { getConvo } from '../graphql/queries'
import { graphql } from 'react-apollo'
import './chat.css'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import _ from "lodash";
import LegacyModalContainer from '../../../../components/modalContainer/legacyModalContainer';
import {
  List,
  Modal,
  Button,
  Avatar,
  ListItem,
  ListItemText
} from "@material-ui/core";

class Messenger extends Component {
  state = {
    chipData: [],
    conversationListModalOpen: false
  };

  async componentWillReceiveProps(props, nextProps){
    var chipData = []
    if(props.conversation && props.coaches.length && props.athletes.length){
        var allUsers = props.coaches.concat(props.athletes);
        await Promise.all(props.conversation.associated.items.map(async link => {
            let user = _.find(allUsers, u => u && u.id && u.id == link.convoLinkUserId);
            const match = _.find(chipData, user)
            if(!match) chipData.push(user)
        }))
    }
    this.setState({
        chipData: chipData
    })
  }

  render() {
    // const classes = {};
    const {
      classes,
      switchView,
      conversation,
      conversationName,
      data: {
        subscribeToMore,
        fetchMore,
        getConvo: { messages: { items: messages = [], nextToken } = {} } = {}
      } = {}
    } = this.props
    var uniqueMessages = messages.filter((v, i, a) => a.indexOf(v) === i);
    return (
      <React.Fragment>
        {conversation ?
          <div className={'conversationNameContainer'}>
            <p style={{ margin: 0 }}>{this.props.conversationName}</p>
            <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => this.setState({conversationListModalOpen: true})}>
              <PersonOutlineIcon style={{ color: 'lightgray' }} />
              <p style={{ color: 'lightgray', margin: 0 }}>{this.props.conversation.associated.items.length}</p>
            </div>
          </div>
        :null}
        <MessagePane
          classes={classes}
          conversation={this.props.conversation}
          userId={this.props.userId}
          userMap={this.getUserMap()}
          {...{ messages:uniqueMessages, subscribeToMore, fetchMore, nextToken }}
        />
        <InputBarWithData
          conversation={this.props.conversation}
          userId={this.props.userId}
        />
        <LegacyModalContainer open={this.state.conversationListModalOpen} onClose={() => this.setState({conversationListModalOpen: false})} containerClassName={"groupsPaper"}>
              <div className={"modalHeaderContainer"}>
                  <h2 className={"modalHeaderText"}>{this.props.conversation ? this.props.conversation.name : ''}</h2>
              </div>
              <div className={"outerModalContainer"}>
                  <div>
                      <List className={"userListContainer"}>
                        {this.state.chipData.length ? _.orderBy(this.state.chipData, 'nameLast', 'nameFirst').map((sub, i) =>
                            <ListItem
                            key={i}
                            role={undefined}
                            dense
                            button
                            className={classes.listItem}
                            >
                            {
                                sub.avatarUrl && sub.avatarUrl.includes('https://') ? <Avatar alt={sub.nameFirst} src={sub.avatarUrl} className={classes.userImage}/> :
                                <Avatar className={classes.userImage}><PersonOutlineIcon /></Avatar>
                            }
                              <ListItemText primary={ `${sub.nameFirst} ${sub.nameLast}`} />
                            </ListItem>
                        ) : null}
                      </List>
                      <div>
                      </div>
                  </div>
                  <div className={"modalFooterContainer"}>
                      <Button
                          type="submit"
                          className={"cancelButton"}
                          onClick={() => this.setState({ conversationListModalOpen: false })}>
                          Cancel
                      </Button>
                  </div>
              </div>
      </LegacyModalContainer>
      </React.Fragment>
    )
  }

    // getUserMap = () => {
    //   const {
    //     coaches,
    //     athletes,
    //     conversation: { associated: { items = [] } = {} } = {}
    //   } = this.props
    //   if(coaches && athletes){
    //       var allUsers = coaches.concat(athletes);
    //       var acc = {};
    //       allUsers.map((user) => {
    //         if(user.id){
    //           acc[user.id] = {username:`${user.nameFirst} ${user.nameLast}`, avatarUrl: user.avatarUrl}
    //         }
    //       })
    //       return acc
    //   }
    // }
    getUserMap = () => {
      const {
        coaches,
        athletes,
        conversation: { associated: { items = [] } = {} } = {}
      } = this.props
      if(coaches && athletes){
          var allUsers = coaches.concat(athletes);
          var acc = {};
          allUsers.map((user) => {
            if(user && user.id){
              acc[user.id] = {username:`${user.nameFirst} ${user.nameLast}`, avatarUrl: user.avatarUrl}
            }
          // Log.debug("Acc ==== ", acc)
          })
          return acc
      }
    }
}

Messenger.propTypes = {
  conversation: PropTypes.object,
  conversationName: PropTypes.string,
  userId: PropTypes.string,
  switchView: PropTypes.func.isRequired,
  data: PropTypes.object
}

const MessengerWithData = graphql(getConvo, {
  skip: props => !props.conversation,
  options: props => ({
    variables: { id: props.conversation.id },
    fetchPolicy: 'network-only'
  })
})(Messenger)

export default Messenger
export { MessengerWithData }
