import { API } from "aws-amplify";


export async function getGroupsForTeam(teamId){
    return API.get('groups', `/programs/${teamId}/groups`);
}

export async function getGroup(groupId){
    return API.get('groups', `/groups/${groupId}`);
}

export async function getCoachesForTeam(teamId){
    return API.get('programs', `/programs/${teamId}/coaches`);
}

export async function getAthletesForTeam(teamId){
    return API.get('programs', `/programs/${teamId}/players`)
}

export async function getGuardians(userId){
    return API.get('users', `/users/${userId}/guardianList`)
}

export async function getCoachesForOrganization(organizationId){
    return API.get('organizations', `/organizations/${organizationId}/coaches`)
}

export async function getAthletesForOrganziation(organizationId){
    return API.get('organizations', `/organizations/${organizationId}/athletes`)
}

export async function getUserByUsername(username){
    return API.get('users', `/users/username/${username}`)
}

export async function getUserById(id){
    return API.get('users', `/users/${id}`)
}