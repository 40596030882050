import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { getAthletesByTeamId } from '../../../../actions/team-actions';
import moment from 'moment';
import { API } from 'aws-amplify';
import { goalsClient } from '../../../../clients';

import './index.scss';
import {
	formatMeasureValueForSaving,
	formatMeasureValueForDisplaying,
} from '../../../../utils/activityUtils';

import BaseModal from '../BaseModal';
import SearchBar from '../../searchBars/SearchBar';
import CancelButton from '../../buttons/CancelButton';
import Loader from '../../../loaderWithMessage/index';
import { result } from 'lodash';

function ActivityScoreModal({
	open,
	closeFunction,
	activity,
	isGoal,
	currentTeamId,
	getAthletesByTeamId,
	athletes,
	loggedInUserId,
}) {
	const [scoreList, setScoreList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [athleteList, setAthleteList] = useState([]);
	const [inputValue, setInputValue] = useState('');

	const LIMIT = 100;
	const offset = 0;

	let measureUnit = activity.scoreType
		? activity.scoreType === 'DISTANCE'
			? 'ft:in'
			: activity.scoreType === 'TIME' || activity.scoreType === 'SHORTEST_TIME'
			? 'min:sec.millisec'
			: activity.scoreType === 'WEIGHT'
			? 'lbs'
			: activity.scoreType === 'MPH'
			? 'mph'
			: activity.scoreType === 'COUNT'
			? 'count'
			: ''
		: 'something went wrong';

	let topMessage = activity.scoreType
		? activity.scoreType === 'DISTANCE'
			? `Enter a ${isGoal ? 'goal ' : ''}distance for each athlete`
			: activity.scoreType === 'TIME' || activity.scoreType === 'SHORTEST_TIME'
			? `Enter a ${isGoal ? 'goal ' : ''}time for each athlete`
			: activity.scoreType === 'WEIGHT'
			? `Enter the ${isGoal ? 'goal ' : ''}weight lifted for each athlete`
			: activity.scoreType === 'MPH'
			? `Enter the mph for each athlete`
			: activity.scoreType === 'COUNT'
			? `Enter the ${isGoal ? 'goal ' : ''}count completed for each athlete`
			: activity.scoreType === 'PARTICIPATION'
			? `Select who completed this participation activity`
			: null
		: 'something went wrong';

	const loadAthletes = () => {
		getAthletesByTeamId(currentTeamId, null, offset, LIMIT);
	};

	async function loadGoals() {
		let {
			data: { results: fetchedData },
		} = await goalsClient.searchGoalsByTeamId(currentTeamId, {
			activityId: activity.id,
		});
		setScoreList(fetchedData);
		setLoading(false);
	}

	const saveActivityResult = (result) => {
		return API.post('activities', '/activityResults', {
			body: result,
		});
	};
	const saveActivityGoals = async (userData) => {
		await goalsClient.createUserActivityGoals(
			activity.id,
			loggedInUserId,
			currentTeamId,
			userData
		);
	};

	const handleActivityResultChange = (athlete) => (event) => {
		let cloneScoreList = scoreList;
		let existingId = null;
		const index = scoreList.findIndex((score) => score.userId === athlete.id);
		if (index !== -1) {
			existingId = cloneScoreList[index].id;
			cloneScoreList.splice(index, 1);
		}
		cloneScoreList.push({
			userId: athlete.id,
			value:
				activity.scoreType === 'PARTICIPATION'
					? event.target.checked
					: event.target.value,
			id: existingId,
			isEdited: true
		});
		setScoreList([...cloneScoreList]);
	};

	const save = async () => {
		await Promise.all(
			scoreList.map(async (entry) => {
				let { oldformat, newFormat } = formatMeasureValueForSaving(
					activity.scoreType,
					entry.value
				);
				if (!isGoal) {
					let resultJSON = {
						score:
							activity.scoreType.toLowerCase() === 'time' ||
							activity.scoreType.toLowerCase() === 'shortesttime' ||
							activity.scoreType.toLowerCase() === 'shortest_time'
								? newFormat
								: oldformat,
						createdAt: moment(),
						userId: entry.userId,
						activityId: activity.id,
						workoutSessionId: 'none',
						type: activity.scoreType.toLowerCase(),
						scoreType: activity.scoreType.toLowerCase(),
						completionValue: activity.completionValue,
						legacyActivityId: 0,
						globalActivityId: 0,
					};
					await saveActivityResult(resultJSON);
				}
				if (isGoal) {
					const goalValue = newFormat;
					const goalData = [
						{
							userId: entry.userId,
							value: Number(goalValue),
							activityId: activity.id,
							id: entry.id,
						},
					];
					await saveActivityGoals(goalData);
				}
			})
		);
		setScoreList([]);
		if (isGoal) {
			loadGoals();
		}
		setAthleteList([]);
	};

	function scoreExists(athleteId) {
		const index = scoreList.findIndex((score) => score.userId === athleteId);
		if (index !== -1) {
			console.log(athleteId, index);
		}
		return scoreList.findIndex((score) => score.userId === athleteId);
	}

	function searchBarOnChange(event) {
		if (event.target.value.length > 0) {
			const filteredAthletes = athletes.list.filter((athlete) =>
				(athlete.firstName + ' ' + athlete.lastName)
					.toLowerCase()
					.includes(event.target.value.toLowerCase())
			);
			if (filteredAthletes.length === 0) {
				setSearchActive(true);
			}
			setAthleteList([...filteredAthletes]);
		} else {
			setSearchActive(false);
			setAthleteList(athletes.list);
		}
	}

	const searchSubmit = (event, value) => {
		if (event) { event.preventDefault() }
		if (value === '') {
			setAthleteList(athletes.list);
		}
	}

	useEffect(() => {
		loadAthletes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (open && isGoal) {
			setLoading(true);
			loadGoals();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const modalContent = (
		<div className='activity-score-modal'>
			<div className='modal-header'>
				<h3>{activity.title}</h3>
			</div>
			<div className='modal-body'>
				<div className='top-body'>
					<div className='search-bar'>
						<div className='search-bar-element'>
							<SearchBar
								searchBarOnChange={searchBarOnChange}
								searchSubmit={searchSubmit}
							/>
						</div>
					</div>
					<div className='message'>{topMessage}</div>
				</div>
				<div className='main-body'>
					{(athleteList.length || searchActive
						? athleteList
						: athletes.list
					).map((athlete) => {
						return activity.scoreType === 'PARTICIPATION' && !isGoal ? (
							<div className='athlete-participation'>
								<input
									type='checkbox'
									onChange={handleActivityResultChange(athlete)}
									className='athlete-checkbox'
								/>
								<label>
									{athlete.firstName} {athlete.lastName}
								</label>
							</div>
						) : (
							<div className='athlete-score'>
								<p>{measureUnit}</p>
								<input
									id={athlete.firstName}
									type='text'
									className='athlete-input'
									placeholder=' '
									onChange={handleActivityResultChange(athlete)}
									value={
										scoreExists(athlete.id) !== -1
											? formatMeasureValueForDisplaying(
													activity.scoreType,
													scoreList.find(
														(score) => score.userId === athlete.id
													)['value'],
													scoreList.find(
														(score) => score.userId === athlete.id
													)['isEdited']
											  )
											: ''
									}
								/>
								<label className='athlete-label'>
									{athlete.firstName} {athlete.lastName}
								</label>
							</div>
						);
					})}
				</div>
			</div>
			<div className='modal-footer'>
				<div className='footer-buttons'>
					<CancelButton
						onClick={() => {
							closeFunction();
							setScoreList([]);
						}}
					/>
					<button
						id='save-button'
						className='button'
						onClick={() => {
							save();
							closeFunction();
							setScoreList([]);
						}}>
						SAVE
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<BaseModal
			open={open}
			modalContent={
				loading ? (
					<Loader isActive={true} message={'loading goals'} />
				) : (
					modalContent
				)
			}></BaseModal>
	);
}

ActivityScoreModal.propTypes = {
	open: PropTypes.bool,
	closeFunction: PropTypes.func,
	activity: {
		id: PropTypes.string,
		title: PropTypes.string,
		media: { type: PropTypes.string, url: PropTypes.string },
		description: PropTypes.string,
	},
};

ActivityScoreModal.defaultProps = {
	open: false,
	closeFunction: null,
	activity: {
		id: null,
		title: 'no title given',
		media: { type: null, url: null },
		description: null,
	},
};

const mapStateToProps = ({
	teams: { currentTeamId, athletes },
	users: { loggedInUserId, byId },
}) => ({
	currentTeamId,
	athletes,
	loggedInUserId,
});

export default hoistStatics(
	compose(
		connect(mapStateToProps, {
			getAthletesByTeamId,
		})
	)
)(ActivityScoreModal);
