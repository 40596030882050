import React, { Component } from "react";
import {
    Button,
    Grid,
    TextField,
    Modal,
    CircularProgress,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { validateScoreOrGoalValue, getScoreTypeHelperTxt, getResultDescriptionTxt } from '../../utils/activityUtils'

let styles = {
    modalContent :{
        marginTop: "0px !important"
    },
    disabledBtn:{
        opacity:0.3
    }
}
class AthleteResultModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            validationData : {}
        }
    }

    componentWillReceiveProps = (nextProps)=>{
        const {  athletes, activity={} } = this.props;
        if((athletes||[]).length != (nextProps.athletes||[]).length || (activity.id != (nextProps.activity||{}).id)){
            this.validateAllUserData(nextProps.userResults, nextProps.activity);
        }
    }

    validateAllUserData = (userResults, activity) => {
        let validationData = {};
        (userResults||[]).forEach(element => {
            let isValid = validateScoreOrGoalValue(activity.scoreType, element.score);
            validationData[element.userId] = isValid;           
        });        
        this.setState({validationData});
    }

    validate =  (value, type, userId) =>{
        let { validationData } = this.state;        
        let isValid = validateScoreOrGoalValue(type, value);
        this.setState({validationData: {...validationData, [userId]: isValid}})
    }

    render() {
        const { activity, open, athletes, userResults, searchString, savingActivityResults, isGoal, isLoading, classes } = this.props;
        const { validationData } = this.state;     
        const isSaveDisabled = Object.values(validationData).some(item => item === false);
        console.log('===============', activity);
        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"listViewActivityModalPaper"}
                    isLoading={isLoading}
                    >
                        <div className={classnames("modalContent", classes.modalContent)}>
                            <div className={"modalHeaderContainer"}>
                                <h2 className={"modalHeaderText"}>{activity.name}</h2>
                            </div>
                            {!savingActivityResults ?
                                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="search"
                                            value={searchString ? searchString : ''}
                                            className={"searchTextField"}
                                            onChange={this.props.searchAthletes}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p style={{ color: 'grey', marginBottom: 0, fontSize: 15, textAlign: 'left' }}>
                                            { getResultDescriptionTxt(isGoal, activity.scoreType)}
                                        </p>
                                    </Grid>
                                </Grid>
                            : null}
                            {athletes.length && !savingActivityResults ?
                            <Grid container spacing={16}>
                                {_.orderBy(athletes, ['nameLast', 'nameFirst']).map((athlete) => {
                                const score = (_.find(userResults, ur => ur.userId == athlete.id)||{}).score||'';
                                    return (<Grid item xs={12} key={athlete.id}>
                                        {activity.scoreType == 'participation' ?
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={'true'}
                                                            color="primary"
                                                            onChange={this.props.handleActivityResultChange(athlete.id)}
                                                        />
                                                    }
                                                    label={`${athlete.nameFirst} ${athlete.nameLast}`}
                                                />
                                            </FormGroup>
                                        :
                                            <TextField
                                                type={["time", "shortesttime", "distance", "shortest_time"].includes((activity.scoreType||"").toLowerCase()) ? "text":"number"}
                                                margin={"dense"}
                                                className={"textField"}
                                                onBlur={() => this.validate(score, activity.scoreType, athlete.id)}
                                                label={`${athlete.nameFirst} ${athlete.nameLast}`}
                                                helperText={getScoreTypeHelperTxt(activity.scoreType)}
                                                onChange={this.props.handleActivityResultChange(athlete.id)}
                                                value={score}
                                                error={validationData[athlete.id] != null && !validationData[athlete.id]}
                                            />
                                        }
                                    </Grid>)
                                }
                                )}
                            </Grid>
                            :
                            savingActivityResults ?
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <h3>saving your activity results...</h3>
                                <CircularProgress />
                            </div>
                            :
                            null
                            }
                        </div>
                        {!savingActivityResults ?
                        <div className={"resultModalFooterContainer"}>
                            <div>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    className={"outlinedButton"}
                                    value={1}
                                    onClick={this.props.close}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className={classnames("filledButton", {[classes.disabledBtn]: isSaveDisabled})}
                                    value={1}
                                    disabled={isSaveDisabled}
                                    onClick={this.props.saveActivityResults}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                        :
                        null}
                </LegacyModalContainer>
            </div>
        )
    }
}


export default withStyles(styles)(AthleteResultModal);

