import React, { Component } from "react";
import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import SwipeableViews from 'react-swipeable-views';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';

import MaskedInput from 'react-text-mask';

import shortid from "shortid";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    flexGrow: 1,
    width: '100%'
  },
  selectField: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'left',
    paddingTop: '5px'
  },
  button: {
    width: 80,
    height: 40,
    marginRight: 10,
    zIndex: 99
  }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={["+", /[1-9]/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class TrueLacrosseUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    };
  }

  handleTabValueChange = (user, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, open, close, deleteUser, user : {
      nameFirst,
      nameLast,
      email,
      phoneNumber,
      username,
      gender,
      graduationYear,
      birthday,
      parent1Email,
      parent1FirstName,
      parent1LastName,
      parent1Username,
      parent2Email,
      parent2FirstName,
      parent2LastName,
      parent2Username,
      parent1Phone,
      parent2Phone,
      jerseyNumber,
      position,
      currentSchool,
      dominantHand,
      id,
      secondaryPosition,
      collegeCommitment,
      parent1PreferredCommunication,
      parent2PreferredCommunication,
      evaluationQuestions = {}
    } } = this.props;
    const {
      value, errorMessage } = this.state;

    const heights = [
      "3 ft. 0 in.", "3 ft. 1 in.", "3 ft. 2 in.", "3 ft. 3 in.", "3 ft. 4 in.", "3 ft. 5 in.", "3 ft. 6 in.", "3 ft. 7 in.", "3 ft. 8 in.", "3 ft. 9 in.", "3 ft. 10 in.", "3 ft. 11 in.",
      "4 ft. 0 in.", "4 ft. 1 in.", "4 ft. 2 in.", "4 ft. 3 in.", "4 ft. 4 in.", "4 ft. 5 in.", "4 ft. 6 in.", "4 ft. 7 in.", "4 ft. 8 in.", "4 ft. 9 in.", "4 ft. 10 in.", "4 ft. 11 in.",
      "5 ft. 0 in.", "5 ft. 1 in.", "5 ft. 2 in.", "5 ft. 3 in.", "5 ft. 4 in.", "5 ft. 5 in.", "5 ft. 6 in.", "5 ft. 7 in.", "5 ft. 8 in.", "5 ft. 9 in.", "5 ft. 10 in.", "5 ft. 11 in.",
      "6 ft. 0 in.", "6 ft. 1 in.", "6 ft. 2 in.", "6 ft. 3 in.", "6 ft. 4 in.", "6 ft. 5 in.", "6 ft. 6 in.", "6 ft. 7 in.", "6 ft. 8 in.", "6 ft. 9 in.", "6 ft. 10 in.", "6 ft. 11 in.",
      "7 ft. 0 in.", "7 ft. 1 in.", "7 ft. 2 in.", "7 ft. 3 in.", "7 ft. 4 in.", "7 ft. 5 in.", "7 ft. 6 in.", "7 ft. 7 in.", "7 ft. 8 in.", "7 ft. 9 in.", "7 ft. 10 in.", "7 ft. 11 in."
    ]

    return (
      <LegacyModalContainer
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={close}
        containerClassName={"userConfigModal"}>
          {errorMessage !== "" ? <Typography style={{ color: "red" }}>{errorMessage}</Typography> : null}
          <div className={"formContainer"}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleTabValueChange}
              className={"tabContainer"}>
              <Tab label="Contact Info" value={0} />
              <Tab label="Basic Info" value={1} />
              <Tab label="Emergency Contact" value={2} />
              <Tab label="Evaluation" value={3} />
            </Tabs>
            <SwipeableViews
              index={value}
              onChangeIndex={this.handleChangeIndex}>
              <div>
                <Grid container spacing={8}>
                  <Grid item xs={12} className={"inputSectionTitle"} style={{ marginBottom: 20 }}>
                    <h2>Contact Info</h2>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>First Name: </b> {nameFirst}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Last Name: </b> {nameLast}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Email: </b> {email || parent1Email}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Phone: </b> {phoneNumber || parent1Phone}</p>
                  </Grid>
                  {
                    !!username &&
                    <Grid item xs={12} sm={6}>
                      <p><b>Username: </b> {username}</p>
                    </Grid>
                  }
                </Grid>
              </div>
              <div>
                <Grid container spacing={8}>
                  <Grid item xs={12} className={"inputSectionTitle"} style={{ marginBottom: 20 }}>
                    <h2>Basic Info</h2>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Gender: </b> {gender || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Dominant Hand: </b> {dominantHand || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Position: </b> {position || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Secondary Position: </b> {secondaryPosition || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Birthday: </b> {birthday || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Current School: </b> {currentSchool || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>College Commitment: </b> {collegeCommitment}</p>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={8}>
                  <Grid item xs={12} className={"inputSectionTitle"} style={{ marginBottom: 20 }}>
                    <h2>Emergency Contact(s)</h2>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 1 First: </b> {parent1FirstName || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 1 Last: </b> {parent1LastName || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 1 Email: </b> {parent1Email || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 1 Phone: </b> {parent1Phone || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 2 First: </b> {parent2FirstName || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 2 Last: </b> {parent2LastName || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 2 Email: </b> {parent2Email || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 2 Phone: </b> {parent2Phone || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 1 Preferred Contact: </b> {parent1PreferredCommunication || "No Input"}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p><b>Parent 2 Preferred Contact: </b> {parent2PreferredCommunication || "No Input"}</p>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid>
                  <Grid item xs={12} className={'evaluationOuterContainer'}>
                    <div className={'evaluationContainer'}>
                      <p className={'evaluationTitle'}>Short-Term Goals</p>
                      <p className={'evaluationResponse'}>1. {evaluationQuestions.one}</p>
                      <p className={'evaluationResponse'}>2. {evaluationQuestions.two}</p>
                      <p className={'evaluationResponse'}>3. {evaluationQuestions.three}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} className={'evaluationOuterContainer'}>
                    <div className={'evaluationContainer'}>
                      <p className={'evaluationTitle'}>Long-Term Goals</p>
                      <p className={'evaluationResponse'}>1. {evaluationQuestions.four}</p>
                      <p className={'evaluationResponse'}>2. {evaluationQuestions.five}</p>
                      <p className={'evaluationResponse'}>3. {evaluationQuestions.six}</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </SwipeableViews>
          </div>
          <div className={"modalFooterContainer"}>
            <div style={{ float: "right", width: "100%", justifyContent: "end", alignItems: "end", textAlign: "end" }}>
              {
                this.props.type !== "duplicate" ?
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={deleteUser}>
                    <DeleteIcon className={"styledIcon"} /> Remove User
                            </Button>
                  : null
              }
              {
                this.props.type === "duplicate" ?
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={deleteUser}>
                    <DeleteIcon className={"styledIcon"} /> Remove Duplicate
                            </Button>
                  : null
              }
              {
                this.props.type === "duplicate" ?
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={this.props.keepUser}>
                    <SaveIcon className={"styledIcon"} /> Keep User
                              </Button>
                  : null
              }
              <Button
                type="submit"
                variant="outlined"
                className={"outlinedButton"}
                onClick={close}>
                Cancel
                            </Button>
            </div>
          </div>
      </LegacyModalContainer>
    );
  }
}

TrueLacrosseUserModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrueLacrosseUserModal);
