import React, { Component } from "react";
import { isMobile, isTablet } from 'react-device-detect';
import "./home.css";
import { getThemeByTenant } from '../../themes';
import googlePlay from '../../assets/images/google-play.png';
import appleAppStore from '../../assets/images/apple-app-store.png';
import { Button, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import IconButton from '../../components/iconButton'
import { API } from "aws-amplify";
import ReactPlayer from 'react-player';
import * as availableIcons from '../../components/icons';
import { connect } from 'react-redux';
import moment from 'moment'
import _ from "lodash"
import fileDownload from 'js-file-download';
import WorkoutInsights from '../teamInsights/workoutInsights';
import { analyticsService } from '../../services/analyticsService';
import MetricCard from '../../components/metricCard'
import { onboardingClient, hrvClient } from '../../clients';
import VideoModal from '../../components/videoModal';
import { getIconByName } from '../../utils/commonUtil';
import { setCurrentTeamById } from '../../actions/team-actions';
const styles = theme => ({
});
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoBoxOpen: false,
      metrics: [],
      reports: [
        {
          "head": "Export HRV & Energy Score",
          "icon": "BeatHeart",
          "description": "Previous 14 days for all athletes",
          "url": "/activities/drill"
        }
      ],
      quickStartVideos: [],
      selectedVideo: {},
      appLinks: {},
      initialQuickVideoCount: 8,
      quickNavs: [],
      //use to control video box animation
      activateVideoBox: false,
      showVideos: true
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { setCurrentTeamById, currentTeam, tenant: { id } } = this.props;
    this.setState({
      isPageLoading: true,
      loadingMessage: 'grabbing your dashboard...',
    });
    setCurrentTeamById(currentTeam.id);
    onboardingClient.getOnboardingByTeamId(currentTeam.id, id).then(({ data }) => {
      this.transformData(data);
    }).catch((e) => {
      console.log("exception")
      console.log(e);
    }).finally(() => {
      this.setState({ isPageLoading: false });
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentTeam.id != this.props.currentTeam.id) {
      this.fetchData();
    }
  }

  toggleVideoModel = (selectedVideo = {}) => {
    this.setState({ isVideoBoxOpen: !this.state.isVideoBoxOpen, selectedVideo });
  }

  transformData = (data) => {
    this.setState({ metrics: data.metrics || [], appLinks: data.links || {}, quickNavs: data.quickNavs || [] });
  }

  loadMoreVideo = () => {
    const { quickStartVideos } = this.state;
    this.setState({ initialQuickVideoCount: quickStartVideos.length })
  }

  toggleQuickStartVideos = () => {
    const { showVideos } = this.state;
    this.setState({ showVideos: !showVideos });
  }

  getReports = async (key) => {
    const { currentTeam } = this.props
    switch (key) {
      case 'hrvReportByTeamId':
        this.setState({
          isPageLoading: true,
          loadingMessage: 'generating report...',
        });
        const startDate = moment().subtract(14, 'days').format("YYYY-MM-DDTHH:mm:ss[Z]")
        const endDate = moment().format("YYYY-MM-DDTHH:mm:ss[Z]")
        const { data: { s3Link } } = await hrvClient.createHRVReportByTeamId(currentTeam.id, currentTeam.name, startDate, endDate)
        await fetch(s3Link)
          .then(response => response.text())
          .then(data => {
            fileDownload(data, "report.csv")
          });
        this.setState({
          isPageLoading: false,
          loadingMessage: '',
        });
        break;

      default:
        break;
    }
  }

  render() {
    const theme = getThemeByTenant();
    const { history, tenant, appContext } = this.props;
    const { isVideoBoxOpen, metrics, selectedVideo, appLinks, initialQuickVideoCount, quickNavs, activateVideoBox, isPageLoading, showVideos, reports } = this.state;
    const { onboardingVideos: { results: onboardingVideos = [] } = {} } = tenant;
    if (isMobile || isTablet) {
      return <div className={'homeContainer'}>
        <img src={theme.image.home} style={{ width: '100%', zIndex: 999999999 }} />
        <div style={{ padding: 20 }}>
          <a href={theme.product.link} style={{ color: 'black', fontSize: '6vw' }}>Thanks for creating your account!<br />
            <br />Click now to download the {theme.product.name} App and sign in.</a>
        </div>
        <div style={{ textAlign: "center" }}>
          <a target="_blank" href={theme.product.onGooglePlay}>
            <img src={googlePlay} style={{ maxHeight: 50 }} />
          </a>
          <div style={{ display: "block", width: 15, height: 15 }}></div>
          <a target="_blank" href={theme.product.onAppleAppStore}>
            <img src={appleAppStore} style={{ maxHeight: 55 }} />
          </a>
        </div>
        <div style={{ padding: 20 }}>
          <a style={{ color: 'black', fontSize: '6vw' }}>If you already have the app downloaded, tap the button below to sign in.</a>
          <div style={{ display: "block", width: 15, height: 15 }}></div>
          <a target="_blank" href={theme.product.appUrl}>
            <Button variant="contained" color="primary">
              Sign In
            </Button>
          </a>
        </div>

      </div>

    } else {
      if (appContext.isAthlete) {
        return (
          <PageWrapper removePadding fullWidth canEditProfile={false} title="Welcome" {...this.props}>
            <div className={'homeContainer'}>
              <div style={{ paddingTop: 96, textAlign: 'center' }}>
                <p>
                  Download the iOS or Android app to sign in!
                </p>
                <a target="_blank" href={appLinks.androidApp}>
                  <img src={googlePlay} style={{ maxHeight: 50 }} />
                </a>
                <div style={{ display: "inline-block", width: 15, height: 15 }}></div>
                <a target="_blank" href={appLinks.iosApp}>
                  <img src={appleAppStore} style={{ maxHeight: 55 }} />
                </a>
              </div>
            </div>
          </PageWrapper>
        )
      }
      return (
        <PageWrapper title="Get Started" {...this.props} isPageLoading={isPageLoading} loadingMessage={this.state.loadingMessage}>
          <div className={'homeContainer'}>
            <React.Fragment>
              {!!quickNavs.length && <div className="quickNavSection">
                <div className="sectionHead">Quick Navigation</div>
                <div className="sectionDetails">
                  {quickNavs.map(quickNav => {
                    return (
                      <div className="quickNavContainer card" onClick={() => { history.push(quickNav.url) }}>
                        {getIconByName(quickNav.icon)}
                        <div className="infoContainer">
                          <div className="infoHead" title={quickNav.head}>{quickNav.head}</div>
                          <div className="infoDetails">{quickNav.description}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>}
              <div className="teamOverviewSection">
                <div className="sectionHead">Team Insights:<b>&nbsp;Workouts</b></div>
                <div className="sectionDetails">
                  <WorkoutInsights />
                </div>
              </div>
              {metrics.length > 0 && <div className="teamOverviewSection">
                <div className="sectionHead">Team Overview</div>
                <div className="sectionDetails">
                  {metrics.map(metric => {
                    return (
                      <MetricCard label={metric.label} icon={metric.icon} value={metric.value} />
                    )
                  })}
                </div>
              </div>}
              {tenant.id === 'ownit' && <div className="quickNavSection">
                <div className="sectionHead">Reports</div>
                <div className="sectionDetails">
                  {reports.map(report => {
                    return (
                      <div className="quickNavContainer card" onClick={() => this.getReports('hrvReportByTeamId')}>
                        {getIconByName(report.icon)}
                        <div className="infoContainer">
                          <div className="infoHead" title={report.head}>{report.head}</div>
                          <div className="infoDetails">{report.description}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>}
              {!!onboardingVideos.length && showVideos && <div className="quickVideoSection">
                <div className="sectionHead">Quick Start Videos <span onClick={this.toggleQuickStartVideos}>{"Hide Videos"}</span></div>
                <div className="sectionDetails">
                  <div className="videoSection">
                    {onboardingVideos.slice(0, initialQuickVideoCount).map(videoDetail => {
                      return (
                        <div className="demoVideo card" onClick={() => {
                          analyticsService.trackEvent('Tutorial Video Clicked', {
                            title: videoDetail.title,
                            category: videoDetail.category
                          });
                          this.toggleVideoModel(videoDetail);
                        }}>
                          <div className="videoThumbnail">
                            <img src={videoDetail.thumbnailUrl} />
                            <div className="shortHead">{videoDetail.category}</div>
                            {getIconByName("PlayCircle")}
                          </div>
                          <div className="videoSummary">
                            {videoDetail.title}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {initialQuickVideoCount < onboardingVideos.length && <div className="loadMore" onClick={this.loadMoreVideo}>
                    Load More Videos
                  </div>}
                </div>
              </div>}
              {!showVideos && <div className="quickVideoSection">
                <div className="sectionHead">Quick Start Videos <span onClick={this.toggleQuickStartVideos}>{"Show Videos"}</span></div>
              </div>}
              <div style={{ paddingTop: 96 }}>
                <a target="_blank" href={appLinks.androidApp}>
                  <img src={googlePlay} style={{ maxHeight: 50 }} />
                </a>
                <div style={{ display: "inline-block", width: 15, height: 15 }}></div>
                <a target="_blank" href={appLinks.iosApp}>
                  <img src={appleAppStore} style={{ maxHeight: 55 }} />
                </a>
              </div>
              <VideoModal
                videoUrl={selectedVideo.videoUrl}
                isVideoBoxOpen={isVideoBoxOpen}
                toggleVideoModel={() => this.toggleVideoModel()}
              >
                <div className="summary">
                  <div className="summaryTitle">{selectedVideo.title}</div>
                  <div className="summaryDetails">{selectedVideo.description}</div>
                  <div className="summaryButtons">
                    <IconButton
                      overrideContainerClassName={"iconButtonContainer"}
                      position={'left'}
                      color={'#1354F9'}
                      text={selectedVideo.buttonTitle}
                      textColor={'#FFF'}
                      onClick={() => history.push(selectedVideo.routeUrl)}
                      icon={getIconByName(selectedVideo.buttonIcon, { nativeColor: '#FFF' })}
                    />
                  </div>
                </div>
              </VideoModal>
            </React.Fragment>
          </div>
        </PageWrapper>
      )
    }

  }
}

function mapStateToProps(state) {
  const { session: { userContext, appContext }, tenant } = state;
  let currentTeam = {};
  if (userContext && userContext.appContextList && userContext.appContextList.length && appContext) {
    currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id) || {};
  }
  return {
    currentTeam: currentTeam || {},
    appContext,
    tenant,
  };
}


export default withStyles(styles)(
  connect(mapStateToProps, { setCurrentTeamById })(Home)
);
