import React, { Component } from 'react'
import {
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CancelIcon from  '@material-ui/icons/Cancel';
import FloatingSelect from '../floatingSelect';

const styles = theme => ({
    dropdown: {
        marginBottom: "0px",
        paddingLeft: "10px",
        "& .additionalData":{
            display:"none"
        }
    },
    typeLabel: {
        minHeight: "24px",
        padding: "8px 15px",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "150%",
        cursor: "pointer",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        "&.dropDownBorderBottom":{
            borderBottom:"1px solid #DDE2E5"
        },
        "&.dropDownBorderBottom:last-child":{
            borderBottom:"0px solid #DDE2E5"
        },
        "&:hover": {
            background: "#1354F9",
            color: "#ffffff !important"
        },
        "&.grayOut":{
            color: "rgba(11,11,11, 0.3)"
        },
        "&.dropDownDisabled":{
            color: "rgba(11,11,11, 0.3) !important",
            pointerEvents: "none",
        }
    },
    fieldTypeCount:{
        display:"none",
        color: "#ffffff",
        right: "15px",
        padding: "3px 6px",
        position: "absolute",
        fontSize: "12px",
        background: "#A0A7AB",
        height: "20px",
        fontWeight: "600",
        lineHeight: "120%",
        borderRadius: "1000px",
        display: "flex",
        alignItems: "center"
    },
    dropDownMenuHeader: {
        color: "rgba(11,11,11, 0.3)",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        pointerEvents: "none",
        cursor: "default",
        flexDirection: "row",
        borderBottom: "1px solid #DDE2E5",
    },
    dropDownRemoveHeader:{
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        cursor: "pointer",
        borderBottom: "1px solid #DDE2E5",
    },
    dropdownLabel: {
        left: "10px"
    },
    fieldAdditionalData: {
        marginTop: "1px",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "100%",
        color: "#A0A7AB"
    },
    ellipseAdditionalData:{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
});


const FloatingSelectWithCustomChild = (props) => {
    const { onChange, classes, value = "", label, dropDownLabel, ellipseAdditionalData, disable, dropDownWidth = "300px", showDropDownBorder=false, menuItemData = [], enableRemoveBtn } = props
    return (
        <FloatingSelect
            disabled={disable}
            label={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            overrideLabelCss={classes.dropdownLabel}
            overrideContainerCss={classes.dropdown}
        >
            <div className={classnames(classes.typeLabel, classes.dropDownMenuHeader, "d-flex")} style={{width:dropDownWidth}}>
                <span className="d-block mr-auto">{dropDownLabel}</span>               
                <ArrowDropDown />              
            </div>
            {enableRemoveBtn && !!value && <div className={classnames(classes.typeLabel, classes.dropDownRemoveHeader, "d-flex w-100 flex-row justify-content-between align-items-center")} style={{width:dropDownWidth}} value={null}>
                <span className="d-block mr-auto">Remove</span>               
                <CancelIcon />              
            </div>}
            {menuItemData.map((data, i) => (
                <div key={i} className={classnames(classes.typeLabel, {"dropDownBorderBottom":showDropDownBorder},{"grayOut":data.grayOut}, {"dropDownDisabled":data.disabled})} value={data.value} style={{width:dropDownWidth}}>
                    {data.displayValue}
                    {data.count != null && <span className={classnames(classes.fieldTypeCount, "additionalData")}>
                        {data.count}
                    </span>
                    }
                    {
                        !!data.additionalData && <div className={classnames(classes.fieldAdditionalData, "additionalData", {[classes.ellipseAdditionalData]: ellipseAdditionalData})}>
                            {data.additionalData}
                        </div>
                    }
                </div>
            ))}
        </FloatingSelect>
    )
}

export default withStyles(styles)(FloatingSelectWithCustomChild);
