import React, { Component } from "react";
import { connect } from "react-redux";
import AddTeamsComponent from "../components/AddTeams";
import { searchMembersByTeamId } from "../../../actions/team-actions";
import { getGroupsByTeamId } from "../../../actions/group-actions";
import { isEqual, isEmpty } from "lodash";

class AddTeams extends Component {
  constructor(props) {
    super(props);
    const { byId, loggedInUserId } = props;
    const {
      teams: { results },
    } = byId[loggedInUserId];
    this.state = {
      teams: results,
      searchValue: "",
      selectedTeams: props.selectedTeams || {},
      isAllMemberSelected: false,
    };
  }
  componentDidMount = () => {
    const { selectedTeams } = this.state;
    Object.values(selectedTeams).forEach((team) => {
      this.loadData(team);
    });
    const count = Object.values(selectedTeams).reduce((count, team) => {
      count +=
        ((team.participants || {}).participants || []).length &&
        ((team.participants || {}).participants || [])[0] === "ALL"
          ? 1
          : 0;
      return count;
    }, 0);
    if (
      Object.values(selectedTeams).length &&
      count === Object.values(selectedTeams).length
    ) {
      this.setState({ isAllMemberSelected: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { membersById } = this.props;
    const { isAllMemberSelected, selectedTeams } = this.state;
    if (
      prevState.isAllMemberSelected !== isAllMemberSelected &&
      isAllMemberSelected
    ) {
      Object.keys(selectedTeams).forEach((item) => {
        selectedTeams[item] = {
          ...selectedTeams[item],
          participants: {
            participants: ["ALL"],
            participantsById: (membersById.data[item] || {}).results || {},
          },
        };
      });
      this.setState({ selectedTeams });
    }

    if (
      !isEqual(prevProps.membersById.data, membersById.data) &&
      !isEmpty(membersById.data)
    ) {
      if (isAllMemberSelected) {
        Object.keys(selectedTeams).forEach((item) => {
          selectedTeams[item] = {
            ...selectedTeams[item],
            participants: {
              participants: ["ALL"],
              participantsById: (membersById.data[item] || {}).results || {},
            },
          };
        });
        this.setState({ selectedTeams });
      }
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleDone = () => {
    const { selectedTeams } = this.state;
    const { handleDone } = this.props;
    handleDone(selectedTeams);
  };

  handleSelectTeams = (team) => {
    const { selectedTeams } = this.state;
    if (!selectedTeams[team.id]) {
      selectedTeams[team.id] = { ...team };
      this.loadData(team);
    } else {
      delete selectedTeams[team.id];
    }
    this.setState({ selectedTeams });
  };

  handleAddParticipants = (team) => {
    const { selectedTeams } = this.state;
    const { handleAddParticipants } = this.props;
    handleAddParticipants(selectedTeams, selectedTeams[team.id] || team);
  };

  handleSearch = (searchValue) => {
    const { byId, loggedInUserId } = this.props;
    let {
      teams: { results },
    } = byId[loggedInUserId];
    results = results.filter((item) =>
      (item.customName || item.name || "")
        .toLowerCase()
        .includes((searchValue || "").toLowerCase())
    );
    this.setState({ searchValue, teams: results });
  };

  loadData = (team) => {
    const { searchMembersByTeamId, getGroupsByTeamId } = this.props;
    getGroupsByTeamId(team.id);
    searchMembersByTeamId(team.id, "", 0, 1000);
  };

  render() {
    const { searchValue, teams, selectedTeams, isAllMemberSelected } =
      this.state;
    const { handleClose, membersByRole, notifyParticipants, handleChange } =
      this.props;
    return (
      <>
        <AddTeamsComponent
          teams={teams}
          searchValue={searchValue}
          handleClose={handleClose}
          handleDone={this.handleDone}
          selectedTeams={selectedTeams}
          handlePropsChange={handleChange}
          handleChange={this.handleChange}
          handleSearch={this.handleSearch}
          membersByRole={membersByRole.data}
          notifyParticipants={notifyParticipants}
          isAllMemberSelected={isAllMemberSelected}
          handleSelectTeams={this.handleSelectTeams}
          handleAddParticipants={this.handleAddParticipants}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId, byId },
  teams: { membersByRole, membersById },
}) => ({
  byId,
  membersById,
  membersByRole,
  loggedInUserId,
});

export default connect(mapStateToProps, {
  getGroupsByTeamId,
  searchMembersByTeamId,
})(AddTeams);
