import { organizationClient } from '../clients';

export const ORGANIZATIONS_GET_BY_ID_LOADING = 'ORGANIZATIONS_GET_BY_ID_LOADING';
export const ORGANIZATIONS_GET_BY_ID_SUCCESS = 'ORGANIZATIONS_GET_BY_ID_SUCCESS';
export const ORGANIZATIONS_GET_BY_ID_ERROR = 'ORGANIZATIONS_GET_BY_ID_ERROR';

export const ORGANIZATIONS_SET_CURRENT_BY_ID_LOADING = 'ORGANIZATIONS_SET_CURRENT_BY_ID_LOADING';
export const ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS = 'ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS';
export const ORGANIZATIONS_SET_CURRENT_BY_ID_ERROR = 'ORGANIZATIONS_SET_CURRENT_BY_ID_ERROR';

export const setCurrentOrganizationById = (id) => (async (dispatch) => {
    dispatch({ type: ORGANIZATIONS_SET_CURRENT_BY_ID_LOADING });
    try {
        const { data: organization } = await organizationClient.getOrganizationById(id, { metrics: false });
        dispatch({ type: ORGANIZATIONS_GET_BY_ID_SUCCESS, data: { organization } });
        dispatch({ type: ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS, data: { id } });
    } catch (e) {
        console.log(`Error setting current organization by ID ${id}`, e);
        dispatch({ type: ORGANIZATIONS_SET_CURRENT_BY_ID_ERROR });
    }
});