import React from 'react';

import AddTagsIcon from './addTags';
import ArrowLeftIcon from './arrowLeft';
import ArrowRightIcon from './arrowRight';
import AthleteIcon from './athlete';
import AthletesIcon from './athletes';
import BaseballCapIcon from './baseballCap';
import Calendar from './calendar';
import CloseIcon from './close';
import CommentIcon from './comment';
import CreditCardIcon from './creditCard';
import CrownIcon from './crown';
import EducationIcon from './education';
import EventsIcon from './events';
import HeartbeatIcon from './heartbeat';
import InvoiceIcon from './invoice';
import IpadIcon from './ipad';
import LeaderboardsIcon from './leaderboards';
import LockIcon from './lock';
import MarketplaceIcon from './marketplace';
import MessagesIcon from './messages';
import PaperPlaneIcon from './paperPlane';
import PhoneIcon from './phone';
import RosterIcon from './roster';
import SecurityIcon from './security';
import SettingsIcon from './settings';
import StrategyIcon from './strategy';
import SentMessageIcon from './sentMessage';
import TreadmillIcon from './treadmill';
import TrophyIcon from './trophy';
import WhistleIcon from './whistle';

import BaseballIcon from './baseball';
import BasketballIcon from './basketball';
import FootballIcon from './football';
import GolfIcon from './golf';
import HockeyIcon from './hockey';
import LacrosseIcon from './lacrosse';
import SkiingIcon from './skiing';
import SoccerIcon from './soccer';
import SoftballIcon from './softball';
import SwimIcon from './swim';
import TennisIcon from './tennis';
import TrackAndFieldIcon from './trackAndField';
import VolleyballIcon from './volleyball';
import WrestlingIcon from './wrestling';
import CheckMarkIcon from './checkMark';
import ChevronUpIcon from './chevronUp';
import COGIcon from './cog';
import Envelope from './envelope';
import GraduationCapIcon from './graduationCap';
import ParentsIcon from './parents';
import PlusIcon from './plus';
import PodiumIcon from './podium';
import ShareIcon from './share';
import ShoppingCartIcon from './shoppingCart';
import LayerIcon from './layer';
import AddUserIcon from './addUser';
import ChatIcon from './chat';
import DumbbellIcon from './dumbbell';
import QRCodeIcon from './qrCode';
import UserSettingsIcon from './userSettings';
import TimesIcon from './times';
import TimesCircleIcon from './timesCircle';
import PlayIcon from './play';
import PlayCircleIcon from './playCircle';
import DownloadIcon from './download';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CancelIcon from '@material-ui/icons/Cancel';
import MergeIcon from './merge';
import PencilIcon from './pencil';
import RedoIcon from './redo';
import AirplaneIcon from './airplane';
import SendIcon from '@material-ui/icons/Send';
import MeetingIcon from './meeting';
import LargeGroupsIcon from './largeGroups';
import SmallGroupsIcon from "./smallGroups";
import OneOnOneIcon from "./oneOnOne";
import GoalIcon from './goal';
import HelplineIcon from './helpline'
import LocalLibraryIcon from './localLibrary'
import ZoomIcon from './zoom'
import UpliftIcon from './uplift'
import BeatHeartIcon from './BeatHeart'
import UpdateIcon from './update'

export const getIconBySport = (sport) => {
  switch(sport) {
    case 'Baseball':
      return <BaseballIcon />;
    case 'Basketball':
      return <BasketballIcon />;
    case 'Football':
      return <FootballIcon />;
    case 'Golf':
      return <GolfIcon />;
    case 'Hockey':
      return <HockeyIcon />;
    case 'Lacrosse':
      return <LacrosseIcon />;
    case 'Skiing':
      return <SkiingIcon />;
    case 'Soccer':
      return <SoccerIcon />;
    case 'Softball':
      return <SoftballIcon />;
    case 'Swim':
      return <SwimIcon />;
    case 'Tennis':
      return <TennisIcon />;
    case 'track-and-field':
      return <TrackAndFieldIcon />;
    case 'Volleyball':
      return <VolleyballIcon />;
    case 'Wrestling':
      return <WrestlingIcon />;
    default:
      return <TrophyIcon /> 
  }
}

export {
  AddTagsIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AthleteIcon,
  AthletesIcon,
  BaseballCapIcon,
  BaseballIcon,
  BasketballIcon,
  Calendar,
  CloseIcon,
  CommentIcon,
  CreditCardIcon,
  CrownIcon,
  EducationIcon,
  EventsIcon,
  FootballIcon,
  GolfIcon,
  HeartbeatIcon,
  HockeyIcon,
  InvoiceIcon,
  IpadIcon,
  LacrosseIcon,
  LeaderboardsIcon,
  LockIcon,
  MarketplaceIcon,
  MessagesIcon,
  PaperPlaneIcon,
  PhoneIcon,
  RosterIcon,
  SecurityIcon,
  SentMessageIcon,
  SettingsIcon,
  SkiingIcon,
  SoccerIcon,
  SoftballIcon,
  SwimIcon,
  StrategyIcon,
  TrackAndFieldIcon,
  TreadmillIcon,
  TrophyIcon,
  VolleyballIcon,
  WhistleIcon,
  WrestlingIcon,
  CheckMarkIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  COGIcon,
  Envelope,
  GraduationCapIcon,
  ParentsIcon,
  PlusIcon,
  PodiumIcon,
  ShareIcon,
  ShoppingCartIcon,
  LayerIcon,
  AddUserIcon,
  ChatIcon,
  DumbbellIcon,
  QRCodeIcon,
  UserSettingsIcon,
  TimesIcon,
  TimesCircleIcon,
  PlayIcon,
  PlayCircleIcon,
  DownloadIcon,
  ChevronRightIcon,
  CancelIcon,
  MergeIcon,
  PencilIcon,
  RedoIcon,
  AirplaneIcon,
  SendIcon,
  MeetingIcon,
  LargeGroupsIcon,
  OneOnOneIcon,
  SmallGroupsIcon,
  GoalIcon,
  HelplineIcon,
  LocalLibraryIcon,
  ZoomIcon,
  UpliftIcon,
  BeatHeartIcon,
  UpdateIcon
};
