import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import SelectTeamsModal from '../selectTeamsModal'
import { API } from "aws-amplify";
import { withStyles } from '@material-ui/core/styles';
import './marketplace.css'
import products from "../admin/products";
import PageWrapper from '../nav/pageWrapper';
import {testId} from '../../utils/test-utils';
import ProductCopyModal from './ProductCopyModal';

const styles = theme => ({
  button: {
    color: 'white',
    backgroundColor: "#1354F9"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});


class Marketplace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value:0,
      groups:[],
      currentTeamId:"",
      isLoading: false,
      orderedGroups: [],
      loadingMessage: "",
      buyNowOpen: false,
      message:'',
      loading: false
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleEditGroup(group){
    this.props.history.push(`/groups/${group.id}`)
  }

  async handleDeleteGroup(group){
    await this.deleteGroup(group.id);
    var elementPos = this.state.groups.map(function(x) {return x.id; }).indexOf(group.id);
    this.state.groups.splice(elementPos, 1);
    this.setState({groups: this.state.groups});
  }

  async componentWillReceiveProps(props, nextProps){
    if(this.state.currentTeamId && props.currentTeam && props.currentTeam.id !== this.state.currentTeamId){
      if(props.currentTeam.id !== this.state.currentTeamId){
        await this.componentDidMount(props.currentTeam)  // Force an update of the component when team changes
      }
    }
  }

  async componentDidMount(team){
    const teamId = team ? team.id : this.props.currentTeam.id
    const teamSport = team ? team.sport : this.props.currentTeam.sport

    this.setState({  isLoading: true, loadingMessage: 'grabbing your offers...'});
    var featureImageUrlMapping = {}
    var partnerImageUrlMapping = {}

    try{
      // var groups = await this.getMarketplace()
      var groups = await this.getMarketplaceBySport(teamSport);
      if(!groups.length) groups = await this.getMarketplaceBySport('Basketball');
      var groupings = _.groupBy(groups, 'priority')
      var orderedGroups = [{ label: 'Other', priorityLevel: 99, groups: [] }, {label: 'Featured', priorityLevel: 1, groups: groupings['featured'] || [] }, {label: 'Recommended', priorityLevel: 2, groups: groupings['recommended'] || []}];

      let logo = '';
      groups.map(async (product,i) => {
        if(product.featureImageUrl) {
          if(product.featureImageUrl.includes("https://")) {
            featureImageUrlMapping[product.id] = product.featureImageUrl
          } else {
            logo = `https://s3.amazonaws.com/programax-videos-production/uploads/marketplace/${product.legacyLongId ? product.legacyLongId : product.id}/featured/${product.featureImageUrl}`
            featureImageUrlMapping[product.id] = logo
            product.featureImageUrl = logo;
          }
          this.setState({featureImageUrlMapping})
        }
        if(product.partnerImageUrl) {
          if(product.partnerImageUrl.includes("https://")) {
            partnerImageUrlMapping[product.id] = product.featureImageUrl
          } else {
            logo = `https://s3.amazonaws.com/programax-videos-production/uploads/marketplace/${product.legacyLongId ? product.legacyLongId : product.id}/partner/${product.partnerImageUrl}`
            partnerImageUrlMapping[product.id] = logo
            product.partnerImageUrl = logo
          }
          this.setState({partnerImageUrlMapping})
        }
      })

      await Promise.all(groups.map(async product => {
        const taggings = _.values(product.taggings);
        if(taggings.length === 0){
            orderedGroups[0].groups.push(product);
        }
        await Promise.all(taggings.map(tagging => {
            if(tagging.tag && tagging.tag.id && tagging.tag.label) {
                let index = _.findIndex(orderedGroups, group => group.label === tagging.tag.label)
                if(index !== -1) orderedGroups[index].groups.push(product);
                else orderedGroups.push({ label: tagging.tag.label, groups: [product], priorityLevel: orderedGroups.length + 1 });
            }
        }));
    }))

      this.setState({orderedGroups, groups, partnerImageUrlMapping, featureImageUrlMapping, isLoading: false})
    }
    catch(e){
      console.log('e ', e)
    }
  }

  getMarketplace() {
    return API.get("products", `/products`);
  }

  getMarketplaceBySport(sport) {
    const { tenant } = this.props;
    return API.get("products", `/products/sport/${sport}?tenant=${tenant}`);
  }

  copyAllProducts(products, teamIds){
    return API.post("products", `/products/copyAllNew`, {
      body: {
        products: products,
        teamIds
      }
    })
  }

  save = async (teamIds) => {
    const { orderedGroups } = this.state
    let { enableFullScreenLoader } = this.props;
    var products = []

    this.setState({
      buyNowOpen: false
    })
    enableFullScreenLoader(true, 'Uploading program to your ' + 'team'.pluralize('teams', teamIds.length));
    orderedGroups.forEach(item => {
      item.groups.forEach(group => {
        if (!group.price || group.price && (group.price == 0 || group.price == '0' || group.price == '0.00')) {
          products.push(group)
        }
      })
    })
    try {
      await this.copyAllProducts(products, teamIds);
      enableFullScreenLoader(true, 'Uploaded successfully');
    }
    catch (ex) {
      enableFullScreenLoader(true, 'Uploading failed. Please try again later');
    }
    finally {
      setTimeout(() => enableFullScreenLoader(false) , 2000);
    }
  }

  render() {
    const { classes } = this.props;
    const { groups = [], orderedGroups = [], isLoading, loadingMessage,  buyNowOpen, message, loading } = this.state;
    return (
      <PageWrapper title="Marketplace" {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
      <div className="Marketplace">
        {
          groups.length > 0
        ?
          <Grid container spacing={0} style={{
            width:'calc(100% + 30px)',
            marginLeft:'-15px',
            marginTop:0 }}
            // onClick={() => this.props.history.push(`/marketplace/${groups[0].id}`)}
          >

            <Grid item xs={12} style={{
              justify:'left',
              width:'calc(100% + 30px)',
              height:400,
              zIndex:'-50',
              backgroundImage:'url(' + groups[0].featureImageUrl + ')',
              backgroundSize: 'cover' }}>
            </Grid>

            <Grid item xs={12} style={{justify:"left", width:'110vw', height:400, zIndex:'-50', marginTop:'-400px', background: 'rgba(0, 0, 0, 0.5)' }}>
              {
                groups[0].featureImageUrl
              ?
              <div>
                <h2 style={{color:'white', fontweight:300, marginTop:40, marginBottom: -30, textAlign:"left", width:'80%', marginLeft:90}}>
                  FEATURED
                </h2>
              </div>
              :
                <div style={{marginTop:55}}></div>
              }
              {
                groups[0].partner
              ?
                <h1 style={{color:'white', textAlign:"left", width:'80%', marginLeft:90, fontSize:'1.5em'}}>
                <img alt='' src={groups[0].partnerImageUrl} style={{height:50, width:50, marginBottom:'-10px'}}/> {groups[0].partner}</h1>
              :
                null
              }
              <h1 style={{color:'white', fontweight:'lighter', textAlign:"left", width:'80%', marginLeft:90, lineHeight:'54px', marginBottom:0, fontSize:"4em"}}>{groups[0].name}</h1>
              {
                groups[0].description
              ?
                <div style={{
                  color:'white',
                  fontweight:'lighter',
                  paddingTop:'20px',
                  textAlign:'left',
                  width:'80%',
                  marginLeft:90,
                  fontSize:'1.5em'}}
                >
                  {groups[0].description}
                </div>
              :
                null
              }
              {
                groups[0].price
              ?
                <h2 style={{color:'white', fontweight:'lighter', textAlign:"left", width:"100%", marginLeft:90, marginTop:15, fontSize:"2em"}}>
                  ${groups[0].price}
                </h2>
              :
                <h2 style={{color:'white', fontweight:'lighter', textAlign:"left", width:"100%", marginLeft:90, marginTop:15, fontSize:"2em"}}>
                  FREE
                </h2>
              }
            </Grid>
        </Grid>
        :
        null
      }
      {
        !isLoading
      ?
        <Paper style={{width:'100%', paddingTop:0, paddingBottom:20, zIndex:100, marginTop:"-75px", borderRadius:0}}>
          <Grid container justify="flex-end" alignItems="flex-end" style={{ padding: 20 }}>
            <Button
                {...testId(`all-free-content-button`)}
                variant="contained"
                className={classes.button}
                onClick={() => this.setState({ buyNowOpen: true })}
              >
                Download All Free Content
            </Button>
          </Grid>
          {orderedGroups.length ? _.orderBy(orderedGroups, 'priorityLevel').map((orderedGroup, index) => {
              return <Grid key={index} container spacing={24} style={{paddingLeft:20, paddingRight:20}}>
                {orderedGroup.groups.length ? <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0, display: 'flex', justifyContent: 'flex-start', height: 70, marginBottom: 0 }}>
                  <h2>{orderedGroup.label}</h2>
                </Grid>
                :null}
                {_.orderBy(orderedGroup.groups, ['priorityLevel', 'partnerName']).map((group, i) => {
                  console.log(group.name, group.featureImageUrl);
                  return <Grid item xs={12} lg={4} md={6} key={group.id} style={{height:208, cursor:'pointer', padding:7, borderRadius:2}}>
                    <Paper style={{borderRadius:2, height:195, backgroundImage: "url('" + group.featureImageUrl + "')", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                      <Grid container spacing={8} style={{paddingLeft:30, paddingRight:30}}>
                        <Grid item xs={12} style={{height:105}}>
                        {
                          group.partnerName
                        ?
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {
                              group.partnerImageUrl
                            ?
                              <img alt='' src={group.partnerImageUrl} style={{width:25, height:25, marginTop:5, marginRight: 10}} />
                            :
                              null
                            }
                            <h4 style={{color:"white", height:40, textAlign:'left', fontSize:"1.0em", whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden", marginBottom:0}}>
                              {group.partnerName}
                            </h4>
                          </div>
                        :
                            <h4 style={{height:40, marginBottom:0}}>&nbsp;</h4>
                        }
                          <h4 style={{color:"white", textAlign:'left', fontSize:"1.5em", whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden", marginTop:0, marginBottom:0}}>
                            {group.name}
                          </h4>
                        </Grid>
                        {
                          group.price && group.price > 0
                        ?
                          <Grid item xs={12} style={{textAlign:'left', width:'100%'}}>
                            <h1 style={{color:"white", fontSize:"1.25em", display:'inline-block', marginRight:15}}>${group.price}</h1>
                            <Button
                              style={{marginTop:'-10px', width:105, display:'inline-block'}}
                              variant="contained"
                              className={classes.button}
                              onClick={() => this.props.history.push(`marketplace/${group.id}`)}
                            >
                              View
                            </Button>
                          </Grid>
                        :
                          <Grid item xs={12} style={{textAlign:'left', width:'100%'}}>
                            <h1 style={{color:"white", fontSize:"1.25em", display:'inline-block', marginRight:15}}>FREE</h1>
                            <Button
                                {...testId(`package-${group.id}-button`)}
                              style={{marginTop:'-10px', width:105,  display:'inline-block'}}
                              variant="contained"
                              className={classes.button}
                              onClick={() => this.props.history.push(`marketplace/${group.id}`)}
                            >
                              View
                            </Button>
                          </Grid>
                        }
                      </Grid>
                    </Paper>
                    <div
                      style={{ borderRadius:2, width:"100%", height:195, marginTop:'-195px', background:'rgba(0, 0, 0, 0.5)' }}
                      onClick={() => this.props.history.push(`marketplace/${group.id}`)}>
                    </div>
                  </Grid>})}
              </Grid>
            })
            :
            null
        }
        </Paper>
      :
        null
      }
      <SelectTeamsModal
          title={"Add: All Free Content"}
          classes={classes}
          open={buyNowOpen}
          save={this.save}
          close={() => this.setState({buyNowOpen:false})}
        />
      </div>
      </PageWrapper>
    );
  }
}

Marketplace.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
      currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(Marketplace));
