export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dev-api.maxone-dev.com"
  },
  cognito: {
    USER_POOL_ID: "us-east-1_pJ6r0r1Dg",
    APP_CLIENT_ID: "1cus46cg9816mkvfrik546mcs5",
    IDENTITY_POOL_ID: "us-east-1:b6b2fabb-d31a-49df-a61e-22ebae97a91a"
  }
}
