import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "../../../components/pagination";

const styles = () => ({
  tableCurved: {
    borderCollapse: "separate",
    width: "100%",
    borderSpacing: "0 10px",
    "& tr": {
      height: "70px",
      background: "#FFFFFF",
      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "15px",
      "& td": {
        "&:first-child": {
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
        "&:last-child": {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        },
      },
      "& th": {
        "&:first-child": {
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
        "&:last-child": {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        },
      },
    },
  },
  tableRow: {
    // height: '70px',
    // background: '#FFFFFF',
    // boxShadow: '0px 0px 30px rgb(0 0 0 / 15%)',
    // borderRadius: '15px'
  },
  filtersSection: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "10px",
  },
  searchBox: {
    background: "#FFFFFF",
    /* Gray / 20% Gray */

    border: "1px solid #DDE2E5",
    boxSizing: "border-box",
    borderRadius: "8px",
    float: "left",
    width: "315px",
    height: "48px",
    padding: "9px 20px",
    display: "flex",
    alignItems: "center",
    "& input": {
      overflow: "visible",
      background: "transparent",
      border: 0,
      color: "#222127",
      outline: "none",
      fontSize: "14px",
      fontWeight: "400",
      width: "100%",
      lineHeight: "21px",
    },
  },
  filterDropdownSection: {
    width: "244px",
    height: "48px",
    borderRadius: "8px",
    marginLeft: "10px",
    background: "#FFFFFF",
    /* Gray / 20% Gray */

    border: "1px solid #DDE2E5",
    boxSizing: "border-box",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  myAtheleteSection: {
    display: "flex",
    fontSize: "33px",
    justifyContent: "flex-start",
    lineHeight: "50px",
    fontWeight: "500",
  },
  calendarSection: {
    display: "flex",
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: "400",
    alignItems: "center",
  },
  hrvColumn: {
    width: "83px",
    height: "25px",
    background: "#FF0A45",
    opacity: "0.14",
    borderRadius: "100px",
  },
  redTriangle: {
    width: "12px",
    height: "8.7px",
    background: "#FF0A45",
    borderRadius: "1px",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
});

const TableComponent = ({
  columns,
  data,
  handleClick,
  pageSize,
  handlePageChange,
  currentPage,
  totalCount,
  classes,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  return (
    <div>
      <Table
        className={classes.tableCurved}
        aria-label="simple table"
        size={"medium"}
      >
        <TableHead>
          <TableRow className={classes.tableRow}>
            {columns.map((item, index) => (
              <TableCell
                key={index}
                style={item.cellStyle}
                size={item.size}
                align={"left"}
                padding={"default"}
              >
                {item.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((row, index) => {
            return (
              <TableRow
                hover
                className={classes.tableRow}
                tabIndex={-1}
                key={index}
                onClick={() => handleClick(row)}
              >
                {columns.map((item, key) => (
                  <TableCell
                    style={item.cellStyle}
                    key={key}
                    align={"left"}
                    padding={"default"}
                  >
                    {item.render ? item.render(row) : row[item.field]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {totalPages > 1 ? (
        <div style={{ textAlign: "end" }}>
          <Pagination
            currentPage={currentPage + 1}
            totalPages={totalPages}
            changeCurrentPage={handlePageChange}
            theme="square-i"
          />
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(TableComponent);
