import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar } from '@material-ui/core';
import { getIconByName } from '../../../icons/utils';
import '../index.scss';

const Component = ({ id, url, title, subTitle, type, children, selected, collapsed, setCollapsed, handleTeamChange }) => {
    const LeftIcon = getIconByName(collapsed ? "Plus" : "Remove")
    return (
        <div className={classNames("row no-gutters align-items-center justify-content-between item-container", { selected: collapsed && selected })}>
            <div className={"col-md-10"}>
                <div className={"row no-gutters align-items-center"}>
                    <div className={classNames("col-md-2 no-gutters align-items-center")}>
                        {(children || []).length ?
                            <div className={classNames("left-container", { add: collapsed }, { remove: !collapsed })} onClick={() => setCollapsed(!collapsed)}>
                                <LeftIcon className={"left-item-icon"} />
                            </div>
                            : null}
                        <Avatar src={url} className={"avatar-container"} />
                    </div>

                    <div className={"col-md-10 list-details-container"}>
                        <div className={classNames("list-item-title")}>{title}</div>
                        <span className={classNames("list-item-type")}>{type}</span>
                        <span className={classNames("list-item-sub-title")}>{subTitle}</span>
                        {selected ? <span className={"current-container"}>Current</span> : null}
                    </div>
                </div>
            </div>
            {type === 'TEAM' && (
                <div className={"col-md-2 select-button-container"}>
                    {!selected ? <span className={"select-container"} onClick={() => { children && children.length === 1 ? handleTeamChange(children[0].id, children[0].title) : handleTeamChange(id, title) }}>Select</span> : null}
                </div>
            )}
        </div>
    )
}

Component.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.array,
    selected: PropTypes.bool,
};

Component.defaultProps = {
    url: "https://cdn.gomaxone.com/tenant/m1/logo.png",
    title: "",
    subTitle: "",
    type: "",
    children: [],
    selected: false,
};

export default Component;