import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../../../../components/v2/buttons';
import EmailNotificationRow from './EmailNotificationRow';

const Component = (
    {
        events,
        availableEventTypes,
        allEventTypes,
        onAddEventClick,
        onTestEventClick,
        onDeleteEventClick,
        onEventChange
    }
) => {
    return (
        <div className={'director-integrations-view-container'}>
            <p>
                For more information on how to use the Zapier Parser Integration, click on this <a href='https://parser.zapier.com/' target='_blank'>link</a>.
            </p>
            {events.map((props, index) => (
                <div key={index} className={'integration-row'}>
                    <EmailNotificationRow
                        {...props}
                        availableEventTypes={availableEventTypes}
                        onEmailAddressChange={(value) => onEventChange({ ...props, emailAddress: value }, index)}
                        onEventTypeChange={(value) => onEventChange({ ...props, eventType: value }, index)}
                        onTestClick={() => onTestEventClick(index)}
                        onDeleteClick={() => onDeleteEventClick(index)}
                    />
                </div>
            ))}
            {(events.length < allEventTypes.length) && (
                <PrimaryButton onClick={onAddEventClick}>
                    ADD EVENT
                </PrimaryButton>
            )}
        </div>
    )
};

Component.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        eventType: PropTypes.string,
        active: PropTypes.bool,
        loading: PropTypes.bool
    })),
    availableEventTypes: PropTypes.arrayOf(PropTypes.string),
    allEventTypes: PropTypes.arrayOf(PropTypes.string),
    onAddEventClick: PropTypes.func,
    onEventChange: PropTypes.func,
    onDeleteEventClick: PropTypes.func,
    onTestEventClick: PropTypes.func,
};

Component.defaultProps = {
    events: [],
    availableEventTypes: [],
    allEventTypes: [],
    onAddEventClick: () => { },
    onEventChange: (event) => { },
    onDeleteEventClick: (index) => { },
    onTestEventClick: (index) => { }
};

export default Component;