import { SET_TENANT_INFO} from '../types/tenant';

const initState = {
    features: new Set()
};

export default (state = initState, action) => {  
  switch(action.type) {
    case SET_TENANT_INFO:
        return {
        ...state,
        ...action.payload,
        features: new Set((action.payload||{}).features)
    };
    default :
      return state;
  }
}