import { connect } from 'react-redux';

import React, { Component } from 'react'

import { API, Storage } from "aws-amplify"

import {
    TextField,
    InputAdornment,
    CircularProgress
} from '@material-ui/core'

import { ClickAwayListener } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email'
import MessageIcon from '@material-ui/icons/Message'
import FilterList from '@material-ui/icons/FilterList'
import EventIcon from '@material-ui/icons/EventAvailable'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import ChipData from '../../../../components/chipData'
import SearchOverlay from '../../components/searchOverlay'
import StyledButton from '../../../../components/styledButton'
import BorderedInput from '../../../../components/borderedInput'
import OptionsSelector from '../../../../components/optionsSelector'

import { Editor, RichUtils } from 'draft-js';
import { mediaBlockRenderer, onAddImage } from '../../../../components/draftjsEntities';
import { FormatUnderlined, FormatListBulleted, FormatListNumbered, FormatItalic, FormatBold, Link, Image }  from '@material-ui/icons';
import {
    resolveContext,
    handleSendMessage,
    handleSaveMessage,
    resolveRecipientList,
    resolveFilteredRecipientList,
    resolveSelectedRecipientsList
} from '../../../../utils/messageUtils.js'
import { resolveActivityList } from '../../../../utils/activityUtils.js'
import { onAddLink } from '../../../../components/draftjsEntities';
import { withStyles } from '@material-ui/core/styles';
import './styles.css'
import { tenantMap } from '../../../../utils/commonUtil';

const styles = {
    emailInput:{
        width:"100%"
    },
    textAreaContainer:{
        width: "100%",
        height:"180px",
        overflow:'scroll',
        fontSize: "18px",
        borderRadius: "5px",
        border: "1px solid lightgray",
        padding:"10px",
        textAlign:"left"
    },
    iconContainer:{
        textAlign:"right",
        position:'absolute',
        top:"0",
        right:"0",
        "& svg":{
            color:"lightgray",
            fontSize:"18px"
        }
    }
}

class ComposeScreen extends Component{

    constructor(props){
        super(props)

        this.state = {
            positions: [],
            context: 'team',
            allActivities: [],
            recipientsList: [],
            isSendingMessage: false,
            selectedRecipients: [],
            currentTeamFilter: null,
            loadingRecipients: false,
            recipientSearchString: '',
            potentialRecipientsList: [],
            isSearchingRecipients: false,
            isSelectingActivities: false,
            editorClassName: 'editor'
        }
    }

    async componentWillMount(){
        const { context } = this.state
        this.handleCategorySelection('all')
    }

    async componentWillReceiveProps(props, nextProps){
        const { athletes } = props
        const { context } = this.state
        const newContext = resolveContext(window.location.href)
        if((context != null && context !== newContext)){
            this.handleCategorySelection('all', true)
            this.setState({
                context: newContext
            })
        }
    }

    handleTypeChange(type){
        const { changeMessageType, isMonthlyMessageLimitMet } = this.props
        if(type == 'sms' && isMonthlyMessageLimitMet)
            return
        changeMessageType(type)
    }

    handleChangeTeamFilter = team => {
        const { currentTeamFilter } = this.state
        this.setState({ currentTeamFilter: team == currentTeamFilter ? null : team })
        this.handleRecipientSearch({target: {value: ''}}, team)
    }

    handleChangePositionFilter = position => {
        const { positionFilter, currentTeamFilter } = this.state
        this.setState({ positionFilter: positionFilter && position.name.toLowerCase() == positionFilter.name.toLowerCase() ? null : position })
        this.handleRecipientSearch({target: {value: ''}}, currentTeamFilter, position)
    }

    handleRecipientSearch = async (event = {}, teamFilter, positionFilter) => {
        const { target: { value = '' }} = event
        const {
            currentTeamFilter,
            isSearchingRecipients,
            potentialRecipientsList,
        } = this.state

        const {
            all,
            groups,
            parents,
            coaches,
            athletes,
            currentRecipientCategory
        } = this.props

        const data = {
            all,
            value,
            groups,
            parents,
            coaches,
            athletes,
            positionFilter,
            currentRecipientCategory,
            currentTeamFilter: teamFilter ? teamFilter : currentTeamFilter,
        }

        this.setState({ recipientSearchString: value })

        if(!isSearchingRecipients)
            this.setState({ isSearchingRecipients: true })

        let newRecipients = await resolveFilteredRecipientList(data)

        this.setState({ potentialRecipientsList: newRecipients })

        if(teamFilter || positionFilter)
            this.handleCategorySelection(currentRecipientCategory)
    }

    toggleRecipientFilter = () => {
        const { isSearchingRecipients } = this.state
        this.setState({ isSearchingRecipients: !isSearchingRecipients })
    }

    toggleActivitySelector = async () => {
        const { currentTeam } = this.props
        const { allActivities, isSelectingActivities } = this.state

        let activities = allActivities

        if(!activities.length){
            this.setState({ areActivitiesLoading: true })
            activities = await resolveActivityList(currentTeam.id)
        }

        this.setState({
            allActivities: activities,
            areActivitiesLoading: false,
            isSelectingActivities: !isSelectingActivities
        })
    }

    isDraftOptionDisabled = () => {
        const { currentMessageRecipients, currentMessageString } = this.props
        return !currentMessageRecipients.length && currentMessageString == ''
    }

    isSendOptionDisabled = () => {
        const { currentMessageRecipients, currentMessageString } = this.props
        return !currentMessageRecipients.length || currentMessageString == ''
    }

    isScheduledOptionDisabled = () => {
        const { currentMessageRecipients, currentMessageString, currentTeam } = this.props
        return !!currentTeam.isFree  || !currentMessageRecipients.length || currentMessageString == ''
    }

    handleChangeMessageString = event => {
        const { changeMessageString } = this.props
        changeMessageString(event.target.value)
    }

    handleChangeMessageURL = event => {
        const { changeMessageURL } = this.props
        changeMessageURL(event.target.value)
    }

    handleCategorySelection = async (category, forceRefresh) => {
        const { currentTeamFilter, positionFilter } = this.state
        const { teams, currentTeam = {}, changeRecipientCategory, updateRosters, athletes, coaches, groups, parents } = this.props
        const context = await resolveContext(window.location.href)

        if(forceRefresh)
            this.setState({ loadingRecipients: true })

        const {
            groupList,
            coachList,
            parentList,
            recipients,
            athleteList,
            positionList
        } = await resolveRecipientList(currentTeam, context, category, groups, coaches, athletes, parents, teams, currentTeamFilter, positionFilter, forceRefresh)

        let all = athleteList.concat(parentList, coachList)

        changeRecipientCategory(category)

        updateRosters(all, groupList, coachList, athleteList, parentList)

        this.setState({
            context: context,
            positions: positionList,
            loadingRecipients: false,
            potentialRecipientsList: recipients,
        })
    }

    handleCloseSearch = () => {
        this.setState({ isSearchingRecipients: false })
    }

    handleRecipientClick = async recipient => {
        const { handleAddRecipients, currentMessageRecipients } = this.props
        let newSelectedRecipients = await resolveSelectedRecipientsList(recipient, currentMessageRecipients)
        handleAddRecipients(newSelectedRecipients)
    }

    handleActivitySelection = activity => {
        const { changeActivity } = this.props
        this.toggleActivitySelector()
        changeActivity(activity)
    }

    handleSendMessage = async () => {
        const { context } = this.state
        const { athletes, coaches, parents, sendMessage, currentTeam, currentRecipientCategory, tenant} = this.props
        let data = {
            domain: tenant.id,
            context: context,
            parents: parents,
            coaches: coaches,
            athletes: athletes,
            currentTeam: currentTeam,
            currentRecipientCategory: currentRecipientCategory
        }
        this.setState({ isSendingMessage: true })
        await sendMessage(data)
        this.setState({ isSendingMessage: false })
    }

    handleSaveMessage = async () => {
        const { saveMessage, currentTeam, currentRecipientCategory, tenant } = this.props

        let data = {
            domain: tenant.id,
            currentTeam: currentTeam,
            currentRecipientCategory: currentRecipientCategory
        }

        saveMessage(data)
    }

    render(){
        const { currentTeam, tenant} = this.props;
        return(
            <div className={'composeScreenOuterContainer'}>
                <div className={'composeTitleContainer'}>
                    <p className={'titleText'}>New Message</p>
                </div>
                <div className={'composeScreenInnerContainer'}>
                    <div className={'composeScreenLeftContainer'}>
                        {this.renderMessageTypes()}
                        {this.renderAddRecipients()}
                        {this.renderMessageContent()}
                    </div>
                    <div className={'composeScreenRightContainer'}>
                        {this.renderRecipientsList()}
                    </div>
                </div>
                {tenant.id == tenantMap.vnn && currentTeam && !!currentTeam.isFree && this.renderRemainingMessagesBanner()}
                <div className={'composeScreenFooterOuterContainer'}>
                    {this.renderFooter()}
                </div>
            </div>
        )
    }

    renderMessageTypes(){
        const { currentMessageType, isMonthlyMessageLimitMet } = this.props
        return(
            <div className={'inputContainer'}>
                <p className={'textInputLabel'}>Message Type:</p>
                <div className={'messageTypesContainer'}>
                    <div
                        onClick={() => this.handleTypeChange('sms')}
                        className={
                            isMonthlyMessageLimitMet ? 'messageTypeContainer leftContainer disabledType' :
                            currentMessageType == 'sms' ? 'messageTypeContainer leftContainer activeType' :
                            'messageTypeContainer leftContainer'
                        }
                    >
                        <p>SMS</p>
                        <MessageIcon className={'tabIcon'} />
                    </div>
                    <div
                        className={currentMessageType.toLowerCase() == 'email' ? 'messageTypeContainer activeType' : 'messageTypeContainer'}
                        onClick={() => this.handleTypeChange('email')}
                    >
                        <p>Email</p>
                        <EmailIcon className={'tabIcon'} />
                    </div>
                    <div
                        className={
                            currentMessageType.toLowerCase() == 'pushnotification' || currentMessageType.toLowerCase() == 'notification' || currentMessageType.toLowerCase() == 'push' ? 'messageTypeContainer rightContainer activeType' :
                            'messageTypeContainer rightContainer'
                        }
                        onClick={() => this.handleTypeChange('notification')}
                    >
                        <p>Push Notification</p>
                        <NotificationsIcon className={'tabIcon'}/>
                    </div>
                </div>
            </div>
        )
    }

    renderAddRecipients(){
        const {
            context,
            positions,
            positionFilter,
            loadingRecipients,
            currentTeamFilter,
            isSearchingRecipients,
            recipientSearchString,
            potentialRecipientsList,
        } = this.state

        const { currentTeam, teams, currentMessageRecipients, currentRecipientCategory, tenant: { domain } } = this.props

        return(
            <ClickAwayListener onClickAway={this.handleCloseSearch}>
                <div className={'inputContainer'}>
                    <p className={'textInputLabel'}>Add Recipients:</p>
                        <BorderedInput
                            filterOptions={teams}
                            loading={loadingRecipients}
                            secondaryFilterOptions={[]}
                            value={recipientSearchString}
                            searching={isSearchingRecipients}
                            selectedOption={currentTeamFilter}
                            secondaryFilterOptions={positions}
                            onChange={this.handleRecipientSearch}
                            showFilter={context == 'organization'}
                            secondarySelectedOption={positionFilter}
                            onAdornmentClick={this.toggleRecipientFilter}
                            adornment={<FilterList className={'icon'} />}
                            placeholder={'Start typing to search by name'}
                            showSecondaryFilter={domain == 'truelacrosse'}
                            handleSelectFilter={this.handleChangeTeamFilter}
                            handleSelectSecondaryFilter={this.handleChangePositionFilter}
                        />
                        {isSearchingRecipients &&
                            <SearchOverlay
                                currentTeam={currentTeam}
                                close={this.handleCloseSearch}
                                searchValue={recipientSearchString}
                                recipients={potentialRecipientsList}
                                isTrueLacrosse={domain == 'truelacrosse'}
                                isKickID={false}
                                activeCategory={currentRecipientCategory}
                                isOrganization={context == 'organization'}
                                selectedRecipients={currentMessageRecipients}
                                onClickRecipient={r => this.handleRecipientClick(r)}
                                onClick={category => this.handleCategorySelection(category)}
                            />
                        }
                </div>
            </ClickAwayListener>
        )
    }

        // TEXT EDITOR HELPERS //
    // inline text styling
    _onInlineClick(event, inlineType) {
        event.preventDefault();
        this.props.handleEditorChange(
            RichUtils.toggleInlineStyle(
                this.props.editorState,
                inlineType
            )
        )
    }

    // block styling
    _onBlockClick(event, blockType) {
        event.preventDefault();
        let { blockType:existingBlockType }= this.state;
        if(existingBlockType == blockType){
            this.setState({blockType: null})
        }
        else{
            this.setState({blockType:blockType})
        }
        this.props.handleEditorChange(
            RichUtils.toggleBlockType(
                this.props.editorState,
                blockType
            )
        )
    }


    onAddLink = (event) => {
        const { editorState } = this.props;
        return onAddLink(event, editorState, this.props.handleEditorChange)
    };

    onAddImage = (event) => {
        const { editorState } = this.props;
        return onAddImage(event, editorState, this.props.handleEditorChange)
    };

    // highlight active selections
    isClassActive(style) {
        const { editorState } = this.props;
        const currentStyle = editorState.getCurrentInlineStyle();
        const contentState = editorState.getCurrentContent();
        const blocks = contentState.getBlocksAsArray();
        const match = _.find(blocks, b => b.get('type') === style);
        return currentStyle.has(style) || match;
    }

    focus = () => {
        this.setState({ editorClassName: `${this.state.editorClassName} editor-hide-placeholder` })
        this.refs.editor.focus()
    }

    renderMessageContent(){
        const {
            currentMessageType,
            currentMessageString,
            selectedActivity = {},
            currentMessageURL = '',
            currentMessageActivity = '',
            classes,
            editorState,
            handleEditorChange
        } = this.props

        const {
            allActivities,
            isSelectingActivities,
            editorClassName,
            areActivitiesLoading = false,
            blockType
        } = this.state

        return(
            currentMessageType.toLowerCase() == 'pushnotification' || currentMessageType.toLowerCase() == 'notification' || currentMessageType.toLowerCase() == "push" ?
                <div className={'notificationInputContainer'}>
                    <BorderedInput placeholder={'Message'} value={currentMessageString} onChange={this.handleChangeMessageString} />
                    <BorderedInput
                        value={currentMessageURL}
                        placeholder={'External URL'}
                        onChange={this.handleChangeMessageURL}
                        helperText={'Please inclide http:// or https://'}
                    />
                    <BorderedInput
                        clickable={true}
                        loading={areActivitiesLoading}
                        value={selectedActivity.name}
                        placeholder={'Link Activity'}
                        handleClick={this.toggleActivitySelector}
                        onChange={this.handleChangeMessageActivity}
                    />
                    {isSelectingActivities &&
                        <OptionsSelector
                            options={allActivities}
                            selectedOption={selectedActivity}
                            onClickOption ={this.handleActivitySelection}
                        />
                    }
                </div>
            :
                <div className={'inputContainer position-relative'}>
                    <p className={'textInputLabel'}>Message Content:</p>
                    {currentMessageType == 'email' ?
                        <div className={classes.emailInput}>
                            <div className={classes.iconContainer}>
                                <FormatBold onMouseDown={(event) => { this._onInlineClick(event, `BOLD`) }} className={"styleOptionButton"} style={this.isClassActive(`BOLD`) ? { color: '#00a1e1' } : null} />
                                <FormatItalic onMouseDown={(event) => { this._onInlineClick(event, `ITALIC`) }} className={"styleOptionButton"} style={this.isClassActive(`ITALIC`) ? { color: '#00a1e1' } : null} />
                                <FormatUnderlined onMouseDown={(event) => { this._onInlineClick(event, `UNDERLINE`) }} className={"styleOptionButton"} style={this.isClassActive(`UNDERLINE`) ? { color: '#00a1e1' } : null} />
                                <FormatListBulleted onMouseDown={(event) => { this._onBlockClick(event, `unordered-list-item`) }} className={"styleOptionButton"} style={blockType == `unordered-list-item` ? { color: '#00a1e1' } : null} />
                                <FormatListNumbered onMouseDown={(event) => { this._onBlockClick(event, `ordered-list-item`) }} className={"styleOptionButton"} style={blockType == `ordered-list-item` ? { color: '#00a1e1' } : null} />
                                <Link onMouseDown={this.onAddLink} className={"styleOptionButton"} />
                                <Image onMouseDown={this.onAddImage} className={"styleOptionButton"} />
                            </div>
                            <div onClick={this.focus} className={classes.textAreaContainer}>
                                <Editor
                                    ref={"editor"}
                                    editorState={editorState}
                                    onChange={handleEditorChange}
                                    placeholder="Message content goes here"
                                    blockRendererFn={mediaBlockRenderer}
                                />
                            </div>
                        </div>
                        :
                        <BorderedInput placeholder={'Message content goes here'} textArea value={currentMessageString} onChange={this.handleChangeMessageString} />
                    }
                    {currentMessageType == 'sms' && <div className={'inputSubtextContainer'}>
                        <p className={'inputSubtext'}>340 Character Limit</p>
                        <p className={'inputSubtext'}>{currentMessageString.split('').length}/340</p>
                    </div>}
                </div>
        )
    }

    renderRecipientsList(){
        const { currentMessageRecipients } = this.props

        return(
            <div className={'recipientsListOuterContainer'}>
                <p className={'textInputLabel'}>Recipients: <span className={'recipientsCountText'}>{currentMessageRecipients.length ? currentMessageRecipients.length : 'None'}</span></p>
                {currentMessageRecipients.length ?
                    <div className={'recipientsListContainer'}>
                        {currentMessageRecipients.map((recipient, i) => {
                            return (
                                <ChipData key={i} recipient={recipient} handleDelete={() => this.handleRecipientClick(recipient)} />
                            )
                        })}
                    </div>
                :
                    <div onClick={this.toggleRecipientFilter} className={'emptyRecipientsListContainer'}>
                        <div className={'emptyRecipientsListText'}>
                            <AddIcon className={'emptyRecipientsIcon'}/>
                            <p>Add Recipients</p>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderRemainingMessagesBanner(){
        const { messagesSentThisMonth } = this.props

        return(
            <div className={'remainingMessagesBannerContainer'}>
                <p>{5 - messagesSentThisMonth <= 0 ? 'No' : `${5 - messagesSentThisMonth}`} text blasts remaining this month</p>
            </div>
        )
    }

    renderFooter(){
        const { isSendingMessage } = this.state
        const { toggleDateTimeModal, isMonthlyMessageLimitMet, currentMessageType } = this.props
        return(
            <div className={'composeScreenFooterInnerContainer'}>
                <StyledButton
                    title={'Save Draft'}
                    backgroundColor={'#1354F9'}
                    onClick={this.handleSaveMessage}
                    disabled={this.isSendOptionDisabled()}
                />
                <div className={'multipleButtonContainer'}>
                    <StyledButton
                        textColor={'black'}
                        icon={<EventIcon />}
                        title={'Schedule For Later'}
                        onClick={() => toggleDateTimeModal()}
                        disabled={this.isScheduledOptionDisabled()}
                    />
                    <StyledButton
                        backgroundColor={'#27AE60'}
                        onClick={this.handleSendMessage}
                        title={isSendingMessage ? 'Sending' : 'Send Now'}
                        disabled={this.isSendOptionDisabled() || (currentMessageType == 'sms' && isMonthlyMessageLimitMet)}
                        icon={
                            isSendingMessage ?
                                <CircularProgress
                                    style={{
                                        width: null,
                                        height: null,
                                        color: 'white',
                                        marginLeft: 10
                                    }}
                                />
                            :
                                <PlayCircleFilledIcon />
                        }
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext }, tenant } = state
    const currentTeam = userContext.appContextList.find(c => c.id === appContext.id)

    return { currentTeam: currentTeam, tenant }
}

export default withStyles(styles)(connect(mapStateToProps)(ComposeScreen));
