import React from 'react';
import { addhttp } from '../../utils/commonUtil'
import { EditorState, RichUtils, CompositeDecorator, AtomicBlockUtils } from 'draft-js';

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
        );
    },
        callback
    );
}


const EditorLink = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} target='_blank' style={{
            textDecoration: "underline",
            color: "#0044CC"
        }}>
            {props.children}
        </a>
    );
};

export function getLinkDecorator() {
    return new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: EditorLink,
        },
    ])
}

export const mediaBlockRenderer = block => {
    if (block.getType() === "atomic") {
        return {
            component: Media,
            editable: false
        };
    }
    return null;
};

export const Image = props => {
    if (!!props.src) {
        return <img src={props.src} style={{width:"100px", height:"50px"}}/>;
    }
    return null;
};

export const Media = props => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    let media;

    if (type === "image") {
        media = <Image src={src}/>;
    }

    return media;
};

export const onAddLink = (event, editorState, handleEditorChange) => {
    event.preventDefault();
    const link = addhttp(window.prompt("Paste the link -"));
    if (!link) {
        handleEditorChange(RichUtils.toggleLink(editorState, editorState.getSelection(), null));
        return "handled";
    }
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        {
            target: '_blank',
            url: link
        }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let nextEditorState = EditorState.set(editorState,
        { currentContent: contentStateWithEntity }
    );
    // Apply selection
    nextEditorState = RichUtils.toggleLink(nextEditorState,
        nextEditorState.getSelection(), entityKey
    );
    handleEditorChange(RichUtils.toggleLink(nextEditorState, nextEditorState.getSelection(), entityKey));
    return "handled";
};

export const onAddImage = (e, editorState, handleEditorChange) => {
    e.preventDefault();
    const link = window.prompt("Paste Image Link -");
    if (!link) {
        handleEditorChange(RichUtils.toggleLink(editorState, editorState.getSelection(), null));
        return "handled";
    }
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        "image",
        "IMMUTABLE",
        { src: link }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
        editorState,
        { currentContent: contentStateWithEntity },
        "create-entity"
    );
    let nextEditorState = AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        " "
    )
    handleEditorChange(nextEditorState, nextEditorState.getSelection(), entityKey);
    return "handled";
};