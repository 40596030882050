import React, { Component } from 'react'

import './styles.css'

// DIFF TABS HERE
import ReportTab from './tabs'

const signupTabs = [
    { id: 1, title: 'Need to contact our support team?' }
]

export default class ReportForm extends Component{
    constructor(props){
        super(props)
    }

    resolveTab = tab => {
        const {
            reportData:{
                email,
                issue
            }, 
            reportData,   
            currentTab,                        
            isLoading,
            handleChange,
            handleCreateTicket,
            invalidEmail
        } = this.props      
            return (
                <ReportTab
                    key={1}
                    title={tab.title}
                    isLoading={isLoading}
                    open={currentTab === tab.id}
                    reportData={reportData}
                    invalidEmail={invalidEmail}
                    handleCreateTicket={handleCreateTicket}
                    handleChange={(property, value) => handleChange(property, value)}
                    issue={issue}
                />
            )
    }

    render(){
        return(
            <div className={'reportFormOuterContainer'}>
                {signupTabs.map(tab => {
                    return this.resolveTab(tab)
                })}
            </div>
        )
    }
}
