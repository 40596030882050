import React, { Component } from "react";
import { connect } from "react-redux";
import CreateTagComponent from "../components/CreateTag";
import tinycolor from "tinycolor2";
import {
  createSchedule,
  getSchedulesByName,
  getSchedulesByParentId,
} from "../../../actions/schedules-actions";

class CreateTag extends Component {
  constructor(props) {
    super(props);

    const { teamsById, currentTeamId } = props;
    const currentTeam = teamsById[currentTeamId];

    this.state = {
      name: "",
      color: "#ECD401",
      currentTeam: currentTeam,
      errors: {},
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
    this.setState({
      [key]: value,
      errors: { ...this.state.errors, [key]: false },
    });
  };

  validate = async () => {
    const { name } = this.state;
    let errors = {};
    if (!name) {
      errors.name = "Required!";
    } else {
      const names = await getSchedulesByName(name)
      if((names || []).length) {
        errors.name = "Name already exists!";
      }
    }
    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  handleSubmit = async () => {
    const { name, color, currentTeam } = this.state;
    const { getSchedulesByParentId, handleClose } = this.props;
    if (!await this.validate()) {
      return;
    }
    const data = await createSchedule(name, color, currentTeam.organizationId);

    getSchedulesByParentId(currentTeam.organizationId);
    handleClose(data);
  };

  render() {
    const { name, color, errors } = this.state;
    const { handleClose } = this.props;
    return (
      <CreateTagComponent
        name={name}
        errors={errors}
        color={color}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleClose={handleClose}
      />
    );
  }
}

const mapStateToProps = ({ teams: { byId: teamsById, currentTeamId } }) => ({
  teamsById,
  currentTeamId,
});

export default connect(mapStateToProps, {
  createSchedule,
  getSchedulesByParentId,
})(CreateTag);
