import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';


import { API } from "aws-amplify";
import _ from "lodash"

import { withStyles } from '@material-ui/core/styles';

import logo from "../../assets/images/logo-dark.png"

import "./groups.css";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: "100%",
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  paper: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'left',
    width: '100%'
  }
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class GroupsNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      groups:[],
      groupName:"",
      players:[],
      coaches:[],
      programId:"",
      coachChecked:[],
      playerChecked:[],
      value:0
    };
  }

  handleChange = name => event => {
    event.preventDefault()
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handlePlayerToggle = value => () => {
    const { playerChecked } = this.state;
    const currentIndex = playerChecked.indexOf(value);
    const newChecked = [...playerChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      playerChecked: newChecked,
    });
  };

  handleCoachToggle = value => () => {
    const { coachChecked } = this.state;
    const currentIndex = coachChecked.indexOf(value);
    const newChecked = [...coachChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      coachChecked: newChecked,
    });
  };

  async componentDidMount(){
    console.log('currentTeam ', this.props.currentTeam)

    var programId = this.props.currentTeam.id
    this.setState({programId})

    try{
      var players = await this.getPlayers(this.props.currentTeam.id)
      var coaches = await this.getCoaches(this.props.currentTeam.id)
      console.log('players ', players)
      console.log('coaches ', coaches)
      this.setState({players: players || [], coaches: coaches || []})
    }
    catch(e){
      console.log('e ', e)
    }
  }

  getGroups() {
    const { currentTeam } = this.props;
    return API.get("groups", `/programs/${currentTeam.id}/groups`);
  }

  getPlayers() {
    const { currentTeam } = this.props;
    return API.get("programs", `/programs/${currentTeam.id}/players`);
  }

  getCoaches() {
    const { currentTeam } = this.props;
    return API.get("programs", `/programs/${currentTeam.id}/coaches`);
  }

  createGroup(group) {
    console.log('createGroup', group)
    const { currentTeam } = this.props;
    if(!group.id){
      return API.post("groups", `/programs/${currentTeam.id}/groups`, {
        body: group
      });
    }
    else {
      return API.put("groups", `/groups/${group.id}`,{
        body: group
      });
    }
  }

  async handleCreateGroup(){
    const { currentTeam } = this.props;
    var selectedPlayers = []
    var selectedCoaches = []

    if(this.state.coachChecked === 0 && this.state.playerChecked === 0){
      console.log('Please select at least one player or coach.')
      this.setState({errorMessage: "Please select at least one player or coach."})
    }
    else if(!this.state.groupName){
      alert('Group must have a name')
      this.setState({errorMessage: "Group must have a name"})
    }
    else{
      for(var i=0; i < this.state.coachChecked.length; i++){
        var coach = _.find(this.state.coachs, p => p.id === this.state.coachChecked[i])
        coach.role="coach"
        selectedCoaches.push(coach)
      }
      for(var i=0; i < this.state.playerChecked.length; i++){
        var player = _.find(this.state.players, p => p.id === this.state.playerChecked[i])
        player.role="player"
        console.log('here is your player => ', player);
        // selectedPlayers.push(player)
      }

      // var participants = selectedCoaches.concat(selectedPlayers)
      // console.log('participants', participants)
      // var groupJSON = {
      //   "name": this.state.groupName,
      //   "participants":participants,
      //   "parentId": currentTeam.id
      // }

      // var newGroup = await this.createGroup(groupJSON)
      // this.props.history.push('/groups')
    }
  }

  render() {
    const { classes, theme } = this.props;
    const { groups, value } = this.state;

    return (
      <div className="groupsOuterContainer">
        <div className={"pageTitleContainer"} >
            <h1 className={"pageTitle"}>New Group</h1>
            <div className={"commandButtonContainer"}>
              <Button size="small" variant="contained" className={classes.button}
                onClick={() => this.props.history.push('/groups/')}>
                Cancel
              </Button>
              <Button size="small" variant="contained" className={classes.button}
                color="primary"
                onClick={() => this.handleCreateGroup()}>
                Save
              </Button>
            </div>
          </div>
          
          <div>
            <Paper>
            <Grid container spacing={0}>
            
              <Grid item xs={12}>
                <div style={{paddingLeft:15, paddingRight:15}}>
                  <TextField
                    id="groupName"
                    label="Group Name"
                    style={{width:'100%'}}
                    value={this.state.groupName}
                    onChange={this.handleChange("groupName")}
                    margin="normal"
                  />
                </div>
              </Grid>

              <Grid item xs={12} style={{paddingLeft:15, paddingRight:15}}>
                <Tabs style={{borderBottom:'1px solid lightgray'}}
                  value={this.state.value}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Athletes" />
                  <Tab label="Coaches" />
                </Tabs>
              
              {
                value === 0 && <TabContainer dir="">
                  
                  <List subheader={<ListSubheader style={{textAlign:'left'}}>Add athletes to {this.state.groupName ? this.state.groupName : "new"} group...</ListSubheader>}>
                    <Divider />
                    {
                      this.state.players.map((player, i) =>
                        <ListItem
                          key={i}
                          role={undefined}
                          dense
                          button
                          onClick={this.handlePlayerToggle(i)}
                          className={classes.listItem}
                        >
                          <Checkbox
                            checked={this.state.playerChecked.indexOf(i) !== -1}
                            tabIndex={-1}
                            disableRipple
                          />
                          <ListItemText primary={`${player.nameFirst} ${player.nameLast}`} />
                        </ListItem>
                      )
                    }
                  </List>
                  
                </TabContainer>
              }
              {
                value === 1 && <TabContainer dir="">
                  <List subheader={<ListSubheader style={{textAlign:'left'}}>Add coaches to {this.state.groupName ? this.state.groupName : "new"} group...</ListSubheader>}>
                    <Divider />
                    {
                      this.state.coaches.map((coach, i) =>
                        <ListItem
                          key={i}
                          role={undefined}
                          dense
                          button
                          onClick={this.handleCoachToggle(i)}
                          className={classes.listItem}
                        >
                          <Checkbox
                            checked={this.state.coachChecked.indexOf(i) !== -1}
                            tabIndex={-1}
                            disableRipple
                          />
                          <ListItemText primary={ `${coach.nameFirst} ${coach.nameLast}`} />
                        </ListItem>
                      )
                    }
                  </List>
                </TabContainer>
              }
              
              </Grid>
            
            </Grid>
            </Paper>
          </div>
      </div>
    );
  }
}

GroupsNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    currentTeam: currentTeam||{}
  };
}

export default withStyles(styles)(connect(mapStateToProps)(GroupsNew));
