import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Auth } from "aws-amplify";
import _ from 'lodash';
import { Button, Modal, Snackbar, IconButton } from '@material-ui/core';
import Routes from "./routes";
import Nav from './containers/nav/nav';
import { logout, setAppContext, setUserContext } from './actions/session';
import './App.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { getThemeByTenant } from './themes';
import { ApolloProvider } from "react-apollo"; // 2
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import AWSAppSyncClient, { createAppSyncLink, createLinkWithCache } from "aws-appsync";
import { tenantClient } from './clients'
import config from './config'
import ContextService from './services/context';
import appSyncConfig from "./appsync-stg"; // OPS
import opsAppSyncConfig from "./appsync-ops"; // OPS
import devAppSyncConfig from "./appsync-dev"; // DEV
import stgAppSyncConfig from "./appsync-stg"; // STAGING
import tstAppSyncConfig from "./appsync-tst"; // TEST
// import appSyncConfig from "./appsync-staging"; // STAGING
// import appSyncConfig from "./appsync-prod"; // PROD
import { Rehydrated } from "aws-appsync-react"; // 4

import { isMobile, isTablet } from 'react-device-detect';
import {analyticsService, currentTimestamp} from './services/analyticsService';
import classnames from 'classnames';
import { Close } from '@material-ui/icons';
import NewCoach from './components/newCoach';
import { autoSetEnvironment } from 'maxone-react-ui';
import { getTenant } from "./utils/environmentUtils"
import Loader from './components/loaderWithMessage';
import { setTenantInfo } from './actions/tenant';
import { setAuthenticatedUserById } from './actions/user-actions';
import { setCurrentTeamById } from './actions/team-actions';
import loader from './reducers/loader';
import { enableFullScreenLoader } from './actions/loading'
import JssProvider from "react-jss/lib/JssProvider";
import { getThemeMode, getTenantSubdomain } from './utils/commonUtil'
import { getEnvironment } from './utils/environmentUtils'

const stateLink = createLinkWithCache(cache => withClientState({
  cache,
  resolvers: {},
}));

const awsAppSyncLink = createAppSyncLink({ // 5
  url: appSyncConfig.graphqlEndpoint,
  region: appSyncConfig.region,
  auth: {
    type: appSyncConfig.authenticationType,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  complexObjectsCredentials: () => Auth.currentCredentials()
});

const link = ApolloLink.from([stateLink, awsAppSyncLink]);

const client = new AWSAppSyncClient({}, { link });

const subdomain = getTenantSubdomain();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      authSession: null,
      refreshPopup: false,
      disableExternalClick: false
    };
  }

  async componentWillMount() {
    autoSetEnvironment();
    let { setTenantInfo, enableFullScreenLoader, setAuthenticatedUserById, setCurrentTeamById } = this.props;
    try {
      enableFullScreenLoader(true, "Loading....");
      let { data } = await tenantClient.getTenantByDomain(subdomain);
      localStorage.setItem('tenantId', data.id);
       if(data.id === 'ownit'){
        window.location.replace('https://coach.ownitapp.com');
      }
      document.querySelector("link[rel*='icon']").href = data.favicon;
      document.querySelector("title").innerHTML = data.name;
      setTenantInfo(data);
      const authSession = await Auth.currentSession();
      if (!!authSession) {
        const contextService = new ContextService();
        console.log('authSession ', authSession);
        //TODO: Build app context using reducer once loader logic is moved to reducer
        let {userContext, appContext} = await contextService.buildUserContext();
        this.props.setUserContext(userContext);
        this.props.setAppContext(appContext);
        this.setState({ authSession })
        document.addEventListener('visibilityChange', this.checkVersion, false);
        this.checkVersion()

        // Set the authenticated user and current team in Redux
        setAuthenticatedUserById(userContext.user.id);
        setCurrentTeamById(appContext.id);

      }
    }
    catch (e) {
      console.log('e =>> ', e)
      enableFullScreenLoader(false)
      // if (e !== 'No current user') {
      //   alert(e);
      // }
    }
  }

  componentDidUpdate(prevProps) {
    const { users, teams, organizations, enableFullScreenLoader } = this.props;
    const { users: prevUsers, teams: prevTeams, organizations: prevOrganizations } = prevProps;

    // Once they're authenticated and everything has finished loading for the first time, change the app context
    const isAuthenticated = (users.loggedInUserId !== null && teams.currentTeamId !== null && organizations.currentOrganizationId !== null);
    if (isAuthenticated && (!prevUsers.loggedInUserId || !prevTeams.currentTeamId || !prevOrganizations.currentOrganizationId)) {
      this.userHasAuthenticated(true);
      enableFullScreenLoader(false);
    }

    if (this.props.location !== prevProps.location) {
      const { location: { pathname } } = this.props;
      let trackingName = this.getScreenTrackingName(pathname);
      //Don't track Home page request
      if(trackingName != 'Home'){
        analyticsService.trackScreenView(trackingName);
      }

      const currentUser  = this.props.userContext.user;
      // console.log(currentUser)
      if (!analyticsService.identified) {
        analyticsService.initWithUser(currentUser);
      }
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('visibilityChange', this.checkVersion);
  }

  getScreenTrackingName=(path)=>{
    let trackingName = "";
    switch(path){
      case '/':trackingName = 'Home';break;
      case '/leaderboards/points':trackingName = 'Points Leaderboard';break;
      case '/leaderboards/activities':trackingName = 'Performance Leaders';break;
      case '/schedules':trackingName = 'Calendars';break;
      case '/activities/drill':trackingName = 'Skill Development';break;
      case '/activities/exercise':trackingName = 'Strength & Conditioning';break;
      case '/activities/education':trackingName = 'Education Materials';break;
      case '/coachResources':trackingName = 'Coaching Resources';break;
      case '/workoutPrograms':trackingName = 'Workout Program';break;
      case '/workoutSchedules':trackingName = 'Assign Workouts';break;
      case '/directorMessages':trackingName = 'Director Messaging';break;
      case '/directorSchedules':trackingName = 'Director Calendar';break;
      case '/directorActivities':trackingName = 'Director Content Sharing';break;
      case '/directorSettings':trackingName = 'Organization Settings';break;
      default: trackingName = path.charAt(1).toUpperCase() + path.slice(2);
    }
    return trackingName;
  }

  userInfo = () => {
    const authSession = this.state.authSession
    const { userContext } = this.props;
    if (!authSession) return {}
    if (!userContext.user) return {}
    const payload = authSession.idToken.payload
    return { name: payload['cognito:username'], id: userContext.user.id }
    // return { name: payload['cognito:username'], id: payload['sub'] }
  }

  checkVersion = () => {
    let currentVersion = localStorage.getItem('version')
    if (config.version != currentVersion) {
      this.setState({ refreshPopup: true })
    }
  }

  userHasAuthenticated = authenticated => {
    const { tenant } = this.props;
    this.setState({ isAuthenticated: authenticated });
    window.fwSettings={'widget_id':63000001356};
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
    FreshworksWidget('prefill', 'ticketForm', {
      custom_fields:{
        cf_tenant: tenant
      }
    });
    FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_tenant']);
    var s = document.createElement( 'script' );
    const src = 'https://widget.freshworks.com/widgets/63000001356.js'
    s.setAttribute( 'src', src );
    document.body.appendChild( s );


  }

  toggleOverlay = (active) =>{
    this.setState({overlayActive: active})
  }


  enableFullScreenLoader = (active, loadingMessage, loadingMessage2) => {
    //TODO: call action
    let {  enableFullScreenLoader } = this.props;
    enableFullScreenLoader(active, loadingMessage, loadingMessage2)
  }

  toggleExternalClickDisable = ( overrideState) => {
    let { disableExternalClick } = this.state;
    this.setState({disableExternalClick: (overrideState !== null? overrideState :!disableExternalClick)});
  }

  handleLogout = async event => {
    const { tenant } = this.props;
    if (tenant === 'wcs') {
      const wcsOrgId = this.props.currentTeam.organizationId.split("-")[1]
      localStorage.removeItem(`@wcs:students-${wcsOrgId}:`, null)
      localStorage.removeItem(`@wcs:teachers-${wcsOrgId}:`, null)
    }
    await Auth.signOut();
    this.props.logout();
    this.userHasAuthenticated(false);
    analyticsService.trackEvent("Signed Out");
    analyticsService.clearCurrentUser();
    this.props.history.push("/login");
  }


  onClickUser = () => {
    this.props.history.push("/profile")
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value })
  }

  refresh = () => {
    localStorage.setItem('version', config.version)
    window.location.reload(true)
  }

  render(props) {
    const { appContext, currentTeam, tenantName, tenant, loading, loadingMessage, loadingMessage2, history, location } = this.props;
    const  { refreshPopup, overlayActive, disableExternalClick} = this.state
    const theme = getThemeByTenant(tenant);
    let isDarkMode = false;
    if (tenant == 'vnn' || tenant == 'm1vnn' || tenant == 'stagevnn' || tenant == 'demovnn' || tenant == 'coachup') {
      if (currentTeam && !currentTeam.isFree) {
        theme.palette.primary.main = currentTeam && currentTeam.organization && currentTeam.organization.primaryColor
          ? currentTeam.organization.primaryColor
          : theme.palette.primary.main
        theme.palette.secondary.main = currentTeam && currentTeam.organization && currentTeam.organization.secondaryColor
          ? currentTeam.organization.secondaryColor
          : theme.palette.secondary.main;
      }
      theme.palette.primary.isDarkMode = getThemeMode(theme.palette.primary.main);
      theme.palette.secondary.isDarkMode = getThemeMode(theme.palette.secondary.main);
      if (theme.palette.primary.isDarkMode) {
        if (theme.overrides.MuiButton.contained) {
          theme.overrides.MuiButton.contained.color = "#ffffff";
        } else if (theme.overrides.MuiButton.containedPrimary) {
          theme.overrides.MuiButton.containedPrimary.color = "#ffffff";
        }
      }
      if (theme.palette.secondary.isDarkMode) {
        if (theme.overrides.MuiButton.contained) {
          theme.overrides.MuiButton.contained.color = "#ffffff";
        } else if (theme.overrides.MuiButton.containedPrimary) {
          theme.overrides.MuiButton.containedPrimary.color = "#ffffff";
        }
      }
    }

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      tenant: tenant,
      handleLogout: this.handleLogout,
      toggleOverlay: this.toggleOverlay,
      enableFullScreenLoader: this.enableFullScreenLoader,
      toggleExternalClickDisable: this.toggleExternalClickDisable,
      ...this.userInfo()   // This line is imperative for the ChatQL
    };

    let activeRoute = this.props.location ? this.props.location.pathname : "";

    if ((isMobile || isTablet) && tenantName) {
      return <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          {/* {!appContext.isAthlete && !appContext.isGuardian ? <Button onClick={() => this.handleLogout()} color="inherit" style={{ position: "fixed", color: "white", top: 15, right: 15, zIndex: 10000000 }}>LOGOUT</Button> : null} */}
          <Routes childProps={childProps} />
        </MuiThemeProvider>
      </ApolloProvider>
    }

    return (
      <JssProvider>
      <div className={classnames("App container",{"darkMode":isDarkMode},{overlayActive:overlayActive})}>
        {
          !!tenantName && <React.Fragment>
            {this.state.isAuthenticated && !appContext.isAthlete && !appContext.isGuardian
              ?
              <ApolloProvider client={client}>
                <MuiThemeProvider theme={theme}>
                  <NewCoach />
                  <Nav disableExternalClick={disableExternalClick} enableFullScreenLoader={this.enableFullScreenLoader} tenant={this.props.tenant} onClickUser={this.onClickUser} history={history} location={location}>
                    {this.renderBillingModal()}
                    <Routes childProps={childProps} />
                  </Nav>
                </MuiThemeProvider>
              </ApolloProvider>
              :
              <ApolloProvider client={client}>
                <MuiThemeProvider theme={theme}>
                  <Routes childProps={childProps} />
                </MuiThemeProvider>
              </ApolloProvider>}
            </React.Fragment>
        }
        <Snackbar
          style={{
            zIndex:2147483648
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={refreshPopup}
          onClose={() => this.handleChange('refreshPopup', false)}
          message={`Refresh the ${tenantName} web app to receive latest code`}
          action={[
            <Button 
              key="undo" 
              style={{ color: theme.palette.secondary.main!==("#373738" && "#000000")
              ? theme.palette.secondary.main 
              : 'white' }} 
              size="small" 
              onClick={this.refresh}
            >
              Refresh
            </Button>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => this.handleChange('refreshPopup', false)}
            >
              <Close />
            </IconButton>
          ]}
        />
          {loading && <Loader message={loadingMessage} message2={loadingMessage2}/>}
      </div>
      </JssProvider>
    );
  }

  renderBillingModal() {
    const { currentTeam, userContext, appContext } = this.props;
    return (
      <Modal
        open={
          !appContext.isAdmin &&
          !userContext.isImpersonating &&
          currentTeam &&
          currentTeam.organization &&
          currentTeam.organization.billing &&
          !!currentTeam.organization.billing.isLockedOut
        }
      >
        <div className={'billingModal'}>
          <Button
            className={'logoutButton'}
            onClick={() => this.handleLogout()}
          >
            LOGOUT
          </Button>
          <p className={'titleText'}>
            Locked Out for Billing
          </p>
          <div className={'innerBillingModal'}>
            <p>We are currently waiting for your team's payment. Your account will be unlocked as soon as payment is received.</p>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const {
    session: { userContext, appContext },
    tenant,
    loader: {loading, loadingMessage, loadingMessage2},
    users,
    teams,
    organizations
  } = state;
  let currentTeam = {};
  if (userContext && userContext.appContextList && userContext.appContextList.length && appContext) {
    currentTeam = _.find(userContext.appContextList, c => c && c.id === appContext.id);
  }
  return {
    tenant: tenant.id || subdomain,
    tenantName: tenant.name,
    appContext: appContext,
    currentTeam: currentTeam||{},
    userContext: userContext ? userContext : null,
    loading,
    loadingMessage,
    loadingMessage2,
    users,
    teams,
    organizations
  };
}


export default withRouter(connect(mapStateToProps, { setAppContext, logout, setUserContext, setTenantInfo, enableFullScreenLoader, setAuthenticatedUserById, setCurrentTeamById })(App));
