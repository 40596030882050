import { combineReducers } from "redux";
import moment from "moment";
import {
  SET_OFFSET,
  SET_END_DATE,
  SET_START_DATE,
  SET_SELETED_TEAMS,
  GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_LOADING,
  GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_SUCCESS,
  GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_ERROR,
  GET_EVENT_BY_ID_LOADING,
  GET_EVENT_BY_ID_SUCCESS,
  GET_EVENT_BY_ID_ERROR,
  GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_LOADING,
  GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_SUCCESS,
  GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_ERROR,
} from "../actions/nylas-calendar-actions";
import { isEmpty } from "lodash";

const offset = (state = 0, action) => {
  if (action.type === SET_OFFSET) {
    return action.offset;
  }
  return state;
};

const endDate = (
  state = moment.utc().add(31, "days").startOf("day").unix(),
  action
) => {
  if (action.type === SET_END_DATE) {
    return action.endDate;
  }
  return state;
};
const startDate = (
  state = moment.utc().subtract(1, "days").endOf("day").unix(),
  action
) => {
  if (action.type === SET_START_DATE) {
    return action.startDate;
  }
  return state;
};

const selectedTeams = (state = [], action) => {
  if (action.type === SET_SELETED_TEAMS) {
    return action.teams;
  }
  return state;
};

const events = (state = { loading: false, hasMore: false, data: {}, error: "" }, action) => {
  switch (action.type) {
    case GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_LOADING: {
      return {
        ...state,
        loading: true,
        hasMore: false,
        data: action.data.offset === 0 ? {} : { ...state.data },
        error: "",
      };
    }
    case GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_SUCCESS: {
      const { data } = action.data;
      return {
        ...state,
        loading: false,
        hasMore: !isEmpty(data),
        data: action.data.offset === 0 ? data : { ...state.data, ...data },
        error: "",
      };
    }

    case GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_ERROR: {
      return {
        ...state,
        hasMore: false,
        loading: false,
        data: {},
        error: "No events found",
      };
    }
  }
  return state;
};

const eventById = (state = { loading: false, data: {}, error: "" }, action) => {
  switch (action.type) {
    case GET_EVENT_BY_ID_LOADING: {
      return {
        ...state,
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_EVENT_BY_ID_SUCCESS: {
      const data = { ...state.data };
      data[action.data.id] = action.data;
      return {
        ...state,
        loading: false,
        data: data,
        error: "",
      };
    }

    case GET_EVENT_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: "No events found",
      };
    }
  }
  return state;
};

const instanceById = (state = { loading: false, data: {}, error: "" }, action) => {
  switch (action.type) {
    case GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_LOADING: {
      return {
        ...state,
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_SUCCESS: {
      const data = { ...state.data };
      data[action.data.id] = action.data;
      return {
        ...state,
        loading: false,
        data: data,
        error: "",
      };
    }

    case GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_ERROR: {
      return {
        ...state,
        loading: false,
        data: {},
        error: "No events found",
      };
    }
  }
  return state;
};

export default combineReducers({
  offset,
  events,
  endDate,
  eventById,
  startDate,
  instanceById,
  selectedTeams,
});
