import React, { Component } from 'react'

import _ from 'lodash'
import { connect } from 'react-redux'
import ListCard from '../../components/listCard'
import SearchIcon from '@material-ui/icons/Search'
import BorderedInput from '../../components/borderedInput'
import OptionsSelector from '../../components/optionsSelector'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CardListItem from '../../components/cardListItem'
import { getLocalizedString } from '../../utils/locale-utils';
import PageWrapper from '../nav/pageWrapper';
import { questionClient, tagClient } from '../../clients';
import AddQuestion from './addQuestion';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BodyWrapper from '../../components/bodyWrapper'
import InfiniteScroll from 'react-infinite-scroller'
import { withStyles } from "@material-ui/core/styles"
import GenerateReportModal  from './generateReportModal';
import ThemeButton from '../../components/themeButton';
import classnames from 'classnames';
import fileDownload from 'js-file-download';
import { getQuestionInputInfo } from '../../utils/questionsUtils';
import { QUESTION_TYPES } from '../../constants'
const styles = {
    scroller: {
        height: "calc(100vh - 282px)",
        overflow: "scroll"
    },
    generateBtn:{
        marginRight:"10px"
    },
    searchSectionContainer:{
        width: "500px !important",
        "& p":{
            marginBottom:"0px !important"
        }
    },
    gridContainer:{
        cursor:"pointer",
        maxWidth: "460px",
        minWidth: "350px",
        flex: 1,
        marginRight: "20px"
    },
    workoutListCardContainer:{
        padding: "30px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        overflow: "scroll",
        paddingBottom: "90px",
        paddingRight: "15px"
    }
};

class Questions extends Component {
    constructor(props) {
        super(props);
        this.typingTimeout = null;
        this.state = {
            page: -1,
            allTags: [],
            questions: [],
            isLoading: true,
            filteredQuestions: [],
            selectedTagFilter: {},
            isSelectingTag: false,
            openQuestionEditModal: false,
            limit:20,
            isGenerateModalOpen: false
        }
    }
    componentDidMount() {
        this.fetchQuestions();
    }

    fetchQuestions = async (page) => {
        let { currentTeam, enableFullScreenLoader } = this.props;
        let { searchTerm, page: existingPage, questions: existingQuestions, limit, selectedTagFilter } = this.state;
        let params = {
            teamId: currentTeam.id
        }
        if(!!selectedTagFilter && selectedTagFilter.id){
            params['tags.slug'] = selectedTagFilter.id;
        }
        if (page == null) {
            page = existingPage + 1;           
        }
        params.offset = page * limit;
        params.limit = limit;
        if (searchTerm) {
            params.q = searchTerm;
        }
        try {
            const [{ data: { results, total, meta: { distinctTags } } }, { data: {results:availableTags} }] = await Promise.all([questionClient.searchQuestions(params), tagClient.getAvailableTagsByTeamId(currentTeam.id)]);
            let questions = results || [];
            //format few values
            questions.forEach((question) => {
                if(question.type == QUESTION_TYPES.SINGLE_CHOICE && (question.options||[]).length == 2 && (question.options[0]).toLowerCase() == "yes" && (question.options[1]).toLowerCase() == "no"){
                    question.type = QUESTION_TYPES.YES_NO
                }
                question.name = question.title;
                question.formattedType = getQuestionInputInfo(question.type);
            })
            if (page != 0 && page != null) {
                questions = existingQuestions.concat(questions);
            }            
            let slugAndTagMap = {};
            availableTags.forEach(tag=> {
                slugAndTagMap[tag.slug] = tag.label
            })
            let allTags = distinctTags.map(item => {return {name: slugAndTagMap[item.slug], id: item.slug}});
            this.setState({ total, questions, filteredQuestions: questions, allTags, page });
        }
        catch (e) {
            console.log(e);
        }
        enableFullScreenLoader(false);
        this.setState({ isLoading: false })
    }



    toggleQuestionEditModal = (selectedQuestion) => {
        let { openQuestionEditModal } = this.state;
        this.setState({ openQuestionEditModal: !openQuestionEditModal, selectedQuestion: (!openQuestionEditModal? selectedQuestion: null) });
    }

    // handleEditQuestion = (question) => {
    //     this.setState({ selectedQuestion: question, openQuestionEditModal: true });
    // }

    handleDeleteQuestion = async (question) => {
        let { enableFullScreenLoader } = this.props;
        enableFullScreenLoader(true, "Deleting...");
        try {
            await questionClient.deleteQuestionById(question.id);
        }
        catch (e) {
            console.log(e);
        }
        this.fetchQuestions(0);
    }

    saveQuestion = async (formData, tags, addNew) => {
        let { currentTeam, currentUser, enableFullScreenLoader } = this.props;
        delete formData.formattedType;
        enableFullScreenLoader(true, "Saving...");
        if(formData.type == QUESTION_TYPES.YES_NO){
            formData.type = QUESTION_TYPES.SINGLE_CHOICE
            formData.options = ["YES","NO"]
        }
        try {
            let questionData = {};
            if(formData.id){
                let { data } = await questionClient.updateQuestionById(formData.id, { ...formData, teamId: currentTeam.id, userId: currentUser.id });
                questionData = data;
            }
            else{
                let { data } = await questionClient.createQuestion({ ...formData, teamId: currentTeam.id, userId: currentUser.id });
                questionData = data;
            }
            //TODO: Add tagging           
            await tagClient.replaceTagLabelsByEntityId("QUESTION", questionData.id, currentTeam.id, tags);
        }
        catch (e) {
            console.log(e)
        }
        this.setState({ openQuestionEditModal: false, selectedQuestion: null }, () => this.fetchQuestions(0))
        if (addNew) {
            this.setState({ openQuestionEditModal: true })
        }

    }

    toggleFilterMenu = () => {
        let { isSelectingTag } = this.state;
        this.setState({ isSelectingTag: !isSelectingTag});
    }

    handleTagSelection = async tag => {
        let { selectedTagFilter } = this.state;
        let { enableFullScreenLoader } = this.props;
        let computedOption = {};
        enableFullScreenLoader(true);
        if(!selectedTagFilter || selectedTagFilter.id != tag.id){
            computedOption = tag;         
        }
        this.setState({selectedTagFilter:computedOption}, () => this.fetchQuestions(0));
    }


    handleSearch = (searchTerm, timeOut = 0) => {
        let { enableFullScreenLoader } = this.props;
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }
        this.setState({
            searchTerm
        }, () => {
            this.typingTimeout = setTimeout(() => {
                enableFullScreenLoader(true)
                this.fetchQuestions(0);
            }, (timeOut || 1000))
        });
    }

    toggleGenerateReportModal = () => {
        let { isGenerateModalOpen } = this.state;
        this.setState({ isGenerateModalOpen: !isGenerateModalOpen})
    }

    generateReport = async (scope, entityId, startDate, endDate) => {
        let { enableFullScreenLoader } = this.props;
        try {
            enableFullScreenLoader(true, "Generating Report....");
            let { data: {s3Link} } = await questionClient.createReport(scope, entityId, startDate, endDate);
            await fetch(s3Link)
            .then(response => response.text())
            .then(data => {
                fileDownload(data, "report.csv")
            });    
        }
        catch (e) {
            console.log(e)
        }
        enableFullScreenLoader(false);
        this.toggleGenerateReportModal();
    }

    render() {
        const {
            allTags,
            isLoading,
            isSelectingTag,
            filteredQuestions,
            selectedTagFilter,
            openQuestionEditModal,
            selectedQuestion,
            searchTerm,
            loadingChildMessage,
            total,
            page,
            isGenerateModalOpen
        } = this.state
        let { classes } = this.props;

        const cardFooterElements = [
            {
                name: "Delete",
                icon: <DeleteIcon />,
                action: this.handleDeleteQuestion
            },
            {
                name: "Edit",
                icon: <EditIcon />,
                action: this.toggleQuestionEditModal
            }
        ];

        return (
            <PageWrapper title={getLocalizedString("Questions")} {...this.props} isPageLoading={isLoading} loadingMessage="Loading...">
                <div className={'workoutListOuterContainer'}>
                    <div className={'workoutListInnerContainer'}>
                        <div className={'workoutListHeaderContainer'}>
                            <p className={'workoutListHeaderTitle'}></p>
                            <div className={'d-flex align-items-center ml-auto'}>
                            <div className={classes.generateBtn}>
                                <ThemeButton
                                        width="150px"
                                        textColor={"#ffffff"}
                                        color={"#27AE60"}
                                        height={"44px"}
                                        position="left"
                                        iconName=""
                                        theme="small"
                                        text={"Generate Report"}
                                        onClick={this.toggleGenerateReportModal}
                                    />
                                </div>
                                <div className={classnames("inputContainer", classes.searchSectionContainer)}>
                                    <BorderedInput
                                        leftIcon={<SearchIcon />}
                                        value={searchTerm}
                                        placeholder={'Search Questions'}
                                        onChange={(e) => this.handleSearch(e.target.value)}
                                    />
                                    <div style={{ marginLeft: 10, width: '100%' }}>
                                        <BorderedInput
                                            clickable={true}
                                            value={selectedTagFilter.name}
                                            placeholder={'Filter by Tags'}
                                            rightIcon={<ArrowDropDownIcon />}
                                            handleClick={this.toggleFilterMenu}
                                        />
                                        {isSelectingTag &&
                                            <OptionsSelector
                                                options={allTags}
                                                close={this.toggleFilterMenu}
                                                className={'optionsSelector'}
                                                selectedOption={selectedTagFilter}
                                                onClickOption={this.handleTagSelection}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.scroller} ref={(ref) => this.scrollParentRef = ref}>
                            <InfiniteScroll
                                pageStart={page}
                                loadMore={this.fetchQuestions}
                                hasMore={filteredQuestions.length < total}
                                useWindow={false}
                                getScrollParent={() => this.scrollParentRef}
                                loader={<div className="moreMessageLoader">Loading ...</div>}
                            >
                                <div className={classes.workoutListCardContainer}>
                                    <div className={classes.gridContainer}>
                                        <CardListItem
                                            addingListItem={true}
                                            program={{ weeks: [[]] }}
                                            onEditSelect={this.toggleQuestionEditModal}
                                            newContentTitle={"Create New Question"}
                                            save={this.saveQuestion}
                                        />
                                    </div>
                                    {isLoading && <ListCard title='Loading...' loading={true} />}
                                    {filteredQuestions.map((question, index) => {
                                        return (
                                            <div className={classes.gridContainer} key={index}>
                                                <CardListItem
                                                    item={question}
                                                    details={(question.formattedType)}
                                                    subtitle={question.description}
                                                    onEditSelect={this.toggleQuestionEditModal}
                                                    onDeleteSelect={this.handleDeleteQuestion}
                                                    save={this.saveQuestion}
                                                    cardFooterElements={cardFooterElements}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
                {openQuestionEditModal && <AddQuestion open={true} close={this.toggleQuestionEditModal} selectedQuestion={selectedQuestion} save={this.saveQuestion} />}
                {isGenerateModalOpen && <GenerateReportModal generateReport={this.generateReport} closePopup={this.toggleGenerateReportModal}/>}
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = userContext.appContextList.find(c => c.id === appContext.id);
    return {
        currentTeam,
        currentUser: userContext.user
    }
}

export default (withStyles(styles)(connect(mapStateToProps)(Questions)));
