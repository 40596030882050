import React, { Component, Fragment } from 'react'
import VideoThumbnail from 'react-video-thumbnail'; // use npm published version

import './styles.css'
import _ from 'lodash'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { Draggable } from 'react-beautiful-dnd'
import { getTeamLogo, getActivityThumbnail, fetchVimeoVideoThumbnail, fetchWistiaVideoThumbnail } from '../../utils/commonUtil.js'
import { connect } from 'react-redux';
 class DraggableListItem extends Component {
    constructor(props){
        super(props)
        this.state = { isHovering: false }
    }

    handleOnMouseEnter = () => {
        this.setState({ isHovering: true })
    }

    handleOnMouseLeave = () => {
        this.setState({ isHovering: false })
    }

    componentDidMount(){
        const { listItem } = this.props;        
        if (listItem.videoType == "vimeo") {            
            fetchVimeoVideoThumbnail(listItem).then(res => this.setState({ vimeo_thumb: res }));
        } else if (listItem.videoType == "wistia") {            
            fetchWistiaVideoThumbnail(listItem).then(res => this.setState({ wistia_thumb: res }));
        }
    }

    render(){
        const { isHovering } = this.state
        const { title, listItem, index, isSelected, selectItem, editItem, currentTeam, libraries } = this.props
        const category = libraries.find(item => (item.value||"").toLowerCase() == (listItem.category||"").toLowerCase())||{};
        const teamLogo = getTeamLogo(currentTeam)
        listItem.thumbnailImage = getActivityThumbnail(listItem) || this.state.vimeo_thumb || this.state.wistia_thumb ||teamLogo;
        return(
            <Draggable key={listItem.id} draggableId={listItem.id.toString()} index={index} style={{width:"100%"}} >
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onMouseEnter={this.handleOnMouseEnter} 
                        onMouseLeave={this.handleOnMouseLeave}
                        className={'draggableListItemOuterContainer'}
                        onClick={()=> selectItem(index, listItem)}
                    > 
                        <div className={'draggableActivityImageContainer'}>
                            <img src={(listItem || {}).thumbnailImage || null } />
                        </div>
                        <div className={'textIconContainer'}>
                            <p className={'title'}>{title}</p>
                            {
                                isHovering ?
                                <Fragment>
                                    <p className={'subCategorySelected'}>{`Drag and drop into the workout`}</p>
                                    <div className="editIconContainer"  onClick={()=> editItem(listItem)}><EditIcon /><div>Edit</div></div>
                                </Fragment>
                            :
                                <p className={'subCategory'}>{`${category.name||""}`}</p>
                            }
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }
}

const mapStateToProps = (state) => {
    const { session: { appContext } } = state;
    return {
        libraries: appContext.libraries||[]
    };
}

export default connect(mapStateToProps)(DraggableListItem);
