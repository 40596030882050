import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import trueLacrosseLogo from '../assets/images/themes/trueLacrosse/transparentWithGreen.png';
import loginTrueLacrosseLogo from '../assets/images/themes/trueLacrosse/transparentWithBlack.png';
import homeImage from '../assets/images/themes/trueLacrosse/truelacrosse-welcome.png'; // swap out for true lacrosse

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'truelacrosse',
    name: 'True Lacrosse',
    appUrl: 'm1-trueLacrosse://',
    domain: 'truelacrosse.gomaxone.com',
    link: 'https://apps.apple.com/us/app/true-lacrosse/id1485887086', // replace with real link
    onAppleAppStore: 'https://apps.apple.com/us/app/true-lacrosse/id1485887086', // replace with real link
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.truelacrosse' // replace with real link
  },
  image: {
    home: homeImage,
    logo: trueLacrosseLogo,
    loginLogo: loginTrueLacrosseLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#96d500"
    },
    secondary: {
      main: "#A9A9A9"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  }

});
