import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en';
import de from './locales/de'

const resources = {
    en: { translation: en },
    de: { translation: de }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });


export const getLocalizedString = (key) => i18n.t(key);


export default i18n;