import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './index.scss';

import DownArrowIcon from '../../icons/DownArrowIcon';
import UpArrowIcon from '../../icons/UpArrowIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import ClipboardIcon from '../../icons/ClipboardIcon';
import PencilIcon from '../../icons/PencilIcon';
import GoalIcon from '../../icons/GoalIcon';

import NewWorkoutActivityModal from '../../../modals/newWorkoutActivityModal';
// import bootstrap from 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap-grid.min.css';

function SingleActivity({
	currentTeamIdFromRedux,
	byId,
	activity,
	deleteSingleActivity,
	multiDeleteActive,
	addToDeleteList,
	activityViewerOnClick,
	activityScoreOnClick,
	isGoalSetter,
	routeId,
	deleteStatus,
	//storybook props
	dropDownProp,
	//amit props
	modalTags,
	editActivitySaveFunction,
	currentActivityToState,
	currentTeamId,
	workoutModalOnChangeFunction,
	addTag,
	removeTag,
	resetActivity,
	currentActivity,
	handleFileSelect,
	handleVideoUrlChange,
	removeActivityVideo,
	handleThumbnailSelect,
	cancelUpload,
	displaySuccessOverlay,
	uploadingThumbnailFile,
	videoFileUploadProgress,
	thumbnailUploadProgress,
	overlayMessage,
	uploadingVideoFile,
	invalidVideoUrl,
}) {
	const [dropDown, setDropDown] = useState(dropDownProp);
	//state to add class for styles if its going to be deleted
	const [toBeDeleted, setToBeDeleted] = useState(false);

	//amit state
	const [newWorkoutModalOpen, setNewWorkoutModalOpen] = useState(false);

	let { logo } = byId[currentTeamIdFromRedux];

	const dropdownOnClick = () => {
		dropDown ? setDropDown(false) : setDropDown(true);
	};

	const deleteButtonOnClick = (id) => {
		if (window.confirm('Are you sure you want to delete this activity?')) {
			deleteSingleActivity(id);
		}
	};

	useEffect(() =>{
		if (deleteStatus === 'cleared') {
			setToBeDeleted(false)
		}
		if (deleteStatus === 'delete all') {
			setToBeDeleted(true)
		}
	}, [deleteStatus]);

	return (
		<div
			className={`activity-thumbnail ${toBeDeleted ? 'to-be-deleted' : ''} ${
				multiDeleteActive ? 'delete-active' : ''
			}`}
			onClick={
				multiDeleteActive
					? () => {
							addToDeleteList(activity.id);
							toBeDeleted ? setToBeDeleted(false) : setToBeDeleted(true);
					  }
					: undefined
			}>
			<img
				src={
					activity.media !== null
						? activity.media.thumbnail
							? activity.media.thumbnail
							: logo
						: logo
				}
				alt=''
				onClick={
					multiDeleteActive ? undefined : () => activityViewerOnClick(activity)
				}
				className='thumbnail'
			/>
			<div className='text-and-arrow-container grid'>
				<div className='row no-gutters text-and-arrow'>
					<p className='elipsis-overflow col-10'>{activity.title}</p>
					<div className='col-2 dropdown'>
						<button
							onClick={multiDeleteActive ? undefined : dropdownOnClick}
							className='button dropdown-button'>
							{dropDown ? (
								<UpArrowIcon className='icon' />
							) : (
								<DownArrowIcon className='icon' />
							)}
						</button>
					</div>
				</div>
				{/* dropdown starts here */}
				<div className={dropDown ? 'visible' : 'invisible'}>
					<div className='row no-gutters dropdown-container'>
						{activity.scoreType !== 'PARTICIPATION' && routeId !== 'coach' ? (
							<div className='col-3 dropdown'>
								<Tooltip title='Goal'>
									<button
										className='button'
										onClick={() => {
											activityScoreOnClick(activity);
											isGoalSetter(true);
										}}>
										<GoalIcon className='icon' />
									</button>
								</Tooltip>
							</div>
						) : null}
						<div
							className={
								routeId === 'coach'
									? 'col-6 dropdown'
									: activity.scoreType !== 'PARTICIPATION'
									? 'col-3 dropdown'
									: 'col-4 dropdown'
							}>
							<Tooltip title='Delete'>
								<button
									onClick={() => deleteButtonOnClick(activity.id)}
									className='button'>
									<DeleteIcon className='icon' />
								</button>
							</Tooltip>
						</div>
						{routeId !== 'coach' ? (
							<div
								className={
									activity.scoreType !== 'PARTICIPATION'
										? 'col-3 dropdown'
										: 'col-4 dropdown'
								}>
								<Tooltip title='Score'>
									<button className='button'>
										<ClipboardIcon
											className='icon'
											onClick={() => {
												activityScoreOnClick(activity);
												isGoalSetter(false);
											}}
										/>
									</button>
								</Tooltip>
							</div>
						) : null}
						<div
							className={
								routeId === 'coach'
									? 'col-6 dropdown'
									: activity.scoreType !== 'PARTICIPATION'
									? 'col-3 dropdown'
									: 'col-4 dropdown'
							}>
							<Tooltip title='Edit'>
								<button
									className='button'
									onClick={() => {
										currentActivityToState(activity);
										setNewWorkoutModalOpen(true);
									}}>
									<PencilIcon className='icon' />
								</button>
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
			<NewWorkoutActivityModal
				activity={currentActivity}
				tags={modalTags}
				handleChange={(name) => workoutModalOnChangeFunction(name)}
				save={() => {
					editActivitySaveFunction();
					setNewWorkoutModalOpen(false);
				}}
				open={newWorkoutModalOpen}
				close={() => {
					setNewWorkoutModalOpen(false);
					resetActivity();
				}}
				resetActivity={() => {resetActivity()}}
				addTag={(tag) => addTag(tag)}
				removeTag={(tag) => removeTag(tag)}
				currentTeam={currentTeamId}
				handleFileSelect={handleFileSelect}
				handleVideoUrlChange={handleVideoUrlChange}
				removeActivityVideo={removeActivityVideo}
				handleThumbnailSelect={handleThumbnailSelect}
				cancelUpload={cancelUpload}
				// displaySuccessOverlay={displaySuccessOverlay}
				uploadingThumbnailFile={uploadingThumbnailFile}
				videoFileUploadProgress={videoFileUploadProgress}
				thumbnailUploadProgress={thumbnailUploadProgress}
				// overlayMessage={overlayMessage}
				uploadingVideoFile={uploadingVideoFile}
				invalidVideoUrl={invalidVideoUrl}
			/>
		</div>
	);
}

SingleActivity.propTypes = {
	dropDownProp: PropTypes.bool,
	activity: {
		id: PropTypes.string,
		title: PropTypes.string,
		media: {
			type: PropTypes.string,
			source: PropTypes.string,
			url: PropTypes.string,
			thumbnail: PropTypes.string,
		},
		description: PropTypes.string,
		tags: [
			{
				id: PropTypes.string,
				label: PropTypes.string,
				slug: PropTypes.string,
			},
			{
				id: PropTypes.string,
				label: PropTypes.string,
				slug: PropTypes.string,
			},
		],
		isDeleted: PropTypes.bool,
		hasLeaderboard: PropTypes.string,
		category: PropTypes.string,
		scoreType: PropTypes.string,
		isPercentOfMax: PropTypes.string,
		percentOfMaxActivityId: PropTypes.string,
	},
};

SingleActivity.defaultProps = {
	dropDownProp: false,
	activity: {
		title: 'no title provided',
		media: {
			thumbnail: null,
		},
	},
};
const mapStateToProps = (state) => {
	const {
		session: { appContext },
		teams: { byId, currentTeamId },
	} = state;

	return {
		appContext: appContext,
		byId: byId,
		currentTeamIdFromRedux: currentTeamId,
	};
};

export default connect(mapStateToProps)(SingleActivity);
