import React, { Component } from 'react'

import AddIcon from '@material-ui/icons/Add'
import GroupIcon from '@material-ui/icons/Group'
import CancelIcon from '@material-ui/icons/Cancel'
import { WhistleIcon } from '../../../../components/icons'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import momentTZ from 'moment-timezone';

import './styles.css'

export default class RecipientListItem extends Component {
    constructor(props){
        super(props)
        this.state = { isHovering: false }
    }

    onMouseEnter = () => {
        const { selected } = this.props
        if(!selected) return
        this.setState({ isHovering: true })
    }

    onMouseLeave = () => {
        const { selected } = this.props
        this.setState({ isHovering: false })
    }

    isParent = () => {
        const { recipient = {} } = this.props
        return recipient.secondaryText == 'Parent'
    }

    renderIcon = () => {
        const { recipient = {} } = this.props
        if(recipient.secondaryText && recipient.secondaryText.includes('Users'))
            return <GroupWorkIcon className={'icon'} />
        if(recipient.secondaryText && recipient.secondaryText.includes('Parent'))
            return <GroupIcon className={'icon'} />
        if(recipient.secondaryText && recipient.secondaryText.includes('Coach'))
            return <WhistleIcon className={'icon'} />
        if(recipient.secondaryText && recipient.secondaryText.includes('Athlete'))
            return <DirectionsRunIcon className={'icon'} />
    }

    getHighlightedText(name) {
        const { searchValue } = this.props
        if(name){
            const parts = name.split(new RegExp(`(${searchValue})`, 'gi'))
            return (
                parts.map((part, i) =>
                    <span key={i} style={part.toLowerCase() === searchValue.toLowerCase() ? { color: '#1354F9' } : {} }>
                        {part}
                    </span>
                )
            )
        }
    }

    render(){
        const {
            order,
            selected,
            searchValue,
            onHandleClick,
            recipient = {}
        } = this.props

        const { isHovering } = this.state

        return(
            <div className={order > 0 ? 'listItemOuterContainer topBorder' : 'listItemOuterContainer'}>
                <div className={'iconNameContainer'}>
                    {this.renderIcon()}
                    <p className={selected && isHovering ? 'listItemPrimaryText hoveringSelected' : 'listItemPrimaryText'}>
                        {this.getHighlightedText(recipient.name)}
                    </p>
                    {
                      recipient.name && (recipient.name !== "All Athletes") && (recipient.name !== "All Coaches") && (recipient.name !== "All Parents") && <p className={'listItemSecondaryText'}>{" "} - {momentTZ.tz(recipient.timezone || momentTZ.tz.guess()).format('z')}</p>
                    }
                    {this.isParent() && <div className={'diagonalDivider'}/>}
                    {this.isParent() &&
                        <div className={'parentAthleteContainer'}>
                            <p className={'parentAthleteText'}>{this.getHighlightedText(`${recipient.athlete.nameFirst} ${recipient.athlete.nameLast}`)}</p>
                            <p className={'parentAthleteSubtext'}>Athlete Name</p>
                        </div>
                    }
                </div>
                <div className={'addOuterContainer'}>
                    <p className={'listItemSecondaryText'}>{recipient.secondaryText}</p>
                    <div
                        onClick={() => { this.setState({ isHovering: true }); onHandleClick() }}
                        className={selected ? 'addRecipientButtonContainer selectedAddButtonContainer' : 'addRecipientButtonContainer'}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        {selected ? <CancelIcon className={'addIcon'} /> : <AddIcon className={'addIcon'} />}
                        <p className={'addRecipientButtonText'}>
                            {selected && isHovering ? 'Remove' : selected ? 'Added' : 'Add'}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
