import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { API, Auth } from "aws-amplify";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
 } from "@material-ui/core";

 import LanguageIcon from "@material-ui/icons/Language"

 import moment from "moment"

import { withStyles } from '@material-ui/core/styles';

import {Storage} from "aws-amplify";

const styles = theme => ({});

class UserActivityLeaderboard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          isLoading: false,
          leaderboardActivity: {}
      };
  }

  async componentDidMount(){
    var leaderboardActivity = await this.getLeaderboard();
    console.log('leaderboardActivity ', leaderboardActivity)
    var newLeaderboard = {}
    if(leaderboardActivity){
      const customPrefix =  { public: "", private: "", protected:"" };

      console.log('Get ======= ', leaderboardActivity.location)
      var location = leaderboardActivity.location;
      await Storage.get(location, { download: true, customPrefix: customPrefix, bucket: 'maxone-leaderboards' })
      .then(result => {
            var newLeaderboardString = this.Utf8ArrayToStr(result.Body)
            newLeaderboard = JSON.parse(newLeaderboardString);
            newLeaderboard.location = location
            var userId = this.props.match.params.userId

            var userTotal = _.find(newLeaderboard.totals, {id:userId});
            var userResults = _.filter(newLeaderboard.results, {userId:userId});

            console.log(userTotal)
            console.log(userResults)
            this.setState({ leaderboardActivity: newLeaderboard, userTotal, userResults })
      })
      .catch(err => {
          console.log('error axios');
          console.log(err)
      });
    }
  }

  async getLeaderboard(currentLeaderboard){
    const { currentTeam } = this.props;
    return Auth.currentSession()
    .then(auth =>{
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken
        }
      }
      // var currentTeam ={id: "c95fa480-9726-11e8-8888-57998f2718c8"}
      // var currentLeaderboard = "5q6wTdJqzhhp6J47guTfWD"
      return API.get("leaderboards", `/leaderboards/${currentLeaderboard}`, options);
    })
  }
  Utf8ArrayToStr(array) {
      var out, i, len, c;
      var char2, char3;

      out = "";
      len = array.length;
      i = 0;
      while (i < len) {
          c = array[i++];
          switch (c >> 4) {
              case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                  // 0xxxxxxx
                  out += String.fromCharCode(c);
                  break;
              case 12: case 13:
                  // 110x xxxx   10xx xxxx
                  char2 = array[i++];
                  out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                  break;
              case 14:
                  // 1110 xxxx  10xx xxxx  10xx xxxx
                  char2 = array[i++];
                  char3 = array[i++];
                  out += String.fromCharCode(((c & 0x0F) << 12) |
                      ((char2 & 0x3F) << 6) |
                      ((char3 & 0x3F) << 0));
                  break;
          }
      }

      return out;
  }

  render() {
    const { leaderboardActivity = {}, userTotal = {}, userResults = {} } = this.state
    return(
      <div>
      <Grid container spacing={8}>
        <Grid item xs={12} style={{textAlign:'left'}}>
          <h2>{leaderboardActivity.name} Results for {userTotal.nameFirst} {userTotal.nameLast}</h2>
        </Grid>
        <Grid item xs={12} style={{textAlign:'left', marginTop:-30}}>
          <p>These are all the results this athlete has recorded for this activity.</p>
        </Grid>
        <Grid item xs={12} style={{textAlign:'right', marginTop:10}}>
          <Paper>
            {
              leaderboardActivity.totals
              ?
              <div>
              <Grid container spacing={8} style={{paddingTop:15, paddingBottom:15}}>
                <Grid item xs={1} style={{textAlign:'center'}}>
                </Grid>
                <Grid item xs={4} style={{textAlign:'left', fontWeight:'bold'}}>
                  When
                </Grid>
                <Grid item xs={1} style={{textAlign:'left', fontWeight:'bold'}}>
                  Result
                </Grid>
              </Grid>
              <Divider />
              {
                userResults.map((result, i) =>{
                  return(
                    <div key={i}>
                      <Grid container spacing={8} style={{paddingTop:15, paddingBottom:15}}>
                        <Grid item xs={1} style={{textAlign:'center', padding:0}}>
                          { i + 1 }
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'left'}}>
                          {moment(result.createdAt).format("MMM DD, YYYY - hh:mm:ss A")}
                        </Grid>
                        <Grid item xs={1} style={{textAlign:'left'}}>
                          {result.completionValue}
                        </Grid>
                      </Grid>
                      <Divider />
                    </div>
                  )
                })
              }
              </div>
              :
              <div style={{paddingTop:20, paddingBottom:20, textAlign:"center"}}>
                There are no results.
              </div>
            }
          </Paper>
        </Grid>
      </Grid>
      </div>
    )
  }

}

UserActivityLeaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
      currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(UserActivityLeaderboard));
