import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API, Auth } from "aws-amplify";
import _ from "lodash"
import {
    Card,
    Grid,
    Modal,
    Hidden,
    Button,
    Collapse,
    CardMedia,
    TextField,
    CardHeader,
    IconButton,
    Typography,
    CardActions,
    CardContent,
    InputAdornment,
    CircularProgress,
 } from "@material-ui/core";

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { withStyles } from '@material-ui/core/styles';

import logo from "../../assets/images/brand/logo-dark@2x.png";

import classNames from 'classnames';

import moment from 'moment';

import NewEventModal from './new.js';

import "./events.css";

import ContextService from '../../services/context';

import short from "short-uuid";
import PageWrapper from '../nav/pageWrapper';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
const contextService = new ContextService()

var translator = short();

const styles = theme => ({
    plusButton: {
        backgroundColor: "#1354F9",
        color: 'white'
    },
    textButton: {
        color: 'white',
        marginRight: '3px',
        backgroundColor: "#1354F9",
    },
    secondaryTextButton: {
        marginRight: '3px',
        backgroundColor: "#27AE60",
        color:"#ffffff",
        '&:disabled': {
            opacity:"0.3",
            color:"#ffffff"
        }
    },
    button: {
        width: 50
    },
    pageTitleWrapper: {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    pageTitle: {
        textAlign: 'left',
        marginLeft: 60
    },
    activitiesContainer: {
        paddingLeft: 40,
        paddingRight: 40
    }
});

class ListEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: {},
            open: false,
            message: '',
            isLoading: false,
            viewingEvent: {},
            eventEnrollments: [],
            eventType: 'upcoming',
            paidEventFound: false,
            freeEventFound: false,
            newEventModalOpen: false,
            viewingEventEnrollments: [],
            isEnrollmentModalOpen: false,
            events: { 'upcoming': [], 'past': [] },
            renderedEvents: { 'upcoming': [], 'past': [] },
        };
    }

    componentDidMount = async () => {
        const today = moment().startOf("day").format("YYYY-MM-DDThh:mm")

        this.setState({
            isLoading: true,
            loadingMessage: 'hang tight while we grab your events...',
        })

        const events = await this.getEvents();
        const upcomingEvents = _.filter(events, event => event.startDate > today);
        const pastEvents = _.filter(events, event => event.startDate < today);
        let eventEnrollments = [];

        await Promise.all(events.map(async event => {
            const enrollments = await this.getEventEnrollments(event);
            eventEnrollments = _.concat(eventEnrollments, enrollments);
        }))

        this.setState({
            isLoading: false,
            eventEnrollments: eventEnrollments,
            events: { 'upcoming': upcomingEvents, 'past': pastEvents },
            renderedEvents: { 'upcoming': upcomingEvents, 'past': pastEvents },
        });
    }

    // APIS
    getUser(userId){
        return API.get("users", `/users/${userId}`);
    }

    getEvents(){
        const { currentTeam = {} } = this.props
        return API.get("events", `/programs/${currentTeam.id}/events`);
    }

    getEventEnrollments(event){
        return API.get("events", `/events/${event.id}/enrollments`);
    }

    handleDelete = async event => {
        const today = moment().startOf("day").format("YYYY-MM-DDThh:mm")
        const deletedEvent = await this.deleteEvent(event);
        const events = await this.getEvents();
        const upcomingEvents = _.filter(events, event => event.startDate > today);
        const pastEvents = _.filter(events, event => event.startDate < today);
        this.setState({
            events: { 'upcoming': upcomingEvents, 'past': pastEvents },
            renderedEvents: { 'upcoming': upcomingEvents, 'past': pastEvents },
        })
    }

    deleteEvent = event => {
        return API.del("events", `/events/${event.id}`)
    }

    updateEvent = event => {
        return API.put("events", `/events/${event.id}`, {
            body: event
        });
    }

    createEvent = event => {
        const { currentTeam } = this.props;
        return API.post("events", `/programs/${currentTeam.id}/events`, {
            body: event
        });
    }

    getEventByCode(code) {
        return API.get("events", `/events/code/${code}`);
    }

    handleOpen = event => {
        if(!event) {
            event = {};
            event.inviteCode = translator.new().substring(1, 6);
            event.zeroCostInviteCode = translator.new().substring(1, 6);
        }
        this.setState({ newEventModalOpen: true, event: event });
    };

    handleCloseNewEvent = () => {
        this.setState({ newEventModalOpen: false });
    }

    handleSubmit = async() => {
        const { currentTeam } = this.props;
        const { event, events } = this.state;

        const paidEvent = await this.getEventByCode(event.inviteCode)
        if(paidEvent) return this.setState({ paidEventFound: true, message: 'please choose a different invite code' })
        const freeEvent = await this.getEventByCode(event.zeroCostInviteCode)
        if(freeEvent) return this.setState({ freeEventFound: true, message: 'please choose a different, free invite code' })

        let startDate = moment().startOf("day").format("YYYY-MM-DDThh:mm"),
            endDate = moment().startOf("day").format("YYYY-MM-DDThh:mm");

        const eventJSON = {
            parentId: currentTeam.id,
            name: event.name ? event.name : null,
            description: event.description ? event.description :  null,
            level: event.level ? event.level : null,
            location: event.location ? event.location : null,
            startDate: event.startDate ? event.startDate : startDate,
            endDate: event.endDate ? event.endDate : endDate,
            disclaimer: event.disclaimer ? event.disclaimer : null,
            inviteCode: event.inviteCode ? event.inviteCode : translator.new().substring(1, 6),
            zeroCostInviteCode: event.zeroCostInviteCode ? event.zeroCostInviteCode : translator.new().substring(1, 6),
            collectShirtSize: event.collectShirtSize ? event.collectShirtSize : 'false',
            id: event.id ? event.id : null
        }

        if(event.id) {
            const newEvent = await this.updateEvent(eventJSON);
            this.setState({ event: {}, newEventModalOpen: false });
        }
        else {
            const newEvent = await this.createEvent(eventJSON);
            let newEvents = events;
            if(newEvent.startDate > startDate){
                newEvents['upcoming'].push(newEvent)
            }
            else {
                newEvents['past'].push(newEvent)
            }
            this.setState({ event: {}, events: newEvents, renderedEvents: newEvents, newEventModalOpen: false });
        }
    }

    handleEventChange = name => e => {
        const { event } = this.state;
        console.log(event)
        event[name] = e.target.value;
        console.log(name)
        this.setState({ event: event });
    };

    showEnrollments = async (enrollments, event) => {
        let users = [];
        await Promise.all(enrollments.map(async e => {
            const enrolledUser = await this.getUser(e.userId);
            users.push(_.extend(e, {user:enrolledUser}));
        }))
        this.setState({
            viewingEvent: event,
            isEnrollmentModalOpen: true,
            viewingEventEnrollments: users
        })
    }

    toggleEnrollmentModal = () => {
        this.setState({
            isEnrollmentModalOpen: !this.state.isEnrollmentModalOpen
         })
    }

    toggleEventType = () => {
        const { eventType } = this.state;
        this.setState({
            eventType: eventType == 'upcoming' ? 'past' : 'upcoming'
        })
    }

    searchEvents = event => {
        const { events } = this.state;

        if(event.target.value){
            this.setState({
                renderedEvents: {
                    'upcoming': _.filter(events['upcoming'], e => e.name.toLowerCase().includes(event.target.value.toLowerCase())),
                    'past': _.filter(events['past'], e => e.name.toLowerCase().includes(event.target.value.toLowerCase()))
                }
            })
        }
        else {
            this.setState({
                renderedEvents: {
                    'upcoming': events['upcoming'],
                    'past': events['past']
                }
            })
        }
    }

    render() {
        const { classes, appContext, currentTeam } = this.props;
        const {
            event,
            events,
            message,
            eventType,
            open = false,
            freeEventFound,
            paidEventFound,
            isLoading = false,
            newEventModalOpen,
            viewingEvent = {},
            loadingMessage = "",
            renderedEvents = [],
            eventEnrollments = [],
            viewingEventEnrollments = [],
            isEnrollmentModalOpen = false,
        } = this.state;

        return (
            <PageWrapper title={`Camps & Events`} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
            <div className={"eventsOuterContainer"}>
                <div>
                    <div className={"pageTitleContainer"}>
                    <h1 className={"pageTitle"}></h1>
                        <TextField
                            id="search"
                            className={"searchTextField"}
                            onChange={this.searchEvents}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className={"titleButtonContainer"}>
                            <Button
                                aria-label="add"
                                title="Toggle Events"
                                className={classes.textButton}
                                style={{ marginRight: 10 }}
                                onClick={() => this.toggleEventType()}>
                                View {eventType == 'upcoming' ? "Past" : "Upcoming"}
                            </Button>
                            {!appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditEvents') ?
                            <Button
                                mini
                                title="Add Schedules"
                                variant="fab"
                                aria-label="add"
                                className={classes.plusButton}
                                onClick={() => this.handleOpen()}>
                                <AddIcon className={"icon"} />
                            </Button>
                            :null}
                        </div>
                    </div>
                    <Grid
                        container
                        spacing={24}
                        className={"selectContainer"}
                    >
                    {renderedEvents[eventType].length ? renderedEvents[eventType].map(event => (
                        <Grid item sm={12} md={6} lg={4} key={event.id}>
                            <EventCard
                                event={event}
                                classes={classes}
                                appContext={appContext}
                                currentTeam={currentTeam}
                                delete={this.handleDelete}
                                edit={() => this.handleOpen(event)}
                                showEnrollments={(enrollments) => this.showEnrollments(enrollments, event)}
                                eventEnrollments={_.filter(eventEnrollments, e => e.eventId == event.id)} />
                        </Grid>
                    ))
                    :
                        <p className={"emptyListText"}>Hey coach, add some events...</p>
                    }
                    </Grid>
                    <NewEventModal
                        event={event}
                        message={message}
                        open={newEventModalOpen}
                        paidEventFound={paidEventFound}
                        freeEventFound={freeEventFound}
                        close={this.handleCloseNewEvent}
                        handleSubmit={() => this.handleSubmit()}
                        handleChange={name => this.handleEventChange(name)}
                    />
                    <EnrollmentsModal
                        event={viewingEvent}
                        open={isEnrollmentModalOpen}
                        close={() => this.toggleEnrollmentModal()}
                        enrollments={viewingEventEnrollments}
                    />
                </div>
            </div>
            </PageWrapper>
        );
    }
}

class EventCard extends Component {
    state = { selected: false };

    handleDeleteClick() {
        this.props.delete(this.props.event);
    }

    handleEditClick = () => {
        this.props.edit(this.props.event);
    }

    handleClick = () => {
        this.setState(state => ({ selected: !state.selected }));
    };

    handleShowEnrollments = () => {
        const { eventEnrollments } = this.props;
        this.props.showEnrollments(eventEnrollments)
    }

    render() {
        const { selected } = this.state;
        const { eventEnrollments, classes, appContext, currentTeam } = this.props;
        const renderedStartDate = this.props.event.startDate ? moment(this.props.event.startDate).format("l") : null
        const renderedEndDate = this.props.event.endDate ? moment(this.props.event.endDate).format("l") : null
        return (
            <div>
                <Card className={"eventCard"}>
                    <div className={"details"}>
                        <div className={"titleDateContainer"}>
                            <div className={"nameLevelContainer"}>
                                <Typography className={"title"} variant="subheading">
                                    {this.props.event.name ? this.props.event.name : "event name"}
                                </Typography>
                                <Typography className={"styledText"}>
                                    {this.props.event.level ? this.props.event.level : "event level"}
                                </Typography>
                                <Hidden mdUp>
                                    <Typography className={classNames("styledText", "smallText")}>
                                        {`${renderedStartDate} - ${renderedEndDate}`}
                                    </Typography>
                                </Hidden>
                            </div>
                            {renderedStartDate ?
                            <Hidden smDown>
                                <Typography className={classNames("styledText", "smallText")}>
                                    {`${renderedStartDate} - ${renderedEndDate}`}
                                </Typography>
                            </Hidden>
                            : null}
                        </div>
                        <div className={"inviteCodeContainer"}>
                            <p className={classNames("styledText", "smallText")}>Invite Code: {this.props.event.inviteCode}</p>
                            <p className={classNames("styledText", "smallText")}>Free Code: {this.props.event.zeroCostInviteCode}</p>
                        </div>
                    </div>
                    <div className={"iconContainer"}>
                        {eventEnrollments.length ? <Button
                            mini
                            className={classes.textButton}
                            style={{ marginLeft: 10 }}
                            onClick={eventEnrollments.length ? this.handleShowEnrollments : null}
                        >
                            View {eventEnrollments.length ? eventEnrollments.length : "0"} Participants
                        </Button>
                        : <div />}
                        {!appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditActivities') ?
                        <div>
                            <IconButton
                                title="Delete Event"
                                onClick={() => { if(window.confirm('Are you sure you wish to delete this event?')) this.handleDeleteClick() }}
                                aria-label="delete"
                                variant="contained"
                                className={"scheduleEdit"}
                            >
                                <DeleteIcon className={"styledIcon"} />
                            </IconButton>
                            <IconButton
                                title="Edit Event"
                                onClick={() => this.handleEditClick()}
                                aria-label="edit"
                                variant="contained"
                                className={"scheduleEdit"}
                            >
                                <EditIcon className={"styledIcon"} />
                            </IconButton>
                        </div>
                        : null}
                    </div>
                </Card>
            </div>
        )
    }
}
class EventModal extends Component {

    handleDeleteClick() {
        this.props.delete(this.props.event);
    }

    handleEditClick() {
        this.props.edit();
    }

    render() {
        const { event, open } = this.props;

        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"newEventPaper"}>
                        <div className={"modalHeaderContainer"}>
                            <h2 className={"modalHeaderText"}>{event.title || "New Event"}</h2>
                        </div>
                        <Typography variant="subheading" id="simple-modal-description">
                            {event.description}
                        </Typography>
                        <div className={"imageContainer"}>
                            <img className={"image"} src={logo} alt="logo"  />
                        </div>
                        <div className={"modalFooterContainer"}>
                            <IconButton
                                onClick={() => { if(window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteClick() }}
                                aria-label="Delete"
                                className={"deleteIcon"}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => this.handleEditClick()}
                                aria-label="Edit"
                                className={"editIcon"}
                            >
                                <EditIcon />
                            </IconButton>
                        </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

class EnrollmentsModal extends Component {
    render() {
        const { open, event, enrollments = [] } = this.props;

        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"eventEnrollmentPaper"}
                >
                    <div className={"modalHeaderContainer"}>
                        <h2 className={"modalHeaderText"}>{event.name ? `${event.name}: Enrollments` : "Event Enrollments"}</h2>
                    </div>
                    <div className={"enrollmentCategoryContainer"}>
                        <div className={"enrollmentColumn"}>
                            <p>Name</p>
                        </div>
                        <div className={"enrollmentColumn"}>
                            <p>Amount Paid</p>
                        </div>
                        <div className={"enrollmentColumn"}>
                            <p>Shirt Size</p>
                        </div>
                    </div>
                    <div className={"enrollmentsContainer"}>
                        {enrollments.map(enrollment => {
                            return (
                                <div
                                    key={enrollment.user.id}
                                    className={"enrollmentContainer"}
                                >
                                    <div className={"enrollmentColumn"}>
                                        <p>{enrollment.user.nameFirst} {enrollment.user.nameLast}</p>
                                    </div>
                                    <div className={"enrollmentColumn"}>
                                        <p>{enrollment.amountPaid ? `$${enrollment.amountPaid}` : "$0"}</p>
                                    </div>
                                    <div className={"enrollmentColumn"}>
                                        <p>{enrollment.shirtSize}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={"modalFooterContainer"}>
                        <Button
                            type="submit"
                            className={"cancelButton"}
                            onClick={this.props.close}
                        >
                            Cancel
                        </Button>
                    </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

ListEvents.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ListEvents));
