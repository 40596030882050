import { userClient } from '../clients';

export const USERS_GET_BY_ID_LOADING = 'USERS_GET_BY_ID_LOADING';
export const USERS_GET_BY_ID_SUCCESS = 'USERS_GET_BY_ID_SUCCESS';
export const USERS_GET_BY_ID_ERROR = 'USERS_GET_BY_ID_ERROR';

export const USERS_SET_AUTHENTICATED_BY_ID_LOADING = 'USERS_SET_AUTHENTICATED_BY_ID_LOADING';
export const USERS_SET_AUTHENTICATED_BY_ID_SUCCESS = 'USERS_SET_AUTHENTICATED_BY_ID_SUCCESS';
export const USERS_SET_AUTHENTICATED_BY_ID_ERROR = 'USERS_SET_AUTHENTICATED_BY_ID_ERROR';

export const setAuthenticatedUserById = (id) => (async (dispatch) => {
    dispatch({ type: USERS_SET_AUTHENTICATED_BY_ID_LOADING });
    try {
        const { data: user } = await userClient.getUserById(id, 'maxone');
         dispatch({ type: USERS_GET_BY_ID_SUCCESS, data: { user } });
         dispatch({ type: USERS_SET_AUTHENTICATED_BY_ID_SUCCESS, data: { id } });
    } catch (e) {
        console.log(`Error getting user by ID ${id}`, e);
        dispatch({ type: USERS_SET_AUTHENTICATED_BY_ID_ERROR });
    }
});