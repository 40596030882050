import { tagsClient } from '../clients';

export const TAGS_GET_AVAILABLE_BY_TEAM_ID_LOADING = 'TAGS_GET_AVAILABLE_BY_TEAM_ID_LOADING';
export const TAGS_GET_AVAILABLE_BY_TEAM_ID_SUCCESS = 'TAGS_GET_AVAILABLE_BY_TEAM_ID_SUCCESS';
export const TAGS_GET_AVAILABLE_BY_TEAM_ID_ERROR = 'TAGS_GET_AVAILABLE_BY_TEAM_ID_ERROR';

export const TAGS_GET_AVAILABLE_BY_ENTITY_ID_LOADING = 'TAGS_GET_AVAILABLE_BY_ENTITY_ID_LOADING';
export const TAGS_GET_AVAILABLE_BY_ENTITY_ID_SUCCESS = 'TAGS_GET_AVAILABLE_BY_ENTITY_ID_SUCCESS';
export const TAGS_GET_AVAILABLE_BY_ENTITY_ID_ERROR = 'TAGS_GET_AVAILABLE_BY_ENTITY_ID_ERROR';

export const ADD_AVAILABLE_TAG_LOADING = 'ADD_AVAILABLE_TAG_LOADING';
export const ADD_AVAILABLE_TAG_SUCCESS = 'ADD_AVAILABLE_TAG_SUCCESS';
export const ADD_AVAILABLE_TAG_ERROR = 'ADD_AVAILABLE_TAG_ERROR';

export const ADD_TAGS_LOADING = 'ADD_TAGS_LOADING';
export const ADD_TAGS_SUCCESS = 'ADD_TAGS_SUCCESS';
export const ADD_TAGS_ERROR = 'ADD_TAGS_ERROR';

export const getAvailableTagsByTeamId = (teamId) => (async (dispatch) => {
    try {
        dispatch({ type: TAGS_GET_AVAILABLE_BY_TEAM_ID_LOADING });
        const { data } = await tagsClient.getAvailableTagsByTeamId(teamId);
        dispatch({ type: TAGS_GET_AVAILABLE_BY_TEAM_ID_SUCCESS, data });
    } catch (e) {
        console.warn(`Error getting available tags by team ID ${teamId}`, e);
        dispatch({ type: TAGS_GET_AVAILABLE_BY_TEAM_ID_ERROR });
    }
});

export const getTagsByEntityId = (teamId, entityType, entityId) => (async (dispatch) => {
    try {
        dispatch({ type: TAGS_GET_AVAILABLE_BY_ENTITY_ID_LOADING });
        const { data } = await tagsClient.getTagsByEntityId(teamId, entityType, entityId);
        dispatch({ type: TAGS_GET_AVAILABLE_BY_ENTITY_ID_SUCCESS, data });
    } catch (e) {
        console.warn(`Error getting available tags by ENTITY ID ${entityId}`, e);
        dispatch({ type: TAGS_GET_AVAILABLE_BY_ENTITY_ID_ERROR });
    }
});

export const addAvailableTag = (teamId, label) => (async (dispatch) => {
    try {
        dispatch({ type: ADD_AVAILABLE_TAG_LOADING });
        const { data } = await tagsClient.addAvailableTag(teamId, label);
        dispatch({ type: ADD_AVAILABLE_TAG_SUCCESS, data });
    } catch (e) {
        console.warn(`Error adding available tag by team ID ${teamId}`, e);
        dispatch({ type: ADD_AVAILABLE_TAG_ERROR });
    }
});

export const addTags = (teamId, entityType, entityId, tagIds) => (async (dispatch) => {
    try {
        dispatch({ type: ADD_TAGS_LOADING });
        const { data } = await tagsClient.addTags(teamId, entityType, entityId, tagIds);
        dispatch({ type: ADD_TAGS_SUCCESS, data });
    } catch (e) {
        console.warn(`Error adding tags by entity ID ${entityId}`, e);
        dispatch({ type: ADD_TAGS_ERROR });
    }
});

export const addNewTag = async (teamId, label) => {
    try {
        const { data } = await tagsClient.addAvailableTag(teamId, label)
        return data
    } catch (e) {
        return {}
    }
}
