import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import { API, Storage } from "aws-amplify";

import { withStyles } from '@material-ui/core/styles';

import _ from "lodash"
import logo from "../../assets/images/logo-dark.png"
import PageWrapper from '../nav/pageWrapper';
import "./groups.css";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: "100%",
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginLeft:35,
    float:"left"
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom:'-35px'
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class GroupsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      value:0,
      groups:[],
      groupName:"",
      players:[],
      coaches:[],
      programId:"",
      coachChecked:[],
      playerChecked:[],
      value:0
    };
  }

  handleChange = name => event => {
    event.preventDefault()
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handlePlayerToggle = value => () => {
    const { playerChecked } = this.state;
    const currentIndex = playerChecked.indexOf(value);
    const newChecked = [...playerChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      playerChecked: newChecked,
    });
  };

  handleCoachToggle = value => () => {
    const { coachChecked } = this.state;
    const currentIndex = coachChecked.indexOf(value);
    const newChecked = [...coachChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      coachChecked: newChecked,
    });
  };

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }


  async componentDidMount(){


    var programId = this.props.currentTeam.id;
    this.setState({programId});

    console.log('programId => ', programId);

    try{
      var players = await this.getPlayers(programId)
      players = _.filter(players, player => player && player.id)
      var coaches = await this.getCoaches(programId)
      coaches = _.filter(coaches, coach => coach && coach.id)
      var group = await this.getGroup(this.props.match.params.id)
      var coachChecked = []
      var playerChecked = []

      await this.asyncForEach(players, async(player) => {
        if(player.avatarUrl && !player.avatarUrl.includes("https://")){
          var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${player.legacyId ? player.legacyId : player.id}/${player.avatarUrl}`
          player.avatarUrl = logo;
        }
      })
      await this.asyncForEach(coaches, async(coach) => {
        if(coach.avatarUrl && !coach.avatarUrl.includes("https://")){
          var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${coach.legacyId ? coach.legacyId : coach.id}/${coach.avatarUrl}`
          coach.avatarUrl = logo;
        }
      })

      for(var i=0; i< group.participants.length; i++){
        var user = group.participants[i];
        var findPlayer = _.find(players, p => p.id === user.userId);
        var findCoach = _.find(coaches, p => p.id === user.userId);
        if(findCoach){
          var elementPos = coaches.map(function(x) {return x.id; }).indexOf(user.userId);
          coachChecked.push(elementPos)
        }
        else if(findPlayer){
          var elementPos = players.map(function(x) {return x.id; }).indexOf(user.userId);
          playerChecked.push(elementPos)
        }
        else if( !findPlayer && !findCoach){
          console.log('User not found to be part of program: ', user)
          players.push(user)
          var elementPos = players.map(function(x) {return x.id; }).indexOf(user.id);
          playerChecked.push(elementPos)
        }
      }
      this.setState({players: players || [], coaches: coaches || [], group, coachChecked, playerChecked, groupName: group.name})
    }
    catch(e){
      console.log('e ', e)
    }
  }

  getGroups(programId) {
    return API.get("groups", `/programs/${programId}/groups`);
  }

  getGroup(groupId) {
    return API.get("groups", `/groups/${groupId}`);
  }

  getPlayers(programId) {
    return API.get("programs", `/programs/${programId}/players`);
  }

  getCoaches(programId) {
    return API.get("programs", `/programs/${programId}/coaches`);
  }

  createGroup(group) {
    group.id = this.props.match.params.id
    return API.post("groups", `/programs/${this.state.programId}/groups`,{
      body: group
    });
  }

  async handleCreateGroup(){
    var selectedPlayers = []
    var selectedCoaches = []

    if(this.state.coachChecked === 0 && this.state.playerChecked === 0){
      console.log('Please select at least one player or coach.')
      this.setState({errorMessage: "Please select at least one player or coach."})
    }
    else if(!this.state.groupName){
      console.log('Group must have a name')
      this.setState({errorMessage: "Group must have a name"})
    }
    else{
      for(var i=0; i < this.state.coachChecked.length; i++){
        var coach = this.state.coaches[this.state.coachChecked[i]]
        coach.role="coach"
        selectedCoaches.push(coach)
      }
      for(var i=0; i < this.state.playerChecked.length; i++){
        var player = this.state.players[this.state.playerChecked[i]]
        player.role="player"
        selectedPlayers.push(player)
      }

      var participants = selectedCoaches.concat(selectedPlayers)
      var group = {
        "name": this.state.groupName,
        "participants":participants,
        "programId": this.state.programId
      }
      var newGroup = await this.createGroup(group)

      this.props.history.push('/groups')
    }
  }

  render() {
    const { classes, theme } = this.props;
    const { groups, value } = this.state;

    return (
      <PageWrapper title="Edit Group" {...this.props}>
      <div className="groupsOuterContainer">
        <div className={"pageTitleContainer"} >
            <h1 className={"pageTitle"}>Edit Group</h1>
            <div className={"commandButtonContainer"}>
              <Button size="small" variant="contained" className={classes.button}
                onClick={() => this.props.history.push('/groups/')}>
                Cancel
              </Button>
              <Button size="small" variant="contained" className={classes.button}
                color="primary"
                onClick={() => alert('Make Group Edit Great Again!')}>
                Save
              </Button>
            </div>
          </div>

        <Grid container spacing={24} className={"selectContainer"}>
          <Grid item xs={12}>
            <TextField
              id="groupName"
              label="Group Name"
              className={classes.textField}
              value={this.state.groupName}
              onChange={this.handleChange("groupName")}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={this.state.value}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab style={{width:"100%"}} label="Athletes" />
                <Tab style={{width:"100%"}} label="Coaches" />
              </Tabs>
            </Paper>
            <Paper className={classes.Paper}>
            {
              value === 0 && <TabContainer dir="">
              <List subheader={<ListSubheader style={{textAlign:'left'}}>Name</ListSubheader>}>
                <Divider />
                  {
                  this.state.players.map((player, i) =>
                    <ListItem
                      key={i}
                      role={undefined}
                      dense
                      button
                      onClick={this.handlePlayerToggle(i)}
                      className={classes.listItem}
                    >
                      <Checkbox
                        checked={this.state.playerChecked.indexOf(i) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                      {
                        player.avatarUrl && player.avatarUrl.includes('https://') ? <Avatar alt={player.nameFirst} src={player.avatarUrl} className={classes.userImage}/> :
                        <Avatar className={classes.userImage}><PersonOutlineIcon /></Avatar>
                      }
                      <ListItemText primary={ `${player.nameFirst} ${player.nameLast}`} />
                    </ListItem>
                    )
                  }
              </List>
              </TabContainer>
            }
            {
              value === 1 && <TabContainer dir="">
              <List subheader={<ListSubheader style={{textAlign:'left'}}>Name</ListSubheader>}>
                <Divider />
                {
                this.state.coaches.map((coach, i) =>
                  <ListItem
                    key={i}
                    role={undefined}
                    dense
                    button
                    onClick={this.handleCoachToggle(i)}
                    className={classes.listItem}
                  >
                    <Checkbox
                      checked={this.state.coachChecked.indexOf(i) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                    {
                      coach.avatarUrl ? <Avatar alt={coach.nameFirst} src={coach.avatarUrl} className={classes.userImage}/> :
                      <Avatar className={classes.userImage}><PersonOutlineIcon /></Avatar>
                    }
                    <ListItemText primary={ `${coach.nameFirst} ${coach.nameLast}`} />
                  </ListItem>
                  )
                }
              </List>
              </TabContainer>
            }
            </Paper>
          </Grid>
        </Grid>
      </div>
      </PageWrapper>
    );
  }
}

GroupsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    currentTeam: currentTeam||{}
  };
}

export default withStyles(styles)(connect(mapStateToProps)(GroupsEdit));
