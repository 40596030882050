import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let SwimIcon = (props) => (
  <SvgIcon width="20" height="13" viewBox="0 0 20 13" {...props}>
  	<path d="M6.25783 2.79314C6.25783 4.33525 4.93321 5.58628 3.30039 5.58628C1.66757 5.58628 0.342948 4.33525 0.342948 2.79314C0.342948 1.25103 1.66757 0 3.30039 0C4.93525 0 6.25783 1.25103 6.25783 2.79314Z" />
<path d="M18.857 8.91718C18.4101 9.10416 17.7855 9.32198 17.012 9.48197C14.8199 9.93497 12.5401 9.72485 10.2378 8.85742C8.83358 8.32925 7.39874 8.01119 5.97615 7.91095C5.60264 7.88397 5.23118 7.87433 4.85971 7.87818L7.47834 3.58342C7.52528 3.50631 7.58243 3.43885 7.64775 3.38102C7.67428 3.35018 7.70489 3.32126 7.73959 3.29427L11.7114 0.235123C11.889 0.0982611 12.1298 0.063564 12.3421 0.142597L18.4101 2.41142C18.7346 2.53286 18.8917 2.87791 18.7632 3.1844C18.6366 3.48318 18.2835 3.63354 17.9651 3.52559C17.8814 3.5333 17.7957 3.52752 17.71 3.50246L12.7054 2.02397L10.346 4.1193L18.4876 8.549C18.6529 8.6396 18.7774 8.76875 18.857 8.91718Z" />
<path d="M3.00237 9.17168C1.28995 9.46854 0.334754 10.07 0.295974 10.0969C0.116364 10.203 -0.00201504 10.3899 2.59813e-05 10.602C2.59813e-05 10.9316 0.283729 11.1976 0.630702 11.1976C0.755205 11.1976 0.869502 11.1629 0.967471 11.1051C1.13483 11.0357 1.93083 10.7254 3.25342 10.6213C4.63315 10.5114 6.88439 10.6097 9.77448 11.6969C11.1828 12.227 12.5809 12.5277 13.9259 12.5875C14.0953 12.5952 14.2668 12.599 14.4362 12.599C15.3424 12.599 16.2221 12.4891 17.0609 12.2713C18.6121 11.8684 19.5387 11.215 19.7306 11.0723C19.8939 10.9644 20 10.787 20 10.5846C20 10.255 19.7163 9.989 19.3693 9.989C19.2346 9.989 19.1081 10.0295 19.006 10.0969L19.0081 10.095C19.0081 10.095 19.0063 10.096 19.0037 10.0975C18.9378 10.1364 18.1281 10.613 16.7058 10.8468C15.3383 11.0723 13.1075 11.1147 10.2378 10.0353C8.83765 9.50902 7.44976 9.1794 6.11493 9.05988C5.03727 8.9635 3.99022 9.00013 3.00237 9.17168Z" />
  </SvgIcon>
);

export default SwimIcon;