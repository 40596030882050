import React from "react";
import { connect } from "react-redux";
import AddParticipantsComponent from "../components/AddParticipants";
import { searchMembersByTeamId } from "../../../actions/team-actions";
import { userRoleTypes } from "../../../models/enums";

const allRoutes = [
  { key: "all", title: "All" },
  { key: "athletes", title: "Athletes" },
  { key: "parents", title: "Parents" },
  { key: "coaches", title: "Coaches" },
];

class AddParticipants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      searchValue: "",
      participantsById: {},
      all: { members: false, athletes: false, parents: false, coaches: false },
      groupsById: {},
    };
  }

  componentDidMount = () => {
    let {
      selectedTeam: { id, participants },
      membersById: { data: members },
      groupsByTeamId,
    } = this.props;
    members = members[id];
    const groups = groupsByTeamId.data[id];
    if ((participants || {}).participants) {
      ((participants || {}).participants || []).forEach((item) => {
        if (["ALL", "ATHLETES", "PARENTS", "COACHES"].indexOf(item) > -1) {
          this.handleSelect(item, true);
        } else if (item.includes("GROUP")) {
          const groupId = item.split("$_$")[1];
          const group = groups.find((item) => item.id === groupId);
          this.handleSelectGroups(group);
        } else {
          const user = members.results[item];
          if (user) {
            this.handleSelectParticipants(user);
          }
        }
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === "searchValue") {
        this.loadData();
      }
    });
  };

  loadData = () => {
    const { searchMembersByTeamId, selectedTeam } = this.props;
    const { searchValue } = this.state;
    searchMembersByTeamId(selectedTeam.id, searchValue, 0, 1000);
  };

  handleSelect = (key, selected) => {
    const { all, participantsById } = this.state;
    let {
      selectedTeam,
      membersByRole: { data: members },
    } = this.props;
    members = members[selectedTeam.id];
    if (key === "ALL") {
      Object.values(members.results).forEach((users) => {
        users
          .filter((item) => item.firstName && item.lastName)
          .forEach((item) => {
            if (!participantsById[item.id]) {
              if (selected) {
                participantsById[item.id] = item;
              }
            } else {
              if (!selected) {
                delete participantsById[item.id];
              }
            }
          });
      });
      Object.keys(all).forEach((item) => {
        all[item] = selected;
      });
    } else {
      members.results[
        key === "ATHLETES"
          ? userRoleTypes.ATHLETE.toLowerCase()
          : key === "PARENTS"
          ? userRoleTypes.PARENT.toLowerCase()
          : userRoleTypes.COACH.toLowerCase()
      ]
        .filter((item) => item.firstName && item.lastName)
        .forEach((item) => {
          if (!participantsById[item.id]) {
            if (selected) {
              participantsById[item.id] = item;
            }
          } else {
            if (!selected) {
              delete participantsById[item.id];
            }
          }
        });
      if (key === "ATHLETES") {
        all.athletes = selected;
      }
      if (key === "PARENTS") {
        all.parents = selected;
      }
      if (key === "COACHES") {
        all.coaches = selected;
      }
      all["members"] = all.athletes && all.coaches && all.parents;
    }
    this.setState({ all, participantsById });
  };

  handleSelectParticipants = (participant) => {
    const { all, participantsById } = this.state;
    let {
      selectedTeam,
      membersByRole: { data: members },
    } = this.props;
    members = members[selectedTeam.id];
    if (!participantsById[participant.id]) {
      participantsById[participant.id] = participant;
      if (
        Object.values(participantsById).filter(
          (item) => item.role === participant.role
        ).length ===
        members.results[
          participant.role === userRoleTypes.HEAD_COACH.toLowerCase()
            ? userRoleTypes.COACH.toLowerCase()
            : (participant.role || "").toLowerCase()
        ].length
      ) {
        all[
          participant.role === userRoleTypes.ATHLETE
            ? "athletes"
            : participant.role === userRoleTypes.PARENT
            ? "parents"
            : "coaches"
        ] = true;
        all["members"] = all.athletes && all.coaches && all.parents;
      }
    } else {
      all["members"] = false;
      all[
        participant.role === userRoleTypes.ATHLETE
          ? "athletes"
          : participant.role === userRoleTypes.PARENT
          ? "parents"
          : "coaches"
      ] = false;
      delete participantsById[participant.id];
    }
    this.setState({
      participantsById: { ...participantsById },
      all: { ...all },
    });
  };

  handleSelectGroups = (group) => {
    const { selectedTeam } = this.props;
    const { groupsById } = this.state;
    if (!groupsById[group.id]) {
      groupsById[group.id] = group;
      groupsById[group.id].participantsById = (group.participants || []).reduce(
        (obj, item) => {
          obj[item.id] = {
            avatarUrl: item.avatarUrl,
            firstName: item.nameFirst || item.firstName,
            id: item.id,
            lastName: item.nameLast || item.lastName,
            role: item.secondaryText
              ? item.secondaryText.toUpperCase()
              : (
                  ((item.teams || {}).results || []).find(
                    (element) => element.id === selectedTeam.id
                  ) || {}
                ).role,
          };
          return obj;
        },
        {}
      );
    } else {
      delete groupsById[group.id];
    }
    this.setState({ groupsById });
  };

  handleDone = () => {
    let { all, participantsById, groupsById } = this.state;
    const { handleDone } = this.props;
    let participants = [];
    if (all.members) {
      participants.push(`ALL`);
    } else {
      if (all.athletes) {
        participants.push(`ATHLETES`);
      } else {
        participants = participants.concat(
          Object.values(participantsById)
            .filter((item) => item.role === userRoleTypes.ATHLETE)
            .map((item) => item.id)
        );
      }
      if (all.parents) {
        participants.push(`PARENTS`);
      } else {
        participants = participants.concat(
          Object.values(participantsById)
            .filter((item) => item.role === userRoleTypes.PARENT)
            .map((item) => item.id)
        );
      }
      if (all.coaches) {
        participants.push(`COACHES`);
      } else {
        participants = participants.concat(
          Object.values(participantsById)
            .filter(
              (item) =>
                [userRoleTypes.HEAD_COACH, userRoleTypes.COACH].indexOf(
                  item.role
                ) > -1
            )
            .map((item) => item.id)
        );
      }
    }
    Object.keys(groupsById).forEach((item) => {
      participants.push(`GROUP$_$${item}`);
    });
    handleDone({ participants, participantsById, groupsById });
  };

  render() {
    const { index, searchValue, participantsById, all, groupsById } =
      this.state;
    const { selectedTeam, membersByRole, groupsByTeamId } = this.props;
    return (
      <>
        <AddParticipantsComponent
          all={all}
          index={index}
          routes={allRoutes}
          groupsById={groupsById}
          searchValue={searchValue}
          handleDone={this.handleDone}
          selectedTeam={selectedTeam}
          handleSelect={this.handleSelect}
          handleChange={this.handleChange}
          participantsById={participantsById}
          handleSelectGroups={this.handleSelectGroups}
          members={membersByRole.data[selectedTeam.id] || {}}
          groups={groupsByTeamId.data[selectedTeam.id] || []}
          handleSelectParticipants={this.handleSelectParticipants}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId },
  teams: { membersById, membersByRole },
  group: { groupsByTeamId },
}) => ({
  membersById,
  membersByRole,
  loggedInUserId,
  groupsByTeamId,
});

export default connect(mapStateToProps, {
  searchMembersByTeamId,
})(AddParticipants);
