import React from 'react';
import './index.scss';

export default function BaseModal({ open, modalContent, loading=false }) {

    const loadingMessage = <div className='loading-message'><h1>loading content</h1></div>

	return open ? (
		<div className='base-modal'>
			<div className='modal-content'>{loading ? loadingMessage : modalContent}</div>
		</div>
	) : null;
}
