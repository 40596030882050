import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import './index.scss';

const Component = ({ loading, error, errorMessage, children }) => loading ? (
    <div className={'m1-loading-page'}>
        <CircularProgress />
    </div>
) : error ? (
    <div className={'m1-error-page'}>
        {errorMessage}
    </div>
) : children;

Component.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    children: PropTypes.any
};

Component.defaultProps = {
    loading: false,
    error: false,
    errorMessage: 'There was an error loading this content. Please try again later',
    children: null
};

export default Component;