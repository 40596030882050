import React, { Component } from "react";
import { connect } from 'react-redux';
import ModalContainer from '../../components/modalContainer';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleButton from '../../components/toggleButton';
import FloatingInput from '../../components/floatingInput';
import ChipInput from '../../components/chipInput';
import { QUESTION_TYPES } from '../../constants';
import ThemeButton from '../../components/themeButton'
import EditableText from '../../components/editableText';
import FloatingSelect from '../../components/floatingSelect';
import { getQuestionInputInfo } from '../../utils/questionsUtils';
import {
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
const questionOptions = Object.keys(QUESTION_TYPES).map(key => { return { label: getQuestionInputInfo(key), value: key } })
let styles = {
    inputContainer: {
        marginBottom: "15px",
        "& .chipInputOuterContainer": {
            padding: "24px 15px"
        }
    },
    dropDownField: {
        width: "335px"
    },
    pointContainer: {
        marginLeft: "23px",
        maxWidth: "114px"
    },
    inputHead: {
        fontSize: "20px",
        lineHeight: "22px",
        marginBottom: "4px"
    },
    inputSubHead: {
        fontSize: "14px",
        lineHeight: "21px",
        marginRight: "8px"
    },
    rangeInput: {
        marginLeft: "10px",
        "& > div": {
            width: "120px",
            marginLeft: "10px"
        }
    },
    textArea: {
        minHeight: "70px"
    },
    optionInput: {
        border: "1px solid #DDE2E5",
        paddingLeft: "15px",
        borderRadius: "4px",
        width: "472px",
        height: "35px",
        marginBottom: "0px",
        "& input": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "150%"
        }
    },
    choices: {
        marginBottom: "13px",
    },
    chipContainer: {
        height: "147px"
    },
    multipleChoiceOptions: {
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    typeOption: {
        marginBottom: "0px"
    },
    checkboxRoot: {
        width: "29px"
    },
    saveAnotherCheckBox: {
        marginRight: "15px",
        marginBottom: "0px"
    },
    modalContent:{
        maxHeight:"calc(100vh - 215px)",
        overflow:"scroll"
    },
    textInputInfo:{
        marginLeft: "17px",
        fontSize: "14px",
        lineHeight: "150%"
    }
}

class AddQuestion extends Component {
    constructor() {
        super();
        this.state = {
            formData: {
                leaderboard: false,
                pointValue: 0
            },
            options: ["", ""],
            tags: new Set(),
            addAnother: false
        }
    }

    componentDidMount = () => {
        let { selectedQuestion } = this.props
        //Populate Form Data
        if (!!selectedQuestion) {
            this.setState({ formData: { ...selectedQuestion }, options: [...selectedQuestion.options], tags: new Set((selectedQuestion.tags||[]).map(tag => tag.label)) });
        }
    }

    handleChange = (key, value) => {
        let { formData, options } = this.state;
        this.setState({ formData: { ...formData, [key]: value }, options: key == 'type' && value == 'RANGE' ? [0, 0] : key == 'type' ? ["", ""]: options });
    }

    removeTag = (tag) => {
        let { tags } = this.state;
        let clonedTags = new Set(tags);
        clonedTags.delete(tag);
        this.setState({ tags: clonedTags })
    }

    addTag = (tag) => {
        let { tags } = this.state;
        let clonedTags = new Set(tags);
        clonedTags.add(tag);
        this.setState({ tags: clonedTags })
    }

    addOptions = () => {
        let { options } = this.state;
        this.setState({ options: options.concat([""]) });
    }

    updateOption = (value, index) => {
        let { options } = this.state;
        options = [...options];
        options[index] = value
        this.setState({ options });
    }

    saveData = () => {
        let { save } = this.props;
        let { formData, tags, options, addAnother } = this.state;
        // let clonedData = { ...formData, options: options.filter(i=>!!i) }; //@Amit, this was removing number 0 as an option for RANGE
        let clonedData = { ...formData, options: options };
        save(clonedData, Array.from(tags), addAnother)
    }

    deleteOption = (index) => {
        let { options } = this.state;
        let clonedOptions = [...options]; 
        if(index <2){
            clonedOptions[index] = ""
        }
        else {
            clonedOptions = options.filter((item, i) => i !== index)
        }
        this.setState({ options: clonedOptions })
    }

    toggleAddAnother = () => {
        let { addAnother } = this.state;
        this.setState({ addAnother: !addAnother });
    }

    render() {
        const { open, close, classes, currentTeam } = this.props
        const { formData: { title, description, type, pointValue, leaderboard, id }, tags, options, addAnother } = this.state;
        let isValid = !!title && !!type
        if ((type == QUESTION_TYPES.MULTIPLE_CHOICE || type == QUESTION_TYPES.SINGLE_CHOICE) && options.filter(i => !!i).length < 2) {
            isValid = false
        }
        return (
            <ModalContainer
                customPadding={"30px"}
                heading={`${id?"Update": "Create New"} Question`}
                open={open}
                width={"800px"}
                onClose={close}
                rightBtn={
                    <div className={classNames(classes.rightSection, "d-flex")}>
                        {!id &&
                            <FormControlLabel
                                className={classes.saveAnotherCheckBox}
                                value={addAnother.toString()}
                                control={<Checkbox color="primary" classes={{ root: classes.checkboxRoot }} />}
                                label="Create another after saving"
                                labelPlacement="end"
                                onChange={this.toggleAddAnother}
                            />}
                        <ThemeButton
                            disabled={!isValid}
                            width="199px"
                            textColor={"#ffffff"}
                            color={"#27AE60"}
                            height={"44px"}
                            position="left"
                            iconName=""
                            theme="small"
                            text={id ? "Update Question" : "Create New Question"}
                            onClick={this.saveData}

                        />
                    </div>
                }
                useDefaultLeftBtn={true}
                defaultLeftBtnProps={{
                    onClick: close,
                    text: "Discard",
                    iconName: ""
                }}
            >
                <div className={classes.modalContent}>
                    <div className={classes.inputContainer}>
                        <div className={classes.inputHead}>Question*</div>
                        <FloatingInput
                            type="text"
                            label="Add your Question here (128 characters max)"
                            value={title}
                            overrideContainerCss={classes.textArea}
                            multiline={true}
                            maxLength={128}
                            onChange={(e) => this.handleChange("title", e.target.value)}
                            removeBottomMargin={true}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <div className={classes.inputHead}>Description</div>
                        <FloatingInput
                            type="text"
                            label="Add your subtext here (256 character max)"
                            value={description}
                            overrideContainerCss={classes.textArea}
                            multiline={true}
                            maxLength={256}
                            onChange={(e) => this.handleChange("description", e.target.value)}
                            removeBottomMargin={true}
                        />
                    </div>
                    <div className={classNames(classes.questionTypeContainer, classes.inputContainer, "d-flex align-items-center")}>
                        <div className={classes.dropDownField}>
                            <FloatingSelect
                                disabled={!!id}
                                overrideContainerCss={classes.typeOption}
                                label="Type"
                                value={type}
                                onChange={(e) => this.handleChange("type", e.target.value)}
                            >
                                {questionOptions.map(option => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </FloatingSelect>
                        </div>
                        {type == QUESTION_TYPES.FREE_FORM && <div className={classNames(classes.textInputInfo, "d-flex")}>*500 character limit for text input</div>}
                        {type == QUESTION_TYPES.RANGE && <div className={classNames(classes.rangeInput, "d-flex")}>
                            <FloatingInput
                                type="number"
                                label="Min Value"
                                value={options[0] || 0}
                                enableNumberIncrementArrow={true}
                                onChange={(e) => this.updateOption(Number(e.target.value), 0)}
                                removeBottomMargin={true}
                            />
                            <FloatingInput
                                type="number"
                                label="Max Value"
                                value={options[1] || 0}
                                enableNumberIncrementArrow={true}
                                onChange={(e) => this.updateOption(Number(e.target.value), 1)}
                                removeBottomMargin={true}
                            />
                        </div>}
                    </div>
                    {(type == QUESTION_TYPES.SINGLE_CHOICE || type == QUESTION_TYPES.MULTIPLE_CHOICE) && <div className={classes.multipleChoiceOptions}>
                        {options.map((option, index) => {
                            return (<div className={classNames(classes.choices, "d-flex align-items-center")}>
                                <EditableText
                                    className={classes.optionInput}
                                    value={option}
                                    placeholder={`Enter option ${index + 1} here`}
                                    handleChange={event => this.updateOption(event.target.value, index)}
                                />
                                {<DeleteIcon onClick={() => this.deleteOption(index)} />}
                            </div>)
                        })}
                        {options.length < 8 && <ThemeButton
                            theme={"small"}
                            width={"140px"}
                            height={"35px"}
                            onClick={this.addOptions}
                            text="ADD OPTION"
                            color={"#1354F9"}
                            textColor={"#ffffff"}
                            iconName={"Plus"}
                            position="left"
                        />}
                    </div>}

                    {/* <div className={classNames(classes.inputContainer, "flex-row")}>
                        <div className={classNames(classes.leaderboardSection, "flex-row")}>
                            <div className={classes.inputSubHead}>Leaderboards</div>
                            <ToggleButton
                                buttonEnabledText="Enabled"
                                buttonDisabledText="Disabled"
                                value={leaderboard}
                                onChange={(e) => this.handleChange("leaderboard", e.target.value)} />
                        </div>
                        <div className={classes.pointContainer}>
                            <FloatingInput
                                type="number"
                                label="Point Value"
                                value={pointValue}
                                enableNumberIncrementArrow={true}
                                onChange={(e) => this.handleChange("pointValue", e.target.value)}
                                removeBottomMargin={true}
                            />
                        </div>
                    </div> */}
                    <div className={classes.inputContainer}>
                        <div className={classes.inputHead}>Add Tags</div>
                        <ChipInput
                            enableTopExstTagLayout={true}
                            currentTeam={currentTeam}
                            className={classes.chipContainer}
                            chips={Array.from(tags)}
                            removeChip={this.removeTag}
                            handleNewChip={this.addTag}
                            placeholder={'Type to add a tag, press enter or "," to start a new tag.'}
                        />
                    </div>
                </div>
            </ModalContainer>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = (userContext.appContextList || []).find(c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        currentUser: userContext.user
    };
}


export default withStyles(styles)(connect(mapStateToProps)(AddQuestion));
