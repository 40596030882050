import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';

import {
  Avatar,
  Button,
  Hidden,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';

import PersonOutlineIcon  from "@material-ui/icons/PersonOutline";
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import AssessmentIcon from '@material-ui/icons/Assessment';

import _ from "lodash";
import "./new.css";
import moment from "moment";
import { API, Auth, Storage } from "aws-amplify";
import logo from "../../assets/images/logo-dark.png"

import { setUserContext, setAppContext } from '../../actions/session';
import ContextService from '../../services/context';
import PageWrapper from '../nav/pageWrapper';
const contextService = new ContextService()

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: '100%',
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  root: {
    flexGrow: 1,
  },
  header: {
    fontSize:35,
    display:"inline-block",
    marginLeft:30,
    textAlign:"left"
  },
  subHeader: {
    fontWeight: 100,
  },
  selectedRosterCard: {
      backgroundColor: "#27AE60"
  },
  modalInviteButton: {
      width: 100,
      height: 40,
      marginRight: 10,
      backgroundColor: "#27AE60",
      color: theme.product.name == 'MaxOne' ? 'black' : 'white'
  },
  addUserButton: {
      height: 40,
      color: 'white',
      backgroundColor: "#1354F9"
  },
  plusButton: {
      backgroundColor: "#1354F9",
      color: 'white',
      outlineColor: 'transparent',
      outlineStyle: 'none'
  },
  textButton: {
      color: 'white',
      height: '40px',
      marginRight: '3px',
      backgroundColor: "#1354F9",
  },
  pageTitle: {
      textAlign: 'left',
      marginLeft: 50
  },
  details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: `100%`
  },
});
class WCSDirectorCreateTeams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            athletes: [],
            renderedAthletes: [],
            renderedCoaches: [],
            selectedAthlete: [],
            coaches: [],
            message: '',
            error: false,
            errorMessage: '',
            searchText: null,
            isLoading: false,
            rosterFilterBy: 'graduationYear',

        };
    }

    async componentDidMount(){
        const { currentTeam } = this.props;
        const organization = await this.getOrganization(currentTeam.organizationId)
        const teams = await this.getTeamsByOrganization(organization.id);
        const wcsOrgId = organization.id.split("-")[1]
        // const wcsOrgId = "wcs-284".split("-")[1]
        const defaultTeamId = "wcs-default-" + wcsOrgId
        console.log('defaultTeamId ', defaultTeamId)
        let athletes = [];
        let coaches = [];
        let students = localStorage.getItem(`@wcs:students-${wcsOrgId}:`, null)
        console.log("students == ", students)
        if(students){
          athletes = JSON.parse(students)
        }
        else{
          athletes = await this.getAthletes(defaultTeamId)
          localStorage.setItem(`@wcs:students-${wcsOrgId}:`, JSON.stringify(athletes))
        }
        coaches = await this.getCoaches(defaultTeamId)
        localStorage.setItem(`@wcs:teachers-${wcsOrgId}:`, JSON.stringify(coaches))
        this.setState({organization, athletes, renderedAthletes: athletes, coaches, renderedCoaches: coaches});
    }

    getAthlete() {
        var athleteId = this.props.match.params.id
        return API.get("users", `/users/${athleteId}`);
    }

    getOrganization(organizationId) {
        return API.get("organizations", `/organizations/${organizationId}`);
    }

    getTeamsByOrganization(organizationId) {
        return API.get("programs", `/programs/organization/${organizationId}`);
    }

    getAthletes(id){
        const teamId = id ? id : this.props.currentTeam.id;
        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("programs", `/programs/${teamId}/players`);
        })
    }

    getCoaches(id){
        const teamId = id ? id : this.props.currentTeam.id;

        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("programs", `/programs/${teamId}/coaches`);
        })
    }

    createTeam(team){
        return API.post('programs', '/programs', {
            body: team
        })
    }

    createSchedule(currentTeam, schedule) {
        return API.post("schedules", `/teams/${currentTeam.id}/schedules`, {
            body: schedule
        });
    }

    createRole(role) {
        return API.post("users", `/users/${role.userId}/roles`, {
            body: role
        });
    }

    updateOrganization(organization){
        return API.post("organizations", `/organizations/${organization.id}`, {
            body: organization
        });
    }

    setSessionInfo = async (username) => {
      let prefix = null
      if(window.location.href.toLowerCase().includes('wcs'))  prefix = "wcs";
      console.log('prefix ===>', prefix)
      const contextService = new ContextService();
      const {userContext, appContext} = await contextService.buildUserContext(username, prefix);
      this.props.setUserContext(userContext);
      this.props.setAppContext(appContext);
    }

    handleSubmit = async event => {
        const { name, sport, selectedAthlete } = this.state;
        const { currentTeam, currentUser } = this.props;
        let organization = this.state.organization;
        const team = {
            name: name,
            legacyId: 0,
            sport: sport,
            customName: `${name} ${sport}`,
            organizationId: currentTeam.organizationId,
            tenant: "wcs",
            tenantId: "wcs"
        }
        const newTeam = await this.createTeam(team);

        const scheduleJSON = {
            name: newTeam.customName,
            visibility: 'everyone',
            color: '#dce775',
        }
        await this.createSchedule(newTeam, scheduleJSON);

        await this.asyncForEach(selectedAthlete, async user =>{
          const roleJSON = {
              legacyId: 0,
              role: user.role === "student" ? "player" : "coach",
              parentId: newTeam.id,
              userId: user.id,
              tenant: "wcs",
              tenantId: "wcs"
          }
          const newRole = await this.createRole(roleJSON);

        })

        const roleJSON = {
            legacyId: 0,
            role: "coach",
            parentId: newTeam.id,
            userId: currentUser.id,
            tenant: "wcs",
            tenantId: "wcs"

        }
        const newRole = await this.createRole(roleJSON);
        const updatedSession = await this.setSessionInfo(currentUser.username);
        this.props.history.push('../')
    }

    handleChange = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }

    handleSelect = athlete => {
        // check if athlete is in the selection already
        let selectedAthletes = this.state.selectedAthlete;
        console.log('selectedAthletes.length', selectedAthletes.length)
        let isSelected = _.find(selectedAthletes, {id: athlete.id})
        console.log('isSelected', isSelected)

        // if not, then push onto array
        // if they are, then remove them from array.
        if(isSelected){
          let index = _.findIndex(selectedAthletes, {id: athlete.id})
          console.log('Index found ===>', index)
          selectedAthletes.splice(index, 1)
        }
        else{
          selectedAthletes.push(athlete);
        }
        console.log('selectedAthletes.length after', selectedAthletes.length)

        this.setState({
            selectedAthlete: selectedAthletes
        })
    }

    searchAthletes = event => {
        const { athletes } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedAthletes: _.filter(athletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()) || (a.graduationYear && a.graduationYear == event.target.value))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedAthletes: athletes
            })
        }
    }

    searchCoaches = event => {
        const { coaches } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedCoaches: _.filter(coaches, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedCoaches: coaches
            })
        }
    }

    handleFilter = filterValue => event => {
        this.setState({
            rosterFilterBy: filterValue
        })
    }

    renderTeamInputs(){
        const { team } = this.state;
        const { classes } = this.props;
        const sports = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling','Band', 'Marching Band', 'Other'];
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <TextField
                        id="name"
                        margin="normal"
                        label="Team Name"
                        className={classes.textField}
                        onChange={this.handleChange('name')}
                        value={this.state.name ? this.state.name : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.textField}>
                        <InputLabel htmlFor="sport">Team Sport</InputLabel>
                        <Select
                            input={<Input id="sport" />}
                            style={{ textAlign: 'left' }}
                            onChange={this.handleChange("sport")}
                            value={this.state.sport ? this.state.sport : ""}
                        >
                            {sports.map(sport => (
                                <MenuItem
                                    key={sport}
                                    value={sport}
                                >
                                    {sport}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    renderAthletes(){
        const { team, searchText, athletes, renderedAthletes, renderedCoaches, coaches, rosterFilterBy, appContext, currentTeam, selectedAthlete } = this.state;
        const { classes } = this.props;
        const sports = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Other'];
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <div className={"pageTitleContainer"}>
                        <h1 className={"pageTitle"}>Students</h1>
                        <TextField
                            id="search"
                            value={searchText ? searchText : ''}
                            className={"searchTextField"}
                            onChange={this.searchAthletes}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CloseIcon onClick={() => this.setState({searchText: '', renderedAthletes: athletes })} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <Grid item xs={12}>
                    {
                        renderedAthletes.length ?
                        <Grid container>
                            <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                                <Grid container className={"content"}>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                <strong>Last Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>First Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Email</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('graduationYear')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Grad Year</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                _.orderBy(renderedAthletes, [rosterFilterBy, 'nameLast']).map((athlete, index) => {
                                return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                        <AthleteCard
                                            athlete={athlete}
                                            classes={classes}
                                            appContext={appContext}
                                            currentTeam={currentTeam}
                                            view={() => this.handleView(athlete)}
                                            selected={_.findIndex(selectedAthlete, {id: athlete.id})}
                                            delete={() => this.handleDelete(athlete)}
                                            history={() => this.handleViewHistory(athlete)}
                                            handleSelect={() => this.handleSelect(athlete)}
                                        />
                                    </Grid>)
                                })
                            }
                        </Grid>
                    :
                        searchText
                    ?
                        <p className={"emptyListText"}>No results...</p>
                    :
                        <p className={"emptyListText"}>Loading Students...</p>
                    }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    renderCoaches(){
        const { team, searchText, renderedCoaches, coaches, rosterFilterBy, appContext, currentTeam, selectedAthlete } = this.state;
        const { classes } = this.props;
        const sports = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Other'];
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <div className={"pageTitleContainer"}>
                        <h1 className={"pageTitle"}>Teachers</h1>
                        <TextField
                            id="search"
                            value={searchText ? searchText : ''}
                            className={"searchTextField"}
                            onChange={this.searchCoaches}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CloseIcon onClick={() => this.setState({searchText: '', renderedCoaches: coaches })} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <Grid item xs={12}>
                    {
                        renderedCoaches.length ?
                        <Grid container>
                            <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                                <Grid container className={"content"}>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                <strong>Last Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>First Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Email</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                _.orderBy(renderedCoaches, [rosterFilterBy, 'nameLast']).map((coach, index) => {
                                return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                        <AthleteCard
                                            athlete={coach}
                                            classes={classes}
                                            appContext={appContext}
                                            currentTeam={currentTeam}
                                            selected={_.findIndex(selectedAthlete, {id: coach.id})}
                                            handleSelect={() => this.handleSelect(coach)}
                                        />
                                    </Grid>)
                                })
                            }
                        </Grid>
                    :
                        searchText
                    ?
                        <p className={"emptyListText"}>No results...</p>
                    :
                        <p className={"emptyListText"}>Loading Teachers...</p>
                    }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { classes, theme, appContext, userContext } = this.props;
        const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
        const { error, message, errorMessage, isLoading, value, athletes, coaches } = this.state;

        return (
        <PageWrapper title="New Team" {...this.props}>
        <div className={"newTeamOuterContainer"}>
            <div className={"pageTitleContainer"}>
            <div className={"avatarNameContainer"}>
            <h1 className={"pageTitle"}></h1>
            </div>
            {
              value !== 2 ?
            <div className={"titleButtonContainer"}>
            {
              value !== 0 &&
              <Button
                  mini
                  color="default"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({searchText: '', renderedAthletes: athletes, renderedCoaches: coaches })
                    this.setState({value:  value - 1})
                  }}
              >
                  Back
              </Button>
            }
              <Button
                  mini
                  color="primary"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({value:  value + 1})
                  }}
              >
                  Next
              </Button>
              </div>
              :
              <div className={"titleButtonContainer"}>
              {
                value !== 0 &&
                <Button
                    mini
                    color="default"
                    variant="contained"
                    title="View Schedules"
                    className={"styledButton"}
                    onClick={() =>{
                      console.log('changing value')
                      this.setState({searchText: '', renderedAthletes: athletes, renderedCoaches: coaches })
                      this.setState({value:  value - 1})
                    }}
                >
                    Back
                </Button>
              }
              <Button
                  mini
                  color="primary"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={this.handleSubmit}
              >
                  Submit
              </Button>
              </div>
            }

            </div>
            <SwipeableViews index={value}>
              <div className={"wcsInnerContainer"}>
                  {this.renderTeamInputs()}
              </div>
              <div className={"wcsInnerContainer"}>
                {this.renderAthletes()}
              </div>
              <div className={"wcsInnerContainer"}>
                {this.renderCoaches()}
              </div>
            </SwipeableViews>
        </div>
        </PageWrapper>
        );
    }
}


class AthleteCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          athlete: {},
          isSelected: false
      };
    }
    async componentDidMount(){
      this.setState({athlete: this.props.athlete || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      if(props.selected === -1){
        this.setState({isSelected: false})
      }
      else{
        this.setState({isSelected: true})

      }
      this.setState({athlete: props.athlete})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.athlete ? `${this.props.athlete.nameFirst} ${this.props.athlete.nameLast}` : "";
        const email = this.props.athlete ? `${this.props.athlete.email}` : "";
        const { athlete, isSelected } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${isSelected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15}}>
                                    {athlete && athlete.nameLast ? athlete.nameLast : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"}>
                                  {athlete && athlete.nameFirst ? athlete.nameFirst : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"}>
                                  {athlete && athlete.email ? athlete.email : ""}
                                </p>
                            </div>
                        </Grid>
                        {
                          athlete.graduationYear &&
                          <Grid item xs={3} className={"rosterColumnContainer"} >
                              <div style={{paddingTop:10}}>
                                  <p className={"textContainer"}>
                                      {athlete.graduationYear}
                                  </p>
                              </div>
                          </Grid>
                        }
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

WCSDirectorCreateTeams.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WCSDirectorCreateTeams));
