import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Storage } from "aws-amplify";
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import './gear.css'

const styles = theme => ({
  // container: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  // },
  // textField: {
  //   marginLeft: 8,
  //   marginRight: 8,
  //   width: 300,
  // },
  // menu: {
  //   width: 500,
  //   padding: 50
  // },
  button: {
    color: 'white', 
    backgroundColor: "#1354F9",
  },
  // root: {
  //   flexGrow: 1,
  // },
  // header: {
  //   fontWeight: 100,
  //   fontSize:45,
  //   marginLeft:35,
  //   float:"left"
  // },
  // subHeader: {
  //   fontWeight: 100,
  // },
  // image: {
  //   width: 100,
  //   marginBottom:'-35px'
  // },
});


class Gear extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      groups: [],
      isLoading: false,
    };
  }

  async componentDidMount(){
    const { currentTeam } = this.props;
    console.log('currentTeam', currentTeam)
    if(currentTeam.logo) {
      if(currentTeam.logo.includes("https://")) {
        currentTeam.logo = currentTeam.logo;
      } else {
        var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${currentTeam.legacyId ? currentTeam.legacyId : currentTeam.id}/${currentTeam.logo}`
        currentTeam.logo = logo;
      }
    }
    var link = this.buildLink();
    this.setState({link});
  }

  buildLink = () => {
    const { currentTeam, userContext } = this.props
    var link = "https://teamstore.squadlocker.com/launch?"

    link = link + "partner=" + currentTeam.organizationId
    link = link + "&teamuid=" + currentTeam.id
    link = link + "&sport=" + currentTeam.sport
    link = link + "&teamname=" + currentTeam.name
    link = link + "&color=Black"
    link = link + "&email=" + userContext.user.email
    link = link + "&logo=" + currentTeam.logo

    return link
  }

  render() {
    const { classes, theme, currentTeam } = this.props;

    console.log(currentTeam.logo);

    return (
      <PageWrapper title="Gear Store" {...this.props}>
      <div className={"gearOuterContainer"}>
        <div className={"pageTitleContainer"}>
          <h1 className={"pageTitle"}>Gear Store</h1>
          <div className={"titleButtonContainer"}>
          <Button
            target="_blank"
            variant="contained"
            //href={this.state.link}
            href={"https://calendly.com/toddgrant"}
            className={classes.button}
          >
            {currentTeam.logo?
              'Schedule a Demo'
            :
              'Upload Your Logo'
            }
          </Button>
          </div>
        </div>
        <div
          className={"selectContainer"}
        >
            <div className={"storeInfoTextContainer"}>
              <img style={{width:150, height:70, textAlign:"left"}} src="https://s3-us-west-2.amazonaws.com/d2c-cta/build/sqd/images/logo.svg" />
              {currentTeam.logo?
                <Hidden smDown>
                  <span style={{fontSize:23, marginLeft: 15}}>We’ve set up a free gear store for your team! Schedule a time to get it set up.</span>
                </Hidden>
              :
                <Hidden smDown>
                  <span style={{fontSize:23, marginLeft: 15}}>Upload your logo and open your store today!</span>
                </Hidden>
              }
            </div>
            {currentTeam.logo &&
            <div className={"productsContainer"}>
              <Hidden mdUp>
                <div style={{zIndex:12}}>
                  <img style={{maxHeight:230}} src={currentTeam.logo ? `https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/1305775-600.lgrd],productPath[https://teamlocker-cache.s3.amazonaws.com/teamlocker/stores/98457/df2acd3e-0c69-480d-a553-0adfbb77b8dc.png],alignLogo[Center],scaleLogo[100%],logoPath[${currentTeam.logo}]&call=url[file:previewTextRasterArtworkText.chain]` : "https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1268475.lgrd],productPath[https://teamlocker.s3.amazonaws.com/products/PS1268475-090_F.png],alignLogo[Center],scaleLogo[100%],logoPath[https://s3.amazonaws.com/teamlocker/images/your-logo-here.png]&amp;call=url[file:previewTextRasterArtworkText.chain]"} />
                </div>
              </Hidden>
              <Hidden smDown>
              <div style={{zIndex:12, marginRight: -100}}>
                  <img style={{maxHeight:230}} src={currentTeam.logo ? `https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/1305775-600.lgrd],productPath[https://teamlocker-cache.s3.amazonaws.com/teamlocker/stores/98457/df2acd3e-0c69-480d-a553-0adfbb77b8dc.png],alignLogo[Center],scaleLogo[100%],logoPath[${currentTeam.logo}]&call=url[file:previewTextRasterArtworkText.chain]` : "https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1268475.lgrd],productPath[https://teamlocker.s3.amazonaws.com/products/PS1268475-090_F.png],alignLogo[Center],scaleLogo[100%],logoPath[https://s3.amazonaws.com/teamlocker/images/your-logo-here.png]&amp;call=url[file:previewTextRasterArtworkText.chain]"} />
                </div>
                <div style={{ zIndex: 14, marginRight: -150 }}>
                  <img style={{maxHeight:280}} src={currentTeam.logo ? `https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1300123-025.lgrd],productPath[https://teamlocker.s3.amazonaws.com/products/PS1300123-025_F.png],alignLogo[Center],scaleLogo[100%],logoPath[${currentTeam.logo}]&call=url[file:previewTextRasterArtworkText.chain]` : "https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1300123-025.lgrd],productPath[https://teamlocker.s3.amazonaws.com/products/PS1300123-025_F.png],alignLogo[Center],scaleLogo[100%],logoPath[https://s3.amazonaws.com/teamlocker/images/your-logo-here.png]&amp;call=url[file:previewTextRasterArtworkText.chain]"} />
                </div>
                <div style={{marginLeft:75, zIndex:13}}>
                  <img style={{maxHeight:200, marginBottom:35}} src={currentTeam.logo ? `https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1261121.lgrd],productPath[https://teamlocker.s3.amazonaws.com/products/PS1261121-040_F.png],alignLogo[Center],scaleLogo[100%],logoPath[${currentTeam.logo}]&call=url[file:previewTextRasterArtworkText.chain]` : "https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1261121.lgrd],productPath[https://teamlocker.s3.amazonaws.com/products/PS1261121-040_F.png],alignLogo[Center],scaleLogo[100%],logoPath[https://s3.amazonaws.com/teamlocker/images/your-logo-here.png]&amp;call=url[file:previewTextRasterArtworkText.chain]"} />
                </div>
                <div style={{marginLeft:-120, zIndex:10}}>
                  <img style={{maxHeight:240}} src={currentTeam.logo ? `https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1295305.lgrd],productPath[https://teamlocker-cache.s3.amazonaws.com/teamlocker/stores/98457/d5ba53c6-36b3-41cd-ac63-1bbd72e45111.png],alignLogo[Center],scaleLogo[100%],logoPath[${currentTeam.logo}]&call=url[file:previewTextRasterArtworkText.chain]` : "https://squadlocker.liquifire.com/squadlocker?set=gridPath[https://teamlocker.s3.amazonaws.com/grids/PS1295305.lgrd],productPath[https://teamlocker-cache.s3.amazonaws.com/teamlocker/stores/98457/d5ba53c6-36b3-41cd-ac63-1bbd72e45111.png],alignLogo[Center],scaleLogo[100%],logoPath[https://s3.amazonaws.com/teamlocker/images/your-logo-here.png]&amp;call=url[file:previewTextRasterArtworkText.chain]"} />
                </div>
              </Hidden>
            </div>}
        </div>
      </div>
      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
      currentTeam: currentTeam||{},
      userContext: userContext
    };
}

const mapDispatchToProps = (dispatch) => ({
});


Gear.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Gear));
