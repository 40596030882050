import React, { Component } from "react";

import moment from 'moment'
import momentTZ from 'moment-timezone';

import Calendar from 'react-calendar'

import { TimeHours, TimeMinutes } from '../../../constants'

import StyledButton from '../../styledButton'
import BorderedInput from '../../borderedInput'
import FloatingSelect from '../../floatingSelect';

import { Button, Modal, MenuItem } from '@material-ui/core'

import ScheduleIcon from '@material-ui/icons/Schedule'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import EventIcon from '@material-ui/icons/EventAvailable'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { resolveTimeFromString } from '../../../utils/messageUtils.js'

import "./styles.css";

const defaultTimeZone = momentTZ.tz.guess();
const timeZonesList = momentTZ.tz.names();


class DateTimeModal extends Component {
    state = {
        actualDate: '',
        showInputs: true,
        displayedTime: '',
        displayedDate: '',
        showMinutes: false,
        showCalendar: false,
        showDateError: false,
        showTimeSelection: false,
        selectedTimeZone: defaultTimeZone,
        selectedTimeZoneUser: 'sender'
    };

    resetModal = () => {
        this.setState({
            actualDate: '',
            showInputs: true,
            displayedTime: '',
            displayedDate: '',
            showMinutes: false,
            showCalendar: false,
            showDateError: false,
            showTimeSelection: false,
        })
    }

    toggleInputs = () => {
        this.setState({
            showInputs: true,
            showCalendar: false,
            showDateError: false,
            showTimeSelection: false
        })
    }

    toggleCalendar = () => {
        this.setState({
            showInputs: false,
            showCalendar: true,
            showDateError: false,
            showTimeSelection: false
        })
    }

    toggleTimeSelection = () => {
        const { displayedTime } = this.state

        this.setState({
            showInputs: false,
            showCalendar: false,
            showDateError: false,
            showTimeSelection: true
        })

        if (displayedTime == '')
            this.setState({ displayedTime: moment().add(1, 'hour').startOf('hour').format('LT') }) // default to next hour
    }

    handleDayChange = date => {
        if (moment(date).startOf('day') < moment().startOf('day'))
            return this.setState({ showDateError: true })
        this.setState({
            showDateError: false,
            actualDate: moment(date),
            displayedDate: moment(date).format('L'),
        })
    }

    handleTimeChange = (time, type) => {
        const { actualDate } = this.state
        if (time.target)
            this.setState({ showDateError: false, displayedTime: time.target.value })
        else
            this.setState({ showDateError: false, displayedTime: actualDate.set(type, time).format('LT') })
    }

    handleConfirmDay = () => {
        this.toggleInputs()
    }

    handleConfirmTime = async () => {
        const { displayedTime, actualDate } = this.state
        const newActualDate = await resolveTimeFromString(displayedTime, actualDate)
        if (newActualDate < new Date())
            return this.setState({ showDateError: true })
        this.setState({ actualDate: newActualDate })
        this.toggleInputs()
    }

    handleScheduleMessage = () => {
        let { actualDate, selectedTimeZone, selectedTimeZoneUser } = this.state
        console.log('selectedTimeZoneUser ', selectedTimeZoneUser)
        const { close, scheduleMessage } = this.props
        console.log('actual date before ', actualDate.format())
        actualDate = momentTZ(actualDate).tz(selectedTimeZone, true).format()
        console.log('actualDate ', actualDate)
        actualDate = moment.utc(actualDate).format()
        console.log('actualDate UTC', actualDate)
        const isMatchUserTimezone = selectedTimeZoneUser === "endUser" ? true : false;
        console.log('isMatchUserTimezone ---', isMatchUserTimezone)

        scheduleMessage(actualDate, selectedTimeZone, isMatchUserTimezone)
        this.resetModal()
        close()
    }

    isScheduleBtnDisabled = () => {
        const { displayedDate, displayedTime } = this.state
        return displayedDate == '' || displayedTime == ''
    }

    handleBackButtonClick = () => {
        this.toggleInputs()
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    render() {
        const { open, close } = this.props
        const { showInputs, showCalendar, showTimeSelection, displayedDate, showDateError } = this.state

        return (
            <div>
                <Modal open={open} onClose={close}>
                    <div className={!!showCalendar ? 'longDateTimeModalOuterContainer' : 'dateTimeModalOuterContainer'}>
                        {!showInputs && this.renderBackButton()}
                        {!!showInputs && this.renderInputs()}
                        {!!showCalendar && this.renderCalendar()}
                        {!!showTimeSelection && this.renderTimeSelection()}
                        {!!showDateError && <p className={'dateErrorText'}>Please select a time in the future</p>}
                    </div>
                </Modal>
            </div>
        )
    }

    renderBackButton() {
        return (
            <div className={'backButtonContainer'} onClick={this.handleBackButtonClick}>
                <ChevronLeftIcon className={'icon'} /> Back
            </div>
        )
    }

    renderTimeZoneSelection(side, userType, text) {
        const { selectedTimeZoneUser } = this.state;
        return(
            <div
              className={`timeZoneTypeContainer
               ${side === 'left' ? 'leftContainer' : '' }
               ${side === 'right' ? 'rightContainer' : '' }
               ${ selectedTimeZoneUser === userType
                ? "activeTimeZoneType"
                : "disabled"
            }`}
            onClick={() =>
              this.handleChange("selectedTimeZoneUser", userType)
            }
          >
            <p>{text}</p>
          </div>
        )
    }

    renderInputs() {
        const { displayedDate = '', displayedTime = '', selectedTimeZone , selectedTimeZoneUser} = this.state
        const { openTime, openCalendar, handleDateChange } = this.props

        return (
          <div className={'inputContainer'}>
            <p className={'title'}>Schedule and Send Later</p>
            <BorderedInput
              value={displayedDate}
              adornment={<EventIcon />}
              placeholder={'MM / DD / YYYY'}
              handleClick={this.toggleCalendar}
              onAdornmentClick={this.toggleCalendar}
            />
            <BorderedInput
              value={displayedTime}
              adornment={<ScheduleIcon />}
              placeholder={'Select a time'}
              disabled={displayedDate == ''}
              handleClick={this.toggleTimeSelection}
              onAdornmentClick={displayedDate == '' ? null : () => this.toggleTimeSelection()}
            />
         <div className="timeZoneSelector">
             <FloatingSelect
                label="Timezone"
                value={selectedTimeZone}
                onChange={(e) => this.handleChange('selectedTimeZone', e.target.value)}
              >
                {timeZonesList.map(timeZone => (
                  <MenuItem
                   key={timeZone}
                  value={timeZone}>
                    {timeZone}
                  </MenuItem>
                ))}
              </FloatingSelect>
                </div>


            <div className={"timeZoneTypesContainer"}>
              {this.renderTimeZoneSelection('left', 'sender', 'All recipients will receive the message at the same time.')}
              {this.renderTimeZoneSelection('right', 'endUser', 'All recipients will receive the message adjusted for the time zone they are located in.')}
            </div>

            <div className={"msgButtonContainer"}>
                <div></div>
             <StyledButton
              icon={<EventIcon />}
              backgroundColor={'#27AE60'}
              title={'Schedule Message'}
              onClick={this.handleScheduleMessage}
              disabled={this.isScheduleBtnDisabled()}
            />
            </div>
          </div>
        )
    }

    renderCalendar() {
        const { actualDate, displayedDate = '', } = this.state
        return (
            <div className={'calendarSelectionContainer'}>
                <div className={'confirmationRowContainer'}>
                    <BorderedInput
                        disabled
                        value={displayedDate}
                        placeholder={'MM / DD / YYYY'}
                    />
                    <StyledButton
                        title={'Confirm'}
                        icon={<EventIcon />}
                        backgroundColor={'#27AE60'}
                        onClick={() => this.handleConfirmDay()}
                    />
                </div>
                <Calendar
                    tileDisabled={({ date, view }) => { return moment(date).startOf('day') < moment().startOf('day') }}
                    value={actualDate ? new Date(actualDate) : new Date()} className={'reactCalendar'} onChange={this.handleDayChange}
                />
            </div>
        )
    }

    renderTimeSelection() {
        const { showMinutes, actualDate, displayedTime = '' } = this.state

        return (
            <div className={'timeSelectionContainer'}>
                <p className={'title'}>Select a Time</p>
                <div className={'confirmationRowContainer'}>
                    <BorderedInput
                        value={displayedTime}
                        onChange={value => this.handleTimeChange(value)}
                    />
                    <StyledButton
                        title={'Confirm'}
                        icon={<EventIcon />}
                        backgroundColor={'#27AE60'}
                        onClick={() => this.handleConfirmTime()}
                    />
                </div>
                <div className={'timeOptionsContainer'}>
                    {showMinutes ?
                        TimeMinutes.map((timeObject, i) => {
                            return (
                                <div key={i} onClick={() => this.handleTimeChange(timeObject.actual, 'm')} className={'timeContaier'}>
                                    <p>{timeObject.displayed}</p>
                                </div>
                            )
                        })
                        :
                        TimeHours.map((timeObject, i) => {
                            return (
                                <div key={i} onClick={() => this.handleTimeChange(timeObject.actual, 'h')} className={'timeContaier'}>
                                    <p>{timeObject.displayed}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default DateTimeModal;
