import { SET_LIBRARY_INFO } from "../types/training";

const initState = {
  libraryInfo: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_LIBRARY_INFO:
      return {
        ...state,
        libraryInfo: action.payload,
      };
    default:
      return state;
  }
};
