import React, { Component } from 'react'

import ChipData from '../chipData'
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { tagClient } from '../../clients';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.css'
import _ from 'lodash';

const styles = {
    tagSearchDropDown: {
        maxHeight: "145px",
        overflow: "scroll",
        border: "1px solid #DDE2E5",
        background: "#fff",
        boxShadow: "0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%)",
        boxSizing: "border-box",
        borderRadius:"4px",
        minWidth: "160px",
        zIndex: 12,
        overflowX: "hidden",
        marginTop: "27px",
        flexDirection: "column",
        "& > div": {
            cursor:"pointer",
            "&:hover": {
                background: "#EDEFF0"
            },
            "&:first-child": {
                paddingTop: "5px"
            },
            "&:last-child": {
                paddingBottom: "5px"
            },
            padding: "3.5px 5px",
            fontSize: "14px"
        },
        "&::-webkit-scrollbar": {
            width: "14px",
            "-webkit-appearance": "unset !important",
            display:"block",
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            border: "2px solid rgba(0, 0, 0, 0)",
            borderLeft: "3px solid rgba(0, 0, 0, 0)",
            backgroundClip: "padding-box",
            backgroundColor: "#9ca6af",
            "-webkit-border-radius": "25px",
            height: "28px"
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent"
        }
    },
    inputContainer:{
        marginTop:"5px",
        display:"inline-flex",
        transform:"translate(0px)"
    }
}

class ChipInput extends Component {
    constructor(props){
        super(props)
        this.nameInput = {}
        this.state = { 
            currentTag: '',
            existingTags:[],
            enableExistingTag: false,
            isLoadingExistingTags:true
        }
    }

    componentDidMount = () => {
        this.fetchAvailableTags();
    }

    fetchAvailableTags = () => {
        let { currentTeam } = this.props;
        let existingTags = new Set();
        tagClient.getAvailableTagsByTeamId(currentTeam.id || currentTeam).then(({ data: { results : tagsList} })=> {
            (tagsList||[]).forEach(tag => {
                existingTags.add((tag.label||"").trim());
            });
            this.setState({isLoadingExistingTags:false, existingTags: Array.from(existingTags).sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            })});
        });
    }

    focusInput = () => {
        this.nameInput.focus()
    }

    handleTagEntry = e => {
        let value = e.target.value.replace(",","");
        this.setState({ currentTag: value })
    }

    inputKeyDown = e => {
        const { currentTag } = this.state
        const { removeChip, handleNewChip } = this.props
        if ((e.key === 'Enter' || e.key === "," || e.key === 'Tab') && currentTag) {
            handleNewChip(currentTag)
            this.setState({ currentTag: '' })
            e.preventDefault();
        }
        else if(e.key === 'Backspace' && !currentTag){
            removeChip()
        }
    }

    toggleExistingTags = (tagDropEnabled) => {
        let tagsContainerBottom;
        if(tagDropEnabled){
            let rect = this.nameInput.getBoundingClientRect()
            let isOutside = (rect.bottom + 143) >= (window.innerHeight || document.documentElement.clientHeight);
            if(isOutside){
                //Input height is 27
                tagsContainerBottom = 27
            }
        }
        this.setState({enableExistingTag: tagDropEnabled, tagsContainerBottom});
    }

    handleExistingTagSelection = (e, tag) => {     
        let { handleNewChip } = this.props;
        e.preventDefault();        
        handleNewChip(tag);
        this.setState({ currentTag: '' });
    }

    render(){
        let { currentTag, existingTags, enableExistingTag, isLoadingExistingTags, tagsContainerBottom } = this.state
        const { chips, placeholder, removeChip, className, classes, enableTopExstTagLayout } = this.props;
        let uniqTags = chips;
        if(uniqTags.some(item => typeof(item) === "string")){
            uniqTags = _.uniq(chips);
        }
        else {
            uniqTags = _.uniqBy(chips, "name");
        }

        existingTags = existingTags.filter(item => item.toLowerCase().startsWith(currentTag.toLowerCase().trim()));
        return(
            <div onBlur={() => this.toggleExistingTags(false)} onClick={this.focusInput} className={classnames('chipInputOuterContainer', className)}>
                {uniqTags.map((tag, index) => {
                    return <ChipData key={index} recipient={tag} handleDelete={() => removeChip(tag)} />
                })}
                <div className={`position-relative ${classes.inputContainer}`}>
                    <input
                        onFocus={() => this.toggleExistingTags(true)}
                        type={'text'}
                        value={currentTag}
                        className={'chipInput'}
                        onKeyDown={this.inputKeyDown}
                        onChange={this.handleTagEntry}
                        ref={(input) => { this.nameInput = input }}
                    />
                    {(isLoadingExistingTags || !!existingTags.length) && enableExistingTag && <div className={`existingTagDropDown position-fixed d-flex ${classes.tagSearchDropDown}`} style={{bottom:enableTopExstTagLayout? 27: tagsContainerBottom}}>
                        {isLoadingExistingTags ? <CircularProgress className={'loadingCircle mx-auto'} /> :
                            existingTags.map((tag, index) => {
                                return (<div key={index} className="tagElement" onMouseDown={(e) => this.handleExistingTagSelection(e, tag)}>{tag}</div>)
                            })
                        }
                    </div>}
                </div>
                 {placeholder?<label className={`chipInputPlaceholder${(currentTag || chips.length)?" hasValue":""}`}>{placeholder}</label>:null}
            </div>
        )
    }
}

export default withStyles(styles)(ChipInput);