import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { getTenant } from '../../utils/commonUtil'
import { WorkoutSchedule } from 'maxone-workout-programs';
import PageWrapper from "../nav/pageWrapper"
import {analyticsService} from "../../services/analyticsService";
import { getLocalizedString } from '../../utils/locale-utils';
import { ZoomIcon } from '../../components/icons';
import { SERVICES, TRAINING_OPTIONS } from '../../constants';
import { zoomClient } from '../../clients'

class WorkoutSchedules extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: false, 
            workoutProgram: {},
            userZoomData: {}
         }
    }

    componentDidMount = async () => {
        const { currentUser, isZoomConnected } = this.props
        if(this.props.location.state){
            let workoutProgram = this.props.location.state.workoutProgram;
            this.setState({ workoutProgram: workoutProgram })
        }
        if(isZoomConnected) {
           await zoomClient.getAccountStatus(currentUser.id).then((response) => {
                let { data } = response;
                this.setState({ userZoomData: data })
            }).finally(() => {
                this.setState({ isLoading: false });
            })
          }
    }

    async onAssign(assignment) {
        let { currentUser, userContext, currentTeam, appContext } = this.props;
        analyticsService.trackEvent('Assignment Assigned',
            {
                start_day: assignment.startDay,
                schedule_id: assignment.scheduleId,
                notification_reminder: assignment.notificationReminder,
                workout_entity: assignment.workoutEntity,
                assigner_id: assignment.assignerId
            })
        let userTraits = await analyticsService.getUserTraits(currentUser, userContext.roles, currentTeam, appContext, userContext);
        analyticsService.initWithUser(userTraits);
    }

    render() {
        const { workoutProgram, isLoading, loadingMessage } = this.state
        const { classes, appContext, currentTeam, tenant, currentUser, isZoomConnected, isUpliftConnected } = this.props
        const liveTrainingOptionsStatus = { [SERVICES.ZOOM]:isZoomConnected, [SERVICES.UPLIFT]:isUpliftConnected };

        const trainingOptions = TRAINING_OPTIONS.filter(options => liveTrainingOptionsStatus[options.feature])
        return (
            <PageWrapper title={getLocalizedString("ASSIGN")+" to "+getLocalizedString("ATHLETES")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <div>
                    <WorkoutSchedule 
                        domain={tenant}
                        teamId={currentTeam.id}
                        programId={(workoutProgram || {}).id}
                        userID={currentUser.id}
                        onAssign={assignment => this.onAssign(assignment)}
                        trainingOptions={trainingOptions}
                        userZoomData={this.state.userZoomData}
                        isZoomConnected={isZoomConnected}
                        {...this.props}
                    />
                </div>
            </PageWrapper>
        );
    }
}

WorkoutSchedules.propTypes = { };

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        currentUser: userContext.user || {},
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        isZoomConnected:appContext.isZoomConnected,
        isUpliftConnected:appContext.isUpliftConnected
    };
}

export default (connect(mapStateToProps)(WorkoutSchedules));
