import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { biabClient } from '../../clients'
import PageWrapper from '../nav/pageWrapper';
import Pagination from '../../components/pagination'
import { ROUTE_PATH } from "../../routeUtil";
import { AirplaneIcon } from '../../components/icons';
import BodyWrapper from "../../components/bodyWrapper";
import classnames from 'classnames'
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import { monthDiff } from '../../utils/commonUtil';
import { getTenantOrganizations, getTeamsByOrganization, saveOrganization } from '../../services/organizationService';
import { saveTeam } from '../../services/teamService';
import { saveTenant, getTenant } from '../../services/tenantService';

let styles = {
    userInfo: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        "& tr": {
            background: "#ffffff",
            height: "52px",
            "& th": {
                padding: "11px 0px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "14px",
                color: "#A0A7AB"
            }
        },
        "& tr th,tr td": {
            borderBottom: "1px solid #DDE2E5",
            maxWidth: "100px",
            paddingRight: "10px",
            overflow: "hidden",
            wordBreak: "break-all"
        },
        "& tr th:first-child,tr td:first-child": {
            paddingLeft: "16px",
            paddingRight: "16px"
        },
        "& tr th:last-child,tr td:last-child": {
            paddingRight: "16px"
        },
        "& tr th": {
            textAlign: "left"
        }
    },
    athleteList: {
        "& tr:hover": {
            background: "#F8F8F8"
        }
    },
    td: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#0B0B0B",
        textAlign: "left"
    },
    tableHead: {
        height: "36px !important"
    },
    athleteListContainer: {
        border: "1px solid #DDE2E5",
        borderBottom: "0px",
        background: "#ffffff",
        borderRadius: "8px 8px 0px 0px",
        overflow: "hidden"
    },
    sendInviteContainer: {
        // width: "136px",
        color: "#1354F9",
        textAlign: "right",
        cursor: "pointer",
        marginRight: "5px",
        "& svg": {
            cursor:"pointer",
            fill: "#1354F9",
            width: "16px",
            height: "16px",
            marginRight: "4px"
        }
    },
    statusBox: {
        padding: "4px 8px",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "12px",
        color: "#FFFFFF",
        borderRadius: "20px"
    },
    athleteImgBox: {
        minWidth: "36px",
        minHeight: "36px",
        maxWidth: "36px",
        maxHeight: "36px",
        borderRadius: "50%",
        border: "2px solid #DDE2E5",
        marginRight: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        overflow: "hidden",
        "& svg": {
            fill: "#A0A7AB"
        },
        "& img":{
            maxWidth:"100%",
            maxHeight:"100%"
          }
    },
    lastNameSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexWrap: "nowrap"
    },
    additionalInfoContainer: {
        height: "74px",
        paddingLeft: "15px",
        paddingRight: "15px",
        border: "1px solid #DDE2E5",
        borderTop: "0",
        background: "#ffffff",
        borderRadius: "0px 0px 8px 8px"
    },
    pageInfo: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        textAlign: "right",
        color: "#5B6062",
        textAlign: "right",
        marginLeft: "auto"
    },
    cursorPointer: {
        cursor: "pointer"
    }
};

const STATUS = Object.freeze({
    ACTIVE: "Active",
    NOT_ACTIVE: "Not Active",
});

class Customers extends Component {
    constructor(props) {
        super(props);
        this.fields = [
            {
                displayName: "Name",
                value: "name",
                sortParam: "userfullname.keyword"
            },
            {
                displayName: "MaxPay Status",
                value: "status",
                sortParam: "status.keyword"
            },
            {
                displayName: "# of Teams",
                value: "teamsLength",
                sortParam: "teamsLength.keyword"
            }
        ];
        this.state = {
            isLoading: true,
            showLoadingChild: true,
            currentPage: 0,
            athletePerPageLimit: 20,
            sortParam: "currentPeriodStart",
            sortOrder: "desc",
            organizations:[]
        }
    }

    async componentDidMount() {
        let { currentTeam, tenant } = this.props;
        await this.fetchOrganizationData()

    }

    getStatus = (isPaid) => {
        switch (isPaid) {
            case true: return { name: STATUS.ACTIVE, color: "#27AE60" };
            case false: return { name: STATUS.NOT_ACTIVE, color: "#F93F3F" };
            default: return { name: STATUS.NOT_ACTIVE, color: "#F93F3F" };
        }
    }

    fetchOrganizationData = async() => {
      let { tenant } = this.props;
      let organizations = await getTenantOrganizations(tenant)
      await organizations.forEach(async element => {
          element.statusInfo = this.getStatus(element.isPaid)
          let teams = await getTeamsByOrganization(element.id)
          element.teams = teams
          element.teamsLength = teams.length
          this.setState({organizations})
      });
      this.setState({organizations, isLoading:false})
    }

    setChildRef = (ref) => {
        this.paginationRef = ref;
    }

    convertToMaxPay = async(organization, test) => {
        const { tenant: tenantId } = this.props;
        this.setState({isLoading: true, loadingMessage1: `Converting ${organization.name} to MaxPay....`})
        let tenant = await getTenant(tenantId);  // getTenant
        if(!tenant.biab){ // If tenant has no default BIAB assets set
          let { data } = await biabClient.getBusinessesByTenant(tenant.id);
          if(data.total === 0 ){
            this.setState({isLoading: false})
            return alert("No default assets configured for this tenant")
          }
          else{
            const assetBusiness = data.results[0]
            let {data: businessData } = await biabClient.getConfigsByTeamId(tenant.id, assetBusiness.teamId)
            if(businessData){
              tenant.biab = {
                mobileBackgroundImageUrl: businessData.mobileBackgroundImageUrl,
                backgroundImageUrl: businessData.backgroundImageUrl,
                headerImageUrl: businessData.headerImageUrl,
                appIconUrl: businessData.appIconUrl,
                appName: businessData.appName
              }
              if(!tenant.androidAppUrl)tenant.androidAppUrl = businessData.androidAppUrl
              if(!tenant.iosAppUrl)tenant.iosAppUrl = businessData.iosAppUrl
              await saveTenant(tenant)
            }
          }
        }
        await (organization.teams || []).forEach(async team => {
          team.isPaid = true;
          let business = {
            tenant: tenant.id,
            business: team.customName ? team.customName : team.name,
            teamId: team.id,
            tenant: tenantId,
            organizationId: organization.id,
            androidAppUrl: tenant.androidAppUrl,
            iosAppUrl: tenant.iosAppUrl,
          }
          Object.keys(tenant.biab).forEach(key =>{
            business[key] = tenant.biab[key]
          })
          let { data, status } = await biabClient.addBusiness(tenantId, business)
          await saveTeam(team)
        })

        organization.isPaid = true
        organization.teamsCanOverridePricing = false
        await saveOrganization(organization)
        let organizations = await getTenantOrganizations(tenantId)
        await organizations.forEach(async element => {
            element.statusInfo = this.getStatus(element.isPaid)
            let teams = await getTeamsByOrganization(element.id)
            element.teams = teams
            element.teamsLength = teams.length
        });
        this.setState({isLoading: false, organizations})
    }

    render() {
        let { isLoading, organizations,
          showLoadingChild, loadingMessage1, loadingMessage2, currentPage, totalPages } = this.state;
        let { classes } = this.props;
        return (
            <PageWrapper setChildRef={this.setChildRef} {...this.props} title={"Customers"}>
                <div className={classes.pageContainer}>
                    <BodyWrapper isLoading={isLoading} showLoadingChild={showLoadingChild} loadingMessage1={loadingMessage1} loadingMessage1={loadingMessage2}>
                        <div className={classes.athleteListContainer}>
                            <table className={classes.userInfo}>
                                <thead>
                                    <tr className={classes.tableHead}>
                                        {this.fields.map((field) => { return <th className={classnames(classes.cursorPointer)}>{field.displayName}</th> })}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className={classes.athleteList}>
                                    {organizations.map((organization, index) => (
                                        <tr key={index} >


                                            {this.fields.map((field) => {
                                                return (
                                                    field.value == "name" ?
                                                        <td>
                                                            <span className={classes.lastNameSection}>
                                                                <span>{organization.name || ""}</span>
                                                            </span>
                                                        </td> :
                                                        field.value == "status" ?
                                                            <td className={classnames(classes.td, classes.statusContainer)}>
                                                                <span className={classes.statusBox} style={{
                                                                    background: organization.statusInfo.color
                                                                }}>{organization.statusInfo.name}</span>
                                                            </td> :
                                                        field.value == "teamsLength" ?
                                                            <td className={classes.td}>
                                                                {organization.teams && organization.teams.length || ""}
                                                            </td>
                                                            :
                                                            <td className={classes.td}>
                                                                {organization[field.value] || ""}
                                                            </td>
                                                )
                                            })}

                                            <td className={classnames(classes.td, classes.sendInviteContainer, classes.cursorPointer)}>
                                                {organization.statusInfo.name != STATUS.ACTIVE &&  <Button color="primary" variant="contained" onClick={() => this.convertToMaxPay(organization)}>Convert</Button>}
                                            </td>

                                        </tr>))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className={classnames(classes.additionalInfoContainer, "d-flex align-items-center")}>
                            {!isLoading && <div className={classes.pageInfo}>
                                Showing
                            <span className={"font-weight-bold"}> {(currentPage * 20) + Math.min(organizations.length, 1)} - {(currentPage * 20) + organizations.length} </span> of <span>{organizations.length}</span>
                            </div>}
                        </div>
                        <div className="d-flex justify-content-end">
                            <Pagination
                                currentPage={currentPage + 1}
                                totalPages={totalPages}
                                changeCurrentPage={this.changeCurrentPage}
                                theme="square-i"
                            />
                        </div>
                    </BodyWrapper>
                </div>
            </PageWrapper>);
    }
}


function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        userContext: userContext,
        appContext: appContext
    };
}



export default withStyles(styles)(connect(mapStateToProps)(Customers));
