import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let MeetingIcon = (props) => (
  <SvgIcon width="185.84" height="158.43" viewBox="0 0 185.84 158.43" {...props}>
  	<g id="Layer_2" data-name="Layer 2">
    <g id="_12" data-name="12">
      <g>
        <g>
          <path d="M163.69,52.78a14.5,14.5,0,1,0-14.5-14.5A14.5,14.5,0,0,0,163.69,52.78Z" />
          <path d="M174.87,54.34h-5.15a14.43,14.43,0,0,1-12.06,0h-5.15a10.9,10.9,0,0,0-10.13,6.73,15.16,15.16,0,0,1,12.33,15.38L154,102.34a15.86,15.86,0,0,1-4.2,10.29v17.73a7.5,7.5,0,0,0,7.5,7.5H170a7.5,7.5,0,0,0,7.51-7.5V102a11.61,11.61,0,0,0,7.62-10.55l.67-25.9A10.91,10.91,0,0,0,174.87,54.34Z" />
        </g>
        <g>
          <path d="M128.27,63.53A14.5,14.5,0,1,0,113.77,49,14.5,14.5,0,0,0,128.27,63.53Z" />
          <path d="M139.45,65.09H134.3a14.53,14.53,0,0,1-12.06,0h-5.15A10.9,10.9,0,0,0,107.33,71,15.2,15.2,0,0,1,119.1,86.28l-.67,25.89a15.84,15.84,0,0,1-4,10.07V141.1a7.51,7.51,0,0,0,7.51,7.51h12.7a7.5,7.5,0,0,0,7.5-7.51V112.79a11.64,11.64,0,0,0,7.63-10.56l.67-25.89A10.91,10.91,0,0,0,139.45,65.09Z" />
        </g>
        <g>
          <path d="M22.15,52.78a14.5,14.5,0,1,0-14.5-14.5A14.5,14.5,0,0,0,22.15,52.78Z" />
          <path d="M31.57,102.34,30.9,76.45A15.18,15.18,0,0,1,43.45,61a10.91,10.91,0,0,0-10.11-6.69H28.19a14.46,14.46,0,0,1-12.07,0H11A10.91,10.91,0,0,0,0,65.59l.67,25.9A11.63,11.63,0,0,0,8.3,102v28.32a7.5,7.5,0,0,0,7.5,7.5H28.5a7.5,7.5,0,0,0,7.51-7.5V112.88A15.86,15.86,0,0,1,31.57,102.34Z" />
        </g>
        <g>
          <path d="M57.35,63.53A14.5,14.5,0,1,0,42.85,49,14.5,14.5,0,0,0,57.35,63.53Z" />
          <path d="M66.88,112.17l-.67-25.89A15.2,15.2,0,0,1,78.26,71a10.92,10.92,0,0,0-9.73-5.86H63.38a14.56,14.56,0,0,1-12.07,0H46.16a10.91,10.91,0,0,0-11,11.25l.67,25.89a11.63,11.63,0,0,0,7.62,10.56V141.1a7.5,7.5,0,0,0,7.5,7.51H63.7a7.5,7.5,0,0,0,7.5-7.51V122.58A15.91,15.91,0,0,1,66.88,112.17Z" />
        </g>
        <g>
          <path d="M92.65,38.21a14.5,14.5,0,1,0-14.5-14.5A14.5,14.5,0,0,0,92.65,38.21Z" />
          <path d="M135.92,29a14.5,14.5,0,1,0-14.5-14.5A14.5,14.5,0,0,0,135.92,29Z" />
          <path d="M49.69,29a14.5,14.5,0,1,0-14.5-14.5A14.5,14.5,0,0,0,49.69,29Z" />
        </g>
        <g>
          <circle cx="92.65" cy="58.85" r="14.5" />
          <path d="M103.84,74.91H98.69a14.46,14.46,0,0,1-12.07,0H81.47a10.91,10.91,0,0,0-11,11.26l.67,25.89a11.62,11.62,0,0,0,7.62,10.55v28.32a7.5,7.5,0,0,0,7.5,7.5H99a7.5,7.5,0,0,0,7.5-7.5V122.61a11.61,11.61,0,0,0,7.62-10.55l.67-25.89A10.91,10.91,0,0,0,103.84,74.91Z" />
        </g>
      </g>
    </g>
  </g>
  </SvgIcon>
);

export default MeetingIcon;