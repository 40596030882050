import React from "react";
import { FaUsers } from "react-icons/fa";
import { Avatar, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchInput from "../../../components/searchInput";
import { colors } from "../../../components/v2/utils/theme-utils";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ShadowRoundContainer from "./shared/ShadowRoundContainer";
import moment from "moment";

const styles = () => ({
  container: {
    flex: 1,
  },
  searchBox: {
    height: 48,
    borderRadius: 4,
  },
});

const CustomCalendar = ({ startDate, setStartDate }) => {
  return (
    <ShadowRoundContainer
      shadowContainerStyle={{
        overflowY: "scroll",
        borderRadius: 16,
        width: "100%",
        height: 300,
      }}
    >
      <div style={{ width: "95%", marginLeft: "2.5%" }}>
        <Calendar
          defaultValue={new Date()}
          onClickDay={(value) => {
            setStartDate(value.getTime() / 1000);
          }}
        />
      </div>
    </ShadowRoundContainer>
  );
};

export default withStyles(styles)(CustomCalendar);
