import React from 'react';
// import {SvgIcon, Defs, Stop, Path, LinearGradient, Circle} from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { svgPathProperties } from "svg-path-properties";
import { withStyles } from "@material-ui/core/styles";
const styles = () => ({});
const severityColors = {
    high: {
        start: '#ed9494',
        mid: '#e46e75',
        end: '#e2646d',
        endpointStroke: '#e2646d44'
    },
    medium: {
        start: '#fff9bd',
        mid: '#f1de31',
        end: '#ecd401',
        endpointStroke: '#ecd40144'
    },
    low: {
        start: '#d8ffcb',
        mid: '#00C64F',
        end: '#00C64F',
        endpointStroke: '#7ac36044'
    }
}
const SpeedoMeterSvg = ({score = 0,size, totalValue = 10}) => {
    let offset = score / totalValue;
    const originalWidth = 220;
    const originalHeight = 115;
    const mx = 10;
    const my = 110;
    const ax = 210;
    const ay = 110;

    //the default "unfilled" arc path
    const arc = `M ${mx} ${my} A 100 100 180 0 1 ${ax} ${ay}`;
    
    const pathProperties = new svgPathProperties(arc);

    //arc length
    const len = pathProperties.getTotalLength();

    //the point of the score number on the arc
    const point = pathProperties.getPointAtLength(len * offset); 

    //the part of the arc that will be colored, representing score progress
    const filledArc = `M ${mx} ${my} A 100 100 180 0 1 ${point.x} ${point.y}`;


    //we take the given size and scale the original to fit
    const sizeModifier = size / originalWidth;
    const width = originalWidth * sizeModifier;
    const height = originalHeight * sizeModifier;

    //here we get the arc color, depending on the score
    const getSeverity = offset => {
        if(offset < .3) {
            return 'high'
        }
        else if(offset < .6){
            return 'medium'
        }
        return 'low'
    }

    const severity = getSeverity(offset);

return(
    
        <SvgIcon width={'325px'} height={'134px'} viewBox={`0 0 ${originalWidth} ${originalHeight}`}>
<defs>
    <linearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
        <stop offset="0%" stopColor={severityColors[severity].start} stopOpacity="1" />
        <stop offset="20%" stopColor={severityColors[severity].mid} stopOpacity="1" />
        <stop offset="60%" stopColor={severityColors[severity].end} stopOpacity="1" />
    </linearGradient>
</defs>

<path 
    d={arc}
    fill="none"
    stroke="#edeff0"
    strokeWidth="3"
    strokeLinecap="round"
/>

{offset > 0 && <path 
    d={filledArc}
    fill="none"
    stroke="url(#grad)"
    strokeWidth="3"
    strokeLinecap="round"
/>}

{offset > 0  && <circle cx={point.x} cy={point.y} r="2.5" fill={severityColors[severity].end} stroke={offset < 1 ? severityColors[severity].endpointStroke : 'none'} strokeWidth={9} />}  

</SvgIcon>
    
)
}
export default withStyles(styles)(SpeedoMeterSvg);
