import React, { Component } from "react";
import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import SwipeableViews from 'react-swipeable-views';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import MaskedInput from 'react-text-mask';

import shortid from "shortid";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    textField: {
        flexGrow: 1,
        width:'100%'
    },
    selectField: {
        flexGrow: 1,
        width:'100%',
        textAlign: 'left',
        paddingTop: '5px'
    },
    button: {
        width: 80,
        height: 40,
        borderRadius: 0,
        marginRight: 10,
        zIndex: 99
    }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={["+", /[1-9]/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class ProductModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            type: "",
            partner: "",
            price: "",
            sport: "",
            detailedDescription: "",
            shortDescription: "",
            partnerImage: "",
        };
    }

    async componentWillReceiveProps(nextProps){
      console.log('nextprops === ', nextProps)
      this.setState({
        name: nextProps.product.name ? nextProps.product.name : "",
        type: nextProps.product.type ? nextProps.product.type : "",
        partner: nextProps.product.partner ? nextProps.product.partner : "",
        price: nextProps.product.price ? nextProps.product.price : "",
        sport: nextProps.product.sport ? nextProps.product.sport : "",
        detailedDescription: nextProps.product.detailedDescription ? nextProps.product.detailedDescription : "",
        shortDescription: nextProps.product.shortDescription ? nextProps.product.shortDescription : "",
        partnerImage: nextProps.product.partnerImage ? nextProps.product.partnerImage : ""
      })
    }

    createProduct = async product => {

      console.log('Product ', product)
      if(product.id){
          await API.post("products", `/products`, {
              body: product
          });
      }

      this.props.close(product);
    }

    handleSubmit = () => {
        const { product } = this.props;
        var productJson = product;
        productJson.name = this.state.name
        productJson.type = this.state.type
        productJson.partner = this.state.partner
        productJson.price = this.state.price
        productJson.sport = this.state.sport
        productJson.programId = this.state.programId || "null"
        if(this.state.detailedDescription){
          productJson.detailedDescription = this.state.detailedDescription
        }
        if(this.state.shortDescription){
          productJson.shortDescription = this.state.shortDescription
        }
        if(this.state.partnerImage){
          productJson.partnerImage = this.state.partnerImage
        }

        console.log('productJson', productJson)
        this.createProduct(productJson);
    }

    handleChange = name => product => {
        console.log('name ', name)
        console.log('product ', product)
        this.setState({
            [name]: product.target.value
        });
    };

    handleTabValueChange = (product, value) => {
        console.log('HERE IS THE VALUE', value);
        this.setState({ value });
    };

    render() {
        const { classes, open, close, product } = this.props;
        const { value } = this.state;

        return (
            <LegacyModalContainer
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={close}
                containerClassName={"paper"}>       
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>
                        Product
                    </h2>
                </div>
                {this.state.errorMessage !== ""?<Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>:null}
                <div  className={"formContainer"}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabValueChange}
                    className={"tabContainer"}>
                    <Tab label="Basic Info" value={0} />
                </Tabs>
                <SwipeableViews
                    axis={'x-reverse'}
                    index={value}
                    onChangeIndex={this.handleChangeIndex}>
                    <div dir={"right"}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className={"inputSectionTitle"}>
                                <h2>Product</h2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    className={classes.textField}
                                    value={this.state.name}
                                    onChange={this.handleChange("name")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="type"
                                    label="Type"
                                    className={classes.textField}
                                    value={this.state.type}
                                    onChange={this.handleChange("type")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="shortDescription"
                                    label="Short Description"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.shortDescription}
                                    onChange={this.handleChange("shortDescription")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="sport"
                                    label="Sport"
                                    className={classes.textField}
                                    value={this.state.sport}
                                    onChange={this.handleChange("sport")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="partner"
                                    label="Partner"
                                    className={classes.textField}
                                    value={this.state.partner}
                                    onChange={this.handleChange("partner")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="price"
                                    label="Price"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.price}
                                    onChange={this.handleChange("price")}
                                    margin={"dense"}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rowsMax="10"
                                    id="detailedDescription"
                                    label="Detailed Description"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.detailedDescription}
                                    onChange={this.handleChange("detailedDescription")}
                                    margin={"dense"}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </SwipeableViews>
                </div>
                <div className={"modalFooterContainer"}>
                    <div>
                        <IconButton
                            title="Delete product"
                            onClick={this.deleteProduct}
                            aria-label="edit"
                            variant="contained">
                            <DeleteIcon className={"styledIcon"} />
                        </IconButton>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={"outlinedButton"}
                            onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={"filledButton"}
                            onClick={this.handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </LegacyModalContainer>
        );
    }
}

ProductModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductModal);
