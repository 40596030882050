import React, { Component } from "react";
import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import SwipeableViews from 'react-swipeable-views';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import MaskedInput from 'react-text-mask';

import shortid from "shortid";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    textField: {
        flexGrow: 1,
        width:'100%'
    },
    selectField: {
        flexGrow: 1,
        width:'100%',
        textAlign: 'left',
        paddingTop: '5px'
    },
    button: {
        width: 80,
        height: 40,
        borderRadius: 0,
        marginRight: 10,
        zIndex: 99
    }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={["+", /[1-9]/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class ProgramModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            type: "",
            partner: "",
            price: "",
            sport: "",
            detailedDescription: "",
            shortDescription: "",
            partnerImage: "",
        };
    }

    async componentWillReceiveProps(nextProps){
      console.log('nextprops === ', nextProps)
      this.setState({
        name: nextProps.program.name ? nextProps.program.name : "",
        type: nextProps.program.type ? nextProps.program.type : "",
        coachCode: nextProps.program.coachCode ? nextProps.program.coachCode : "",
        playerCode: nextProps.program.playerCode ? nextProps.program.playerCode : "",
        keyMetric1: nextProps.program.keyMetric1 ? nextProps.program.keyMetric1 : "",
        keyMetric2: nextProps.program.keyMetric2 ? nextProps.program.keyMetric2 : "",
        keyMetric3: nextProps.program.keyMetric3 ? nextProps.program.keyMetric3 : "",
      })
    }

    createProgram = async program => {

      console.log('Program ', program)
      if(program.id){
          await API.post("programs", `/programs`, {
              body: program
          });
      }

      this.props.close(program);
    }

    handleSubmit = () => {
        const { program } = this.props;
        var programJson = program;
        programJson.name = this.state.name
        programJson.type = this.state.type
        programJson.coachCode = this.state.coachCode
        programJson.playerCode = this.state.playerCode
        if(this.state.keyMetric1){
          programJson.keyMetric1 = this.state.keyMetric1
        }
        if(this.state.keyMetric2){
          programJson.keyMetric2 = this.state.keyMetric2
        }
        if(this.state.keyMetric3){
          programJson.keyMetric3 = this.state.keyMetric3
        }

        console.log('programJson', programJson)
        this.createProgram(programJson);
    }

    handleChange = name => program => {
        console.log('name ', name)
        console.log('program ', program)
        this.setState({
            [name]: program.target.value
        });
    };

    handleTabValueChange = (program, value) => {
        console.log('HERE IS THE VALUE', value);
        this.setState({ value });
    };

    render() {
        const { classes, open, close, program } = this.props;
        const { value } = this.state;

        return (
            <LegacyModalContainer
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={close}
                containerClassName={"paper"}>
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>
                        Program
                    </h2>
                </div>
                {this.state.errorMessage !== ""?<Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>:null}
                <div  className={"formContainer"}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabValueChange}
                    className={"tabContainer"}>
                    <Tab label="Basic Info" value={0} />
                </Tabs>
                <SwipeableViews
                    axis={'x-reverse'}
                    index={value}
                    onChangeIndex={this.handleChangeIndex}>
                    <div dir={"right"}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className={"inputSectionTitle"}>
                                <h2>Program</h2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    className={classes.textField}
                                    value={this.state.name}
                                    onChange={this.handleChange("name")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="type"
                                    label="Type"
                                    className={classes.textField}
                                    value={this.state.type}
                                    onChange={this.handleChange("type")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="playerCode"
                                    label="Player Invite Code"
                                    className={classes.textField}
                                    value={this.state.playerCode}
                                    onChange={this.handleChange("playerCode")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="coachCode"
                                    label="Coach Invite Code"
                                    className={classes.textField}
                                    value={this.state.coachCode}
                                    onChange={this.handleChange("coachCode")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3>KEY METRICS</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <p>{`Choose the main three activities you'd like to track for your players.`}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="keyMetric1"
                                    label="Key Metric #1"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.keyMetric1}
                                    onChange={this.handleChange("keyMetric1")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="keyMetric2"
                                    label="Key Metric #2"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.keyMetric2}
                                    onChange={this.handleChange("keyMetric2")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="keyMetric3"
                                    label="Key Metric #3"
                                    style={{width:'100%'}}
                                    className={classes.textField}
                                    value={this.state.keyMetric2}
                                    onChange={this.handleChange("keyMetric3")}
                                    margin={"dense"}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </SwipeableViews>
                </div>
                <div className={"modalFooterContainer"}>
                    <div>
                        <IconButton
                            title="Delete program"
                            onClick={this.deleteProgram}
                            aria-label="edit"
                            variant="contained">
                            <DeleteIcon className={"styledIcon"} />
                        </IconButton>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={"outlinedButton"}
                            onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={"filledButton"}
                            onClick={this.handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </LegacyModalContainer>
        );
    }
}

ProgramModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgramModal);
