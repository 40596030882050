import React, { Component, useState } from 'react'
import './styles.css'
import IconButton from '../../iconButton'
import { MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import ActivityBlock from '../activityBlock'
import QuestionBlock from '../questionBlock';
import { Droppable } from 'react-beautiful-dnd'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import OptionsSelector from '../../optionsSelector'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import classnames from 'classnames';
import NewQuestionBlock from '../questionBlock/newQuestionBlock';
import AddQuestionModal from '../questionBlock/addQuestionModal';
const blockOptions = [{ id: 'series', name: 'Series' }, { id: 'circuit', name: 'Circuit' }];
const styles = {

}

class WorkoutBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSelectorOpen: false,
            showDeleteConfirm: false,
            selectedQuestionDisplayValue: ""
        }
    }

    toggleBlockTypeSelector = () => {
        const { isSelectorOpen } = this.state
        this.setState({ isSelectorOpen: !isSelectorOpen })
    }

    handleSegmentTypeSelection = typeSelection => {
        const { handleChangeBlockType } = this.props
        handleChangeBlockType(typeSelection) // id is equivalent to the value
        this.toggleBlockTypeSelector()
    }

    handleDeletePress = () => {
        const { handleDelete } = this.props
        const { showDeleteConfirm } = this.state
        if (!showDeleteConfirm)
            return this.setState({ showDeleteConfirm: true })
        handleDelete()
        this.handleDeleteCancel()
    }

    handleDeleteCancel = () => {
        this.setState({ showDeleteConfirm: false })
    }

    toggleQuestionModal = () => {
        let { addQuestionModalEnabled } = this.state;
        this.setState({ addQuestionModalEnabled: !addQuestionModalEnabled })
    }

    setQuestion = (question, workoutBlock) => {
        let { setQuestion } = this.props;
        this.toggleQuestionModal();        
        setQuestion(question, workoutBlock);
    }

    render() {
        const { isSelectorOpen, showDeleteConfirm, addQuestionModalEnabled } = this.state
        const {
            activities,
            currentTeam,
            workoutBlock,
            allActivities,
            handleDuplicate,
            handleScoreType,
            handleActivityChange,
            handleDeleteForBlockItem,
            handleDuplicateActivity,
            handleWorkoutActivityChange,
            handleAddSetGroup,
            classes,
            questions,
            questionTags
        } = this.props
        const isQuestionBlock = workoutBlock.isQuestionBlock;
        const blockItems  = (isQuestionBlock ? workoutBlock.questions : workoutBlock.activities) || [];
        return (
            <div className={'workoutBlockOuterContainer'}>
                <div className={'blockTitleContainer'}>
                    <div className={'blockTitleTextContainer'}>
                        {!showDeleteConfirm ?
                            <p className={'blockTitleText'}>Block {parseInt(workoutBlock.id) + 1} {workoutBlock.type ? ` - ${workoutBlock.type.replace(/^\w/, c => c.toUpperCase())}` : null}</p>
                            :
                            <p className={'blockTitleText'} style={{ color: 'red' }}>Remove Block {parseInt(workoutBlock.id) + 1} {workoutBlock.type ? ` - ${workoutBlock.type.replace(/^\w/, c => c.toUpperCase())}` : null}?</p>
                        }
                        {!showDeleteConfirm && !isQuestionBlock && <ArrowDropDownIcon className={'icon'} onClick={this.toggleBlockTypeSelector} />}
                        {isSelectorOpen && !isQuestionBlock &&
                            <div className={'optionsContainer'}>
                                {blockOptions.map(option => (
                                    <MenuItem onClick={e => this.handleSegmentTypeSelection(e.target.getAttribute('value'))} key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </div>
                        }
                    </div>
                    <div className={'blockTitleButtonContainer'}>
                        {!showDeleteConfirm &&
                            <React.Fragment>
                                {!isQuestionBlock && <div onClick={handleDuplicate} className={'textButtonContainer'}>
                                    <p className={'cancelText'} onClick={this.handleDeleteCancel}>Duplicate</p>
                                    <FilterNoneIcon className={'duplicateIcon'} />
                                </div>}
                                <div onClick={this.handleDeletePress} className={'textButtonContainer'} style={{ marginLeft: 20 }}>
                                    <p className={'cancelText'} onClick={this.handleDeleteCancel}>Delete</p>
                                    <DeleteIcon className={'deleteIcon'} />
                                </div>
                            </React.Fragment>
                        }
                        {showDeleteConfirm &&
                            <React.Fragment>
                                <p className={'cancelText'} onClick={this.handleDeleteCancel}>Cancel</p>
                                <p className={'confirmText'} onClick={this.handleDeletePress}>Confirm</p>
                            </React.Fragment>
                        }
                    </div>
                </div>
                <Droppable isCombineEnabled={true} droppableId={workoutBlock.id}>
                    {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={'activityBlockOuterContainer'}>
                            {blockItems.length ?
                               blockItems.map((item, index) => {
                                    return (
                                        isQuestionBlock ? 
                                        <QuestionBlock
                                            index={index}
                                            question={item}
                                            handleDeleteForBlockItem={() => handleDeleteForBlockItem(item)}
                                        />
                                        :
                                        <ActivityBlock
                                            isQuestionBlock={isQuestionBlock}
                                            index={index}
                                            key={item.id}
                                            activity={item}
                                            currentTeam={currentTeam}
                                            allActivities={allActivities} 
                                            handleAddSetGroup={() => handleAddSetGroup(item)}
                                            handleDuplicate={() => handleDuplicateActivity(item)}
                                            handleDeleteForBlockItem={() => handleDeleteForBlockItem(item)}
                                            handleScoreType={option => handleScoreType(option, item.id)}
                                            handleActivityChange={(property, value) => handleActivityChange(item, property, value)}
                                            handleDeleteActivitySetGroup={handleDeleteForBlockItem}
                                            handleWorkoutActivityChange={handleWorkoutActivityChange}
                                        />
                                    )
                                })
                                :
                                !isQuestionBlock && <div className={'emptyActivitiesContainer'}>
                                    <p>Drag activities here</p>
                                </div>
                            }
                            <div style={blockItems.length ? {} : { display: 'none' }}>
                                {provided.placeholder}
                            </div>
                            {isQuestionBlock && <NewQuestionBlock toggleQuestionModal={this.toggleQuestionModal}/>}
                            {addQuestionModalEnabled && <AddQuestionModal 
                                onChange={(question) => this.setQuestion(question, workoutBlock)}
                                questions={questions}
                                questionTags={questionTags}
                                toggleQuestionModal={this.toggleQuestionModal}
                            />}
                        </div>
                    )}
                </Droppable>

            </div>
        )
    }
}

export default withStyles(styles)(WorkoutBlock)
