import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import { API, Auth, Storage } from "aws-amplify";
import _ from "lodash";
import SwipeableViews from 'react-swipeable-views';
import fileDownload from 'js-file-download';
import QRCode from 'qrcode.react';

import { withStyles } from '@material-ui/core/styles';
import { Button, Hidden } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import Icon from '@material-ui/core/Icon';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { VictoryChart, VictoryZoomContainer, VictoryLine, VictoryAxis } from 'victory';

import { setUserContext, setAppContext } from '../../actions/session';
import PageWrapper from '../nav/pageWrapper';
import { setAuthenticatedUserById } from '../../actions/user-actions';
import { setCurrentTeamById } from '../../actions/team-actions';

const styles = theme => ({
    pageTitle: {
        textAlign: 'left',
        marginLeft: 50
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: `100%`
    },
})

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 5,
      open: false,
      isLoading:true,
      headers:[
        { id: 'image', numeric: false, disablePadding: false, label: '' },
        { id: 'nameFirst', numeric: false, disablePadding: false, label: 'First Name' },
        { id: 'nameLast', numeric: false, disablePadding: false, label: 'Last Name' },
        { id: 'username', numeric: false, disablePadding: false, label: 'Username' },
      ]
    };
  }

  async componentDidMount() {
    console.log('this.props ', this.props)
    this.setState({ isLoading: true, loadingMessage: "Gathering users..." })
    var athletes = []
    this.setState({
        isLoading: false,
        athletes: athletes || [],
        renderedAthletes: athletes || []
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChange = name => event => {
      this.setState({
          [name]: event.target.value
      });
  }
  handleFilter = filterValue => event => {
      this.setState({
          rosterFilterBy: filterValue
      })
  }

  handleSearch = async() => {
    this.setState({ isLoading: true, loadingMessage: "Searching users...", totalUsersFound: null})
    var searchContext = {
        nameFirst: this.state.searchFirst ? this.state.searchFirst : null,
        nameLast: this.state.searchLast ? this.state.searchLast : null,
        id: this.state.searchUserId ? this.state.searchUserId : null,
        username: this.state.searchUsername ? this.state.searchUsername : null,
    }
    var athletes = await this.getUsers(searchContext)

    if(this.state.searchUserId){
        athletes = [athletes]
    }

    if(!athletes){
        athletes = []
        this.setState({totalUsersFound: '0'})
    }
    else{
        this.setState({totalUsersFound: athletes.length})
    }

    await Promise.all(athletes.map(async(athlete) => {
        if(athlete.avatarUrl && !athlete.avatarUrl.includes("https://")){
            var logo = athlete.avatarUrl.includes("https://") || athlete.avatarUrl.includes("http://") ? athlete.avatarUrl : `https://s3.amazonaws.com/programax-videos-production/user/avatar/${athlete.legacyId ? athlete.legacyId : athlete.id}/${athlete.avatarUrl}`;
            athlete.avatarUrl = logo;
        }
    }))

    this.setState({
        isLoading: false,
        athletes: athletes || [],
        renderedAthletes: athletes || []
    });
  }

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClose = (user) => {
    if(user.id){
      //replace updated user in data
      var data =this.state.data
      var element =  data.find(function(d) {
          return d.id === user.id
      })
      var index = data.indexOf(element)
      data.splice(index, 1, user)
      this.setState({data})
    }
    this.setState({ open: false });
  };

  handleOpen = event => {
      this.setState({ open: true, viewingEvent: event });
  };

  getUsers(body) {
    return API.post("admin", `/admin/users`, {body:body});
  }

  async impersonate(user) {
    let { tenant } = this.props;
    this.setState({isLoading: true, loadingMessage:`Impersonating ${user.username}.... `})
    var username = user.username;
    console.log('username ', username)
    try{
      var context = await Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken
          },
          body:{
            username: username,
            tenant: user.tenant || tenant,
            platform: "web"
          }
        }
        // console.log('auth.idToken.jwtToken', auth.idToken.jwtToken)
        return API.post("auth", `/auth/userContext`, options);
      })
      console.log("context ", context)
      this.props.setUserContext(context.userContext);
      this.props.setAppContext(context.appContext);
      this.props.setAuthenticatedUserById(context.userContext.user.id);
      this.props.setCurrentTeamById(context.appContext.id);
      this.setState({isLoading:false})
    }
    catch(e){
      console.log('Error ==>', e)
      this.setState({isLoading:false})
    }
  }

  handleSelect = athlete => {
      this.setState({
          selectedAthlete: athlete == this.state.selectedAthlete ? null : athlete
      })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleView = user => {
    this.props.history.push('/profile', { user: user, role: 'coach' })
  }

  render() {
    const {
        open,
        email,
        value,
        csvType,
        nameLast,
        contacts,
        nameFirst,
        isLoading,
        isSearching = false,
        phoneNumber,
        rosterFilterBy,
        loadingMessage,
        selectedAthlete,
        activityResults=[],
        renderedAthletes=[],
        leaderboardResults=[],
        isAthleteHistoryModalOpen,
    } = this.state;

    const { classes, currentTeam, tenant } = this.props;
    return (
    <PageWrapper title={"Users *impersonating athletes is currently not an option"}  {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
      <div className={"rosterOuterContainer"}>
          <div style={{}}>
              <div>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12} style={{textAlign:"left"}}>                
                  </Grid>
                </Grid>
                  <div style={{background:'white', flex:1, paddingTop:10, paddingBottom:5}}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={1} >
                    <SearchIcon fontSize="large" style={{width:35, height:35, marginTop:5}}>
                    </SearchIcon>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} >
                      <TextField
                          id="searchUserId"
                          label="User Id"
                          style={{width:'100%'}}
                          onChange={this.handleChange("searchUserId")}
                      />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2} >
                        <TextField
                            id="searchUsername"
                            style={{width:'100%'}}
                            onChange={this.handleChange("searchUsername")}
                            label="Username"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                        <TextField
                            id="searchFirst"
                            style={{width:'100%'}}
                            onChange={this.handleChange("searchFirst")}
                            label="First Name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                        <TextField
                            id="searchLast"
                            style={{width:'100%'}}
                            onChange={this.handleChange("searchLast")}
                            label="Last Name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} style={{}}>
                          <Button
                              mini
                              title="Add Athletes"
                              variant="fab"
                              className={"styledButton"}
                              color="primary"
                              onClick={this.handleSearch}
                          >
                              <SearchIcon className={"icon"} style={{ color: 'white' }} />
                          </Button>
                      </Grid>
                  </Grid>
                  </div>
              </div>
              <div>
              {
                this.state.totalUsersFound
                ?
                <div className={"emptyUsersSelectContainer"}>Found: {this.state.totalUsersFound} users</div>
                :
                null
              }
              </div>
              <div className={renderedAthletes.length ? "selectContainer" : "emptyUsersSelectContainer"}>
              {
                  renderedAthletes.length ?
                  <div>
                      <div className={"rosterCard"}>
                          <div className={"content"}>
                              <div className={"rosterColumnContainer"} onClick={this.handleFilter('id')}>
                                  <div className={"nameColumn"} >
                                      <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15}}>
                                          <strong>ID</strong>
                                      </p>
                                  </div>
                              </div>
                              <div className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                  <div className={"nameColumn"} >
                                      <p className={"textContainer"}>
                                          <strong>Last Name</strong>
                                      </p>
                                  </div>
                              </div>
                              <div className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                  <div className={"nameColumn"} >
                                      <p className={"textContainer"}>
                                          <strong>First Name</strong>
                                      </p>
                                  </div>
                              </div>
                              <div className={"rosterColumnContainer"} onClick={this.handleFilter('username')}>
                                  <div className={"nameColumn"} >
                                      <p className={"textContainer"}>
                                          <strong>Username</strong>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      {_.orderBy(renderedAthletes, rosterFilterBy,).map((athlete, index) => {
                          return(<div key={index}className={"atheleteGridItem"}>
                                  <AthleteCard
                                      athlete={athlete}
                                      classes={classes}
                                      view={() => this.handleView(athlete)}
                                      selected={selectedAthlete == athlete}
                                      impersonate={() => this.impersonate(athlete)}
                                      history={() => this.handleViewHistory(athlete)}
                                      handleSelect={() => this.handleSelect(athlete)}
                                  />
                              </div>)
                      })}
                  </div>
              :
              <div>
                  {
                    isSearching
                    ?
                    <p className={"emptyListText"}>No users found matching that criteria.</p>
                    :
                    <p className={"emptyListText"}>Use searches above to find users.</p>
                  }
              </div>
              }
              </div>
          </div>
      </div>
      </PageWrapper>
    );
  }
}
class AthleteCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          athlete: {}
      };
    }
    async componentDidMount(){
      this.setState({athlete: this.props.athlete || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      this.setState({athlete: props.athlete})
    }

    handleImpersonate() {
        this.props.impersonate(this.props.athlete);
    }

    handleEditClick(){
        this.props.view(this.props.athlete);
    }

    formatPhoneNumber(phoneNumber) {
        if(phoneNumber){
          let areaCode = phoneNumber.substring(0,3);
          let nextThree = phoneNumber.substring(3,6);
          let finalFour = phoneNumber.substring(6,10);
          return `${areaCode}-${nextThree}-${finalFour}`;
        }
    }

    render() {
        const { selected } = this.props;
        const name = this.props.athlete ? `${this.props.athlete.nameFirst} ${this.props.athlete.nameLast}` : "";
        const email = this.props.athlete ? `${this.props.athlete.email}` : "";
        const phoneNumber = this.props.athlete ? `${this.formatPhoneNumber(this.props.athlete.phoneNumber)}` : "";
        const { athlete } = this.state;

        return (
            <div>
                <div className={ selected ? "selectedRosterCard" : "rosterCard" }>
                    <div onClick={this.props.handleSelect} className={"content"}>
                        <div className={"rosterColumnContainer"} >
                            <div className={"nameColumn"} >
                                <Hidden smDown>
                                    { athlete.avatarUrl && <Avatar src={athlete.avatarUrl} style={{height:48, width:48}} /> }
                                    { !athlete.avatarUrl && <Avatar style={{height:48, width:48}}> <PersonIcon /> </Avatar> }
                                </Hidden>
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15}}>
                                    {athlete.id}
                                </p>
                            </div>
                        </div>
                        <div className={"rosterColumnContainer"} >
                            <div className={"nameColumn"} >
                                <p className={"textContainer"}>
                                    {athlete.nameLast}
                                </p>
                            </div>
                        </div>
                        <div className={"rosterColumnContainer"} >
                            <div className={"nameColumn"} >
                                <p className={"textContainer"}>
                                    {athlete.nameFirst}
                                </p>
                            </div>
                        </div>
                        {
                            selected
                        ?
                            <div className={"rosterColumnContainer"}>
                                <Hidden smDown>
                                    <div style={{textAlign:"right", marginRight:15}}>

                                        <IconButton
                                            title="Edit User"
                                            onClick={() => this.handleEditClick()}
                                            aria-label="delete"
                                            variant="contained"
                                            className={"roundCommandButton"}
                                        >
                                            <EditIcon className={"styledIcon"} />
                                        </IconButton>

                                        <IconButton
                                            title="Impersonate User"
                                            onClick={() => this.handleImpersonate()}
                                            aria-label="delete"
                                            variant="contained"
                                            className={"roundCommandButton"}
                                        >
                                            <PersonIcon className={"styledIcon"} />
                                        </IconButton>
                                    </div>
                                </Hidden>
                                <Hidden mdUp>
                                    <div style={{display: 'flex'}}>
                                        <IconButton
                                            title="Edit User"
                                            onClick={() => this.handleEditClick()}
                                            aria-label="delete"
                                            variant="contained"
                                            className={"roundCommandButton"}
                                        >
                                            <EditIcon className={"styledIcon"} />
                                        </IconButton>

                                        <IconButton
                                            title="Impersonate User"
                                            onClick={() => this.handleImpersonate()}
                                            aria-label="delete"
                                            variant="contained"
                                            className={"roundCommandButton"}
                                        >
                                            <PersonIcon className={"styledIcon"} />
                                        </IconButton>
                                    </div>
                                </Hidden>
                            </div>
                        :
                        <div className={"rosterColumnContainer"} >
                            <div className={"nameColumn"} >
                                <p className={"textContainer"}>
                                    {athlete.username}
                                </p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

AdminUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam||{},
        userContext,
        appContext,
    };
}
const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext)),
  setAuthenticatedUserById: (id) => dispatch(setAuthenticatedUserById(id)),
  setCurrentTeamById: (id) => dispatch(setCurrentTeamById(id))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdminUsers));
