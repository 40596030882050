import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RunningIcon = (props) => (
  <SvgIcon width="18" height="22" viewBox="0 0 18 22" {...props}>
    <path d="M12.887 4.11816C12.5168 4.4873 12.0673 4.67188 11.5385 4.67188C11.0096 4.67188 10.5601 4.4873 10.1899 4.11816C9.81971 3.74902 9.63461 3.30078 9.63461 2.77344C9.63461 2.24609 9.81971 1.79785 10.1899 1.42871C10.5601 1.05957 11.0096 0.875 11.5385 0.875C12.0673 0.875 12.5168 1.05957 12.887 1.42871C13.2572 1.79785 13.4423 2.24609 13.4423 2.77344C13.4423 3.30078 13.2572 3.74902 12.887 4.11816ZM5.27163 13.4126C5.58894 14.1509 6.09135 14.7178 6.77885 15.1133L7.17548 15.3506L6.85817 16.1812C6.51442 16.9458 5.91947 17.3281 5.07332 17.3281H2.01923C1.67548 17.3281 1.37139 17.2095 1.10697 16.9722C0.86899 16.7085 0.75 16.4053 0.75 16.0625C0.75 15.7197 0.86899 15.4297 1.10697 15.1924C1.37139 14.9287 1.67548 14.7969 2.01923 14.7969H4.67668L5.27163 13.4126ZM15.9808 9.73438C16.3245 9.73438 16.6154 9.86621 16.8534 10.1299C17.1178 10.3672 17.25 10.6572 17.25 11C17.25 11.3428 17.1178 11.646 16.8534 11.9097C16.6154 12.147 16.3245 12.2656 15.9808 12.2656H13.8389C13.0457 12.2656 12.4772 11.9097 12.1334 11.1978L11.3401 9.57617L10.0709 12.6611L12.53 14.085C12.9002 14.3223 13.1647 14.6387 13.3233 15.0342C13.482 15.4297 13.4952 15.8252 13.363 16.2207L12.1334 20.2549C11.9483 20.835 11.5385 21.125 10.9038 21.125C10.7716 21.125 10.6394 21.0986 10.5072 21.0459C10.1899 20.9668 9.95192 20.769 9.79327 20.4526C9.63461 20.1362 9.59495 19.8066 9.67428 19.4639L10.7849 16.0229L7.41346 14.0454C6.85817 13.7026 6.47476 13.228 6.26322 12.6216C6.07812 12.0151 6.11779 11.4219 6.38221 10.8418L7.84976 7.40088L7.25481 7.20312C6.91106 7.12402 6.58053 7.21631 6.26322 7.47998L4.67668 8.6665C4.41226 8.87744 4.10817 8.96973 3.76442 8.94336C3.42067 8.89062 3.14303 8.73242 2.93149 8.46875C2.71995 8.17871 2.6274 7.8623 2.65385 7.51953C2.70673 7.15039 2.87861 6.86035 3.16947 6.64941L4.71635 5.46289C5.69471 4.72461 6.76562 4.50049 7.92909 4.79053L10.7452 5.62109C11.8558 5.91113 12.6755 6.5835 13.2043 7.63818L14.2356 9.73438H15.9808Z" fill="#222127"/>
  </SvgIcon>
);

export default RunningIcon;
