import React, { Component } from 'react'
import {
    FormControl,
    TextField,
    InputAdornment
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

const styles = theme => ({
    formControl: {
        border: "1px solid #DDE2E5",
        borderRadius: "4px",
        height: "48px",
        paddingLeft: "15px",
        flexGrow: 1,
        width: "100%",
        textAlign: "left",
        marginBottom: "24px",
        fontFamily: 'Poppins',   
        background:'#ffffff',
        '&.error':{
            border: "1px solid #F93F3F",
            '& label':{
                color:"#F93F3F !important",
                opacity:'0.7'
            }
        }  
    },
    textFieldlabelRoot: {
        top: "17px",
        transform: "translate(0, 0px) scale(1)",
        left: "0px",
        color: "#0B0B0B",
        opacity: "0.3",
        fontFamily: 'Poppins',
        fontSize: "14px"
    },
    labelFocused: {
        transform: "translate(0, 1.5px) scale(0.75)",
        top: "4px",
        color: "#0B0B0B !important",
        opacity: "0.3",
        '& ~ div':{
            '& .incrementContainer':{
                display:"flex"
            }
        }
    },
    input: {
        fontFamily: "Poppins",
        "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none"
        }
    },
    incrementContainer: {
        display:"none",
        position: "absolute",
        flexDirection: "column",
        right: 0,
        top: "-18px",
        "& svg": {
            height: "25px",
            fill: "#C4C4C4"
        }
    },
    marginBottomZero:{
        marginBottom:"0px"
    },
    helperText:{
        textAlign:"right"
    },
    textArea:{
        height:"auto"
    }
});

const FloatingInput = (props) => {
    const { 
        id, label, value, onChange, 
        enableNumberIncrementArrow, className, overrideContainerCss,
        overrideInputCSS, overrideNameLabelRootCss,
        overideLabelFocusCss, required,
        classes, type, disabled, removeBottomMargin, onEnterPressed, helperText, error,
        onBlur, multiline, rows, maxLength } = props
    return (
        <FormControl className={classNames(classes.formControl, {[classes.textArea]:multiline}, overrideContainerCss,{[classes.marginBottomZero]:removeBottomMargin}, {error})}>
            <TextField
                required={required}
                error={error}
                id={id}
                type={type}
                label={label}
                className={className}
                disabled={disabled}
                value={value}
                onChange={onChange}
                helperText={helperText}
                onBlur={onBlur}
                multiline={multiline}
                row={rows}
                onKeyPress={(ev) => {               
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                      if(typeof(onEnterPressed) === 'function'){
                          onEnterPressed();
                      }
                    }
                  }}
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        input: classNames(classes.input, overrideInputCSS)
                    },
                    endAdornment: enableNumberIncrementArrow? getIncrementArrow(enableNumberIncrementArrow, onChange, classes, value):null
                }}
                InputLabelProps={{
                    classes: {
                        root: classNames(classes.textFieldlabelRoot, overrideNameLabelRootCss),
                        shrink: classNames(classes.labelFocused, overideLabelFocusCss)
                    }
                }}
                inputProps={{
                    maxLength
                }}
                FormHelperTextProps={{
                    classes: {
                        root: classNames(classes.helperText)                    
                    }
                }}
            />

        </FormControl>
    )
}

function getIncrementArrow(enableNumberIncrementArrow, onChange, classes, value) {
    return enableNumberIncrementArrow && <InputAdornment>
        <div className={classNames(classes.incrementContainer,"incrementContainer")}>
            <ArrowDropUp iconStyle={classes.arrowIcon} className={'icon'} onClick={() => onChange({ target: { value: Number(value) + 1 } })} />
            <ArrowDropDown iconStyle={classes.arrowIcon} className={'icon'} onClick={() => value > 0 ? onChange({ target: { value: value - 1 } }) : null} />
        </div>
    </InputAdornment>
}

export default withStyles(styles)(FloatingInput);