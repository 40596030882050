import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Calendar = (props) => (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="11" cy="11" r="9.5" stroke="#CED3D6" stroke-width="3" />
    </SvgIcon>
);

export default Calendar;
