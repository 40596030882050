import { getTeamActivities, saveTagging, createActivity, updateActivity} from '../services/activitiesService'
import {isVideo, isImage, isPDF, FileType, getExtension} from './commonUtil'
import { s3Upload } from "../../src/libs/awsLib";
import { analyticsService, currentTimestamp } from '../services/analyticsService';
import { getTagsByEntityId, addAvailableTag } from '../actions/tags-actions';
import { saveMultipleTags } from '../services/taggingService';
import { activityClient } from '../../src/clients';
import _ from "lodash"
import { getTimeObject } from './commonUtil'
import { getMimeType } from './media-utils'
import { WhiteLabelMediaClient, WhiteLabelTeamClient } from 'maxone-api-client'
const mediaClient = new WhiteLabelMediaClient();
const teamClient = new WhiteLabelTeamClient();

export async function resolveActivityList(entityId, context){
    let activities = await getTeamActivities(entityId)
    activities = activities.filter(a => a.id)
    return activities
}

export async function resolveFilteredActivities(event, activities){
    if(!event.target.value || event.target.value == '')
        return activities
    return activities.filter(a => a && a.name && a.name.toLowerCase().includes(event.target.value.toLowerCase()))
}

export async function resolveActivitiesWithFilter(filters, tagFilters, searchString, activities) {
    return activities.filter(a => a && a.name && a.name.toLowerCase().includes(searchString.toLowerCase()) &&
        ((tagFilters||[]).length ? tagFilters.some(tag=> tag === ""? (a.taggings||[]).length != 0: a.taggings.includes(tag)):true)
        && (!filters?true: filters.find(f => (f||"").toLowerCase() == (a.category||{}).toLowerCase())))
}

export function getFileTypeSupportStatus(filename){
    let fileType = FileType.OTHER;
    let fileExtension = getExtension(filename);
    let isSupported = true;
    if(isVideo(filename)){
        if(!['mp4','webm','ogg', 'mov'].includes(fileExtension)){
            isSupported = false;
        }
        fileType = FileType.VIDEO;
    }else if(isPDF(filename)){
        fileType = FileType.PDF;
    }
    else if(isImage(filename)){
        fileType = FileType.IMAGE;
    }
    else{
        isSupported = false;
    }
    return {isSupported, fileType}
}

export async function saveActivity(activity, currentUser, currentTeam, activityTags, isDirectorActivity, uploadingFile) {
    let savedActivityDetails = {};
    try {
        // When saving an activity, we need to check if the videoUrl is actually a document/attachment URL and reset properties accordingly
        if (!activity.attachment && activity.videoUrl && activity.media && activity.media.url) {
            activity.attachment = { url: activity.media.url, type: activity.media.type };
        }
        let activityAttachment = activity.attachment && activity.attachment.url ? activity.attachment.url : activity.attachment;
        const activityJSON = {
            ...activity,
            teamId: isDirectorActivity? null: currentTeam.id,
            isPercentOfMax: activity.scoreType == 'weight' ? activity.isPercentOfMax: false,
            legacyId: activity.legacyId || 0,
            isMarketplace: activity.isMarketplace || 'false',
            completionValue: activity.completionValue || 10,
            hasLeaderboard: activity.category != 'education' &&  activity.scoreType !== 'participation' ? String(activity.hasLeaderboard) || 'false' : 'false',
            scoreType: activity.scoreType || 'participation',
            percentOfMaxActivityId: (activity.isPercentOfMax || (activity.isPercentOfMax == undefined)) && activity.scoreType == 'weight' ? activity.percentOfMaxActivityId||'0' : '0',
            attachment: (activityAttachment||"").includes("blob") ? null : activityAttachment,
            organizationId: isDirectorActivity? currentTeam.organizationId: null,
        }
        if (activity.isNewActivity) {
            console.log('the if statement ran')
            //Override activity id need to shift it
            let { data }  = await activityClient.createActivity(activityJSON.name,
                activityJSON.description, activityJSON.category, activityJSON.teamId, activityJSON.isMarketplace,
                activityJSON.hasLeaderboard, activityJSON.completionValue, activityJSON.scoreType, activityJSON.videoType,
                activityJSON.percentOfMaxActivityId, activityJSON.videoName, activityJSON.thumbnailBase64,
                activityJSON.attachment, activityJSON.source,
                activityJSON.videoUrl, activityJSON.isPercentOfMax,
                activityJSON.organizationId, activityJSON.videoId, activityJSON.thumbnail, activityJSON.isCoachesOnly);
            savedActivityDetails = data;
            // switch (activityJSON.category) {
            //     case 'drill':
            //         analyticsService.trackEvent('Drills (Activity) Created', {
            //             drillId: activityJSON.id,
            //             drillSlug: activityJSON.name,
            //             drillCreatorID: currentUser.id,
            //             drillCreatedDate: currentTimestamp
            //         });
            //         break;

            //     case 'exercise':
            //         analyticsService.trackEvent('Exercise (Activity) Created', {
            //             exerciseId: activityJSON.id,
            //             exerciseSlug: activityJSON.name,
            //             exerciseCreatorID: currentUser.id,
            //             exerciseCreatedDate: currentTimestamp
            //         });
            //         break;
            //     case 'education':
            //         analyticsService.trackEvent('Education (Activity) Created', {
            //             educationId: activityJSON.id,
            //             educationSlug: activityJSON.name,
            //             educationCreatorID: currentUser.id,
            //             educationCreatedDate: currentTimestamp
            //         });
            //         break;
            // }
        }
        else {
            let { data } = await activityClient.updateActivityById(activityJSON.id, activityJSON.name,
                activityJSON.description, activityJSON.category, activityJSON.teamId, activityJSON.isMarketplace,
                activityJSON.hasLeaderboard, activityJSON.completionValue, activityJSON.scoreType, activityJSON.videoType,
                activityJSON.percentOfMaxActivityId, activityJSON.videoName, activityJSON.thumbnailBase64,
                activityJSON.attachment, activityJSON.source,
                activityJSON.videoUrl, activityJSON.isPercentOfMax,
                activityJSON.organizationId, activityJSON.videoId, activityJSON.thumbnail, activityJSON.isCoachesOnly);
                savedActivityDetails = data;
        }

        let taggingJson = {
            parentId:savedActivityDetails.id,
            parentType:"activity",
            labels : (activityTags||[]).map(item => item.name ? item.name : item)
        }
        activity.taggings = taggingJson.labels || [];
        await saveMultipleTags(taggingJson);
        await Promise.all(
          activity.taggings.map(label => addAvailableTag(label, currentTeam.id))
        )

        await activityClient.reindexActivityById(activity.id);

        if(!!activity.fileObject && isVideo(activity.videoName)) {
            console.log('uploadingFile ', uploadingFile)
            if (uploadingFile) {
                uploadingFile(true)
            }
            let ext = getExtension(activity.videoName);
            const formData = new FormData()
            formData.append('file', activity.fileObject)
            console.log('savedActivityDetails ', savedActivityDetails)

            await fetch(savedActivityDetails.url, {
              method:"put",
              body: activity.fileObject,
              headers:{
                "Content-Type": `video/${ext}`
              }
            })
        }
    }
    catch (e) {
        console.log(e);
    }
    if (uploadingFile) {
        uploadingFile(null);
    }
    return savedActivityDetails.id;
}


export function getActivityGrouping(activities , activeLabelFilter){
    let filterableTags = [{ label: 'untagged' }];
    let taggedActivityGroupings = [{ label: 'untagged', activities: [] }];
    activities.map(async activity => {
        const taggings = _.values(activity.taggings);
        if(taggings.length === 0){
            const isActivityPresent = _.find(taggedActivityGroupings[0].activities, a => a.id == activity.id)
            if(!isActivityPresent) taggedActivityGroupings[0].activities.push(activity);
        }
        taggings.map(tag => {
                if(_.isEmpty(tag))
                    return
                let tagPresent = _.find(filterableTags, t => { return t.label.toLowerCase() == tag.toLowerCase() });
                if(!tagPresent) filterableTags.push({ label:tag });
                let index = _.findIndex(taggedActivityGroupings, group => group.label.toLowerCase() == tag.toLowerCase())
                if(index != -1) {
                    const isActivityPresent = _.find(taggedActivityGroupings[index].activities, a => a.id == activity.id)
                    if(!isActivityPresent) taggedActivityGroupings[index].activities.push(activity);
                }
                else taggedActivityGroupings.push({ label: tag.toLowerCase(), activities: [activity] });
        });
    })

    let filteredTaggedActivityGroupings = taggedActivityGroupings;
    if(activeLabelFilter != 'all'){
        filteredTaggedActivityGroupings = _.filter(taggedActivityGroupings, group => group.label.toLowerCase() == activeLabelFilter.toLowerCase());
    }
    return {taggedActivityGroupings, filteredTaggedActivityGroupings, filterableTags}
}


export async function uploadActivityAttachment(file, activityId, teamId, progressCallback){
    var re = /(?:\.([^.]+))?$/;
    var extension = re.exec(file.name)[1];
    const key = `${'ACTIVITY'.toLowerCase()}/${activityId}.${extension.toLowerCase()}`;
    const type = getMimeType(extension);
    const url = await s3Upload(file, key, type,  progressCallback)
    const team = type && type.toUpperCase().includes('IMAGE') ? teamClient.getTeamById(teamId) : {};
    const { data } = await mediaClient.createMedia('ACTIVITY', activityId, key, type, url, type && type.toUpperCase().includes('IMAGE') ? url : team.logo)
    return { url, mediaId: data.id, thumbnail: (type && type.toUpperCase().includes('IMAGE') ? url : team.logo) };
}

export async function resolveActivityThumbnail(currentTeam, activity){
    const data = {}
    const teamLogo = (currentTeam.tenant == 'vnn' || currentTeam.tenant == 'm1vnn') && currentTeam.organization && currentTeam.organization.logoUrl ? currentTeam.organization.logoUrl
        : currentTeam.logo && currentTeam.logo.includes('https') ? currentTeam.logo
        : currentTeam.logo ? `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${currentTeam.legacyId ? currentTeam.legacyId : currentTeam.id}/${currentTeam.logo}` : null;
    if(activity.attachment && !activity.attachment.includes("https://")){
        let thumbnail = null;
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(activity.attachment)[1];
        if(ext.toUpperCase() === "MOV" || ext.toUpperCase() === "M4V" || ext.toUpperCase() === "MP4"){ // assume file type is video
            thumbnail = `https://s3.amazonaws.com/programax-videos-production/videos/${activity.legacyVideoId ? activity.legacyVideoId : activity.videoId}/thumbnail/thumbnail.png`
        }
        data.thumbnail = thumbnail
        data.teamLogo = teamLogo
    }

    return data
}

export async function correctLegacyActivityData(activity) {
    let attachment = null
    let thumbnail = null
    if (activity.attachment && !activity.attachment.includes("https://")) {
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(activity.attachment)[1];

        if (ext && (ext.toUpperCase() === "MOV" || ext.toUpperCase() === "M4V" || ext.toUpperCase() === "MP4")) { // assume file type is video'
            let splitAttachment = activity.videoName ? activity.videoName : activity.attachment.split('?AWSAccessKeyId')[0];
            if (splitAttachment && (splitAttachment.toUpperCase().includes('M4V') || splitAttachment.toUpperCase().includes('MOV'))) {
                let newAttachment = splitAttachment.split('.');
                newAttachment.pop();
                newAttachment.push('mp4');
                attachment = newAttachment.join('.');
            }
            else {
                attachment = splitAttachment
            }
            attachment = `https://s3.amazonaws.com/programax-videos-production/videos/${activity.legacyVideoId ? activity.legacyVideoId : activity.videoId}/processed/${attachment}`
            thumbnail = `https://s3.amazonaws.com/programax-videos-production/videos/${activity.legacyVideoId ? activity.legacyVideoId : activity.videoId}/thumbnail/thumbnail.png`
        }
        else if (ext && (ext.toUpperCase() === "JPG" || ext.toUpperCase() === "PNG")) { // assume is an image
            attachment = `https://s3.amazonaws.com/programax-videos-production/uploads/activity/image/${activity.legacyId ? activity.legacyId : activity.id}/${activity.attachment.split('?AWSAccessKeyId')[0]}`
            thumbnail = attachment
        }
        else { // file of some kind
            attachment = `https://s3.amazonaws.com/programax-videos-production/uploads/activity/file/${activity.legacyId ? activity.legacyId : activity.id}/${activity.attachment.split('?AWSAccessKeyId')[0]}`
        }
        activity.attachment = attachment;
        if(activity.thumbnail && activity.thumbnail.includes("https")){
            activity.thumbnail = activity.thumbnail;
        }
        else{
            activity.thumbnail = thumbnail;
        }
    }
    if(!!activity.videoId && !activity.videoUrl){
        activity.videoUrl = generateVideoLink(activity);
    }
}


export function formatMeasureValueForDisplaying(scoreType, value = "", isEdited=false){
    scoreType = (scoreType||"").toLowerCase();
    if (
			scoreType == 'time' ||
			scoreType == 'shortesttime' ||
			scoreType == 'shortest_time'
		) {
			//convert milliseconds to formatted string
			let { minutes, seconds, millisec } = getTimeObject(value);
      if((minutes || minutes === 0) && (seconds || seconds === 0) && (millisec || millisec === 0) && !isEdited){
        seconds = _.padStart(seconds, 2, '0');
        value = minutes + ':' + seconds;
        if (Number(millisec)) {
          millisec = _.padStart(millisec, 3, '0');
          value = value + '.' + millisec;
        }
      }
      else{
        return value;
      }
		} else if (scoreType == 'distance' && !isEdited) {
			var inches = value;
			var feet = Math.floor(inches / 12);
			inches %= 12;
			value = feet + ':' + inches;
		}
    return value;
}

//Old value is legacy value still being used to save activity result
export function formatMeasureValueForSaving(scoreType, value = ""){
    scoreType = (scoreType||"").toLowerCase();
    let oldformat = value;
    let newFormat = value;
    if (
			!!value &&
			(scoreType == 'time' ||
				scoreType == 'shortesttime' ||
				scoreType == 'shortest_time')
		) {
			let { minutes, seconds, millisec } = getTimeObject(value);
			//WHY DO WE ACTUALLY HAVE THIS ;(
			if (scoreType === 'time') {
                console.log('in time')
				oldformat = {
					minutes: parseInt(minutes),
					seconds: parseInt(seconds),
					hundredths: millisec,
				};
                console.log(oldformat)
			} else {
                console.log('in shortest time')
				let minutesInSeconds = parseInt(minutes) * 60 + parseInt(seconds);
				oldformat = `${minutesInSeconds}.${millisec}`;
                console.log(oldformat)
			}
			newFormat =
				minutes * 60000 + (parseInt(seconds) || 0) * 1000 + Number(millisec);
		}
        console.log(newFormat)
    if(!!value && scoreType == 'distance'){
        let splitDistance = oldformat.toString().split(':');
        splitDistance = splitDistance.map(d => parseInt(d))
        let inchesInFeet = parseInt(splitDistance[1]) / 12
        oldformat = splitDistance.length > 1 ? (splitDistance[0] + inchesInFeet).toFixed(2) : parseInt(oldformat).toFixed(2)
        //Save goal in inches
        newFormat = (splitDistance[0] * 12) + (splitDistance[1]||0);
    }
    return {oldformat, newFormat: Number(newFormat)};
}


export function validateScoreOrGoalValue(scoreType, value){
    let isValid = true;
    //Validation Logic
    scoreType = (scoreType||"").toLowerCase();
    if(value && (scoreType == "shortesttime" || scoreType == "time")){
            let splittedValues = String(value).split(":");
            if(splittedValues.length !== 3 && splittedValues.length !== 2){
                isValid = false;
            }
            else {
                let {minutes, seconds, millisec} = getTimeObject(value);
                if(minutes > 60 || seconds > 60 || millisec > 1000){
                    isValid = false;
                }
            }
    }
    return isValid;
}


export function getScoreTypeHelperTxt(scoreType){
    let helperTxt = "";
    switch((scoreType||"").toLowerCase()){
        case "weight": helperTxt = "lbs"; break;
        case "mph": helperTxt = "mph"; break;
        case "distance": helperTxt = "ft:in"; break;
        case "time": helperTxt = "min:sec.millisec"; break;
        case "shortesttime": helperTxt = "min:sec.millisec";
    }
    return helperTxt;
}

export function getResultDescriptionTxt(isGoal, scoreType, isSingular) {
    let additionalGoalTxt = isGoal ? "goal": "";
    let singularityTxt = isSingular ? 'an': "each"
    let helperTxt = `Enter a ${additionalGoalTxt||"score"} for${singularityTxt}athlete`;
    switch((scoreType||"").toLowerCase()){
        case 'participation': helperTxt = "Select who completed this participation activity"; break;
        case "weight": helperTxt = isGoal? `Enter the goal weight lifted for ${singularityTxt} athlete` :`Enter the weight lifted for ${singularityTxt} athlete`; break;
        case "mph": helperTxt = isGoal?  `Enter the goal mph for ${singularityTxt} athlete` : `Enter the mph for ${singularityTxt} athlete`; break;
        case "count": helperTxt = isGoal?  `Enter the goal count for ${singularityTxt} athlete` : `Enter the count completed for ${singularityTxt} athlete`; break;
        case "distance": helperTxt = `Enter a ${additionalGoalTxt} distance for ${singularityTxt} athlete`; break;
        case "time": helperTxt = `Enter a ${additionalGoalTxt} time for ${singularityTxt} athlete`; break;
        case "shortesttime": helperTxt = `Enter a ${additionalGoalTxt} time for ${singularityTxt} athlete`;
    }
    return helperTxt;
}

export function generateVideoLink(activity){
    let { videoType, videoId } = activity;
    //YouTube video:
    if (videoType === "youtube")
      return `https://www.youtube.com/watch?v=${videoId}`;

    // vimeo
    if (videoType === "vimeo")
      return `https://vimeo.com/${videoId}`;

    if (videoType === "wistia")
      return `https://fast.wistia.com/embed/iframe/${videoId}`;

    // attachment
    if (activity.videoType === "coach") {
      let url = activity.attachment
      const videoName = (activity.videoName || '').toLowerCase();
      if((videoName.endsWith('mov') || videoName.endsWith('mp4') || videoName.endsWith('m4v')) && (activity.legacyVideoId || activity.videoId)){
        url = `https://programax-videos-production.s3.amazonaws.com/videos/${activity.legacyVideoId || activity.videoId}/processed/${activity.videoName}`
      }
      return url
    } else {
      return activity.attachment;
    }
  }
