import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ProgressRing from '../../components/progressRing';
import classnames from 'classnames';

const styles = {
    progressContainer: {
        fontFamily: "Poppins",
        fontStyle: "normal"
    },
    progressInfo: {

    },
    stepDetails: {
        fontSize: "16px",
        lineHeight: "140%",
        letterSpacing: "0.01em",
        color: "#75797B",
        marginTop: "2px"
    },
    stepInfo: {
        color: "#0B0B0B",
        marginLeft: "8px",
        fontWeight: "600"
    },
    head: {
        color: "#0B0B0B",
        fontWeight: 600,
        fontSize: "26px",
        lineHeight: "120%"
    },
    progressDetails: {
        marginLeft: "12px",
        textAlign: "left"
    },
    progressRingContainer: {
        position: "relative",
        marginLeft: "30px"
    },
    progressInfo: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    progressPrimaryTxt: {
        fontWeight: "bold",
        fontSize: "10px",
        lineHeight: "120%",
        color: "#75797B"
    },
    progressSecondaryTxt: {
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "140%",
        fontWeight: "600",
        fontSize: "18px",
        color: "#1354F9"
    }
};

function ProgressStep(props) {
    const { classes, currentStep, stepDetails, totalSteps = 5} = props;
    const progressRingSize = "68";
    const progressInPercent = (currentStep/totalSteps) * 100;
    return (
        <div className={classnames("d-flex", classes.progressContainer)}>
            <div className={classes.progressRingContainer} style={{ width: `${progressRingSize}px`, height: `${progressRingSize}px` }}>
                <div className={classes.progressInfo}>
                    <div className={classes.progressPrimaryTxt}>
                        Step
                    </div>
                    <div className={classes.progressSecondaryTxt}>
                        {currentStep}/{totalSteps}
                    </div>
                </div>
                <ProgressRing
                    progress={progressInPercent}
                    size={progressRingSize}
                    strokeWidth={10}
                    circleOneStroke='#EDEFF0'
                    circleTwoStroke="#1354F9"
                />
            </div>
            <div className={classnames("d-flex flex-column justify-content-center",classes.progressDetails)}>
                <div className={classes.head}>{stepDetails.title}</div>
                {stepDetails.nextStep && <div className={classes.stepDetails}>Next Step:
                    <span className={classes.stepInfo}>
                        {stepDetails.nextStep}
                    </span>
                </div>
                }
            </div>
        </div>);
}

ProgressStep.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgressStep);