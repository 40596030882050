import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { getTenant, tenantMap } from '../../utils/commonUtil';
import sportsEngineLogo from "../../assets/images/themes/sportsengine/icon.png";
import { DownloadIcon, UpdateIcon } from '../../components/icons';
import classnames from 'classnames';
import { ROUTE_PATH } from '../../routeUtil';
import { PrimaryButton, OAuthButton, oAuthService } from 'maxone-react-ui';
import ThemeButton from '../../components/themeButton'
import { testId } from '../../utils/test-utils';
import { enableFullScreenLoader } from '../../actions/loading'
const styles = theme => ({
});

class AthleteImporter extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    handlefetchAthletes = () => { 
         this.props.fetchAthletes();
    }
    render() {
        const { classes, history, tenant, fetchAthletes } = this.props;
        return (
            (tenant != tenantMap.coachup && tenant != tenantMap.vnn) &&
            <div className={'ml-auto d-flex'}>
                <ThemeButton
                    width={"130px"}
                    textColor={"#ffffff"}
                    color={"#1354F9"}
                    height={"40px"}
                    theme="small"
                    iconName="Download"
                    onClick={() => { history.push(ROUTE_PATH.SE_IMPORT_CSV) }}
                    text={"CSV Import"}
                    {...testId(`import-csv-button`)}
                />
                {
                   tenant == tenantMap.se &&
                    <ThemeButton
                        width={"180px"}
                        textColor={"#ffffff"}
                        color={"#1354F9"}
                        height={"40px"}
                        theme="small"
                        iconName="Download"
                        onClick={() => { history.push(ROUTE_PATH.SE_IMPORT) }}
                        {...testId(`import-se-button`)}
                        text={"SportsEngine Import"}
                    />}
            </div>
        );
    }
}

export default withStyles(styles)(AthleteImporter);
