export const colors = {
    SUCCESS: "#27AE60",
    ERROR: "#F93F3F",
    BLACK: "#000000",
    WHITE: "#FFFFFF",
    PRIMARY: "#1354F9",
    WHITE_20: '#F8F8F8',
    GREY_10: "#EDEFF0",
    GREY_20: "#DDE2E5",
    GREY_30: "#CED3D6",
    GREY_40: "#A0A7AB",
    GREY_50: "#75797B",
    GREY_60: "#5B6062",
    GREY_70: '#3C3F40',
    GREY_80: "#27292A",
    GREY_90: "#0B0B0B",
    ORANGE: '#FFA800'
};

export const convertToRGB = (hex, opacity) => {
    const aRgbHex =
      hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));
    return `rgba(${aRgbHex[0]}, ${aRgbHex[1]}, ${aRgbHex[2]}, ${opacity})`
}