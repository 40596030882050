import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ProgressStep from '../progressStep';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import SportsEngineBtn from '../../../components/themeButton';
import RedoIcon from '../../../components/icons/redo';
import { userImportClient } from '../../../clients'
import { testId } from '../../../utils/test-utils';
const styles = {
    headerAdditionalInfo: {
        marginLeft: "110px",
        textAlign: "left",
        paddingBottom: "30px",
        marginTop: "10px",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#27292A",
        display:"flex"
    },
    subInfoHead: {
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginRight: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "98px",
        paddingLeft: "30px",
        paddingRight: "30px",
        maxWidth:"320px"
    },
    subInfoTxt: {
        textAlign: "right",
        color: "#27292A",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "120%",
        marginRight: "16px",
    },
    subInfoCnt: {
        fontWeight: "500",
        fontSize: "48px",
        lineHeight: "80%",
        color: "#222127"
    },
    serviceButtonContainer:{
        marginLeft:"78px"
    },
    rightBtn:{
        marginLeft:"16px"
    },
    section:{
        marginBottom:"38px"
    },
    redoContainer:{
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "120%",
        display: "flex",
        alignItems: "center",
        color: "#27292A",
        borderRadius: "5px",
        cursor:"pointer",
        "& svg":{
            width:"16px",
            height:"16px",
            fill:"#F93F3F",
            marginRight:"10px"
        }
    }
};

class ReviewAndInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:false
        }
    }

    sendInvite = async () => {
        let { goToNextStep, importProcessId, setImportInviteType } = this.props;
        this.setState({isLoading: true});
        try{
            await userImportClient.beginInviteByProcessId(importProcessId);
        }
        catch(e){
            console.log("Exception")
            console.log(e)
        }
        this.setState({isLoading: false});
        setImportInviteType(true)
        goToNextStep();
    }

    render() {
        const { classes, importAndInvite, discardAll, goToNextStep, athleteImported, groupOrTeamCount, isGroup, currentStep, totalSteps } = this.props;
        let { isLoading } = this.state;
        return (
            <React.Fragment>
                <HeaderWrapper>
                    <ProgressStep stepDetails={{
                        "title": " Review and Invite Athletes",
                    }} currentStep={currentStep} totalSteps={totalSteps} />
                    <div className={classes.headerAdditionalInfo}>
                        You made it! Your athletes are loaded and assigned to your team. You can instantly start all of your athletes to their assigned groups or choose to do so at a later time. Please review all the info below.
                    </div>
                    <div className={classes.headerAdditionalInfo}>
                        <div className={classes.subInfoHead}>
                            <span className={classes.subInfoTxt}>
                                Athletes imported from SportsEngine
                            </span>
                            <span className={classes.subInfoCnt}>
                                {athleteImported}
                            </span>
                        </div>
                        <div className={classes.subInfoHead}>
                            <span className={classes.subInfoTxt}>
                                Total {isGroup? "Groups":"Teams"} <br /> Created:
                            </span>
                            <span className={classes.subInfoCnt}>
                                {groupOrTeamCount}
                            </span>
                        </div>
                    </div>
                </HeaderWrapper>
                <BodyWrapper isLoading={isLoading} loadingMessage1={"Sending Invites"}>
                    <div className={classes.importInfo}>
                        <div className={classnames(classes.serviceButtonContainer,)}>
                            <div className={classnames(classes.section, "d-flex align-items-center justify-content-start")} {...testId(`import-invite-button-container`)}>
                                <SportsEngineBtn
                                    width={"416px"}
                                    height={"70px"}
                                    onClick={this.sendInvite}
                                    text="Import and invite ALL Athletes NOW"
                                    color={"#27AE60"}
                                    textColor={"#FFFFFF"}
                                />
                                <div className={classes.rightBtn}>
                                    <SportsEngineBtn
                                        width={"315px"}
                                        height={"70px"}
                                        onClick={goToNextStep}
                                        text="Import Only & invite Later"
                                        color={"#ffffff"}
                                        textColor={"#5B6062"}
                                    />
                                </div>
                            </div>
                            <div className={classnames(classes.redoContainer)} onClick={discardAll}>
                                <RedoIcon />
                                Discard and Start Over
                            </div>
                        </div>
                    </div>
                </BodyWrapper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ReviewAndInvite);