import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import vnnIcon from '../assets/images/themes/vnn/vnn-logo.png';
import vnnLongLogo from '../assets/images/themes/vnn/vnn-logo.png';
import homeImage from '../assets/images/themes/vnn/vnn-welcome.jpg';
import vnnM1PowerLogo from '../assets/images/themes/vnn/vnn-logo-powered-by-m1.png';
import vnnLoginLogo from '../assets/images/themes/vnn/vnn-logo.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'm1vnn',
    name: 'M1VNN',
    appUrl: 'vnn-m1://',
    domain: 'm1vnn.gomaxone.com',
    link: 'https://apps.apple.com/us/app/vnn-team-app/id1459170836',
    onAppleAppStore: 'https://apps.apple.com/us/app/vnn-team-app/id1459170836',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.gomaxone.m1mobile.m1vnn',
    segment: {
      coach: 'ZmK9YNOhuDzfOeHV4D2TPX7j2nSUIpCK'
    }
  },
  image: {
    home: homeImage,
    logo: vnnIcon,
    loginLogo: vnnM1PowerLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#02FF80"
    },
    secondary: {
      main: "#00E3FF"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  }

});
