import React, { Component } from 'react'
import './styles.css'
import ChipInput from '../../chipInput'
import { Modal } from '@material-ui/core'
import StyledButton from '../../styledButton'
import EmailIcon from '@material-ui/icons/Email'
import DeleteIcon from '@material-ui/icons/Delete'

export default class TagModal extends Component {

    render() {
        const { open, close, tags, saveTags, errorMessage, handleContinue, text, secondaryText, removeTag, handleNewTag, currentTeam } = this.props

        return(
            <div>
                <Modal open={open} onClose={close}>
                    <div className={'tagModalContainer'}>
                        <div className={'contentContainer'}>
                            <p className={'titleText'}>Add Tags</p>
                            {errorMessage ?  
                                <p className={'errorContentText'}>
                                    {errorMessage}
                                </p>
                            :
                                <p className={'contentText'}>
                                    Type the name of your desired tag and press 'Enter' to confirm.
                                    Add as many tags as you like.
                                </p>
                            }
                            <ChipInput 
                                currentTeam={currentTeam}
                                chips={tags} 
                                removeChip={tag => removeTag(tag)} 
                                handleNewChip={tag => handleNewTag(tag)} 
                            />
                        </div>
                        <div className={'bottomButtonContainer'}>
                            <StyledButton
                                onClick={close} 
                                title={'Discard'}
                                backgroundColor={'white'} 
                            />
                            <StyledButton
                                onClick={saveTags}   
                                title={'Save Tags'}
                                backgroundColor={'#27AE60'} 
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}