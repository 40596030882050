import React, { Component } from "react";
import YouTube from 'react-youtube';

class YouTubeVideo extends Component {

  constructor(props) {
      super(props);

      this.state = {
          videoId: null
      };
  }

  componentDidMount = async () => {
      this.setState({videoId: this.props.match.params.videoId})
      console.log('props', this.props)
  }

  render(){
    var { videoId } = this.state;
    const youtubeOpts = {
        volume: true,
        width: '100%',
        height: '100%',
    };
    console.log('Video Id = ', videoId)
    // var source = `//player.vimeo.com/video/${videoId}`
    // console.log('source ==', source)
    return(
      <div style={{height:"100vh", width:"100vw"}}>
      {
        videoId
        ?
          <YouTube
              opts={youtubeOpts}
              videoId={videoId} />
        :
        null
      }
      </div>
    )
  }

}

export default YouTubeVideo
