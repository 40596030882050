
import React, { Component } from "react";
import {
    Button,
    Grid,
    Modal
} from '@material-ui/core';
import { setNewCoachSession } from '../../actions/session'
import { connect } from 'react-redux'
import { getThemeByTenant } from '../../themes';
import ReactPlayer from 'react-player';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import LegacyModalContainer from '../modalContainer/legacyModalContainer';
const theme = getThemeByTenant();
const logo = theme.image.logo;

let style = {
    containerStyle : {
        height: 620,
        justifyContent: 'flex-start',
        padding: '25px 15px 40px 15px'
    }
}
class NewCoach extends Component {

    toggleModal = () => {
        this.props.setNewCoachSession(false)
    }

    getVideoURL = () => {
        const { tenant } = this.props;
        let url = ''
        switch (tenant) {
            case 'spellman':
                url = 'https://programax-videos-production.s3.amazonaws.com/public/uploads/spellman/Registration+Confirmation+-+Coach.mp4'
                return url
        }
    }

    render() {
        const { newCoachSession, classes } = this.props
        let url = this.getVideoURL()
        return (
            <LegacyModalContainer
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={newCoachSession}
                onClose={this.toggleModal}
                containerClassName={classnames("calendarPaper", classes.containerStyle)}
                >
                <Grid container alignItems="flex-start">
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img style={{ width: 100 }} src={logo} alt="logo" />
                        <p style={{ fontWeight: 100 }}>Thanks for creating your Spellman Performance coach account! Watch the video below to learn why we've created the app & how to get started.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginLeft: 15 }}>
                        <div className='playerWrapper'>
                            {url ? <ReactPlayer
                                controls={true}
                                className='react-player'
                                url={url}
                            /> : null}
                        </div>
                    </Grid>
                </Grid>
                <div className={"modalFooterContainer"} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        className={"cancelButton"}
                        onClick={this.toggleModal}>
                        Let's Go!
                    </Button>
                </div>
            </LegacyModalContainer>
        )
    }
}

const mapStateToProps = state => ({
    newCoachSession: state.session.newCoachSession,
});

export default withStyles(style)(connect(mapStateToProps, { setNewCoachSession })(NewCoach))