import { nylasCalendarClient } from "../clients";

export const SET_OFFSET = "SET_OFFSET";

export const SET_END_DATE = "SET_END_DATE";

export const SET_START_DATE = "SET_START_DATE";

export const SET_SELETED_TEAMS = "SET_SELETED_TEAMS";

export const GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_LOADING =
  "GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_LOADING";
export const GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_SUCCESS =
  "GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_SUCCESS";
export const GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_ERROR =
  "GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_ERROR";

export const GET_EVENT_BY_ID_LOADING = "GET_EVENT_BY_ID_LOADING";
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_ERROR = "GET_EVENT_BY_ID_ERROR";

export const GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_LOADING =
  "GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_LOADING";
export const GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_SUCCESS =
  "GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_SUCCESS";
export const GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_ERROR =
  "GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_ERROR";

export const setOffset = (offset) => async (dispatch) => {
  dispatch({ type: SET_OFFSET, offset });
};

export const setEndDate = (endDate) => async (dispatch) => {
  dispatch({ type: SET_END_DATE, endDate });
};

export const setStartDate = (startDate) => async (dispatch) => {
  dispatch({ type: SET_START_DATE, startDate });
};

export const setSelectedTeams = (teams) => async (dispatch) => {
  dispatch({ type: SET_SELETED_TEAMS, teams });
};

export const getEventsByTeamIdsAndUserId =
  ({
    organizationId,
    loggedInUserId,
    selectedTeams,
    startDate,
    offset = 0,
    limit = 100,
  }) =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_LOADING, data: { offset } });
        const { data } = await nylasCalendarClient.getEventsByTeamIdsAndUserId(
          organizationId,
          {
            userId: loggedInUserId,
            teamIds: selectedTeams,
            offset: offset * limit,
            startDate,
            limit,
          }
        );
        dispatch({ type: SET_OFFSET, offset: offset + 1 });
        dispatch({
          type: GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_SUCCESS,
          data: { data, offset },
        });
      } catch (e) {
        console.warn(`Error`, e);
        dispatch({ type: GET_EVENTS_BY_TEAM_IDS_AND_USER_ID_ERROR });
      }
    };

export const createEvent = async ({
  organizationId,
  title,
  location,
  message,
  startDate,
  endDate,
  userId,
  teamIds,
  scheduleId,
  participants,
  externalURL,
  attachments,
  days,
  until,
  object,
  eventType,
}) => {
  try {
    const { data } = await nylasCalendarClient.createEvent(organizationId, {
      userId,
      title,
      location,
      message,
      startDate,
      endDate,
      object,
      scheduleId,
      teamIds,
      participants,
      externalURL,
      eventType,
      attachments,
      days,
      until,
    });
    return data;
  } catch (e) {
    console.warn(`Error`, e);
  }
};

export const getEventById = (organizationId, eventId) => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENT_BY_ID_LOADING });
    const { data } = await nylasCalendarClient.getEventById(
      organizationId,
      eventId
    );
    dispatch({ type: GET_EVENT_BY_ID_SUCCESS, data });
  } catch (e) {
    console.warn(`Error`, e);
    dispatch({ type: GET_EVENT_BY_ID_ERROR });
  }
};

export const getRecurringEventInstanceByInstanceId =
  (organizationId, masterEventId, instanceId) => async (dispatch) => {
    try {
      dispatch({ type: GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_LOADING });
      const { data } =
        await nylasCalendarClient.getRecurringEventInstanceByInstanceId(
          organizationId,
          masterEventId,
          instanceId
        );
      dispatch({
        type: GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_SUCCESS,
        data,
      });
    } catch (e) {
      console.warn(`Error`, e);
      dispatch({ type: GET_RECURRING_EVENT_INSTANCE_BY_INSTANCE_ID_ERROR });
    }
  };

export const updateEvent = async ({
  organizationId,
  eventId,
  title,
  location,
  message,
  startDate,
  endDate,
  object,
  userId,
  teamIds,
  scheduleId,
  participants,
  externalURL,
  attachments,
  eventType,
  days,
  until,
  masterEventId,
  deletedRecurrenceInstanceIds,
}) => {
  try {
    const { data } = await nylasCalendarClient.updateEvent(
      organizationId,
      eventId,
      {
        userId,
        title,
        location,
        message,
        startDate,
        endDate,
        object,
        scheduleId,
        teamIds,
        participants,
        externalURL,
        attachments,
        eventType,
        days,
        until,
        masterEventId,
        deletedRecurrenceInstanceIds,
      }
    );
    return data;
  } catch (e) {
    console.warn(`Error`, e);
  }
};

export const deleteEventById = async (organizationId, eventId) => {
  try {
    const { data } = await nylasCalendarClient.deleteEventById(
      organizationId,
      eventId
    );
    return data;
  } catch (e) {
    console.warn(`Error`, e);
  }
};

export const cancelEventById = async (organizationId, eventId) => {
  try {
    const { data } = await nylasCalendarClient.cancelEventById(
      organizationId,
      eventId
    );
    return data;
  } catch (e) {
    console.warn(`Error`, e);
  }
};

export const sendRSVP = async (eventId, participantId, status) => {
  try {
    const { data } = await nylasCalendarClient.sendRSVP(
      eventId,
      {
        participantId, status
      }
    );
    return data;
  } catch (e) {
    console.warn(`Error`, e);
  }
};

export const sendEmailNotifications = async (
  organizationId,
  masterEventId,
  eventId,
  title
) => {
  try {
    const { data } = await nylasCalendarClient.sendEmailNotifications(
      organizationId,
      eventId,
      {
        title,
        masterEventId
      }
    );
    return data;
  } catch (e) {
    console.warn(`Error`, e);
  }
};
