import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from "lodash";
import "./profile.css";
import PropTypes from 'prop-types';
import { API, Auth, Storage } from "aws-amplify";
import { s3Upload } from "../../libs/awsLib";
import ContextService from '../../services/context';
import logo from "../../assets/images/brand/logo-dark@2x.png";
import { setUserContext, setAppContext } from '../../actions/session';
import { userImportClient, biabClient, authClient } from '../../clients'
import PasswordInput from '../../components/passwordInput';
import {
    Tab,
    Tabs,
    Grid,
    Paper,
    Modal,
    Button,
    Select,
    Divider,
    MenuItem,
    Snackbar,
    Checkbox,
    TextField,
    IconButton,
    Typography,
    InputLabel,
    FormControl,
    FormControlLabel, CircularProgress
} from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { WhiteLabelBIABClient } from 'maxone-api-client'
import { getEnvironment } from 'maxone-react-ui';
import { UserTransactionsScreen } from 'diamond-subscription';
import { getTenant } from '../../utils/commonUtil';
import { getLocalizedString } from "../../utils/locale-utils"
import BTSTabView from './components/BTSTabView';
import { serviceIntegrationClient } from '../../clients';

import MetaTags from 'react-meta-tags';

import ImageCropperModal from '../../components/modals/imageCropperModal'
import PageWrapper from '../nav/pageWrapper';
import { ROUTE_PATH } from "../../routeUtil";

const contextService = new ContextService()

const BTS_TAB_VALUE = 'BTS_TAB';

const styles = theme => ({
    saveButton: {
        width: 100,
        height: 40,
        backgroundColor: "#27AE60",
        color: theme.product.name == 'MaxOne' ? 'black' : theme.product.name == 'VNN' && !theme.palette.secondary.isDarkMode?'#000000':"#ffffff"
    },
    button: {
        width: 50
    },
    pageTitleWrapper: {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    pageTitle: {
        textAlign: 'left',
        marginLeft: 60
    },
    activitiesContainer: {
        paddingLeft: 40,
        paddingRight: 40
    }
});

function TabContainer({ children }) {
    return (
      <Typography component="div" style={{padding: 15}}>
        {children}
      </Typography>
    );
  }

class UserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabValue:0,
            user: {},
            open: false,
            fileName: "",
            password: null,
            currentRole: {},
            attachment: null,
            isLoading: true,
            oldPassword: null,
            linkedParents: [],
            isCropperModalOpen: false,
            invalidPhoneNumber: false,
            passwordConfirmation: null
        };
    }

    componentDidMount = async () => {
        const { currentUser, currentTeam, tenant } = this.props;
        let user = this.props.location.state && this.props.location.state.user ? this.props.location.state.user : null;
        const role = this.props.location.state && this.props.location.state.role ? this.props.location.state.role : null;
        let isImportedUser = !!this.props.location.state && this.props.location.state.isImportedUser;
        //transform user data
        if(isImportedUser){
            user.nameFirst = user.firstName;
            user.nameLast = user.lastName;
        }

        let currentRole = {}
        let linkedParents = []

        if(currentUser.avatarUrl && !currentUser.avatarUrl.includes("https://")){
          var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${currentUser.legacyId ? currentUser.legacyId : currentUser.id}/${currentUser.avatarUrl}`
          currentUser.avatarUrl = logo;
        }
        if(user && user.avatarUrl && !user.avatarUrl.includes("https://")){
          var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${user.legacyId ? user.legacyId : user.id}/${user.avatarUrl}`
          user.avatarUrl = logo;
        }
        if(user && !isImportedUser){
            const [guardians, userInfo, roles] = await Promise.all([this.getParents(user.id), this.getUser(user.id), this.getRoles(user.id)]);
            user = userInfo;
            if(guardians && guardians.length){
                await Promise.all(guardians.map(async guardian => {
                    if (guardian.userId != "null") {
                        const parent = await this.getUser(guardian.userId)
                        linkedParents.push(parent);
                    }
                }))
            }
            currentRole = _.find(roles, role => role.parentId == currentTeam.id)
        }
        this.setState({
            role: role,
            currentRole: currentRole,
            linkedParents: linkedParents,
            user: user ? user : currentUser,
            isImportedUser,
            isLoading:false
        });
    };

    getUser = (userId) => {
        return API.get("users", `/users/${userId}`);
    }

    saveRole = role => {
        return API.put("users", `/users/roles/${role.id}`, {
            body: role
        });
    }

    getParents = (userId) => {
        return API.get("users", `/users/${userId}/guardians`);
    }

    getRoles = userId => {
        return API.get("users", `/users/${userId}/roles`);
    }

    saveUser = user => {
        user.tenant = user.tenant || user.tenantId || this.props.tenant;
        return API.put("users", `/users/${user.id}`,{ body: user });
    }

    saveImportedUser = async () => {
        let { user } = this.state;
        let { id, nameFirst: firstName, nameLast:lastName, email, birthdate, phoneNumber, teamId } = user
        try {
            this.setState({
                open: true,
                message: `Updating ${user.nameFirst}`,
            });
            let { data } = await userImportClient.updateUserImportById(id, firstName, lastName, email, birthdate, phoneNumber, teamId);
            this.setState({
                open: true,
                message: `Successfully Updated ${user.nameFirst}`,
            });
        }
        catch(e){
            console.log("Exception")
            console.log(e);
        }
        this.hideSnackBar();
    }

    handleChange = name => e => {
        const { user } = this.state;
        user[name] = e.target.value;
        this.setState({ user: user });
    };

    handlePassword = name => e => { this.setState({ [name]: e.target.value }) }

    getCroppedAttachment = async (attachment) => {
        const { user } = this.state;
        const { currentUser } = this.props;
        user.avatarUrl = attachment;
        const savedUser = await this.saveUser(user);

        // Refresh image in UI
        let context = null;
        if(user.id == currentUser.id) context = await this.setSessionInfo(user.username);
        this.setState({ isCropperModalOpen: false });
    }

    handleFileChange = async(event) => {
        const { currentUser } = this.props;
        const idForS3 = currentUser.legacyId && currentUser.legacyId != 0 ? currentUser.legacyId : currentUser.id;
        this.setState({ isLoading: true, message: 'uploading your image...' })
        this.file = event.target.files[0];
        const fileStructure = `uploads/user/avatar/${idForS3}/`;
        const attachment = this.file ? await s3Upload(this.file, fileStructure) : null;

        this.setState({
            message: '',
            isLoading: false,
            isCropperModalOpen: true,
            attachment: attachment,
            fileName: this.file.name,
        })
    }

    toggleModal = () => {
        this.setState({ isCropperModalOpen: !this.state.isCropperModalOpen })
    }

    setSessionInfo = async (username) => {
        const {tenant} = this.props.currentTeam;
        const contextService = new ContextService();
        const {userContext, appContext} = await contextService.buildUserContext(username, tenant);
        this.props.setUserContext(userContext);
        this.props.setAppContext(appContext);
    }

    hideSnackBar(){
        setTimeout(() => this.setState({open:false}), 2000);
    }

    handleSaveUser = async () => {
        const { currentUser } = this.props;
        const { user, password, passwordConfirmation, oldPassword } = this.state;
        let strippedNumber = null;
        let strippedParentNumber = null;

        if(user.phoneNumber){
            strippedNumber = user.phoneNumber.replace(/[^0-9\.]+/g, '');
            let invalidChar = strippedNumber.match(/[^$,.\d]/)
            if(invalidChar && invalidChar.length)
                return this.setState({ invalidPhoneNumber: true, message: "invalid characters" })
            if(strippedNumber.length < 10)
                return this.setState({ invalidPhoneNumber: true, message: "did you forget an area code?" })
            if(strippedNumber.length > 12)
                return this.setState({ invalidPhoneNumber: true, message: "did you add too many numbers?" })
        }

        if(user.emergencyContactPhone){
            strippedParentNumber = user.emergencyContactPhone.replace(/[^0-9\.]+/g, '');
            let invalidChar = strippedParentNumber.match(/[^$,.\d]/)
            if(invalidChar && invalidChar.length)
                return this.setState({ invalidPhoneNumber: true, message: "invalid characters" })
            if(strippedParentNumber.length < 10)
                return this.setState({ invalidPhoneNumber: true, message: "did you forget an area code?" })
            if(strippedParentNumber.length > 12)
                return this.setState({ invalidPhoneNumber: true, message: "did you add too many numbers?" })
        }

        user.phoneNumber = strippedNumber;
        user.emergencyContactPhone = strippedParentNumber;

        if(password !== passwordConfirmation){
            this.setState({ open: true, message: "Oops, looks like your passwords don't match!" })
            return;
        }

        try {
            if(password && passwordConfirmation && password == passwordConfirmation){
                if(passwordConfirmation.length < 6) return this.setState({ open: true, message: 'Oops, your password needs to be at least 6 characters long' })
                const authenticatedUser = await Auth.currentAuthenticatedUser();
                const successfulPasswordChange = await Auth.changePassword(authenticatedUser, oldPassword, password);
            }
            let context = null;
            const request = await this.saveUser(user);
            if(user.id == currentUser.id) context = await this.setSessionInfo(user.username);
            this.setState({
                open: true,
                password: null,
                attachment: null,
                oldPassword: null,
                passwordConfirmation: null,
                message: `Successfully Updated ${user.nameFirst}`,
            });
            if(this.props.appContext.isAdmin) return this.setState({ open: true, message: "success!" })
            if(this.props.location && this.props.location.state && this.props.location.state.role == 'coach') return this.goBack();
            if(user.id != currentUser.id) return this.props.history.push('/athletes')
            this.hideSnackBar()
        }
        catch(e){
            console.log('e ', e)
            this.setState({ open: true, message: "Oops, we had a problem saving your profile!" })
        }
    }

    handleTabChange = async (event, tabValue) => {
        this.setState({tabValue});
        switch (tabValue) {
            case BTS_TAB_VALUE:
                await this.loadBTSContent();
                break;
            default:
                break;
        }
    };

    loadBTSContent = async () => {
        const { user: { id }, bts: { loading, data } = {} } = this.state;
        if (loading || data) {
            return;
        }
        this.setState({ bts: { loading: true, error: false } });
        try {
            const { data: services } = await serviceIntegrationClient.getLinkedServicesByUserId(id);
            this.setState({ bts: { loading: false, error: false, data: { services } } });
        } catch (e) {
            console.log(`Error loading BTS content for user ID ${id}`, e);
            this.setState({ bts: { loading: false, error: true } });
        }
    };

    createNewDate = year =>  {
        return new Date(Date.UTC(year | 0, 0, 0, 0, 0)).getFullYear();
    };

    handleToggleStaff = async () => {
        let currentRole = this.state.currentRole
        currentRole.isStaff = !currentRole.isStaff
        this.setState(currentRole)
        const savedRole = await this.saveRole(currentRole)
    }

    handleStaffPermissionsClick = () => {
        this.props.history.push('/settings', { tabValue: 2 })
    }

    getBIABDetails = async(tenant, teamId) => {
        try {
            return await biabClient.getConfigsByTeamId(tenant, teamId)
        } catch(e) {
            console.log("Failed getting BIAB response");
            console.log(e);
            return null;
        }
    }

    renderSubscriptionOptions = () => {
        const { user } = this.state;
        const { tenant } = this.props;
        return (
            <div className={'user-transactions-container'}>
                <UserTransactionsScreen tenant={tenant} userId={user.id} />
            </div>
        )
    };

    goBack = () => {
        const { isImportedUser, role} = this.state;
        this.props.history.goBack();
    };

    onResetPassword = async () => {
        const { tenant, userContext: { user: { id } } } = this.props;
        const { user } = this.state;
        this.setState({ passwordResetLoading: true, passwordResetError: false, passwordResetSuccess: false });
        try {
            await authClient.sendForgotPasswordByUsername(tenant, user.username, id);
            this.setState({ passwordResetLoading: false, passwordResetSuccess: true });
        } catch (e) {
            this.setState({ passwordResetLoading: false, passwordResetSuccess: false, passwordResetError: true });
        }
    };

    onBTSEditFieldClick = (field) => {
        const { bts } = this.state;
        const { data: { services: { results } } } = bts;
        const service = results.find(({ service }) => service === field);
        if (service) {
            service.editing = true;
            service.previousServiceUserId = service.serviceUserId;
        } else {
            results.push({ service: field, editing: true });
        }
        this.setState({ bts });
    };

    onBTSFieldChange = (field, value) => {
        const { bts } = this.state;
        const { data: { services: { results } } } = bts;
        results.find(({ service }) => service === field).serviceUserId = value;
        this.setState({ bts });
    };

    onBTSFieldSaveClick = async (field) => {
        const { bts, user: { id: userId } } = this.state;
        const { data: { services: { results } } } = bts;
        const service = results.find(({ service }) => service === field);
        const { id, serviceUserId, service: _service } = service;
        service.editing = false;
        service.loading = true;
        this.setState({ bts });
        try {
            if (id) {
                await serviceIntegrationClient.unlinkServiceUser(userId, _service);
            }
            await serviceIntegrationClient.linkServiceUser(userId, _service, serviceUserId);
        } catch (e) {
            console.log(`Error saving linked service user ${serviceUserId}`, e);
        } finally {
            service.loading = false;
            this.setState({ bts });
        }
    };

    onBTSFieldCancelClick = (field) => {
        const { bts } = this.state;
        const { data: { services: { results } } } = bts;
        const service = results.find(({ service }) => service === field);
        service.serviceUserId = service.previousServiceUserId;
        service.editing = false;
        this.setState({ bts });
    };

    render() {
        const { classes, appContext, userContext, currentTeam, tenant, organizations: { currentOrganizationId, byId: organizationsById } } = this.props;
        const { tabValue, isImportedUser, user, oldPassword, password, passwordConfirmation, attachment, isCropperModalOpen, fileName, role, isLoading, message, invalidPhoneNumber, currentRole, passwordResetLoading, passwordResetError, passwordResetSuccess, bts } = this.state;
        var year = new Date().getFullYear();
        const teams = (userContext.appContextList || []).filter(item => item.isTeam);
        const teamMenuData = (teams || []).map(item => {
            return {
                value: item.id,
                displayValue: item.name,
            }
        });
        var years = []; var pushedDate;
        for (var i = 0; i < 12; i++) {
            pushedDate = this.createNewDate(year);
            years.push(pushedDate);
            year++;
        }

        const isAthlete = (role || '').toUpperCase() === 'ATHLETE';
        const { services = [] } = organizationsById[currentOrganizationId] || {};
        const showBTSTab = isAthlete && (services.includes('RSPCT') || services.includes('KINEXON'));

        return (
            <PageWrapper title="User Profile" {...this.props} isPageLoading={isLoading} loadingMessage={message}>
            <div className={"userProfileOuterContainer"}>
                <MetaTags>
                    <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                    <meta http-equiv="Pragma" content="no-cache" />
                    <meta http-equiv="Expires" content="0" />
                </MetaTags>
                <div>
                    <ImageCropperModal
                        fileName={fileName}
                        attachment={attachment}
                        close={this.toggleModal}
                        open={isCropperModalOpen}
                        title={"Crop Image"}
                        getCroppedAttachment={this.getCroppedAttachment}
                    />
                    <Snackbar
                        open={this.state.open}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.message}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({open:false, message:""})}
                            >
                            <CloseIcon className={classes.icon} />
                            </IconButton>
                        ]}
                    />

                    <div className={"pageTitleContainer"} style={{height:70}}>
                        <h1 className={"pageTitle"}></h1>
                            <div className={"titleButtonContainer"}>
                                <Button
                                    type="submit"
                                    title="Cancel"
                                    className={"cancelButton"}
                                    onClick={this.goBack}>
                                    Back
                                </Button>
                                {!appContext.isStaff || (appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditUsers')) ?
                                    <Button
                                        type="submit"
                                        title="Save User"
                                        className={classes.saveButton}
                                        onClick={() => isImportedUser? this.saveImportedUser() :this.handleSaveUser()}>
                                        Save
                                    </Button>
                                :null}
                            </div>

                    </div>
                    <Paper>
                        <Grid
                            container
                            spacing={16}
                        >
                            <Grid item xs={12}>
                                <div style={{borderBottom:'1px solid lightgray'}}>
                                    <Tabs
                                        value={this.state.tabValue}
                                        onChange={this.handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                    >
                                        <Tab label="Profile" value={0}/>
                                        {role == 'athlete' && !isImportedUser && !currentTeam.isParentInfoHidden && !window.location.href.toLowerCase().includes('grdrive') &&  <Tab label="Parents" value={1} />}
                                        {!isImportedUser && appContext.isMaxPay && <Tab label={'Subscription Options'} value={3} />}
                                        {showBTSTab && <Tab label={'BTS Management'} value={BTS_TAB_VALUE} />}
                                    </Tabs>
                                </div>

                                {
                                    tabValue === 0 && <TabContainer>
                                        <Grid
                                        container
                                        spacing={16}
                                    >
                                    {!isImportedUser  &&  <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {
                                                    user.avatarUrl
                                                    ?
                                                    <img src={user.avatarUrl} style={{width:100, height:100, borderStyle: "dashed"}} />
                                                    :
                                                    <div>
                                                    {
                                                    this.state.attachment
                                                    ?
                                                    <div style={{width:100, height:100, marginLeft:5, background:'url(' + this.state.attachment+ ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', borderStyle: "dashed"}}>
                                                    </div>
                                                    :
                                                    <div style={{width:100, height:100, marginLeft:5, backgroundColor:"#EDEFF0", borderStyle: "dashed"}}>
                                                        <CameraAltIcon style={{marginTop:35}} />
                                                    </div>
                                                    }
                                                    </div>
                                                }
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 20 }}>
                                                    <h3 style={{textAlign:'left'}} className={"inputSectionTitle"}>Avatar Image</h3>
                                                    <div>
                                                        <input id="avatarInput" type="file" ref={(ref) => this.avatarInput = ref} onChange={this.handleFileChange} style={{ display: 'none' }} />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={(e) => {
                                                                if(role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')){
                                                                    return
                                                                }
                                                                this.avatarInput.click()
                                                            }}
                                                        >
                                                            Upload Image
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    }
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="nameFirst"
                                                margin={"dense"}
                                                label="First Name"
                                                className={"textField"}
                                                value={user.nameFirst ? user.nameFirst : ""}
                                                onChange={this.handleChange("nameFirst")}
                                                disabled={role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="nameLast"
                                                margin={"dense"}
                                                label="Last Name"
                                                className={"textField"}
                                                value={user.nameLast ? user.nameLast : ""}
                                                onChange={this.handleChange("nameLast")}
                                                disabled={role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                margin={"dense"}
                                                className={"textField"}
                                                value={user.email ? user.email : ""}
                                                onChange={this.handleChange("email")}
                                                disabled={window.location.href.toLowerCase().includes('vnn') || (role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers'))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                maxLength={12}
                                                id="phoneNumber"
                                                margin={"dense"}
                                                helperText={message}
                                                label="Phone Number"
                                                className={"textField"}
                                                error={invalidPhoneNumber}
                                                onChange={this.handleChange("phoneNumber")}
                                                value={user.phoneNumber ? user.phoneNumber : ""}
                                                disabled={role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        {isImportedUser  && (appContext.isOwner || appContext.isHeadcoach || appContext.isAdmin) &&
                                        <Grid item xs={12} md={6}>
                                            <FormControl className={"textField"}>
                                                <InputLabel htmlFor="graduationYear-simple">{`Athlete's Team`}</InputLabel>
                                                <Select
                                                    id="team"
                                                    className={"textField"}
                                                    style={{ textAlign: 'left' }}
                                                    onChange={this.handleChange("teamId")}
                                                    value={user.teamId || ""}
                                                    inputProps={{
                                                        name: 'teamId',
                                                        id: 'team-simple',
                                                    }}
                                                >
                                                    {(teams||[]).map(item => {
                                                        return <MenuItem style={{ textAlign: 'left' }} key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                        {!isImportedUser  &&   <Grid item xs={12} md={6}>
                                            <TextField
                                                id="username"
                                                label="Username"
                                                margin={"dense"}
                                                className={"textField"}
                                                onChange={this.handleChange("username")}
                                                value={user.username ? user.username : ""}
                                                disabled={!userContext.isImpersonating && !appContext.isAdmin}
                                                helperText={userContext.isImpersonating || appContext.isAdmin ? 'you will also need to update username on cognito' : ''}
                                            />
                                        </Grid> }
                                        {role == 'coach' && (appContext.isOwner || appContext.isHeadcoach || appContext.isAdmin) ?
                                            <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                                                <FormControlLabel
                                                    label="Staff Member"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={currentRole && !!currentRole.isStaff}
                                                            onChange={this.handleToggleStaff}
                                                        />
                                                    }
                                                />
                                                <p style={{ color: 'gray', fontSize: 9, marginTop: -15 }}>
                                                    making this user a staff member will restrict their access.
                                                </p>
                                            </Grid>
                                        :
                                            null
                                        }
                                        {role == "athlete" && !isImportedUser ? <Grid item xs={12} md={6} style={{ paddingTop: 13 }}>
                                            <FormControl className={"textField"}>
                                                <InputLabel htmlFor="graduationYear-simple">{`Athlete's Graduation Year`}</InputLabel>
                                                <Select
                                                    id="graduationYear"
                                                    className={"textField"}
                                                    style={{ textAlign: 'left' }}
                                                    onChange={this.handleChange("graduationYear")}
                                                    value={user.graduationYear ? parseInt(user.graduationYear) : ""}
                                                    inputProps={{
                                                        name: 'graduationYear',
                                                        id: 'graduationYear-simple',
                                                    }}
                                                    disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                                >
                                                    {years.map(year => {
                                                        return <MenuItem style={{ textAlign: 'left' }} key={year} value={year}>{year}</MenuItem>
                                                    })}
                                                </Select>
                                        </FormControl>
                                        </Grid>
                                        :
                                        <Grid item md={6} />
                                        }
                                        {user.evaluationQuestions && <Grid item xs={12} className={'evaluationOuterContainer'}>
                                            <div className={'evaluationContainer'}>
                                                <p className={'evaluationTitle'}>Short-Term Goals</p>
                                                <p className={'evaluationResponse'}>{user.evaluationQuestions.one}</p>
                                                <p className={'evaluationResponse'}>{user.evaluationQuestions.two}</p>
                                                <p className={'evaluationResponse'}>{user.evaluationQuestions.three}</p>
                                            </div>
                                        </Grid>}
                                        {user.evaluationQuestions && <Grid item xs={12} className={'evaluationOuterContainer'}>
                                            <div className={'evaluationContainer'}>
                                                <p className={'evaluationTitle'}>Long-Term Goals</p>
                                                <p className={'evaluationResponse'}>{user.evaluationQuestions.four}</p>
                                                <p className={'evaluationResponse'}>{user.evaluationQuestions.five}</p>
                                                <p className={'evaluationResponse'}>{user.evaluationQuestions.six}</p>
                                            </div>
                                        </Grid>}
                                    </Grid>
                                        {role === 'coach' && (
                                            <div style={{ textAlign: 'left', marginTop: 15 }}>
                                                {!passwordResetLoading && !passwordResetSuccess && !passwordResetError && (
                                                    <Button
                                                        variant={'contained'}
                                                        color={'primary'}
                                                        onClick={this.onResetPassword}
                                                    >
                                                        Send Password Reset
                                                    </Button>
                                                )}
                                                {passwordResetLoading && (
                                                    <CircularProgress />
                                                )}
                                                {passwordResetSuccess && (
                                                    <div className={'password-reset-message password-reset-success'}>
                                                        Successfully sent password reset notification via email!
                                                    </div>
                                                )}
                                                {passwordResetError && (
                                                    <div className={'password-reset-message password-reset-error'}>
                                                        There was an error sending the password reset. Please try again later.
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </TabContainer>
                                }

                                {tabValue === 2 && <TabContainer>
                                    <Grid
                                        container
                                        spacing={16}
                                    >
                                        <Grid item xs={12} sm={4}>
                                            <PasswordInput
                                                type="password"
                                                id="oldPassword"
                                                margin={"dense"}
                                                label="Old Password"
                                                className={"textField"}
                                                onChange={this.handlePassword("oldPassword")}
                                                value={oldPassword ? oldPassword : ""}
                                                disabled={role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <PasswordInput
                                                id="password"
                                                type="password"
                                                margin={"dense"}
                                                label={getLocalizedString("NEW_PASSWORD")}
                                                className={"textField"}
                                                onChange={this.handlePassword("password")}
                                                value={password ? password : ""}
                                                disabled={role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <PasswordInput
                                                type="password"
                                                margin={"dense"}
                                                className={"textField"}
                                                label="Confirm Password"
                                                id="passwordConfirmation"
                                                onChange={this.handlePassword("passwordConfirmation")}
                                                value={passwordConfirmation ? passwordConfirmation : ""}
                                                error={password && passwordConfirmation && password !== passwordConfirmation}
                                                disabled={role == 'coach' && appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabContainer>}
                                {tabValue === 3 && this.renderSubscriptionOptions()}
                                {tabValue === 1 && <TabContainer>
                                    {!window.location.href.toLowerCase().includes('vnn') && <Grid
                                        container
                                        spacing={16}
                                    >
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type="text"
                                                id="parentName"
                                                margin={"dense"}
                                                label="Emergency Contact Name"
                                                className={"textField"}
                                                onChange={this.handleChange("parentName")}
                                                value={user.parentName ? user.parentName : ""}
                                                disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="phone"
                                                type="phone"
                                                margin={"dense"}
                                                label="Emergency Phone"
                                                helperText={message}
                                                className={"textField"}
                                                error={invalidPhoneNumber}
                                                onChange={this.handleChange("emergencyContactPhone")}
                                                value={user.emergencyContactPhone ? user.emergencyContactPhone : ""}
                                                disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="text"
                                                id="parentEmail"
                                                label="Emergency Email"
                                                className={"textField"}
                                                onChange={this.handleChange("parentEmail")}
                                                value={user.parentEmail ? user.parentEmail : ""}
                                                disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                            />
                                        </Grid>
                                    </Grid>}
                                    {!!this.state.linkedParents.length &&
                                        <div style={{marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <h2 style={{fontSize: 18, fontWeight: 'bold'}}>Linked Parents</h2>
                                        </div>
                                    }
                                    {this.state.linkedParents.length ? this.state.linkedParents.map(parent => {
                                        return <Grid container spacing={16} key={parent.id}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    type="text"
                                                    id="parentName"
                                                    margin={"dense"}
                                                    label="First Name"
                                                    className={"textField"}
                                                    onChange={this.handleChange("parentName")}
                                                    value={parent.nameFirst ? parent.nameFirst : ""}
                                                    disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    type="text"
                                                    id="parentName"
                                                    margin={"dense"}
                                                    label="Last Name"
                                                    className={"textField"}
                                                    onChange={this.handleChange("parentName")}
                                                    value={parent.nameLast ? parent.nameLast : ""}
                                                    disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="phone"
                                                    type="phone"
                                                    margin={"dense"}
                                                    label="Parent Phone"
                                                    helperText={message}
                                                    className={"textField"}
                                                    error={invalidPhoneNumber}
                                                    onChange={this.handleChange("emergencyContactPhone")}
                                                    value={parent.phoneNumber ? parent.phoneNumber : ""}
                                                    disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    type="text"
                                                    id="parentEmail"
                                                    margin={"dense"}
                                                    label="Parent Email"
                                                    className={"textField"}
                                                    onChange={this.handleChange("parentEmail")}
                                                    value={parent.email ? parent.email : ""}
                                                    disabled={appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditUsers')}
                                                />
                                            </Grid>
                                        </Grid>
                                    })
                                    : null}
                                </TabContainer>}
                                {tabValue === BTS_TAB_VALUE && (
                                    <TabContainer>
                                        <BTSTabView
                                            {...bts}
                                            onEditClick={this.onBTSEditFieldClick}
                                            onChange={this.onBTSFieldChange}
                                            onSaveClick={this.onBTSFieldSaveClick}
                                            onCancelClick={this.onBTSFieldCancelClick}
                                        />
                                    </TabContainer>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
                <div style={{position:"absolute", bottom:0, right:10, fontSize:10, color:"gray"}}>V. 2.0.0</div>
            </div>
            </PageWrapper>
        );
    }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { organizations, users, session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
        organizations,
        users
    };
}

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
  });

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
