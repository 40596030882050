import { API } from "aws-amplify";

export async function getTeamWorkouts(teamId){
    return API.get('workouts', `/programs/${teamId}/workouts`);
}

export async function getWorkout(workoutId){
    return API.get('workouts', `/workouts/${workoutId}`)
}

export async function saveWorkout(workout){
    if(workout.id){
        return API.put('workouts', `/workouts/${workout.id}`, {
            body: workout
        });        
    }
    return API.post('workouts', '/workouts', {
        body: workout
    });
}

export async function deleteWorkout(workoutId){
    return API.del('workouts', `/workouts/${workoutId}`)
}

export async function createWorkoutActivity(workout) {
    return API.post("activities", "/workoutActivities", {
        body: workout
    });
}

