import React from 'react'
import BigCalendar from 'react-big-calendar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.less';
import classnames from 'classnames'
import moment from 'moment';
BigCalendar.momentLocalizer(moment);
const DraggableCalendar = withDragAndDrop(BigCalendar)

let styles = theme => ({
    rbcToolbar: {      
        boxSizing: "border-box",
        borderRadius: "8px 8px 0px 0px",
        display: "flex",
        padding: "10px",
        alignItems: "center",
        marginBottom:"14px"
    },
    rbcBtnGroup: {
        height: "40px",
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "4px",
        padding: "0px 16px",
        display: "flex",
        alignItems: "center",     
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#75797B",
        cursor: "pointer",
        fontWeight: "900",
        textTransform: "uppercase",
        marginRight: "20px"
    },
    arrowBtn: {
        padding: "0px 10px",
        marginRight: "10px"
    },
    rbcToolbarLabel: {
        marginLeft: "16px",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "120%",
        color: "#5B6062"
    },
    todayBtn:{
        backgroundColor: "#1354F9",
        color:"#ffffff"
    }
})

export let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

class CustomToolbar extends React.Component {
    render() {
        let { date, classes } = this.props
        return (
            <div className={classes.rbcToolbar}>
                <div id="calTodayNav" className={classnames(classes.rbcBtnGroup, classes.todayBtn)} onClick={this.navigate.bind(null, navigate.TODAY)}>
                    Today
                </div>
                <div className={classnames(classes.rbcBtnGroup, classes.arrowBtn)}>
                    <ChevronLeftIcon id="calPrevNav" onClick={this.navigate.bind(null, navigate.PREVIOUS)} />
                </div>
                <div className={classnames(classes.rbcBtnGroup, classes.arrowBtn)}>
                    <ChevronRightIcon id="calForwNav" onClick={this.navigate.bind(null, navigate.NEXT)} />
                </div>
                <span className={classes.rbcToolbarLabel}>{date.toLocaleString('default', { month: 'long', year: "numeric" })}</span>
            </div>
        )
    }

    navigate = action => {
        this.props.onNavigate(action)
    }
}

const CustomDraggableCalendar = (props) => {    
    return (
        <DraggableCalendar
            components={{
                week: {
                    header: ({ date, localizer }) => localizer.format(date, 'ddd DD')
                },             
                toolbar: toolBarProps => (<CustomToolbar {...toolBarProps} classes={props.classes} />)
            }}        
           {...props}

        />
    )
}

export default withStyles(styles)(CustomDraggableCalendar);