import React, { Component } from 'react'

import StyledButton from '../../../../components/styledButton'

import MessageList from '../../components/messageList'
import ViewingMessage from '../../components/viewingMessage'
import EmptyListState from '../../components/emptyListState'
import { getActivity } from '../../../../services/activitiesService'

import {
    resolveMessageString,
    resolveMessageRecipients
} from '../../../../utils/messageUtils.js'
import { getLocalizedString } from '../../../../utils/locale-utils';

import './styles.css'

export default class SentScreen extends Component{
    constructor(props){
        super(props)
        this.state = {
            viewingMessage: null,
            viewingMessageString: '',
            viewingMessageRecipients: []
        }
    }

    handleMessageSelect = async message => {
        const string = await resolveMessageString(message.message)
        const allUsers = this.props.allUsers;
        const recipients = await resolveMessageRecipients(message.recipients, null, allUsers)
        if(message.activityId || (message.meta && message.meta.activityId)){
          try{
            const activityId = (message.meta && message.meta.activityId) ? message.meta.activityId : message.activityId;
            const activity = await getActivity(activityId)
            if(!message.meta){
              message.meta = {};
            }
            if(activity.name) message.meta.activityName = activity.name
          }
          catch(e){
            console.log('error ', e)
          }
        }
        this.setState({
            viewingMessage: message,
            viewingMessageString: string,
            viewingMessageRecipients: recipients
        })
    }

    render(){
        const {
            messages = [],
            onPressCompose,
            handleEditMessage,
            page,
            loadMessages,
            hasMoreMessages,
            allUsers
        } = this.props

        const {
            viewingMessage,
            viewingMessageString,
            viewingMessageRecipients
        } = this.state
        console.log('viewingMessage ', viewingMessage)
        return(
            <div className={'sentScreenOuterContainer'}>
                <div className={'sentTitleContainer'}>
                    <p className={'titleText'}> {getLocalizedString("SENT")} History</p>
                </div>
                {messages.length ?
                    <div className={'sentScreenInnerContainer'}>
                        <MessageList
                            sent
                            messages={messages}
                            viewingMessage={viewingMessage}
                            onClickMessage={message => this.handleMessageSelect(message)}
                            page={page}
                            loadMessages={loadMessages}
                            hasMoreMessages={hasMoreMessages}
                        />
                        <ViewingMessage
                            sent
                            message={viewingMessage}
                            string={viewingMessageString}
                            recipients={viewingMessageRecipients}
                            editMessage={m => handleEditMessage(m)}
                            emptyBody={'Select a sent message to view it'}
                        />
                    </div>
                :
                    <EmptyListState
                        title={'No Sent Messages'}
                        onPressCompose={onPressCompose}
                        body={'When you have Sent messages you will be able to find them here. Get started by composing a new message.'}
                    />
                }
            </div>
        )
    }
}
