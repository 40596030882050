import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { biabClient } from '../../clients'
import PageWrapper from '../nav/pageWrapper';
import { TeamSubscriptionDetailsScreen } from 'diamond-subscription';

const styles = theme => ({
    container:{
        background: "white",
        padding: "15px",
        borderRadius: "5px",
        marginTop: "15px"
    }
});



class SalesPage extends Component {
    constructor(props) {
        super(props),
        this.state = {          
        }
    }

    componentDidMount() {
    }


    render() {
        let { isLoading, } = this.state;
        let { classes, tenant, currentTeam } = this.props;
        return (
            <PageWrapper {...this.props} title={"Subscription Details"} >
                <div className={classes.container}>
                    <TeamSubscriptionDetailsScreen tenant={tenant} teamId={currentTeam.id}/>
               </div>
            </PageWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        userContext: userContext,
        appContext: appContext
    };
}

export default withStyles(styles)(connect(mapStateToProps)(SalesPage));