import React, { Component } from 'react'

import EditIcon from '@material-ui/icons/Edit'

import StyledButton from '../../../../components/styledButton'
import { getLocalizedString } from '../../../../utils/locale-utils'
import './styles.css'

export default class EmptyListState extends Component {
    render(){
        const { 
            body = '',
            title = '',
            onPressCompose
        } = this.props
        return(
            <div className={'emptyScreenInnerContainer'}>
                <div className={'emptyScreenContentContainer'}>
                    <p className={'titleText'}>{title}</p>
                    <p className={'bodyText'}>{body}</p>
                    <StyledButton 
                        icon={<EditIcon />}
                        onClick={onPressCompose}
                        backgroundColor={'#27AE60'}
                        title={getLocalizedString("COMPOSE_NEW_MESSAGE")}
                    />
                </div>
            </div>
        )
    }
}