import React, { Component, Fragment } from 'react'
import './styles.css'
import _ from 'lodash'
import IconButton from '../iconButton'
import TextButton from '../textButton'
import FilterOption from '../filterOption'
import BorderedInput from '../borderedInput'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import DraggableListItem from '../draggableListItem'
import { ClickAwayListener } from '@material-ui/core'
import { Droppable } from 'react-beautiful-dnd'
import FilterList from '@material-ui/icons/FilterList'
import classnames from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
const filterType = Object.freeze({TAG:1,CATEGORY:2});
class FilterableSideList extends Component{
    constructor(props){
        super(props);
        this.state = {
            searchString: '',
            showFilters: false,
            selectedCategoryFilterOptions: props.libraries.map(f => f.value),
            selectedTagFilters: [],
            showLoadMore: (props.start + props.limit) < props.totalActivities,
            selectedTagFiltersBasedOnSelectedCat:[],
            originalTagFiltersState:[],
            originalCategoryFilterOptionsState:props.libraries.map(f => f.value),
            applyFilterClicked:false
        }
    }

    resetFilters = (type, resetActiveFilters) => {
        const { libraries } = this.props
        const { selectedTagFilters } = this.state;
        let newState = {};
        switch(type){
            case filterType.TAG:newState = { selectedTagFilters: [], selectedTagFiltersBasedOnSelectedCat: [], selectedTagFilters:[] }; break;
            case filterType.CATEGORY: newState = { selectedCategoryFilterOptions: libraries.map(f => f.value), selectedTagFiltersBasedOnSelectedCat:  selectedTagFilters}; break;
            default: newState = { searchString:"", selectedCategoryFilterOptions: libraries.map(f => f.value), selectedTagFilters: [], selectedTagFiltersBasedOnSelectedCat:[], selectedTagFilters:[] }
        }
        this.setState(newState,()=>{
            if(resetActiveFilters){
                this.applyFilters();
            }
        });
    }

    closeFilters = () => {
        const {originalTagFiltersState, originalCategoryFilterOptionsState} = this.state;
        this.setState({ showFilters: false })
        this.props.toggleOverlay(false);
        //reset original filter state
        this.setState({
            selectedTagFiltersBasedOnSelectedCat:originalTagFiltersState,
            selectedTagFilters: originalTagFiltersState,
            selectedCategoryFilterOptions: originalCategoryFilterOptionsState
        })
    }

    toggleShowFilters = () => {
        const { showFilters } = this.state
        if(!showFilters){
            this.props.toggleOverlay(true);
        }
        this.setState({ showFilters: !showFilters })
    }

    handleFilterSelect = (filter, type=filterType.CATEGORY) => {
        let { selectedCategoryFilterOptions, selectedTagFilters } = this.state;
        const { tagsWithCategoryFilter } = this.props;
        const filterValue = type == filterType.TAG ? selectedTagFilters: selectedCategoryFilterOptions
        let present = filterValue.some(f => f == filter)
        let newSelectedFilterOptions = present ? _.without(filterValue, filter) : filterValue.concat([filter])
        this.setState({[type == filterType.TAG? 'selectedTagFilters' : 'selectedCategoryFilterOptions']: newSelectedFilterOptions},()=>{
            let { selectedCategoryFilterOptions, selectedTagFilters } = this.state;
            let selectedTagFiltersBasedOnSelectedCat = selectedTagFilters.filter(selectedTag =>
                _.intersection(Array.from(tagsWithCategoryFilter[selectedTag]),selectedCategoryFilterOptions).length != 0
            );
            this.setState({selectedTagFiltersBasedOnSelectedCat});
        })
    }

    applyFilters = () => {
        const { selectedCategoryFilterOptions, selectedTagFiltersBasedOnSelectedCat, searchString } = this.state
        const { filterSelect, search, libraries } = this.props
        filterSelect((selectedCategoryFilterOptions||[]).length != (libraries||[]).length? selectedCategoryFilterOptions : null, selectedTagFiltersBasedOnSelectedCat, searchString);
        this.setState({selectedTagFilters: selectedTagFiltersBasedOnSelectedCat,
            originalTagFiltersState:selectedTagFiltersBasedOnSelectedCat,
            originalCategoryFilterOptionsState:selectedCategoryFilterOptions, applyFilterClicked:true
        },()=> this.closeFilters())
    }

    handleSearch = event => {
        const { search, totalActivities, start, limit } = this.props
        this.setState({ searchString: event.target.value })
        search(event)
    }

    handleSelectItem = (index, item) => {
      const { selectItem } = this.props;
      selectItem(index, item)
    }

    render(){
        const { showFilters, selectedCategoryFilterOptions, selectedTagFilters, searchString, selectedTagFiltersBasedOnSelectedCat, applyFilterClicked } = this.state
        const { libraries, items, currentTeam, filteredItems, title, onDragEnd, search, loadMoreActivities, start, limit, totalActivities, selectedIndex, editItem, tagsWithCategoryFilter } = this.props
        const activeCategoryFilterCount = selectedCategoryFilterOptions.length;
        const hasActiveCategoryFilter = selectedCategoryFilterOptions.length != libraries.length? true:false;
        const hasActiveFilters = selectedTagFiltersBasedOnSelectedCat.length || hasActiveCategoryFilter;
        return(
            <ClickAwayListener onClickAway={this.closeFilters}>
                {showFilters && <div onClick={this.closeFilters} className="filterOverlay" />}
                <div className={classnames('filterableListOuterContainer',{'appliedFilterTextActive':applyFilterClicked})}>
                    <div className={classnames('filterOuterContainer',{'filterMenuActive':showFilters})}>
                        <div className={'filterBoxShadowContainer'}>
                            <div className={classnames('filterBarContainer',{active: hasActiveFilters})}>
                                <BorderedInput
                                    handleClick={null}
                                    icon={<SearchIcon className="searchIcon"/>}
                                    value={searchString}
                                    onChange={this.handleSearch}
                                    placeholder={`Search Activities (${totalActivities ? totalActivities : filteredItems.length})`}
                                    onAdornmentClick={!showFilters?this.toggleShowFilters:()=>this.handleSearch({
                                        target:{
                                            value:''
                                        }
                                    })}
                                    adornment={!showFilters?
                                    <div className={'adornmentContainer'}><FilterList className={'icon'} /><p>FILTER</p></div>
                                    :
                                    <CancelIcon className={"cancelIcon"} />
                                    }
                                />
                                {applyFilterClicked && !showFilters ?
                                    <div className="filterInfoContainer">
                                        <span onClick={()=>this.resetFilters(null,true)} className={'resetTxt'}>{hasActiveFilters ? "Reset ALL Filters" :""}</span>
                                        <p className={'filterInfo'}>{`${(hasActiveCategoryFilter || applyFilterClicked) ?`${activeCategoryFilterCount == libraries.length ? "All ": activeCategoryFilterCount} ${activeCategoryFilterCount == 1?'Category':'Categories'} ${selectedTagFiltersBasedOnSelectedCat.length ? ',':''}`:''} ${selectedTagFiltersBasedOnSelectedCat.length? `${selectedTagFiltersBasedOnSelectedCat.length} ${selectedTagFiltersBasedOnSelectedCat.length == 1?'Tag':'Tags'}`:''} Applied`}</p>
                                    </div> : null
                                }
                            </div>
                            {showFilters &&
                                <>
                                    <div className={'filtersContainer'}>
                                        <div className={'filtersInnerContainer'}>
                                            <div className={'filterTitleContainer'}>
                                                <span className={'filterTitle'}>{title}</span>
                                                {hasActiveCategoryFilter &&
                                                    <span className={'actionButton active'} onClick={() => { this.resetFilters(filterType.CATEGORY) }}>Select ALL</span>
                                                }
                                            </div>
                                            {libraries.filter(item => item.value).map(filter => {
                                                return <FilterOption details={`(${items.filter(item => (item.category || "").toLowerCase() == (filter.value || "").toLowerCase()).length})`} checked={!!selectedCategoryFilterOptions.find(f => f == filter.value)} onClick={() => this.handleFilterSelect(filter.value)} title={filter.name} />
                                            })}
                                        </div>

                                        <div className={'filtersInnerContainer'}>
                                            <div className={'filterTitleContainer'}>
                                                <span className={'filterTitle'}>Filter by Tag{selectedTagFiltersBasedOnSelectedCat.length ? <span className="selectedTag">&nbsp;-&nbsp;{selectedTagFiltersBasedOnSelectedCat.length} Selected</span> : ''}</span>
                                                <span onClick={() => { this.resetFilters(filterType.TAG) }} className={classnames('actionButton', { active: !!selectedTagFiltersBasedOnSelectedCat.length })}>Reset</span>
                                            </div>
                                            <div className="tagListing">
                                                {tagsWithCategoryFilter && Object.keys(tagsWithCategoryFilter).map(tag => {
                                                    if (selectedCategoryFilterOptions.some(f => tagsWithCategoryFilter[tag].has(f)))
                                                        return <div
                                                            onClick={() => this.handleFilterSelect(tag, filterType.TAG)}
                                                            className={classnames('tag', { active: selectedTagFilters.includes(tag) })}>{tag || "Untagged"}
                                                        </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'lowerButtonContainer'}>
                                        <span onClick={this.resetFilters} className={'text'}>Reset ALL Filters</span>
                                        <IconButton
                                            position={'left'}
                                            color={'#1354F9'}
                                            text={'APPLY FILTERS'}
                                            icon={<CheckIcon style={{ color: 'white' }} />}
                                            onClick={() => { this.applyFilters(); }}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className={'itemsContainer'}>
                    <Droppable isCombineEnabled={true} droppableId={'activityList'}>
                    {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {filteredItems.filter(i => i && i.id).map((item, index) => {
                                return (
                                    <DraggableListItem
                                        key={item.id}
                                        index={index}
                                        listItem={item}
                                        title={item.name}
                                        editItem={editItem}
                                        currentTeam={currentTeam}
                                        isSelected={selectedIndex === index}
                                        selectItem={(index, item)=>this.handleSelectItem(index, item)}
                                    />
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                    {
                      (((start - limit) + limit) < totalActivities)  ? // Start automatically adds limit before getting to this page.
                      <div className={'loadMoreContainer'}>
                          <TextButton title={"Load More Activities"} textColor={"gray"} onClick={loadMoreActivities}/>
                      </div> : null
                    }
                    </div>
                </div>
            </ClickAwayListener>
        )
    }
}


const mapStateToProps = (state) => {
    const { session: { appContext } } = state;
    return {
        libraries: appContext.libraries||[]
    };
}

export default connect(mapStateToProps)(FilterableSideList);
