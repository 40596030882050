import React, { Component, Linking, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Calendar, { Views, Navigate } from 'react-big-calendar'
import { connect } from 'react-redux';

import classnames from 'classnames'
import BodyWrapper from "../../components/bodyWrapper";
import './workoutInsightsStyles.css'
import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { workoutClient } from '../../clients'
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Modal } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTeamLogo } from '../../utils/commonUtil'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ImgWithFallBack from '../../components/imgWithFallback'
const localizer = Calendar.momentLocalizer(moment)

let styles = {
    rbcToolbar: {
        background: "#F8F8F8",
        boxSizing: "border-box",
        borderRadius: "8px 8px 0px 0px",
        height: "52px",
        display: "flex",
        padding: "10px",
        alignItems: "center"
    },
    rbcBtnGroup: {
        height: "32px",
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "4px",
        padding: "0px 16px",
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#75797B",
        cursor: "pointer"
    },
    arrowBtn: {
        padding: "0px 10px",
    },
    rbcToolbarLabel: {
        marginLeft: "16px",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "120%",
        color: "#5B6062"
    },
    eventName: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        color: "#0B0B0B",
        whiteSpace: "normal",
        maxHeight: "34px",
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    eventSubInfo: {
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "120%",
        color: "#A0A7AB",
        marginBottom: "7px"
    },
    eventProgress: {
        display: "flex",
        '& > div:first-child': {
            marginRight: "12px"
        }
    },
    progressContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    progressValue: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "100%",
        textAlign: "center",
        color: "#0B0B0B"
    },
    progressHead: {
        marginTop: "2px",
        color: "#A0A7AB",
        fontWeight: "bold",
        fontSize: "10px"
    },
    eventStatusColorContainer: {
        minWidth: "4px",
        minHeight: "100%"
    },
    eventSubContainer: {
        padding: "6px 8px",
        width: "100%",
        minHeight: "91px"
    },
    workoutInsight: {
        width: "100%",
        padding: "0 20px",
        paddingRight: "0px"
    },
    statusBox: {
        padding: "4px 8px",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "12px",
        color: "#FFFFFF",
        borderRadius: "20px"
    },
    userInfo: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        "& tr": {
            background: "#ffffff",
            height: "52px",
            "& th": {
                padding: "11px 0px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "14px",
                color: "#A0A7AB"
            }
        },
        "& tr th,tr td": {
            borderBottom: "1px solid #DDE2E5",
            maxWidth: "100px",
            paddingRight: "10px",
            overflow: "hidden",
            wordBreak: "break-all"
        },
        "& tr th:first-child,tr td:first-child": {
            paddingLeft: "16px",
            paddingRight: "16px"
        },
        "& tr th:last-child,tr td:last-child": {
            paddingRight: "16px"
        },
        "& tr th": {
            textAlign: "left"
        }
    },
    workoutDetailContainer: {
        width: "800px",
        height: "630px",
        background: "#F8F8F8",
        borderRadius: "8px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 70%)",
        transition: "300ms",
        position: "fixed",
        position: "relative",
        '&.active': {
            transform: "translate(-50%, -50%)"
        }
    },
    topSection: {
        padding: "30px",
        height: "150px",
        background: "#ffffff",
        paddingBottom: "20px",
        borderRadius: "7px 7px 0px 0px"
    },
    bottomSection: {
        padding: "30px",
        borderRadius: "0px 0px 7px 7px",
        paddingTop: "0px",
        height: "calc(100% - 150px)",
        overflow: "scroll",
        '&.isLoading': {
            height: "calc(100% - 150px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    },
    workoutName: {
        fontWeight: "600",
        fontSize: "26px",
        lineHeight: "120%",
        color: "#222127"
    },
    workoutNamehead: {
        marginBottom: "20px"
    },
    summaryBox: {
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginRight: "8px",
        fontWeight: "600",
        fontSize: "17px",
        lineHeight: "100%",
        color: "#3C3F40",
        textAlign: 'center',
        padding: "8px 12px",
        filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06))"
    },
    boxHead: {
        fontSize: "12px",
        lineHeight: "100%",
        color: "#A0A7AB",
        marginBottom: "4px"
    },
    groupDetails: {
        paddingBottom: "10px"
    },
    subHead: {
        marginTop: "20px",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "120%",
        color: "#222127",
        marginBottom: "10px"
    },
    activityDetailList: {
        marginLeft: "-16px",
        flexWrap: "wrap"
    },
    activityDetail: {
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "4px",
        marginLeft: "16px",
        width: "calc(50% - 16px)",
        height: "35px",
        minHeight: "35px",
        marginBottom: "8px"
    },
    activityImgContainer: {
        width: "35px",
        height: "35px",
        overflow: "hidden",
        borderRadius: "4px 0px 0px 4px"
    },
    activityImg: {
        width: "100%",
        height: "100%"
    },
    activityName: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#222127",
        lineHeight: "35px",
        marginLeft: "8px"
    },
    workoutSummary: {
        display: "flex"
    },
    progressCircle: {
        padding: "4px 5px",
        minWidth: "20px",
        padding: "0px 5px",
        height: "28px",
        background: "#DDE2E5",
        borderRadius: "20px",
        marginLeft: "auto",
        marginRight: "4px",
        textAlign: "center",
        color: 'white',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    athleteName: {
        minWidth: "320px"
    },
    athleteImgBox: {
        marginRight: "8px",
        minWidth: "36px",
        minHeight: "36px",
        maxWidth: "36px",
        maxHeight: "36px",
        borderRadius: "50%",
        border: "2px solid #DDE2E5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        overflow: "hidden",
        "& img": {
            width: "100%",
            height: "100%"
        },
        "& svg":{
            fill:"#DDE2E5"
        }
    },
    userPercent: {
        textAlign: "center !important"
    },
    athleteDetails: {
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px 8px 0px 0px",
        overflow: "hidden"
    },
    viewMoreBtn: {
        background: "#1354F9",
        borderRadius: "4px",
        padding: "10px 22px",
        width: "100%",
        color: "#ffffff",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "120%",
        textAlign: "center",
        display: "none"
    },
    nameSection: {
        display: "flex",
        alignItems: "center"
    },
    eventContainer: {
        "&:hover": {
            "& .eventStatusColor": {
                background: "#1354F9 !important"
            },
            "& .eventProgressCnt": {
                display: "none"
            },
            "& .eventViewMoreBtn": {
                display: "block"
            },
            "& .wrktEventName": {
                color: "#1354F9"
            }
        }
    },
    activityCircle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg":{
            fill:"#DDE2E5",
            width:"13px",
            height:"13px",
            marginRight:"2px"
        }
    }
};

export let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}
function Event({ event, classes, onClick }) {
    let completedPercent = Math.round((event.completed / event.assigned) * 100) || 0;
    let color = getColorFromValue(completedPercent);
    return (
        <div className={classnames("d-flex", classes.eventContainer)} onClick={() => onClick(event.id)}>
            <div style={{ background: color }} className={classnames(classes.eventStatusColorContainer, "eventStatusColor")}></div>
            <div className={classes.eventSubContainer}>
                <div className={classnames(classes.eventName, "wrktEventName")}>{event.name}</div>
                <div className={classnames("text-truncate", classes.eventSubInfo)}>{event.scheduleGroupName}</div>
                <div className={classnames(classes.eventProgress, "eventProgressCnt")}>
                    <div className={classes.progressContainer}>
                        <div className={classes.progressValue}>
                            {completedPercent}%
                    </div>
                        <div className={classes.progressHead}>
                            COMPLETE
                    </div>
                    </div>
                    <div className={classes.progressContainer}>
                        <div className={classes.progressValue}>
                            {event.started}/{event.assigned}
                        </div>
                        <div className={classes.progressHead}>
                            STARTED
                    </div>
                    </div>
                </div>
                <div className={classnames(classes.viewMoreBtn, "eventViewMoreBtn")}>
                    View More
                </div>
            </div>
        </div>
    )
}


class CustomToolbar extends React.Component {
    render() {
        let { date, classes } = this.props
        return (
            <div className={classes.rbcToolbar}>
                <div id="calTodayNav" className={classes.rbcBtnGroup} onClick={this.navigate.bind(null, navigate.TODAY)}>
                    Today
                </div>
                <div className={classnames(classes.rbcBtnGroup, classes.arrowBtn)} onClick={this.navigate.bind(null, navigate.PREVIOUS)}>
                    <ChevronLeftIcon id="calPrevNav" />
                </div>
                <div className={classnames(classes.rbcBtnGroup, classes.arrowBtn)} onClick={this.navigate.bind(null, navigate.NEXT)} >
                    <ChevronRightIcon id="calForwNav"/>
                </div>
                <span className={classes.rbcToolbarLabel}>{date.toLocaleString('default', { month: 'long', year: "numeric" })}</span>
            </div>
        )
    }
    navigate = action => {
        let { fetchData } = this.props;
        if (action == navigate.PREVIOUS) {
            fetchData(-7);
        }
        else if (action == navigate.NEXT) {
            fetchData(7);
        }
        else {
            fetchData(0);
        }
        this.props.onNavigate(action)
    }
}

class WorkoutInsights extends Component {
    constructor(props) {
        super(props);
        this.fields = [
            {
                displayName: "Athlete",
                value: "name"
            },
            {
                displayName: "Status",
                value: "status"
            },
            {
                displayName: "% Complete",
                value: "completionPercent"
            }
        ];
        this.state = {
            events: [],
            currentViewingWeekStartDate: moment().startOf('week'),
            isLoading: true,
            isWorkoutDetailModalOpen: false,
            activateWorkoutDetailBox: false,
            loadingWorkoutDetailData: true,
            selectedWorkoutScheduleDetails: {}
        }
    }

    componentDidMount() {
        this.fetchWorkouts(0);
    }

    fetchWorkouts = async (weekDateBuffer) => {
        let { currentTeam } = this.props;
        let { currentViewingWeekStartDate } = this.state;
        let newStartDateForAWeek = currentViewingWeekStartDate.clone();
        if (weekDateBuffer) {
            newStartDateForAWeek.add(weekDateBuffer, 'days');
        }
        else {
            const currentWeekStartDate = moment();
            newStartDateForAWeek = currentWeekStartDate.startOf('week');
        }
        const from_date = newStartDateForAWeek.startOf('week').format();
        const to_date = newStartDateForAWeek.endOf('week').format();
        this.setState({ currentViewingWeekStartDate: newStartDateForAWeek });

        if (this.navigationDataFetchTimeout) {
            clearTimeout(this.navigationDataFetchTimeout);
        }
        this.navigationDataFetchTimeout = setTimeout(async () => {
            this.setState({ isLoading: true });
            try {
                let computedData = [];
                let { data } = await workoutClient.getScheduledWorkoutsByTeamId(currentTeam.id, { startDate: from_date, endDate: to_date });
                data.results.forEach((schedule) => {
                    let start = moment(schedule.assignedDate)
                    computedData.push({ ...schedule, name: schedule.title, start: start, end: start, color: schedule.color, allDay: 'true' })
                })
                this.setState({ events: computedData });
            }
            catch (e) {
                console.log("Exception");
                console.log(e);
            }
            this.setState({ isLoading: false });
        }, weekDateBuffer === 0 ? 0 : 500);
    }

    toggleWorkoutDetailModal = (selectedWorkoutScheduleDetails) => {
        let { isWorkoutDetailModalOpen } = this.state;
        if (!isWorkoutDetailModalOpen) {
            let computedData = {};
            this.setState({ isWorkoutDetailModalOpen: !isWorkoutDetailModalOpen, loadingWorkoutDetailData: true, selectedWorkoutScheduleDetails });
            workoutClient.getWorkoutAssignmentDataById(selectedWorkoutScheduleDetails.id).then((data) => {
                const computedActivities = (data.activities || []).map(item => { return { ...item, activityCompletedPercent: Math.round((item.complete / item.assigned) * 100) } });
                computedData = { ...selectedWorkoutScheduleDetails, ...data,  activities: computedActivities };
            }).catch((e) => {
                console.log("exception")
                console.log(e)
            }).finally(() => {
                this.setState({ loadingWorkoutDetailData: false, selectedWorkoutScheduleDetails: computedData });
            });

            setTimeout(() => { this.setState({ activateWorkoutDetailBox: true }) }, 100)
        }
        else {
            this.setState({ activateWorkoutDetailBox: false, selectedWorkoutScheduleDetails: {} })
            setTimeout(() => { this.setState({ isWorkoutDetailModalOpen: !isWorkoutDetailModalOpen }) }, 400);
        }
    }

    render() {
        let { events, isLoading, isWorkoutDetailModalOpen, activateWorkoutDetailBox, loadingWorkoutDetailData, selectedWorkoutScheduleDetails = {} } = this.state;
        let { classes, currentTeam } = this.props;
        let workoutScheduleAthletes = selectedWorkoutScheduleDetails.athletes || []
        let workoutScheduleActivity = selectedWorkoutScheduleDetails.activities || [];
        const teamLogo = getTeamLogo(currentTeam)
        return (
            <div className={classnames(classes.workoutInsight, "workoutInsight")}>
                <BodyWrapper removePadding={true} isLoading={isLoading} showLoadingChild={true}>
                    <Calendar
                        views={['week']}
                        step={900}
                        events={events}
                        defaultView={Views.WEEK}
                        defaultDate={new Date()}
                        components={{
                            week: {
                                header: ({ date, localizer }) => localizer.format(date, 'ddd DD')
                            },
                            event: props => (<Event {...props} classes={classes} onClick={() => this.toggleWorkoutDetailModal(props.event)} />),
                            toolbar: props => (<CustomToolbar {...props} classes={classes} fetchData={this.fetchWorkouts} />)
                        }}
                    />
                    <Modal
                        open={isWorkoutDetailModalOpen}
                        onBackdropClick={() => this.toggleWorkoutDetailModal()}>
                        <div className={classnames(classes.workoutDetailContainer, { 'active': activateWorkoutDetailBox })}>
                            <div className={classes.topSection}>
                                <div className={classnames(classes.workoutNamehead, "d-flex justify-content-between")}>
                                    <div className={classes.workoutName}>
                                        {selectedWorkoutScheduleDetails.name} Details
                                            </div>
                                    <div className={classes.iconContainer} onClick={() => this.toggleWorkoutDetailModal()}>
                                        <CancelIcon className="icon" style={{ opacity: 0.3, cursor: "pointer" }} />
                                    </div>
                                </div>
                                <div className={classes.workoutSummary}>
                                    <div className={classes.summaryBox}>
                                        <div className={classes.boxHead}>
                                            DATE
                                                </div>
                                        {moment(selectedWorkoutScheduleDetails.assignedDate).format("MM/DD/YYYY")}
                                    </div>
                                    <div className={classes.summaryBox}>
                                        <div className={classes.boxHead}>
                                            ACTIVITIES
                                            </div>
                                        <span className={classes.activityCircle}>
                                            <PlayCircleFilledIcon className={classes.playIcon}/>
                                            {selectedWorkoutScheduleDetails.totalActivities}
                                        </span>
                                    </div>
                                    <div className={classes.summaryBox}>
                                        <div className={classes.boxHead}>
                                            COMPLETE
                                                </div>
                                        {Math.round((selectedWorkoutScheduleDetails.completed / selectedWorkoutScheduleDetails.assigned) * 100)}%
                                            </div>
                                    <div className={classes.summaryBox}>
                                        <div className={classes.boxHead}>
                                            STARTED
                                                </div>
                                        {selectedWorkoutScheduleDetails.started} of  {selectedWorkoutScheduleDetails.assigned}
                                    </div>
                                </div>
                            </div>
                            <div className={classnames(classes.bottomSection, { "isLoading": loadingWorkoutDetailData })}>
                                {loadingWorkoutDetailData ? <CircularProgress /> :
                                    <React.Fragment>
                                        <div className={classes.groupDetails}>
                                            <div className={classes.subHead}>
                                                {selectedWorkoutScheduleDetails.scheduleGroupName} Workout Status
                                        </div>
                                            <div className={classes.athleteDetails}>
                                                <table className={classes.userInfo}>
                                                    <thead>
                                                        <tr className={classes.tableHead}>
                                                            <th className={classnames(classes.athleteName)}>Athlete</th>
                                                            <th>Status</th>
                                                            <th className={classes.userPercent}>% Complete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className={classes.athleteList}>
                                                        {workoutScheduleAthletes.map((athlete, index) =>
                                                            <tr key={index}>
                                                                <td className={classnames(classes.td, classes.athleteName)}>
                                                                    <span className={classes.nameSection}>
                                                                        <span className={classes.athleteImgBox}>
                                                                            {athlete.image ?
                                                                                <img src={athlete.image} /> :
                                                                                <AccountCircle />
                                                                            }
                                                                        </span>
                                                                        <span>{`${athlete.firstName} ${athlete.lastName}`}</span>
                                                                    </span>
                                                                </td>
                                                                <td className={classnames(classes.td, classes.statusContainer)}>
                                                                    <span className={classes.statusBox} style={{
                                                                        background: getColorFromValue(athlete.status == "NOT STARTED" ? 0 :
                                                                            athlete.status == "COMPLETED" ? 67 : 33)
                                                                    }}>{athlete.status}</span>
                                                                </td>
                                                                <td className={classnames(classes.td, classes.userPercent)}>
                                                                    <span>{(athlete.status == "STARTED" || athlete.percentCompleted) ? `${athlete.percentCompleted||0}%` : "-"}</span>
                                                                </td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={classes.activityDetails}>
                                            <div className={classes.subHead}>
                                                Activity Completion Status
                                        </div>
                                            <div className={classnames(classes.activityDetailList, "d-flex")}>
                                                {workoutScheduleActivity.map((activity, index) =>
                                                    <div className={classnames(classes.activityDetail, "d-flex align-items-center")}>
                                                        <span className={classes.activityImgContainer}>
                                                            <ImgWithFallBack className={classes.activityImg} src={activity.thumbnail || teamLogo} fallbackSrc={teamLogo} />
                                                        </span>
                                                        <span className={classnames(classes.activityName, "text-truncate")}>
                                                            {activity.name}
                                                        </span>
                                                        <span style={{background: !!activity.activityCompletedPercent? getColorFromValue(activity.activityCompletedPercent): null}} className={classes.progressCircle}>
                                                            {activity.activityCompletedPercent ? (activity.activityCompletedPercent + "%"): "-"}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </Modal>
                </BodyWrapper>
            </div>
        );
    }
}

function getColorFromValue(value){
    return value > 66 ? "#27AE60" : value > 0 ? "#FFA800" : "#F93F3F";
}

function mapStateToProps(state) {
    const { session: { accessToken, userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        accessToken: accessToken,
        currentUser: userContext.user,
        currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(WorkoutInsights));