import React from "react";
import "./Pagination.css";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

let styles = {
  pagination: {
    padding: "30px 0",
    "& ul":{
      margin: 0,
      padding: 0,
      "list-style-type": "none"
    },
    "& a":{
      borderRadius: "4px",
      border: "1px solid #DDE2E5",
      marginRight: "4px",
      fontWeight: "bold",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "23px",
      textAlign: "center",
      background: "#FFFFFF",
      cursor: "pointer",
      display: "inline-block",
      padding: "10px 18px",
      color: "#222"
    },
    "& a:last-child":{     
      marginRight: "0px" 
    },
    "& .is-active":{
      background: "#1354F9",
      color: "#ffffff !important"
    }
  },
  ellpsis:{
    background:"rgb(255, 255, 255, 0.5) !important",
    cursor:"default !important"
  },
  disabled:{
    pointerEvent:"none !important",
    cursor:"default !important"
  }
}

class ReactPagination extends React.Component {
  state = {
    firstThreeArray: [1],
    lastNumber: "",
    showEllipis: true
  };

  componentDidMount() {
    if (this.props.totalPages <= 5) {
      var fArray = [];

      for (var i = 1; i <= this.props.totalPages; i++) {
        fArray.push(i);
      }

      this.setState({ firstThreeArray: fArray });
    } else {
      if (this.props.currentPage < 3) {
        this.setState({ firstThreeArray: [1, 2, 3] });
      } else {
        var fArray = [1];
        var index = 1;
        for (let j = this.props.currentPage; j >= 0; j--) {
          fArray.push(j);
          if (index === 3) {
            break;
          }
          index++;
        }

        fArray.reverse();
        this.setState({ firstThreeArray: fArray });
      }
      this.setState({ lastNumber: this.props.totalPages });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.totalPages <= 5) {
      var fArray = [];

      for (var i = 1; i <= nextProps.totalPages; i++) {
        fArray.push(i);
      }
      this.setState({ firstThreeArray: fArray });
    } else {
      if (
        this.props.currentPage !== nextProps.currentPage ||
        this.props.totalPages !== nextProps.totalPages
      ) {
        if (nextProps.currentPage < 3) {
          this.setState({ firstThreeArray: [1, 2, 3] });
        } else {
          var fArray = [];
          fArray.push(nextProps.currentPage - 1);
          fArray.push(nextProps.currentPage);
          if (nextProps.currentPage + 1 < nextProps.totalPages) {
            fArray.push(nextProps.currentPage + 1);
          }
          if (
            nextProps.currentPage == nextProps.totalPages - 2 ||
            nextProps.currentPage == nextProps.totalPages - 1 ||
            nextProps.currentPage == nextProps.totalPages
          ) {
            this.setState({ showEllipis: false });
          } else {
            this.setState({ showEllipis: true });
          }
          if(!fArray.includes(1)){
            fArray = [1].concat(fArray);
          }
          this.setState({ firstThreeArray: fArray });
        }
        this.setState({ lastNumber: nextProps.totalPages });
      }
    }
  }

  prev = () => {
    if (this.props.currentPage > 1) {
      this.props.changeCurrentPage(this.props.currentPage - 1);
    }
  };

  next = () => {
    if (this.props.currentPage < this.props.totalPages) {
      this.props.changeCurrentPage(this.props.currentPage + 1);
    }
  };

  changeCurrentPage = no => {
    this.props.changeCurrentPage(no);
  };

  showEllipsis = () => {
    const { classes } = this.props;
    if (this.state.showEllipis) {
      return (
        <a className={classes.ellpsis}>
          <li>...</li>
        </a>
      );
    }
  };

  isactive = currentPage => {
    if (this.props.currentPage == currentPage) {
      return true;
    }
    return false;
  };

  showLastPagi = () => {
    if (this.props.currentPage !== this.props.totalPages) {
      return (
        <a
          className={this.isactive(this.props.totalPages) ? "is-active" : ""}
          onClick={() => {
            this.changeCurrentPage(this.props.totalPages);
          }}
        >
          <li>{this.props.totalPages}</li>
        </a>
      );
    }
  };

  showPrev = () => {
    const { classes, currentPage } = this.props;
    const isDisabled = currentPage == 1;
      return (
        <a className={classnames({[classes.disabled]: isDisabled})} onClick={this.prev}>
          <li>{"<"}</li>
        </a>
      );
  };

  showNext = () => {
    const { classes, currentPage, totalPages } = this.props;
    const isDisabled = currentPage == totalPages;  
      return (
        <a className={classnames({[classes.disabled]: isDisabled})} onClick={this.next}>
          <li>{">"}</li>
        </a>
      );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.pagination}>
        <ul>
          {this.showPrev()}
          {this.props.totalPages <= 5 ? (
            this.state.firstThreeArray.map((no, index) => {
              return (
                <a
                  key={index}
                  className={this.isactive(no) ? "is-active" : ""}
                  onClick={() => {
                    this.changeCurrentPage(no);
                  }}
                >
                  <li>{no}</li>
                </a>
              );
            })
          ) : (
            <React.Fragment>
              {this.state.firstThreeArray.map((no, index) => {
                return (
                  <a
                    key={index}
                    className={this.isactive(no) ? "is-active" : ""}
                    onClick={() => {
                      this.changeCurrentPage(no);
                    }}
                  >
                    <li>{no}</li>
                  </a>
                );
              })}
              {this.showEllipsis()}

              {this.showLastPagi()}
            </React.Fragment>
          )}
          {this.showNext()}
        </ul>
      </div>
    );
  }
}

ReactPagination.defaultProps = {
  currentPage: 1,
  totalPages: 1
};

export default withStyles(styles)(ReactPagination)