import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import UpwardLogo from '../assets/images/themes/upward/upward-logo.png';
import UpwardWhiteLogo from '../assets/images/themes/upward/upward-white-logo.png';
import homeImage from '../assets/images/themes/upward/upward-welcome.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'upward',
    name: 'Upward Sports VC',
    appUrl: 'm1-upward://',
    domain: 'upward.gomaxone.com',
    link: 'https://apps.apple.com/us/app/id1518324426',
    onAppleAppStore: 'https://apps.apple.com/us/app/id1518324426',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.upward' // replace with real link
  },
  image: {
    home: homeImage,
    logo: UpwardWhiteLogo,
    loginLogo: UpwardLogo,
    longLogo: UpwardWhiteLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#0f4a84"
    },
    secondary: {
      main: "#0082ea",
      isDarkMode: true,
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#0082ea"
    },
    secondary: green,
    error: red,
  }

});
