import React from "react";
import moment from "moment";
import { AccountCircle } from "@material-ui/icons";
import TableComponent from "./Table";
import { habitResultClient } from "../../../clients";
import ModalContainer from "../../../components/modalContainer";
import { popupColumns } from "../constants";
import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  SleepIcon,
  PaperIcon,
  RunningIcon,
  GlassIcon,
  HealthKitIcon,
  ManIcon,
  FlowerIcon,
  LeafIcon,
  CheckIcon,
  CrossIcon,
} from "../icons";
import { isEmpty } from "lodash";
import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";

const icons = {
  Bed: <SleepIcon style={{ width: 42, height: 16 }} />,
  CupWater: <GlassIcon style={{ width: 42, height: 16 }} />,
  Nutrition: <PaperIcon style={{ width: 42, height: 16 }} />,
  Mindset: <FlowerIcon style={{ width: 42, height: 16 }} />,
  Running: <RunningIcon style={{ width: 42, height: 16 }} />,
  SelfCare: <ManIcon style={{ width: 42, height: 16 }} />,
  Leaf: <LeafIcon style={{ width: 42, height: 16 }} />,
  Immune: <HealthKitIcon style={{ width: 42, height: 16 }} />,
};

const styles = () => ({
  icons: {
    "& svg": {
      height: "14px !important",
    },
  },
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    "& svg": {
      fill: "#A0A7AB",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  tableCurved: {
    borderCollapse: "separate",
    width: "100%",
    borderSpacing: "0 10px",
    "& tr": {
      height: "70px",
      // background: "#FFFFFF",
      // boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.15)",
      // borderRadius: "15px",
    },
  },
});

class HabitAdherenceModal extends React.Component {
  state = {
    averagePercentage: 0,
    percentageByDate: {},
    showModal: false,
    popupColumns: [],
    habits: [],
  };

  componentDidMount = () => {
    this.getAthleteData();
  };

  getAthleteData = async () => {
    const { selectedAthlete, selectedControllable } = this.props;

    let habits = [];
    const today = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const startDate = moment()
      .subtract(6, "days")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    let {
      data: habitResults,
    } = await habitResultClient.getHomePageDataByUserId(
      (selectedAthlete || {}).id,
      startDate,
      today
    );
    habitResults = habitResults[moment().format("YYYY-MM-DD")];
    habitResults = Object.keys(habitResults)
      .filter(
        (item) =>
          (habitResults[item].controllable || {}).id ===
          (selectedControllable || {}).id
      )
      .reduce((obj, item) => {
        obj[item] = habitResults[item];
        return obj;
      }, {});
    let {
      data: todayData,
      percentageByDate,
      averagePercentage,
    } = this.procceedResultsData(habitResults);
    Object.values(todayData).forEach((element) => {
      habits.push(element);
    });
    this.setState({ habits, percentageByDate, averagePercentage }, () => {
      this.setState({ showModal: true });
      this.setColumns(habits);
    });
  };

  procceedResultsData = (data) => {
    const percentageByDate = {};
    Object.values(data).forEach((element) => {
      let answeredHabits = 0;
      let unansweredHabits = 0;
      element.results.forEach((result) => {
        if (result.answer) {
          answeredHabits += 1;
        } else {
          unansweredHabits += 1;
        }
      });
      element.resultsByDate = element.results
        ? element.results.reduce((obj, result) => {
            const date = ((result.dueDate || "").split("T") || [])[0];
            obj[date] = result || {};
            return obj;
          }, {})
        : {};
      element.habit.percentage = Math.floor(
        (answeredHabits / (answeredHabits + unansweredHabits)) * 100
      );
      element.habit.answeredHabits = answeredHabits;
      element.habit.unansweredHabits = unansweredHabits;
    });

    Array.from({ length: 7 }, (_, i) =>
      moment().subtract(i, "days").format("YYYY-MM-DD")
    ).forEach((item) => {
      if (!percentageByDate[item]) {
        percentageByDate[item] = {
          answeredHabits: 0,
          unansweredHabits: 0,
          percentage: 0,
        };
      }
      Object.values(data)
        .filter((element) => !isEmpty(element.resultsByDate[item]))
        .forEach((element) => {
          if (element.resultsByDate[item].answer) {
            percentageByDate[item].answeredHabits += 1;
          } else {
            percentageByDate[item].unansweredHabits += 1;
          }
        });
      percentageByDate[item].percentage = Math.floor(
        (percentageByDate[item].answeredHabits /
          (percentageByDate[item].answeredHabits +
            percentageByDate[item].unansweredHabits)) *
          100
      );
    });

    let averagePercentage = Object.values(data).reduce((acc, item) => {
      acc += item.habit.percentage;
      return acc;
    }, 0);
    averagePercentage = averagePercentage / Object.values(data).length;

    return { data, percentageByDate, averagePercentage };
  };

  setColumns = (habits) => {
    const { classes } = this.props;
    popupColumns.forEach((item) => {
      habits.forEach((habit) => {
        if (habit.resultsByDate[item.field]) {
          return (item.render = (row) => {
            const res = row.resultsByDate[item.field] || {};
            return (
              <div className={classes.icons}>
                {res.answer === true ? (
                  <CheckIcon />
                ) : res.answer === false ? (
                  <CrossIcon />
                ) : (
                  ""
                )}
              </div>
            );
          });
        }
      });
      switch (item.field) {
        case "controllable":
          return (item.render = (item) => {
            const icon = icons[(item.controllable || {}).icon];
            return (
              <Chip avatar={icon} label={(item.controllable || {}).name} />
            );
          });
        case "habit":
          return (item.render = (item) => {
            return (item.habit || {}).title;
          });
        case "habitResults":
          return (item.render = (item) => {
            return <div>{`${(item.habit.percentage || 0).toFixed(0)}%`}</div>;
          });
      }
    });
    this.setState({ popupColumns });
  };

  render() {
    const {
      popupColumns,
      habits,
      showModal,
      averagePercentage,
      percentageByDate,
    } = this.state;
    const { onClose, classes, selectedAthlete } = this.props;
    return (
      <ModalContainer
        open={showModal}
        showFooter={false}
        onClose={onClose}
        width={"80vw"}
        customPadding={"30px"}
        backgroundColor={"#F0F0F0"}
        height={"80vh"}
        heading={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={classes.athleteImgBox}>
              {selectedAthlete.avatarUrl ? (
                <img src={selectedAthlete.avatarUrl} />
              ) : (
                <AccountCircle />
              )}
            </span>
            <span>{`${selectedAthlete.firstName} ${selectedAthlete.lastName}`}</span>
          </div>
        }
        additionalTopSection={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <span>Habit Adherence</span>
          </div>
        }
      >
        <div className="row">
          <div className="col-md-12">
            <TableComponent
              columns={popupColumns}
              data={habits}
              pageSize={(habits || []).length}
              totalCount={(habits || []).length}
              currentPage={0}
              handlePageChange={() => {}}
              handleClick={() => {}}
            />
          </div>

          <div className="col-md-12" style={{ marginTop: 40 }}>
            <Table
              className={classes.tableCurved}
              aria-label="simple table"
              size={"medium"}
            >
              <TableHead>
                <TableRow
                  hover={false}
                  className={classes.tableRow}
                  tabIndex={-1}
                  onClick={() => {}}
                >
                  <TableCell
                    style={{
                      width: "15vw",
                      overflowWrap: "break-word",
                      padding: "10px 10px",
                      borderBottom: 0,
                    }}
                    align={"left"}
                  >
                    Average
                  </TableCell>
                  <TableCell
                    style={{
                      width: "30vw",
                      overflowWrap: "break-word",
                      padding: "10px 10px",
                      borderBottom: 0,
                    }}
                    align={"left"}
                  ></TableCell>
                  <TableCell
                    style={{
                      width: "15vw",
                      overflowWrap: "break-word",
                      padding: "10px 10px",
                      borderBottom: 0,
                    }}
                    align={"left"}
                  >
                    {(averagePercentage || 0).toFixed(0)}%
                  </TableCell>
                  {Object.keys(percentageByDate).map((item, index) => {
                    return (
                      <TableCell
                        style={{
                          width: "4vw",
                          overflowWrap: "break-word",
                          padding: "20px 10px",
                          borderBottom: 0,
                        }}
                        align={"left"}
                      >
                        {(percentageByDate[item].percentage || 0).toFixed(0)}%
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </Table>
          </div>
        </div>
      </ModalContainer>
    );
  }
}

export default withStyles(styles)(HabitAdherenceModal);
