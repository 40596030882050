import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Auth, API } from 'aws-amplify'
import { getUser } from '../graphql/queries'
import { connect } from 'react-redux'
import {
  registerUser,
  createConvo,
  createConvoLink,
  updateConvoLink
} from '../graphql/mutations'
import UserBar from './UserBar'
import SideBar from './SideBar'
import { MessengerWithData } from './Messenger'
import {
    Chip,
    Grid,
    Divider,
    List,
    ListItemText,
    ListItem,
    ListSubheader,
    ListItemIcon,
    Modal,
    Avatar,
    Drawer,
    Button,
    TextField,
    IconButton,
    Tooltip,
    Tabs,
    Snackbar,
    Tab,
    Checkbox,
    InputAdornment
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import _ from "lodash";

import SwipeableViews from 'react-swipeable-views';

import { graphql, compose, withApollo } from 'react-apollo'
import ApolloClient from 'apollo-client';

import './chat.css'
function chatName(userName) {
  return `${userName} (chat)`
}

const styles = theme => ({
});


const convoList = {}

class ChatApp extends Component {
  state = {
    value: 0,
    group: {},
    coaches: [],
    athletes: [],
    viewCN: false,
    searchText: "",
    errorMessage: '',
    nameError: false,
    registered: false,
    lengthError: false,
    defaultGroup: true,
    renderedCoaches: [],
    renderedAthletes: [],
    checkedForGroup: false,
    isGroupModalOpen: false,
    conversation: undefined,
    createdNewGroups: false,
    userConversations: { items: [] },
  }

  signout = e => {
    e.preventDefault()
    Auth.signOut()
      .then(data => window.location.reload())
      .catch(err => console.log(err))
  }

  componentWillUnmount(){
    this.props.client.resetStore();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { data = {}, currentTeam } = this.props;
    if (!data.getUser) {
      console.log('!data.getUser')
      var getUserQueryData = null;
      try{
        var newData = await this.props.client.query({
          query: getUser,
          variables: { id: this.props.currentUser.id },
          options:{
            fetchPolicy:"no-cache"
          }
        })
        console.log('newData newData newData newData ', newData)
        var queryData = await this.props.client.cache.writeQuery({
            query: getUser,
            variables: { id: this.props.currentUser.id },
            data:{
              ...newData.data
            }
          })

          if(!this.state.conversation){
            const { coaches, athletes } = this.state;

            if(newData.data.getUser && newData.data.getUser.userConversations.items.length > 0){
              let conversation = null;
              let updateConversations = {...newData.data.getUser.userConversations};
              updateConversations.items = _.filter(newData.data.getUser.userConversations.items, item => item.conversation && item.conversation.teamId == currentTeam.id)
              if(prevProps.location.state) {
                conversation = _.find(updateConversations.items, item => item.name == prevProps.location.state.group.name)
                console.log('here is the conversation', conversation)
                this.initConvo(conversation);
              }
              else {
                conversation = _.find(updateConversations.items, item => item.name == currentTeam.customName)
                console.log('here is the conversation', conversation)
                this.initConvo(conversation)
              }
              this.setState({ conversation: conversation, userConversations: updateConversations, isLoading: false });
            }
          }
      }
      catch(e){
        console.log('ERROR => ', e)
      }
    }
    else{
      if(!this.state.conversation){
        const { coaches, athletes } = this.state;

        if(data.getUser && data.getUser.userConversations.items.length > 0){
          let conversation = null;
          let updateConversations = {...data.getUser.userConversations};
          updateConversations.items = _.filter(data.getUser.userConversations.items, item => item.conversation && item.conversation.teamId == currentTeam.id)
          if(prevProps.location.state) {
            conversation = _.find(updateConversations.items, item => item.name == prevProps.location.state.group.name)
            console.log('here is the conversation', conversation)
            this.initConvo(conversation);
          }
          else {
            conversation = _.find(updateConversations.items, item => item.name == currentTeam.customName)
            console.log('here is the conversation', conversation)
            this.initConvo(conversation)
          }
          this.setState({ conversation: conversation, userConversations: updateConversations, isLoading: false });
        }
      }
    }
  }

  // async componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { data = {}, currentTeam } = this.props;
  //   const userConversations = await this.getConversations();
  //   var teamName = currentTeam.customName ? currentTeam.customName : `${currentTeam.name} ${currentTeam.sport}`
  //   let defaultGroup = _.find(userConversations, convo => convo.name == teamName)

    // if (!defaultGroup) {
    //   this.setState({ 
    //     isLoading: false,
    //     checkedForGroup: true,
    //     showMobileChatModal: true,
    //   })
    // }

  //   if(defaultGroup && !data.getUser) {
  //     var getUserQueryData = null;
  //     try{
  //       var newData = await this.props.client.query({
  //         query: getUser,
  //         variables: { id: this.props.currentUser.id },
  //         options:{
  //           fetchPolicy:"no-cache"
  //         }
  //       })
  //       var queryData = await this.props.client.cache.writeQuery({
  //         query: getUser,
  //         variables: { id: this.props.currentUser.id },
  //         data:{
  //           ...newData.data
  //         }
  //       })

  //       if(!this.state.conversation){
  //         console.log('deeper inside IF')
  //         const { coaches, athletes } = this.state;

  //         if(newData.data.getUser && newData.data.getUser.userConversations.items.length > 0){
  //           let updateConversations = {...newData.data.getUser.userConversations};
  //           console.log('deepest inside IF');
  //           updateConversations.items = _.filter(newData.data.getUser.userConversations.items, item => item.conversation && item.conversation.teamId == currentTeam.id)
  //           let conversation = null;
  //           if(prevProps.location.state) {
  //             conversation = _.find(updateConversations.items, item => item.name == prevProps.location.state.group.name)
  //             this.initConvo(_.find(updateConversations.items, item => item.name == prevProps.location.state.group.name))
  //           }
  //           else {
  //             conversation = _.find(updateConversations.items, item => item.name == currentTeam.customName || item.name == `${currentTeam.name} ${currentTeam.sport}`)
  //             this.initConvo(_.find(updateConversations.items, item => item.name == currentTeam.customName || item.name == `${currentTeam.name} ${currentTeam.sport}`))
  //           }
  //           this.setState({ conversation, userConversations: updateConversations, isLoading: false });
  //         }
  //       }
  //     }
  //     catch(e){
  //       console.log('ERROR => ', e)
  //     }
  //   }
  //   else if(defaultGroup && !this.state.conversation){
  //     const { coaches, athletes } = this.state;
  //     console.log('data.getUser.userConversations in else =====> ', data.getUser.userConversations);
  //     let updateConversations = {...data.getUser.userConversations};
  //     updateConversations.items = _.filter(data.getUser.userConversations.items, item => item.conversation && item.conversation.teamId == currentTeam.id)
  //     let conversation = null;
  //     if(prevProps.location.state) {
  //       console.log('defaultGroup in if =====> ', defaultGroup)
  //       conversation = _.find(updateConversations.items, item => item.name == prevProps.location.state.group.name)
  //       this.initConvo(_.find(updateConversations.items, item => item.name == prevProps.location.state.group.name))
  //     }
  //     else {
  //       conversation = _.find(updateConversations.items, item => item.name == currentTeam.customName || item.name == `${currentTeam.name} ${currentTeam.sport}`)
  //       console.log('conversation in else =====> ', conversation);
  //       this.initConvo(_.find(updateConversations.items, item => item.name == currentTeam.customName || item.name ==`${currentTeam.name} ${currentTeam.sport}`))
  //     }
  //     this.setState({ conversation, userConversations: updateConversations, isLoading: false });
  //   }
  //   else if(!defaultGroup) {
  //     alert('noooooooo')
  //     return this.setState({ isLoading:false })
  //   }
  // }

  async componentDidMount(){
    const { data: { loading, getUser } = {}, currentTeam } = this.props;
    const coaches = await this.getCoaches();
    const athletes = await this.getAthletes();
    // const userConversations = await this.getConversations();

    var teamName = currentTeam.customName ? currentTeam.customName : `${currentTeam.name} ${currentTeam.sport}`

    // if we have a group that matches this name, we good
    // let defaultGroup = _.find(userConversations, convo => convo.name == teamName)

    // otherwise create their default groups now
    // if(!defaultGroup){
    //   const newCoachGroup = await this.launchNewGroupConversation({ name: "All Coaches" }, coaches);
    //   const newTeamGroup = await this.launchNewGroupConversation({ name: teamName }, _.concat(athletes, coaches));
    // }
    // if (!defaultGroup) {
      // see if there is already a team group to be added to.
    //   let teamConversations = await this.getTeamConversations();
    //   if (teamConversations && teamConversations.length === 0) {
    //     const newCoachGroup = await this.launchNewGroupConversation({ name: "All Coaches" }, coaches );
    //     const newTeamGroup = await this.launchNewGroupConversation({ name: teamName },   _.concat(athletes, coaches));
    //   }
    //   else if (teamConversations && teamConversations.length > 0) {
    //     // if appContext isCoach || isOwner, add to All Coaches chat.
    //     if (this.props.appContext.isCoach || this.props.appContext.isOwner) {
    //       const coachGroup = _.find(teamConversations, ["name", "All Coaches"]);
    //       console.log("Found Coach Group = ", coachGroup);
    //       if (coachGroup) {
    //         await this.linkNewConversation(
    //           coachGroup.id,
    //           this.props.userContext.user.id,
    //           coachGroup.name
    //         );
    //       }
    //       else{
    //         const newCoachGroup = await this.launchNewGroupConversation({ name: "All Coaches" }, coaches );
    //       }
    //     }
    //     // Add all coaches and athletes to Team Chat.
    //     const teamGroup = _.find(teamConversations, ["name", teamName]);
    //     console.log("Found teamGroup Group = ", teamGroup);
    //     if (teamGroup) {
    //       await this.linkNewConversation(
    //         teamGroup.id,
    //         this.props.userContext.user.id,
    //         teamGroup.name
    //       );
    //     }
    //     else{
    //       const newTeamGroup = await this.launchNewGroupConversation({ name: teamName },   _.concat(athletes, coaches));
    //     }
    //   }
    // }

    await Promise.all(athletes.map(async(athlete) => {
      if(athlete && athlete.avatarUrl && !athlete.avatarUrl.includes("https://")){
          var logo = athlete.avatarUrl.includes("https://") || athlete.avatarUrl.includes("http://") ? athlete.avatarUrl : `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${athlete.legacyId ? athlete.legacyId : athlete.id}/${athlete.avatarUrl}`
          athlete.avatarUrl = logo;
      }
    }))

    await Promise.all(coaches.map(async(coach) => {
      if(coach && coach.avatarUrl && !coach.avatarUrl.includes("https://")){
          var logo = coach.avatarUrl.includes("https://") || coach.avatarUrl.includes("http://") ? coach.avatarUrl : `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${coach.legacyId ? coach.legacyId : coach.id}/${coach.avatarUrl}`
          coach.avatarUrl = logo;
      }
    }))

    // this.props.client.resetStore();

    this.setState({coaches, athletes})
  }

  async getConversations(){
    const { currentTeam, currentUser } = this.props;
    return API.get("chat", `/users/${currentUser.id}/parent/${currentTeam.id}/conversation`);
  }
  async getTeamConversations() {
    try {
      console.log("Getting conversations");
      const { currentTeam, userContext } = this.props;
      // return API.get("chat", `/users/${user.id}/parent/${currentTeam.id}/conversation`);
      return API.get("chat", `/parent/${currentTeam.id}/conversation`);
    } catch (e) {
      console.log("Getting conversations error ===== ", e);
    }
  }

  async deleteChatGroupEnrollment(userId, group){
      return API.del("chat", `/users/${userId}/conversation/${group.id}/link`);
  }

  async deleteChatGroup(){
    const { conversation } = this.state;
    return API.del("chat", `/conversation/${conversation.id}`);
  }

  async getAthletes(){
      const { currentTeam } = this.props;
      return API.get("programs", `/programs/${currentTeam.id}/players`);
  }

  async getCoaches(){
      const { currentTeam } = this.props;
      return API.get("programs", `/programs/${currentTeam.id}/coaches`);
  }

  initConvo = selection => {
    if(selection){
      switch (selection.__typename) {
        case 'User':
          return this.startConvoWithUser({ user: selection })
        case 'ConvoLink':
          return this.gotoConversation({ convoLink: selection })
        case 'Message':
          return this.startConvoAtMessage({ message: selection })
        default:
          break
      }
    }
  }

  startConvoWithUser = async ({ user }) => {
    let conversationInfo = this.findConverationWithUser(user)
    if (!conversationInfo) {
      conversationInfo = await this.launchNewConversation(user)
    }
    this.setState({ ...conversationInfo, viewCN: false })
  }

  gotoConversation = ({ convoLink }) => {
    this.setState({
      conversation: convoLink.conversation,
      conversationName: convoLink.name,
      viewCN: false
    })
  }

  startConvoAtMessage = ({ message }) => {
    const {
      data: {
        getUser: { userConversations: { items: convoLinks = [] } = {} } = {}
      } = {}
    } = this.props
    const convoLink = convoLinks.find(
      c => c.conversation.id === message.messageConversationId
    )
    if (convoLink) {
      this.setState({
        conversation: convoLink.conversation,
        conversationName: convoLink.conversation.name,
        viewCN: false
      })
    }
  }

  findConverationWithUser = user => {
    const {
      data: {
        getUser: { userConversations: { items: convoLinks = [] } = {} } = {}
      } = {}
    } = this.props
    const convoLink = convoLinks.find(c => {
      const {
        conversation: { associated: { items: assoc = [] } = {} } = {}
      } = c
      return assoc.some(a => a.convoLinkUserId === user.id)
    })
    return convoLink
      ? {
          conversation: convoLink.conversation,
          conversationName: convoLink.conversation.name
        }
      : null
  }

  launchNewConversation = user => {
    let resolveFn
    const promise = new Promise((resolve, reject) => {
      resolveFn = resolve
    })

    this.props.createConvo({
      update: async (proxy, { data: { createConvo } }) => {
        if (createConvo.id === '-1' || convoList[`${createConvo.id}`]) {
          return
        }
        convoList[`${createConvo.id}`] = true
        const me = this.props.data.getUser
        const otherChatName = chatName(me.username)
        const myChatName = chatName(user.username)
        const links = await Promise.all([
          this.linkNewConversation(createConvo.id, user.id, otherChatName),
          this.linkNewConversation(createConvo.id, me.id, myChatName)
        ])
        const promises = links.map(c => this.updateToReadyConversation(c))
        const convoLinks = await Promise.all(promises)
        resolveFn({
          conversation: convoLinks[0].conversation,
          conversationName: myChatName
        })
      }
    })
    return promise
  }

  launchNewGroupConversation = (group, chipData) => {
    let resolveFn
    const promise = new Promise((resolve, reject) => {
      resolveFn = resolve
    })
    const me = this.props.data ? this.props.data.getUser : {};
    const chatName = group.name;
    this.props.createConvo({
        variables: {
          input: { name: chatName ? chatName : "", teamId: this.props.currentTeam.id ?  this.props.currentTeam.id : ""}
        },
        optimisticResponse: {
          createConvo: {
            id: '-1',
            name: chatName,
            createdAt: '',
            teamId: this.props.currentTeam.id,
            __typename: 'Conversation',
            associated: {
              __typename: 'ModelConvoLinkConnection',
              items: []
            }
          }
        },
      update: async (proxy, { data: { createConvo } }) => {
        if (createConvo.id === '-1' || convoList[`${createConvo.id}`]) return;

        convoList[`${createConvo.id}`] = true;
        const links = await Promise.all(chipData.map(async user => {
          return this.linkNewConversation(createConvo.id, user.id, chatName);
        }))

        const promises = links.map(c => this.updateToReadyConversation(c, chatName));
        const convoLinks = await Promise.all(promises);

        resolveFn({
          conversation: convoLinks[0].conversation,
          conversationName: chatName
        })
      }
    })
    return promise
  }

  linkNewConversation = (convoId, userId, chatName) => {
    let resolveFn
    const promise = new Promise((resolve, reject) => {
      resolveFn = resolve
    })
    this.props.createConvoLink({
      variables: { convoId, userId, name: chatName },
      optimisticResponse: {
        createConvoLink: {
          __typename: 'ConvoLink',
          id: '-1',
          status: 'PENDING',
          name: chatName,
          conversation: {
            __typename: 'Conversation',
            id: convoId,
            name: chatName,
            teamId: this.props.currentTeam.id,
            createdAt: '',
            associated: {
              __typename: 'ModelConvoLinkConnection',
              items: []
            }
          }
        }
      },
      update: async (proxy, { data: { createConvoLink } }) => {
        if (createConvoLink.id === '-1') {
          return
        }
        resolveFn(createConvoLink)
      }
    })
    return promise
  }

  updateToReadyConversation = (convoLink, chatName) => {
    let resolveFn
    const promise = new Promise((resolve, reject) => {
      resolveFn = resolve
    })

    this.props.updateConvoLink({
      variables: { id: convoLink.id },
      optimisticResponse: {
        updateConvoLink: {
          __typename: 'ConvoLink',
          id: convoLink.id,
          name: chatName,
          convoLinkUserId: '-1',
          status: 'CONFIRMING',
          conversation: {
            __typename: 'Conversation',
            id: convoLink.conversation.id,
            name: chatName,
            teamId: this.props.currentTeam.id,
            createdAt: '',
            associated: {
              __typename: 'ModelConvoLinkConnection',
              items: []
            }
          }
        }
      },
      update: async (proxy, { data: { updateConvoLink } }) => {
        if (updateConvoLink.status === 'READY') {
          resolveFn(updateConvoLink)
        }
      }
    })
    return promise
  }

  switchView = () => {
    this.setState({ viewCN: !this.state.viewCN })
  }

  toggleGroupModal = async () => {
    const { athletes, coaches, userConversations } = this.state;
    var coachList = coaches;
    var athleteList = athletes;

    if(!athletes.length){
      athleteList = await this.getAthletes()
      this.setState({
        athletes: _.filter(athleteList, athlete => athlete.nameFirst),
        renderedAthletes: _.filter(athleteList, athlete => athlete.nameFirst),
      })
    }
    else {
      this.setState({ renderedAthletes: athletes || [] })
    }

    if(!coaches.length){
      coachList = await this.getCoaches()
      this.setState({
        coaches: _.filter(coachList, coach => coach.nameFirst),
        renderedCoaches: _.filter(coachList, coach => coach.nameFirst)
      })
    }
    else {
      this.setState({ renderedCoaches: coaches || [] })
    }

    this.props.history.push('/chat/new', {
      group: {},
      userConversations: userConversations,
      renderedCoaches: _.filter(coachList, coach => coach.nameFirst),
      renderedAthletes: _.filter(athleteList, athlete => athlete.nameFirst)
    });
  }

  editGroup = async () => {
    const { athletes, coaches, userConversations } = this.state;
    var coachList = coaches;
    var athleteList = athletes;
    if(!athletes.length){
      athleteList = await this.getAthletes()
      this.setState({
        athletes: _.filter(athleteList, athlete => athlete.nameFirst),
        renderedAthletes: _.filter(athleteList, athlete => athlete.nameFirst),
      })
    }
    else {
      this.setState({ renderedAthletes: athletes || [] })
    }

    if(!coaches.length){
      coachList = await this.getCoaches()
      this.setState({
        coaches: _.filter(coachList, coach => coach.nameFirst),
        renderedCoaches: _.filter(coachList, coach => coach.nameFirst)
      })
    }
    else {
      this.setState({ renderedCoaches: coaches || [] })
    }

    this.props.history.push('/chat/new', {
      group: this.state.conversation,
      userConversations: userConversations,
      renderedCoaches: _.filter(coachList, coach => coach.nameFirst),
      renderedAthletes: _.filter(athleteList, athlete => athlete.nameFirst)
    });
  }

  deleteGroup = async () => {
    const { conversation } = this.state;
    const { currentTeam } = this.props;
    let updatedConversations = this.state.userConversations;
    try{
      const deletedGroup = await this.deleteChatGroup();
    }
    catch(e){
      alert('Oops, we had trouble with that. Try again?');
    }
    let match = _.find(updatedConversations.items, item => item.conversation == conversation);
    updatedConversations.items = _.without(this.state.userConversations.items, match);
    this.initConvo(_.find(updatedConversations.items, item => item.name == currentTeam.customName));
    this.setState({ userConversations: updatedConversations, open: true });
    this.hideSnackBar();
  }

  deleteConvoLink = async (userData, group) => {
    this.deleteChatGroupEnrollment(userData.id, group)
  }

  searchAthletes = event => {
    const { athletes, coaches, value } = this.state;

    if(event.target.value){
        this.setState({
            renderedAthletes: _.filter(athletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
        })
    }
    else {
        this.setState({
            renderedAthletes: athletes
        })
    }
  }

  searchCoaches = event => {
    const { coaches, value } = this.state;
    if(event.target.value){
        this.setState({
            renderedCoaches: _.filter(coaches, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
        })
    }
    else {
        this.setState({
            renderedCoaches: coaches
        })
    }
  }

  hideSnackBar(){
      setTimeout(() => this.setState({open:false}), 2000);
  }

  render() {
    const { group, renderedAthletes, renderedCoaches, isLoading, loadingMessage, errorMessage, lengthError, nameError, value, athletes, coaches } = this.state;
    let { data: { subscribeToMore, getUser: user = {} } = {} } = this.props
    user = user || { name: '', registered: false }
    const { classes = { } } = this.props;
    let {isGroupModalOpen} = this.state;
    let cn = this.state.viewCN ? 'switchview' : ''
    cn +=
      ' ' +
      'row align-items-stretch w-100 h-100 position-absolute'

    return (
      <div className={'chatOuterContainer'}>
        <div className="col viewer bg-white" style={{height:'88vh', maxWidth:"calc(100% - 230px)", margin: '-10px 10px 0px 0px'}}>
          <MessengerWithData
              classes={classes}
              switchView={this.switchView}
              conversation={this.state.conversation}
              conversationName={this.state.conversationName}
              userId={this.props.id}
              athletes={athletes}
              coaches={coaches}
            />
        </div>
        <div className="drawer" style={{ backgroundColor: '#28272C', marginRight: -25 }}>
          <div className="rightMenu">
            <UserBar
              switchView={this.switchView}
              name={user.username}
              registered={user.registered}
              signout={this.signout}
              toggleGroupModal={this.toggleGroupModal}
              conversationLength={this.state.userConversations ? this.state.userConversations.items.length : 0}
            />
            <SideBar
              {...{
                subscribeToMore,
                userId: this.props.id,
                conversations: this.state.userConversations,
                onChange: this.initConvo,
                editGroup: this.editGroup,
                deleteGroup: this.deleteGroup,
                currentTeam: this.props.currentTeam
              }}
            />
          </div>
        </div>
        <Snackbar
          open={this.state.open}
          TransitionComponent={this.state.Transition}
          ContentProps={{
              'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">group deleted</span>}
          action={[
              <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={() => this.setState({open:false})}
              >
              <CloseIcon className={classes.icon} />
              </IconButton>
          ]}
        />
      </div>
    )
  }
}
ChatApp.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
  createConvo: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(ApolloClient),
  createConvoLink: PropTypes.func.isRequired,
  updateConvoLink: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { session: { userContext, appContext }, chat:{ viewingGroup } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    viewingGroup,
    currentTeam: currentTeam||{},
    userContext: userContext,
    currentUser: userContext.user,
  };
}

const ChatAppWithData = compose(
  withApollo,
  graphql(getUser, {
    skip: props => !props.id,
    options: props => ({
      variables: { id: props.id },
      fetchPolicy: 'no-cache'
    })
  }),
  graphql(registerUser, {
    name: 'registerUser',
    options: props => ({
      // variables: {
      //   input: {
      //     id: props.id,
      //     username: props.name,
      //     registered: true
      //   }
      // },
      // optimisticResponse: {
      //   registerUser: {
      //     id: props.id,
      //     username: 'Standby',
      //     registered: false,
      //     __typename: 'User',
      //     userConversations: {
      //       __typename: 'ModelConvoLinkConnection',
      //       items: []
      //     }
      //   }
      // },
      // update: (proxy, { data: { registerUser } }) => {
      //   const QUERY = {
      //     query: getUser,
      //     variables: { id: props.id }
      //   }
      //   const prev = proxy.readQuery(QUERY)
      //   const data = {
      //     ...prev,
      //     getUser: { ...registerUser }
      //   }
      //   proxy.writeQuery({ ...QUERY, data })
      // }
      update: (proxy, { data: { registerUser } }) => {
        const QUERY = {
          query: getUser,
          variables: { id: props.id }
        }
        let prev = undefined;
        try{
          prev = proxy.readQuery(QUERY)
        }
        catch(e){
          console.log("error = ", e)
        }
        const data = {
          ...prev,
          getUser: { ...registerUser }
        }
        proxy.writeQuery({ ...QUERY, data })
      }
    })
  }),
  graphql(createConvo,
    {
      name: 'createConvo',
      options: props => ({
        ignoreResults: true
      })
    }),
  graphql(createConvoLink, {
    name: 'createConvoLink',
    options: props => ({
      ignoreResults: true
    })
  }),
  graphql(updateConvoLink, {
    name: 'updateConvoLink',
    options: props => ({
      ignoreResults: true
    })
  }),
  connect(mapStateToProps)
)(ChatApp)

export default ChatApp
export { ChatAppWithData }
