import React from "react";
import MainComponent from "../components/Main";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  habitResultClient,
  teamClient,
  hrvClient,
  serviceIntegrationClient,
} from "../../../clients";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import _ from "lodash";
import { mainColumns } from "../constants";
import { AccountCircle } from "@material-ui/icons";
import {
  SleepIcon,
  PaperIcon,
  RunningIcon,
  GlassIcon,
  HealthKitIcon,
  ManIcon,
  FlowerIcon,
  LeafIcon,
} from "../icons";

const icons = {
  Bed: <SleepIcon />,
  CupWater: <GlassIcon />,
  Nutrition: <PaperIcon />,
  Mindset: <FlowerIcon />,
  Running: <RunningIcon />,
  SelfCare: <ManIcon />,
  Leaf: <LeafIcon />,
  Immune: <HealthKitIcon />,
};

const LIMIT = 20;

const styles = () => ({
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    "& svg": {
      fill: "#A0A7AB",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  joinedDate: {
    height: "18px",
    opacity: "60%",
    marginBottom: "0px",
    fontSize: "12px",
    lineHeight: "18px",
  },
});

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      athletes: [],
      totalCount: 0,
      currentPage: 0,
      searchTerm: "",
      athletesData: [],
      athletesTableData: [],
      mainColumns: this.setColumns(),
      groups: [],
      loadingChildComponent: false,
      loadingChildMessage: "",
      selectedGroup: "allGroups",
      showHabitAdherenceModal: false,
      selectedControllable: {},
      selectedAthlete: {},
    };
  }

  componentDidMount = async () => {
    const { currentPage, searchTerm } = this.state;
    this.fetchAthletes(searchTerm, currentPage);
    let groups = await this.getGroups();
    this.setState({ groups });
  };

  handleChange = (key, value) => {
    if (key === "searchTerm" && !value) {
      this.setState({ [key]: value }, () => this.handleSearch());
      return
    }
    this.setState({ [key]: value });
  };

  getGroups() {
    const { currentTeamId } = this.props;
    return API.get("groups", `/programs/${currentTeamId}/groups`);
  }

  fetchAthletes = async (searchTerm, page = 0) => {
    let { currentTeamId } = this.props;
    this.setState({
      pageSize: LIMIT,
      currentPage: page,
      loadingChildComponent: true,
      loadingChildMessage: "Loading...",
    });

    let queryParams = {
      q: searchTerm || "*",
      "teams.team-role.keyword": `${currentTeamId}-ATHLETE`,
      limit: LIMIT,
      offset: page * LIMIT,
    };

    try {
      const {
        data: { total, results },
      } = await teamClient.searchMembersByTeamId(currentTeamId, queryParams);
      const athletes = await this.getAthletesData(results);
      this.setState({
        athletes,
        totalCount: total,
        isLoading: false,
        loadingMessage: "",
        loadingChildComponent: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        athletes: [],
        isLoading: false,
        loadingMessage: "",
        loadingChildComponent: false,
      });
    }
  };

  getAthletesData = async (athletesArr) => {
    const athletes = await Promise.all(
      (athletesArr || []).map(async (athlete) => {
        const today = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
        const startDate = moment()
          .subtract(6, "days")
          .format("YYYY-MM-DDTHH:mm:ss[Z]");
        const data = await Promise.all([
          habitResultClient.getHomePageDataByUserId(
            athlete.id,
            startDate,
            today
          ),
          hrvClient.getHRVListByUserId(athlete.id, today, today),
          serviceIntegrationClient.getServiceDataByUserId(
            "WHOOP",
            athlete.id,
            today,
            today
          ),
        ]);
        const athleteObj = this.createAtheletsData(athlete, data);
        return athleteObj;
      })
    );
    return athletes;
  };

  createAtheletsData = (athlete, athleteData) => {
    let today = moment().format("YYYY-MM-DD");
    let athleteObj = {
      id: athlete.id,
      firstName: athlete.firstName || athlete.nameFirst,
      lastName: athlete.lastName || athlete.nameLast,
      joinedDate: athlete.createdDate,
      avatarUrl: athlete.avatarUrl,
    };
    let controllables = [];
    let habits = [];
    let habitResults = athleteData[0].data;
    let hrvData = athleteData[1].data.results;
    let { percentage, data: todayData } = this.procceedResultsData(
      habitResults[today]
    );
    let whoopData = athleteData[2].data.results;
    for (const [key, value] of Object.entries(todayData)) {
      controllables.push(value.controllable);
      habits.push(value.habit);
    }
    let uniqueControllables = [
      ...new Map(controllables.map((item) => [item["id"], item])).values(),
    ];
    let uniqueHabits = [
      ...new Map(habits.map((item) => [item["id"], item])).values(),
    ];
    athleteObj.percentage = percentage;
    athleteObj.controllables = uniqueControllables;
    athleteObj.habits = uniqueHabits;
    athleteObj.whoopData = whoopData;
    athleteObj.hrvData = hrvData;
    return athleteObj;
  };

  getControllables = (item) => {
    return (item.controllables || []).map((element, index) => {
      return (
        <div
          key={index}
          className="mr-3"
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.handleChange("showHabitAdherenceModal", true);
            this.handleChange("selectedControllable", element);
            this.handleChange("selectedAthlete", item);
          }}
        >
          {icons[element.icon]}
        </div>
      );
    });
  };

  setColumns = () => {
    const { classes } = this.props;
    mainColumns.forEach((item) => {
      switch (item.field) {
        case "athleteName":
          return (item.render = (item) => {
            return (
              <div
                style={{ display: "inline-flex", cursor: "pointer" }}
                onClick={() =>
                  this.navigateTo(`/wellnessDashboard/athlete/${item.id}`, {
                    athlete: item,
                  })
                }
              >
                <span className={classes.athleteImgBox}>
                  {item.avatarUrl ? (
                    <img src={item.avatarUrl} />
                  ) : (
                    <AccountCircle />
                  )}
                </span>
                <div>
                  {" "}
                  <p className="mb-0">{`${item.firstName} ${item.lastName}`}</p>
                  <p className={classes.joinedDate}>
                    {moment(item.joinedDate).fromNow()}
                  </p>
                </div>
              </div>
            );
          });
        case "habitResult":
          return (item.render = (item) => {
            return <div>{`${(item.percentage || 0).toFixed(0)}%`}</div>;
          });
        case "controllables":
          return (item.render = (item) => {
            return (
              <div style={{ display: "flex", cursor: "pointer" }}>
                {this.getControllables(item)}
              </div>
            );
          });
        case "hrv":
          return (item.render = (item) => {
            let hrv = (item.hrvData || []).length && item.hrvData[0].hrv
              ? Math.round(item.hrvData[0].hrv)
              : (item.whoopData || []).length && item.whoopData[0].hrv
              ? Math.round(item.whoopData[0].hrv)
              : "";
            return <div>{hrv}</div>;
          });
        case "recovery":
          return (item.render = (item) => {
            let recovery = (item.whoopData || []).length
              ? item.whoopData[0].recoveryScore
              : "";
            return <div>{recovery ? `${Math.round(recovery)}%` : ""}</div>;
          });
        case "day":
          return (item.render = (item) => {
            let dailyStrain = (item.whoopData || []).length
              ? (item.whoopData[0].dailyStrain || 0).toFixed(1)
              : "";
            return <div>{dailyStrain}</div>;
          });

        case "restingHeartRate":
          return (item.render = (item) => {
            const restingHeartRate = (item.whoopData || []).length
              ? Math.round(item.whoopData[0].restingHeartRate || 0)
              : "";
            return <div>{restingHeartRate}</div>;
          });

        case "respiratoryRate":
          return (item.render = (item) => {
            const respiratoryRate = (item.whoopData || []).length
              ? (item.whoopData[0].respiratoryRate || 0).toFixed(1)
              : "";
            return <div>{respiratoryRate}</div>;
          });
      }
    });
    return mainColumns;
  };

  handleSearch = () => {
    const { searchTerm } = this.state;
    this.fetchAthletes(searchTerm, 0);
    this.setState({ currentPage: 0 });
  };

  handleGroupChange = async (event) => {
    const { groups } = this.state;
    const groupId = event.target.value;
    this.setState({ selectedGroup: groupId });
    if (groupId !== "allGroups") {
      this.setState({
        loadingChildComponent: true,
        loadingChildMessage: "Loading...",
      });
      const group = groups.find((item) => item.id === groupId);
      const athletes = await this.getAthletesData(group.participants);
      this.setState({
        athletes,
        searchTerm: "",
        currentPage: 0,
        pageSize: (group.participants || []).length,
        totalCount: (group.participants || []).length,
        loadingChildComponent: false,
        loadingChildMessage: "",
      });
    } else {
      this.fetchAthletes();
    }
  };

  handlePageChange = (page) => {
    const { searchTerm } = this.state
    this.setState({ currentPage: page - 1 }, () =>
      this.fetchAthletes(searchTerm, page - 1)
    );
  };

  procceedResultsData = (data) => {
    let percentage = 0;
    Object.values(data).forEach((element) => {
      let answeredHabits = 0;
      let unansweredHabits = 0;
      element.results.forEach((result) => {
        if (result.answer) {
          answeredHabits += 1;
        } else {
          unansweredHabits += 1;
        }
      });
      element.habit.percentage = Math.floor(
        (answeredHabits / (answeredHabits + unansweredHabits)) * 100
      );
      element.habit.answeredHabits = answeredHabits;
      element.habit.unansweredHabits = unansweredHabits;
    });
    percentage = Object.values(data).reduce((totalPercent, item) => {
      totalPercent += item.habit.percentage;
      return totalPercent;
    }, 0);
    if (Object.values(data).length) {
      percentage = percentage / Object.values(data).length;
    }
    return { percentage, data };
  };

  navigateTo = (key, params) => {
    const { history } = this.props;
    history.push(key, params);
  };

  render() {
    const {
      athletes,
      mainColumns,
      groups,
      selectedGroup,
      currentPage,
      totalCount,
      loadingChildComponent,
      loadingChildMessage,
      showHabitAdherenceModal,
      selectedControllable,
      selectedAthlete,
      searchTerm,
    } = this.state;
    const { handleLogout } = this.props;
    return (
      <MainComponent
        groups={groups}
        pageSize={LIMIT}
        searchTerm={searchTerm}
        mainColumns={mainColumns}
        athletes={athletes}
        totalCount={totalCount}
        currentPage={currentPage}
        navigateTo={this.navigateTo}
        selectedGroup={selectedGroup}
        handleSearch={this.handleSearch}
        selectedAthlete={selectedAthlete}
        handlePageChange={this.handlePageChange}
        loadingChildMessage={loadingChildMessage}
        handleGroupChange={this.handleGroupChange}
        loadingChildComponent={loadingChildComponent}
        handleLogout={handleLogout}
        selectedControllable={selectedControllable}
        showHabitAdherenceModal={showHabitAdherenceModal}
        handleChange={this.handleChange}
      />
    );
  }
}
const mapStateToProps = ({
  users: { loggedInUserId },
  teams: { byId, currentTeamId },
  tenant,
  session: { userContext, appContext },
}) => ({
  byId,
  tenant,
  currentTeamId,
  loggedInUserId,
  userContext,
  appContext,
});
export default connect(mapStateToProps)(withStyles(styles)(Main));
