import { combineReducers } from 'redux';

import {
    USERS_GET_BY_ID_LOADING,
    USERS_GET_BY_ID_SUCCESS,
    USERS_GET_BY_ID_ERROR,
    USERS_SET_AUTHENTICATED_BY_ID_SUCCESS
} from '../actions/user-actions';

const loading = (state = false, action) => {
    switch (action.type) {
        case USERS_GET_BY_ID_LOADING:
            return true;
        case USERS_GET_BY_ID_SUCCESS:
        case USERS_GET_BY_ID_ERROR:
            return false;
    }
    return state;
};

const error = (state = false, action) => {
    switch (action.type) {
        case USERS_GET_BY_ID_ERROR:
            return true;
        case USERS_GET_BY_ID_SUCCESS:
        case USERS_GET_BY_ID_LOADING:
            return false;
    }
    return state;
};

const byId = (state = {}, action) => {
    if (action.type === USERS_GET_BY_ID_SUCCESS) {
        const { user } = action.data;
        return [ user ].reduce((list, item) => {
            list[item.id] = item;
            return list;
        }, state);
    }
    return state;
};

const loggedInUserId = (state = null, action) => {
    if (action.type === USERS_SET_AUTHENTICATED_BY_ID_SUCCESS) {
        const { id } = action.data;
        return id;
    }
    return state;
};

export default combineReducers({
    loading,
    error,
    byId,
    loggedInUserId
});
