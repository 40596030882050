import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';
import StateLoader from './services/stateLoader';
import logger from 'redux-logger';

const stateLoader = new StateLoader();
export const store = createStore(
    reducers,
    {},
    applyMiddleware(logger, thunk),
);

