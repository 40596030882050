import React, { Component } from 'react'
import './styles.css'
import CheckIcon from '@material-ui/icons/Check';

export default class CheckBox extends Component {
    render(){
        const { checked = false } = this.props
        return(
            <div className={checked ? 'checkedCheckBoxOuterContainer' : 'checkBoxOuterContainer'}>
                {checked && <CheckIcon className={'checkmark'} />}
            </div>
        )
    }
}