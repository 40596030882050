import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash"

import AddGroupModal from './components/addGroupModal'


import Tab from '@material-ui/core/Tab';
import { Button } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import SwipeableViews from 'react-swipeable-views';

import { API, Auth, Storage } from "aws-amplify";

import { withStyles } from '@material-ui/core/styles';

import { analyticsService, currentTimestamp } from '../../services/analyticsService';
import { getLocalizedString } from '../../utils/locale-utils';

import {
    resolveUserList,
    resolveGroupMembers,
    resolveFilteredRecipientList,
    resolveGroupParticipantsForSaving
} from '../../utils/groupsUtils'

import classNames from 'classnames';

import "./groups.css";
import classnames from "classnames";
import PageWrapper from '../nav/pageWrapper';


const styles = theme => ({
    plusButton: {
        backgroundColor: "#1354F9",
        color: 'white'
    },
    textButton: {
        color: 'white',
        height: '40px',
        marginRight: '3px',
        backgroundColor: "#1354F9",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 8,
        marginRight: 8,
        width: 300,
    },
    menu: {
        width: 500,
        padding: 50
    },
    button: {
        width:"100%",
        marginTop:10
    },
    header: {
        fontWeight: 100,
        fontSize:45,
        marginLeft:35,
        float:"left"
    },
    subHeader: {
        fontWeight: 100,
    },
    image: {
        width: 100,
        marginBottom:'-35px'
    },
});


class Groups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            group: {},
            groups:[],
            players: [],
            coaches: [],
            programId:'',
            groupName: '',
            searchText: '',
            coachChecked:[],
            isSaving: false,
            isLoading: false,
            playerChecked:[],
            renderedGroups: [],
            renderedParents: [],
            renderedCoaches: [],
            renderedAthletes: [],
            isGroupModalOpen: false,
            currentGroupMembers: [],
            recipientSearchString: '',
            isSearchingRecipients: false,
            currentRecipientCategory: 'all'
        };
    }

    // APIS
    getGroups() {
        const { currentTeam } = this.props;
        return API.get("groups", `/programs/${currentTeam.id}/groups`);
    }

    deleteGroup(groupId) {
        return API.del("groups", `/groups/${groupId}`);
    }

    getPlayers(id){
        const teamId = id ? id : this.props.currentTeam.id;
        return Auth.currentSession()
            .then(auth =>{
                const options = {
                    headers: {
                        Authorization: auth.idToken.jwtToken,
                    },
                }
                return API.get("programs", `/programs/${teamId}/players`);
            })
    }

    getCoaches(programId) {
        return API.get("programs", `/programs/${programId}/coaches`);
    }

    saveGroup(group) {
        const { currentTeam } = this.props;
        if(!group.id){
            return API.post("groups", `/programs/${currentTeam.id}/groups`, {
                body: group
            });
        }
        else {
            return API.put("groups", `/groups/${group.id}`,{
                body: group
            });
        }
    }

    handleTabValueChange = (product, value) => {
        this.setState({ value });
    };

    async handleDeleteGroup(group){
        var deleteGroup = await this.deleteGroup(group.id)
        var elementPos = this.state.groups.map(function(x) {return x.id; }).indexOf(group.id);
        this.state.groups.splice(elementPos, 1)
        this.setState({groups: this.state.groups})
    }

    componentWillMount = async () => {
        const { currentTeam } = this.props
        const { coachList, parentList, athleteList, recipients } = await resolveUserList(currentTeam, 'all')
        this.setState({
            renderedCoaches: coachList,
            renderedParents: parentList,
            renderedAthletes: athleteList,
            currentRecipientCategory: 'all',
            potentialRecipientsList: recipients
        })
    }

    async componentWillReceiveProps(props, nextProps){
        if(this.state.currentTeam && props.currentTeam && props.currentTeam.id !== this.state.currentTeam)
            if(props.currentTeam.id !== this.state.currentTeam){
                this.setState({ isLoading: true, loadingMessage: 'hang tight while we grab your groups...' });
                await this.componentDidMount()  // Force an update of the component when team changes
                this.setState({ isLoading: false });
            }
    }

    async componentDidMount(){
        this.setState({currentTeam: this.props.currentTeam.id, isLoading: true, loadingMessage: 'hang tight while we grab your groups...'})
        var programId = this.props.currentTeam.id
        this.setState({programId})
        try{
            var groups = await this.getGroups()
            this.setState({
                groups: groups,
                isLoading: false,
                renderedGroups: groups
            })
        }
        catch(e){
            console.log('ERROR =>', e)
        }
    }

    toggleRecipientFilter = () => {
        const { isSearchingRecipients } = this.state
        this.setState({ isSearchingRecipients: !isSearchingRecipients })
    }

    closeRecipientFilter = () => {
        this.setState({ isSearchingRecipients: false })
    }

    handleCategoryChange = async (category) => {
        const { currentTeam } = this.props
        const { renderedAthletes, renderedCoaches, renderedParents } = this.state

        const {
            coachList,
            parentList,
            recipients,
            athleteList,
        } = await resolveUserList(currentTeam, category, renderedCoaches, renderedAthletes, renderedParents)

        this.setState({
            loadingRecipients: false,
            currentRecipientCategory: category,
            potentialRecipientsList: recipients
        })
    }

    handleRecipientClick = user => {
        const { currentGroupMembers } = this.state
        let newGroupMembers = []
        const alreadyIncluded = currentGroupMembers.find(u => u.id == user.id)
        if(alreadyIncluded)
            newGroupMembers = _.without(currentGroupMembers, alreadyIncluded)
        else
            newGroupMembers = currentGroupMembers.concat([user])
        this.setState({ currentGroupMembers: newGroupMembers })
    }

    handleRecipientSearch = async (event = {}) => {
        const { target: { value = '' }} = event
        const {
            renderedParents,
            renderedCoaches,
            renderedAthletes,
            isSearchingRecipients,
            potentialRecipientsList,
            currentRecipientCategory
        } = this.state

        const data = {
            value,
            currentRecipientCategory,
            parents: renderedParents,
            coaches: renderedCoaches,
            athletes: renderedAthletes,
            isCoachup: window.location.href.toLowerCase().includes('coachup')
        }

        this.setState({ recipientSearchString: value })

        if(!isSearchingRecipients)
            this.setState({ isSearchingRecipients: true })

        let newRecipients = await resolveFilteredRecipientList(data)

        this.setState({ potentialRecipientsList: newRecipients })
    }

    resetGroupModal = () => {
        this.setState({
            group: {},
            groupName: '',
            currentGroupMembers: []
        })
    }

    toggleGroupModal = async group => {
        const { currentTeam } = this.props;

        if(group){
            const { renderedAthletes = [], renderedCoaches = [], renderedParents = [] } = this.state
            const currentGroupMembers = await resolveGroupMembers(group, renderedAthletes, renderedCoaches, renderedParents)
            this.setState({
                group: group,
                currentGroupMembers,
                groupName: group.name
            })
        }
        else{
            this.resetGroupModal()
        }

        this.setState({
            isGroupModalOpen: !this.state.isGroupModalOpen
        })
    }

    handleGroupSave = async () => {
        const { currentTeam, currentUser } = this.props
        const { group, groupName, renderedAthletes, renderedCoaches, renderedParents, currentGroupMembers } = this.state;

        this.setState({ isSaving: true })

        const participants = await resolveGroupParticipantsForSaving(currentGroupMembers, renderedAthletes, renderedCoaches, renderedParents)

        const modifiers = [];
        currentGroupMembers.forEach(({ id }) => {
            switch (id.toLowerCase()) {
                case 'allathletes':
                    modifiers.push('ALL_ATHLETES');
                    break;
                case 'allcoaches':
                    modifiers.push('ALL_COACHES');
                    break;
                case 'allparents':
                    modifiers.push('ALL_PARENTS');
                    break;
            }
        });

        const groupJSON = {
            "name": groupName,
            "parentId": currentTeam.id,
            "participants": participants,
            "id": group.id ? group.id : null,
            "legacyId": group.legacyId ? group.legacyId : 0,
            modifiers
        };

        const newGroup = await this.saveGroup(groupJSON);
        analyticsService.trackEvent('Group Created', {
            id: newGroup.id,
            name: newGroup.name,
            creator_id: currentUser.id,
            members: newGroup.participants,
        });

        const groups = await this.getGroups();

        this.setState({
            group: {},
            groupName: "",
            groups: groups,
            isSaving: false,
            isLoading: false,
            renderedGroups: groups,
            isGroupModalOpen: false,
            currentGroupMembers: []
        })
    }

    handleGroupNameChange = event => {
        this.setState({ groupName: event.target.value })
    }

    searchGroups = event => {
        const { groups } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedGroups: _.filter(groups, g => g.name.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedGroups: groups
            })
        }
    }

    render() {
        // const { classes, theme, currentTeam, currentUser } = this.props;
        // if (!analyticsService.identified) {
        //   analyticsService.initWithUser(currentUser);  // We need this in case the user enters directly from this page
        // }
        const { classes, theme, currentTeam } = this.props;
        const {
            group,
            value,
            groups,
            isSaving,
            isLoading,
            nameError,
            groupName,
            searchText,
            lengthError,
            coachChecked,
            errorMessage,
            playerChecked,
            selectedGroup,
            loadingMessage,
            renderedGroups,
            renderedCoaches,
            renderedParents,
            isGroupModalOpen,
            renderedAthletes,
            currentGroupMembers,
            recipientSearchString,
            isSearchingRecipients,
            potentialRecipientsList,
            currentRecipientCategory
        } = this.state;

        return (
            <PageWrapper title={getLocalizedString("GROUPS")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <div className={"groupsOuterContainer"}>
                    <AddGroupModal
                        isSaving={isSaving}
                        groupName={groupName}
                        open={isGroupModalOpen}
                        currentTeam={currentTeam}
                        coaches={renderedCoaches}
                        parents={renderedParents}
                        athletes={renderedAthletes}
                        handleGroupSave={this.handleGroupSave}
                        closeRecipientFilter={this.closeRecipientFilter}
                        toggleGroupModal={this.toggleGroupModal}
                        currentGroupMembers={currentGroupMembers}
                        handleNameChange={this.handleGroupNameChange}
                        recipientSearchString={recipientSearchString}
                        isSearchingRecipients={isSearchingRecipients}
                        potentialRecipientsList={potentialRecipientsList}
                        handleRecipientSearch={this.handleRecipientSearch}
                        toggleRecipientFilter={this.toggleRecipientFilter}
                        currentRecipientCategory={currentRecipientCategory}
                        handleRecipientClick={user => this.handleRecipientClick(user)}
                        handleCategoryChange={category => this.handleCategoryChange(category)}
                    />
                    <div>
                        <div className={"pageTitleContainer"} >
                            <h1 className={"pageTitle"}></h1>
                            <TextField
                                id="search"
                                value={searchText ? searchText : ''}
                                className={"searchTextField"}
                                onChange={this.searchGroups}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CloseIcon onClick={() => this.setState({searchText: '', renderedGroups: groups })} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className={"titleButtonContainer"}>
                                <Button
                                    mini
                                    title="Add Group"
                                    variant="fab"
                                    aria-label="add"
                                    className={classes.plusButton}
                                    onClick={() => this.toggleGroupModal()}>
                                    <AddIcon className={"icon"} style={{ color: 'white' }} />
                                </Button>
                            </div>
                        </div>
                        <div className={renderedGroups.length ? "selectContainer" : "emptyGroupsSelectContainer"}>
                            {renderedGroups.length ? renderedGroups.map((group, i) =>
                                    <div key={i}className={"groupGridItem"}>
                                        <GroupCard
                                            group={group}
                                            selected={selectedGroup == group}
                                            edit={() => this.toggleGroupModal(group)}
                                            delete={() => this.handleDeleteGroup(group)}
                                            handleSelect={() => this.setState({ selectedGroup: group })}
                                        />
                                    </div>
                                )
                                :
                                <p className={"emptyListText"}>Hey coach, add some groups...</p>
                            }
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
}

class GroupCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            group: {participants:[]}
        };
    }
    handleDeleteClick() {
        this.props.delete(this.props.athlete);
    }

    handleEditClick(){
        this.props.edit(this.props.athlete);
    }

    async componentDidMount(){
        this.setState({group: this.props.group || {}})
    }

    async componentWillReceiveProps(props, nextProps){
        this.setState({group: props.group})
    }


    render() {
        const { classes, selected } = this.props;
        const { group } = this.state;
        return (
            <div>
                <div className={selected ? "selectedGroupCard" : "groupCard"}>
                    <div onClick={this.props.handleSelect} className={"content"}>
                        <div className={"groupColumnContainer"} >
                            <div className={"titleRow"}>
                                {/* <Avatar style={{ height: 25, width: 25 }}><GroupIcon style={{ height: 25 }}/></Avatar> */}
                                <p className={"title"} style={{marginLeft: 5}}>
                                    {group.name}
                                </p>
                            </div>
                        </div>
                        {selected ?
                            <div className={"groupColumnContainer"} style={{width:'25%', textAlign:'right', paddingRight:30}}>
                                <div>
                                    <IconButton
                                        title="Delete Group"
                                        onClick={() => { if(window.confirm('Are you sure you wish to delete this group?')) this.handleDeleteClick() }}
                                        aria-label="delete"
                                        variant="contained"
                                        className={"scheduleEdit"}
                                    >
                                        <DeleteIcon className={"styledIcon"} />
                                    </IconButton>
                                    <IconButton
                                        title="Edit Group"
                                        onClick={() => this.handleEditClick()}
                                        aria-label="delete"
                                        variant="contained"
                                        className={"scheduleEdit"}
                                    >
                                        <EditIcon className={"styledIcon"} />
                                    </IconButton>
                                </div>
                            </div>
                            :
                            <div className={"groupColumnContainer"} style={{width:'25%', textAlign:'right', paddingRight:30}}>
                                <p className={"title"}>
                                    {group.participants.length} members
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

Groups.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam||{},
        currentUser: state.session.userContext.user
    };
}

export default withStyles(styles,{withTheme:true})(connect(mapStateToProps)(Groups));
