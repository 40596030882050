import React from "react";
import { Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
//TODO: This needs to be removed in future and new modal design should be followed
const LegacyModalContainer = ({containerClassName, isLoading, ...props}) => {
    return (<Modal
        {...props}
        >
        <div style={getModalStyle()} className={containerClassName}>
            {isLoading ? <CircularProgress className={'loadingCircle'} /> : props.children}
        </div>
    </Modal>)
}


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


export default LegacyModalContainer;
