import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import './chat.css'
import _ from "lodash";
import { API, Storage } from "aws-amplify";
import { graphql, compose } from 'react-apollo'
import SearchIcon from '@material-ui/icons/Search';
import SwipeableViews from 'react-swipeable-views';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import {
    Tab,
    Tabs,
    Chip,
    Grid,
    List,
    Modal,
    Avatar,
    Drawer,
    Button,
    Tooltip,
    Divider,
    Checkbox,
    ListItem,
    TextField,
    IconButton,
    withStyles,
    ListItemText,
    ListItemIcon,
    ListSubheader,
    InputAdornment,
    CircularProgress
} from "@material-ui/core";

import {
    registerUser,
    createConvo,
    createConvoLink,
    updateConvoLink
  } from '../graphql/mutations'

import LegacyModalContainer from '../../../../components/modalContainer/legacyModalContainer';

const styles = theme => ({
    textButton: {
        color: 'white',
        marginRight: '3px',
        backgroundColor: "#1354F9",
    },
    secondaryTextButton: {
        height: 40,
        marginRight: '3px',
        backgroundColor: "#27AE60",
        color:"#ffffff",
        '&:disabled': {
            opacity:"0.3",
            color:"#ffffff"
        }
    },
});

const convoList = {}

class NewChatGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            group: {},
            coaches: [],
            athletes: [],
            errorMessage: "",
            isLoading: false,
            nameError: false,
            lengthError: false,
            renderedCoaches: [],
            renderedAthletes: [],
            isGroupModalOpen: true,
        };
    }

    async componentDidMount(){
        if(this.props.location.state){
            const group = this.props.location.state.group;
            const renderedCoaches = this.props.location.state.renderedCoaches;
            const renderedAthletes = this.props.location.state.renderedAthletes;
            const userConversations = this.props.location.state.userConversations;

            this.setState({
                group: group,
                coaches: renderedCoaches,
                athletes: renderedAthletes,
                renderedCoaches: renderedCoaches,
                renderedAthletes: renderedAthletes,
                userConversations: userConversations
            })
        }
    }

    // APIS START //
    async deleteChatGroupEnrollment(userId, group){
        return API.del("chat", `/users/${userId}/conversation/${group.id}/link`);
    }
    // APIS END //

    // SAVING GROUP LOGIC START //
    saveGroup = async (group, chipData) => {
        let updatedConversations = this.state.userConversations;
        if(this.state.chipData === 0){
            return this.setState({lengthError: true, errorMessage: "Please select at least one player or coach."})
        }
        else if((!group.id && !group.name)){
            return this.setState({nameError: true, errorMessage: "Group must have a name"})
        }

        this.setState({
            isLoading: true,
            errorMessage: '',
            nameError: false,
            lengthError: false,
        })

        let newConversation = await this.launchNewGroupConversation(group, chipData);

        this.props.history.push('/chat', {group: group});
    }

    launchNewGroupConversation = (group, chipData) => {
        let resolveFn
        const promise = new Promise((resolve, reject) => {
          resolveFn = resolve
        })
        const me = this.props.data ? this.props.data.getUser : {};
        const chatName = group.name;
        this.props.createConvo({
            variables: {
              input: { name: chatName ? chatName : "", teamId: this.props.currentTeam.id ?  this.props.currentTeam.id : ""}
            },
            optimisticResponse: {
              createConvo: {
                id: '-1',
                name: chatName,
                createdAt: '',
                teamId: this.props.currentTeam.id,
                __typename: 'Conversation',
                associated: {
                  __typename: 'ModelConvoLinkConnection',
                  items: []
                }
              }
            },
          update: async (proxy, { data: { createConvo } }) => {
            if (createConvo.id === '-1' || convoList[`${createConvo.id}`]) return;

            convoList[`${createConvo.id}`] = true;
            const links = await Promise.all(chipData.map(async user => {
              return this.linkNewConversation(createConvo.id, user.id, chatName);
            }))

            const promises = links.map(c => this.updateToReadyConversation(c, chatName));
            const convoLinks = await Promise.all(promises);

            resolveFn({
              conversation: convoLinks[0].conversation,
              conversationName: chatName
            })
          }
        })
        return promise
    }

    linkNewConversation = (convoId, userId, chatName) => {
        let resolveFn
        const promise = new Promise((resolve, reject) => {
            resolveFn = resolve
        })
        this.props.createConvoLink({
            variables: { convoId, userId, name: chatName },
            optimisticResponse: {
                createConvoLink: {
                __typename: 'ConvoLink',
                id: '-1',
                status: 'PENDING',
                name: chatName,
                conversation: {
                    __typename: 'Conversation',
                    id: convoId,
                    name: chatName,
                    teamId: this.props.currentTeam.id,
                    createdAt: '',
                    associated: {
                    __typename: 'ModelConvoLinkConnection',
                    items: []
                    }
                }
                }
            },
            update: async (proxy, { data: { createConvoLink } }) => {
                if (createConvoLink.id === '-1') {
                return
                }
                resolveFn(createConvoLink)
            }
        })
        return promise
      }

    updateToReadyConversation = (convoLink, chatName) => {
        let resolveFn
        const promise = new Promise((resolve, reject) => {
            resolveFn = resolve
        })

        this.props.updateConvoLink({
            variables: { id: convoLink.id },
            optimisticResponse: {
                updateConvoLink: {
                __typename: 'ConvoLink',
                id: convoLink.id,
                name: chatName,
                convoLinkUserId: '-1',
                status: 'CONFIRMING',
                conversation: {
                    __typename: 'Conversation',
                    id: convoLink.conversation.id,
                    name: chatName,
                    teamId: this.props.currentTeam.id,
                    createdAt: '',
                    associated: {
                    __typename: 'ModelConvoLinkConnection',
                    items: []
                    }
                }
                }
            },
            update: async (proxy, { data: { updateConvoLink } }) => {
                if (updateConvoLink.status === 'READY') {
                resolveFn(updateConvoLink)
                }
            }
        })
        return promise
    }
    // SAVING GROUP LOGIC END //

    deleteConvoLink = async (userData, group) => {
        this.deleteChatGroupEnrollment(userData.id, group)
    }

    // UI HELPERS START //
    searchAthletes = event => {
        const { athletes, coaches, value } = this.state;
        if(event.target.value){
            console.log('searching athletes', event.target.value)
            this.setState({
                renderedAthletes: _.filter(athletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            console.log('searching athletes without value')
            this.setState({
                renderedAthletes: athletes
            })
        }
    }

    searchCoaches = event => {
        const { coaches, value } = this.state;
        if(event.target.value){
            this.setState({
                renderedCoaches: _.filter(coaches, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                renderedCoaches: coaches
            })
        }
    }
    // UI HELPERS END //

    render() {
        const {
            value,
            group,
            isLoading,
            nameError,
            lengthError,
            errorMessage,
            loadingMessage,
            renderedCoaches,
            renderedAthletes,
            isGroupModalOpen,
            userConversations,
        } = this.state;

        const { classes } = this.props;

        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div />
                <div style={{
                    width: 260,
                    height: `100vh`,
                    marginRight: -20,
                    padding: '20px 20px',
                    backgroundColor: '#28272C'
                }}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                        <div style={{ height: 15, width: 180, backgroundColor: 'white', borderRadius: 10, marginBottom: 40 }} />
                        <div style={{ height: 15, width: 120, backgroundColor: 'lightgray', borderRadius: 10, marginLeft: 10, marginBottom: 20 }} />
                        <div style={{ height: 15, width: 120, backgroundColor: 'lightgray', borderRadius: 10, marginLeft: 10, marginBottom: 20 }} />
                        <div style={{ height: 15, width: 120, backgroundColor: 'lightgray', borderRadius: 10, marginLeft: 10, marginBottom: 20 }} />
                    </div>
                </div>
                <AddGroupModal
                    classes={classes}
                    value={value}
                    group={group}
                    isLoading={isLoading}
                    nameError={nameError}
                    open={isGroupModalOpen}
                    lengthError={lengthError}
                    groups={userConversations}
                    errorMessage={errorMessage}
                    loadingMessage={loadingMessage}
                    coaches={renderedCoaches}
                    athletes={renderedAthletes}
                    searchCoaches={this.searchCoaches}
                    userId={this.props.currentUser.id}
                    searchAthletes={this.searchAthletes}
                    close={() => this.props.history.push('/chat')}
                    save={(group, chipData) => this.saveGroup(group, chipData)}
                    deleteConvoLink={userData => this.deleteConvoLink(userData, group)}
                />
            </div>
        )
    }
}

class AddGroupModal extends Component {
    constructor(props){
      super(props);
      this.state = {
        value: 0,
        group: {},
        chipData:[],
        loaded: false
      };
    }

    saveGroup(group){
        if(group.name === "" || !group.name){
            alert("Your conversation must have a name")
        }
        else{
            var groups = this.props.groups || []
            var nameExists = _.find(groups.items, item => item.name.toLowerCase() == group.name.toLowerCase());
            if(!nameExists){
                this.setState({errorMessage: ""})
                this.props.save(group, this.state.chipData)
            }
            else{
                alert("Your conversation must have a unique name")
                this.setState({errorMessage: "Name already exists."})
            }
        }
    }

    async componentWillReceiveProps(props, nextProps){
        if(!this.state.group) {
            this.setState({group: props.group})
        }
        this.setState({athletes:props.athletes, coaches: props.coaches, viewingGroup:props.viewingGroup})
        if(props.group && props.group.associated && props.group.associated.items.length){
            var chipData = []
            var userId = this.props.userId;
            let user = _.find(props.coaches, c => c.id == userId);
            chipData.push(user)
            if(props.group && props.group.associated && props.group.associated.items.length){
                await Promise.all(props.group.associated.items.map(async link => {
                    let user = _.find(props.coaches, c => c.id == link.convoLinkUserId);
                    if(!user) user = _.find(props.athletes, a => a.id == link.convoLinkUserId);
                    const match = _.find(chipData, user)
                    if(!match) chipData.push(user)
                }))
            }
            this.setState({
                loaded: true,
                chipData: chipData
            })
        }
        else {
            var chipData = []
            var userId = this.props.userId;
            let user = _.find(props.coaches, c => c.id == userId);

            chipData.push(user)
            this.setState({
                loaded: true,
                chipData: chipData
            })
        }
    }

    handleTabValueChange = (product, value) => {
        this.setState({ value });
    };

    handleChange = name => event => {
        this.setState({
            group: {
                [name]: event.target.value
            },
        });
    };

    handleDelete = data => {
        var { athletes, coaches } = this.state;
        this.setState(state => {
            const chipData = [...state.chipData];
            const chipToDelete = chipData.indexOf(data);
            chipData.splice(chipToDelete, 1);
            if(data.type === "athlete"){
            this.setState({athletes})
            }
            else if(data.type === "coach"){
            this.setState({coaches})
            }
            if(this.props.group.id){
            this.props.deleteConvoLink(data)
            }
            return { chipData };
        });
    };

    handleAddChip = (data, type) => {
        var { athletes, coaches } = this.state;
        this.setState(state => {
            const chipData = [...state.chipData];
            if(type === "athlete"){
            var index = athletes.indexOf(data);
            this.setState({athletes})
            }
            else if(type === "coach"){
            var index = coaches.indexOf(data);
            this.setState({coaches})
            }
            data.type = type
            chipData.push(data)
            return { chipData };
        });
    };

    render(){
        const { chipData, group, value } = this.state;
        const { close, classes, athletes, coaches, groupName, isLoading, loadingMessage, errorMessage, lengthError, nameError, userId } = this.props;

        return(
            <LegacyModalContainer open={this.props.open} onClose={close} containerClassName={"groupsPaper"}>
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>{group.name ? group.name : "New Conversation"}</h2>
                </div>
                {
                isLoading
                ?
                    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress  />
                        <h2>{loadingMessage}</h2>
                    </div>
                :
                <div className={"outerModalContainer"}>
                    <div className={"nameInputContainer"} style={nameError ? { marginTop: 40 } : {}}>
                        <TextField
                        required
                        id="groupName"
                        margin="normal"
                        error={nameError}
                        label="Group Name"
                        style={{width:'100%'}}
                        helperText={nameError ? errorMessage : ''}
                        value={groupName ? groupName : group.name ? group.name : ""}
                        onChange={this.handleChange("name")}
                        />
                    </div>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabValueChange}
                        className={"tabContainer"}
                    >
                        <Tab label="Athletes" value={0} />
                        <Tab label="Coaches" value={1} />
                    </Tabs>
                    <SwipeableViews index={value}>
                    <div className={"listAndSearchContainer"}>
                        <List className={"userListContainer"}>
                        {athletes ? _.orderBy(_.uniqBy(athletes, 'id'), 'graduationYear', 'nameLast').map((athlete, i) =>
                            <ListItem
                            key={i}
                            role={undefined}
                            dense
                            button
                            onClick={()=>{
                                if(userId == athlete.id) return
                                if(!!_.find(chipData, c => { return c && athlete && c.id == athlete.id })){
                                    this.handleDelete(athlete)
                                }
                                else {
                                    this.handleAddChip(athlete, "athlete")
                                }

                            }}
                            className={classes.listItem}
                            >
                            <Checkbox
                                tabIndex={-1}
                                disableRipple
                                checked={!!_.find(chipData, c => { return c && athlete && c.id == athlete.id })}
                            />
                            {
                                athlete.avatarUrl && athlete.avatarUrl.includes('https://') ? <Avatar alt={athlete.nameFirst} src={athlete.avatarUrl} className={classes.userImage}/> :
                                <Avatar className={classes.userImage}><PersonOutlineIcon /></Avatar>
                            }
                            <ListItemText primary={ `${athlete.nameFirst} ${athlete.nameLast}`} secondary={athlete.graduationYear} />
                            </ListItem>
                        ) : null}
                        </List>
                        <div>
                        <TextField
                            id="search"
                            margin="normal"
                            error={lengthError}
                            style={{width:'100%'}}
                            helperText={lengthError ? errorMessage : ''}
                            onChange={value == 0 ? this.props.searchAthletes : this.props.searchCoaches}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        </div>
                    </div>
                    <div className={"listAndSearchContainer"}>
                        <List className={"userListContainer"}>
                            {coaches ? _.orderBy(_.uniqBy(coaches, 'id'), 'nameLast').map((coach, i) =>
                            <ListItem
                                key={i}
                                role={undefined}
                                dense
                                button
                                onClick={()=> {
                                if(userId == coach.id) return
                                if(!!_.find(chipData, c => { return c && coach && c.id == coach.id })){
                                    this.handleDelete(coach)
                                }
                                else {
                                    this.handleAddChip(coach, "coach")
                                }
                                }}
                                className={classes.listItem}
                            >
                                <Checkbox
                                    tabIndex={-1}
                                    disableRipple
                                    checked={!!_.find(chipData, c => { return c && coach && c.id == coach.id })}
                                />
                                {
                                coach.avatarUrl ? <Avatar alt={coach.nameFirst} src={coach.avatarUrl} className={classes.userImage}/> :
                                <Avatar className={classes.userImage}><PersonOutlineIcon /></Avatar>
                                }
                                <ListItemText primary={ `${coach.nameFirst} ${coach.nameLast}`} />
                            </ListItem>
                            ) : null}
                        </List>
                        <div>
                            <TextField
                                id="search"
                                margin="normal"
                                error={lengthError}
                                style={{width:'100%'}}
                                helperText={lengthError ? errorMessage : ''}
                                onChange={value == 0 ? this.props.searchAthletes : this.props.searchCoaches}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    </SwipeableViews>
                    <div className={"modalFooterContainer"}>
                        <Button
                            type="submit"
                            className={"cancelButton"}
                            onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className={classes.secondaryTextButton}
                            onClick={() => this.saveGroup(group)}>
                            Save
                        </Button>
                    </div>
                </div>
                }
            </LegacyModalContainer>
        )
    }
}

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        currentTeam: currentTeam||{},
        currentUser: userContext.user,
        appContext: appContext
    };
}

const NewChatGroupWithData = compose(
    graphql(createConvo,
      {
        name: 'createConvo',
        options: props => ({
          ignoreResults: true
        })
      }),
    graphql(createConvoLink, {
      name: 'createConvoLink',
      options: props => ({
        ignoreResults: true
      })
    }),
    graphql(updateConvoLink, {
      name: 'updateConvoLink',
      options: props => ({
        ignoreResults: true
      })
    }),
    withStyles(styles),
    connect(mapStateToProps)
  )(NewChatGroup)

  export default NewChatGroup
  export { NewChatGroupWithData }
