import React, { Component } from 'react';
// import loadingGif from '../../assets/images/app/loading.gif';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

let styles = {
    loaderContainer: {
        position: "fixed",
        width:"100vw",
        height:"100vh",
        top:0,
        left:0,
        background: "rgba(0,0,0, 0.6)",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        zIndex:"1400",
        "& img":{
            width:"98px",
            height:"98px"
        }
    },
    message:{
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "150%",
        marginBottom:"5px"
    }
};

const Loader = ({ message, message2, classes, isActive=true }) => {
    return (
        isActive? 
        <div className = {classes.loaderContainer } >
            {/* <img src={loadingGif} /> */}
            <CircularProgress style={{color: 'rgb(0, 161, 225)'}}/>
           {!!message && <p className={classes.message}>{message}</p>}
           {!!message2 && <p className={classes.message}>{message2}</p>}
        </div >
        : 
        null
    )
}

export default withStyles(styles)(Loader);