export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ops-api.maxone.io"
  },
  apiGatewayActivities: {
    URL: "https://ops-api.gomaxone.com/activities",
    REGION:"us-east-1"
  },
  apiGatewayWorkoutPrograms: {
    URL: "https://ops-api.gomaxone.com/workoutPrograms",
    REGION:"us-east-1"
  },
  apiGatewayWorkouts: {
    URL:"https://ops-api.gomaxone.com/workouts",
    REGION:"us-east-1"
  },
  apiGatewayWorkoutSchedules: {
    URL: "https://ops-api.gomaxone.com/workoutSchedules",
    REGION:"us-east-1"
  },
  apiGatewayTaggings: {
    URL:"https://ops-api.gomaxone.com/taggings",
    REGION:"us-east-1"
  },
  apiGatewayPrograms: {
    URL: "https://ops-api.gomaxone.com/programs",
    REGION:"us-east-1"
  },
  cognito: {
    USER_POOL_ID: "eu-west-1_LpnoUz5SR",
    APP_CLIENT_ID: "25hu8srkb29vbvqcdhe012psho",
    IDENTITY_POOL_ID: "us-east-1:9c2be610-ca5a-4bab-a152-b950357779a3"
  },
  segment:{
    KEY: "uRFYrFGabbiP8lOB00v6cON3YBb4CKbU"
  }
}
