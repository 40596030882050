import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Collapse,
} from '@material-ui/core';
import '../index.scss';
import MenuItem from './MenuItem'
import classNames from 'classnames';


const Component = ({ hasActiveValue, label, list, isOpen, handleClick, isEditable, handleSaveClick, cancelChanges, handleEditClick }) => {

    const [expanded, setExpanded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showVisibleIcon, setIsVisible] = useState(false);
    const [save, setSave] = useState(false);
    const handleClickIcon = () => {
        // setExpanded(true);
        if (isEdit) {
            cancelChanges();
            setIsEdit(false);
            setIsVisible(false);
            setSave(false);
        } else {
            setIsEdit(true);
            setIsVisible(true)
        }



    }
    const handleVisiblity = data => {
        setSave(true);
        handleEditClick(data)
    }
    const saveChanges = () => {
        setSave(false);
        setIsVisible(false);
        setIsEdit(false);
        handleSaveClick();
    }
    return (
        <List dense={true} className={classNames({ hasActiveValue: hasActiveValue })}>
            {isOpen ? <MenuItem
                isOpen={isOpen}
                header={true}
                label={label}
                rightIcon={list.length ? expanded ? "ExpandLess" : "ExpandMore" : ""}
                handleClick={() => list.length ? setExpanded(!expanded) : handleClick()}
                middleIcon={list.length && expanded && isOpen && isEditable ? isEdit ? 'CancelCircle' : 'PencilIcon' : ''}
                handleClickMiddleIcon={() => expanded && isEditable && isOpen ? handleClickIcon() : () => { }}
                iconNextToLabel={list.length && expanded && isEditable && isOpen && save && isEdit ? 'CheckMark' : ''}
                handleClickIconNextToLabel={() => expanded && isEditable && isOpen ? saveChanges() : () => { }}
                isEditable={isEditable}
                isInEditMode={isEdit}
            /> : null}
            {(list || []).length ? <Collapse in={expanded || !isOpen} timeout="auto" unmountOnExit>
                {(list || []).map((item, index) =>
                    <MenuItem
                        key={index}
                        isOpen={isOpen}
                        disable={isEdit && showVisibleIcon && item.hideForCoaches}
                        leftCornerIcon={isEdit && showVisibleIcon ? item.hideForCoaches ? 'VisibilityOff' : 'Visibility' : ''}
                        handleLeftCornerIconClick={() => { handleVisiblity(item) }}
                        isInEditMode={isEdit}
                        isEditable={isEditable}
                        {...item}
                    />)}
            </Collapse> : null}
        </List>
    )
}

Component.propTypes = {
    isOpen: PropTypes.bool,
    leftIcon: PropTypes.string,
    label: PropTypes.string,
    hasActiveValue: PropTypes.bool,
    handleClick: PropTypes.func,
    list: PropTypes.arrayOf(PropTypes.shape({
        isOpen: PropTypes.bool,
        header: PropTypes.bool,
        label: PropTypes.string,
        selected: PropTypes.bool,
        leftIcon: PropTypes.string,
        rightIcon: PropTypes.string,
        handleClick: PropTypes.func
    }))
};

Component.defaultProps = {
    isOpen: true,
    leftIcon: "",
    label: "",
    hasActiveValue: false,
    list: [],
    handleClick: () => { },
    isEditable: false,
};

export default Component
