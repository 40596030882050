import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ProgressStep from '../progressStep';
import HeaderWrapper from "../headerWrapper";
import SportsEngineBtn from '../../../components/themeButton';
import CSVReader from 'react-csv-reader'
const styles = {
    headerAdditionalInfo: {
        marginLeft: "110px",
        paddingRight:"24px",
        textAlign: "left",
        paddingBottom: "30px",
        marginTop: "10px",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#27292A"
    },
    nativeFileInput: {
        display:"none"  
    },
    inputClickContainer:{
        background: "#FFFFFF",
        border: "1px solid #DDE2E5",
        boxsizing: "border-box",
        borderRadius: "8px",
        width:"100vw",
        maxWidth:"720px",
        height:"62px",
        position:'relative',
        padding:"15px",
        fontSize:"24px",
        lineHeight:"150%",
        display: "flex",
        alignItems: "center"
    },
    label:{
        color:"rgba(11, 11, 11,0.3)",
        "&.hasContent":{
            position: "absolute",
            transform: "translateY(-19px)",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "180%",
            color: "rgba(11,11,11,0.3)"
        }
    },
    csvReaderContainer:{
        "& label":{
            marginBottom:0
        }
    },
    content:{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
};

function Step1(props) {
    const { classes, parseCSVFile, fileName, uploadCSVFile, currentStep, totalSteps } = props;

    return (
        <React.Fragment>
            <HeaderWrapper>
                <ProgressStep stepDetails={{
                    "title": "Upload your CSV File",
                    "nextStep": "Field Mapping"
                }} currentStep={currentStep} totalSteps={totalSteps} />
                <div className={classes.headerAdditionalInfo}>
                    Select your CSV file and upload it to proceed to the next step.
                </div>
                <div className={classes.headerAdditionalInfo}>
                    <div className={classes.fileNameContainer}>
                        <div className="d-flex justify-content-start align-items-center">
                            <CSVReader cssClass={classes.csvReaderContainer}
                                cssInputClass={classes.nativeFileInput}
                                onFileLoaded={parseCSVFile}
                                onError={(error)=>{ console.log(error); }}
                                label={
                                    <div className={classes.inputClickContainer}>
                                        <div className={classnames(classes.label, {'hasContent': !!fileName})}>Select CSV File</div>
                                        <div className={classes.content}>
                                            {fileName}
                                        </div>
                                    </div>
                                }
                                />
                            <SportsEngineBtn
                                disabled={!fileName}
                                width={"198px"}
                                height={"62px"}
                                iconName="Download"
                                onClick={uploadCSVFile}
                                text="Upload CSV"
                            />
                        </div>
                    </div>
                    
                </div>
            </HeaderWrapper>          
        </React.Fragment>
    );
}

export default withStyles(styles)(Step1);