import _ from 'lodash'

import moment from 'moment'
import momentTZ from 'moment-timezone';

import {
    getGroup,
    getGuardians,
    getGroupsForTeam,
    getCoachesForTeam,
    getUserByUsername,
    getAthletesForTeam,
    getCoachesForOrganization,
    getAthletesForOrganziation,
    getUserById
} from '../services/usersService'

import {
    getMessages,
    sendMessage,
    saveMessage,
    deleteMessage,
    scheduleMessage,
    sendPushNotification,
    getScheduledMessages,
    deleteScheduledMessage,
    getMessagesWithPagination,
    getDraftMessages
} from '../services/messagesService'

import {
    getTeamsByOrganization,
    getGroupsForOrganization,
    getFullOrganizationRoster
} from '../services/organizationService'

import {
    analyticsService,
    currentTimestamp
} from '../services/analyticsService';
import { ALL_USERS_GROUPS } from '../constants';

import { TimeHours } from '../constants'
import { getTenant } from '../utils/commonUtil'

const isCoachup = window.location.href.toLowerCase().includes('coachup')

export function resolveContext(href){
    let context = _.last(href.split('/')) == 'messages' ? 'team' : 'organization'
    return context
}

export function resolveTeams(organizationId){
    const teams = getTeamsByOrganization(organizationId)
    return teams
}

export function resolveTabCount(tabName, scheduledMessages, draftMessages, totalSentMessagesCount){
    switch(tabName){
        case 'Drafts':
            return draftMessages.length
        case 'Scheduled':
            return scheduledMessages.length
        case 'Sent':
            return totalSentMessagesCount
        default:
            return 0
    }
}

export async function resolveRecipientList(team, context, category, groups = [], coaches = [], athletes = [], parents = [], teams = [], currentTeamFilter, positionFilter, forceRefresh = false){
    let groupList = []
    let coachList = []
    let parentList = []
    let athleteList = []
    let positionList = []

    const allCoaches = {...ALL_USERS_GROUPS.allCoaches}
    const allParents = {...ALL_USERS_GROUPS.allParents}
    const allAthletes = {...ALL_USERS_GROUPS.allAthletes}

    let entityId = context == 'organization' ? team.organizationId : team.id

    let filteredAthletes = currentTeamFilter ? athletes.filter(a => a.roles && a.roles.find(r => r.parentId == currentTeamFilter.id)) : athletes
        filteredAthletes = positionFilter ? filteredAthletes.filter(a => a && a.position == positionFilter.name) : filteredAthletes
    let filteredCoaches = currentTeamFilter ? coaches.filter(a => a.roles && a.roles.find(r => r.parentId == currentTeamFilter.id)) : coaches
    let filteredGroups = currentTeamFilter ? groups.filter(g => g.parentId == currentTeamFilter.id) : groups
    let filteredParents = currentTeamFilter ? parents.filter(p => p.athlete && p.athlete.roles && p.athlete.roles.find(r => r.parentId == currentTeamFilter.id)) : parents

    let returnValues = {
        groupList: groups,
        coachList: coaches,
        parentList: parents,
        athleteList: athletes,
        positionList: _.uniqBy(athletes.map(a => { if(a && a.position){ return { name: a.position, id: a.position } }}), p => p && p.name.toLowerCase()).filter(p => p)
    }

    switch (category){
        case 'athletes':
            returnValues.recipients = filteredAthletes
            return returnValues
        case 'coaches':
            returnValues.recipients = filteredCoaches
            return returnValues
        case 'parents':
            returnValues.recipients = filteredParents
            return returnValues
        case 'groups':
            returnValues.recipients = filteredGroups
            return returnValues
        default:
            if(athletes.length > 0 && !forceRefresh){
                athleteList = athletes
                coachList = coaches
                parentList = parents
                groupList = groups
            }
            else if ((athletes.length == 0 || forceRefresh) && context == 'organization'){
                let fullRoster = await getFullOrganizationRoster(entityId)
                athleteList = fullRoster.athletes
                coachList = fullRoster.coaches
                groupList = fullRoster.groups
                parentList = fullRoster.parents
            }
            else if(forceRefresh || context !== 'organization'){
                groupList = await getGroupsForTeam(entityId)
                coachList = await getCoachesForTeam(entityId)
                athleteList = await getAthletesForTeam(entityId)
                await Promise.all(athleteList.filter(a => a).map(async a => {
                    let guardians = await getGuardians(a.id)
                    guardians.filter(g => g).map(g => g.athlete = a )
                    parentList = parentList.concat(guardians)
                }))
                groupList.filter(g => g).map(g => g.secondaryText = `${g.participants.length} Users`)
                coachList.filter(c => c).map(c => { if(c){ c.secondaryText = 'Coach'; c.name = `${c.nameFirst} ${c.nameLast}` } })
                athleteList.filter(a => a).map(a => { if(a){ a.secondaryText = 'Athlete'; a.name = `${a.nameFirst} ${a.nameLast}` } })
                parentList.filter(p => p).map(p => { if(p){ p.secondaryText = 'Parent'; if(p) p.name = `${p.nameFirst} ${p.nameLast}` } })
            }

            if(!athleteList.find(a => a && a.name.includes('All Athletes'))){
                allAthletes.secondaryText = `${athleteList.length} Athletes`
                athleteList.push(allAthletes)
            }
            if(!coachList.find(c => c && c.name.includes('All Coaches'))){
                allCoaches.secondaryText = `${coachList.length} Coaches`
                coachList.push(allCoaches)
            }

            if(!parentList.find(p => p && p.name.includes('All Parents'))){
                allParents.secondaryText = `${parentList.length} Parents`
                parentList.push(allParents)
            }

            positionList = _.uniqBy(athleteList.map(a => { if(a && a.position){ return { name: a.position, id: a.position }}}), p => p && p.name.toLowerCase()).filter(p => p)

            coachList = isCoachup ? [] : _.orderBy(_.uniqBy(coachList, 'name'), ['orderOnTop','name'])
            parentList = isCoachup ? [] : _.orderBy(_.uniqBy(parentList, 'name'), ['orderOnTop','name'])
            return {
                positionList: positionList,
                groupList: _.orderBy(_.uniqBy(groupList, 'id'), ['orderOnTop','name']),
                coachList: _.orderBy(_.uniqBy(coachList, 'id'), ['orderOnTop','name']),
                parentList: _.orderBy(_.uniqBy(parentList, 'id'), ['orderOnTop','name']),
                athleteList: _.orderBy(_.uniqBy(athleteList, 'id'), ['orderOnTop','name']),
                recipients: _.orderBy(athleteList.concat(parentList, coachList), ['orderOnTop','name'])
            }
    }
}

export async function resolveSelectedRecipientsList(recipient, selectedRecipients = []){
    if(selectedRecipients){
        let selected = selectedRecipients.find(r => r === recipient)

        if(selected)
            return _.without(selectedRecipients, recipient)

        return selectedRecipients.concat([recipient])
    }

}

export async function resolveFilteredRecipientList(data){
    const { coaches, athletes, parents, groups, all, value, currentRecipientCategory, currentTeamFilter, positionFilter } = data

    console.log(currentTeamFilter)

    let returnValue = []
    let potentialRecipientsList = []
    if(!value || value == ''){
        switch(currentRecipientCategory){
            case 'all':
                returnValue = currentTeamFilter ? all.filter(a => a.roles && a.roles.find(r => r.parentId == currentTeamFilter.id)) : all
                returnValue = positionFilter ? returnValue.filter(a => a.position && a.position.toLowerCase() == positionFilter.name.toLowerCase()) : returnValue
                return _.orderBy(returnValue, ['orderOnTop','name'])
            case 'coaches':
                returnValue = currentTeamFilter ? coaches.filter(a => a.roles && a.roles.find(r => r.parentId == currentTeamFilter.id)) : coaches
                return _.orderBy(returnValue, ['orderOnTop','name'])
            case 'athletes':
                returnValue = currentTeamFilter ? athletes.filter(a => a.roles && a.roles.find(r => r.parentId == currentTeamFilter.id)) : athletes
                returnValue = positionFilter ? returnValue.filter(a => a.position && a.position.toLowerCase() == positionFilter.name.toLowerCase()) : returnValue
                return _.orderBy(returnValue, ['orderOnTop','name'])
            case 'parents':
                returnValue = currentTeamFilter ? parents.filter(p => p.athlete && p.athlete.roles && p.athlete.roles.find(r => r.parentId == currentTeamFilter.id)) : parents
                return _.orderBy(returnValue, ['orderOnTop','name'])
            case 'groups':
                returnValue = currentTeamFilter ? groups.filter(g => g.parentId == currentTeamFilter.id) : groups
                return _.orderBy(returnValue, ['orderOnTop','name'])
            default:
                returnValue = currentTeamFilter ? all.filter(a => a.roles && a.roles.find(r => r.parentId == currentTeamFilter.id)) : all
                return _.orderBy(returnValue, ['orderOnTop','name'])
        }
    }

    if(currentRecipientCategory == 'all')
        potentialRecipientsList = all
    if(currentRecipientCategory == 'coaches')
        potentialRecipientsList = coaches
    if(currentRecipientCategory == 'athletes')
        potentialRecipientsList = athletes
    if(currentRecipientCategory == 'parents')
        potentialRecipientsList = parents
    if(currentRecipientCategory == 'groups')
        potentialRecipientsList = groups

    let filteredList = potentialRecipientsList.filter(r =>
        r && r.name.toLowerCase().includes(value.toLowerCase()) ||
        r && r.athlete && r.athlete.nameFirst.toLowerCase().includes(value.toLowerCase()) ||
        r && r.athlete && r.athlete.nameLast.toLowerCase().includes(value.toLowerCase())
    )

    returnValue = (currentTeamFilter && (currentRecipientCategory == 'athletes' || currentRecipientCategory == 'coaches')) ?
        filteredList.filter(a => a.roles.find(r => r.parentId == currentTeamFilter.id)) :
        currentTeamFilter && currentRecipientCategory == 'parents' ?
        filteredList.filter(p => p.athlete.roles && p.athlete.roles.find(r => r.parentId == currentTeamFilter.id)) :
        currentTeamFilter && currentRecipientCategory == 'groups' ?
        filteredList.filter(g => g.parentId == currentTeamFilter.id) :
        filteredList

    returnValue = positionFilter ? returnValue.filter(a => a.position && a.position.toLowerCase() == positionFilter.name.toLowerCase()) : returnValue

    return returnValue
}

export async function handleSendMessage(domain, context, parents, coaches, athletes, messageId, currentTeam, messageType, messageString, recipients, recipientType, externalUrl = '', activityId = null, currentUserId){
    console.log('externalUrl ', externalUrl)
    console.log('activityId ', activityId)

    const isAllParentsSelected = _.find(recipients, recipient => recipient.id == 'allParents');
    const isAllCoachesSelected = _.find(recipients, recipient => recipient.id == 'allCoaches');
    const isAllAthletesSelected = _.find(recipients, recipient => recipient.id == 'allAthletes');

    if(isAllAthletesSelected)
        recipients = recipients.concat(athletes)

    if(isAllCoachesSelected)
        recipients = recipients.concat(coaches)

    if(isAllParentsSelected)
        recipients = recipients.concat(parents)

    recipients.map(r => r.role = r.secondaryText.toLowerCase())
    if(messageType == 'pushNotification' || messageType == 'notification'){
        let notificationMessage = {};
        let recipientIdAndRoleList = [];
        //handled group data structure and rest (groups contains participant)
        var participantsList = recipients.map(item => item.participants || item).flat()
        .filter(ele=> !!ele && ele.id != 'allParents' && ele.id != 'allCoaches' && ele.id != 'allAthletes');
        participantsList = _.uniqBy(participantsList, "id");
        participantsList.map(async participant => {
            recipientIdAndRoleList.push({userId:participant.id, role: participant.role});})
        if (recipientIdAndRoleList.length) {
            notificationMessage = {
                id: messageId,
                senderId: currentUserId,
                isDraft: false,
                domain: domain,
                type: messageType,
                sendingFrom: domain,
                sendingBySport: null,
                activityId: activityId,
                teamId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
                message: messageString,
                sendingByUser: 'Multi User',
                recipientIdAndRoleList:recipientIdAndRoleList,
                createdAt: new Date().getTime(),
                externalUrl: externalUrl == '' ? null : externalUrl,
                title: context == 'organization' ? 'Message from your administrator' : 'Message from your coach',
            }
            try {
                const sentNotification = await sendPushNotification(notificationMessage)
            }
            catch (e) {
                console.log('we had trouble sending that notification => ', e)
            }

            notificationMessage.recipients = await resolveMessageRecipients(participantsList)
            trackSegmentMessagingEvent(
                currentUserId,
                recipientIdAndRoleList.map(x=>x.userId),
                messageId,
                messageType,
                'send_now',
                messageString,
                externalUrl,
                activityId
            );
        }
        return notificationMessage
    }
    else {
        var message = {
            id: messageId,
            senderId: currentUserId,
            isDraft: false,
            domain: domain,
            type: messageType,
            message: messageString,
            recipientType: recipientType,
            createdAt: new Date().getTime(),
            currentTeam: context == 'organization' ? currentTeam.organization : currentTeam,
            parentId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
            recipients: recipients.filter(r => r && (r.participants || r.phoneNumber || r.email))
        }

        try{
            const sentMessage = await sendMessage(message)
            trackSegmentMessagingEvent(
                currentUserId,
                message.recipients.map(element => element.id),
                sentMessage.id,
                messageType,
                'send_now',
                messageString
            )
            return sentMessage
        }
        catch(e){
            console.log('error sending that message => ', e)
        }
    }
}

function trackSegmentMessagingEvent(
    sender_id,
    recipients_id,
    id,
    type,
    delivery_type,
    raw_text,
    external_url,
    activity_id){
    let trackingEvent = {
        sender_id,
        recipients_id,
        id,
        type,
        delivery_type,
        raw_text,
      };
      if(type == 'notification' || type == 'pushNotification'){
        trackingEvent.external_url = !!external_url;
        trackingEvent.linked_activity = activity_id? 'yes':'no';
      }
      else {
          trackingEvent.external_url = false
          trackingEvent.linked_activity = false
      }
      analyticsService.trackEvent('Message Sent', trackingEvent);
}

export async function handleSaveMessage(domain, context, messageId, currentTeam, messageType, messageString, recipients, recipientType, externalUrl = '', activityId = null){
    console.log('saving message ')
    recipients.map(r => r.role = r.secondaryText.toLowerCase())

    var message = {
        id: messageId,
        isDraft: true,
        domain: domain,
        type: messageType,
        recipients: recipients,
        message: messageString,
        activityId: activityId,
        parentId: currentTeam.id,
        recipientType: recipientType,
        externalUrl: externalUrl == '' ? null : externalUrl,
        parentId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
    }

    try{
        console.log('here is your message prior to saving => ', message)
        const savedMessage = await saveMessage(message)
        return savedMessage
    }
    catch(e){
        console.log('error sending that message => ', e)
    }
}

export async function handleScheduleMessage(date, domain, context, messageId, currentTeam, messageType, messageString, recipients, recipientType, externalUrl = '', activityId = null, currentUserId,
parents, coaches, athletes, selectedTimezone = momentTZ.tz.guess(), matchUserTimezone = false){
  console.log('=============== handleScheduleMessage =================================')

  console.log('externalUrl ', externalUrl)
  console.log('activityId ', activityId)
    if (messageType == 'pushNotification' || messageType == 'notification') {
        const isAllParentsSelected = _.find(recipients, recipient => recipient.id == 'allParents');
        const isAllCoachesSelected = _.find(recipients, recipient => recipient.id == 'allCoaches');
        const isAllAthletesSelected = _.find(recipients, recipient => recipient.id == 'allAthletes');
        if(isAllAthletesSelected)
            recipients = recipients.concat(athletes)

        if(isAllCoachesSelected)
            recipients = recipients.concat(coaches)

        if(isAllParentsSelected)
            recipients = recipients.concat(parents)

        let scheduledMessage = {};
        let recipientIdAndRoleList = [];
        //handled group data structure and rest (groups contains participant)
        var participantsList = recipients.map(item => item.participants || item).flat()
        .filter(ele=> !!ele && ele.id != 'allParents' && ele.id != 'allCoaches' && ele.id != 'allAthletes');
        participantsList.forEach(recipient => {
            recipientIdAndRoleList.push({userId:recipient.id, role: recipient.role});
        });

        if(recipientIdAndRoleList.length){
            let notificationMessage = {
                id: messageId,
                isDraft: false,
                senderId: currentUserId,
                domain: domain,
                tenant: domain,
                isSent: 'false',
                type: messageType,
                sendingFrom: domain,
                sendingBySport: null,
                activityId: activityId,
                teamId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
                message: messageString,
                sendingByUser: 'Multi User',
                recipientIdAndRoleList:recipientIdAndRoleList,
                recipients:participantsList,
                createdAt: new Date().getTime(),
                activationTime: new Date(date).getTime(),
                externalUrl: externalUrl == '' ? null : externalUrl,
                organizationId: context == 'organization' ? currentTeam.organizationId : null,
                title: context == 'organization' ? 'Message from your administrator' : 'Message from your coach',
                parentId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
                meta:{
                  title: context == 'organization' ? 'Message from your administrator' : 'Message from your coach',
                  activityId: activityId,
                  externalUrl: externalUrl == '' ? null : externalUrl,
                },
                selectedTimezone,
                matchUserTimezone
            }
            notificationMessage.recipients = await resolveMessageRecipients(recipients)
            try{
                scheduledMessage = await scheduleMessage(notificationMessage)
            }
            catch(e){
                console.log('error sending that message => ', e)
            }
        }
        scheduledMessage.recipients = await resolveMessageRecipients(recipients)
        trackSegmentMessagingEvent(
            currentUserId,
            recipientIdAndRoleList.map(item => item.userId),
            messageId,
            messageType,
            'send_later',
            messageString,
            externalUrl,
            activityId
        );
        return scheduledMessage
    }
    else{
        recipients.map(r => r.role = r.secondaryText.toLowerCase())

        var message = {
            id: messageId,
            domain: domain,
            isSent: "false",
            type: messageType,
            parentType: context,
            recipients: recipients,
            message: messageString,
            activityId: activityId,
            recipientType: recipientType,
            createdAt: new Date().getTime(),
            activationTime: new Date(date).getTime(),
            externalUrl: externalUrl == '' ? null : externalUrl,
            parentId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
            teamId: context == 'organization' ? currentTeam.organizationId : currentTeam.id,
            tenant: getTenant(),
            senderId: currentUserId,
            meta:{
              title: context == 'organization' ? 'Message from your administrator' : 'Message from your coach',
              activityId: activityId,
              externalUrl: externalUrl == '' ? null : externalUrl,
            },
            selectedTimezone,
            matchUserTimezone
        }

        try{
            const scheduledMessage = await scheduleMessage(message);
            trackSegmentMessagingEvent(
                currentUserId,
                recipients.map(element => element.id),
                scheduledMessage.id,
                messageType,
                'send_later',
                messageString
            )
            return scheduledMessage
        }
        catch(e){
            console.log('error sending that message => ', e)
        }
    }


}

// there's some garbage in here to ensure we are showing
    // recipients
export async function resolveMessages(entityId){
    let sentMessages = []
    let draftMessages = []
    let monthlyMessages = []
    let scheduledMessages = []
    let messageLimitReached = false

    let endOfMonth = moment().endOf('month')
    let startOfMonth = moment().startOf('month')

    try{
        let allMessages = await getMessages(entityId)
        let allSentMessages = allMessages.filter(message => !message.isDraft)
        sentMessages = allSentMessages.filter(m => !m.parentMessageId && m.recipients)
        _.each(_.groupBy(allSentMessages.filter(m => m.parentMessageId), m => m.parentMessageId), messageGrouping => {
            let message = messageGrouping[0]
            message.recipients = message.recipients || messageGrouping.map(m => m.username)
            sentMessages.push(message)
        })
        draftMessages = allMessages.filter(message => message.isDraft === true)
        scheduledMessages = await getScheduledMessages(entityId)
        console.log('scheduledMessages ', scheduledMessages)
        await Promise.all(sentMessages.map(message => {
            if(
                message.createdAt &&
                message.messageType &&
                message.messageType == "sms" &&
                message.createdAt > startOfMonth &&
                message.createdAt < endOfMonth &&
                !_.find(monthlyMessages, m => { return m.id == message.id })
            ){
                monthlyMessages.push(message)
            }
        }))
    }
    catch(e){
        console.log('error resolving messages', e)
    }

    let data = {
        sentMessages: sentMessages,
        draftMessages: draftMessages,
        monthlyMessages: monthlyMessages,
        scheduledMessages: scheduledMessages
    }

    return data
}


export async function getMessagesWithPaginationData(entityId, page) {
    let allMessages = await getMessagesWithPagination(entityId, page);
    return allMessages;
}


export async function getDraftMessagesData(entityId) {
    let allMessages = await getDraftMessages(entityId);
    return allMessages.sentMessages;
}

export async function getScheduledMessagesData(entityId){
    let messages = await getScheduledMessages(entityId)
    console.log('messages ', messages)
    messages = await Promise.all(messages.map(async item => ({
        ...item,
        // recipients: await resolveMessageRecipients(null, item.recipientIdAndRoleList)
    })))
      const sortedMessages = messages.filter((e, i) => messages.findIndex(a => a['id'] === e['id']) === i).sort(function(a, b){
        let timeToDisplayA = a.sendDate || a.activationTime;
        let timeToDisplayB = b.sendDate || b.activationTime;

        let convertedTimeA = momentTZ.tz(timeToDisplayA, a.timezone || momentTZ.tz.guess()).format('lll');
        let convertedTimeB = momentTZ.tz(timeToDisplayB, b.timezone || momentTZ.tz.guess()).format('lll');
        console.log("convertedTimeA ", convertedTimeA)
        console.log("convertedTimeB ", convertedTimeB)
       if(convertedTimeA < convertedTimeB) { return -1; }
       if(convertedTimeA > convertedTimeB) { return 1; }
       return 0;
     });
    return sortedMessages
}



export async function resolveMessageRecipients(messageRecipients, recipientIdAndRoleList, allUsers){
    let recipientsToReturn = []
    if(recipientIdAndRoleList && recipientIdAndRoleList.length){
        await Promise.all(recipientIdAndRoleList.map(async r => {
            let recipient = await getUserById(r.userId || r.id)
            recipient.name = `${recipient.nameFirst|| ""} ${recipient.nameLast || ""}`
            recipient.secondaryText = recipient.athlete ? 'Parent' :
                recipient.role ? recipient.role.charAt(0).toUpperCase() + recipient.role.slice(1) :
                recipient.roles ? recipient.roles[0].role.charAt(0).toUpperCase() + recipient.roles[0].role.slice(1) :
                'Athlete'
            recipientsToReturn.push(recipient)
        }) )
        return _.uniqBy(recipientsToReturn, 'name')
    }
    else if(messageRecipients){
        if(typeof(messageRecipients[0]) === 'string'){
            await Promise.all(messageRecipients.map(async r => {
                let recipientArr = await getUserByUsername(r)
                let recipient = recipientArr[0] // some day update this to just return user
                recipient.name = `${recipient.nameFirst} ${recipient.nameLast}`
                recipient.secondaryText = recipient.athlete ? 'Parent' :
                    recipient.role ? recipient.role.charAt(0).toUpperCase() + recipient.role.slice(1) :
                    recipient.roles ? recipient.roles[0].role.charAt(0).toUpperCase() + recipient.roles[0].role.slice(1) :
                    'Athlete'
                recipientsToReturn.push(recipient)
            }) )
        }
        else{
            if(messageRecipients){
                await Promise.all(messageRecipients.map(async r => {
                    if(r){
                        if(r.groupId || r.participants){
                            const groupId = r.groupId ? r.groupId : r.id
                            let fullGroup = await getGroup(groupId)
                            r.name = fullGroup.name
                            r.secondaryText = `${fullGroup.participants.length} Users`
                        }
                        else {
                            let recipient = {};
                            if(!r.nameFirst){
                                recipient = (allUsers||[]).find(item => item.id == (r.userId || r.id)) || {nameFirst:"Loading..."}
                            }
                            r.name = `${r.nameFirst || recipient.nameFirst || ""} ${r.nameLast || recipient.nameLast || ""}`
                            r.secondaryText = r.secondaryText || (r.athlete ? 'Parent' :
                                r.role ? r.role.charAt(0).toUpperCase() + r.role.slice(1) :
                                r.roles && r.roles.length && r.roles[0].role ? r.roles[0].role.charAt(0).toUpperCase() + r.roles[0].role.slice(1) :
                                'Athlete')
                        }
                        recipientsToReturn.push(r)
                    }
                }))
            }
        }
        return recipientsToReturn;
    }
    else {
        return recipientsToReturn
    }
}

export async function resolveMessageString(messageString){
    let string = messageString
    return string
}

export async function resolveTimeFromString(timeString, actualDate){
    let dayOrNight = timeString.split(' ')
    let timeArray = timeString.split(':')||[]
    let timeNumbers = timeString.replace(/[^0-9]+/g, ' ').split(' ')
    let actualHour = TimeHours.find(t => t.displayed == `${timeArray[0]}:00 ${dayOrNight[1]}`)
    let actualMinute = timeNumbers[1].split('')[0] == 0 ? timeNumbers[1].split('')[1] : timeNumbers[1]
    let parsedHourValue = timeArray[0];
    //Convert in 24hour format
    if(dayOrNight[1].toLowerCase() == "pm"){
        parsedHourValue = Number(parsedHourValue) + 12;
    }
    else if (parsedHourValue == 12){
        parsedHourValue = "00"
    }
    let date = actualDate.set('h', (actualHour||{}).actual||parsedHourValue)
    date.set('m', actualMinute)
    return date
}

export async function handleMessageDelete(message){
    let deletedMessage = {}

    try{
        if(message.activationTime)
            deletedMessage = await deleteScheduledMessage(message.id)
        else
            deletedMessage = await deleteMessage(message.id)
    }
    catch(e){
        console.log('error deleting message', e)
    }

    return deletedMessage
}

export const getTotalUsersByMessage = ({ recipients = [], groups = [] }) => {
    return _.uniqBy(groups, "id").reduce((current, { users: { total } }) => current + total, recipients.length);
};
