import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CoachUpLogo from '../assets/images/themes/coachup/coachup-logo.png';
import CoachUpShortLogo from '../assets/images/themes/coachup/coachup-short-logo.png';
// import homeImage from '../assets/images/themes/coachup/coachup-welcome.png';
import homeImage from '../assets/images/coachup-welcome.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  product: {
    id: 'coachup',
    name: 'CoachUp',
    appUrl: 'm1-coachup://',
    domain: 'coachup.gomaxone.com',
    link: 'https://apps.apple.com/us/app/coachup/id1509253113', // replace with real link
    onAppleAppStore: 'https://apps.apple.com/us/app/coachup/id1509253113', // replace with real link
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.coachup' // replace with real link
  },
  image: {
    home: homeImage,
    logo: CoachUpShortLogo,
    loginLogo: CoachUpLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#102B52"
    },
    secondary: {
      main: "#296ED2",
      isDarkMode: true,
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
      },
      containedSecondary:{},
      disabled:{
        color:'#ffffff !important'
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      },
      root:{
        borderRadius:"5px",
        disabled:{
          color:'#ffffff !important'
        }
      },
      fab:{
        borderRadius:"5px"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
    MuiStepIcon:{
      text:{
      }
    }
  }

});
