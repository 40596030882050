import React from 'react'
import './styles.css'
import SignupForm from '../signupForm'
import { testId } from '../../../utils/test-utils';

export default function SignupContent(props){
    return(
        <div className={'signupContentOuterContainer'} {...testId('signup-form-container')}>
                <SignupForm
                   {...props}
                />
            <p className={'copyright'}>Copyright © 2020 Maxone</p>
        </div>
    )
}
