import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let GolfIcon = (props) => (
  <SvgIcon width="19" height="18" viewBox="0 0 19 18" {...props}>
    <path d="M5.55859 0.0896603C5.35209 0.200275 5.20455 0.410497 5.15661 0.664929C5.13441 0.79401 5.11967 3.1947 5.11967 7.4762V14.092H6.74214V7.36186C6.74214 6.91308 6.7422 6.49348 6.74225 6.10112C6.74294 0.758646 6.74298 0.464619 6.59599 0.316081C6.58743 0.30743 6.57837 0.299272 6.56879 0.290639C6.55619 0.279294 6.54268 0.267129 6.52819 0.251944C6.28513 -0.00621796 5.87215 -0.076168 5.55859 0.0896603Z" />
    <path d="M8.71532 1.14808C8.32808 1.17755 7.47991 1.29189 7.4357 1.32137C7.41723 1.32864 7.40623 2.7116 7.40623 4.3932V7.45049L7.93356 7.13711C8.60844 6.73513 10.1462 5.97557 10.8543 5.69148C12.252 5.13094 13.491 4.81384 14.5605 4.73997C14.7892 4.72523 14.9293 4.69949 14.9293 4.67356C14.9293 4.61462 14.6749 4.04289 14.5311 3.78492C13.9741 2.756 12.8642 1.85616 11.6657 1.4691C10.8064 1.18874 9.70003 1.06694 8.71532 1.14808Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54628 11.138C7.62015 11.1343 7.88186 11.1195 8.12529 11.1048C8.66381 11.068 10.2383 11.09 10.8908 11.1417C14.0145 11.3851 16.5553 12.1926 17.6726 13.3027C18.2848 13.9112 18.4359 14.5491 18.1188 15.1908C17.4182 16.5995 14.4717 17.7058 10.725 17.964C10.0207 18.0119 8.20624 18.0119 7.52409 17.964C2.78912 17.6247 -0.441268 15.991 0.0491291 14.1841C0.134002 13.867 0.325758 13.5793 0.661332 13.2622C1.12226 12.827 1.77139 12.4508 2.64568 12.1119C3.08086 11.9459 4.08758 11.6398 4.34574 11.5993L4.49329 11.5735V13.0154L4.35675 13.0412C4.10232 13.0891 3.6302 13.2514 3.37577 13.3768C3.05493 13.5389 2.85198 13.7233 2.77457 13.9298C2.71563 14.0811 2.71563 14.1031 2.77457 14.2544C3.01054 14.8701 4.31981 15.3091 5.93128 15.3091C7.373 15.3091 8.6196 14.9587 9.01412 14.4424C9.13574 14.2801 9.16894 14.0367 9.09153 13.8819C8.92924 13.5721 8.43885 13.2807 7.78244 13.1111L7.4062 13.0115V11.1417L7.54628 11.138ZM13.9778 15.0063C15.0214 14.5196 15.0324 13.0593 13.9962 12.5799C13.3139 12.2628 12.5247 12.5541 12.2078 13.2361C12.1229 13.4204 12.1081 13.4905 12.1081 13.7966C12.1119 14.1065 12.1229 14.1692 12.2188 14.3609C12.3441 14.6153 12.6465 14.9104 12.8899 15.021C13.03 15.0875 13.1371 15.1022 13.4172 15.1022C13.7233 15.1059 13.7933 15.0949 13.9778 15.0063Z" />
  </SvgIcon>
);

export default GolfIcon;