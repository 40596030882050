import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import FilterListIcon from '@material-ui/icons/FilterList';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';

import { lighten } from '@material-ui/core/styles/colorManipulator';
import moment from "moment"
import CSVReader from 'react-csv-reader'
import _ from "lodash"

import EnhancedTableHeader from '../../components/enhancedTableHeader'

import { API, Storage } from "aws-amplify";

import TrueLacrosseUserModal from './trueLacrosseUserModal.js';
import TrueLacrosseImportModal from './trueLacrosseImportModal.js';

import logo from "../../assets/images/logo-dark.png"

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginLeft:35,
    float:"left"
  },
});

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class AdminTrueLacrosseImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      imports: [],
      progress: 0,
      open: false,
      openLoader: false,
      openImportModal: false,
      isUploading:false,
      viewType:"user",
      importUsers:[],
      duplicates:[],
      importData:{},
      isLoading:false,
      totalLoading:0,
      headers:[
        { id: 'info', numeric: false, disablePadding: true, label: '' },
        { id: 'nameFirst', numeric: false, disablePadding: false, label: 'First' },
        { id: 'nameLast', numeric: false, disablePadding: false, label: 'Last' },
        { id: 'email', numeric: false, disablePadding: false, label: 'email' },
      ],
      importHeaders:[
        { id: 'info', numeric: false, disablePadding: true, label: '' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created On' },
        { id: 'createdBy', numeric: false, disablePadding: false, label: 'Created By' },
        { id: 'athletesCreated', numeric: false, disablePadding: false, label: '# of Athletes' },
        { id: 'parentsCreated', numeric: false, disablePadding: false, label: '# of Parents' },
      ],
      newFile:false
    };
    this.handleChange = this.handleChange.bind(this);

  }

  async componentDidMount() {

    let imports = await this.getAllImports()
    console.log('imports ', imports)
    if(imports){
      imports = imports.sort(function compare(a, b) {
        var dateA = new Date(a.createdAt);
        var dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    }
    this.setState({imports})
  }

  handleChange = async(data) => {
    // console.log('data ==== ', data)
    let importHeaders = []
    let importUsers = []

    let duplicates = [] // check if there are duplicates by nameFirst, nameLast, emaill

    if(data.length){
      if(data[0].length === 1 || data[0][1] === ""){ // remove the title row if there is one
        data.splice(0, 1)
      }
      if(data.length){ //check again to make sure there is still data after removing the title
        importHeaders = data[0]
        data.splice(0, 1)
      }
      else{
        console.log('Super Oops, looks like that csv sucks')
      }
      if(data.length > 1){ //check again to make sure there is still data after removing the title
        importUsers = data
      }
      else{
        alert("Oops, looks like there's no data in that CSV.  Please check your CSV and try again.  ")

      }
      console.log('importHeaders ', importHeaders)
      console.log('importUsers ', importUsers)
      this.setState({isLoading: true, totalLoading: data.length})
      let users = []
      await this.asyncForEach(importUsers, async (userFields, index) =>{
        let user = {}
        let csvRow = userFields
        await this.asyncForEach(userFields, (field, i) =>{
          var headerName = importHeaders[i].charAt(0).toLowerCase() + importHeaders[i].slice(1) // lowercase the first letter of header
          headerName = headerName.replace(/\s/g, '')
          if(headerName === "playerEmail"){ headerName = "email"}
          if(headerName === "playerEmail(Optional)"){ headerName = "email"}
          if(headerName === "playerEmailOptional"){ headerName = "email"}
          if(headerName === "playerPhone"){ headerName = "phoneNumber"}
          if(headerName === "playerCellPhone"){ headerName = "phoneNumber"}
          if(headerName === "playerPhone(Optional)"){ headerName = "phoneNumber"}
          if(headerName === "playerPhoneOptional"){ headerName = "phoneNumber"}
          if(headerName === "playerCellPhoneOptional"){ headerName = "phoneNumber"}
          if(headerName === "playerCellPhone(Optional)"){ headerName = "phoneNumber"}

          if(headerName === "jersey#"){ headerName = "jerseyNumber"}
          if(headerName === "playerGender"){ headerName = "gender"}
          if(headerName === "playerAge"){ headerName = "age"}
          if(headerName === "grade"){ headerName = "grade"}
          if(headerName === "highSchoolGraduationYear"){ headerName = "graduationYear"}
          if(headerName === "primaryPosition"){ headerName = "position"}
          if(headerName === "secondayPosition(s)"){ headerName = "secondaryPosition"}
          if(headerName === "birthDate"){ headerName = "birthday"}
          if(headerName === "birthdate"){ headerName = "birthday"}
          if(headerName === "dateofBirth"){ headerName = "birthday"}

          if(headerName === "firstName"){ headerName = "nameFirst"}
          if(headerName === "lastName"){ headerName = "nameLast"}
          if(headerName === "parent1First"){ headerName = "parent1FirstName"}
          if(headerName === "parent1Last"){ headerName = "parent1LastName"}
          if(headerName === "parent2First"){ headerName = "parent2FirstName"}
          if(headerName === "parent2Last"){ headerName = "parent2LastName"}

          if(headerName === "parent1CellPhone"){ headerName = "parent1Phone"}
          if(headerName === "parent2CellPhone"){ headerName = "parent2Phone"}
          if(headerName === "parent1HomePhone"){ headerName = "parent1HomePhone"}
          if(headerName === "parent2HomePhone"){ headerName = "parent1HomePhone"}

          // Remove ones we dont care about
          if(headerName === "guardian1BestTimetoContact" || headerName === "guardian1BestTimeToContact"){ headerName = null}
          if(headerName === "guardian2BestTimetoContact" || headerName === "guardian2BestTimeToContact"){ headerName = null}

          if(field && headerName){
            if(headerName === "nameFirst"){
              if(field.includes("(PAID")){
                field = field.split("(")[0].replace(/\s/g, '')
              }
              field = field.replace(/\s/g, '')
            }
            if(headerName === "nameLast"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "parent1FirstName"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "parent1LastName"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "email"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "parent1Email"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "parent2FirstName"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "parent2LastName"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "parent2Email"){
              field = field.replace(/\s/g, '')
            }
            if(headerName === "phoneNumber"){
              field = field.replace(/[-\/\\^"'$*+?.() |[\]{}]/g , '')
            }
            if(headerName === "parent1Phone"){
              field = field.replace(/[-\/\\^"'$*+?.() |[\]{}]/g , '')
            }
            if(headerName === "parent2Phone"){
              field = field.replace(/[-\/\\^"'$*+?.() |[\]{}]/g , '')
            }
            user[headerName] = field
          }
        })

        if(user && user !== {} && user.nameFirst && user.nameLast){

          let birthdaySplit = user.birthday ? user.birthday.split('/') : [0, 0, 0];
          const birthMonth = birthdaySplit[0];
          const birthDay = birthdaySplit[1];
          const birthYear = birthdaySplit[2];
          const nameFirst = user.nameFirst ? user.nameFirst.replace(/[-\/\\^"'$*+?.() |[\]{}]/g , '').toLowerCase() : null
          const nameLastLetter = user.nameLast ? user.nameLast.replace(/[-\/\\^"'$*+?.() |[\]{}]/g , '') : null;
          let parentPhone4Digits = ""
          if(user.parent1Phone){
            parentPhone4Digits = user.parent1Phone.substr(user.parent1Phone.length - 4)
          }
          else if(user.parent2Phone){
            parentPhone4Digits = user.parent2Phone.substr(user.parent2Phone.length - 4)
          }
          else{
            parentPhone4Digits = birthYear + birthMonth
          }

          let id = birthYear + nameFirst + nameLastLetter + parentPhone4Digits + birthMonth + birthDay
          user.id = id
          console.log('user ===> ', user)
          //check user array for this id.  if so, push to duplicates array.
          const filteredArray = users.filter( u => u.nameFirst && user.nameFirst && u.nameFirst.replace(/\s/g, '') === user.nameFirst.replace(/\s/g, '') && u.nameLast && user.nameLast && u.nameLast.replace(/\s/g, '') === user.nameLast.replace(/\s/g, '') && u.birthday && user.birthday && u.birthday.replace(/\s/g, '') === user.birthday.replace(/\s/g, ''));
          if(filteredArray.length){
            console.log('duplicate ==>', filteredArray)
            // duplicates = duplicates.concat(filteredArray)
            duplicates.push(user)
          }
          else{
            // check if the user potentially exists yet
            const isUser = await this.checkIfUserExists(user);
            console.log("isUser ", isUser)

            if(isUser){
              duplicates.push(user)
            }
            else{
              users.push(user)
            }
          }
        }
      })
      console.log('users ---> ', users)
      duplicates = _.orderBy(duplicates, ['nameLast'],['asc']); // Use Lodash to sort array by 'name'
      console.log('duplicates ---> ', duplicates)
      this.setState({isLoading: false, totalLoading:0})

      this.setState({importUsers: users, duplicates: duplicates})
    }
    else{
      console.log('Oops, looks like that csv sucks')
    }
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  async uploadCsv(){
    if(this.state.newFile){
      const response = await fetch(URL.createObjectURL(this.state.newFile));
      const blob = await response.blob();
      const fileName = moment().format() + "_" + this.state.newFile.name;
      const fileStructure = `organizations/${this.props.organization.id}/awardCSV/${fileName}`;
      console.log('blob', blob)

      try{
          // var buf = new Buffer.from(blob, 'blob');
          const customPrefix =  { public: "", private: "", protected:"" };
          const access = { ACL: 'public-read', level: "public", contentType: this.state.newFile.type, customPrefix:customPrefix };

          await Storage.put(`${fileStructure}`, blob, access)
            .then(async succ => {
              console.log('SUCCESS === ', succ)
            })
            .catch(err => {
              console.log("ERRORS FO DAYS", err)
            });
      }
      catch(e){
        console.log('Big ol error, ', e)
      }
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClose = (org) => {
    if(org.id){
      //replace updated user in data
      var data =this.state.data
      var element =  data.find(function(d) {
          return d.id === org.id
      })
      var index = data.indexOf(element)
      data.splice(index, 1, org)
      this.setState({data})
    }
    this.setState({ open: false });
  };

  handleCloseImportModal = (org) => {
    this.setState({ openImportModal: false });
  };

  handleOpen = event => {
      this.setState({ open: true, viewingEvent: event, viewType: "user" });
  };

  handleOpenImport = event => {
      this.setState({ openImportModal: true, viewingEvent: event});
  };

  handleOpenDuplicate = event => {
      this.setState({ open: true, viewingEvent: event, viewType: "duplicate" });
  };

  getOrganizations() {
    return API.get("admin", `/admin/organizations`);
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  removeFromList(type, user){
    if(type === "duplicate"){
      let duplicates = this.state.duplicates;
      const index = _.findIndex(duplicates, user)
      duplicates.splice(index, 1)
      this.setState({duplicates, open:false})
      return
    }
    let importUsers = this.state.importUsers;
    const index = _.findIndex(importUsers, user)
    importUsers.splice(index, 1)
    this.setState({importUsers, open:false})
  }
  addUserToList(user){
    let importUsers = this.state.importUsers;
    let duplicates = this.state.duplicates;
    const index = _.findIndex(duplicates, user)
    duplicates.splice(index, 1)
    user.id = user.id + "_1"
    importUsers.push(user)
    this.setState({importUsers, duplicates, open:false})
  }

  async generateUsernames(athlete, parent1, parent2){

    if(!athlete.username || athlete.reset ){
      athlete.username = athlete.nameFirst.toLowerCase() + "." + athlete.nameLast.toLowerCase() + Math.floor((Math.random() * 100) + 1)
    }
    if(parent1 && (!parent1.username || parent1.reset)){
      parent1.username = parent1.nameFirst.toLowerCase() + "." + parent1.nameLast.toLowerCase() + Math.floor((Math.random() * 100) + 1)
    }
    if(parent2 && (!parent2.username || parent1.reset)){
      parent2.username = parent2.nameFirst.toLowerCase() + "." + parent2.nameLast.toLowerCase() + Math.floor((Math.random() * 100) + 1)
    }

    let isAthleteUsername = await this.checkIfUsernameExists(athlete.username);
    let isParent1Username = parent1 ? await this.checkIfUsernameExists(parent1.username) : null;
    let isParent2Username = parent2 ? await this.checkIfUsernameExists(parent2.username) : null;

    if(isAthleteUsername === "true" || isAthleteUsername === true){
      console.log('trying again')
      athlete.reset = true
    }
    else{
      athlete.reset = false;
    }
    if(isParent1Username === "true" || isParent1Username === true){
      console.log('trying parent1 again')
      parent1.reset = true
    }
    else if(parent1){
      parent1.reset = false;
    }
    if(isParent2Username === "true" || isParent2Username === true){
      console.log('trying parent2 again')
      parent2.reset = true
    }
    else if(parent2){
      parent2.reset = false;
    }

    if(athlete.reset || (parent1 && parent1.reset) || (parent2 && parent2.reset)){
      return await this.generateUsernames(athlete, parent1, parent2)
    }
    else{
      let response = {
        username: athlete.username
      }
      response['parent1Username'] = parent1 && parent1.username ? parent1.username : null
      response['parent2Username'] = parent2 && parent2.username ? parent2.username : null
      return response
    }

  }

  async submitUsers(){
    if(this.state.isUploading){
      return
    }
    let { importUsers } = this.state;
    let total = importUsers.length
    let progress = 0

    // Upload CSV

    await this.setState({openLoader:true, isUploading:true}, async()=>{
      let totalUploaded = 0;
      let totalAthletes = 0;
      let totalParents = 0;
      await setTimeout(async() => {
        await importUsers.map(async(user, i) =>{
          await setTimeout(async() => {
            // Create User API
            let usernameData = {
              nameFirst: user.nameFirst,
              nameLast: user.nameLast
            }
            let parent1UsernameData = user.parent1FirstName && user.parent1LastName ? {
              nameFirst: user.parent1FirstName,
              nameLast: user.parent1LastName
            } : null
            let parent2UsernameData = user.parent2FirstName && user.parent2LastName ? {
              nameFirst: user.parent2FirstName,
              nameLast: user.parent2LastName
            } : null
            await this.generateUsernames(usernameData, parent1UsernameData, parent2UsernameData ).then(async usernames =>{
              // Check if username exists yet.
              // Add user to athletes list, and parents to parents list to display
              user.username = usernames.username ? usernames.username : null
              user.parent1Username = usernames.parent1Username ? usernames.parent1Username : null
              user.parent2Username = usernames.parent2Username ? usernames.parent2Username : null
              delete user['id']
              // Import the user.  Will return user, and parents created.
              await this.createUsers(user).then(async response =>{
                // If doesnt return anything, then add to failures.
                totalAthletes = totalAthletes + 1;
                if(response.parent1){
                  totalParents = totalParents + 1;
                }
                if(response.parent2){
                  totalParents = totalParents + 1;
                }
                totalUploaded = totalUploaded + 1
                progress = (totalUploaded / total) * 100
                console.log('progress ===> ', Math.floor(progress))

                if(Math.floor(progress) !== this.state.progress){
                  this.setState({progress: Math.floor(progress)})
                }

                // When done with uploads....
                if(Math.floor(progress) === 100){
                  // Create Import Job API
                  let importData = {
                    createdAt: moment().format(),
                    createdBy: {
                      username: this.props.currentUser.username,
                      nameFirst: this.props.currentUser.nameFirst,
                      nameLast: this.props.currentUser.nameLast,
                      id: this.props.currentUser.id
                    },
                    organizationId:"2019true2019lacrosse",
                    athletesCreated: totalAthletes,
                    parentsCreated: totalParents
                  }
                  await this.createImport(importData)
                  let imports = this.state.imports;
                  imports.push(importData)
                  imports = imports.sort(function compare(a, b) {
                    var dateA = new Date(a.createdAt);
                    var dateB = new Date(b.createdAt);
                    return dateB - dateA;
                  });
                  this.setState({imports, importData})
                }
              }).catch(e => { // if the create user fails on one user;
                totalUploaded = totalUploaded + 1
                progress = (totalUploaded / total) * 100
                console.log('progress ===> ', Math.floor(progress))

                if(Math.floor(progress) !== this.state.progress){
                  this.setState({progress: Math.floor(progress)})
                }
              })
            })
          }, 5000)
        })
      }, 1000);
    })
  }

  async checkIfUserExists(user){
    return API.post("truelacrosse", `/truelacrosse/check/email`, {
      body:user
    });
  }

  async checkIfUsernameExists(username){
    return API.get("truelacrosse", `/truelacrosse/username/${username}`);
  }

  async getAllImports(){
    return API.get("truelacrosse", `/truelacrosse/imports`);
  }
  async createImport(data){
    return API.post("truelacrosse", `/truelacrosse/imports`, {body: data});
  }
  async createUsers(data){
    console.log('data ---> ', data)
    return API.post("truelacrosse", `/truelacrosse/import/users`, {body: data});
  }

  render() {
    const { classes } = this.props;
    const { data = [], imports =[], importData={}, updatedState={},order, orderBy, selected, rowsPerPage, page, headers, importHeaders, open = false, openImportModal = false, openLoader, viewingEvent = {}, importUsers=[], viewType } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div >
      <Grid container spacing={0}>
        <Grid item xs={8} sm={8} style={{justify:"left"}}>
          <h1 className={classes.header}>User Import</h1>
        </Grid>
        <Grid item xs={4} sm={4} style={{paddingLeft:10, paddingRight:0, paddingTop:25}}>
        {
          this.state.importUsers.length > 0 || this.state.duplicates.length > 0 ?
          <Button
            variant="contained"
            component="label"
            raised
            style={{marginRight:10}}
            onClick={() =>this.setState({importUsers:[], duplicates: [], importData: {}, isUploading:false, progress:0, openLoader:false})}
          >
            Start Over
          </Button> :null
        }
        {
          this.state.importUsers.length > 0 || this.state.duplicates.length > 0 ?
          <Button
            variant="contained"
            component="label"
            color="primary"
            raised
            onClick={async() => await this.submitUsers()}
            disabled={this.state.duplicates.length || this.state.isUploading ? 1 : 0}
            style={{marginRight:10}}
          >
            Submit
          </Button>
          :null
        }
        </Grid>
      </Grid>
      <Paper className={classes.root} style={{padding:50}}>
      {
        this.state.openLoader ?
        <div style={{marginBottom:25}}>
          {
            this.state.progress === 100 ?
            <p>Successfully imported {importData.athletesCreated} athletes and {importData.parentsCreated} parents!</p>
            :
            <p>Importing Your Roster: {this.state.progress}%</p>
          }
          <LinearProgress variant="determinate" value={this.state.progress} size={100} color={"primary"} />
          {
            this.state.progress === 100 ?
              <Button
                variant="contained"
                component="label"
                raised
                style={{marginTop:15}}
                onClick={() =>this.setState({importUsers:[], duplicates: [], importData:{} ,isUploading:false, progress:0, openLoader:false})}
              >
                Close
              </Button>
            :
            null
          }
        </div>
        :
        null
      }
        {
          !this.state.duplicates.length
          ?
          <div className={classes.tableWrapper}>
          </div>
          :
          <div className={classes.tableWrapper} style={{marginTop:10}}>
            <div style={{textAlign:"center", marginTop:10, marginBottom:10}}>

              <p style={{padding:10}}>
                <b>Duplicates</b>
                <p style={{padding:10}}>Nothing to import yet, see instructions below:<br/><br/>Click the "i" icon to the left of the athletes' name to either keep or remove them (We recommend removing the duplicates to avoid multiple user accounts being created)</p>
              </p>
              <Button
                variant="contained"
                component="label"
                color="secondary"
                raised
                onClick={() => this.setState({duplicates:[]})}
                style={{marginTop:-25, marginBottom:15}}
              >
                Remove All Duplicates
              </Button>
            </div>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.state.duplicates.length}
                headers={headers}
              />
              <TableBody>
                {this.state.duplicates
                  .sort(getSorting(order, orderBy))
                  .map((n, i) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`${n.id}${i}`}
                        selected={isSelected}
                      >
                      <TableCell component="th" scope="row" style={{width:50,  maxWidth:50}}>
                        <IconButton color="primary" className={classes.button} aria-label="info" onClick={() => this.handleOpenDuplicate(n,i)}>
                          <InfoIcon />
                        </IconButton>
                      </TableCell>
                        <TableCell component="th" scope="row" style={{width:50}} >
                          {n.nameFirst}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width:50}}>
                          {n.nameLast}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width:50}} >
                          {n.email ? n.email : n.parent1Email}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        }
        {
          this.state.isLoading ?
          <div style={{textAlign:"center", marginTop:15, marginBottom:15}}>
            <CircularProgress variant="indeterminate" size={100} color={"primary"} />
            <h3> Analyzing {this.state.totalLoading} CSV rows. This will take a little time...</h3>
          </div>
          : null
        }
        {
          this.state.importUsers.length || this.state.duplicates.length ?
          <div style={{textAlign:"center", marginTop:10, marginBottom:10}}>
          </div>
          :  <div>
              <CSVReader onFileLoaded={data => this.handleChange(data)} />
                <p style={{padding:10, marginTop:15}}>
                  <b>Previous Imports</b>
                </p>
                {
                  imports.length ?
                  <Table className={classes.table} aria-labelledby="tableTitle" style={{minWith:500}}>
                    <EnhancedTableHeader
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={imports.length}
                      headers={importHeaders}
                    />
                    <TableBody>
                      {imports
                        .sort(getSorting(order, orderBy))
                        .map((n, i) => {
                          const isSelected = this.isSelected(n.id);
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={`${n.id}${i}`}
                              selected={isSelected}
                            >
                            <TableCell component="th" scope="row" >
                              <IconButton color="primary" className={classes.button} aria-label="info" onClick={() => this.handleOpenImport(n,i)}>
                                <InfoIcon />
                              </IconButton>
                            </TableCell>
                              <TableCell component="th" scope="row">
                                {n.createdAt ? moment(n.createdAt).format("MM/DD/YYYY hh:ss a") : ''}
                              </TableCell>
                              <TableCell component="th" scope="row" >
                                {n.createdBy && n.createdBy.nameFirst && n.createdBy.nameLast ? `${n.createdBy.nameFirst} ${n.createdBy.nameLast}` : '' }
                              </TableCell>
                              <TableCell component="th" scope="row"  >
                                {n.athletesCreated}
                              </TableCell>
                              <TableCell component="th" scope="row" >
                                {n.parentsCreated}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  :
                  <p> No Previous Imports </p>
                }

            </div>
        }
        {
          this.state.importUsers.length && !this.state.duplicates.length ?
          <div style={{textAlign:"center", marginTop:10, marginBottom:10}}>
          <p style={{padding:10}}>
            <b>Total to Import: {this.state.importUsers.length}</b>
          </p>
            <p style={{padding:10}}>NOTE: Once you have imported a file & pressed SUBMIT, the users will need to be added to their respective team to receive their account credentials</p>
          </div>
          :  null
        }
        {
          !this.state.importUsers.length
          ?
          <div className={classes.tableWrapper}>
            <div style={{textAlign:"center", marginTop:10, marginBottom:10}}>

              <p style={{padding:10}}>
                <b>Import</b>
              </p>
              <p style={{padding:10}}>
                <b>NOTHING TO IMPORT  (Note: Please use the INFO icon on your users in Duplicates to either KEEP or REMOVE them from the import)</b>
              </p>
            </div>
          </div>
          :
          <div className={classes.tableWrapper} style={{marginTop:10}}>
            <div style={{textAlign:"center", marginTop:10, marginBottom:10}}>

              <p style={{padding:10}}>
                <b>Import</b>
              </p>
            </div>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={importUsers.length}
                headers={headers}
              />
              <TableBody>
                {importUsers
                  .sort(getSorting(order, orderBy))
                  .map((n, i) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`${n.id}${i}`}
                        selected={isSelected}
                      >
                      <TableCell component="th" scope="row" style={{width:50,  maxWidth:50}} >
                        <IconButton color="primary" className={classes.button} aria-label="info" onClick={() => this.handleOpen(n,i)}>
                          <InfoIcon />
                        </IconButton>
                      </TableCell>
                        <TableCell component="th" scope="row" style={{width:50}} >
                          {n.nameFirst}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width:50}}>
                          {n.nameLast}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width:50}} >
                          {n.email ? n.email : n.parent1Email}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        }
      </Paper>
      <TrueLacrosseUserModal close={this.handleClose} open={open} user={viewingEvent} type={viewType} deleteUser={() => this.removeFromList(viewType, viewingEvent)} keepUser={() => this.addUserToList(viewingEvent)}/>
      <TrueLacrosseImportModal close={this.handleCloseImportModal} open={openImportModal} importData={viewingEvent} />

      </div>
    );
  }
}

AdminTrueLacrosseImport.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        currentUser: userContext.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AdminTrueLacrosseImport));
