export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "programax-videos-production"
    },
    s3Legacy: {
        REGION: "us-east-1",
        BUCKET: "programax-videos-production"
    },
    apiGatewayChat: {
        REGION: "us-east-1",
        URL: "https://qhd12rnmxf.execute-api.us-east-1.amazonaws.com/ops"
    },
    "apiGatewayActivities":{REGION: "us-east-1", URL:"https://9vie4u0pna.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayAuth":{REGION: "us-east-1", URL:"https://qlrngbwkna.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayAdmin":{REGION: "us-east-1", URL:"https://ybp19yxqc3.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayBilling":{REGION: "us-east-1", URL:"https://xfgbeb5j5f.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayEvents":{REGION: "us-east-1", URL:"https://6tjx5uda5g.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayFeedItems":{REGION: "us-east-1", URL:"https://fl0iff9fs0.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayGroups":{REGION: "us-east-1", URL:"https://omfzy8cwa3.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayLeaderboards":{REGION: "us-east-1", URL:"https://70e827e37i.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayMessages":{REGION: "us-east-1", URL:"https://z09y2ga8ch.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayScheduledMessages":{REGION: "us-east-1", URL:"https://1vvs7cvnjh.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayOrganizations":{REGION: "us-east-1", URL:"https://wluo8vthul.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayProducts":{REGION: "us-east-1", URL:"https://chjam2wbc1.execute-api.us-east-1.amazonaws.com/ops" },
    apiGatewayPrograms: {
        REGION: "us-east-1",
        URL: "https://onjsgowu0e.execute-api.us-east-1.amazonaws.com/ops"
    },
    "apiGatewaySchedules":{REGION: "us-east-1", URL:"https://v7mpmsoz4f.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayUsers":{REGION: "us-east-1", URL:"https://734xt8l4s6.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayWorkoutPrograms":{REGION: "us-east-1", URL:"https://tvlspbyg1l.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayWorkouts":{REGION: "us-east-1", URL:"https://q0maq0ph8d.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayWorkoutSchedules":{REGION: "us-east-1", URL:"https://bhdiwcphmd.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayWorkoutSessions":{REGION: "us-east-1", URL:"https://80gh1dcix5.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayTags":{REGION: "us-east-1", URL:"https://1bwljpdz0e.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayTaggings":{REGION: "us-east-1", URL:"https://793zhz4pee.execute-api.us-east-1.amazonaws.com/ops" },
    "apiGatewayWCS":{REGION: "us-east-1", URL:"https://wdcn7l32y6.execute-api.us-east-1.amazonaws.com/ops" },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_n0pHUpSrH",
        APP_CLIENT_ID: "3ls8lstjbm65j7un4arq0klep4",
        IDENTITY_POOL_ID: "us-east-1:0a19b97b-b8b1-4b79-bdf4-d9a15bb73737"
    }
}
