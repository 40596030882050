export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://tst-api.maxone-dev.eu"
  },
  cognito: {
    USER_POOL_ID: "us-east-1_bdsrShXUY",
    APP_CLIENT_ID: "36hj7kpk7j1putuafekhvdqc5s",
    IDENTITY_POOL_ID: "us-east-1:ba2bbba1-4dd7-49d2-9c6a-52542e72201c"
  }
}
