import React, { Component } from "react";
import urlParser from "../../js-video-url-parser";
import {
    getFileTypeSupportStatus,
    uploadActivityAttachment,
    formatMeasureValueForDisplaying,
    formatMeasureValueForSaving
} from '../../utils/activityUtils'
import shortid from "shortid";
import { FileType, toBase64 } from '../../utils/commonUtil'
import { goalsClient } from '../../clients'
import { API } from "aws-amplify";
import moment from 'moment'
export default class NewActivityModule extends React.Component {
    handleVideoUrlChange = name => async e => {
        const videoUrl = e.target.value
        const video = await urlParser.parse(videoUrl);
        let { activity } = this.state;
        if (video && (video.provider == "vimeo" || video.provider == "youtube" || video.provider == "wistia")) {
            activity.videoId = video.id;
            activity.videoType = video.provider;
            activity.videoUrl = videoUrl;
            this.setState({ activity, invalidVideoUrl: false });
        }
        else {
            this.setState({ invalidVideoUrl: true, videoId: "" });
        }
    }

    removeActivityVideo = () => {
        let { activity } = this.state
        activity.videoId = null
        activity.videoType = null
        activity.videoName = null
        activity.attachment = null
        activity.videoUrl = null
        this.setState({ activity, invalidVideoUrl: false });
    }

    handleThumbnailSelect = async (e) => {
        e.preventDefault();
        let { activity = {} } = this.state;
        if (e.target.files.length) {
            let file = e.target.files[0];
            activity.thumbnailName = file.name
            this.setState({ uploadingThumbnailFile: true, activity });
            activity.thumbnailBase64 = await toBase64(file);
            this.setState({
                activity: activity,
                uploadingThumbnailFile: false,
                thumbnailUploadProgress: 0
            });
        }
    }

    handleFileSelect = async (e) => {
        e.preventDefault();
        let { activity = {} } = this.state;
        if (e.target.files.length) {
            let file = e.target.files[0];
            let fileName = file.name;
            const fileTypeSupportStatus = getFileTypeSupportStatus(fileName);
            if (!fileTypeSupportStatus.isSupported) {
                if (fileTypeSupportStatus.fileType == FileType.VIDEO) {
                    alert('Sorry, we do not support this video type. Supported video formats are MP4, WebM, OGG')
                }
                else {
                    alert('Selected file type is not supported.\n(Currently only accept Video, Image or PDF file.)')
                }
                return;
            }
            // here is a edge case fix:
            // if this is originally a video file (or pdf file), now you/
            // need to tell the program it is not a video file
            activity.videoType = null;
            activity.videoId = null;
            activity.videoName = file.name;
            activity.fileObject = file;
            const objectURL = URL.createObjectURL(file);
            activity.attachment = objectURL;
            // console.log(' fileTypeSupportStatus.fileType', fileTypeSupportStatus.fileType)
            // console.log(' FileType.VIDEO', FileType.VIDEO)
            // if (fileTypeSupportStatus.fileType == FileType.VIDEO) {
            //     activity.videoType = 'coach';
            //     this.setState({
            //         activity: activity
            //     });
            // }
            // else {
                this.setState({uploadingVideoFile:true});
                const attachmentUrl = await uploadActivityAttachment(file, activity.id, (progress) => {
                    if (!this.state.cancelUpload) {
                        this.setState({ videoFileUploadProgress: progress });
                    }
                });
                if (!this.state.cancelUpload) {
                    activity.attachment = attachmentUrl;
                }
                this.setState({cancelUpload: false});
                this.setState({
                    activity: activity,
                    uploadingVideoFile:false,
                    videoFileUploadProgress:0
                });
            // }
        }
    }

    cancelUpload = () => {
        this.setState({ cancelUpload: true, uploadingVideoFile: false });
    }


    handleActivityChange = name => event => {
        const { activity = {} } = this.state;
        let clonedActivity = {...activity};
        if (name === 'description') {
            clonedActivity[name] = event;
        }
        if (name === 'isCoachesOnly') {
            clonedActivity[name] = !event.target.value;
        }
        else {
            clonedActivity[name] = event && event.target ? event.target.value : event;
        }
        this.setState({ activity: clonedActivity });
    }


    removeActivityTag = (tag) => {
        let activityTags = this.state.activityTags
        if (tag)
            return this.setState({ activityTags: _.without(activityTags, tag) })
        activityTags.pop()
        this.setState({ activityTags: activityTags })
    }

    toggleActivityModal = (activity, addingAnother) => {
        let { isActivityModalOpen } = this.state;
        let activityTags = [];
        if (_.isEmpty(activity)) {
            let activityId = shortid.generate();
            activity = {
                overrideActivityId: activityId,
                id: activityId,
                isNewActivity: true,
                hasLeaderboard: false,
                isPercentOfMax: true,
                completionValue: 0
            }
        }
        else if (!isActivityModalOpen) {
            activityTags = activity.taggings.map(tag => {
                return { name: tag }
            });
            this.setState({ activityTags });
        }
        this.setState({
            isActivityModalOpen: addingAnother || !isActivityModalOpen,
            activity: {...activity} || {},
            displaySuccessOverlay: false,
            activityTags,
            uploadingThumbnailFile: null,
            uploadingVideoFile: null,
            invalidVideoUrl: false
        });
    }

    handleActivityTag = (tag) => {
        const { activityTags } = this.state
        this.setState({
            activityTags: activityTags.concat([{ name: tag }]),
        })
    }


    handleActivityTag = (tag) => {
        const { activityTags } = this.state
        this.setState({
            activityTags: activityTags.concat([{ name: tag }]),
        })
    }

    uploadingFile = (isUploading) => {
        this.setState({ uploadingVideoFile:isUploading})
    }

    saveActivityGoals = async (userData) => {
        const {userResults, activity} = this.state;
        const {currentTeam, currentUser} = this.props;
        await goalsClient.createUserActivityGoals(activity.id, currentUser.id, currentTeam.id, userData);
    }

    getAthletes(){
        return API.get("programs", `/programs/${this.props.currentTeam.id}/players`);
    }


    /* ######################################################* Activity Result Modal Methods START ######################################################*/

    toggleAthleteResultModal = async (activity, isGoal) => {
        const { currentTeam } = this.props;
        const { athletes, athleteResultModalOpen, activities } = this.state;
        let userGoalResults = [];
        let promises = [];
        let athletesForResults = athletes||[];
        this.setState({isLoadingResultData : true});
        if(!athleteResultModalOpen){
            this.setState({athleteResultModalOpen: true, isLoadingResultData : true});
            if(!athletesForResults.length){
                athletesForResults = this.getAthletes();
            }
            promises.push(athletesForResults);
            if(isGoal) {
                promises.push(goalsClient.searchGoalsByTeamId(currentTeam.id, {activityId:activity.id}));
            }
            //fetch data
            let resolvedPromiseData = await Promise.all(promises);
            if(!!(resolvedPromiseData[1]||{}).data){
                (resolvedPromiseData[1].data.results||[]).forEach(item => {
                    //find activity with that id
                    if(item.value) {
                        let activityData = (activities||[]).find(activity => activity.id == item.activityId)||{};
                        item.value = formatMeasureValueForDisplaying(activityData.scoreType, item.value);
                        userGoalResults.push({...item, score: item.value});
                    }
                });
            }
            athletesForResults = resolvedPromiseData[0] || [];
        }
        this.setState({
            userResults: userGoalResults,
            athletes: athletesForResults,
            filteredAthletes: athletesForResults,
            activity: activity ? activity : null,
            athleteResultModalOpen: !athleteResultModalOpen,
            isGoal,
            isLoadingResultData: false,
            athleteSearchString:""
        })
    }

    saveActivityResults = async () => {
        const { userResults, activity, isGoal } = this.state
        this.setState({savingActivityResults: true});
        let userData = [];
        await Promise.all(userResults.map(async result => {
            let goalValue = null;
            if(result.userId && result.score){
                let {oldformat, newFormat} = formatMeasureValueForSaving(activity.scoreType, result.score);
                goalValue = newFormat;
                let scoreType = (activity.scoreType||"").toLowerCase();
                let resultJSON = {
                    score: (scoreType == 'time'|| scoreType == 'shortesttime')? newFormat:oldformat,
                    createdAt: moment(),
                    userId: result.userId,
                    activityId: activity.id,
                    workoutSessionId: 'none',
                    type: activity.scoreType,
                    scoreType: activity.scoreType,
                    completionValue: activity.completionValue,
                    legacyActivityId: activity.legacyId ? activity.legacyId : 0,
                    globalActivityId: activity.globalActivityId ? activity.globalActivityId : '0',
                }
                if(!isGoal) {
                    await this.saveActivityResult(resultJSON)
                }
            }
            userData.push({userId: result.userId, value: Number(goalValue), id: result.id});
        }))
        if(isGoal){
            await this.saveActivityGoals(userData)
        }
        this.setState({
            savingActivityResults: false,
            athleteResultModalOpen: false,
            isGoal: false,
            userResults: []
        })
    }


    saveActivityResult(result){
        return API.post("activities", "/activityResults", {
            body: result
        });
    }

    handleActivityResultChange = athleteId => event => {
        const {userResults, activity} = this.state
        let clonedResult = [...userResults];
        let score = event.target.value
        console.log("score " + event.target.value)
        let resultObject = { userId: athleteId, score: score }
        let resultPresentIndex = clonedResult.findIndex(ur => ur.userId == athleteId);
        if(resultPresentIndex != -1){
            resultObject.id = clonedResult[resultPresentIndex].id;
            if(activity.scoreType == 'participation'){
                clonedResult.splice(resultPresentIndex, 1);
            }
            else{
                clonedResult[resultPresentIndex] = resultObject;
            }
        }
        else {
            clonedResult.push(resultObject);
        }
        this.setState({userResults:clonedResult});
    }

    searchAthletes = event => {
        const { athletes } = this.state;
        if(event.target.value){
            this.setState({
                athleteSearchString: event.target.value,
                filteredAthletes: _.filter(athletes, a => { return a.nameFirst && a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast && a.nameLast.toLowerCase().includes(event.target.value.toLowerCase())})
            })
        }
        else {
            this.setState({
                athleteSearchString: null,
                filteredAthletes: athletes
            })
        }
    }

}
