import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { biabClient, userClient } from '../../clients'
import PageWrapper from '../nav/pageWrapper';
import Pagination from '../../components/pagination'
import { ROUTE_PATH } from "../../routeUtil";
import { AirplaneIcon } from '../../components/icons';
import BodyWrapper from "../../components/bodyWrapper";
import classnames from 'classnames'
import AccountCircle from '@material-ui/icons/AccountCircle';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import moment from 'moment';
import SearchInput from '../../components/searchInput';
import FilterDropDown from '../../components/filterDropDown'
import { monthDiff, downloadFileFromUrl } from '../../utils/commonUtil';
import ThemeButton from '../../components/themeButton';

let styles = {
    userInfo: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        "& tr": {
            background: "#ffffff",
            height: "52px",
            "& th": {
                padding: "11px 0px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "14px",
                color: "#A0A7AB"
            }
        },
        "& tr th,tr td": {
            borderBottom: "1px solid #DDE2E5",
            maxWidth: "100px",
            paddingRight: "10px",
            overflow: "hidden",
            wordBreak: "break-all"
        },
        "& tr th:first-child,tr td:first-child": {
            paddingLeft: "16px",
            paddingRight: "16px"
        },
        "& tr th:last-child,tr td:last-child": {
            paddingRight: "16px"
        },
        "& tr th": {
            textAlign: "left"
        }
    },
    athleteList: {
        "& tr:hover": {
            background: "#F8F8F8"
        }
    },
    td: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#0B0B0B",
        textAlign: "left"
    },
    tableHead: {
        height: "36px !important"
    },
    athleteListContainer: {
        border: "1px solid #DDE2E5",
        borderBottom: "0px",
        background: "#ffffff",
        borderRadius: "8px 8px 0px 0px",
        overflow: "hidden"
    },
    sendInviteContainer: {
        width: "100px",
        color: "#1354F9",
        textAlign: "center",
        cursor: "pointer",
        "& svg": {
            cursor:"pointer",
            fill: "#1354F9",
            width: "16px",
            height: "16px",
            marginRight: "4px"
        }
    },
    statusBox: {
        padding: "4px 8px",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "12px",
        color: "#FFFFFF",
        borderRadius: "20px"
    },
    athleteImgBox: {
        minWidth: "36px",
        minHeight: "36px",
        maxWidth: "36px",
        maxHeight: "36px",
        borderRadius: "50%",
        border: "2px solid #DDE2E5",
        marginRight: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        overflow: "hidden",
        "& svg": {
            fill: "#A0A7AB"
        },
        "& img":{
            maxWidth:"100%",
            maxHeight:"100%"
          }
    },
    lastNameSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexWrap: "nowrap"
    },
    additionalInfoContainer: {
        height: "74px",
        paddingLeft: "15px",
        paddingRight: "15px",
        border: "1px solid #DDE2E5",
        borderTop: "0",
        background: "#ffffff",
        borderRadius: "0px 0px 8px 8px"
    },
    pageInfo: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        textAlign: "right",
        color: "#5B6062",
        textAlign: "right",
        marginLeft: "auto"
    },
    cursorPointer: {
        cursor: "pointer"
    },
    nameContainer:{
        width: "35%"
    },
    sorted:{
        fontWeight:"bold !important",
        color:"#000000 !important"
    },
    searchBox: {   
        height: "44px",
        width: "300px"  
    },
    headerSection:{
        marginBottom:"20px"
    },
    filterSection:{
        minWidth:"450px",
        justifyContent: "flex-end",
        "& > div":{
            "&:first-child":{
                marginRight:"15px"
            }
        }
    },
    generateCSVBtn:{
        marginLeft:"10px"
    }
};

const TYPES = Object.freeze({
    MONTHLY: "Monthly",
    QUATERLY: "Quaterly",
    YEARLY: "Yearly",
});


const STATUS = Object.freeze({
    ACTIVE: "Active",
    CANCELED: "Canceled",
    TRIALING: "Free Trial",
    PAYMENT_ISSUE: "Payment Issue"
});

const filters = [
    {
        type:"intervalDuration",
        displayValue:"Select Type",
        values:Object.keys(TYPES).map(item => { return {displayValue:TYPES[item], value:item}})
    },
    {
        type:"status",
        displayValue:"Select Status",
        values:Object.keys(STATUS).map(item => { 
            let statusInfo = getStatus(item);
            return {displayValue:statusInfo.name, value:statusInfo.value}
        })
    }
]

function getStatus(status) {
    switch ((status||"").toLowerCase()) {
        case "active": return { name: STATUS.ACTIVE, value:"active", color: "#27AE60" };
        case "incomplete": 
        case "incomplete_expired":
        case "canceled": return { name: STATUS.CANCELED, value:"incomplete,incomplete_expired,canceled", color: "#F93F3F" };
        case "trialing": return { name: STATUS.TRIALING, value:"trailing", color: "#1354FA" };
        default: return { name: STATUS.PAYMENT_ISSUE, value:"past_due,unpaid", color: "#FFA800" };
    }
}
class Customers extends Component {
    constructor(props) {
        super(props);
        this.fields = [
            {
                displayName: "Name",
                value: "name",
                sortParam: "userfullname.keyword"
            },
            {
                displayName: "Status",
                value: "status",
                sortParam: "status.keyword"
            },
            {
                displayName: "Type",
                value: "intervalDuration",
                sortParam: "intervalDuration.keyword"
            },
            {
                displayName: "Rate",
                value: "price",
                sortParam: "rawPrice"
            },
            {
                displayName: "Months Active",
                value: "monthsActive",
                sortParam: "createdDate"
            },
            {
                displayName: "Joined Date",
                value: "formattedCreatedDate",
                sortParam: "createdDate"
            }
        ];
        this.state = {
            isLoading: true,
            athletes: [],
            athletesCount: 0,
            currentPage: 0,
            athletePerPageLimit: 20,
            sortParam: "currentPeriodStart",
            sortOrder: "desc",
            selectedFilters:{}
        }
    }

    componentDidMount() {
        let { currentTeam, tenant } = this.props;
        this.fetchSubscriptionAthletes();
    }

    changeCurrentPage = (page) => {
        if (!!this.paginationRef && !!this.paginationRef.scrollTo) {
            this.paginationRef.scrollTo(0, 0)
        }
        this.fetchSubscriptionAthletes(page - 1);
    }

    sortData = (sortParam, sortValue) => {
        let { sortParam : existingSortParam, currentPage, sortOrder} = this.state;
        this.setState({sortParam, sortValue, sortOrder:existingSortParam != sortParam? "desc": (sortOrder == "asc"? "desc": "asc")}, () => this.fetchSubscriptionAthletes(currentPage||0))
    }

    fetchSubscriptionAthletes = async (page = null) => {
        let { currentUser, currentTeam, tenant } = this.props;
        let { currentPage, athletePerPageLimit, sortParam, sortOrder, searchTerm, selectedFilters, originalCount } = this.state;
        if (page !== null) {
            this.setState({ isLoading: true, showLoadingChild: true });
        }
        let queryParams = {
            limit: athletePerPageLimit,
            offset: (page !== null ? page : currentPage) * athletePerPageLimit,
            sort: `${sortParam}.${sortOrder}`
        };
        Object.keys(selectedFilters||{}).forEach(item => {
            if(!!selectedFilters[item]){
                queryParams[item] = selectedFilters[item];
            }
        });        
        if(searchTerm){
            queryParams.q = searchTerm;
        }

        try {
            let { data: responseData } = await biabClient.getSubscriptionByTeamId(tenant, currentTeam.id, queryParams);
            let { total: athletesCount, results: athletes, meta: { } } = responseData;
            athletes.forEach(element => {
                let user = element.user || {}
                element.name = element.userfullname;
                element.formattedCreatedDate = moment(element.createdDate).format("YYYY-MM-DD");
                element.statusInfo = getStatus(element.status);
                element.monthsActive = monthDiff(new Date(element.createdDate), new Date()) + 1;
            });

            this.setState({ athletesCount, athletes, originalCount:originalCount||athletesCount, currentPage: page, totalPages: Math.ceil(athletesCount / athletePerPageLimit) });
        }
        catch (e) {
            console.log("Text")
            console.log(e)
        }
        this.setState({ isLoading: false, showLoadingChild: false, selectedUserIds: new Set(), groupSelected: false, allSelected: false })
    }


    setChildRef = (ref) => {
        this.paginationRef = ref;
    }

    sendMessage = (athlete) => {
        this.props.history.push(ROUTE_PATH.MESSAGES, { selectedUser: athlete, userType:"Athlete" });
    }

    handleSearch = (searchTerm, timeOut = 0) => {    
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.setState({
          searchTerm
        }, () => {
          this.typingTimeout = setTimeout(() => {
            this.fetchSubscriptionAthletes(0);
          }, (timeOut || 1000))
        });
    }

    selectFilter = (type, value) => {
        let { selectedFilters } = this.state;
        if(selectedFilters[type] == value){
            value = "";
        }
        let newFilters = {...selectedFilters, [type]: value}
        this.setState({selectedFilters: newFilters}, ()=>  this.fetchSubscriptionAthletes(0))
    }
    
    generateCSVInfo = async () => {
        let { enableFullScreenLoader, currentTeam } = this.props;        
        try {
            enableFullScreenLoader(true, "Please wait while we process your request...");
            let { data: { message } } = await biabClient.getCustomerCsvByTeamId(currentTeam.id);         
            await downloadFileFromUrl(message, `${currentTeam.name}_customers.csv`);
        }
        catch (e) {
            console.log(e)
        }
        enableFullScreenLoader(false);
    }


    render() {
        let { isLoading, athletes, showLoadingChild, loadingMessage1, loadingMessage2, currentPage, athletesCount, totalPages, sortValue, searchTerm, selectedFilters, originalCount } = this.state;
        let { classes } = this.props;
        return (
            <PageWrapper setChildRef={this.setChildRef} {...this.props} title={"Customers"} removePadding={true}>
                <div className={classes.pageContainer}>
                    <BodyWrapper isLoading={isLoading} showLoadingChild={showLoadingChild} loadingMessage1={loadingMessage1} loadingMessage1={loadingMessage2}>
                        <div className={classnames(classes.headerSection, "d-flex align-items-center justify-content-between")}>
                            <SearchInput placeholder={"Search Customers"} customClass={classes.searchBox} searchTerm={searchTerm} handleSearch={this.handleSearch}/>
                            <div className={classnames(classes.filterSection, "d-flex")}>
                               {filters.map(filter => <FilterDropDown
                                        height={"44px"}
                                        value={selectedFilters[filter.type]}
                                        label={filter.displayValue}
                                        onChange={value => this.selectFilter(filter.type, value)}
                                        menuItemData={filter.values}
                                        showDropDownBorder={true}
                                    />)
                                }
                                <ThemeButton
                                    overrideContainerClassName={classes.generateCSVBtn}
                                    width={"240px"}
                                    textColor={"#ffffff"}
                                    color={"#1354F9"}
                                    height={"44px"}
                                    theme="small"
                                    iconName="Download"
                                    onClick={this.generateCSVInfo}
                                    disabled={!originalCount}
                                    text={"Export Customer Information"}
                                    />
                            </div>
                        </div>
                        <div className={classes.athleteListContainer}>
                            <table className={classes.userInfo}>
                                <thead>
                                    <tr className={classes.tableHead}>
                                        {this.fields.map((field) => { return <th onClick={()=>this.sortData(field.sortParam, field.value)} className={classnames(classes.cursorPointer, {[classes.sorted]: field.value == sortValue})}>{field.displayName}<SwapVertIcon className={classes.sortIcon}/></th> })}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className={classes.athleteList}>
                                    {athletes.map((athlete, index) => (
                                        <tr key={index} >
                                            {this.fields.map((field) => {
                                                return (
                                                    field.value == "name" ?
                                                        <td className={classnames(classes.td, classes.nameContainer)}>
                                                            <span className={classes.lastNameSection}>
                                                                <span className={classes.athleteImgBox}>
                                                                    {!!athlete.user && !!athlete.user.avatarUrl ?
                                                                        <img src={athlete.user.avatarUrl} /> :
                                                                        <AccountCircle />
                                                                    }
                                                                </span>
                                                                <span>{athlete.name || ""}</span>
                                                            </span>
                                                        </td> :
                                                        field.value == "status" ?
                                                            <td className={classnames(classes.td, classes.statusContainer)}>
                                                                <span className={classes.statusBox} style={{
                                                                    background: athlete.statusInfo.color
                                                                }}>{athlete.statusInfo.name}</span>
                                                            </td> :
                                                            <td className={classes.td}>
                                                                {athlete[field.value] || ""}
                                                            </td>
                                                )
                                            })}

                                            <td className={classnames(classes.td, classes.sendInviteContainer, classes.cursorPointer)}>
                                                {(athlete.statusInfo.name == STATUS.ACTIVE || athlete.statusInfo.name == STATUS.TRIALING) &&  <AirplaneIcon onClick={() => this.sendMessage(athlete.user)}/>}
                                            </td>
                                        </tr>))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className={classnames(classes.additionalInfoContainer, "d-flex align-items-center")}>
                            {!isLoading && <div className={classes.pageInfo}>
                                Showing
                            <span className={"font-weight-bold"}> {(currentPage * 20) + Math.min(athletes.length, 1)} - {(currentPage * 20) + athletes.length} </span> of <span>{athletesCount}</span>
                            </div>}
                        </div>
                        <div className="d-flex justify-content-end">
                            <Pagination
                                currentPage={currentPage + 1}
                                totalPages={totalPages}
                                changeCurrentPage={this.changeCurrentPage}
                                theme="square-i"
                            />
                        </div>
                    </BodyWrapper>
                </div>
            </PageWrapper>);
    }
}


function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        userContext: userContext,
        appContext: appContext
    };
}



export default withStyles(styles)(connect(mapStateToProps)(Customers));
