import React, { Component } from "react";
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import WistiaVideo from "../../containers/video/wistia";
import Vimeo from 'react-vimeo';
export default class VideoPlayer extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    fallBackToDifferentFormat(url){
        //Try changing video format if there is failure;
        const {fallbackUrl} = this.state;
        if(!fallbackUrl){
            let splittedUrl = url.split('.');
            let extension = splittedUrl.pop();
            extension = extension == "mp4"? "mov":"mp4";
            splittedUrl.push(extension);
            this.setState({fallbackUrl:splittedUrl.join('.')});
        }
    }

    render(){
        const youtubeOpts = {
            volume: true,
            width: '650',
            height: '400'
        };

        const { activity } = this.props
        let url = activity.attachment
        if (activity.videoType === "coach") {
            const videoName = (activity.videoName || '').toLowerCase();
            if((videoName.endsWith('mov') || videoName.endsWith('mp4') || videoName.endsWith('m4v')) && (activity.legacyVideoId || activity.videoId)){
                url = `https://programax-videos-production.s3.amazonaws.com/videos/${activity.legacyVideoId || activity.videoId}/processed/${activity.videoName}`
            }
        }
        //Use fallback url on failure
        url = this.state.fallbackUrl || url || "";
        return(
            activity.videoType == "coach" && !!url ? <div className='playerWrapper'>
                <ReactPlayer
                    controls={true}
                    className='react-player'
                    url={url || url.replace(/\.(m4v)/i, '.mp4')}
                    onError={()=>this.fallBackToDifferentFormat(url)}
                />
            </div>
            : activity.videoType == "youtube" ?
                // <div className="videoContainer">
                    <YouTube
                        opts={youtubeOpts}
                        videoId={activity.videoId}
                    />
                // </div>
            : activity.videoType == "vimeo" ?
                <div className="videoContainer">
                    <Vimeo playerOptions={{ style: {height: '100%', width: '100%'} }} videoId={ activity.videoId } autoplay={true} />
                </div>
            : activity.videoType == "wistia" ?
                <div className="videoContainer">
                    <WistiaVideo playerOptions={{ style: { height: '100%', width: '100%' } }} videoId={activity.videoId} autoplay={true} />
            </div>
            :
            <div className='playerWrapper'>
                <ReactPlayer
                    controls={true}
                    className='react-player'
                    url={url || url.replace(/\.(m4v)/i, '.mp4')}
                    onError={()=>this.fallBackToDifferentFormat(url)}
                />
            </div>
        )
    }
}