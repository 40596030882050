import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CrossIcon = (props) => (
  <SvgIcon width="14" height="14" viewBox="0 0 15 16" fill="none"{...props}>
<path d="M12.8471 2.5255L7.31472 8.05784L1.78124 2.52436C1.7468 2.48992 1.69111 2.49004 1.65679 2.52436C1.62247 2.55868 1.62235 2.61437 1.65679 2.64881L7.19039 8.18241L1.65805 13.7148C1.62348 13.7491 1.62348 13.8046 1.65793 13.8391C1.69237 13.8735 1.74793 13.8735 1.78237 13.8391L7.31484 8.30661L12.8462 13.8379C12.8804 13.8724 12.9361 13.8723 12.9704 13.8379C13.0047 13.8036 13.0048 13.7479 12.9704 13.7135L7.43904 8.18216L12.9715 2.64969C13.0058 2.61538 13.006 2.55969 12.9715 2.52524C12.9371 2.4908 12.8815 2.49105 12.8471 2.5255Z" fill="#27292A" stroke="#27292A" stroke-width="3"/>
</SvgIcon>
);

export default CrossIcon;
