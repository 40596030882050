import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';

import {
  Avatar,
  Button,
  Hidden,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';

import PersonOutlineIcon  from "@material-ui/icons/PersonOutline";
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import AssessmentIcon from '@material-ui/icons/Assessment';

import _ from "lodash";
import "./new.css";
import moment from "moment";
import { API, Auth, Storage } from "aws-amplify";
import logo from "../../assets/images/logo-dark.png"

import { setUserContext, setAppContext } from '../../actions/session';
import ContextService from '../../services/context';
const contextService = new ContextService()

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: '100%',
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  header: {
    fontSize:35,
    display:"inline-block",
    marginLeft:30,
    textAlign:"left"
  },
  subHeader: {
    fontWeight: 100,
  },
  selectedRosterCard: {
      backgroundColor: "#27AE60"
  },
  modalInviteButton: {
      width: 100,
      height: 40,
      marginRight: 10,
      backgroundColor: "#27AE60",
      color: theme.product.name == 'MaxOne' ? 'black' : 'white'
  },
  addUserButton: {
      height: 40,
      color: 'white',
      backgroundColor: "#1354F9"
  },
  plusButton: {
      backgroundColor: "#1354F9",
      color: 'white',
      outlineColor: 'transparent',
      outlineStyle: 'none'
  },
  textButton: {
      color: 'white',
      height: '40px',
      marginRight: '3px',
      backgroundColor: "#1354F9",
  },
  pageTitle: {
      textAlign: 'left',
      marginLeft: 50
  },
  details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: `100%`
  },
});
class WCSAdminCreateTeams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            athletes: [],
            schools: [],
            teams: [],
            renderedAthletes: [],
            renderedCoaches: [],
            selectedAthlete: [],
            coaches: [],
            message: '',
            error: false,
            errorMessage: '',
            searchText: null,
            isLoading: false,
            selectedSchool:null,
            rosterFilterBy: 'graduationYear',

        };
    }

    async componentDidMount(){
        const { currentTeam } = this.props;
        const organization = await this.getOrganization(currentTeam.organizationId)
        const teams = await this.getTeamsByOrganization(organization.id);
        const wcsOrgId = organization.id.split("-")[1]
        // const wcsOrgId = "wcs-284".split("-")[1]
        const defaultTeamId = "wcs-default-" + wcsOrgId
        console.log('defaultTeamId ', defaultTeamId)
        let schools = await this.getSchools()
        console.log('Schools ', schools )
        this.setState({organization, schools});
    }

    getAthlete() {
        var athleteId = this.props.match.params.id
        return API.get("users", `/users/${athleteId}`);
    }

    getOrganization(organizationId) {
        return API.get("organizations", `/organizations/${organizationId}`);
    }

    getTeamsByOrganization(organizationId) {
        return API.get("programs", `/programs/organization/${organizationId}`);
    }

    getAthletes(id){
        const teamId = id ? id : this.props.currentTeam.id;
        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("programs", `/programs/${teamId}/players`);
        })
    }

    getCoaches(id){
        const teamId = id ? id : this.props.currentTeam.id;

        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("programs", `/programs/${teamId}/coaches`);
        })
    }

    createTeam(team){
        return API.post('programs', '/programs', {
            body: team
        })
    }

    createSchedule(currentTeam, schedule) {
        return API.post("schedules", `/teams/${currentTeam.id}/schedules`, {
            body: schedule
        });
    }

    createRole(role) {
        return API.post("users", `/users/${role.userId}/roles`, {
            body: role
        });
    }

    getSchools() {
        return API.get("wcs", `/wcs/organizations`);
    }

    updateOrganization(organization){
        return API.post("organizations", `/organizations/${organization.id}`, {
            body: organization
        });
    }

    updateTeams = async () => {
      this.setState({teams:[], renderedTeams: [], isLoadingTeams:true})
      const wcsOrgId = this.state.selectedSchool.id
      console.log('wcsOrgId ', wcsOrgId)
      let teams = await this.getTeamsByOrganization(wcsOrgId)
      console.log('teams ', teams)
      this.setState({teams, renderedTeams:teams, isLoadingTeams:false})
    }

    setSessionInfo = async (username) => {
      let prefix = null
      if(window.location.href.toLowerCase().includes('wcs'))  prefix = "wcs";
      console.log('prefix ===>', prefix)
      const contextService = new ContextService();
      const {userContext, appContext} = await contextService.buildUserContext(username, prefix);
      this.props.setUserContext(userContext);
      this.props.setAppContext(appContext);
    }

    handleSubmit = async event => {
        const { name, sport, selectedAthlete } = this.state;
        const { currentTeam, currentUser } = this.props;
        let organization = this.state.selectedSchool;
        const team = {
            name: name,
            legacyId: 0,
            sport: sport,
            customName: `${name} ${sport}`,
            organizationId: organization.id,
            tenant: "wcs",
            tenantId: "wcs"
        }
        const newTeam = await this.createTeam(team);

        const scheduleJSON = {
            name: newTeam.customName,
            visibility: 'everyone',
            color: '#dce775',
        }
        await this.createSchedule(newTeam, scheduleJSON);

        await this.asyncForEach(selectedAthlete, async user =>{
          const roleJSON = {
              legacyId: 0,
              role: user.role === "student" ? "player" : "coach",
              parentId: newTeam.id,
              userId: user.id,
              tenant: "wcs",
              tenantId: "wcs"
          }
          const newRole = await this.createRole(roleJSON);

        })
        //
        // const roleJSON = {
        //     legacyId: 0,
        //     role: "coach",
        //     parentId: newTeam.id,
        //     userId: currentUser.id,
        //     tenant: "wcs",
        //     tenantId: "wcs"
        //
        // }
        // const newRole = await this.createRole(roleJSON);
        // const updatedSession = await this.setSessionInfo(currentUser.username);
        alert(`Successfully created team ${team.name} in ${organization.name}.`)
        this.setState({value:0, selectedSchool:null})
    }

    handleChange = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }

    handleSelectSchool = school => {
        this.setState({
            selectedSchool: school === this.state.selectedSchool ? null : school
        })
    }


    handleSelect = athlete => {
        // check if athlete is in the selection already
        let selectedAthletes = this.state.selectedAthlete;
        console.log('selectedAthletes.length', selectedAthletes.length)
        let isSelected = _.find(selectedAthletes, {id: athlete.id})
        console.log('isSelected', isSelected)

        // if not, then push onto array
        // if they are, then remove them from array.
        if(isSelected){
          let index = _.findIndex(selectedAthletes, {id: athlete.id})
          console.log('Index found ===>', index)
          selectedAthletes.splice(index, 1)
        }
        else{
          selectedAthletes.push(athlete);
        }
        console.log('selectedAthletes.length after', selectedAthletes.length)

        this.setState({
            selectedAthlete: selectedAthletes
        })
    }

    searchAthletes = event => {
        const { athletes } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedAthletes: _.filter(athletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()) || (a.graduationYear && a.graduationYear == event.target.value))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedAthletes: athletes
            })
        }
    }

    searchCoaches = event => {
        const { coaches } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedCoaches: _.filter(coaches, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedCoaches: coaches
            })
        }
    }

    handleFilter = filterValue => event => {
        this.setState({
            rosterFilterBy: filterValue
        })
    }

    renderSchoolInputs(){
        const { schools, rosterFilterBy, selectedSchool, appContext } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                        <Grid container className={"content"}>
                            <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('id')}>
                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                        <strong>ID</strong>
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('name')}>
                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                        <strong>Name</strong>
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('identifier')}>
                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                        <strong>Identifier</strong>
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                      </Grid>

                        {
                            _.orderBy(schools, [rosterFilterBy, 'name']).map((school, index) => {
                            return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                    <SchoolCard
                                        school={school}
                                        classes={classes}
                                        appContext={appContext}
                                        selected={selectedSchool === school}
                                        handleSelect={() => this.handleSelectSchool(school)}
                                    />
                                </Grid>)
                            })
                        }
                </Grid>
                </Grid>
            </Grid>
        )
    }

    renderTeamsInputs(){
        const { teams, rosterFilterBy, appContext } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                        <Grid container className={"content"}>
                            <Grid item xs={6} className={"rosterColumnContainer"} onClick={this.handleFilter('id')}>
                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                        <strong>Name</strong>
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={"rosterColumnContainer"} onClick={this.handleFilter('name')}>
                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                        <strong>Created</strong>
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                      </Grid>

                        {
                            _.orderBy(teams, [rosterFilterBy, 'name']).map((team, index) => {
                            return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                    <TeamCard
                                        team={team}
                                        classes={classes}
                                        appContext={appContext}
                                        selected={false}
                                        handleSelect={() => false}
                                    />
                                </Grid>)
                            })
                        }
                </Grid>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { classes, theme, appContext, userContext } = this.props;
        const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
        const { error, message, errorMessage, isLoading, value, teams } = this.state;

        return (
        <div className={"newTeamOuterContainer"}>
            <div className={"pageTitleContainer"}>
            <div className={"avatarNameContainer"}>
                <h1 className={"pageTitle"}>New Team</h1>
            </div>
            {
              value !== 1 ?
            <div className={"titleButtonContainer"}>
            {
              value !== 0 &&
              <Button
                  mini
                  color="default"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({searchText: '', renderedTeams: teams })
                    this.setState({value:  value - 1})
                  }}
              >
                  Back
              </Button>
            }
              <Button
                  mini
                  color="primary"
                  variant="contained"
                  disabled={!this.state.selectedSchool}
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({value:  value + 1})
                    if(value + 1 === 1){
                      this.updateTeams()
                    }
                  }}
              >
                  Next
              </Button>
              </div>
              :
              <div className={"titleButtonContainer"}>
              {
                value !== 0 &&
                <Button
                    mini
                    color="default"
                    variant="contained"
                    title="View Schedules"
                    className={"styledButton"}
                    onClick={() =>{
                      console.log('changing value')
                      this.setState({searchText: '', renderedTeams: teams })
                      this.setState({value:  value - 1})
                    }}
                >
                    Back
                </Button>
              }
              </div>
            }

            </div>
            <SwipeableViews index={value}>
              <div className={"wcsInnerContainer"}>
                  {this.renderSchoolInputs()}
              </div>
              <div className={"wcsInnerContainer"}>
                  {this.renderTeamsInputs()}
              </div>
            </SwipeableViews>
        </div>

        );
    }
}

class SchoolCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          school: {}
      };
    }
    async componentDidMount(){
      this.setState({school: this.props.school || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      this.setState({school: props.school})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.school ? `${this.props.school.name}` : "";
        const { school } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${selected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {school && school.id ? school.id.split("-")[1] : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {school && school.name ? school.name : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                  {school && school.identifier ? school.identifier : ""}
                                </p>
                            </div>
                        </Grid>
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}

class TeamCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          team: {}
      };
    }
    async componentDidMount(){
      this.setState({team: this.props.team || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      this.setState({team: props.team})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.team ? `${this.props.team.customName}` : "";
        const { team } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${selected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={6} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {name}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={6} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                  {team && team.createdAt ? moment(team.createdAt).format("M/DD/YYYY hh:mm:ss a") : ""}
                                </p>
                            </div>
                        </Grid>
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}
class AthleteCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          athlete: {},
          isSelected: false
      };
    }
    async componentDidMount(){
      this.setState({athlete: this.props.athlete || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      if(props.selected === -1){
        this.setState({isSelected: false})
      }
      else{
        this.setState({isSelected: true})

      }
      this.setState({athlete: props.athlete})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.athlete ? `${this.props.athlete.nameFirst} ${this.props.athlete.nameLast}` : "";
        const email = this.props.athlete ? `${this.props.athlete.email}` : "";
        const { athlete, isSelected } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${isSelected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15}}>
                                    {athlete && athlete.nameLast ? athlete.nameLast : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"}>
                                  {athlete && athlete.nameFirst ? athlete.nameFirst : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"}>
                                  {athlete && athlete.email ? athlete.email : ""}
                                </p>
                            </div>
                        </Grid>
                        {
                          athlete.graduationYear &&
                          <Grid item xs={3} className={"rosterColumnContainer"} >
                              <div style={{paddingTop:10}}>
                                  <p className={"textContainer"}>
                                      {athlete.graduationYear}
                                  </p>
                              </div>
                          </Grid>
                        }
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

WCSAdminCreateTeams.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WCSAdminCreateTeams));
