import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let HockeyIcon = (props) => (
  <SvgIcon width="20" height="12" viewBox="0 0 20 12" {...props}>
  	<path d="M19.2262 1.88965C18.1864 0.947611 15.3482 0.232106 11.834 0.0249649C11.0754 -0.0219736 8.27256 0.00149577 7.59236 0.0602709C3.69871 0.388636 1.06016 1.20557 0.4269 2.28454C0.340982 2.42923 0.321392 2.51127 0.321392 2.71842C0.321392 3.03107 0.430778 3.23821 0.755266 3.53535C1.60363 4.30942 3.73035 4.96228 6.38053 5.25554C7.72909 5.40411 8.30766 5.43533 9.99643 5.43533C10.9972 5.43533 11.9042 5.41574 12.2833 5.38452C16.2317 5.06779 18.9249 4.24289 19.5664 3.15229C19.6523 3.0076 19.6719 2.92556 19.6719 2.71842C19.6719 2.40168 19.5584 2.19454 19.2262 1.88965Z" />
<path d="M19.9492 8.70711C19.9884 8.59385 20 8.01528 20 5.98244C19.9961 4.56347 19.9843 3.4494 19.9688 3.50409C19.8398 3.91837 19.3825 4.31327 18.581 4.69633C16.8649 5.51346 13.9878 6.00591 10.5633 6.06856C6.29071 6.14285 2.39318 5.45081 0.735645 4.32511C0.387892 4.08654 0.106261 3.76205 0.0242203 3.50409C0.00850611 3.44919 -0.00312647 4.59082 0.000751058 6.04101L0.00850571 8.67956L0.121975 8.87119C0.567687 9.62956 1.93584 10.2981 4.05848 10.7867C5.96622 11.2246 8.19049 11.4318 10.5633 11.3848C12.1466 11.3534 13.3427 11.2518 14.6288 11.0408C17.5725 10.56 19.6051 9.66486 19.9492 8.70711Z" />
  </SvgIcon>
);

export default HockeyIcon;