import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash"
import { API, Storage } from "aws-amplify";
import { s3Upload } from "../../libs/awsLib";
import classnames from 'classnames';
// Material UI
import {
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Tabs,
    Tab,
    Select,
    MenuItem,
    IconButton,
    InputLabel,
    FormControl,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Input,
    Snackbar,
    Slide
  } from "@material-ui/core";

import { refreshUserContext } from '../../actions/session'
import { withStyles } from '@material-ui/core/styles';

import planPromo from "../../assets/images/signup/signup-bg-callout.png"
import { getThemeByTenant } from '../../themes';

import CloseIcon from '@material-ui/icons/Close';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { setUserContext, setAppContext } from '../../actions/session';
import ImageCropperModal from '../../components/modals/imageCropperModal'

import ContextService from '../../services/context';

import config from "../../config"
import "./settings.css";
import PageWrapper from '../nav/pageWrapper';
import { tenantMap } from "../../utils/commonUtil";
import { getLocalizedString } from '../../utils/locale-utils';
import ModalContainer from '../../components/modalContainer';
import { userClient, teamClient } from '../../clients';
import { ROUTE_PATH } from "../../routeUtil";

const contextService = new ContextService()

const styles = theme => ({
  plusButton: {
    backgroundColor: "#1354F9",
    color: 'white'
  },
  textButton: {
      color: 'white',
      height: '40px',
      marginRight: '3px',
      backgroundColor: "#1354F9",
  },
  secondaryTextButton: {
    height: '40px',
    marginRight: '3px',
    backgroundColor: "#27AE60",
    color:"#ffffff",
    '&:disabled': {
      opacity:"0.3",
      color:"#ffffff"
  }
  },
  planPromo:{
    background:'url(' + planPromo + ')',
    backgroundPosition:'50%',
    backgroundSize:'cover'
  },
  biabUrl:{
    textAlign: 'left',
    fontSize: '16px',
    cursor: 'pointer'
  },
  biabUrlText:{
    color:'#0645AD',
    textDecoration:'underline'
  },
  deleteBtn:{
    background:"#F93F3F"
  },
  displayInitial: {
    flexWrap: 'initial'
  },
  whiteColor: {
    color:"#ffffff"
  }
});

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding:15 }}>
      {children}
    </Typography>
  );
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tabValue:0,
      fileName: "",
      orgAttachment: null,
      loadingMetrics: false,
      isCropperModalOpen: false,
      subscriptionPlans:[],
      monthly:"",
      quarterly:"",
      yearly:"",
      checkedMonthly:false,
      checkedQuarterly:false,
      checkedYearly:false,
      organization:{
        name:"",
        type:"",
        address1:"",
        address2:"",
        postalCode:"",
        city:"",
        state:""
      },
      team:{
        name:"",
        sport:"",
        playerCode:"",
        coachCode:""
      },
      billing:{
        contactName:"",
        contactEmail:"",
        contactPhone:""
      },
      expMonth:"",
      expYear:"",
      cvc:"",
      number:"",
      coaches:[],
      teamId:"",
      order: 'asc',
      orderBy: 'nameFirst',
      selected: "",
      page: 0,
      open: false,
      Transition: TransitionUp,
      biabLandingUrl:"",
      athletesCount:0
    };
  }

  async componentWillReceiveProps(props, nextProps){
    if(this.state.currentTeam && props.currentTeam && props.currentTeam.id !== this.state.currentTeam)
    if(props.currentTeam.id !== this.state.currentTeam){
      this.setState({currentTeam: props.currentTeam})
      var teamId = props.currentTeam.id
      this.setState({teamId})

      //get organization using current team.
      let organization = await this.getOrganization(props.currentTeam.organizationId)
      var keyMetrics = await this.getKeyMetricOptions(props.currentTeam.id)
      keyMetrics = _.sortBy(keyMetrics, 'name');
      var team = props.currentTeam;

      if(!team.keyMetrics){
        team.keyMetrics = []
      }
      this.setState({team:team,  keyMetricOptions: keyMetrics, organization})
    }
  }

  getSignedUrl = async (key) =>{
    let result = null
    const customPrefix =  { public: "uploads/", private: "uploads/", protected:"uploads/" };
    result =  await Storage.get(key, {customPrefix: customPrefix, bucket: 'programax-videos-production'})
      .then(returned => {
        return returned
      })
      .catch(err => console.log(err));
    return result
  }

  resetSubscriptionPricing = () => {
    this.setState({
      yearly:"",
      monthly:"",
      quarterly:"",
      checkedYearly:false,
      checkedMonthly:false,
      checkedQuarterly:false
    })
  }

  async componentDidMount(){
    this.setState({checkedMonthly:false, checkedQuarterly:false, checkedYearly:false})
    this.setState({monthly:"", quarterly:"", yearly:""})
    let teamLogo = null;
    var team = this.props.currentTeam;

    if (this.props.location && this.props.location.state) {
      let tabValue = this.props.location.state.tabValue;
      if (tabValue) this.setState({tabValue: tabValue})
    }
    if (!team.keyMetrics) team.keyMetrics = []
    if (team.logo) {
      const idTeam = team.id
      const logoTeam = team.logo
      const teamLegacyId = team?.legacyId
      const teamLegacyIdNotZero = teamLegacyId !== 0 || teamLegacyId !== '0'
      const urlTeamId = teamLegacyIdNotZero ? teamLegacyId : idTeam
      const fullLogoUrl = `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${urlTeamId}/${logoTeam}`
      
      teamLogo = team.logo.includes('https') ? team.logo : fullLogoUrl;
    }
    
    this.setState({team, teamLogo, currentTeam: team })
    this.setState({ loadingMetrics: true })

    var [keyMetrics, organization] = await Promise.all([this.getKeyMetricOptions(team.id), this.getOrganization(team.organizationId), this.fetchAthletesCount()]);
    keyMetrics = _.sortBy(keyMetrics, 'name');
    this.setState({organization, keyMetricOptions: keyMetrics, loadingMetrics: false })
  }

  handleClick = (event, id) => {
    const { appContext } = this.props
    if(appContext.isOwner){
      if(this.state.selected === id){
        this.setState({ selected: "" });
      }
      else{
        this.setState({ selected: id });
      }
    }
  };

  toggleModal = () => {
    this.setState({ isCropperModalOpen: !this.state.isCropperModalOpen })
  }

  getCroppedAttachment = async (attachment) => {
    const { team } = this.state;
    team.logo = attachment;
    this.setState({ teamLogo: attachment })
    await this.handleSaveTeam();
    this.setState({ isCropperModalOpen: false });
  }

  handleFileChange = async(event) => {
    const { currentTeam } = this.props;
    const idForS3 = currentTeam.legacyId && (currentTeam.legacyId !== 0 || currentTeam.legacyId !== '0') ? currentTeam.legacyId : currentTeam.id;
    this.file = event.target.files[0];
    const fileStructure = `uploads/program/logo/${idForS3}/${this.file.name}`;
    const attachment = this.file ? await s3Upload(this.file, fileStructure) : null;

    this.setState({
        isCropperModalOpen: true,
        attachment: attachment,
        fileName: this.file ? this.file.name : ''
    })
  }

  async handleDeleteOwnerRole(roles) {
    const { currentTeam } = this.props;
    const role = _.find(roles, function(result) { return result.role === "owner"; })
    await this.deleteRole(role.id)
    const coaches = await this.getCoaches(currentTeam.id)
    this.setState({coaches})
  };

  async handleAddOwnerRole(user) {
    const { currentTeam } = this.props;
    var role = {
      parentId:currentTeam.organizationId,
      userId:user.id,
      role:"owner"
    }
    await this.createRole(role)
    const coaches = await this.getCoaches(currentTeam.id)
    this.setState({coaches})
  };

  setSessionInfo = async () => {
    const { currentTeam, tenant } = this.props
    const contextService = new ContextService();
    const appContext = await contextService.changeAppContext(this.props.userContext, currentTeam.id, tenant);

    this.props.setAppContext(appContext);
  }

  hideSnackBar(){
    setTimeout(() => this.setState({open:false}), 5000)
  }

  async handleSaveTeam() {
    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });
    let team = this.state.team;

    try {
      await this.saveTeam();
      var userContext = this.props.userContext;
      team = _.find(userContext.appContextList, function(data){ return data.id === team.id});
      var index = userContext.appContextList.indexOf(team);
      userContext.appContextList.splice(index, 1, team);
      this.props.setUserContext(userContext);
      await this.setSessionInfo();
      this.setState({
        open: true,
        attachment: null,
        message:`Successfully Updated ${team.name}, you may have to reload to see your changes reflected`,
      })
      this.hideSnackBar();
      // window.location.reload();
    }
    catch(e){
      console.log('ERROR =>', e)
      let message = "";
      let apiResponseError = e.response ? e.response.data:{};
      if(apiResponseError.coachCodeUsed || apiResponseError.playerCodeUsed){
        message = "";
        if(apiResponseError.coachCodeUsed)
            message = "Coach code";
        if(apiResponseError.playerCodeUsed)
            message = (message ? message + " and " : "") + "Athlete code";
        message = message + " already in use."
      }
      this.setState({open:true, message})
    }

  }

  handleChangeTeam = name => event => {
    var team = this.state.team;
    if(name === 'isFree')
      team[name] = team.isFree ? false : true
    else if(name === 'isParentInfoHidden')
      team[name] = team.isParentInfoHidden ? false : true
    else
      team[name] = event.target.value

    this.setState({ team });
  };

  handleChange= name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeKeyMetric = name => event => {
    var team = this.state.team;
    if(!team.keyMetrics[0]){
      team.keyMetrics[0] = null
    }
    if(!team.keyMetrics[1]){
      team.keyMetrics[1] = null
    }
    if(!team.keyMetrics[2]){
      team.keyMetrics[2] = null
    }
    var metric = _.find(this.state.keyMetricOptions, c => c.id === event.target.value)
    team.keyMetrics[name] = metric
    this.setState({team});
  };

  handleInputChange = event => {
    event.preventDefault()
    this.setState({
      query: event.value
    })
  }

  handleTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  deleteRole(id) {
    return API.del("users", `/users/roles/${id}`);
  }

  createRole(role) {
    return API.post("users", `/users/${role.userId}/roles`,{
      body:role
    });
  }

  getOrganization(organizationId) {
    return API.get("organizations", `/organizations/${organizationId}`);
  }

  getKeyMetricOptions(teamId) {
    return API.get("activities", `/programs/${teamId}/activities`);
  }

  saveTeam(){
    const { team } = this.state;
    if(team.id){
      return API.put("programs", `/programs/${team.id}`,{
        body:team
      });
    }
    else {
      return API.post("programs", `/programs`,{
        body:team
      });
    }
  }

  getCoaches(teamId) {
    return API.get("programs", `/programs/${teamId}/coaches`);
  }

  changeStaffPermissions = permission => event => {
    const { team } = this.state;
    if(!team.staffPermissions) team.staffPermissions = {}
    team.staffPermissions[permission] = team.staffPermissions[permission] === undefined ? false : !team.staffPermissions[permission]
    this.setState({team})
  }

  toggleDeleteModal = () => {
    let { enableDeleteTeamModal } = this.state;
    this.setState({ enableDeleteTeamModal: !enableDeleteTeamModal})
  }

  fetchAthletesCount = async () => {
    let { currentTeam } = this.props;
    let queryParams = {
      "teams.id.keyword": currentTeam.id,
      "teams.role": "ATHLETE",
      limit: 0,
    };
    try {
      let { data } = await userClient.searchUsers(queryParams);
      let { total: athletesCount } = data;
      this.setState({ athletesCount});
    }
    catch (e) {
      console.log(e)
    }
  }

  deleteTeam = async () => {
    let { enableFullScreenLoader, currentTeam, history } = this.props;
    try {
      enableFullScreenLoader(true, "Deleting Team....");
      await teamClient.deleteTeamById(currentTeam.id);
    }
    catch (e) {
      console.log(e)
    }
    enableFullScreenLoader(false);
    this.props.refreshUserContext();
    history.push(ROUTE_PATH.HOME);
  }

  render() {
    const { classes, tenant, currentTeam } = this.props;
    const { tabValue, fileName, isCropperModalOpen, attachment, enableDeleteTeamModal, athletesCount } = this.state;
    const teamName = (currentTeam.customName || (currentTeam.name + currentTeam.sport));
    //STAFF PERMISSION IS TEMPORARILY DISABLED
    //const hasStaffAccess = ((tenant !== tenantMap.acesnationconnect && !appContext.isStaff && tenant !== tenantMap.coachup) || (appContext.isOwner && tenant == tenantMap.acesnationconnect))
    const hasStaffAccess = false;
    return (
      <PageWrapper title={this.state.tabValue === 0? getLocalizedString("SETTINGS"): `${this.state.currentTeam ? (this.state.currentTeam.name||"") : ''} `+getLocalizedString("SETTINGS")} {...this.props}>
      <div className={"settingsOuterContainer"}>
        <ImageCropperModal
            title={"Team Logo"}
            fileName={fileName}
            attachment={attachment}
            close={this.toggleModal}
            open={isCropperModalOpen}
            getCroppedAttachment={this.getCroppedAttachment}
        />
        <Snackbar
            open={this.state.open}
            TransitionComponent={this.state.Transition}
            ContentProps={{
              'aria-describedby': 'message-id',
              className:classes.displayInitial
              }}
            message={<span id="message-id">{this.state.message}</span>}
            action={[
              <IconButton key="close" className={classnames(classes.close, classes.whiteColor)} onClick={() => this.setState({open:false, message:""})}>
                <CloseIcon className={classes.icon}/>
              </IconButton>
            ]}
          />
        <div className={"pageTitleContainer"}>
          <h2 className={"pageTitle"}> </h2>
        </div>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Paper>
              <div style={{borderBottom:'1px solid lightgray'}}>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={getLocalizedString("TEAM")} />
                  {tenant !== tenantMap.coachup && <Tab label={getLocalizedString("KEY_METRICS")} />}
                  {hasStaffAccess ?
                    <Tab label={getLocalizedString("STAFF_PERMISSIONS")} />
                  :null}
                  {/* <Tab label="Support" /> */}
                </Tabs>
            </div>

            {
              tabValue === 0 &&
              <TabContainer>
                {this.renderTeam()}
              </TabContainer>
            }
            {
              tenant !== tenantMap.coachup && tabValue === 1 &&
              <TabContainer>
                {this.renderKeyMetrics()}
              </TabContainer>
            }
            {
              hasStaffAccess && tabValue === 2 &&
              <TabContainer>
                {this.renderStaffPermissions()}
              </TabContainer>
            }
            {/* {
              tabValue === 2 &&
              <TabContainer>
                {this.renderSupport()}
              </TabContainer>
            } */}
            </Paper>
          </Grid>
        </Grid>
          {
            enableDeleteTeamModal && <ModalContainer
              width={"800px"}
              open={true}
              onClose={this.toggleDeleteModal}
              heading={`Delete Team: ${teamName}`}
              useDefaultLeftBtn={true}
              defaultLeftBtnProps={{
                iconName:null,
                onClick: this.toggleDeleteModal
              }}
              useDefaultRightBtn={true}
              defaultRightBtnProps={{
                iconName:null,
                text:"Delete",
                disabled:athletesCount,
                onClick:this.deleteTeam,
                color:"#F93F3F"
              }}
            >
              {!athletesCount? <div>Click delete to confirm.</div>:
              <div>{`A team can only be deleted when all athletes have been removed. There currently exists ${athletesCount} ${'athlete'.pluralize('athletes',athletesCount)} on the team. Please remove before deleting.`}</div>}
            </ModalContainer>
          }
      </div>
      </PageWrapper>
    );
  }

  renderSupport() {
    const { tenant } = this.props;
    const theme = getThemeByTenant(tenant);

    return(
      <div style={{textAlign:"left", marginBottom:15}}>
        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <h4 className={'subHeading'} style={{textAlign:'left'}}>Support</h4>
          </Grid>
          <Grid item sm={12} md={6} style={{marginTop:10, textAlign:"left"}}>
              <p style={{fontWeight:"bold"}}>Tour</p>
              <p>{`Most pages in ${theme.product.name} have a guided tour box at the top. You may have already hidden some of them. Reset the tour here to bring them all back.`}</p>
              <Button variant="outlined" className={"outlinedButton"}>
                  Reset
              </Button>
          </Grid>
          <Grid item sm={12} md={6} style={{marginTop:10, textAlign:"left"}}>
              <p style={{fontWeight:"bold"}}>Help</p>
              <p>{`There are little glowing help tips all throughout ${theme.product.name}. If you have viewed the help tip, it will no longer glow. Reset the helpers here to make them glow again so you can find them easier.`}</p>
              <Button variant="outlined" className={"outlinedButton"}>
                  Reset
              </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderKeyMetrics() {
    const { classes, currentTeam } = this.props;
    const { team, keyMetricOptions = [], loadingMetrics } = this.state;
    if(team.keyMetrics && team.keyMetrics.length){
      if(team.keyMetrics[0] && team.keyMetrics[0].legacyId && !team.keyMetrics[0].id){
        let keyMetric = _.find(keyMetricOptions, c => c.legacyId === team.keyMetrics[0].legacyId)
        if(keyMetric){
          team.keyMetrics[0] = keyMetric
        }
      }
      if(team.keyMetrics[1] && team.keyMetrics[1].legacyId && !team.keyMetrics[1].id){
        let keyMetric = _.find(keyMetricOptions, c => c.legacyId === team.keyMetrics[1].legacyId)
        if(keyMetric){
          team.keyMetrics[1] = keyMetric
        }
      }
      if(team.keyMetrics[2] && team.keyMetrics[2].legacyId && !team.keyMetrics[2].id){
        let keyMetric = _.find(keyMetricOptions, c => c.legacyId === team.keyMetrics[2].legacyId)
        if(keyMetric){
          team.keyMetrics[2] = keyMetric
        }
      }
    }

    return(
      <div style={{textAlign:"left"}}>
        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
            <h4 className={'subHeading'} style={{textAlign:'left'}}>Key Metrics </h4>
            {!contextService.isPermittedToUseFeature(currentTeam) && <p className={'eliteText'}>Elite</p>}
          </Grid>
          <Grid item xs={4} style={{textAlign:'right'}}>
            <Button disabled={!contextService.isPermittedToUseFeature(currentTeam)} variant="contained" className={classes.secondaryTextButton} onClick={() => this.handleSaveTeam()}>Save</Button>
          </Grid>
          <Grid item xs={12} className={"inputSectionTitle"}>
              <p style={{textAlign:'left'}}>{`Choose the three most important activities you want to use to measure your athletes (ex. Bench Press Max or Free throws out of 100).  These will show up in their profile on the app and in your "quick-view" on the athlete roster.  After all, measurement is the first step towards improvement!`}</p>
          </Grid>
        </Grid>
        {loadingMetrics ?
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        :
        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Select
              disabled={!contextService.isPermittedToUseFeature(currentTeam)}
              value={team.keyMetrics && team.keyMetrics[0] ? team.keyMetrics[0].id : "none"}
              onChange={this.handleChangeKeyMetric(0)}
              inputProps={{
                name: 'keyMetric0',
                id: 'keyMetric0',
              }}
            >
              <MenuItem value="none">
                <em>No Activity</em>
              </MenuItem>
              {
                keyMetricOptions.map((metric, i) => {
                  return(
                    <MenuItem key={i} value={metric.id}>{metric.name}</MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Select
              disabled={!contextService.isPermittedToUseFeature(currentTeam)}
              value={team.keyMetrics && team.keyMetrics[1] ? team.keyMetrics[1].id : "none"}
              onChange={this.handleChangeKeyMetric(1)}
              inputProps={{
                name: 'metric.legacyId',
                id: 'metric.legacyId',
              }}
            >
              <MenuItem value="none">
                <em>No Activity</em>
              </MenuItem>
              {
                keyMetricOptions.map((metric, i) => {
                  return(
                    <MenuItem key={i} value={metric.id}>{metric.name}</MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
          <Grid item xs={12} style={{marginBottom:15}}>
            <Select
              disabled={!contextService.isPermittedToUseFeature(currentTeam)}
              value={team.keyMetrics && team.keyMetrics[2] ? team.keyMetrics[2].id : "none"}
              onChange={this.handleChangeKeyMetric(2)}
              inputProps={{
                name: 'keyMetric2',
                id: 'keyMetric2',
              }}
            >
              <MenuItem value="none">
                <em>No Activity</em>
              </MenuItem>
              {
                keyMetricOptions.map((metric, i) => {
                  return(
                    <MenuItem key={i} value={metric.id}>{metric.name}</MenuItem>
                  )
                })
              }
            </Select>
          </Grid>
        </Grid>}
      </div>
    )
  }

  renderTeam(){
    const { team, teamLogo } = this.state;
    const { classes, tenant, userContext, appContext,  currentTeam = {} } = this.props;
    const sports = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Other'];

    return(
      <div style={{textAlign:"left"}}>
        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8}>
              <h4 className={'subHeading'} style={{textAlign:'left'}}>{team.customName ? team.customName : `${team.name} ${team.sport} Team`}</h4>
          </Grid>

          <Grid item xs={4} style={{textAlign:'right'}}>
            {appContext.isOwner && <Button variant="contained" className={classnames(classes.secondaryTextButton, classes.deleteBtn)} onClick={() => this.toggleDeleteModal()}>Delete</Button>}
            <Button variant="contained" className={classes.secondaryTextButton} onClick={() => this.handleSaveTeam()}>Save</Button>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={2} style={{paddingTop:5, marginBottom:15}}>
            <p style={{marginTop:0, fontSize:12, color:'rgba(0,0,0,0.54)'}}>Logo</p>
            {
              this.state.attachment
            ?
              <div style={{
                background:'url(' + this.state.attachment + ')',
                width:100,
                height:100,
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                borderStyle:'solid'}}
              >
              </div>
            :
              <div>
              {
                teamLogo
              ?
                <div style={{
                  background:'url(' + teamLogo + ')',
                  width:100,
                  height:100,
                  backgroundPosition:'center',
                  backgroundRepeat:'no-repeat',
                  backgroundSize:'cover',
                  borderStyle:'solid'}}
                >
                </div>
              :
                <div style={{
                  width:100,
                  height:100,
                  backgroundColor:"#EDEFF0",
                  borderStyle:'dashed'}}
                >
                  <CameraAltIcon style={{marginTop:35}} />
                </div>
              }
              </div>
            }

            <div style={{marginTop:15}}>
              <input id="teamInput" type="file" ref={(ref)=>this.teamInput=ref} onChange={this.handleFileChange} style={{display:'none'}} />
              <Button
                variant="contained"
                className={classes.textButton}
                size="small"
                onClick={(e=> !!currentTeam.isFree ? null : this.teamInput.click())}
              >
                Upload Image
              </Button>
            </div>

          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={10}>
            <Grid container
              spacing={16}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              {(appContext.isAdmin || userContext.isImpersonating) &&
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p style={{ margin: 0, fontSize: 20 }}>Paid Team</p>
                    <Checkbox
                      checked={!team.isFree}
                      onChange={this.handleChangeTeam('isFree')}
                    />
                </Grid>
              }
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                    id="name"
                    label="Name"
                    style={{width:'100%'}}
                    value={team.name}
                    disabled={!!currentTeam.isFree}
                    onChange={this.handleChangeTeam("name")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {/* <TextField
                    id="sport"
                    label="Type"
                    style={{width:'100%'}}
                    value={team.sport}
                    onChange={this.handleChangeTeam("sport")}
                /> */}
                <FormControl style={{width:'100%'}}>
                    <InputLabel htmlFor="sport">Sport</InputLabel>
                    <Select
                        disabled={!!currentTeam.isFree}
                        value={team.sport ? team.sport : ''}
                        onChange={this.handleChangeTeam("sport")}
                        input={<Input id="sport" />}
                    >
                        {sports.map(sport => (
                        <MenuItem
                            key={sport}
                            value={sport}
                        >
                            {sport}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                    id="customName"
                    label="Custom Name"
                    style={{width:'100%'}}
                    helperText={'optional'}
                    disabled={!!currentTeam.isFree}
                    value={team.customName ? team.customName : ""}
                    onChange={this.handleChangeTeam("customName")}
                />
              </Grid>
              {tenant !== 'coachup' && <Grid item xs={12} sm={12} md={6}>
                  <TextField
                      id="coachCode"
                      label="Coach Code"
                      style={{width:'100%'}}
                      value={team.coachCode}
                      disabled={!!currentTeam.isFree}
                      onChange={this.handleChangeTeam("coachCode")}
                  />
              </Grid>}
              {tenant !== 'coachup' && <Grid item xs={12} sm={12} md={6} style={{marginBottom:15}}>
                  <TextField
                      id="playerCode"
                      label="Athlete Code"
                      style={{width:'100%'}}
                      value={team.playerCode}
                      disabled={!!currentTeam.isFree}
                      onChange={this.handleChangeTeam("playerCode")}
                  />
              </Grid>}
              {tenant !== tenantMap.se && tenant !== 'coachup' && tenant !== 'kickid' && <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={!!currentTeam.isFree}
                    checked={!!team.isParentInfoHidden}
                    onChange={this.handleChangeTeam("isParentInfoHidden")}
                  />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      color: 'gray'
                      }}
                    >
                      Hide Parent Information on Signup
                  </p>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderStaffPermissions(){
    const { classes, tenant } = this.props;
    const { team } = this.state;

    return(
      <div style={{textAlign:"left"}}>
        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8}>
            <h4 className={'subHeading'} style={{textAlign:'left'}}>Staff Permissions</h4>
          </Grid>
          <Grid item xs={4} style={{textAlign:'right'}}>
            <Button variant="contained" className={classes.secondaryTextButton} onClick={() => this.handleSaveTeam()}>Save</Button>
          </Grid>
          <Grid item xs={12} className={"inputSectionTitle"}>
              <p style={{textAlign:'left'}}>{`This section allows you to set limitations for the staff members you create in this team's account. Create new staff members by editing coaches in your roster.`}</p>
          </Grid>
        </Grid>
        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <FormControlLabel
              label="Invite Users"
              control={
                  <Checkbox
                    color="primary"
                    onChange={this.changeStaffPermissions('canInviteUsers')}
                    checked={!team.staffPermissions || team.staffPermissions['canInviteUsers'] === undefined || (team.staffPermissions && team.staffPermissions['canInviteUsers'])}
                  />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Edit Users"
              control={
                  <Checkbox
                    color="primary"
                    onChange={this.changeStaffPermissions('canEditUsers')}
                    checked={!team.staffPermissions || team.staffPermissions['canEditUsers'] === undefined || (team.staffPermissions && team.staffPermissions['canEditUsers'])}
                  />
              }
            />
          </Grid>
         {tenant !== tenantMap.se && <Grid item xs={12}>
            <FormControlLabel
              label="Send Messages"
              control={
                  <Checkbox
                    color="primary"
                    onChange={this.changeStaffPermissions('canSendMessages')}
                    checked={!team.staffPermissions || team.staffPermissions['canSendMessages'] === undefined || (team.staffPermissions && team.staffPermissions['canSendMessages'])}
                  />
              }
            />
          </Grid>}
          <Grid item xs={12}>
            <FormControlLabel
              label="Edit Schedules"
              control={
                  <Checkbox
                    color="primary"
                    onChange={this.changeStaffPermissions('canEditSchedules')}
                    checked={!team.staffPermissions || team.staffPermissions['canEditSchedules'] === undefined || (team.staffPermissions && team.staffPermissions['canEditSchedules'])}
                  />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Edit Events"
              control={
                  <Checkbox
                    color="primary"
                    onChange={this.changeStaffPermissions('canEditEvents')}
                    checked={!team.staffPermissions || team.staffPermissions['canEditEvents'] === undefined || (team.staffPermissions && team.staffPermissions['canEditEvents'])}
                  />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Edit Activities & Workouts"
              control={
                  <Checkbox
                    color="primary"
                    onChange={this.changeStaffPermissions('canEditActivities')}
                    checked={!team.staffPermissions || team.staffPermissions['canEditActivities'] === undefined || (team.staffPermissions && team.staffPermissions['canEditActivities'])}
                  />
              }
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

Settings.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext }, teams: { byId, currentTeamId } } = state;
    return {
      currentTeam: byId[currentTeamId] || {},
      appContextList: userContext.appContextList,
      userContext: userContext,
      appContext:appContext
    };
}


const mapDispatchToProps = {
  setUserContext,
  setAppContext,
  refreshUserContext
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Settings));
