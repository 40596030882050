import React, { Component } from "react";

import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import classNames from "classnames";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { API, Auth } from "aws-amplify";

import { setUserContext, setAppContext } from "../../actions/session";
import { setCurrentTeamById } from "../../actions/team-actions";
import { setAuthenticatedUserById } from "../../actions/user-actions";
import { analyticsService } from "../../services/analyticsService";
import _ from "lodash";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.quickAccessRef = React.createRef();
  }

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    return {};
  }

  async componentDidMount() {
    const { userContext, classes, appContext } = this.props;
    const { user, roles, appContextList } = userContext;
    var avatarUrl =
      userContext.user && userContext.user.avatarUrl
        ? userContext.user.avatarUrl
        : null;
    console.log("this.props", this.props);
    console.log("userContext ", userContext);
    console.log("appContext ", appContext);
    console.log("avatarUrl ", avatarUrl);
    if (
      userContext.user &&
      userContext.user.avatarUrl &&
      !userContext.user.avatarUrl.includes("https://")
    ) {
      var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${
        userContext.user.legacyId
          ? userContext.user.legacyId
          : userContext.user.id
      }/${userContext.user.avatarUrl}`;
      avatarUrl = logo;
    }
    let isIdentityTracked = false;
    if (window.analytics) {
      let traits = {};
      if (typeof window.analytics.user == "function") {
        let user = window.analytics.user();
        if (!!user) {
          isIdentityTracked = true;
        }
      }
    }
    console.log(isIdentityTracked);
    console.log(analyticsService.identified);
    const currentTeam =
      _.find(
        appContextList,
        (c) => appContext && c && c.id === appContext.id
      ) || {};

    if (!analyticsService.identified && user && roles && roles[0]) {
      let userTraits = await analyticsService.getUserTraits(
        user,
        roles,
        currentTeam,
        appContext,
        userContext
      );
      analyticsService.initWithUser(userTraits);
    }

    //   var trackingParam = {
    //     id: user.id,
    //     created: user.createdAt,
    //     first_name: user.nameFirst,
    //     last_name: user.nameLast,
    //     email: user.email,
    //     username: user.username,
    //     role: roles[roles.length - 1].role,
    //     program: currentTeam.customName
    //   };
    //   if (!!currentTeam.organization) {
    //     trackingParam.org_name = currentTeam.organization.name,
    //       trackingParam.mascot = currentTeam.organization.mascot,
    //       trackingParam.team_colors_primary = currentTeam.organization.primaryColor,
    //       trackingParam.team_colors_decondary = currentTeam.organization.secondaryColor,
    //       trackingParam.team_colors_tertiary = currentTeam.organization.tertiaryColor,
    //       trackingParam.logo_url = currentTeam.organization.logoUrl
    //   }
    //   analyticsService.initWithUser(trackingParam);
    // }
    this.setState({ avatarUrl });
  }

  async handleUnimpersonate() {
    let { tenant } = this.props;
    this.setState({
      isLoading: true,
      loadingMessage: `Stopping Impersonating.... `,
    });
    try {
      var context = await Auth.currentSession().then((auth) => {
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
          body: {
            tenant: tenant,
            platform: "web",
          },
        };
        // console.log('auth.idToken.jwtToken', auth.idToken.jwtToken)
        return API.post("auth", `/auth/userContext`, options);
      });
      console.log("context ", context);
      this.props.setUserContext(context.userContext);
      this.props.setAppContext(context.appContext);
      this.props.setCurrentTeamById(context.appContext.id);
      this.props.setAuthenticatedUserById(context.userContext.user.id);
      this.setState({ isLoading: false });
      // this.props.history.push('/admin/users')
    } catch (e) {
      console.log("Error ==>", e);
      this.setState({ isLoading: false });
    }
    this.props.history.push("/admin/users");
    this.props.history.go(-1);
  }

  toggleQuickAccessMenu = (overrideStatus) => {
    let { quickAccessEnabled } = this.state;
    this.setState({
      quickAccessEnabled:
        overrideStatus != null ? overrideStatus : !quickAccessEnabled,
    });
  };

  render() {
    const {
      userContext,
      canEditProfile = true,
      handleLogout,
      history,
    } = this.props;
    const { avatarUrl, quickAccessEnabled } = this.state;
    // console.log('userContext', userContext);
    if (!userContext.user) {
      userContext.user = {};
    }
    return (
      !!userContext.user && (
        <div
          tabIndex={0}
          className="quickAccessContainer"
          ref={this.quickAccessRef}
          onBlur={() => this.toggleQuickAccessMenu(false)}
        >
          <div
            onClick={this.toggleQuickAccessMenu}
            href="/editUser"
            title="Your Profile"
            className="userShortInfo"
          >
            <span className="userName" style={{ margin: "10px" }}>
              {userContext.user.nameFirst} <br /> {userContext.user.nameLast}
            </span>
            {userContext.isImpersonating && avatarUrl && (
              <Tooltip title="Unimpersonate">
                <Avatar
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to stop impersonating this user?"
                      )
                    )
                      this.handleUnimpersonate();
                  }}
                  alt={userContext.user.nameFirst}
                  src={avatarUrl}
                  className="userImage"
                  style={{ borderColor: "red", borderStyle: "solid" }}
                />
              </Tooltip>
            )}
            {avatarUrl && !userContext.isImpersonating && (
              <Avatar
                alt={userContext.user.nameFirst}
                src={avatarUrl}
                className="userImage"
              />
            )}
            {!avatarUrl && !userContext.isImpersonating && (
              <Avatar className="userImage">
                <PersonOutlineIcon />
              </Avatar>
            )}
            {!avatarUrl && userContext.isImpersonating && (
              <Tooltip title="Unimpersonate">
                <Avatar
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to stop impersonating this user?"
                      )
                    )
                      this.handleUnimpersonate();
                  }}
                  style={{ borderColor: "red", borderStyle: "solid" }}
                  className="userImage"
                >
                  <PersonOutlineIcon />
                </Avatar>
              </Tooltip>
            )}
          </div>
          <div
            className={classNames("quickAccessMenu", {
              active: quickAccessEnabled,
              miniMenu: canEditProfile === false,
            })}
          >
            <div className="toolTip"></div>
            {canEditProfile && (
              <div>
                <div
                  onClick={() => history.push("/profile")}
                  href="/editUser"
                  className="menuItem"
                >
                  Profile Settings
                </div>
                <hr />
              </div>
            )}
            <div onClick={handleLogout} className="menuItem">
              Logout
            </div>
          </div>
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => {
  const {
    session: { userContext, appContext },
  } = state;
  return {
    userContext,
    appContext,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext)),
  setCurrentTeamById: (id) => dispatch(setCurrentTeamById(id)),
  setAuthenticatedUserById: (id) => dispatch(setAuthenticatedUserById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
