import React from "react";
import { Route, Redirect } from "react-router-dom";
import { SE_SESSION_KEY } from "../constants";
import { ROUTE_PATH } from "../routeUtil";

export default ({ component: C, props: cProps, ...rest }) => {
    let access_token = sessionStorage.getItem(SE_SESSION_KEY);
    let isAlreadyLoggedIn = cProps.isAuthenticated;
    if(isAlreadyLoggedIn){
        //Redirect to home page      
        if(!!cProps && !!cProps.history){
            cProps.history.replace(ROUTE_PATH.HOME);
        }
        else{
            window.location = ROUTE_PATH.HOME;
        }
    }
    return (<Route
        {...rest}
        render={props =>
            access_token
                ? <C {...props} {...cProps} />
                : <Redirect
                    to={ROUTE_PATH.SE_ADMIN_LOGIN}
                />}
    />);
}
