import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import AOCLongLogo from '../assets/images/themes/aoc/aoc-long-logo.png';
import AOCLogo from '../assets/images/themes/aoc/aoc-logo.png';
import AOCShortLogo from '../assets/images/themes/aoc/aoc-short-logo.png';
import homeImage from '../assets/images/themes/aoc/aoc-welcome.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id:"aoc",
    name: 'AOC VB Team',
    appUrl: 'm1-aoc://',
    domain: 'aoc.gomaxone.com',
    link: 'https://apps.apple.com/us/app/aoc-vb-team/id1507185594', // replace with real link
    onAppleAppStore: 'https://apps.apple.com/us/app/aoc-vb-team/id1507185594', // replace with real link
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.aoc' // replace with real link
  },
  image: {
    home: homeImage,
    logo: AOCShortLogo,
    loginLogo: AOCLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#00a7e1"
    },
    secondary: {
      main: "#00a7e1"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  }

});
