import { isMobile, isTablet } from "react-device-detect";
import _ from "lodash";

import ContextService from "../services/context";
import { analyticsService } from "../services/analyticsService";
import { getThemeByTenant } from "../themes";
import { logLastLogIn } from "./log-utils";

// export const authorizeSEUser = async (tenant, onAuthenticationSuccess,onAuthenticationError)=> {
//     try {
//         //Save original request url
//         if(window.sessionStorage && window.location){
//             window.sessionStorage.setItem("requestUrl", window.location.href);
//         }
//         const sessionData = await oAuthService.authorizeWithTenant(tenant, platform) || { error: true };
//         if (sessionData) {
//             onAuthenticationSuccess(sessionData);
//         } else {
//             console.log('No access token returned from tenant authorization');
//             onAuthenticationError({ handled: true, message: 'User cancelled authorization' });
//         }
//     } catch (e) {
//         console.log(e);
//         onAuthenticationError({ handled: false, message: 'An error occurred with authorization' });
//     }
// }

export const setSessionInfo = async (username, props) => {
  const trimmedUsername = username.trim();
  const contextService = new ContextService();
  const { userContext, appContext } = await contextService.buildUserContext(
    trimmedUsername
  );

  props.setUserContext(userContext);
  props.setAppContext(appContext);
  props.setAuthenticatedUserById(userContext.user.id);
  props.setCurrentTeamById(appContext.id);

  if (appContext.isAthlete || isMobile || isTablet) {
    const theme = getThemeByTenant();
    const appUrl = theme.product.appUrl ? theme.product.appUrl : "m1://";
    const productName = theme.product.name ? theme.product.name : "MaxOne";
    if (window.confirm(`Open the ${productName} app`)) {
      window.location = appUrl;
      return null;
    }
  }
  const { user, roles, appContextList } = userContext;
  logLastLogIn(user, props.tenant);
  const currentTeam =
    _.find(appContextList, (c) => appContext && c && c.id === appContext.id) ||
    {};
  if (user && roles && roles[0]) {
    let userTraits = await analyticsService.getUserTraits(
      user,
      roles,
      currentTeam,
      appContext,
      userContext
    );
    analyticsService.initWithUser(userTraits);
    analyticsService.trackEvent("Signed In");
  }

  return appContext;
};
