import React, { Component, Linking } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import { API, Auth, Storage } from "aws-amplify";
import _ from "lodash";
import SwipeableViews from 'react-swipeable-views';
import fileDownload from 'js-file-download';
import QRCode from 'qrcode.react';
import { withStyles } from '@material-ui/core/styles';
import { 
    Button, 
    Menu, 
    MenuItem, 
    CircularProgress,
    Grid,
    TextField
 } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FilterList } from '@material-ui/icons';
import { getThemeByTenant } from '../../themes';
import "../roster/roster.css";

import ContextService from '../../services/context';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
const contextService = new ContextService()

const styles = (theme) => ({
    selectedRosterCard: {
        backgroundColor: "#27AE60"
    },
    modalInviteButton: {
        width: 100,
        height: 40,
        marginRight: 10,
        backgroundColor: "#27AE60",
        color: theme.product.name == 'MaxOne' ? 'black' : 'white'
    },
    addUserButton: {
        height: 40,
        color: 'white',
        backgroundColor: "#1354F9"
    },
    plusButton: {
        backgroundColor: "#1354F9",
        color: 'white',
        outlineColor: 'transparent',
        outlineStyle: 'none'
    },
    textButton: {
        color: 'white',
        height: '40px',
        marginRight: '3px',
        backgroundColor: "#1354F9",
    },
    pageTitle: {
        textAlign: 'left',
        marginLeft: 50
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: `100%`
    },
    roster:{
        height:"700px !important"
    }
})



class TrueLacrosseAddAthleteModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            rosterFilterBy: "nameLast",
            renderedAthletes: [],
            searchText: "",
            anchorEl: "",
            openPopup: false,
            selectedFilter: null,
            filters: {
                graduationYear: [],
                state: []
            }
        };
    }

    getInviteMethod = () => {
        return this.props.method === 0 ? "Email" : "Text"
    }

    handleRemoveClick = contact => {
        this.props.remove(contact);
    }

    handleEditClick = contact => {
        this.props.edit(contact);
    }

    handleFilter = filterValue => event => {
        this.setState({
            rosterFilterBy: filterValue
        })
    }

    componentDidMount(){
      this.setState({renderedAthletes: this.props.trueLacrosseAthletes})
    }
    componentWillReceiveProps(props){
      this.setState({renderedAthletes: props.trueLacrosseAthletes})
    }

    searchAthletes = event => {
        const { filters } = this.state;
        const { trueLacrosseAthletes } = this.props
        let renderedAthletes = trueLacrosseAthletes.filter(item => {
            if(filters['state'].length && filters['graduationYear'].length){
                return filters['graduationYear'].indexOf(item.graduationYear) > -1 && filters['state'].indexOf(item['state/Province'] || item.state) > -1
            } else if(!filters['state'].length && !filters['graduationYear'].length){
                return true
            } else if (filters['graduationYear'].length) {
                return filters['graduationYear'].indexOf(item.graduationYear) > -1
            } else if (filters['state'].length) {
                return filters['state'].indexOf(item['state/Province'] || item.state) > -1
            }
        })

        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedAthletes: _.filter(renderedAthletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()) || (a.graduationYear && a.graduationYear == event.target.value))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedAthletes: renderedAthletes
            })
        }
    }

    handleCsvRosterDownload() {
        fileDownload(['First Name', 'Last Name', 'Phone Number', 'Email'], "MaxOne_Roster.csv");
    }

    getName = () => {
        if (window.location.href.toLowerCase().includes('onesoftball')) {
            return 'One Softball';
        }
        else if (window.location.href.toLowerCase().includes('pgc')) {
            return 'PGC';
        }

        else if (window.location.href.toLowerCase().includes('vnn')) {
            return 'VNN';
        }

        else if (window.location.href.toLowerCase().includes('m1vnn')) {
            return 'M1VNN';
        }

        else if (window.location.href.toLowerCase().includes('wcs')) {
            return 'WCS';
        }

        else if (window.location.href.toLowerCase().includes('spellman')) {
            return 'Spellman';
        }
        else if (window.location.href.toLowerCase().includes('truelacrosse')) {
            return 'True Lacrosse';
        }

        else {
            return 'MaxOne'
        }

    }

    setFilter = (key, value) => {
        let { filters, searchText } = this.state
        const { trueLacrosseAthletes } = this.props
        if (filters[key].indexOf(value) > -1) {
            let index = filters[key].indexOf(value)
            filters[key].splice(index, 1)
        } else {
            filters[key].push(value)
        }
        let renderedAthletes = trueLacrosseAthletes.filter(item => {
            if(filters['state'].length && filters['graduationYear'].length){
                if (filters['state'].indexOf('No State') > -1) {
                    return filters['graduationYear'].indexOf(item.graduationYear) > -1 && ((!item['state/Province'] && !item.state) || filters['state'].indexOf(item['state/Province'] || item.state) > -1)
                } else {
                    return filters['graduationYear'].indexOf(item.graduationYear) > -1 && filters['state'].indexOf(item['state/Province'] || item.state) > -1
                }
            } else if(!filters['state'].length && !filters['graduationYear'].length){
                return true
            } else if (filters['graduationYear'].length) {
                return filters['graduationYear'].indexOf(item.graduationYear) > -1
            } else if (filters['state'].length) {
                if (filters['state'].indexOf('No State') > -1) {
                    return ((!item['state/Province'] && !item.state) || filters['state'].indexOf(item['state/Province'] || item.state) > -1)
                } else {
                    return filters['state'].indexOf(item['state/Province'] || item.state) > -1
                }
            }
        })
        if(searchText){
            this.setState({
                filters,
                renderedAthletes: _.filter(renderedAthletes, a => a.nameFirst.toLowerCase().includes(searchText.toLowerCase()) || a.nameLast.toLowerCase().includes(searchText.toLowerCase()) || (a.graduationYear && a.graduationYear == searchText))
            })
        }
        else {
            this.setState({
                filters,
                renderedAthletes: renderedAthletes
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({
        [name]: value,
        });
    };

    render() {
        const { classes, trueLacrosseAthletes, contacts, contact, errorMessage, nameFirst, nameLast, phoneNumber, email, value, csvType, close, currentTeam = {}, tenant, invalidPhoneNumber, message} = this.props;
        const { rosterFilterBy, renderedAthletes, searchText, anchorEl, openPopup, selectedFilter, filters } = this.state
        const appName = this.getName()
        const theme = getThemeByTenant(tenant);
        let filter = [
            { key: 'Graduation Year', value: "graduationYear", children:[...new Set(trueLacrosseAthletes.filter(item => item.graduationYear).map(item => item.graduationYear).sort((a, b) => b - a))] },
            { key: 'State', value: "state", children:["No State", ...new Set(trueLacrosseAthletes.filter(item => item['state/Province'] || item.state).map(item => item['state/Province'] || item.state).sort())] }
        ]
        return(
            <LegacyModalContainer open={this.props.open} onClose={close} containerClassName={`rosterPaper ${classes.roster}`}>
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>Add Athletes</h2>
                </div>
                <div className={"outerModalContainer"} style={{ marginTop: 40 }}>

                    <Grid container direction="column" justify="center" alignItems="center" style={{border: '1px solid primary'}}>
                        <Grid item style={{width:'100%'}}>
                            <Tabs
                                value={value}
                                textColor="primary"
                                indicatorColor="primary"
                                className={"tabContainer"}
                                onChange={this.props.handleTabValueChange}
                            >
                                {/* <Tab label="Manual" value={0} /> */}                                    
                                <Tab label="True Lacrosse Users" value={0} />
                                <Tab label="Invite Code" value={1} />
                            </Tabs>
                        </Grid>
                        {
                            value ?
                                <Grid item style={{ width: '100%' }}>
                                    <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, marginTop: 10, color: 'black' }}>
                                        Please remind your athletes to download the {appName} app onto their Android or Apple phones
                                    and then enroll in your team using one of the two methods below.
                                </p>
                                </Grid>
                                :
                                <Grid item xs={12} style={{ alignSelf: 'flex-start' }}>
                                    <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, marginTop: 10, color: 'black' }}>
                                        Please select the athletes you want to add to your roster. Note - in order to scroll while filtering, you will need to click out of the filtering pop up.
                                </p>
                                </Grid>
                        }

                        <Grid item style={{width:'100%'}}>
                            <SwipeableViews index={value}>
                            <div className={"upload"}>
                                <Grid container direction="column" justify="center" alignItems="center" style={{border: '1px solid primary'}}>
                                        <Grid item style={{width:'100%'}}>
                                            {contacts.length ? <div className={"contactsChipContainer"}>
                                            {
                                                contacts.length === 0
                                                ?
                                                <div style={{marginLeft:10, color: 'darkgray'}}>
                                                </div>
                                                :
                                                <div>{contacts.map((contact, i) => (
                                                    <Chip
                                                        label={`${contact.nameFirst} ${contact.nameLast}`}
                                                        onDelete={() => this.handleRemoveClick(contact)}
                                                        className={"chip"}
                                                        clickable={true}
                                                        onClick={() => this.handleEditClick(contact)}
                                                        key={`${contact.nameFirst}-${contact.nameLast}-${i}`}/>
                                                ))}</div>
                                                }
                                            </div>
                                            : null}
                                        </Grid>
                                </Grid>
                                <Grid container alignItems="space-around" style={{ height: '50px !important' }}>
                                        <Grid item xs={9} style={{ marginTop: 10 }}>
                                            <TextField
                                                id="search"
                                                value={searchText ? searchText : ''}
                                                className={"searchTextField"}
                                                onChange={this.searchAthletes}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CloseIcon onClick={() => this.setState({ searchText: '', renderedAthletes: trueLacrosseAthletes })} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => { this.handleChange('openPopup', true); this.handleChange('anchorEl', event.currentTarget) }}
                                            >
                                                <FilterList />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={openPopup}
                                                onClose={() => this.handleChange('openPopup', false)}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 48 * 4.5,
                                                        width: 200,
                                                    },
                                                }}
                                            >
                                                {filter.map((option, index) => (
                                                    <MenuItem key={index} onClick={(event) => this.handleChange('selectedFilter', { ...option, anchorEl: event.currentTarget })}>
                                                        {option.key}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                            {selectedFilter && <Menu
                                                id="long-menu"
                                                anchorEl={selectedFilter.anchorEl}
                                                keepMounted
                                                open={true}
                                                onClose={() => this.handleChange('selectedFilter', null)}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 48 * 4.5,
                                                        width: 200,
                                                    },
                                                }}
                                            >
                                                {selectedFilter.children.map((option, index) => (
                                                    <MenuItem key={index}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox color="primary" checked={filters[selectedFilter.value].indexOf(option) > -1 ? true : false} onChange={() => this.setFilter(selectedFilter.value, option)} value={option} />
                                                            }
                                                            label={option}
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Menu>}
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C', maxHeight:50}}>
                                    <Grid container className={"content"}>
                                        <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                    <strong>Last Name</strong>
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"} style={{ color: 'white' }}>
                                                    <strong>First Name</strong>
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('graduationYear')}>
                                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"} style={{ color: 'white' }}>
                                                    <strong>Grad Year</strong>
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('State')}>
                                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"} style={{ color: 'white' }}>
                                                    <strong>State</strong>
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                    <Grid className={"wcsUserContainer"} container style={{ overflow: "scroll", maxHeight: contacts && contacts.length ? 350 : 400, minHeight: 110, width: "100%", border: '1px gray solid' }}>
                                {
                                    _.orderBy(renderedAthletes, [rosterFilterBy, 'nameLast']).map((athlete, index) => {
                                    return(<Grid item xs={12} key={index} className={"atheleteWCSGridItem"}>
                                            <Grid container className={"content"} onClick={() =>this.props.push(athlete)}>
                                            <Grid item xs={3} className={"rosterColumnContainer"}>
                                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                    <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15}}>
                                                        {athlete && athlete.nameLast ? athlete.nameLast : ""}
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} className={"rosterColumnContainer"}>

                                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"}>
                                                    {athlete && athlete.nameFirst ? athlete.nameFirst : ""}
                                                </p>
                                            </div>
                                            </Grid>
                                            <Grid item xs={3} className={"rosterColumnContainer"}>

                                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"}>
                                                    {athlete && athlete.graduationYear ? athlete.graduationYear : ""}
                                                </p>
                                            </div>
                                            </Grid>
                                            <Grid item xs={3} className={"rosterColumnContainer"}>

                                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                                <p className={"textContainer"}>
                                                    {athlete && (athlete['state/Province'] || athlete['state'])? (athlete['state/Province'] || athlete['state']) : ""}
                                                </p>
                                            </div>
                                            </Grid>
                                            </Grid>
                                        </Grid>)
                                    })
                                }
                                {
                                    !searchText && !renderedAthletes.length &&
                                    <div className={"rosterLoadingOverlay"} style={{width:"100%", alignItems:"center", justifyContent:"center"}}>
                                        <CircularProgress style={{marginTop:5}} />
                                        <h4 style={{textAlign:"center"}}>{"Loading..."}</h4>
                                    </div>
                                }
                                {
                                    (searchText || (filters.graduationYear.length > 0 || filters.state.length > 0)) && !renderedAthletes.length &&
                                    <div className={"rosterLoadingOverlay"} style={{width:"100%", alignItems:"center", justifyContent:"center"}}>
                                        <h4 style={{textAlign:"center"}}>{"No Results"}</h4>
                                    </div>
                                }
                                </Grid>
                                </div>
                                <div>
                                    <div className={"codeContainer"} >
                                        <div className={"codeOptionContainer"}>
                                            <h3>Scan</h3>
                                            <p>Have athletes scan from your screen</p>
                                        </div>
                                        <div className={"codeOptionContainer"}>
                                            <h3>Type</h3>
                                            <p>Send the following code to your athletes</p>
                                        </div>
                                    </div>
                                    <div className={"codeContainer"} >
                                        <div className={"codeOptionContainer"}>
                                            <QRCode
                                                value={currentTeam.playerCode}
                                                size={180}
                                            />
                                        </div>
                                        <div className={"codeOptionContainer"}>
                                            <h2>{currentTeam.playerCode}</h2>
                                        </div>
                                    </div>
                                </div>                                  
                            </SwipeableViews>
                        </Grid>
                    </Grid>
                    <div className={"modalFooterContainer"}>
                        <div>
                            <Button
                                type="submit"
                                className={"cancelButton"}
                                onClick={this.props.cancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.modalInviteButton}
                                onClick={this.props.send}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </LegacyModalContainer>
        )
    }
}

TrueLacrosseAddAthleteModal.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
    };
  }

export default withStyles(styles)(connect(mapStateToProps)(TrueLacrosseAddAthleteModal));
