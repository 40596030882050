import slugify from 'slugify';
import React from 'react'

export const formatSingularPlural = (value = 0, singular = 'item', plural = 'items') => {
    return `${value} ${value === 1 ? singular : plural}`;
};

export const formatValueAsSlug = (value = '') => slugify(value, { lower: true, strict: true });

String.prototype.pluralize = function (plural, count) {
    if(!count || count == 1){
        return this;
    }
    else{
        return plural;
    } 
}

String.prototype.highlightTerm = function (highlight, className="") {
    const parts = this.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span className={className}>{part}</span> : part)}</span>;
}

String.prototype.captialize = function () {
    return  (this||"").toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}