import React, { Component } from "react";
import { connect } from "react-redux";
import EventDetailComponent from "../components/EventDetail";
import {
  sendRSVP,
  getEventById,
  getEventsByTeamIdsAndUserId,
  getRecurringEventInstanceByInstanceId,
} from "../../../actions/nylas-calendar-actions";
import { downloadFileFromUrl, isJson } from "../../../utils/commonUtil";
import { enableFullScreenLoader } from "../../../actions/loading";

const viewOptions = {
  detail: "detail",
  cancel: "cancel",
  confirmationPopup: "confirmationPopup",
};

class EventDetail extends Component {
  constructor(props) {
    super(props);

    const { teamsById, currentTeamId } = props;
    const currentTeam = teamsById[currentTeamId];
    this.state = {
      collapsed: {},
      currentTeam: currentTeam,
      currentView: viewOptions.detail,
    };
  }

  componentDidMount = () => {
    const { currentTeam } = this.state;
    const { getRecurringEventInstanceByInstanceId, getEventById, event } =
      this.props;
    if (event.masterEventId) {
      getRecurringEventInstanceByInstanceId(
        currentTeam.organizationId,
        event.masterEventId,
        event.id
      );
    } else {
      getEventById(currentTeam.organizationId, event.id);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.eventById.loading !== this.props.eventById.loading) {
      this.props.enableFullScreenLoader(this.props.eventById.loading);
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleCollapse = (index, value) => {
    const { collapsed } = this.state;
    if (!collapsed[index]) {
      collapsed[index] = value;
    } else {
      collapsed[index] = "";
    }
    this.setState({ collapsed });
  };

  handleEdit = () => {
    const { history, event, enableFullScreenLoader } = this.props;
    enableFullScreenLoader(true, "Loading....");
    if (event.masterEventId) {
      history.push(`/edit-event/${event.masterEventId}/${event.id}`);
    } else {
      history.push(`/edit-event/${event.id}`);
    }
    enableFullScreenLoader(false);
  };

  handleCancel = () => {
    const { history, event, enableFullScreenLoader } = this.props;
    enableFullScreenLoader(true, "Loading....");
    if (event.masterEventId) {
      history.push(`/cancel-event/${event.masterEventId}/${event.id}`);
    } else {
      history.push(`/cancel-event/${event.id}`);
    }
    enableFullScreenLoader(false);
  };

  sendRSVP = async (status) => {
    const { event, handleClose, loggedInUserId } = this.props;
    await sendRSVP(event.id, loggedInUserId, status);
    this.loadData();
    setTimeout(() => handleClose(), 1000);
  };

  loadData = async () => {
    const { currentTeam } = this.state;
    const {
      startDate,
      selectedTeams,
      loggedInUserId,
      getEventsByTeamIdsAndUserId,
    } = this.props;
    getEventsByTeamIdsAndUserId({
      organizationId: currentTeam.organizationId,
      loggedInUserId,
      selectedTeams,
      limit: 100,
      startDate,
      offset: 0,
    });
  };

  handleFileDownload = (url, filename) => {
    const { enableFullScreenLoader } = this.props;
    enableFullScreenLoader(true, "Loading....");
    downloadFileFromUrl(url, filename);
    enableFullScreenLoader(false);
  };

  render() {
    const { collapsed } = this.state;
    const {
      event,
      visible,
      handleClose,
      currentRole,
      eventById,
      instanceById,
      navigateTo,
    } = this.props;
    return (
      <EventDetailComponent
        visible={visible}
        navigateTo={navigateTo}
        handleClose={handleClose}
        collapsed={collapsed}
        sendRSVP={this.sendRSVP}
        currentRole={currentRole || {}}
        handleEdit={this.handleEdit}
        handleChange={this.handleChange}
        handleFileDownload={this.handleFileDownload}
        handleCollapse={this.handleCollapse}
        handleCancel={this.handleCancel}
        event={
          event.masterEventId
            ? instanceById.data[event.id] || {}
            : eventById.data[event.id] || {}
        }
      />
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId },
  teams: { byId: teamsById, currentTeamId },
  nylasCalendar: {
    endDate,
    startDate,
    eventById,
    instanceById,
    selectedTeams,
  },
}) => ({
  endDate,
  startDate,
  eventById,
  teamsById,
  instanceById,
  currentTeamId,
  selectedTeams,
  loggedInUserId,
});

export default connect(mapStateToProps, {
  getEventById,
  getEventsByTeamIdsAndUserId,
  enableFullScreenLoader,
  getRecurringEventInstanceByInstanceId,
})(EventDetail);
