import React, { Component, Linking, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import { connect } from 'react-redux';
import classnames from 'classnames'
import ThemeButton from '../../components/themeButton'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BodyWrapper from "../../components/bodyWrapper";
import SearchInput from '../../components/searchInput';
import TabularDesign from '../../components/tabularDesign'
import { goalsClient } from '../../clients';
import AddGoalModal from './addGoalModal';
import moment from 'moment';
import { ROUTE_PATH } from "../../routeUtil";
import { formatMeasureValueForDisplaying } from '../../utils/activityUtils'

let styles = {
  athleteList: {
    "& tr:hover": {
      background: "#F8F8F8"
    }
  },
  td: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#0B0B0B",
    textAlign: "left"
  },
  tableHead: {
    height: "36px !important"
  },
  topSearchSection: {
    marginBottom: "20px"
  },
  quickFilterContainer: {
    height: "24px"
  },
  rowSelected: {
    background: "#F8F8F8 !important",
    "& .checkBoxContainer": {
      "&:before": {
        content: "''",
        width: "1px",
        height: "calc(100% + 1px)",
        borderLeft: "4px solid #1354F9",
        top: "0",
        left: "0",
        position: "absolute"
      }
    }
  },
  searchBox: {
    height: "44px",
    width: "300px"
  },
  serviceIcon: {
    "& svg": {
      cursor: "pointer",
      width: "20px",
      height: "20px",
      fill: "#DDE2E5"
    }
  },
  editUserContainer: {
    width: "100px",
    textAlign: "right !important",
    "& svg": {
      cursor: "pointer",
      width: "20px",
      height: "20px",
      fill: "#DDE2E5"
    },
    "& span:first-child": {
      marginRight: "12px"
    }
  },
  statusBox: {
    padding: "4px 8px",
    fontWeight: "bold",
    fontSize: "11px",
    lineHeight: "12px",
    color: "#FFFFFF",
    borderRadius: "20px"
  },
  disabled: {
    opacity: 0.3
  },
  outsideClickDiv: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "200vh",
    height: "100vh"
  },
  highlightColor: {
    color: "#1354F9"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  progressContainer: {
    padding: "4px 8px",
    borderRadius: "20px",
    color: "#ffffff"
  },
  additionalData: {
    color: "#0B0B0B",
    opacity: 0.4,
    fontSize: "14px",
    lineHeight: "14px"
  }
};

class AthleteGoals extends Component {
  constructor(props) {
    super(props);
    this.typingTimeout = null;
    this.fields = [
      {
        displayName: "Activity Name",
        value: "activityName"
      },
      {
        displayName: "Progress",
        value: "progress"
      },
      {
        displayName: "Starting Measure",
        value: "startingMeasure",
        additionalField: "formattedStartingMeasureDate"
      },
      {
        displayName: "Current Measure",
        value: "currentMeasure",
        additionalField: "formattedCurrentMeasureDate"
      },
      {
        displayName: "Current PR",
        value: "currentPr",
        additionalField: "formattedCurrentPrDate"
      },
      {
        displayName: "Goal",
        value: "value"
      }
    ];
    this.state = {
      athletesCount: 0,
      goals: [],
      currentPage: 0,
      totalPages: 1,
      goalPerPageLimit: 20,
      searchTerm: "",
      enableAddGoalModal: false,
      selectedGoal: null
    }
  }
  componentDidMount() {
    const { location: { state }, history } = this.props
    if (state && !!state.userId) {
      this.setState({ userId: state.userId }, this.fetchGoals);
    }
    else {
      history.replace(ROUTE_PATH.ATHLETES);
    }
  }

  deleteGoal = async (goalId) => {
    if (window.confirm(`Are you sure you wish to delete this goal`)) {
      try {
        this.setState({ isLoading: true, showLoadingChild: true, loadingMessage1: "Deleting Goal", loadingMessage2: "" });
        await goalsClient.deleteGoalById(goalId);
      }
      catch (e) {
        console.log("Exception")
        console.log(e);
      }
      await this.fetchGoals(0)
    }
  }

  fetchGoals = async (pageNumber) => {
    let { goalPerPageLimit, currentPage } = this.state;
    this.setState({ isLoading: true, showLoadingChild: pageNumber == undefined ? false : true, loadingMessage1: "Fetching Goals..." });
    let results = [];
    let totalPages = 0;
    let { currentTeam } = this.props;
    let { searchTerm, userId } = this.state;
    pageNumber = (pageNumber !== undefined ? pageNumber : currentPage)
    let queryParams = {
      "userId.keyword": userId,
      limit: goalPerPageLimit,
      offset: pageNumber * goalPerPageLimit,
    };
    if (searchTerm) {
      queryParams.activityName = searchTerm + "*";
    }
    try {
      let { data: { results: fetchedData, total } } = await goalsClient.searchGoalsByTeamId(currentTeam.id, queryParams);
      results = fetchedData;
      totalPages = Math.ceil(total / goalPerPageLimit);
      (results || []).forEach(item => {
        let scoreType = (item.scoreType || "").replace("_", "");
        let color = getColorFromValue(item.progress);
        item.progress = item.progress + "%";
        item.progressColor = color;
        item.value = formatMeasureValueForDisplaying(scoreType, item.value || "");
        if (item.startingMeasure) {
          item.startingMeasure = formatMeasureValueForDisplaying(scoreType, item.startingMeasure);
        }
        if (item.currentMeasure) {
          item.currentMeasure = formatMeasureValueForDisplaying(scoreType, item.currentMeasure);
        }
        if (item.currentPr) {
          item.currentPr = formatMeasureValueForDisplaying(scoreType, item.currentPr);
        }
        if (item.startingMeasureDate) {
          item.startingMeasureDate = moment(item.startingMeasureDate);
          item.formattedStartingMeasureDate = moment(item.startingMeasureDate).format("MM/DD/YY");
        }
        if (item.currentMeasureDate) {
          item.currentMeasureDate = moment(item.currentMeasureDate);
          item.formattedCurrentMeasureDate = moment(item.currentMeasureDate).format("MM/DD/YY");
        }
        if (item.currentPrDate) {
          item.currentPrDate = moment(item.currentPrDate);
          item.formattedCurrentPrDate = moment(item.currentPrDate).format("MM/DD/YY");
        }
      });
    }
    catch (e) {
      console.log(e)
    }
    this.setState({ isLoading: false, loadingMessage1: "", loadingMessage2: "", showLoadingChild: false, goals: results, currentPage: pageNumber, totalPages });
  }

  handleSearch = (searchTerm, timeOut = 0) => {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.setState({
      searchTerm
    }, () => {
      this.typingTimeout = setTimeout(() => {
        this.fetchGoals(0);
      }, (timeOut || 1000))
    });
  }

  editGoal = (goal) => {
    this.setState({ selectedGoal: goal, enableAddGoalModal: true })
  }

  toggleAddGoalModal = () => {
    let { enableAddGoalModal } = this.state;
    this.setState({ enableAddGoalModal: !enableAddGoalModal, selectedGoal: null })
  }

  saveGoal = async (selectedActivityId, goalValue, currentMeasure, currentMeasureDate, startingMeasure, existingGoalId, startingMeasureDate) => {
    let { currentUser, currentTeam, enableFullScreenLoader } = this.props;
    let { userId } = this.state;
    try {
      enableFullScreenLoader(true, "Saving...");
      let userData = [{ userId, value: Number(goalValue), id: existingGoalId, currentMeasure, startingMeasure, currentMeasureDate, startingMeasureDate }];
      await goalsClient.createUserActivityGoals(selectedActivityId, currentUser.id, currentTeam.id, userData);
    }
    catch (e) {
      console.log(e);
    }
    enableFullScreenLoader(false);
    this.toggleAddGoalModal();
    this.fetchGoals(0);
  }

  render() {
    let { isLoading,
      goals,
      loadingMessage1,
      loadingMessage2,
      showLoadingChild,
      searchTerm,
      enableAddGoalModal,
      selectedGoal,
      currentPage,
      totalPages,
      userId
    } = this.state;
    let { classes, currentTeam } = this.props;
    return (
      <PageWrapper setChildRef={this.setChildRef} title="User Goals" removePadding={true} {...this.props}>
        <div className={classes.pageContainer}>
          <BodyWrapper isLoading={isLoading} showLoadingChild={showLoadingChild} loadingMessage1={loadingMessage1} loadingMessage2={loadingMessage2}>
            <div className={classes.searchBarContainer}>
              <div className={classnames(classes.topSearchSection, "d-flex align-items-center justify-content-between")}>
                <SearchInput placeholder={"Search Activity"} customClass={classes.searchBox} searchTerm={searchTerm} handleSearch={this.handleSearch} />
                <ThemeButton
                  theme={"small"}
                  width={"142px"}
                  height={"44px"}
                  onClick={this.toggleAddGoalModal}
                  text="Add a Goal"
                  color={"#27AE60"}
                  textColor={"#ffffff"}
                  iconName={"Plus"}
                  position={"left"}
                />
              </div>
            </div>
            <TabularDesign
              currentPage={currentPage}
              totalPages={totalPages}
              changeCurrentPage={(page) => this.fetchGoals(page - 1)}
            >
              <React.Fragment>
                <thead>
                  <tr className={classes.tableHead}>
                    {this.fields.map((field) => { return <th>{field.displayName}</th> })}
                    <th>Edit Goal</th>
                  </tr>
                </thead>
                <tbody className={classes.athleteList}>
                  {goals.map((goal, index) => (<tr key={index} className={classes.rowSelected}>
                    {this.fields.map((field) => {
                      return (
                        <td className={classes.td}>
                          <span style={{ backgroundColor: field.value == "progress" ? goal.progressColor : null }}
                            className={field.value == "progress" ? classes.progressContainer : ""}>
                            {(goal[field.value] == 0 ? 0 : (goal[field.value] || "-"))}
                            {!!goal[field.additionalField] && <span className={classes.additionalData}>({goal[field.additionalField]})</span>}
                          </span>
                        </td>
                      )
                    })}
                    <td className={classnames(classes.td, classes.editUserContainer)}>
                      <span className={classes.serviceIcon} onClick={() => this.editGoal(goal)}><EditIcon /></span>
                      <span className={classes.serviceIcon} onClick={() => this.deleteGoal([goal.id])}><DeleteIcon /></span>
                    </td>
                  </tr>))
                  }
                </tbody>
              </React.Fragment>
            </TabularDesign>
            {enableAddGoalModal &&
              <AddGoalModal closePopup={this.toggleAddGoalModal} selectedGoal={selectedGoal} save={this.saveGoal} userId={userId} currentTeamId={currentTeam.id} />
            }
          </BodyWrapper>
        </div>
      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { session: { accessToken, userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  const teams = (userContext.appContextList || []).filter(item => item.isTeam);
  return {
    isDirector: !!appContext && (appContext.isOwner || appContext.isHeadcoach || appContext.isAdmin),
    accessToken: accessToken,
    currentUser: userContext.user,
    currentTeam: currentTeam || {},
    teams
  };
}

function getColorFromValue(value) {
  return value >= 100 ? "#27AE60" : value > 50 ? "#FFA800" : "#F4582A";
}

export default withStyles(styles)(connect(mapStateToProps)(AthleteGoals));