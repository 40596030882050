import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WorkoutProgramList } from 'maxone-workout-programs'
import PageWrapper from '../nav/pageWrapper';
import {analyticsService, currentTimestamp} from "../../services/analyticsService";
import { getLocalizedString } from '../../utils/locale-utils'

class WorkoutProgramsList extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false, loadingMessage: '' }
    }

    edit = workoutProgram => {
        this.props.history.push({ pathname: '/workoutPrograms/new', state: { workoutProgram: workoutProgram }})
    }

    async onProgramAssigned(assignment) {
        analyticsService.trackEvent('Assignment Assigned',
            {
                start_day: assignment.startDay,
                schedule_id: assignment.scheduleId,
                notification_reminder: assignment.notificationReminder,
                workout_entity: assignment.currentWorkoutProgram,
                assigner_id: assignment.assignerId
            })
        let userTraits = await analyticsService.getUserTraits(this.props.userContext.user, this.props.userContext.roles, this.props.currentTeam, this.props.appContext, this.props.userContext);
        analyticsService.initWithUser(userTraits);
    }

    render() {
        const { isLoading, loadingMessage } = this.state;
        const { classes, appContext, userContext, currentTeam, tenant, enableFullScreenLoader } = this.props;

        return (
            <PageWrapper title={getLocalizedString("BUILD_PROGRAM")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <div style={{ height: '100%' }}>
                    <WorkoutProgramList edit={workoutProgram => this.edit(workoutProgram)} teamId={currentTeam.id}
                                        domain={tenant} userID={userContext.user.id}
                                        onProgramAssigned={assignment => this.onProgramAssigned(assignment)}
                                        enableFullScreenLoader={enableFullScreenLoader}
                    />
                </div>
            </PageWrapper>
        );
    }
}

WorkoutProgramsList.propTypes = { }

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        userContext: userContext,
        currentTeam: currentTeam||{}
    };
}

export default (connect(mapStateToProps)(WorkoutProgramsList));
