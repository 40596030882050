import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import StateLoader from './services/stateLoader';
import { store } from './configure-store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import AWS from "aws-sdk";
import Amplify, { Auth } from 'aws-amplify'
// import config from "./config"; // If working on the Programax site, use this
import { getRegion, getEnvironment, getApiUrl, getConfigMap, getTenant } from "./utils/environmentUtils"
import coachupConfigOps from "./config-coachup"; // If working on the COACHUP , use this
import kickidConfigOps from "./config-kickid"; // If working on the COACHUP , use this
import kickidConfigStage from "./config-kickid-stg"; // If working on the COACHUP , use this
import coachupConfigStage from "./config-coachup-stg"; // If working on the COACHUP , use this

import vnnConfigOps from "./config-vnn";

// Persist redux state with localStorage
const stateLoader = new StateLoader();
console.log('window location', window.location)
const REGION = getRegion();
const AWS_REGION = getRegion();
const ENV = getEnvironment();
const TENANT = getTenant();

const coachupConfig = (ENV === 'stg') ? coachupConfigStage : coachupConfigOps;
const kickidConfig = (ENV === 'stg') ? kickidConfigStage : kickidConfigOps;

console.log('kickidConfig', kickidConfig)
console.log('ENV', ENV)
console.log('REGION', REGION)
console.log('TENANT', TENANT)
const configMap = getConfigMap(REGION)
const config = configMap[ENV]
const apiUrl = getApiUrl(ENV, REGION)

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: config.cognito.IDENTITY_POOL_ID
});
AWS.config.region = REGION;
AWS.config.bucket = 'maxone-leaderboards';

// your Cognito Hosted UI configuration
let oauth = {
  domain: "vnnauth.gomaxone.com",
  scope: [
    "openid"
  ],
  redirectSignIn: "https://vnn.gomaxone.com/",
  redirectSignOut: "https://vnn.gomaxone.com/login",
  responseType: "token",
  // options: {
  //   AdvancedSecurityDataCollectionFlag: true
  // },
};

if(TENANT === 'm1vnn'){
  oauth = {
    domain: "m1vnnauth.gomaxone.com",
    scope: [
      "openid"
    ],
    redirectSignIn: "https://m1vnn.gomaxone.com",
    redirectSignOut: "https://m1vnn.gomaxone.com/login",
    // redirectSignIn: "http://localhost:3000",
    // redirectSignOut: "http://localhost:3000",
    responseType: "token",
    // options: {
    //   AdvancedSecurityDataCollectionFlag: true
    // },
  };
}

if(TENANT === "wcs"){
  oauth = {
    domain: "maxone-wcs.auth.us-east-1.amazoncognito.com",
    scope: [
      "openid"
    ],
    redirectSignIn: "https://wcs.gomaxone.com",
    redirectSignOut: "https://wcs.gomaxone.com",
    // redirectSignIn: "localhost:3000",
    // redirectSignOut: "localhost:3000",
    responseType: "token",
    // options: {
    //   AdvancedSecurityDataCollectionFlag: true
    // },
  };
}

Amplify.configure({
  Auth: {
    oauth: oauth,
    mandatorySignIn: false,
    region: "us-east-1", // Must be us-east-1 to save to S3
    identityPoolId:  config.cognito.IDENTITY_POOL_ID,
    userPoolId: TENANT === 'coachup' ? coachupConfig.cognito.USER_POOL_ID : TENANT === 'vnn' ? vnnConfigOps.cognito.USER_POOL_ID : config.cognito.USER_POOL_ID,
    userPoolWebClientId: TENANT === 'coachup' ? coachupConfig.cognito.APP_CLIENT_ID : TENANT === 'vnn' ? vnnConfigOps.cognito.APP_CLIENT_ID : config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: "us-east-1", // Must be us-east-1 to save to S3
    bucket: `${ENV}-source-videos`,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: apiUrl,
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "chat",
        endpoint: apiUrl + "/chat",
        region: REGION
      },
      {
        name: "activities",
        endpoint: apiUrl + "/activities",
        region: REGION
      },
      {
        name: "admin",
        endpoint: apiUrl + "/admin",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "auth",
        endpoint: apiUrl + "/auth",
        region: REGION
      },
      {
        name: "billing",
        endpoint: apiUrl + "/billing",
        region: REGION
      },
      {
        name: "events",
        endpoint: apiUrl + "/events",
        region: REGION
      },
      {
        name: "feedItems",
        endpoint: apiUrl + "/feedItems",
        region: REGION
      },
      {
        name: "groups",
        endpoint: apiUrl + "/groups",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "leaderboards",
        endpoint: apiUrl + "/leaderboards",
        region: REGION
      },
      {
        name: "messages",
        endpoint: apiUrl + "/messages",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "scheduledMessages",
        endpoint: apiUrl + "/scheduledMessages",
        region: REGION
      },
      {
        name: "organizations",
        endpoint: apiUrl + "/organizations",
        region: REGION
      },
      {
        name: "products",
        endpoint: apiUrl + "/products",
        region: REGION
      },
      {
        name: "programs",
        endpoint: apiUrl + "/programs",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "schedules",
        endpoint: apiUrl + "/schedules",
        region: REGION
      },
      {
        name: "tenants",
        endpoint: apiUrl + "/tenants",
        region: REGION
      },
      {
        name: "users",
        endpoint: apiUrl + "/users",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "workoutPrograms",
        endpoint: apiUrl + "/workoutPrograms",
        region: REGION
      },
      {
        name: "workouts",
        endpoint: apiUrl + "/workouts",
        region: REGION
      },
      {
        name: "workoutSchedules",
        endpoint: apiUrl + "/workoutSchedules",
        region: REGION
      },
      {
        name: "tags",
        endpoint: apiUrl + "/tags",
        region: REGION
      },
      {
        name: "taggings",
        endpoint: apiUrl + "/taggings",
        region: REGION
      },
      {
        name: "wcs",
        endpoint: apiUrl + "/wcs",
        region: REGION
      },
      {
        name: "truelacrosse",
        endpoint: apiUrl + "/truelacrosse",
        region: REGION
      },
      {
        name: "subscriptions",
        endpoint: apiUrl + "/subscriptions",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "reports",
        endpoint: "https://ops-api.gomaxone.com" + "/subscriptions", // Always ops.  never stg
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "subscriptions-test",
        endpoint: apiUrl + "/subscriptions",
        region: REGION,
        custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "onboarding",
        endpoint: apiUrl + "/onboarding",
        region: REGION
      }
    ]
  }
});

Auth.configure({ oauth })

ReactDOM.render(
  <Router>
    <Provider store={store}>
        <App />
    </Provider>
  </Router>
    , document.getElementById('root')
  );
ENV === 'stg' ? unregister() : registerServiceWorker();
