import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import vnnIcon from '../assets/images/themes/vnn/icon.png';
import vnnLongLogo from '../assets/images/themes/vnn/vnn-logo.png';
import homeImage from '../assets/images/themes/wcs/wcs-welcome.jpg';
import vnnM1PowerLogo from '../assets/images/themes/vnn/vnn-logo-powered-by-m1.png';
import wcsLogo from '../assets/images/themes/wcs/wcs-logo.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'wcs', 
    name: 'WCS',
    appUrl: 'm1-wcs://',
    domain: 'wcs.gomaxone.com',
    link: 'https://apps.apple.com/us/app/wcs-team-app/id1471036449',
    onAppleAppStore: 'https://apps.apple.com/us/app/wcs-team-app/id1471036449',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.wcs'
  },
  image: {
    home: homeImage,
    logo: wcsLogo,
    loginLogo: wcsLogo,
    longLogo: wcsLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#DCCD8A"
    },
    secondary: {
      main: "#BF1E2D"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  }

});
