import { get } from 'http'
import moment from 'moment'

export const getAthleteDetailsHeader = () => {
    let header = []
    Array.from({ length: 7 }, (_, i) => moment().subtract(i, "days")).forEach((item) => {
        header.push({
            title: moment(item).format("MM/DD"),
            field: moment(item).format("YYYY-MM-DD"),
            cellStyle: { width: '3vw', overflowWrap: 'break-word', padding: "10px 10px" }
        })
    })

    return header
}

export const mainColumns = [
    { title: "Athlete Name", field: "athleteName", cellStyle: { width: '29vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: ` Adherence${'\u000A'}(Past week)`, field: "habitResult", cellStyle: { width: '20vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: "Controllables in use (Today)", field: "controllables", cellStyle: { width: '30vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: "HRV", field: "hrv", cellStyle: { width: '3vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: "Recovery", field: "recovery", cellStyle: { width: '5vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: 'Day Strain', field: "day", cellStyle: { width: '5vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: 'RHR', field: "restingHeartRate", cellStyle: { width: '3vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: 'Respiratory Rate', field: "respiratoryRate", cellStyle: { width: '5vw', overflowWrap: 'break-word', padding: "10px 10px" } }
]

export const athleteColumns = [
    { title: "Controllable", field: "controllable", cellStyle: { width: '10vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: "Habit", field: "habit", cellStyle: { width: '40vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: ` Adherence${'\u000A'}(Past week)`, field: "habitResults", cellStyle: { width: '10vw', overflowWrap: 'break-word', padding: "10px 10px", whiteSpace: "pre-wrap" } },
    ...getAthleteDetailsHeader()
]

export const popupColumns = [
    { title: "Controllable", field: "controllable", cellStyle: { width: '15vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: "Habit", field: "habit", cellStyle: { width: '30vw', overflowWrap: 'break-word', padding: "10px 10px" } },
    { title: ` Adherence${'\u000A'}(Past week)`, field: "habitResults", cellStyle: { width: '15vw', overflowWrap: 'break-word', padding: "10px 10px", whiteSpace: "pre-wrap" } },
    ...getAthleteDetailsHeader()
]