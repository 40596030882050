import React, { Component } from 'react'
import './styles.css'
import CheckIcon from '@material-ui/icons/Check'
import Checkbox from '@material-ui/core/Checkbox'
import { PrimaryButton, InputField, PhoneNumberInputField, PasswordInputField } from 'maxone-react-ui'
import classnames from 'classnames';
import { testId } from '../../../../../utils/test-utils';
import FloatingSelectWithCustomChild from '../../../../../components/floatingSelectWithCustomChild';
import { withStyles } from '@material-ui/core'

const termsUrl = ""
const privacyPolicyUrl = ""

const styles = {
    dropdownContainer:{
        background:"white",
        "& > div": {
            height: "56px"
        },
        "& > div label": {
            fontSize: "16px",
            marginTop: "4px !important",
            "& + div":{
                marginTop: "18px"
            }
        }
    }
}

class SignupTab extends Component {
    constructor(props) {
        super(props),
        this.menuItemData=[
            {displayValue:"0-30", value:"0-30"},
            {displayValue:"31-100", value:"31-100"},
            {displayValue:"101-200", value:"101-200"},
            {displayValue:"201-500", value:"201-500"},
            {displayValue:"501-800", value:"501-800"},
            {displayValue:"800+", value:"800+"},]
        this.state = {
            hasAcknowledged: false,
            acknowledgeError: false
        }
    }

    toggleAcknowledge = () => {
        const { hasAcknowledged } = this.state
        this.setState({ hasAcknowledged: !hasAcknowledged })
    }

    handleCreateAccount = () => {
        const { hasAcknowledged } = this.state
        const { handleCreateAccount } = this.props
        if (!hasAcknowledged)
            return this.setState({ acknowledgeError: true })
        else {
            this.setState({ acknowledgeError: false })
            handleCreateAccount()
        }
    }

    render() {
        const { acknowledgeError, hasAcknowledged } = this.state
        const {
            open, title, signupData: {
                nameLast,
                nameFirst,
                phoneNumber = "",
                username },
            existingUsername,
            onClick,
            handleChange,
            isPasswordValid,
            isContinueDisabled,
            isLoading,
            passwordError,
            phoneNumberErrorStateEnabled,
            classes,
            orgSize
        } = this.props
        return (
            open ?
                <div className={'currentTabContainer'}>
                    <div className={'row tabContentContainer'}>
                        <div className={'col-lg-4 columnContainer tabContentLeftContainer'}>
                            <p className={'stepNumberText'}>Step 1 of 2</p>
                            <p className={'stepTitleText'}>Create an Account</p>
                            <p className={'hideMediumDown stepDescriptionText'}>To complete the process you must create or log into your Maxone Account. <br /> We have pre-filled your account information from your SportsEngine Account. <br /> Already have an account? <span className={'boldedText'}><a href="/">Sign In</a></span></p>
                        </div>
                        <div className={'col-lg-8 columnContainer tabContentRightContainer'}>
                            {/* <div className={'marginSpacer20'}/> */}
                            <div className={'row'}>
                                <div className={'col-6'} {...testId(`first-name-container`)}>
                                    <InputField value={nameFirst} label={'First Name'} onChange={value => handleChange('nameFirst', value)} />
                                </div>
                                <div className={'col-6'} {...testId(`last-name-container`)}>
                                    <InputField value={nameLast} label={'Last Name'} onChange={value => handleChange('nameLast', value)} />
                                </div>
                            </div>
                            <div className={'marginSpacer20'} />
                            <div {...testId(`email-container`)}>
                                <InputField label={'Email Address'} onChange={value => handleChange('email', value)} />
                            </div>
                            <div class="marginSpacer10"></div>
                            <div className={'marginSpacer10'} />                           
                            <div className={classes.dropdownContainer} {...testId(`number-athletes-container`)}>
                                <FloatingSelectWithCustomChild
                                    value={orgSize}
                                    dropDownWidth="100%"
                                    label="Estimated Number of Athletes"
                                    onChange={value => handleChange('orgSize', value)}
                                    dropDownLabel="-Select-"
                                    menuItemData={this.menuItemData}
                                    showDropDownBorder={true}
                                />
                            </div>
                            <div class="marginSpacer10"></div>
                            <div className={'marginSpacer10'} />
                            <div {...testId(`phone-number-container`)}>
                                <PhoneNumberInputField value={phoneNumber} label={'Enter Phone Number'} onChange={(country, value, isValid) => { handleChange('countryCode', country); handleChange('phoneNumber', value); handleChange('isPhoneNumberValid', isValid) }} />
                            </div>
                            <p className={classnames('smallGrayDetailText', { 'smallErrorDetailText': phoneNumberErrorStateEnabled })}>You must use a valid phone number and confirm it in the following step.</p>
                            <div className={'marginSpacer10'} />
                            <div className="row">
                                <div className={'col-lg-6'} {...testId(`username-container`)}>
                                    <InputField label={'Username'} onChange={value => handleChange('username', value)} />
                                    <p className={classnames('smallGrayDetailText', { "valid": !existingUsername && username }, { 'smallErrorDetailText': existingUsername })}>{existingUsername ? "Username Taken" : !!username && "Looks Good"}</p>
                                </div>
                                <div className='marginSpacer20 d-lg-none' />
                                <div className="col-lg-6" {...testId(`password-container`)}>
                                    <PasswordInputField label={'Password'} onChange={value => handleChange('password', value)} />
                                    <p className={classnames('smallGrayDetailText', { "valid": isPasswordValid }, { 'smallErrorDetailText': passwordError })}>8 Characters Minimum</p>
                                </div>
                            </div>
                            <div className={'agreementContainer'} onClick={this.toggleAcknowledge}  {...testId(`checkbox-container`)}>
                                <Checkbox onChange={this.toggleAcknowledge} checked={!!hasAcknowledged} className={'checkBox'} />
                                <p className={classnames('smallGrayDetailText', { 'smallErrorDetailText': acknowledgeError })}>
                                    By clicking <span className={'boldText'}>Agree and Continue</span>, I acknowledge that I am at least 13 years old or older and
                                    accept the MaxOne Terms of Use and acknowledge I have read
                                    the Privacy Policy.
                                </p>
                            </div>
                            <div className={'marginSpacer20'} />
                            <div className={'rowContainer justify-content-end'}  {...testId(`submit-button-container`)}>
                                <PrimaryButton
                                    height={60}
                                    multiline={true}
                                    loading={!!isLoading}
                                    text={'Agree and Continue'}
                                    disabled={isContinueDisabled}
                                    onClick={this.handleCreateAccount}
                                    secondaryText={'Next: Verify your phone number'}
                                    width={window.innerWidth < 576 ? '100%' : 275}
                                    leftIcon={<CheckIcon style={{ color: 'white' }} />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div onClick={onClick} className={'closedTabOuterContainer borderBottom step1'}>
                    <div className={'closedTabTitle d-flex w-100 align-items-center'}>Step 1: <span className={'completedStepText'}>{title}</span> <span className="spacer"></span><CheckIcon style={{ color: '#27AE60' }} /></div>
                </div>
        )
    }
}

export default withStyles(styles)(SignupTab)
