import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import CreateEventComponent from "../components/CreateEvent";
import CreateTag from "./CreateTag";
import AddTeams from "./AddTeams";
import AddParticipants from "./AddParticipants";
import PageWrapper from "../../../containers/nav/pageWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import short from "short-uuid";
import {
  getSchedulesByParentId,
  deleteScheduleById,
} from "../../../actions/schedules-actions";
import {
  createEvent,
  updateEvent,
  getEventById,
  sendEmailNotifications,
  getEventsByTeamIdsAndUserId,
  getRecurringEventInstanceByInstanceId,
} from "../../../actions/nylas-calendar-actions";
import { isEqual } from "lodash";
import { formatDate } from "../../../utils/date-utils";
import { downloadFileFromUrl, isJson } from "../../../utils/commonUtil";
import { s3Upload } from "../../../libs/awsLib";
import { getMimeType } from "../../../utils/media-utils";
import { enableFullScreenLoader } from "../../../actions/loading";

const viewOptions = {
  createTag: "createTag",
  addTeam: "addTeam",
  addParticipants: "addParticipants",
  confirmationPopup: "confirmationPopup",
  createTagConfirmationPopup: "createTagConfirmationPopup",
  editRecurringEvent: "editRecurringEvent",
  rsvpPopup: "rsvpPopup",
};

class CreateEvent extends Component {
  constructor(props) {
    super(props);

    const {
      teamsById,
      eventById,
      usersById,
      instanceById,
      currentTeamId,
      loggedInUserId,
      match: { params },
    } = props;
    const {
      teams: { results },
    } = usersById[loggedInUserId];
    const currentTeam = teamsById[currentTeamId];
    const isDirector = (
      results.find((item) => item.id === currentTeam.id) || {}
    ).isDirector;
    let eventDetails = {};
    const masterEventId = (params || {}).masterEventId;
    if (masterEventId) {
      eventDetails = instanceById.data[(params || {}).id] || {};
    } else {
      eventDetails = eventById.data[(params || {}).id] || {};
    }
    const {
      title,
      location,
      message,
      tag,
      externalURL,
      teams,
      when,
      eventType,
      recurrence,
      until,
      days,
      attachments,
    } = eventDetails;
    this.state = {
      errors: {},
      event: eventDetails,
      currentView: "",
      id: (params || {}).id || "",
      title: title || "",
      location: isJson(location) ? JSON.parse(location) : {},
      message: message || "",
      externalURL: externalURL || "",
      object: (when || {}).object || "timespan",
      endTime: (when || {}).end_time
        ? moment((when || {}).end_time * 1000).format()
        : "",
      endDate:
        (when || {}).end_date || until ? (when || {}).end_date || until : "",
      startDate:
        (when || {}).object === "date"
          ? (when || {}).date || ""
          : (when || {}).object === "datespan"
          ? (when || {}).start_date || ""
          : (when || {}).start_time
          ? moment((when || {}).start_time * 1000).format()
          : "",
      startTime: (when || {}).start_time
        ? moment((when || {}).start_time * 1000).format()
        : "",
      selectedTag: tag || {},
      selectedTeam: {},
      selectedTeams: teams
        ? teams.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {})
        : {},
      collapsedIndex: -1,
      additionalView: "",
      currentTeam: currentTeam,
      popupView: "",
      isTBDEvent: eventType === "tbd" ? true : false,
      isAllDayEvent:
        ["date", "datespan"].indexOf((when || {}).object) > -1 &&
        eventType !== "tbd"
          ? true
          : false,
      isMultidayEvent: (when || {}).object === "datespan" ? true : false,
      isRecurringEvent: recurrence ? true : false,
      selectedDays: days
        ? days.reduce((obj, item) => {
            obj[item] = true;
            return obj;
          }, {})
        : {},
      allEvent: true,
      urls: attachments ? JSON.parse(attachments) : [],
      isDirector: isDirector,
      canDeleteTag: false,
      notifyParticipants: true,
      tagToBeDeleted: {},
      masterEventId: masterEventId,
    };
  }

  componentDidMount = () => {
    const { currentTeam, masterEventId } = this.state;
    const {
      getRecurringEventInstanceByInstanceId,
      getSchedulesByParentId,
      match: { params },
      getEventById,
    } = this.props;
    if (masterEventId) {
      getRecurringEventInstanceByInstanceId(
        currentTeam.organizationId,
        masterEventId,
        (params || {}).id
      );
    } else if ((params || {}).id && (params || {}).id !== "new") {
      getEventById(currentTeam.organizationId, (params || {}).id);
    }
    getSchedulesByParentId(currentTeam.organizationId);
  };

  componentDidUpdate = (prevProps) => {
    const {
      match: { params },
      eventById,
      instanceById,
    } = this.props;
    const { masterEventId } = this.state;
    if (
      (!isEqual(prevProps.eventById.data, eventById.data) &&
        eventById.data[(params || {}).id]) ||
      (!isEqual(prevProps.instanceById.data, instanceById.data) &&
        instanceById.data[(params || {}).id])
    ) {
      let eventDetails = {};
      if (masterEventId) {
        eventDetails = instanceById.data[(params || {}).id] || {};
      } else {
        eventDetails = eventById.data[(params || {}).id] || {};
      }
      const {
        title,
        location,
        message,
        externalURL,
        tag,
        teams,
        when,
        until,
        eventType,
        recurrence,
        days,
        attachments,
      } = eventDetails;
      this.setState({
        event: eventDetails,
        id: (params || {}).id || "",
        title: title || "",
        location: isJson(location) ? JSON.parse(location) : {},
        message: message || "",
        externalURL: externalURL || "",
        object: (when || {}).object || "timespan",
        endTime: (when || {}).end_time
          ? moment((when || {}).end_time * 1000).format()
          : "",
        endDate:
          (when || {}).end_date || until ? (when || {}).end_date || until : "",
        startDate:
          (when || {}).object === "date"
            ? (when || {}).date || ""
            : (when || {}).object === "datespan"
            ? (when || {}).start_date || ""
            : (when || {}).start_time
            ? moment((when || {}).start_time * 1000).format()
            : "",
        startTime: (when || {}).start_time
          ? moment((when || {}).start_time * 1000).format()
          : "",
        selectedTag: tag || {},
        selectedTeams: teams
          ? teams.reduce((obj, item) => {
              obj[item.id] = item;
              return obj;
            }, {})
          : {},
        isTBDEvent: eventType === "tbd" ? true : false,
        isAllDayEvent:
          ["date", "datespan"].indexOf((when || {}).object) > -1 &&
          eventType !== "tbd"
            ? true
            : false,
        isMultidayEvent: (when || {}).object === "datespan" ? true : false,
        isRecurringEvent: recurrence ? true : false,
        selectedDays: days
          ? days.reduce((obj, item) => {
              obj[item] = true;
              return obj;
            }, {})
          : {},
        urls: attachments ? JSON.parse(attachments) : [],
      });
    }
  };

  handleChange = (key, value, parent, index) => {
    if (parent) {
      if (index === 0 || index) {
        const data = [...this.state[parent]];
        data[index][key] = value;
        this.setState({ [parent]: data });
      } else {
        const data = { ...this.state[parent] };
        data[key] = value;
        this.setState({ [parent]: data });
      }
    } else {
      this.setState({
        [key]: value,
        errors: { ...this.state.errors, [key]: false },
      });
    }
  };

  validate = () => {
    const {
      title,
      startDate,
      startTime,
      endTime,
      endDate,
      selectedTeams,
      object,
    } = this.state;

    let currentTime = moment().format();
    let errors = {};

    if (!title) {
      errors.title = "Required!";
    }
    if (!startDate) {
      errors.startDate = "Required!";
    }
    if (object === "timespan") {
      if (!startTime) {
        errors.startTime = "Required!";
      } else {
        let processedStartTime = moment(
          `${formatDate(startDate, "YYYY-MM-DD")} ${formatDate(
            startTime,
            "hh:mm A"
          )}`,
          "YYYY-MM-DD hh:mm A"
        ).format();
        if (moment(processedStartTime).isBefore(currentTime)) {
          errors.startTime = "Invalid Start Time";
        }
      }

      if (!endTime) {
        errors.endTime = "Required!";
      } else if (
        startTime &&
        endTime &&
        moment(endTime).isBefore(moment(startTime))
      ) {
        errors.endTime = "End time should be after start time";
      } else {
        let processedEndTime = moment(
          `${formatDate(startDate, "YYYY-MM-DD")} ${formatDate(
            endTime,
            "hh:mm A"
          )}`,
          "YYYY-MM-DD hh:mm A"
        ).format();
        if (moment(processedEndTime).isBefore(currentTime)) {
          errors.endTime = "Invalid End Time";
        }
      }
    } else if (object === "datespan") {
      if (!startDate) {
        errors.startDate = "Required!";
      }
      if (!endDate) {
        errors.endDate = "Required!";
      }
      if (endDate && moment(endDate) <= moment(startDate)) {
        errors.endDate = "End date should be after start date";
      }
    }
    if (Object.keys(selectedTeams || {}).length === 0) {
      errors.teams = "Team(s) Required!";
    }

    Object.values(selectedTeams).forEach((item) => {
      if (
        Object.keys((item.participants || {}).participantsById || {}).length ===
          0 &&
        Object.keys((item.participants || {}).groupsById || {}).length === 0
      ) {
        errors.teams = " Participant(s) Required";
      }
    });

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }

    return true;
  };

  handleEventTypeChange = (key, value) => {
    const { isTBDEvent, isAllDayEvent, isRecurringEvent, isMultidayEvent } =
      this.state;
    if (key === "type") {
      if (value === "recurrence") {
        this.setState({
          isRecurringEvent: !isRecurringEvent,
          isMultidayEvent: false,
          object: isAllDayEvent ? "date" : "timespan",
          isAllDayEvent: isAllDayEvent,
        });
      } else {
        this.setState({
          isMultidayEvent: !isMultidayEvent,
          isRecurringEvent: false,
          object: !isMultidayEvent ? "datespan" : "date",
          isAllDayEvent: !isMultidayEvent || isAllDayEvent,
        });
      }
    } else {
      if (value === "allDay") {
        this.setState({
          isAllDayEvent: !isAllDayEvent,
          isTBDEvent: false,
          object: !isAllDayEvent ? "date" : "timespan",
        });
      } else {
        this.setState({
          isTBDEvent: !isTBDEvent,
          isAllDayEvent: false,
          object: !isTBDEvent ? "date" : "timespan",
        });
      }
    }
  };

  handleSubmit = async () => {
    const {
      id,
      urls,
      title,
      object,
      endDate,
      endTime,
      message,
      allEvent,
      location,
      startDate,
      startTime,
      isTBDEvent,
      currentTeam,
      selectedTag,
      externalURL,
      selectedDays,
      selectedTeams,
      masterEventId,
      isRecurringEvent,
      notifyParticipants,
      isAllMemberSelected,
    } = this.state;
    const {
      loggedInUserId,
      match: { params },
      enableFullScreenLoader,
    } = this.props;

    if (!this.validate()) {
      return;
    }
    enableFullScreenLoader(true, "Loading....");
    const teamIds = Object.keys(selectedTeams);
    let participants = {};
    Object.values(selectedTeams).forEach((item) => {
      participants[item.id] = isAllMemberSelected
        ? ["ALL"]
        : item.participants.participants;
    });
    let data = {
      organizationId: currentTeam.organizationId,
      title,
      object,
      message,
      location: location.place_name ? JSON.stringify(location) : "",
      externalURL,
      days: Object.keys(selectedDays),
      userId: loggedInUserId,
      scheduleId: selectedTag.id,
      teamIds: teamIds,
      participants: participants,
      eventType: isTBDEvent ? "tbd" : "",
      attachments: (urls || []).length ? JSON.stringify(urls) : null,
    };
    if (object === "date") {
      data.startDate = formatDate(startDate, "YYYY-MM-DD");
    } else if (object === "datespan") {
      data.startDate = formatDate(startDate, "YYYY-MM-DD");
      data.endDate = formatDate(endDate, "YYYY-MM-DD");
    } else {
      data.startDate = moment(
        `${formatDate(startDate, "YYYY-MM-DD")} ${formatDate(
          startTime,
          "hh:mm A"
        )}`,
        "YYYY-MM-DD hh:mm A"
      )
        .utc()
        .unix();
      data.endDate = moment(
        `${formatDate(startDate, "YYYY-MM-DD")} ${formatDate(
          endTime,
          "hh:mm A"
        )}`,
        "YYYY-MM-DD hh:mm A"
      )
        .utc()
        .unix();
    }
    if (isRecurringEvent) {
      data.until = formatDate(endDate, "YYYY-MM-DD");
    }

    let event = {};
    if (id) {
      if (masterEventId) {
        if (allEvent) {
          data.eventId = masterEventId;
        } else {
          data.eventId = (params || {}).id;
          data.masterEventId = masterEventId;
        }
      } else {
        data.eventId = (params || {}).id;
      }
      event = await updateEvent(data);
    } else {
      event = await createEvent(data);
    }
    enableFullScreenLoader(false);
    if (notifyParticipants && !id) {
      sendEmailNotifications(currentTeam.organizationId, "", event.id, "New");
    }
    this.setState({
      event: { ...event },
      currentView: id ? viewOptions.confirmationPopup : viewOptions.rsvpPopup,
    });
  };

  loadData = async () => {
    const { currentTeam } = this.state;
    const {
      startDate,
      selectedTeams,
      loggedInUserId,
      getEventsByTeamIdsAndUserId,
    } = this.props;
    getEventsByTeamIdsAndUserId({
      organizationId: currentTeam.organizationId,
      loggedInUserId,
      selectedTeams,
      limit: 100,
      startDate,
      offset: 0,
    });
  };

  handleAddParticipants = (selectedTeams, selectedTeam) => {
    this.setState({
      selectedTeams,
      popupView: viewOptions.addParticipants,
      selectedTeam,
      additionalView: "",
    });

    if (Object.keys(selectedTeams).length > 0) {
      this.setState({ errors: { ...this.state.errors, teams: "" } });
    }
  };

  handleDone = (data) => {
    const { popupView, selectedTeams, selectedTeam } = this.state;
    if (popupView === viewOptions.addParticipants) {
      selectedTeams[selectedTeam.id].participants = data;
      this.setState({
        selectedTeams,
        popupView: "",
        additionalView: viewOptions.addTeam,
        errors: { ...this.state.errors, teams: "" },
      });
    } else {
      this.setState({
        selectedTeams,
        popupView: "",
        additionalView: viewOptions.addTeam,
      });
    }
  };

  handleClose = () => {
    const { history } = this.props;
    this.loadData();
    setTimeout(() => history.push("/timeline"), 1000);
  };

  handleDaySelection = (key, day) => {
    let { selectedDays } = this.state;
    if (key === "all") {
      selectedDays =
        Object.keys(selectedDays).length === 7
          ? {}
          : [
              { label: "SU", value: "SU" },
              { label: "MO", value: "MO" },
              { label: "TU", value: "TU" },
              { label: "WE", value: "WE" },
              { label: "TH", value: "TH" },
              { label: "FR", value: "FR" },
              { label: "SA", value: "SA" },
            ].reduce((obj, item) => {
              obj[item.value] = true;
              return obj;
            }, {});
    } else {
      if (!selectedDays[day]) {
        selectedDays[day] = true;
      } else {
        delete selectedDays[day];
      }
    }
    this.setState({ selectedDays });
  };

  uploadFiles = async (file) => {
    let fileName = file.name.split(".");
    const extension = fileName.pop();
    const name = short.generate();
    const key = `nylas-calendar/${name}.${extension.toLowerCase()}`;
    const type = getMimeType(extension);
    const url = await s3Upload(file, key, type);
    return url;
  };

  handleFileUpload = async (files) => {
    const { urls } = this.state;
    const { enableFullScreenLoader } = this.props;
    enableFullScreenLoader(true, "Uploading Files....");
    const url = await this.uploadFiles(files[0]);
    enableFullScreenLoader(false);
    this.setState({ urls: [...urls, url] });
  };

  handleFileChange = async (e) => {
    this.handleFileUpload(e.currentTarget.files);
  };

  handleDeleteFile = (index) => {
    const { urls } = this.state;
    if (urls.length) {
      urls.splice(index, 1);
    }
    this.setState({ urls });
  };

  handleFileDownload = (url, filename) => {
    downloadFileFromUrl(url, filename);
  };

  deleteSchedule = async () => {
    const { currentTeam, tagToBeDeleted } = this.state;
    const { getSchedulesByParentId } = this.props;
    await deleteScheduleById(tagToBeDeleted.id);
    getSchedulesByParentId(currentTeam.organizationId);
    this.setState({ currentView: "", tagToBeDeleted: {} });
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  sendEmailNotifications = () => {
    const { currentTeam, event } = this.state;
    sendEmailNotifications(currentTeam.organizationId, "", event.id, "Updated");
    this.setState({ currentView: "" }, () => {
      this.handleBack();
    });
  };

  render() {
    const {
      id,
      urls,
      title,
      event,
      object,
      errors,
      message,
      endDate,
      endTime,
      allEvent,
      location,
      startDate,
      startTime,
      popupView,
      isTBDEvent,
      isDirector,
      externalURL,
      selectedTag,
      currentView,
      canDeleteTag,
      selectedDays,
      selectedTeam,
      masterEventId,
      selectedTeams,
      isAllDayEvent,
      tagToBeDeleted,
      collapsedIndex,
      additionalView,
      isMultidayEvent,
      isRecurringEvent,
      notifyParticipants,
    } = this.state;
    const { tags } = this.props;

    return (
      <PageWrapper {...this.props} title={id ? "Edit Event" : "Create Event"}>
        <BodyWrapper
          removePadding={true}
          isLoading={false}
          showLoadingChild={false}
          loadingMessage1={""}
          enableSubContainerStyle={false}
        >
          <Grid container spacing={24}>
            <Grid item xs={additionalView ? 6 : 12}>
              <CreateEventComponent
                id={id}
                urls={urls}
                title={title}
                event={event}
                object={object}
                errors={errors}
                tags={tags.list}
                message={message}
                endDate={endDate}
                endTime={endTime}
                allEvent={allEvent}
                location={location}
                startDate={startDate}
                startTime={startTime}
                isTBDEvent={isTBDEvent}
                isDirector={isDirector}
                externalURL={externalURL}
                currentView={currentView}
                selectedTag={selectedTag}
                canDeleteTag={canDeleteTag}
                selectedDays={selectedDays}
                isAllDayEvent={isAllDayEvent}
                selectedTeams={selectedTeams}
                masterEventId={masterEventId}
                handleClose={this.handleClose}
                collapsedIndex={collapsedIndex}
                tagToBeDeleted={tagToBeDeleted}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                isMultidayEvent={isMultidayEvent}
                isRecurringEvent={isRecurringEvent}
                deleteSchedule={this.deleteSchedule}
                handleFileChange={this.handleFileChange}
                handleDeleteFile={this.handleDeleteFile}
                handleDaySelection={this.handleDaySelection}
                handleFileDownload={this.handleFileDownload}
                handleEventTypeChange={this.handleEventTypeChange}
                sendEmailNotifications={this.sendEmailNotifications}
              />
            </Grid>
            {additionalView === viewOptions.createTag ? (
              <Grid item xs={6}>
                <CreateTag
                  handleClose={(selectedTag) => {
                    this.handleChange("additionalView", "");
                    this.handleChange("selectedTag", selectedTag);
                  }}
                />
              </Grid>
            ) : null}
            {additionalView === viewOptions.addTeam ? (
              <Grid item xs={6}>
                <AddTeams
                  visible={true}
                  handleDone={this.handleDone}
                  selectedTeams={selectedTeams}
                  handleChange={this.handleChange}
                  notifyParticipants={notifyParticipants}
                  handleAddParticipants={this.handleAddParticipants}
                  handleClose={() => this.handleChange("additionalView", "")}
                />
              </Grid>
            ) : null}
            {popupView === viewOptions.addParticipants ? (
              <AddParticipants
                visible={true}
                selectedTeam={selectedTeam}
                handleDone={this.handleDone}
                handleClose={() => this.handleChange("popupView", "")}
              />
            ) : null}
          </Grid>
        </BodyWrapper>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId, byId: usersById },
  teams: { byId: teamsById, currentTeamId },
  schedules: { tags },
  nylasCalendar: { startDate, eventById, instanceById, selectedTeams },
}) => ({
  tags,
  usersById,
  eventById,
  teamsById,
  startDate,
  instanceById,
  selectedTeams,
  currentTeamId,
  loggedInUserId,
});
export default connect(mapStateToProps, {
  getEventById,
  enableFullScreenLoader,
  getSchedulesByParentId,
  getEventsByTeamIdsAndUserId,
  getRecurringEventInstanceByInstanceId,
})(CreateEvent);
