import React, { Component, PureComponent } from 'react'
import './styles.css'
import _ from 'lodash';
import { Modal } from '@material-ui/core'
import { FormatBold, FormatItalic, FormatUnderlined, FormatListBulleted, FormatListNumbered } from '@material-ui/icons';
import StyledButton from '../../styledButton'
import BorderedInput from '../../borderedInput'
import { Editor, EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML, RichUtils } from 'draft-js';
import { getLinkDecorator } from '../../draftjsEntities'

export default class DescriptionModal extends PureComponent {
    constructor(props) {
        super(props);
        this.decorator = getLinkDecorator();
        this.state = {
            editorState: EditorState.createEmpty(this.decorator),
            editorClassName: 'editor'
        }
    }

    componentWillReceiveProps(props) {
        if (props.description && props.description.split('{').length == 1 && this.props.open != props.open) { // this is to catch legacy descriptions and format them
            const descriptionToRender = `<div>${props.description}</div>`
            const blocksFromHTML = convertFromHTML(descriptionToRender);
            let newState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            this.setState({
                editorState: EditorState.createWithContent(newState, this.decorator)
            })
        }
        else if (props.description && this.props.open != props.open) { // this is for editing descriptions created with our new text editor
            try {
                let newState = convertFromRaw(JSON.parse(props.description))
                this.setState({
                    editorState: EditorState.createWithContent(newState, this.decorator)
                })
            }
            catch (e) {
                //Catch json.parse exception when description is not in json format and is in old format
            }
        }
    }

    // TEXT EDITOR HELPERS //
    // inline text styling
    _onInlineClick(inlineType) {
        this.handleEditorChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineType
            )
        )
    }

    // block styling
    _onBlockClick(blockType){
        this.handleEditorChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        )
    }

    // highlight active selections
    isClassActive(style){
        const { editorState } = this.state;
        const currentStyle = editorState.getCurrentInlineStyle();
        const contentState = editorState.getCurrentContent();
        const blocks = contentState.getBlocksAsArray();
        const match = _.find(blocks, b => b.get('type') === style);
        return currentStyle.has(style) || match;
    }

    handleSave = () => {
        const { editorState } = this.state
        const { handleSave, toggleModal } = this.props
        handleSave(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
        toggleModal()
    }

    focus = () => {
        this.setState({ editorClassName: `${this.state.editorClassName} editor-hide-placeholder` })
        this.refs.editor.focus()
    }

    handleEditorChange = async (editorState) => {
        // update text editor state to reflect changes
        this.setState({ editorState });
    }

    render() {
        const { description, editorState, editorClassName } = this.state
        const { open, value, errorMessage, toggleModal } = this.props

        return (
            <div>
                <Modal open={open} onClose={toggleModal}>
                    <div className={'descriptionModalContainer'}>
                        <div className={'contentContainer'}>
                            <p className={'titleText'}>Edit Description</p>
                            {errorMessage ?
                                <p className={'errorContentText'}>
                                    {errorMessage}
                                </p>
                                :
                                null
                            }
                            <div id={"content"}>
                                <div className={"textEditButtonContainer"}>
                                    <p className={"subHead"}>Description</p>
                                    <div className={"iconContainer"}>
                                        <FormatBold onMouseDown={(event) => { event.preventDefault(); this._onInlineClick(`BOLD`) }} className={"styleOptionButton"} style={this.isClassActive(`BOLD`) ? { color: '#00a1e1' } : null} />
                                        <FormatItalic onMouseDown={(event) => { event.preventDefault(); this._onInlineClick(`ITALIC`) }} className={"styleOptionButton"} style={this.isClassActive(`ITALIC`) ? { color: '#00a1e1' } : null} />
                                        <FormatUnderlined onMouseDown={(event) => { event.preventDefault(); this._onInlineClick(`UNDERLINE`) }} className={"styleOptionButton"} style={this.isClassActive(`UNDERLINE`) ? { color: '#00a1e1' } : null} />
                                        <FormatListBulleted onMouseDown={(event) => { event.preventDefault(); this._onBlockClick(`unordered-list-item`) }} className={"styleOptionButton"} style={this.isClassActive(`unordered-list-item`) ? { color: '#00a1e1' } : null} />
                                        <FormatListNumbered onMouseDown={(event) => { event.preventDefault(); this._onBlockClick(`ordered-list-item`) }} className={"styleOptionButton"} style={this.isClassActive(`ordered-list-item`) ? { color: '#00a1e1' } : null} />
                                    </div>
                                </div>
                                <div className={editorClassName} onClick={this.focus}>
                                    <Editor
                                        ref={"editor"}
                                        editorState={this.state.editorState}
                                        onChange={this.handleEditorChange}
                                        placeholder="Add your Description / Instructions here"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'bottomButtonContainer'}>
                            <StyledButton
                                title={'Discard'}
                                onClick={toggleModal}
                                backgroundColor={'white'}
                            />
                            <StyledButton
                                onClick={this.handleSave}
                                title={'Save Description'}
                                backgroundColor={'#27AE60'}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}