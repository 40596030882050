import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames'

const styles = {
    container: {
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "15px",
    }
};

const ShadowContainer = (props) => {
    let { classes, className } = props;
    return (
        <div className={classnames([classes.container, className])}>
            {props.children}
        </div>
    );
}

ShadowContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShadowContainer);