import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import AWS from "aws-sdk";
import moment from "moment";
import "./leaderboards.css";
import { Storage, API } from "aws-amplify";
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PageWrapper from '../nav/pageWrapper';
import { getLocalizedString } from '../../utils/locale-utils'
import { formatMeasureValueForDisplaying } from '../../utils/activityUtils'
import ConfirmationDialog from '../../components/confirmationDialog';
import {
  Button,
  Divider,
  Grid,
  Paper,
  IconButton
} from "@material-ui/core";

const styles = theme => ({
  circleProgressColor: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    backgroundColor: "#1354F9",
    color: 'white'
  },
  resultRow: {
    cursor: 'pointer'
  }
});

class ActivityLeaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaderboard: {},
      isLoading: false,
      viewingResult: null,
      openConfirmationDialog: false
    };
  }

  async componentWillReceiveProps(props, nextProps) {
    if (this.state.currentTeam && props.currentTeam && props.currentTeam.id !== this.state.currentTeam)
      if (props.currentTeam.id !== this.state.currentTeam) {
        await this.componentDidMount()  // Force an update of the component when team changes
      }
  }

  async loadData() {
    this.setState({ currentTeam: this.props.currentTeam, isLoading: true })
    var leaderboard = await this.getLeaderboard(this.props.match.params.id);
    if (leaderboard && leaderboard.activity && ((leaderboard.activity.scoreType || "").toLowerCase() === "shortesttime" || leaderboard.activity.scoreType == "time")) {
      _.map(leaderboard.results, result => {
        if (result.score !== null) {
          result.time = formatMeasureValueForDisplaying(leaderboard.activity.scoreType, result.score);
        }
        return result;
      });
      leaderboard.results.sort((a, b) => (a.score - b.score))
    }
    this.setState({
      isLoading: false,
      leaderboard: leaderboard
    })
  }

  async componentDidMount() {
    this.loadData();
  }

  async getActivity(activityId) {
    return API.get('activities', `/activities/${activityId}`)
  }

  async getLeaderboard(activityId) {
    const { currentTeam } = this.props;
    return API.get("leaderboards", `/leaderboards/team/${currentTeam.id}/activities/${activityId}`);
  }

  async deleteActivityResult() {
    const { viewingResult } = this.state;
    return API.del(
      "activities",
      `/activityResults/${viewingResult.activityResultId}`,
      { body: { userId: viewingResult.player.id } }
    );
  }

  Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
      c = array[i++];
      switch (c >> 4) {
        case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c);
          break;
        case 12: case 13:
          // 110x xxxx   10xx xxxx
          char2 = array[i++];
          out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
          break;
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = array[i++];
          char3 = array[i++];
          out += String.fromCharCode(((c & 0x0F) << 12) |
            ((char2 & 0x3F) << 6) |
            ((char3 & 0x3F) << 0));
          break;
      }
    }

    return out;
  }

  handleResultClick(result) {
    const { viewingResult } = this.state
    if (result == viewingResult)
      return this.setState({ viewingResult: null })
    this.setState({ viewingResult: result })
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value })
  }

  handleDeleteResultConfirmation = async (confirm) => {
    this.setState({ openConfirmationDialog: false })

    if (confirm) {
      this.setState({ isLoading: true })

      await this.deleteActivityResult().then(() => {
        this.loadData()
      }).catch((e) => {
        console.log(e);
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { leaderboard = {}, isLoading, viewingResult, openConfirmationDialog } = this.state
    const showTime = leaderboard.activity &&
      ((leaderboard.activity.scoreType || "").toLowerCase() == "shortesttime" || leaderboard.activity.scoreType == "time");
    return (
      <PageWrapper title={getLocalizedString("PERFORMANCE_LEADERS")} {...this.props} isPageLoading={isLoading} loadingMessage={"loading your leaderboard..."}>
        <div style={{ height: '100%' }}>
          <ConfirmationDialog
            className={"toggleConfirmationContainer"}
            open={openConfirmationDialog}
            content={"Are you sure you want to delete the activity result?"}
            buttonClassName={"toggleConfirmationButton"}
            handleClose={this.handleDeleteResultConfirmation}
          />
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ marginTop: 10, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ margin: 0 }}> Leaderboard for {leaderboard.activity ? leaderboard.activity.name : ''}</h2>
              <Button
                mini
                variant="fab"
                aria-label="add"
                title="Back"
                className={classes.iconButton}
                onClick={() => this.props.history.push('/leaderboards/activities')}
              >
                <ChevronLeftIcon className={"icon"} />
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: 10 }}>
              {/*<Button variant="contained" color="primary">Reset Total Points</Button>*/}
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: 10 }}>
              <Paper>
                {
                  leaderboard.results
                    ?
                    <div>
                      <Grid container spacing={8} style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          Athlete
                    </Grid>
                        <Grid item xs={4} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          When
                    </Grid>
                        <Grid item xs={1} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                          Result
                    </Grid>
                      </Grid>
                      <Divider />
                      {
                        leaderboard.results.map((result, i) => {
                          return (
                            <div
                              key={i}
                              className={classes.resultRow}
                            >
                              <Grid container spacing={8} style={{ paddingTop: 15, paddingBottom: 15, display: 'flex', alignItems: 'center' }}>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ textAlign: 'center', padding: 0 }}
                                  onClick={() => this.handleResultClick(result)}
                                >
                                  {i + 1}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  style={{ textAlign: 'left' }}
                                  onClick={() => this.handleResultClick(result)}
                                >
                                  {result.player.nameFirst} {result.player.nameLast}
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{ textAlign: 'left' }}
                                  onClick={() => this.handleResultClick(result)}
                                >
                                  {moment(result.createdAt).format("MMM DD, YYYY")}
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ textAlign: 'left' }}
                                  onClick={() => {
                                    viewingResult == result ? this.handleChange('openConfirmationDialog', true) : this.handleResultClick(result)
                                  }}
                                >
                                  {viewingResult == result ?
                                    <IconButton
                                      aria-label="Delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    :
                                    showTime ?
                                      result.time : (result.score % 1 != 0 ? result.score.toFixed(2) : result.score)
                                  }
                                </Grid>
                              </Grid>
                              <Divider />
                            </div>
                          )
                        })
                      }
                    </div>
                    :
                    <div style={{ paddingTop: 20, paddingBottom: 20, textAlign: "center" }}>
                      There are no results.
                  </div>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>
      </PageWrapper>
    )
  }

}

ActivityLeaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    currentTeam: currentTeam || {}
  };
}

export default withStyles(styles)(connect(mapStateToProps)(ActivityLeaderboard));
