import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GlassIcon = (props) => (
  <SvgIcon width="16" height="18" viewBox="0 0 16 18" {...props}>
<path d="M13.4575 5.75H2.78406L2.41281 2.375H13.8372L13.4575 5.75ZM8.125 15.0312C7.45367 15.0312 6.80984 14.7646 6.33514 14.2899C5.86043 13.8152 5.59375 13.1713 5.59375 12.5C5.59375 10.8125 8.125 7.94375 8.125 7.94375C8.125 7.94375 10.6562 10.8125 10.6562 12.5C10.6562 13.1713 10.3896 13.8152 9.91486 14.2899C9.44016 14.7646 8.79633 15.0312 8.125 15.0312ZM0.53125 0.6875L2.21875 16.0691C2.32844 16.9128 3.03719 17.5625 3.90625 17.5625H12.3438C13.1875 17.5625 13.9216 16.9128 14.0312 16.0691L15.7188 0.6875H0.53125Z" fill="#222127"/>
  </SvgIcon>
);

export default GlassIcon;
