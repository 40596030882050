import React, { Component } from 'react'

import moment from 'moment'
import momentTZ from 'moment-timezone'

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/EventAvailable'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import ChipData from '../../../../components/chipData'
import StyledButton from '../../../../components/styledButton'
import BorderedInput from '../../../../components/borderedInput'
import parse from 'html-react-parser';
import './styles.css'

export default class ViewingMessage extends Component {

    handleDeleteMessage = () => {
        const { deleteMessage, message } = this.props
        deleteMessage(message)
    }

    handleEditMessage = () => {
        const { sent, editMessage, message } = this.props
        if(!!sent) return // users cannnot edit sent messages
        editMessage(message)
    }

    render(){
        const { sent, message, emptyBody = '' } = this.props
        return(
            message ?
                <div className={'viewingMessageOuterContainer'}>
                    {this.renderMessageHeader()}
                    {this.renderMessageSendTypeHeader()}
                    {this.renderMessageRecipients()}
                    {this.renderMessageBody()}
                    {/* {!sent && this.renderFooterButtons()} */}
                </div>
            :
            <div className={'viewingMessageOuterContainer'}>
                <p className={'emptyListContainer'}>{emptyBody}</p>
            </div>
        )
    }

    renderMessageHeader(){
        const { sent, message, scheduled } = this.props
        console.log('message ', message)

        let timeToDisplay = sent ? (message.sentAt || message.sendDate) : scheduled ? message.sendDate || message.activationTime : (message.createdAt || message.sendDate)
        console.log("message timezone ", message.timezone)
        console.log("current timezone ", momentTZ.tz.guess())

        let convertedTime = momentTZ.tz(timeToDisplay, message.timezone || momentTZ.tz.guess()).format('lll');
        console.log("convertedTime ", convertedTime)

        let convertedWithCurrentTimezone = momentTZ.tz(momentTZ.tz(timeToDisplay, message.timezone || momentTZ.tz.guess()).format(), momentTZ.tz.guess()).format('lll')
        console.log("convertedWithCurrentTimezone ", convertedWithCurrentTimezone)


        return(
            <div className={'dateAndButtonContainer'}>
                <div className={'dateContainer'}>
                    <p className={'dateLabelText'}>{!!scheduled ? 'Scheduled to send' : !!sent ? 'Message Sent On' : 'Created on'}:</p>
                    <p className={!!sent ? 'sentText' : 'dateText'}>{message.matchUserTimezone ? convertedTime  : moment(timeToDisplay).format('lll')}</p>
                </div>
                {sent ?
                    <div />
                :
                    <div className={'actionButtonContainer'}>
                        <StyledButton
                            textColor={'red'}
                            icon={<DeleteIcon />}
                            onClick={this.handleDeleteMessage}
                        />
                        <StyledButton
                            icon={<EditIcon />}
                            title={'Edit Message'}
                            backgroundColor={'#1354F9'}
                            onClick={this.handleEditMessage}
                        />
                    </div>
                }
            </div>
        )
    }

    renderMessageSendTypeHeader(){
        const { sent, message, scheduled } = this.props
        return(
            <div>
                <div style={{marginTop: 10}}>
                    <p> Message Timezone: { momentTZ.tz(message.timezone || momentTZ.tz.guess()).format('z')}</p>
                    <p>{!!scheduled && message.matchUserTimezone ? "Scheduled to be sent in the user's timezone"  : !!sent && message.matchUserTimezone ? "Message was sent matching each user's timezone" : message.matchUserTimezone ? "Created to match each user's timezone" : ''}</p>
                    <p>{message.matchUserTimezone && message.partialSend ? "Note:  This message has been partially sent due to differing timezones.  Check the Sent and Scheduled tabs to see who it has been sent to and who its still scheduled to send to.  "  : ''}</p>
                </div>
            </div>
        )
    }

    renderMessageRecipients(){
        const { message: { recipients = [], groups = [], timezone } = {} } = this.props
        console.log('timezone ', timezone)
        const reducedGroups = _.uniqBy(groups, "id");
        const totalUsers = reducedGroups.reduce((current, { users: { total } }) => current + total, recipients.length);


        return (
            <div onClick={this.handleEditMessage} className={'recipientsListOuterContainer viewingMessageRecipientsContainer'}>
                <p className={'viewingMessageRecipientsLabel'}>Recipients: {totalUsers > 0 ? totalUsers : 'None'}</p>
                {totalUsers > 0 ?
                    <div className={'recipientsListContainer'}>

                        {reducedGroups.filter(item => !!item).map(({ name, users: { total = 0 } = {} }, index) => {
                            return (
                                <ChipData recipient={{ name }} timezone={timezone || momentTZ.tz.guess() } />
                            )
                        })}

                        {recipients.map((recipient, i) => {
                            return (
                                <ChipData
                                    key={i}
                                    recipient={recipient}
                                    timezone={timezone || momentTZ.tz.guess() }
                                />
                            )
                        })}
                    </div>
                :
                    <div className={'emptyRecipientsListContainer'}>
                        <div className={'emptyRecipientsListText'}>
                            <p>No Recipients</p>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderMessageBody(){
        const { string, message } = this.props
        const messageType = message.messageType || message.type;
        return(
            <div onClick={this.handleEditMessage} className={'recipientsListOuterContainer viewingMessageRecipientsContainer'}>
                {messageType.toLowerCase() == 'pushnotification' || messageType.toLowerCase() == 'push' || messageType.toLowerCase() == 'notification' ?
                    <div className={'notificationInfoContainer'}>
                        <BorderedInput disabled value={string} />
                        <BorderedInput disabled value={message && message.meta && message.meta.externalUrl ? message.meta.externalUrl : message.externalUrl} placeholder={'External URL'} />
                        <BorderedInput disabled value={message && message.meta && message.meta.activityName ? message.meta.activityName : message && message.meta && message.meta.activityId ? message.meta.activityId : message.activityId} placeholder={'Link Activity'} />
                    </div>
                :
                    <div>
                        <p className={'viewingMessageRecipientsLabel'}>Content:</p>
                        <div className={'borderedTextAreaContainer'}>
                            {messageType.toLowerCase() == 'email'? parse(message.message):message.message}
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderFooterButtons(){
        const { toggleDateTimeModal, sendMessage } = this.props
        return(
            <div className={'footerButtonContainer'}>
                <StyledButton onClick={toggleDateTimeModal} textColor={'black'} icon={<EventIcon />} title={'Schedule For Later'} />
                <StyledButton onClick={sendMessage} title={'Send Now'} icon={<PlayCircleFilledIcon />} backgroundColor={'#27AE60'} />
            </div>
        )
    }
}
