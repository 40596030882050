const isCoachup = window.location.href.toLowerCase().includes('coachup')
export const TimeHours = [
    { actual: 6, displayed: '6:00 AM' },
    { actual: 7, displayed: '7:00 AM' },
    { actual: 8, displayed: '8:00 AM' },
    { actual: 9, displayed: '9:00 AM' },
    { actual: 10, displayed: '10:00 AM' },
    { actual: 11, displayed: '11:00 AM' },
    { actual: 12, displayed: '12:00 PM' },
    { actual: 13, displayed: '1:00 PM' },
    { actual: 14, displayed: '2:00 PM' },
    { actual: 15, displayed: '3:00 PM' },
    { actual: 16, displayed: '4:00 PM' },
    { actual: 17, displayed: '5:00 PM' },
    { actual: 18, displayed: '6:00 PM' },
    { actual: 19, displayed: '7:00 PM' },
    { actual: 20, displayed: '8:00 PM' },
    { actual: 21, displayed: '9:00 PM' }
]

export const TimeMinutes = [
    { actual: 0, displayed: '00' },
    { actual: 5, displayed: '05' },
    { actual: 10, displayed: '10' },
    { actual: 15, displayed: '15' },
    { actual: 20, displayed: '20' },
    { actual: 25, displayed: '25' },
    { actual: 30, displayed: '30' },
    { actual: 35, displayed: '35' },
    { actual: 40, displayed: '40' },
    { actual: 45, displayed: '45' },
    { actual: 50, displayed: '50' },
    { actual: 55, displayed: '55' }
]

export const ActivityTypes = [
    { displayed: 'Skill Development', actual: 'drill' },
    { displayed: 'Strength & Conditioning', actual: 'exercise' },
    { displayed: 'Education Materials', actual: 'education' },
    ...(isCoachup ? []:[{ displayed: 'Coaching Resources', actual: 'coach' }])
]

export const scoreTypes = [
    { id: 0, label: 'Count', value: 'count' },
    { id: 1, label: 'Longest Time', value: 'time' },
    { id: 3, label: 'Participation', value: 'participation' },
    { id: 4, label: 'Weight', value: 'weight' },
    { id: 2, label: 'Shortest Time', value: 'shortestTime' },
    { id: 5, label: 'MPH', value: 'mph' },
    { id: 6, label: 'Distance', value: 'distance' }
]

export const scoreTypesMap = scoreTypes.reduce(
    (obj, item) => Object.assign(obj, { [item.value.toLowerCase()]: item.label }), {});

export const SIZES = [
    { id: '0-30', name: '0-30'  },
    { id: '31-100', name: '31-100' },
    { id: '101-200', name: '101-200'  },
    { id: '201-500', name: '201-500'  },
    { id: '501-800', name: '501-800'  },
    { id: '800+', name: '800+'  }
]

export const repsOrTimeOptions = [
    { id: 'reps', name: 'Reps'  },
    { id: 'time', name: 'Time' },
]

export const SE_SESSION_KEY = "se_acc_t" ;
export const SERVICE_OAUTH_ERROR = "se_o_e"

export const SPORTS = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Other'];


export const QUESTION_TYPES = Object.freeze({
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    FREE_FORM: 'FREE_FORM',
    RANGE:'RANGE',
    TIME:'TIME',
    NUMBER_INPUT:'NUMBER_INPUT',
    YES_NO:"YES_NO"
});

export const REPORT_SCOPES = Object.freeze({
    ATHLETE: 'ATHLETE',
    GROUP: 'GROUP',
    QUESTION: 'QUESTION',
    PROGRAM:'PROGRAM',
    WORKOUT:'WORKOUT'
});

export const ORG_PLANS = [
    { id: 'teams-1', name: 'Single Team', price: 300, minTeams: 1, maxTeams: 1, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-2', name: 'Two Teams', price: 600, minTeams: 2, maxTeams: 2, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-3', name: 'Three Teams', price: 900, minTeams: 3, maxTeams: 3, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-4', name: 'Four Teams', price: 1200, minTeams: 4, maxTeams: 4, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-5', name: 'Five Teams', price: 1500, minTeams: 5, maxTeams: 5, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-6', name: 'Six Teams', price: 1800, minTeams: 6, maxTeams: 6, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'bronze', name: 'Bronze', price: 2000, minTeams: 7, maxTeams: 9, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'silver', name: 'Silver', price: 2500, minTeams: 10, maxTeams: 14, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'gold', name: 'Gold', price: 3000, minTeams: 15, maxTeams: 19, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'platinum', name: 'Platinum', price: 3500, minTeams: 20, maxTeams: 24, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-school', name: 'Full School', price: 1500, minTeams: 5, maxTeams: 1000, organizationTypes: ['School'] }
];

export const SERVICES = Object.freeze({
    ZOOM:"ZOOM",
    UPLIFT:"UPLIFT"
})

export const LIVE_EVENT_TYPES = Object.freeze({
    SMALL_GROUP: "SMALL_GROUP",
    ONE_ON_ONE: "ONE_ON_ONE",
    LARGE_GROUP: "LARGE_GROUP",
});

export const TRAINING_OPTIONS= [
    {
        name:"Live Group Meeting",
        subText:"Zoom",
        isMeeting: true,
        feature: SERVICES.ZOOM,
        id:SERVICES.ZOOM,
        type:LIVE_EVENT_TYPES.LARGE_GROUP
    },
    {
        name:"Live Interactive Coaching",
        subText:"uplift",
        isMeeting: true,
        feature: SERVICES.UPLIFT,
        id:SERVICES.UPLIFT,
        type:LIVE_EVENT_TYPES.SMALL_GROUP
    }
  ]

export const WORKOUT_EDIT_TYPE = Object.freeze({
    CREATE_NEW: 'CREATE_NEW',
    EDIT_ORIGINAL: 'EDIT_ORIGINAL',
    ONE_TIME_EDIT:'ONE_TIME_EDIT'
});

export const ALL_USERS_GROUPS = Object.freeze({
    allCoaches : { name: 'All Coaches', id: 'allCoaches', orderOnTop:2 },
    allParents: { name: 'All Parents', id: 'allParents', orderOnTop:3 },
    allAthletes : { name: 'All Athletes', id: 'allAthletes', orderOnTop:1 }
})
