import { schedulesClient } from "../clients";

export const GET_SCHEDULES_BY_PARENT_ID_LOADING =
  "GET_SCHEDULES_BY_PARENT_ID_LOADING";
export const GET_SCHEDULES_BY_PARENT_ID_SUCCESS =
  "GET_SCHEDULES_BY_PARENT_ID_SUCCESS";
export const GET_SCHEDULES_BY_PARENT_ID_ERROR =
  "GET_SCHEDULES_BY_PARENT_ID_ERROR";;

export const getSchedulesByParentId = (parentId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SCHEDULES_BY_PARENT_ID_LOADING });
    const { data } = await schedulesClient.getSchedulesByParentId(parentId);
    dispatch({ type: GET_SCHEDULES_BY_PARENT_ID_SUCCESS, data });
  } catch (e) {
    console.warn(`Error getting schedules by parent ID ${parentId}`, e);
    dispatch({ type: GET_SCHEDULES_BY_PARENT_ID_ERROR });
  }
};

export const createSchedule = async (name, color, parentId) => {
  try {
    const { data } = await schedulesClient.createSchedule({
      name,
      color,
      parentId,
    });
    return data;
  } catch (e) {
    console.warn(`Error creating tag by parent ID ${parentId}`, e);
  }
};

export const getSchedulesByName = async (name) => {
  name = name.replace(/ /g, "-")
  try {
    const { data: { results } } = await schedulesClient.getSchedulesByName(name);
    return results;
  } catch (e) {
    console.warn(`Error getting schedule names by  ${name}`, e);
  }
};

export const deleteScheduleById = async (scheduleId) => {
  try {
    const { data } = await schedulesClient.deleteScheduleById(scheduleId);
    return data;
  } catch (e) {
    console.warn(`Error deleting tag by schedule ID ${scheduleId}`, e);
  }
};