import React, {useState} from 'react'
import { Search } from '../../../icons'
const SearchBar = ({ handleSearch }) => {
    const [searchValue, setSearchValue] = useState('');
    const setValue = (e) => {
        console.log('==', e.target.value);
        setSearchValue(e.target.value);
        handleSearch(e.target.value)

    }
    return (
        <>
            <Search fill={'#dde2e5'}/>
            <input type="text" placeholder="Search" value={searchValue} onChange={e => setValue(e)} />
        </>
    )
}

export default SearchBar;