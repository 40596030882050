import React, { Component } from "react";

import {
    Grid,
    Modal,
    Button
} from "@material-ui/core";

import "../calendar.css";
import LegacyModalContainer from '../../../components/modalContainer/legacyModalContainer';
export default class CopyModal extends Component {
    render(){
        return (
            <LegacyModalContainer
                open={this.props.open}
                onClose={this.props.toggleModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                containerClassName={"howToCalendarPaper"}
            >
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>Copying Events</h2>
                </div>
                <Grid container>
                    <Grid item>
                    <p>To copy an event click, drag, and drop the event into the lower half of the desired day.</p>
                    </Grid>
                </Grid>
                <div className={"modalFooterContainer"}>
                    <Button
                        variant="contained"
                        className={"cancelButton"}
                        onClick={this.props.toggleModal}>
                        Close
                    </Button>
                </div>
            </LegacyModalContainer>
        )
    }
}