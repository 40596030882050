import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BanIcon = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 10 11" {...props}>
    <circle
      cx="5"
      cy="5.5"
      r="4.30303"
      stroke="#EF3C3C"
      fill="#fff"
      stroke-width="1.39394"
    />
    <path
      d="M1.76562 2.26465L8.0048 8.50383"
      stroke="#EF3C3C"
      fill="#fff"
      stroke-width="1.39394"
    />
  </SvgIcon>
);

export default BanIcon;
