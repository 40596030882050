export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dev-api.maxone-dev.eu"
  },
  cognito: {
    USER_POOL_ID: "eu-west-1_uEOeR4QuX",
    APP_CLIENT_ID: "4dmm5gm75stkugmqffhi6erct9",
    IDENTITY_POOL_ID: "eu-west-1:8239599d-782c-4a71-abd6-f4ee4b122873"
  }
}
