import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SleepIcon = (props) => (
  <SvgIcon width="20" height="14" viewBox="0 0 20 14" {...props}>
    <path d="M16.3483 2.2792H9.35256V8.40045H2.35684V0.530273H0.60791V13.6472H2.35684V11.0238H18.0972V13.6472H19.8461V5.77706C19.8461 4.84937 19.4776 3.95968 18.8216 3.3037C18.1657 2.64773 17.276 2.2792 16.3483 2.2792ZM5.8547 7.52599C6.55047 7.52599 7.21774 7.2496 7.70972 6.75761C8.2017 6.26563 8.47809 5.59836 8.47809 4.9026C8.47809 4.20683 8.2017 3.53956 7.70972 3.04758C7.21774 2.55559 6.55047 2.2792 5.8547 2.2792C5.15893 2.2792 4.49166 2.55559 3.99968 3.04758C3.5077 3.53956 3.2313 4.20683 3.2313 4.9026C3.2313 5.59836 3.5077 6.26563 3.99968 6.75761C4.49166 7.2496 5.15893 7.52599 5.8547 7.52599Z" fill="#222127"/>
  </SvgIcon>
);

export default SleepIcon;
