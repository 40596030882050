import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import volleyball575Logo from '../assets/images/themes/575volleyball/575volleyball-logo.png';
import volleyball575LogoLogin from '../assets/images/themes/575volleyball/575volleyball-logo-login.png';
import homeImage from '../assets/images/themes/upward/upward-welcome.png';


// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    name: '575 Volleyball',
    appUrl: 'm1-volleyball575://',
    domain: '575volleyball.gomaxone.com',
    link: 'https://apps.apple.com/us/app/575-volleyball/id1548327549',
    onAppleAppStore: 'https://apps.apple.com/us/app/575-volleyball/id1548327549',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.volleyball575' // replace with real link
  },
  image: {
    home: homeImage,
    logo: volleyball575Logo,
    loginLogo: volleyball575LogoLogin,
  },
  palette: {
    type: "light",
    primary: {
      main: "#898D90"
    },
    secondary: {
      main: "#0957C4",
      isDarkMode: true,
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#0e3f90"
    },
    secondary: green,
    error: red,
  }

});
