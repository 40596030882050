import React, { Component } from "react";
import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getThemeByTenant } from '../../themes';
import { coppaClient, userClient } from "../../clients";
import googlePlay from '../../assets/images/google-play.png';
import appleAppStore from '../../assets/images/apple-app-store.png';
import { connect } from 'react-redux';

const styles = theme => ({
    image: {
        width: 100,
        height: 100,
        objectFit: "contain"
    },
})

class ParentVerification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statusVerified: false,
            athlete: {}
        }
    }

    componentDidMount = async () => {
       this.updateVerificationStatus();
    }

    updateVerificationStatus = () => {
        const { location: { search } } = this.props;
        const userId = search.split("=")[1]
        userClient.getUserById(userId, "maxone").then(({ data }) => {
            this.setState({ athlete: data })
        }).catch((e) => {
            this.setState({ athlete: {} })
        })
        coppaClient.updateParentVerificationStatusByUserId(userId).then(({ data }) => {
            if (data.status === "VERIFIED") {
                this.setState({ statusVerified: true })
            }
        }).catch((e) => {
            this.setState({ statusVerified: false })
            alert("Oops! not able to verify. Try again")
        })
    }

    render() {
        const { statusVerified, athlete } = this.state
        const { classes, tenant } = this.props;
        const theme = getThemeByTenant();
        const { logo } = tenant;
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "100vh", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../../assets/images/SportsSpecific_Header_Soccer.jpg')}` }}>
                <Grid item xs={11} md={6}>
                    <Paper style={{ borderRadius: 5 }}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={4} md={8} style={{ paddingTop: 30, textAlign: "center" }}>
                                <img className={classes.image} src={logo} alt="logo" />
                            </Grid>
                            {statusVerified ?
                                <Grid item xs={8} spacing={12} style={{ margin: 30 }}>
                                    <Typography style={{ textAlign: "left" }}>Email has been verified! </Typography>
                                    <Typography style={{ textAlign: "left", marginTop: 15 }}>Thank you for giving consent to {athlete.firstName} {athlete.lastName} to access the {theme.product.name} App!</Typography>
                                    <Typography style={{ textAlign: "left", marginTop: 15 }}>You will recieve an additional email once your athlete’s account is created with directions to set up a parent/guardian account to access your athletes information and training. </Typography>
                                </Grid> : null}
                            {statusVerified ?
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={8} md={3} style={{ marginBottom: 30, textAlign: "center" }}>
                                        <a href={theme.product.onGooglePlay}>
                                            <img src={googlePlay} style={{ height: 51, width: 150 }} />
                                        </a>
                                    </Grid>
                                    <Grid item xs={8} md={3} style={{ marginBottom: 30, textAlign: "center" }}>
                                        <a target="_blank" href={theme.product.onAppleAppStore}>
                                            <img src={appleAppStore} style={{ height: 50, width: 150 }} />
                                        </a>
                                    </Grid>
                                </Grid>
                                 : null}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({ tenant }) => ({
    tenant
  });
  

export default withStyles(styles)(connect(mapStateToProps)(ParentVerification))