import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let TrackAndFieldIcon = (props) => (
  <SvgIcon width="19" height="18" viewBox="0 0 19 18" {...props}>
  	<path d="M5.15564 0.0653481C6.08446 0.380894 6.58095 1.29539 6.29585 2.15423C5.81414 3.6304 3.70583 3.79735 2.98822 2.41413C2.84081 2.13091 2.82603 2.07066 2.82603 1.66218C2.82603 1.27684 2.84081 1.18868 2.96372 0.961293C3.12591 0.645747 3.5487 0.255814 3.86803 0.121184C4.18249 -0.0136304 4.8509 -0.0413645 5.15564 0.0653481Z" />
<path d="M12.803 3.53731C14.7198 4.7117 14.8427 4.81382 14.9115 5.24544C15.0049 5.82566 14.479 6.39669 13.8499 6.39669C13.55 6.39669 13.3093 6.27602 11.8742 5.38485L10.6012 4.60039L9.80987 4.78609C9.37736 4.88821 9.01369 4.97637 9.00377 4.98114C8.99386 4.99051 9.52963 5.74705 10.198 6.66614C10.8616 7.58982 11.4612 8.46244 11.5349 8.61103C11.6282 8.8059 11.6675 8.987 11.6871 9.25626C11.7068 9.59513 11.692 9.66475 11.5741 9.91546C11.4857 10.0966 11.3482 10.2822 11.186 10.4261C10.9598 10.6303 9.6821 11.5029 8.13389 12.5241C7.8095 12.7376 7.5244 12.928 7.50476 12.9466C7.48026 12.9605 7.89799 13.8192 8.43377 14.8498C9.36258 16.6508 9.40186 16.7344 9.40186 17.0083C9.40673 17.3936 9.23481 17.6721 8.88087 17.8623C8.42871 18.1083 7.83401 18.0155 7.54405 17.6581C7.42619 17.5097 4.76714 12.4176 4.76714 12.3386C4.76714 12.3155 5.5536 11.7724 6.51197 11.1271C7.4754 10.4865 8.25679 9.93419 8.24688 9.90168C8.22723 9.82748 6.22687 7.04707 6.19244 7.04707C6.17766 7.04707 5.99097 7.45555 5.77471 7.95679C5.56332 8.45344 5.37176 8.87129 5.35698 8.88047C5.28328 8.92694 1.22848 9.56281 1.01728 9.56281C0.707672 9.56281 0.37337 9.40026 0.181813 9.14496C-0.00993964 8.89425 -0.0540866 8.49972 0.0688215 8.18418C0.157308 7.97038 0.412849 7.72426 0.633966 7.64069C0.702811 7.61755 1.44493 7.48292 2.28039 7.34351C3.11585 7.20888 3.81869 7.07903 3.83347 7.06029C3.85311 7.03715 4.08414 6.52177 4.34455 5.90923L4.8212 4.79527L7.15101 3.33766L9.02847 2.89666C10.0654 2.65073 10.9355 2.45108 10.9699 2.45108C11.0043 2.45126 11.8301 2.94332 12.803 3.53731Z" />
<path d="M13.2997 11.99L12.8573 11.303C12.6164 10.9271 12.4001 10.6207 12.3805 10.6209C12.3559 10.6209 12.3067 10.6765 12.2675 10.7461C12.1494 10.9596 11.8447 11.2288 11.3138 11.5908C11.0386 11.7765 10.8124 11.9483 10.8124 11.9714C10.8124 11.9993 11.1024 12.4635 11.4563 13.0112L12.1002 14.0092H14.8231C17.3388 14.0092 17.6909 14.0125 17.9242 13.8477C17.9667 13.8177 18.0052 13.7821 18.0521 13.74C18.5782 13.2665 18.4601 12.4495 17.8162 12.1154C17.6884 12.0458 17.3985 12.0318 15.4866 12.0133L13.2997 11.99Z" />
  </SvgIcon>
);

export default TrackAndFieldIcon;