import React, { Component } from 'react'
import { Button } from '@material-ui/core'

import './styles.css'

export default class TextButton extends Component {
    render(){
        const {
            icon,
            width,
            title,
            onClick,
            disabled,
            textColor,
            backgroundColor
        } = this.props

        return(
            <a
                disabled={disabled}
                variant={'contained'}
                className={'styledButton'}
                onClick={disabled ? null : onClick}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: width ? width : null,
                    opacity: disabled ? 0.4 : 1,
                    justifyContent: 'center',
                    backgroundColor: backgroundColor ? backgroundColor : 'white',
                    color: backgroundColor == 'white' ? 'black' : textColor ? textColor : 'white',
                    border: (backgroundColor && backgroundColor !== 'white') ? 'none' : '1px solid lightgray'
                }}
            >
                <p className={'styledButtonText'} style={{textAlign:"center"}}>{title}</p>
                {icon && icon}
            </a>
        )
    }
}
