import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import './index.scss';

const Component = (props) => {
    const { children } = props;
    return (
        <div className={'m1-cancel-button'}>
            <Button variant={'outlined'} {...props}>
                {children}
            </Button>
        </div>
    )
};

Component.propTypes = {
    children: PropTypes.any
};

Component.defaultProps = {
    children: null
};

export default Component;