import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';

import {
  Avatar,
  Button,
  Hidden,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Modal,
  Paper,
  Select,
  Checkbox,
  FormControlLabel,
  TextField,
  Menu,
} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import Snackbar from '@material-ui/core/Snackbar';
import ImageCropperModal from '../../components/modals/imageCropperModal'
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonOutlineIcon  from "@material-ui/icons/PersonOutline";
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { FilterList } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import AssessmentIcon from '@material-ui/icons/Assessment';

import _ from "lodash";
import "./new.css";
import moment from "moment";
import { API, Auth, Storage } from "aws-amplify";
import logo from "../../assets/images/logo-dark.png"
import config from "../../config"
import { s3Upload } from "../../libs/awsLib";

import { setUserContext, setAppContext } from '../../actions/session';
import ContextService from '../../services/context';
const contextService = new ContextService()

const ORGANIZATION_ID = '2019true2019lacrosse' // Default True Lacrosse Org Id
const TEAM_ID = 'team2019true2019lacrosse' // Default True Lacrosse Team Id, everyone is added to this team when they are created
const TENANT = 'truelacrosse' // Default True Lacrosse Team Id, everyone is added to this team when they are created


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: '100%',
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  header: {
    fontSize:35,
    display:"inline-block",
    marginLeft:30,
    textAlign:"left"
  },
  subHeader: {
    fontWeight: 100,
  },
  selectedRosterCard: {
      backgroundColor: "#1354F9"
  },
  modalInviteButton: {
      width: 100,
      height: 40,
      marginRight: 10,
      backgroundColor: "#27AE60",
      color: theme.product.name == 'MaxOne' ? 'black' : 'white'
  },
  addUserButton: {
      height: 40,
      color: 'white',
      backgroundColor: "#1354F9"
  },
  plusButton: {
      backgroundColor: "#1354F9",
      color: 'white',
      outlineColor: 'transparent',
      outlineStyle: 'none'
  },
  textButton: {
      color: 'white',
      height: '40px',
      marginRight: '3px',
      backgroundColor: "#1354F9",
  },
  pageTitle: {
      textAlign: 'left',
      marginLeft: 50
  },
  details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: `100%`
  },
});
class TrueLacrosseDirectorCreateTeams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            fileName: "",
            isCropperModalOpen: false,
            open:false,
            athletes: [],
            renderedAthletes: [],
            renderedCoaches: [],
            selectedAthletes: [],
            selectedCoaches: [],
            coaches: [],
            team:{},
            sport: "Lacrosse",
            teamLogo: null,
            message: '',
            error: false,
            errorMessage: '',
            searchText: null,
            isLoading: false,
            rosterFilterBy: 'graduationYear',
            anchorEl: "",
            openPopup: false,
            selectedFilter: null,
            filters: {
                graduationYear: [],
                state: []
            }
        };
    }

    async componentDidMount(){
        const organization = await this.getOrganization(ORGANIZATION_ID)
        const teams = await this.getTeamsByOrganization(ORGANIZATION_ID);
        const wcsOrgId = organization.id.split("-")[1]
        // const wcsOrgId = "wcs-284".split("-")[1]
        const defaultTeamId = "team2019true2019lacrosse"
        console.log('defaultTeamId ', defaultTeamId)
        let athletes = [];
        let coaches = [];
        athletes = await this.getAthletes()
          // localStorage.setItem(`@wcs:students-${wcsOrgId}:`, JSON.stringify(athletes))
        coaches = await this.getCoaches()
        // localStorage.setItem(`@wcs:teachers-${wcsOrgId}:`, JSON.stringify(coaches))
        this.setState({organization, athletes, renderedAthletes: athletes, coaches, renderedCoaches: coaches});
    }

    getAthlete() {
        var athleteId = this.props.match.params.id
        return API.get("users", `/users/${athleteId}`);
    }

    getOrganization(organizationId) {
        return API.get("organizations", `/organizations/${organizationId}`);
    }

    getTeamsByOrganization(organizationId) {
        return API.get("programs", `/programs/organization/${organizationId}`);
    }

    getAthletes(){
        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("truelacrosse", `/truelacrosse/athletes`);
        })
    }

    getCoaches(){
        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("truelacrosse", `/truelacrosse/coaches`);
        })
    }

    createTeam(team){
        return API.post('programs', '/programs', {
            body: team
        })
    }

    createSchedule(currentTeam, schedule) {
        return API.post("schedules", `/teams/${currentTeam.id}/schedules`, {
            body: schedule
        });
    }

    createRole(role) {
        return API.post("users", `/users/${role.userId}/roles`, {
            body: role
        });
    }

    updateOrganization(organization){
        return API.post("organizations", `/organizations/${organization.id}`, {
            body: organization
        });
    }

    setSessionInfo = async (username) => {
      let prefix = null
      const contextService = new ContextService();
      const {userContext, appContext} = await contextService.buildUserContext(username);
      this.props.setUserContext(userContext);
      this.props.setAppContext(appContext);
    }

    setFilter = (key, value) => {
        let { filters, athletes } = this.state

        if (filters[key].indexOf(value) > -1) {
            let index = filters[key].indexOf(value)
            filters[key].splice(index, 1)
        } else {
            filters[key].push(value)
        }
        let renderedAthletes = athletes.filter(item => {
            if(filters['state'].length && filters['graduationYear'].length){
                return filters['graduationYear'].indexOf(item.graduationYear) > -1 && filters['state'].indexOf(item['state/Province'] || item.state) > -1
            } else if(!filters['state'].length && !filters['graduationYear'].length){
                return true
            } else if (filters['graduationYear'].length) {
                return filters['graduationYear'].indexOf(item.graduationYear) > -1
            } else if (filters['state'].length) {
                return filters['state'].indexOf(item['state/Province'] || item.state) > -1
            }
        })
        this.setState({ filters, renderedAthletes })
    }

    handleSubmit = async event => {
      let { attachment, teamLogo } = this.state
      if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
        return;
      }

      this.setState({ isLoading: true });

      const { name, sport, selectedAthletes, selectedCoaches } = this.state;
      const { currentTeam, currentUser } = this.props;
      let organization = this.state.organization;
      let team = {
          name: name,
          legacyId: 0,
          sport: sport,
          customName: `${name} ${sport}`,
          organizationId: ORGANIZATION_ID,
          tenant: TENANT,
          tenantId: TENANT
      }
      if(teamLogo){
        team.logo  = teamLogo
      }
      console.log('team --- ', team)
      const newTeam = await this.createTeam(team);
      console.log('newTeam --- ', newTeam)

      const scheduleJSON = {
          name: newTeam.customName,
          visibility: 'everyone',
          color: '#dce775',
          tenant: TENANT,
          tenantId: TENANT
      }
      await this.createSchedule(newTeam, scheduleJSON);

      await this.asyncForEach(selectedCoaches, async user =>{
        const roleJSON = {
            legacyId: 0,
            role: "coach",
            parentId: newTeam.id,
            userId: user.id,
            tenant: TENANT,
            tenantId: TENANT
        }
        const newRole = await this.createRole(roleJSON);
      })
      await this.asyncForEach(selectedAthletes, async user =>{
        const roleJSON = {
            legacyId: 0,
            role: "player",
            parentId: newTeam.id,
            userId: user.id,
            tenant: TENANT,
            tenantId: TENANT
        }
        const newRole = await this.createRole(roleJSON);
      })


      const roleJSON = {
          legacyId: 0,
          role: "coach",
          parentId: newTeam.id,
          userId: currentUser.id,
          tenant: TENANT,
          tenantId: TENANT

      }
      const newRole = await this.createRole(roleJSON);
      const updatedSession = await this.setSessionInfo();
      this.setState({
        open: true,
        isLoading: false,
        attachment: null,
        message:`Successfully Created ${team.name}, you may have to reload to see your changes reflected`,
      })
      this.hideSnackBar();
      this.props.history.push('/athletes')
    }

    handleChange = (name, value) => {
        this.setState({
        [name]: value,
        });
    };

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }

    handleSelect = athlete => {
        // check if athlete is in the selection already
        let selectedAthletes = this.state.selectedAthletes;
        let isSelected = _.find(selectedAthletes, {id: athlete.id})

        // if not, then push onto array
        // if they are, then remove them from array.
        if(isSelected){
          let index = _.findIndex(selectedAthletes, {id: athlete.id})
          selectedAthletes.splice(index, 1)
        }
        else{
          selectedAthletes.push(athlete);
        }

        this.setState({
            selectedAthletes: selectedAthletes
        })
    }
    handleSelectCoach = coach => {
        // check if athlete is in the selection already
        let selectedCoaches = this.state.selectedCoaches;
        let isSelected = _.find(selectedCoaches, {id: coach.id})
        // if not, then push onto array
        // if they are, then remove them from array.
        if(isSelected){
          let index = _.findIndex(selectedCoaches, {id: coach.id})
          selectedCoaches.splice(index, 1)
        }
        else{
          selectedCoaches.push(coach);
        }
        this.setState({
            selectedCoaches: selectedCoaches
        })
    }

    searchAthletes = event => {
        const { filters, athletes } = this.state;

        let renderedAthletes = athletes.filter(item => {
            if(filters['state'].length && filters['graduationYear'].length){
                return filters['graduationYear'].indexOf(item.graduationYear) > -1 && filters['state'].indexOf(item['state/Province'] || item.state) > -1
            } else if(!filters['state'].length && !filters['graduationYear'].length){
                return true
            } else if (filters['graduationYear'].length) {
                return filters['graduationYear'].indexOf(item.graduationYear) > -1
            } else if (filters['state'].length) {
                return filters['state'].indexOf(item['state/Province'] || item.state) > -1
            }
        })

        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedAthletes: _.filter(renderedAthletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()) || (a.graduationYear && a.graduationYear == event.target.value))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedAthletes: renderedAthletes
            })
        }
    }

    searchCoaches = event => {
        const { coaches } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedCoaches: _.filter(coaches, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedCoaches: coaches
            })
        }
    }

    handleFilter = filterValue => event => {
        this.setState({
            rosterFilterBy: filterValue
        })
    }

    toggleModal = () => {
      this.setState({ isCropperModalOpen: !this.state.isCropperModalOpen })
    }

    getCroppedAttachment = async (attachment) => {
      const { team } = this.state;
      let newTeam = team;
      newTeam.logo = attachment;
      this.setState({ teamLogo: attachment })
      this.setState({ isCropperModalOpen: false });
    }

    handleFileChange = async(event) => {
      console.log('event.target.files', event.target.files)
      const idForS3 = ORGANIZATION_ID + "/" + moment().valueOf();
      this.file = event.target.files[0];
      const fileStructure = `uploads/program/logo/${idForS3}/`;
      const attachment = this.file ? await s3Upload(this.file, fileStructure) : null;

      this.setState({
          isCropperModalOpen: true,
          attachment: attachment,
          fileName: this.file ? this.file.name : ''
      })
    }

    hideSnackBar(){
      setTimeout(() => this.setState({open:false}), 5000)
    }

    toggleModal = () => {
      this.setState({ isCropperModalOpen: !this.state.isCropperModalOpen })
    }

    renderTeamInputs(){
        const { team, teamLogo } = this.state;
        const { classes } = this.props;
        const sports = ['Lacrosse'];
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
            <Grid item style={{paddingTop:5, marginBottom:15, width:100}}>
              {
                this.state.attachment
              ?
                <div style={{
                  background:'url(' + this.state.attachment + ')',
                  width:100,
                  height:100,
                  backgroundPosition:'center',
                  backgroundRepeat:'no-repeat',
                  backgroundSize:'cover',
                  borderStyle:'solid'}}
                >
                </div>
              :
                <div>
                {
                  teamLogo
                ?
                  <div style={{
                    background:'url(' + teamLogo + ')',
                    width:100,
                    height:100,
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    borderStyle:'solid'}}
                  >
                  </div>
                :
                  <div style={{
                    width:100,
                    minWidth:100,
                    height:100,
                    backgroundColor:"#EDEFF0",
                    borderStyle:'dashed'}}
                  >
                    <CameraAltIcon style={{marginTop:35}} />
                  </div>
                }
                </div>
              }

              <div style={{marginTop:15, width:100}}>
                <input id="teamInput" type="file" accept="image/*" ref={(ref)=>this.teamInput=ref} onChange={this.handleFileChange} style={{display:'none'}} />
                <Button
                  variant="contained"
                  className={classes.textButton}
                  style={{width:100}}
                  size="small"
                  onClick={(e=> this.teamInput.click())}
                >
                  Upload
                </Button>
              </div>

            </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="name"
                        margin="normal"
                        label="Team Name"
                        className={classes.textField}
                        onChange={(event) => this.handleChange('name', event.target.value)}
                        value={this.state.name ? this.state.name : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.textField}>
                        <InputLabel htmlFor="sport">Team Sport</InputLabel>
                        <Select
                            input={<Input id="sport" />}
                            style={{ textAlign: 'left' }}
                            onChange={(event) => this.handleChange('sport', event.target.value)}
                            value={this.state.sport ? this.state.sport : ""}
                        >
                            {sports.map(sport => (
                                <MenuItem
                                    key={sport}
                                    value={sport}
                                >
                                    {sport}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        )
    }

    renderAthletes = () => {
        const { filters, searchText, athletes, renderedAthletes, renderedCoaches, coaches, rosterFilterBy, appContext, currentTeam, selectedAthletes, anchorEl, openPopup, selectedFilter } = this.state;
        const { classes } = this.props;
        let filter = [
            { key: 'Graduation Year', value: "graduationYear", children:[...new Set(athletes.filter(item => item.graduationYear).map(item => item.graduationYear).sort((a, b) => b - a))] },
            { key: 'State', value: "state", children:[...new Set(athletes.filter(item => item['state/Province'] || item.state).map(item => item['state/Province'] || item.state).sort())] }

        ]
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <div className={"pageTitleContainer"}>
                        <h1 className={"pageTitle"}>Athletes</h1>
                        <TextField
                            id="search"
                            value={searchText ? searchText : ''}
                            className={"searchTextField"}
                            onChange={this.searchAthletes}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CloseIcon onClick={() => this.setState({searchText: '', renderedAthletes: athletes })} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => { this.handleChange('openPopup', true); this.handleChange('anchorEl', event.currentTarget) }}
                            >
                                <FilterList />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={openPopup}
                                onClose={() => this.handleChange('openPopup', false)}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: 200,
                                    },
                                }}
                            >
                                {filter.map((option, index) => (
                                    <MenuItem key={index} onClick={(event) => this.handleChange('selectedFilter', { ...option, anchorEl: event.currentTarget })}>
                                        {option.key}
                                    </MenuItem>
                                ))}
                            </Menu>
                            {selectedFilter && <Menu
                                id="long-menu"
                                anchorEl={selectedFilter.anchorEl}
                                keepMounted
                                open={true}
                                onClose={() => this.handleChange('selectedFilter', null)}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: 200,
                                    },
                                }}
                            >
                                {selectedFilter.children.map((option, index) => (
                                    <MenuItem key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={filters[selectedFilter.value].indexOf(option) > -1 ? true : false} onChange={() => this.setFilter(selectedFilter.value, option) } value={option} />
                                            }
                                            label={option}
                                        />
                                    </MenuItem>
                                ))}
                            </Menu>}
                        </div>
                    </div>

                    <Grid item xs={12}>
                    {
                        renderedAthletes.length ?
                        <Grid container>
                            <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                                <Grid container className={"content"}>
                                    <Grid item xs={2} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                <strong>Last Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>First Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Email</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} className={"rosterColumnContainer"} onClick={this.handleFilter('graduationYear')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Grad Year</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} className={"rosterColumnContainer"} onClick={this.handleFilter('state/Province')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>State</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                _.orderBy(renderedAthletes, [rosterFilterBy, 'nameLast']).map((athlete, index) => {
                                return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                        <AthleteCard
                                            athlete={athlete}
                                            classes={classes}
                                            appContext={appContext}
                                            currentTeam={currentTeam}
                                            view={() => this.handleView(athlete)}
                                            selected={_.findIndex(selectedAthletes, {id: athlete.id}) > -1}
                                            delete={() => this.handleDelete(athlete)}
                                            history={() => this.handleViewHistory(athlete)}
                                            handleSelect={() => this.handleSelect(athlete)}
                                        />
                                    </Grid>)
                                })
                            }
                        </Grid>
                    :
                        searchText
                    ?
                        <p className={"emptyListText"}>No results...</p>
                    :
                        <p className={"emptyListText"}>Loading Athletes...</p>
                    }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    renderCoaches(){
        const { team, searchText, renderedCoaches, coaches, rosterFilterBy, appContext, currentTeam, selectedAthletes, selectedCoaches } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <div className={"pageTitleContainer"}>
                        <h1 className={"pageTitle"}>Coaches</h1>
                        <TextField
                            id="search"
                            value={searchText ? searchText : ''}
                            className={"searchTextField"}
                            onChange={this.searchCoaches}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CloseIcon onClick={() => this.setState({searchText: '', renderedCoaches: coaches })} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <Grid item xs={12}>
                    {
                        renderedCoaches.length ?
                        <Grid container>
                            <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                                <Grid container className={"content"}>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                <strong>Last Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>First Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Email</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                _.orderBy(renderedCoaches, [rosterFilterBy, 'nameLast']).map((coach, index) => {
                                return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                        <AthleteCard
                                            athlete={coach}
                                            classes={classes}
                                            appContext={appContext}
                                            currentTeam={currentTeam}
                                            selected={_.findIndex(selectedCoaches, {id: coach.id}) > -1}
                                            handleSelect={() => this.handleSelectCoach(coach)}
                                        />
                                    </Grid>)
                                })
                            }
                        </Grid>
                    :
                        searchText
                    ?
                        <p className={"emptyListText"}>No results...</p>
                    :
                        <p className={"emptyListText"}>Loading Coaches...</p>
                    }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { classes, theme, appContext, userContext } = this.props;
        const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
        const { error, message, errorMessage, isLoading, value, athletes, coaches, attachment, fileName, isCropperModalOpen } = this.state;

        return (
        <div className={"newTeamOuterContainer"}>
            <div className={"pageTitleContainer"}>
            <div className={"avatarNameContainer"}>
                <h1 className={"pageTitle"}>New Team</h1>
            </div>
            <ImageCropperModal
                title={"Team Logo"}
                fileName={fileName}
                attachment={attachment}
                close={this.toggleModal}
                open={isCropperModalOpen}
                getCroppedAttachment={this.getCroppedAttachment}
            />
            <Snackbar
                open={this.state.open}
                TransitionComponent={this.state.Transition}
                ContentProps={{
                  'aria-describedby': 'message-id',
                  }}
                message={<span id="message-id">{this.state.message}</span>}
                action={[
                  <IconButton key="close" className={classes.close} onClick={() => this.setState({open:false, message:""})}>
                    <CloseIcon className={classes.icon}/>
                  </IconButton>
                ]}
              />
            {
              value !== 2 ?
            <div className={"titleButtonContainer"}>
            {
              value !== 0 &&
              <Button
                  mini
                  color="default"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({searchText: '', renderedAthletes: athletes, renderedCoaches: coaches })
                    this.setState({value:  value - 1})
                  }}
              >
                  Back
              </Button>
            }
              <Button
                  mini
                  color="primary"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({value:  value + 1})
                  }}
              >
                  Next
              </Button>
              </div>
              :
              <div className={"titleButtonContainer"}>
              {
                value !== 0 &&
                <Button
                    mini
                    color="default"
                    variant="contained"
                    title="View Schedules"
                    className={"styledButton"}
                    onClick={() =>{
                      console.log('changing value')
                      this.setState({searchText: '', renderedAthletes: athletes, renderedCoaches: coaches })
                      this.setState({value:  value - 1})
                    }}
                >
                    Back
                </Button>
              }
              <Button
                  mini
                  color="primary"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={this.handleSubmit}
              >
                  Submit
              </Button>
              </div>
            }

            </div>
            {
              this.state.isLoading ?
              <div style={{textAlign:"center", paddingTop:15, paddingBottom:15, background:"white"}}>
                <CircularProgress variant="indeterminate" size={100} color={"primary"} />
                <h3>We are creating your new team....</h3>
                <p> Note:  Once your team is created, you may need to refresh the page to see it in the menu side bar.</p>
              </div>
              : null
            }
            <SwipeableViews index={value}>
              <div className={"wcsInnerContainer"}>
                  {this.renderTeamInputs()}
              </div>
              <div className={"wcsInnerContainer"}>
                {this.renderAthletes()}
              </div>
              <div className={"wcsInnerContainer"}>
                {this.renderCoaches()}
              </div>
            </SwipeableViews>
        </div>

        );
    }
}


class AthleteCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          athlete: {}
      };
    }
    async componentDidMount(){
      this.setState({athlete: this.props.athlete || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      this.setState({athlete: props.athlete})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.athlete ? `${this.props.athlete.nameFirst} ${this.props.athlete.nameLast}` : "";
        const email = this.props.athlete ? `${this.props.athlete.email}` : "";
        const { athlete } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${selected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={2} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15}}>
                                    {athlete && athlete.nameLast ? athlete.nameLast : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={2} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"}>
                                  {athlete && athlete.nameFirst ? athlete.nameFirst : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={4} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"}>
                                  {athlete && athlete.email ? athlete.email : ""}
                                </p>
                            </div>
                        </Grid>
                          <Grid item xs={2} className={"rosterColumnContainer"} >
                              <div style={{paddingTop:10}}>
                                  <p className={"textContainer"}>
                                      {athlete && athlete.graduationYear ? athlete.graduationYear : ""}
                                  </p>
                              </div>
                          </Grid>
                          <Grid item xs={2} className={"rosterColumnContainer"} >
                              <div style={{paddingTop:10}}>
                                  <p className={"textContainer"}>
                                    {athlete && (athlete['state/Province'] || athlete['state'])? (athlete['state/Province'] || athlete['state']) : ""}
                                  </p>
                              </div>
                          </Grid>
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

TrueLacrosseDirectorCreateTeams.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TrueLacrosseDirectorCreateTeams));
