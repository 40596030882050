import { upliftClient, zoomClient } from '../clients'
import ContextService from '../services/context';
import { enableFullScreenLoader } from './loading';
import { setAppContext } from './session';
import { SERVICES } from '../constants';

export function disconnectService(serviceType, userId) {
    return async dispatch => {
        try{
            dispatch(enableFullScreenLoader(true));
            if(serviceType == SERVICES.ZOOM){
                await zoomClient.unlinkAccount(userId)
            }
            else {
                await upliftClient.unlinkAccount(userId)
            }
            const contextService = new ContextService();
            //Used to update nav data and update service connection status
            const appContext = await contextService.buildAppContext();
            dispatch(setAppContext(appContext));
            dispatch(enableFullScreenLoader(false));
        }
        catch(ex){
            dispatch(enableFullScreenLoader(false));
        }
    }
}