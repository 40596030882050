import React, { Component } from 'react'

import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

import './styles.css'

export default class OptionSelectorItem extends Component {
    constructor(props){
        super(props)
        this.state = { isHovering: false }
    }

    onMouseEnter = () => {
        const { selected } = this.props
        if(!selected) return
        this.setState({ isHovering: true })
    }

    onMouseLeave = () => {
        const { selected } = this.props
        this.setState({ isHovering: false })
    }

    render(){
        const { 
            order,
            option, 
            selected,
            onHandleClick,
        } = this.props

        const { isHovering } = this.state

        return(
            <div className={order > 0 ? 'listItemOuterContainer topBorder' : 'listItemOuterContainer'}>
                <div>
                    <p className={selected && isHovering ? 'listItemPrimaryText hoveringSected' : 'listItemPrimaryText'}>
                        {option.name}
                    </p>
                </div>
                <div className={'addOuterContainer'}>
                    <p className={'listItemSecondaryText'}>{option.category}</p>
                    <div
                        onClick={() => { this.setState({ isHovering: true }); onHandleClick() }}
                        className={selected ? 'addRecipientButtonContainer selectedAddButtonContainer' : 'addRecipientButtonContainer'}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        {selected ? <CancelIcon className={'addIcon'} /> : <AddIcon className={'addIcon'} />}
                        <p className={'addRecipientButtonText'}>
                            {selected && isHovering ? 'Remove' : selected ? 'Selected' : 'Select'}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}