import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let BeatHeartIcon = (props) => (
  <SvgIcon width="33" height="29" viewBox="0 0 33 29" {...props}>
    <path d="M21.0771 16.2255H28.8572L16.1577 28.87L3.46289 16.2255H12.0806L15.1492 13.147V22.171L21.0771 16.2255Z"/>
    <path d="M30.6811 14.2161H20.2375L17.1683 17.294V8.27L11.2403 14.2161H1.64103C-1.08711 10.3451 -0.355796 4.97776 3.40373 1.98159C7.31598 -1.13648 13.0266 -0.50617 16.1584 3.38892C16.3656 3.13103 16.5876 2.88453 16.8224 2.65009C20.366 -0.879938 26.1123 -0.881278 29.6578 2.64607C32.822 5.79496 33.1637 10.6901 30.6811 14.2161Z"/>
  </SvgIcon>
);

export default BeatHeartIcon;