import { getThemeByTenant } from '../themes';
import React from 'react';
import moment from 'moment';
import * as availableIcons from '../components/icons';
import qs from 'qs';

export const mapboxAccessToken = "pk.eyJ1IjoibWF4b25lZGV2IiwiYSI6ImNsM2xvdjVvcTAyNWgzZHFmbG15Nmdybm0ifQ.IEUIKDCJFGm14hs4hDVayg"

export const FileType = Object.freeze({ "VIDEO": 1, "PDF": 2, "IMAGE": 3, "OTHER": 4 });

export const isJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

/**
 * Domain => tenant mappings
 *
 * @param {Object}
 */
export const tenantMap = {
    localhost: 'm1',
    onesoftball: 'osb',
    pgc: 'pgc',
    vnn: 'vnn',
    m1vnn: 'm1vnn',
    wcs: 'wcs',
    spellman: 'spellman',
    truelacrosse: 'truelacrosse',
    grdrive: 'grdrive',
    aoc: 'aoc',
    tph: 'tph',
    usahockey: 'usahockey',
    coachup: 'coachup',
    app: 'm1',
    upward: 'upward',
    kickid: 'kickid',
    se: 'sportsengine',
    shoreline: 'shoreline',
    academyperformance: 'academyperformance',
    ipalliance: 'ipalliance',
    weareacademy: 'weareacademy',
    acesnationconnect: 'acesnationconnect',
    gvn: 'gvn',
    "575volleyball": "575volleyball"
};

export const tenantNameMap = {
    onesoftball: 'One Softball',
    pgc: 'PGC',
    vnn: 'VNN',
    m1vnn: 'M1VNN',
    wcs: 'WCS',
    spellman: 'Spellman',
    truelacrosse: 'True Lacrosse',
    grdrive: 'GRDRIVE',
    aoc: 'AOC',
    tph: 'TPH',
    usahockey: 'USE Hockey',
    coachup: 'COACHUP',
    app: 'M1',
    upward: 'UPWARD',
    kickid: 'KICKID',
    se: 'SportsEngine',
    shoreline: 'SHORELINE',
    academyperformance: 'ACADEMY PERFORMANCE',
    ipalliance: 'IP ALLIANCE',
    weareacademy: 'WE ARE ACADEMY',
    acesnationconnect: 'ACES NATION CONNECT',
    gvn: 'GVN',
    "575volleyball": "575volleyball"
};



export function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'mov':
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
            return true;
    }
    return false;
}

export function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
        case 'ico':
            return true;
    }
    return false;
}

export function isPDF(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'pdf':
            return true;
    }
    return false;
}

export function getExtension(filename) {
    filename = (filename||"").toLowerCase();
    let parts = filename.split('.');
    //This may contains something like "movie.mp4?skip=1". So we need to remove that to get ext
    let unformattedFileExt = parts[parts.length - 1]||"";
    let ext = (unformattedFileExt||"").replace(/[?#/].*/g, "");
    return ext;
}

export function getTeamLogo(team) {
    const logo = getThemeByTenant().image.logo;
    const teamlogo = (!!team ? (team.tenant == 'vnn' || team.tenant == 'm1vnn') && team.organization && team.organization.logoUrl ? team.organization.logoUrl
        : team.logo && team.logo.includes('https') ? team.logo
            : team.logo ? `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${team.legacyId ? team.legacyId : team.id}/${team.logo}`
                : null: null)
    return teamlogo || logo
}

export function getActivityThumbnail(activity) {
  let thumbnail = null;
  if (!!activity) {
    if (activity.thumbnail || activity.customThumbnailPath) {
      thumbnail = activity.customThumbnailPath || activity.thumbnail;
    }
    if (!thumbnail && activity.videoType === "youtube") {
      thumbnail = `http://img.youtube.com/vi/${activity.videoId}/0.jpg`;
    }
    if (
      !thumbnail &&
      !!activity.attachment &&
      !!activity.attachment.url &&
      (activity.attachment.url.includes("jpg") || activity.attachment.url.includes("png"))
    ) {
      thumbnail = activity.attachment.url;
    }
  }
  return thumbnail;
}

export function getActivityBackground(teamLogo, thumbnail) {
    return `url(${thumbnail ? thumbnail : teamLogo ? teamLogo : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAABGdBTUEAALGPC/xhBQAABD9JREFUeAHtmvtLFFEUx7/b2roiBj3Mgp5IagbVTwVRv/RDD3oQ9KAsgh5EqWVaUvRLVARF5CvLUPqpJ5WkSS/rT6jYrYzCCHqY5iMi3HIN2/YuTLit13On3Tuz1VmQO3O+Z8495+OZuzOz45g8OSMA/kgJDJEqLIQIMCCiERgQAyIIEDJ3EAMiCBAydxADIggQMncQAyIIEDJ3EAMiCBAydxADIggQMncQAyIIEDJ3EAEogdDD5KysTDidzjDbYDvNza/Q29s7mEvUmsv1A+Mmqs/R1+fAm9eJyvM6zDxy9XgeYtiwFOXgHs8TbNu2A52dncrHmHEcMeo79h3+gPQMv/JhXz47sXlVurK/1lNs5szpqK+vRXb2VOWEVB2nZH3D8TNvTcFRjd3fTysgMdHYsWNw7dplLFq0oP+8UW3Pnf8Fh0vfY/iIvqjiqBysHZBIIikpCadPVyAvb4dKToP4BLBuUyd2H2jD0KHW/BhjCSBRscPhwJ49u1FWdhIul2sQCANLie4fKD7YipXrPw3soMlqGSAj/+XLl+LKlYtITU01TOQ4MvU7jpa/w+x53aRvrB0sByQKEIt3Xd11TJuWrVTPrDk+TEpX/6ZSCqroZAsgkZtYvK9evYTFixcqpmqPm22ARLli8a6sLEd+fm5Y9WK92r+/GGlpo8PsduzYCkgULGAUFRWgvLwktHgnJyejuroqeIG51Q4eEXOautWIODqGhmXLlmDChPFwu93IzMyIYeToQsUNIFHGjBnTo6tGw9G2n2IaaoppyLjqIFllL5+7cb5mVITsHBJAzpauCHssDX8FoNfNboi/3z8JCf85oJSUFHz9+u13LvD7/dqfMxmTau+gwsK9OHbsKBIT1R9SGck1Nt42NsPGiorK4D3dqTCbrh3ti3R9fQPWrt2Ajo4OXTVojasdkMje632CFStWoanpedTFeDxeXLhwOeo4qgEsASSSaW1tw+rV63D3bqNqbhF+dXU3Q92o6xFuxIRBg2WAxOQ9PT3Izd0ZvP+qGigXqS0QCODEiZPBW5JiyxZnIxlLARmTlpSUoaCgKPRtZNhko8/nw/bt+aiqqpa5aLXbAkhU1NBwK3i6rEd7e7u0wJaWltBpef/+A6mPbsE2QKIwr/dpaPF+9qwpos5Hjx6HtBcvXkZoVhpsBSQKbWv7iDVrcnDnzr1fddfW3kBOzkZ0dVn7/PlXAv02tF8o9ptLuikW77y8XSgs3IXubh9qas5Jfa0W4gKQUXRpaYWxGTej7adY3JCQJMKAJGAMMwMySEhGBiQBY5gZkEFCMjIgCRjDzIAMEpLR1HXQoUNH/ujNDMncUZv7gq8HnS1JMxWn1+8w5W/qFTxTkf8RZz7FiH8kA2JABAFC5g5iQAQBQuYOYkAEAULmDmJABAFC5g5iQAQBQuYOYkAEAULmDmJABAFC5g5iQAQBQv4JE4/n9IwzJCoAAAAASUVORK5CYII='})`
}

export async function fetchVimeoVideoThumbnail(activity) {
    return fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${activity.videoId}&width=480&height=360`, {
        method: 'get',
        cache: 'no-cache',
        mode: 'cors'
    }).then(resp => {
        return resp.json()
    }).then(res => { return res.thumbnail_url });
}

export async function fetchWistiaVideoThumbnail(activity) {
    return fetch(`https://fast.wistia.com/oembed?url=https://fast.wistia.com/embed/iframe/${activity.videoId}`, {
        method: 'get',
        cache: 'no-cache',
        mode: 'cors'
    }).then(resp => {
        return resp.json()
    }).then(res => { return res.thumbnail_url });
}

/**
 * Returns the tenant matching in the current URL
 *
 * @return {String}
 */
export const getTenant = () => {
    const { location: { hostname } } = window;
    let defaultTenant = hostname.toLowerCase().split('.')[0]
    const tenant = tenantMap[defaultTenant];
    // return tenant || tenantMap.kickid;
    // return tenant || tenantMap.se;
    // return tenant || tenantMap.spellman;
    return tenant || defaultTenant;
};

export const getTenantSubdomain = () => {
    const { location: { hostname } } = window;
    let subdomain = hostname.toLowerCase().split('.')[0];
    return subdomain === 'localhost' ? 'app' : subdomain;
};

export function getTimezoneName() {
    var sone = moment.tz.guess();
    var timezone = moment.tz(sone).zoneAbbr()
    return timezone;
}

if (!String.prototype.decodeHTML) {
    String.prototype.removeHTMLString = function () {
        return this.replace(/&apos;/g, "'")
            .replace(/&quot;/g, '"')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&amp;/g, '&')
            .replace(/&nbsp;/g, " ")
            .replace(/<[^>]*>?/gm, '');
    };
}

export function getIconByName(name, props){
    let iconName = name + "Icon";
    let Icon = null;
    const allIcons = Object.keys(availableIcons);
    if (allIcons.includes(iconName)) {
        Icon = availableIcons[iconName];
    }
    else {
        Icon = availableIcons["AthletesIcon"];
    }
    return <Icon {...props} />
}

export function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}


export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function getTimeObject(time){
    let minutes, seconds, millisec = 0;
    //To be fixed in future. We shouldn't have these many logic for time.
    let colonSeparated = String(time||"").split(":");
    let dotSeparated = String(time||"").split(".");
    //Used for score
    if (dotSeparated.length == 2 && !colonSeparated.length) {
        millisec = dotSeparated[1];
        minutes = Number((dotSeparated[0] / 60).toFixed(0)) || 0;
        seconds = dotSeparated[0] - (minutes * 60);
    }
    //Used for athlete result
    else if(colonSeparated.length == 3 || colonSeparated.length == 2){
        if(colonSeparated.length == 2){
            let milliAndSeconds = colonSeparated[1].split(".");
            seconds = Number(milliAndSeconds[0] || 0);
            millisec =  Number(_.padEnd(milliAndSeconds[1]|| 0, 3, '0'));
        }
        else {
            seconds = Number(colonSeparated[1] || 0);
            millisec =  Number(_.padEnd(colonSeparated[2]|| 0, 3, '0'));
        }
        minutes = Number(colonSeparated[0] || 0);
    }
    //Time is saved in millisecond
    else if(typeof time === "number"){
        //Additional handling for old time score calculation logic
        minutes = Math.floor(time / 60000);
        seconds = Number(Math.floor(((time % 60000) / 1000))) || 0;
        millisec = Number(Math.floor((time - (minutes * 60000 + seconds * 1000)))) || 0;
    }
    return {minutes, seconds, millisec}
}

export function getThemeMode(rgbValue){
    var rgbValue = hexToRgb(rgbValue)
    var color = Math.round(((parseInt(rgbValue[0]) * 299) +
    (parseInt(rgbValue[1]) * 587) +
    (parseInt(rgbValue[2]) * 114)) / 1000);
    var isDarkMode = (color > 125) ? false : true;
    return isDarkMode;
  }


  // //Convert hex color to rgb
function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = (hex||"").replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ?
      [parseInt(result[1], 16),parseInt(result[2], 16),(result[3], 16)]
     : null;
}

export async function downloadFileFromUrl(url, filename){
    try{
        let hiddenElement = document.createElement('a');
        let data = await fetch(url);
        let blob = await data.blob();
        const blobUrl = URL.createObjectURL(blob);
        hiddenElement.href = blobUrl;
        hiddenElement.download = filename;
        hiddenElement.target = '_self';
        document.body.appendChild(hiddenElement);
        hiddenElement.click();
        document.body.removeChild(hiddenElement);
    }
    catch(e){
        console.log(e)
    }
}

export function addhttp(url) {
    if (url && !/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
}
