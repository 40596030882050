import React from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import PageWrapper from "../../../containers/nav/pageWrapper";
import TableComponent from "./Table";
import MenuItem from "@material-ui/core/MenuItem";

import { SearchIcon, CalendarIcon } from "../icons";
import BodyWrapper from "../../../components/bodyWrapper";
import FloatingSelect from "../../../components/floatingSelect";
import { ROUTE_PATH } from "../../../routeUtil";
import HabitAdherenceModal from "./HabitAdherenceModal";

const styles = () => ({
  tableCurved: {
    borderCollapse: "separate",
    width: "100%",
    borderSpacing: "0 10px",
    "& tr": {
      height: "70px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 30px rgb(0 0 0 / 15%)",
      borderRadius: "15px",
      "& td": {
        "&:first-child": {
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
        "&:last-child": {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        },
      },
      "& th": {
        "&:first-child": {
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
        "&:last-child": {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        },
      },
    },
  },
  tableRow: {
    // height: '70px',
    // background: '#FFFFFF',
    // boxShadow: '0px 0px 30px rgb(0 0 0 / 15%)',
    // borderRadius: '15px'
  },
  filtersSection: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "10px",
  },
  searchBox: {
    background: "#FFFFFF",
    /* Gray / 20% Gray */

    border: "1px solid #DDE2E5",
    boxSizing: "border-box",
    borderRadius: "8px",
    float: "left",
    width: "315px",
    height: "48px",
    padding: "9px 20px",
    display: "flex",
    alignItems: "center",
    "& input": {
      overflow: "visible",
      background: "transparent",
      border: 0,
      color: "#222127",
      outline: "none",
      fontSize: "14px",
      fontWeight: "400",
      width: "100%",
      lineHeight: "21px",
    },
  },
  filterDropdownSection: {
    width: "244px",
    height: "48px",
    borderRadius: "8px",
    marginLeft: "10px",
  },
  myAtheleteSection: {
    display: "flex",
    fontSize: "33px",
    justifyContent: "flex-start",
    lineHeight: "50px",
    fontWeight: "500",
  },
  calendarSection: {
    display: "flex",
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: "400",
    alignItems: "center",
  },
  hrvColumn: {
    width: "83px",
    height: "25px",
    background: "#FF0A45",
    opacity: "0.14",
    borderRadius: "100px",
  },
  redTriangle: {
    width: "12px",
    height: "8.7px",
    background: "#FF0A45",
    borderRadius: "1px",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
});

const Main = (props) => {
  const {
    classes,
    mainColumns,
    athletes,
    handleSearch,
    groups,
    selectedGroup,
    handleGroupChange,
    selectedAthlete,
    pageSize,
    currentPage,
    totalCount,
    handlePageChange,
    loadingChildComponent,
    loadingChildMessage,
    showHabitAdherenceModal,
    selectedControllable,
    handleChange,
    searchTerm,
  } = props;
  const groupsList = [{ id: "allGroups", name: "All Groups" }, ...groups];
  return (
    <PageWrapper title={"My Athletes"} {...props}>
      <BodyWrapper
        removePadding={true}
        isLoading={loadingChildComponent}
        showLoadingChild={true}
        loadingMessage1={loadingChildMessage}
        enableSubContainerStyle={false}
      >
        <div>
          <div className="d-flex justify-content-center mb-3">
            <div className={`${classes.calendarSection}`}>
              <CalendarIcon className="mr-1" />
              Today, {`${moment().format("MMM Do")}`}
            </div>
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.searchBox}>
              <SearchIcon />
              <input
                type="text"
                value={searchTerm}
                disabled={selectedGroup !== "allGroups"}
                placeholder=" Search Athletes by Name..."
                onChange={(e) => handleChange("searchTerm", e.target.value)}
                onKeyPress={(e) => (e.charCode === 13 ? handleSearch() : null)}
              />
            </div>
            <div className={classes.filterDropdownSection}>
              <FloatingSelect
                overrideContainerCss={{ marginLeft: 15 }}
                label="Groups"
                value={selectedGroup}
                onChange={handleGroupChange}
              >
                {groupsList.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </FloatingSelect>
            </div>
          </div>
          <TableComponent
            columns={mainColumns}
            data={athletes}
            pageSize={pageSize}
            totalCount={totalCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleClick={() => {}}
          />
          {showHabitAdherenceModal ? (
            <HabitAdherenceModal
              open={true}
              selectedAthlete={selectedAthlete}
              selectedControllable={selectedControllable}
              onClose={() => handleChange("showHabitAdherenceModal", false)}
              backgroundColor={"#F0F0F0"}
            />
          ) : null}
        </div>
      </BodyWrapper>
    </PageWrapper>
  );
};

export default withStyles(styles)(Main);
