import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { getIconByName } from '../../utils/commonUtil';
import classnames from 'classnames';
const styles = {
    card: {
        background: "#F8F8F8",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px",
        height: "120px",
        minWidth: "173px",
        marginLeft: "20px",
        marginBottom: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:"column",
        padding:"0 10px",
        "& svg": {
            height: "48px",
            width: "48px",
            color: "#1354F9"
          }
      },
      overViewHead: {
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "14px",
          lineHeight: "120%",
          textAlign: "center",
          textTransform: "uppercase",
          color: "#A0A7AB",
          marginBottom: "4px",
      },      
      overDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      info: {
        fontSize: "48px",
        lineHeight: "100%",
        color: "#3C3F40",
        marginLeft: "4px",
      }     
}

const MetricCard = ({ label, icon, value, classes, overrideClass, onClick }) => {
    return(<div className={classnames(classes.card, overrideClass)} onClick={onClick}>
        <div className={classes.overViewHead}>{label}</div>
        <div className={classes.overDetail}>
            {getIconByName(icon)}
            <div className={classes.info}>{value}</div>
        </div>
    </div>)
}

export default withStyles(styles)(MetricCard)