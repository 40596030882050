import { QUESTION_TYPES } from '../constants';

export const getQuestionInputInfo = function (questionType, rangeValues=[]){
    let inputInfo = questionType;
    if(questionType == QUESTION_TYPES.FREE_FORM){
        inputInfo = `Text Input`
    }
    else if(questionType == QUESTION_TYPES.RANGE && rangeValues.length){
        inputInfo = `Range (${rangeValues[0]||0}-${rangeValues[rangeValues.length -1]||0})`
    }
    else if(questionType == QUESTION_TYPES.SINGLE_CHOICE && rangeValues.length){
        inputInfo = `${rangeValues[0]||0}/${rangeValues[rangeValues.length -1]||0}`
    }
    else if(questionType == QUESTION_TYPES.YES_NO){
        inputInfo = `YES/NO`
    }
    return (inputInfo||"").replace("_"," ").captialize();
}