import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PageWrapper from '../nav/pageWrapper';
import BodyWrapper from "../../components/bodyWrapper";
import { getLocalizedString } from '../../utils/locale-utils';
import ThemeButton from '../../components/themeButton';
import { connect } from 'react-redux';
import { upliftClient } from '../../clients'
import { disconnectService } from '../../actions/externalService';
import { ROUTE_PATH } from '../../routeUtil';
import { SERVICES } from '../../constants'

const styles = {
    head: {
        fontSize: "26px",
        lineHeight: "120%",
        marginBottom: "30px"
    },
    name: {
        fontSize: "40px",
        marginBottom: "30px",
        fontWeight: 500
    },
    infoTitle: {
        color: "#A0A7AB",
        marginRight: "7px",
        fontSize: "18px"
    },
    infoDesc:{
        fontSize: "18px"
    },
    subInfo:{
        marginBottom: "20px",
        lineHeight: "120%"
    }
};

class UpliftConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userUpliftData: {},
            isLoading: true
        }
    }

    componentDidMount = () => {
        let { currentUser } = this.props;
        upliftClient.getLinkedAccountByUserId(currentUser.id).then((response) => {
            let { data } = response;
            this.setState({ userUpliftData: data })
        }).finally(() => {
            this.setState({ isLoading: false });
        })
    }

    disconnectService = () => {
        let { disconnectService, currentUser } = this.props;
        disconnectService(SERVICES.UPLIFT, currentUser.id);
        history.replace(ROUTE_PATH.LIVE_TRAINING_STARTING);
    }

    render() {
        const { classes, loadingMessage } = this.props;
        const { isLoading, userUpliftData } = this.state;
        return (
            <PageWrapper title={getLocalizedString("Live Training")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <BodyWrapper enableSubContainerStyle={true} isLoading={isLoading}>
                    <div className={classnames(classes.head, "d-flex justify-content-between")}>
                        <div className={classes.headTitle}>Uplift Configuration</div>
                        <div>
                            <ThemeButton
                                onClick={this.disconnectService}
                                width={"127px"}
                                textColor={"#ffffff"}
                                color={"#F93F3F"}
                                height={"44px"}
                                theme="small"
                                text={"Disconnect"}
                            /></div>
                    </div>

                    <div className={classes.info}>
                        <div className={classes.name}>
                            {userUpliftData.name}
                        </div>
                        <div className={classnames(classes.subInfo, "d-flex")}>
                            <div className={classes.infoTitle}>User Email:</div>
                            <div className={classes.infoDesc}>{userUpliftData.email}</div>
                        </div>                  
                    </div>
                </BodyWrapper>
            </PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        currentUser: userContext.user
    };
}

export default withStyles(styles)(connect(mapStateToProps, { disconnectService })(UpliftConfiguration));
