import React from 'react';
import PropTypes from 'prop-types';
import { Cancel } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import FloatingSelectWithCustomChild from '../../../../components/floatingSelectWithCustomChild';
import FloatingInput from '../../../../components/floatingInput';
import { getLocalizedString } from '../../../../utils/locale-utils';
import { isValidEmail } from '../../../../utils/validation-utils';
import { PrimaryButton } from '../../../../components/v2/buttons';

import './index.scss';

const getLabelByKey = (key) => {
    const label = getLocalizedString(key);
    if (label !== key) {
        return label;
    }
    return key.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

/**
 * Renders an email notification setting row. Pulls event types from the localized list, but if unable to be found, the
 * component attempts to display a user-friendly string for the provided key.
 *
 * @example `NEW_EVENT_TYPE` = `New Event Type`
 */
const Component = (props) => {
    const {
        loading,
        eventType,
        emailAddress,
        availableEventTypes,
        onEventTypeChange,
        onEmailAddressChange,
        onTestClick,
        onDeleteClick
    } = props;

    const testDisabled = (!emailAddress || !eventType || !isValidEmail(emailAddress));
    const eventTypes = [ ...new Set(availableEventTypes.concat(eventType)) ].sort().filter(type => !!type).map(type => ({ displayValue: getLabelByKey(type), value: type }));

    return (
        <div className={'m1-email-notification-setting-row'}>
            <div className={'row align-items-center'}>
                <div className={'col-md notification-setting-field-container'}>
                    <FloatingInput
                        label={'Email Address'}
                        value={emailAddress}
                        overrideContainerCss={'email-address-input-field'}
                        onChange={({ target: { value } }) => onEmailAddressChange(value)}
                    />
                </div>
                <div className={'col-md notification-setting-field-container'}>
                    <FloatingSelectWithCustomChild
                        showDropDownBorder
                        dropDownWidth={'100%'}
                        label={'Event'}
                        onChange={onEventTypeChange}
                        dropDownLabel={'Event'}
                        value={eventType}
                        menuItemData={eventTypes}
                    />
                </div>
                <div className={'col-md-auto buttons-container'}>
                    {loading ? (
                        <CircularProgress size={30} />
                    ) : (
                        <div className={'row no-gutters align-items-center'}>
                            <div className={'col'}>
                                <PrimaryButton onClick={onTestClick} disabled={testDisabled}>
                                    Test
                                </PrimaryButton>
                            </div>
                            <div className={'col-auto'}>
                                <div className={'cancel-button-container'} onClick={onDeleteClick}>
                                    <Cancel />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

Component.propTypes = {

    /**
     * The currently selected event type
     */
    eventType: PropTypes.string,

    /**
     * The email address for the event type
     */
    emailAddress: PropTypes.string,

    /**
     * Whether or not the event is in the process of being tested
     */
    loading: PropTypes.bool,

    /**
     * List of available event types the user can select
     */
    availableEventTypes: PropTypes.arrayOf(PropTypes.string),

    /**
     * Action to take when the test button is enabled and has been clicked
     */
    onTestClick: PropTypes.func,

    /**
     * Action to take when the delete button has been clicked
     */
    onDeleteClick: PropTypes.func,

    /**
     * Action to take when the event type dropdown selector has been changed
     */
    onEventTypeChange: PropTypes.func,

    /**
     * Action to take when the email address field has been changed
     */
    onEmailAddressChange: PropTypes.func
};

Component.defaultProps = {
    loading: false,
    availableEventTypes: [],
    onTestClick: () => {},
    onDeleteClick: () => {},
    onEventTypeChange: (value) => {},
    onEmailAddressChange: (value) => {}
};

export default Component;