export default class StateLoader {

  loadState() {
      try {
        let serializedState = localStorage.getItem("m1state");

        if (serializedState === null) {
          return undefined;
        }
        return JSON.parse(serializedState);
      }
      catch (err) {
        return undefined;
      }
  }

  saveState(state = {}) {
    try {

      // Auto persisting every reducer is a terrible practice. Make it an opt-in temporarily until we refactor this mess...
      const whiteListedState = {
        chat: { ...state.chat },
        session: { ...state.session },
        message: { ...state.message },
        sidebar: { ...state.sidebar }
      };
      let serializedState = JSON.stringify(whiteListedState);
      localStorage.setItem("m1state", serializedState);
    }
    catch (err) {
    }
  }
}
