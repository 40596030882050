export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "programax-videos-production"
    },
    s3Legacy: {
        REGION: "us-east-1",
        BUCKET: "programax-videos-production"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_WBqB66Yl0",
        APP_CLIENT_ID: "25af1tmtmj6eufa1257gi23v7m",
        IDENTITY_POOL_ID: "us-east-1:0a19b97b-b8b1-4b79-bdf4-d9a15bb73737"
    }
}
