import React, { Component } from 'react'
import './styles.css'
import CloseIcon from '@material-ui/icons/Close'
import { ClickAwayListener } from '@material-ui/core'

export default class ToastMessage extends Component {
    render(){
        const { open, close, text, action, actionText } = this.props
        return(
            open ?
            <ClickAwayListener onClickAway={close}>
                <div className={'toastMessageOuterContainer'}>
                    <p>{text}</p>
                    <p onClick={action} className={'actionText'}>{actionText}</p>
                    <CloseIcon onClick={close} style={{ color: 'grey', cursor: 'pointer' }} />
                </div>
            </ClickAwayListener>
            :
            null
        )
    }
}