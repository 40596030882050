import React from "react";
import PageWrapper from "../../../containers/nav/pageWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import {
  Grid,
  List,
  Radio,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ConfirmationPopup from "./ConfirmationPopup";
import ShadowContainer from "../../../components/shadowContainer";
import { PrimaryButton, BorderedButton } from "../../../components/v2/buttons";
import { colors } from "../../../components/v2/utils/theme-utils";
import CancelCalendar from "../icons/CancelCalendar";

const viewOptions = {
  confirmationPopup: "confirmationPopup",
};

const styles = (theme) => ({
  container: {
    padding: 25,
  },
  showContainer: {
    borderRadius: 5,
    padding: 35,
  },
  nameTextField: {
    height: 49,
    fontSize: 16,
  },
  nameFocused: {
    transform: "translate(0, 1.5px) scale(0.50);",
    top: "8px",
  },
  nameInput: {
    fontSize: 16,
  },
  nameFieldLabel: {
    fontSize: 16,
  },
  addTeamBtn: {
    width: "20%",
    height: 52,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.isDarkMode ? "#ffffff" : "black",
  },
  tagContainer: {
    width: "100%",
    padding: "10px 0px",
    flexWrap: "wrap",
    overflow: "visible",
  },
  createTag: {
    color: `${colors.PRIMARY} !important`,
    fontSize: 12,
    cursor: "pointer",
  },
});

const CancelEvent = (props) => {
  const {
    event,
    classes,
    allEvent,
    handleBack,
    currentView,
    removeEvent,
    handleChange,
    isRecurringEvent,
    handleConfirmation,
    sendEmailNotifications,
  } = props;
  
  let participants = {};
  (event?.teams || []).forEach((item) => {
    Object.values(item.participants.participantsById || {}).forEach(
      (participant) => {
        participants[participant.id] = true;
      }
    );
    Object.values((item.participants || {}).groupsById || {}).forEach((group) =>
      Object.values(group.participantsById || {}).forEach((participant) => {
        participants[participant.id] = true;
      })
    );
  });
  let total = Object.keys(participants).length;
  return (
    <PageWrapper {...props} title={"Cancel Event"}>
      <BodyWrapper
        removePadding={true}
        isLoading={false}
        showLoadingChild={false}
        loadingMessage1={""}
        enableSubContainerStyle={false}
      >
        <ShadowContainer className={classes.showContainer}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <List
                style={{ marginBottom: 20 }}
                subheader={
                  <p style={{ margin: 0, fontWeight: 600 }}>
                    {isRecurringEvent
                      ? "This is a recurrent event"
                      : "This is a non-recurring event"}
                  </p>
                }
                component="div"
              >
                <ListItem>
                  <ListItemText primary={"Cancel this event"} />
                  <ListItemSecondaryAction onClick={() => {}}>
                    <Radio
                      color="primary"
                      checked={!allEvent}
                      onChange={() => {
                        handleChange("allEvent", false);
                        handleChange("removeEvent", true);
                      }}
                      value="true"
                      name="removeEvent"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                {isRecurringEvent ? (
                  <ListItem>
                    <ListItemText primary={"Cancel all events"} />
                    <ListItemSecondaryAction onClick={() => {}}>
                      <Radio
                        color="primary"
                        checked={allEvent}
                        onChange={() => {
                          handleChange("allEvent", true);
                          handleChange("removeEvent", false);
                        }}
                        value="true"
                        name="allEvent"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : null}
              </List>
              <List
                subheader={
                  <p style={{ margin: 0, fontWeight: 600 }}>
                    Timeline settings
                  </p>
                }
                component="div"
              >
                <ListItem>
                  <ListItemText primary={"Remove event from timeline"} />
                  <ListItemSecondaryAction onClick={() => {}}>
                    <Radio
                      color="primary"
                      checked={removeEvent}
                      onChange={(e) =>
                        handleChange("removeEvent", e.target.value === "true")
                      }
                      value="true"
                      name="removeEvent"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                {allEvent || !isRecurringEvent ? (
                  <ListItem>
                    <ListItemText primary={"Keep event visible "} />
                    <ListItemSecondaryAction onClick={() => {}}>
                      <Radio
                        color="primary"
                        checked={!removeEvent}
                        onChange={(e) =>
                          handleChange("removeEvent", e.target.value === "true")
                        }
                        value="false"
                        name="removeEvent"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : null}
              </List>
            </Grid>
            <Grid item xs={12} className={"d-flex justify-content-center"}>
              <PrimaryButton
                onClick={handleConfirmation}
                style={{ height: 52, marginRight: 20 }}
              >
                Confirm Cancelation
              </PrimaryButton>
              <BorderedButton onClick={handleBack} style={{ height: 49 }}>
                Cancel
              </BorderedButton>
            </Grid>
          </Grid>
        </ShadowContainer>
        {currentView === viewOptions.confirmationPopup ? (
          <ConfirmationPopup
            handleConfirmation={sendEmailNotifications}
            handleClose={handleBack}
            currentAction={removeEvent ? "delete" : "cancel"}
            eventDescription={
              removeEvent
                ? `You have deleted an event for ${total} participants`
                : `You have cancelled an event for ${total} participants`
            }
            icon={CancelCalendar}
          />
        ) : null}
      </BodyWrapper>
    </PageWrapper>
  );
};
export default withStyles(styles)(CancelEvent);
