import React, { Component } from "react";
import "./director.css";
import { withStyles } from '@material-ui/core/styles';
import BodyWrapper from "../../components/bodyWrapper";
import PageWrapper from '../nav/pageWrapper';
import { API } from "aws-amplify";
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from "lodash"
import { CSVLink, CSVDownload } from "react-csv";
import { ROUTE_PATH } from "../../routeUtil";
import { userClient, organizationClient } from '../../clients';
import SearchInput from '../../components/searchInput';
import TabularDesign from '../../components/tabularDesign';
import { AirplaneIcon } from '../../components/icons'
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete";
import AccountCircle from '@material-ui/icons/AccountCircle';

const styles = theme => ({
  coachList: {
    "& tr:hover": {
      background: "#F8F8F8"
    }
  },
  td: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#0B0B0B",
    textAlign: "left"
  },
  tableHead: {
    height: "36px !important"
  },
  topSearchSection: {
    marginBottom: "20px"
  },
  searchBox: {
    height: "44px",
    width: "300px"
  },
  sendInviteContainer: {
    width: "136px",
    color: "#1354F9",
    textAlign: "right",
    cursor: "pointer",
    "& svg": {
      fill: "#1354F9",
      width: "16px",
      height: "16px",
      marginRight: "4px"
    }
  },
  serviceIcon: {
    marginLeft:"10px",
    "& svg": {
      cursor: "pointer",
      width: "20px",
      height: "20px",
      fill: "#DDE2E5"
    }
  },
  editUserContainer: {
    width: "100px",
    textAlign: "right !important",
    "& svg": {
      cursor: "pointer",
      width: "20px",
      height: "20px",
      fill: "#DDE2E5"
    },
    "& span:first-child": {
      marginRight: "12px"
    }
  },
  statusBox: {
    padding: "4px 8px",
    fontWeight: "bold",
    fontSize: "11px",
    lineHeight: "12px",
    color: "#FFFFFF",
    borderRadius: "20px"
  },
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    "& svg": {
      fill: "#A0A7AB"
    },
    "& img":{
      maxWidth:"100%",
      maxHeight:"100%"
    }
  },
  lastNameSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "nowrap"
  },
  highlightColor: {
    color: "#1354F9"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  btn:{
    background:"#1354F9"
  },
  pageInfo:{
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "right",
    color: "#5B6062",
    marginLeft: "auto"
  }
});
class DirectorCoaches extends Component {
  constructor(props) {
    super(props);
    this.typingTimeout = null;
    this.state = {
      organization: {},
      csvLink: {},
      isPageLoading: true,
      loadingMessage: "hang tight while we grab your coaches...",
      coachPerPageLimit: 20,
      searchTerm: "",
      currentPage: 0,
      coachFields: [
        {
          displayName: "Last Name",
          value: "lastName"
        },
        {
          displayName: "First Name",
          value: "firstName"
        },
        {
          displayName: "Phone Number",
          value: "phoneNumber"
        }
      ],
      coaches:[]
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {      
      const [organization, csvLink] = await Promise.all([this.getOrganization(), this.getCsvLink(), this.fetchCoaches()]);
      this.setState({
        organization,
        csvLink
      });
    }
    catch (e) {
      console.log(e)
    }
    this.setState({
      isPageLoading: false
    });
  }

  getCsvLink() {
    const { currentTeam } = this.props;
    return API.get('organizations', `/organizations/${currentTeam.organizationId}/coachCodes`)
  }

  getOrganization() {
    const { currentTeam } = this.props;
    return API.get('organizations', `/organizations/${currentTeam.organizationId}`)
  }

  fetchCoaches = async (page = null) => {
    let { currentTeam } = this.props;
    let { currentPage, coachPerPageLimit, searchTerm, originalCount } = this.state;
    if (page !== null) {
      this.setState({ isLoading: true, showLoadingChild: true });
    }
    let queryParams = {
      "organizations.org-roles.keyword": currentTeam.organizationId + "-COACH",
      limit: coachPerPageLimit,
      offset: (page !== null ? page : currentPage) * coachPerPageLimit,
      fields:"firstName,lastName,phoneNumber"
    };
    if (searchTerm) {
      queryParams.q = searchTerm;
    }
    try {
      let responseData;
      let { data } = await userClient.searchUsers(queryParams);
      responseData = data;
      let { total: coachesCount, results: coaches } = responseData;
      coaches.forEach(element => {
        element.statusInfo = this.getStatus((element.teams||[]).length);
        element.teamsCount = (element.teams||[]).length;
      });
      this.setState({ coachesCount, originalCount:searchTerm? originalCount: coachesCount, coaches, currentPage: page, totalPages: Math.ceil(coachesCount / coachPerPageLimit) });
    }
    catch (e) {
      console.log(e)
    }
    this.setState({ isLoading: false, loadingMessage: "", showLoadingChild: false })
  }


  getStatus = (teamsCount) => {
    if(teamsCount > 0){
      return { name: "ACTIVE", color: "#27AE60" }
    }
    return { name: "INACTIVE", color: "#F93F3F" }
  }

  setChildRef = (ref) => {
    this.paginationRef = ref;
  }

  editUser = async (user) => {
    this.props.history.push(ROUTE_PATH.PROFILE, {user, role: 'coach'})
  }

  changeCurrentPage = (page) => {
    if (!!this.paginationRef && !!this.paginationRef.scrollTo) {
      this.paginationRef.scrollTo(0, 0)
    }
    this.fetchCoaches(page - 1);
  }

  handleSearch = (searchTerm, timeOut = 0) => {    
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.setState({
      searchTerm
    }, () => {
      this.typingTimeout = setTimeout(() => {
        this.fetchCoaches(0);
      }, (timeOut || 1000))
    });
  }

  deleteUser = async (user) => {
    const { currentTeam, enableFullScreenLoader } = this.props;
    if (window.confirm('Are you sure you wish to delete this coach?')) {
      try{
        enableFullScreenLoader(true, "Deleting...");
        await organizationClient.deleteUserFromOrganization(currentTeam.organizationId, user.id);
        this.fetchCoaches(0);
      }
      catch(e){
        console.log(e);
      }
      enableFullScreenLoader(false);
    }
  }

  sendMessage = (user) => {
    this.props.history.push(ROUTE_PATH.MESSAGES, { selectedUser: user, userType:"Coach"})
  }

  render() {
    const { history, classes, tenant } = this.props;
    const { isPageLoading, csvLink, organization, loadingMessage, showLoadingChild, coachFields, coaches, searchTerm, currentPage, totalPages, coachesCount, originalCount } = this.state;
    return (
      <PageWrapper setChildRef={this.setChildRef} title="Coaches" isPageLoading={isPageLoading} loadingMessage={loadingMessage} removePadding={true} {...this.props}>
        <div className={classes.pageContainer}>
          <BodyWrapper isLoading={showLoadingChild} showLoadingChild={true} loadingMessage1={loadingMessage} >
          <div className={classnames(classes.topSearchSection, "d-flex align-items-center justify-content-between w-100")}>
              <SearchInput placeholder={"Search Coaches"} customClass={classes.searchBox} searchTerm={searchTerm} handleSearch={this.handleSearch} />
              <CSVLink className={classnames("btn btn-primary", classes.btn)} data={this.state.csvLink && this.state.csvLink.csv ? this.state.csvLink.csv : ""} filename={`${organization.name}_coachCodes.csv`}>
              Download Coach and Athlete Invite codes
              </CSVLink>
          </div>         
            <TabularDesign
              currentPage={currentPage}
              totalPages={totalPages}
              changeCurrentPage={this.changeCurrentPage}
              footerTxt={!isPageLoading && <div className={classes.pageInfo}>
                Showing
                <span className={"font-weight-bold"}> {(currentPage * 20) + Math.min(coaches.length, 1)} - {(currentPage * 20) + coaches.length} </span> of <span>{coachesCount}{!!searchTerm && `(total ${originalCount})`}</span>
              </div>
              }
            >
              <React.Fragment>
                <thead>
                  <tr className={classes.tableHead}>
                    {coachFields.map((field) => { return <th>{field.displayName}</th> })}
                    <th className={classes.statusContainer}>Status</th>
                    <th className={classes.editUserContainer}>Edit User</th>
                  </tr>
                </thead>
                <tbody className={classes.coachList}>
                  {coaches.map((coach, index) => (<tr key={index}>
                    {coachFields.map((field) => {
                      return (
                        <td className={classes.td}>
                          {field.value == "lastName" ?
                            <span className={classes.lastNameSection}>
                              <span className={classes.athleteImgBox}>
                                {coach.avatarUrl ?
                                  <img src={coach.avatarUrl} /> :
                                  <AccountCircle />
                                }
                              </span>
                              <span>{(coach[field.value] || "")}</span>
                            </span>
                            :
                            <span>{(coach[field.value] || "")}
                            </span>
                          }
                        </td>
                      )
                    })}
                    <td className={classnames(classes.td, classes.statusContainer)}>
                      <span className={classes.statusBox} style={{
                        background: coach.statusInfo.color
                      }}>{coach.statusInfo.name}</span>
                    </td>
                    <td className={classnames(classes.td, classes.sendInviteContainer, "text-right")}>                      
                      <span className={classes.serviceIcon} onClick={() => this.sendMessage(coach)}> <AirplaneIcon /> </span>
                      <span className={classes.serviceIcon} onClick={() => this.editUser(coach)}><EditIcon /></span>         
                      {(!coach.teamsCount) && <span className={classes.serviceIcon} onClick={() => this.deleteUser(coach)}><DeleteIcon /></span>  }   
                    </td>                    
                  </tr>))
                  }
                </tbody>
              </React.Fragment>
            </TabularDesign>
          </BodyWrapper>
        </div>
      </PageWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  let currentTeam = {};
  if (userContext && userContext.appContextList && userContext.appContextList.length && appContext) {
    currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id) || {};
  }
  return {
    currentTeam: currentTeam || {}
  };
}


export default withStyles(styles)(connect(mapStateToProps)(DirectorCoaches));
