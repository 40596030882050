import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import vnnIcon from '../assets/images/themes/vnn/vnn-logo.png';
import vnnLongLogo from '../assets/images/themes/vnn/vnn-logo.png';
import homeImage from '../assets/images/themes/vnn/vnn-welcome.jpg';
import vnnM1PowerLogo from '../assets/images/themes/vnn/vnn-logo-powered-by-m1.png';
import vnnLoginLogo from '../assets/images/themes/vnn/vnn-logo.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  product: {
    id: 'vnn', 
    name: 'VNN',
    appUrl: 'm1-vnn://',
    domain: 'vnn.gomaxone.com',
    link: 'https://apps.apple.com/us/app/vnn-team-app/id1459170836',
    onAppleAppStore: 'https://apps.apple.com/us/app/vnn-team-app/id1459170836',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.gomaxone.m1mobile.vnn',
    segment: {
      coach: 'ZmK9YNOhuDzfOeHV4D2TPX7j2nSUIpCK'
    }
  },
  image: {
    home: homeImage,
    logo: vnnIcon,
    loginLogo: vnnM1PowerLogo,
    longLogo: vnnIcon
  },
  palette: {
    type: "light",
    primary: {
      main: "#02FF80"
    },
    secondary: {
      main: "#00E3FF"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#373944"
    },
    secondary: green,
    error: red,
  },
  overrides: {
    MuiButton: {
      disabled:{
        color:'#a8a8a8 !important'
      },
      contained:{
        "background-color":"#1354F9 !important",
        color:"#ffffff"
      },
      root:{
        borderRadius:"5px",
        disabled:{
          color:'#a8a8a8 !important'
        }
      },
      fab:{
        borderRadius:"5px"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
    MuiStepIcon:{
      text:{
      }
    }
  }
});
