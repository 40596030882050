import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    eye: {
        cursor: 'pointer',
    },
});

class PasswordInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordIsMasked: true,
        };
    }

    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

    render() {
        const { classes, value } = this.props;
        const { passwordIsMasked } = this.state;

        return (
            <TextField
                {...this.props}
                type={passwordIsMasked ? 'password' : 'text'}
                InputProps={{
                    endAdornment: (
                        !!value ? <InputAdornment position="end">
                            {passwordIsMasked ? <Visibility
                                className={classes.eye}
                                onClick={this.togglePasswordMask}
                            /> : <VisibilityOff className={classes.eye}
                                onClick={this.togglePasswordMask}
                                />}
                        </InputAdornment> : null
                    ),
                }}
            />
        );
    }
}

PasswordInput.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired,
};

export default withStyles(styles)(PasswordInput);