import React, { Component } from 'react'

import './styles.css'

import EditIcon from '@material-ui/icons/Edit'
import { getLocalizedString } from "../../../../utils/locale-utils"
export default class ComposeMessageTab extends Component {
    render(){
        const { 
            onClick, 
            active = false 
        } = this.props
        return(
            <div onClick={onClick} className={active ? 'composeMessageTabOuterContainer' : 'composeMessageTabOuterContainer nonActive'}>
                <p className={'composeMessageTabText'}> {getLocalizedString("COMPOSE_NEW_MESSAGE")} </p>
                <EditIcon className={'editIcon'} />
            </div>
        )
    }
}