<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.385 9.99615C11.1021 9.87829 11.0786 9.50115 11.3379 9.33615C12.9171 8.25186 13.86 6.319 13.4593 4.174C13.1057 2.17043 11.5029 0.520429 9.52286 0.119715C6.19929 -0.587428 3.27643 1.93472 3.27643 5.14043C3.27643 6.83757 4.10143 8.32257 5.39786 9.26543C5.63357 9.454 5.58643 9.80757 5.32714 9.92543C2.21571 11.104 0 14.1211 0 17.6333C0 18.2226 0.495 18.694 1.08429 18.694H5.13857L7.02429 20.5797C7.35429 20.9097 7.77857 21.0983 8.25 21.0983C8.72143 21.0983 9.14571 20.9097 9.47571 20.5797L11.3614 18.694H15.4157C16.005 18.694 16.5 18.2226 16.5 17.6333C16.4764 14.1919 14.3786 11.2454 11.385 9.99615ZM11.2436 17.0676L8.58 19.7311C8.39143 19.9197 8.06143 19.9197 7.84929 19.7311L5.23286 17.0676C4.62 16.4783 4.38429 15.2761 4.80857 14.4983C5.04429 14.074 6.435 12.6361 8.25 14.7104C9.97071 12.6361 11.4557 14.074 11.6914 14.4983C12.0921 15.2997 11.8329 16.5019 11.2436 17.0676Z" fill="#222127"/>
</svg>

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ManIcon = (props) => (
  <SvgIcon width="17" height="22" viewBox="0 0 17 22" {...props}>
<path d="M11.385 9.99615C11.1021 9.87829 11.0786 9.50115 11.3379 9.33615C12.9171 8.25186 13.86 6.319 13.4593 4.174C13.1057 2.17043 11.5029 0.520429 9.52286 0.119715C6.19929 -0.587428 3.27643 1.93472 3.27643 5.14043C3.27643 6.83757 4.10143 8.32257 5.39786 9.26543C5.63357 9.454 5.58643 9.80757 5.32714 9.92543C2.21571 11.104 0 14.1211 0 17.6333C0 18.2226 0.495 18.694 1.08429 18.694H5.13857L7.02429 20.5797C7.35429 20.9097 7.77857 21.0983 8.25 21.0983C8.72143 21.0983 9.14571 20.9097 9.47571 20.5797L11.3614 18.694H15.4157C16.005 18.694 16.5 18.2226 16.5 17.6333C16.4764 14.1919 14.3786 11.2454 11.385 9.99615ZM11.2436 17.0676L8.58 19.7311C8.39143 19.9197 8.06143 19.9197 7.84929 19.7311L5.23286 17.0676C4.62 16.4783 4.38429 15.2761 4.80857 14.4983C5.04429 14.074 6.435 12.6361 8.25 14.7104C9.97071 12.6361 11.4557 14.074 11.6914 14.4983C12.0921 15.2997 11.8329 16.5019 11.2436 17.0676Z" fill="#222127"/>

</SvgIcon>
);

export default ManIcon;
