import React, { Component } from 'react'

import { Chip } from '@material-ui/core'
import momentTZ from 'moment-timezone'

import './styles.css'

export default class ChipData extends Component {
    constructor(props){
        super(props)
        this.state = { isHovering: false }
    }

    handleOnMouseEnter = () => {
        this.setState({ isHovering: true })
    }

    handleOnMouseLeave = () => {
        this.setState({ isHovering: false })
    }

    render(){
        const { isHovering } = this.state
        const { recipient, handleDelete } = this.props

        return(
            <Chip
                size={'small'}
                onDelete={handleDelete}
                label={this.renderLabel()}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
                className={isHovering ? 'chipContainer hoveringChip' : 'chipContainer'}
            />
        )
    }

    renderLabel(){
        const { recipient, timezone = "America/New_York" } = this.props
        return (
            recipient && <div className={'labelTextContainer d-flex flex-column'}>
                <div className={'primaryText'}>{typeof(recipient) === 'string' ? recipient : (recipient.name === 'Loading... ') ? `${recipient.firstName} ${recipient.lastName}` : recipient.name}</div>
                {
                  recipient.secondaryText && recipient.name && (recipient.name !== "All Athletes") && (recipient.name !== "All Coaches") && (recipient.name !== "All Parents")
                  ?
                    <div className={'secondaryText'}>
                      <p className={'listItemSecondaryText'}>{recipient.secondaryText} - {momentTZ.tz(recipient.timezone || timezone || momentTZ.tz.guess()).format('z')}</p>
                    </div>
                   :
                   recipient.secondaryText && <div className={'secondaryText'}>{recipient.secondaryText}</div>
                }
            </div>
        )
    }
}
