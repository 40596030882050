import React, { Component } from 'react'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import OptionsSelector from '../optionsSelector'

import './styles.css'

export default class CustomFilter extends Component {
    constructor(props){
        super(props)
        this.state = { isViewingSelector: false }
    }

    toggleSelector = () => {
        const { isViewingSelector } = this.state
        this.setState({ isViewingSelector: !isViewingSelector })
    }

    render(){
        const { isViewingSelector } = this.state
        const { title, value, onClick, placeholder, filterOptions, handleSelectFilter } = this.props

        return(
            <div className={'customFilterContainer'}>
                <div onClick={() => this.toggleSelector()} className={'customFilterOuterContainer'}>
                    <div className={'customFilterInnerContainer'}>
                        <div className={'filterNameContainer'}>
                            <p className={'titleText'}>{title}</p>
                            <p className={'valueText'}>{value ? value.name : placeholder}</p>
                        </div>
                        <ArrowDropDownIcon className={'icon'} />
                    </div>
                </div>
                {isViewingSelector && 
                    <OptionsSelector 
                        selectedOption={value}
                        options={filterOptions}
                        onClickOption={option => handleSelectFilter(option)}
                    />
                }
            </div>
        )
    }
}