import React, { Component } from 'react'

import { ClickAwayListener } from '@material-ui/core'

import OptionSelectorItem from '../optionSelectorItem'

import _ from 'lodash'

import './styles.css'

export default class OptionsSelector extends Component {
    render(){
        const {
            options = [],
            onClickOption,
            position = 'right',
            selectedOption = {},
            close = () => { return }
        } = this.props
        
        return(
            <ClickAwayListener onClickAway={() => close()}>
                <div className={position == 'left' ? 'leftOptionsSelectorContainer' : 'optionsSelectorContainer'}>
                    <div className={'optionsSelectorInnerContainer'}>
                        <div className={'optionsListContainer'}>
                            {_.orderBy(options, 'name').map((option = {}, i) => {
                                return (
                                    <OptionSelectorItem 
                                        key={i}
                                        order={i}
                                        option={option} 
                                        onHandleClick={() => onClickOption(option)}
                                        selected={selectedOption && option && selectedOption.id == option.id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </ClickAwayListener >
        )
    }
}