import React, { Component } from 'react'
import {
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    checkboxRoot:{
        width:"24px",
        height:"24px",
        marginRight:"6px"
    },
    checkboxChecked:{
        color: "#1354F9 !important"
    },
    checkboxLabel:{
       margin:"0px" 
    },
    checked:{
        color:"#1354F9"
    }
});


const CheckboxWithLabel = (props) => {
    const { label, onChange, checked, classes } = props
    return (
        <FormControlLabel
            label={label}
            classes={{
                root: classes.checkboxLabel,
                label:classnames({[classes.checked]: checked})
            }}
            control={
                <Checkbox
                    classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked
                    }}
                    onChange={onChange}
                    checked={checked}
                />
            }
        />
    )
}

export default withStyles(styles)(CheckboxWithLabel);