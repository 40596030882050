import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Button, CircularProgress } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { setUserContext, setAppContext } from '../../actions/session';
import { withStyles } from '@material-ui/core/styles';
import Amplify, { Auth, Hub, API } from "aws-amplify";
import { withOAuth } from "aws-amplify-react";
import MetaTags from 'react-meta-tags';
import { OAuthButton, initServices } from 'maxone-react-ui';

import config from "../../config-vnn";
import m1vnnconfig from "../../config-m1vnn";
import coachupConfig from "../../config-coachup";
import { parse } from 'qs';
import { tenantMap } from '../../utils/commonUtil';
import { userClient, authClient } from '../../clients';
import PasswordInput from '../../components/passwordInput';
import { getLocalizedString } from '../../utils/locale-utils'
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import {setAuthenticatedUserById}  from '../../actions/user-actions';
import {setCurrentTeamById} from '../../actions/team-actions';
import { setSessionInfo } from '../../utils/sessionUtils';
import "./login.css";

const INCORRECT_PASSWORD_MESSAGE = getLocalizedString("INVALID_LOGIN")
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
    color: theme.palette.text.secondary,
  },
  menu: {
    width: 500,
    padding: 50
  },
  modal: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: 300,
    marginTop:10
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 8 * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginBottom:'-15px'
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    maxWidth: 200
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    try{

      Hub.listen("auth", data => {
        // The Auth module will emit events when user signs in, signs out, etc
        const { channel, payload, source } = data;

        if (channel === "auth" && (props.tenant == 'vnn' || props.tenant == 'm1vnn')) {
          console.log('payload ===> ', payload)
          console.log('channel ===> ', channel)
          console.log('source ===> ', source)
          switch (payload.event) {
            case "signIn":
              let tenant = null;
              Auth.currentAuthenticatedUser()
                  .then(async user => {
                    var username = user.username;
                    console.log('username ', username)

                    try {
                      this.setState({isLoading: true})
                      var idToken = user.signInUserSession.idToken
                      var identities = idToken.payload.identities
                      console.log('identities ', identities)
                      var userInfo = identities[0]
                      console.log('userInfo ', userInfo)
                      let provider = idToken.payload.identities[0].providerName ? idToken.payload.identities[0].providerName : null
                      console.log('provider', provider)

                      if(provider){
                        if(provider === "test-vnn-connect") tenant = "test"
                        else if(provider === "Test") tenant = "test"
                        else if(provider === "Demo") tenant = "demo"
                        else if(provider === "Stage") tenant = "staging"
                        else if(provider === "Staging") tenant = "staging"
                        else tenant = "prod"
                      }
                      if(tenant !== "wcs"){
                        localStorage.setItem("@vnnTenant", tenant)
                      }
                      console.log("!!!!")
                      var userData = await this.getUserData(userInfo.userId, tenant)
                      console.log(userData)
                      var username = userData.username ? userData.username : ""
                      var appContext = await setSessionInfo(username, this.props);
                      props.userHasAuthenticated(true);
                      this.setState({ authState: "signedIn", isLoading: false });
                      props.history.push("/athletes");
                    } catch (e) {
                      console.log("e ", e);
                      this.setState({
                        isLoading: false,
                        errorMessage: `Oops we were unable to log you in with those credentials. Try again!`
                      })
                    }
                  })
                  .catch(e => {
                    console.log(e);
                    this.setState({
                      isLoading: false,
                      errorMessage: `Oops we were unable to log you in with those credentials. Try again!`
                    })
                  });
              break;
            case "signIn_failure":
              this.setState({
                isLoading: false,
                errorMessage: `Oops we were unable to log you in with those credentials. Try again!`
              })
              break;
            default:
              break;
          }
        }
      });
    }
    catch(e){
      console.log('error => ', e)
    }

    this.reloaded = false

    this.state = {
      isLoading: true,
      loadingMessage: "",
      username: "",
      password: "",
      show: false,
      user:null,
      newPassword: "",
      newPasswordConfirm:"",
      passwordError:false,
      isLoadingPassword: false,
      code: "",
      temporaryPassword: "",
      showConfirmationCode: false,
      message: "",
      errorMessage: "",
      showCode:true
    };
  }


  async getUserData(userId, tenant){
    let prefix = "vnn";
    if(tenant === "demo" || tenant === "demovnn"){
      prefix = "demovnn"
    }
    else if(tenant === "staging" || tenant === "stagevnn"){
      prefix = "stagevnn"
    }
    else if(tenant === "test" || tenant === "m1vnn"){
      prefix = "m1vnn"
    }
    try{
      console.log('userId ', userId)
      console.log('prefix ', prefix)
      return API.get('users', `/users/${prefix}-${userId}`)
    }
    catch(e){
      console.log('ERROR ', e)
    }
  }

  validateForm() {
    return !window.location.href.includes('vnn') && this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  checkAmplify(){
    let { tenant } = this.props;
    if(tenant === 'vnn'){
      Amplify.configure({
        Auth: {
          userPoolId: config.cognito.USER_POOL_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_ID
        }
      })
    }
    if(tenant === 'm1vnn'){
      Amplify.configure({
        Auth: {
          userPoolId: m1vnnconfig.cognito.USER_POOL_ID,
          userPoolWebClientId: m1vnnconfig.cognito.APP_CLIENT_ID
        }
      })
    }
    if(tenant === 'coachup'){
      Amplify.configure({
        Auth: {
          userPoolId: coachupConfig.cognito.USER_POOL_ID,
          userPoolWebClientId: coachupConfig.cognito.APP_CLIENT_ID
        }
      })
    }
  }

  componentDidMount() {
    initServices();

    const { location: { search }, history, tenant } = this.props;
    const { auth_code: code, un: username = '' } = parse(search, { ignoreQueryPrefix: true });

    console.log('Auth code', code, 'tenant', tenant);

    // If CoachUp sends an already authenticated user to us, we can initialize
    // the OAuth process immediately and attempt to log them in automagically.
    if (code && tenant === tenantMap.coachup) {
      return history.push(`/oauth?code=${code}`);
    }

    this.setState({
      isLoading: false,
      username
    })
  }

  async onHubCapsule(capsule) {

  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.setState({ errorMessage: "" });
    const { tenant } = this.props;
    const firstTrimUsername = this.state.username.trimStart();
    let username = firstTrimUsername.trimEnd();
    const { password } = this.state;

    try {
      if(this.state.showConfirmationCode){
        await Auth.confirmSignUp(this.state.username, this.state.confirmationCode, {
          // Optional. Force user confirmation irrespective of existing alias. By default set to True.
          forceAliasCreation: true
        }).then(data => {
          console.log('Auth.confirmSignUp = ', data);
          this.setState({errorMessage:'', showConfirmationCode:false})})
            .catch(err => console.log(err));

      }

      let { username, password } = this.state;
      // try {
      //   const { data } = await userClient.getUserByEmailOrUsername(username, TENANT);
      //   ({ username } = data);
      //   console.log('getUserByEmailOrUsername ', data)
      // } catch (e) {
      //   console.log(e);
      // }
      let result = null;

      if(tenant === "kickid"){
        try{
          const { data: { user, key, tokens }, data, status } = await authClient.loginByUsernamePassword(tenant, username, password);

          console.log('status ', status)
          console.log('data ', data)
          console.log('user ', user)
          console.log('username ', username)
          console.log('key ', key)
          console.log("Auth ", Auth)
          result = await Auth.signIn(username, key)
          console.log('result ', result)
        }
        catch(e){
          console.log(e);
          let { data } = e.response||{};
          this.setState({errorMessage:(data||{}).message||INCORRECT_PASSWORD_MESSAGE, isLoading: false})
          return;
        }
        //
        //   result = await Auth.federatedSignIn(
        //     'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_i08Xsesfm',
        //     {
        //         token: tokens.idToken,
        //         expires_at: 864000 * 1000 + new Date().getTime() // the expiration timestamp
        //     },
        //     user
        // )
        // result = {}
        // let currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        // console.log('currentAuthenticatedUser ', currentAuthenticatedUser)
        // let currentSession = await Auth.currentCredentials();
        // console.log('currentSessionest ', currentCredentials)
      }
      else{
        result = await Auth.signIn(username, password);
      }
      if( result.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({ isLoading: false, show: true, user:result, message: "Enter the your new password.", showCode:false })
      }
      else {
        try {
          var appContext = await setSessionInfo(username, this.props)
          this.props.userHasAuthenticated(true);
          if(appContext.isOwner || appContext.isHeadCoach || appContext.isCoach ){
            this.props.history.push('/athletes')
          } else if(appContext.isAthlete){
            alert()
          } else {
            this.props.history.push('/')
          }
        }
        catch(e) {
          console.log('ERROR =>', e)
        }
      }
    } catch (e) {
      console.log('ERROR => ', e);

      if( e.code === "PasswordResetRequiredException"){
        Auth.forgotPassword(username)
            .then(data => {
              this.setState({ delivery: data.CodeDeliveryDetails, isLoading: false });
              this.setState({ showCode: true, show: true, user:result, message: `We've recently upped our password security.  \n Your password must be at least 8 letters in length, with 1 lowercase, 1 uppercase, and 1 number. \n We sent an email to: ${this.state.delivery.Destination} with a code to reset your password.` })
            })
            .catch((err)=> console.log("ERROR =>", err));
      }
      else if(e.code === "UserNotConfirmedException"){
        await Auth.resendSignUp(this.state.username).then((data) => {
          this.setState({ errorMessage: `Oops you haven't been confirmed yet.  We sent an ${data.CodeDeliveryDetails.AttributeName} to ${data.CodeDeliveryDetails.Destination}` })
          this.setState({showConfirmationCode: true})
        }).catch(e => {
          console.log('ERROR ==>', e);
        });

      }
      else if(e.code === "UserNotFoundException" || "NotAuthorizedException"){
        this.setState({ errorMessage: INCORRECT_PASSWORD_MESSAGE })
      }
      else{
        this.setState({ errorMessage: INCORRECT_PASSWORD_MESSAGE })

      }
      this.setState({ isLoading: false });
    }
  }

  handleSignUpSubmit = async event => {
    event.preventDefault();

    this.props.history.push("/signup/who");
  }

  handleNewPasswordSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoadingPassword: true });

    if(!this.state.newPassword) return this.setState({ errorMessage: 'Oops! Enter a new password.' })
    if(this.state.showCode && !this.state.code) return this.setState({ errorMessage: 'Oops! Enter the confirmation code you received in your email.' })
    if(this.state.newPassword.length < 8) return this.setState({ errorMessage: 'Oops! Your password must be at least 8 characters long.' })

    let number = _.find(this.state.newPassword.split(''), char => !isNaN(parseInt(char) * 1));
    let uppercase = _.find(this.state.newPassword.split(''), char => char === char.toUpperCase())
    let lowercase = _.find(this.state.newPassword.split(''), char => char === char.toLowerCase())

    if(!number) return this.setState({ errorMessage: 'Oops! Your password needs at least one number.' })
    if(!uppercase) return this.setState({ errorMessage: 'Oops! Your password needs at least one uppercase letter.' })
    if(!lowercase) return this.setState({ errorMessage: 'Oops! Your password needs at least one lowercase letter.' })

    try {
      if(this.state.showCode) {
        console.log('this.state.showCode ', this.state.showCode)
        await Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.newPassword)
            .then(() => {})
            .catch(e => console.log('ERROR => ', e));
      } else {
        await Auth.completeNewPassword(this.state.user, this.state.newPassword)
            .then(() => {})
            .catch(e => {console.log("ERROR => ", e);})
      }

      let { username } = this.state;
      try {
        const { data } = await userClient.getUserByEmailOrUsername(username, 'maxone');
        ({ username } = data);
        console.log(data)
      } catch (e) {
        console.log(e);
      }

      await Auth.signIn(username, this.state.newPassword);

      try {
        await setSessionInfo(username, this.props)
      } catch(e) {
        console.log("ERROR => ", e);
      }
      this.props.userHasAuthenticated(true);
      this.props.history.push('/')
    }
    catch (e) {
      // alert(e.message);
      console.log('e ', e)
      this.setState({ isLoadingPassword: false });
    }
  }

  handleError = err => {
    console.log('error logging in -> ', err)
    this.setState({
      errorMessage: 'Oops, something went wrong! Try again?'
    })
  }

  handleSuccess = async data => {
    console.log('SUCCESS => ', data)
  }

  render() {
    const { classes, tenant } = this.props;
    const { isLoading, loadingMessage } = this.state;
    const { logo, id } = tenant

    this.checkAmplify()

    return (
        <div className="Login">
          <MetaTags>
            <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Expires" content="0" />
          </MetaTags>
          {
            isLoading
                ?
                <div className={"loadingOverlay"}>
                  <CircularProgress  />
                  <h2>{loadingMessage}</h2>
                </div>
                :
                <Grid container spacing={24}>
                  <Grid item xs={12} style={{textAlign:'center'}}>
                    <img className={classes.image} src={logo}  alt="logo" />
                    <h1 className={classes.header}>{getLocalizedString("WELCOME")}</h1>
                    <h4 className={classes.subHeader}>{getLocalizedString("LETS_GET_TO_WORK")}</h4>
                    {(!window.location.href.includes('vnn')) && (id !== tenantMap.coachup)?
                        <form onSubmit={this.handleSubmit}>
                          {
                            this.state.errorMessage !== ""
                                ?
                                <Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>
                                :
                                null
                          }
                          <Grid item xs={12}>
                            <TextField
                                id="username"
                                margin="normal"
                                label={getLocalizedString("USERNAME")}
                                className={classes.textField}
                                value={this.state.username}
                                onChange={this.handleChange('username')}
                                helperText={getLocalizedString("USERNAME_CASE_SENSITIVE")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PasswordInput
                                id="password"
                                label={getLocalizedString("PASSWORD")}
                                className={classes.textField}
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                type="password"
                                autoComplete="password"
                                margin="normal"
                            />
                          </Grid>
                          {
                            this.state.showConfirmationCode
                                ?
                                <Grid item xs={12}>
                                  <TextField
                                      id="confirmationCode"
                                      label="Confirmation Code"
                                      className={classes.textField}
                                      value={this.state.confirmationCode}
                                      onChange={this.handleChange('confirmationCode')}
                                      margin="normal"
                                  />
                                </Grid>
                                :
                                null
                          }

                          <Grid item xs={12}>
                            <Button disabled={!this.validateForm()} type="submit"
                                    variant="contained" color="primary" className={classes.button}>
                              {getLocalizedString("LOGIN")}
                            </Button>
                          </Grid>
                          <Grid item xs={12} style={{marginTop:15}}>
                            <Typography variant="subheading">
                              <a href="/forgot/password">{getLocalizedString("FORGOT_PASSWORD")}</a>
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={12} style={{marginTop:15}}>
                <Divider style={{marginTop:30, marginBottom:30}} />
                </Grid>
                  <Grid item xs={12} style={{marginTop:15}}>
                    <Typography variant="subheading">
                      {`Don't have an account?`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={()=> this.props.history.push('/signup')}>
                      Sign Up
                    </Button>
                  </Grid> */}
                        </form>
                        :
                        <Grid item xs={12}>
                          {
                            window.location.href.includes('vnn') && !window.location.href.includes('m1vnn')
                                ?
                                <Button onClick={async() => await Auth.federatedSignIn({ provider: 'VNN' })}
                                        variant="contained" color="primary" className={classes.button}>
                                  Login
                                </Button>
                                :
                                (id === tenantMap.coachup) ?
                                    <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                        className={classes.button}
                                    >
                                      <OAuthButton
                                          text={'Sign In'}
                                          tenant={id}
                                          backgroundColor={'#102B52'}
                                          onAuthenticationError={data => this.handleError(data)}
                                          onAuthenticationSuccess={token => this.handleSuccess(token)}
                                      />
                                    </div>
                                    :
                                    <Button onClick={() => this.props.OAuthSignIn()}
                                            variant="contained" color="primary" className={classes.button}>
                                      Login
                                    </Button>
                          }
                        </Grid>
                    }
                  </Grid>
                </Grid>
          }
          <LegacyModalContainer
              open={this.state.show}
              className={classes.modal}
              onClose={() => this.setState({show:false})}
          >
            <Paper className={classes.paper}>
              <Typography variant="title" id="modal-title">
                New Password Required
              </Typography>
              <Typography variant="subheading" id="simple-modal-description">
                {this.state.message}
              </Typography>
              <form onSubmit={this.handleNewPasswordSubmit}>
                {
                  this.state.showCode
                      ?
                      <Grid item xs={12}>
                        <TextField
                            id="code"
                            label="Enter Code"
                            className={classes.textField}
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            margin="normal"
                        />
                      </Grid>
                      :
                      null
                }
                <Grid item xs={12}>
                  <PasswordInput
                      id="newPassword"
                      label={getLocalizedString("NEW_PASSWORD")}
                      className={classes.textField}
                      value={this.state.newPassword}
                      type="password"
                      autoComplete="password"
                      onChange={this.handleChange('newPassword')}
                      margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit"
                          variant="contained" color="primary" className={classes.button}>
                    Submit
                  </Button>
                </Grid>
              </form>
            </Paper>
          </LegacyModalContainer>
        </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tenant }) => ({
  tenant
});

const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext)),
  setAuthenticatedUserById: (userId) => dispatch(setAuthenticatedUserById(userId)),
  setCurrentTeamById:(teamId) => dispatch(setCurrentTeamById(teamId))

});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withOAuth(Login)));
