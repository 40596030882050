import { configClient } from '../clients';

export const getTeamSwitcherByUserId = async (userId) => {
    try {
        const { data } = await configClient.getTeamSwitcherByUserId(userId);
        return data
    } catch (e) {
        console.log(`Error getting team switcher by userId ${userId}`, e);
        return {}
    }
}

export const getNavigationData = async (userId, teamId) => {
    try {
        const { data } = await configClient.getNavigationData(userId, teamId);
        return data
    } catch (e) {
        console.log(`Error getting navigaton data by userId ${userId} and teamId ${teamId}`, e);
        return {}
    }
}

export const getImpersonatedNavigationData = async (userId, teamId, adminUserId) => {
    try {
        const { data } = await configClient.getImpersonatedNavigationData(userId, teamId, adminUserId);
        return data
    } catch (e) {
        console.log(`Error getting impersonated navigaton data by userId ${userId} and teamId ${teamId} and adminuserId ${adminUserId}`, e);
        return {}
    }
}