import { connect } from 'react-redux';
import React, { Component } from "react";

import _ from 'lodash'

import { Tabs } from './constants'

import MessageTab from './components/messageTab'
import ToastMessage from '../../components/toastMessage'
import FeedbackToast from '../../components/feedbackToast'
import ComposeMessageTab from './components/composeMessageTab'
import WarningModal from '../../components/modals/warningModal'
import DateTimeModal from '../../components/modals/dateTimeModal'

import SentScreen from './screens/sentScreen'
import DraftsScreen from './screens/draftsScreen'
import ComposeScreen from './screens/composeScreen'
import ScheduledScreen from './screens/scheduledScreen'
import { stateToHTML } from 'draft-js-export-html';
import {
  resolveTeams,
  resolveContext,
  resolveTabCount,
  handleSendMessage,
  handleSaveMessage,
  handleMessageDelete,
  resolveMessageString,
  handleScheduleMessage,
  resolveMessageRecipients,
  getScheduledMessagesData,
  getDraftMessagesData,
  getMessagesWithPaginationData
} from '../../utils/messageUtils.js'
import { getLocalizedString } from '../../utils/locale-utils';

import { getActivity } from '../../services/activitiesService'
import { getLinkDecorator } from '../../components/draftjsEntities';
import './styles.css'
import PageWrapper from '../nav/pageWrapper';
import {  EditorState } from 'draft-js';
const surveyUrl = 'https://www.surveymonkey.com/r/LXGZXQQ'

class Messages extends Component {
  constructor(props){
    super(props)
    this.decorator = getLinkDecorator();
    this.state = {
      all: [],
      teams: [],
      groups: [],
      parents: [],
      coaches: [],
      athletes: [],
      context: resolveContext(window.location.href),
      messageId: null,
      domain: 'maxone',
      sentMessages: [],
      draftMessages: [],
      potentialTab: null,
      isToastOpen: false,
      messagesSentThisMonth: 0,
      selectedActivity: {},
      activeTab: 'Compose',
      scheduledMessages: [],
      currentMessageString: '',
      currentMessageType: 'sms',
      isWarningModalOpen: false,
      isDateTimeModalOpen: false,
      currentMessageRecipients: [],
      currentRecipientCategory: 'all',
      isMonthlyMessageLimitMet: false,
      sentMessagesPageFetched:0,
      hasMoreMessages:true,
      sentMessagesPageSize:20,
      switchingEmailMessageType: false,
      editorState: EditorState.createEmpty(this.decorator),
    }
  }

  componentWillMount = async () => {
    const { currentOrg, location, tenant } = this.props
    const context = resolveContext(window.location.href)
    this.refreshMessages(context)
    const domain = tenant.id
    let teams = !!currentOrg ? await resolveTeams(currentOrg.id):[];
    //Use custom name for the team or team name + sports
    teams.forEach(team => {
      team.name = team.customName ? team.customName : `${team.name} ${team.sport}`
    });
    let currentMessageRecipients = [];
    if(!!location.state && !!location.state.selectedUser){
      let userData = {...location.state.selectedUser};
      userData.nameFirst = userData.nameFirst||userData.firstName;
      userData.nameLast = userData.nameLast||userData.lastName;
      userData.name = `${userData.nameFirst||userData.firstName} ${userData.nameLast||userData.lastName}`;
      userData.secondaryText = location.state.userType || 'Athlete';
      currentMessageRecipients.push(userData);
    }
    this.setState({ teams, domain, currentMessageRecipients })
  }

  async componentWillReceiveProps(props, nextProps){
    const { context } = this.state
    const newContext = resolveContext(window.location.href)
    if(context !== newContext){
      this.setState({ context: newContext, sentMessagesPageFetched:0, sentMessages:[] },()=>{
        this.refreshMessages(newContext)
      })
    }
  }

  resetComposeUI = () => {
    this.setState({
      currentMessageURL: '',
      currentMessageString: '',
      currentMessageType: 'sms',
      currentMessageRecipients: [],
      currentRecipientCategory: 'all'
    })
  }

  refreshMessages = async (context) => {
    const { currentTeam, currentOrg } = this.props;
    let entityId = context == 'team' ? currentTeam.id : currentOrg.id;
    const response = await Promise.all([getScheduledMessagesData(entityId),getDraftMessagesData(entityId), this.loadMoreSentMessages()]);
    this.setState({draftMessages:response[1], scheduledMessages:response[0] })
  }

  loadMoreSentMessages = async () => {
    let { sentMessages, sentMessagesPageFetched, sentMessagesPageSize, hasMoreMessages } = this.state;
    const context = resolveContext(window.location.href)
    const { currentTeam, currentOrg } = this.props;
    let entityId = context == 'team' ? currentTeam.id : currentOrg.id;
    const { sentMessages: moreMessagesData, messagesSentThisMonth, totalMessagesCount} = await getMessagesWithPaginationData(entityId, sentMessagesPageFetched)
    if((!moreMessagesData||[]).length || ((moreMessagesData||[]).length < sentMessagesPageSize)){
      hasMoreMessages = false
    }
    else{
      sentMessagesPageFetched = sentMessagesPageFetched + 1;
    }
    await this.isMonthlyMessageLimitMet(messagesSentThisMonth)
    this.setState({ sentMessages : sentMessages.concat(moreMessagesData) , sentMessagesPageFetched, hasMoreMessages, totalMessagesCount })
  }

  isMonthlyMessageLimitMet = (messagesSentThisMonth) => {
      const { domain } = this.state
      const { currentTeam } = this.props

      if(domain == 'vnn' && messagesSentThisMonth >= 5 && !!currentTeam.isFree){
        this.setState({
          currentMessageType: 'email',
          isMonthlyMessageLimitMet: true
        })
      }
  }

  handleDelete = async (messagesArray, message) => {
    this.setState({ [messagesArray]: _.without(this.state[messagesArray], message) })
    const deletedMessage = await handleMessageDelete(message)
  }

  toggleToast = () => {
    const { isToastOpen } = this.state
    this.setState({ isToastOpen: !isToastOpen })
  }

  handleSend = async data => {
    const {
      domain,
      context,
      parents,
      coaches,
      athletes,
      currentTeam,
      currentRecipientCategory
    } = data

    const {
      messageId,
      sentMessages,
      draftMessages,
      messagesSentThisMonth,
      selectedActivity,
      currentMessageURL,
      scheduledMessages,
      currentMessageType,
      currentMessageString,
      currentMessageRecipients,
      totalMessagesCount
    } = this.state

    const { currentUser } = this.props;

    this.resetComposeUI()

    const sentMessage = await handleSendMessage(
      domain,
      context,
      parents,
      coaches,
      athletes,
      messageId,
      currentTeam,
      currentMessageType,
      currentMessageString,
      currentMessageRecipients,
      currentRecipientCategory,
      currentMessageURL,
      selectedActivity.id,
      currentUser.id
    )

    let newmessagesSentThisMonth = currentMessageType == 'sms' ? messagesSentThisMonth + 1 : messagesSentThisMonth

    this.setState({
      isToastOpen: true,
      messagesSentThisMonth: newmessagesSentThisMonth,
      toastMessage: 'Message Sent Successfully',
      totalMessagesCount:totalMessagesCount + 1,
      sentMessages: (_.uniqBy(sentMessages||[], "id")).concat([sentMessage]),
      toastAction: () => this.handleTabChange('Sent', null, true),
      draftMessages: _.without(draftMessages, (draftMessages||[]).find(m => m.id == messageId)),
      scheduledMessages: _.without(scheduledMessages, (scheduledMessages||[]).find(m => m.id == messageId))
    })


    this.isMonthlyMessageLimitMet(messagesSentThisMonth)
  }

  handleSave = async data => {
    const {
      domain,
      currentTeam,
      currentRecipientCategory
    } = data

    const {
      context,
      messageId,
      draftMessages,
      selectedActivity,
      currentMessageURL,
      scheduledMessages,
      currentMessageType,
      currentMessageString,
      currentMessageRecipients,
    } = this.state

    this.resetComposeUI()

    let savedMessage = await handleSaveMessage(
      domain,
      context,
      messageId,
      currentTeam,
      currentMessageType,
      currentMessageString,
      currentMessageRecipients,
      currentRecipientCategory,
      currentMessageURL,
      selectedActivity.id
    )

    this.setState({
      isToastOpen: true,
      toastMessage: 'Message Saved to Drafts',
      toastAction: () => this.handleTabChange('Drafts', null, true),
      scheduledMessages: _.without(scheduledMessages, (scheduledMessages||[]).find(m => m.id == messageId)),
      draftMessages: _.without(draftMessages, (draftMessages||[]).find(m => m.id == messageId)).concat([savedMessage])
    })
  }

  scheduleMessage = async (date, selectedTimezone, isMatchUserTimezone) => {
    const { currentTeam, tenant } = this.props
    const { draftMessages } = this.state
    const domain = tenant.id
    const {
      context,
      messageId,
      selectedActivity,
      currentMessageURL,
      currentMessageType,
      currentMessageString,
      currentMessageRecipients,
      currentRecipientCategory,
      parents,
      coaches,
      athletes,
    } = this.state

    const { currentUser } = this.props;

    this.resetComposeUI()

    console.log('isMatchUserTimezone ', isMatchUserTimezone)
    console.log('domain ', domain)
    console.log('tenant ', tenant)
    console.log('currentTeam ', currentTeam)
    console.log('currentUser ', currentUser)
    console.log('currentMessageRecipients ', currentMessageRecipients)

    let scheduledMessages = await handleScheduleMessage(
      date,
      domain,
      context,
      messageId,
      currentTeam,
      currentMessageType,
      currentMessageString,
      currentMessageRecipients,
      currentRecipientCategory,
      currentMessageURL,
      selectedActivity.id,
      currentUser.id,
      parents,
      coaches,
      athletes,
      selectedTimezone,
      isMatchUserTimezone
    )

    this.setState({
      isToastOpen: true,
      toastMessage: 'Message Scheduled to Send',
      scheduledMessages,
      toastAction: () => this.handleTabChange('Scheduled', null, true),
      draftMessages: _.without(draftMessages, (draftMessages||[]).find(m => m.id == messageId))
    })
  }

  handleTabChange = async (tabName, message, override) => {
    const { currentMessageString, currentMessageRecipients, messagesSentThisMonth } = this.state

    let string = ''
    let type = 'sms'
    let activity = {}
    let recipients = ''
    let externalUrl = ''

    if(message){
      console.log('message here ', message)
      if(message.type) message.messageType = message.type.toLowerCase();
      type = message.messageType || message.type
      string = await resolveMessageString(message.message)
      recipients = await resolveMessageRecipients(message.recipients)
      if(message.groups){
        _.uniqBy(message.groups, "id").forEach(group => {
          recipients.push({
            name: group.name,
            id: group.id,
            orderOnTop: 1,
            secondaryText: `${group.users.total} ${group.id === "allAthletes" ? "Athlete(s)" : group.id === "allCoaches" ? "Coach(es)" : group.id === "allParents" ? "Parent(s)" : "User(s)"}`
          })
        })
      }
      if(message.meta){
        if(message.meta.activityId) message.activityId = message.meta.activityId
        if(message.meta.externalUrl) message.externalUrl = message.meta.externalUrl
        if(message.meta.title) message.title = message.meta.title
      }
      if(message.activityId)
        activity = await getActivity(message.activityId)
      if(message.externalUrl)
        externalUrl = message.externalUrl
    }

    if(!override && (currentMessageString || currentMessageRecipients.length)){
      this.toggleWarningModal(tabName)
      return
    }

    this.setState({
      activeTab: tabName,
      currentMessageType: type,
      selectedActivity: activity,
      currentMessageString: string,
      currentMessageURL: externalUrl,
      messageId: message ? message.id : null,
      currentMessageRecipients: message ? recipients : [],
    })

    this.isMonthlyMessageLimitMet(messagesSentThisMonth)
  }

  handleTabCount(tabName){
    const { draftMessages = [], scheduledMessages = [], totalMessagesCount: totalSentMessagesCount } = this.state
    return resolveTabCount(tabName, scheduledMessages, draftMessages, totalSentMessagesCount)
  }

  handleUpdateRosters = (all, groups, coaches, athletes, parents) => {
    this.setState({
      all: all,
      groups,
      coaches,
      athletes,
      parents: parents,
    })
  }

  handleChangeMessageType = type => {
    let { currentMessageType, switchingEmailMessageType, currentMessageString } = this.state;
    if(!switchingEmailMessageType && (currentMessageType == 'email' || type == 'email') && currentMessageString!=''){
        this.setState({switchingEmailMessageType: true, isWarningModalOpen: true, potentialMessagingType: type })
    }
    else{
      if((currentMessageType == 'email' || type == 'email')){
        currentMessageString = '';
      }
      this.setState({ currentMessageString, currentMessageType: type, potentialMessagingType: null, isWarningModalOpen:false, editorState: EditorState.createEmpty(this.decorator) })
    }
  }

  handleChangeMessageURL = url => {
    this.setState({ currentMessageURL: url })
  }

  handleChangeRecipientCategory = category => {
    this.setState({ currentRecipientCategory: category })
  }

  handleChangeMessageString = string => {
    const { currentMessageType } = this.state
    if(currentMessageType == 'sms' && string.split('').length > 340)
      return
    this.setState({ currentMessageString: string })
  }

  handleSelectActivity = activity => {
    const { selectedActivity } = this.state
    this.setState({ selectedActivity: activity == selectedActivity ? {} : activity })
  }

  handleUpdateRecipients = recipients => {
    console.log('recipients ', recipients
    )
    this.setState({ currentMessageRecipients: recipients })
  }

  toggleDateTimeModal = () => {
    const { isDateTimeModalOpen } = this.state
    this.setState({ isDateTimeModalOpen: !isDateTimeModalOpen })
  }

  toggleWarningModal = (tab) => {
    const { isWarningModalOpen } = this.state;
    this.setState({
      potentialTab: tab ? tab : null,
      isWarningModalOpen: !isWarningModalOpen,
      switchingEmailMessageType: false,
      potentialMessagingType: null
    })
  }

  handleEditorChange = async (editorState) => {
    // update text editor state to reflect changes
    this.setState({ editorState }, () => {
        // convert for saving
        this.convertInputToRaw();
    });
}

  convertInputToRaw = () => {
    let { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    let hasTxt = editorState.getCurrentContent().hasText()
    let options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'image') {
          const data = entity.getData();
          return {
            element: 'img',
            attributes: {
              src: data.src,
            },
            style: {
              width: "100px",
              height: "50px"
            },
          };
        }
      },
    };
    this.handleChangeMessageString(hasTxt ? stateToHTML(contentState, options) : '');
  }


  handleContent(){
    const {
      all,
      teams,
      groups,
      context,
      parents,
      coaches,
      athletes,
      messageId,
      activeTab,
      sentMessages,
      draftMessages,
      messagesSentThisMonth,
      selectedActivity,
      currentMessageURL,
      scheduledMessages,
      currentMessageType,
      currentMessageString,
      isMonthlyMessageLimitMet,
      currentRecipientCategory,
      currentMessageRecipients,
      sentMessagesPageFetched,
      hasMoreMessages,
      editorState
    } = this.state

    const { currentTeam: { id } } = this.props
    const allUsers = (parents||[]).concat(coaches||[]).concat(athletes||[]);
    switch(activeTab){
      case 'Drafts':
        return (
          <DraftsScreen
            currentTeamId={id}
            messages={draftMessages}
            currentRecipientCategory={currentRecipientCategory}
            onPressCompose={() => this.handleTabChange('Compose')}
            changeMessageType={type => this.handleChangeMessageType(type)}
            handleEditMessage={message => this.handleTabChange('Compose', message)}
            handleDeleteMessage={message => this.handleDelete('draftMessages', message)}
            allUsers={allUsers}
          />
        )
      case 'Scheduled':
        return (
          <ScheduledScreen
            currentTeamId={id}
            messages={scheduledMessages}
            currentRecipientCategory={currentRecipientCategory}
            onPressCompose={() => this.handleTabChange('Compose')}
            changeMessageType={type => this.handleChangeMessageType(type)}
            handleEditMessage={message => this.handleTabChange('Compose', message)}
            handleDeleteMessage={message => this.handleDelete('scheduledMessages', message)}
            allUsers={allUsers}
          />
        )
      case 'Sent':
        return (
          <SentScreen
            currentTeamId={id}
            messages={sentMessages}
            onPressCompose={() => this.handleTabChange('Compose')}
            page={sentMessagesPageFetched}
            loadMessages={this.loadMoreSentMessages}
            hasMoreMessages={hasMoreMessages}
            allUsers={allUsers}
          />
        )
      default:
        return (
          <ComposeScreen
            all={all}
            teams={teams}
            groups={groups}
            context={context}
            parents={parents}
            coaches={coaches}
            athletes={athletes}
            messageId={messageId}
            messagesSentThisMonth={messagesSentThisMonth}
            selectedActivity={selectedActivity}
            currentMessageURL={currentMessageURL}
            currentMessageType={currentMessageType}
            currentMessageString={currentMessageString}
            sendMessage={(data) => this.handleSend(data)}
            saveMessage={(data) => this.handleSave(data)}
            toggleDateTimeModal={this.toggleDateTimeModal}
            currentMessageRecipients={currentMessageRecipients}
            currentRecipientCategory={currentRecipientCategory}
            isMonthlyMessageLimitMet={isMonthlyMessageLimitMet}
            changeMessageURL={url => this.handleChangeMessageURL(url)}
            changeMessageType={type => this.handleChangeMessageType(type)}
            changeActivity={activity => this.handleSelectActivity(activity)}
            updateRosters={this.handleUpdateRosters}
            changeMessageString={string => this.handleChangeMessageString(string)}
            handleAddRecipients={recipients => this.handleUpdateRecipients(recipients)}
            changeRecipientCategory={category => this.handleChangeRecipientCategory(category)}
            handleEditorChange={this.handleEditorChange}
            editorState={editorState}
          />
        )
    }

  }

  render(){
    const { activeTab, isDateTimeModalOpen, isWarningModalOpen, potentialTab, isToastOpen, toastMessage, toastAction, switchingEmailMessageType, potentialMessagingType } = this.state

    return(
      <PageWrapper title={getLocalizedString("MESSAGES")} {...this.props}>
      <div className={'messagesPageOuterContainer'}>
        <div className={'tabsContainer'}>
          <ComposeMessageTab
            active={activeTab == 'Compose'}
            onClick={() => this.handleTabChange('Compose')}
          />
          {Tabs.map(tab => {
            return(
              <MessageTab
                key={tab}
                name={tab}
                active={activeTab == tab}
                count={this.handleTabCount(tab)}
                onClick={() => this.handleTabChange(tab)}
              />
            )
          })}
        </div>
        <div className={'messagesPageContentContainer'}>
          {this.handleContent()}
        </div>
        <DateTimeModal
          open={isDateTimeModalOpen}
          close={this.toggleDateTimeModal}
          scheduleMessage={(date, selectedTimeZone, isMatchUserTimezone) => this.scheduleMessage(date, selectedTimeZone, isMatchUserTimezone)}
        />
        <WarningModal
          open={isWarningModalOpen}
          close={this.toggleWarningModal}
          handleContinue={() => switchingEmailMessageType? this.handleChangeMessageType(potentialMessagingType): this.handleTabChange(potentialTab, null, true)}
          text={switchingEmailMessageType? 'Are you sure you want to leave': 'Are you sure you want to leave without saving your message first?'}
          secondaryText={switchingEmailMessageType? 'Your message will be discarded':'You can save the message as a draft from the Compose Screen'}
        />
        <div className={'toastMessageContainer'}>
            <ToastMessage
              open={isToastOpen}
              text={toastMessage}
              close={this.toggleToast}
              actionText={'View Message'}
              action={() => toastAction()}
            />
        </div>
      </div>
      </PageWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { session: { userContext, appContext }, tenant } = state;
  const currentTeam = userContext.appContextList.find(c => c.id === appContext.id) ||{};
  const currentOrg = userContext.appContextList.find(c => c.id === currentTeam.organizationId)||currentTeam.organization;
  return { currentOrg: currentOrg, currentTeam: currentTeam, currentUser: userContext.user, tenant }
}

export default connect(mapStateToProps)(Messages);
