import React from 'react';
import SpeedoMeterSvg from './SpeedoMeterSvg';
import { withStyles } from "@material-ui/core/styles";
import { WhoopConnectedIcon } from '../../icons';

const styles = () => ({
    deviceConnected: {
        width: '140px',
        height: '25px',
        // background: '#D9F9DA',
        // opacity: '0.14',
        borderRadius: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      deviceConnectedText: {
        // color: "#00C64F",
        fontSize: "10px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      connectedArea: {
        width: '14px',
        color: '#fff',
        height: '14px',
        margin: '2px 4px 2px 2px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        justifyContent: 'center',
        // backgroundColor: '#00C64F',
        "& svg": {
            height: "10px !important",
            width: "10px !important",
          },
      }
});

const SpeedoMeter = ({ size, score = 0, totalValue, date, today,classes, todayHrvData }) => {
  return (
    <div>
      <div style={{ alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ position: 'absolute' }}>
          <SpeedoMeterSvg size={size} score={score} totalValue={totalValue} />
        </div>
        
          <span
            style={{
            //   ...getFontStyle(fontWeights.BOLD, 44, 150, colors.WHITE),
            //   pTransform: 'uppercase',
            //   pAlign: 'center',
            color: '#000000',
            fontSize: '55px',
            fontWeight: '500'
            }}
          >
            {score}
          </span>
       
        <p
          style={{
            fontSize: '12px',
            color: '#222127',
            opacity: '0.25'
          }}
          className="mb-0"
        >
          ENERGY SCORE
        </p>
        <p
          style={{
            // ...getFontStyle(fontWeights.REGULAR, 12, 150, colors.WHITE),
            // pTransform: 'uppercase',
            // pAlign: 'center',
            fontSize: '12px',
            color: '#222127',
            opacity: '0.6',
          }}
          className="mb-1"
        >
          OWN IT
        </p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
        <span style={{fontSize:"10px"}} >LOW</span>
        <div className={classes.deviceConnected}>
                   <div className={classes.deviceConnectedText} style={{ }}>
                       <div className={classes.connectedArea}>
                        {/* <WhoopConnectedIcon /> */}
                      </div>
                           {/* Device Connected */}
                    </div>
                   </div>
        <span style={{fontSize:"10px"}}>HIGH</span>
      </div>
    </div>
  );
};

export default withStyles(styles)(SpeedoMeter);