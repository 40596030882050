import React, { Component } from 'react'
import './styles.css'
import { PrimaryButton, InputField } from 'maxone-react-ui'
import { testId } from '../../../../utils/test-utils';
import FloatingSelectWithCustomChild from '../../../../components/floatingSelectWithCustomChild'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';


const styles = {
    dropdownContainer:{
        background:"white",
        "& > div": {
            height: "20%"
        },
        "& > div label": {
            fontSize: "100%",
            marginTop: "4px !important",
            "& + div":{
                marginTop: "18px"
            }
        }
    }
}

class ReportTab extends Component {
    constructor(props) {
        super(props),
        this.menuItemData=[
            {displayValue:"I'm having an issue with the Website.", value:"I'm having an issue with the Website."},
            {displayValue:"I'm having an issue with the Mobile App.", value:"I'm having an issue with the Mobile App"},
            {displayValue:"I'm having an issue with the Website and Mobile App.", value:"I'm having an issue with the Website and Mobile App."},
            {displayValue:"I have a feature I'd like to see.", value:"I have a feature I'd like to see."},
            {displayValue:"I have a Question I need Answered.", value:"I have a Question I need Answered."},
            {displayValue:"I'm not sure, but i still need help.", value:"I'm not sure, but i still need help."},]
        this.state = {
            hasAcknowledged: false,
            acknowledgeError: false,

        }
    }

    toggleAcknowledge = () => {
        const { hasAcknowledged } = this.state
        this.setState({ hasAcknowledged: !hasAcknowledged })
    }

    handleCreateTicket = async () => {
        const { handleCreateTicket } = this.props;      
        await handleCreateTicket();
        // window.location.reload(true)
    }

    handleChangeMessageString = event => {
        const { handleChange } = this.props
        handleChange(event.target.value)
    }


    render() {
        console.log("freshdesk testing tab")
        const {
            reportData: {
                nameLast,
                nameFirst,
                email,
                description,
                subject,
                phoneNumber = "",
                username },
            handleChange,
            isLoading,
            classes,
            issue,
            invalidEmail
        } = this.props
        console.log({nameLast, nameFirst, email, description, subject, username })
        return (
            
                <div className={'currentTabContainer'}>
                    <div className={'tabContentContainer'}>
                        <div className={'col columnContainer tabContentLeftContainer borderBottom'}>
                            <p className={'stepTitleText'}>Need to contact our support team?</p>
                            <p className={'hideMediumDown stepDescriptionText'}>Are you experiencing an issue? Is there a feature you would like us to add? Do you have a question? </p>
                        </div>
                    </div>
                        <div className={'row tabContentContainer2 no-gutters'}>
                        <div className={'d-flex flex-column w-100 columnContainer tabContentRightContainer'}>
                            {/* <div className={'marginSpacer20'}/> */}
                            <div className={'row'}>
                                <div className={'col-lg-6 mb-3 mb-lg-0'} {...testId(`first-name-container`)}>
                                    <InputField value={nameFirst||""} label={'First Name'} onChange={value => handleChange('nameFirst', value)} />
                                </div>                           
                                <div className={'col-lg-6'} {...testId(`last-name-container`)}>
                                    <InputField value={nameLast||""} label={'Last Name'} onChange={value => handleChange('nameLast', value)} />
                                </div>
                            </div>
                            <div {...testId(`email-container`)}>
                                {invalidEmail ? <p className={'textInputLabel'} style={{ color: "red"}}>Oops, invalid email.</p>: <p></p>}
                                <InputField value={email||""} label={'Email Address'} error={invalidEmail} helperText={invalidEmail ? "Oops, invalid email." : ''} onChange={value => handleChange('email', value)} />
                            </div>
                            <div className={'marginSpacer10'} />                           
                            <div className={classes.dropdownContainer} {...testId(`freshdest-ticket-container`)}>
                                <FloatingSelectWithCustomChild
                                    value={issue}
                                    dropDownWidth="100%"
                                    label="How can we help?"
                                    onChange={value => handleChange('issue', value)}
                                    dropDownLabel="-Select-"
                                    menuItemData={this.menuItemData}
                                    showDropDownBorder={true}
                                />
                            </div>
                            <div className={'marginSpacer10'} />
                            <div className="row">
                                <div className={'col'} {...testId(`subject-container`)}>
                                    <InputField label={'Subject'} value={subject||""} onChange={value => handleChange('subject', value)} />
                                </div>
                            </div>
                            <div className={'marginSpacer10'} />
                            <div className="row">
                                <div className="col" {...testId(`description-container`)}>
                                    <InputField label={'Description'} value={description||""}  onChange={value => handleChange('description', value)} />

                                </div>
                            </div>
                            <div className={'marginSpacer20'} />
                            <div className={'rowContainer justify-content-end'}  {...testId(`submit-button-container`)}>
                                <PrimaryButton
                                    height={50}
                                    loading={!!isLoading}
                                    text={'Submit Ticket'}
                                    onClick={this.handleCreateTicket}//handleCreateAccount -> handleCreateTicket
                                    width={window.innerWidth < 576 ? '100%' : 200}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'nextStep borderTop'}>
                        <p className={'nextStepTitle'}>Next Step: <span className={'boldedText'}>{"A member from our support team will reach out to you shortly!"}</span></p>
                    </div>

                </div>
                
        )
    }
}

export default withStyles(styles)(ReportTab)
