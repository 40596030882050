import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PaperIcon = (props) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" {...props}>
    <path d="M20.25 15.1875C20.25 16.2616 19.8233 17.2918 19.0638 18.0513C18.3043 18.8108 17.2741 19.2375 16.2 19.2375H12.15C11.0759 19.2375 10.0457 18.8108 9.28622 18.0513C8.5267 17.2918 8.1 16.2616 8.1 15.1875V13.1625H20.25V15.1875ZM2.025 0H12.15C12.6871 0 13.2021 0.213348 13.5819 0.593109C13.9617 0.97287 14.175 1.48794 14.175 2.025V11.1375H6.075V16.2H2.025C1.48794 16.2 0.97287 15.9867 0.593109 15.6069C0.213348 15.2271 0 14.7121 0 14.175V2.025C0 1.48794 0.213348 0.97287 0.593109 0.593109C0.97287 0.213348 1.48794 0 2.025 0V0ZM2.025 3.0375V5.0625H4.05V3.0375H2.025ZM12.15 5.0625V3.0375H6.075V5.0625H12.15ZM2.025 7.0875V9.1125H4.05V7.0875H2.025ZM6.075 7.0875V9.1125H12.15V7.0875H6.075ZM2.025 11.1375V13.1625H4.05V11.1375H2.025Z" fill="#222127"/>
  </SvgIcon>
);

export default PaperIcon;
