import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import classNames from "classnames";
import _, { isObject } from "lodash";
import "./newWorkoutActivityModal.css";
import { markdownToDraft } from "markdown-draft-js";
// // text editor
import {
  Editor,
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  RichUtils,
  CompositeDecorator,
} from "draft-js";

// // material ui
import {
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  FormatItalic,
  FormatBold,
  Link,
} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

import {
  Input,
  MenuItem,
  Button,
  Modal,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import FloatingInputWithAdorment from "../../components/floatingInputWithAdorment";
import FloatingInput from "../../components/floatingInput";
import FloatingSelect from "../../components/floatingSelect";
import IconButton from "../iconButton";
import ToggleButton from "../toggleButton";
import ChipInput from "../chipInput";
import {
  getTeamLogo,
  getActivityThumbnail,
  fetchVimeoVideoThumbnail,
  fetchWistiaVideoThumbnail,
  isVideo,
  addhttp,
} from "../../utils/commonUtil";
import ConfirmationDialog from "../confirmationDialog";
import { scoreTypes } from "../../constants.js";
import {
  analyticsService,
  currentTimestamp,
} from "../../services/analyticsService";
import LegacyModalContainer from "../modalContainer/legacyModalContainer";
import { getLinkDecorator, onAddLink } from "../draftjsEntities";

const styles = (theme) => ({
  link: {
    textDecoration: "underline",
    color: "",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  nameTextField: {
    height: "62px",
    fontSize: "24px",
  },
  nameFocused: {
    transform: "translate(0, 1.5px) scale(0.50);",
    top: "8px",
  },
  nameInput: {
    fontSize: "24px",
  },
  nameFieldLabel: {
    fontSize: "24px",
  },
  categoryControl: {
    marginRight: "15px",
  },
  subHead: {
    marginRight: "8px",
    fontSize: "14px",
  },
  toggleSwitchContainer: {
    marginRight: "16px",
    minWidth: "216px",
  },
  pointValueInput: {
    maxWidth: "114px",
  },
  marginBottomZero: {
    marginBottom: "0px",
  },
  formRowMargin: {
    marginBottom: "20px",
  },
  adornmentButton: {
    padding: "8px 11px",
    fontSize: "12px",
    boxShadow: "none",
    textTransform: "inherit",
    fontFamily: "Poppins",
  },
  checkboxRoot: {
    width: "29px",
  },
  buttonDisabled: {
    opacity: 0.3,
  },
  adormentRootClass: {
    flexGrow: 1,
    display: "flex",
    marginLeft: "0px",
  },
  playButton: {
    position: "absolute",
    zIndex: 10,
  },
});

class NewWorkoutActivityModal extends Component {
  constructor(props) {
    super(props);
    this.decorator = getLinkDecorator();
    this.state = {
      activityId: null,
      editorState: EditorState.createEmpty(this.decorator),
      addAnother: false,
      openVideoToggleDialog: false,
      playerEnabled: false,
      editorClassName: "editor",
    };
  }

  componentWillReceiveProps(props) {
    if (props.open && !this.props.open) {
      this.setState({
        editorState: EditorState.createEmpty(this.decorator),
      });

      if (props.activity.videoType === "vimeo") {
        fetchVimeoVideoThumbnail(props.activity).then((res) => {
          this.setState({ vimeo_thumb: res });
        });
      }

      if (props.activity.videoType === "wistia") {
        fetchWistiaVideoThumbnail(props.activity).then((res) => {
          this.setState({ wistia_thumb: res });
        });
      }

      if (props.activity.videoId || props.activity.videoUrl) {
        this.setState({ enableVideoUrl: true });
      }
      if (props.activity) {
        if (
          props.activity.description &&
          props.activity.description.split("{").length > 1
        ) {
          // this is to catch legacy descriptions and format them
          let newState = convertFromRaw(JSON.parse(props.activity.description));
          this.setState({
            editorState: EditorState.createWithContent(
              newState,
              this.decorator
            ),
            activityId: props.activity.id,
          });
        } else if (props.activity.description) {
          // this is for editing descriptions created with our new text editor
          try {
            const rawData = markdownToDraft(props.activity.description);
            let newState = convertFromRaw(rawData);
            this.setState({
              editorState: EditorState.createWithContent(
                newState,
                this.decorator
              ),
              activityId: props.activity.id,
            });
          } catch (e) {
            //Catch json.parse exception when description is not in json format and is in old format
          }
        }
      }
    } else if (!props.activity || !props.activity.description) {
      this.setState({
        editorState: EditorState.createEmpty(this.decorator),
      });
    }
  }

  toggleVideoUrl = () => {
    const { activity, removeActivityVideo, uploadingVideoFile } = this.props;
    if (activity.videoId || activity.attachment || uploadingVideoFile) {
      this.setState({ openVideoToggleDialog: true });
    } else {
      let { enableVideoUrl } = this.state;
      removeActivityVideo();
      this.setState({ enableVideoUrl: !enableVideoUrl, playerEnabled: false });
    }
  };

  handleVideoConfirmationClose = (userResponse) => {
    if (userResponse) {
      const {
        removeActivityVideo,
        cancelUpload,
        uploadingVideoFile,
      } = this.props;
      let { enableVideoUrl } = this.state;
      removeActivityVideo();
      if (uploadingVideoFile) {
        cancelUpload();
      }
      this.setState({ enableVideoUrl: !enableVideoUrl });
    }
    this.setState({ openVideoToggleDialog: false });
  };

  toggleAddAnother = () => {
    this.setState({ addAnother: !this.state.addAnother });
  };

  validateActivity() {
    const {
      activity,
      uploadingVideoFile,
      uploadingThumbnailFile,
      invalidVideoUrl,
    } = this.props;
    let validationText = "";
    let validationArray = [];
    if (uploadingVideoFile || uploadingThumbnailFile) {
      return (validationText = "Please wait while we upload your file");
    }
    if (!activity.name) {
      validationArray.push("Title");
    }
    if (!activity.scoreType) {
      validationArray.push("Measure");
    }
    if (!activity.category) {
      validationArray.push("Category");
    }
    if (activity.videoUrl && invalidVideoUrl) {
      validationArray.push("Unsupported video url");
    }
    if (validationArray.length) {
      const last = validationArray.pop();
      validationText =
        "Missing " +
        validationArray.join(", ") +
        (validationArray.length != 0 ? " and " : "") +
        last +
        ".";
    }
    return validationText;
  }

  // TEXT EDITOR HELPERS //
  // inline text styling
  _onInlineClick(inlineType) {
    this.handleEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineType)
    );
  }

  // block styling
  _onBlockClick(blockType) {
    let { blockType: existingBlockType } = this.state;
    if (existingBlockType == blockType) {
      this.setState({ blockType: null });
    } else {
      this.setState({ blockType: blockType });
    }
    this.handleEditorChange(
      RichUtils.toggleBlockType(this.state.editorState, blockType)
    );
  }

  onAddLink = (event) => {
    const { editorState } = this.state;
    return onAddLink(event, editorState, this.handleEditorChange);
  };

  // highlight active selections
  isClassActive(style) {
    const { editorState } = this.state;
    const currentStyle = editorState.getCurrentInlineStyle();
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    const match = _.find(blocks, (b) => b.get("type") === style);
    return currentStyle.has(style) || match;
  }

  handleEditorChange = async (editorState) => {
    // update text editor state to reflect changes
    this.setState({ editorState }, () => {
      // convert for saving
      this.convertInputToRaw();
    });
  };

  convertInputToRaw = () => {
    this.props.handleChange("description")(
      JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
    );
  };

  focus = () => {
    this.setState({
      editorClassName: `${this.state.editorClassName} editor-hide-placeholder`,
    });
    this.refs.editor.focus();
  };

  handleSave = () => {
    let activity = this.props.activity;
    try {
      let descriptionText = "";
      if (!!activity.description) {
        let description = JSON.parse(activity.description);
        for (let i = 0; i < description.blocks.length; i++) {
          descriptionText += description.blocks[i].text;
          descriptionText += "\n";
        }
      }
      let commonTrackingParams = {
        performance_leaderboard: activity.hasLeaderboard ? "yes" : "no",
        uploaded_video_file: activity.attachment ? "yes" : "no",
        youtube_link: activity.videoType === "youtube" ? "yes" : "no",
        thumbnail: "thumbnail" in activity ? "yes" : "no",
        tags: this.props.tags,
      };
      analyticsService.trackEvent("Activity Created", {
        created_from: "workouts",
        type: activity.category,
        name: activity.name,
        measure: activity.scoreType,
        point_value: activity.completionValue,
        description: descriptionText,
        ...commonTrackingParams,
      });
    } catch (e) {}
    this.resetModal();
    if (this.props.resetActivity) {
      this.props.resetActivity();
    }
    this.props.save(this.state.addAnother);
  };

  resetModal = () => {
    this.setState({
      editorState: EditorState.createEmpty(this.decorator),
      addAnother: false,
      vimeo_thumb: null,
      wistia_thumb: null,
      enableVideoUrl: false,
      fallbackUrl: null,
      playerEnabled: false,
    });
  };

  getTitle(category, isNewActivity) {
    switch (category) {
      case "drill":
        return "New Skill Development Activity";
      case "exercise":
        return "New Strength & Conditioning Activity";
      case "education":
        return "New Educational Material";
      case "coach":
        return "New Coaching Resources Activity";
      default:
        return isNewActivity ? "Create New Drill" : "Edit Drill";
    }
  }

  fallBackToDifferentFormat = (url) => {
    //Try changing video format if there is failure;
    const { fallbackUrl } = this.state;
    if (!fallbackUrl) {
      let splittedUrl = url.split(".");
      let extension = splittedUrl.pop();
      extension = extension == "mp4" ? "mov" : "mp4";
      splittedUrl.push(extension);
      this.setState({ fallbackUrl: splittedUrl.join(".") });
    }
  };

  enablePlayer = () => {
    this.setState({ playerEnabled: true });
  };

  render() {
    let {
      enableVideoUrl,
      addAnother,
      openVideoToggleDialog,
      fallbackUrl,
      playerEnabled,
      editorClassName,
      editorState,
      blockType,
    } = this.state;
    const {
      open,
      close,
      classes,
      activity = { completionValue: 1, hasLeaderboard: false },
      activities = [],
      category,
      tags = [],
      coachTraining = false,
      removeTag,
      addTag,
      displaySuccessOverlay,
      uploadingThumbnailFile,
      uploadingVideoFile,
      overlayMessage,
      videoFileUploadProgress,
      thumbnailUploadProgress,
      currentTeam,
      invalidVideoUrl,
      libraries,
    } = this.props;
    const videoFileName =
      activity.videoName ||
      (activity.attachment
        ? activity.attachment.substring(
            activity.attachment.lastIndexOf("/") + 1
          )
        : "");
    let thumbnailFileName = activity.thumbnailName || activity.thumbnail || "";
    thumbnailFileName = thumbnailFileName.substring(
      thumbnailFileName.lastIndexOf("/") + 1
    );
    const thumbnailImage =
      activity.thumbnailBase64 ||
      activity.thumbnail ||
      getActivityThumbnail(activity) ||
      this.state.vimeo_thumb ||
      this.state.wistia_thumb ||
      (!activity.isNewActivity ? getTeamLogo(currentTeam) : null);
    let attachmentVideoUrl = "";
    if (activity && activity.attachment && isVideo(videoFileName)) {
      attachmentVideoUrl = fallbackUrl || activity.attachment || "";
    }
    //Convert legacy scoretype to scoreTypes value
    if (activity && parseInt(activity.scoreType)) {
      activity.scoreType = scoreTypes.map((a) => a.value)[activity.scoreType];
    }

    const title = this.getTitle(category, activity.isNewActivity);

    const validationText = this.validateActivity();
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);

    console.log(JSON.stringify(attachmentVideoUrl))
    return (
      <div>
        {displaySuccessOverlay ? (
          <div className="activityOverlay">
            <div className="overlayMessage1">{overlayMessage}</div>
            <div className="overlayMessage2">{activity.name}</div>
            {uploadingVideoFile === true && (
              <div className="overlayMessage3">Uploading video...</div>
            )}
          </div>
        ) : null}
        <ConfirmationDialog
          className={"toggleConfirmationContainer"}
          open={openVideoToggleDialog}
          content={
            "Current video will be lost if you continue. Are you sure you want to switch?"
          }
          buttonClassName={"toggleConfirmationButton"}
          handleClose={this.handleVideoConfirmationClose}
        />
        <LegacyModalContainer
          open={open}
          style={displaySuccessOverlay ? { opacity: 0 } : null}
          className="activityModal"
          onClose={() => {
            this.resetModal();
            close();
          }}
          containerClassName={"activityModalPaper"}
        >
          <div className="modalSubContainer">
            <div className={"modalHeader"}>
              <div className={"heading"}>{title}</div>
              <CancelIcon
                className="icon"
                style={{ opacity: 0.3, cursor: "pointer" }}
                onClick={() => {
                  this.resetModal();
                  close();
                }}
              />
            </div>
            <FloatingInput
              label="Name"
              className={"textField"}
              value={activity.name ? activity.name : ""}
              onChange={this.props.handleChange("name")}
              overrideContainerCss={classes.nameTextField}
              overrideInputCSS={classes.nameInput}
              overrideNameLabelRootCss={classes.nameFieldLabel}
              overideLabelFocusCss={classes.nameFocused}
            />
            <div className="flex-row">
              <FloatingSelect
                overrideContainerCss={classes.categoryControl}
                label="Category"
                value={(activity.category || "").toLowerCase()}
                onChange={this.props.handleChange("category")}
              >
                {libraries.map((category) => (
                  <MenuItem
                    key={category.value}
                    value={(category.value || "").toLowerCase()}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </FloatingSelect>
              <FloatingSelect
                label="Measure"
                value={
                  (activity.scoreType || "").toLowerCase() === "shortesttime" ||
                  (activity.scoreType || "").toLowerCase() === "shortest_time"
                    ? "shortestTime"
                    : activity.scoreType || ""
                }
                onChange={this.props.handleChange("scoreType")}
              >
                {scoreTypes.map((type) => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </FloatingSelect>
            </div>
            {activity.scoreType === "weight" && activity.category ? (
              <div className={classNames("flex-row", classes.formRowMargin)}>
                <div
                  className={classNames(
                    classes.toggleSwitchContainer,
                    "flex-row"
                  )}
                >
                  <div className={classes.subHead}>% of max</div>
                  <ToggleButton
                    value={
                      activity.isPercentOfMax !== undefined ||
                      activity.percentOfMaxActivityId == "0"
                        ? String(activity.isPercentOfMax).toLowerCase() ==
                          "true"
                        : true
                    }
                    onChange={this.props.handleChange("isPercentOfMax")}
                  />
                </div>
                <FloatingSelect
                  label="% of max activity"
                  disabled={
                    !activities.length || activity.isPercentOfMax !== undefined
                      ? String(activity.isPercentOfMax).toLowerCase() != "true"
                      : false
                  }
                  value={
                    activity.percentOfMaxActivityId
                      ? activity.percentOfMaxActivityId
                      : ""
                  }
                  onChange={this.props.handleChange("percentOfMaxActivityId")}
                  overrideContainerCss={classes.marginBottomZero}
                >
                  {_.orderBy(activities, "name")
                    .filter(
                      (item) =>
                        (item.category || "").toLowerCase() ==
                        (activity.category || "").toLowerCase()
                    )
                    .map((activity) => {
                      return (
                        <MenuItem key={activity.id} value={activity.id}>
                          {activity.name}
                        </MenuItem>
                      );
                    })}
                </FloatingSelect>
              </div>
            ) : null}
            <div className={classNames("flex-row", classes.formRowMargin)}>
              {activity.category != "coach" &&
              category != "coach" &&
              activity.category != "education" &&
              category != "education" &&
              activity.scoreType !== "participation" &&
              !coachTraining ? (
                <div
                  className={classNames(
                    classes.toggleSwitchContainer,
                    "flex-row"
                  )}
                >
                  <div className={classes.subHead}>
                    Performance Leaderboard:
                  </div>
                  <ToggleButton
                    buttonEnabledText="Enabled"
                    buttonDisabledText="Disabled"
                    value={
                      [undefined, "false"].indexOf(activity.hasLeaderboard) ===
                      -1
                        ? activity.hasLeaderboard
                        : false
                    }
                    onChange={this.props.handleChange("hasLeaderboard")}
                  />
                </div>
              ) : null}
              {activity.category != "coach" &&
              category != "coach" &&
              !coachTraining ? (
                <FloatingInput
                  type="number"
                  id="completionValue"
                  label="Point Value"
                  className={"textField"}
                  value={activity.completionValue}
                  enableNumberIncrementArrow={true}
                  onChange={this.props.handleChange("completionValue")}
                  overrideContainerCss={classNames(
                    classes.pointValueInput,
                    classes.marginBottomZero
                  )}
                />
              ) : null}
            </div>
            <div className={"descriptionTagContainer"}>
              <div id={"content"}>
                <div className={"textEditButtonContainer"}>
                  <p className={"subHead"}>Description</p>
                  <div className={"iconContainer"}>
                    <FormatBold
                      onMouseDown={(event) => {
                        event.preventDefault();
                        this._onInlineClick(`BOLD`);
                      }}
                      className={"styleOptionButton"}
                      style={
                        this.isClassActive(`BOLD`) ? { color: "#00a1e1" } : null
                      }
                    />
                    <FormatItalic
                      onMouseDown={(event) => {
                        event.preventDefault();
                        this._onInlineClick(`ITALIC`);
                      }}
                      className={"styleOptionButton"}
                      style={
                        this.isClassActive(`ITALIC`)
                          ? { color: "#00a1e1" }
                          : null
                      }
                    />
                    <FormatUnderlined
                      onMouseDown={(event) => {
                        event.preventDefault();
                        this._onInlineClick(`UNDERLINE`);
                      }}
                      className={"styleOptionButton"}
                      style={
                        this.isClassActive(`UNDERLINE`)
                          ? { color: "#00a1e1" }
                          : null
                      }
                    />
                    <FormatListBulleted
                      onMouseDown={(event) => {
                        event.preventDefault();
                        this._onBlockClick(`unordered-list-item`);
                      }}
                      className={"styleOptionButton"}
                      style={
                        blockType == `unordered-list-item`
                          ? { color: "#00a1e1" }
                          : null
                      }
                    />
                    <FormatListNumbered
                      onMouseDown={(event) => {
                        event.preventDefault();
                        this._onBlockClick(`ordered-list-item`);
                      }}
                      className={"styleOptionButton"}
                      style={
                        blockType == `ordered-list-item`
                          ? { color: "#00a1e1" }
                          : null
                      }
                    />
                    <Link
                      onMouseDown={(event) => {
                        this.onAddLink(event);
                      }}
                      className={"styleOptionButton"}
                      style={
                        this.isClassActive(`ordered-list-item`)
                          ? { color: "#00a1e1" }
                          : null
                      }
                    />
                  </div>
                </div>
                <div className={editorClassName} onClick={this.focus}>
                  <Editor
                    ref={"editor"}
                    editorState={editorState}
                    onChange={this.handleEditorChange}
                    placeholder="Add your Description / Instructions here"
                  />
                </div>
              </div>
              <div className="addTagContainer">
                <div className={"subHead"}>Add Tags</div>
                <ChipInput
                  currentTeam={currentTeam}
                  chips={tags}
                  removeChip={(tag) => removeTag(tag)}
                  handleNewChip={(tag) => addTag(tag)}
                  placeholder={
                    'Type to add a tag, press enter or "," to start a new tag.'
                  }
                />
              </div>
            </div>
            <p className={"subHead"}>Media</p>
            <div className="flex-row">
              <div className="fileInputContainer">
                {enableVideoUrl ? (
                  <FloatingInput
                    required={true}
                    error={invalidVideoUrl}
                    id="videoUrl"
                    label="Video URL"
                    className={"textField"}
                    value={activity.videoUrl || ""}
                    helperText={
                      invalidVideoUrl
                        ? "Enter youtube, vimeo or wistia url"
                        : ""
                    }
                    onBlur={this.props.handleVideoUrlChange()}
                    onChange={this.props.handleChange("videoUrl")}
                    overrideContainerCss={classNames(classes.marginBottomZero)}
                  />
                ) : (
                  <FloatingInputWithAdorment
                    disabled={true}
                    id="fileUrl"
                    className={"textField fileInput"}
                    value={videoFileName}
                    overrideContainerCss={classes.marginBottomZero}
                    label={"Activity Video"}
                    adormentRootClass={classes.adormentRootClass}
                  >
                    <div className="fileInfoContainer">
                      <div className="fileName">{videoFileName + " "}</div>
                      {(uploadingVideoFile === false ||
                        uploadingVideoFile === true) &&
                      videoFileName ? (
                        <div className="fileUploadTxt">
                          {uploadingVideoFile === false
                            ? `Uploaded`
                            : `Uploading ${videoFileUploadProgress}%`}
                        </div>
                      ) : null}
                    </div>
                    <input
                      accept="*"
                      className={"input"}
                      id="raised-button-file"
                      type="file"
                      ref="fileUploader"
                      onChange={this.props.handleFileSelect}
                      margin={"dense"}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        disableRipple
                        variant="contained"
                        component="span"
                        className={classes.adornmentButton}
                      >
                        Choose File
                      </Button>
                    </label>
                  </FloatingInputWithAdorment>
                )}
                <div
                  className="videoUrlToggleText"
                  onClick={this.toggleVideoUrl}
                >
                  {enableVideoUrl
                    ? "Upload the Video Instead"
                    : "Add Video URL Instead (youtube, vimeo, wistia etc.)"}
                </div>
                <FloatingInputWithAdorment
                  disabled={true}
                  id="thumbnailUrl"
                  className={"textField fileInput"}
                  value={thumbnailFileName}
                  overrideContainerCss={classes.marginBottomZero}
                  label={"Add Custom Preview Thumbnail"}
                  adormentRootClass={classes.adormentRootClass}
                >
                  <div className="fileInfoContainer">
                    <div className="fileName">{thumbnailFileName + " "}</div>
                    {(uploadingThumbnailFile === false ||
                      uploadingThumbnailFile === true) &&
                    thumbnailFileName ? (
                      <div className="fileUploadTxt">
                        {uploadingThumbnailFile === false
                          ? `Uploaded`
                          : `Uploading ${thumbnailUploadProgress}%`}
                      </div>
                    ) : null}
                  </div>
                  <input
                    accept="image/*"
                    className={"input"}
                    id="thumbnail-file"
                    type="file"
                    disabled={uploadingVideoFile}
                    onChange={this.props.handleThumbnailSelect}
                    margin={"dense"}
                  />
                  <label htmlFor="thumbnail-file">
                    <Button
                      disableRipple
                      variant="contained"
                      component="span"
                      disabled={uploadingVideoFile}
                      className={classes.adornmentButton}
                    >
                      Choose File
                    </Button>
                  </label>
                </FloatingInputWithAdorment>
              </div>
              <div
                className={classNames("thumbnailContainer", {
                  hasVideo: attachmentVideoUrl,
                })}
              >
                {!playerEnabled && thumbnailImage ? (
                  <React.Fragment>
                    {attachmentVideoUrl && (
                      <IconButton
                        overrideContainerClassName={classes.playButton}
                        width={113}
                        height={32}
                        onClick={() => this.enablePlayer()}
                        text={"Play Video"}
                        textColor={"#FFFFFF"}
                        position={"left"}
                        removeIconPadding={true}
                        color={"#27AE60"}
                        icon={<PlayCircleFilledIcon className="playIcon" />}
                      />
                    )}
                    <img className="thumbnailImage" src={thumbnailImage} />
                  </React.Fragment>
                ) : (playerEnabled || !thumbnailImage) && attachmentVideoUrl ? (
                  <ReactPlayer
                    style={{ width: "100%", height: "100%" }}
                    controls={true}
                    className="react-player"
                    url={
                      isObject(attachmentVideoUrl)
                        ? (attachmentVideoUrl.url || "").replace(
                            /\.(m4v)/i,
                            ".mp4"
                          )
                        : (attachmentVideoUrl || "").replace(/\.(m4v)/i, ".mp4")
                    }
                    onError={() =>
                      this.fallBackToDifferentFormat(attachmentVideoUrl)
                    }
                  />
                ) : (
                  <div className="thumbnailTxt">Upload Thumbnail</div>
                )}
              </div>
            </div>
            <div
              className={classNames("flex-row", classes.formRowMargin)}
              style={{ paddingTop: 10 }}
            >
              <div
                className={classNames(
                  classes.toggleSwitchContainer,
                  "flex-row"
                )}
              >
                <div className={classes.subHead}>Access Controls:</div>
                <ToggleButton
                  buttonEnabledText="All Users"
                  buttonDisabledText="Coaches"
                  value={!activity.isCoachesOnly}
                  onChange={this.props.handleChange("isCoachesOnly")}
                />
              </div>
            </div>
          </div>
          <div className={"modalFooterContainer"}>
            <Button
              type="submit"
              className={"cancelButton"}
              value={1}
              onClick={() => {
                this.resetModal();
                this.props.close();
              }}
            >
              {activity.isNewActivity ? "Discard" : "Discard Changes"}
            </Button>
            <div className="saveButtonContainer">
              {activity.isNewActivity ? (
                <FormControlLabel
                  className="saveAnotherCheckBox"
                  value={addAnother.toString()}
                  control={
                    <Checkbox
                      color="primary"
                      classes={{ root: classes.checkboxRoot }}
                    />
                  }
                  label="Create another after saving"
                  labelPlacement="end"
                  onChange={this.toggleAddAnother}
                />
              ) : null}
              <Tooltip
                title={validationText}
                placement="top"
                disableFocusListener
                disableTouchListener
              >
                <span>
                  <Button
                    disabled={!!validationText}
                    type="submit"
                    className={"saveButton"}
                    classes={{
                      disabled: classes.buttonDisabled,
                    }}
                    onClick={this.handleSave}
                  >
                    {activity.isNewActivity
                      ? "Create New Activity"
                      : "Update Activity"}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </LegacyModalContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    session: { appContext },
    trainings,
  } = state;

  return {
    libraries: trainings.libraryInfo || [],
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(NewWorkoutActivityModal)
);
