import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import logo from '../assets/images/icon-114x114.png';
import loginLogo from '../assets/images/logo-dark.png';
import homeImage from '../assets/images/M1Welcome.jpg';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'maxone',
    name: 'MaxOne',
    appUrl: 'm1://',
    domain: 'app.gomaxone.com',
    link: 'https://itunes.apple.com/us/app/maxone/id1274244831?mt=8',
    onAppleAppStore: 'https://apps.apple.com/us/app/maxone/id1274244831',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.athletes&hl=en'
  },
  image: {
    home: homeImage,
    logo: logo,
    loginLogo: loginLogo
  },
  palette: {
    type:"light",
    primary: {
      main: '#00a1e1'
    },
    secondary: {
      main:"#CFDE00"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type:"light",
    primary: {
      main: '#00a1e1'
    },
    secondary: {
      main:"#CFDE00"
    },
    white: "white",
    error: red,
  }

});
