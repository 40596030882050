import React, { Component } from 'react'
import BorderedDropdown from '../borderedDropdown'
import './styles.css'

export default class TempoInput extends Component{
    constructor(props){
        super(props)
        this.state = {
            tempo:this.props.tempo
        }
    }
    handleIncrease = index => {
        const { handleChange } = this.props;
        const {tempo = [null, null, null, null, null, null, null] } = this.state
        if(tempo[index] < 10)
            tempo[index] = (tempo[index]||0) + 1
        for(var i=0; i <= index;i++){
            if(tempo[i] === null){
                tempo[i] = 0;
            }
        }
        this.setState({tempo})
        handleChange(tempo)   
    }

    handleDecrease = index => {
        const { handleChange } = this.props;
        const { tempo = [null, null, null, null, null, null, null] } = this.state
        if(tempo[index] > 0)
            tempo[index] = tempo[index] - 1
        else {
            tempo[index] = null;
            for(var i=index + 1; i < tempo.length;i++){
                tempo[i] = null;
            }
        }
        this.setState({tempo})
        handleChange(tempo)   
    }

    handleChange = (value,index) => {
        const { handleChange } = this.props;
        const { tempo = [null, null, null, null, null, null, null] } = this.state
        value = value.toString().split("").pop();
        value = parseInt(value)||0;
        if(value < 10){
            tempo[index] = value;          
            for(var i=0; i <= index;i++){
                if(tempo[i] === null){
                    tempo[i] = 0;
                }
            }
            this.setState({tempo});
        }
    }

    render(){
        const { tempo = [null, null, null, null, null, null, null] } = this.state
        return(
            <div className={'tempoInputContainer'}>
                {tempo.map((t, index) => {
                    return(
                        <BorderedDropdown 
                            placeholder="--"
                            value={t}
                            key={index}
                            increment={true}
                            onKeyPress={(e) => this.handleChange(e.target.value,index)}
                            handleChange={(e) => this.handleChange(e.target.value,index)}
                            handleIncrease={() => this.handleIncrease(index)}
                            handleDecrease={() => this.handleDecrease(index)}
                        />
                    )
                })}
            </div>
        )
    }
}