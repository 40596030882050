import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '../nav/pageWrapper';
import { getLocalizedString } from '../../utils/locale-utils';
import { withStyles } from '@material-ui/core/styles';
import BodyWrapper from '../../components/bodyWrapper';
import DropDownWithSearch from "../../components/dropDownWithSearch";
import MetricCard from '../../components/metricCard';
import FloatingSelectWithCustomChild from '../../components/floatingSelectWithCustomChild'
import SearchInput from '../../components/searchInput';
import { GridItemButton } from 'maxone-react-ui';
import classnames from 'classnames';
import ThemeButton from '../../components/themeButton'
import { scoreTypesMap } from '../../constants.js'
import { activityClient, workoutClient, onboardingClient, tagClient, contentCopyClient, dynamicLibraryClient } from '../../clients';
import CheckboxWithLabel from '../../components/checkboxWithLabel';
import InfiniteScroll from 'react-infinite-scroller';
import SelectTeamsModal from '../selectTeamsModal';
import _ from 'lodash';
import optionsSelector from 'maxone-workout-programs/dist/components/optionsSelector';
const styles = theme => ({
    topSection: {
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        marginBottom:"20px",
        padding:"9px 15px 0px",
        transition: "top 0.6s"
    },
    title:{
        fontWeight: 600,
        fontSize: "26px",
        lineHeight: "120%", 
        color: "#75797B",
        marginBottom:"10px"
    },
    optionContainer:{
        marginTop:"18px",
        marginLeft:"-20px"
    },
    metric:{
        background:"#ffffff",
        cursor:"pointer"
    },
    header: {
        paddingBottom: "20px",
        paddingTop: "5px",
        position: "sticky",
        top: "15px",
        background:"#EDEFF0",
        zIndex:1
    },
    headerSubSection :{    
        minWidth: "666px",
        "& > div":{
            background:"#ffffff"
        }
    },
    searchBox:{
        marginRight:"17px",
        borderRadius:"4px",
        height:"48px",
        maxWidth:"393px"
    },
    gridContainer:{
        cursor:"pointer",
        maxWidth: "500px",
        minWidth: "350px",
        flex: 1,
        marginRight: "20px",
        marginBottom: "20px",
        "& > div":{
            background:"#ffffff",
            borderRadius:"8px"
        }    
    },
    disabledMetrics:{
        opacity:"0.6"
    },
    selectedOption:{
        background:"#1354F9",
        "& div":{
            color:"#ffffff !important",
            "& svg":{
                color:"#ffffff !important"
            }
        }
    },
    gridFooter:{
        fontSize:"14px",
        fontWeight:500
    },
    listing:{
        position:"relative",
        marginRight:"-20px",
        maxHeight: "80vh",
        overflow: "scroll",
        "& > div":{
            display: "flex ",
            flexWrap:"wrap"
        }
    },
    selectedGrid:{
        "& div":{
            background:"#1354F9 !important",
            color:"#ffffff !important"
        }
    },
    categoryFilter:{
        marginBottom:"15px",
        "& label":{
            marginRight:"12px"
        }
    },
    count:{
        color:"#5B6062"
    },
    addContentLoading:{
        width: "100%",
        marginTop:"10px",
        marginBottom:"10px"
    },
    shareBtn:{
        bottom: "20px",
        position: "fixed",
        right: "40px"
    }
});

const OPTIONS = Object.freeze({
    ACTIVITIES:"ACTIVITIES",
    WORKOUTS:"WORKOUTS",
    PROGRAMS:"PROGRAMS"
});

const OPTION_SINGULAR = Object.freeze({
    ACTIVITIES:"ACTIVITY",
    WORKOUTS:"WORKOUT",
    PROGRAMS:"PROGRAM"
});

class ContentSharing extends Component {
    constructor(props) {
        super(props);
        this.limit = 20;
        this.state = {
            menuItemData: [],
            formattedData:[],
            currentPage:-1,
            selectedDataIds: new Set(),
            selectedCategoryFilters: new Set(),
            filterClearParam:{
                currentPage:-1,
                total:0,
                selectedDataIds: new Set()
            },
            defaultParam:{
                currentPage:-1,
                total:0,
                selectedDataIds: new Set(),
                formattedData:[], 
                tagMenuData: null,              
                selectedCategoryFilters: new Set(),
                selectedTagFilter: null,
                searchTerm:"",
                isSelectAllEnabled:false
            },
            checkboxDisabled:{
                opacity:0.4
            }
        }
    }

    componentDidMount() {
        let { teams, currentTeam } = this.props;
        let menuItemData = [
            {
                value: currentTeam.organizationId,
                displayValue: "Organization Content",
                isOrg:true
            }
        ]
        menuItemData = menuItemData.concat(teams.map((team, i) => {
            return {
                value: team.id,
                displayValue: team.customName || `${team.name} ${team.sport}`
            }
        }));
        let selectableOptions = [
            { label:OPTIONS.ACTIVITIES, icon: "PlayCircle", value:0},
            { label: OPTIONS.WORKOUTS, icon: "Whistle", value:0 },
            { label: OPTIONS.PROGRAMS, icon: "Athletes", value:0 }
        ]
        this.setState({ menuItemData, selectableOptions })
    }

    selectTeam = async (value) => {
        let { tenant, currentTeam } = this.props;
        let { selectableOptions, defaultParam } = this.state;
        this.setState({ selectedTeamId: value, isChildLoading:true });
        let isOrg = currentTeam.organizationId == value;
        let computedOptions = [];
        let tagSlugMap = {};
        let librarySlugMap = {};
        try {
            let promises = [];
            let [{ data }, { data: { results : tagsList} }, { data: { libraries, id: tenantLibraryId }}] = await Promise.all([onboardingClient.getOnboardingByTeamId(isOrg?currentTeam.id:value, tenant), 
                tagClient.getAvailableTagsByTeamId(isOrg?currentTeam.id:value), dynamicLibraryClient.getDynamicLibraryByParentId(isOrg?"ORGANIZATION":"TEAM", value)]);
            (tagsList||[]).forEach( tag => {
                tagSlugMap[tag.slug] = tag.label;
            });
            let metrics = data.metrics || [];
            let metricsObj = {};
            metrics.forEach(metric => {
                metricsObj[(metric.label||"").toUpperCase()] =  metric.value;                
            });
            selectableOptions.forEach(option => {
                computedOptions.push({...option, value: isOrg? 0: metricsObj[option.label]});
            });
            (libraries||[]).forEach(item => {
                librarySlugMap[item.value.toLowerCase()] = item.label;              
            });
        }
        catch(e){

        }
        this.setState({...defaultParam, distinctCategories: null,
             selectedOption:isOrg? OPTIONS.ACTIVITIES:null, selectableOptions: computedOptions,
              isChildLoading: isOrg||false, tagSlugMap, librarySlugMap, isOrg}, ()=>{
            if(isOrg){
                this.getData(0)
            }
        });
    }

    toggleFilterMenu = () => {
        let { isSelectingTag } = this.state;
        this.setState({ isSelectingTag: !isSelectingTag });
    }

    handleTagSelection = (tagSlug) => {
        let { filterClearParam } = this.state; 
        this.setState({selectedTagFilter : tagSlug, isChildLoading: true, ...filterClearParam},  ()=>this.getData(0))
    }

    handleSearch = (searchTerm) => {
        let { filterClearParam } = this.state;
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }
        this.setState({
            searchTerm
        }, () => {
            this.typingTimeout = setTimeout(() => {                
                this.setState({isChildLoading:true, ...filterClearParam }, ()=>this.getData(0));
            }, 1000)
        });
      
    }

    getData = async (currentPage) => {   
        let tagMenuData = [];
        let { classes } = this.props;
        let { searchTerm, selectedOption, selectedTeamId, selectedCategoryFilters, 
            formattedData: existingData, tagSlugMap, selectedTagFilter, tagMenuData: existingTagMenuData, 
            distinctCategories:existingCategories, librarySlugMap, isSelectAllEnabled, selectableOptions, isOrg } = this.state;
        if(isOrg){
            tagSlugMap = {};
        }
        let selectedDataIds = new Set();
        let formattedData=[], formattedDistinctCategories = [];
        let results, total, distinctCategories, distinctTags;
        this.setState({ isFetchingData: true});
        
        //User data 
        let params = {
            limit: this.limit,
            offset:currentPage * this.limit   
        }
        if(!!selectedCategoryFilters.size){
            params.category = Array.from(selectedCategoryFilters).join(", ");
        }
        if(searchTerm){
            params.q = searchTerm;
        }
        if(!!selectedTagFilter){
            if(selectedOption == OPTIONS.PROGRAMS){
                params["tags.slug"] = selectedTagFilter;
            }
            else {
                params["tags.slug.keyword"] = selectedTagFilter;
            }
        }
        try{
            ({ results, total, distinctCategories, distinctTags, distinctCategories } = await this.fetchData(selectedOption, selectedTeamId, params));
            if(isSelectAllEnabled || isOrg){                
                let dataFetchedCount = existingData.concat(results).length;
                if ((total - dataFetchedCount) != 0) {
                    //Poll Remaining data
                    let pagesToBeFetched = Math.ceil((total - dataFetchedCount) / this.limit);
                    let promises = [];
                    for (let i = 1; i <= pagesToBeFetched; i++) {
                        currentPage = currentPage + 1;
                        params.offset = currentPage * this.limit;
                        promises.push(this.fetchData(selectedOption, selectedTeamId, params))
                    }
                    let data = await Promise.all(promises);
                    data.forEach(({ results: dataFetched }) => {
                        results = results.concat(dataFetched);
                    })
                }
            }

            let mappedCategoryData = {};
            if(selectedOption == OPTIONS.ACTIVITIES){
                (distinctCategories||[]).forEach(category => {
                    mappedCategoryData[category.slug] = category.total;
                });
                if((existingCategories||[]).length){
                    distinctCategories = existingCategories
                };
                (distinctCategories||[]).forEach(item => {
                    let data = {};
                    data.slug = item.slug;
                    data.value = item.slug;
                    data.total = item.total;
                    data.label = <div className={"d-flex"}>{librarySlugMap[item.slug.toLowerCase()]} <div className={classes.count}>({data.total})</div></div>;
                    if(!!librarySlugMap[item.slug.toLowerCase()]){
                        formattedDistinctCategories.push(data);
                    }
                });
            }
            (results||[]).forEach((item) => {
                let { name, id, title, tags } = item;
                let data = {};
                data.title = `${title||name||""}`;
                data.subtitle =`${(tags||[]).map(e => e.label).join(", ")}`;
                data.footerData =  this.getGridFooterElmnt(selectedOption, item);
                data.id = id;
                if(isOrg){
                    (tags||[]).forEach(tag => tagSlugMap[tag.slug] = tag.label)                    
                }
                formattedData.push(data);
            });
            tagMenuData = (distinctTags||[]).map(tag=> {return {
                value:tag.slug,
                displayValue:tagSlugMap[tag.slug]
            }})
            if(currentPage != 0){
                formattedData = existingData.concat(formattedData)
            }
            if(isSelectAllEnabled){
                selectedDataIds = new Set(formattedData.map(item => item.id));
            }
        }
        catch(e){
            console.log(e);
        }
        if(!!selectableOptions && isOrg){
            selectableOptions = selectableOptions.map(item => {
                return {...item, value: item.label == OPTIONS.ACTIVITIES? total:0 }
                }
            );
        }
        this.setState({isFetchingData: false, tagMenuData: existingTagMenuData||tagMenuData, formattedData,
             total, loadingChild: false, currentPage, isChildLoading: false,
            distinctCategories: formattedDistinctCategories, selectedDataIds,
            selectableOptions
        });
    }

    fetchData = async (selectedOption, selectedTeamId, params) => {
        let results, total, distinctCategories, distinctTags;
        try{
            switch (selectedOption){
                case OPTIONS.PROGRAMS: ({ data: {total, results, meta:{ distinctTags }} } = await workoutClient.searchWorkoutProgramsByTeamId(selectedTeamId, params));break;
                case OPTIONS.WORKOUTS:  ({ data:  {total, results, meta:{ distinctTags }} } = await workoutClient.searchWorkoutsByTeamId(selectedTeamId, params));break;
                case OPTIONS.ACTIVITIES:   ({ data: {total, results, meta:{distinctCategories, distinctTags }} } = await activityClient.searchActivities({...params, "parentId.keyword": selectedTeamId }));
            }
        }
        catch(e){
            console.log(e);
        }
        return {
            results, total, distinctCategories, distinctTags, distinctCategories
        }
    }

    getGridFooterElmnt = (type, data) => {
        let footerData = {};
        switch (type){
            case OPTIONS.PROGRAMS: {
                let workouts = 0;
                let weeks = 0;
                (data.weeks || []).forEach(week => {
                    (week || []).forEach(day => {
                        if(_.isArray(day)){
                            (day || []).forEach(workout => {
                                if (workout.name) {
                                    workouts++;
                                }
                            })
                        }
                        else if(!!day && day.name){
                            workouts++;
                        }
                    });
                    if(workouts){
                        weeks++;
                    }
                });
                footerData.main = `${weeks} weeks`;
                footerData.subSection = `${workouts} workouts`;
                break;
            }
            case OPTIONS.WORKOUTS:  footerData.main = `${data.totalActivities||0} drills`;  break;
            case OPTIONS.ACTIVITIES: footerData.main = scoreTypesMap[(data.scoreType||"").toLowerCase()];  break;
        }  
        return footerData;    
    }

    selectMetricOption = (selectedOption) => {
        let { defaultParam } = this.state;        
        this.setState({...defaultParam, distinctCategories: null, isChildLoading:true, selectedOption}, () => this.getData(0))
    }

    selectDataValue = (id) => {
        let { selectedDataIds } = this.state;
        let clonedIds = new Set(selectedDataIds);
        if (selectedDataIds.has(id)) {
            clonedIds.delete(id);
        }
        else {
            clonedIds.add(id);
        }
        this.setState({ selectedDataIds: clonedIds })
    }

    selectAll = () => {
        let { isSelectAllEnabled, currentPage, formattedData, total } = this.state;
        let needToFetchMoreData = !isSelectAllEnabled && formattedData.length < total;
        let selectedDataIds = new Set();
        if(!isSelectAllEnabled){
            selectedDataIds = new Set(formattedData.map(item => item.id));
        }
        this.setState({ isSelectAllEnabled: !isSelectAllEnabled, selectedDataIds, isChildLoading: needToFetchMoreData }, () => {
            if (needToFetchMoreData) {
                this.getData(currentPage + 1)
            }
        })
    }

    handleCategoryFilter = (filterValue) => {
        let { selectedCategoryFilters, defaultParam, distinctCategories} = this.state;
        let clonedValue = new Set(selectedCategoryFilters);
        if(clonedValue.has(filterValue)){
            clonedValue.delete(filterValue);
        }
        else{
            clonedValue.add(filterValue);
        }
        this.setState({...defaultParam, selectedCategoryFilters: clonedValue, isChildLoading:true, distinctCategories }, ()=>this.getData(0));
    }

    toggleSelectTeamModal = () => {
        let { selectTeamModalOpen } = this.state;
        this.setState({selectTeamModalOpen: !selectTeamModalOpen});
    }

    handleTransfer = async (teamIds) =>{
        const { selectedDataIds, selectedOption, defaultParam } = this.state;
        const { currentUser, enableFullScreenLoader } = this.props;
        enableFullScreenLoader(true, "Please wait while we process your request");
        let type;
        switch(selectedOption){
            case OPTIONS.PROGRAMS: type = "workout_program"; break;
            case OPTIONS.WORKOUTS: type = "workout"; break;
            case OPTIONS.ACTIVITIES: type = "activity"; break;
        }
        const data = [];
        teamIds.forEach((teamId) => {
            selectedDataIds.forEach(id => data.push({ type, id, teamId, includeNested: (selectedOption == OPTIONS.WORKOUTS || selectedOption == OPTIONS.PROGRAMS) }));
        });
        const { data: { id, url } } = await contentCopyClient.createProcessByUserId(currentUser.id);
        await fetch(url, {
            method: 'put',
            body: Buffer.from(JSON.stringify(data)),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        try {
            await contentCopyClient.beginProcessById(id);
        } catch (e) {
            console.log(`Error beginning process by ID ${id}`);
        }
        enableFullScreenLoader(true, "We’ve got it from here! It may take some time for the activities, workouts or programs to show up in the destination teams.", "Feel free to use the platform while we complete the transfer behind the scenes!");
        this.setState({selectTeamModalOpen: false, selectedDataIds:new Set(), selectedCategoryFilters: new Set()});
        setTimeout(()=>enableFullScreenLoader(false), 2500);
    }

    render() {
        const { classes, appContext, currentTeam, tenant, libraries, tenantName } = this.props;
        const { isChildLoading, isLoading, loadingMessage, selectedCategoryFilters,
            menuItemData, selectedTeamId, selectableOptions,
            currentPage, isFetchingData, total, tagMenuData,
            selectTeamModalOpen,
            isSelectingTag, selectedTagFilter, searchTerm, distinctCategories, selectedOption, formattedData, selectedDataIds, isSelectAllEnabled, isOrg } = this.state;
        return (
            <PageWrapper removePadding={true} title={getLocalizedString("CONTENT_SHARING")} isPageLoading={isLoading} loadingMessage={loadingMessage}  {...this.props}>
                <BodyWrapper showLoadingChild={true} isLoading={isChildLoading}>
                    <div className={classes.topSection}>
                        <div className={classes.title}>{tenantName} Default Training Libraries</div>
                        <DropDownWithSearch
                            value={selectedTeamId}
                            placeholder={"Select a source team"}
                            onChange={this.selectTeam}
                            menuItemData={menuItemData}
                            dropdownHeight={"550px"}
                        />
                        <div className={classnames(classes.optionContainer,"d-flex", {[classes.disabledMetrics]:!selectedTeamId})}>
                            {(selectableOptions || []).map(option => (!isOrg || (isOrg && option.label == OPTIONS.ACTIVITIES)) && <MetricCard label={option.label} icon={option.icon} value={option.value} overrideClass={classnames(classes.metric, {[classes.selectedOption]: option.label == selectedOption})} onClick={()=>selectedTeamId? this.selectMetricOption(option.label): null}/>)}
                        </div>
                    </div>
                    
                    {!!(distinctCategories || []).length &&
                        <div className={classnames(classes.categoryFilter, "d-flex flex-wrap")}>
                            {distinctCategories.map(category => <div className={classnames({[classes.checkboxDisabled]: category.total})}><CheckboxWithLabel
                                label={category.label}
                                checked={selectedCategoryFilters.has(category.value)}
                                onChange={() => this.handleCategoryFilter(category.value)}
                            /></div>)}
                        </div>
                    }
                    {!!selectedOption && <div className={classes.bottomSection}>
                        <div className={classnames(classes.header, "d-flex align-items-center justify-content-between")}>
                            <div className={classnames(classes.headerSubSection, "d-flex")}>
                                <SearchInput placeholder={`Search ${(selectedOption||"").captialize()}`} customClass={classes.searchBox} searchTerm={searchTerm} handleSearch={this.handleSearch} />
                                <FloatingSelectWithCustomChild
                                    value={selectedTagFilter}
                                    label="Filter by Tags"
                                    onChange={value => this.handleTagSelection(value)}
                                    dropDownLabel="-Select-"
                                    menuItemData={tagMenuData||[]}
                                    showDropDownBorder={true}
                                    enableRemoveBtn={true}                            
                                />
                            </div>
                            <ThemeButton
                                width={"96px"}
                                color={"#1354F9"}
                                height={"44px"}
                                theme="small"
                                text={isSelectAllEnabled ? "Deselect All":"Select All"}
                                disabled={!formattedData.length}
                                onClick={this.selectAll}
                            />
                        </div>
                        <div className={classnames(classes.listing)} ref={(ref) => this.scrollParentRef = ref}>
                            <InfiniteScroll
                                pageStart={currentPage}
                                loadMore={() => isFetchingData ? null : this.getData(currentPage+1)}
                                hasMore={formattedData.length < total}
                                useWindow={false}
                                getScrollParent={() => this.scrollParentRef}
                                loader={<div className={classes.addContentLoading}>Loading ...</div>}
                            >
                                {formattedData.map(item => <div className={classnames(classes.gridContainer, { [classes.selectedGrid]: selectedDataIds.has(item.id)})} onClick={() => this.selectDataValue(item.id)}>
                                    <GridItemButton
                                        subtitle={item.subtitle}
                                        title={item.title}
                                        footerContainer={<div className={classnames(classes.gridFooter, "d-flex justify-content-between align-items-center")}>
                                            <div className={classes.cardCountInfo}>{(item.footerData || {}).main}</div>
                                            {!!(item.footerData || {}).subSection && <div className={classes.subSection}>{item.footerData.subSection}</div>}
                                        </div>}
                                    />
                                </div>)}
                            </InfiniteScroll>
                            <div className={classes.shareBtn}>
                                <ThemeButton
                                    width={"120px"}
                                    color={"#27AE60"}
                                    height={"44px"}
                                    theme="small"
                                    text={`Share ${selectedDataIds.size} ${((OPTION_SINGULAR[selectedOption]).pluralize(OPTIONS[selectedOption],selectedDataIds.size)).captialize()}`}
                                    disabled={!selectedDataIds.size}
                                    onClick={this.toggleSelectTeamModal}
                                />
                            </div>
                        </div>
                    </div>}
                    {selectTeamModalOpen && <SelectTeamsModal                    
                        title={`Share Content: ${(selectedOption||"").captialize()}`}
                        open={true}
                        save={this.handleTransfer}
                        close={this.toggleSelectTeamModal}
                    />}
                </BodyWrapper>
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    const { tenant, session: { userContext, appContext } } = state;
    const currentTeam = userContext.appContextList.find(c => c.id === appContext.id);
    return {
        appContext: appContext,
        currentTeam: currentTeam || {},
        currentUser: userContext.user,
        libraries: appContext.libraries || [],
        teams: (userContext.appContextList || []).filter(data => data.isTeam),
        tenantName: tenant.name
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ContentSharing));