import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ProgressStep from '../progressStep';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import { ROUTE_PATH } from '../../../routeUtil'
import ThemeButton from "../../../components/themeButton";
import { testId } from '../../../utils/test-utils';
const styles = {
    importSummary:{
        marginTop:"96px"
    },
    head:{
        fontSize: "26px",
        lineHeight: "120%",
        fontWeight: 600,
        marginBottom:"16px"
    },
    txt:{
        width:"484px",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "150%",
        textAlign: "center",
        color: "#27292A",
        marginBottom:"20px"
    },
    serviceButtonContainer:{
        "& div:nth-child(2)":{
            marginLeft:"16px"
        }
    }
};

class ReviewAndInvite extends Component {

    navigate = (routeName) => {
        this.props.history.push(routeName)
    }


    render() {
        const { classes, isInviteSent } = this.props;
        return (
            <React.Fragment>
                <BodyWrapper>
                    <div className={classnames(classes.importSummary, "d-flex flex-column align-items-center")}>
                        <div className={classes.head}>
                            {isInviteSent ? "Import & Invite Complete" : "Import Process Complete"}
                        </div>
                        {
                        isInviteSent ? <div className={classes.txt}> Your Athletes are currently being imported into your organization. Once the import is completed, the invites will be sent out. Depending on the number of athletes, this may take several minutes to several hours. When the import is finished, the new athletes can be found in <span className="font-weight-bold">Invites</span> where you have the ability to resend invitation if needed. <br/><br/>
                            Click on Manage Athlete Invites to go to your new athletes. Otherwise, return to the Dashboard where you can explore the MaxOne Virtual Training Platform. 
                        </div>
                            :
                        <div className={classes.txt}>
                            Your Athletes are currently being imported into your organization. This may take several minutes based on the number of athletes. When the import is finished, the new athletes can be found in <span className="font-weight-bold">Invites.</span> <br/><br/>
                            Click on <span className="font-weight-bold">Manage Athlete Invites</span> to go to your new athletes now. Otherwise, you can return to the Dashboard. 
                        </div>
                        }

                        <div className={classnames(classes.serviceButtonContainer, "d-flex align-items-center justify-content-center")} {...testId(`buttons-container`)}>
                            <ThemeButton
                                width={"293px"}
                                height={"70px"}
                                onClick={()=>this.navigate(ROUTE_PATH.ATHLETES_IMPORT_ORG_STATUS)}
                                text="Manage Athlete Invites"                       
                            />                            
                            <ThemeButton
                                width={"271px"}
                                height={"70px"}
                                onClick={()=>this.navigate(ROUTE_PATH.HOME)}
                                text="Return to Dashboard"
                                color={"#ffffff"}
                                textColor={"#5B6062"}
                            />
                        </div>
                    </div>
                </BodyWrapper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ReviewAndInvite);