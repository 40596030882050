import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import QRCode from 'qrcode.react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "../roster.css";
import LegacyModalContainer from '../../../components/modalContainer/legacyModalContainer';

const styles = {

}


class AddWCSAthleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rosterFilterBy: "nameLast",
            renderedAthletes: [],
            searchText: ""
        };
    }

    handleRemoveClick = contact => {
        this.props.remove(contact);
    }

    handleEditClick = contact => {
        this.props.edit(contact);
    }

    handleFilter = filterValue => event => {
        this.setState({
            rosterFilterBy: filterValue
        })
    }

    componentDidMount() {
        this.setState({ renderedAthletes: this.props.wcsAthletes })
    }
    componentWillReceiveProps(props) {
        this.setState({ renderedAthletes: props.wcsAthletes })
    }

    searchAthletes = event => {
        const { wcsAthletes } = this.props;
        if (event.target.value) {
            this.setState({
                searchText: event.target.value,
                renderedAthletes: _.filter(wcsAthletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()) || `${a.nameFirst} ${a.nameLast}`.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedAthletes: wcsAthletes
            })
        }
    }


    render() {
        const { classes, wcsAthletes, contacts, value, close, currentTeam = {}, userContext } = this.props;
        const { rosterFilterBy, renderedAthletes, searchText } = this.state
        return (
            <LegacyModalContainer open={this.props.open} onClose={close} containerClassName={"rosterPaper"}>
                <div className={"modalHeaderContainer"}>
                    <h2 className={"modalHeaderText"}>Add Athletes</h2>
                </div>
                <div className={"outerModalContainer"}>
                    <Grid container direction="column" justify="center" alignItems="center" style={{ border: '1px solid primary' }}>
                        <Grid item style={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                textColor="primary"
                                indicatorColor="primary"
                                className={"tabContainer"}
                                onChange={this.props.handleTabValueChange}
                                style={value == 1 ? { marginTop: -15 } : {}}
                            >
                                {window.location.href.toLowerCase().includes('wcs') && !userContext.isWCSAdmin ? null : <Tab label="Invite Code" value={0} />}
                                <Tab label="WCS Roster" value={window.location.href.toLowerCase().includes('wcs') && !userContext.isWCSAdmin ? 0 : 1} />
                            </Tabs>
                        </Grid>
                        {
                            value || (window.location.href.toLowerCase().includes('wcs') && !userContext.isWCSAdmin)
                                ?
                                <Grid item style={{ width: '100%' }}>
                                    <Grid container direction="column" justify="center" alignItems="center" style={{ border: '1px solid primary' }}>
                                        <Grid item style={{ width: '100%' }}>
                                            {contacts.length ? <div className={"contactsChipContainer"}>
                                                {
                                                    contacts.length === 0
                                                        ?
                                                        <div style={{ marginLeft: 10, color: 'darkgray' }}>
                                                        </div>
                                                        :
                                                        <div>{contacts.map(contact => (
                                                            <Chip
                                                                label={`${contact.nameFirst} ${contact.nameLast}`}
                                                                onDelete={() => this.handleRemoveClick(contact)}
                                                                className={"chip"}
                                                                clickable={true}
                                                                onClick={() => this.handleEditClick(contact)}
                                                                key={`${contact.nameFirst}-${contact.nameLast}`} />
                                                        ))}</div>
                                                }
                                            </div>
                                                : null}
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" justify="center" alignItems="center" style={{ marginBottom: 15 }}>
                                        <TextField
                                            id="search"
                                            value={searchText ? searchText : ''}
                                            className={"searchTextField"}
                                            onChange={this.searchAthletes}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CloseIcon onClick={() => this.setState({ searchText: '', renderedAthletes: wcsAthletes })} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C', marginTop: 25 }}>
                                        <Grid container className={"content"}>
                                            <Grid item xs={4} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                                <div className={"nameColumn"} style={{ paddingTop: 15 }} >
                                                    <p className={"textContainer"} style={{ paddingLeft: 15, paddingRight: 15, color: 'white' }}>
                                                        <strong>Last Name</strong>
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                                <div className={"nameColumn"} style={{ paddingTop: 15 }} >
                                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                                        <strong>First Name</strong>
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} className={"rosterColumnContainer"} onClick={this.handleFilter('graduationYear')}>
                                                <div className={"nameColumn"} style={{ paddingTop: 15 }} >
                                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                                        <strong>Grad Year</strong>
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={"wcsUserContainer"} container style={{ overflow: "scroll", maxHeight: contacts && contacts.length ? 160 : 230, width: "100%", border: '1px gray solid' }}>
                                        {
                                            _.orderBy(renderedAthletes, [rosterFilterBy, 'nameLast']).map((athlete, index) => {
                                                return (<Grid item xs={12} key={index} className={"atheleteWCSGridItem"}>
                                                    <Grid container className={"content"} onClick={() => this.props.push(athlete)}>
                                                        <Grid item xs={4} className={"rosterColumnContainer"}>
                                                            <div className={"nameColumn"} style={{ paddingTop: 15 }} >
                                                                <p className={"textContainer"} style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                                    {athlete && athlete.nameLast ? athlete.nameLast : ""}
                                                                </p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4} className={"rosterColumnContainer"}>

                                                            <div className={"nameColumn"} style={{ paddingTop: 15 }} >
                                                                <p className={"textContainer"}>
                                                                    {athlete && athlete.nameFirst ? athlete.nameFirst : ""}
                                                                </p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4} className={"rosterColumnContainer"}>

                                                            <div className={"nameColumn"} style={{ paddingTop: 15 }} >
                                                                <p className={"textContainer"}>
                                                                    {athlete && athlete.graduationYear ? athlete.graduationYear : ""}
                                                                </p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>)
                                            })
                                        }
                                        {
                                            !searchText && !renderedAthletes.length &&
                                            <div className={"rosterLoadingOverlay"} style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                                                <CircularProgress style={{ marginTop: 5 }} />
                                                <h4 style={{ textAlign: "center" }}>{"It looks like this is the first time you're adding your athletes so this may take a little bit. Thank you for your patience!"}</h4>
                                            </div>
                                        }
                                        {
                                            searchText && !renderedAthletes.length &&
                                            <div className={"rosterLoadingOverlay"} style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                                                <h4 style={{ textAlign: "center" }}>{"No Results"}</h4>
                                            </div>
                                        }
                                    </Grid>
                                    <div className={"modalFooterContainer"}>
                                        <div>
                                            <Button
                                                type="submit"
                                                className={"cancelButton"}
                                                onClick={this.props.cancel}
                                            >
                                                Cancel
                                </Button>
                                            <Button
                                                type="submit"
                                                className={classes.modalInviteButton}
                                                onClick={this.props.send}
                                            >
                                                Add
                                </Button>
                                        </div>
                                    </div>
                                </Grid>
                                :
                                <Grid item style={{ width: '100%' }}>
                                    <div>
                                        <div className={"codeContainer"} >
                                            <div className={"codeOptionContainer"}>
                                                <h3>Scan</h3>
                                                <p>Have athletes scan from your screen</p>
                                            </div>
                                            <div className={"codeOptionContainer"}>
                                                <h3>Type</h3>
                                                <p>Send the following code to your athletes</p>
                                            </div>
                                        </div>
                                        <div className={"codeContainer"} >
                                            <div className={"codeOptionContainer"} style={{ height: 180 }}>
                                                <     QRCode
                                                    value={currentTeam.playerCode}
                                                    size={180}
                                                />
                                            </div>
                                            <div className={"codeOptionContainer"}>
                                                <h2>{currentTeam.playerCode}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                        }
                    </Grid>
                </div>
            </LegacyModalContainer>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext }, tenant } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        currentUser: userContext.user,
        tenantName: tenant.name,
        userContext
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AddWCSAthleteModal));