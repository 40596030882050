import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HeartBeatIcon = (props) => (
  <SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none"{...props}>
<path d="M11.0873 8.75611H15.1798L8.49954 15.4074L1.82178 8.75611H6.3549L7.96904 7.13672V11.8836L11.0873 8.75611Z" fill="#333333"/>
<path d="M16.139 7.69921H10.6454L9.0309 9.31825V4.57141L5.91268 7.69921H0.863222C-0.571847 5.66298 -0.187157 2.83961 1.79044 1.26356C3.84838 -0.376627 6.85229 -0.0450662 8.4997 2.00384C8.6087 1.86819 8.72549 1.73852 8.849 1.6152C10.713 -0.241677 13.7357 -0.242382 15.6007 1.61309C17.2651 3.26948 17.4449 5.84444 16.139 7.69921Z" fill="#333333"/>
 </SvgIcon>
);

export default HeartBeatIcon;
