import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';

import {
  Avatar,
  Button,
  Hidden,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';

import PersonOutlineIcon  from "@material-ui/icons/PersonOutline";
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import AssessmentIcon from '@material-ui/icons/Assessment';

import _ from "lodash";
import "./new.css";
import moment from "moment";
import { API, Auth, Storage } from "aws-amplify";
import logo from "../../assets/images/logo-dark.png"

import { setUserContext, setAppContext } from '../../actions/session';
import ContextService from '../../services/context';
import PageWrapper from '../nav/pageWrapper';
const contextService = new ContextService()

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: '100%',
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  header: {
    fontSize:35,
    display:"inline-block",
    marginLeft:30,
    textAlign:"left"
  },
  subHeader: {
    fontWeight: 100,
  },
  selectedRosterCard: {
      backgroundColor: "#27AE60"
  },
  modalInviteButton: {
      width: 100,
      height: 40,
      marginRight: 10,
      backgroundColor: "#27AE60",
      color: theme.product.name == 'MaxOne' ? 'black' : 'white'
  },
  addUserButton: {
      height: 40,
      color: 'white',
      backgroundColor: "#1354F9"
  },
  plusButton: {
      backgroundColor: "#1354F9",
      color: 'white',
      outlineColor: 'transparent',
      outlineStyle: 'none'
  },
  textButton: {
      color: 'white',
      height: '40px',
      marginRight: '3px',
      backgroundColor: "#1354F9",
  },
  pageTitle: {
      textAlign: 'left',
      marginLeft: 50
  },
  details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: `100%`
  },
});
class WCSAdminEditCoaches extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            athletes: [],
            schools: [],
            renderedAthletes: [],
            renderedCoaches: [],
            selectedAthlete: [],
            coaches: [],
            message: '',
            error: false,
            errorMessage: '',
            searchText: null,
            isLoading: false,
            rosterFilterBy: 'graduationYear',
            selectedSchool:null,
            isLoadingCoaches:false,
            owners:[],
            addNew: false,
            wcsSearchText:'',
            wcsMessage:"",
            newUser:null,
            usersToRemove:[]

        };
    }

    async componentDidMount(){
        const { currentTeam } = this.props;
        const organization = await this.getOrganization(currentTeam.organizationId)
        const teams = await this.getTeamsByOrganization(organization.id);
        const wcsOrgId = organization.id.split("-")[1]
        // const wcsOrgId = "wcs-284".split("-")[1]
        const defaultTeamId = "wcs-default-" + wcsOrgId
        console.log('defaultTeamId ', defaultTeamId)
        let athletes = [];
        let coaches = [];
        let schools = await this.getSchools()
        console.log('Schools ', schools)
        // let students = localStorage.getItem(`@wcs:students-${wcsOrgId}:`, null)
        // let teachers = localStorage.getItem(`@wcs:teachers-${wcsOrgId}:`, null)
        // console.log("students == ", students)
        // if(students){
        //   athletes = JSON.parse(students)
        // }
        // else{
        //   athletes = await this.getAthletes(defaultTeamId)
        //   localStorage.setItem(`@wcs:students-${wcsOrgId}:`, JSON.stringify(athletes))
        // }
        // if(teachers){
        //   coaches = JSON.parse(teachers)
        // }
        // else{
        //   coaches = await this.getCoaches(defaultTeamId)
        //   localStorage.setItem(`@wcs:teachers-${wcsOrgId}:`, JSON.stringify(coaches))
        // }
        this.setState({organization, schools, athletes, renderedAthletes: athletes, coaches, renderedCoaches: coaches});
    }

    getOrganization(organizationId) {
        return API.get("organizations", `/organizations/${organizationId}`);
    }

    getTeamsByOrganization(organizationId) {
        return API.get("programs", `/programs/organization/${organizationId}`);
    }

    getAthletes(id){
        const teamId = id ? id : this.props.currentTeam.id;
        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("programs", `/programs/${teamId}/players`);
        })
    }

    getCoaches(id){
        const teamId = id ? id : this.props.currentTeam.id;

        return Auth.currentSession()
        .then(auth =>{
          const options = {
            headers: {
              Authorization: auth.idToken.jwtToken,
            },
          }
          return API.get("programs", `/programs/${teamId}/coaches`);
        })
    }

    createTeam(team){
        return API.post('programs', '/programs', {
            body: team
        })
    }

    getOwners(orgId) {
        return API.get("organizations", `/organizations/${orgId}/owners`);
    }

    getOwnerRoles(orgId) {
        return API.get("organizations", `/organizations/${orgId}/roles/owner`);
    }

    deleteRole(roleId) {
        return API.del("users", `/users/roles/${roleId}`);
    }

    createRole(role) {
        return API.post("users", `/users/${role.userId}/roles`, {
            body: role
        });
    }

    getRoles(userId) {
        return API.get("users", `/users/${userId}/roles`);
    }

    createUser(user) {
        return API.post("users", `/users`, {
            body: user
        });
    }

    getSchools() {
        return API.get("wcs", `/wcs/organizations`);
    }

    getUser(id) {
        return API.get("users", `/users/wcs-${id}`);
    }
    getWCSUser(id) {
        return API.get("wcs", `/wcs/search/user/${id}`);
    }

    updateOrganization(organization){
        return API.post("organizations", `/organizations/${organization.id}`, {
            body: organization
        });
    }

    updateCoaches = async () => {
      this.setState({coaches:[], renderedCoaches:[], isLoadingCoaches:true, selectedAthlete:[], owners:[]})
      const wcsOrgId = this.state.selectedSchool.id.split("-")[1]
      console.log('wcsOrgId ', wcsOrgId)
      const defaultTeamId = "wcs-default-" + wcsOrgId
      let coaches = await this.getCoaches(defaultTeamId)
      console.log('coaches ', coaches)
      let selected = []
      this.setState({coaches, renderedCoaches:coaches, isLoadingCoaches:false, selectedAthlete:selected, owners:selected})
    }

    setSessionInfo = async (username) => {
      let prefix = null
      if(window.location.href.toLowerCase().includes('wcs'))  prefix = "wcs";
      console.log('prefix ===>', prefix)
      const contextService = new ContextService();
      const {userContext, appContext} = await contextService.buildUserContext(username, prefix);
      this.props.setUserContext(userContext);
      this.props.setAppContext(appContext);
    }

    handleSubmit = async event => {
      const { addNew, newUser, selectedAthlete, coaches, selectedSchool } = this.state;
      let user = newUser;
      const wcsOrgId = selectedSchool.id.split("-")[1]
      // const wcsOrgId = "wcs-284".split("-")[1]
      const defaultTeamId = "wcs-default-" + wcsOrgId

      if(this.state.addNew){
        // adding newUser to selectedSchool
        if(newUser.type === "OneRoster"){
          //create user
          let userJSON = {
              id: "wcs-" + newUser.sourcedId,
              status: newUser.status,
              updatedAt: newUser.dateLastModified ? newUser.dateLastModified : null,
              username: newUser.username ? newUser.username : null,
              userIds: newUser.userIds ? newUser.userIds : null,
              enabledUser: newUser.enabledUser ? newUser.enabledUser : null,
              nameFirst: newUser.givenName ? newUser.givenName : null,
              nameLast: newUser.familyName ? newUser.familyName : null,
              nameMiddle: newUser.middleName ? user.middleName : null,
              role: newUser.role ? newUser.role : null,
              email: newUser.email ? newUser.email : null,
              identifier: newUser.identifier ? newUser.identifier : null,
              tenant:"wcs",
              tenantId:"wcs",
              migratedAt: moment().format()
            }
          await this.createUser(userJSON)
          user = userJSON
        }

        const roleJSON = {
            legacyId: 0,
            role: "coach",
            parentId: defaultTeamId,
            userId: user.id,
            tenant: "wcs",
            tenantId: "wcs"
        }
        const newRole = await this.createRole(roleJSON);
        const orgRoleJSON = {
            legacyId: 0,
            role: "teacher",
            parentId: selectedSchool.id,
            userId: user.id,
            tenant: "wcs",
            tenantId: "wcs"
        }
        const newOrgRole = await this.createRole(orgRoleJSON);
        alert(`${user.nameFirst} ${user.nameLast} was successfully added to ${selectedSchool.name}.`)
        this.setState({newUser:null, addNew:false, value:0, selectedSchool:null})

      }
      else{
        // removing list of teachers from school and all coaching positions
        const { name, sport, selectedAthlete, coaches } = this.state;
        const { currentTeam, currentUser } = this.props;
        let organization = this.state.selectedSchool;
        let organizationTeams = await this.getTeamsByOrganization(organization.id)
        console.log('organizationTeams ', organizationTeams)

        await this.asyncForEach(selectedAthlete, async user =>{
          console.log('user ', user)
          let userRoles = await this.getRoles(user.id);
          console.log("userRoles ==> ", userRoles)

          // remove teacher role;
          let teacherRole = _.find(userRoles, {parentId: organization.id, role: "teacher"})
          console.log("teacherRole ==> ", teacherRole)
          if(teacherRole){
            await this.deleteRole(teacherRole.id)
          }

          await this.asyncForEach(userRoles, async userRole =>{
            let isOrgTeam = _.find(organizationTeams, {id: userRole.parentId})
            if(isOrgTeam){
              console.log("isOrgTeam ", isOrgTeam)
              await this.deleteRole(userRole.id)
            }
          })
          alert(`Successfully removed ${user.nameFirst} ${user.nameLast} from ${selectedSchool.name} and all ${selectedSchool.name} teams.`)
          this.setState({newUser:null, addNew:false, selectedAthlete:[], coaches:[], renderedCoaches:[]})
        })
        this.setState({value:0, selectedSchool:null})
      }
    }

    handleChange = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }

    handleSelect = athlete => {
        // check if athlete is in the selection already
        let selectedAthletes = this.state.selectedAthlete;
        console.log('selectedAthletes.length', selectedAthletes.length)
        let isSelected = _.find(selectedAthletes, {id: athlete.id})
        console.log('isSelected', isSelected)

        // if not, then push onto array
        // if they are, then remove them from array.
        if(isSelected){
          let index = _.findIndex(selectedAthletes, {id: athlete.id})
          console.log('Index found ===>', index)
          selectedAthletes.splice(index, 1)
        }
        else{
          selectedAthletes.push(athlete);
        }
        console.log('selectedAthletes.length after', selectedAthletes.length)

        this.setState({
            selectedAthlete: selectedAthletes
        })
    }

    handleSelectSchool = school => {
        this.setState({
            selectedSchool: school === this.state.selectedSchool ? null : school
        })
    }

    searchAthletes = event => {
        const { athletes } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedAthletes: _.filter(athletes, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()) || (a.graduationYear && a.graduationYear == event.target.value))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedAthletes: athletes
            })
        }
    }

    searchCoaches = event => {
        const { coaches } = this.state;
        if(event.target.value){
            this.setState({
                searchText: event.target.value,
                renderedCoaches: _.filter(coaches, a => a.nameFirst.toLowerCase().includes(event.target.value.toLowerCase()) || a.nameLast.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchText: "",
                renderedCoaches: coaches
            })
        }
    }

    handleFilter = filterValue => event => {
        this.setState({
            rosterFilterBy: filterValue
        })
    }

    async searchUser(){
      this.setState({wcsMessage: ''})
      const id = this.state.wcsSearchText;

      try{
          let user = await this.getUser(id)
          if(user){
            user.type = "MaxOne"
            this.setState({newUser: user, wcsMessage: `User already exists in our system.  Press SUBMIT if you would still like to add them as a teacher to ${this.state.selectedSchool.name}.`})
            return
          }
      }
      catch(e){
        console.log('m1 user not found ', e)
      }

      try{
          let user = await this.getWCSUser(id)
          if(user){
            user.type = "OneRoster"
            this.setState({newUser: user, wcsMessage: `User Found.  Press SUBMIT to add them as a teacher to ${this.state.selectedSchool.name}.`})
            return
          }
      }
      catch(e){
        console.log('OneRoster user not found ', e)
        this.setState({newUser: null, wcsMessage: `Could not find user with id: ${id} in the OneRoster system.  Please Try Again.`})

      }


    }

    renderTeamInputs(){
        const { schools, rosterFilterBy, selectedSchool, appContext } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                        <Grid container className={"content"}>
                            <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('id')}>
                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                        <strong>ID</strong>
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('name')}>
                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                        <strong>Name</strong>
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('identifier')}>
                            <div className={"nameColumn"} style={{ paddingTop:15}} >
                                    <p className={"textContainer"} style={{ color: 'white' }}>
                                        <strong>Identifier</strong>
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                      </Grid>

                        {
                            _.orderBy(schools, [rosterFilterBy, 'name']).map((school, index) => {
                            return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                    <SchoolCard
                                        school={school}
                                        classes={classes}
                                        appContext={appContext}
                                        selected={selectedSchool === school}
                                        handleSelect={() => this.handleSelectSchool(school)}
                                    />
                                </Grid>)
                            })
                        }
                </Grid>
                </Grid>
            </Grid>
        )
    }
    renderCoaches(){
        const { team, searchText, renderedCoaches, coaches, rosterFilterBy, appContext, currentTeam, selectedAthlete } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <div className={"pageTitleContainer"}>
                        <h1 className={"pageTitle"}>Teachers</h1>
                        <TextField
                            id="search"
                            value={searchText ? searchText : ''}
                            className={"searchTextField"}
                            onChange={this.searchCoaches}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CloseIcon onClick={() => this.setState({searchText: '', renderedCoaches: coaches })} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Grid item xs={12}>
                    {
                      this.state.selectedSchool &&
                      <h5> WARNING: On submission all selected users will be removed as teachers/coaches for all teams in {this.state.selectedSchool.name}.</h5>

                    }
                    </Grid>
                    <Grid item xs={12}>
                    {
                        renderedCoaches.length ?
                        <Grid container>
                            <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                                <Grid container className={"content"}>
                                  <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('id')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                <strong>ID</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                                <strong>Last Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>First Name</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                        <div className={"nameColumn"} style={{ paddingTop:15}} >
                                            <p className={"textContainer"} style={{ color: 'white' }}>
                                                <strong>Email</strong>
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                _.orderBy(renderedCoaches, [rosterFilterBy, 'nameLast']).map((coach, index) => {
                                return(<Grid item xs={12} key={index}className={"atheleteGridItem"}>
                                        <AthleteCard
                                            athlete={coach}
                                            classes={classes}
                                            appContext={appContext}
                                            currentTeam={currentTeam}
                                            selected={selectedAthlete}
                                            handleSelect={() => this.handleSelect(coach)}
                                        />
                                    </Grid>)
                                })
                            }
                        </Grid>
                    :
                        searchText
                    ?
                        <p className={"emptyListText"}>No results...</p>
                    :
                    <div>
                      {
                        this.state.isLoadingCoaches ?
                        <p className={"emptyListText"}>Loading Teachers...</p>
                        :
                        <p className={"emptyListText"}>No results...</p>
                      }
                    </div>
                    }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderAddRemoveChoice(){
        const { value } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                    {
                      this.state.selectedSchool &&
                      <h4> Editing Teachers for {this.state.selectedSchool.name}.</h4>

                    }
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:15}}>
                      <Button
                          mini
                          color="primary"
                          variant="contained"
                          title="View Schedules"
                          className={"styledButton"}
                          onClick={() =>{this.setState({value:  value + 1})}}
                      >
                          Remove Teachers
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                          mini
                          color="primary"
                          variant="contained"
                          title="View Schedules"
                          className={"styledButton"}
                          onClick={() =>{ this.setState({addNew: true, value: value + 1})}}
                      >
                          Add New Teacher
                      </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderSearchNew(){
        const { value, wcsSearchText } = this.state;
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                    {
                      this.state.selectedSchool &&
                      <h4> Search OneRoster for user using ID</h4>
                    }
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          id="search"
                          value={wcsSearchText ? wcsSearchText : ''}
                          className={"searchTextField"}
                          onChange={this.handleChange('wcsSearchText')}
                          InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                      <SearchIcon />
                                  </InputAdornment>
                              ),
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <CloseIcon onClick={() => this.setState({wcsSearchText: '' })} />
                                  </InputAdornment>
                              ),
                          }}
                      />
                      <Button
                          mini
                          color="primary"
                          variant="contained"
                          title="View Schedules"
                          className={"styledButton"}
                          onClick={() => this.searchUser()}
                          style={{marginLeft:10}}
                      >
                          Search
                      </Button>
                    </Grid>
                    {
                      this.state.wcsMessage &&
                      <Grid item xs={12} className={"atheleteGridItem"} style={{marginTop:15, marginBottom:15}}>
                          <p>{this.state.wcsMessage}</p>
                      </Grid>
                    }
                    {
                      this.state.newUser &&
                      <Grid container>
                        <Grid item xs={12} className={"rosterCard"} style={{ backgroundColor: '#28272C' }}>
                            <Grid container className={"content"}>
                              <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('id')}>
                                <div className={"nameColumn"} style={{ paddingTop:15}} >
                                        <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                            <strong>ID</strong>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameLast')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                        <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, color: 'white'}}>
                                            <strong>Last Name</strong>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                        <p className={"textContainer"} style={{ color: 'white' }}>
                                            <strong>First Name</strong>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={3} className={"rosterColumnContainer"} onClick={this.handleFilter('nameFirst')}>
                                    <div className={"nameColumn"} style={{ paddingTop:15}} >
                                        <p className={"textContainer"} style={{ color: 'white' }}>
                                            <strong>Email</strong>
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={"atheleteGridItem"}>
                                <AthleteCard
                                    athlete={this.state.newUser}
                                    classes={classes}
                                    selected={1}
                                />
                      </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        )
    }

    render() {
        const { classes, theme, appContext, userContext } = this.props;
        const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
        const { error, message, errorMessage, isLoading, value, athletes, coaches } = this.state;

        return (
        <PageWrapper title="Edit Teachers" {...this.props}>
        <div className={"newTeamOuterContainer"}>
            <div className={"pageTitleContainer"}>
            <div className={"avatarNameContainer"}>
                <h1 className={"pageTitle"}>Edit Teachers</h1>
            </div>
            {
              value !== 2 ?
            <div className={"titleButtonContainer"}>
            {
              value !== 0 &&
              <Button
                  mini
                  color="default"
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({searchText: '', renderedAthletes: athletes, renderedCoaches: coaches })
                    this.setState({value:  value - 1})
                  }}
              >
                  Back
              </Button>
            }
              <Button
                  mini
                  color="primary"
                  disabled={!this.state.selectedSchool}
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={() =>{
                    console.log('changing value')
                    this.setState({value:  value + 1})
                    if(value + 1 === 1){
                      this.updateCoaches()
                    }
                  }}
              >
                  Next
              </Button>
              </div>
              :
              <div className={"titleButtonContainer"}>
              {
                value !== 0 &&
                <Button
                    mini
                    color="default"
                    variant="contained"
                    title="View Schedules"
                    className={"styledButton"}
                    onClick={() =>{
                      console.log('changing value')
                      this.setState({searchText: '', renderedAthletes: athletes, renderedCoaches: coaches })
                      this.setState({value:  value - 1})
                    }}
                >
                    Back
                </Button>
              }
              <Button
                  mini
                  color="primary"
                  disabled={(this.state.addNew && !this.state.newUser) || (!this.state.addNew && !this.state.selectedAthlete.length)}
                  variant="contained"
                  title="View Schedules"
                  className={"styledButton"}
                  onClick={this.handleSubmit}
              >
                  Submit
              </Button>
              </div>
            }

            </div>
            <SwipeableViews index={value}>
              <div className={"wcsInnerContainer"}>
                  {this.renderTeamInputs()}
              </div>
              <div className={"wcsInnerContainer"}>
                  {this.renderAddRemoveChoice()}
              </div>
              <div className={"wcsInnerContainer"}>
              {
                this.state.addNew ?
                this.renderSearchNew()
                :
                this.renderCoaches()
              }
              </div>
            </SwipeableViews>
        </div>
        </PageWrapper>
        );
    }
}


class AthleteCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          athlete: {},
          isSelected: false
      };
    }
    async componentDidMount(){
      this.setState({athlete: this.props.athlete || {}})
      const selected = _.findIndex(this.props.selected, {id: this.props.athlete.id})
      if(selected === -1){
        this.setState({isSelected: false})
      }
      else{
        this.setState({isSelected: true})
      }
    }

    async componentWillReceiveProps(props, nextProps){
      const selected = _.findIndex(props.selected, {id: props.athlete.id})
      if(selected === -1){
        this.setState({isSelected: false})
      }
      else{
        this.setState({isSelected: true})
      }
      this.setState({athlete: props.athlete})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.athlete ? `${this.props.athlete.nameFirst} ${this.props.athlete.nameLast}` : "";
        const email = this.props.athlete ? `${this.props.athlete.email}` : "";
        const { athlete, isSelected } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${isSelected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {athlete && athlete.id ? athlete.id.split("-")[1] : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {athlete && athlete.nameLast ? athlete.nameLast : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}} >
                                  {athlete && athlete.nameFirst ? athlete.nameFirst : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                  {athlete && athlete.email ? athlete.email : ""}
                                </p>
                            </div>
                        </Grid>
                        {
                          athlete.graduationYear &&
                          <Grid item xs={3} className={"rosterColumnContainer"} >
                              <div style={{paddingTop:10}} >
                                  <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                      {athlete.graduationYear}
                                  </p>
                              </div>
                          </Grid>
                        }
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}
class SchoolCard extends Component {
  constructor(props) {
      super(props);

      this.state = {
          school: {}
      };
    }
    async componentDidMount(){
      this.setState({school: this.props.school || {}})
    }

    async componentWillReceiveProps(props, nextProps){
      this.setState({school: props.school})
    }

    render() {
        const { selected, appContext, currentTeam, classes } = this.props;
        const name = this.props.school ? `${this.props.school.name}` : "";
        const { school } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} style={{}} className={ `rosterCard ${selected ? classes.selectedRosterCard : ""}`}>
                    <Grid container onClick={this.props.handleSelect} className={"content"}>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {school && school.id ? school.id.split("-")[1] : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                    {school && school.name ? school.name : ""}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={3} className={"rosterColumnContainer"} >
                            <div style={{paddingTop:10}} >
                                <p className={"textContainer"} style={{paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                                  {school && school.identifier ? school.identifier : ""}
                                </p>
                            </div>
                        </Grid>
                        </Grid>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

WCSAdminEditCoaches.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WCSAdminEditCoaches));
