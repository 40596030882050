import React, { Component } from "react";
import { organizationClient } from '../../clients'
import { withStyles } from "@material-ui/core/styles"
import { connect } from 'react-redux';
import classnames from 'classnames';
import MetricCard from '../../components/metricCard'

const styles = {
    metricsContainer:{
        marginTop:"20px"
    },
    title:{
        fontSize: "24px",
        lineHeight: "110%",
        marginBottom:"36px",
        textAlign:"left"
    },
    metrics:{
        marginLeft:"-20px",
        "& > div":{
            background:"#ffffff !important"  
        }
    }
}

class OrgMetrics extends Component {
    constructor(props){
        super(props)
        this.state = {
            metrics:[]
        }
    }

    componentDidMount(){
        this.fetchOrgInfo();
    }

    getIconNameByMetricType = (key) => {
        key = (key||"").toLowerCase();
        let iconName = "Athletes"
        switch(key){
            case "workoutprograms": iconName = "Athletes"; break;
            case "workouts": iconName = "Whistle"; break;
            case "activities": iconName = "PlayCircle"; break;
            case "coaches": iconName = "Whistle"; break;
            case "athletes": iconName = "Athletes"; break;
            case "parents": iconName = "Athletes"; break;
            case "teams": iconName = "COG"; break;
        }
        return iconName;
    }

    fetchOrgInfo = async () => {
        let { currentTeam } = this.props;
        //fetch org metrics
        let { data:{ metrics } } = await organizationClient.getOrganizationById(currentTeam.organizationId, { metrics: true });
        let formattedData = [];
        Object.keys(metrics).forEach(metric => {
            let formatted = {};
            let key = (metric||"").replace("total", "").toUpperCase();
            if(key == "WORKOUTPROGRAMS"){
                key = "PROGRAMS"
            }
            formatted.label = key;
            formatted.icon = this.getIconNameByMetricType(key);
            formatted.value = metrics[metric];
            formattedData.push(formatted);
        })
        this.setState({metrics: formattedData});
    }

    render(){
        let { classes } = this.props;
        let { metrics } = this.state;
        return(
            <div className={classes.metricsContainer}>
                <div className={classes.title}>
                    Organization Overview
                </div>
                <div className={classnames(classes.metrics,"d-flex flex-wrap")}>
                    {(metrics||[]).map(metric => <MetricCard label={metric.label} icon={metric.icon} value={metric.value}/>)}
                </div>
            </div>
        )
    }

}


function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam
    };
}

export default withStyles(styles)(connect(mapStateToProps)(OrgMetrics));