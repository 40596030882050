import moment from 'moment';

class AnalyticsService {

    constructor() {
        this.identified = false;
    }

    initWithUser(user = {}) {
        if (window.analytics) {
            window.analytics.identify(user.id, user);
            this.identified = true;
        }
        if (window.userGuiding) {
            window.userGuiding.identify(user.id, user);
            console.log(`Identified user ${user.id} with User Guiding`);
        }
    }

    clearCurrentUser() {
        if (!this.identified) {
            return console.log('No segment key defined for athlete and/or coach');
        }
        if (window.analytics) {
            window.analytics.reset();
        }
    }

    trackScreenView(name, params = {}) {
        if (!this.identified) {
            return console.log('No segment key defined for athlete and/or coach');
        }
        if (window.analytics && this.identified) {
            let traits = {};
            if(typeof(window.analytics.user) == 'function'){
                let user = window.analytics.user();
                traits = user.traits();
            }
            console.log(`Segment tracking for Page ${name} with traits ${traits}`)
            window.analytics.page(name, params,{traits});
        }
    }

    trackEvent(name, params = {}) {
        if (!this.identified) {
            return console.log('No segment key defined for athlete and/or coach');
        }
        if (window.analytics && this.identified) {
            let traits = {};
            if(typeof(window.analytics.user) == 'function'){
                let user = window.analytics.user();
                traits = user.traits();
            }
            console.log(`segment tracking for Event ${name} with traits ${traits}`)
            window.analytics.track(name, params,{traits});
        }
    }


 

    getUserType(appContext) {
        let userType = '';
        if (appContext.isAthlete) {
            userType = 'athlete';
        }
        else if (appContext.isCoach) {
            userType = 'coach';
        }
        else if (appContext.isOwner) {
            userType = 'owner';
        }
        else if (appContext.isHeadCoach) {
            userType = 'head_coach';
        }
        else if (appContext.isParent) {
            userType = 'parent';
        }
        return userType
    }


    async getUserTraits(user, roles, currentTeam, appContext, userCon) {
        let numberOfUsersInTeam = userCon.numberOfUsersInTeam;
        let numberOfUsersInOrg = userCon.numberOfUsersInOrg;
        let numberOfAssignmentsByUser = userCon.numberOfAssignmentsByUser;
        let numberOfSessionsByUser = userCon.numberOfSessionsByUser;
        let userType = this.getUserType(appContext);
        let userStatus = "?";
        if ('billing' in currentTeam.organization) {
            userStatus = currentTeam.organization.billing.isLockedOut? 'free' : 'paying';
        }
        console.log(`user traits collected: numberOfUsersInTeam ${numberOfUsersInTeam}, numberOfUsersInOrg ${numberOfUsersInOrg} numberOfAssignmentsByUser${numberOfAssignmentsByUser}  numberOfSessionsByUser ${numberOfSessionsByUser}`)
        return {
            id: user.id,
            created: user.createdAt,
            first_name: user.nameFirst,
            last_name: user.nameLast,
            email: user.email,
            username: user.username,
            role: roles[roles.length - 1].role,
            program_name: currentTeam.customName,
            phone: user.phoneNumber,
            sport: currentTeam.sport,
            user_status: userStatus,
            user_type: userType,
            program_id: currentTeam.id,
            organization_id: ('organization' in currentTeam)? currentTeam.organization.id: '?',
            program_size: numberOfUsersInTeam,
            organization_size: numberOfUsersInOrg,
            tenant: user.tenant,
            referral_source: document.referrer,  // TODO: This will probably need some integration with HubSpot
            critical_actions_performed: numberOfAssignmentsByUser,
            sessions_total: numberOfSessionsByUser - numberOfAssignmentsByUser // See https://segment.com/blog/facts-vs-stories-why-segment-has-no-sessions-api/
        }
    }

}

export const currentTimestamp = moment().valueOf();

export const analyticsService = new AnalyticsService();