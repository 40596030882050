import React, { Component, Linking } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API, Auth, Storage } from 'aws-amplify';
import _ from 'lodash';
import QRCode from 'qrcode.react';

import SwipeableViews from 'react-swipeable-views';

import { Button, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { withStyles } from '@material-ui/core/styles';

import './roster.css';

import { getThemeByTenant } from '../../themes';

import { getLocalizedString } from '../../utils/locale-utils';

import ContextService from '../../services/context';
import {
	analyticsService,
	currentTimestamp,
} from '../../services/analyticsService';
import PageWrapper from '../nav/pageWrapper';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import ThemeButton from '../../components/themeButton';
const contextService = new ContextService();

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const styles = (theme) => ({
	selectedRosterCard: {
		backgroundColor: '#27AE60',
		color: '#ffffff',
	},
	modalInviteButton: {
		width: 100,
		height: 40,
		marginRight: 10,
		backgroundColor: '#27AE60',
		color: theme.product.name == 'MaxOne' ? 'black' : 'white',
	},
	addUserButton: {
		height: 40,
		color: 'white',
		backgroundColor: '#1354F9',
	},
	plusButton: {
		backgroundColor: '#1354F9',
		color: 'white',
	},
	textButton: {
		color: 'white',
		height: '40px',
		marginRight: '3px',
		backgroundColor: '#1354F9',
	},
});

class CoachRoster extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: 0,
			email: '',
			coaches: [],
			contact: '',
			open: false,
			openWCS: false,
			wcsCoaches: [],
			message: '',
			contacts: [],
			wcsSendInviteOpen: false,
			sendingInvites: false,
			sentInfo: false,
			nameLast: '',
			nameFirst: '',
			phoneNumber: '',
			searchText: null,
			errorMessage: '',
			isLoading: false,
			existingUser: null,
			loadingMessage: '',
			renderedCoaches: [],
			selectedCoach: null,
			invalidPhoneNumber: false,
			rosterFilterBy: 'nameLast',
			isExistingUserModalOpen: false,
		};
	}

	getCoaches() {
		const { currentTeam } = this.props;
		return API.get('programs', `/programs/${currentTeam.id}/coaches`);
	}

	getCoachesById(id) {
		return API.get('programs', `/programs/${id}/coaches`);
	}

	getUserByUsername(username) {
		return API.get('users', `/users/username/${username}`);
	}

	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	}

	deleteRole = (roleId) => {
		return Auth.currentSession().then((auth) => {
			const options = {
				headers: {
					Authorization: auth.idToken.jwtToken,
				},
			};
			return API.del('users', `/users/roles/${roleId}`);
		});
	};

	getRoles = (coachId) => {
		return Auth.currentSession().then((auth) => {
			const options = {
				headers: {
					Authorization: auth.idToken.jwtToken,
				},
			};
			return API.get('users', `/users/${coachId}/roles`);
		});
	};

	createRole = (coachId) => {
		const { currentTeam } = this.props;
		return API.post('users', `/users/${coachId}/roles`, {
			body: {
				role: 'coach',
				parentId: currentTeam.id,
			},
		});
	};

	sendMessage(message) {
		if (message.type === 'email') {
			return Auth.currentSession().then((auth) => {
				const options = {
					headers: {
						Authorization: auth.idToken.jwtToken,
					},
				};
				return API.post('messages', `/messages/email`, {
					body: message,
				});
			});
		} else if (message.type === 'sms') {
			return Auth.currentSession().then((auth) => {
				const options = {
					headers: {
						Authorization: auth.idToken.jwtToken,
					},
				};
				return API.post('messages', `/messages/sms`, {
					body: message,
				});
			});
		}
	}

	getGroups() {
		const { currentTeam } = this.props;
		return API.get('groups', `/programs/${currentTeam.id}/groups`);
	}

	saveGroup(group) {
		return API.put('groups', `/groups/${group.id}`, {
			body: group,
		});
	}

	getOrganization(organizationId) {
		return API.get('organizations', `/organizations/${organizationId}`);
	}

	handleDelete = async (coach) => {
		const { currentTeam } = this.props;
		const roles = await this.getRoles(coach.id);
		const currentRole = _.find(
			roles,
			(role) => role.parentId === currentTeam.id
		);
		await this.deleteRole(currentRole.id);
		const groups = await this.getGroups();
		await Promise.all(
			groups.map(async (group) => {
				let coachPresent = _.find(group.participants, (p) => p.id == coach.id);
				group.participants = _.without(group.participants, coachPresent);
				group = await this.saveGroup(group);
			})
		);
		this.setState({
			coaches: _.without(this.state.coaches, coach),
			renderedCoaches: _.without(this.state.coaches, coach),
		});
	};

	async componentWillReceiveProps(props, nextProps) {
		if (
			this.state.currentTeam &&
			props.currentTeam &&
			props.currentTeam.id !== this.state.currentTeam
		) {
			if (props.currentTeam.id !== this.state.currentTeam) {
				await this.componentDidMount(); // Force an update of the component when team changes
			}
		}
	}

	async componentDidMount() {
		this.setState({ currentTeam: this.props.currentTeam.id });
		this.setState({
			isLoading: true,
			loadingMessage: 'hang tight while we grab your coaches...',
		});

		let coaches = await this.getCoaches();
		if (!coaches) {
			coaches = [];
		}
		await Promise.all(
			coaches.map(async (coach) => {
				if (coach.avatarUrl && !coach.avatarUrl.includes('https://')) {
					var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${
						coach.legacyId ? coach.legacyId : coach.id
					}/${coach.avatarUrl}`;
					coach.avatarUrl = logo;
				}
			})
		);
		this.setState({
			isLoading: false,
			coaches: coaches || [],
			renderedCoaches: coaches || [],
		});
		if (window.location.href.toLowerCase().includes('wcs')) {
			// if WCS
			const organization = await this.getOrganization(
				this.props.currentTeam.organizationId
			);
			const wcsOrgId = organization.id.split('-')[1];
			let wcsCoaches = [];
			let coaches = localStorage.getItem(`@wcs:teachers-${wcsOrgId}:`, null);
			if (coaches) {
				wcsCoaches = JSON.parse(coaches);
			} else {
				wcsCoaches = await this.getOrganizationCoaches(
					this.props.currentTeam.organizationId
				);
				localStorage.setItem(
					`@wcs:teachers-${wcsOrgId}:`,
					JSON.stringify(wcsCoaches)
				);
			}
			this.setState({
				wcsCoaches: wcsCoaches,
			});
		}
	}

	toggleInviteModal = () => {
		const { currentTeam } = this.props;
		analyticsService.trackEvent('Account Added User', {
			program_id: currentTeam.id,
			user_type: 'coach',
		});
		if (window.location.href.toLowerCase().includes('vnn')) {
			return window.open('https://teammate.getvnn.com', '_blank');
		}
		if (window.location.href.toLowerCase().includes('wcs')) {
			this.setState({ openWCS: !this.state.openWCS, value: 1 });
			return;
		}

		this.setState({ open: !this.state.open });
	};

	cancelInviteModal = () => {
		this.setState({
			open: false,
			contacts: [],
		});
	};

	removeContactFromList = (contact) => {
		this.setState({ contacts: _.without(this.state.contacts, contact) });
	};

	handleExistingUserConfirm = async () => {
		const { existingUser } = this.state;
		this.setState({
			isLoading: true,
			loadingMessage: 'adding your new coaches...',
		});
		const newEnrollment = await this.createRole(existingUser.id);
		const coaches = await this.getCoaches();
		this.setState({
			email: '',
			open: false,
			nameLast: '',
			nameFirst: '',
			phoneNumber: '',
			isLoading: false,
			coaches: coaches,
			loadingMessage: '',
			renderedCoaches: coaches,
			isExistingUserModalOpen: false,
		});
	};

	handleExistingUserDecline = async () => {
		const { existingUser } = this.state;
		this.setState({
			isLoading: true,
			loadingMessage: 'adding your new coaches...',
		});
		const contactJSON = {
			nameFirst: existingUser.nameFirst,
			nameLast: existingUser.nameLast,
			email: existingUser.email,
			phoneNumber: existingUser.phoneNumber,
		};
		try {
			const newCoach = await this.signupCoach(contactJSON);
			const coaches = await this.getCoaches();
			this.setState({
				email: '',
				open: false,
				nameLast: '',
				nameFirst: '',
				phoneNumber: '',
				isLoading: false,
				coaches: coaches,
				loadingMessage: '',
				renderedCoaches: coaches,
				isExistingUserModalOpen: false,
			});
		} catch (e) {
			console.log('e', e);
		}
	};

	handleNewContact = async () => {
		const { nameFirst, nameLast, email, phoneNumber, contacts } = this.state;
		let strippedNumber = '';

		if (email) {
			const existingUser = await this.getUserByUsername(email);

			if (existingUser.length) {
				return this.setState({
					isExistingUserModalOpen: true,
					existingUser: existingUser[0],
				});
			}

			if (email != '' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
				return this.setState({
					errorMessage: 'Oops, invalid email.',
					invalidPhoneNumber: false,
					message: '',
				});
		}

		if (phoneNumber) {
			strippedNumber = phoneNumber.replace(/[^0-9\.]+/g, '');
			if (strippedNumber.match(/[^$,.\d]/))
				return this.setState({
					invalidPhoneNumber: true,
					message: 'invalid characters',
				});
			if (strippedNumber.length < 10)
				return this.setState({
					invalidPhoneNumber: true,
					message: 'did you forget an area code?',
				});
			if (strippedNumber.length > 10)
				return this.setState({
					invalidPhoneNumber: true,
					message: 'did you add too many numbers?',
				});
		}

		if (!nameFirst || nameFirst === '')
			return this.setState({
				errorMessage: 'Oops, please enter a first name.',
				invalidPhoneNumber: false,
				message: '',
			});

		if (!nameLast || nameFirst === '')
			return this.setState({
				errorMessage: 'Oops, Please enter a last name.',
				invalidPhoneNumber: false,
				message: '',
			});

		const contactJSON = {
			nameFirst: nameFirst,
			nameLast: nameLast,
			email: email === '' ? null : email,
			phoneNumber: strippedNumber === '' ? null : strippedNumber,
		};

		this.setState({
			email: '',
			nameLast: '',
			nameFirst: '',
			phoneNumber: '',
			errorMessage: '',
			invalidPhoneNumber: false,
			contacts: [...contacts, contactJSON],
		});

		return contactJSON;
	};

	getOrganizationCoaches(id) {
		const orgId = id ? id : this.props.currentTeam.organizationId;
		return Auth.currentSession().then((auth) => {
			const options = {
				headers: {
					Authorization: auth.idToken.jwtToken,
				},
			};
			return API.get('organizations', `/organizations/${orgId}/coaches`);
		});
	}

	signupCoach(contact) {
		const { currentTeam } = this.props;
		return Auth.currentSession().then((auth) => {
			const options = {
				headers: {
					Authorization: auth.idToken.jwtToken,
				},
				body: { contact, currentTeam },
			};
			return API.post('users', `/users/coaches`, options);
		});
	}

	handleTabValueChange = (event, value) => {
		this.setState({ value });
	};

	handleAddWCSCoach = async (user) => {
		const { contacts } = this.state;
		this.setState({ contacts: [...contacts, user] });
	};

	handleSubmitWCSCoaches = async () => {
		const { contacts, currentTeam } = this.state;
		let added = [];
		await this.asyncForEach(contacts, async (user) => {
			const roleJSON = {
				legacyId: 0,
				role: 'coach',
				parentId: currentTeam,
				userId: user.id,
				tenant: 'wcs',
				tenantId: 'wcs',
			};
			const newRole = await this.createWCSRole(roleJSON);
			added.push(user);
		});
		let renderedCoaches = this.state.renderedCoaches.concat(added);
		this.setState({ renderedCoaches, openWCS: false, wcsSendInviteOpen: true });
	};

	async sendLoginInfo(organization, userIds) {
		const { currentTeam } = this.props;
		return Auth.currentSession().then((auth) => {
			const options = {
				headers: {
					Authorization: auth.idToken.jwtToken,
				},
			};
			return API.post('wcs', `/wcs/invites`, {
				body: {
					sentBy: organization,
					sendToType: 'team',
					userType: 'coach',
					sendTo: this.props.currentTeam.id,
					userIds: userIds,
				},
			});
		});
	}

	createWCSRole(role) {
		return API.post('users', `/users/${role.userId}/roles`, {
			body: role,
		});
	}

	handleInviteSend = async () => {
		const { currentTeam } = this.props;
		const { nameFirst, nameLast, email, phoneNumber, coaches } = this.state;
		var contacts = this.state.contacts;

		if (nameFirst || nameLast || email || phoneNumber) {
			const newCoachContact = await this.handleNewContact();
			if (!newCoachContact) return;
			contacts.push(newCoachContact);
		}

		this.setState({
			isLoading: true,
			loadingMessage: 'adding your new coaches...',
		});

		var added = await Promise.all(
			contacts.map(async (contact) => {
				try {
					const newCoach = await this.signupCoach(contact);
					return newCoach;
				} catch (e) {
					alert(e.message);
				}
			})
		);
		// const coaches = await this.getCoaches();

		var renderedCoaches = this.state.renderedCoaches.concat(added);
		this.setState({
			open: false,
			contacts: [],
			contact: '',
			nameLast: '',
			nameFirst: '',
			isLoading: false,
			errorMessage: '',
			coaches: coaches || [],
			renderedCoaches: renderedCoaches || [],
		});
	};

	handleView = (coach) => {
		this.props.history.push('/profile', { user: coach, role: 'coach' });
	};

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
			errorMessage: '',
		});
	};

	handleSelect = (coach) => {
		this.setState({
			selectedCoach: coach == this.state.selectedCoach ? null : coach,
		});
	};
	handleSendLoginInfo = async () => {
		this.setState({ sendingInvites: true });
		const { currentTeam } = this.props;
		let { contacts, renderedCoaches } = this.state;
		contacts = [...new Set([...contacts, ...renderedCoaches])];
		const organization = await this.getOrganization(currentTeam.organizationId);
		const sentInfo = await this.sendLoginInfo(
			organization,
			contacts.map((item) => item.id)
		);

		console.log('sentInfo ---> ', sentInfo);
		this.setState({ sentInfo, contacts: [] });
	};

	searchCoaches = (event) => {
		const { coaches } = this.state;
		if (event.target.value) {
			this.setState({
				searchText: event.target.value,
				renderedCoaches: _.filter(
					coaches,
					(a) =>
						a.nameFirst
							.toLowerCase()
							.includes(event.target.value.toLowerCase()) ||
						a.nameLast
							.toLowerCase()
							.includes(event.target.value.toLowerCase()) ||
						(a.phoneNumber && a.phoneNumber.includes(event.target.value)) ||
						`${a.nameFirst} ${a.nameLast}`
							.toLowerCase()
							.includes(event.target.value.toLowerCase())
				),
			});
		} else {
			this.setState({
				searchText: '',
				renderedCoaches: coaches,
			});
		}
	};

	handleFilter = (filterValue) => (event) => {
		this.setState({
			rosterFilterBy: filterValue,
		});
	};
	handleRefreshCoaches = async () => {
		this.setState({
			isLoading: true,
			loadingMessage: 'hang tight while we grab your coaches...',
			searchText: '',
		});

		let coaches = await this.getCoaches();
		if (!coaches) {
			coaches = [];
		}
		await Promise.all(
			coaches.map(async (coach) => {
				if (coach.avatarUrl && !coach.avatarUrl.includes('https://')) {
					var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/user/avatar/${
						coach.legacyId ? coach.legacyId : coach.id
					}/${coach.avatarUrl}`;
					coach.avatarUrl = logo;
				}
			})
		);
		this.setState({
			isLoading: false,
			coaches: coaches || [],
			renderedCoaches: coaches || [],
		});
	};

	removeAllCoaches = () => {
		const { coaches } = this.state;
		const { userContext } = this.props
		const currentUser = userContext.user.id;
		if (
			window.confirm(
				'Are you sure you want to remove all coaches from this team?'
			)
		) {
			this.setState({
				isLoading: true,
				loadingMessage: 'hang tight, removing your coaches...',
			});
			coaches.forEach((coach) => {
				if(coach.id !== currentUser){
				this.handleDelete(coach);
				}
			});
			setTimeout(() => {
				this.handleRefreshCoaches();
			}, 2500);
		}
	};
	render() {
		const { classes, appContext, currentTeam, userContext = {} } = this.props;
		const {
			open,
			email,
			value,
			coaches,
			message,
			contacts,
			nameLast,
			nameFirst,
			isLoading,
			searchText,
			phoneNumber,
			errorMessage,
			existingUser,
			selectedCoach,
			rosterFilterBy,
			loadingMessage,
			wcsSendInviteOpen,
			invalidPhoneNumber,
			renderedCoaches = [],
			isExistingUserModalOpen,
		} = this.state;

		return (
			<PageWrapper
				title={getLocalizedString('COACHES')}
				{...this.props}
				isPageLoading={isLoading}
				loadingMessage={loadingMessage}>
				<div className={'rosterOuterContainer'}>
					<div>
						<div className={'row pb-2'}>
							<div className={'col-md-8 d-flex align-items-center'}>
								<h1 className={'pageTitle'}></h1>
								<TextField
									id='search'
									value={searchText ? searchText : ''}
									className={'searchTextField'}
									style={{ width: '70%' }}
									onChange={this.searchCoaches}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												<CloseIcon
													onClick={() =>
														this.setState({
															searchText: '',
															renderedCoaches: coaches,
														})
													}
												/>
											</InputAdornment>
										),
									}}
								/>
							</div>
							<div className='col-md-4 d-flex justify-content-end align-items-center'>
								<div style={{ marginRight: '10px' }}>
									{(!!appContext.isOwner && (coaches.length > 1)) && <ThemeButton
										width={'120px'}
										textColor={'#ffffff'}
										color={'#f93f3f'}
										height={'40px'}
										theme='leftAlignIcon'
										// iconName="Update"
										onClick={() => this.removeAllCoaches()}
										text={'Remove All Coaches'}
										position='left'
									/>}
								</div>
								<div style={{ marginRight: '10px' }}>
									<ThemeButton
										width={'150px'}
										textColor={'#ffffff'}
										color={'#1354F9'}
										height={'40px'}
										theme='leftAlignIcon'
										iconName='Update'
										onClick={() => this.handleRefreshCoaches()}
										text={'Update'}
										position='left'
									/>
								</div>
								<div>
									{(!appContext.isStaff ||
										(appContext.isStaff &&
											contextService.isStaffPermitted(
												currentTeam,
												'canInviteUsers'
											))) &&
									window.location.href.toLowerCase().includes('wcs') ? (
										<span
											className={'titleButtonContainer'}
											style={{ marginRight: 10 }}>
											<Button
												mini
												title='Send Invites'
												variant='fab'
												className={classes.plusButton}
												onClick={() =>
													this.setState({
														wcsSendInviteOpen: true,
														sentInfo: false,
													})
												}>
												<ShareIcon
													className={'icon'}
													style={{ color: 'white' }}
												/>
											</Button>
										</span>
									) : null}
									{!appContext.isStaff ||
									(appContext.isStaff &&
										contextService.isStaffPermitted(
											currentTeam,
											'canInviteUsers'
										)) ? (
										<span className={'titleButtonContainer'}>
											<Button
												mini
												title='Add Coaches'
												variant='fab'
												className={classes.plusButton}
												onClick={this.toggleInviteModal}>
												<AddIcon
													className={'icon'}
													style={{ color: 'white' }}
												/>
											</Button>
										</span>
									) : null}
								</div>
							</div>
						</div>
						<div
							className={
								renderedCoaches.length
									? 'selectContainer'
									: 'emptyUsersSelectContainer'
							}>
							{renderedCoaches.length ? (
								<div>
									<div
										className={'rosterCard'}
										style={{ backgroundColor: '#28272C' }}>
										<div className={'content'}>
											<div
												className={'rosterColumnContainer'}
												onClick={this.handleFilter('nameLast')}>
												<div className={'nameColumn'}>
													<p
														className={'textContainer'}
														style={{
															paddingLeft: 15,
															paddingRight: 15,
															color: 'white',
														}}>
														<strong>Last Name</strong>
													</p>
												</div>
											</div>
											<div
												className={'rosterColumnContainer'}
												onClick={this.handleFilter('nameFirst')}>
												<div className={'nameColumn'}>
													<p
														className={'textContainer'}
														style={{ color: 'white' }}>
														<strong>First Name</strong>
													</p>
												</div>
											</div>
											<div className={'rosterColumnContainer'}>
												<div className={'nameColumn'}>
													<p
														className={'textContainer'}
														style={{ color: 'white' }}>
														<strong>Phone #</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
									{_.orderBy(renderedCoaches, rosterFilterBy).map((coach) => (
										<div key={coach.id} className={'atheleteGridItem'}>
											<CoachCard
												coach={coach}
												classes={classes}
												appContext={appContext}
												currentTeam={currentTeam}
												isOwner={this.props.isOwner}
												selected={selectedCoach == coach}
												view={() => this.handleView(coach)}
												delete={() => this.handleDelete(coach)}
												handleSelect={() => this.handleSelect(coach)}
											/>
										</div>
									))}
								</div>
							) : searchText ? (
								<p className={'emptyListText'}>No results...</p>
							) : (
								<p className={'emptyListText'}>
									Hey coach, add some coaches...
								</p>
							)}
						</div>
						<AddCoachModal
							open={open}
							email={email}
							value={value}
							message={message}
							classes={classes}
							contacts={contacts}
							nameLast={nameLast}
							nameFirst={nameFirst}
							isLoading={isLoading}
							currentTeam={currentTeam}
							phoneNumber={phoneNumber}
							errorMessage={errorMessage}
							send={this.handleInviteSend}
							push={this.handleNewContact}
							close={this.toggleInviteModal}
							cancel={this.cancelInviteModal}
							handleChange={this.handleChange}
							invalidPhoneNumber={invalidPhoneNumber}
							handleTabValueChange={this.handleTabValueChange}
							remove={(contact) => this.removeContactFromList(contact)}
						/>
						<ExistingUserModal
							user={existingUser}
							open={isExistingUserModalOpen}
							confirm={this.handleExistingUserConfirm}
							decline={this.handleExistingUserDecline}
						/>
						<AddWCSCoachModal
							open={this.state.openWCS}
							value={value}
							wcsAthletes={this.state.wcsCoaches}
							classes={classes}
							contacts={contacts}
							nameFirst={nameFirst}
							phoneNumber={phoneNumber}
							currentTeam={currentTeam}
							selectCsv={this.selectCsv}
							send={this.handleSubmitWCSCoaches}
							push={(coach) => this.handleAddWCSCoach(coach)}
							close={this.toggleInviteModal}
							cancel={() => this.setState({ openWCS: !this.state.openWCS })}
							handleChange={this.handleChange}
							errorMessage={this.state.errorMessage}
							invalidPhoneNumber={invalidPhoneNumber}
							edit={(contact) => this.editContact(contact)}
							handleTabValueChange={this.handleTabValueChange}
							handleRosterTypeChange={this.handleRosterTypeChange}
							remove={(contact) => this.removeContactFromList(contact)}
						/>

						<Dialog
							open={wcsSendInviteOpen}
							TransitionComponent={Transition}
							keepMounted
							onClose={() =>
								this.setState({
									wcsSendInviteOpen: false,
									sendingInvites: false,
									sentInfo: false,
								})
							}
							aria-labelledby='alert-dialog-slide-title'
							aria-describedby='alert-dialog-slide-description'>
							<DialogTitle id='alert-dialog-slide-title'>
								{!this.state.sendingInvites && !this.state.sentInfo
									? 'Send Coaches Their Login Info?'
									: 'Login Info'}
							</DialogTitle>
							<DialogContent>
								{!this.state.sendingInvites && !this.state.sentInfo ? (
									<DialogContentText id='alert-dialog-slide-description'>
										Some of your coaches may have been onboarded using OneRoster
										and may not have their login info yet. Would you like to
										send these users their login info? Pressing <b>SEND INFO</b>{' '}
										will send a text/email to the coaches with their username
										and temporary password. This will not send info to users who
										have already logged in.
									</DialogContentText>
								) : null}
								{this.state.sendingInvites && !this.state.sentInfo ? (
									<DialogContentText id='alert-dialog-slide-description'>
										<div
											style={{
												textAlign: 'center',
												marginTop: 15,
												marginBottom: 15,
											}}>
											<CircularProgress
												variant='indeterminate'
												size={100}
												color={'primary'}
											/>
											<h3> Sending Login Info to Coaches...</h3>
										</div>
									</DialogContentText>
								) : null}
								{this.state.sendingInvites && this.state.sentInfo ? (
									<DialogContentText id='alert-dialog-slide-description'>
										<p>
											<b>Login Info Completed Sending</b>
										</p>
										<p>Successfully Sent: {this.state.sentInfo.successCount}</p>
										<p>
											{' '}
											Existing Logins (These users already have logins):{' '}
											{this.state.sentInfo.existsCount}
										</p>
										{this.state.sentInfo.failureCount ? (
											<p>Failed Sending: {this.state.sentInfo.failureCount}</p>
										) : null}
										{this.state.sentInfo.failureCount ? (
											<p>
												Note: The most common reason for sending failure is
												invalid email. Check these users:
											</p>
										) : null}
										{this.state.sentInfo.failures ? (
											<ul>
												{this.state.sentInfo.failures.map((contact) => (
													<li>
														{contact.nameFirst} {contact.nameLast}
													</li>
												))}
											</ul>
										) : null}
									</DialogContentText>
								) : null}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() =>
										this.setState({
											wcsSendInviteOpen: false,
											sendingInvites: false,
											sentInfo: false,
										})
									}>
									CLOSE
								</Button>
								{!this.state.sendingInvites && !this.state.sentInfo ? (
									<Button
										onClick={() => this.handleSendLoginInfo()}
										color='primary'>
										SEND INFO
									</Button>
								) : null}
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</PageWrapper>
		);
	}
}

class CoachCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			coach: {},
			selected: false,
			expanded: false,
		};
	}
	async componentDidMount() {
		console.log('props.isOwner ', this.props.isOwner);
		this.setState({ coach: this.props.coach || {} });
	}

	async componentWillReceiveProps(props, nextProps) {
		this.setState({ coach: props.coach });
	}

	handleExpandClick = () => {
		this.setState((state) => ({ expanded: !state.expanded }));
	};

	handleDeleteClick() {
		analyticsService.trackEvent('Account Removed User', {
			user_id: this.props.coach.id,
			email: this.props.coach.email,
			team_id: this.props.currentTeam.id,
			organization_id: this.props.currentTeam.organizationId,
			user_type: 'coach',
			sport: this.props.currentTeam.sport,
		});
		this.props.delete(this.props.coach);
	}

	handleEditClick() {
		this.props.view(this.props.coach);
	}

	formatPhoneNumber(phoneNumber) {
		if (!phoneNumber) return '';
		let areaCode = phoneNumber.substring(0, 3);
		let nextThree = phoneNumber.substring(3, 6);
		let finalFour = phoneNumber.substring(6, 10);
		return `${areaCode}-${nextThree}-${finalFour}`;
	}

	render() {
		const { selected, appContext, isOwner, currentTeam, classes } = this.props;
		const email = this.props.coach ? `${this.props.coach.email}` : '';
		// const phoneNumber = this.props.coach ? `${this.formatPhoneNumber(this.props.coach.phoneNumber)}` : "";
		const phoneNumber = this.props.coach ? this.props.coach.phoneNumber : '';
		const { coach } = this.state;

		return (
			<div>
				<div
					className={`rosterCard ${
						selected ? classes.selectedRosterCard : ''
					}`}>
					<div
						onClick={isOwner ? this.props.handleSelect : null}
						className={'content'}>
						<div className={'rosterColumnContainer'}>
							<div className={'nameColumn'}>
								<Hidden smDown>
									{coach.avatarUrl && (
										<Avatar
											src={coach.avatarUrl}
											style={{ height: 48, width: 48 }}
										/>
									)}
									{!coach.avatarUrl && (
										<Avatar style={{ height: 48, width: 48 }}>
											{' '}
											<PersonIcon />{' '}
										</Avatar>
									)}
								</Hidden>
								<p
									className={'textContainer'}
									style={{ paddingLeft: 15, paddingRight: 15 }}>
									{coach.nameLast}
								</p>
							</div>
						</div>
						<div className={'rosterColumnContainer'}>
							<div className={'nameColumn'}>
								<p className={'textContainer'}>{coach.nameFirst}</p>
							</div>
						</div>
						<Hidden smDown>
							<div className={'rosterColumnContainer'}>
								<div style={{ width: "49%", display: "inline-block", float: "left" }}>
								<p className={'textContainer'} style={{ textAlign: "left" }}>
									{phoneNumber}
								</p>
								</div>
								{selected && (
										<div className={'buttonContainer'} style={{ float: "right", display: "inline-block",  width: "49%" }}>
											{!appContext.isStaff ||
											(appContext.isStaff &&
												contextService.isStaffPermitted(
													currentTeam,
													'canEditUsers'
												)) ? (
												<IconButton
													title='Delete Coach'
													onClick={() => {
														if (
															window.confirm(
																'Are you sure you wish to remove this coach?'
															)
														)
															this.handleDeleteClick();
													}}
													aria-label='delete'
													variant='contained'
													className={'scheduleEdit'}>
													<DeleteIcon className={'styledIcon'} />
												</IconButton>
											) : null}

											<IconButton
												title='View Coach Details'
												onClick={() => this.handleEditClick()}
												aria-label='delete'
												variant='contained'
												className={'scheduleEdit'}>
												<EditIcon className={'styledIcon'} />
											</IconButton>
										</div>
								)}
							</div>
						</Hidden>
					</div>
				</div>
			</div>
		);
	}
}

class AddCoachModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleRemoveClick = (contact) => {
		this.props.remove(contact);
	};

	render() {
		const {
			email,
			value,
			close,
			message,
			classes,
			contacts,
			nameLast,
			nameFirst,
			isLoading,
			phoneNumber,
			errorMessage,
			loadingMessage,
			currentTeam = {},
			invalidPhoneNumber,
		} = this.props;

		return (
			<LegacyModalContainer
				open={this.props.open}
				onClose={close}
				containerClassName={'rosterPaper'}>
				<div className={'modalHeaderContainer'}>
					<h2 className={'modalHeaderText'}>Add Coaches</h2>
				</div>
				{isLoading ? (
					<div className={'outerModalContainer'}>
						<h2>{loadingMessage}</h2>
					</div>
				) : (
					<div className={'outerModalContainer'} style={{ marginBottom: 30 }}>
						<div className={'modalBody'}>
							{/* <Tabs
                            value={value}
                            textColor="primary"
                            indicatorColor="primary"
                            className={"tabContainer"}
                            onChange={this.props.handleTabValueChange}
                            style={{marginTop: contacts.length ? 65 : -20}}
                        >
                            <Tab label="Manual" value={0} />
                            <Tab label="Invite Code" value={1} />
                        </Tabs> */}
							{/* {contacts.length != 0 ? <div className={"contactsChipContainer"}>
                            {contacts.map((contact, index) => (
                                <Chip
                                    label={`${contact.nameFirst} ${contact.nameLast}`}
                                    onDelete={() => this.handleRemoveClick(contact)}
                                    className={"chip"}
                                    clickable={true}
                                    key={`${contact}-${index}`}
                                />
                            ))}
                        </div>
                        : null} */}
							<SwipeableViews index={value}>
								{/* <div className={"inputContainer"}>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} style={{marginTop:-24, paddingBottom:0}}>
                                        <div style={{height:20}}>
                                            {errorMessage.length > 0 ? <Typography style={{color:"red"}}>{errorMessage}</Typography> : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            value={nameFirst}
                                            name="contact"
                                            label={"First Name"}
                                            type="text"
                                            className={"textField"}
                                            onChange={this.props.handleChange("nameFirst")} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            value={nameLast}
                                            name="contact"
                                            label={"Last Name"}
                                            type="text"
                                            className={"textField"}
                                            onChange={this.props.handleChange("nameLast")} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            value={phoneNumber}
                                            name="phoneNumber"
                                            helperText={message}
                                            label={"Phone Number"}
                                            className={"textField"}
                                            error={invalidPhoneNumber}
                                            onChange={this.props.handleChange("phoneNumber")} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            value={email}
                                            name="email"
                                            label={"Email"}
                                            type="text"
                                            className={"textField"}
                                            onChange={this.props.handleChange("email")} />
                                    </Grid>
                                    <Grid item xs={12} className={"addContactButtonContainer"} style={{}}>
                                        <Button
                                            title="Add To Invitation List"
                                            variant="contained"
                                            className={classes.addUserButton}
                                            onClick={this.props.push}>
                                            Add Coach
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div> */}
								<div>
									<div className={'codeContainer'}>
										<div className={'codeOptionContainer'}>
											<h3>Scan</h3>
											<p>Have coaches scan from your screen</p>
										</div>
										<div className={'codeOptionContainer'}>
											<h3>Type</h3>
											<p>Send the following code to your coaches</p>
										</div>
									</div>
									<div className={'codeContainer'}>
										<div className={'codeOptionContainer'}>
											<QRCode value={currentTeam.coachCode} size={180} />
										</div>
										<div className={'codeOptionContainer'}>
											<h2>{currentTeam.coachCode}</h2>
										</div>
									</div>
								</div>
							</SwipeableViews>
						</div>
						<div className={'modalFooterContainer'}>
							<div>
								<div>
									<Button
										type='submit'
										className={'cancelButton'}
										onClick={this.props.cancel}>
										Cancel
									</Button>
									{/* <Button
                                        type="submit"
                                        className={classes.modalInviteButton}
                                        onClick={this.props.send}>
                                        Invite
                                    </Button> */}
								</div>
							</div>
						</div>
					</div>
				)}
			</LegacyModalContainer>
		);
	}
}

class ExistingUserModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { confirm, decline, open, user } = this.props;
		return (
			<LegacyModalContainer
				open={open}
				onClose={decline}
				containerClassName={'rosterPaper'}>
				<div className={'modalHeaderContainer'}>
					<h2 className={'modalHeaderText'}>We Found a Match</h2>
				</div>
				<div className={'outerModalContainer'}>
					<div className={'modalBody'}>
						<p>
							We found an existing user that matches those credentials, does
							this information look correct?
						</p>
						{user ? (
							<Grid container spacing={24}>
								<Grid item xs={12}>
									<p>
										Name: {user.nameFirst} {user.nameLast}
									</p>
								</Grid>
								{user.phoneNumber && (
									<Grid item xs={12}>
										<p>Phone: {user.phoneNumber}</p>
									</Grid>
								)}
								{user.email && (
									<Grid item xs={12}>
										<p>Email: {user.email}</p>
									</Grid>
								)}
							</Grid>
						) : null}
						<p>If not, no problem. We will put them into the system.</p>
					</div>
					<div className={'modalFooterContainer'}>
						<div>
							<div>
								<Button
									type='submit'
									className={'cancelButton'}
									onClick={confirm}>
									Yes
								</Button>
								<Button
									type='submit'
									className={'modalInviteButton'}
									onClick={decline}>
									No
								</Button>
							</div>
						</div>
					</div>
				</div>
			</LegacyModalContainer>
		);
	}
}
class AddWCSCoachModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rosterFilterBy: 'nameLast',
			renderedCoaches: [],
			searchText: '',
		};
	}

	handleRemoveClick = (contact) => {
		this.props.remove(contact);
	};

	handleEditClick = (contact) => {
		this.props.edit(contact);
	};

	handleFilter = (filterValue) => (event) => {
		this.setState({
			rosterFilterBy: filterValue,
		});
	};
	componentDidMount() {
		this.setState({ renderedCoaches: this.props.wcsAthletes });
	}
	componentWillReceiveProps(props) {
		this.setState({ renderedCoaches: props.wcsAthletes });
	}

	searchCoaches = (event) => {
		const { wcsAthletes } = this.props;
		if (event.target.value) {
			this.setState({
				searchText: event.target.value,
				renderedCoaches: _.filter(
					wcsAthletes,
					(a) =>
						a.nameFirst
							.toLowerCase()
							.includes(event.target.value.toLowerCase()) ||
						a.nameLast
							.toLowerCase()
							.includes(event.target.value.toLowerCase()) ||
						`${a.nameFirst} ${a.nameLast}`
							.toLowerCase()
							.includes(event.target.value.toLowerCase())
				),
			});
		} else {
			this.setState({
				searchText: '',
				renderedCoaches: wcsAthletes,
			});
		}
	};

	render() {
		const {
			classes,
			wcsAthletes,
			contacts,
			contact,
			errorMessage,
			nameFirst,
			nameLast,
			phoneNumber,
			email,
			value,
			csvType,
			close,
			currentTeam = {},
			tenant,
			invalidPhoneNumber,
			message,
		} = this.props;
		const { rosterFilterBy, renderedCoaches, searchText } = this.state;
		const theme = getThemeByTenant('wcs');
		return (
			<LegacyModalContainer
				open={this.props.open}
				onClose={close}
				containerClassName={'rosterPaper'}>
				<div className={'modalHeaderContainer'}>
					<h2 className={'modalHeaderText'}>Add Coaches</h2>
				</div>
				<div className={'outerModalContainer'}>
					<Grid
						container
						direction='column'
						justify='center'
						alignItems='center'
						style={{ border: '1px solid primary' }}>
						<Grid item style={{ width: '100%' }}>
							<Tabs
								value={value}
								textColor='primary'
								indicatorColor='primary'
								className={'tabContainer'}
								onChange={this.props.handleTabValueChange}
								style={value == 1 ? { marginTop: -15 } : {}}>
								<Tab label='Invite Code' value={0} />
								<Tab label='WCS Teachers' value={1} />
							</Tabs>
						</Grid>

						{value ? (
							<Grid item style={{ width: '100%' }}>
								<Grid
									container
									direction='column'
									justify='center'
									alignItems='center'
									style={{ border: '1px solid primary' }}>
									<Grid item style={{ width: '100%' }}>
										{contacts.length ? (
											<div className={'contactsChipContainer'}>
												{contacts.length === 0 ? (
													<div
														style={{ marginLeft: 10, color: 'darkgray' }}></div>
												) : (
													<div>
														{contacts.map((contact) => (
															<Chip
																label={`${contact.nameFirst} ${contact.nameLast}`}
																onDelete={() => this.handleRemoveClick(contact)}
																className={'chip'}
																clickable={true}
																onClick={() => this.handleEditClick(contact)}
																key={`${contact.nameFirst}-${contact.nameLast}`}
															/>
														))}
													</div>
												)}
											</div>
										) : null}
									</Grid>
								</Grid>
								<Grid
									container
									direction='column'
									justify='center'
									alignItems='center'
									style={{ marginBottom: 10 }}>
									<TextField
										id='search'
										value={searchText ? searchText : ''}
										className={'searchTextField'}
										onChange={this.searchCoaches}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position='end'>
													<CloseIcon
														onClick={() =>
															this.setState({
																searchText: '',
																renderedCoaches: wcsAthletes,
															})
														}
													/>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									className={'rosterCard'}
									style={{ backgroundColor: '#28272C' }}>
									<Grid container className={'content'}>
										<Grid
											item
											xs={4}
											className={'rosterColumnContainer'}
											onClick={this.handleFilter('nameLast')}>
											<div className={'nameColumn'} style={{ paddingTop: 15 }}>
												<p
													className={'textContainer'}
													style={{
														paddingLeft: 15,
														paddingRight: 15,
														color: 'white',
													}}>
													<strong>Last Name</strong>
												</p>
											</div>
										</Grid>
										<Grid
											item
											xs={4}
											className={'rosterColumnContainer'}
											onClick={this.handleFilter('nameFirst')}>
											<div className={'nameColumn'} style={{ paddingTop: 15 }}>
												<p
													className={'textContainer'}
													style={{ color: 'white' }}>
													<strong>First Name</strong>
												</p>
											</div>
										</Grid>
										<Grid
											item
											xs={4}
											className={'rosterColumnContainer'}
											onClick={this.handleFilter('graduationYear')}>
											<div className={'nameColumn'} style={{ paddingTop: 15 }}>
												<p
													className={'textContainer'}
													style={{ color: 'white' }}>
													<strong>Email</strong>
												</p>
											</div>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									className={'wcsUserContainer'}
									container
									style={{
										overflow: 'scroll',
										maxHeight: contacts && contacts.length ? 160 : 230,
										width: '100%',
										border: '1px gray solid',
									}}>
									{_.orderBy(renderedCoaches, [rosterFilterBy, 'nameLast']).map(
										(athlete, index) => {
											return (
												<Grid
													item
													xs={12}
													key={index}
													className={'atheleteWCSGridItem'}>
													<Grid
														container
														className={'content'}
														onClick={() => this.props.push(athlete)}>
														<Grid
															item
															xs={4}
															className={'rosterColumnContainer'}>
															<div
																className={'nameColumn'}
																style={{ paddingTop: 15 }}>
																<p
																	className={'textContainer'}
																	style={{ paddingLeft: 15, paddingRight: 15 }}>
																	{athlete && athlete.nameLast
																		? athlete.nameLast
																		: ''}
																</p>
															</div>
														</Grid>
														<Grid
															item
															xs={4}
															className={'rosterColumnContainer'}>
															<div
																className={'nameColumn'}
																style={{ paddingTop: 15 }}>
																<p className={'textContainer'}>
																	{athlete && athlete.nameFirst
																		? athlete.nameFirst
																		: ''}
																</p>
															</div>
														</Grid>
														<Grid
															item
															xs={4}
															className={'rosterColumnContainer'}>
															<div
																className={'nameColumn'}
																style={{ paddingTop: 15 }}>
																<p className={'textContainer'}>
																	{athlete && athlete.email
																		? athlete.email
																		: ''}
																</p>
															</div>
														</Grid>
													</Grid>
												</Grid>
											);
										}
									)}
									{!searchText && !renderedCoaches.length && (
										<div
											className={'rosterLoadingOverlay'}
											style={{
												width: '100%',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<CircularProgress style={{ marginTop: 5 }} />
											<h4 style={{ textAlign: 'center' }}>
												{
													"It looks like this is the first time you're adding your coaches so this may take a little bit. Thank you for your patience!"
												}
											</h4>
										</div>
									)}
									{searchText && !renderedCoaches.length && (
										<div
											className={'rosterLoadingOverlay'}
											style={{
												width: '100%',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<h4 style={{ textAlign: 'center' }}>{'No Results'}</h4>
										</div>
									)}
								</Grid>
								<div className={'modalFooterContainer'}>
									<div>
										<Button
											type='submit'
											className={'cancelButton'}
											onClick={this.props.cancel}>
											Cancel
										</Button>
										<Button
											type='submit'
											className={classes.modalInviteButton}
											onClick={this.props.send}>
											Add
										</Button>
									</div>
								</div>
							</Grid>
						) : (
							<Grid item style={{ width: '100%' }}>
								<div>
									<div className={'codeContainer'}>
										<div className={'codeOptionContainer'}>
											<h3>Scan</h3>
											<p>Have coaches scan from your screen</p>
										</div>
										<div className={'codeOptionContainer'}>
											<h3>Type</h3>
											<p>Send the following code to your coaches</p>
										</div>
									</div>
									<div className={'codeContainer'}>
										<div className={'codeOptionContainer'}>
											<QRCode value={currentTeam.coachCode} size={180} />
										</div>
										<div className={'codeOptionContainer'}>
											<h2>{currentTeam.coachCode}</h2>
										</div>
									</div>
								</div>
							</Grid>
						)}
					</Grid>
				</div>
			</LegacyModalContainer>
		);
	}
}
CoachRoster.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	const {
		session: { userContext, appContext },
	} = state;
	const currentTeam = _.find(
		userContext.appContextList,
		(c) => c.id === appContext.id
	);
	const isOwner = _.find(
		userContext.roles,
		(role) =>
			role.role == 'owner' && role.parentId == currentTeam.organizationId
	);
	return {
		appContext: appContext,
		currentTeam: currentTeam || {},
		isOwner: !!isOwner,
		userContext: userContext,
	};
};

export default withStyles(styles)(connect(mapStateToProps)(CoachRoster));
