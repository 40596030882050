import { getTenant as getTenantName } from "./utils/environmentUtils";
import { getLocalizedString } from "./utils/locale-utils";

export const TENANT = getTenantName();
//TODO: Add team to team route and make everything constant driven
export const ROUTE_PATH = {
  HOME: "/",
  PROFILE: "/profile",
  CHAT: "/chat",
  CHATNEW: "/chat/new",
  ACTIVITIES_LIST: "/activities/:id",
  CAMP_EVENT: "/events",
  CAMP_EVENT_NEW: "/events/new",
  EVENT: "/event/:id",
  SCHEDULES: "/schedules",
  TIMELINE: "/timeline",
  CREATE_EVENT: "/create-event/new",
  EDIT_EVENT: "/edit-event/:id",
  EDIT_RECURRING_EVENT: "/edit-event/:masterEventId/:id",
  CANCEL_EVENT: "/cancel-event/:id",
  CANCEL_RECURRING_EVENT: "/cancel-event/:masterEventId/:id",
  COACHES: "/coaches",
  ATHLETES: "/athletes",
  ATHLETES_IMPORT_STATUS: "/team/Athletes/imported",
  ATHLETES_IMPORT_ORG_STATUS: "/director/Athletes/imported",
  LOGIN_IN: "/in",
  LOGIN: "/login",
  SIGNUP: "/signup",
  OAUTH: "/oauth",
  SIGNUP_COACH: "/signup/coach",
  SIGNUP_PARENT: "/signup/parent",
  SIGNUP_ATHLETE: "/signup/athlete",
  VIDEO_EMBED: "/video/:videoId/embed",
  VIDEO_YOUTUBE: "/youtube/:videoId/embed",
  VIDEO_WISTIA: "/wistia/:videoId/embed",
  FORGOT_PASSWORD: "/forgot/password",
  ADMIN_NOTIFICATION: "/admin/notifications",
  ADMIN_USERS: "/admin/users",
  ADMIN_ORGANIZATIONS: "/admin/organizations",
  ADMIN_PRODUCTS: "/admin/products",
  PRODUCTS_NEW: "/products/new",
  ADMIN_TEAMS: "/admin/teams",
  ADMIN_INVOICES: "/admin/invoices",
  ADMIN_COPYACTIVITIES: "/admin/copyActivities",
  TRUELACROSSE_IMPORT: "/truelacrosse/import",
  TRUELACROSSE_NEW: "/truelacrosse/new",
  GAURDIAN_ATHLETE_NEW: "/guardian/athlete/new",
  GAURDIAN_ATHLETE: "/guardian/athlete/:id",
  GEAR: "/gear",
  MESSAGES: "/messages",
  DIRECTOR_MESSAGES: "/directorMessages",
  COACH_RESOURCES: "/coachResources",
  MARKETPLACE: "/marketplace",
  MARKETPLACE_DETAIL: "/marketplace/:id",
  GROUPS: "/groups",
  GROUPS_NEW: "/groups/new",
  GROUPS_ID: "/groups/:id",
  WORKOUTS: "/workouts",
  WORKOUTS_NEW: "/workouts/new",
  WORKOUT_PROGRAMS: "/workoutPrograms",
  WORKOUT_PROGRAM_NEW: "/workoutPrograms/new",
  WORKOUT_SCHEDULES: "/workoutSchedules",
  SETTINGS: "/settings",
  DIRECTOR_SETTINGS: "/directorSettings",
  NEW_TEAM: "/newTeam",
  DIRECTOR_SCHEDULES: "/directorSchedules",
  DIRECTOR_ACTIVITIES: "/directorActivities",
  DIRECTOR_NEW_TEAM: "/directorNewTeam",
  ADMIN_EDIT_ATHLETES_DIRECTOR: "/admin/editAthleticDirectors",
  ADMIN_EDIT_TEACHERS: "/admin/editTeachers",
  ADMIN_ADD_NEW_TEAM: "/admin/addNewTeam",
  ADMIN_VIEW_TEAMS: "/admin/viewTeams",
  ADMIN_REPORTS: "/admin/reports",
  LEADERBOARD_POINTS: "/leaderboards/points",
  LEADERBOARD_ACTIVITIES: "/leaderboards/activities",
  LEADERBOARD_ACTIVITIES_DETAILS: "/leaderboards/activities/:id",
  LEADERBOARD_ACTIVITIES_DETAILS_BY_USERID:
    "/leaderboards/activities/:id/user/:userId",
  DIRECTOR_COACHES: "/director/Coaches",
  SE_ADMIN_LOGIN: "/se/admin/login",
  SE_ADMIN_DIRECTOR_SIGNUP: "/se/admin/signup",
  SE_ADMIN: "/se/admin",
  SE_IMPORT: "/se/import",
  SE_IMPORT_CSV: "/se/importcsv/",
  MAXPAY_SUBSCRIPTION: "/team/maxpay/subscription",
  MAXPAY_BANK_INFO: "/team/maxpay/bankinginfo",
  MAXPAY_CUSTOMERS: "/team/maxpay/customers",
  MAXPAY_SALES_PAGE: "/team/maxpay/salesPage",
  MAXPAY_FREEIUM_SETTINGS: "/team/maxpay/freemiumSetting",
  MAXPAY_CONVERT: "/maxpay/convert",
  LIVE_TRAINING_STARTING: "/liveTraining/gettingStarted",
  ZOOM_CONFIGURATION: "/liveTraining/zoomConfiguration",
  UPLIFT_CONFIGURATION: "/liveTraining/upliftConfiguration",
  TEAM_MEETING_SETTINGS: "/liveTraining/teamSettings",
  SUPPORT: "/support",
  ATHLETE_GOALS: "/athletes/goals",
  QUESTIONS: "/training/questions",
  SUPPORT: "/support",
  DYNAMIC_LIBRARY: "/tenantadmin/editlibrary",
  ADMIN_CONTENT_SHARING: "/adminContentSharing",
  COACH_DASHBOARD: "/wellnessDashboard",
  ATHLETE_DASHBOARD: "/wellnessDashboard/athlete/:id",
};

export const PAGE_NAME = Object.freeze({
  ...ROUTE_PATH,
});

export const getPageNameByPath = (path) => {
  return Object.keys(PAGE_NAME).find((key) => PAGE_NAME[key] === path);
};

export const NAV_TAB_HEAD = Object.freeze({
  DASHBOARD: "MY DASHBOARD",
  TEAM_MANAGEMENT: getLocalizedString("TEAM") + " MANAGEMENT",
  COMMUNICATION: getLocalizedString("COMMUNICATION"),
  TRAINING_LIBRARIES: getLocalizedString("TRAINING_LIBRARIES"),
  // WORKOUT_TRAINING:'Workouts & Training',
  WORKOUT_TRAINING: getLocalizedString("WORKOUTS_ASSIGNMENTS"),
  ADMIN: "ADMIN",
  DIRECTOR: getLocalizedString("DIRECTOR"),
  GUARDIAN: "GUARDIAN",
  WCS_ADMIN: "ADMIN",
});
