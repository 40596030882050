import React from "react";
import ModalContainer from "../../../components/modalContainer";
import {
  Tabs,
  Tab,
  List,
  Button,
  Avatar,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import SearchInput from "../../../components/searchInput";
import CheckBox from "../../../components/checkBox";
import { withStyles } from "@material-ui/core/styles";
import { PrimaryButton } from "../../../components/v2/buttons";
import { colors } from "../../../components/v2/utils/theme-utils";
import { WhistleIcon } from "../../../components/icons";
import { userRoleTypes } from "../../../models/enums";
import { MdGroups } from "react-icons/md";
import { FaRunning, FaUserFriends } from "react-icons/fa";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsRoot: {
    marginBottom: 10,
  },
  tabsIndicator: {
    height: 4,
    backgroundColor: colors.BLACK,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    color: colors.GREY_40,
    fontSize: 14,
    fontWeight: 400,
    marginRight: theme.spacing.unit * 4,
    "&:hover": {
      color: "none",
      opacity: 1,
    },
    "&:focus": {
      outline: "none",
    },
  },
  tabSelected: {
    color: `${colors.BLACK} !important`,
    fontSize: 14,
    fontWeight: 700,
  },
  searchBox: {
    height: 48,
    borderRadius: 4,
  },
  nested: {
    padding: "10px 20px",
  },
  checkBox: {
    marginRight: 20,
  },
});

const ItemView = ({
  role,
  item,
  index,
  classes,
  participantsById,
  handleSelectParticipants,
}) => {
  return (
    <>
      <ListItem key={index} disableGutters className={classes.nested}>
        {item?.avatarUrl ? (
          <Avatar src={item?.avatarUrl || ""} />
        ) : (
          <Avatar>{`${item.firstName[0]}${item.lastName[0]}`}</Avatar>
        )}
        <ListItemText
          primary={`${item.firstName} ${item.lastName}`}
          secondary={role}
        />
        <ListItemSecondaryAction onClick={() => handleSelectParticipants(item)}>
          <div className={classes.checkBox}>
            <CheckBox checked={participantsById[item.id] ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

const All = ({
  all,
  members,
  groups,
  classes,
  groupsById,
  handleSelect,
  handleSelectGroups,
}) => {
  return (
    <List component="div" disablePadding>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <MdGroups />
        </Avatar>
        <ListItemText
          primary={`All members from this team (${members?.total})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("ALL", !all.members)}
        >
          <div className={classes.checkBox}>
            {" "}
            <CheckBox checked={all.members ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <FaRunning />
        </Avatar>
        <ListItemText
          primary={`All Athletes (${members?.meta?.totalAthletes})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("ATHLETES", !all.athletes)}
        >
          <div className={classes.checkBox}>
            <CheckBox checked={all.athletes ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <FaUserFriends />
        </Avatar>
        <ListItemText
          primary={`All Parents (${members?.meta?.totalParents})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("PARENTS", !all.parents)}
        >
          <div className={classes.checkBox}>
            {" "}
            <CheckBox checked={all.parents ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <WhistleIcon />
        </Avatar>
        <ListItemText
          primary={`All Coaches (${members?.meta?.totalCoaches})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("COACHES", !all.coaches)}
        >
          <div className={classes.checkBox}>
            {" "}
            <CheckBox checked={all.coaches ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>

      <div style={{ height: 20, backgroundColor: colors.GREY_20 }} />
      {(groups || []).map((group, index) => {
        return (
          <>
            <ListItem key={index} disableGutters className={classes.nested}>
              <Avatar>
                <MdGroups />
              </Avatar>
              <ListItemText
                primary={`${group.name} (${group?.participants?.length})`}
              />
              <ListItemSecondaryAction
                onClick={() => handleSelectGroups(group)}
              >
                <div className={classes.checkBox}>
                  {" "}
                  <CheckBox checked={groupsById[group.id] ? true : false} />
                </div>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </>
        );
      })}
    </List>
  );
};

const Athletes = ({
  all,
  classes,
  members,
  handleSelect,
  participantsById,
  handleSelectParticipants,
}) => {
  return (
    <List component="div" disablePadding>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <FaRunning />
        </Avatar>
        <ListItemText
          primary={`All Athletes (${members?.meta?.totalAthletes})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("ATHLETES", !all.athletes)}
        >
          <div className={classes.checkBox}>
            <CheckBox checked={all.athletes ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {(((members || {}).results || {}).athlete || []).map((item, index) => {
        return (
          <ItemView
            item={item}
            index={index}
            classes={classes}
            role={userRoleTypes.ATHLETE}
            participantsById={participantsById}
            handleSelectParticipants={handleSelectParticipants}
          />
        );
      })}
    </List>
  );
};

const Parents = ({
  all,
  classes,
  members,
  handleSelect,
  participantsById,
  handleSelectParticipants,
}) => {
  return (
    <List component="div" disablePadding>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <FaUserFriends />
        </Avatar>
        <ListItemText
          primary={`All Parents (${members?.meta?.totalParents})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("PARENTS", !all.parents)}
        >
          <div className={classes.checkBox}>
            {" "}
            <CheckBox checked={all.parents ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {(((members || {}).results || {}).parent || []).map((item, index) => {
        return (
          <ItemView
            item={item}
            index={index}
            classes={classes}
            role={userRoleTypes.PARENT}
            participantsById={participantsById}
            handleSelectParticipants={handleSelectParticipants}
          />
        );
      })}
    </List>
  );
};

const Coaches = ({
  all,
  classes,
  members,
  handleSelect,
  participantsById,
  handleSelectParticipants,
}) => {
  return (
    <List component="div" disablePadding>
      <Divider />
      <ListItem disableGutters className={classes.nested}>
        <Avatar>
          <WhistleIcon />
        </Avatar>
        <ListItemText
          primary={`All Coaches (${members?.meta?.totalCoaches})`}
        />
        <ListItemSecondaryAction
          onClick={() => handleSelect("COACHES", !all.coaches)}
        >
          <div className={classes.checkBox}>
            <CheckBox checked={all.coaches ? true : false} />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {(((members || {}).results || {}).coach || []).map((item, index) => {
        return (
          <ItemView
            item={item}
            index={index}
            classes={classes}
            role={userRoleTypes.COACH}
            participantsById={participantsById}
            handleSelectParticipants={handleSelectParticipants}
          />
        );
      })}
    </List>
  );
};

const AddParticipants = ({
  all,
  index,
  groups,
  members,
  classes,
  groupsById,
  handleDone,
  searchValue,
  handleSelect,
  handleChange,
  selectedTeam,
  participantsById,
  handleSelectGroups,
  handleSelectParticipants,
}) => {
  return (
    <ModalContainer
      open={true}
      showFooter={false}
      showCloseIcon={false}
      onClose={() => {}}
      width={"60vw"}
      customPadding={0.001}
      backgroundColor={"#fff"}
      height={"80vh"}
      heading={
        <div className="d-flex align-items-center m-2">
          <span style={{ width: "30vw" }}>Add participants</span>
          <ListItem disableGutters>
            {selectedTeam?.logo ? (
              <Avatar src={selectedTeam?.logo || ""} />
            ) : (
              <Avatar>
                <MdGroups />
              </Avatar>
            )}
            <ListItemText
              primary={
                <span style={{ fontSize: 14, fontWeight: 500, width: "10vw" }}>
                  {selectedTeam.customName || selectedTeam.name}
                </span>
              }
            />
          </ListItem>
        </div>
      }
      customButton={
        <PrimaryButton style={{ margin: 20 }} onClick={handleDone}>
          Done
        </PrimaryButton>
      }
    >
      <div className="row">
        <div className="col-md-7">
          <Tabs
            value={index}
            onChange={(_, value) => handleChange("index", value)}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label="All"
            />
            <Tab
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label="Athletes"
            />
            <Tab
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label="Parents"
            />
            <Tab
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              label="Coaches"
            />
          </Tabs>
        </div>
        <div className="col-md-5">
          {(index !== 0) ? (
            <SearchInput
              placeholder={"Filter participants by name"}
              customClass={classes.searchBox}
              searchTerm={searchValue}
              handleSearch={(value) => handleChange("searchValue", value)}
            />
          ) : null}
        </div>
        <div className="col-md-12">
          {index === 0 && (
            <div>
              <All
                all={all}
                groups={groups}
                classes={classes}
                members={members}
                groupsById={groupsById}
                handleSelect={handleSelect}
                handleSelectGroups={handleSelectGroups}
                handleSelectParticipants={handleSelectParticipants}
              />
            </div>
          )}
        </div>
        <div className="col-md-12">
          {index === 1 && (
            <div>
              <Athletes
                all={all}
                classes={classes}
                members={members}
                handleSelect={handleSelect}
                participantsById={participantsById}
                handleSelectParticipants={handleSelectParticipants}
              />
            </div>
          )}
        </div>
        <div className="col-md-12">
          {index === 2 && (
            <div>
              <Parents
                all={all}
                classes={classes}
                members={members}
                handleSelect={handleSelect}
                participantsById={participantsById}
                handleSelectParticipants={handleSelectParticipants}
              />
            </div>
          )}
        </div>
        <div className="col-md-12">
          {index === 3 && (
            <div>
              <Coaches
                all={all}
                classes={classes}
                members={members}
                handleSelect={handleSelect}
                participantsById={participantsById}
                handleSelectParticipants={handleSelectParticipants}
              />
            </div>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default withStyles(styles)(AddParticipants);
