import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual, groupBy } from "lodash";
import moment from "moment";
import TimelineComponent from "../components/Timeline";
import {
  setStartDate,
  setSelectedTeams,
  getEventsByTeamIdsAndUserId,
} from "../../../actions/nylas-calendar-actions";

import { enableFullScreenLoader } from "../../../actions/loading";

class Timeline extends Component {
  constructor(props) {
    super(props);

    const { byId, loggedInUserId, teamsById, currentTeamId, selectedTeams } =
      props;
    const {
      teams: { results },
    } = byId[loggedInUserId];
    const groupByOrgId = groupBy(results, "organizationId");
    const currentTeam = teamsById[currentTeamId];
    this.state = {
      searchValue: "",
      grouppedEvents: {},
      currentTeam: currentTeam,
      groupByOrgId: groupByOrgId,
      selectedMonth: moment().format("YYYY-MM-DD"),
      selectedEvent: {},
      selectedTeamsById: selectedTeams.length
        ? selectedTeams.reduce((obj, item) => {
            obj[item] =
              teamsById[item] || results.find((team) => team.id === item) || {};
            return obj;
          }, {})
        : {
            [currentTeam.id]: currentTeam,
          },
    };
  }

  componentDidMount = () => {
    const { currentTeam, selectedTeamsById } = this.state;
    const {
      startDate,
      selectedTeams,
      loggedInUserId,
      setSelectedTeams,
      getEventsByTeamIdsAndUserId,
    } = this.props;
    if (selectedTeams.length) {
      getEventsByTeamIdsAndUserId({
        organizationId: currentTeam.organizationId,
        loggedInUserId,
        selectedTeams,
        limit: 100,
        startDate,
        offset: 0,
      });
    } else {
      setSelectedTeams(Object.keys(selectedTeamsById));
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentTeam } = this.state;
    const {
      events,
      startDate,
      selectedTeams,
      loggedInUserId,
      getEventsByTeamIdsAndUserId,
    } = this.props;
    if (!isEqual(prevProps.selectedTeams, selectedTeams)) {
      getEventsByTeamIdsAndUserId({
        organizationId: currentTeam.organizationId,
        loggedInUserId,
        selectedTeams,
        limit: 100,
        startDate,
        offset: 0,
      });
    }
    if (prevProps.startDate !== startDate) {
      getEventsByTeamIdsAndUserId({
        organizationId: currentTeam.organizationId,
        loggedInUserId,
        selectedTeams,
        limit: 100,
        startDate,
        offset: 0,
      });
    }
    if(!isEqual(prevProps.events.data, events.data)) {
      let grouppedEvents = {}
      grouppedEvents = groupBy(Object.keys(events.data), (date) =>
        moment(date).format("MMMM")
      ); 
      Object.keys(grouppedEvents).forEach(item => {
        grouppedEvents[item] = grouppedEvents[item].reduce((obj, item) => {
          obj[item] = events.data[item]
          return obj
        }, {})
      })
      this.setState({ grouppedEvents })
    }
    if (prevProps.events.loading !== events.loading) {
      this.props.enableFullScreenLoader(events.loading);
    }
  };

  handleChange = (key, value, parent) => {
    if (parent) {
      const data = { ...this.state[parent] };
      data[key] = value;
      this.setState({ [parent]: data });
    } else {
      this.setState({ [key]: value });
    }
  };

  loadData = async () => {
    const { currentTeam } = this.state;
    const {
      offset,
      startDate,
      selectedTeams,
      loggedInUserId,
      getEventsByTeamIdsAndUserId,
    } = this.props;
    getEventsByTeamIdsAndUserId({
      organizationId: currentTeam.organizationId,
      loggedInUserId,
      selectedTeams,
      limit: 100,
      startDate,
      offset,
    });
  };

  handleCreate = () => {
    const { history } = this.props;
    history.push("/create-event/new");
  };

  handleSelectTeam = (team) => {
    const { selectedTeamsById } = this.state;
    const { setSelectedTeams, enableFullScreenLoader } = this.props;
    enableFullScreenLoader(true, "Loading....");
    if (!selectedTeamsById[team.id]) {
      selectedTeamsById[team.id] = team;
    } else {
      delete selectedTeamsById[team.id];
    }
    setSelectedTeams(Object.keys(selectedTeamsById));
    enableFullScreenLoader(false);
    this.setState({ selectedTeamsById });
  };

  handleSearch = (searchValue) => {
    const { byId, loggedInUserId } = this.props;
    let {
      teams: { results },
    } = byId[loggedInUserId];
    results = results.filter((item) =>
      (item.customName || item.name || "")
        .toLowerCase()
        .includes((searchValue || "").toLowerCase())
    );
    const groupByOrgId = groupBy(results, "organizationId");
    this.setState({ searchValue, groupByOrgId });
  };

  render() {
    const {
      searchValue,
      currentTeam,
      selectedEvent,
      selectedMonth,
      groupByOrgId,
      grouppedEvents,
      selectedTeamsById,
      loadingChildMessage,
      loadingChildComponent,
    } = this.state;
    const { events, history, offset, startDate, setStartDate } = this.props;
    return (
      <TimelineComponent
        {...this.props}
        offset={offset}
        history={history}
        events={events.data}
        startDate={startDate}
        hasMore={events.hasMore}
        loadData={this.loadData}
        searchValue={searchValue}
        currentTeam={currentTeam}
        setStartDate={setStartDate}
        groupByOrgId={groupByOrgId}
        selectedMonth={selectedMonth}
        selectedEvent={selectedEvent}
        grouppedEvents={grouppedEvents}
        handleCreate={this.handleCreate}
        handleChange={this.handleChange}
        handleSearch={this.handleSearch}
        selectedTeamsById={selectedTeamsById}
        handleSelectTeam={this.handleSelectTeam}
        loadingChildMessage={loadingChildMessage}
        loadingChildComponent={loadingChildComponent}
      />
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId, byId },
  teams: { byId: teamsById, currentTeamId },
  nylasCalendar: { offset, events, startDate, selectedTeams },
}) => ({
  byId,
  offset,
  events,
  startDate,
  teamsById,
  currentTeamId,
  selectedTeams,
  loggedInUserId,
});

export default connect(mapStateToProps, {
  setStartDate,
  setSelectedTeams,
  enableFullScreenLoader,
  getEventsByTeamIdsAndUserId,
})(Timeline);
