import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getIconByName } from '../../../icons/utils';
import ListItem from './ListItem'
import '../index.scss';


const Component = ({ title, subTitle, list, handleTeamChange }) => {
    const [expanded, setExpanded] = useState(true)

    let RightIcon = getIconByName(expanded ? "ExpandLess" : "ExpandMore")
    
    return (
        <>
            <div className={"row no-gutters justify-content-between align-items-center role-switcher-list"} >
                <div>
                    <span className={"title"}>{title}</span>
                    <span className={"subTitle"}>{subTitle}</span>
                </div>
                {/* <div> <RightIcon className={classNames("right-icon", { grey: !expanded, white: expanded })} /> </div> */}
            </div>
            {expanded ?
                (list || []).map((item, index) => {
                    return (
                        <div className={"list-item"}>
                            <ListItem
                                key={index}
                                node={item}
                                level={0}
                                handleTeamChange={handleTeamChange}
                            />
                        </div>
                    )
                })
                : null
            }
        </>
    )
};

Component.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        subTitle: PropTypes.string,
        type: PropTypes.string,
        handleChange: PropTypes.func,
        children: PropTypes.array,
        selected: PropTypes.bool,
        leftIcon: PropTypes.string,
    }))
};

Component.defaultProps = {
    title: "",
    subTitle: "",
    rightIcon: "ExpandMore",
    list: []
};

export default Component;