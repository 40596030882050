import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Switch from '../../../components/switch';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import Cell from '../../../components/editableTableCell'
import Pagination from '../../../components/pagination'
import SportsEngineBtn from '../../../components/themeButton';
import { userImportClient } from '../../../clients'
import {testId} from '../../../utils/test-utils';
const styles = theme => ({
    userInfo: {
        width: "100%",
        marginTop: "20px",
        "-moz-border-radius": "6px",
        "-webkit-border-radius": "6px",
        borderCollapse: "separate",
        borderSpacing: 0,
        borderRadius: "6px",
        border: "1px solid #ffffff",
        "& tr": {
            background: "#ffffff",
            height: "45px",
            "& th": {
                background: "#A0A7AB",
                padding: "12px 20px",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "150%",
                color: "#FFFFFF"
            }
        },
        /* top-left border-radius */
        "& tr:first-child th:first-child": {
            "border-top-left-radius": "6px"
        },
        "& tr:first-child th:last-child": {
            "border-top-right-radius": "6px"
        },

        /* bottom-left border-radius */
        "& tr:last-child td:first-child": {
            "border-bottom-left-radius": "6px"
        },

        /* bottom-right border-radius */
        "& tr:last-child td:last-child": {
            "border-bottom-right-radius": "6px"
        },
        "& tr th,tr td": {
            borderRight: "1px solid #DDE2E5",
            borderBottom: "1px solid #DDE2E5",
        },
        "& tr th:last-child, tr td:last-child": {
            borderRight: "0px"
        },
        "& tr th": {
            background: "#eee",
            borderTop: "1px solid #DDE2E5",
            textAlign: "left"
        }
    },
    issueCountInfo: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "150%",
        color: "#F93F3F"
    },
    athleteImportHead: {
        textAlign: "left",
        padding: "30px",
        paddingTop: "0px",
        color: "#0B0B0B",
        fontFamily: "Poppins",
        fontStyle: "normal"
    },
    headerHead: {
        fontWeight: "600",
        fontSize: "26px",
        lineHeight: "120%",
        marginBottom: "20px"
    },
    headInfo: {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#27AE60",
        marginBottom: "30px",
        "&.hasError": {
            color: "#F93F3F"
        }
    }

});

class AthleteImportReview extends Component {
    constructor(props) {
        super(props);
        this.myRef = null;
        this.fields=[
            {
                displayName:"First Name",
                value:"firstName",
                isRequired:true
            },
            {
                displayName:"Last Name",
                value:"lastName",
                isRequired:true
            },
            {
                displayName:"Email",
                value:"email",
                isRequired:true
            },
            {
                displayName:"Birth Date",
                value:"birthdate"
            },
            {
                displayName:"Phone",
                value:"phoneNumber"
            }
        ];
        this.state = {
            showRowWithIssuesOnTop: true,
            isLoading:true,
            athletesCount:0,
            athletes:[],
            currentPage:0,
            totalPages:1,
            athletePerPageLimit:20,
            showLoadingChild:false,
            athletesWithIssueCount:0
        }
    }

    toggleShowIssuesOnTop = () => {
        let { showRowWithIssuesOnTop } = this.state;
        this.setState({ showRowWithIssuesOnTop: !showRowWithIssuesOnTop, currentPage:0 },this.fetchAthletes);
    }


    updateAField = (fieldName, value,  index) => {
        let { athletes, athletesWithIssueCount } = this.state;
        let athlete = athletes[index];
        let missingFields = [...athlete.missingFields];       
        let isRequired = this.fields.find(field => field.value == fieldName).isRequired;    
        if(isRequired && value == "" && (athlete.missingFields||[]).length == 0){   
            athletesWithIssueCount = athletesWithIssueCount + 1;
            missingFields = missingFields.concat(fieldName)            
        }
        else if(isRequired && value != "" && missingFields.includes(fieldName)){                 
            missingFields = missingFields.filter(item => item!= fieldName);
            if(missingFields.length == 0){
                athletesWithIssueCount = athletesWithIssueCount - 1;
            }
        } 
        let updatedUser = {...athlete, [fieldName]:value, missingFields};
        let newArray = [...athletes]
        newArray[index] = updatedUser;
        this.setState({athletes: newArray, athletesWithIssueCount});
    }

    syncUpdatedUser = async (index, fieldName) => {
        let { athletes} = this.state;
        let { importProcessId } = this.props;
        let athlete = athletes[index];       
        let { id, firstName, lastName, email, birthdate, phoneNumber} = athlete;            
        try{
            let { data } = await userImportClient.updateUserImportById(id, firstName, lastName, email, birthdate, phoneNumber);                            
        }
        catch(e){
            console.log("Exception");
            console.log(e);
            let queryParams = {
                limit:1,
                offset:0
            };
            userImportClient.searchUserImportsByProcessId(importProcessId, queryParams).then(({data}) => {
                let { total:athletesCount, results:athletes, meta} = data;
                let athletesWithIssueCount = (meta||{}).missingFieldsTotal || 0;
                this.setState({athletesWithIssueCount});
            }).catch((e) => {
                console.log("Exception")
                console.log(e)
            })
            //Delete invalid field
            this.updateAField(fieldName, "", index);            
        }         
    }

    componentDidMount(){     
        this.fetchAthletes();
    }

    fetchAthletes = (page = null) => {
            let { importProcessId } = this.props;
            let { currentPage, athletePerPageLimit, showRowWithIssuesOnTop} = this.state;
            if(page !== null){
                this.setState({isLoading:true, showLoadingChild : true});
            }
            let queryParams = {
                limit:athletePerPageLimit,
                offset:(page !== null? page : currentPage) * athletePerPageLimit
            };
            if(showRowWithIssuesOnTop){
                queryParams.sort = "status.keyword.desc"
            }
            userImportClient.searchUserImportsByProcessId(importProcessId, queryParams).then(({data}) => {
                let { total:athletesCount, results:athletes, meta} = data;
                let athletesWithIssueCount = (meta||{}).missingFieldsTotal || 0;
                this.setState({athletesCount, athletesWithIssueCount,originalAthletes:athletes, athletes, currentPage:page, totalPages: Math.ceil(athletesCount/athletePerPageLimit)});
            }).catch((e) => {
                console.log("Exception")
                console.log(e)
            }).finally(()=>{
                this.setState({isLoading:false, showLoadingChild : false})
            });               
    }

    render() {
        const { classes, goBack, importRecords } = this.props;
        const { athletesCount, athletesWithIssueCount, athletes, showRowWithIssuesOnTop, isLoading, currentPage, totalPages, showLoadingChild } = this.state;
        let hasDataError = athletesWithIssueCount != 0;
        return (
            <React.Fragment>
                <HeaderWrapper>
                    <AthleteImportHeader
                        classes={classes}
                        hasDataError={hasDataError}
                        importRecords={importRecords}
                        goBack={goBack}
                        athletesCount={athletesCount}
                    />
                </HeaderWrapper>
                <BodyWrapper isLoading={isLoading} showLoadingChild={showLoadingChild}>
                    <div className={classes.reviewContainer} >
                        {hasDataError && <div className={classnames(classes.summaryContainer, "d-flex justify-content-between align-items-center")}>
                            <div className={classes.toggleIssueContainer}>
                                <Switch
                                    label="Show Rows with Issues at the top"
                                    checked={showRowWithIssuesOnTop}
                                    handleChange={this.toggleShowIssuesOnTop}
                                />
                            </div>
                            {athletesWithIssueCount && <div className={classes.issueCountInfo}>
                                {athletesWithIssueCount} athletes missing required info
                            </div>}
                        </div>}
                        <table className={classes.userInfo}>
                            <thead>
                                <tr>
                                    {this.fields.map((field) => { return <th>{field.displayName}</th> })}
                                </tr>
                            </thead>
                            <tbody>
                                {athletes.map((athlete, index) => (<tr key={index}>
                                    {this.fields.map((field) => {
                                        return (<Cell
                                            testId={field.displayName}
                                            onBlur={() => this.syncUpdatedUser(index, field.value)}
                                            placeholder={field.isRequired ? "no data - click to edit" : ""}
                                            value={athlete[field.value] || ""}
                                            onChange={(value) => this.updateAField(field.value, value, index)} />)
                                    })}
                                </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-end">
                    <Pagination
                        currentPage={currentPage + 1}
                        totalPages={totalPages}
                        changeCurrentPage={(page)=>this.fetchAthletes(page - 1)}
                        theme="square-i"
                        />
                    </div>
                </BodyWrapper>
            </React.Fragment>
        );
    }
}

const AthleteImportHeader = (props) => {
    let { hasDataError, importRecords, goBack, classes, athletesCount } = props;
    return (
        <div className={classes.athleteImportHead}>
            <div className={classes.headerHead}>Athlete Import Review - {athletesCount} Athlete Records</div>
            <div className={classnames(classes.headInfo, { "hasError": hasDataError })}>
                {hasDataError ?
                    <React.Fragment>
                        <div className={classes.headInfoDetail}>Some athletes records are <span className="font-weight-bold">missing minimum reqiured info</span></div>
                        <div className={classes.headInfoDetail}>Please add the missing information below. If you wish to discard the athletes with missing data you can proceed.</div>
                    </React.Fragment> :
                    <div className={classes.headInfoDetail}>Everything looks good, ready to import athletes</div>
                }
            </div>
            <div className={classnames(classes.serviceButtonContainer, "d-flex align-items-center justify-content-between")}>
                <div className="d-flex justify-content-start">
                    <SportsEngineBtn
                        position="left"
                        width={"170px"}
                        height={"50px"}
                        iconName="ChevronLeft"
                        onClick={goBack}
                        disabled={false}
                        text="Edit Mapping"
                        color={"#ffffff"}
                        textColor={"#5B6062"}
                    />
                </div>
                <div className="d-flex align-self-end" {...testId(`submit-button`)}>
                        <SportsEngineBtn
                            width={hasDataError?"507px":"293px"}
                            height={"50px"}
                            iconName="ChevronRight"
                            onClick={importRecords}
                            text={hasDataError ? "Proceed and Discard Athletes with Missing Data" :
                            "Import All Athlete Records"
                        }
                        />
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(AthleteImportReview);