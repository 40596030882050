import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';
import '../index.scss';
import { getIconByName } from '../../../icons/utils';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    rightIconColor: {
        '& svg': {
            color: theme.palette.secondary.main
        }
    }
});


const Component = ({ url, isOpen, title, subTitle, rightIcon, rightIconClassName, classes, handleClick, isSingleTeam }) => {
    let RightIcon;
    if (rightIcon) {
        RightIcon = getIconByName(rightIcon)
    }
    return (
        <div className={`selected-team-container`} onClick={handleClick}>
            <div className={`row no-gutters align-items-center ${isOpen ? "" : "justify-content-center"}`}>
                <div className={"col-md-2 align-items-center justify-content-center"}>
                    <Avatar src={url} className={"avatar-container align-items-center justify-content-center"} />
                </div>
                {isOpen ?
                    <div className={`col-md-${rightIcon ? '9' : '10'} detail-container`}>
                        <div className={"title"}>{title}</div>
                        <div className={"subtitle"}>{subTitle}</div>
                    </div>
                    : null}
                {rightIcon && isOpen && !isSingleTeam ?
                    <div className={"col-md-1"}>
                        <div className={classNames("right-icon-container", classes.rightIconColor)}><RightIcon className={rightIconClassName} /></div>
                    </div>
                    : null}
            </div>
        </div>
    )
}

Component.propTypes = {
    url: PropTypes.string,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    rightIcon: PropTypes.string,
    rightIconClassName: PropTypes.string,
    isSingleTeam: PropTypes.bool,
};

Component.defaultProps = {
    isOpen: true,
    isSingleTeam: false,
};

export default withStyles(styles)(Component);
