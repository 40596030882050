import { ENABLE_LOADER, DISABLE_LOADER } from '../types/loader';

const initState = {
    loading: false,
    loadingMessage: "",
    loadingMessage2:""
};
export default (state = initState, action) => {
    const { type, payload } = action;
    switch(type){
      case ENABLE_LOADER: return { loading : true, loadingMessage : payload.loadingMessage, loadingMessage2: payload.loadingMessage2}; break;
      case DISABLE_LOADER: return { loading : false }
  }
  return state;
}
