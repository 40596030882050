import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Calendar = (props) => (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="11" cy="11" r="11" fill="#222127" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7494 14.3598C16.14 13.9692 16.14 13.3361 15.7494 12.9455L13.3385 10.5346L15.7494 8.12374C16.1399 7.73321 16.1399 7.10005 15.7494 6.70952L14.8251 5.78519C14.4346 5.39467 13.8014 5.39467 13.4109 5.78519L11 8.19608L8.58898 5.78508C8.19846 5.39456 7.56529 5.39456 7.17477 5.78508L6.25043 6.70941C5.85991 7.09994 5.85991 7.7331 6.25043 8.12363L8.66143 10.5346L6.25041 12.9456C5.85989 13.3362 5.85989 13.9693 6.25041 14.3599L7.17475 15.2842C7.56527 15.6747 8.19844 15.6747 8.58896 15.2842L11 12.8732L13.4109 15.2841C13.8014 15.6746 14.4346 15.6746 14.8251 15.2841L15.7494 14.3598Z" fill="white" />
    </SvgIcon>
);

export default Calendar;

