import React, { Component } from 'react'
import IconButton from '../../iconButton'
import ActivityGroup from '../activityGroup'
import EditIcon from '@material-ui/icons/Edit'
import { Draggable } from 'react-beautiful-dnd'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import { API } from "aws-amplify";
import _ from "lodash"
import { withStyles } from '@material-ui/core'

const styles = {
    subDetails: {
        marginTop: "2px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: "#A0A7AB",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "100%"
    },
    questionContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: "left",
        width: "100%",
        alignItems: "center"
    },
    questionBlockOuterContainer: {
        display: "flex",
        alignItems: "center",
        padding: "20px 15px"
    },
    questionBlock: {
        flex: "1"
    },
    deleteIconContainer: {
        marginLeft: "30px",
        marginRight: "10px",
        color: "#DDE2E5",
        cursor:"pointer"
    },
    questionBlockContainer: {
        display: "flex",
        marginBottom: "10px",
        borderRadius: "4px",
        background: "#FFFFFF",
        boxSizing: "border-box",
        flexDirection: "column",
        border: "1px solid #DDE2E5"
    },
    infoDetail:{
        whiteSpace: "nowrap",
        marginLeft: "30px"
    }
}
class QuestionBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDeleteConfirm: false
        }
    }

    componentDidMount = async () => { }


    handleDeletePress = () => {
        const { handleDeleteForBlockItem } = this.props
        const { showDeleteConfirm } = this.state
        if (!showDeleteConfirm)
            return this.setState({ showDeleteConfirm: true })
        handleDeleteForBlockItem()
        this.handleDeleteCancel()
    }

    handleDeleteCancel = () => {
        this.setState({ showDeleteConfirm: false })
    }

    render() {
        const { question, index, classes } = this.props
        const { showDeleteConfirm } = this.state;
        return (
            <Draggable key={question.droppableId} draggableId={question.droppableId.toString()} index={index} >
                {(provided) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={classes.questionBlockContainer}
                    >
                        <div className={classes.questionBlockOuterContainer}>
                            <div className={classes.questionContainer}>
                                {showDeleteConfirm ?
                                    <div className={'removeActivityText'}>{`Remove ${question.title || ""}?`}</div> :
                                    <div className={classes.questionBlock}>
                                        <div>
                                            {question.title}
                                        </div>
                                        <div className={classes.subDetails}>
                                            <div className={classes.dropDownDesc}>
                                                {question.description}
                                            </div>
                                            <div className={classes.infoDetail}>
                                                {question.inputInfo}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {showDeleteConfirm ?
                                    <div className={'activityConfirmDeleteContainer'}>
                                        <span className={'cancelText'} onClick={this.handleDeleteCancel}>Cancel</span>
                                        <IconButton
                                            width={111}
                                            color={'white'}
                                            position={'right'}
                                            textColor={'#F93F3F'}
                                            text={'Confirm'}
                                            onClick={this.handleDeletePress}
                                            icon={<DeleteIcon className={'deleteIcon'} />}
                                        />
                                    </div>
                                    :
                                    <div className={classes.deleteIconContainer}>
                                        <DeleteIcon className={'icon'} onClick={this.handleDeletePress} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }
}


export default withStyles(styles)(QuestionBlock);