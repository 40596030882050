import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import KickIDLogo from '../assets/images/themes/kickid/kickid-logo.png';
import KickIDLongLogo from '../assets/images/themes/kickid/kickid-long-logo.png';
import homeImage from '../assets/images/themes/kickid/kickid-welcome.jpg';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id:"kickid",
    name: 'Kick ID',
    appUrl: 'm1-kickid://',
    domain: 'kickid.gomaxone.com',
    link: 'https://itunes.apple.com/us/app/maxone/id1274244831?mt=8',// replace with real link
    onAppleAppStore: 'https://apps.apple.com/us/app/maxone/id1274244831',// replace with real link
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.athletes&hl=en'// replace with real link
  },
  image: {
    home: homeImage,
    logo: KickIDLogo,
    loginLogo: KickIDLongLogo,
    logoWidth: 60,
    logoHeight: 30,
  },
  palette: {
    type: "light",
    primary: {
      main: "#323335"
    },
    secondary: {
      main: "#6AE4FA"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#0082ea"
    },
    secondary: green,
    error: red,
  }

});
