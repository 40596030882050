import React from 'react';
import PropTypes from 'prop-types';
import '../index.scss';
import { getIconByName } from '../../../icons/utils';

const Component = ({ logo, label, maxWidth, maxHeight, rightIcon, rightIconClassName, isOpen, toggleOpen }) => {
    let RightIcon;
    if (rightIcon) {
        RightIcon = getIconByName(rightIcon)
    }
    return (
        <div className={`row no-gutters align-items-center ${isOpen ? "justify-content-between" : "justify-content-center"} header`}>
            {logo ? <img onClick={toggleOpen} src={logo} style={{ maxWidth, maxHeight }} /> : null}
            {label ? <p>{label}</p> : null}
            {rightIcon && isOpen ? <div onClick={toggleOpen}> <RightIcon className={rightIconClassName} /> </div> : null}
        </div>
    )
}

Component.propTypes = {
    logo: PropTypes.string,
    isOpen: PropTypes.bool,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    rightIcon: PropTypes.string,
    handleClick: PropTypes.func,
    rightIconClassName: PropTypes.array,
    toggleOpen: PropTypes.func
};

Component.defaultProps = {
    selected: false,
    isOpen: true,
    maxWidth: 120,
    maxHeight: 50
};

export default Component;
