import { Storage } from "aws-amplify";

export async function s3Upload(file, filename, type, callback) {
    const customPrefix = { public: "", private: "", protected: "" };

    // S3 doesn't handle quicktime videos well
    if (type === "video/quicktime") type = "video/mp4";

    const stored = await Storage.put(filename || file.name, file, {
      customPrefix: customPrefix,
      contentType: type,
      progressCallback(progress) {
          if (typeof callback == "function") {
              callback(((progress.loaded / progress.total) * 100).toFixed(2));
          }
      },
    });

    const result = await Storage.get(stored.key, { customPrefix: customPrefix });
    const resultArr = result.split("?");
    return resultArr[0];
}
