import React, { Component, Linking, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import { connect } from 'react-redux';
import Loader from '../../components/loaderWithMessage';
import { userImportClient } from '../../clients'
import classnames from 'classnames'
import ThemeButton from '../../components/themeButton'
import Pagination from '../../components/pagination'
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import { AirplaneIcon } from '../../components/icons';
import BodyWrapper from "../../components/bodyWrapper";
import ModalContainer from '../../components/modalContainer';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { ROUTE_PATH } from "../../routeUtil";
import { API } from "aws-amplify";
import SearchInput from '../../components/searchInput';
import DropDownWithSearch from '../../components/dropDownWithSearch';
import TabularDesign from '../../components/tabularDesign';
import AthleteImporter from '../seImporter/athleteImporter';
import {
  Checkbox
} from '@material-ui/core';
const STATUS = Object.freeze({
  INVITED: 'INVITED',
  INACTIVE: 'INACTIVE'
})
let styles = {
  athleteList: {
    "& tr:hover": {
      background: "#F8F8F8"
    }
  },
  td: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#0B0B0B",
    textAlign: "left"
  },
  tableHead: {
    height: "36px !important"
  },
  topSearchSection: {
    marginBottom: "20px"
  },
  quickFilterContainer: {
    height: "24px"
  },
  filter: {
    cursor: "pointer",
    marginRight: "12px",
    paddingRight: "12px",
    borderRight: "1px solid #DDE2E5",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#1354F9",
    height: "24px",
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginLeft: "30px"
    },
    "&:last-child": {
      borderRight: "none"
    }
  },
  rowSelected:{
    background: "#F8F8F8 !important",
    "& .checkBoxContainer": {      
      "&:before":{
        content: "''",
        width: "1px",
        height: "calc(100% + 1px)",
        borderLeft: "4px solid #1354F9",
        top: "0",
        left: "0",
        position: "absolute"
      }
    }
  },
  quickFilterCount: {
    color: "#A0A7AB",
    fontWeight: "500",
    paddingLeft: "2px"
  },
  searchBox: {   
    height: "44px",
    width: "300px"  
  },
  sendInviteContainer: {
    width: "136px",
    color: "#1354F9",
    textAlign: "right",
    cursor: "pointer",
    "& svg": {
      fill: "#1354F9",
      width: "16px",
      height: "16px",
      marginRight: "4px"
    }
  },
  serviceIcon:{
    "& svg": {
      cursor:"pointer",
      width: "20px",
      height: "20px",
      fill: "#DDE2E5"
    }
  },
  editUserContainer: {
    width: "100px",
    textAlign: "right !important",
    "& svg": {
      cursor:"pointer",
      width: "20px",
      height: "20px",
      fill: "#DDE2E5"
    },
    "& span:first-child": {
      marginRight: "12px"
    }
  },
  statusBox: {
    padding: "4px 8px",
    fontWeight: "bold",
    fontSize: "11px",
    lineHeight: "12px",
    color: "#FFFFFF",
    borderRadius: "20px"
  },
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow:"hidden",
    "& svg": {
      fill: "#A0A7AB"
    },
    "& img":{
      maxWidth:"100%",
      maxHeight:"100%"
    }
  },
  checkBoxContainer: {
    width: "32px",
    position:"relative",
    overflow:"visible !important"
  },  
  lastNameSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "nowrap"
  },
  checkboxRoot: {
    width: "16px",
    height: "16px",
    "& svg": {
      fill: "#A0A7AB"
    }
  },
  checkboxChecked: {
    "& svg": {
      fill: "#1354F9"
    }
  },
  filterActive: {
    color: "#0B0B0B"
  },
  athleteModalTxt:{
    paddingTop:"20px",
    paddingBottom:"20px"
  },
  pageInfo:{
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "right",
    color: "#5B6062",
    textAlign: "right",
    marginLeft: "auto"
  },
  highlightColor:{
    color:"#1354F9"
  },
  selectAllContainer:{
    background: "#FFFFFF",
    border: "1px solid #DDE2E5",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#000000",
    minHeight:"37px",
    minWidth:"100%",
    marginBottom:"8px"
  },
  cursorPointer:{
    cursor:"pointer"
  },
  padding15: {
    padding: "15px !important",
  }
};

class AthleteRoster extends Component {
  constructor(props) {
    super(props);
    this.typingTimeout = null;
    this.actionItems = [
      {
        displayName:"Delete",
        color:"#F93F3F",
        action:this.deleteUserActionItem
      },
      {
        displayName:"Send Invite / Re-invite",
        action:this.sendInviteActionItem
      }
    ];
    this.directorLevelActionItems = [
      {
        displayName:"Change Team",
        action:this.toggleTeamEditModal
      }
    ];
    this.fields = [
      {
        displayName: "Last Name",
        value: "lastName"
      },
      {
        displayName: "First Name",
        value: "firstName"
      },
      {
        displayName: "Email",
        value: "email"
      }
    ];
    this.directorFields = [
      {
        displayName: "Team Name",
        value: "teamName"
      },
      {
        displayName: "Group Name",
        value: "groupName"
      }
    ];
    this.state = {
      isLoading: true,
      athletesCount: 0,
      athletes: [],
      currentPage: 0,
      totalPages: 1,
      athletePerPageLimit: 20,
      searchTerm: "",
      inviteSentModalActive:false,
      selectedUserIds:new Set(),
      groupSelected:false,
      isOrgLevel:isFromDirectorNav(),
      quickFilters: {
        "All": 0,
        "Invited": 0,
        "Inactive": 0
      },
      activeQuickFilter: "All",
      selectedDropDownOption:"",
      allSelected:false,
      athletesFields:isFromDirectorNav() ? [...this.fields, ...this.directorFields]: this.fields
    }
  }
  componentDidMount() {
    this.fetchAthletes();
  }

  async componentWillReceiveProps(props, nextProps) {
    const { isOrgLevel } = this.state
    const isOrgLevelDetail = isFromDirectorNav()
    console.log("isOrgLevelDetail")
    console.log(isOrgLevelDetail)
    console.log("isOrgLevel")
    console.log(isOrgLevel)
    if (isOrgLevel !== isOrgLevelDetail) {
      this.setState({
        isOrgLevel: isOrgLevelDetail,
        activeQuickFilter: "All",
        allSelected: false,
        selectedDropDownOption: "",
        selectedUserIds: new Set(),
        searchTerm: "",
        athletesFields: isOrgLevelDetail ? [...this.fields, ...this.directorFields]: this.fields
      }, () => {
        this.fetchAthletes(0);
      });
    }
  }

  getESFilterName = (activeFilter) => {
    switch (activeFilter) {
      case "Invited": return "INVITE_SUCCESS";
      case "Inactive": return "INVITE_PENDING";
    }
  }

  setChildRef = (ref) => {
    this.paginationRef = ref;
  }

  deleteAthletes = async (userIds=[], isBulkAction) => {
    let { currentPage, athletes, allSelected, isOrgLevel} = this.state;
    let { currentTeam } = this.props;
    let organizationId = (currentTeam||{}).organizationId;
    let teamId = (currentTeam||{}).id;
    let deleteAll = allSelected && isBulkAction;   
    if (window.confirm(`Are you sure you wish to delete${deleteAll ? ' all' :' this'.pluralize(' these', userIds.length)}${' athlete'.pluralize(" athletes", userIds.length)}?`)) {
      try {
        this.setState({ isLoading: true, showLoadingChild: true, loadingMessage1: "Deleting User", loadingMessage2: "" });
        if(deleteAll){
          isOrgLevel?
          await userImportClient.deleteUserImportsByOrganizationId(organizationId):
          await userImportClient.deleteUserImportsByTeamId(teamId);
          this.fetchAthletes(0)
        }
        else {
          await userImportClient.deleteUserImportsByIds(userIds);
          this.fetchAthletes(Math.max(0, (userIds.length == athletes.length? currentPage -1: currentPage)))
        }
      }
      catch (e) {
        console.log("Exception")
        console.log(e);
      }
      this.setState({ isLoading: false, showLoadingChild: false, loadingMessage1: "", loadingMessage2: "" });
    }
  }

  deleteUserActionItem = async () => {
    let { selectedUserIds } = this.state;
    await this.deleteAthletes(Array.from(selectedUserIds), true);
  }

  fetchAthletes = async (page = null) => {
    let { currentUser, currentTeam } = this.props;
    let { currentPage, athletePerPageLimit, activeQuickFilter, searchTerm, quickFilters:oldMetaInfo, isOrgLevel } = this.state;
    let organizationId = (currentTeam||{}).organizationId;
    if (page !== null) {
      this.setState({ isLoading: true, showLoadingChild: true });
    }
    let queryParams = {
      limit: athletePerPageLimit,
      offset:(page !== null? page : currentPage) * athletePerPageLimit
    };
    if (activeQuickFilter != "All") {
      queryParams.status = this.getESFilterName(activeQuickFilter)
    }
    if (searchTerm) {
      queryParams.q = searchTerm;
    }
    try{
      let responseData;
      if(isOrgLevel){
        let { data } = await userImportClient.searchUserImportsByOrganizationId(organizationId, queryParams);
        responseData = data;
      }
      else {
        let { data } = await  userImportClient.searchUserImportsByTeamId(currentTeam.id, queryParams);
        responseData = data;
      }
      let { total: athletesCount, results: athletes, meta: { inactiveTotal, invitedTotal, quickFilters } } = responseData;
      //Preserve total in case of search term
      let updatedQuickFilters = {
        "All": invitedTotal + inactiveTotal,
        "Invited": invitedTotal,
        "Inactive": inactiveTotal
      }
      athletes.forEach(element => {
        element.statusInfo = this.getStatus(element.status)
      });
      this.setState({quickFilters:updatedQuickFilters, athletesCount, athletes, currentPage: page, totalPages: Math.ceil(athletesCount / athletePerPageLimit) });
    }
    catch(e) {
      console.log("Text")
      console.log(e)
    }
    this.setState({ isLoading: false,  loadingMessage1:"", loadingMessage2:"", showLoadingChild: false, groupSelected:false })
  }

  selectQuickFilter = (filterName) => {
    let { activeQuickFilter } = this.state;
    if (filterName != activeQuickFilter) {
      this.setState({ activeQuickFilter: filterName, currentPage: 1 }, () => this.fetchAthletes(0));
    }
  }

  handleSearch = (searchTerm, timeOut = 0) => {    
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.setState({
      searchTerm
    }, () => {
      this.typingTimeout = setTimeout(() => {
        this.fetchAthletes(0);
      }, (timeOut || 1000))
    });
  }

  toggleAthleteInviteModal = () => {
    let { enableAthletInviteModal } = this.state;
    this.setState({ enableAthletInviteModal })
  }

  getStatus = (status) => {
    switch (status) {
      case "INVITE_SUCCESS": return { name: STATUS.INVITED, color: "#FFA800" };
      default: return { name: STATUS.INACTIVE, color: "#F93F3F" };
    }
  }

  toggleUserSelection = (userId) => {
    let { selectedUserIds } = this.state;
    let clonedUserIds = new Set(selectedUserIds);
    if(clonedUserIds.has(userId)){
      clonedUserIds.delete(userId)
    }
    else{
      clonedUserIds.add(userId)
    }
    this.setState({selectedUserIds:clonedUserIds, allSelected: false, groupSelected:false});
  }

  onGroupSelect = () => {
    let { groupSelected, athletes, selectedUserIds } = this.state;
     let clonedUserIds = new Set(selectedUserIds);
     let userIds = athletes.map((item) => item.id);
    if(userIds.every(id => clonedUserIds.has(id))){
      userIds.forEach(id => clonedUserIds.delete(id))
    }
    else{
      userIds.forEach(id => clonedUserIds.add(id))
    }
    this.setState({selectedUserIds: clonedUserIds, groupSelected: !groupSelected, allSelected: false})
  }

  selectAll = () => {
    let { allSelected, groupSelected} = this.state;
    //Clear all selection
    if(allSelected === true){
      this.setState({groupSelected:false, selectedUserIds:new Set()})
    }
    this.setState({allSelected: !allSelected})
  }

  sendInviteActionItem = async () =>{
      let { selectedUserIds } = this.state;
      await this.sendInviteByUserIds(Array.from(selectedUserIds),"", true);
  }

  sendInviteByUserIds = async (userIds, athleteName, isBulkAction) => {
    let { currentPage, allSelected, athletesCount, isOrgLevel } = this.state;
    let { currentTeam } = this.props;
    this.setState({ isLoading: true, showLoadingChild: true, loadingMessage1:"Sending Invite", loadingMessage2:"" });
    try{
      if(isBulkAction && allSelected){
        let teamId = (currentTeam||{}).id;        
        let organizationId = (currentTeam||{}).organizationId;
        if(isOrgLevel){
          await userImportClient.inviteUserImportByOrganizationId(organizationId);
        }
        else {
          await userImportClient.inviteUserImportByTeamId(teamId);
        }
        this.setState({selectedAthleteName: athleteName, selectedAthletesCount: athletesCount})
      }
      else{
        await userImportClient.inviteByUserImportIds(userIds);
        this.setState({selectedAthleteName: athleteName, selectedAthletesCount: userIds.length})
      }
      await this.fetchAthletes(currentPage);    
      this.toggleAthleteInviteSentModal();
    }
    catch(e){
      console.log("Exception")
      console.log(e)
    }
    this.setState({isLoading: false, showLoadingChild: false, loadingMessage1:"", loadingMessage2:""})
  }

  toggleAthleteInviteSentModal = () => {
    let { inviteSentModalActive } = this.state;
    this.setState({inviteSentModalActive: !inviteSentModalActive});
  }


  applyBulkOption = () => {
    let { selectedActionItem } = this.state;
    let actionItems = this.getActionItems();
    if(selectedActionItem !== null){
      let actionDetails = actionItems[selectedActionItem];
      if(!!actionDetails && actionDetails.action){
        actionDetails.action();
      }
      this.setState({selectedActionItem:null});
    }
  }

  selectActionItem = (selectedOption) =>{
    this.setState({selectedActionItem:selectedOption});
  }

  getActionItems = () =>{
    let { isDirector } = this.props;
    let computedActionItems = this.actionItems;
    if(isDirector){
      computedActionItems = computedActionItems.concat(this.directorLevelActionItems);
    }
   return computedActionItems;
  }

  changeCurrentPage = (page) => {
    if(!!this.paginationRef && !!this.paginationRef.scrollTo){
      this.paginationRef.scrollTo(0,0)
    }
    this.fetchAthletes(page - 1);
  }


  editUser = async(athlete) => { 
    let userInfo = null;
    let isImportedUser = false;
    if(athlete.status == "JOINED" && athlete.userId){
      this.setState({ isLoading: true, showLoadingChild: true });
      userInfo = await this.getUser(athlete.userId);
    }  
    else {
      userInfo = athlete;
      isImportedUser = true;
    } 
    this.props.history.push(ROUTE_PATH.PROFILE, { user: userInfo, role: 'athlete', isImportedUser: isImportedUser })
  }

  updateTeam = async (newTeamId) => {
    let { selectedUserIds, allSelected, isOrgLevel, currentPage} = this.state;
    let { currentTeam } = this.props;
    let currentTeamId = currentTeam.id;
    let orgId = currentTeam.organizationId;
    this.toggleTeamEditModal();
    if(selectedUserIds || allSelected){
      try {
        this.setState({
          isLoading:true,
          showLoadingChild: true,
          loadingMessage1:"Updating team...",
          loadingMessage2: "This may take several minutes based on the number of athletes. Please come back later."
        }) 
        
        if(allSelected){
          if(isOrgLevel){
               //Bulk Org level update
              await userImportClient.updateUserImportTeamByOrgId(orgId, newTeamId);
          }
          else {
            //Bulk Team level update
            await userImportClient.switchUserImportTeamByIds(currentTeamId, newTeamId);
          }    
        }
        else {  
          //Update selected user ids
          await userImportClient.updateUserImportsTeamByIds(Array.from(selectedUserIds), newTeamId);
        }

      }
      catch(e){
          console.log("Exception")
          console.log(e);
      }
      this.fetchAthletes(currentPage);
  }  
}

  getUser = async (userId) => {
    return API.get("users", `/users/${userId}`);
  }

  toggleTeamEditModal = () =>{
    let { enableTeamEditModal } = this.state;
    this.setState({enableTeamEditModal: !enableTeamEditModal});
  }

  render() {
    let { isLoading,
      quickFilters,
      currentPage,
      totalPages,
      athletes,
      groupSelected,
      loadingMessage1,
      loadingMessage2,
      showLoadingChild,
      activeQuickFilter,
      inviteSentModalActive,      
      selectedAthleteName,
      selectedAthletesCount,
      searchTerm,
      selectedUserIds,
      selectedActionItem,
      athletesCount,
      allSelected,
    athletePerPageLimit, 
    athletesFields, 
    enableTeamEditModal } = this.state;
    let { classes, teams, tenant, history } = this.props;
    let actionItems = this.getActionItems();
    return (
      <PageWrapper setChildRef={this.setChildRef} title="Invites" removePadding={true} {...this.props}>
        <div className={classes.pageContainer}>
          <BodyWrapper classes={classnames(classes.padding15)} isLoading={isLoading} showLoadingChild={showLoadingChild} loadingMessage1={loadingMessage1} loadingMessage2={loadingMessage2}>
            <div className={classes.athletesContainer}>
              <div className={classnames(classes.topSearchSection, "d-flex align-items-center")}>
                <SearchInput placeholder={"Search Athletes"} customClass={classes.searchBox} searchTerm={searchTerm} handleSearch={this.handleSearch}/>
                <div className={classnames(classes.quickFilterContainer, "d-flex align-items-center")}>
                  {
                    Object.keys(quickFilters).map(filter => {
                      return (
                        <div className={classnames(classes.filter, { [classes.filterActive]: filter == activeQuickFilter })} onClick={() => this.selectQuickFilter(filter)}>
                          {filter}
                          <span className={classes.quickFilterCount}>({quickFilters[filter]})</span>
                        </div>)
                    })
                  }
                </div>
                <AthleteImporter history={history} tenant={tenant}/>
              </div>
            </div>
            {(athletes.map((item) => item.id).every(id => selectedUserIds.has(id)) || allSelected) && 
            athletesCount > athletePerPageLimit && 
            <div className={classnames("d-flex text-align-center justify-content-center align-items-center", classes.selectAllContainer)}>
                All {allSelected? athletesCount: athletes.length} on this page are selected.&nbsp; 
                <span onClick={this.selectAll} className={classnames(classes.highlightColor, classes.cursorPointer)}>{allSelected? "Clear selection": `Select all ${athletesCount} athletes instead`}</span>
            </div>}
            <TabularDesign
              currentPage={currentPage}
              totalPages={totalPages}
              changeCurrentPage={this.changeCurrentPage}
              footerTxt={!isLoading && <div className={classes.pageInfo}>
                Showing
                <span className={"font-weight-bold"}> {(currentPage * 20) + Math.min(athletes.length, 1)} - {(currentPage * 20) + athletes.length} </span> of <span>{athletesCount}{!!searchTerm && `(total ${quickFilters.All})`}</span>
              </div>
              }
              selectedActionItem={selectedActionItem}
              selectActionItem={this.selectActionItem}
              actionItems={actionItems}
              actionDisabled={!selectedUserIds.size}
              applyBulkOption={this.applyBulkOption}>
              <React.Fragment>
                <thead>
                  <tr className={classes.tableHead}>
                    <th className={classes.checkBoxContainer}>
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checkboxChecked
                        }}
                        onChange={this.onGroupSelect}
                        checked={athletes.map((item) => item.id).every(id => selectedUserIds.has(id)) || allSelected}
                      /></th>
                      <th></th>
                    {athletesFields.map((field) => { return <th style={{ minWidth: 100 }}>{field.displayName}</th> })}
                    <th className={classes.statusContainer} style={{ minWidth: 100 }}>Status</th>
                    <th className={classes.sendInviteContainer} style={{ minWidth: 100 }}></th>
                    <th className={classes.editUserContainer} style={{ minWidth: 100 }}>Edit User</th>
                  </tr>
                </thead>
                <tbody className={classes.athleteList}>
                  {athletes.map((athlete, index) => (<tr key={index} className={classnames({ [classes.rowSelected]: selectedUserIds.has(athlete.id) })}>
                    <td className={classnames(classes.checkBoxContainer, "checkBoxContainer")}>
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checkboxChecked
                        }}
                        onChange={() => this.toggleUserSelection(athlete.id)}
                        checked={selectedUserIds.has(athlete.id) || this.state.allSelected}
                      /></td>
                      <td>
                      <span className={classes.athleteImgBox}>
                                {athlete.avatarUrl ?
                                  <img src={athlete.avatarUrl} /> :
                                  <AccountCircle />
                                }
                              </span>
                      </td>
                    {athletesFields.map((field) => {
                      return (
                        <td className={classes.td} style={{ minWidth: 100 }}>
                          {field.value == "lastName" ?
                            <span className={classes.lastNameSection}>
                              <span>{(athlete[field.value] || "").highlightTerm(searchTerm, classes.highlightColor)}</span>
                            </span>
                            :
                            <span>{(athlete[field.value] || "").highlightTerm(searchTerm, classes.highlightColor)}
                            </span>
                          }

                        </td>
                      )
                    })}
                    <td className={classnames(classes.td, classes.statusContainer)} style={{ minWidth: 100 }}>
                      <span className={classes.statusBox} style={{
                        background: athlete.statusInfo.color
                      }}>{athlete.statusInfo.name}</span>
                    </td>
                    <td style={{ minWidth: 100 }} className={classnames(classes.td, classes.sendInviteContainer)} onClick={() => this.sendInviteByUserIds([athlete.id], athlete.firstName + " " + athlete.lastName)}>
                      <span>
                        <AirplaneIcon />
                      </span>
                      <span>
                        {athlete.statusInfo.name == STATUS.INVITED && "Resend Invite"}
                        {athlete.statusInfo.name == STATUS.INACTIVE && "Send Invite"}</span>
                    </td>
                    <td style={{ minWidth: 100 }} className={classnames(classes.td, classes.editUserContainer)}>
                      <span className={classes.serviceIcon} onClick={() => this.deleteAthletes([athlete.id])}><DeleteIcon /></span>
                      <span className={classes.serviceIcon} onClick={() => this.editUser(athlete)}><EditIcon /></span>
                    </td>
                  </tr>))
                  }
                </tbody>
              </React.Fragment>
            </TabularDesign>
          </BodyWrapper>
        </div>
        <AthleteInviteModal
          toggleAthleteInviteModal={this.toggleAthleteInviteSentModal}
          classes={classes}
          isOpen={inviteSentModalActive}
          closePopup={this.toggleAthleteInviteSentModal}
          athleteName={selectedAthleteName}
          athletesCount={selectedAthletesCount}
        />
       {enableTeamEditModal && <TeamUpdateModal
          allSelected={allSelected}
          selectedUserIds={selectedUserIds}
          classes={classes}
          isOpen={enableTeamEditModal}
          closePopup={this.toggleTeamEditModal}
          teams={teams}    
          updateTeam={this.updateTeam} 
        />}
      </PageWrapper>
    );
  }
}

function isFromDirectorNav() {
  let showOrgDetails = window.location.href.indexOf(ROUTE_PATH.ATHLETES_IMPORT_ORG_STATUS) != -1;
  return showOrgDetails;
}

function mapStateToProps(state) {
  const { session: { accessToken, userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  const teams = (userContext.appContextList||[]).filter(item => item.isTeam);
  return {
    isDirector:!!appContext  && (appContext.isOwner || appContext.isHeadcoach || appContext.isAdmin),
    accessToken: accessToken,
    currentUser: userContext.user,
    currentTeam: currentTeam||{},
    teams
  };
}

function AthleteInviteModal({classes, isOpen, closePopup, athleteName, athletesCount }) {
  let headTxt = !athleteName ? `${'sent'.pluralize('sending', athletesCount)} to ${athletesCount} ${'athlete'.pluralize('athletes', athletesCount)}` : `sent to ${athleteName}`
  let bodyTxt = athleteName || athletesCount == 1 ? "Your Athlete has been invited into your organization":
  "Your Athletes are currently being invited, this may take a few minutes depending on how many invites are being sent.";
  return (
    <ModalContainer
      heading={`Invite ${headTxt}`}
      open={isOpen}
      width={"630px"}
      onClose={closePopup}
      showFooter={false}
      customPadding={"30px"}
    >
      <div className={classes.athleteModalTxt}>
        {bodyTxt}
        </div>
      <ThemeButton
        theme={"small"}
        width={"214px"}
        height={"43px"}
        onClick={closePopup}
        text="Return to Athletes Screen"
        color={"#ffffff"}
        textColor={"#5B6062"}
      />
    </ModalContainer>
  )
}

function TeamUpdateModal({allSelected, isOpen, closePopup, teams, selectedUserIds, updateTeam}) {  
  let [currentTeamId, changeTeamId] = useState("");
  let headTxt = `Change team for ${allSelected? 'all users':('selected ' + 'user'.pluralize("users",(selectedUserIds||{}).size))}`;
  const teamMenuData = (teams||[]).map(item => {
      return {
          value: item.id,
          displayValue: item.name,
      }
  });
  return (
    <ModalContainer
      customPadding={"30px"}
      heading={headTxt}
      open={isOpen}
      width={"600px"}
      overflow= {"visible"}
      onClose={closePopup}
      useDefaultLeftBtn={true}
      useDefaultRightBtn={true}
      defaultLeftBtnProps={{onClick:closePopup}}
      defaultRightBtnProps={
      {disabled:!currentTeamId,
      onClick:()=>updateTeam(currentTeamId),
      text:"Save"  }}
    >

    <DropDownWithSearch 
      value={currentTeamId}
      placeholder="Select Team"
      onChange={(value) => { 
        changeTeamId(value)}}
      menuItemData={teamMenuData}
    />
  </ModalContainer>
  )
}


export default withStyles(styles)(connect(mapStateToProps)(AthleteRoster));