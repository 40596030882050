import React, { Component } from 'react'
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { PlusIcon } from '../../icons';

const styles = {
    dropDownContainer: {
        position: "relative",
        width: "100%",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px",
        textAlign: "left",
        background: "white"
    },
    dropDownHead: {
        height: "44px",
        padding: "11px",
        cursor: "pointer",
        "& svg":{
            color:"#ADB4B9"
        }
    }
}

class NewQuestionBlock extends Component {
    render() {
        let { classes, toggleQuestionModal } = this.props;
        return (
                <div className={classes.dropDownContainer}>
                    <div className={classnames(classes.dropDownHead, "d-flex justify-content-between")} onClick={toggleQuestionModal}>
                        <span>{"Add Question"}</span>
                        <PlusIcon />
                    </div>
                </div>
        );
    }
}

export default withStyles(styles)(NewQuestionBlock);