import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ShadowContainer from "../../../components/shadowContainer";
import SearchInput from "../../../components/searchInput";
import CheckBox from "../../../components/checkBox";
import { AccountCircle } from "@material-ui/icons";
import { colors } from "../../../components/v2/utils/theme-utils";
import Switch from "../../../components/switch";

const getTotalMembers = (membersByRole, selectedTeams) => {
  const total = Object.keys(selectedTeams).reduce((sum, item) => {
    sum += (membersByRole[item] || {}).total || 0;
    return sum;
  }, 0);
  return total;
};

const viewOptions = {
  createTag: "createTag",
  addTeam: "addTeam",
  addParticipants: "addParticipants",
};

const styles = (theme) => ({
  showContainer: {
    borderRadius: 5,
    padding: "15px 0px",
    minHeight: "72vh",
  },
  searchBox: {
    height: 48,
    width: "95%",
    borderRadius: 4,
  },
  teamContainer: {
    height: "71vh",
    overflow: "scroll",
  },
  teamItemContainer: {
    borderTop: `1px solid ${colors.GREY_20}`,
    padding: 15,
  },
  athleteImgBox: {
    minWidth: "36px",
    minHeight: "36px",
    maxWidth: "36px",
    maxHeight: "36px",
    borderRadius: "50%",
    border: "2px solid #DDE2E5",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    "& svg": {
      fill: "#A0A7AB",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
});

const AddTeams = (props) => {
  const {
    teams,
    classes,
    searchValue,
    handleSearch,
    handleChange,
    membersByRole,
    selectedTeams,
    handlePropsChange,
    handleSelectTeams,
    notifyParticipants,
    isAllMemberSelected,
    handleAddParticipants,
  } = props;
  return (
    <ShadowContainer className={classes.showContainer}>
      <div className={"row px-3 mb-2 d-flex align-items-center"}>
        <div className={"col-md-5 text-left"}>
          <p style={{ margin: 0 }}>Add teams</p>
        </div>
        <div
          className={
            "col-md-7 d-flex justify-content-between align-items-center"
          }
        >
          <p style={{ margin: 0, fontSize: 14 }}>Notify all participants</p>
          <Switch
            checked={notifyParticipants}
            handleChange={(value) =>
              handlePropsChange("notifyParticipants", value)
            }
          />
        </div>
      </div>
      <div className={"row justify-content-center px-3"}>
        <div className={"col-md-7"}>
          <SearchInput
            placeholder={"Filter teams by name"}
            customClass={classes.searchBox}
            searchTerm={searchValue}
            handleSearch={handleSearch}
          />
        </div>
        <div className={"col-md-5 justify-content-center align-items-center"}>
          {Object.keys(selectedTeams).length ? (
            <div className="d-flex justify-content-between align-items-center">
              <div
                style={{
                  fontSize: 13,
                }}
              >
                All members on selected teams (
                {getTotalMembers(membersByRole, selectedTeams)})
              </div>

              <div
                onClick={() =>
                  handleChange("isAllMemberSelected", !isAllMemberSelected)
                }
              >
                <CheckBox checked={isAllMemberSelected} />
              </div>
            </div>
          ) : null}
        </div>
        <div className={`col-md-12 mt-3 ${classes.teamContainer}`}>
          {(teams || [])
            .sort(function (a, b) {
              return a.customName
                .toLowerCase()
                .localeCompare(b.customName.toLowerCase());
            })
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${classes.teamItemContainer} d-flex justify-content-between align-items-center`}
                >
                  <div className="d-flex align-items-center">
                    <span className={classes.athleteImgBox}>
                      {item.logo ? <img src={item.logo} /> : <AccountCircle />}
                    </span>
                    <span className="m-0">{item.customName}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    {selectedTeams[item.id] ? (
                      <a
                        className="mr-2"
                        style={{
                          fontSize: 12,
                          color: colors.PRIMARY,
                          cursor: "pointer",
                        }}
                        onClick={() => handleAddParticipants(item)}
                      >
                        Select Members
                      </a>
                    ) : null}
                    <div onClick={() => handleSelectTeams(item)}>
                      <CheckBox
                        checked={selectedTeams[item.id] ? true : false}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </ShadowContainer>
  );
};
export default withStyles(styles)(AddTeams);
