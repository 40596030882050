import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchBar from '../../searchBars/SearchBar';
import DropDown from '../../searchBars/DropDown';
import Add from '../../icons/Add';
import DeleteIcon from '../../icons/DeleteIcon';

import NewWorkoutActivityModal from '../../../modals/newWorkoutActivityModal';

import IconButton from '../../buttons/IconButton';

import './index.scss';

function ActivityTopBar({
	//normal props
	selectOptions,
	selectValues,
	tagSearchChange,
	searchSubmit,
	tagSearchActive,
	toggleMultiDelete,
	multiDeleteActive,
	deleteList,
	deleteMultipleActivities,
	routeId,
	addAllToDeleteList,
	loadingMessage,
	cancelDelete,
	//amit module props
	currentTeamId,
	workoutModalOnChangeFunction,
	workoutModalSaveFunction,
	activity,
	removeTag,
	addTag,
	modalTags,
	resetActivity,
	handleFileSelect,
	handleVideoUrlChange,
	removeActivityVideo,
	handleThumbnailSelect,
	cancelUpload,
	displaySuccessOverlay,
	uploadingThumbnailFile,
	videoFileUploadProgress,
	thumbnailUploadProgress,
	overlayMessage,
	uploadingVideoFile,
	invalidVideoUrl,
}) {
	const [modalOpen, setModalOpen] = useState(false);

	function confirmDelete(){
		if(window.confirm("Are you sure you want to delete these activities?")){
			deleteMultipleActivities()
		}
	}

	return (
		<div className='activity-top-bar' key='deleteList'>
			<div className='searchbar-container row'>
				<div className='searchbar col-4'>
					<SearchBar
						searchSubmit={searchSubmit}
						routeId={routeId}
						loadingMessage={loadingMessage}
					/>
				</div>
				<div className='dropdown col-2' />
				<div className='dropdown col-4'>
					<DropDown
						selectOptions={selectOptions}
						selectValues={selectValues}
						tagSearchChange={tagSearchChange}
						tagSearchActive={tagSearchActive}
					/>
				</div>
				<div className='dropdown col-2' />
			</div>

			<div className='button-container'>
				{multiDeleteActive ? (
					<button className='cancel-button' onClick={()=>{toggleMultiDelete(); cancelDelete()}}>
						Cancel
					</button>
				) : (
					<IconButton
						className='button'
						chidren={
							<DeleteIcon
								style={{
									fontSize: 24,
									color: 'white',
									backgroundColor: 'rgb(19, 84, 249)',
									padding: '8px',
									height: '40px',
									width: '40px',
									borderRadius: '5px',
									float: 'left',
									display: 'inline',
									outline: 'none',
								}}
								onClick={toggleMultiDelete}
							/>
						}></IconButton>
				)}

				<IconButton
					className='button'
					chidren={
						<Add
							style={{
								fontSize: 24,
								color: 'white',
								backgroundColor: 'rgb(19, 84, 249)',
								padding: '7px',
								height: '40px',
								width: '40px',
								borderRadius: '5px',
								float: 'right',
								display: 'inline',
								outline: 'none',
							}}
							onClick={() => {
								setModalOpen(true);
							}}
						/>
					}></IconButton>
			</div>
			{multiDeleteActive ? (
				<div className='multi-delete-active'>
					<button
						className='delete-button select-all-button'
						onClick={addAllToDeleteList}>
						Select All
					</button>
					{deleteList.length === 0 ? (
						<p>Select some activities to delete...</p>
					) : (
						<button
							className='delete-button delete-multi-button'
							onClick={confirmDelete}>
							Delete {deleteList.length}{' '}
							{deleteList.length === 1 ? 'Activity' : 'Activities'}
						</button>
					)}
				</div>
			) : null}

			<NewWorkoutActivityModal
				activity={{ ...activity, isNewActivity: true }}
				tags={modalTags}
				handleChange={(name) => workoutModalOnChangeFunction(name)}
				save={(addingAnother) => {
					setModalOpen(false);
					workoutModalSaveFunction(addingAnother, () => setModalOpen(true));
				}}
				open={modalOpen}
				close={() => {
					setModalOpen(false);
					resetActivity();
				}}
				resetActivity={() => {
					resetActivity();
				}}
				addTag={(tag) => addTag(tag)}
				removeTag={(tag) => removeTag(tag)}
				currentTeam={currentTeamId}
				handleFileSelect={handleFileSelect}
				handleVideoUrlChange={handleVideoUrlChange}
				removeActivityVideo={removeActivityVideo}
				handleThumbnailSelect={handleThumbnailSelect}
				cancelUpload={cancelUpload}
				// displaySuccessOverlay={'displaySuccessOverlay'}
				uploadingThumbnailFile={uploadingThumbnailFile}
				videoFileUploadProgress={videoFileUploadProgress}
				thumbnailUploadProgress={thumbnailUploadProgress}
				// overlayMessage={'overlayMessage'}
				uploadingVideoFile={uploadingVideoFile}
				invalidVideoUrl={invalidVideoUrl}
			/>
		</div>
	);
}

export default ActivityTopBar;

ActivityTopBar.propTypes = { selectOptions: PropTypes.array };

ActivityTopBar.defaultProps = { selectOptions: ['one', 'two', 'three'] };
