import _, {merge} from 'lodash';
import moment from 'moment';

import { Actions as ChatActions } from '../actions/chat';

export default (state = { viewingGroup: {}}, action) => {
    const { type, payload } = action;

    switch(type){
      case ChatActions.SET_VIEWING_GROUP:{
              return {
          ...state,
          viewingGroup: payload.viewingGroup
              };
      }
  }

  return state;
}
