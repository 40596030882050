export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ops-api.gomaxone.com"
  },
  apiGatewayChat: {
    REGION: "us-east-1",
    URL: "https://ops-api.gomaxone.com/chat"
  },
  "apiGatewayActivities":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/activities" },
  "apiGatewayAuth":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/auth" },
  "apiGatewayAdmin":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/admin" },
  "apiGatewayBilling":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/billing" },
  "apiGatewayEvents":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/events" },
  "apiGatewayFeedItems":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/feedItems" },
  "apiGatewayGroups":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/groups" },
  "apiGatewayLeaderboards":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/leaderboards" },
  "apiGatewayMessages":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/messages" },
  "apiGatewayScheduledMessages":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/scheduledMeessages" },
  "apiGatewayOrganizations":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/organizations" },
  "apiGatewayProducts":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/products" },
  apiGatewayPrograms: {
    REGION: "us-east-1",
    URL: "https://ops-api.gomaxone.com/programs"
  },
  "apiGatewaySchedules":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/schedules" },
  "apiGatewayUsers":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/users" },
  "apiGatewayWorkoutPrograms":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/workoutPrograms" },
  "apiGatewayWorkouts":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/workouts" },
  "apiGatewayWorkoutSchedules":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/workoutSchedules" },
  "apiGatewayWorkoutSessions":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/workoutSessions" },
  "apiGatewayTags":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/tags" },
  "apiGatewayTaggings":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/taggings" },
  "apiGatewayWCS":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/wcs" },
  "apiGatewayTrueLacrosse":{REGION: "us-east-1", URL:"https://ops-api.gomaxone.com/truelacross" },
  apiGatewaySubscriptions: {
    REGION: "us-east-1",
    URL: "https://ops-api.gomaxone.com/subscriptions"
  },
  apiGatewaySubscriptionsTest: {
    REGION: "us-east-1",
    URL: "https://ops-api.gomaxone.com/test/subscriptions"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_l7HawZZD6",
    APP_CLIENT_ID: "6ncvtuekiid67v822vidc11ieo",
    IDENTITY_POOL_ID: "us-east-1:9c2be610-ca5a-4bab-a152-b950357779a3"
  }
}
