export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://stg-api.gomaxone.com"
  },
  apiGatewayActivities: {
    REGION: "us-east-1",
    URL: "https://stg-api.gomaxone.com/activities"
  },
  apiGatewayUsers: {
    REGION: "us-east-1",
    URL: "https://stg-api.gomaxone.com/users"
  },
  apiGatewayWorkoutPrograms: {
    REGION: "us-east-1",
    URL: "https://stg-api.gomaxone.com/workoutPrograms"
  },
  apiGatewayWorkouts: {
    REGION: "us-east-1",
    URL:"https://stg-api.gomaxone.com/workouts"
  },
  apiGatewayWorkoutSchedules: {
    REGION: "us-east-1",
    URL: "https://stg-api.gomaxone.com/workoutSchedules"
  },
  apiGatewayTaggings: {
    REGION: "us-east-1",
    URL:"https://stg-api.gomaxone.com/taggings"
  },
  cognito: {
    USER_POOL_ID: "us-east-1_Pt0yD4aJi",
    APP_CLIENT_ID: "591b30e2hrl944ss82rgbdn2uh",
    IDENTITY_POOL_ID: "us-east-1:0a19b97b-b8b1-4b79-bdf4-d9a15bb73737"
  },
  segment:{
    KEY: "8B9GGFwd6bUADVj4jTUp1XKfelYqQC6u"
  }
}
