import { getTenant } from "../utils/commonUtil";
import { store } from '../configure-store';
import MaxOne from './maxone';
import OneSoftball from './onesoftball';
import PGC from './pgc';
import VNN from './vnn';
import M1VNN from './m1vnn';
import WCS from './wcs';
import Spellman from './spellman';
import TrueLacrosse from './truelacrosse';
import GRDrive from './grdrive';
import AOC from './aoc';
import TPH from './tph';
import USAHockey from './usaHockey';
import CoachUp from './coachup';
import UPWARD from './upward';
import SHORELINE from './shoreline';
import ACADEMYPERFORMANCE from './academyPerformance';
import IPALLIANCE from './ipalliance';
import WEAREACADEMY from './weareacademy';
import ACESNATIONCONNECT from './acesnationconnect';
import VOLLEYBALL575 from './575volleyball';
import KICKID from './kickid';
import GVN from './gvn';
import { getThemeMode } from '../utils/commonUtil'

const TENANT = getTenant();
const getThemeByTenant = (tenant) => {
  const { tenant: tenantDataFromService } = store.getState();  
  tenant = (tenant || TENANT || "").toLowerCase();
  //Use data from service
  let tenantInfo = null
  switch (tenant) {
    case 'osb':
    case 'onesoftball':
      tenantInfo =  OneSoftball; break;
    case 'pgc':
      tenantInfo =  PGC; break;
    case 'vnn':
    case 'm1vnn':
    case 'demovnn':
    case 'stagevnn':
      tenantInfo =  M1VNN; break;
    case 'wcs':
      tenantInfo =  WCS; break;
    case 'spellman':
      tenantInfo =  Spellman; break;
    case 'truelacrosse':
      tenantInfo =  TrueLacrosse; break;
    case 'grdrive':
      tenantInfo =  GRDrive; break;
    case 'aoc':
      tenantInfo =  AOC; break;
    case 'tph':
      tenantInfo =  TPH; break;
    case 'upward':
      tenantInfo =  UPWARD; break;
    case 'kickid':
      tenantInfo =  KICKID; break;
    case 'shoreline':
      tenantInfo =  SHORELINE; break;
    case 'academyperformance':
      tenantInfo =  ACADEMYPERFORMANCE; break;
    case 'ipalliance':
      tenantInfo =  IPALLIANCE; break;
    case 'weareacademy':
      tenantInfo =  WEAREACADEMY; break;
    case 'acesnationconnect': 
      tenantInfo =  ACESNATIONCONNECT; break;
    case 'usahockey':
      tenantInfo =  USAHockey; break;
    case 'coachup':
      tenantInfo =  CoachUp; break;
    case 'gvn':
      tenantInfo =  GVN; break;
    case '575volleyball': 
      tenantInfo =  VOLLEYBALL575; break;
    case 'm1':
    case 'maxone':
      tenantInfo =  MaxOne; break;
  }
  if(!tenantInfo){
    tenantInfo =  MaxOne;
    if(!!tenantDataFromService && tenantDataFromService.id){
      let {
        androidAppUrl,   
        domain,
        id,
        iosAppUrl,
        logo,     
        name,     
        primaryColor,
        secondaryColor,
        loginLogo,
        appScheme,
        themeType
      } = tenantDataFromService;
      tenantInfo.product = {
        id,
        name,
        appUrl: appScheme,
        domain: domain,
        link: iosAppUrl,
        onAppleAppStore: iosAppUrl,
        onGooglePlay: androidAppUrl
      }
      tenantInfo.image = {
        logo,
        loginLogo: loginLogo || logo
      };
      tenantInfo.palette.type = themeType || "light";
      tenantInfo.palette.primary.main  = primaryColor;
      tenantInfo.palette.secondary.main = primaryColor;
      tenantInfo.paletteSecondary.type = themeType || "light";
      tenantInfo.paletteSecondary.primary.main = secondaryColor;
      tenantInfo.paletteSecondary.secondary.main = secondaryColor;              
      tenantInfo.palette.primary.isDarkMode = getThemeMode(tenantInfo.palette.primary.main);
      tenantInfo.palette.secondary.isDarkMode = getThemeMode(tenantInfo.palette.secondary.main); 
    }
  }
  return tenantInfo
};

export {
  MaxOne as MaxOne,
  OneSoftball,
  PGC,
  VNN,
  M1VNN,
  WCS,
  Spellman,
  TrueLacrosse,
  GRDrive,
  AOC,
  TPH,
  USAHockey,
  CoachUp,
  UPWARD,
  KICKID,
  GVN,
  getThemeByTenant,
};
