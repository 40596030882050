import _ from 'lodash';
import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { setUserContext, setAppContext } from '../../actions/session';
import { WhiteLabelUserClient, WhiteLabelAuthenticationClient  } from 'maxone-api-client';
import { getEnvironment, getRegion } from '../../utils/environmentUtils'

import ContextService from '../../services/context';
import {isMobile, isTablet} from 'react-device-detect';
import { Auth, API } from "aws-amplify";
import MetaTags from 'react-meta-tags';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import "./parent.css";

import { getThemeByTenant } from '../../themes';
import { auto } from 'async';
import {analyticsService, currentTimestamp} from "../../services/analyticsService";
const theme = getThemeByTenant();
const logo = theme.image.loginLogo;

const ENV = getEnvironment();
const userClient = new WhiteLabelUserClient({environment:ENV, region: getRegion()});
const authClient = new WhiteLabelAuthenticationClient({environment:ENV, region: getRegion()});
import PasswordInput from '../../components/passwordInput';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 500,
    padding: 50
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth:500
  },
  button: {
    width: 300,
    marginTop:10
  },
  root: {
    flexGrow: 1,
    margin:20
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginBottom:'-15px'
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom:'-35px'
  },
  textField: {
    flexGrow: 1,
    width:'100%'
  },
});

class ParentSignup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      tenant: "maxone",
      email: "",
      username: "",
      phoneNumber: "",
      message: "",
      password: "",
      nameLast: "",
      nameFirst: "",
      newUser: null,
      appUrl: 'm1://',
      productName: 'MaxOne',
      confirmationCode: "",
      showConfirmation:false,
      athleteCode: null,
      invalidEmail: false,
      invalidNameFirst: false,
      invalidNameLast: false,
      invalidUsername: false,
      invalidPassword: false,
      invalidPhoneNumber: false,
    };
  }

  componentDidMount(){

    this.setState({
      productName: theme.product.name,
      appUrl: theme.product.appUrl
    })

    // check here to see if this user came
    // from a text invite from an athlete
    const isQueryPresent = this.props.location.search !== '';
    var athleteCode = '';
    if(isQueryPresent && this.props.location.search.includes('?code=')) {
    // if they did then the query string is representative of the
    // athlete's id - we use this later to create a guardian link
      athleteCode = this.props.location.search.split('=')[1];
    }
    this.setState({
      athleteCode: athleteCode,
      tenant: theme.product.id ? theme.product.id : "maxone"
    })
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { tenant, email } = this.state;
    let firstName = this.state.nameFirst;
    if (!firstName) {
      this.setState({ invalidNameFirst: true, invalidNameLast: false, invalidUsername: false, invalidEmail: false, invalidPassword: false, invalidPhoneNumber: false,
        message:
          "First Name is required."
      });
      return;
    }

    let lastName = this.state.nameLast;
    if (!lastName) {
      this.setState({ invalidNameFirst: false, invalidNameLast: true, invalidUsername: false, invalidEmail: false, invalidPassword: false, invalidPhoneNumber: false,
        message:
          "Last Name is required."
      });
      return;
    }

    if(this.state.email.length === 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)){
      return this.setState({
        invalidNameFirst: false, invalidNameLast: false, invalidUsername: false, invalidEmail: true, invalidPassword: false, invalidPhoneNumber: false,
        message: "Oops, invalid email."
      });
    }

    if(this.state.phoneNumber.length === 0) {
      this.setState({
        invalidNameFirst: false,
        invalidNameLast: false,
        invalidUsername: false,
        invalidEmail: false,
        invalidPhoneNumber: true,
        message: "Phone Number is required." })
      return
    }

    if(this.state.phoneNumber && this.state.phoneNumber.length !== 0) {
      let strippedNumber = this.state.phoneNumber.replace(/[^0-9\.]+/g, '');
      if(strippedNumber.match(/[^$,.\d]/)) return this.setState({ invalidNameFirst: false, invalidNameLast: false, invalidUsername: false, invalidEmail: false, invalidPhoneNumber: true, message: "Phone Number contains invalid characters." })
      if(strippedNumber.length < 10) return this.setState({ invalidNameFirst: false, invalidNameLast: false, invalidUsername: false, invalidEmail: false, invalidPhoneNumber: true, message: "Oops... Phone Number must have ten digits." })
      if(strippedNumber.length > 10) return this.setState({ invalidNameFirst: false, invalidNameLast: false, invalidUsername: false, invalidEmail: false, invalidPhoneNumber: true, message: "Oops... Phone Number has too many digits." })
    }
    // await Auth.signIn('anonymous', 'Anonymous1').then(signIn => { })
    //     .catch(err => console.log("error", err));
    // // check Username not exist
    // var gotUser = await this.checkUsername(this.state.username);
    //
    // if (gotUser.length !== 0) {
    //   this.setState({
    //     invalidNameFirst: false,
    //     invalidNameLast: false,
    //     invalidUsername: true,
    //     invalidEmail: false,
    //     invalidPassword: false,
    //     invalidPhoneNumber: false,
    //     message:
    //       "A user with this Username already exists."
    //   });
    //   return;
    // }
    // await Auth.signOut().then(signOut => { })
    //     .catch(err => console.log("error", err));

    var username = await this.verifyUsername(this.state.username);
    if (!username) {
      this.setState({
        invalidNameFirst: false,
        invalidNameLast: false,
        invalidUsername: true,
        invalidEmail: false,
        invalidPassword: false,
        invalidPhoneNumber: false,
        message:
          "Username must be at least five characters long and contain no spaces."
      });
      return;
    }

    if(this.state.password.length < 8) {
      this.setState({ invalidPassword: true, invalidEmail: false, invalidPhoneNumber: false, invalidNameFirst: false, invalidNameLast: false, invalidUsername: false,
        message: 'Password needs to be at least eight characters long.' });
        return;
      }

    var password = await this.verifyPassword(this.state.password);
    if (!password) {
      this.setState({
        invalidPassword: true, invalidUsername: false, invalidPhoneNumber: false, invalidPhoneNumber: false,
        message:
          "Password must be at least 8 characters long, contain one number, one uppercase letter and one lowercase letter."
      });
      return;
    }

    let phoneNumber = '';
    if(this.state.phoneNumber) phoneNumber = "+1" + this.state.phoneNumber.replace(/-/g , '')

    try {
      console.log('tenant', this.state.tenant)
      // create user with white label api
      let { data } = await userClient.createUser(
        this.state.tenant,
        firstName,
        lastName,
        email,
        phoneNumber,
        "US",  // TODO: actually get country code
        password,
        username
      )


      console.log('newUser ', data)
      // const newUser = await Auth.signUp({
      //   username: this.state.username,
      //   password: this.state.password,
      //   'attributes': {
      //       "email": this.state.email,
      //       "phone_number": phoneNumber,
      //       "given_name": this.state.nameFirst,
      //       "family_name": this.state.nameLast
      //   }
      // });
      //
      this.setState({
        newUser: data
      });

      let user = this.state.newUser;
      var trackingParam = {
        id: user.id,
        first_name: user.nameFirst,
        last_name: user.nameLast,
        email: user.emailAddress,
        username: user.username,
        program: user.tenant
      };
      analyticsService.initWithUser(trackingParam)
      analyticsService.trackEvent("Signed Up", {user_type: "parent"});

    } catch (e) {
      console.log('error ', e)
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  createUser(user) {
    return API.post("users", "/users", {
      body: user
    });
  }

  createGuardianLink(parentId){
    const { athleteCode } = this.state;
    return API.post("users", `/guardian/${parentId}/athlete/${athleteCode}`);
  }

  setSessionInfo = async (username) => {
    const contextService = new ContextService();
    const { userContext, appContext } = await contextService.buildUserContext(username);

    this.props.setUserContext(userContext);
    this.props.setAppContext(appContext);
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();
    const { athleteCode, appUrl, productName, tenant, email, username, confirmationCode } = this.state
    this.setState({ isLoading: true });

    try {
      // await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);

      // auth and submit code
      let { data } = await authClient.confirmSignupByCode(tenant, email, confirmationCode, username)
      console.log('data ', data)

      var result = await Auth.signIn(this.state.username, this.state.password);
    } catch (e) {
      this.setState({ isLoading: false });
    }
    try {
      var user = this.state.newUser;
      // var user = {
      //   nameFirst: this.state.nameFirst,
      //   nameLast: this.state.nameLast,
      //   username: this.state.username,
      //   phoneNumber: "+1" + this.state.phoneNumber,   /// TODO needs to use actual country code
      //   email: this.state.email
      // }
      // var request = await this.createUser(user)

      // after we signup the parent, if we have an athlete
      // code, we should use that to build a new link then
      // we can tell them to download the mobile app
      if(athleteCode){
        var newGuardianLink = await this.createGuardianLink(user.id)

        if(isMobile || isTablet) {
          if(window.confirm(`Open the ${productName} app`)) {
            window.location = appUrl;
            return null;
          }
        }
      }

      await this.setSessionInfo(user.username);
      this.props.userHasAuthenticated(true)
    }
    catch(e) {
      console.log('e ', e)
      this.setState({ isLoading: false });
    }
  }

  checkUsername = async username => {
    return API.get("users", `/users/username/${username}`);
  };

  cleansePhone = async phoneNumber => {
    console.log("phoneNumber", phoneNumber);
    if (!phoneNumber) {
      return false;
    }
    var phoneString = phoneNumber.toString();
    // return correct phone, fail if invalid phone.
    if (phoneString.length !== 10) {
      return false;
    }
    return phoneNumber;
  };

  verifyEmail = async email => {
    if (!email) {
      return false;
    }
    // return correct email, fail if invalid email.
    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    if (re.test(email)) {
      return email;
    } else {
      return false;
    }
  };

  verifyPassword = async password => {
    // Password must be at least 8 characters long, contain a number, and one uppercase and one lowercase letter.
    console.log("password ", password);
    if (!password) {
      return false;
    }
    if (password.length < 8) {
      alert("Your password needs a minimum of 8 characters.");
    } else if (password.search(/[a-z]/) < 0) {
      alert("Your password needs a lower case letter.");
    } else if (password.search(/[A-Z]/) < 0) {
      alert("Your password needs an upper case letter.");
    } else if (password.search(/[0-9]/) < 0) {
      alert("Your password needs a number.");
    } else {
      return password;
    }
  };

  verifyUsername = async username => {
    if (!username) {
      return false;
    }
    // make sure there are no spaces in the username and that it is at least 5 characters long
    var hasWhiteSpace = this.hasWhiteSpace(username);
    if (hasWhiteSpace) {
      return false;
    }
    if (username.length < 5) {
      return false;
    }

    return username;
  };

  hasWhiteSpace(s) {
    return s.indexOf(" ") >= 0;
  }

  renderConfirmationForm() {
    const { classes } = this.props;

    return (
      <div style={{maxWidth:500, marginLeft:'auto', marginRight:'auto'}}>

        <MetaTags>
          <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </MetaTags>

        <form onSubmit={this.handleConfirmationSubmit} style={{margin:0, padding:15}}>
          <Grid container spacing={8} direction="row" justify="center" alignItems="flex-start">
            <Grid item xs={12} style={{textAlign:'center'}}>
              <img className={classes.image} src={logo} style={{maxWidth:"300px", marginTop:20, marginBottom:10}}/>
              <h1 className={classes.header}>PARENT REGISTRATION</h1>
              <h4 className={classes.subHeader}>{`Enter the signup code we just "texted" you.`}</h4>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="username1"
                style={{maxWidth:500}}
                label="Username"
                className={classes.textField}
                value={this.state.username}
                onChange={this.handleChange('username')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
                <PasswordInput
                    id="password"
                    style={{maxWidth:500}}
                    label="Password"
                    type="password"
                    autoComplete="password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="confirmationCode"
                style={{maxWidth:500}}
                label="Signup Code"
                className={classes.textField}
                value={this.state.confirmationCode}
                onChange={this.handleChange('confirmationCode')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{textAlign:'center'}}>
              <Button variant="contained" color="primary" type="submit" className={classes.button} style={{marginBottom:15}}>
                Submit
              </Button>
            </Grid>

            <Grid item xs={12} style={{marginTop:15}}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{marginTop:15}}>
              <Typography variant="subheading">
                 Looking to <a href="/login">login</a> instead?
               </Typography>
            </Grid>
          </Grid>
        </form>


      </div>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const { invalidNameFirst, invalidNameLast, invalidEmail, invalidPassword, invalidPhoneNumber, invalidUsername, isLoading} = this.state;

    return (
      <div style={{maxWidth:500, marginLeft:'auto', marginRight:'auto'}}>

        <form onSubmit={this.handleSubmit} style={{margin:0, padding:15}}>
          <Grid container spacing={8} direction="row" justify="center" alignItems="flex-start">

            <Grid item xs={12} style={{textAlign:'center'}}>
              <img className={classes.image} src={logo} style={{maxWidth:"300px", marginTop:20, marginBottom:10}}/>
              <h1 className={classes.header}>PARENT REGISTRATION</h1>
              <h4 className={classes.subHeader}>{`Cool, you’re signing up as a parent. Just tell us a bit about yourself.`}</h4>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField margin="normal"
                id="nameFirst"
                label="First Name"
                error={invalidNameFirst}
                helperText={invalidNameFirst ? this.state.message : ''}
                className={classes.textField}
                value={this.state.nameFirst}
                onChange={this.handleChange('nameFirst')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField margin="normal"
                id="nameLast"
                label="Last Name"
                error={invalidNameLast}
                helperText={invalidNameLast ? this.state.message : ''}
                className={classes.textField}
                value={this.state.nameLast}
                onChange={this.handleChange('nameLast')}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField margin="normal" type="email"
                id="email"
                label="Email"
                error={invalidEmail}
                value={this.state.email}
                className={classes.textField}
                helperText={invalidEmail ? this.state.message : ''}
                onChange={this.handleChange('email')}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField margin="normal"
                id="phoneNumber"
                label="Phone Number"
                error={invalidPhoneNumber}
                className={classes.textField}
                value={this.state.phoneNumber}
                helperText={invalidPhoneNumber ? this.state.message : ''}
                onChange={this.handleChange("phoneNumber")}
              />
              <h4 style={{fontWeight:100, fontSize:10, textAlign:'left', marginBottom:-10, marginTop:-5}}>{`* Coaches may send event or program information to this phone number.`}</h4>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField margin="normal"
                id="username"
                label="Username"
                error={invalidUsername}
                helperText={invalidUsername ? this.state.message : ''}
                className={classes.textField}
                value={this.state.username}
                onChange={this.handleChange('username')}
              />
              <h4 style={{fontWeight:100, fontSize:10, textAlign:'left', marginBottom:-10, marginTop:-5}}>{`* remember your username to login`}</h4>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField margin="normal" type="password"
                id="password"
                label="Password"
                error={invalidPassword}
                className={classes.textField}
                value={this.state.password}
                helperText={invalidPassword ? this.state.message : ''}
                onChange={this.handleChange('password')}
              />
              <h4 style={{fontWeight:100, fontSize:10, textAlign:'left', marginBottom:-10, marginTop:-5}}>{`* remember your password to login`}</h4>
            </Grid>
            <Grid item xs={12} sm={12}>
              <h4 style={{fontWeight:100, fontSize:14, textAlign:'center'}}>
                By signing up for {theme.product.name} you agree to our <a href="https://www.gomaxone.com/terms-of-service/">Terms of Service</a>.
              </h4>
            </Grid>
            <Grid item xs={12} sm={12} style={{textAlign:'center'}}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isLoading}
                className={classes.button}
              >
                Sign Up
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} style={{textAlign:'center'}}>
              <Button style={{marginTop:15, marginBottom:15}} className={classes.button} variant="contained" onClick={() => this.setState({showConfirmation: true})}>
                Already have a code?
              </Button>
            </Grid>

            <Grid item xs={12} style={{marginTop:15}}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{marginTop:15}}>
              <Typography variant="subheading">
                 Looking to <a href="/login">login</a> instead?
               </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="ParentSignup">
          {this.state.newUser === null && !this.state.showConfirmation
            ? this.renderForm()
            : this.renderConfirmationForm()}
      </div>
    );
  }
}

ParentSignup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ParentSignup));
