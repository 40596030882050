import { teamClient, userClient } from "../clients";
import { setSelectedTeams } from "./nylas-calendar-actions";
import { setCurrentOrganizationById } from "./organization-actions";

export const TEAMS_GET_BY_ID_LOADING = "TEAMS_GET_BY_ID_LOADING";
export const TEAMS_GET_BY_ID_SUCCESS = "TEAMS_GET_BY_ID_SUCCESS";
export const TEAMS_GET_BY_ID_ERROR = "TEAMS_GET_BY_ID_ERROR";

export const TEAMS_SET_CURRENT_BY_ID_LOADING =
  "TEAMS_SET_CURRENT_BY_ID_LOADING";
export const TEAMS_SET_CURRENT_BY_ID_SUCCESS =
  "TEAMS_SET_CURRENT_BY_ID_SUCCESS";
export const TEAMS_SET_CURRENT_BY_ID_ERROR = "TEAMS_SET_CURRENT_BY_ID_ERROR";

export const GET_ATHLETES_BY_TEAM_ID_LOADING =
  "GET_ATHLETES_BY_TEAM_ID_LOADING";
export const GET_ATHLETES_BY_TEAM_ID_SUCCESS =
  "GET_ATHLETES_BY_TEAM_ID_SUCCESS";
export const GET_ATHLETES_BY_TEAM_ID_ERROR = "GET_ATHLETES_BY_TEAM_ID_ERROR";

export const GET_MEMBERS_BY_TEAM_ID_LOADING = "GET_MEMBERS_BY_TEAM_ID_LOADING";
export const GET_MEMBERS_BY_TEAM_ID_SUCCESS = "GET_MEMBERS_BY_TEAM_ID_SUCCESS";
export const GET_MEMBERS_BY_TEAM_ID_ERROR = "GET_MEMBERS_BY_TEAM_ID_ERROR";

export const setCurrentTeamById = (id) => async (dispatch) => {
  dispatch({ type: TEAMS_SET_CURRENT_BY_ID_LOADING });
  try {
    const { data: team } = await teamClient.getTeamById(id);
    dispatch({ type: TEAMS_GET_BY_ID_SUCCESS, data: { team } });
    dispatch({ type: TEAMS_SET_CURRENT_BY_ID_SUCCESS, data: { id } });
    dispatch(setSelectedTeams([id]));
    return dispatch(setCurrentOrganizationById(team.organizationId));
  } catch (e) {
    console.log(`Error setting current team by ID ${id}`, e);
    dispatch({ type: TEAMS_SET_CURRENT_BY_ID_ERROR });
  }
};

export const getAthletesByTeamId =
  (teamId, term = "*", offset = 0, limit = 10) =>
  async (dispatch) => {
    dispatch({ type: GET_ATHLETES_BY_TEAM_ID_LOADING });
    try {
      const { data } = await teamClient.searchMembersByTeamId(teamId, {
        q: term,
        "teams.team-role.keyword": `${teamId}-ATHLETE`,
        offset,
        limit,
      });
      dispatch({
        type: GET_ATHLETES_BY_TEAM_ID_SUCCESS,
        data: { ...data, offset },
      });
    } catch (e) {
      console.warn(`Error getting athletes by Team ID ${teamId}`, e);
      dispatch({ type: GET_ATHLETES_BY_TEAM_ID_ERROR });
    }
  };

export const searchMembersByTeamId =
  (teamId, term = "*", offset = 0, limit = 50) =>
  async (dispatch) => {
    dispatch({ type: GET_MEMBERS_BY_TEAM_ID_LOADING });
    try {
      const { data } = await teamClient.searchMembersByTeamId(teamId, {
        q: term,
        offset,
        limit,
      });
      dispatch({
        type: GET_MEMBERS_BY_TEAM_ID_SUCCESS,
        data: { teamId, data },
      });
    } catch (e) {
      console.warn(`Error getting members by Team ID ${teamId}`, e);
      dispatch({ type: GET_MEMBERS_BY_TEAM_ID_ERROR });
    }
  };
