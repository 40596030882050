import React, { Component } from 'react';
import { connect } from 'react-redux';
import { emailClient } from '../../../clients';
import { WrappedPage, LoadingPage } from '../../../components/v2/containers';
import DirectorIntegrationsView from './components/DirectorIntegrationsView';

import './index.scss';

class Integrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            events: [],
            availableEventTypes: [],
            allEventTypes: []
        }
    }

    componentDidMount() {
        this.loadEmailNotificationSettings();
    }

    /**
     * Populate the current email settings from the API. In addition to returning currently set values, the
     * response contains all possible event types, so store those off as option parameters in the UI.
     */
    loadEmailNotificationSettings = async () => {
        const { organizations: { currentOrganizationId } } = this.props;
        this.setState({ loading: true, error: false });
        try {
            const { data: emailNotificationSettings } = await emailClient.getNotificationSettingsByOrganizationId(currentOrganizationId);
            const activeEvents = emailNotificationSettings.results.filter(({ active }) => active);
            const allEventTypes = emailNotificationSettings.results.map(({ eventType }) => eventType);
            const availableEventTypes = allEventTypes.filter(type => !activeEvents.find(({ eventType }) => type === eventType));
            this.setState({ events: activeEvents, allEventTypes, availableEventTypes });
        } catch (e) {
            console.log(`Error getting email notification settings for organization ID ${currentOrganizationId}`, e);
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    /**
     * When an event is updated, set the state of the event to loading and trigger a short timeout throttle
     * before sending the save request to the server to prevent jumpy UI and unnecessary API requests.
     */
    onEventChange = (event, index) => {
        const { events, allEventTypes } = this.state;
        if (event.emailAddress) {
            event.emailAddress = event.emailAddress.toLowerCase().replace(/\s/g, '');
        }

        // No need to trigger a change event if nothing was actually changed
        if (event.eventType === events[index].eventType && event.emailAddress === events[index].emailAddress) {
            return;
        }
        events[index] = { ...events[index], ...event, saved: !(event.emailAddress && event.eventType) };
        const availableEventTypes = allEventTypes.filter(type => !events.find(({ eventType }) => type === eventType));
        this.setState({ events, availableEventTypes }, () => {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }
            this.saveTimeout = setTimeout(this.triggerSave, 500);
        });
    };

    /**
     * Loop through each of the events that are triggered for save in the state and send an API request
     * to persist the changes. When all updates have been made, refresh the current list so any generated
     * IDs are populated in case of a delete request in the same session.
     */
    triggerSave = async () => {
        const { organizations: { currentOrganizationId } } = this.props;
        let { events } = this.state;
        const eventsToSave = events.filter(({ saved }) => saved === false);
        await Promise.all(
            eventsToSave.map(({ eventType, emailAddress }) => emailClient.addNotificationSetting(eventType, currentOrganizationId, emailAddress))
        );
        const { data: { results } } = await emailClient.getNotificationSettingsByOrganizationId(currentOrganizationId);
        events = events.map((event) => {
            return {
                ...event,
                ...results.find(({ eventType: type }) => type === event.eventType),
                saved: true,
                active: true
            }
        });
        this.setState({ events });
    };

    /**
     * When a new event addition is requested, just add an empty item to the list.
     */
    onAddEventClick = () => {
        const { events } = this.state;
        events.push({ });
        this.setState({ events });
    };

    /**
     * When a delete is triggered on the specified event index, check to see if there's an ID present on the
     * event. If so, pop a confirmation modal and send a request to the server to delete before updating the UI.
     * If not, just remove from the list.
     */
    onDeleteEventClick = async (index) => {
        const { events, availableEventTypes } = this.state;
        const { eventType, id } = events[index];
        if (id) {
            // Confirm here?

            events[index].loading = true;
            this.setState({ events });
            await emailClient.deleteNotificationSettingById(id);
        }
        events.splice(index, 1);
        availableEventTypes.push(eventType);
        this.setState({ events, availableEventTypes });
    };

    /**
     * When testing the event, ensure the configurations have been saved beforehand. If not, delay the request
     * for a little bit to attempt to give enough time to complete before retrying. If it's still not saved by
     * the second attempt, let the chips fall where they may...
     */
    onTestEventClick = async (index, retry = false) => {
        const { events } = this.state;
        const { organizations: { currentOrganizationId } } = this.props;
        const { eventType, saved } = events[index];
        events[index].loading = true;
        this.setState({ events });

        // If the event hasn't finished saving yet, give it a sec to attempt to complete and retry
        // the test event
        if (saved === false) {
            if (!retry) {
                console.log(`Event ${eventType} has not finished saving. Waiting a short time before retrying the test event...`);
                await new Promise(resolve => setTimeout(resolve, 2500));
                return this.onTestEventClick(index, true);
            }
            console.log(`Event ${eventType} has not been saved, the test event may not work as expected.`);
        }
        await emailClient.sendMockEvent(eventType, currentOrganizationId);
        events[index].loading = false;
        this.setState({ events });
    };

    render() {
        const {
            loading,
            error,
            events,
            availableEventTypes,
            allEventTypes
        } = this.state;
        return (
            <WrappedPage title={'Integrations'}>
                <LoadingPage loading={loading} error={error}>
                    <DirectorIntegrationsView
                        events={events}
                        availableEventTypes={availableEventTypes}
                        allEventTypes={allEventTypes}
                        onEventChange={this.onEventChange}
                        onAddEventClick={this.onAddEventClick}
                        onDeleteEventClick={this.onDeleteEventClick}
                        onTestEventClick={this.onTestEventClick}
                    />
                </LoadingPage>
            </WrappedPage>
        )
    }
}

const mapStateToProps = ({ organizations }) => ({ organizations });

export default connect(mapStateToProps)(Integrations);
