import React from "react";
import ModalContainer from "../../../components/modalContainer";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PrimaryButton } from "../../../components/v2/buttons";
import { colors } from "../../../components/v2/utils/theme-utils";
import Calendar from "../icons/Calendar";

const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "center",
    "&>svg": {
      fontSize: "42px",
    }
  },

  okButtonWrapper: {
    "& > div:first-child": {
      width: "100%",
    }
  },
  okButton: {
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    border: `2px solid ${colors.PRIMARY} !important`,
    color: `${colors.PRIMARY} !important`,
    "&:hover": {
      backgroundColor: "transparent",
      border: `2px solid ${colors.PRIMARY} !important`,
    },
    "&:focus": {
      backgroundColor: "transparent",
      border: `2px solid ${colors.PRIMARY} !important`,
    }
  }
});

const RSVPPopup = ({
  handleClose,
  sendRSVP,
  classes,
}) => {
  return (
    <ModalContainer
      open={true}
      showFooter={false}
      showCloseIcon={false}
      onClose={handleClose}
      width={400}
      customPadding={25}
      backgroundColor={"#fff"}
      heading={""}
      customButton={""}

    >
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div
            className={`${classes.header} d-flex justify-content-center`}
          >
            <Calendar />
          </div>
        </Grid>
        <Grid item xs={12} className={"d-flex justify-content-center"}>
          <p style={{ textAlign: "center" }}>
            Are you going to attend the event?
          </p>
        </Grid>
        <Grid item xs={6} className={`${classes.okButtonWrapper} d-flex justify-content-center`}>
          <PrimaryButton
            onClick={() => sendRSVP("No")}
            style={{ height: 52, width: "100%" }}
            className={classes.okButton}
          >
            NOT GOING
          </PrimaryButton>
        </Grid>
        <Grid item xs={6} className={`${classes.okButtonWrapper} d-flex justify-content-center`}>
          <PrimaryButton
            onClick={() => sendRSVP("Yes")}
            style={{ height: 52, width: "100%" }}
            className={classes.okButton}
          >
            GOING
          </PrimaryButton>
        </Grid>
        <Grid item xs={12} className={`${classes.okButtonWrapper} d-flex justify-content-center mt-2`}>
          <p style={{ color: colors.PRIMARY, cursor: "pointer" }} onClick={handleClose}>Skip</p>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

export default withStyles(styles)(RSVPPopup);
