import { combineReducers } from 'redux';

import {
    ORGANIZATIONS_GET_BY_ID_LOADING,
    ORGANIZATIONS_GET_BY_ID_SUCCESS,
    ORGANIZATIONS_GET_BY_ID_ERROR,
    ORGANIZATIONS_SET_CURRENT_BY_ID_LOADING,
    ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS,
    ORGANIZATIONS_SET_CURRENT_BY_ID_ERROR
} from '../actions/organization-actions';

const loading = (state = false, action) => {
    switch (action.type) {
        case ORGANIZATIONS_GET_BY_ID_LOADING:
        case ORGANIZATIONS_SET_CURRENT_BY_ID_LOADING:
            return true;
        case ORGANIZATIONS_GET_BY_ID_SUCCESS:
        case ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS:
        case ORGANIZATIONS_GET_BY_ID_ERROR:
        case ORGANIZATIONS_SET_CURRENT_BY_ID_ERROR:
            return false;
    }
    return state;
};

const error = (state = false, action) => {
    switch (action.type) {
        case ORGANIZATIONS_GET_BY_ID_ERROR:
        case ORGANIZATIONS_SET_CURRENT_BY_ID_ERROR:
            return true;
        case ORGANIZATIONS_GET_BY_ID_LOADING:
        case ORGANIZATIONS_SET_CURRENT_BY_ID_LOADING:
        case ORGANIZATIONS_GET_BY_ID_SUCCESS:
        case ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS:
            return false;
    }
    return state;
};

const byId = (state = {}, action) => {
    if (action.type === ORGANIZATIONS_GET_BY_ID_SUCCESS) {
        const { organization } = action.data;
        return [ organization ].reduce((list, item) => {
            list[item.id] = item;
            return list;
        }, state);
    }
    return state;
};

const currentOrganizationId = (state = null, action) => {
    if (action.type === ORGANIZATIONS_SET_CURRENT_BY_ID_SUCCESS) {
        const { id } = action.data;
        return id;
    }
    return state;
};

export default combineReducers({
    loading,
    error,
    byId,
    currentOrganizationId
});
