import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const HealthKitIcon = (props) => (
  <SvgIcon width="18" height="22" viewBox="0 0 18 22" {...props}>
    <path
      d="M16.6605 3.39353C12.0486 3.39353 9.14942 0.913327 9.11976 0.891046L8.875 0.679688L8.63024 0.891046C8.60058 0.913327 5.70144 3.39353 1.08949 3.39353H0.71875V11.179C0.71875 11.2643 0.811435 19.6504 8.78973 21.5486L8.875 21.5708L8.96027 21.5486C16.9386 19.6504 17.0312 11.2643 17.0312 11.179V3.39353H16.6605ZM13.88 12.8474H10.5433V16.184H7.20668V12.8474H3.87003V9.51072H7.20668V6.17407H10.5433V9.51072H13.88V12.8474Z"
      fill="#222127"
    />
  </SvgIcon>
);

export default HealthKitIcon;
