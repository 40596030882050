import React, { Component } from "react";
import PropTypes from 'prop-types';
import './styles.css'
import { withStyles } from '@material-ui/core/styles';
import SignupContent from './signupContent'
import { getTenant } from "../../utils/environmentUtils"
import { API } from "aws-amplify";
import { userClient, authClient, organizationClient } from '../../clients';
import { ReactComponent as MaxoneFullLogo } from '../../assets/images/maxone-logo-horizontal.svg';
import { connect } from 'react-redux'
import ContextService from '../../services/context';
import { setUserContext, setAppContext } from '../../actions/session';
import { Auth } from "aws-amplify";
import { analyticsService } from "../../services/analyticsService";

const tenant = getTenant();

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  menu: {
    width: 500,
    padding: 50
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: 300,
    marginTop: 10
  },
  root: {
    flexGrow: 1,
  },
  header: {
    fontWeight: 100,
    fontSize: 45,
    marginBottom: '-15px'
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom: '-35px'
  },
});


class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      currentTab: 1,
      business: {},
      signupData: {
        password: null,
        username: null,
        nameLast: (props.userInfo||{}).lastName||"",
        nameFirst: (props.userInfo||{}).firstName||"",
        phoneNumber: (props.userInfo||{}).phoneNumber||"",
        countryCode: null,
        email: null,
        orgSize:null
      },
      isLoading: false,
      existingUsername: false,
      passwordError: false,
      verificationCode: null,
      isPhoneNumberValid: false,
      isVerificationCodeValid:true
    }
  }

  componentDidMount() {
  }

  handleSignUpDataChange = (property, value) => {
    this.setState(prevState => ({
      signupData: { ...prevState.signupData, [property]: value },
      existingUsername: false,
      isVerificationCodeValid:true
    }))
  }

  handleTabChange = tabId => {
    this.setState({
      currentTab: tabId,
      isLoading: false
    })
  }

  handleAccountCreation = async () => {
    const {
      signupData: {
        password,
        username,
        nameLast,
        nameFirst,
        phoneNumber,
        countryCode,
        email
      }
    } = this.state;
    try {
      this.setState({ isLoading: true })
      const { data } = await userClient.createUser(tenant, nameFirst, nameLast, email, phoneNumber, countryCode, password, username);
      this.setState({userId: data.id});
      this.handleTabChange(2);
    }
    catch(e) {
      if(!!e && e.response && e.response.status  == 409){
        console.log("entered here")
        this.setState({existingUsername:true});
      }
      console.log("exception")
      console.log(e)
    }
    finally{
      this.setState({isLoading: false});
    }
  }

  handleConfirmUser = async () => {
    let { selectedOrgName } = this.props;
    const {
      signupData: {
        email,
        verificationCode,
        username,
        password,
        nameLast,
        nameFirst,     
      },
      userId
    } = this.state;
    try {
      if(!selectedOrgName){
        selectedOrgName = `sportsengine - ${nameFirst||""} ${nameLast||""}`
      }

      this.setState({ isLoading: true });
      let { data } = await authClient.confirmSignupByCode(tenant, email, verificationCode, username);
      try{
        const { data:orgInfo } = await organizationClient.createOrganization(tenant, selectedOrgName, "School", userId);
        await Auth.signIn(username, password);
        //TODO: REMOVE IT... BAD WAY TEMPORARY
        const programJSON = {
          name: selectedOrgName,
          sport: "BasketBall",
          organizationId: orgInfo.id,
          customName:selectedOrgName
        }
        let program = await this.createProgram(programJSON);
        const roleInfo = await this.createRole(program.id, userId);
        program.organization = orgInfo;
        await this.loginUser(username, program);
      }
      catch(e){
        // alert("Something went wrong. Please try again.")
        console.log("exception")
        console.log(e)
      }
    }
    catch (e) {
      this.setState({isVerificationCodeValid: false});
      console.log(e);
    }
    finally{
      this.setState({isLoading:false});
    }
  }

  //TODO: REMOVE IT... BAD WAY TEMPORARY
  async createProgram (program) {
    var data = await API.post("programs", "/programs", {
        body: program
    });
    return data
}

//TODO: REMOVE IT... BAD WAY TEMPORARY
  async createRole(parentId, userId) {
    const programRoleJSON = {
      parentId,
      userId,
      role: "coach"
  }
    var data = await API.post("users", `/users/${userId}/roles`, {
        body: programRoleJSON
    });
    return data;
  }

  handleResendVerificationCode = async () => {
    const { signupData: {
      username
    } } = this.state;
    await authClient.resendConfirmationCode(tenant, username).then((data) => { console.log(data) }).catch(e => { console.log('ERROR ==>', e); });
  }

  loginUser = async (username, program) => {
    await this.setSessionInfo(username, program)
    this.props.userHasAuthenticated(true);
    this.setState({ isLoading: false });
    this.props.history.push('/')
  }

  setSessionInfo = async (username, program) => {
    let { signupData } = this.state; 
    const contextService = new ContextService();
    const { userContext, appContext } = await contextService.buildUserContext(username, null);
    this.props.setUserContext(userContext);
    this.props.setAppContext(appContext);
    const { user, roles } = userContext
    if (user && roles && roles[0]) {
      let userTraits = await analyticsService.getUserTraits(user, roles, program, appContext, userContext);
      analyticsService.initWithUser(userTraits);
      analyticsService.trackEvent("SE SIGN UP",{
        orgSize:signupData.orgSize
      })
  }
  }

  render() {
    const { classes } = this.props;
    const {
      currentTab,
      isLoading,
      passwordError,
      existingUsername,
      isPhoneNumberValid,
      signupData,
      isVerificationCodeValid
    } = this.state
    return (
      <div className="signup">
        <div className="maxoneLogo d-flex justify-content-center">
          <MaxoneFullLogo />
        </div>
        <SignupContent
          signupData={signupData}
          currentTab={currentTab}
          isLoading={isLoading}
          passwordError={passwordError}
          existingUsername={existingUsername}
          handleConfirmUser={this.handleConfirmUser}
          handleCreateAccount={this.handleAccountCreation}
          handleTabChange={tabId => this.handleTabChange(tabId)}
          handleResendVerificationCode={this.handleResendVerificationCode}
          handleChange={(property, value) => this.handleSignUpDataChange(property, value)}
          isPhoneNumberValid={isPhoneNumberValid}
          isVerificationCodeValid={isVerificationCodeValid}
        />
      </div>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

function mapStateToProps(state) {
  return {
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Signup));
