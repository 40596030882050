import _ from "lodash";
import "./copyActivities.css";
import shortid from 'shortid';
import { API } from "aws-amplify";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from "react";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

import {
    Grid, 
    List,
    Icon,
    Button,
    Select,
    Snackbar,
    ListItem,
    Checkbox,
    TextField,
    IconButton,
    FormControl,
    InputAdornment
} from '@material-ui/core';
import PageWrapper from '../nav/pageWrapper';
const styles = theme => ({
    plusButton: {
        color: 'white',
        backgroundColor: "#1354F9"
    }
});

class AdminCopyActivities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            toTeam: null,
            fromTeam: null,
            toTeamId: '',
            fromTeamId: '',
            foundTeams: [],
            teamNameSearch: '',
            snackMessage: null,
            includeCoach: false,
            includeDrill: false,
            includeWorkouts: false,
            includeExercise: false,
            includeEducation: false,
        };
    }

    resetState(){
        this.setState({
            open: false,
            toTeam: null,
            fromTeam: null,
            toTeamId: '',
            fromTeamId: '',
            foundTeams: [],
            teamNameSearch: '',
            snackMessage: null,
            includeCoach: false,
            includeDrill: false,
            includeWorkouts: false,
            includeExercise: false,
            includeEducation: false,
        })
    }

    async componentDidMount(){
    }

    getActivities(category, teamId){
        return API.get("activities", `/programs/${teamId}/activities/${category}`);
    }
    
    getActivity(activityId){
        return API.get("activities", `/activities/${activityId}`,{
            queryStringParameters: {
                includeTaggingData:true
          }});
    }

    getWorkouts(teamId){
        return API.get("workouts", `/programs/${teamId}/workouts`);
    }

    getTeamByName(teamName){
        return API.get("programs", `/programs/name/${teamName}`);
    }

    getTeam(teamId){
        return API.get("programs", `/programs/${teamId}`);
    }

    createActivity(activity){
        return API.post("activities", `/activities`, {
            body: activity
        });
    }

    createWorkout(workout){
        return API.post("workouts", `/workouts`, {
            body: workout
        });
    }

    saveTagging = tagging => {
        return API.post("taggings", `/taggings`, {
            body: tagging
        });
    }

    hideSnackBar(){
        setTimeout(() => this.setState({open:false}), 2000);
    }

    findTeam = name => async event => {
        const { toTeamId, fromTeamId, teamNameSearch } = this.state
        if(name == 'teamNameSearch'){
            try{
                this.setState({
                    open: true,
                    snackMessage: 'looking for teams that match your query, hold your horses'
                })
                const teams = await this.getTeamByName(teamNameSearch)
                this.setState({
                    foundTeams: teams
                })
            }
            catch(e){
                this.setState({
                    open: true,
                    snackMessage: 'we could not find any teams with that are similar to that name'
                })
            }
            this.hideSnackBar()
        }
        else{
            let teamId = name == 'toTeam' ? toTeamId : fromTeamId
            try{
                const team = await this.getTeam(teamId)
                if(!team){
                    this.setState({
                        open: true,
                        snackMessage: 'we could not find a team that matches that id'
                    })
                    this.hideSnackBar();
                }
                else{
                    this.setState({
                        open: true,
                        [name]: team,
                        snackMessage: `found ${team.customName}`
                    })
                    this.hideSnackBar();
                }
            }
            catch(e){
                this.setState({
                    open: true,
                    snackMessage: 'we could not find a team that matches that id'
                })
                this.hideSnackBar();
            }
        }
    }

    handleCopyActivities =  async () => {
        const { fromTeamId, toTeamId, includeCoach, includeDrill, includeEducation, includeExercise, includeWorkouts } = this.state
        this.setState({
            open: true,
            snackMessage: 'I will hide when the task is complete, grab some coffee'
        })
        try{
            let workouts = []
            let activityIdAndActivityMap = [];
            let activityIdAndTaggingMap = {};
            if(includeCoach){
                let newActivities = await this.getActivities('coach', fromTeamId)
                let toTeamActivities = await this.getActivities('coach', toTeamId)
                await Promise.all(newActivities.map(newActivity => {
                    let alreadyPresent = _.find(toTeamActivities, teamActivity => teamActivity.source == newActivity.id)
                    if(!alreadyPresent) {
                        newActivity.source = newActivity.id;
                        newActivity.overrideActivityId = shortid.generate();
                        activityIdAndActivityMap[newActivity.id] = newActivity
                    }
                }))
            }
            if(includeDrill){
                let newActivities = await this.getActivities('drill', fromTeamId)
                let toTeamActivities = await this.getActivities('drill', toTeamId)
                await Promise.all(newActivities.map(newActivity => {
                    let alreadyPresent = _.find(toTeamActivities, teamActivity => teamActivity.source == newActivity.id)                    
                    if(!alreadyPresent) {
                        newActivity.source = newActivity.id;
                        newActivity.overrideActivityId = shortid.generate();
                        activityIdAndActivityMap[newActivity.id] = newActivity
                    }
                }))
            }
            if(includeEducation){
                let newActivities = await this.getActivities('education', fromTeamId)
                let toTeamActivities = await this.getActivities('education', toTeamId)
                await Promise.all(newActivities.map(newActivity => {
                    let alreadyPresent = _.find(toTeamActivities, teamActivity => teamActivity.source == newActivity.id)                    
                    if(!alreadyPresent) {
                        newActivity.source = newActivity.id;
                        newActivity.overrideActivityId = shortid.generate();
                        activityIdAndActivityMap[newActivity.id] = newActivity
                    }
                }))
            }
            if(includeExercise){
                let newActivities = await this.getActivities('exercise', fromTeamId)
                let toTeamActivities = await this.getActivities('exercise', toTeamId)
                await Promise.all(newActivities.map(newActivity => {
                    let alreadyPresent = _.find(toTeamActivities, teamActivity => teamActivity.source == newActivity.id)                    
                    if(!alreadyPresent) {
                        newActivity.source = newActivity.id;
                        newActivity.overrideActivityId = shortid.generate();
                        activityIdAndActivityMap[newActivity.id] = newActivity
                    }
                }))
            }

            let newWorkoutArray = [];
            if(includeWorkouts){
                let newWorkouts = await this.getWorkouts(fromTeamId)
                let toTeamWorkouts = await this.getWorkouts(toTeamId)
                await Promise.all(newWorkouts.map(newWorkout => {
                    let alreadyPresent = _.find(toTeamWorkouts, teamWorkout => teamWorkout.source == newWorkout.id)                   
                    if(!alreadyPresent) {
                        newWorkout.source = newWorkout.id;
                        workouts.push(newWorkout)
                    }
                }))
               
                await Promise.all(workouts.map(async workout => {
                    let newWorkoutJSON = workout
                    newWorkoutJSON.teamId = toTeamId
                    let drills = await this.getActivities('drill', toTeamId)
                    let exercises = await this.getActivities('exercise', toTeamId)
                    let edcuationResources = await this.getActivities('education', toTeamId)
                    let toTeamActivities = drills.concat(exercises, edcuationResources)

                    if(workout){
                        await Promise.all(_.values(workout.workoutSegments).map(async ws => {
                            let newWorkoutActivitiesData = [];
                            await Promise.all(_.values(ws.workoutActivities).map(async wa => {
                                if(wa && wa.id){
                                    try {
                                        wa.id = shortid.generate()
                                        let fullActivity = await this.getActivity(wa.activityId)
                                        let alreadyPresent = _.find(toTeamActivities, teamActivity => teamActivity.source == fullActivity.id)||{};
                                        if (!activityIdAndActivityMap[fullActivity.id]) {
                                            activityIdAndActivityMap[fullActivity.id] = fullActivity;
                                            activityIdAndActivityMap[fullActivity.id].overrideActivityId = alreadyPresent.id || shortid.generate();
                                            activityIdAndActivityMap[fullActivity.id].source = fullActivity.id
                                        }                                        
                                        activityIdAndActivityMap[fullActivity.id].parentId = toTeamId;
                                        activityIdAndActivityMap[fullActivity.id].isWorkoutActivity = true;
                                        wa.activityId = activityIdAndActivityMap[fullActivity.id].overrideActivityId;                      
                                        newWorkoutActivitiesData.push(wa);
                                    }
                                    catch (e) {
                                        console.log('error making that activity => ', e)
                                    }
                                }
                            }))
                            ws.workoutActivities = newWorkoutActivitiesData;
                        }))                    
                        newWorkoutArray.push(newWorkoutJSON)
                        const savedWorkout = this.createWorkout(newWorkoutJSON)
                    }
                }))
            }

            await Promise.all(Object.keys(activityIdAndActivityMap).map(id => {
                let newActivityJSON = activityIdAndActivityMap[id];
                newActivityJSON.parentId = toTeamId
                if(!_.isEmpty(newActivityJSON.taggings)){
                    activityIdAndTaggingMap[newActivityJSON.overrideActivityId] = newActivityJSON.taggings;                
                }
               const newActivity = this.createActivity(newActivityJSON)
            }));


            await Promise.all(Object.keys(activityIdAndTaggingMap).map(activityId => {
                let taggingObjects = _.values(activityIdAndTaggingMap[activityId]);
                taggingObjects.map(async taggingObj => {
                    if (!!taggingObjects) {
                        let taggingJSON = taggingObj.tagging;
                        taggingJSON.parentId = activityId;
                        this.saveTagging(taggingJSON)
                    }                   
                })
            }
           ));

            this.hideSnackBar();
            this.resetState();
        }
        catch(e){
            this.setState({
                open: true,
                snackMessage: 'no activities found to copy'
            })
            this.hideSnackBar()

            console.log('error copying activities => ', e)
        }
    }

    handleChange = name => async event => {
        this.setState({ [name]: event.target.value })
    };

    handleActivityType = name => event => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    render() {
        const { classes, theme } = this.props;
        const { toTeamId, fromTeamId, fromTeam, toTeam, includeCoach, includeDrill, includeEducation, includeExercise, includeWorkouts, teamNameSearch, foundTeams } = this.state;

        return (
            <PageWrapper title="Copy Content" {...this.props}>
            <div className="copyActivitiesOuterContainer">
                <div className={"pageTitleContainer"} >
                    <h2 className={"pageTitle"}></h2>
                    <Button
                        mini
                        variant="fab"
                        aria-label="add"
                        title="Add Activity"
                        style={{color: 'white'}}
                        className={classes.plusButton}
                        onClick={this.handleCopyActivities}
                    >
                        <CheckIcon className={"icon"} />
                    </Button>
                </div>
                <div className={"innerContainer"}>
                    <p className={"directionsText"}>
                        Get the IDs of the teams you wish to copy to and from. If you do not have them handy, you may search for teams by name (case sensitive) then use their ID.
                    </p>
                    <p className={"directionsText"}>
                        After entering the ID, click the check icon at the end of the field and ensure the custom name that appears below the input matches the team you expected to find.
                    </p>
                    <p className={"directionsText"}>
                        As an admin you can find the ID in the navigation header next to the current team's name.
                    </p>
                    <p className={"directionsText"}>
                        after finding the teams, select what content types you'd like to include in the process then copy away!
                    </p>
                    <Grid container spacing={24}>
                        <Grid item xs={12} className="searchContainer">
                            <TextField
                                label="Search by team name"
                                id="teamNameSearch"
                                margin={"dense"}
                                className={"textField"}
                                value={teamNameSearch ? teamNameSearch : ""}
                                onChange={this.handleChange("teamNameSearch")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment 
                                            position="start"
                                            onClick={this.findTeam("teamNameSearch")}
                                        >
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {foundTeams.length ? 
                            foundTeams.map(team => {
                                return <Grid item xs={12} key={team.id}>
                                    <p className={"directionsText"} style={{color: 'grey'}}>
                                        {team.id} - {team.customName ? team.customName : team.name}
                                    </p>
                                </Grid>})
                        : null}
                        <Grid item xs={12} className="searchContainer">
                            <TextField
                                label="From Team ID"
                                margin={"dense"}
                                id="fromTeamId"
                                className={"textField"}
                                value={fromTeamId ? fromTeamId : ""}
                                onChange={this.handleChange("fromTeamId")}
                                helperText={fromTeam ? fromTeam.customName : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment 
                                            position="start"
                                            onClick={this.findTeam("fromTeam")}
                                        >
                                            <CheckIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="searchContainer">
                            <TextField
                                label="To Team Id"
                                id="toTeamId"
                                margin={"dense"}
                                className={"textField"}
                                value={toTeamId ? toTeamId : ""}
                                onChange={this.handleChange("toTeamId")}
                                helperText={toTeam ? toTeam.customName : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment 
                                            position="start"
                                            onClick={this.findTeam("toTeam")}
                                        >
                                            <CheckIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="searchContainer">
                            <p style={{margin: 0}}>Workouts</p>
                            <Checkbox checked={includeWorkouts} onChange={this.handleActivityType('includeWorkouts')} />
                        </Grid>
                        <Grid item xs={12} className="searchContainer">
                            <p style={{margin: 0}}>Skill Development</p>
                            <Checkbox checked={includeDrill} onChange={this.handleActivityType('includeDrill')} />
                        </Grid>
                        <Grid item xs={12} className="searchContainer">
                            <p style={{margin: 0}}>Strength & Condtioning</p>
                            <Checkbox checked={includeExercise} onChange={this.handleActivityType('includeExercise')} />
                        </Grid>
                        <Grid item xs={12} className="searchContainer">
                            <p style={{margin: 0}}>Education Materials</p>
                            <Checkbox checked={includeEducation} onChange={this.handleActivityType('includeEducation')} />
                        </Grid>
                        <Grid item xs={12} className="searchContainer">
                            <p style={{margin: 0}}>Coaching Resources</p>
                            <Checkbox checked={includeCoach} onChange={this.handleActivityType('includeCoach')} />
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    open={this.state.open}
                    TransitionComponent={this.state.Transition}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.snackMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            aria-label="Close"
                            onClick={() => this.setState({open:false, snackMessage:""})}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
            </PageWrapper>
        );
    }
}

AdminCopyActivities.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AdminCopyActivities));
