import React, { Component } from 'react'

import './styles.css'

import IconButton from '../iconButton'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'

export default class FeedbackToast extends Component {
    handleDismissClick = () => {
        const { close } = this.props
        close()
    }

    handleSurveyClick = () => {
        const { surveyUrl } = this.props
        window.open(surveyUrl)
    }

    render(){
        const { text, open = true } = this.props
        return(
            open ?
                <div className={'feedbackToastOuterContainer'}>
                    <p className={'feedbackToastText'}>{text}</p>
                    <div className={'feedbackToastBtnContainer'}>
                        <IconButton onClick={this.handleSurveyClick} text={'Visit Survey'} position={'left'} icon={<CheckIcon className={'checkIcon'}/>} color={'#27AE60'} />
                        <IconButton onClick={this.handleDismissClick} text={'Dismiss'} position={'left'} icon={<CancelIcon className={'cancelIcon'}/>} color={'white'} textColor={'black'} />
                    </div>
                </div>
            :
                null
        )
    }
}