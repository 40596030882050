import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CancelCalendar = (props) => (
  <SvgIcon width="39" height="48" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path opacity="0.4" d="M0 36.1827C0 38.1785 1.55228 39.7308 3.54808 39.7308H29.5673C31.4892 39.7308 33.1154 38.1785 33.1154 36.1827V16.0769H0V36.1827ZM23.6538 21.6947C23.6538 21.2512 24.0234 20.8077 24.5409 20.8077H27.4976C27.9411 20.8077 28.3846 21.2512 28.3846 21.6947V24.6514C28.3846 25.1689 27.9411 25.5385 27.4976 25.5385H24.5409C24.0234 25.5385 23.6538 25.1689 23.6538 24.6514V21.6947ZM23.6538 31.1563C23.6538 30.7127 24.0234 30.2692 24.5409 30.2692H27.4976C27.9411 30.2692 28.3846 30.7127 28.3846 31.1563V34.113C28.3846 34.6304 27.9411 35 27.4976 35H24.5409C24.0234 35 23.6538 34.6304 23.6538 34.113V31.1563ZM14.1923 21.6947C14.1923 21.2512 14.5619 20.8077 15.0793 20.8077H18.0361C18.4796 20.8077 18.9231 21.2512 18.9231 21.6947V24.6514C18.9231 25.1689 18.4796 25.5385 18.0361 25.5385H15.0793C14.5619 25.5385 14.1923 25.1689 14.1923 24.6514V21.6947ZM14.1923 31.1563C14.1923 30.7127 14.5619 30.2692 15.0793 30.2692H18.0361C18.4796 30.2692 18.9231 30.7127 18.9231 31.1563V34.113C18.9231 34.6304 18.4796 35 18.0361 35H15.0793C14.5619 35 14.1923 34.6304 14.1923 34.113V31.1563ZM4.73077 21.6947C4.73077 21.2512 5.10036 20.8077 5.61779 20.8077H8.57452C9.01803 20.8077 9.46154 21.2512 9.46154 21.6947V24.6514C9.46154 25.1689 9.01803 25.5385 8.57452 25.5385H5.61779C5.10036 25.5385 4.73077 25.1689 4.73077 24.6514V21.6947ZM4.73077 31.1563C4.73077 30.7127 5.10036 30.2692 5.61779 30.2692H8.57452C9.01803 30.2692 9.46154 30.7127 9.46154 31.1563V34.113C9.46154 34.6304 9.01803 35 8.57452 35H5.61779C5.10036 35 4.73077 34.6304 4.73077 34.113V31.1563ZM29.5673 6.61539H26.0192V3.06731C26.0192 2.47596 25.4279 1.88462 24.8365 1.88462H22.4712C21.8059 1.88462 21.2885 2.47596 21.2885 3.06731V6.61539H11.8269V3.06731C11.8269 2.47596 11.2356 1.88462 10.6442 1.88462H8.27885C7.61358 1.88462 7.09615 2.47596 7.09615 3.06731V6.61539H3.54808C1.55228 6.61539 0 8.24159 0 10.1635V13.7115H33.1154V10.1635C33.1154 8.24159 31.4892 6.61539 29.5673 6.61539Z" fill="#0B0B0B" />
    <circle cx="28" cy="37" r="11" fill="#EF3C3C" />
    <path d="M31.6064 33.5356L24.5354 40.6067" stroke="white" stroke-width="2" stroke-linecap="round" />
    <path d="M31.6064 40.6064L24.5354 33.5354" stroke="white" stroke-width="2" stroke-linecap="round" />
  </SvgIcon>
);

export default CancelCalendar;






