import React, { Component } from 'react'

import './styles.css'
import classnames from 'classnames'

export default class BorderedIconButton extends Component {
    render(){
        const { icon, onClick, overrideContainerClassName } = this.props
        return(
            <div onClick={onClick} className={classnames('borderedIconContainer',overrideContainerClassName)}>
                {icon && icon}
            </div>
        )
    }
}