import { API } from "aws-amplify";

export async function getTeamActivities(teamId){
    return API.get('activities', `/programs/${teamId}/activities`);
}

export async function getTeamActivitiesElasticSearch(teamId, queryStringParameters){
    return API.get('activities', `/team/${teamId}/activities`, {
      queryStringParameters
    });
}

export async function getActivity(activityId){
    return API.get('activities', `/activities/${activityId}`);
}

export async function createActivity(activity){
    return API.post("activities", `/activities`, {
        body: activity
    });
}

export async function updateActivity(activity) {
    return API.put("activities", `/activities/${activity.id}`, {
        body: activity
    });
}

export async function saveTagging(tagging) {
    if(tagging.id){
        return API.put("taggings", `/taggings/${tagging.id}`, {
            body: tagging
        });
    }
    else {
        return API.post("taggings", `/taggings`, {
            body: tagging
        });
    }
}


export async function getProductActivities(){
    return API.get("activities", '/products/activities',{
        queryStringParameters:{
            newTagFormat:true
        }
    });
}

export async function getActivitiesByCategory(currentTeamId, category){
    return API.get("activities", `/programs/${currentTeamId}/activities/${category}`,{
        queryStringParameters:{
            newTagFormat:true
        }
    });
}

export async function getActivitiesByTeamId(currentTeamId){
    return API.get("activities", `/programs/${currentTeamId}/activities`, {
        queryStringParameters:{
            newTagFormat:true
        }
    });
}

export async function getActivitiesByOrganisationId(organisationId){
    return API.get("activities", `/organizations/${organisationId}/activities`, {
        queryStringParameters:{
            newTagFormat:true
        }
    });
}