import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SwipeableViews from 'react-swipeable-views';
import { analyticsService, currentTimestamp } from '../../services/analyticsService';
import _ from 'lodash';
import { API } from "aws-amplify";
import { withStyles } from '@material-ui/core/styles';
import './marketplace.css'
import PageWrapper from '../nav/pageWrapper';
import {testId} from '../../utils/test-utils';
import ModalContainer from '../../components/modalContainer';
import ThemeButton from '../../components/themeButton'

import SelectTeamsModal from '../selectTeamsModal'


const styles = theme => ({
  secondaryButton: {
    // width: 100,
    height: 40,
    marginRight: 10,
    backgroundColor: "#27AE60",
    color:"#ffffff"
  },
  primaryButton: {
    backgroundColor: "#1354F9",
    color:"#ffffff"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width: "100%",
    marginTop: 10
  },
  header: {
    fontWeight: 100,
    fontSize: 45,
    marginLeft: 35,
    float: "left"
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom: '-35px'
  },
  selectField: {
    flexGrow: "1",
    width: "100%",
    textAlign: "left",
    marginTop: "5px"
  }
});


class MarketplaceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cvc: "",
      number: "",
      expYear: "",
      expMonth: "",
      isLoading: false,
      addNowOpen: false,
      buyNowOpen: false,
      showBilling: true,
      showPrograms: false,
      copyModalOpen: false,
      billing: {
        name: "",
        email: "",
        phone: ""
      },
      card: {
        cvc: "",
        number: "",
        expYear: "",
        expMonth: "",
      }
    };
  }

  // async handleDeleteGroup(group){
  //   var deleteGroup = await this.deleteGroup(group.id);
  //   var elementPos = this.state.groups.map(function(x) {return x.id; }).indexOf(group.id);
  //   this.state.groups.splice(elementPos, 1);
  //   this.setState({groups: this.state.groups});
  // }

  async componentDidMount() {
    const { userContext } = this.props;

    try {
      var product = await this.getProduct()
      console.log('product')
      console.log(product);

      if (product.featureImageUrl) {
        if (product.featureImageUrl.includes("https://")) {
          product.featureImageUrl = product.featureImageUrl
        }
        else {
          var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/marketplace/${product.legacyLongId ? product.legacyLongId : product.id}/featured/${product.featureImageUrl}`
          product.featureImageUrl = logo;
        }
      }
      if (product.partnerImageUrl) {
        if (product.partnerImageUrl.includes("https://")) {
          product.partnerImageUrl = product.featureImageUrl;
        }
        else {
          var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/marketplace/${product.legacyLongId ? product.legacyLongId : product.id}/partner/${product.partnerImageUrl}`
          product.partnerImageUrl = logo;
        }
      }

      const organization = _.find(userContext.appContextList, c => c.id === this.props.currentTeam.organizationId);

      // if(organization.billing && organization.billing.isAutomatedBilling) {
      //   this.setState({billingMethodSelected:"invoice"})
      // }

      if (organization && organization.billing && organization.billing.contacts) {
        this.setState({ billing: organization.billing.contacts[0] })
      }

      if (organization && organization.billing && organization.billing.methods) {
        this.setState({ expMonth: organization.billing.methods[0].expMonth, expYear: organization.billing.methods[0].expYear })
      }

      this.setState({ product })
    }
    catch (e) {
      console.log('e ', e)
    }
    this.setState({isLoading:false});
  }

  getProduct() {
    var id = this.props.match.params.id;
    return API.get("products", `/products/${id}`);
  }

  createStripeTokenAndCustomer() {
    const { currentUser } = this.props;
    const { card, product } = this.state;
    return API.post("billing", `/billing/customer/user`, {
      body: {
        card: card,
        userId: currentUser.id,
      }
    });
  }

  purchaseProduct(value, selectedTeamsForDownload) {
    const { currentUser } = this.props;
    const { product, billing } = this.state;
    const paymentMethod = value == 0 ? 'card' : 'invoice';
    if (!product.price || product.price && (product.price == 0 || product.price == '0' || product.price == '0.00')) return;
    return API.post("products", `/products/${product.id}/purchase`, {
      body: {
        tenant: this.props.tenant,
        billing: billing,
        userId: currentUser.id,
        teams: selectedTeamsForDownload,
        paymentMethod: paymentMethod
      }
    });
  }

  copyProduct(teamIds) {
    const { product } = this.state;
    return API.post("products", `/products/copynew`, {
      body: {
        product: product,
        teamIds
      }
    })
  }

  handlePurchaseProduct = async (value, teamIds, teams) => {
    const { product } = this.state;
    let { enableFullScreenLoader } = this.props;
    this.setState({ buyNowOpen: false, messageKey: 'PURCHASE_LOADING'})
    enableFullScreenLoader(true, 'Uploading program to your ' + 'team'.pluralize('teams', teamIds.length));
    try {
      const purchasedProduct = await this.purchaseProduct(value, teams);
      // await this.productCopyRef.beginCopy(product, teams);
      const copiedProduct = await this.copyProduct(teamIds);
      analyticsService.trackEvent('Marketplace Package Downloaded', {
        marketplaceAssetId: product.id,
        marketplaceAssetSlug: product.slug,
        marketplaceAssetName: product.name,
        marketplaceAssetPurchaseDate: currentTimestamp,
        marketplaceAssetPurchasePrice: product.price,
        marketplaceAssetPurchaseType: product.price && product.price > 0 ? 'PAID' : 'FREE'
      });
      enableFullScreenLoader(true, 'Uploaded successfully');
      this.setState({  messageKey: 'PURCHASE_SUCCESS' })
    }
    catch (ex) {
      // this.productCopyRef.showError('There was an error saving this program to your team(s). Please try again later.');
      this.setState({ messageKey: 'PURCHASE_FAILED' });
      enableFullScreenLoader(true, 'Uploading failed. Please try again later');
    }
    finally {
      setTimeout(() => enableFullScreenLoader(false), 2000);
    }
  }

  handleSaveBilling = async value => {
    const { currentUser } = this.props;
    const { card, billing } = this.state;

    if (value == 0 && !card.number) return this.setState({ errorMessage: 'oops, Card Number is required' });
    if (value == 0 && !card.cvc) return this.setState({ errorMessage: 'oops, CVC is required' });
    if (value == 0 && !card.expMonth) return this.setState({ errorMessage: 'oops, an Expiration Month is required' });
    if (value == 0 && !card.expYear) return this.setState({ errorMessage: 'oops, an Epiration Year is required' });

    if (value == 1 && !billing.name) return this.setState({ errorMessage: 'oops, a Contact Name is required' });
    if (value == 1 && !billing.email) return this.setState({ errorMessage: 'oops, a Contact Email is required' });

    if (value == 0 && !currentUser.stripeCustomerToken) {
      const updatedUser = await this.createStripeTokenAndCustomer();
    }
    this.setState({ showPrograms: true, showBilling: false });
  }

  handleClose = () => {
    this.setState({ buyNowOpen: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeCard = name => event => {
    const { card } = this.state;
    card[name] = event.target.value;
    this.setState(card);
  }

  handleChangeBilling = name => event => {
    const { billing } = this.state;
    billing[name] = event.target.value;
    this.setState(billing);
  }


  render() {
    const { product = {}, buyNowOpen, addNowOpen, card, billing, showBilling, showPrograms, errorMessage, isLoading, message, messageKey, copyModalOpen } = this.state;
    const { classes, theme, userContext } = this.props;
    const currentOrg = _.find(userContext.appContextList, c => c.id === this.props.currentTeam.organizationId);

    return (
      <PageWrapper title="Marketplace" {...this.props}  isPageLoading={isLoading} loadingMessage={message}>
        <div className="Marketplace">
          <Grid container spacing={0} style={{
            width: 'calc(100% + 30px)',
            marginLeft: '-15px',
            marginTop: 0
          }}
          >

            <Grid item xs={12} style={{
              justify: 'left',
              width: 'calc(100% + 30px)',
              height: 400,
              zIndex: '-50',
              backgroundImage: 'url(' + product.featureImageUrl + ')',
              backgroundSize: 'cover'
            }}
            >
            </Grid>

            <Grid item xs={12} style={{
              justify: "left",
              width: 'calc(100% + 30px)',
              height: 400,
              zIndex: '-50',
              marginTop: '-400px',
              background: 'rgba(0, 0, 0, 0.5)'
            }}
            >
              {
                product.featured
                  ?
                  <h2 style={{ color: 'white', fontweight: 300, marginTop: 75, textAlign: "left", width: '90%', marginLeft: 50 }}>
                    FEATURED
                </h2>
                  :
                  <div style={{ marginTop: 75 }}></div>
              }
              {
                product.partner
                  ?
                  <h1 style={{ color: 'white', fontWeight: "lighter", textAlign: "left", width: '90%', marginLeft: 50, fontSize: '1.5em' }}>
                    <img src={product.partnerImageUrl} style={{ height: 50, width: 50, marginBottom: '-10px' }} />
                    {product.partner}
                  </h1>
                  :
                  null
              }

              <h1 style={{ color: 'white', fontweight: 300, textAlign: 'left', width: '90%', marginLeft: 50, fontSize: '3rem', marginBottom: 10 }}>
                {product.name}
                <span style={{ color: 'lightgray', fontSize: '1.5rem', marginLeft: 10 }}>{product.price && product.price > 0 ? `$${product.price}` : 'FREE'}</span>
              </h1>

              {
                product.description
                  ?
                  <div style={{
                    height: 20,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'white',
                    fontweight: 'lighter',
                    textAlign: 'left',
                    width: '80%',
                    marginLeft: 50,
                    fontSize: '1em'
                  }}
                  >
                    {product.description}
                  </div>
                  :
                  <div style={{ height: 20 }}></div>
              }

              {/* {
                product.price > 0
              ?
                <div>
                  <h2 style={{color:'white', fontweight:'lighter', textAlign:"left", width:"100%", marginLeft:50, fontSize:"1.5em", marginTop:10}}>
                    ${product.price}
                    <Button  onClick={() => this.setState({buyNowOpen:true})} variant="contained" color="secondary" style={{marginLeft:10, borderRadius: 0}}>
                      BUY NOW
                    </Button>
                  </h2>
                </div>
              :
                <h2 style={{color:'white', fontweight:'lighter', textAlign:"left", width:"100%", marginLeft:50, fontSize:"1.5em", marginTop:10}}>
                  FREE
                  <Button  onClick={() => this.setState({addNowOpen:true})} variant="contained" color="secondary" style={{marginLeft:10, borderRadius: 0}}>
                    ADD NOW
                  </Button>
                </h2>
              } */}

            </Grid>
          </Grid>
          <Paper style={{ width: '100%', minWidth: 690, paddingTop: 0, paddingBottom: 20, zIndex: 100, marginTop: product.description ? -60 : -200, borderRadius: 0 }}>
            <Grid container spacing={0} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>{`WHAT'S INSIDE`}</h3>
                <div className={"btnContainer d--flex"}>
                  <Button variant="contained" className={classes.primaryButton} style={{height: "40px", marginRight:"15px", width: 150}} onClick={()=> this.props.history.push('/marketplace')}>
                        BACK
                  </Button>

                  {
                    product.price && product.price != 0 && product.price != '0' && product.price != '0.00'
                      ?
                      <Button {...testId(`buy-now-button`)} onClick={() => this.setState({buyNowOpen:true, showBilling: true})} variant="contained" className={classes.secondaryButton}>${product.price} Buy Now</Button>
                      :
                      <Button {...testId(`buy-now-button`)} onClick={() => this.setState({buyNowOpen:true})} variant="contained" className={classes.secondaryButton}>Add Now</Button>
                    }
                  </div>
                </Grid>
                <Grid item xs={12} style={{textAlign:'left'}}>
                  <p  style={{textAlign:'left'}} dangerouslySetInnerHTML={{__html: product.details}} />
                </Grid>
              </Grid>            
          </Paper>
          <BillingModal
            card={card}
            classes={classes}
            open={buyNowOpen}
            product={product}
            billing={billing}
            message={message}
            isLoading={isLoading}
            showBilling={showBilling}
            errorMessage={errorMessage}
            showPrograms={showPrograms}
            handleChange={name => this.handleChange(name)}
            save={this.handleSaveBilling}
            close={() => this.setState({ buyNowOpen: false })}
            purchase={this.handlePurchaseProduct}
            handleChangeCard={name => this.handleChangeCard(name)}
            handleChangeBilling={name => this.handleChangeBilling(name)}
          />
        </div>
      </PageWrapper>
    );
  }
}

class BillingModal extends Component {
  state = { value: 0 };

  handleTabValueChange = (product, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { open, product, card, billing, showBilling, showPrograms, errorMessage, isLoading, message, classes, save, purchase, close, messageKey } = this.props;
    const years = _.range(new Date().getFullYear(), new Date().getFullYear() + 15);
    const months = _.range(1, 12);

    return (
      showBilling && product.price && product.price != 0 && product.price != '0' && product.price != '0.00' ? 
        <ModalContainer
          open={open}
          onClose={this.props.close}
          heading={product.name ? product.name : 'Product'}
          leftBtn={
            <ThemeButton
                width={"103px"}
                iconColor={"#75797B"}
                textColor={"#0B0B0B"}
                color={"#ffffff"}
                height={"44px"}
                position="left"
                iconName="Cancel"
                theme="small"
                onClick={close}
                text="Cancel"
            />
        }
        rightBtn={
            <ThemeButton
                width={"86px"}
                textColor={"#ffffff"}
                color={"#27AE60"}
                height={"44px"}
                position="left"            
                theme="small"                              
                onClick={()=>save(value)}
                text={"Next"}
                {...testId(`buy-now-button`)}
            />}
        >
          <div>
            {isLoading ?
              <div>
                <div style={{ height: 400 }}>
                <h2 {...testId(`billing-${messageKey}`)}>{message}</h2>
                </div>
              </div>
              :
              <React.Fragment>
                <div>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabValueChange}
                    className={"tabContainer"}>
                    <Tab style={{outline:'none'}} label="CC" value={0} />
                    <Tab style={{outline:'none'}} label="Invoice" value={1} />
                  </Tabs>
                  <SwipeableViews index={value}>
                    <div style={{ height: 400 }}>
                      <Grid container spacing={8} style={{ width: '100%' }}>
                        <Grid item xs={10} style={{ marginTop: 10 }}>
                          <TextField
                            id="number"
                            label="Card Number"
                            style={{ width: '100%' }}
                            value={card.number ? card.number : ""}
                            onChange={this.props.handleChangeCard("number")}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ marginTop: 10 }}>
                          <TextField
                            id="cvc"
                            label="CVC"
                            style={{ width: '100%' }}
                            value={card.cvc ? card.cvc : ""}
                            onChange={this.props.handleChangeCard("cvc")}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: 10 }}>
                          <FormControl className={classes.selectField}>
                            <InputLabel>
                              Month
                            </InputLabel>
                            <Select
                              input={<Input id="expMonth" />}
                              value={card.expMonth ? card.expMonth : ""}
                              onChange={this.props.handleChangeCard("expMonth")}
                            >
                              {months.map(month => (
                                <MenuItem
                                  key={month}
                                  value={month}>
                                  {month}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: 10 }}>
                          <FormControl className={classes.selectField}>
                            <InputLabel>
                              Year
                            </InputLabel>
                            <Select
                              input={<Input id="expYear" />}
                              value={card.expYear ? card.expYear : ""}
                              onChange={this.props.handleChangeCard("expYear")}
                            >
                              {years.map(year => (
                                <MenuItem
                                  key={year}
                                  value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {
                          errorMessage !== ""
                            ?
                            <Grid item xs={12}>
                              <Typography style={{ color: "red" }}>{errorMessage}</Typography>
                            </Grid>
                            :
                            null
                        }
                      </Grid>
                    </div>
                    <div style={{ height: 400 }}>
                      <Grid container spacing={8} style={{ width: '100%' }}>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                          <TextField
                            id="contactName"
                            label="Billing Contact Name"
                            style={{ width: '100%' }}
                            value={billing.name ? billing.name : ""}
                            onChange={this.props.handleChangeBilling("name")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                          <TextField
                            id="phone"
                            label="Billing Contact Phone"
                            style={{ width: '100%' }}
                            value={billing.phone ? billing.phone : ""}
                            onChange={this.props.handleChangeBilling("phone")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                          <TextField
                            id="email"
                            label="Billing Contact Email"
                            style={{ width: '100%' }}
                            value={billing.email ? billing.email : ""}
                            onChange={this.props.handleChangeBilling("email")}
                          />
                        </Grid>
                        {
                          errorMessage !== ""
                            ?
                            <Grid item xs={12}>
                              <Typography style={{ color: "red" }}>{errorMessage}</Typography>
                            </Grid>
                            :
                            null
                        }
                      </Grid>
                    </div>
                  </SwipeableViews>
                </div>
              </React.Fragment>}
          </div>
        </ModalContainer>
        :
        <SelectTeamsModal
          title={`Add: ${product.name}`}
          classes={classes}
          open={open}
          save={(teamIds, teams) => purchase(value, teamIds, teams)}
          close={close}
          productPrice={product.price != 0 && product.price != '0' && product.price != '0.00' ? product.price : null}
        />
    )
  }
}

MarketplaceDetails.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    userContext: userContext,
    currentTeam: currentTeam||{},
    currentUser: userContext.user
  };
}

export default withStyles(styles)(connect(mapStateToProps)(MarketplaceDetails));
