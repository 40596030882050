import React, { Component } from "react";

class VimeoVideo extends Component {

  constructor(props) {
      super(props);

      this.state = {
          videoId: null
      };
  }

  componentDidMount = async () => {
      this.setState({videoId: this.props.match.params.videoId})
      console.log('props', this.props)
  }

  render(){
    var { videoId } = this.state;
    var source = `//player.vimeo.com/video/${videoId}`
    console.log('source ==', source)
    return(
      <div style={{height:"100vh", width:"100vw"}}>
      {
        videoId
        ?
        <iframe src={source} width="100vw" height="100vh" frameBorder="0" style={{width:'100vw', height:'100vh'}} webkitAllowFullscreen mozallowfullscreen allowFullScreen></iframe>
        :
        null
      }
      </div>
    )
  }

}

export default VimeoVideo
