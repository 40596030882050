import React, { Component } from "react";
import { connect } from 'react-redux';
import ModalContainer from '../../components/modalContainer';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputField } from 'maxone-react-ui';
import DatePicker from "react-datepicker";
import FloatingSelect from '../../components/floatingSelect'
import {
    MenuItem
} from '@material-ui/core';
import { questionClient, workoutClient, userClient, groupClient } from '../../clients'
import { REPORT_SCOPES } from '../../constants';
import DropDownWithSearch from "../../components/dropDownWithSearch"
import moment from "moment";

let styles = {
    topSectionAdditionalContent: {
        marginTop: "6px"
    },
    subHeadTxt: {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom: "12px"
    },
    activityCard: {
        verticalAlign: "top",
        cursor: "pointer",
        width: "277px",
        height: "61px",
        marginBottom: "15px",
        display: "inline-block",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        padding: "0 12px",
        "&.selectedTeamSquare": {
            border: "2px solid #27AE60"
        },
        "&:nth-child(even)": {
            marginLeft: "14px",
        }
    },
    activityName: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        color: "#0B0B0B",
        marginLeft: "10px"
    },
    searchBox: {
        height: "44px",
        marginBottom: "17px"
    },
    checkMark: {
        border: "1px solid #DDE2E5",
        color: "#DDE2E5",
        "& svg": {
            width: "20px",
            height: "20px"
        }
    },
    activityCardContainer: {
        height: "350px",
        paddingTop: "20px",
        paddingBottom: "20px",
        justifyContent: "space-between",
        position: "relative"
    },
    marketplaceModalPaper: {
        marginRight: "-30px",
        marginLeft: "-30px",
        marginBottom: "-30px",
        paddingLeft: "30px",
        paddingRight: "30px"
    },
    loadingChild: {
        backgroundColor: "rgba(255,255,255,0.5)",
        position: "absolute"
    },
    goalInputs: {
        "& .react-datepicker-wrapper": {
            width: "100%",
            marginBottom: "24px"
        },
        "& .react-datepicker__input-container": {
            width: "100%"
        },
        "& .react-datepicker-popper": {
            zIndex: 2,
        },
        "& .react-datepicker__navigation--previous":{
            width: 0,
            height: 0,
            borderTop: "5px solid transparent !important",
            borderBottom: "5px solid transparent !important",
            borderRight: "5px solid #000 !important",
            marginTop: "5px"
        },
        "& .react-datepicker__navigation--next":{
            width: 0,
            height: 0,
            borderTop: "5px solid transparent !important",
            borderBottom: "5px solid transparent !important",
            borderLeft: "5px solid #000 !important",
            marginTop: "5px"
        }
    },
    input: {
        marginBottom: "24px",
        "&:last-child": {
            marginBottom: "0px"
        },
        "& .m1-base-input-field": {
            height: "50px"
        },
        "& input":{
            background:"transparent !important"
        }
    },
    inputField: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px",
        marginBottom: "4px"
    }
}

class GenerateReportModal extends Component {
    constructor(props) {
        super(props);
        this.typingTimeout = null;
        this.state = {
            totalPages: 0,
            isLoading: false,
            searchTerm: "",
            loadingChild: false,
            loadingMessage: "",
            menuItemData:[],
            currentPage:-1
        }
    }

    componentDidMount = () => {     
    }

    getData = async (searchTerm) => {   
        const offset = 20; 
        let { currentTeam } = this.props;
        let { scope, menuItemData, currentPage } = this.state;
        let formattedData = [];
        let results, total;
        currentPage = currentPage + 1;
        //User data 
        let params = {
            limit: offset,
            offset:currentPage * offset   
        }
        if(searchTerm){
            params.q = searchTerm;
        }
        let userParams = {
            ...params,   
            'teams.id.keyword':currentTeam.id,
            'teams.role':"ATHLETE"
        }
        let questionParams = {
            ...params,
            teamId:currentTeam.id
        }
        try{
            switch (scope){
                case REPORT_SCOPES.ATHLETE: ({ data: {total, results  } } = await userClient.searchUsers(userParams));
                (results||[]).forEach(({ firstName, lastName, id}) => {
                    let data = {};
                    data.title = `${firstName||""} ${lastName||""}`,
                    data.id = id
                    formattedData.push(data);
                }); break;
                case REPORT_SCOPES.PROGRAM: ({ data: {total, results:formattedData} } = await workoutClient.searchWorkoutProgramsByTeamId(currentTeam.id, params));break;
                case REPORT_SCOPES.WORKOUT:  ({ data:  {total, results:formattedData} } = await workoutClient.searchWorkoutsByTeamId(currentTeam.id, params));break;
                case REPORT_SCOPES.GROUP:   ({ data: {total, results} } = await groupClient.getGroupsByTeamId(currentTeam.id, params));
                (results||[]).forEach(({ name, id}) => {
                    let data = {};
                    data.title = `${name||""}`,
                    data.id = id
                    formattedData.push(data);
                }); break;
                case REPORT_SCOPES.QUESTION:  ({ data: {total, results:formattedData} } = await questionClient.searchQuestions(questionParams)); break;
            }
        }
        catch(e){
            console.log(e);
        }
        menuItemData = menuItemData.concat(formattedData.map(item => {return { value:item.id, displayValue:item.title }}))
        this.setState({formattedData, total, menuItemData, loadingChild: false, currentPage});
    }

    handleBack = () => {
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    toggleCalendar = () => {
        let { isDateSelectorOpen } = this.state;
        this.setState({
            isDateSelectorOpen: !isDateSelectorOpen
        })
    }

    handleScopeChange = (scope) => {
        this.setState({loadingChild: true});
        this.setState({scope, menuItemData:[], currentPage:-1, total:0, entityId:null}, this.getData)
    }

    //fetch groups
    //fetch questions
    //fetch workout
    //fetch programs
    //fetch athletes

    render() {
        const { closePopup, classes, generateReport } = this.props
        const {  loadingChild, startingDate, loadingMessage, isLoading, entityId, endingDate, scope, menuItemData, total } = this.state;
        return (
            <ModalContainer
                customPadding={"30px"}
                heading={`Generate New Report`}
                open={true}
                width={"630px"}
                onClose={closePopup}
                useDefaultLeftBtn={true}
                useDefaultRightBtn={true}
                defaultLeftBtnProps={{ onClick: closePopup }}
                defaultRightBtnProps={{
                    onClick: () => generateReport(scope, entityId, startingDate, endingDate),
                    text: "Generate",
                    disabled:(!entityId || !startingDate|| !endingDate ||  moment(endingDate).isBefore(startingDate))
                }}
            >
                <div className={classes.marketplaceModalPaper}>
                    {isLoading ?
                        <div className={classes.activityCardContainer}>
                            <div className={"contentLoadingOverlay"}>
                                <CircularProgress />
                                <h2>{loadingMessage}</h2>
                            </div>
                        </div>
                        :
                        <div className={classes.activityCardContainer} ref={(ref) => this.scrollParentRef = ref}>
                            {loadingChild &&
                                <div className={classnames("contentLoadingOverlay", classes.loadingChild)}>
                                    <CircularProgress />
                                </div>
                            }
                            <div className={classnames(classes.goalInputs)}>
                                <div className={classes.input}>
                                <FloatingSelect                                 
                                    label="Who are you generating this report for?"
                                    value={scope||""}
                                    onChange={e => this.handleScopeChange(e.target.value)}
                                    >
                                    {Object.keys(REPORT_SCOPES).map(scope => (
                                        <MenuItem
                                            key={scope}
                                            value={scope}>
                                            {scope}
                                        </MenuItem>
                                    ))}
                                </FloatingSelect>
                                <DropDownWithSearch 
                                    enableInfiniteLoading={true}
                                    loadMore={()=>this.getData()}
                                    value={entityId}
                                    hasMore={menuItemData.length < total}
                                    disabled={!scope}
                                    placeholder={scope ? `Start typing to search by ${scope.toLowerCase()} name`:"Please select report type above."}
                                    onChange={(value) => { 
                                        this.handleChange("entityId", value)}}
                                    menuItemData={menuItemData}
                                    dropdownHeight={"201px"}
                                    />
                                </div>
                                <div className={"position-relative"}>
                                <DatePicker selected={startingDate} onChange={date => this.handleChange('startingDate', date)}
                                    popperPlacement="top-start"
                                    customInput={<CalendarInput classes={classes} label={"Select a start date"}/>}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "160px, 0px"
                                        },
                                        flip: {
                                            enabled: false
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false
                                        }
                                    }}
                                />
                                </div>
                                <div className={"position-relative"}>
                                 <DatePicker selected={endingDate} onChange={date => this.handleChange('endingDate', date)}
                                    popperPlacement="top-start"
                                    customInput={<CalendarInput classes={classes} label={"Select an end date"}/>}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "160px, 0px"
                                        },
                                        flip: {
                                            enabled: false
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false
                                        }
                                    }}
                                />
                                </div>
                            </div>
                        </div>}
                </div>
            </ModalContainer>
        )
    }
}

const CalendarInput = ({ value, onClick, classes, label }) => (
    <div className={classes.input}>
        <div onClick={onClick} className={"position-relative"}>
            <InputField value={value} label={label} />
        </div>
    </div>
);

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = (userContext.appContextList || []).find(c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(GenerateReportModal));