import React, { Component } from "react";
import PropTypes from 'prop-types';

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { API } from "aws-amplify";

import MetaTags from 'react-meta-tags';

import { withStyles } from '@material-ui/core/styles';

import "./signup.css";

import { getThemeByTenant } from '../../themes';
const theme = getThemeByTenant();
const logo = theme.image.loginLogo;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  menu: {
    width: 500,
    padding: 50
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: 300,
    marginTop:10
  },
  root: {
    flexGrow: 1,
  },
  header: {
    fontWeight: 100,
    fontSize:45,
    marginBottom:'-15px'
  },
  subHeader: {
    fontWeight: 100,
  },
  image: {
    width: 100,
    marginBottom:'-35px'
  },
});


class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    this.setState({ newUser: "test" });

    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
  }


  render() {
    const { classes } = this.props;

    return (
      <div className="Signup">
      <MetaTags>
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </MetaTags>
      <Grid container spacing={24}>
        <Grid item xs={12} style={{justify:"center"}}>
            <img className={classes.image} src={logo}  />
            <h1 className={classes.header}>WELCOME</h1>
            <h4 className={classes.subHeader}>{`Let's get to work.`}</h4>
            <form  onSubmit={this.handleSubmit}>
            {
              this.state.errorMessage !== ""
              ?
              <Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>
              :
              null
            }

            <Grid item xs={12}>
              <Button href={'https://www.gomaxone.com/sports/all-request-demo/'}
              variant="contained" color="primary" className={classes.button} style={{ color: 'white' }}>
                {`I'm a coach`}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => this.props.history.push('/signup/athlete')}
              variant="contained" color="primary" className={classes.button}>
              {`I'm an athlete`}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => this.props.history.push('/signup/parent')}
              variant="contained" color="primary" className={classes.button}>
              {`I'm a parent`}
              </Button>
            </Grid>
            <Grid item xs={12} style={{marginTop:15}}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{marginTop:15}}>
              <Typography variant="subheading">
                 Looking to <a href="/login">login</a> instead?
               </Typography>
            </Grid>
            </form>
        </Grid>
      </Grid>
      </div>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signup);
