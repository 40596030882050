import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import { connect } from 'react-redux';
import _ from "lodash"
import { OAuthButton, initServices } from 'maxone-react-ui';
import Step1 from './seImportProcess/step1_selectRegistration';
import Step1CSV from './seImportProcess/step1_csvupload';
import Step2 from './seImportProcess/step2_editMapping';
import Step3 from "./seImportProcess/step3_athleteImportReview";
import Step4 from './seImportProcess/step4_selectContentLibrary';
import Step5 from './seImportProcess/step5_generateGroup';
import Step6 from './seImportProcess/step6_reviewAndInvite';
import Step7 from './seImportProcess/step7_final';
import Loader from '../../components/loaderWithMessage'; 
import { sportsEngineClient, userImportClient } from '../../clients'
import { ROUTE_PATH } from "../../routeUtil";
const styles = theme => ({
});
const COACH_STEPS = ["import", "mapping", "verify", "grouping", "invite", "summary"];
const DIRECTOR_STEPS=["import", "mapping", "verify", "contentLibrary", "grouping", "invite", "summary"];
class ImportFromSportsEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPageLoading: true,
            step: 1,
            isReviewScreenActive: true,
            isCSVProcess:false,
            //temp data
            registrations: [],
            registrationSelectedId: null,
            loadingMessage1:"Loading",
            loadingMessage2:"",
            importProcessId:"",
            //importProcessId:"ZMXTe-W-g"
        }
    }

    componentDidMount() {
        initServices();
        if(this.props.location.pathname == ROUTE_PATH.SE_IMPORT_CSV){
            this.setState({isCSVProcess: true, isPageLoading:false});
        } else {
            this.authorizeUser(()=>{
                this.setState({isPageLoading:false});
            });
        }
    }

    authorizeUser = (cb) => {
        let { accessToken } = this.props;
        if(!accessToken){
            this.reauthorizeUser();
        }
        else{
            sportsEngineClient.getUserByAccessToken(accessToken).then(({ data, status }) => {
                cb();
            }).catch((e)=>{
                this.reauthorizeUser();
            });
        }
    }

    reauthorizeUser = () => {
        sessionStorage.setItem("redirectUrl", window.location.pathname);
        this.inputElement.onClick();
    }

    discardAll = () => {
        let { importProcessId } = this.state;   
        //Let it be async
        userImportClient.deleteUserImportsByProcessId(importProcessId).then((data)=>{
            //success, no handling required
        }).catch((e) => {
            console.log(e)
        });
        this.setState({step:1});
    }

    loadRegistration = async (registrationId) => {
        let { accessToken, currentUser, currentTeam } = this.props;
        this.setState({isPageLoading:true, loadingMessage1:"Mapping Imported Data"});
        try{
            let { data } = await sportsEngineClient.importUsersByRosterId(registrationId, currentUser.id, (currentTeam||{}).organizationId, accessToken);
            this.setState({importProcessId:data.id, registrationSelectedId: registrationId})
            this.goToNextStep();
        }
        catch(e){

        }
        this.setState({isPageLoading:false, loadingMessage1:""});
     
    }    

    goToNextStep = () => {
        let { step } = this.state;
        this.setState({ step: step + 1 });
    }

    goBack = () => {
        let { step } = this.state;
        this.setState({ step: step - 1 });
    }

    importRecords = async () => {
        let { importProcessId } = this.state;
        this.setState({isPageLoading:true, loadingMessage1:"Importing Athletes"})
        try{
            await userImportClient.deleteIncompleteUserImportsByProcessId(importProcessId)
        }
        catch(e){
            console.log("Exception")
            console.log(e)
        }
        this.setState({isPageLoading:false, loadingMessage1:""})
        this.goToNextStep();
    }

    selectContentLibrary = (isGroupActive) => {
        this.setState({enableGroups: isGroupActive})
        this.goToNextStep();
    }


    importAndInvite = (inviteUser) => {
        this.setState({ userInviteSent: inviteUser });
        this.goToNextStep();
    }

    parseCSVFile = (data=[], fileInfo) => {
        let jsonArray = [];
        let columnData = data.shift();     
        (data||[]).forEach((element) => {         
            if(!!element && element.length && !(element.length == 1 && element[0] === "")){
                let jsonRowData = {};
                element.forEach((row, index) => {
                    jsonRowData[columnData[index]] = row;
                });
                jsonArray.push(jsonRowData);
            }
        });
        this.setState({csvFileName: fileInfo, csvJsonData: jsonArray});
    }
    
    uploadCSVFile = async () => {
        let { currentUser, currentTeam } = this.props;
        let { csvJsonData } = this.state; 
        try{
            this.setState({ isPageLoading: true, loadingMessage1:"Uploading CSV file" });
            var { data } = await userImportClient.createImportProcess(currentUser.id, (currentTeam||{}).organizationId);
            const importProcessId = data.id;
            this.setState({importProcessId});
            await fetch(data.uploadUrl, {
                method:"put",
                body: Buffer.from(JSON.stringify(csvJsonData)),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
           
        }
        catch(e){                    
            console.log(e);
        }
        finally{
            this.setState({ isPageLoading: false});
        }
        this.goToNextStep();
    }

    setGroupOrTeamCount = (athleteCount, groupOrTeamCount) => {
        this.setState({athleteImported:athleteCount, groupOrTeamCount:groupOrTeamCount})
    }

    setImportInviteType = (isInvite) => {
        this.setState({isInviteSent: isInvite})
    }


    enableCSVProcess = () => {
        this.props.history.push(ROUTE_PATH.SE_IMPORT_CSV);
        this.setState({isCSVProcess: true});
    }

    getCurrentStep = () => {
        const {  registrations,
            registrationSelectedId,
            step,
            mappingInfo,
            csvFileName, 
            isCSVProcess,
            importProcessId,
            enableGroups,
            athleteImported,
            groupOrTeamCount,
            isInviteSent
            } = this.state;
        const { accessToken, currentUser, currentTeam, isDirector } = this.props;
        const organizationId = (currentTeam||{}).organizationId;
        const stepsToBeUsed = isDirector ? DIRECTOR_STEPS:COACH_STEPS;
        const totalSteps = stepsToBeUsed.length - 2;
        const computedStep = stepsToBeUsed[step - 1];
        const isGroupEnabled = isDirector ? enableGroups: true;
        switch (computedStep) {
            case "import": if (isCSVProcess) {
                return (<Step1CSV
                    goBack={this.goBack}
                    parseCSVFile={this.parseCSVFile}
                    fileName={csvFileName}
                    uploadCSVFile={this.uploadCSVFile}
                    accessToken={accessToken}
                    currentStep={step} 
                    totalSteps={totalSteps}
                />)
            }
            else {
                return (<Step1
                    goBack={this.goBack}
                    registrations={registrations}
                    registrationSelectedId={registrationSelectedId}
                    handleRegistrationChange={this.handleRegistrationChange}
                    loadRegistration={this.loadRegistration}
                    accessToken={accessToken}
                    currentStep={step} 
                    totalSteps={totalSteps}
                    enableCSVProcess={this.enableCSVProcess}
                />)
            }
            case "mapping": return (<Step2
                importProcessId={importProcessId}
                goBack={this.goBack}         
                mappingInfo={mappingInfo}
                goToNextStep={this.goToNextStep}
                currentStep={step} 
                totalSteps={totalSteps}
            />);
            case "verify": return (<Step3
                goBack={this.goBack}
                importRecords={this.importRecords}
                importProcessId={importProcessId}
                organizationId={organizationId}
            />);
            case "contentLibrary": return (<Step4
                goBack={this.goBack}
                selectContentLibrary={this.selectContentLibrary}
                importProcessId={importProcessId}
                currentStep={step - 1} 
                totalSteps={totalSteps}
            />);
            case "grouping": return (<Step5 
                setGroupOrTeamCount={this.setGroupOrTeamCount}
                enableGroups={isGroupEnabled}
                goToNextStep={this.goToNextStep}        
                importProcessId={importProcessId}
                currentStep={step - 1} 
                totalSteps={totalSteps}
            />)
            case "invite": return (<Step6
                goToNextStep={this.goToNextStep}   
                importAndInvite={this.importAndInvite}
                athleteImported={athleteImported}
                groupOrTeamCount={groupOrTeamCount}
                isGroup={isGroupEnabled}    
                importProcessId={importProcessId}
                discardAll={this.discardAll}
                setImportInviteType={this.setImportInviteType}
                currentStep={step - 1} 
                totalSteps={totalSteps}
            />)
            case "summary": return (<Step7
                history={this.props.history}
                isInviteSent={false}
            />)
        }
    }


    render() {
        const { classes, tenant } = this.props;
        const { isPageLoading, loadingMessage, loadingMessage1, loadingMessage2, registrations, registrationSelectedId, step, mappingInfo, isReviewScreenActive, isCSVProcess } = this.state;
        const currentStepJsx = this.getCurrentStep();
        return (
            <PageWrapper title={isCSVProcess? "Import from CSV":"Import from SportsEngine"} removePadding={true} {...this.props}>
                <div className={'importContainer'}>
                    {isPageLoading
                        ?
                       <Loader message={loadingMessage1} message2={loadingMessage2} />
                        :
                        <React.Fragment>
                            {currentStepJsx}
                        </React.Fragment>
                    }                
                </div>
                <OAuthButton
                    width={0}
                    height={0}
                    ref={input => { console.log(input); this.inputElement = input}}
                    text={''}
                    tenant={tenant}                       
                />
            </PageWrapper>
        )

    }
}

function mapStateToProps(state) {    
    const { session: { accessToken, userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        accessToken: accessToken,
        currentUser: userContext.user,
        currentTeam: currentTeam||{},
        isDirector:!!appContext  && (appContext.isOwner || appContext.isHeadcoach || appContext.isAdmin)
    };
}


export default withStyles(styles)(connect(mapStateToProps)(ImportFromSportsEngine));


