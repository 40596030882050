import React from "react";
import { Grid } from "@material-ui/core";
import { HuePicker } from "react-color";
import { withStyles } from "@material-ui/core/styles";
import ShadowContainer from "../../../components/shadowContainer";
import FloatingInput from "../../../components/floatingInput";
import { colors } from "../../../components/v2/utils/theme-utils";
import { PrimaryButton, BorderedButton } from "../../../components/v2/buttons";

const viewOptions = {
  createTag: "createTag",
  addTeam: "addTeam",
};

const styles = (theme) => ({
  showContainer: {
    borderRadius: 5,
    padding: 35,
  },
  errorMessage: {
    color: colors.ERROR,
    fontSize: 12,
  },
  nameTextField: {
    height: 49,
    fontSize: 16,
  },
  nameFocused: {
    transform: "translate(0, 1.5px) scale(0.50);",
    top: "8px",
  },
  nameInput: {
    fontSize: 16,
  },
  nameFieldLabel: {
    fontSize: 16,
  },
  addTeamBtn: {
    width: "20%",
    height: 52,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.isDarkMode ? "#ffffff" : "black",
  },
  tagContainer: {
    width: "100%",
    padding: "10px 0px",
    flexWrap: "wrap",
    overflow: "visible",
  },
  createTag: {
    color: `${colors.PRIMARY} !important`,
    fontSize: 12,
    cursor: "pointer",
  },
  huePicker: {
    borderRadius: 25,
  },
});

const CreateTag = (props) => {
  const {
    handleClose,
    errors,
    name,
    color,
    handleChange,
    handleSubmit,
    classes,
  } = props;
  return (
    <ShadowContainer className={classes.showContainer}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <FloatingInput
            label="Tag name"
            className={"textField"}
            value={name || ""}
            error={errors?.name}
            onChange={(e) => handleChange("name", e.target.value)}
            overrideContainerCss={classes.nameTextField}
            overrideInputCSS={classes.nameInput}
            overrideNameLabelRootCss={classes.nameFieldLabel}
            overideLabelFocusCss={classes.nameFocused}
          />
           {errors?.name?.length > 0 && (
            <span className={classes.errorMessage}>{errors?.name}</span>
          )}
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between">
            <p
              style={{
                marginBottom: 20,
                fontSize: 12,
                color: colors.GREY_40,
              }}
            >
              Pick color
            </p>
            <div
              style={{
                backgroundColor: color,
                width: 22,
                height: 22,
                borderRadius: 11,
              }}
            ></div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <HuePicker
            color={color}
            width={"100%"}
            height={18}
            styles={{
              default: {
                hue: {
                  radius: "9px",
                  height: "20px",
                },
              },
            }}
            className={classes.huePicker}
            onChangeComplete={({ hex }) => {
              handleChange("color", hex);
            }}
          />
        </Grid>
        <Grid item xs={12} className={"d-flex justify-content-center"}>
          <BorderedButton
            onClick={handleClose}
            style={{
              height: 37,
            }}
          >
            Cancel
          </BorderedButton>
          <PrimaryButton
            onClick={handleSubmit}
            style={{ height: 39, marginLeft: 20 }}
          >
            Create Tag
          </PrimaryButton>
        </Grid>
      </Grid>
    </ShadowContainer>
  );
};
export default withStyles(styles)(CreateTag);
