import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { LoadingPage } from '../../../../components/v2/containers';
import { PencilIcon, CheckmarkIcon } from '../../../../components/v2/icons';
import { Cancel } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

const RSPCT_LOGO = require('../../../../assets/images/bts/rspct-logo.png');
const KINEXON_LOGO = require('../../../../assets/images/bts/kinexon-logo.png');

const ElementContainer = (
    {
        service,
        serviceLabel,
        linkId,
        idLabel,
        headerImage,
        editable = true,
        loading,
        onEditClick,
        onChange,
        onSaveClick,
        onCancelClick
    }
) => {
    console.log(service);
    return (
        <div className={'bts-element-container'}>
            {headerImage && (
                <div className={'bts-element-header-image-container'}>
                    <img src={headerImage} />
                </div>
            )}
            {service && (
                <div className={'bts-element-value-container'}>
                    {(service && service.editing) ? (
                        <div className={'bts-element-value-text'}>
                            <div className={'row no-gutters align-items-center'}>
                                <div className={'col-3'}>

                                </div>
                                <div className={'col-6'}>
                                    <input value={service.serviceUserId} onChange={onChange} />
                                </div>
                                <div className={'col-3'}>
                                    <div className={'row no-gutters align-items-center justify-content-around'}>
                                        <div className={'col-auto'}>
                                            <CheckmarkIcon className={'checkmark-icon'} onClick={onSaveClick} />
                                        </div>
                                        <div className={'col-auto'}>
                                            <div className={'cancel-button-container'} onClick={onCancelClick}>
                                                <Cancel className={'cancel-icon'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={'bts-element-value-text bts-element-value-text--static'}>
                            {!service.loading && (
                                <>
                                    {service.serviceUserId || '-----'}
                                    {editable && (
                                        <PencilIcon className={'pencil-icon'} onClick={onEditClick} />
                                    )}
                                </>
                            )}
                            {service.loading && (
                                <div className={'service-loader-container'}>
                                    <CircularProgress size={25} />
                                </div>
                            )}
                        </div>
                    )}
                    <div className={'bts-element-subvalue-text'}>
                        {`${serviceLabel} ID`}
                    </div>
                </div>
            )}
            <div className={'bts-element-value-container bts-link-id-container'}>
                <div className={'bts-element-value-text'}>
                    {linkId || '-----'}
                </div>
                <div className={'bts-element-subvalue-text'}>
                    {idLabel}
                </div>
            </div>
        </div>
    );
}

const Component = (
    {
        loading,
        error,
        data: { services: { results: services = [] } = {} },
        onEditClick,
        onSaveClick,
        onCancelClick,
        onChange
    }
) => {

    const kinexon = services.find(({ service }) => service.toUpperCase() === 'KINEXON');
    const rspct = services.find(({ service }) => service.toUpperCase() === 'RSPCT') || { serviceUserId: '-----' };
    const { meta: { linkId } = {} } = kinexon || { };

    return (
        <div className={'user-profile-bts-tab-view'}>
            <LoadingPage loading={loading} error={error}>
                <div className={'bts-title-text'}>
                    Technology Configuration
                </div>
                <div className={'row'}>
                    <div className={'col-auto'}>
                        <ElementContainer
                            service={kinexon}
                            serviceLabel={'Kinexon'}
                            linkId={linkId}
                            idLabel={'League ID'}
                            editable={false}
                            headerImage={KINEXON_LOGO}
                            onEditClick={() => onEditClick('KINEXON')}
                            onSaveClick={() => onSaveClick('KINEXON')}
                            onCancelClick={() => onCancelClick('KINEXON')}
                            onChange={({ target: { value } }) => onChange('KINEXON', value)}
                        />
                    </div>
                    <div className={'col-auto'}>
                        <ElementContainer
                            service={rspct}
                            serviceLabel={'RSPCT'}
                            linkId={linkId}
                            idLabel={'Tech ID'}
                            headerImage={RSPCT_LOGO}
                            onEditClick={() => onEditClick('RSPCT')}
                            onSaveClick={() => onSaveClick('RSPCT')}
                            onCancelClick={() => onCancelClick('RSPCT')}
                            onChange={({ target: { value } }) => onChange('RSPCT', value)}
                        />
                    </div>
                    <div className={'col-lg'}>
                        <div className={'bts-element-container bts-directions-container'}>
                            <div className={'bts-element-title'}>
                                Directions
                            </div>
                            <div className={'bts-element-subtitle'}>
                                Step 1: Create RSPCT Account
                            </div>
                            <div className={'bts-element-directions'}>
                                Go to the RSPCT dashboard and create the RSPCT account for the new athlete.
                            </div>
                            <div className={'bts-element-subtitle'}>
                                Step 2: Connect RSPCT to Kinexon
                            </div>
                            <div className={'bts-element-directions'}>
                                In the RSPCT dashboard, set the RSPCT Tech ID for the athlete so that it matches the Kinexon League ID.
                            </div>
                            <div className={'bts-element-subtitle'}>
                                Step 3: Connect MaxOne to RSPCT
                            </div>
                            <div className={'bts-element-directions'}>
                                In the RSPCT dashboard, find the RSPCT ID and add it to the RSPCT ID field to the left.
                            </div>
                            <div className={'bts-element-subtitle'}>
                                Configuration Completed!
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingPage>
        </div>
    )
};

Component.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.shape({
        services: PropTypes.arrayOf(PropTypes.shape({ }))
    })
};

Component.defaultProps = {
    loading: false,
    error: false,
    data: { }
};

export default Component;
