import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { API } from "aws-amplify";
import _ from "lodash"
import moment from "moment"

import { withStyles } from '@material-ui/core/styles';

import logo from "../../assets/images/logo-dark.png"

import "./notifications.css";
import PageWrapper from '../nav/pageWrapper';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: "white",
  },
  nested: {
    paddingLeft: 2
  },
});

const classes = styles();

function TabContainer({ children }) {
    return (
      <Typography component="div" style={{padding: 15}}>
        {children}
      </Typography>
    );
  }

class AdminReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            reports:[],
            report:{},
            payout:{},
            balanceSummaryTotal:0,
            chargeTabValue:0,
            refundTabValue:0,
            transferTabValue:0,
            transferReversalTabValue:0,
            balanceSummaryOpen: true,
            payoutsOpen: false,
            chargesOpen: false,
            refundsOpen: false,
            transfersOpen: false,
            transferReversalsOpen: false,
            topupsOpen: false,
            showPayoutModal: false,
            showChargeModal: false,
            showRefundModal: false,
            showTransferModal: false,
            showTransferReversalsModal: false,
            charge: {},
            refund: {},
            transfer: {},
            transferReversal: {},
            chargesGroupedByOrganization: {},
            refundsGroupedByOrganization: {},
            transfersGroupedByOrganization: {},
            totalRefundsByOrganization: {},
            totalTransfersByOrganization: {},
            chargesExpanded: {},
            refundsExpanded: {},
            transfersExpanded: {},
            transferReversalsExpanded: {},
        };
    }

    async componentDidMount(){
        // console.log('admin push notifications did mount');
        this.setState({isLoading: true})
        let reports = await this.getAllReports();
        // console.log('reports', reports)
        let report = await this.getFullReport(reports[0].id);
        // console.log('report ', report)
        await this.refreshReportData(report)

        this.setState({reports, report})
        this.setState({isLoading: false})
    }

    hideSnackBar(){
        setTimeout(() => this.setState({open:false}), 2000);
    }

    async getAllReports(){
        return API.get("reports", "/reports")
    }
    async getFullReport(id){
        return API.get("reports", `/reports/${id}/full`)
    }

    async refreshReportData(report){
      let balanceSummaryTotal = 0;
      // Object.keys(report.balanceSummary).map(key =>{
        balanceSummaryTotal = balanceSummaryTotal + parseFloat(report.balanceSummary['starting_balance'].net_amount) +
        parseFloat(report.balanceSummary['activity_gross'].net_amount) +
        parseFloat(report.balanceSummary['activity_fee'].net_amount) +
        parseFloat(report.balanceSummary['payouts_gross'].net_amount) +
        parseFloat(report.balanceSummary['payouts_fee'].net_amount)
      // })

      // group charges by organizationName
      let chargesGroupedByOrganization = {}
      let totalChargesByOrganization = {}
      let chargesExpanded = {}
      _.map(report.charges, charge => {
        if(!charge.organizationName)charge.organizationName = "No Organization";
        if(!chargesGroupedByOrganization[charge.organizationName])chargesGroupedByOrganization[charge.organizationName] = []
        if(!totalChargesByOrganization[charge.organizationName])totalChargesByOrganization[charge.organizationName] = 0
        chargesGroupedByOrganization[charge.organizationName].push(charge)
        chargesExpanded[charge.organizationName] = false
        totalChargesByOrganization[charge.organizationName] = totalChargesByOrganization[charge.organizationName] + parseFloat(charge.net)
      })
      // group refunds by organizationName
      let refundsGroupedByOrganization = {}
      let totalRefundsByOrganization = {}
      let refundsExpanded = {}
      _.map(report.refunds, refund => {
        if(!refund.organizationName)refund.organizationName = "No Organization";
        if(!refundsGroupedByOrganization[refund.organizationName])refundsGroupedByOrganization[refund.organizationName] = []
        if(!totalRefundsByOrganization[refund.organizationName])totalRefundsByOrganization[refund.organizationName] = 0
        refundsGroupedByOrganization[refund.organizationName].push(refund)
        refundsExpanded[refund.organizationName] = false
        totalRefundsByOrganization[refund.organizationName] = totalRefundsByOrganization[refund.organizationName] + parseFloat(refund.net)
      })
      // group transfers by organizationName
      let transfersGroupedByOrganization = {}
      let totalTransfersByOrganization = {}
      let transfersExpanded = {}
      _.map(report.transfers, transfer => {
        if(!transfer.organizationName)transfer.organizationName = "No Organization";
        if(!transfersGroupedByOrganization[transfer.organizationName])transfersGroupedByOrganization[transfer.organizationName] = []
        if(!totalTransfersByOrganization[transfer.organizationName])totalTransfersByOrganization[transfer.organizationName] = 0
        transfersGroupedByOrganization[transfer.organizationName].push(transfer)
        transfersExpanded[transfer.organizationName] = false
        totalTransfersByOrganization[transfer.organizationName] = totalTransfersByOrganization[transfer.organizationName] + parseFloat(transfer.net)
      })

      this.setState({report, balanceSummaryTotal, chargesGroupedByOrganization, chargesExpanded,
        refundsGroupedByOrganization, refundsExpanded, totalRefundsByOrganization,
        transfersGroupedByOrganization, transfersExpanded, totalTransfersByOrganization, totalChargesByOrganization})
    }
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleExpandCharge = key => {
        let chargesExpanded = this.state.chargesExpanded;
        chargesExpanded[key] = !this.state.chargesExpanded[key]
        this.setState({chargesExpanded});
    };
    handleExpandRefund = key => {
        let refundsExpanded = this.state.refundsExpanded;
        refundsExpanded[key] = !this.state.refundsExpanded[key]
        this.setState({refundsExpanded});
    };
    handleExpandTransfer= key => {
        let transfersExpanded = this.state.transfersExpanded;
        transfersExpanded[key] = !this.state.transfersExpanded[key]
        this.setState({transfersExpanded});
    };
    handleExpandTransferReversal= key => {
        let transferReversalsExpanded = this.state.transferReversalsExpanded;
        transferReversalsExpanded[key] = !this.state.transferReversalsExpanded[key]
        this.setState({transferReversalsExpanded});
    };

    handleReportChange = async event => {
        this.setState({isLoading: true})
        console.log('event ', event)
        let transfersGroupedByOrganization = {}
        let totalTransfersByOrganization = {}
        let transfersExpanded = {}
        let report = await this.getFullReport(event.target.value)
        await this.refreshReportData(report)
        this.setState({isLoading: false, report})
    };

    handleClick = () => {
      this.setState({open: !this.state.open});
    };

    showPayout = (payout) => {
      this.setState({payout: payout, showPayoutModal: true})
    };
    showCharge = (charge) => {
      this.setState({charge: charge, showChargeModal: true})
    };
    showRefund = (refund) => {
      this.setState({refund, showRefundModal: true})
    };
    showTransfer = (transfer) => {
      this.setState({transfer, showTransferModal: true})
    };
    showTransferReversals = (transferReversal) => {
      this.setState({transferReversal, showTransferReversalsModal: true})
    };
    handleClosePayout= (event) => {
      this.setState({payout: {}, showPayoutModal: false})
    };
    handleCloseCharge= (event) => {
      this.setState({charge: {}, showChargeModal: false})
    };
    handleCloseRefund= (event) => {
      this.setState({refund: {}, showRefundModal: false})
    };
    handleCloseTransfer= (event) => {
      this.setState({transfer: {}, showTransferModal: false})
    };
    handleCloseTransferReversals= (event) => {
      this.setState({transferReversal: {}, showTransferReversalsModal: false})
    };
    handleChargeTabChange= (event, newValue) => {
      this.setState({chargeTabValue: newValue})
    };
    handleRefundTabChange= (event, newValue) => {
      this.setState({refundTabValue: newValue})
    };
    handleTransferTabChange= (event, newValue) => {
      this.setState({transferTabValue: newValue})
    };
    transferReversalsTabChange= (event, newValue) => {
      this.setState({transferReversalTabValue: newValue})
    };

    render() {
        const { classes, theme } = this.props;
        const { open, report, reports, isLoading,
          chargeTabValue, charge, chargesOpen, showChargeModal, chargesExpanded, chargesGroupedByOrganization, totalChargesByOrganization,
          refundTabValue, refund, refundsOpen, showRefundModal, refundsExpanded, refundsGroupedByOrganization, totalRefundsByOrganization,
          transferTabValue, transfer, transfersOpen, showTransferModal, transfersExpanded, transfersGroupedByOrganization, totalTransfersByOrganization,
          transferReversalTabValue, transferReversals, transferReversalsOpen = false, showTransferReversalModal, transferReversalsExpanded,
          payout, payoutsOpen, showPayoutModal,
          topupsOpen,
          balanceSummaryTotal, balanceSummaryOpen } = this.state;

        return (
            <PageWrapper title={"Stripe Reports"} isPageLoading={isLoading} loadingMessage={"loading report..."}>
              <div className={classes.root}>
              <Grid container spacing={24}>
                  <Grid item xs={12} sm={12} style={{textAlign:"right", marginRight:10}}>
                  <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={report.id}
                     onChange={this.handleReportChange}
                     style={{width:250, textAlign:"center"}}
                   >
                   {reports.map(r => {
                     return(<MenuItem key={r.id} value={r.id}>{r.description}</MenuItem>)
                    })
                   }
                   </Select>
                  </Grid>
              </Grid>
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                  <Grid item xs={12} sm={12}>
                  <h3>{report.description} ({moment.utc(report.interval_start * 1000).format("YYYY-MM-DD")} - {moment.utc(report.interval_end * 1000).subtract(1, 'd').format("YYYY-MM-DD")})</h3>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="default" target="_blank" href={report.stripe_payout_link}>Stripe Payout Summary CSV</Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="default" target="_blank" href={report.stripe_balance_summary_link}>Stripe Balance Summary CSV</Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="default" target="_blank" href={report.stripe_balance_change_link}>Stripe Balance Change CSV</Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="default" target="_blank" href={`https://dashboard.stripe.com/reports/balance?startDate=${moment.utc(report.interval_start * 1000).format("YYYY-MM-DD")}&endDate=${moment.utc(report.interval_end * 1000).subtract(1, 'd').format("YYYY-MM-DD")}&currency=usd&templateType=merchant&timezone=US%2FEastern`}>Stripe Report Dashboard</Button>
                  </Grid>
              </Grid>
              <Divider style={{width:"100%"}} />
              {
                ///  =====  BALANCE SUMMARY ====
              }
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                  <Grid item xs={12} sm={12} onClick={() => this.setState({balanceSummaryOpen: !balanceSummaryOpen})} style={{marginBottom:15}}>
                    <h4>Balance Summary   {balanceSummaryOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                  </Grid>
                  <Divider style={{width:"100%"}}/>
                  <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                    <Grid item xs={12} sm={12}>
                      Starting Balance: <b>{report.balanceSummary && report.balanceSummary.starting_balance.net_amount ? report.balanceSummary.starting_balance.net_amount :" "}</b>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      Remaining Balance: <b>{report.balanceSummary && report.balanceSummary.ending_balance.net_amount ? report.balanceSummary.ending_balance.net_amount : " "}</b>
                    </Grid>
                  </Grid>
                  <Divider style={{width:"100%"}}/>
                  <Collapse in={balanceSummaryOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
                  <List
                     component="nav"
                     aria-labelledby="nested-list-subheader"
                     subheader={
                       <ListSubheader component="div" id="nested-list-subheader">
                       </ListSubheader>
                     }
                     className={classes.root}
                   >
                   {
                     report.balanceSummary && Object.keys(report.balanceSummary).map(key => {
                       return(
                         <div key={report.balanceSummary[key].category} >
                           <ListItem button key={report.balanceSummary[key].category} onClick={this.showMoreInfoBalanceSummary}>
                             <ListItemText primary={`${report.balanceSummary[key].description} (${report.balanceSummary[key].category})`} />
                             <ListItemText style={{textAlign:"right"}}primary={`${report.balanceSummary[key].net_amount}`} />
                           </ListItem>
                           <Divider style={{width:"100%"}}/>
                         </div>
                       )
                     })
                   }
                  </List>
                  <Grid item xs={12} sm={12} style={{textAlign:"right", marginRight:20, marginBottom:15, marginTop:10}}>
                    Total (starting_balance + activity_gross + activity_fee + payouts_gross + payouts_fee ): <b>{(Math.round((balanceSummaryTotal + Number.EPSILON) * 100) / 100).toFixed(2)}</b>
                  </Grid>
                  <Divider style={{width:"100%"}}/>
                  </Collapse>
              </Grid>
              {
                ///  =====  END BALANCE SUMMARY ====
                ///  =====  CHARGES ====
              }
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                  <Grid item xs={12} sm={12} onClick={() => this.setState({chargesOpen: !chargesOpen})}>
                    <h4>Charges   {chargesOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                  </Grid>
              </Grid>
              <Divider style={{width:"100%"}}/>
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                <Grid item xs={12} sm={12}>
                  Total Charges: <b>{report.totals ? (Math.round((report.totals.charges.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
              </Grid>
              <Divider style={{width:"100%"}}/>
              <Collapse in={chargesOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
              <List
                 component="nav"
                 aria-labelledby="nested-list-subheader"
                 subheader={
                   <ListSubheader component="div" id="nested-list-subheader">
                   </ListSubheader>
                 }
                 className={classes.root}
               >
               {
                 chargesGroupedByOrganization && Object.keys(chargesGroupedByOrganization).map(key => {
                   return(
                     <div key={key}>
                       <ListItem button key={key} onClick={() => this.handleExpandCharge(key)}>
                         <ListItemText primary={`${key}`} />
                         <ListItemText style={{textAlign:"right"}} primary={<b>{(Math.round((totalChargesByOrganization[key] + Number.EPSILON) * 100) / 100).toFixed(2)}</b>} />
                         {chargesExpanded[key] ? <ExpandLess /> : <ExpandMore />}
                       </ListItem>
                       <Divider style={{width:"100%"}} />
                       <Collapse in={chargesExpanded[key]} timeout="auto" unmountOnExit style={{width:"100%"}}>
                       {
                         chargesGroupedByOrganization[key] && chargesGroupedByOrganization[key].map(charge => {
                           return(
                             <div key={charge.balance_transaction_id}>
                               <ListItem button key={charge.balance_transaction_id} onClick={() => this.showCharge(charge)}>
                                 <ListItemText primary={`${charge.charge_created_utc.split(' ')[0]} - ${charge.description} - ${charge.customer_description ? charge.customer_description : charge.customer_email}`} />
                                 <ListItemText style={{textAlign:"right"}} primary={`${charge.net}`} />
                               </ListItem>
                               <Divider style={{width:"100%"}} />
                            </div>
                           )
                         })
                      }
                       </Collapse>
                     </div>
                   )
                 })
               }
               </List>
              </Collapse>
              {
                ///// ===== END CHARGES ======
                ///// ===== REFUNDS ======
              }
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                  <Grid item xs={12} sm={12} onClick={() => this.setState({refundsOpen: !refundsOpen})}>
                    <h4>Refunds   {refundsOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                  </Grid>
              </Grid>
              <Divider style={{width:"100%"}}/>
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                <Grid item xs={12} sm={12}>
                  Total Refunds: <b>{report.totals ? (Math.round((report.totals.refunds.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
              </Grid>
              <Divider style={{width:"100%"}}/>
              <Collapse in={refundsOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
              <List
                 component="nav"
                 aria-labelledby="nested-list-subheader"
                 subheader={
                   <ListSubheader component="div" id="nested-list-subheader">
                   </ListSubheader>
                 }
                 className={classes.root}
               >
               {
                 refundsGroupedByOrganization && Object.keys(refundsGroupedByOrganization).map(key => {
                   return(
                     <div key={key}>
                       <ListItem button key={key} onClick={() => this.handleExpandRefund(key)}>
                         <ListItemText primary={`${key}`} />
                         <ListItemText style={{textAlign:"right"}} primary={<b>{(Math.round((totalRefundsByOrganization[key] + Number.EPSILON) * 100) / 100).toFixed(2)}</b>} />
                         {refundsExpanded[key] ? <ExpandLess /> : <ExpandMore />}
                       </ListItem>
                       <Divider style={{width:"100%"}} />
                       <Collapse in={refundsExpanded[key]} timeout="auto" unmountOnExit style={{width:"100%"}}>
                       {
                         refundsGroupedByOrganization[key] && refundsGroupedByOrganization[key].map(refund => {
                           return(
                             <div key={refund.balance_transaction_id}>
                               <ListItem button key={refund.balance_transaction_id} onClick={() => this.showRefund(refund)}>
                                 <ListItemText primary={`${refund.created_utc.split(' ')[0]} - ${refund.description} - ${refund.customer_description}`} />
                                 <ListItemText style={{textAlign:"right"}} primary={`${refund.net}`} />
                               </ListItem>
                               <Divider style={{width:"100%"}} />
                            </div>
                           )
                         })
                      }
                       </Collapse>
                     </div>
                   )
                 })
               }
               </List>
              </Collapse>
              {
                /////// === END REFUNDS  =======
                ///// ===== Transfers ======
              }
              {
                report.totals && report.totals.transfers.net ?
                <div>
                <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                    <Grid item xs={12} sm={12} onClick={() => this.setState({transfersOpen: !transfersOpen})}>
                      <h4>Transfers   {transfersOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                    </Grid>
                </Grid>
                <Divider style={{width:"100%"}}/>
                <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                  <Grid item xs={12} sm={12}>
                    Total Transfers: <b>{report.totals ? (Math.round((report.totals.transfers.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b> {report.totals && report.totals.transferReversals.net ? (` ( Transfers Reversals: ${report.totals ? (Math.round((report.totals.transferReversals.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}.  Net: ${ report.totals ? (Math.round(((report.totals.transferReversals.net +report.totals.transfers.net)  + Number.EPSILON) * 100) / 100).toFixed(2) :""} )`) : ""}
                  </Grid>
                </Grid>
                <Divider style={{width:"100%"}}/>
                <Collapse in={transfersOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
                <List
                   component="nav"
                   aria-labelledby="nested-list-subheader"
                   subheader={
                     <ListSubheader component="div" id="nested-list-subheader">
                     </ListSubheader>
                   }
                   className={classes.root}
                 >
                 {
                   transfersGroupedByOrganization && Object.keys(transfersGroupedByOrganization).map(key => {
                     return(
                       <div key={key}>
                         <ListItem button key={key} onClick={() => this.handleExpandTransfer(key)}>
                           <ListItemText primary={`${key}`} />
                           <ListItemText style={{textAlign:"right"}} primary={<b>{(Math.round((totalTransfersByOrganization[key] + Number.EPSILON) * 100) / 100).toFixed(2)}</b>} />
                           {transfersExpanded[key] ? <ExpandLess /> : <ExpandMore />}
                         </ListItem>
                         <Divider style={{width:"100%"}} />
                         <Collapse in={transfersExpanded[key]} timeout="auto" unmountOnExit style={{width:"100%"}}>
                         {
                           transfersGroupedByOrganization[key] && transfersGroupedByOrganization[key].map(transfer => {
                             return(
                               <div key={transfer.balance_transaction_id} >
                                 <ListItem button key={transfer.balance_transaction_id} onClick={() => this.showTransfer(transfer)}>
                                   <ListItemText primary={`${transfer.created_utc.split(' ')[0]} - ${transfer.description}`} />
                                   <ListItemText style={{textAlign:"right"}} primary={`${transfer.net}`} />
                                 </ListItem>
                                 <Divider style={{width:"100%"}} />
                              </div>
                             )
                           })
                        }
                         </Collapse>
                       </div>
                     )
                   })
                 }
                 </List>
                </Collapse>
              </div>
                : null
              }
              {
                /////// === END TRANSFERS  =======
                ///// ===== Transfer Reversals ======
              }
              {
                report.totals && report.totals.transferReversals.net ?
                <div>
                  <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                      <Grid item xs={12} sm={12} onClick={() => this.setState({transferReversalsOpen: !transferReversalsOpen})}>
                        <h4>Transfers Reversals  {transferReversalsOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                      </Grid>
                  </Grid>
                  <Divider style={{width:"100%"}}/>
                  <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                    <Grid item xs={12} sm={12}>
                      Total Transfers Reversals: <b>{report.totals ? (Math.round((report.totals.transferReversals.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                    </Grid>
                  </Grid>
                  <Divider style={{width:"100%"}}/>
                  <Collapse in={transferReversalsOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
                  <List
                     component="nav"
                     aria-labelledby="nested-list-subheader"
                     subheader={
                       <ListSubheader component="div" id="nested-list-subheader">
                       </ListSubheader>
                     }
                     className={classes.root}
                   >
                     {
                       report.transferReversals && report.transferReversals.map(transferReversal => {
                         return(
                           <div key={transferReversal.balance_transaction_id} >
                             <ListItem button key={transferReversal.balance_transaction_id} onClick={() => this.showTransfer(transferReversal)}>
                               <ListItemText primary={`${transferReversal.created_utc.split(' ')[0]} - ${transferReversal.description}`} />
                               <ListItemText style={{textAlign:"right"}} primary={`${transferReversal.net}`} />
                             </ListItem>
                             <Divider style={{width:"100%"}} />
                          </div>
                         )
                       })
                    }
                   </List>
                  </Collapse>
                </div>
                : null
              }
              {
                /////// === END TRANSFERS Reversals  =======
                ////// ==== PAYOUTS ======
              }
              {
                report.totals && report.totals.payouts.net ?
                <div>
                <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                    <Grid item xs={12} sm={12} onClick={() => this.setState({payoutsOpen: !payoutsOpen})}>
                      <h4>Payouts   {payoutsOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                    </Grid>
                </Grid>
                <Divider style={{width:"100%"}}/>
                <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                  <Grid item xs={12} sm={12}>
                  Total Payouts: <b>{report.totals ? report.totals.payouts.net :""}</b>
                  </Grid>
                </Grid>
                <Divider style={{width:"100%"}}/>
                <Collapse in={payoutsOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
                <List
                   component="nav"
                   aria-labelledby="nested-list-subheader"
                   subheader={
                     <ListSubheader component="div" id="nested-list-subheader">
                     </ListSubheader>
                   }
                   className={classes.root}
                 >
                 {
                   report.payouts && report.payouts.map(payout => {
                     return(
                       <div key={payout.payout_id}>
                         <ListItem button key={payout.payout_id} onClick={() => this.showPayout(payout)}>
                           <ListItemText primary={`${payout.effective_at_utc.split(' ')[0]} ${payout.description}`} />
                           <ListItemText style={{textAlign:"right"}} primary={`${payout.net}`} />
                         </ListItem>
                         <Divider style={{width:"100%"}} />
                       </div>
                     )
                   })
                 }
                 </List>
                </Collapse>
                </div>
                :null
              }
              {
                /////// ===== END PAYOUTS =====
                ////// ==== topups ======
              }
              {
                report.totals && report.totals.topups.net ?
                <div>
                <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15}}>
                    <Grid item xs={12} sm={12} onClick={() => this.setState({topupsOpen: !topupsOpen})}>
                      <h4>TopUps   {topupsOpen ? <ExpandLess /> : <ExpandMore />}</h4>
                    </Grid>
                </Grid>
                <Divider style={{width:"100%"}}/>
                <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15}}>
                  <Grid item xs={12} sm={12}>
                  Total TopUps: <b>{report.totals ? report.totals.topups.net :""}</b>
                  </Grid>
                </Grid>
                <Divider style={{width:"100%"}}/>
                <Collapse in={topupsOpen} timeout="auto" unmountOnExit style={{width:"100%"}}>
                <List
                   component="nav"
                   aria-labelledby="nested-list-subheader"
                   subheader={
                     <ListSubheader component="div" id="nested-list-subheader">
                     </ListSubheader>
                   }
                   className={classes.root}
                 >
                 {
                   report.topups && report.topups.map(topup => {
                     return(
                       <div key={topup.balance_transaction_id}>
                         <ListItem button key={topup.balance_transaction_id} onClick={()=> window.open(`https://dashboard.stripe.com/topups/${topup.source_id}`, "_blank")}>
                           <ListItemText primary={`${topup.created_utc.split(' ')[0]} ${topup.description}`} />
                           <ListItemText style={{textAlign:"right"}} primary={`${topup.net}`} />
                         </ListItem>
                         <Divider style={{width:"100%"}} />
                       </div>
                     )
                   })
                 }
                 </List>
                </Collapse>
                </div>
                :null
              }
              {
                /////// ===== END topups =====
              }
              <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, marginTop:15, textAlign:"right"}}>
                <Grid item xs={12} sm={12} style={{marginRight:100}}>
                Totals:
                </Grid>
                <Grid item xs={12} sm={12}>
                  Charges: <b>{report.totals ? (Math.round((report.totals.charges.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
                <Grid item xs={12} sm={12}>
                  Refunds: <b>{report.totals ? (Math.round((report.totals.refunds.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
                <Grid item xs={12} sm={12}>
                  Transfers: <b>{report.totals ? (Math.round((report.totals.transfers.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
                <Grid item xs={12} sm={12}>
                  Transfer Reversals: <b>{report.totals ? (Math.round((report.totals.transferReversals.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
                {
                  report.totals && report.totals.topups.net ?
                  <Grid item xs={12} sm={12}>
                    TopUps: <b>{report.totals ? (Math.round((report.totals.topups.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                  </Grid>
                  :
                  null
                }
                <Grid item xs={12} sm={12}>
                  Payouts: <b>{report.totals ? (Math.round((report.totals.payouts.net + Number.EPSILON) * 100) / 100).toFixed(2) :""}</b>
                </Grid>
                <Grid item xs={12} sm={12}>
                  Starting Balance: <b>{report.balanceSummary && report.balanceSummary.starting_balance.net_amount ? report.balanceSummary.starting_balance.net_amount  :""}</b>
                </Grid>
                <Grid item xs={12} sm={12}>
                  Ending Balance: <b>{report.balanceSummary && report.balanceSummary.ending_balance.net_amount ? report.balanceSummary.ending_balance.net_amount  :""}</b>
                </Grid>
                <Grid item xs={12} sm={12}>
                  Charges + Refunds + Transfers{report.transferReversals && report.totals.transferReversals.net ? ' + Transfer Reversals' : ''} {report.totals && report.totals.topups.net ? ' + TopUps' : ''} - Payouts + Starting Balance - Ending Balance: <b>{report.totals && report.balanceSummary ?
                    ((Math.round(((Math.round((report.totals.charges.net + Number.EPSILON) * 100) / 100) + (Math.round((report.totals.refunds.net + Number.EPSILON) * 100) / 100) + (Math.round((report.totals.transfers.net + Number.EPSILON) * 100) / 100) + (Math.round((report.totals.topups.net + Number.EPSILON) * 100) / 100) + (Math.round((report.totals.transferReversals.net + Number.EPSILON) * 100) / 100) - (Math.round((report.totals.payouts.net + Number.EPSILON) * 100) / 100) + parseFloat(report.balanceSummary.starting_balance.net_amount) - parseFloat(report.balanceSummary.ending_balance.net_amount)) + Number.EPSILON) * 100) / 100).toFixed(2)
                     :""}</b>
                </Grid>
              </Grid>
              <Modal
                open={showPayoutModal}
                onClose={this.handleClosePayout}
                onBackdropClick={this.handleClosePayout}
                onEscapeKeyDown={this.handleClosePayout}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
              <Grid container spacing={16} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'100vh', width:'100vw', justifyContent:"center", alignItems:"center"}}>
                <Grid item xs={10}>
                  <div className={classes.root}>
                    <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'60vh', minHeight:500, }}>
                      <Grid item xs={12} sm={12} onClick={() => this.setState({payoutsOpen: !payoutsOpen})}>
                        <h3>Payout: <a href={`https://dashboard.stripe.com/payouts/${payout.payout_id}`} target={'_blank'}>{payout.payout_id}</a></h3>
                      </Grid>
                      <Divider style={{width:"100%"}} />
                      <Grid item xs={6} sm={6}>
                        <p><b>Gross:</b> {payout.gross}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Fee:</b> {payout.fee}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Net:</b> {payout.net}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Currency</b> {payout.currency}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Effective At (UTC):</b> {payout.effective_at_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Expected Arrival Date:</b> {payout.payout_expected_arrival_date}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Description:</b> {payout.description}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Balance Transaction Id:</b> <a href={`https://dashboard.stripe.com/search?query=${payout.balance_transaction_id}`} target={'_blank'}>{payout.balance_transaction_id}</a></p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Payout Status:</b> {payout.payout_status}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Payout Reversed At:</b> {payout.payout_reversed_at}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Payout Type:</b> {payout.payout_type}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Destination Id:</b> {payout.payout_destination_id}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Receipt:</b> <a href={`https://dashboard.stripe.com/payouts/${payout.payout_id}/receipt`} target={'_blank'}> Link </a></p>
                      </Grid>
                      <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                        <Button variant={'contained'} onClick={this.handleClosePayout}>Close</Button>
                      </Grid>
                    </Grid>
                  </div>
                  </Grid>
                </Grid>
              </Modal>
              <Modal
                open={showChargeModal}
                onClose={this.handleCloseCharge}
                onBackdropClick={this.handleCloseCharge}
                onEscapeKeyDown={this.handleCloseCharge}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
              <Grid container spacing={16} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'100vh', width:'100vw', justifyContent:"center", alignItems:"center"}}>
                <Grid item xs={10}>
                  <div className={classes.root}>
                  <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'60vh', minHeight:500, }}>
                    <Grid item xs={12} sm={12} onClick={() => this.setState({chargesOpen: !chargesOpen})}>
                      <h3>Charge: <a href={`https://dashboard.stripe.com/charges/${charge.charge_id}`} target={'_blank'}>{charge.charge_id}</a></h3>
                    </Grid>
                  <Tabs
                    value={chargeTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChargeTabChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Payment Info" value={0}/>
                    <Tab label="Customer Info" value={1} />
                  </Tabs>
                  <SwipeableViews
                    axis={'x'}
                    index={chargeTabValue}
                    onChangeIndex={this.handleChargeTabChange}
                    style={{width:"100%"}}
                  >
                    <TabContainer value={chargeTabValue} index={0} dir={"right"}>
                    <Grid container spacing={24}>
                      <Divider style={{width:"100%"}} />
                      <Grid item xs={6} sm={3}>
                        <p><b>Gross:</b> {charge.gross}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Fee:</b> {charge.fee}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Net:</b> {charge.net}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Currency</b> {charge.currency}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Created At (UTC):</b> {charge.created_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Available On (UTC):</b> {charge.available_on_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Customer Facing Amount:</b> {charge.customer_facing_amount}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Description:</b> {charge.description}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Status:</b> {charge.status}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Invoice:</b> <a href={`https://dashboard.stripe.com/invoices/${charge.invoice_id}`} target={'_blank'}>{charge.invoice_id}</a></p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Product:</b> <a href={`https://dashboard.stripe.com/products/${charge.product}`} target={'_blank'}>{charge.product}</a></p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Subscription:</b> <a href={`https://dashboard.stripe.com/subscriptions/${charge.subscription_id}`} target={'_blank'}>{charge.subscription_id}</a></p>
                      </Grid>
                      <Divider style={{width:"100%", marginTop:-15}} />
                    </Grid>
                    </TabContainer>
                    <TabContainer value={chargeTabValue} index={1} dir={"right"}>
                      <Grid container spacing={24}>
                        <Divider style={{width:"100%"}} />
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer:</b> <a href={`https://dashboard.stripe.com/customers/${charge.customer_id}`} target={'_blank'}>{charge.customer_id}</a></p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer Name:</b> {charge.customer_name}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer Email:</b> {charge.customer_email}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer M1 User Id:</b> {charge.userId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer Description:</b> {charge.customer_description}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Tenant:</b> {charge.tenant}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Organization Id:</b> {charge.organizationId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Organization Name:</b> {charge.organizationName}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Team Id:</b> {charge.teamId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Team Name:</b> {charge.teamName}</p>
                        </Grid>
                        <Divider style={{width:"100%", marginTop:-15}} />
                      </Grid>
                    </TabContainer>
                  </SwipeableViews>

                      <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
                        <Button variant={'contained'} onClick={this.handleCloseCharge}>Close</Button>
                      </Grid>
                    </Grid>
                  </div>
                  </Grid>
                </Grid>
              </Modal>
              <Modal
                open={showRefundModal}
                onClose={this.handleCloseRefund}
                onBackdropClick={this.handleCloseRefund}
                onEscapeKeyDown={this.handleCloseRefund}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
              <Grid container spacing={16} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'100vh', width:'100vw', justifyContent:"center", alignItems:"center"}}>
                <Grid item xs={10}>
                  <div className={classes.root}>
                  <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'60vh', minHeight:500, }}>
                    <Grid item xs={12} sm={12} onClick={() => this.setState({chargesOpen: !chargesOpen})}>
                      <h3>Refund: <a href={`https://dashboard.stripe.com/charges/${refund.charge_id}`} target={'_blank'}>{refund.charge_id}</a></h3>
                    </Grid>
                  <Tabs
                    value={refundTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleRefundTabChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Payment Info" value={0}/>
                    <Tab label="Customer Info" value={1} />
                  </Tabs>
                  <SwipeableViews
                    axis={'x'}
                    index={refundTabValue}
                    onChangeIndex={this.handleRefundTabChange}
                    style={{width:"100%"}}
                  >
                    <TabContainer value={refundTabValue} index={0} dir={"right"}>
                    <Grid container spacing={24}>
                      <Divider style={{width:"100%"}} />
                      <Grid item xs={6} sm={3}>
                        <p><b>Gross:</b> {refund.gross}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Fee:</b> {refund.fee}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Net:</b> {refund.net}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Currency</b> {refund.currency}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Created At (UTC):</b> {refund.created_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Available On (UTC):</b> {refund.available_on_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Customer Facing Amount:</b> {refund.customer_facing_amount}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Description:</b> {refund.description}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Status:</b> {refund.status}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Invoice:</b> <a href={`https://dashboard.stripe.com/invoices/${refund.invoice_id}`} target={'_blank'}>{refund.invoice_id}</a></p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Product:</b> <a href={`https://dashboard.stripe.com/products/${refund.product}`} target={'_blank'}>{refund.product}</a></p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Subscription:</b> <a href={`https://dashboard.stripe.com/subscriptions/${refund.subscription_id}`} target={'_blank'}>{refund.subscription_id}</a></p>
                      </Grid>
                      <Divider style={{width:"100%", marginTop:-15}} />
                    </Grid>
                    </TabContainer>
                    <TabContainer value={refundTabValue} index={1} dir={"right"}>
                      <Grid container spacing={24}>
                        <Divider style={{width:"100%"}} />
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer:</b> <a href={`https://dashboard.stripe.com/customers/${refund.customer_id}`} target={'_blank'}>{refund.customer_id}</a></p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer Name:</b> {refund.customer_name}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer Email:</b> {refund.customer_email}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer M1 User Id:</b> {refund.userId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>Customer Description:</b> {refund.customer_description}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Tenant:</b> {refund.tenant}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Organization Id:</b> {refund.organizationId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Organization Name:</b> {refund.organizationName}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Team Id:</b> {refund.teamId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Team Name:</b> {refund.teamName}</p>
                        </Grid>
                        <Divider style={{width:"100%", marginTop:-15}} />
                      </Grid>
                    </TabContainer>
                  </SwipeableViews>

                      <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
                        <Button variant={'contained'} onClick={this.handleCloseRefund}>Close</Button>
                      </Grid>
                    </Grid>
                  </div>
                  </Grid>
                </Grid>
              </Modal>
              <Modal
                open={showTransferModal}
                onClose={this.handleCloseTransfer}
                onBackdropClick={this.handleCloseTransfer}
                onEscapeKeyDown={this.handleCloseTransfer}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
              <Grid container spacing={16} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'100vh', width:'100vw', justifyContent:"center", alignItems:"center"}}>
                <Grid item xs={10}>
                  <div className={classes.root}>
                  <Grid container spacing={24} style={{paddingLeft:10, paddingRight:10, marginBottom:15, height:'60vh', minHeight:550, }}>
                    <Grid item xs={12} sm={12} onClick={() => this.setState({transfersOpen: !transfersOpen})}>
                      <h3>Transfer: <a href={`https://dashboard.stripe.com/search?query=${transfer.balance_transaction_id}`} target={'_blank'}>{transfer.balance_transaction_id}</a></h3>
                    </Grid>
                  <Tabs
                    value={transferTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTransferTabChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Payment Info" value={0}/>
                    <Tab label="Tenant Info" value={1}/>
                  </Tabs>
                  <SwipeableViews
                    axis={'x'}
                    index={transferTabValue}
                    onChangeIndex={this.handleTransferTabChange}
                    style={{width:"100%"}}
                  >
                    <TabContainer value={transferTabValue} index={0} dir={"right"}>
                    <Grid container spacing={24}>
                      <Grid item xs={6} sm={3}>
                        <p><b>Gross:</b> {transfer.gross}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Fee:</b> {transfer.fee}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Net:</b> {transfer.net}</p>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <p><b>Currency</b> {transfer.currency}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Created At (UTC):</b> {transfer.created_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Available On (UTC):</b> {transfer.available_on_utc}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Description:</b> {transfer.description}</p>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <p><b>Product:</b> <a href={`https://dashboard.stripe.com/products/${transfer.product}`} target={'_blank'}>{transfer.product}</a></p>
                      </Grid>
                    </Grid>
                    </TabContainer>
                    <TabContainer value={transferTabValue} index={1} dir={"right"}>
                      <Grid container spacing={24}>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Tenant:</b> {transfer.tenant}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Organization Id:</b> {transfer.organizationId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Organization Name:</b> {transfer.organizationName}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Team Id:</b> {transfer.teamId}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>M1 Team Name:</b> {transfer.teamName}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <p><b>View More:</b>  <a href={`https://dashboard.stripe.com/search?query=${transfer.organizationId}${transfer.teamId ? "_" : ""}${transfer.teamId ? transfer.teamId : ""}`} target={'_blank'}>Link</a></p>
                        </Grid>
                      </Grid>
                    </TabContainer>
                  </SwipeableViews>
                      <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
                        <Button variant={'contained'} onClick={this.handleCloseTransfer}>Close</Button>
                      </Grid>
                    </Grid>
                  </div>
                  </Grid>
                </Grid>
              </Modal>
              </div>             
            </PageWrapper>
        );
    }
}

AdminReports.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(AdminReports));
