import { SESSION_SET_USER_CONTEXT, SESSION_SET_APP_CONTEXT, SESSION_LOGOUT, NEW_COACH_SESSION, SET_ACCESS_TOKEN } from '../types/session';

const initState = {
  userContext: {
    appContextList:[]
  },
  appContext: {
    libraries:[]
  },
  newCoachSession: false
};

export default (state = initState, action) => {  
  switch(action.type) {

    case SESSION_SET_USER_CONTEXT:
      return {
        ...state,
        userContext: action.payload
      };
    case SESSION_SET_APP_CONTEXT:
      return {
        ...state,
        appContext: action.payload
      };
    case NEW_COACH_SESSION:
      return {
        ...state,
        newCoachSession: action.payload
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      };
    case SESSION_LOGOUT:
      return initState;
    default :
      return state;
  }
}