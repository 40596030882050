import { API, Auth } from "aws-amplify";
import { getTenant } from "../utils/commonUtil";

import { store } from '../configure-store';
export default class ContextService {
  buildUserContext = async () => {

    const tenantId = localStorage.getItem('tenantId') || getTenant();

    // Note, returns userContext and app Context
    var context = await Auth.currentSession()
    .then(auth =>{
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken
        },
        body: { tenant: tenantId, platform:"web" }
      }
      // console.log('auth.idToken.jwtToken', auth.idToken.jwtToken)
      return API.post("auth", `/auth/userContext`, options);
    })
    return context
  }

  buildAppContext = async () => {
    const tenantId = localStorage.getItem('tenantId') || getTenant();
    const {session: { userContext, appContext:existingAppContext }} = store.getState();
    let clonedObject = {...userContext}
    clonedObject.platform = "web";
    clonedObject.tenant = tenantId;
    //TODO: Find better solution. Consult Jpodz.
    if(!!existingAppContext){
      clonedObject.user = {...userContext.user, lastViewedTeamID:existingAppContext.id}
    }
    var appContext = await Auth.currentSession()
    .then(auth =>{    
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken
        },
        body:clonedObject
      }
      return API.post("auth", `/auth/appContext`, options);
    })
    return appContext
  }

  changeAppContext = async (userContext, contextId, tenant) => {
    //Add platform to user context to compute left nav
    userContext.platform = "web";
    userContext.tenant = tenant;
    var appContext = await Auth.currentSession()
    .then(auth =>{
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken
        },
        body:userContext
      }
      return API.post("auth", `/auth/appContext/${contextId}`, options);
    })
    return appContext
  }

  isStaffPermitted = (team, permission) => {
    if(team && team.staffPermissions && team.staffPermissions[permission] == false) return false
    return true
  }

  isPermittedToUseFeature = (team) => {
    if(team && team.tenant && team.tenant == 'vnn' && team.isFree) 
      return false
    return true
  }

  getTenantPermissions = tenant => {
    return API.post("config", `/tenant/${tenant}`);
  }

}
