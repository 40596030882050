import React, { Component } from 'react'

import './styles.css'
import { MenuItem } from '@material-ui/core'
import OptionsSelector from '../../optionsSelector'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { repsOrTimeOptions } from '../../../constants'

export default class BorderedDropdown extends Component {
    constructor(props){
        super(props)
        this.state = { isDropDownOpen: false }
    }

    toggleMenu = () => {
        const { isDropDownOpen } = this.state
        this.setState({ isDropDownOpen: !isDropDownOpen })
    }
    
    render(){
        const { isDropDownOpen } = this.state
        const { increment = false, placeholder, label, value = "", style = {}, activity = {}, handleClickOption, handleIncrease, handleDecrease, onKeyPress, handleChange = () => {return} } = this.props
        const selectedOption = repsOrTimeOptions.find(type => type.id == activity.repsOrTime || type.id == activity.scoreType)
        return(
            <div onClick={increment ? () => { return } : this.toggleMenu } className={'borderedDropwdownContainer'}>
                <div className={'labelValueContainer'}>
                    {label && <p className={'labelText'}>{label}</p>}
                    {increment ?
                        <input
                            placeholder={placeholder}
                            value={value === null ? '': value}
                            className={'valueText'}
                            onChange={handleChange}
                            onKeyPress={onKeyPress}
                        />
                    :
                        <p className={'valueText'}>{value}</p>
                    }
                </div>
                {increment ?
                    <div className={'incrementContainer'}>
                        <ArrowDropUp className={'icon'} onClick={handleIncrease} />
                        <ArrowDropDown className={'icon'} onClick={handleDecrease} />
                    </div>
                : isDropDownOpen ?
                    <ArrowDropUp className={'icon'} />
                :
                    <ArrowDropDown className={'icon'} />
                }
                {isDropDownOpen && 
                    <div className={'optionsContainer'}>
                        {repsOrTimeOptions.map(option => (
                            <MenuItem onClick={e => handleClickOption(e.target.getAttribute('value'))} key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </div>
                }
            </div>
        )
    }
}