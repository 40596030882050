import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Calendar = (props) => (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="11" cy="11" r="11" fill="#3368FF" />
        <rect width="15.6316" height="15.6316" transform="translate(3.18359 3.47266)" fill="#3368FF" />
        <path d="M8.96541 15.7985L4.82281 11.7372C4.70705 11.6231 4.64917 11.4763 4.64917 11.2969C4.64917 11.1174 4.70705 10.9707 4.82281 10.8565L5.74063 9.97573C5.85639 9.84524 5.99696 9.78 6.16233 9.78C6.34424 9.78 6.50135 9.84524 6.63365 9.97573L9.41192 12.7158L15.3653 6.84416C15.4976 6.71368 15.6465 6.64844 15.8119 6.64844C15.9938 6.64844 16.1426 6.71368 16.2584 6.84416L17.1762 7.72491C17.2919 7.83908 17.3498 7.98588 17.3498 8.16529C17.3498 8.3447 17.2919 8.49149 17.1762 8.60567L9.85842 15.7985C9.74266 15.929 9.59383 15.9942 9.41192 15.9942C9.23001 15.9942 9.08117 15.929 8.96541 15.7985Z" fill="white" />
    </SvgIcon>
);

export default Calendar;
