import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import './index.scss';

const Component = ({ title, users: { loggedInUserId, byId }, children }) => {
    const { firstName, lastName, avatarUrl } = byId[loggedInUserId];
    return (
        <div className={'m1-wrapped-page'}>
            <div className={'header-container'}>
                <div className={'row align-items-center'}>
                    <div className={'page-title col'}>
                        {title}
                    </div>
                    <div className={'col-auto'}>
                        <div className={'user-container'}>
                            <div className={'row no-gutters align-items-center'}>
                                <div className={'col'}>
                                    <div className={'user-name'}>
                                        {firstName}<br />{lastName}
                                    </div>
                                </div>
                                <div className={'col-auto'}>
                                    <div className={'avatar-container'} style={avatarUrl && { backgroundImage: `url(${avatarUrl})` }}>
                                        {avatarUrl ? (
                                            <div />
                                        ) : (
                                            <PersonOutlineIcon />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'content-container'}>
                {children}
            </div>
        </div>
    );
};

Component.propTypes = {
    title: PropTypes.string
};

Component.defaultProps = {
    title: 'Page'
};

const mapStateToProps = ({ users }) => ({ users });

export default connect(mapStateToProps)(Component);
