import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home/home";

import OAuthLoading from './containers/oAuthLoading/index'

import {ChatAppWithData} from "./containers/chat/chat/components/chatapp";
import {NewChatGroupWithData} from "./containers/chat/chat/components/NewChatGroup";

import NotFound from "./containers/notFound/notFound";
import Login from "./containers/login/login";
import ParentSignup from "./containers/signup/parent";
import AthleteSignup from "./containers/signup/athlete";
import Signup from "./containers/signup/signup";
import ParentVerification from "./containers/coppa/ParentVerification";
import CoachSignup from "./containers/signup/coach";
import ForgotPassword from "./containers/forgotPassword/forgotPassword";

import Messages from "./containers/messages";

import CoachResources from "./containers/coachTraining/resources";
import Marketplace from "./containers/marketplace/marketplace";
import MarketplaceDetails from "./containers/marketplace/marketplaceDetails";

import Guardian from "./containers/guardian/guardian";
import NewAthlete from "./containers/guardian/newAthlete";

import DirectorCoaches from "./containers/director/coaches";
import DirectorCalendar from "./containers/calendar/directorShow";
import DirectorListActivities from "./containers/activities/directorList";
import WCSDirectorCreateTeams from "./containers/wcs/wcsNewTeam";
import WCSAdminCreateTeams from "./containers/wcs/wcsAdminNewTeam";
import WCSAdminViewTeams from "./containers/wcs/wcsAdminViewTeams";
import WCSAdminEditAthleticDirectors from "./containers/wcs/editAthleticDirectors";
import WCSAdminEditCoaches from "./containers/wcs/editCoaches";

import Gear from "./containers/gear/gear";

import Groups from "./containers/groups/groups";
import GroupsEdit from "./containers/groups/edit";
import GroupsNew from "./containers/groups/new";

// import ActivitiesList from "./containers/activities/list";
import ActivitiesList from './components/v2/activity/ActivityPage';

import AdminUsers from "./containers/admin/users";
import AdminOrganizations from "./containers/admin/organizations";
import AdminPrograms from "./containers/admin/programs";
import AdminProducts from "./containers/admin/products";
import AdminProductsNew from "./containers/admin/newProduct";
import AdminInvoices from "./containers/admin/invoices";
import AdminNotifications from "./containers/admin/notifications";
import AdminCopyActivities from "./containers/admin/copyActivities";
import AdminReports from "./containers/admin/reports";


import AdminTrueLacrosseImport from "./containers/truelacrosse/trueLacrosseImport";
import TrueLacrosseDirectorCreateTeams from "./containers/truelacrosse/trueLacrosseNewTeam";

import EventsList from "./containers/events/list";
import EventsNew from "./containers/events/new";

import Calendar from "./containers/calendar/show";

import ChatApp from "./containers/chat/chat/components/chatapp";

import Settings from "./containers/settings/teamSettings";
import DirectorSettings from "./containers/settings/directorSettings";

import NewTeam from "./containers/teams/new";

import UserProfile from "./containers/userProfile/profile";

import PointsLeaderboard from "./containers/leaderboards/pointsLeaderboard";
import ActivityLeaderboards from "./containers/leaderboards/activityLeaderboards";
import ActivityLeaderboard from "./containers/leaderboards/activityLeaderboard";
import UserActivityLeaderboard from "./containers/leaderboards/userActivityLeaderboard";


import VimeoVideo from "./containers/video/vimeo";
import YouTubeVideo from "./containers/video/youtube";
import WistiaVideo from "./containers/video/wistia";

import CoachRoster from "./containers/roster/coaches";
import AthleteRoster from "./containers/roster/athletes";
import WorkoutProgramsList from "./containers/workoutPrograms/list";
import WorkoutProgramsNew from "./containers/workoutPrograms/new";
import WorkoutSchedules from "./containers/workoutSchedules";
import WorkoutsNew from "./containers/workouts/new";
import WorkoutsList from "./containers/workouts/list";
import AuthenticatedRoute from "./components/authenticatedRoute";
import UnauthenticatedRoute from "./components/unauthenticatedRoute";
import SEAdminAuthenticatedRoute from "./components/seAdminAuthenticatedRoute";
import SEAdmin from "./containers/seAdmin";
import SEAdminLogin from "./containers/seAdmin/login";
import ImportFromSportsEngine from './containers/seImporter/ImportFromSportsEngine';
import { ROUTE_PATH } from "./routeUtil";
import NewAthleteRoster from "./containers/roster/importedAthlete";
import Error503 from "./containers/errors/503";

import MaxPayCustomers from "./containers/maxPay/customers";
import MaxPayBankingInfo from "./containers/maxPay/bankingInfo";
import MaxPaySubscriptions from "./containers/maxPay/subscription";
import MaxPaySalesPage from "./containers/maxPay/salesPage";
import MaxPayFreemiumSettings from "./containers/maxPay/freemiumSettings";
import MaxPayConvert from "./containers/maxPay/convertToMaxPay";
import FreshdeskForm from "./containers/freshdeskForm"
import GettingStarted from "./containers/liveTraining/gettingStarted";
import ZoomConfiguration from "./containers/liveTraining/zoomConfiguration";

import AthleteGoals from "./containers/roster/athleteGoals"
import Questions from './containers/questions'
import DyanmicLibrary from "./containers/dynamicLibrary"
import UpliftConfiguration from "./containers/liveTraining/upliftConfiguration"
import ContentSharing from './containers/contentSharing';
import Integrations from './containers/director/Integrations';
import AdminContentSharing from "./containers/adminContentSharing";
import MainDashboard from "./screens/dashboard/containers/Main"
import AthleteDashboard from "./screens/dashboard/containers/Athlete"
import Timeline from "./screens/calendar/containers/Timeline"
import CreateEvent from "./screens/calendar/containers/CreateEvent"
import CancelEvent from "./screens/calendar/containers/CancelEvent"

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path={ROUTE_PATH.HOME} exact component={Home} props={childProps} />

    {/* USER PROFILE */}
    <AuthenticatedRoute path={ROUTE_PATH.PROFILE} exact component={UserProfile} props={childProps} />

    {/* CHAT */}
    <AuthenticatedRoute path="/chat" exact component={ChatAppWithData} props={childProps} />
    <AuthenticatedRoute path="/chat/new" exact component={NewChatGroupWithData} props={childProps} />
    {/* //// */}

    {/* ACTIVITIES */}
    <AuthenticatedRoute path={ROUTE_PATH.ACTIVITIES_LIST} exact component={ActivitiesList} props={childProps} />
    {/* ////////// */}

    {/* CAMPS/EVENTS */}
    <AuthenticatedRoute path="/events" exact component={EventsList} props={childProps} />
    <AuthenticatedRoute path="/events/new" exact component={EventsNew} props={childProps} />
    {/* //////////// */}

    {/* SCHEDULES */}
    <AuthenticatedRoute path={ROUTE_PATH.SCHEDULES} exact component={Calendar} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.TIMELINE} exact component={Timeline} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.CREATE_EVENT} exact component={CreateEvent} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.EDIT_EVENT} exact component={CreateEvent} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.EDIT_RECURRING_EVENT} exact component={CreateEvent} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.CANCEL_EVENT} exact component={CancelEvent} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.CANCEL_RECURRING_EVENT} exact component={CancelEvent} props={childProps} />
    {/* ///////// */}

    {/* ROSTER */}
    <AuthenticatedRoute path={ROUTE_PATH.COACHES} exact component={CoachRoster} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.ATHLETES} exact component={AthleteRoster} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.ATHLETES_IMPORT_STATUS} exact component={NewAthleteRoster} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.ATHLETES_IMPORT_ORG_STATUS} exact component={NewAthleteRoster} props={childProps} />
    {/* ////// */}

    { /* Only Unauthenticated Routes should be Signups and Login */ }
    <UnauthenticatedRoute path="/in" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/oauth" exact component={OAuthLoading} props={childProps} />
    <AuthenticatedRoute path="/signup/coach" exact component={CoachSignup} props={childProps} />
    <UnauthenticatedRoute path="/signup/parent" exact component={ParentSignup} props={childProps} />
    <UnauthenticatedRoute path="/signup/athlete" exact component={AthleteSignup} props={childProps} />
    <UnauthenticatedRoute path="/parent-verification" exact component={ParentVerification} props={childProps} />

    {/* Freshdesk Form */}
    <UnauthenticatedRoute path="/support" exact component={FreshdeskForm} props={childProps} />
    {/* ////// */}

    {/* SE Admin */}
    <UnauthenticatedRoute path={ROUTE_PATH.SE_ADMIN_LOGIN} exact component={SEAdminLogin} props={childProps} />
    <SEAdminAuthenticatedRoute path={ROUTE_PATH.SE_ADMIN} exact component={SEAdmin} props={childProps} />

    {/* ////// */}


    <Route path="/video/:videoId/embed" exact component={VimeoVideo} props={childProps} />
    <Route path="/youtube/:videoId/embed" exact component={YouTubeVideo} props={childProps} />
    <Route path="/wistia/:videoId/embed" exact component={WistiaVideo} props={childProps} />

    <UnauthenticatedRoute path="/forgot/password" exact component={ForgotPassword} props={childProps} />
    {/* <UnauthenticatedRoute path="/forgot/username" exact component={ForgotUsername} props={childProps} /> */}

    { /*  ********************************
           Add authenticated routes below
          ********************************
    */ }

    {/* ACTIVITIES */}
    {/* <AuthenticatedRoute path="/activities" exact component={ActivitiesList} props={childProps} /> */}
    {/* ////////// */}

    {/* ADMIN */}
    <AuthenticatedRoute path="/admin/notifications" exact component={AdminNotifications} props={childProps} />
    <AuthenticatedRoute path="/admin/users" exact component={AdminUsers} props={childProps} />
    <AuthenticatedRoute path="/admin/organizations" exact component={AdminOrganizations} props={childProps} />
    <AuthenticatedRoute path="/admin/products" exact component={AdminProducts} props={childProps} />
    <AuthenticatedRoute path="/products/new" exact component={AdminProductsNew} props={childProps} />
    <AuthenticatedRoute path="/admin/teams" exact component={AdminPrograms} props={childProps} />
    <AuthenticatedRoute path="/admin/invoices" exact component={AdminInvoices} props={childProps} />
    <AuthenticatedRoute path="/admin/copyActivities" exact component={AdminCopyActivities} props={childProps} />
    <AuthenticatedRoute path="/admin/reports" exact component={AdminReports} props={childProps} />
    <AuthenticatedRoute path="/adminContentSharing" exact component={AdminContentSharing} props={childProps} />

    <AuthenticatedRoute path="/truelacrosse/import" exact component={AdminTrueLacrosseImport} props={childProps} />
    <AuthenticatedRoute path="/truelacrosse/new" exact component={TrueLacrosseDirectorCreateTeams} props={childProps} />
    {/* ////////// */}

    {/* GUARDIANS */}
    <AuthenticatedRoute path="/guardian/athlete/new" exact component={NewAthlete} props={childProps} />
    <AuthenticatedRoute path="/guardian/athlete/:id" exact component={Guardian} props={childProps} />
    {/* ////////// */}

    {/* GEAR */}
    <AuthenticatedRoute path="/gear" exact component={Gear} props={childProps} />
    {/* ////////// */}

    {/* MESSAGING */}
    <AuthenticatedRoute path={ROUTE_PATH.MESSAGES} exact component={Messages} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.DIRECTOR_MESSAGES} exact component={Messages} props={childProps} />
    {/* ////////// */}

    {/* COACH TRAINING */}
    <AuthenticatedRoute path={ROUTE_PATH.COACH_RESOURCES} exact component={CoachResources} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MARKETPLACE} exact component={Marketplace} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MARKETPLACE_DETAIL} exact component={MarketplaceDetails} props={childProps} />
    {/* ////////////// */}

    {/* GROUPS */}
    <AuthenticatedRoute path="/groups" exact component={Groups} props={childProps} />
    <AuthenticatedRoute path="/groups/new" exact component={GroupsNew} props={childProps} />
    <AuthenticatedRoute path="/groups/:id" exact component={GroupsEdit} props={childProps} />
    {/* ////////// */}

    {/* WORKOUTS */}
    <AuthenticatedRoute path={ROUTE_PATH.WORKOUTS} exact component={WorkoutsList} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.WORKOUTS_NEW} exact component={WorkoutsNew} props={childProps} />
    {/* //////// */}

    {/* WORKOUT PROGRAMS */}
    <AuthenticatedRoute path={ROUTE_PATH.WORKOUT_PROGRAMS} exact component={WorkoutProgramsList} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.WORKOUT_PROGRAM_NEW} exact component={WorkoutProgramsNew} props={childProps} />
    {/* ////////// */}

    {/* WORKOUT SCHEDULES */}
    <AuthenticatedRoute path={ROUTE_PATH.WORKOUT_SCHEDULES} exact component={WorkoutSchedules} props={childProps} />
    {/* ///////////////// */}

    {/* SETTINGS */}
    <AuthenticatedRoute path={ROUTE_PATH.SETTINGS} exact component={Settings} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.DIRECTOR_SETTINGS} exact component={DirectorSettings} props={childProps} />
    {/* //////// */}

    {/* ADD TEAM */}
    <AuthenticatedRoute path={ROUTE_PATH.NEW_TEAM} exact component={NewTeam} props={childProps} />
    {/* //////// */}

    {/* DIRECTOR */}
    <AuthenticatedRoute path={ROUTE_PATH.DIRECTOR_COACHES} exact component={DirectorCoaches} props={childProps} />
    <AuthenticatedRoute path="/directorSchedules" exact component={DirectorCalendar} props={childProps} />
    {/* <AuthenticatedRoute path="/directorActivities" exact component={DirectorListActivities} props={childProps} /> */}
    <AuthenticatedRoute path="/directorActivities" exact component={ContentSharing} props={childProps} />
    <AuthenticatedRoute path="/directorNewTeam" exact component={WCSDirectorCreateTeams} props={childProps} />
    <AuthenticatedRoute path="/director/integrations" exact component={Integrations} props={childProps} />

    {/* //////// */}
    {/* WCS Admin */}
    <AuthenticatedRoute path="/admin/editAthleticDirectors" exact component={WCSAdminEditAthleticDirectors} props={childProps} />
    <AuthenticatedRoute path="/admin/editTeachers" exact component={WCSAdminEditCoaches} props={childProps} />
    <AuthenticatedRoute path="/admin/addNewTeam" exact component={WCSAdminCreateTeams} props={childProps} />
    <AuthenticatedRoute path="/admin/viewTeams" exact component={WCSAdminViewTeams} props={childProps} />
    {/* //////// */}

    {/* LEADERBOARDS */}
    <AuthenticatedRoute path={ROUTE_PATH.LEADERBOARD_POINTS} exact component={PointsLeaderboard} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.LEADERBOARD_ACTIVITIES} exact component={ActivityLeaderboards} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.LEADERBOARD_ACTIVITIES_DETAILS} exact component={ActivityLeaderboard} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.LEADERBOARD_ACTIVITIES_DETAILS_BY_USERID} exact component={UserActivityLeaderboard} props={childProps} />
    {/* ///////////////// */}

    {/* SE Import */}
    <AuthenticatedRoute path={ROUTE_PATH.SE_IMPORT} exact component={ImportFromSportsEngine} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.SE_IMPORT_CSV} exact component={ImportFromSportsEngine} props={childProps} />
    {/* ////// */}

    {/* MAX PAY*/}
    <AuthenticatedRoute path={ROUTE_PATH.MAXPAY_BANK_INFO} exact component={MaxPayBankingInfo} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MAXPAY_CUSTOMERS} exact component={MaxPayCustomers} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MAXPAY_SUBSCRIPTION} exact component={MaxPaySubscriptions} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MAXPAY_SALES_PAGE} exact component={MaxPaySalesPage} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MAXPAY_FREEIUM_SETTINGS} exact component={MaxPayFreemiumSettings} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.MAXPAY_CONVERT} exact component={MaxPayConvert} props={childProps} />
    {/* ////// */}

    {/* Freshdesk Form */}
    <AuthenticatedRoute path="/support" exact component={FreshdeskForm} props={childProps} />
    {/* ////// */}

    {/* LIVE TRAINING*/}
    <AuthenticatedRoute path={ROUTE_PATH.LIVE_TRAINING_STARTING} exact component={GettingStarted} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.ZOOM_CONFIGURATION} exact component={ZoomConfiguration} props={childProps} />
    <AuthenticatedRoute path={ROUTE_PATH.UPLIFT_CONFIGURATION} exact component={UpliftConfiguration} props={childProps} />
    {/* Athlete Goals */}
    <AuthenticatedRoute path={ROUTE_PATH.ATHLETE_GOALS} exact component={AthleteGoals} props={childProps} />
    {/* ////// */}
    {/* Questions*/}
    <AuthenticatedRoute path={ROUTE_PATH.QUESTIONS} exact component={Questions} props={childProps} />
    {/* ////// */}

    {/* Questions*/}
    <AuthenticatedRoute path={ROUTE_PATH.DYNAMIC_LIBRARY} exact component={DyanmicLibrary} props={childProps} />
    {/* ////// */}

    {/* Coach Dashboard */}
    <AuthenticatedRoute path={ROUTE_PATH.COACH_DASHBOARD} exact component={MainDashboard} props={childProps} />

    {/* Coach Dashboard */}
    <AuthenticatedRoute path={ROUTE_PATH.ATHLETE_DASHBOARD} exact component={AthleteDashboard} props={childProps} />
    
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />

  </Switch>;
