import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ProgressStep from '../progressStep';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import FloatingSelectWithCustomChild from '../../../components/floatingSelectWithCustomChild';
import SportsEngineBtn from '../../../components/themeButton';
import { userImportClient } from '../../../clients'
import {testId} from '../../../utils/test-utils';
const styles = {
    importTitle: {
        color: "#0B0B0B",
        marginBottom: "12px",
        fontWeight: 500,
        fontSize: "14px"
    },
    mappings: {
        marginLeft: "-10px",
        paddingBottom: "30px",
        borderBottom: "1px solid #DDE2E5",
        marginBottom: "20px"
    },
    mapContainer: {
        minWidth: "210px",
        width: "210px",
        height: "285px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        marginLeft: "10px",
        padding: "15px 0px"
    },
    mapField: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "150%",
        marginBottom: "8px",
        padding: "0 15px"
    },
    currentField: {
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#0B0B0B"
    },
    requiredAndValid: {
        color: "#27AE60",
    },
    mapHead: {
        color: "#0B0B0B",
        opacity: "0.3",
        marginRight: "4px",
        fontWeight: "500"
    },
    dropdownContainer: {
        padding: "0 15px",
        paddingBottom: "12px"
    },
    importedValueContainer: {
        borderTop: "1px solid #DDE2E5",
        padding: "0 15px"
    },
    importedValueHead: {
        marginTop: "12px",
        color: "#75797B",
        lineHeight: "150%",
        fontWeight: "500",
        fontSize: "14px"
    },
    importValue: {
        color: "#A0A7AB",
        fontSize: "12px",
        lineHeight: "140%",
        marginTop: "8px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    dropdown: {
        marginBottom: "0px",
        paddingLeft: "10px"
    },
    fieldExample: {
        marginTop: "1px",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "100%",
        color: "#A0A7AB"
    },
    typeLabel: {
        width: "320px",
        height: "49px",
        padding: "8px 15px",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "150%",
        borderBottom: "1px solid #DDE2E5",
        cursor: "pointer",
        "&:hover": {
            background: "#1354F9",
            color: "#ffffff !important",
            "& .fieldExample": {
                color: "#ffffff"
            }
        }
    },
    reqFieldHead: {
        marginRight: "12px"
    },
    reqFieldBox: {
        marginRight: "20px",
        color: "#F93F3F",
        "& svg": {
            fill: "#F93F3F"
        },
        "&.mappingValid": {
            color: "#27AE60",
            "& svg": {
                fill: "#27AE60"
            }
        }
    },
    headerAdditionalInfo: {
        marginLeft: "110px",
        textAlign: "left",
        paddingBottom: "30px",
        marginTop: "10px",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#27292A"
    },
    hasError: {
        color: "#F93F3F"
    },
    mapMissingContainer: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        textAlign: "center",
        color: "#DDE2E5",
        height: "calc(100% - 81px)"
    },
    errorBorder:{
        border:"3px solid #F93F3F",
        width:"216px"
    },
    mappingShortInfo:{
        width:"339px",
        textAlign:"center",
        color: "#27292A",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        marginTop:"8px"
    }
};

class Step2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mappedData: [],
            menuItemData: [],
            isLoading: true,
            loadingMessage1: "Mapping Imported Data"
        }
    }

    componentDidMount = async () => {
        let { importProcessId } = this.props;
        try {
            let { data } = await userImportClient.getMappingByProcessId(importProcessId);
            let menuItemData = data.availableFields || [];
            let mappedData = data.mappedData;
            menuItemData = this.getMenuItemData(mappedData, menuItemData);
            this.setState({ mappedData, menuItemData })
        }
        catch (e) {            
            console.log("Exception")
            console.log(e)
        }
        this.setState({ isLoading: false });
    }

    getMenuItemData = (mappedData, menuItemData) => {
        let mappedFieldWithAssignment = {}
        mappedData.forEach(element => {
            element.displayName = (element.name || "").replace(/_/g, " ");
            if (element.mappedTo) {
                if (mappedFieldWithAssignment[element.mappedTo]) {
                    mappedFieldWithAssignment[element.mappedTo].push(element.name)
                }
                else {
                    mappedFieldWithAssignment[element.mappedTo] = [element.name];
                }
            }
        });
        let convertedMenuItemData = menuItemData.map(item => {
            let fieldMappingData = mappedFieldWithAssignment[item.name];
            return {
                ...item,
                value: item.name,
                displayValue: item.name,
                additionalData: !!fieldMappingData ? `Assigned To ${fieldMappingData.join(", ")}` : (item.sampleValues || []).join(", "),
                grayOut: !!fieldMappingData
            }
        });
        return convertedMenuItemData;
    }

    selectFieldMapping = (name, fieldName) => {
        let { mappedData, menuItemData } = this.state;
        let updatedMap = mappedData.map(data => {
            return { ...data, ...{ mappedTo: name == data.name ? fieldName : data.mappedTo } };
        });
        let updatedMenuItemData = this.getMenuItemData(updatedMap, menuItemData);
        this.setState({ mappedData: updatedMap, menuItemData: updatedMenuItemData });
    }

    saveMappingData = async () => {
        const { mappedData } = this.state;
        const { importProcessId, goToNextStep } = this.props;
        this.setState({isLoading: true, loadingMessage1:"Checking for Errors"})
        let mapping = [];
        mappedData.forEach(item => {
            if(item.mappedTo){
                mapping.push({
                    key: item.name,
                    mapping: item.mappedTo
                })
            }             
        });      
        try{
            await userImportClient.createMappingByProcessId(importProcessId, mapping)
        }
        catch (e) {
            console.log("Exception")
            console.log(e)
        }
        this.setState({ loadingMessage1:"Processing 0%" });
        await userImportClient.beginImportByProcessId(importProcessId);
        await new Promise((resolve,reject) => {
            this.checkCSVUploadProgress(importProcessId, resolve, reject);
        });
        this.setState({ isLoading: false}); 
        goToNextStep();        
    }

    checkCSVUploadProgress = async (importProcessId, resolve, reject) => {
        let processing = true;            
        setTimeout(async () => {
            if (processing) {
                try{
                    var { data } = await userImportClient.getProcessById(importProcessId);
                    if(data.imported != 0){
                    this.setState({ loadingMessage1:`Processing ${Math.round((data.imported/data.total)*100)}%` });
                    }
                    if(data.status == "COMPLETED"){
                        processing = false;
                        resolve();
                    }
                    else{
                        await this.checkCSVUploadProgress(importProcessId, resolve, reject); 
                    }
                }
                catch(e){
                    console.log("exception");
                    console.log(e);
                    reject();
                }                  
            }
        }, 3000);
    }

    render() {
        const { classes, goBack, currentStep, totalSteps } = this.props;
        const { mappedData, menuItemData, isLoading, loadingMessage1, loadingMessage2 } = this.state;
        const requiredFields = mappedData.filter(item => item.isRequired);
        const hasValidationError = requiredFields.some(item => !item.mappedTo);
        const mappedElementCount = mappedData.filter(item => !!item.mappedTo).length;
        return (
            <React.Fragment>
                <HeaderWrapper>
                    <ProgressStep stepDetails={{
                        "title": "Field mapping to MaxOne",
                        "nextStep": "Content Settings"
                    }} currentStep={currentStep} totalSteps={totalSteps} />
                    <div className={classes.headerAdditionalInfo}>
                        Imported <span className="font-weight-bold">{menuItemData.length} Fields</span>. Please select your mapping options below.
                    </div>
                </HeaderWrapper>
                <BodyWrapper isLoading={isLoading} loadingMessage1={loadingMessage1} loadingMessage2={loadingMessage2}>
                    <div className={classes.importInfo}>
                        <div className={classes.importTitle}>
                            <span className={classes.reqFieldHead}>Required Fields:</span>
                            {requiredFields.map(field => {
                                return (
                                    <span className={classnames(classes.reqFieldBox, { "mappingValid": field.mappedTo })}>
                                        <CheckCircleIcon fontSize={'16px'} />
                                        <span>{field.displayName}</span>
                                    </span>
                                )
                            })
                            }
                        </div>
                        <div className={classnames(classes.mappings, "d-flex")}>
                            {
                                mappedData.map(data => {
                                    return (<MapContainer data={data} classes={classes} menuItemData={menuItemData} onChange={this.selectFieldMapping} />)
                                })
                            }
                        </div>
                        <div className={classnames(classes.serviceButtonContainer, "d-flex align-items-center justify-content-between")}>
                            <div className="d-flex justify-content-start">
                                <SportsEngineBtn
                                    position="left"
                                    width={"148px"}
                                    height={"50px"}
                                    iconName="ChevronLeft"
                                    onClick={goBack}
                                    disabled={false}
                                    text="Go Back"
                                    color={"#ffffff"}
                                    textColor={"#5B6062"}
                                />
                            </div>
                            <div className="d-flex align-self-end" {...testId(`submit-button`)}>
                                <SportsEngineBtn
                                    width={"369px"}
                                    height={"50px"}
                                    iconName="ChevronRight"
                                    onClick={this.saveMappingData}
                                    disabled={hasValidationError}
                                    text="Import with selected Mappings"
                                />
                            </div>                            
                        </div>
                        <div className={classnames("d-flex justify-content-end")}>
                            <div className={classes.mappingShortInfo}>Mapping {mappedElementCount} Fields of {menuItemData.length} Available</div>
                        </div>
                    </div>
                </BodyWrapper>
            </React.Fragment>);
    }
}

function MapContainer({ data, classes, onChange, menuItemData }) {
    let isRequiredAndMissing = data.isRequired && !data.mappedTo;
    let isRequiredAndValid = data.isRequired && data.mappedTo;

    return (
        <div className={classnames(classes.mapContainer,{ [classes.errorBorder]: isRequiredAndMissing})} {...testId(`map-${data.name}-container`)}>
            <div className={classes.mapField}>
                <span className={classes.mapHead}>Map:</span>
                <span className={classnames(classes.currentField, { [classes.requiredAndValid]: isRequiredAndValid }, { [classes.hasError]: isRequiredAndMissing })}>{data.displayName}{data.isRequired ? "*" : ""}</span>
                {!!data.mappedTo && <span className="arrow"></span>}
            </div>
            <div className={classes.dropdownContainer} {...testId(`mapped-field-select-container`)}>
                <FloatingSelectWithCustomChild
                    dropDownWidth="320px"
                    value={data.mappedTo}
                    label="Imported Field to use"
                    onChange={(value) => onChange(data.name, value)}
                    dropDownLabel="Select a field to use (required)"
                    menuItemData={menuItemData}
                    showDropDownBorder={true}
                    ellipseAdditionalData={true}
                />
            </div>
            {!!data.mappedTo ?
                <div className={classes.importedValueContainer}>

                    <div className={classes.importedValueHead}>
                        Imported Values:
                    </div>
                    <div className={classes.importedValues}>
                        {(((menuItemData.find(item => item.value == data.mappedTo) || {}).sampleValues) || []).map(value => {
                            return (<div className={classes.importValue}>
                                {value}
                            </div>)
                        }
                        )}
                    </div>
                </div> :
                <div className={classnames("d-flex align-items-center justify-content-center", classes.mapMissingContainer)}>
                    <div>Select an <br /> Imported field</div>
                </div>}
        </div>
    )
}

export default withStyles(styles)(Step2);