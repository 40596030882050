import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import pgcIcon from '../assets/images/themes/pgc/icon-114x114.png';
import pgcLongLogo from '../assets/images/themes/pgc/pgc-long-logo.png';
import homeImage from '../assets/images/pgc-welcome.jpg';
import pgcM1PowerLogo from '../assets/images/themes/pgc/pgc-logo-powered-by-m1.png';
import pgcLoginLogo from '../assets/images/themes/pgc/pgc-logo.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    id: 'pgc', 
    name: 'PGC',
    appUrl: 'm1-pgc://',
    domain: 'pgc.gomaxone.com',
    link: 'https://itunes.apple.com/us/app/pgc-basketball/id1462181501',
    onAppleAppStore: 'https://itunes.apple.com/us/app/pgc-basketball/id1462181501',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.pgc'
  },
  image: {
    home: homeImage,
    logo: pgcIcon,
    loginLogo: pgcM1PowerLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#AA3E22"
    },
    secondary: {
      main: "#F15D22"
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#FC6605"
    },
    secondary: green,
    error: red,
  }

});
