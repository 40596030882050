import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});
class Error503 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
      return (
          <div className={'homeContainer'}>
              <React.Fragment>
                <div className="teamOverviewSection">
                  <h1> <b>503</b> </h1>
                  <h3> As of 9:21AM EST, we received word that AWS, our international service provider is experiencing outages affecting most services across the United States.</h3>
                  <h3>We are working with AWS to get MaxOne up and running again as soon as possible.</h3>
                  <h3>If you are unable to access your account or if you have questions or urgent concerns, please reach out to a MaxOne Customer Representative who will be in response soon: <a href="https://ticket.gomaxone.com/maxone">https://ticket.gomaxone.com/maxone</a>.</h3>
                  <h3>Thank you for your patience as we work to get this resolved as soon as possible.</h3>
                </div>

              </React.Fragment>
          </div>
      )
  }
}


export default withStyles(styles)(Error503);
