import React, { Component } from "react";
import _ from 'lodash'
import classNames from 'classnames';

import { API } from 'aws-amplify'

import { analyticsService, currentTimestamp } from '../../services/analyticsService';
import { connect } from "react-redux"
import AddIcon from '@material-ui/icons/Add'
import AddBox from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '../../components/iconButton'
import { DragDropContext } from 'react-beautiful-dnd'
import TagModal from '../../components/modals/tagModal'
import ToggleButton from '../../components/toggleButton';
import ToastMessage from '../../components/toastMessage'
import EditableText from '../../components/editableText'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import WorkoutBlock from '../../components/workouts/workoutBlock'
import { getTagsByParentId } from '../../services/taggingService'
import FilterableSideList from '../../components/filterableSideList'
import DescriptionModal from '../../components/modals/descriptionModal'
import NewWorkoutActivityModal from '../../components/modals/newWorkoutActivityModal'
import {FileType, tenantMap} from '../../utils/commonUtil'
import urlParser from "../../js-video-url-parser";
import {
    getTeamActivitiesElasticSearch
} from '../../services/activitiesService'
import {
    saveActivity,
    getFileTypeSupportStatus,
    uploadActivityAttachment,
    resolveFilteredActivities,
    resolveActivitiesWithFilter,
    correctLegacyActivityData
} from '../../utils/activityUtils'
import {
    saveTags,
    updateActivity,
    resolveNewBlocks,
    deleteWorkoutBlock,
    resolveTagsForModal,
    resolveSavedWorkout,
    resolveActivityDrop,
    resolveWorkoutBlocks,
    updateWorkoutActivity,
    deleteWorkoutActivity,
    duplicateWorkoutBlock,
    duplicateWorkoutActivity,
    addNewWorkoutActivity
} from '../../utils/workoutUtils';
import { getWorkout } from '../../services/workoutsService';
import PageWrapper from '../nav/pageWrapper';
import { ROUTE_PATH } from '../../routeUtil';
import { withStyles } from "@material-ui/core/styles";
import NewActivityModule from '../newActivityModule';
import shortid from "shortid";
import { getQuestionInputInfo } from '../../utils/questionsUtils';
import { questionClient } from '../../clients';
import { QUESTIONS as STATIC_QUESTIONS } from '../../workoutQuestion';
import { WORKOUT_EDIT_TYPE } from '../../constants'

const styles = {
    addNewBlockBtn:{
        width:"172px",
        textAlign:"left"
    }
}

class WorkoutsNew extends NewActivityModule {
    constructor(props){
        super(props)
        this.state = {
            start:0,
            limit:25,
            name: '',
            tags: [],
            activity:{},
            activities: [],
            description: '',
            isCoachesOnly: false,
            workoutId: null,
            searchString: '',
            activityTags: [],
            totalActivities:0,
            isToastOpen: false,
            selectedIndex: null,
            isTagModalOpen: false,
            isSavingWorkout: false,
            filteredActivities: [],
            allFilteredActivities:[],
            isActivityModalOpen: false,
            workoutBlocks: [{ id: '0', activities: [] }],
            videoFileUploadProgress: 0,
            thumbnailUploadProgress:0,
            isFromWorkoutProgram: false,
            isLoading:true,
            deletedQuestionIds: new Set(),
            questionTags:[]
        }
    }

    componentWillMount = async () => {
        const { location, currentTeam, tenant } = this.props
        const { state } = location
        let questionTags = new Set();
        if(state && !!state.workoutId) {
            const workout = await getWorkout(state.workoutId);
            console.log('workout ', workout)
            const workoutBlocks = await resolveWorkoutBlocks(workout)
            this.setState({
                workoutBlocks,
                name: workout.name,
                workoutId: workout.id,
                description: workout.description,
                isCoachesOnly: workout.isCoachesOnly || false,
                tags: resolveTagsForModal(workout)
            })
        }
        let { data:{ results } } = await questionClient.getQuestionsByTeamId(currentTeam.id, 0, 10000);
        let questions = results || [];
        //format few values
        questions.forEach((question) => {
            question.description = question.description || "N/A";
            question.inputInfo = getQuestionInputInfo(question.type, question.options);
            question.answers = question.options || [];
            question.parentQuestionId = question.id,

            question.tags = (question.tags||[]).map(item => {
                questionTags.add(item.label);
                return item.label;
            })
            delete question.id;
        })
        await this.fetchActivities();
        this.setState({isLoading: false, questions, questionTags: Array.from(questionTags)})
    }

    componentDidMount() {
        let {toggleExternalClickDisable, location} = this.props;
        let { isFromWorkoutProgram, createdForWorkoutProgramId, type, workoutUniqueId } = (location.state||{});
        if(isFromWorkoutProgram){
            this.setState({isFromWorkoutProgram: true, createdForWorkoutProgramId, workoutType: type, workoutUniqueId});
            toggleExternalClickDisable(true);
        }
    }

    fetchActivities = async () =>{
        const { currentTeam } = this.props;
        let activities = await getTeamActivitiesElasticSearch(currentTeam.id) || {};
        //IMPORTANT NOTE: This is to correct legacy thumbnail and attachment data. Without this legacy thumbnail or attachment will have a bug.
        (activities.activities||[]).forEach(activity => {
            correctLegacyActivityData(activity)
            }
        );
        let tagsWithCategory = this.getTagsWithCategory(activities.activities||[])
        let allFilteredActivities = activities ? activities.activities||[]:[];
        let filteredActivities = allFilteredActivities.slice(parseInt(this.state.start), parseInt(this.state.start) + parseInt(this.state.limit));
        this.setState({ activities: activities.activities, totalActivities: activities.total, allFilteredActivities, start: this.state.limit, filteredActivities: filteredActivities, tagsWithCategory })
    }

    getTagsWithCategory = (activities) => {
        let tagsWithCategory = {};
        activities.forEach(activity => {
            if (activity.taggings && activity.taggings.length) {
                activity.taggings.forEach(tag => {
                    if (!tagsWithCategory[tag]) {
                        tagsWithCategory[tag] = new Set();
                    }
                    tagsWithCategory[tag].add(activity.category)
                })
            }
            else {
                if (!tagsWithCategory[""]) {
                    tagsWithCategory[""] = new Set();
                }
                tagsWithCategory[""].add(activity.category)
            }
        });
        return tagsWithCategory;
    }

    saveWorkout = async () => {
        const { currentTeam, currentUser, toggleExternalClickDisable } = this.props
        let { tags, name, workoutId, description, workoutBlocks, isFromWorkoutProgram, createdForWorkoutProgramId, deletedQuestionIds, workoutType, isCoachesOnly } = this.state
        let teamId = currentTeam.id;
        if(!name)
            return this.toggleToast()
        try{
            if(!!workoutType){
                if(workoutType == WORKOUT_EDIT_TYPE.ONE_TIME_EDIT){
                    workoutId = null;
                    teamId = "0";
                }
                else if(workoutType == WORKOUT_EDIT_TYPE.CREATE_NEW){
                    workoutId = null;
                }
            }
            this.props.enableFullScreenLoader(true, "Hang tight, we are saving your workout...")
            this.setState({ isSavingWorkout: true })
            const [savedWorkout, activityCount] = await resolveSavedWorkout(name, workoutId, description, workoutBlocks, teamId, tags, currentUser.id, true, createdForWorkoutProgramId, Array.from(deletedQuestionIds), isCoachesOnly)
            this.setState({ isSavingWorkout: false });
            if(isFromWorkoutProgram){
                this.navigateToWorkoutProgram(savedWorkout);
            }
            else{
                this.props.history.push('/workouts')
            }
            if (savedWorkout) {
                analyticsService.trackEvent('Workout Created', {
                    name: name,
                    id: workoutId,
                    tags: tags.toString(),
                    number_of_blocks: workoutBlocks.length,
                    number_of_activities: activityCount,
                    // Might be interesting to add the number of activities used from previous created activities
                    // and the number of new activities created, as well as the time it took to create the workout
                    // date: currentTimestamp
                });
            }
        }
        catch(e){
            if(!!e && !!e.response && e.response.status == 400){
                alert('Oops, Workout cannot have duplicate name!');
            }
            toggleExternalClickDisable(false)
        }
        this.props.enableFullScreenLoader(false, "")
    }

    toggleTagModal = () => {
        const { isTagModalOpen } = this.state
        this.setState({ isTagModalOpen: !isTagModalOpen })
    }

    onDragEnd = async result => {
        const { workoutBlocks, activities } = this.state
        const data = { result, activities, workoutBlocks }
        const { workoutBlock, blockItems } = await resolveActivityDrop(data)
        if(!workoutBlock || !blockItems)
            return
        let newWorkoutBlock = {...workoutBlock};
        if(workoutBlock.isQuestionBlock){
            newWorkoutBlock.questions = blockItems
        }
        else {
            newWorkoutBlock.activities = blockItems
        }
        const newState = { ...this.state, workoutBlocks: _.without(workoutBlocks, workoutBlock).concat(newWorkoutBlock), selectedIndex:null }
        this.setState(newState)
    }

    addNewBlock = async (isQuestionBlock) => {
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await resolveNewBlocks(workoutBlocks, isQuestionBlock)
        const newState = { ...this.state, workoutBlocks: newWorkoutBlocks }
        this.setState(newState)
    }

    handleActivityFilter = async (selectedCategoryFilter, selectedTagFilters, searchString) => {
        const { activities } = this.state
        let totalActivities = 0;
        let allFilteredActivities = await resolveActivitiesWithFilter(selectedCategoryFilter, selectedTagFilters, searchString, activities)
        totalActivities = allFilteredActivities.length;
        let filteredActivities = allFilteredActivities.slice(0, parseInt(0) + parseInt(this.state.limit));
        this.setState({ filteredActivities, totalActivities, start:this.state.limit, allFilteredActivities, selectedIndex:null, selectedCategoryFilter, selectedTagFilters })
    }

    handleSearch = async event => {
        const { activities } = this.state
        this.setState({ searchString: event.target.value })
        let totalActivities = 0;
        let allFilteredActivities =  await resolveActivitiesWithFilter(this.state.selectedCategoryFilter, this.state.selectedTagFilters, event.target.value, activities)
        totalActivities = allFilteredActivities.length;
        let filteredActivities = allFilteredActivities.slice(0, parseInt(0) + parseInt(this.state.limit));
        this.setState({ filteredActivities, totalActivities, start:this.state.limit, allFilteredActivities, selectedIndex:null })
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value })
    }

    duplicateWorkoutActivity = async activity => {
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await duplicateWorkoutActivity(workoutBlocks, activity)
        this.setState({ workoutBlocks: newWorkoutBlocks })
    }

    handleAddSetGroup = async activity => {
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await addNewWorkoutActivity(workoutBlocks, activity)
        this.setState({ workoutBlocks: newWorkoutBlocks })
    }

    handleDeleteForBlockItem = async (blockItem, workoutBlock) => {
        const { workoutBlocks } = this.state
        let newWorkoutBlocks = [];
        if(!!workoutBlock && workoutBlock.isQuestionBlock){
           let workoutBlockIndex = workoutBlocks.findIndex(block =>  block.id == workoutBlock.id);
           workoutBlocks[workoutBlockIndex].questions = workoutBlock.questions.filter(question => question.droppableId != blockItem.droppableId);
           newWorkoutBlocks = [...workoutBlocks];
        }
        else {
            newWorkoutBlocks = await deleteWorkoutActivity(workoutBlocks, blockItem)
        }
        this.setState({ workoutBlocks: newWorkoutBlocks })

    }

    duplicateWorkoutBlock = async workoutBlock => {
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await duplicateWorkoutBlock(workoutBlocks, workoutBlock)
        this.setState({ workoutBlocks: newWorkoutBlocks })
    }

    deleteWorkoutBlock = async workoutBlock => {
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await deleteWorkoutBlock(workoutBlocks, workoutBlock)
        this.setState({ workoutBlocks: newWorkoutBlocks })
    }

    changeBlockType = async (index, value) => {
        const { workoutBlocks } = this.state
        workoutBlocks[index].type = workoutBlocks[index].type == value ? null : value
        this.setState({ workoutBlocks })
    }

    changeWorkoutActivity = async (activityId, property, value) => {
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await updateWorkoutActivity(workoutBlocks, activityId, property, value)
        this.setState({ workoutBlocks: newWorkoutBlocks })
    }

    changeActivity = async (activity, property, value) => { // this is for properties on an activity that cascade to related workout activities
        const { workoutBlocks } = this.state
        const newWorkoutBlocks = await updateActivity(workoutBlocks, activity.id, property, value)
        this.setState({ workoutBlocks: newWorkoutBlocks })
    }

    handleNewTag = (tag) => {
        const { tags } = this.state
        this.setState({
            tags: tags.concat([{name: tag}]),
        })
    }

    removeTag = tag => {
        let tags = this.state.tags
        if(tag)
            return this.setState({ tags: _.without(tags, tag) })
        tags.pop()
        this.setState({ tags: tags })
    }

    saveTags = () => {
        const { tags, workoutId } = this.state
        if(workoutId)
            saveTags(tags, workoutId)

        this.toggleTagModal()
    }

    handleActivityTag = (tag) =>{
        const { activityTags } = this.state
        this.setState({
            activityTags: activityTags.concat([{name: tag}]),
        })
    }

    //TODO: Move to activityModule
    handleActivitySave = async (addingAnother) => {
        const {currentTeam, currentUser} = this.props;
        let { activity, activityTags, filteredActivities, start, searchString} = this.state;
        let clonedActivity = {...activity};
        activityTags = _.uniq(activityTags)
        clonedActivity.isPercentOfMax = clonedActivity.scoreType == 'weight' ? clonedActivity.isPercentOfMax: false;
        this.setState({displaySuccessOverlay:true, overlayMessage:(clonedActivity.isNewActivity? "Saving Activity":"Updating Activity")})
        clonedActivity.id = await saveActivity(clonedActivity, currentUser, currentTeam, activityTags, false, this.uploadingFile);
        let newActivities = this.state.activities||[];
        clonedActivity.taggings = (activityTags||[]).map(item => item.name);
        let isNewActivity = clonedActivity.isNewActivity;
        if(isNewActivity){
            clonedActivity.isNewActivity = false;
            newActivities = newActivities.concat(clonedActivity);
        }
        else{
            let existingActivityIndex =  newActivities.findIndex(element => element.id === clonedActivity.id);
            newActivities[existingActivityIndex] = clonedActivity;
        }
        let tagsWithCategory = this.getTagsWithCategory(newActivities);
        this.setState({activities: newActivities, tagsWithCategory},()=> this.handleActivityFilter(this.state.selectedCategoryFilter, this.state.selectedTagFilters, searchString));
        this.setState({overlayMessage:isNewActivity?"Activity Created":"Activity Updated"})
        setTimeout(()=>{
            this.toggleActivityModal(null, addingAnother);
        },2000);
    }

    loadMoreActivities = async() => {
      const { currentTeam } = this.props;
      const { searchString } = this.state;
      const start = parseInt(this.state.start) + parseInt(this.state.limit);
      let activities = this.state.allFilteredActivities.slice(0, start);
      this.setState({ start: start, filteredActivities: activities })
    }

    cancelUpload = () =>{
        this.setState({cancelUpload:true, uploadingVideoFile: false});
    }

    handleSelectItem = (index, item) => {
      this.setState({selectedIndex: index})
    }

    editActivity = (item) =>{
        let clonedActivity = _.clone(item);
        this.setState({activity:clonedActivity});
        this.toggleActivityModal(clonedActivity);
    }

    toggleToast = () => {
        const { isToastOpen } = this.state
        this.setState({ isToastOpen: !isToastOpen })
    }


    navigateToWorkoutProgram = (savedWorkout) => {
        let { workoutId, workoutUniqueId } = this.state;
        let { toggleExternalClickDisable, history } = this.props;
        if(!!savedWorkout){
            window.localStorage.setItem("redirectedFromWorkout", Date.now());
            window.localStorage.setItem("savedWorkout", JSON.stringify(savedWorkout));
            window.localStorage.setItem("workoutUniqueId", workoutUniqueId);
        }
        toggleExternalClickDisable(false);
        history.goBack();
    }

    setQuestion = (question, workoutBlock) =>{
        const { workoutBlocks } = this.state
        let questionDetails = { ...question, droppableId: shortid.generate(), isNew: true}
        const newWorkoutBlock = { ...workoutBlock, questions: (workoutBlock.questions||[]).concat(questionDetails) }
        this.setState({workoutBlocks: _.without(workoutBlocks, workoutBlock).concat(newWorkoutBlock)})
    }

    render(){
        const { currentTeam, classes, tenant } = this.props
        const {
            name,
            tags,
            start,
            limit,
            activity,
            activities,
            isToastOpen,
            description,
            isCoachesOnly,
            errorMessage,
            activityTags,
            selectedIndex,
            workoutBlocks,
            overlayMessage,
            isTagModalOpen,
            totalActivities,
            isSavingWorkout,
            uploadingVideoFile,
            isActivityModalOpen,
            displaySuccessOverlay,
            uploadingThumbnailFile,
            isDescriptionModalOpen,
            thumbnailUploadProgress,
            videoFileUploadProgress,
            filteredActivities = [],
            tagsWithCategory,
            invalidVideoUrl,
            isFromWorkoutProgram,
            isLoading,
            questions,
            questionTags
        } = this.state
        console.log('coachesOnly ', isCoachesOnly)
        return(
            <PageWrapper title="Workouts" {...this.props} isPageLoading={isLoading}>
            <div className={'newWorkoutOuterContainer'}>
                <TagModal
                    tags={tags}
                    open={isTagModalOpen}
                    saveTags={this.saveTags}
                    errorMessage={errorMessage}
                    close={this.toggleTagModal}
                    removeTag={tag => this.removeTag(tag)}
                    handleNewTag={tag => this.handleNewTag(tag)}
                    currentTeam={currentTeam}
                />
                <NewWorkoutActivityModal
                    tags={activityTags}
                    activity={activity}
                    activities={activities}
                    open={isActivityModalOpen}
                    save={this.handleActivitySave}
                    addTag={this.handleActivityTag}
                    overlayMessage = {overlayMessage}
                    currentTeam={currentTeam}
                    close={()=>this.toggleActivityModal()}
                    handleFileSelect={this.handleFileSelect}
                    uploadingVideoFile = {uploadingVideoFile}
                    removeActivityVideo={this.removeActivityVideo}
                    removeTag={tag => this.removeActivityTag(tag)}
                    displaySuccessOverlay = {displaySuccessOverlay}
                    handleVideoUrlChange={this.handleVideoUrlChange}
                    uploadingThumbnailFile = {uploadingThumbnailFile}
                    videoFileUploadProgress={videoFileUploadProgress}
                    thumbnailUploadProgress={thumbnailUploadProgress}
                    handleThumbnailSelect={this.handleThumbnailSelect}
                    handleChange={name => this.handleActivityChange(name)}
                    invalidVideoUrl={invalidVideoUrl}
                    cancelUpload={this.cancelUpload}
                />
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div className={'newWorkoutInnerContainer'}>
                        <div className={'newWorkoutWorkoutContainer'}>
                            <div className={'newWorkoutTitleContainer'}>
                                <div className={'titleTextContainer'}>
                                    <div className={'workoutTitleContainer'}>
                                        <EditableText
                                            value={name}
                                            placeholder={'Workout Name'}
                                            handleChange={event => this.handleChange('name', event.target.value)}
                                        />
                                    </div>
                                    <EditableText
                                        isSmall={true}
                                        value={description}
                                        placeholder={'Description - Click to Add'}
                                        handleChange={event => this.handleChange('description', event.target.value)}
                                    />
                                </div>
                                <div className={'titleButtonContainer'}>
                                    <IconButton
                                        text={'TAGS'}
                                        position={'left'}
                                        color={'white'}
                                        textColor={'gray'}
                                        onClick={this.toggleTagModal}
                                        icon={<LocalOfferIcon style={{ color: 'grey', width: 15, padding: 0 }} />}
                                    />
                                    <IconButton
                                        disabled={!name}
                                        position={'left'}
                                        color={'#27AE60'}
                                        text={'SAVE WORKOUT'}
                                        disableColor={"#A9DFBF"}
                                        onClick={this.saveWorkout}
                                        icon={<SaveIcon style={{ color: 'white', width: 25, paddingLeft: 5 }} />}
                                    />
                                    {isFromWorkoutProgram &&
                                        <IconButton
                                            width= "88px"
                                            color={'white'}
                                            textColor={'gray'}
                                            text={'BACK'}
                                            onClick={() => this.navigateToWorkoutProgram()}
                                        />
                                    }
                                </div>
                            </div>
                            <div className={classNames("flex-row", classes.formRowMargin)} style={{ paddingBottom: 10, marginTop: -10 }}>
                                <div className={classNames(classes.toggleSwitchContainer, "flex-row")}>
                                    <div className={classes.subHead} style={{marginRight: 10}}>Access Controls:</div>
                                    <ToggleButton
                                        buttonEnabledText="All Users"
                                        buttonDisabledText="Coaches"
                                        value={!isCoachesOnly}
                                        onChange={event => this.handleChange('isCoachesOnly', !event.target.value)} />
                                </div>
                            </div>
                            <div className={'workoutItemsContainer'}>
                                {_.orderBy(workoutBlocks, 'id').map((workoutBlock, index) => {
                                    return(<div key={index} className={'newWorkoutBuilderContainer'}>
                                        <WorkoutBlock
                                            questionTags={questionTags}
                                            currentTeam={currentTeam}
                                            workoutBlock={workoutBlock}
                                            handleDelete={() => this.deleteWorkoutBlock(workoutBlock)}
                                            handleDuplicate={() => this.duplicateWorkoutBlock(workoutBlock)}
                                            handleChangeBlockType={value => this.changeBlockType(index, value)}
                                            handleDeleteForBlockItem={(blockItem) => this.handleDeleteForBlockItem(blockItem, workoutBlock)}
                                            handleDuplicateActivity={this.duplicateWorkoutActivity}
                                            handleAddSetGroup={this.handleAddSetGroup}
                                            handleActivityChange={this.changeActivity}
                                            handleScoreType={(option, activityId) => this.changeWorkoutActivity(activityId, option.id, 'scoreType')}
                                            handleWorkoutActivityChange={this.changeWorkoutActivity}
                                            setQuestion={this.setQuestion}
                                            questions={questions}
                                        />
                                    </div>)
                                })}
                                <div className={'workoutButtons'}>
                                    <div className={'newBlockButtonContainer'}>
                                        <IconButton
                                            width={"172px"}
                                            position={'left'}
                                            color={'#1354F9'}
                                            text={'ADD NEW BLOCK'}
                                            onClick={()=>this.addNewBlock()}
                                            icon={<p className={'addIcon'}>+</p>}
                                        />
                                       {tenant != tenantMap.vnn &&
                                         <IconButton
                                            overrideContainerClassName={classes.addNewBlockBtn}
                                            position={'left'}
                                            color={'#1354F9'}
                                            text={'ADD NEW QUESTION BLOCK'}
                                            onClick={() => this.addNewBlock(true)}
                                            icon={<p className={'addIcon'}>+</p>}
                                        />
                                       }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'newWorkoutActivitiesContainer'}>
                            <FilterableSideList
                                start={start}
                                limit={limit}
                                items={activities}
                                onDragEnd={this.onDragEnd}
                                title={'Activity Categories'}
                                selectedIndex={selectedIndex}
                                editItem = {this.editActivity}
                                totalActivities={totalActivities}
                                filteredItems={filteredActivities}
                                search={e => this.handleSearch(e)}
                                selectItem={this.handleSelectItem}
                                currentTeam={this.props.currentTeam}
                                loadMoreActivities={this.loadMoreActivities}
                                filterSelect={(selectedCategoryFilter,selectedTagFilter, searchString) => this.handleActivityFilter(selectedCategoryFilter, selectedTagFilter, searchString)}
                                tagsWithCategoryFilter={tagsWithCategory}
                                toggleOverlay={this.props.toggleOverlay}
                            />
                            <div className={'workoutButtons'}>
                                <IconButton
                                    color={'#ADB4B9'}
                                    position={'left'}
                                    textColor={'white'}
                                    text={'NEW ACTIVITY'}
                                    icon={<p className={'addIcon'}>+</p>}
                                    onClick={()=>this.toggleActivityModal()}
                                />
                                <IconButton
                                    disabled={!name}
                                    position={'left'}
                                    color={'#27AE60'}
                                    text={'SAVE WORKOUT'}
                                    onClick={this.saveWorkout}
                                    disableColor={"#A9DFBF"}
                                    icon={<SaveIcon style={{ color: 'white', width: 25, paddingLeft: 5 }} />}
                                />
                            </div>
                        </div>
                    </div>
                </DragDropContext>
                <div className={'toastMessageContainer'}>
                    <ToastMessage
                        open={isToastOpen}
                        close={this.toggleToast}
                        text={'Oops, you need to save a name'}
                    />
                </div>
            </div>
        </PageWrapper>
        )
    }
}

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = userContext && userContext.appContextList ? userContext.appContextList.find(c => c.id === appContext.id):{};
    return {
        currentTeam: currentTeam||{},
        currentUser: userContext.user,
        libraries: appContext.libraries||[]
    }
}

export default withStyles(styles)(connect(mapStateToProps)(WorkoutsNew));
