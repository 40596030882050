import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import GVNLogo from '../assets/images/themes/gvn/gvn-logo.png';
import homeImage from '../assets/images/themes/upward/upward-welcome.png';

// Used in App.js for mostly Nav Components.

export default createMuiTheme({
    typography: {
        fontFamily: 'Poppins'
    },
    overrides: {
        MuiButton: {
            root:{
                borderRadius:"5px"
            },
            fab:{
                borderRadius:"5px"
            },
            containedPrimary:{
                "background-color":"#1354F9",
                color:"#ffffff"
            }
        },
        MuiTab:{
            selected:{
                color: "#1354F9 !important"
            }
        },
    },
    product: {
        name: 'GVN Performance',
        appUrl: 'm1-gvn://',
        domain: 'gvn.gomaxone.com',
        link: 'https://apps.apple.com/us/app/id1544818584',
        onAppleAppStore: 'https://apps.apple.com/us/app/id1544818584',
        onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.gvn'
    },
    image: {
        home: homeImage,
        logo: GVNLogo,
        loginLogo: GVNLogo
    },
    palette: {
        type: "light",
        primary: {
            main: "#474747"
        },
        secondary: {
            main: "#A80919",
            isDarkMode: true,
        },
        white: "white",
        error: red,
    },
    paletteSecondary: {
        type: "dark",
        primary: {
            main: "#A80919"
        },
        secondary: green,
        error: red,
    }

});
