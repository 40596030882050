import React, { Component } from 'react';
import _ from 'lodash';
import './nav.css';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import VNNPaywall from '../../components/modals/vnnPaywall'
import SideBarNavigator from '../../components/v2/navigation/SideBarNavigator';

const drawerWidth = 280

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  disableDrawer: {
    opacity: 0.3,
    pointerEvents: "none"
  },
  content: {
    position: "relative",
    overflowY: "scroll",
    flexGrow: 1,
    backgroundColor: theme.paletteSecondary.background,
    width: "100vw",
    marginTop: 45,
    marginLeft: 70,
    minHeight: '100vh',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    overflowY: "scroll",
    flexGrow: 1,
    backgroundColor: theme.paletteSecondary.background,
    minHeight: 'calc(100vh - 51px)',
    marginLeft: 280,
    marginTop: 50,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }
});


class Nav extends Component {

  state = {
    isOpen: true,
    isPaywallOpen: false
  }

  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  render() {

    const { children, disableExternalClick, classes, history, location, enableFullScreenLoader } = this.props
    const { isOpen, isPaywallOpen } = this.state

    return (
      <div className={classNames(classes.root, { "drawerOpen": isOpen, 'drawerClosed': !isOpen, "disableDrawer": disableExternalClick })}>
        <SideBarNavigator
          isOpen={isOpen}
          history={history}
          location={location}
          handleChange={(key, value) => this.handleChange(key, value)}
          disableExternalClick={disableExternalClick}
          enableFullScreenLoader={enableFullScreenLoader}
        />
        <VNNPaywall
          title={'director'}
          open={isPaywallOpen}
          toggle={() => this.handleChange('isPaywallOpen', !isPaywallOpen)}
        />
        <main className={classNames(classes.content, isOpen && classes.contentShift)} style={{ overflowY: 'hidden' }}>
          {children}
        </main>
      </div>
    )
  }
}

export default withStyles(styles)(Nav)
