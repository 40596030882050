import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Search from '../../icons/Search';
import CloseIcon from '../../icons/CloseIcon';

import './index.scss';

const Component = ({ searchSubmit, loadingMessage, searchBarOnChange = null, routeId=null }) => {
	const [inputValue, setInputValue] = useState('');

	function closeButtonClick(event) {
		setInputValue('');
		searchSubmit(event, '');
	}

	function inputChange(event) {
		setInputValue(event.target.value);
		searchSubmit(event, event.target.value);
		if (searchBarOnChange) {
			searchBarOnChange(event);
		}
	}

	useEffect(() => {
		setInputValue('')
	}, [routeId]);

	useEffect(() => {
		if(loadingMessage == 'deleting activities' || 'deleting activity'){
			closeButtonClick(null)
		}
	}, [loadingMessage]);

	return (
		<form
			onSubmit={(event) => {
				searchSubmit(event, inputValue);
			}}
			className='search-bar-component'>
			<button type='submit' className='button search-button'>
				<Search />
			</button>
			<input
				type='text'
				className='search-bar'
				value={inputValue}
				onChange={inputChange}
			/>
			<button
				type='reset'
				className='button close-button'
				onClick={closeButtonClick}>
				<CloseIcon />
			</button>
		</form>
	);
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;
