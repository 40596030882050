import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProgressStep from '../progressStep';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import SportsEngineBtn from '../../../components/themeButton';
import { sportsEngineClient, userImportClient } from '../../../clients'
import { ROUTE_PATH } from "../../../routeUtil";
import { testId } from '../../../utils/test-utils';
const styles = {
    importTitle: {
        fontWeight: 600,
        color: "#0B0B0B",
        marginBottom: "12px"
    },
    registrationInfo: {
        padding: "30px",
        background: "#F8F8F8",
        borderRadius: "8px",
        marginBottom: "30px"
    },
    radioRoot: {
        width: "24px",
        height: "24px",
        marginRight: "16px",
        paddingLeft: "25px",
    },
    registrationTitle: {
        marginBottom: "22px",
        fontWeight: "600"
    },
    labelTxt: {
        color: "#27292A",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        opacity: 1,
        "&.labelSelected": {
            color: "#27292A",
            opacity: 1,
            "& .regBox": {
                background: "#1354F9"
            }
        }
    },
    regTxt: {
        background: "#75797B",
        borderRadius: "1000px",
        padding: "2px 6px",
        color: "#FFFFFF",
        fontWeight: "600",
        fontSize: "12px",
        marginLeft: "4px"
    },
    radioChecked: {
        color: "#1354F9 !important"
    },
    disabled:{
        background:"#F93F3F"
    }
};

class Step1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            registrations:[],
            isLoading:true,
        }
    }

    componentDidMount(){
        this.fetchRegistration();
    }

    fetchRegistration = () => {
        let { accessToken, enableCSVProcess } = this.props;
        this.setState({loadingMessage1:"Loading Registration Data.", loadingMessage2:"Hang Tight"})
        sportsEngineClient.getRostersByAccessToken(accessToken).then(({ data }) => {
            if((data||[]).length == 0){
                this.setState({loadingMessage1:"No access to registration. Redirecting to CSV Import.", loadingMessage2:"Hang Tight", });
                setTimeout(()=>{
                    enableCSVProcess();
                }, 2000);
            }
            else {
                this.setState({ registrations: data, isLoading: false });
            }
        }).catch((e) => {
            this.setState({ isLoading: false });
        });
    }


    handleRegistrationChange = (tempSelectedId) => {
        this.setState({ tempSelectedId });
    }

    render() {
        const { classes, loadRegistration, registrationSelectedId, currentStep, totalSteps } = this.props;
        const { registrations = [], isLoading, loadingMessage1, loadingMessage2, tempSelectedId } = this.state;
        const registrationId = tempSelectedId || registrationSelectedId;
        return (
            <React.Fragment>
                <HeaderWrapper>
                    <ProgressStep stepDetails={{
                        "title": "Import Athletes from SportsEngine",
                        "nextStep": "Field Mapping"
                    }} currentStep={currentStep} totalSteps={totalSteps}/>
                </HeaderWrapper>
                <BodyWrapper isLoading={isLoading} loadingMessage1={loadingMessage1} loadingMessage2={loadingMessage2}>
                    <div className={classes.importInfo}>
                        <div className={classes.importTitle}>
                            Select a SportsEngine Registration to Import
                    </div>
                        <div className={classes.registrationInfo}>
                            <div className={classes.registrationTitle}>Maxone</div>
                            <div className={classes.registrationOptions}>
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender2"
                                    classes={{
                                        root: classes.root,
                                        row: classes.row
                                    }}
                                    className={classes.group}
                                    value={registrationId}
                                    onChange={(event) => this.handleRegistrationChange(Number(event.target.value))}
                                >
                                    {registrations.map(registration =>
                                        <FormControlLabel
                                            disabled={!registration.total}
                                            value={registration.id}
                                            control={<Radio color="primary" classes={{ root: classes.radioRoot, checked: classes.radioChecked }}  {...testId(`registration-${registration.id}`)} />}
                                            label={<span className={classnames(classes.labelTxt, { "labelSelected": registrationId == registration.id })}>{registration.name}<span className={classnames(classes.regTxt, "regBox", {[classes.disabled]:!registration.total})}>{registration.total}</span></span>}
                                            labelPlacement="end"
                                        />
                                    )}
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end" {...testId(`submit-button`)}>
                            <SportsEngineBtn
                                width={"315px"}
                                height={"50px"}
                                iconName="Download"
                                onClick={()=>loadRegistration(registrationId)}
                                disabled={!registrationId}
                                text="Load Selected Registration"
                            />
                        </div>
                    </div>
                </BodyWrapper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Step1);