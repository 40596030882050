import { SIDEBAR_SHOW, SIDEBAR_HIDE, SIDEBAR_TOGGLE } from '../types/sidebar';

const initState = {
  title: 'UNKNOWN',
  template: null,
  visible: false
};

export default (state = initState, action) => {
  switch(action.type) {
    case SIDEBAR_SHOW:
      return {
        ...state,
        title: action.payload.title,
        template: action.payload.template ? action.payload.template : null,
        visible: true
      };
    case SIDEBAR_HIDE:
      return {
        ...state,
        visible: false
      };
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        visible: !state.visible
      };
    default :
      return state;
  }
}
