import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash"
import { API, Storage } from "aws-amplify";
import { TeamSubscriptionOptionScreen } from 'diamond-subscription';

// Material UI
import {
    Button,
    Divider,
    Grid,
    Switch,
    Paper,
    TextField,
    Typography,
    Tabs,
    Tab,
    Select,
    MenuItem,
    IconButton,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
    Input
} from "@material-ui/core";

import planPromo from "../../assets/images/signup/signup-bg-callout.png"
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import ContextService from '../../services/context';
import PageWrapper from '../nav/pageWrapper';
import { biabClient } from '../../clients'

const contextService = new ContextService()

const styles = theme => ({
    container:{
        background: "white",
        padding: "15px",
        borderRadius: "5px",
        marginTop: "15px"
    }
});

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const { classes, tenant, currentTeam } = this.props;
        return (
            <PageWrapper title={"Subscription Options"} {...this.props}>
                <div className={classes.container}>
                    <TeamSubscriptionOptionScreen tenant={tenant} teamId={currentTeam.id} />
                </div>
            </PageWrapper>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        userContext: userContext,
        appContext: appContext
    };
}

export default withStyles(styles)(connect(mapStateToProps)(Settings));
