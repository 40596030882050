import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"

import { analyticsService, currentTimestamp } from '../../services/analyticsService';

import { NewWorkoutProgram } from 'maxone-workout-programs';
import PageWrapper from '../nav/pageWrapper';
class WorkoutProgramsNew extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false }
    }

    render() {
        const { isLoading, loadingMessage } = this.state
        const { classes, appContext, currentTeam, location } = this.props
        let workoutProgram = null;
        if(location.state){
            workoutProgram = location.state.workoutProgram;
        }
        return (
            <PageWrapper title="Workout Programs" {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
                <div>
                    <NewWorkoutProgram teamId={currentTeam.id} programId={(workoutProgram || {}).id}
                                        onProgramCreate={workoutProgramObject => analyticsService.trackEvent('Program Created',
                                            {
                                                name: workoutProgramObject.name,
                                                id: workoutProgramObject.id,
                                                weeks: workoutProgramObject.weeks,
                                                team_id: workoutProgramObject.team_id,
                                            }
                                        )} {...this.props} />
                </div>
            </PageWrapper>
        );
    }
}

WorkoutProgramsNew.propTypes = { };

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        currentUser: userContext.user,
        appContext: appContext,
        currentTeam: currentTeam||{}
    };
}

export default (connect(mapStateToProps)(WorkoutProgramsNew));
