import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
let styles = {
  searchBox: {
    border: "1px solid #DDE2E5",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: "100%",
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    "& input": {
      border: "none",
      width: "100%",
      height: "100%",
      "&:focus": {
        outline: "none",
      },
    },
    "& svg": {
      fill: "rgba(11,11,11,0.3)",
    },
  },
  notAllowed: {
    cursor: "not-allowed",
  },
};

const SearchInput = (props) => {
  let {
    handleSearch,
    customClass,
    searchTerm,
    placeholder,
    classes,
    disabled = false,
  } = props;
  const [inputValue, setInputValue] = useState(searchTerm);

  const handleChange = (value) => {
    setInputValue(value);
    handleSearch(value);
  };

  return (
    <div className={classnames(classes.searchBox, customClass)}>
      <SearchIcon />
      <input
        disabled={disabled}
        placeholder={placeholder}
        value={inputValue}
        className={
          disabled
            ? `${(classes.search, classes.notAllowed)}`
            : `${classes.search}`
        }
        onChange={(event) => handleChange(event.target.value)}
      />
      {!!searchTerm && <CancelIcon onClick={() => handleChange("")} />}
    </div>
  );
};

export default withStyles(styles)(SearchInput);
