import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const ConfirmationDialog = ({ className, buttonClassName, open, headTxt, content, cancelButtonTxt, agreeButtonTxt, handleClose }) => {
    return (
        <div>
            <Dialog
                fullScreen={false}
                open={open}
                onClose={()=>handleClose()}
                className={className}
            >
                {headTxt && <DialogTitle>{headTxt}</DialogTitle>}
                {content && 
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>}
                <DialogActions>
                    <Button onClick={()=>handleClose()} className={buttonClassName}>
                        {cancelButtonTxt}
                    </Button>
                    <Button onClick={() => handleClose(true)} className={buttonClassName}>
                        {agreeButtonTxt}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmationDialog.defaultProps = {
    cancelButtonTxt: "CANCEL",
    agreeButtonTxt: "YES"
}

export default withMobileDialog()(ConfirmationDialog);
