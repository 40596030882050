import React from 'react';
import {
    Drawer
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../index.scss';
import Menu from '../components/Menu';
import Header from '../components/Header';
import SelectedTeam from '../components/SelectedTeam';

const drawerWidth = 280;

const styles = theme => ({
    drawerPaper: {
        backgroundColor: '#000000',
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    disableDrawer: {
        opacity: 0.3,
        pointerEvents: "none"
    },
    drawerPaperClose: {
        backgroundColor: '#000000',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 8 * 7,
        [theme.breakpoints.up('sm')]: {
            width: 8 * 9,
        }
    }
});

const Component = ({ menu, header, selectedTeam, isOpen, classes, disableExternalClick }) => {
    
    return (
        
        <Drawer
            variant="permanent"
            classes={{ paper: classNames(classes.drawerPaper, !isOpen && classes.drawerPaperClose, { [classes.disableDrawer]: disableExternalClick }) }}
            className={'forceOverlay'}
            open={isOpen}
        >
            <div className={classNames("sidebar-container", { open: isOpen, closed: !isOpen })}>
                <div className={"divider"}>
                    <Header {...header} isOpen={isOpen} />
                    <SelectedTeam {...selectedTeam} isOpen={isOpen}/>
                </div>
                {
                  
                    (menu || []).map((item, index) => {
                        return (
                            <div className={"divider"}>
                                <Menu
                                    key={index}
                                    isOpen={isOpen}
                                    {...item}
                                />
                            </div>
                        )
                    })
                }
                <div style={{height: '70px'}}></div>
            </div>
        </Drawer>
    );
}

Component.propTypes = {
    menu: PropTypes.object,
    isOpen: PropTypes.bool
};

Component.defaultProps = {

};

export default withStyles(styles)(Component)
