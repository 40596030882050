import React, { Component } from 'react'

import CustomFilter from '../customFilter'

import CloseIcon from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';

import './styles.css'

export default class BorderedInput extends Component {
    showTeamFilter = () => {
        const { searching, showFilter } = this.props
        return !!searching && !!showFilter
    }

    showPositionFilter = () => {
        const { searching, showSecondaryFilter } = this.props
        return !!searching && !!showSecondaryFilter
    }

    render(){
        const {
            width,
            style,
            loading,
            textArea,
            leftIcon,
            rightIcon,
            clickable,
            adornment,
            helperText,
            value = '',
            handleClick,
            placeholder,
            filterOptions,
            selectedOption,
            onAdornmentClick,
            disabled = false,
            searching = false,
            showFilter = false,
            onChange = () => {},
            handleSelectFilter,
            secondaryFilterOptions,
            secondarySelectedOption,
            handleSelectSecondaryFilter,
            icon
        } = this.props

        return(
            textArea ? 
                <div className={'borderedTextAreaContainer'}>
                    <textarea 
                        rows={4}
                        style={style}
                        type={'text'} 
                        value={value ? value : ''} 
                        className={'borderedInput'}
                        onChange={onChange} 
                        disabled={!!disabled}
                        placeholder={placeholder ? placeholder : null}
                    />
                </div>
            :

            helperText ?
                <div className={'borderedInputContainerWithHelper'}>
                    <div className={'borderedInputContainer'}>
                        <input 
                            type={'text'} 
                            value={value ? value : ''} 
                            className={'borderedInput'}
                            onChange={onChange} 
                            disabled={!!disabled}
                            placeholder={placeholder ? placeholder : null}
                        />
                        {adornment &&
                            <div 
                                onClick={onAdornmentClick}
                                className={'adornmentContainer'} 
                            >
                                {adornment}
                            </div>
                        }
                    </div>
                    <p className={'helperText'}>{helperText}</p>
                </div>
            :
            clickable ?
                <div onClick={() => handleClick()} className={'borderedInputContainer'}>
                    {loading ?
                        <p className={'clickablePlaceholder'}>Loading...</p>
                    : value ? 
                        <p className={'clickableValueText'}>{value}</p>
                    :
                        <p className={'clickablePlaceholder'}>{placeholder}</p>
                    }
                    {rightIcon && rightIcon}
                </div>
            :
                <div className={adornment ? 'borderedInputContainerWithAdornment' : 'borderedInputContainer'} onClick={handleClick ? () => handleClick() : null}>
                    {leftIcon && leftIcon}
                    <input 
                        type={'text'} 
                        onChange={onChange} 
                        disabled={!!disabled}
                        value={value ? value : ''} 
                        className={'borderedInput'}
                        placeholder={loading ? 'Loading...' : placeholder ? placeholder : null}
                    />
                    {this.showTeamFilter() && <CustomFilter handleSelectFilter={handleSelectFilter} title={'Team'} value={selectedOption} placeholder={'All Teams'} filterOptions={filterOptions} />}
                    {this.showPositionFilter() && <CustomFilter handleSelectFilter={handleSelectSecondaryFilter} title={'Position'} value={secondarySelectedOption} placeholder={'All Positions'} filterOptions={secondaryFilterOptions} />}
                    {adornment &&
                        <div 
                            onClick={onAdornmentClick}
                            className={'adornmentContainer'} 
                        >
                            {!!loading ? <CircularProgress style={{ height: null, width: null }} /> : searching ? <CloseIcon/> : adornment}
                        </div>
                    }
                </div>
        )
    }
}