export default {
    s3: {
      REGION: "us-east-1",
      BUCKET: "programax-videos-production"
    },
    s3Legacy: {
      REGION: "us-east-1",
      BUCKET: "programax-videos-production"
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_i08Xsesfm",
      APP_CLIENT_ID: "4qiob5qb5g9mn8ktki61pn45t8",
      IDENTITY_POOL_ID: "us-east-1:9c2be610-ca5a-4bab-a152-b950357779a3"
    }
  }
