import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import Modal from "@material-ui/core/Modal";
import classnames from "classnames";
import ThemeButton from "../../components/themeButton";
let styles = {
  modalContainer: {
    position: "absolute",
    backgroundColor: "white",
    overflowY: "scroll",
    fontFamily: "Poppins !important",
    border: "1px solid #DDE2E5",
    boxSizing: "border-box",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  modalSubContainer: {
    padding: "20px 40px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "14px",
  },
  heading: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "110%",
    color: "#222127",
  },
  modalFooterContainer: {
    height: "74px",
    minHeight: "74px",
    width: "100%",
    padding: "0 40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#EDEFF0",
    borderRadius: "0px 0px 8px 8px",
  },
  activityModal: {
    overflow: "scroll",
  },
  bottomPadding: {
    paddingBottom: "30px",
  },
};

const DefaultLeftButton = (props) => {
  return (
    <ThemeButton
      width={"103px"}
      iconColor={"#75797B"}
      textColor={"#0B0B0B"}
      color={"#ffffff"}
      height={"44px"}
      position="left"
      iconName="Cancel"
      theme="small"
      text="Cancel"
      {...props}
    />
  );
};

const DefaultRightButton = (props) => {
  return (
    <ThemeButton
      width={"115px"}
      textColor={"#ffffff"}
      color={"#27AE60"}
      height={"44px"}
      position="left"
      iconName="Plus"
      theme="small"
      {...props}
    />
  );
};

const ModalContainer = (props) => {
  const {
    open,
    onClose,
    leftBtn,
    rightBtn,
    children,
    classes,
    width,
    heading,
    customPadding,
    additionalTopSection,
    centerElement,
    useDefaultLeftBtn,
    useDefaultRightBtn,
    defaultLeftBtnProps,
    defaultRightBtnProps,
    overflow,
    customButton,
    showFooter = true,
    showCloseIcon = true,
    disableEnforceFocus,
    backgroundColor,
    height,
  } = props;
  const customLeftBtn = showFooter
    ? (useDefaultLeftBtn && <DefaultLeftButton {...defaultLeftBtnProps} />) ||
      leftBtn
    : null;
  const customRightBtn = showFooter
    ? (useDefaultRightBtn && (
        <DefaultRightButton {...defaultRightBtnProps} />
      )) ||
      rightBtn
    : null;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      className="activityModal"
      disableEnforceFocus={disableEnforceFocus}
      onClose={onClose}
    >
      <div
        style={{
          width: width,
          overflow: overflow,
          backgroundColor: backgroundColor || "",
          height: height || "auto",
        }}
        className={classes.modalContainer}
      >
        <div
          style={{ padding: customPadding || null }}
          className={classes.modalSubContainer}
        >
          <div className={classes.modalHeader}>
            <div className={classes.heading}>{heading}</div>
            {showCloseIcon && (
              <CancelIcon
                className="icon"
                style={{ opacity: 0.3, cursor: "pointer" }}
                onClick={onClose}
              />
            )}
            {customButton ? customButton : null}
          </div>
          {additionalTopSection}
          {children}
        </div>
        {showFooter && (
          <div className={classes.modalFooterContainer}>
            {customLeftBtn}
            {centerElement}
            {customRightBtn}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default withStyles(styles)(ModalContainer);
