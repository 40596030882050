import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { API } from "aws-amplify";
import AWS from "aws-sdk";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography  
 } from "@material-ui/core";

 import LanguageIcon from "@material-ui/icons/Language"

import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import {Storage} from "aws-amplify";

import { getLocalizedString } from '../../utils/locale-utils';
import { formatMeasureValueForDisplaying } from '../../utils/activityUtils'


const styles = theme => ({
  circleProgressColor: {
    color: theme.palette.primary.main,
  },
});

// var currentSession = await Auth.currentSession()
// Log.debug('currentSession ',currentSession)
// logins[`cognito-idp.us-east-1.amazonaws.com/${Config.USER_POOL_ID}`] = currentSession.getIdToken().getJwtToken()
// Log.debug('Config.logins ',logins)
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: Config.IDENTITY_POOL_ID,
//   Logins:logins
// });

class ActivityLeaderboards extends Component {
  constructor(props) {
      super(props);

      this.state = {
          isLoading: false,
          leaderboards: []
      };
  }
  async componentWillReceiveProps(props, nextProps){
    if(this.state.currentTeam && props.currentTeam && props.currentTeam.id !== this.state.currentTeam){
      if(props.currentTeam.id !== this.state.currentTeam){
        await this.componentDidMount()  // Force an update of the component when team changes
      }
    }
  }

  async componentDidMount() {
    this.setState({ currentTeam: this.props.currentTeam, isLoading: true })
    var leaderboards = await this.getAllLeaderboards();
    let leaderboardResult = leaderboards.results || [];
    _.map(leaderboardResult, result => {
      let activity = result.activity;
      if (activity && activity.scoreType && 
        ((activity.scoreType || "").toLowerCase() == "shortesttime" || activity.scoreType == "time")) {
        result.time = formatMeasureValueForDisplaying(activity.scoreType, result.score);
      };
      return result;
    })
    this.setState({
      isLoading: false,
      leaderboards: leaderboardResult || []
    })
  }

  async getAllLeaderboards(){
    const { currentTeam } = this.props;
    var teamId = currentTeam.id;
    return API.get("leaderboards", `/leaderboards/team/${teamId}/activities`,{ 
      queryStringParameters: {
          limit:200
    }});
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  Utf8ArrayToStr(array) {
      var out, i, len, c;
      var char2, char3;

      out = "";
      len = array.length;
      i = 0;
      while (i < len) {
          c = array[i++];
          switch (c >> 4) {
              case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                  // 0xxxxxxx
                  out += String.fromCharCode(c);
                  break;
              case 12: case 13:
                  // 110x xxxx   10xx xxxx
                  char2 = array[i++];
                  out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                  break;
              case 14:
                  // 1110 xxxx  10xx xxxx  10xx xxxx
                  char2 = array[i++];
                  char3 = array[i++];
                  out += String.fromCharCode(((c & 0x0F) << 12) |
                      ((char2 & 0x3F) << 6) |
                      ((char3 & 0x3F) << 0));
                  break;
          }
      }

      return out;
  }

  render() {
    const { classes } = this.props;
    const { leaderboards = [], isLoading} = this.state
    return(
      <PageWrapper title={getLocalizedString("PERFORMANCE_LEADERS")} {...this.props} isPageLoading={isLoading} loadingMessage={"loading your leaderboards..."}>
      <div style={{height: '100%'}}>
        <Grid container spacing={8}>
          <Grid item xs={6} style={{textAlign:'left'}}>
          </Grid>
          <Grid item xs={6} style={{textAlign:'right', marginTop:10}}>
            {/*<Button variant="contained" color="primary">Reset Total Points</Button>*/}
          </Grid>
          <Grid item xs={12} style={{textAlign:'right', marginTop:10}}>
            <Paper>
              {
                leaderboards.length > 0
                ?
                <div>
                <Grid container spacing={8} style={{paddingTop:15, paddingBottom:15}}>
                  <Grid item xs={1} style={{textAlign:'center'}}>
                  </Grid>
                  <Grid item xs={6} style={{textAlign:'left', fontWeight:'bold'}}>
                    Activity
                  </Grid>
                  <Grid item xs={4} style={{textAlign:'left', fontWeight:'bold'}}>
                    Top Athlete
                  </Grid>
                  <Grid item xs={1} style={{textAlign:'left', fontWeight:'bold'}}>
                    Result
                  </Grid>
                </Grid>
                <Divider />
                {
                  _.orderBy(_.filter(leaderboards, lb => lb.activity.name), 'activity.name').map((leaderboard, i) =>{
                    return(
                      <div key={i}>
                        <Grid 
                          container 
                          spacing={8} 
                          style={{paddingTop:15, paddingBottom:15, display:'flex', alignItems:'center', cursor: 'pointer'}}
                          onClick={() => this.props.history.push(`/leaderboards/activities/${leaderboard.activity.id}`)}
                        >
                          <Grid item xs={1} style={{textAlign:'center', padding:0}}>
                            {
                              leaderboard.activity.isGlobal
                              ?
                              <LanguageIcon />
                              :
                              null
                            }
                          </Grid>
                          <Grid item xs={6} style={{textAlign:'left'}}>
                            <div style={{color:"black"}}>{leaderboard.activity.name}</div>
                          </Grid>
                          <Grid item xs={4} style={{textAlign:'left', padding:0}}>
                            {
                              leaderboard.player 
                              ?
                              <div> {leaderboard.player.nameFirst} {leaderboard.player.nameLast}</div>
                              :
                              null
                            }
                          </Grid>
                          <Grid item xs={1} style={{textAlign:'left'}}>
                            <div>{leaderboard.activity && 
                            ((leaderboard.activity.scoreType||"").toLowerCase()  == "shortesttime" || leaderboard.activity.scoreType == "time")?
                            leaderboard.time:
                            leaderboard.score && leaderboard.score% 1 != 0 ? leaderboard.score.toFixed(2):(leaderboard.score||0) }</div>
                          </Grid>
                        </Grid>
                        <Divider />
                      </div>
                    )
                  })
                }
                </div>
                :
                <div style={{paddingTop:20, paddingBottom:20, textAlign:"center"}}>
                  There are no results.
                </div>
              }
            </Paper>
          </Grid>
        </Grid>
      </div>
      </PageWrapper>
    )
  }

}

ActivityLeaderboards.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
      currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ActivityLeaderboards));
