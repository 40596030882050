import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getTeamSwitcherByUserId,
  getNavigationData,
} from "../../../../actions/config-actions";
import { setCurrentTeamById } from "../../../../actions/team-actions";
import SideBarNavigatorComponent from "./components/SideBarNavigator";
import RoleSwitcherComponent from "./components/RoleSwitcher";
import { getIconByName } from "../../icons/utils";
import ContextService from "../../../../services/context";
import { analyticsService } from "../../../../services/analyticsService";
import { setAppContext } from "../../../../actions/session";
import { NAV_TAB_HEAD } from "../../../../routeUtil";
import { dynamicLibraryClient } from "../../../../clients";
import { setLibraryInfo } from "../../../../actions/training-actions";
import _ from "lodash";

const ICONS = Object.freeze({
  DRILL: "{sportsIcon}",
  EXERCISE: "Heartbeat",
  EDUCATION: "GraduationCap",
  QUESTIONS: "Helpline",
  COACH: "Strategy",
});
class SideBarNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      navigation: {},
      isTeamSwitcherVisible: false,
      teamsMenu: [],
      isSearched: false,
      isDefault: true,
      dynamicLibraries: {},
      originalLibraries: {},
    };
  }

  componentDidMount = async () => {
    const { currentTeamId, enableFullScreenLoader, userContext } = this.props;
    const isTenantAdmin =
      userContext && userContext.user && userContext.user.isTenantAdmin;

    this.getNavBarData();

    if (isTenantAdmin) {
      enableFullScreenLoader(true);
      const { data } = await dynamicLibraryClient.getDynamicLibraryByParentId(
        "TEAM",
        currentTeamId
      );
      this.setState({
        dynamicLibraries: _.cloneDeep(data),
        originalLibraries: _.cloneDeep(data),
      });
      enableFullScreenLoader(false);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { loggedInUserId, currentTeamId } = this.props;
    if (
      loggedInUserId !== prevProps.loggedInUserId ||
      currentTeamId !== prevProps.currentTeamId
    ) {
      this.getNavBarData();
    }
  };

  getNavBarData = () => {
    this.setState({ isSearched: false });
    this.getNavDetails();
  };

  getNavDetails = async () => {
    const {
      loggedInUserId,
      currentTeamId,
      enableFullScreenLoader,
      setLibraryInfo,
    } = this.props;
    // enableFullScreenLoader(true, "Loading...");

    if (loggedInUserId && currentTeamId) {
      const navigation = await getNavigationData(loggedInUserId, currentTeamId);
      if (!_.isEqual(this.state.navigation, navigation)) {
        setLibraryInfo(navigation.libraries);
        this.setState({ navigation });
      }
    }

    if (loggedInUserId) {
      const teams = await getTeamSwitcherByUserId(loggedInUserId);
      if (!_.isEqual(this.state.teams, teams)) {
        this.setState({ teams });
      }
    }

    setTimeout(() => enableFullScreenLoader(false), 0);
  };

  saveLibraryVisiblityChanges = async () => {
    const { dynamicLibraries } = this.state;
    const { enableFullScreenLoader, currentTeamId } = this.props;
    const type = dynamicLibraries.type;
    let getDynamicLibrary = {};
    try {
      enableFullScreenLoader(true);
      if (["ORGANIZATION", "TENANT"].includes(type)) {
        getDynamicLibrary = await dynamicLibraryClient.createDynamicLibrary(
          currentTeamId,
          "TEAM",
          dynamicLibraries.libraries
        );
      }
      if (type === "TEAM") {
        getDynamicLibrary = await dynamicLibraryClient.updateDynamicLibraryById(
          dynamicLibraries.id,
          dynamicLibraries.libraries
        );
      }
      this.setState({
        dynamicLibraries: _.cloneDeep(getDynamicLibrary.data),
        originalLibraries: _.cloneDeep(getDynamicLibrary.data),
      });
      enableFullScreenLoader(false);
    } catch (error) {
      enableFullScreenLoader(false);
      console.log("error: ", error);
    }
  };

  handleLibVisChange = async (data) => {
    const { dynamicLibraries } = this.state;
    const index = dynamicLibraries.libraries.findIndex(
      (lib) => lib.label === data.label
    );
    if (index > -1) {
      dynamicLibraries.libraries[index].hideForCoaches =
        !dynamicLibraries.libraries[index].hideForCoaches;
    }
    this.setState({ dynamicLibraries });
  };

  cancelChanges = () => {
    const { originalLibraries } = this.state;
    this.setState({ dynamicLibraries: _.cloneDeep(originalLibraries) });
  };

  processNavigation = (navigation) => {
    const { dynamicLibraries } = this.state;
    const {
      byId,
      currentTeamId,
      location,
      isOpen,
      history,
      tenant,
      userContext,
      appContext,
    } = this.props;
    const currentTeam = byId[currentTeamId] || {};
    const activeRoute = (location || {}).pathname || "";
    const isTenantAdmin =
      userContext && userContext.user && userContext.user.isTenantAdmin;
    const isCoach = appContext && appContext.isCoach;
    let { navData } = navigation;
    let menus = [];
    let showWellness =
      navData &&
      navData.filter(
        (navItem) => navItem.name.toLowerCase() === "team management"
      );
    if (
      showWellness &&
      showWellness.length &&
      isCoach &&
      (tenant || {}).id === "ownit"
    ) {
      let tmpObj = { ...showWellness[0] };
      let obj = {
        icon: "Athlete",
        isElite: false,
        name: "Wellness Dashboard",
        route: "/wellnessDashboard",
      };
      let indexOfWellness = tmpObj.submenus.findIndex(
        (submenu) => submenu.name.toLowerCase() === "wellness dashboard"
      );
      if (indexOfWellness < 0) {
        tmpObj.submenus.unshift(obj);
      }
    }
    let adminNavItems =
      navData &&
      navData.filter((navItem) => navItem.name.toLowerCase() === "admin");
    if (adminNavItems && adminNavItems.length) {
      let tmpObj = { ...adminNavItems[0] };
      let obj = {
        icon: "Share",
        isElite: false,
        name: "Content Sharing",
        route: "/adminContentSharing", //adminContentSharing
      };
      let indexOfAdmin = tmpObj.submenus.findIndex(
        (submenu) => submenu.name.toLowerCase() === "content sharing"
      );
      if (indexOfAdmin < 0) {
        tmpObj.submenus.push(obj);
      }
    }
    menus = (navData || []).map((navItem, index) => {
      let showNewCalendar = false;
      const editable =
        navItem.name.toLowerCase() === "training & content" && isTenantAdmin;
      if (editable) {
        let data = [];
        for (let library of (dynamicLibraries || {}).libraries || []) {
          let tmpObj = {
            hideForCoaches: library.hideForCoaches || false,
            name: library.label,
            icon: ICONS[library.value.toUpperCase()] || "Athlete",
            isElite: tenant.name.toUpperCase() === "VNN" ? true : false,
            route: `/activities/${(library.value || "").toLowerCase()}`,
          };
          data.push(tmpObj);
          navItem.submenus = data;
        }
      }
      if (
        ["acesnationconnect", "m1", "teamesface", "dnasoccerlabs"].indexOf(
          tenant.id
        ) > -1 &&
        [
          "kKKSoDBUSmA5PNfv2W7fih",
          "eYBJGbfARYCZvKMfS2AoKV",
          "HKiHcBxGK",
          "mHvMxpuf0v",
          "xv9S8M64HynLH2ykGcpD9W",
          "osQF9W1rc18ksyaDACjcPMP",
          "9dzvKe6AqqPYsZH4iQz2tN",
          "23fT1AX9H4zuySp3tiFpvR",
          "8RLGiV1id7ghm2bk1c3fpA",
          "isXS46Lw4S9qz1UhaY4WjF",
          "3qwMDcg1cx4Fih5gNdTjxu",
          "fZYeKj499rAHSJyytWorAs",
          "iAk8fuGEkXsLBhXHvVjypg",
          "JaPFt6P9hK",
          "9dzvKe6AqqPYsZH4iQz2tN",
          "nCL2nNaUMHXyQLfaC6sEnb",
          "qWp4pSU5Pe3SEp1Jn2Fy5F",
        ].indexOf(currentTeam.organizationId) > -1
      ) {
        showNewCalendar = true;
      }
      return {
        isOpen: isOpen,
        label: navItem.name,
        isEditable: editable,
        handleEditClick: (data) => this.handleLibVisChange(data),
        handleSaveClick: () => this.saveLibraryVisiblityChanges(),
        cancelChanges: () => this.cancelChanges(),
        hasActiveValue:
          (navItem.submenus || []).findIndex(
            (subMenu) => activeRoute === subMenu.route
          ) === -1
            ? false
            : true,
        list: (navItem.submenus || []).map((subMenu) => {
          let isInBeta =
            subMenu.name === "Team Calendar" ||
            subMenu.name === "Organization Calendar"
              ? true
              : false;
          return {
            selected: activeRoute === subMenu.route,
            leftIcon:
              subMenu.icon === "{sportsIcon}"
                ? getIconByName(currentTeam.sport)
                  ? currentTeam.sport
                  : "Trophy"
                : subMenu.icon,
            label: subMenu.name,
            handleClick: () =>
              subMenu.isElite
                ? handleChange("isPaywallOpen", !isPaywallOpen)
                : showNewCalendar && isInBeta
                ? history.push("/timeline")
                : history.push(subMenu.route),
            isInBeta: isInBeta,
            hideForCoaches: subMenu.hideForCoaches
              ? subMenu.hideForCoaches
              : false,
            isEditable: editable,
            // handleLibVisChange:(data) => this.handleLibVisChange(data)
          };
        }),
      };
    });
    menus.unshift({
      isOpen: isOpen,
      label: NAV_TAB_HEAD.DASHBOARD,
      handleClick: () => history.push("/"),
      hasActiveValue: activeRoute === "/",
    });
    return menus;
  };

  processTeamSwitcher = (teams) => {
    const { byId, currentTeamId } = this.props;
    const currentTeam = byId[currentTeamId] || {};
    const { data, total } = teams;
    const userRole = JSON.parse(localStorage.getItem("ajs_user_traits"))?.role;
    let menus = [];
    if (userRole === "coach" && data && data.length === 1) {
      menus = [
        {
          title: "Teams",
          subTitle: `${data[0].teams.length} Teams`,
          list: (data[0].teams || []).map((element) => {
            return {
              id: element.id,
              url: element.logo,
              title: element.customName || element.name,
              subTitle: `${element.totalAthletes} Athlete${
                element.totalAthletes > 1 ? "s" : ""
              }`,
              type: "TEAM",
              selected: element.id === currentTeam.id,
              listItemSubTitleName: ["list-item-sub-title"],
              listItemTypeClassName: ["list-item-type"],
            };
          }),
        },
      ];
    } else {
      menus = [
        {
          title: "Organizations",
          subTitle: `${total} Orgs`,
          list: (data || []).map((item) => {
            return {
              id: item.id,
              url: item.logo,
              title: item.name,
              subTitle: `${(item.teams || []).length} Team${
                (item.teams || []).length > 1 ? "s" : ""
              }`,
              type: "ORG",
              handleChange: () => {},
              children: (item.teams || []).map((element) => {
                return {
                  id: element.id,
                  url: element.logo,
                  title: element.customName || element.name,
                  subTitle: `${element.totalAthletes} Athlete${
                    element.totalAthletes > 1 ? "s" : ""
                  }`,
                  type: "TEAM",
                  selected: element.id === currentTeam.id,
                };
              }),
              selected: item.id === currentTeam.organizationId,
              listItemSubTitleName: ["list-item-sub-title"],
              listItemTypeClassName: ["list-item-type"],
            };
          }),
        },
      ];
    }

    return menus;
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleTeamChange = async (teamId, teamName) => {
    const { enableFullScreenLoader, history } = this.props;
    enableFullScreenLoader(true, `You are now viewing ${teamName}`);
    this.setState({ isTeamSwitcherVisible: false });
    await this.setSessionInfo(teamId);
    enableFullScreenLoader(false);
    history.push("/");
  };

  setSessionInfo = async (newId) => {
    let { tenant, userContext, setAppContext, setCurrentTeamById } = this.props;
    const contextService = new ContextService();
    const appContext = await contextService.changeAppContext(
      userContext,
      newId,
      tenant
    );
    setCurrentTeamById(newId);
    setAppContext(appContext);
    const { user, roles, appContextList } = userContext;
    const currentTeam =
      _.find(
        appContextList,
        (c) => appContext && c && c.id === appContext.id
      ) || {};
    if (user && roles && roles[0]) {
      let userTraits = await analyticsService.getUserTraits(
        user,
        roles,
        currentTeam,
        appContext,
        userContext
      );
      analyticsService.initWithUser(userTraits);
    }
  };

  handleSearch = async (data) => {
    const { teams } = this.state;
    const teamMenu = this.processTeamSwitcher(teams);

    if (data.length > 0 && teams.data && teams.data.length > 0) {
      let copyTeams = _.cloneDeep(teams);
      let orgsData = _.cloneDeep(copyTeams);
      let orgArr = [];
      orgsData.data.forEach((org) => {
        let teamsArr = [];
        org.teams.forEach((team) => {
          if (team.customName.toLowerCase().includes(data.toLowerCase())) {
            teamsArr.push(team);
            if (orgArr.indexOf(org) > -1) {
              org.teams = teamsArr;
            } else {
              org.teams = teamsArr;
              orgArr.push(org);
            }
            orgsData.data = orgArr;
            const teamsMenu = this.processTeamSwitcher(orgsData);
            this.setState({ teamsMenu: teamsMenu, isSearched: true });
          }
        });
      });
    } else {
      this.setState({ teamsMenu: teamMenu, isSearched: false });
    }
  };

  handleCreateNewTeam = () => {
    this.handleChange("isTeamSwitcherVisible", false);
    this.props.history.push("/newTeam");
  };

  handleRefreshTeams = () => {
    this.getNavBarData();
  };

  handleRoleSwitcher = () => {
    this.handleChange("isTeamSwitcherVisible", true);
    this.handleRefreshTeams();
  };

  render() {
    const { teams, navigation, isTeamSwitcherVisible, isSearched } = this.state;
    const { byId, currentTeamId, tenant, isOpen, handleChange } = this.props;
    const currentTeam = byId[currentTeamId];
    const navMenu = this.processNavigation(navigation);
    const teamMenu = this.processTeamSwitcher(teams);
    let { data } = teams;
    const userRole = JSON.parse(localStorage.getItem("ajs_user_traits"))?.role;
    const isSingleTeam =
      data &&
      data.length === 1 &&
      data[0] &&
      data[0].teams &&
      data[0].teams.length === 1 &&
      userRole &&
      userRole === "coach";
    return (
      <>
        <SideBarNavigatorComponent
          isOpen={isOpen}
          header={{
            logo: tenant.logo,
            rightIcon: "ChevronLeft",
            rightIconClassName: ["right-icon", "grey"],
            toggleOpen: () => handleChange("isOpen", !isOpen),
          }}
          selectedTeam={{
            url: currentTeam && currentTeam.logo,
            rightIcon: "ArrowDropDown",
            title: "Selected: TEAM",
            subTitle: currentTeam?.customName || currentTeam?.name,
            rightIconClassName: ["right-icon"],
            isSingleTeam: isSingleTeam,
            handleClick: () =>
              isSingleTeam ? null : this.handleRoleSwitcher(),
          }}
          menu={navMenu}
        />

        <RoleSwitcherComponent
          isOpen={isTeamSwitcherVisible}
          header={{
            label: "Team Switcher",
            rightIcon: "CancelCircle",
            rightIconClassName: ["right-icon", "white"],
            toggleOpen: () => {
              this.handleChange("isTeamSwitcherVisible", false);
              this.setState({ isSearched: false });
            },
          }}
          selectedTeam={{
            url: currentTeam && currentTeam.logo,
            title: "Selected: TEAM",
            subTitle: currentTeam?.customName || currentTeam?.name,
            handleClick: () => {},
          }}
          menu={isSearched ? this.state.teamsMenu : teamMenu}
          handleTeamChange={this.handleTeamChange}
          history={this.props.history}
          handleSearch={(data) => {
            this.handleSearch(data);
          }}
          handleCreateNewTeam={() => this.handleCreateNewTeam()}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId },
  teams: { byId, currentTeamId },
  tenant,
  session: { userContext, appContext },
}) => ({
  byId,
  tenant,
  currentTeamId,
  loggedInUserId,
  userContext,
  appContext,
});

export default connect(mapStateToProps, {
  setAppContext,
  setCurrentTeamById,
  setLibraryInfo,
})(SideBarNavigator);
