import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import ModalContainer from '../../components/modalContainer';
import { getThemeByTenant } from '../../themes';
import { withStyles } from '@material-ui/core/styles';
import SearchInput from '../../components/searchInput';
import { API } from 'aws-amplify';
import classnames from 'classnames';
import { CheckMarkIcon } from '../../components/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { organizationClient, teamClient } from '../../clients';
import DropDownWithSearch from "../../components/dropDownWithSearch";
import _ from 'lodash';
const appLogo = getThemeByTenant().image.logo;

let styles = {
    topSectionAdditionalContent:{
        marginTop:"6px"
    },
    subHeadTxt:{
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom:"12px"
    },
    teamCard:{
        verticalAlign:"top",
        cursor:"pointer",
        width: "277px",
        height: "61px",
        marginBottom: "15px",
        display: "inline-block",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        padding: "0 12px",
        marginLeft: "15px",
        "&.selectedTeamSquare": {
            border: "2px solid #27AE60"
        }
    },
    teamName:{
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        color: "#0B0B0B",
        marginLeft:"10px"
    },
    searchBox:{
        height:"44px",
        marginBottom: "17px"
    },
    teamImage:{
        minWidth:"36px",
        minHeight:"36px",
        maxWidth:"36px",
        maxHeight:"36px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",        
        borderRadius: "4px"        
    },  
    checkMark:{
        border: "1px solid #DDE2E5",
        color:"#DDE2E5",
        "& svg":{
            width:"20px",
            height:"20px"
        }
    },
    checkMarkFilledContainer:{
        color: "white",
        border: "1px solid #27AE60",
        background: "#27AE60",
        color: "white",
        "& svg":{
            width:"20px",
            height:"20px"
        }
    },
    teamCardContainer:{
        height:"443px",
        overflow:"scroll",
        paddingTop: "20px",
        paddingBottom: "20px",
        justifyContent: "space-between",
        marginLeft:"-16px"
    },
    marketplaceModalPaper:{
        marginRight: "-30px",
        marginLeft: "-30px",
        marginBottom: "-30px",
        background: "#F8F8F8",
        paddingLeft: "30px",
        paddingRight: "30px"
    },
    teamSelectedTxt:{
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%"
    },
    inputDarkBorder:{
        border: "2px solid #A0A7AB"
    }
}
class SelectOrgModal extends Component {
    constructor(){
        super();
        this.state = {
            orgs:[],
            teams:[],
            filteredOrgs:[],
            filteredTeams:[],
            selectedTeamIdsForDownload:new Set(),
            isLoading: false,
            currentPage: -1,
            selectedOrgId: null
        }
    }

    componentDidMount = () => {
        this.getOrgs();
    }

    componentWillReceiveProps(nextProps){
        if(!this.props.open && nextProps.open){
            this.setState({selectedTeamIdsForDownload:new Set()})
        }
    }

    getTeams() {
        const { currentTeam } = this.props;
        return API.get("programs", `/programs/organization/${currentTeam.organizationId}`);
    }
    getOrgs = async () => {
        const offset = 20;
        let { tenant } = this.props;
        let orgs = [], results = [], totalOrgs = 0;
        let { orgs: existingOrgsMenuItemData, currentPage } = this.state;

        currentPage = currentPage + 1;
        let params = {
            limit: offset,
            offset: currentPage * offset,
            tenant
        }
        try {
            ({ data: { results, total: totalOrgs } } = await organizationClient.searchOrganizations(params));
            orgs = results.map(item => { return { value: item.id, displayValue: item.name } });
        }
        catch (ex) {
            console.log(ex)
        }
        this.setState({ totalOrgs, orgs: existingOrgsMenuItemData.concat(orgs), currentPage });
    }

    handleSearch = (searchTerm = '') => {
        let { teams } = this.state;
        let filteredTeams = [];
        if(searchTerm){
            filteredTeams = teams.filter(t => (getTeamName(t).toLowerCase().includes(searchTerm.toLowerCase())));
        }
        else {
            filteredTeams = [...teams];
        }
        this.setState({ searchTerm, filteredTeams });
    }

    handleOrgSearch = (searchTerm = '') => {
        let { orgs } = this.state;
        let filteredOrgs = [];
        if(searchTerm){
            filteredOrgs = orgs.filter(t => (t.includes(searchTerm.toLowerCase())));
        }
        else {
            filteredOrgs = [...orgs];
        }
        this.setState({ searchTerm, filteredOrgs });
    }
    select = (team) => {
        let { allowOnlyOneTeam } = this.props;
        let { selectedTeamIdsForDownload } = this.state;
        let newSelectedTeamIds = new Set(selectedTeamIdsForDownload);
        if(newSelectedTeamIds.has(team.id)){
            newSelectedTeamIds.delete(team.id);
        }
        else {
            if(allowOnlyOneTeam){
                newSelectedTeamIds = new Set([team.id])
            }
            else {
                newSelectedTeamIds.add(team.id);
            }
        }
        this.setState({selectedTeamIdsForDownload : newSelectedTeamIds});
    }

    bulkSelect = (deselectAll) =>{
        let { teams } = this.state;
        let selectedTeamIdsForDownload;
        if(deselectAll){
            selectedTeamIdsForDownload = new Set();
        }
        else{
            selectedTeamIdsForDownload = new Set(teams.map(team => team.id));
        }
        this.setState({selectedTeamIdsForDownload})
    }
    selectOrg = (value) => {
        this.setState({ "selectedOrgId": value, isLoading: true}, () => this.getTeamsByOrg(value))
    }

    getTeamsByOrg = async (value) => {
        try {
            const { data: { results } } = await teamClient.getTeamsByOrgId(value);
            let uniqueTeams = _.uniqBy(results, "id");
            this.setState({teams: uniqueTeams, filteredTeams:uniqueTeams, isLoading: false});
            // this.setState({ teams: results.map(item => { return { value: item.id, displayValue: item.customName || `${item.name} ${item.sport}` } }), isLoading: false})
        }
        catch (ex) {
            console.log(ex)
        }
    }

    render() {
        const { open, close, save, classes, title, productPrice, allowOnlyOneTeam, subHeading, btnTxt } = this.props
        const { searchTerm, filteredTeams, isLoading, selectedTeamIdsForDownload, teams, orgs,selectedOrgId, totalOrgs} = this.state;
        return (
            <ModalContainer
                customPadding={"30px"}
                heading={title}
                open={open}
                width={"630px"}
                onClose={close}
                centerElement={
                    selectedTeamIdsForDownload.size ? 
                    <span className={classes.teamSelectedTxt}>{selectedTeamIdsForDownload.size} {`Team`.pluralize('Teams', selectedTeamIdsForDownload.size)} Selected</span>:
                    null
                }
                useDefaultLeftBtn={true}
                useDefaultRightBtn={true}
                defaultLeftBtnProps={{onClick:close}}
                defaultRightBtnProps={
                {disabled:!selectedTeamIdsForDownload.size,
                onClick:()=>save(Array.from(selectedTeamIdsForDownload), teams.filter( team => selectedTeamIdsForDownload.has(team.id))),
                text:productPrice ? `$${productPrice * selectedTeamIdsForDownload.size} Buy` : (btnTxt ||'Add Now')}}            
                additionalTopSection={
                    <div className={classes.topSectionAdditionalContent}>
                        <div className={classes.subHeadTxt}>
                            {subHeading || `Search and select Organization/teams for uploading`}
                        </div>
                        <div style={{marginBottom: '10px'}}>
                        <DropDownWithSearch
                                enableInfiniteLoading={true}
                                loadMore={this.getOrgs}
                                value={selectedOrgId}
                                hasMore={orgs.length < totalOrgs}
                                placeholder={"Select Destination organization"}
                                onChange={(value) => {
                                    this.selectOrg(value)
                                }}
                                menuItemData={orgs}
                                dropdownHeight={"201px"}
                            />
                        {/* <SearchInput placeholder={"Select Destination Organization"} customClass={classnames(classes.searchBox, {[classes.inputDarkBorder]:!!searchTerm})} searchTerm={searchTerm} handleSearch={this.handleOrgSearch} /> */}
                        </div>
                        {/* <DropDownWithSearch
                            disabled={selectedOrgId === null ? true : false} 
                            value={selectedTeamId}
                            placeholder={"Select destination team"}
                            onChange={this.selectTeam}
                            menuItemData={teams}
                            dropdownHeight={"550px"}
                        /> */}
                        <SearchInput disabled={selectedOrgId === null ? true : false} placeholder={"Search Teams"} customClass={classnames(classes.searchBox, {[classes.inputDarkBorder]:!!searchTerm})} searchTerm={searchTerm} handleSearch={this.handleSearch} />
                    </div>
                }
            >
                <div className={classes.marketplaceModalPaper}>
                    {isLoading ?
                        <div className={classes.teamCardContainer}>
                            <div className={"contentLoadingOverlay"}>
                                <CircularProgress />
                                <h2>Loading Teams...</h2>
                            </div>
                        </div>
                        :
                        <div className={classes.teamCardContainer}>
                                {filteredTeams.length > 1 && !allowOnlyOneTeam &&
                                <div onClick={() => this.bulkSelect(selectedTeamIdsForDownload.size === teams.length)} className={classnames(classes.teamCard, {'selectedTeamSquare':selectedTeamIdsForDownload.size === teams.length})}>
                                        <div className={"d-flex align-items-center h-100"}>
                                            <div className={classnames(classes.teamImage, classes.checkMark, {[classes.checkMarkFilledContainer]:selectedTeamIdsForDownload.size === teams.length})}><CheckMarkIcon /></div>                                
                                            <div className={classes.teamName}>{selectedTeamIdsForDownload.size === teams.length ? "Deselect all teams": "Select All Teams"}</div>
                                         </div>
                                    </div>
                                }
                                {filteredTeams.map(team => {        
                                    return (
                                    <div  onClick={() => this.select(team)} key={team.id} className={classnames(classes.teamCard, {'selectedTeamSquare' :selectedTeamIdsForDownload.has(team.id)})}>
                                        <div className={"d-flex align-items-center h-100"}>
                                            {selectedTeamIdsForDownload.has(team.id) ? <div className={classnames(classes.teamImage,classes.checkMarkFilledContainer)}><CheckMarkIcon /></div>:
                                            <img className={classes.teamImage} src={ (team.tenant == 'vnn' || team.tenant == 'm1vnn') && team.organization && team.organization.logoUrl ? team.organization.logoUrl: (team.logo || appLogo)} /> }                                                
                                            <div className={classes.teamName}>{getTeamName(team)}</div>
                                         </div>
                                    </div>
                                    )
                                })}
                        </div>}
                </div>
            </ModalContainer>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
      currentTeam: currentTeam||{}
    };
}


export default withStyles(styles)(connect(mapStateToProps)(SelectOrgModal));

function getTeamName(team){
    return team.customName ? team.customName : `${team.name} ${team.sport}`;
}