import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { s3Get, s3Upload } from "../../libs/awsLib";
import { API, Storage } from "aws-amplify";
import SwipeableViews from 'react-swipeable-views';
import _ from "lodash"
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';
import urlParser from "../../js-video-url-parser";
import update from 'immutability-helper';

import {
    Tabs,
    Tab,
    Button,
    Grid,
    Paper,
    TextField,
    Modal,
    Typography,
    Divider,
    Card,
    Hidden,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    IconButton,
    withStyles,
    Input,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Step,
    Stepper,
    StepLabel,
    InputAdornment,
    CircularProgress,
    Tooltip
} from '@material-ui/core';

import lightGreen from '@material-ui/core/colors/lightGreen';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LabelIcon from '@material-ui/icons/Label';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import logo from "../../assets/images/brand/logo-dark@2x.png";
import lightLogo from "../../assets/images/brand/icon.png";

import { setUserContext, setAppContext } from '../../actions/session';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';

import classNames from 'classnames';

// SLICK CAROUSEL //
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// ////////////// //

import "./products.css";
import PageWrapper from '../nav/pageWrapper';

const styles = theme => ({
    root: {
        // width: '100%',
        backgroundColor: 'white',
        padding: 20
    },
    stepperButton: {
        marginRight: 8,
    },
    button: {
        width: 50,
        position: 'absolute',
        top: 80,
        right: 20,
    },
    pageTitle: {
        textAlign: 'left',
        marginLeft: 60
    },
    card: {
        borderRadius: 0,
        height: 30,
        display: 'flex',
        backgroundColor: '#CDDD00'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        paddingTop: 5,
        width: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
    },
    cover: {
        width: 100,
        height: 30,
    },
    segmentActivityImage: {
        height: '100%',
        width: 30,
    },
    textField: {
      width: "100%",
      color: "white",
      borderColor: "white"
    }
});

function NextArrow(props) {
    const {className, style, onClick} = props
    return (
        <IconButton
            style={{...style}}
            onClick={onClick}
        >
            <ChevronRightIcon style={{ fontSize: '2em' }} />
        </IconButton>
    );
};

function PrevArrow(props) {
    const {className, style, onClick} = props
    return (
        <IconButton
            style={{...style}}
            onClick={onClick}
        >
            <ChevronLeftIcon style={{ fontSize: '2em' }} />
        </IconButton>
    );
};

class ProductsNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tag: {},
            tags: [],
            value: 0,
            files: [],
            open: false,
            activity: {},
            workouts: [],
            activities: [],
            productName: "",
            isLoading: false,
            dayActivities: {},
            filterableTags: [],
            loadingMessage: "",
            searchString: null,
            dayExpanded: false,
            renderedWorkouts: [],
            category: 'drill',
            editingWorkout: false,
            renderedActivities: [],
            editingWorkoutId: null,
            activeLabelFilter: 'all',
            itemCategory: 'activities',
            taggedActivityGroupings: [],
            filteredTaggedGroupings: [],
            isProductDetailModalOpen: false,
            product: {activities: [], workouts: [], workoutPrograms: []},
        };
    }

    componentDidMount = () => {
        // if(this.props.location.state){
        //     let workout = this.props.location.state.workout;
        //     let formattedWorkout = { workout: 0, name: workout.name, id: workout.id, programId: workout.programId, workoutSegments: [] }

        //     _.each(workout.workoutSegments, (segment, index) => {
        //         let formattedSegment = { segment: parseInt(index), type: parseInt(segment.type), activities: [] }
        //         _.each(segment.workoutActivities, workoutActivity => {
        //             formattedSegment.activities.push(workoutActivity);
        //         })
        //         formattedWorkout.workoutSegments.push(formattedSegment);
        //     })

        //     this.setState({
        //         editingWorkoutId: workout.id,
        //         workout: formattedWorkout,
        //         productName: formattedWorkout.name,
        //         value: 2
        //     })
        // }
    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array)
        }
    }

    loadActivities = async () => {
        const { category } = this.state;
        this.setState({ isLoading: true, loadingMessage: "getting your activities..." });
        // const activities = await this.getActivities();
        const activities = await this.getProductActivities();

        await this.asyncForEach(activities, async(activity) => {
            var attachment = null
            var thumbnail = null
            if(activity.attachment && !activity.attachment.includes("https://")){
                var re = /(?:\.([^.]+))?$/;
                var ext = re.exec(activity.attachment)[1];

                if(ext.toUpperCase() === "MOV" || ext.toUpperCase() === "M4V" || ext.toUpperCase() === "MP4"){ // assume file type is video
                attachment = `https://s3.amazonaws.com/programax-videos-production/videos/${activity.legacyId ? activity.legacyId : activity.id}/original/${activity.attachment}`
                thumbnail = `https://s3.amazonaws.com/programax-videos-production/videos/${activity.legacyId ? activity.legacyId : activity.id}/thumbnail/thumbnail.png`

                }
                else if( ext.toUpperCase() === "JPG" || ext.toUpperCase() === "PNG" ){ // assume is an image
                attachment = `https://s3.amazonaws.com/programax-videos-production/uploads/activity/image/${activity.legacyId ? activity.legacyId : activity.id}/${activity.attachment}`
                thumbnail = attachment
                }
                else{ // file of some kind
                attachment = `https://s3.amazonaws.com/programax-videos-production/uploads/activity/file/${activity.legacyId ? activity.legacyId : activity.id}/${activity.attachment}`

                }
                activity.attachment = attachment
                activity.thumbnail = thumbnail
            }
        })

        const preparedActivities = await this.prepareActivities(category, activities);
    }

    prepareActivities = async (category, activities) => {
        console.log('prepareActivities => ', category, activities);
        const filteredActivities = _.filter(activities, activity => activity.category == category);
        let filterableTags = [{ id: '0', label: 'other' }];
        let taggedActivityGroupings = [{ label: 'other', activities: [] }];
        await Promise.all(filteredActivities.map(async activity => {
            const taggings = _.values(activity.taggings);
            if(taggings.length === 0){
                taggedActivityGroupings[0].activities.push(activity);
            }
            await Promise.all(taggings.map(tagging => {
                if(tagging.tag && tagging.tag.id && tagging.tag.label) {
                    let tagPresent = _.find(filterableTags, t => { return t.label == tagging.tag.label });
                    if(!tagPresent) filterableTags.push(tagging.tag);
                    let index = _.findIndex(taggedActivityGroupings, group => group.label == tagging.tag.label)
                    if(index != -1) taggedActivityGroupings[index].activities.push(activity);
                    else taggedActivityGroupings.push({ label: tagging.tag.label, activities: [activity] });
                }
            }));
        }))

        this.setState({
            isLoading: false,
            category: category,
            activities: activities,
            filterableTags: filterableTags,
            renderedActivities: filteredActivities,
            taggedActivityGroupings: taggedActivityGroupings,
            filteredTaggedGroupings: taggedActivityGroupings
        });
    }

    loadWorkouts = async () => {
        this.setState({ isLoading: true, loadingMessage: "getting your workouts..." });
        // const workouts = await this.getWorkouts();
        const workouts = await this.getProductWorkouts();
        const preparedWorkouts = await this.prepareWorkouts(workouts)
    }

    prepareWorkouts = async workouts => {
        let filterableTags = [{ id: '0', label: 'other' }];
        let taggedWorkoutGroupings = [{ label: 'other', workouts: [] }];

        await Promise.all(workouts.map(async (workout, workoutIndex) => {
            const taggings = _.values(workout.taggings);
            if(taggings.length === 0){
                taggedWorkoutGroupings[0].workouts.push(workout);
            }
            await Promise.all(taggings.map(tagging => {
                if(tagging.tag && tagging.tag.id && tagging.tag.label) {
                    let tagPresent = _.find(filterableTags, t => { return t.label == tagging.tag.label });
                    if(!tagPresent) filterableTags.push(tagging.tag);
                    let index = _.findIndex(taggedWorkoutGroupings, group => group.label == tagging.tag.label)
                    if(index != -1) taggedWorkoutGroupings[index].workouts.push(workout);
                    else taggedWorkoutGroupings.push({ label: tagging.tag.label, workouts: [workout] });
                }
            }));
        }))

        this.setState({
            isLoading: false,
            workouts: workouts,
            renderedWorkouts: workouts,
            filterableTags: filterableTags,
            taggedWorkoutGroupings: taggedWorkoutGroupings,
            filteredTaggedGroupings: taggedWorkoutGroupings
        });
    }

    loadWorkoutPrograms = async () => {
        this.setState({ isLoading: true, loadingMessage: "getting your workout programs..." });
        const workoutPrograms = await this.getProductWorkoutPrograms();
        const preparedWorkoutPrograms = await this.prepareWorkoutPrograms(workoutPrograms)
    }

    prepareWorkoutPrograms = async workoutPrograms => {
        let filterableTags = [{ id: '0', label: 'other' }];
        let taggedWorkoutProgramGroupings = [{ label: 'other', workoutPrograms: [] }];

        await Promise.all(workoutPrograms.map(async (workoutProgram, workoutIndex) => {
            const taggings = _.values(workoutProgram.taggings);
            if(taggings.length === 0){
                taggedWorkoutProgramGroupings[0].workoutPrograms.push(workoutProgram);
            }
            await Promise.all(taggings.map(tagging => {
                if(tagging.tag && tagging.tag.id && tagging.tag.label) {
                    let tagPresent = _.find(filterableTags, t => { return t.label == tagging.tag.label });
                    if(!tagPresent) filterableTags.push(tagging.tag);
                    let index = _.findIndex(taggedWorkoutProgramGroupings, group => group.label == tagging.tag.label)
                    if(index != -1) taggedWorkoutProgramGroupings[index].workoutPrograms.push(workoutProgram);
                    else taggedWorkoutProgramGroupings.push({ label: tagging.tag.label, workoutPrograms: [workoutProgram] });
                }
            }));
        }))

        this.setState({
            isLoading: false,
            filterableTags: filterableTags,
            workoutPrograms: workoutPrograms,
            renderedWorkoutPrograms: workoutPrograms,
            taggedWorkoutProgramGroupings: taggedWorkoutProgramGroupings,
            filteredTaggedGroupings: taggedWorkoutProgramGroupings
        });
    }

    // APIs //
    getProductWorkoutPrograms(){
        return API.get("workoutPrograms", `/products/workoutPrograms`);
    }

    getProductWorkouts(){
        return API.get("workouts", `/products/workouts`);
    }

    getWorkouts(){
        const { currentTeam } = this.props;
        return API.get("workouts", `/programs/${currentTeam.id}/workouts`);
    }

    getProductActivities(){
        return API.get("activities", `/products/activities`);
    }

    getActivities(){
        return API.get("activities", `/programs/${this.props.currentTeam.id}/activities`);
    }

    static getActivity(activity){
        return API.get("activities", `/activity/${activity.id}`);
    }

    saveProduct(product){
        return API.post("products", `/products`, {
            body: product
        });
    }
    /////////

    handleTabValueChange = index => {
        this.setState({ value: index });
    };

    includeItem = item => {
        const { itemCategory } = this.state;
        let included = _.find(this.state.product.activities, a => a.id === item.id) ||
        _.find(this.state.product.workouts, w => w.id === item.id) ||
        _.find(this.state.product.workoutPrograms, wp => wp.id === item.id);
        let product = this.state.product;

        if(included){
            if(itemCategory == 'activities'){
                product.activities = _.without(product.activities, item)
            }
            else if(itemCategory == 'workouts'){
                product.workouts = _.without(product.workouts, item)
            }
            else if(itemCategory == 'workoutPrograms'){
                product.workoutPrograms = _.without(product.workoutPrograms, item)
            }
        }
        else{
            if(itemCategory == 'activities'){
                product.activities.push(item);
            }
            else if(itemCategory == 'workouts'){
                product.workouts.push(item);
            }
            else if(itemCategory == 'workoutPrograms'){
                product.workoutPrograms.push(item);
            }
        }

        this.setState(product);
    }

    itemIncluded = item => {
        return (
            _.find(this.state.product.activities, a => a.id === item.id) ||
            _.find(this.state.product.workouts, w => w.id === item.id) ||
            _.find(this.state.product.workoutPrograms, wp => wp.id === item.id)
        )
    }

    handleSaveProduct = async () => {
        const { product, files } = this.state;
        let featureImageUrl = null;
        if(files.length){
            featureImageUrl = await s3Upload(files[0].file);
        }
        let activityIds = _.map(product.activities, activity => { return { "S": activity.id } });
        let workoutIds = _.map(product.workouts, workout => { return { "S": workout.id } });
        let workoutProgramIds = _.map(product.workoutPrograms, workoutProgram => { return { "S": workoutProgram.id } });

        const productJSON = {
            featureImageUrl: featureImageUrl,
            workouts: workoutIds.length ? workoutIds : [],
            details: product.details ? product.details : null,
            activities: activityIds.length ? activityIds : [],
            sport: product.sport ? product.sport : 'Basketball',
            isActive: product.isActive ? product.isActive : true,
            priority: product.priority ? product.priority : 'normal',
            name: product.name ? product.name : 'Marketplace Product',
            description: product.description ? product.description : null,
            workoutPrograms: workoutProgramIds.length ? workoutProgramIds : []
        }

        const savedProduct = await this.saveProduct(productJSON);

        this.setState({
            value: 0,
            files: [],
            product: {
                activities: [],
                workouts: [],
                workoutPrograms: []
            },
            isProductDetailModalOpen: false
        })
    }

    handleFileSelect = event => {
        event.preventDefault();
        const { product } = this.state;

        if(event.target.files.length) {
            let file = event.target.files[0]
            let newProduct = product;
            newProduct.featureImageUrl = file.name;

            this.state.files.push({type: 'other', file: file});

            this.setState({ product: newProduct });
        }
    }

    removeActivity = activity => {
        let state = this.state;
        state.product.activities = _.without(state.product.activities, activity);
        this.setState({ state });
    }

    removeWorkout = workout => {
        let state = this.state;
        state.product.workouts = _.without(state.product.workouts, workout);
        this.setState({ state });
    }

    removeWorkoutProgram = workoutProgram => {
        let state = this.state;
        state.product.workoutPrograms = _.without(state.product.workoutPrograms, workoutProgram);
        this.setState({ state });
    }

    handleSearch = event => {
        const { itemCategory } = this.state;
        if(itemCategory == 'activities') {
            return this.searchActivities(event);
        }
        else if(itemCategory == 'workouts') {
            return this.searchWorkouts(event);
        }
        else if(itemCategory == 'workoutPrograms') {
            return this.searchWorkoutPrograms(event);
        }
    }

    searchActivities = event => {
        const { activities, category, itemCategory } = this.state;
        if(event.target.value){
            this.setState({
                searchString: event.target.value,
                renderedActivities: _.filter(activities, a => { return a.name && a.name.toLowerCase().includes(event.target.value.toLowerCase()) && a.category == category })
            })
        }
        else {
            this.setState({
                searchString: null,
                renderedActivities: _.filter(activities, a => a.category == category)
            })
        }
    }

    searchWorkouts = event => {
        const { workouts } = this.state;
        if(event.target.value){
            this.setState({
                searchString: event.target.value,
                renderedWorkouts: _.filter(workouts, a => a.name && a.name.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchString: null,
                renderedWorouts: workouts
            })
        }
    }

    searchWorkoutPrograms = event => {
        const { workoutPrograms } = this.state;
        if(event.target.value){
            this.setState({
                searchString: event.target.value,
                renderedWorkoutPrograms: _.filter(workoutPrograms, a => a.name && a.name.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchString: null,
                renderedWorkoutPrograms: workoutPrograms
            })
        }
    }

    handleTagChange = name => event => {
        const { tag } = this.state;
        tag[name] = event.target.value;
        this.setState({ tag: tag });
    }

    handleDescriptionChange = description => {
        const { activity } = this.state;
        activity.description = description;
        this.setState({ activity: activity });
    }

    handleChange = (event, name) => {
        let product = this.state.product
        product[name] = event.target.value;
        this.setState(product);
    }

    handleCategoryChange = async event => {
        const { activities, workouts, workoutPrograms } = this.state;
        this.setState({ itemCategory: event.target.value });
        if(event.target.value == 'activities'){
            if(!activities.length) return await this.loadActivities();
            const preparedActivities = await this.prepareActivities('drill', activities);
        }
        if(event.target.value == 'workouts'){
            if(!workouts.length) return await this.loadWorkouts();
            const preparedWorkouts = await this.prepareWorkouts(workouts);
        }
        if(event.target.value == 'workoutPrograms'){
            if(!workoutPrograms.length) return await this.loadWorkoutPrograms();
            this.prepareWorkoutPrograms(workoutPrograms);
        }
    }

    cancelWorkoutBuilder = () => {
        this.resetWorkout();
        this.props.history.push("/workouts")
    }

    filterActivities = async (event) => {
        const { activities } = this.state;
        let category = event.target.value;

        this.setState({isLoading: true});

        this.prepareActivities(category, activities);
    }

    searchWorkouts = event => {
        const { workouts } = this.state;

        if(event.target.value){
            this.setState({
                searchString: event.target.value,
                renderedWorkouts: _.filter(workouts, w => w.name && w.name.toLowerCase().includes(event.target.value.toLowerCase()))
            })
        }
        else {
            this.setState({
                searchString: null,
                renderedWorkouts: workouts
            })
        }
    }

    filterByLabel = event => {
        const { itemCategory, taggedActivityGroupings, taggedWorkoutGroupings } = this.state;
        let filteredActivities = null;
        let newFilterLabel = event.target.value;
        let filteredTaggedGroupings = null;
        const groupings = itemCategory == 'activities' ? taggedActivityGroupings : itemCategory == 'workouts' ? taggedWorkoutGroupings : taggedActivityGroupings
        if(newFilterLabel != 'all'){
            filteredTaggedGroupings = _.filter(groupings, group => group.label.toLowerCase() == newFilterLabel.toLowerCase());
        }
        this.setState({
            activeLabelFilter: newFilterLabel,
            filteredTaggedGroupings: filteredTaggedGroupings ? filteredTaggedGroupings : groupings
        })
    }

    handleSwipe = () => {
        this.setState({ isSliding: true });
    }

    handleAfterChange = () => {
        this.setState({ isSliding: false });
    }

    toggleProductDetailsModal = () => {
        const { isProductDetailModalOpen } = this.state;
        this.setState({ isProductDetailModalOpen: !isProductDetailModalOpen });
    }

    render() {
        const { currentTeam, classes, appContext } = this.props;
        const {
            tag,
            tags,
            files,
            product,
            videoId,
            category,
            isSliding,
            isLoading,
            value = 0,
            itemCategory,
            searchString,
            errorMessage,
            open = false,
            workout = {},
            activity = {},
            filterableTags,
            loadingMessage,
            activities = [],
            workoutExpanded,
            productName = "",
            activeLabelFilter,
            renderedWorkouts = [],
            editingWorkout = false,
            editingWorkoutId = null,
            includedActivities = [],
            renderedActivities = [],
            filteredTaggedGroupings,
            isProductDetailModalOpen,
            renderedWorkoutPrograms = []
        } = this.state;

        const carouselSettings = {
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 5,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            onSwipe: this.handleSwipe,
            afterChange: this.handleAfterChange,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };

        return (
            <PageWrapper title={product.name ? product.name : "New Product"}  {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
            <div>
            <div className={"productOuterContainer"}>
                <ProductDetailModal
                    product={product}
                    save={this.handleSaveProduct}
                    open={isProductDetailModalOpen}
                    close={this.toggleProductDetailsModal}
                    handleFileSelect={this.handleFileSelect}
                    handleChange={(e, name) => this.handleChange(e, name)}
                    productName={product.name ? product.name : "New Product"}
                />
                <div className={"pageTitleContainer"}>
                    <div className={"titleEditContainer"}>                       
                    </div>
                    <div className={"titleButtonContainer"}>
                        <Button
                            color="primary"
                            aria-label="add"
                            title="Edit Details"
                            style={{color: 'white'}}
                            className={"styledButton"}
                            onClick={this.toggleProductDetailsModal}
                        >
                            Edit Details
                        </Button>
                    </div>
                </div>
                <HorizontalLinearStepper
                    value={value}
                    workout={workout}
                    classes={classes}
                    product={product}
                    activeStep={value}
                    category={category}
                    productName={productName}
                    reset={this.resetWorkout}
                    search={this.searchActivities}
                    activities={renderedActivities}
                    handleSaveProduct={() => this.handleSaveProduct()}
                    includedActivities={includedActivities}
                    getActivities={() => this.loadActivities()}
                    cancelWorkoutBuilder={this.cancelWorkoutBuilder}
                    changeTab={index => this.handleTabValueChange(index)}
                />
                <SwipeableViews index={value} style={{ backgroundColor: 'white' }} >
                <div style={{ backgroundColor: 'white' }}>
                        <div className={"builderContainer"}>
                            <Grid container className={classNames("includedActivitiesContainer", "emptyContainer")}>
                                <Grid item xs={12}>
                                    <h2>Name your product...</h2>
                                    <TextField
                                        id="productName"
                                        className={"workoutProgramNameEdit"}
                                        label={"Product Name"}
                                        value={product.name ? product.name : ""}
                                        onChange={e => this.handleChange(e, "name")}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                        <div className={"searchComponentsContainer"}>
                            <Select
                                className={"categoryFilterSelect"}
                                onClose={this.toggleSelect}
                                onOpen={this.toggleSelect}
                                value={itemCategory}
                                onChange={e => this.handleCategoryChange(e)}
                            >
                                <MenuItem value={'activities'}>Activities</MenuItem>
                                <MenuItem value={'workouts'}>Workouts</MenuItem>
                                <MenuItem value={'workoutPrograms'}>Workout Programs</MenuItem>
                            </Select>
                            {itemCategory == 'activities' ?
                                <Select
                                    className={"categoryFilterSelect"}
                                    open={this.state.showCategoryFilter}
                                    onClose={this.toggleSelect}
                                    onOpen={this.toggleSelect}
                                    value={category}
                                    onChange={event => this.filterActivities(event)}
                                >
                                    <MenuItem value={'drill'}>Skill Development</MenuItem>
                                    <MenuItem value={'exercise'}>Strength &amp; Conditioning</MenuItem>
                                    <MenuItem value={'education'}>Education Materials</MenuItem>
                                </Select>
                            :
                                null
                            }
                            {itemCategory != 'workoutPrograms' ? <Select
                                onChange={this.filterByLabel}
                                className={"categoryFilterSelect"}
                                input={<Input id="scoreType"/>}
                                value={activeLabelFilter ? activeLabelFilter : ""}
                            >
                                <MenuItem value={'all'}>
                                    {'all'}
                                </MenuItem>
                                {filterableTags.map((tag, index) => (
                                    <MenuItem
                                        key={index}
                                        value={tag.label}>
                                        {tag.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            :
                            null
                            }
                            <TextField
                                id="search"
                                className={"categoryFilterSelect"}
                                onChange={this.handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={"selectContainer"}>
                            {!searchString && filteredTaggedGroupings.length ? _.orderBy(filteredTaggedGroupings, [group => group.label]).map((group, index) => {
                                return <Grid
                                            container
                                            key={index}
                                            spacing={16}
                                            style={{ paddingBottom: 10 }}
                                        >
                                    {(itemCategory == 'workouts' && group.workouts && group.workouts.length) || (itemCategory == 'activities' && group.activities &&  group.activities.length) ? <Grid item xs={12} style={{textAlign: 'left', backgroundColor: '#28272C', padding: 0, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <LabelIcon style={{ color: 'white' }} />
                                        <h2 style={{ fontSize: '1.1em', color: 'white', paddingLeft: 10 }}>{group.label ? group.label.toLowerCase().replace(/-/g, ' ').split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : "Unlabeled"}  <span style={{ color: 'gray', fontSize: '0.8em', fontWeight: 'lighter', marginLeft: 5 }}>{(itemCategory == 'activities' && group.activities.length) ? `${group.activities.length} activities` : (itemCategory == 'workouts' && group.workouts.length) ? `${group.workouts.length} workouts` : itemCategory == 'workouts' ? '0 workouts' : '0 activities'}</span></h2>
                                    </Grid>
                                    :
                                    null}
                                    <Grid item xs={12}>
                                        <Hidden smDown>
                                            {itemCategory == 'activities' && group.activities && group.activities.length ?
                                                <Slider {...carouselSettings} className={group.activities.length > 5 ? "multipleElements" : null}>
                                                    {group.activities.length ? _.orderBy(group.activities, 'name').map((activity, index) => {
                                                        return <div key={index}>
                                                            <ActivityCard
                                                                activity={activity}
                                                                view={this.handleOpen}
                                                                currentTeam={currentTeam}
                                                                delete={this.handleDelete}
                                                                include={this.includeItem}
                                                                included={this.itemIncluded(activity)}
                                                            />
                                                        </div>

                                                    })
                                                    :
                                                    <h2 className={"emptyWorkoutsText"}>Hey coach, add some activities...</h2>
                                                    }
                                                </Slider>
                                            :
                                            itemCategory == 'workouts' && group.workouts && group.workouts.length ?
                                                <Slider {...carouselSettings} className={group.workouts.length > 5 ? "multipleElements" : null}>
                                                    {group.workouts.length ? _.orderBy(group.workouts, 'name').map((workout, index) => {
                                                        return <div key={index}>
                                                                <WorkoutCard
                                                                workout={workout}
                                                                isSliding={isSliding}
                                                                view={this.handleOpen}
                                                                delete={this.handleDelete}
                                                                include={this.includeItem}
                                                                included={this.itemIncluded(workout)}
                                                            />
                                                        </div>
                                                    })
                                                    :
                                                    <h2 className={"emptyWorkoutsText"}>Hey coach, add some workouts...</h2>
                                                    }
                                                </Slider>
                                            :
                                            itemCategory == 'workoutPrograms' && group.workoutPrograms && group.workoutPrograms.length ?
                                                <Slider {...carouselSettings} className={group.workoutPrograms.length > 5 ? "multipleElements" : null}>
                                                    {group.workoutPrograms.length ? _.orderBy(group.workoutPrograms, 'name').map((workout, index) => {
                                                        return <div key={index}>
                                                                <WorkoutCard
                                                                workout={workout}
                                                                isSliding={isSliding}
                                                                view={this.handleOpen}
                                                                delete={this.handleDelete}
                                                                include={this.includeItem}
                                                                included={this.itemIncluded(workout)}
                                                            />
                                                        </div>
                                                    })
                                                    :
                                                    <h2 className={"emptyWorkoutsText"}>Hey coach, add some workout programs...</h2>
                                                    }
                                                </Slider>
                                            :
                                                <h2 className={"emptyWorkoutsText"}>Hey coach, add some items...</h2>
                                            }
                                        </Hidden>
                                        <Hidden mdUp>
                                            {itemCategory == 'activities' && group.activities && group.activities.length ?
                                                <Slider {...carouselSettings} className={"multipleElements"}>
                                                    {_.orderBy(group.activities, 'name').map((activity, index) => {
                                                        return <div key={index}>
                                                            <ActivityCard
                                                                activity={activity}
                                                                view={this.handleOpen}
                                                                currentTeam={currentTeam}
                                                                delete={this.handleDelete}
                                                                include={this.includeItem}
                                                                included={this.itemIncluded(activity)}
                                                            />
                                                        </div>
                                                    })}
                                                </Slider>
                                            :
                                            itemCategory == 'workouts' && group.workouts && group.workouts.length ?
                                                <Slider {...carouselSettings} className={"multipleElements"}>
                                                    {group.workouts.length ? _.orderBy(group.workouts, 'name').map((workout, index) => {
                                                        return <div key={index}>
                                                            <WorkoutCard
                                                                workout={workout}
                                                                isSliding={isSliding}
                                                                view={this.handleOpen}
                                                                delete={this.handleDelete}
                                                                include={this.includeItem}
                                                                included={this.itemIncluded(workout)}
                                                            />
                                                        </div>
                                                    })
                                                    :
                                                    <h2 className={"emptyWorkoutsText"}>Hey coach, add some workouts...</h2>
                                                    }
                                                </Slider>
                                            :
                                            itemCategory == 'workoutPrograms' && group.workoutPrograms && group.workoutPrograms.length ?
                                                <Slider {...carouselSettings} className={"multipleElements"}>
                                                    {group.workoutPrograms.length ? _.orderBy(group.workoutPrograms, 'name').map((workoutProgram, index) => {
                                                        return <div key={index}>
                                                            <WorkoutProgramCard
                                                                isSliding={isSliding}
                                                                view={this.handleOpen}
                                                                delete={this.handleDelete}
                                                                include={this.includeItem}
                                                                workoutProgram={workoutProgram}
                                                                included={this.itemIncluded(workoutProgram)}
                                                            />
                                                        </div>
                                                    })
                                                    :
                                                    <h2 className={"emptyWorkoutsText"}>Hey coach, add some workout programs...</h2>
                                                    }
                                                </Slider>
                                            :
                                                <Grid item xs={12}>
                                                    <p>add some items...</p>
                                                </Grid>
                                            }
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            })
                            :
                            <Grid
                                container
                                spacing={16}
                            >
                                {searchString && itemCategory == 'activities' && renderedActivities.length ? _.orderBy(renderedActivities, [activity => activity.name.toLowerCase()], ['asc']).map((activity, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={`${activity.id}-${index}`}
                                    >
                                        <ActivityCard
                                            activity={activity}
                                            view={this.handleOpen}
                                            currentTeam={currentTeam}
                                            delete={this.handleDelete}
                                            include={this.includeItem}
                                            included={this.itemIncluded(activity)}
                                        />
                                    </Grid>
                                ))
                                :
                                searchString && itemCategory == 'workouts' && renderedWorkouts.length ? _.orderBy(renderedWorkouts, [workout => workout.name.toLowerCase()], ['asc']).map((workout, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={`${workout.id}-${index}`}
                                    >
                                        <WorkoutCard
                                            workout={workout}
                                            isSliding={isSliding}
                                            view={this.handleOpen}
                                            delete={this.handleDelete}
                                            include={this.includeItem}
                                            included={this.itemIncluded(workout)}
                                        />
                                    </Grid>
                                ))
                                :
                                searchString && itemCategory == 'workoutPrograms' && renderedWorkoutPrograms.length ? _.orderBy(renderedWorkoutPrograms, [workoutProgram => workoutProgram.name.toLowerCase()], ['asc']).map((workoutProgram, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={`${workoutProgram.id}-${index}`}
                                    >
                                        <WorkoutProgramCard
                                            isSliding={isSliding}
                                            view={this.handleOpen}
                                            delete={this.handleDelete}
                                            include={this.includeItem}
                                            workoutProgram={workoutProgram}
                                            included={this.itemIncluded(workoutProgram)}
                                        />
                                    </Grid>
                                ))
                                :
                                <Grid item xs={12}>
                                    <p>add some items...</p>
                                </Grid>
                                }
                            </Grid>
                            }
                        </div>
                    </div>
                    <div>
                        <div className={"builderContainer"}>
                            {product.workoutPrograms.length ?
                                <div style={{ width: '100%' }}>
                                    <div className={'itemCategoryTitleContainer'}>
                                        <h2 style={{ marginLeft: 10 }}>Workout Programs</h2>
                                    </div>
                                    {product.workoutPrograms.map(workoutProgram => {
                                    return <div key={workoutProgram.id} className={'itemContainer'}>
                                        <p style={{ marginLeft: 15 }}>{workoutProgram.name}</p>
                                        <CloseIcon style={{ color: 'white' }} onClick={() => this.removeWorkoutProgram(workoutProgram)} />
                                    </div>
                                    })}
                                </div>
                            :
                            null
                            }
                            {product.workouts.length ?
                                <div style={{ width: '100%' }}>
                                    <div className={'itemCategoryTitleContainer'}>
                                        <h2 style={{ marginLeft: 10 }}>Workouts</h2>
                                    </div>
                                    {product.workouts.map(workout => {
                                    return <div key={workout.id} className={'itemContainer'}>
                                        <p style={{ marginLeft: 15 }}>{workout.name}</p>
                                        <CloseIcon style={{ color: 'white' }} onClick={() => this.removeWorkout(workout)} />
                                    </div>
                                    })}
                                </div>
                            :
                            null
                            }
                            {product.activities.length ?
                                <div style={{ width: '100%' }}>
                                    <div className={'itemCategoryTitleContainer'}>
                                        <h2 style={{ marginLeft: 10 }}>Activities</h2>
                                    </div>
                                    {product.activities.map(activity => {
                                    return <div key={activity.id} className={'itemContainer'}>
                                        <p style={{ marginLeft: 15 }}>{activity.name}</p>
                                        <CloseIcon style={{ color: 'white', marginLeft: 10, cursor: 'pointer' }} onClick={() => this.removeActivity(activity)} />
                                    </div>
                                    })}
                                </div>
                            :
                            null
                            }
                        </div>
                    </div>
                </SwipeableViews>
            </div>
            </div>
            </PageWrapper>
        );
    }
}

class ActivityCard extends Component {
    state = { expanded: false };


    handleIncludeActivity = () => {
        this.props.include(this.props.activity);
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        let { included, currentTeam } = this.props;

        return (
            <div style ={{ paddingRight: 5 }}>
                <Card className={included ? classNames("workoutActivityCard", "includedActivity") : "workoutActivityCard"}>
                    <div className={included ? "selectedOverlay" : null}>
                        <div onClick={this.handleIncludeActivity}>
                            <CardMedia image={this.props.activity.thumbnail ? this.props.activity.thumbnail : this.props.activity.attachment ? this.props.activity.attachment : logo}  className={"media"} />
                        </div>
                        <div className={"contentActionContainer"}>
                            <div className={"cardContent"}>
                                <Typography style={{ color: 'white', fontSize: '0.8em', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="headline" component="h3">
                                    {this.props.activity.name}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

class WorkoutCard extends Component {
    state = { expanded: false };


    handleIncludeWorkout = () => {
        const { isSliding, workout } = this.props;
        if(!isSliding) this.props.include(this.props.workout);
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        let { included, isSliding } = this.props;

        return (
            <div style={{paddingRight: 5}}>
                <Card className={included ? classNames("workoutCard", "includedActivity") : "workoutCard"} onClick={this.handleIncludeWorkout}>
                    <div className={included ? "selectedOverlay" : null}>
                        <div className={"contentActionContainer"} >
                            <div className={"cardContent"}>
                                <Typography style={{ color: 'white', fontSize: '1em', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="headline" component="h3">
                                    {this.props.workout.name}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

class WorkoutProgramCard extends Component {
    state = { expanded: false };


    handleIncludeWorkoutProgram = () => {
        const { isSliding, workoutProgram } = this.props;
        if(!isSliding) this.props.include(this.props.workoutProgram);
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        let { included, isSliding } = this.props;

        return (
            <div style={{paddingRight: 5}}>
                <Card className={included ? classNames("workoutCard", "includedActivity") : "workoutCard"} onClick={this.handleIncludeWorkoutProgram}>
                    <div className={included ? "selectedOverlay" : null}>
                        <div className={"contentActionContainer"} >
                            <div className={"cardContent"}>
                                <Typography style={{ color: 'white', fontSize: '1em', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="headline" component="h3">
                                    {this.props.workoutProgram.name}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

class ProductDetailModal extends Component {

    render() {
        const { open, productName, product } = this.props;
        const priorityOptions = [{value: 'normal'}, {value: 'recommended'}, {value: 'featured'}]
        const sports = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Other'];
        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"productDetailModalPaper"}>
                    <div className={"modalContent"}>
                        <div className={"modalHeaderContainer"}>
                            <h2 className={"modalHeaderText"}>{productName}</h2>
                        </div>
                        <Grid container spacing={8} style={{ marginTop: 70 }}>
                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    className={"textField"}
                                    value={product.name ? product.name : ""}
                                    onChange={e => this.props.handleChange(e, "name")}
                                    margin={"dense"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={"selectField"}>
                                    <InputLabel htmlFor="sport">Sport</InputLabel>
                                    <Select
                                        value={product.sport ? product.sport : ''}
                                        onChange={e => this.props.handleChange(e, "sport")}
                                        input={<Input id="sport" />}
                                    >
                                        {sports.map(sport => (
                                        <MenuItem
                                            key={sport}
                                            value={sport}
                                        >
                                            {sport}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={"selectField"}>
                                    <InputLabel>
                                        Priority
                                    </InputLabel>
                                    <Select
                                        value={product.priority ? product.priority : ""}
                                        onChange={e => this.props.handleChange(e, "priority")}
                                        input={<Input id="scoreType"/>}>
                                        {priorityOptions.map(priority => (
                                            <MenuItem
                                                key={priority.value}
                                                value={priority.value}>
                                                {priority.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={"selectField"}>
                                    <InputLabel htmlFor="isActive">Active</InputLabel>
                                    <Select
                                        value={product.isActive ? product.isActive : 'true'}
                                        onChange={e => this.props.handleChange(e, "isActive")}
                                        input={<Input id="isActive" />}>
                                        <MenuItem
                                            key={0}
                                            value={'false'}
                                        >
                                            No
                                        </MenuItem>
                                        <MenuItem
                                            key={1}
                                            value={'true'}
                                        >
                                            Yes
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="fileUrl"
                                    margin={"dense"}
                                    label="Featured Image"
                                    className={"textField"}
                                    value={product.featureImageUrl ? product.featureImageUrl : ""}
                                />
                            </Grid>
                            <Grid item xs={6} className={"uploadButtonContainer"}>
                                <input
                                    accept="image/*"
                                    className={"input"}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                    onChange={this.props.handleFileSelect}
                                    margin={"dense"}/>
                                <label htmlFor="raised-button-file">
                                    <Button
                                        variant="raised"
                                        component="span"
                                        className={"uploadButton"}>
                                        Upload File
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={"modalFooterContainer"}>
                        <div>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                className={"outlinedButton"}
                                onClick={this.props.close}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={"filledButton"}
                                onClick={this.props.save}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

class HorizontalLinearStepper extends Component {
    state = {
        showCategoryFilter: false,
        category: 'exercise'
    };

    handleNext = () => {
        const { activeStep } = this.props;
        const steps = this.getSteps();

        if(activeStep === 0) {
            this.props.getActivities();
        }

        if(activeStep === 2) {
            return this.props.handleSaveProduct();
        }

        this.props.changeTab(activeStep + 1);
    };

    handleCancel = () => {
        this.props.cancelWorkoutBuilder()
    }

    handleBack = () => {
      const { activeStep, activities } = this.props;
      if(activeStep == 2 && !activities.length){
          this.props.getActivities()
      }
      this.props.changeTab(activeStep - 1);
    };

    handleReset = () => {
        this.props.reset();
    };

    getSteps() {
        return ['Let\'s Go', 'Select Items', 'Review Product'];
    }

    toggleSelect = () => {
        this.setState({ showCategoryFilter: !this.state.showCategoryFilter });
    }

    filterActivities = (event) => {
        this.setState({ category: event.target.value });
        this.props.filterActivities(event);
    }

    render() {
      const { classes, activeStep, workout, includedActivities, activities, allActivitiesValidated, product, productName } = this.props;
      const { category } = this.state;
      const steps = this.getSteps();

      return (
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const props = {};
                    const labelProps = {};
                    if(index <= activeStep) labelProps.className = "filledCircle";;
                    return (
                        <Step key={label} {...props}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
          </Stepper>
          <div>
            {activeStep == steps.length ? (
                <div className={"stepperButtonContainer"}>
                    <Button onClick={this.handleReset} className={"longMutedProgressButton"} size="small" >
                        Build Another
                    </Button>
                    <Button onClick={this.handleCancel} className={"longFilledButton"} size="small" >
                        View Workouts
                    </Button>
                </div>
            ) : (
                <div className={"stepperButtonContainerWithSearch"}>
                    <div>
                        {activeStep !== 0 ? <Button
                            onClick={this.handleBack}
                            className={"mutedProgressButton"}
                            size="small" >
                            Back
                        </Button>
                        : null}
                        <Button
                            onClick={this.handleCancel}
                            className={"mutedProgressButton"}
                            size="small" >
                            Cancel
                        </Button>
                        {activeStep == 0 && product.name || activeStep == 1 && (product.activities.length > 0 && product.workouts.length > 0) ?
                            <Button
                                disabled={
                                    activeStep == 0 && !product.name ||
                                    activeStep == 1 && (product.activities.length === 0 && product.workouts.length === 0)
                                }
                                onClick={this.handleNext}
                                className={(activeStep == 1 && (product.activities.length === 0 && product.workouts.length === 0)) ? classNames("filledButton", "disabledFilledButton") : "filledButton"}
                                size="small" >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        :
                            <Button
                                disabled={
                                    activeStep == 0 && !product.name ||
                                    activeStep == 1 && (product.activities.length === 0 && product.workouts.length === 0)
                                }
                                onClick={this.handleNext}
                                className={(activeStep == 0 && !product.name) || (activeStep == 1 && (product.activities.length === 0 && product.workouts.length === 0)) ? classNames("filledButton", "disabledFilledButton") : "filledButton"}
                                size="small" >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        }
                    </div>
                </div>
            )}
          </div>
          <div className={"horizontalLineBreak"} />
        </div>
      );
    }
  }

ProductsNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>  {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{}
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ProductsNew));
