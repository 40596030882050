import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import RecipientListItem from '../recipientListItem'
import './styles.css'

let categories = ['all', 'athletes', 'coaches', 'parents']

class SearchOverlay extends Component {
    render(){
        const {
            close,
            activeCategory,
            recipients = [],
            searchValue = '',
            onClickRecipient,
            isTrueLacrosse = false,
            isOrganization = false,
            selectedRecipients = []
        } = this.props

        return(
            <div className={'searchOverlayOuterContainer'}>
                <div className={'searchOverlayInnerContainer'}>
                    {this.renderCategorySelector()}
                    <div className={'filterableRecipientsListContainer'}>
                        {recipients.filter(r => r).map((recipient, i) => {
                            return (
                                <RecipientListItem
                                    key={i}
                                    order={i}
                                    recipient={recipient}
                                    searchValue={searchValue}
                                    onHandleClick={() => onClickRecipient(recipient)}
                                    selected={_.find(selectedRecipients, r => r && r.id === recipient.id)}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    renderCategorySelector(){
        const {
            onClick,
            currentTeam,
            activeCategory,
            tenant
        } = this.props

        categories = tenant === 'coachup' ?
            _.without(categories, 'parents', 'coaches') : tenant === "kickid" ? _.without(categories, 'parents') : categories

        return (
            <div className={'categoriesContainer'}>
                {categories.map((category, i) => {
                    return(
                        <div
                            key={i}
                            onClick={() => onClick(category)}
                            className={
                                activeCategory == category ? 'categoryContainer activeCategory' :
                                'categoryContainer'
                            }
                        >
                            <p>{category.toUpperCase()}</p>
                        </div>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tenant: state.tenant.id,
});


export default connect(mapStateToProps)(SearchOverlay)
