import React from "react";
import PropTypes from "prop-types";
import './chat.css'
import {
    IconButton,
    Tooltip
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

const UserBar = ({ name, registered, signout, switchView, toggleGroupModal, conversationLength }) => (
  <div className="topbar">
    <nav>
      <div style={{width: '100%', color:"white", display: "flex", alignItems: 'center', justifyContent: 'space-between', padding: '0 15px'}}>
        <p style={{ fontSize: '0.9em', margin: 0 }}>Conversations ({conversationLength})</p>
        <Tooltip title="Add" placement="left">
          <IconButton aria-label="Add" onClick={toggleGroupModal}>
            <AddIcon className={"addGroupIcon"} />
          </IconButton>
        </Tooltip> 
      </div>
    </nav>
  </div>
);
UserBar.propTypes = {
  name: PropTypes.string,
  registered: PropTypes.bool,
  switchView: PropTypes.func.isRequired,
  toggleGroupModal: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired
};

export default UserBar;
