import { combineReducers } from 'redux';
import {
    GET_SCHEDULES_BY_PARENT_ID_LOADING,
    GET_SCHEDULES_BY_PARENT_ID_SUCCESS,
    GET_SCHEDULES_BY_PARENT_ID_ERROR,

} from '../actions/schedules-actions';

const tags = (state = { loading: false, list: [], error: '' }, action) => {
    switch (action.type) {
        case GET_SCHEDULES_BY_PARENT_ID_LOADING: {
            return {
                ...state,
                loading: true,
                list: state.list,
                error: ''
            }
        }
        case GET_SCHEDULES_BY_PARENT_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.data.results,
                error: ''
            }
        }

        case GET_SCHEDULES_BY_PARENT_ID_ERROR: {
            return {
                ...state,
                loading: false,
                list: [],
                error: 'No activity found'
            }
        }
    }
    return state;
}

export default combineReducers({
    tags
});
