import React, { Component } from "react";
import { connect } from "react-redux";
import UserInfo from "./userInfo";
import { PrimaryButton } from "../../components/v2/buttons";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      userContext,
      onClickUser,
      history,
      handleLogout,
      title = "Dashboard",
      fullWidth = false,
      canEditProfile,
      isInBeta,
      secondaryAction,
    } = this.props;
    return (
      <div className={`pageHeader ${fullWidth && "fullWidth"}`}>
        <div className={`pageTitle ${isInBeta ? "displayInline" : ""}`}>
          {title} {isInBeta ? <div className="isInBeta">Beta</div> : null}{" "}
        </div>
        <div className="d-flex align-items-center">
          {secondaryAction ? <div style={{ marginRight: "10%" }}>{secondaryAction}</div> : null}
          <UserInfo
            userContext={userContext}
            canEditProfile={canEditProfile}
            isOpen={true}
            onClick={onClickUser}
            history={history}
            handleLogout={handleLogout}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    session: { userContext, appContext },
  } = state;
  return {
    userContext,
    appContext,
  };
};

export default connect(mapStateToProps)(Header);
