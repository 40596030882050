import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Merge = (props) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
    <path d="M11.9541 10.5762C11.3214 10.5762 10.7142 10.411 10.181 10.1094C9.64855 9.80793 9.19351 9.37226 8.8678 8.83009C8.8346 8.77476 8.80279 8.71806 8.77236 8.66135C8.69491 8.51405 8.47777 8.54241 8.43766 8.70423C8.26616 9.39784 7.99231 10.0645 7.62095 10.682C7.47849 10.9192 7.3222 11.1468 7.15416 11.3646C7.61887 11.8729 8.15897 12.3016 8.75093 12.637C9.71148 13.1806 10.8131 13.48 11.9541 13.48C12.7563 13.48 13.4064 12.83 13.4064 12.0278C13.4064 11.2263 12.7563 10.5762 11.9541 10.5762Z" />
    <path d="M7.95778 6.14416C7.95778 5.91941 8.14035 5.73684 8.3651 5.73684H9.95288C9.98953 5.73062 10.0255 5.72232 10.0615 5.70918C10.3007 5.62481 10.3982 5.34681 10.2973 5.11376C10.2579 5.02317 10.2025 4.94088 10.1486 4.85789C9.502 3.85861 8.8554 2.85864 8.20812 1.85937C7.87411 1.34348 7.5394 0.828277 7.20539 0.312387C7.08229 0.122213 6.92877 -0.00917989 6.68327 0.000501692C6.45921 0.00949173 6.32229 0.140885 6.21095 0.313079C5.74278 1.03643 5.27599 1.75978 4.8085 2.48383C4.27256 3.31299 3.73454 4.14145 3.20136 4.97269C3.09694 5.13589 3.00427 5.31292 3.11215 5.5114C3.19514 5.66354 3.32999 5.7154 3.47798 5.73615H4.64599C4.87074 5.73615 5.05331 5.91872 5.05331 6.14347V6.92767C5.05331 7.44011 4.95788 7.95046 4.75111 8.42002C4.42746 9.15444 3.88668 9.73533 3.22556 10.1095C2.69308 10.411 2.08521 10.5762 1.45245 10.5762C0.641965 10.5762 -0.0136159 11.2401 0.000214891 12.0534C0.0140457 12.8549 0.695214 13.4856 1.49602 13.4807C2.62116 13.4731 3.70688 13.1744 4.65498 12.6377C5.61622 12.0942 6.43985 11.3044 7.02766 10.3266C7.63553 9.31488 7.95709 8.15654 7.95709 6.97608V6.14416H7.95778Z" />
  </SvgIcon>
);

export default Merge;