import React from "react";
import { withStyles } from "@material-ui/core/styles";
const styles = () => ({
  shadowRoundContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
    borderRadius: "16px",
  },
});
const ShadowRoundContainer = (props) => {
  const { classes, children, shadowContainerStyle } = props;
  return (
    <div className={classes.shadowRoundContainer} style={shadowContainerStyle}>
      {children}
    </div>
  );
};
export default withStyles(styles)(ShadowRoundContainer);
