import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import ListCard from '../../components/listCard';
import SearchIcon from '@material-ui/icons/Search';
import BorderedInput from '../../components/borderedInput';
import OptionsSelector from '../../components/optionsSelector';
import { deleteWorkout } from '../../services/workoutsService';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CardListItem from '../../components/cardListItem';
import { WorkoutAssignmentModal, CalendarModal } from 'maxone-workout-programs';
import DeleteConfirmationModal from '../../components/modals/deleteConfirmationModal';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
	resolveWorkoutList,
	resolveFilteredWorkoutList,
} from '../../utils/workoutUtils';

import {
	resolveStartDayOptions,
	resolveWorkoutScheduleOptions,
	createWorkoutScheduleAssignments,
} from '../../utils/workoutScheduleUtils';
import { getLocalizedString } from '../../utils/locale-utils';

import './styles.css';
import PageWrapper from '../nav/pageWrapper';
import {
	analyticsService,
	currentTimestamp,
} from '../../services/analyticsService';
import BodyWrapper from '../../components/bodyWrapper';
import { withStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';
import classnames from 'classnames';

const styles = {
	workoutListOuterContainer: {
		paddingTop: '15px',
		width: '100%',
		height: 'calc(100vh - 120px)',
		overflow: 'hidden',
		'& p.clickableValueText': {
			marginBottom: '0 !important',
		},
	},
	addContentLoading: {
		marginLeft: '30px',
		marginBottom: '20px',
	},
	listContainer: {
		padding: '0px',
		paddingBottom: '90px',
		overscrollBehavior: 'contain',
		height: 'calc(100vh - 180px)',
		overflow: 'scroll',
		'& > div': {
			width: '100%',
		},
	},
	gridContainer: {
		cursor: 'pointer',
		maxWidth: '460px',
		minWidth: '350px',
		flex: 1,
		marginRight: '20px',
	},
	workoutListCardContainer: {
		padding: '30px',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		overflow: 'scroll',
		paddingBottom: '90px',
		paddingRight: '15px',
	},
};

class WorkoutsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: -1,
			allTags: [],
			startDay: 1,
			workouts: [],
			isLoading: true,
			viewingWorkout: {},
			workoutSchedules: [],
			filteredWorkouts: [],
			selectedTagFilter: {},
			isSelectingTag: false,
			workoutScheduleId: null,
			notificationReminder: 0,
			workoutSearchString: null,
			isCalendarModalOpen: false,
			isAssignmentModalOpen: false,
			showAssignmentSuccess: false,
			assignedAt: new Date().getTime(),
			loadingChildData: false,
		};
	}

	componentDidMount() {
		this.fetchWorkoutData();
	}

	fetchWorkoutData = async (page) => {
		const { currentTeam } = this.props;
		let {
			currentPage,
			workoutSearchString,
			selectedTagFilter,
			filteredWorkouts: existingWorkouts,
			allTags: existingTags,
		} = this.state;
		try {
			this.setState({ isFetchingData: true });
			currentPage = page != undefined ? page : currentPage + 1;
			let pageLimit = 20;
			let params = {
				limit: pageLimit,
				offset: pageLimit * currentPage,
			};
			if (!!selectedTagFilter && selectedTagFilter.id) {
				params['tags.slug'] = selectedTagFilter.id;
			}
			if (workoutSearchString) {
				params.q = workoutSearchString;
			}
			let { workouts, allTags, total } = await resolveWorkoutList(
				currentTeam.id,
				params
			);
			if (currentPage != 0) {
				workouts = existingWorkouts.concat(workouts);
			}
			this.setState({
                allTags:
                !!selectedTagFilter && selectedTagFilter.id ? existingTags : allTags,
				workouts,
				isLoading: false,
				filteredWorkouts: workouts,
				total,
				currentPage,
				loadingChildData: false,
			});
		} catch (ex) {}
		this.setState({ isLoading: false, isFetchingData: false });
	};
    
	handleEditWorkout = (workout) => {
        const { history } = this.props;
		history.push('/workouts/new', { workoutId: (workout || {}).id });
	};
    
	handleDeleteWorkout = async (workout) => {
        this.setState({ viewingWorkout: workout }, this.toggleDeleteModal())
	};

    toggleDeleteModal = () => {
        const { isDeleteModalOpen } = this.state;
        this.setState({ isDeleteModalOpen: !isDeleteModalOpen });
    };

    confirmDelete = () => {
        const { workouts, viewingWorkout, filteredWorkouts, isDeleteModalOpen } = this.state;
        deleteWorkout(viewingWorkout.id);
        this.setState({
					workouts: _.without(workouts, viewingWorkout),
					filteredWorkouts: _.without(filteredWorkouts, viewingWorkout),
					workout: {},
					isDeleteModalOpen: !isDeleteModalOpen,
				});
    };
    
	handleAssignWorkout = async (workout) => {
		const { currentTeam = {} } = this.props;
		const { workoutSchedules, viewingWorkout } = this.state;
		if (!workoutSchedules.length) {
			const workoutSchedules = await resolveWorkoutScheduleOptions(
				currentTeam.id
			);
			this.setState({ workoutSchedules: workoutSchedules });
		}
		this.setState({ viewingWorkout: workout });
		this.toggleAssignmentModal();
	};

	toggleFilterMenu = () => {
		const { isSelectingTag } = this.state;
		this.setState({ isSelectingTag: !isSelectingTag });
	};

	toggleAssignmentModal = () => {
		const { isAssignmentModalOpen } = this.state;
		this.setState({
			isLoading: false,
			showAssignmentSuccess: false,
			isAssignmentModalOpen: !isAssignmentModalOpen,
		});
	};

	handleChange = (value, name) => {
		this.setState({ [name]: value });
	};

	handleDayChange = (date) => {
		this.setState({ selectedDate: date });
	};

	handleTagSelection = async (tag) => {
		const { workouts, selectedTagFilter } = this.state;
		let newTag =
			!!selectedTagFilter && !!tag && selectedTagFilter.id == tag.id ? {} : tag;
		this.setState({ selectedTagFilter: newTag, loadingChildData: true }, () =>
			this.fetchWorkoutData(0)
		);
		this.toggleFilterMenu();
	};

	handleWorkoutSearch = (event, timeOut = 0) => {
		if (this.typingTimeout) {
			clearTimeout(this.typingTimeout);
		}
		this.setState(
			{
				workoutSearchString: event.target.value,
			},
			() => {
				this.typingTimeout = setTimeout(() => {
					this.setState({ loadingChildData: true });
					this.fetchWorkoutData(0);
				}, timeOut || 1000);
			}
		);
	};

	handleCreateAssignments = async () => {
		const { domain } = this.props;
		const {
			startDay,
			assignedAt,
			workoutScheduleId,
			notificationReminder,
			viewingWorkout,
		} = this.state;
		try {
			this.setState({ isLoading: true });
			console.log('!!');
			const createdAssignment = await createWorkoutScheduleAssignments(
				domain,
				startDay,
				assignedAt,
				workoutScheduleId,
				notificationReminder,
				viewingWorkout
			);
			console.log('!!!');
			console.log(createdAssignment);
			console.log(
				startDay,
				notificationReminder,
				workoutScheduleId,
				assignedAt
			);
			this.setState({ isLoading: false, showAssignmentSuccess: true });
			analyticsService.trackEvent('Assignment Assigned', {
				type: 'workout',
				start_day: startDay,
				notification: notificationReminder,
				workout_id: workoutScheduleId,
			});
		} catch (e) {
			console.log(e);
			this.toggleAssignmentModal();
		}
	};

	confirmStartDate = () => {
		const { selectedDate } = this.state;
		this.setState({ assignedAt: selectedDate });
	};

	toggleCalendarModal = () => {
		const { isCalendarModalOpen, isAssignmentModalOpen } = this.state;
		this.setState({
			isCalendarModalOpen: !isCalendarModalOpen,
			isAssignmentModalOpen: !isAssignmentModalOpen,
		});
	};

	handleDateConfirm = () => {
		this.toggleCalendarModal();
		this.confirmStartDate();
	};


	render() {
		const {
			allTags,
			isLoading,
			assignedAt,
			isSelectingTag,
			workoutSchedules,
			filteredWorkouts,
			selectedTagFilter,
			workoutSearchString,
			isCalendarModalOpen,
			showAssignmentSuccess,
			isAssignmentModalOpen,
			viewingWorkout,
			currentPage,
			total,
			loadingChildData,
			isFetchingData,
			isDeleteModalOpen,
		} = this.state;
		let { classes } = this.props;
		const cardFooterElements = [
			{
				name: 'Delete',
				icon: <DeleteIcon />,
				action: this.handleDeleteWorkout,
			},
			{
				name: 'Assign',
				icon: <AssignmentTurnedInIcon />,
				action: this.handleAssignWorkout,
			},
			{
				name: 'Edit',
				icon: <EditIcon />,
				action: this.handleEditWorkout,
			},
		];
		return (
			<PageWrapper title={getLocalizedString('WORKOUTS')} {...this.props}>
				<BodyWrapper
					enableSubContainerStyle={true}
					removePadding={true}
					showLoadingChild={true}
					isLoading={loadingChildData}>
					<div className={classes.workoutListOuterContainer}>
						<div className={'workoutListHeaderContainer'}>
							<p className={'workoutListHeaderTitle'}></p>
							<div className={'inputContainer'}>
								<BorderedInput
									leftIcon={<SearchIcon />}
									value={workoutSearchString}
									placeholder={'Search Workouts'}
									onChange={this.handleWorkoutSearch}
								/>
								<div style={{ marginLeft: 10, width: '100%' }}>
									<BorderedInput
										clickable={true}
										value={selectedTagFilter.name}
										placeholder={'Filter by Tags'}
										rightIcon={<ArrowDropDownIcon />}
										handleClick={this.toggleFilterMenu}
									/>
									{isSelectingTag && (
										<OptionsSelector
											options={allTags}
											close={this.toggleFilterMenu}
											className={'optionsSelector'}
											selectedOption={selectedTagFilter}
											onClickOption={this.handleTagSelection}
										/>
									)}
								</div>
							</div>
						</div>
						<div
							className={classnames(classes.listContainer)}
							ref={(ref) => (this.scrollParentRef = ref)}>
							<InfiniteScroll
								pageStart={currentPage}
								loadMore={() =>
									isFetchingData ? null : this.fetchWorkoutData()
								}
								hasMore={filteredWorkouts.length < total}
								useWindow={false}
								getScrollParent={() => this.scrollParentRef}
								loader={
									<div className={classes.addContentLoading}>Loading ...</div>
								}>
								<div className={classes.workoutListCardContainer}>
									<div className={classes.gridContainer}>
										<CardListItem
											addingListItem={true}
											onEditSelect={this.handleEditWorkout}
										/>
									</div>
									{isLoading && <ListCard title='Loading...' loading={true} />}
									{filteredWorkouts.map((workout, index) => {
										return (
											<div className={classes.gridContainer} key={index}>
												<CardListItem
													item={workout}
													details={workout.details}
													subtitle={workout.subtitle}
                                                    subtitleChipText={workout.isCoachesOnly ? "Coaches" : "All Users"}
													cardFooterElements={cardFooterElements}
													onEditSelect={this.handleEditWorkout}
													onAssignSelect={this.handleAssignWorkout}
													onDeleteSelect={this.handleDeleteWorkout}
												/>
											</div>
										);
									})}
								</div>
							</InfiniteScroll>
						</div>
					</div>
					<WorkoutAssignmentModal
						isLoading={isLoading}
						assignedAt={assignedAt}
						open={isAssignmentModalOpen}
						selectedWorkoutProgramId={null}
						toggle={this.toggleAssignmentModal}
						workoutSchedules={workoutSchedules}
						viewingWorkout={viewingWorkout}
						//handleDayChange={this.handleDayChange}
						//confirmStartDate={this.confirmStartDate}
						showAssignmentSuccess={showAssignmentSuccess}
						handleCreateAssignments={this.handleCreateAssignments}
						onChange={(value, name) => this.handleChange(value, name)}
						toggleCalendarModal={this.toggleCalendarModal}
					/>
					<CalendarModal
						open={isCalendarModalOpen}
						toggle={this.toggleCalendarModal}
						handleDayChange={this.handleDayChange}
						confirmStartDate={this.handleDateConfirm}
					/>
					<DeleteConfirmationModal
						open={isDeleteModalOpen}
						name={viewingWorkout.title}
						toggle={this.toggleDeleteModal}
						confirmDelete={this.confirmDelete}
						confirmationText={'Remove Workout'}
					/>
				</BodyWrapper>
			</PageWrapper>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		session: { userContext, appContext },
	} = state;
	const currentTeam = userContext.appContextList.find(
		(c) => c.id === appContext.id
	);
	return { currentTeam: currentTeam };
};

export default withStyles(styles)(connect(mapStateToProps)(WorkoutsList));
