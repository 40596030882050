import React, { Component, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames'
import ThemeButton from '../../components/themeButton'
import Pagination from '../../components/pagination'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

let styles = {
    tableInfo: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        "& tr": {
            background: "#ffffff",
            height: "52px",
            "& th": {
                padding: "11px 0px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "14px",
                color: "#A0A7AB"
            }
        },
        "& tr th,tr td": {
            borderBottom: "1px solid #DDE2E5",
            // maxWidth: "100px",
            paddingRight: "5px !important",
            paddingLeft: "5px",
            overflow: "hidden",
            wordBreak: "break-all"
        },
        "& tr th:first-child,tr td:first-child": {
            paddingLeft: "7px",
            paddingRight: "7px"
        },
        "& tr th:last-child,tr td:last-child": {
            paddingRight: "16px !important"
        },
        "& tr th": {
            textAlign: "left"
        },
        "& td": {
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "150%",
            color: "#0B0B0B",
            textAlign: "left"
        },
    },
    athleteList: {
        "& tr:hover": {
            background: "#F8F8F8"
        }
    },
    athleteListContainer: {
        border: "1px solid #DDE2E5",
        borderBottom: "0px",
        background: "#ffffff",
        borderRadius: "8px 8px 0px 0px",
        overflow: "hidden"
    },
    disabled: {
        opacity: 0.3
    },
    outsideClickDiv: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "200vh",
        height: "100vh"
    },
    cursorPointer: {
        cursor: "pointer"
    },
    additionalInfoContainer: {
        height: "74px",
        paddingLeft: "15px",
        paddingRight: "15px",
        border: "1px solid #DDE2E5",
        borderTop: "0",
        background: "#ffffff",
        borderRadius: "0px 0px 8px 8px"
    },
    applyBtnContainer: {
        marginLeft: "4px"
    },
    dropDownContainer: {
        position: "relative",
        width: "267px",
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px"
    },
    dropDownHead: {
        height: "44px",
        padding: "11px"
    },
    dropDownMenu: {
        position: "absolute",
        top: 0,
        left: 0,
        background: "white",
        borderRadius: "8px",
        width: "100%",
        border: "1px solid #DDE2E5"
    },
    dropDownItem: {
        height: "44px",
        padding: "11px",
        borderBottom: "1px solid #DDE2E5",
        cursor: "pointer",
        "&:hover": {
            background: "#F8F8F8"
        }
    }
};

class TabularDesign extends Component {

    render() {
        let {
            currentPage,
            totalPages,
            children,
            footerTxt,
            changeCurrentPage,
            selectedActionItem,
            selectActionItem,
            actionItems,
            actionDisabled,
            applyBulkOption
        } = this.props;
        let { classes } = this.props;
        return (
            <div className={classes.pageContainer}>
                <div className={classes.athleteListContainer}>
                    <table className={classes.tableInfo}>
                        {children}
                    </table>
                </div>
                {(!!actionItems || !!footerTxt) && <div className={classnames(classes.additionalInfoContainer, "d-flex align-items-center")}>
                {!!actionItems && <React.Fragment>
                    <div className={classnames(classes.bulkOptionContainer, { [classes.disabled]: actionDisabled })}>
                        <ActionItemDropDown
                            classes={classes}
                            selectedOption={selectedActionItem}
                            selectActionItem={selectActionItem}
                            options={actionItems}
                            isDisabled={actionDisabled}
                        />
                    </div>
                    <div className={classnames(classes.applyBtnContainer, { [classes.disabled]: actionDisabled })}>
                        <ThemeButton
                            overrideContainerClassName={classnames({ [classes.disabled]: actionDisabled && selectedActionItem == null })}
                            disabled={actionDisabled || selectedActionItem == null}
                            disableColor={"rgba(255,255,255)"}
                            theme={"small"}
                            width={"66px"}
                            height={"44px"}
                            onClick={applyBulkOption}
                            text="Apply"
                            color={"#ffffff"}
                            textColor={"#5B6062"}
                        />
                    </div>
                    </React.Fragment>}
                    {footerTxt}
                </div>}
                <div className="d-flex justify-content-end">
                    <Pagination
                        currentPage={currentPage + 1}
                        totalPages={totalPages}
                        changeCurrentPage={changeCurrentPage}
                        theme="square-i"
                    />
                </div>
            </div>
        );
    }
}

function ActionItemDropDown({ classes, selectedOption, selectActionItem, options = [], isDisabled }) {
    const [menuEnabled, toggleMenuItem] = useState(false);
    return (
        <React.Fragment>
            <div className={classnames({ "d-none": !menuEnabled }, { [classes.outsideClickDiv]: menuEnabled })} onWheel={() => toggleMenuItem(false)} onClick={() => toggleMenuItem(false)}></div>
            <div className={classes.dropDownContainer}>
                <div onBlur={() => toggleMenuItem(false)} className={classnames(classes.dropDownHead, "d-flex justify-content-between")} onBlur={() => toggleMenuItem(false)} onClick={() => !isDisabled && toggleMenuItem(!menuEnabled)}>
                    <span>{(options[selectedOption] || {}).displayName || "Bulk Actions"}</span>
                    <ArrowDropDown />
                </div>
                <div onBlur={() => toggleMenuItem(false)} className={classnames(classes.dropDownMenu, { "d-none": !menuEnabled })} >
                    {options.map((item, index) => <div key={index} className={classnames(classes.dropDownItem)} onBlur={() => toggleMenuItem(false)} style={{ color: item.color || null }} onClick={() => { toggleMenuItem(!menuEnabled); selectActionItem(index) }}>
                        {item.displayName}
                    </div>)}
                </div>
            </div>
        </React.Fragment>
    );
}

export default withStyles(styles)(TabularDesign);