import React, { Component } from "react";

import "./styles.css";
import IconButton from "../../iconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import NoteIcon from "@material-ui/icons/Note";
import CancelIcon from "@material-ui/icons/Cancel";
import BorderedDropdown from "../borderedDropdown";
import BorderedIconButton from "../../borderedIconButton";
import Timer from "@material-ui/icons/Timer";
import DescriptionModal from "../../modals/descriptionModal";
import { repsOrTimeOptions } from "../../../constants";
import FloatingInput from "../../../components/floatingInput";
import TempoInput from "../tempoInput";
import classnames from "classnames";
import FEATURES from "../../../features";
import { connect } from "react-redux";

class ActivityGroup extends Component {
  constructor(props) {
    super(props);
    const {
      activity: { notes },
    } = props;
    this.state = {
      notes: notes,
      showDeleteConfirm: false,
      tempoInputEnabled: false,
      notesInputEnabled: false,
    };
  }

  handleDeletePress = () => {
    const { handleDeleteActivitySetGroup } = this.props;
    const { showDeleteConfirm } = this.state;
    if (!showDeleteConfirm) return this.setState({ showDeleteConfirm: true });
    handleDeleteActivitySetGroup();
    this.handleDeleteCancel();
  };

  handleDeleteCancel = () => {
    this.setState({ showDeleteConfirm: false });
  };

  toggleTempoInput = () => {
    this.setState({
      tempoInputEnabled: !this.state.tempoInputEnabled,
      notesInputEnabled: false,
    });
  };

  toggleNotesInput = () => {
    this.setState({
      notesInputEnabled: !this.state.notesInputEnabled,
      tempoInputEnabled: false,
    });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const {
      notes,
      showDeleteConfirm,
      tempoInputEnabled,
      notesInputEnabled,
    } = this.state;
    const {
      index,
      activity,
      handleWorkoutActivityChange,
      currentTeam,
      tenantFeatures,
    } = this.props;
    const { tempo } = activity;
    const isTimeActivity =
      activity.repsOrTime == "time" ||
      (!activity.repsOrTime && activity.scoreType == "time");
    const isPercentOfMaxActivity =
      activity.isPercentOfMax == "true" || activity.isPercentOfMax == true;
    const maxValue = activity.maxValue ? activity.maxValue : 75;
    let timeValue = { minutes: 2, seconds: 0 };
    if (activity.time !== undefined) {
      var minutes = Math.floor(activity.time / 60);
      var seconds = activity.time - minutes * 60;
      timeValue = { minutes, seconds };
    }
    const { name } = activity.repsOrTime
      ? repsOrTimeOptions.find((option) => option.id == activity.repsOrTime)
      : activity.scoreType == "time"
      ? { name: "Time" }
      : { name: "Reps" };
    activity.time =
      activity.time === undefined ? timeValue.minutes * 60 : activity.time;
    const isTempoFeatureAvailable = tenantFeatures.has(FEATURES.TEMPO);
    return (
      <div className={"activityGroupOuterContainer"}>
        <div className={"activityGroupContainer"}>
          <div className={"activityBlockInfo"}>
            <p
              className={
                showDeleteConfirm
                  ? "setGroupText, deleteGroupText"
                  : "setGroupText"
              }
            >
              {showDeleteConfirm ? "Remove " : null}Set Group {index + 1}
            </p>
          </div>
          <div className={"inputContainer"}>
            {!showDeleteConfirm && (
              <BorderedDropdown
                value={name}
                label={"Tracking"}
                activity={activity}
                handleClickOption={(value) =>
                  handleWorkoutActivityChange(activity.id, "repsOrTime", value)
                }
              />
            )}
            {!showDeleteConfirm && (
              <BorderedDropdown
                label={"Sets"}
                increment={true}
                activity={activity}
                value={activity.sets}
                handleChange={(e) =>
                  handleWorkoutActivityChange(
                    activity.id,
                    "sets",
                    e.target.value
                  )
                }
                handleIncrease={() =>
                  handleWorkoutActivityChange(
                    activity.id,
                    "sets",
                    parseInt(activity.sets) + 1
                  )
                }
                handleDecrease={() =>
                  activity.sets == 0
                    ? () => {
                        return;
                      }
                    : handleWorkoutActivityChange(
                        activity.id,
                        "sets",
                        activity.sets - 1
                      )
                }
              />
            )}
            {isTimeActivity && !showDeleteConfirm ? (
              <React.Fragment>
                <BorderedDropdown
                  increment={true}
                  label={"Minutes"}
                  value={Number(timeValue.minutes || 0)}
                  activity={activity}
                  handleChange={(e) =>
                    handleWorkoutActivityChange(
                      activity.id,
                      "time",
                      Math.max(
                        0,
                        Number(e.target.value || 0) * 60 +
                          Number(timeValue.seconds || 0)
                      )
                    )
                  }
                  handleIncrease={() =>
                    handleWorkoutActivityChange(
                      activity.id,
                      "time",
                      Math.max(0, parseInt(activity.time || 0) + 60)
                    )
                  }
                  handleDecrease={() =>
                    activity.time == 0
                      ? () => {
                          return;
                        }
                      : handleWorkoutActivityChange(
                          activity.id,
                          "time",
                          Number(activity.time || 0) - 60
                        )
                  }
                />
                <BorderedDropdown
                  increment={true}
                  label={"Seconds"}
                  value={Number(timeValue.seconds || 0)}
                  activity={activity}
                  handleChange={(e) =>
                    handleWorkoutActivityChange(
                      activity.id,
                      "time",
                      Math.max(
                        0,
                        Number(timeValue.minutes || 0) * 60 +
                          Number(e.target.value || 0)
                      )
                    )
                  }
                  handleIncrease={() =>
                    handleWorkoutActivityChange(
                      activity.id,
                      "time",
                      Math.max(0, parseInt(activity.time || 0) + 1)
                    )
                  }
                  handleDecrease={() =>
                    activity.time == 0
                      ? () => {
                          return;
                        }
                      : handleWorkoutActivityChange(
                          activity.id,
                          "time",
                          Number(activity.time || 0) - 1
                        )
                  }
                />
              </React.Fragment>
            ) : !showDeleteConfirm ? (
              <BorderedDropdown
                label={"Reps"}
                increment={true}
                activity={activity}
                value={activity.reps}
                handleChange={(e) =>
                  handleWorkoutActivityChange(
                    activity.id,
                    "reps",
                    e.target.value
                  )
                }
                handleIncrease={() =>
                  handleWorkoutActivityChange(
                    activity.id,
                    "reps",
                    parseInt(activity.reps) + 1
                  )
                }
                handleDecrease={() =>
                  activity.reps == 0
                    ? () => {
                        return;
                      }
                    : handleWorkoutActivityChange(
                        activity.id,
                        "reps",
                        activity.reps - 1
                      )
                }
              />
            ) : null}
            {isPercentOfMaxActivity && !showDeleteConfirm && (
              <BorderedDropdown
                increment={true}
                label={"% of Max"}
                activity={activity}
                value={maxValue}
                handleIncrease={() =>
                  handleWorkoutActivityChange(
                    activity.id,
                    "maxValue",
                    parseInt(maxValue) + 5
                  )
                }
                handleDecrease={() =>
                  maxValue == 0
                    ? () => {
                        return;
                      }
                    : handleWorkoutActivityChange(
                        activity.id,
                        "maxValue",
                        maxValue - 5
                      )
                }
              />
            )}

            {!showDeleteConfirm && isTempoFeatureAvailable && (
              <IconButton
                overrideContainerClassName={
                  "tempoContainer " + (tempoInputEnabled && "active")
                }
                onClick={() => this.toggleTempoInput()}
                text={"Tempo"}
                textColor={"#22212"}
                position={"right"}
                textPaddingRight={0}
                color={"white"}
                icon={
                  tempoInputEnabled ? (
                    <CancelIcon
                      className={classnames("cancelIcon", {
                        active: tempoInputEnabled,
                      })}
                    />
                  ) : (
                    <Timer
                      className={classnames("timerIcon", {
                        hasValue: (tempo || []).some((x) => x !== null),
                      })}
                    />
                  )
                }
              />
            )}
            {!showDeleteConfirm && (
              <BorderedIconButton
                overrideContainerClassName={notesInputEnabled && "active"}
                onClick={() => this.toggleNotesInput()}
                icon={
                  notesInputEnabled ? (
                    <CancelIcon
                      className={classnames("cancelIcon", {
                        active: notesInputEnabled,
                      })}
                    />
                  ) : (
                    <NoteIcon
                      className={classnames("notesIcon", { hasValue: !!notes })}
                    />
                  )
                }
              />
            )}
            {!showDeleteConfirm && (
              <BorderedIconButton
                onClick={this.handleDeletePress}
                icon={<DeleteIcon className={"deleteIcon"} />}
              />
            )}
            {showDeleteConfirm && (
              <div className={"deleteConfirmButtonContainer"}>
                <IconButton
                  width={150}
                  color={"white"}
                  textColor={"black"}
                  text={"Do Not Remove"}
                  onClick={this.handleDeleteCancel}
                />
                <IconButton
                  width={160}
                  color={"#F93F3F"}
                  textColor={"white"}
                  text={"Yes Remove Group"}
                  onClick={this.handleDeletePress}
                />
              </div>
            )}
          </div>
        </div>
        {!showDeleteConfirm && tempoInputEnabled && (
          <div className={"additionalGroupDataContainer tempoOuterContainer"}>
            <div>
              <p className={"tempoText"}>Rep Tempo</p>
            </div>
            <div className={"tempoSubSection"}>
              <TempoInput
                tempo={tempo}
                handleChange={(tempo) =>
                  handleWorkoutActivityChange(activity.id, "tempo", tempo)
                }
              />
              <IconButton
                overrideContainerClassName={"tempoSaveBtn"}
                width={77}
                height={46}
                color={"#27AE60"}
                textColor={"white"}
                text={"Save"}
                onClick={() => this.toggleTempoInput()}
              />
            </div>
          </div>
        )}
        {!showDeleteConfirm && notesInputEnabled && (
          <div className={"additionalGroupDataContainer"}>
            <FloatingInput
              label={`Add your Set Group ${index + 1} Notes here`}
              className={"textField"}
              value={notes}
              removeBottomMargin={true}
              multiline={true}
              row={3}
              onEnterPressed={() => this.toggleNotesInput()}
              onChange={(e) => {
                this.handleChange("notes", e.target.value);
                handleWorkoutActivityChange(
                  activity.id,
                  "notes",
                  e.target.value
                );
              }}
            />
            <IconButton
              width={110}
              height={48}
              color={"#27AE60"}
              textColor={"white"}
              text={"Save Note"}
              onClick={() => this.toggleNotesInput()}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { tenant } = state;
  return {
    tenantFeatures: tenant.features,
  };
}

export default connect(mapStateToProps)(ActivityGroup);
