import React, { Component } from "react";
import PropTypes from 'prop-types';
import './styles.css'
import { withStyles } from '@material-ui/core/styles';
import ReportForm from './reportForm'
import { getThemeByTenant } from '../../themes';
import { freshdeskClient } from '../../clients';
import { connect } from 'react-redux';
import { getEnvironment, getRegion } from '../../utils/environmentUtils';
import PageWrapper from '../nav/pageWrapper';

const ENV = getEnvironment().toUpperCase()
const REGION = getRegion();

const styles = theme => ({
});

function FreshDeskWrapper(props) {
  return props.isAuthenticated ?
    <PageWrapper title={"Support"} {...props}>
      {props.children}
    </PageWrapper>
    :
    <React.Fragment>
      {props.children}
    </React.Fragment>
}
class FreshdeskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      currentTab: 1,
      business: {},
      reportData: {
      },
      invalidEmail: false,
      isLoading: false,
      
    }
  }


  componentDidMount() {
    let { currentUser } = this.props;
    if(!!currentUser){
      let { nameLast, nameFirst, phoneNumber, email} = currentUser;
      this.setState({
        reportData: {
          nameLast,
          nameFirst,
          phoneNumber,
          email: email
        }
      })
    }
  }

  handleReportDataChange = (property, value='') => {
    this.setState(prevState => ({
      reportData: { ...prevState.reportData, [property]: value },
      existingUsername: false,
      isVerificationCodeValid:true
    }))
    console.log(this.state);

  }


  handleChangeMessageString = string => {
    this.setState({ currentMessageString: string })
  }


  handleTabChange = tabId => {
    this.setState({
      currentTab: tabId,
      isLoading: false
    })
  }


  handleTicketCreation = async () => {
    try {
      let { tenant } = this.props;
      //grab info and submit.....
      const {
        reportData: {
          nameLast,
          nameFirst,
          email,
          subject,
          description,
          issue
        }
      } = this.state;
      if (!email || email.length === 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        // console.log("Oops, invalid email.");
        return this.setState({
          invalidEmail: true,
          message: "Oops, invalid email."
        });
      }
      const custom_fields = {}
      custom_fields.cf_tenant = tenant;
      custom_fields.cf_type = issue
      const helpdesk_ticket = {}
      helpdesk_ticket.email = email;
      helpdesk_ticket.description = description;
      helpdesk_ticket.subject = subject;
      helpdesk_ticket.name = nameFirst + " " + nameLast;
      helpdesk_ticket.custom_fields = custom_fields;
      const res = await freshdeskClient.createTicket(helpdesk_ticket);
      window.location.reload(true);
    }
    catch (e) {
      console.log(e)
    }
  }




  render() {
    const { classes, tenantName, tenant, isAuthenticated } = this.props;
    const {
      currentTab,
      isLoading,
      reportData,
      invalidEmail
    } = this.state
    const tenantLogo = getThemeByTenant(tenant).image.logo;
    return (
      <FreshDeskWrapper {...this.props}>
        <div className="report">
          {!isAuthenticated && <div className="maxoneLogo d-flex justify-content-center align-items-center">
            <img alt="logo" src={tenantLogo} style={{ maxWidth: "120px", maxHeight: "120px" }} />
            <div className={'appName'}> {tenantName}</div>
          </div>}
          <div className={'reportContentOuterContainer'}>
            <ReportForm
              reportData={reportData}
              invalidEmail={invalidEmail}
              emailError={invalidEmail}
              currentTab={currentTab}
              isLoading={isLoading}
              handleCreateTicket={this.handleTicketCreation}
              handleTabChange={tabId => this.handleTabChange(tabId)}
              handleChange={(property, value) => this.handleReportDataChange(property, value)}
            />
          </div>
        </div>
      </FreshDeskWrapper>
    );
  }
}

FreshdeskForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
  const { tenant,  session: { userContext }  } = state;
  return {
    tenantName: tenant.name,
    tenant : tenant.id,
    currentUser: (userContext||{}).user,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(FreshdeskForm));
