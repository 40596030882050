import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let LargeGroupsIcon = (props) => (
    <SvgIcon width="265.62" height="227.02" viewBox="0 0 265.62 227.02" {...props}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="_3" data-name="3">
                <g>
                    <g>
                        <path d="M234.86,75.83A20.84,20.84,0,1,0,214,55,20.83,20.83,0,0,0,234.86,75.83Z" />
                        <path d="M184,91.27a20.84,20.84,0,1,0-20.83-20.84A20.84,20.84,0,0,0,184,91.27Z" />
                        <path d="M31.53,75.83A20.84,20.84,0,1,0,10.69,55,20.83,20.83,0,0,0,31.53,75.83Z" />
                        <path d="M82.09,91.27A20.84,20.84,0,1,0,61.26,70.43,20.84,20.84,0,0,0,82.09,91.27Z" />
                        <g>
                            <path d="M132.81,54.9A20.84,20.84,0,1,0,112,34.06,20.83,20.83,0,0,0,132.81,54.9Z" />
                            <path d="M195,41.66a20.83,20.83,0,1,0-20.83-20.83A20.82,20.82,0,0,0,195,41.66Z" />
                            <path d="M71.09,41.66A20.83,20.83,0,1,0,50.26,20.83,20.82,20.82,0,0,0,71.09,41.66Z" />
                        </g>
                        <circle cx="132.81" cy="84.55" r="20.83" />
                    </g>
                    <path d="M148.59,109.21h-7.27a20.5,20.5,0,0,1-17,0H117a15.39,15.39,0,0,0-15.47,15.87l1,36.52a16.39,16.39,0,0,0,10.75,14.89v39.95A10.59,10.59,0,0,0,123.85,227h17.92a10.58,10.58,0,0,0,10.58-10.58V176.49a16.38,16.38,0,0,0,10.76-14.89l.94-36.52A15.38,15.38,0,0,0,148.59,109.21Z" />
                    <g>
                        <path d="M199.31,95.11h-7.13a20,20,0,0,1-16.68,0h-7.12a15.07,15.07,0,0,0-13.8,8.82A20.29,20.29,0,0,1,169,124l-.23,8.91a142.66,142.66,0,0,0,15.08.81A135.06,135.06,0,0,0,214,130.35l.5-19.69A15.08,15.08,0,0,0,199.31,95.11Z" />
                        <path d="M96.35,124A20.28,20.28,0,0,1,110.41,104a15.07,15.07,0,0,0-13.85-8.93H89.44a20,20,0,0,1-16.68,0H65.63a15.08,15.08,0,0,0-15.16,15.55L51,130.35a135.06,135.06,0,0,0,30.12,3.32,142,142,0,0,0,15.48-.85Z" />
                        <path d="M45.88,109.64A20.25,20.25,0,0,1,59.94,89.73a15.08,15.08,0,0,0-13.85-8.94H39a20,20,0,0,1-16.68,0H15.16A15.08,15.08,0,0,0,0,96.35L.51,116a135.06,135.06,0,0,0,30.12,3.32,139.54,139.54,0,0,0,15.48-.85Z" />
                        <path d="M250.45,80.55h-7.12a20,20,0,0,1-16.68,0h-7.12a15.07,15.07,0,0,0-13.8,8.82,20.29,20.29,0,0,1,14.41,20l-.23,8.9a140,140,0,0,0,15.08.81,135.06,135.06,0,0,0,30.12-3.32l.5-19.68A15.08,15.08,0,0,0,250.45,80.55Z" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

export default LargeGroupsIcon;