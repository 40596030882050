import { connect } from 'react-redux'
import React, { Component } from 'react'

import StyledButton from '../../../../components/styledButton'

import MessageList from '../../components/messageList'
import ViewingMessage from '../../components/viewingMessage'
import EmptyListState from '../../components/emptyListState'

import { 
    resolveContext,
    resolveMessageString,
    resolveMessageRecipients
} from '../../../../utils/messageUtils.js'

import { getLocalizedString } from '../../../../utils/locale-utils'
import './styles.css'

class DraftsScreen extends Component{
    constructor(props){
        super(props)
        this.state = { 
            context: 'team',
            viewingMessage: null,
            viewingMessageString: '',
            viewingMessageRecipients: []
        }
    }

    componentWillMount = async () => {
        const context = await resolveContext(window.location.href)
        this.setState({ context: context })
    }

    handleTypeChange(type){
        const { changeMessageType } = this.props
        changeMessageType(type)
    }

    handleMessageSelect = async message => {
        const string = await resolveMessageString(message.message)
        const allUsers = this.props.allUsers;
        const recipients = await resolveMessageRecipients(message.recipients, null, allUsers)

        this.setState({ 
            viewingMessage: message,
            viewingMessageString: string,
            viewingMessageRecipients: recipients
        })
    }

    deleteMessage = () => {
        const { viewingMessage } = this.state
        const { handleDeleteMessage } = this.props
        handleDeleteMessage(viewingMessage)
        this.setState({ viewingMessage: null })
    }

    render(){
        const { 
            sendMessage,
            messages = [],
            onPressCompose,
            handleEditMessage
        } = this.props

        const { 
            viewingMessage,
            viewingMessageString,
            viewingMessageRecipients
        } = this.state

        return(
            <div className={'draftsScreenOuterContainer'}>
                <div className={'draftsTitleContainer'}>
        <p className={'titleText'}> {getLocalizedString("DRAFTS")} </p>
                </div>
                {messages.length ?
                    <div className={'draftsScreenInnerContainer'}>
                        <MessageList 
                            messages={messages}
                            viewingMessage={viewingMessage}
                            onClickMessage={message => this.handleMessageSelect(message)}
                        />
                        <ViewingMessage
                            message={viewingMessage}
                            string={viewingMessageString}
                            recipients={viewingMessageRecipients}
                            editMessage={m => handleEditMessage(m)}
                            deleteMessage={() => this.deleteMessage()}
                            emptyBody={'Select a message draft to start editing'}
                        />
                    </div>
                :
                    <EmptyListState 
                        title={'No Draft Messages'}
                        onPressCompose={onPressCompose}
                        body={'Save a message as a draft and it will show up here. Get started by composing a new message.'}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state
    const currentTeam = userContext.appContextList.find(c => c.id === appContext.id)

    return { currentTeam: currentTeam }
}

export default connect(mapStateToProps)(DraftsScreen);