import React, { useState, useEffect } from 'react';
import {
    Drawer
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../index.scss';
import List from '../components/List'
import Header from '../components/Header'
import SelectedTeam from '../components/SelectedTeam'
import SearchBar from '../components/SearchBar'
const drawerWidth = 400;

const styles = theme => ({
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#27292A',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0
    }
});

const Component = ({ menu, header, selectedTeam, isOpen, classes, handleTeamChange, history, handleSearch, handleCreateNewTeam }) => {
   
    return (
        <Drawer
            anchor="left"
            classes={{ paper: classNames(classes.drawerPaper, !isOpen && classes.drawerPaperClose) }}
            className={'forceOverlay'}
            open={isOpen}
        >
            <div className={classNames("role-switcher-container")}>
                <div style={{ marginBottom: 36 }}>
                    <Header {...header} />
                    <SelectedTeam {...selectedTeam} />
                </div>
                <div className='searchbar-container'>
                    <SearchBar handleSearch={data => handleSearch(data)} />
                </div>
                {
                    (menu || []).map((item, index) => {
                        return (
                            <div>
                                <List
                                    key={index}
                                    {...item}
                                    handleTeamChange={handleTeamChange}
                                />
                            </div>
                        )
                    })
                }
                <div className='add-team-container' onClick={() => handleCreateNewTeam()}>
                    <p className='addIcon'>+</p>
                    <p className='addText'>ADD NEW TEAM</p>
                </div>
                <div style={{ height: '70px'}}></div>
            </div>
        </Drawer>
    );
}

Component.propTypes = {
    menu: PropTypes.object,
    isOpen: PropTypes.bool
};

Component.defaultProps = {

};

export default withStyles(styles)(Component)
