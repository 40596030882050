import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { GridItemButton } from 'maxone-react-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatSingularPlural } from '../../utils/format-utils';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ConfirmationDialog from '../confirmationDialog';

import './styles.css';

const styles = {
	iconLabelButtonContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
};

const IconLabelButton = ({ Icon, children }) => (
	<div className={'d-flex'} style={styles.iconLabelButtonContainer}>
		{Icon}
		<span className={'d-none d-lg-block'}>{children}</span>
	</div>
);

const InactiveFooterContainer = ({ details, program: { weeks = [] } }) => (
	<div className={'d-flex'} id={'inactiveFooterContainer'}>
		<div>
			{details ||
				(!!weeks.length && formatSingularPlural(weeks.length, 'Week', 'Weeks'))}
		</div>
		<EditIcon id={'inactiveEditIcon'} />
	</div>
);

const ActiveFooterContainer = ({
	cardFooterElements,
	item,
	showDeleteConfirmationModal,
}) => (
	<div className={'d-flex'} id={'activeFooterContainer'}>
		{(cardFooterElements || []).map((element) => {
			return (
				<div
					onClick={(e) => {
						e.stopPropagation();
						element.action(item);
					}}
					id={'editIcon'}>
					<IconLabelButton Icon={element.icon}>{element.name}</IconLabelButton>
				</div>
			);
		})}
	</div>
);

class CardListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
		};
	}

	render() {
		const { active } = this.state;
		const {
			item = {},
			subtitle,
			details,
			addingListItem,
			onEditSelect,
			newContentTitle,
			disableHover,
            subtitleChipText,
		} = this.props;
		return addingListItem ? (
			<div
				id={'addingGridOuterContainer'}
				onClick={(e) => {
					e.stopPropagation();
					onEditSelect(null);
				}}>
				<AddIcon style={{ color: 'white' }} />
				<p>{newContentTitle || 'Create New Workout'}</p>
			</div>
		) : (
			<React.Fragment>
				<div
					className={'gridItemOuterContainer'}
					data-workout-name={item.name}
					onClick={(e) => {
						e.stopPropagation();
						onEditSelect(item);
					}}>
					<GridItemButton
						details={details}
						subtitle={subtitle}
						title={item.name}
                        subtitleChipText={subtitleChipText}
						onMouseEnter={() => this.setState({ active: true })}
						onMouseLeave={() => this.setState({ active: false })}
						footerContainer={
							active ? (
								<ActiveFooterContainer
									{...this.props}
									showDeleteConfirmationModal={this.showDeleteConfirmationModal}
								/>
							) : (
								<InactiveFooterContainer program={{}} {...this.props} />
							)
						}
					/>
				</div>
			</React.Fragment>
		);
	}
}

CardListItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}),
	onEditSelect: PropTypes.func.isRequired,
	onDeleteSelect: PropTypes.func.isRequired,
	onAssignSelect: PropTypes.func.isRequired,
};

export default CardListItem;
