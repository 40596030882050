
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let OneOnOne = (props) => (
  <SvgIcon width="132.29" height="160.61" viewBox="0 0 132.29 160.61" {...props} >
    <g id="Layer_2" data-name="Layer 2">
      <g id="_1" data-name="1">
        <g>
          <g>
            <path d="M31,41.56A20.3,20.3,0,1,0,10.71,21.27,20.29,20.29,0,0,0,31,41.56Z" />
            <path d="M46.65,43.74H39.44a20.28,20.28,0,0,1-16.88,0H15.35A15.26,15.26,0,0,0,0,59.49L.94,95.72a16.29,16.29,0,0,0,10.67,14.77v39.62a10.5,10.5,0,0,0,10.5,10.5H39.89a10.5,10.5,0,0,0,10.5-10.5V110.49A16.29,16.29,0,0,0,61.06,95.72L62,59.49A15.26,15.26,0,0,0,46.65,43.74Z" />
          </g>
          <g>
            <path d="M101.28,40.58A20.29,20.29,0,1,0,81,20.29,20.28,20.28,0,0,0,101.28,40.58Z" />
            <path d="M132.28,58.51l-.51,19.93a137,137,0,0,1-30.48,3.36,136.91,136.91,0,0,1-30.48-3.36l-.52-19.93A15.27,15.27,0,0,1,85.63,42.77h7.21a20.3,20.3,0,0,0,16.89,0h7.21A15.27,15.27,0,0,1,132.28,58.51Z" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>);

export default OneOnOne;
