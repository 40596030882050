import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { biabClient } from '../../clients'

import {
  Avatar,
  Button,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Modal,
  Paper,
  SwipeableViews,
  Select,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import PersonOutlineIcon  from "@material-ui/icons/PersonOutline";
import { withStyles } from '@material-ui/core/styles';

import _ from "lodash";
import "./new.css";
import moment from "moment";
import { API } from "aws-amplify";
import logo from "../../assets/images/logo-dark.png"
import { saveTenant, getTenant } from '../../services/tenantService';
import { saveTeam } from '../../services/teamService';
import { saveRole } from '../../services/rolesService';

import { setUserContext, setAppContext } from '../../actions/session';
import ContextService from '../../services/context';
import PageWrapper from '../nav/pageWrapper';
import { setCurrentTeamById } from '../../actions/team-actions';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: '100%',
  },
  menu: {
    width: 500,
    padding: 50
  },
  button: {
    width:"100%",
    marginTop:10
  },
  header: {
    fontSize:35,
    display:"inline-block",
    marginLeft:30,
    textAlign:"left"
  },
  subHeader: {
    fontWeight: 100,
  },
});

const plans = [
    { id: 'teams-1', name: 'Single Team', price: 300, minTeams: 1, maxTeams: 1, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-2', name: 'Two Teams', price: 600, minTeams: 2, maxTeams: 2, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-3', name: 'Three Teams', price: 900, minTeams: 3, maxTeams: 3, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-4', name: 'Four Teams', price: 1200, minTeams: 4, maxTeams: 4, organizationTypes: ['School', 'Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-5', name: 'Five Teams', price: 1500, minTeams: 5, maxTeams: 5, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-6', name: 'Six Teams', price: 1800, minTeams: 6, maxTeams: 6, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'bronze', name: 'Bronze', price: 2000, minTeams: 7, maxTeams: 9, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'silver', name: 'Silver', price: 2500, minTeams: 10, maxTeams: 14, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'gold', name: 'Gold', price: 3000, minTeams: 15, maxTeams: 19, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'platinum', name: 'Platinum', price: 3500, minTeams: 20, maxTeams: 24, organizationTypes: ['Club', 'Trainer', 'League', 'Other'] },
    { id: 'teams-school', name: 'Full School', price: 1500, minTeams: 5, maxTeams: 1000, organizationTypes: ['School'] }
];

class NewTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            plan: null,
            message: '',
            error: false,
            errorMessage: '',
            isLoading: false,
        };
    }

    async componentDidMount(){
        const { currentTeam } = this.props;
        const organization = await this.getOrganization(currentTeam.organizationId)
        const teams = await this.getTeamsByOrganization(organization.id);
        const plan = organization.subscription ? this.getPlanByOrgTypeAndSize(organization.type, teams.length + 1) : plans[0];
        this.setState({organization, plan});
    }

    getAthlete() {
        var athleteId = this.props.match.params.id
        return API.get("users", `/users/${athleteId}`);
    }

    getOrganization(organizationId) {
        return API.get("organizations", `/organizations/${organizationId}`);
    }

    getTeamsByOrganization(organizationId) {
        return API.get("programs", `/programs/organization/${organizationId}`);
    }

    createSchedule(currentTeam, schedule) {
        return API.post("schedules", `/teams/${currentTeam.id}/schedules`, {
            body: schedule
        });
    }

    updateOrganization(organization){
        return API.post("organizations", `/organizations/${organization.id}`, {
            body: organization
        });
    }

    subscribe(){
        const { currentUser } = this.props;
        const { organization } = this.state;
        const billingMethod = organization.billing && organization.billing.isAutomatedBilling && organization.stripe.subscriptionId ? 'card' : 'invoice';
        return API.post("billing", `/billing/subscribe`,{
          body: {
            user: currentUser,
            organization: organization.id,
            billingType: billingMethod
          }
        });
    }

    getPlanByOrgTypeAndSize(organizationType, teamCount){
		return _.find(plans, p => p.organizationTypes.indexOf(organizationType) >= 0 && (p.minTeams <= teamCount && p.maxTeams >= teamCount));
	}

    setSessionInfo = async (username) => {
      let prefix = null
      if(window.location.href.toLowerCase().includes('wcs'))  prefix = "wcs";
      console.log('prefix ===>', prefix)
      const contextService = new ContextService();
      const {userContext, appContext} = await contextService.buildUserContext(username, prefix);
      this.props.setUserContext(userContext);
      this.props.setAppContext(appContext);
    }

    handleSubmit = async event => {
        const { name, sport } = this.state;
        const { currentTeam, currentUser , userContext} = this.props;
        const { tenant: tenantId } = this.props;
        console.log('tenantId ', tenantId)
        let tenant = await getTenant(tenantId);  // getTenant

        this.setState({isLoading: true, message:"Please wait while we save your team."});
        try{

            let organization = this.state.organization;
            const team = {
                name: name,
                tenantId: tenantId,
                legacyId: 0,
                sport: sport,
                customName: `${name} ${sport}`,
                organizationId: currentTeam.organizationId
            }
            // Check if Organization is maxpay.  If yes then make team isPaid: true, copy organization subscription plans.
            if(currentTeam.organizationId){
              let organization = await this.getOrganization(currentTeam.organizationId);
              console.log('organization ', organization);
              if(organization){
                console.log('organization ', organization)
                if(organization.isPaid){
                  if(!tenant.biab){ // If tenant has no default BIAB assets set
                    let { data } = await biabClient.getBusinessesByTenant(tenant.id);
                    if(data.total){
                      const assetBusiness = data.results[0]
                      let {data: businessData } = await biabClient.getConfigsByTeamId(tenant.id, assetBusiness.teamId)
                      if(businessData){
                        tenant.biab = {
                          mobileBackgroundImageUrl: businessData.mobileBackgroundImageUrl,
                          backgroundImageUrl: businessData.backgroundImageUrl,
                          headerImageUrl: businessData.headerImageUrl,
                          appIconUrl: businessData.appIconUrl,
                          appName: businessData.appName
                        }
                        if(!tenant.androidAppUrl)tenant.androidAppUrl = businessData.androidAppUrl
                        if(!tenant.iosAppUrl)tenant.iosAppUrl = businessData.iosAppUrl
                        await saveTenant(tenant)
                      }
                    }
                  }
                  team.isPaid = true;
                }
              }
            }
            const newTeam = await saveTeam(team);
            const contextService = new ContextService();
            const appContext = await contextService.changeAppContext(
              userContext,
              newTeam.id,
              this.props.tenant
            );
            this.props.setAppContext(appContext);
            
            if(newTeam.isPaid){
              let business = {
                tenant: tenant.id,
                business: newTeam.customName ? newTeam.customName : newTeam.name,
                teamId: newTeam.id,
                tenant: tenantId,
                tenantId: tenantId,
                organizationId: newTeam.organizationId,
                androidAppUrl: tenant.androidAppUrl,
                iosAppUrl: tenant.iosAppUrl,
              }
              console.log('business ', business)
              Object.keys(tenant.biab).forEach(key =>{
                business[key] = tenant.biab[key]
              })
              let { data, status } = await biabClient.addBusiness(tenantId, business)
              console.log('data ===> ', data)
            }
            const scheduleJSON = {
                name: newTeam.customName,
                visibility: 'everyone',
                color: '#dce775',
            }
            await this.createSchedule(newTeam, scheduleJSON);
            const roleJSON = {
                legacyId: 0,
                role: "coach",
                parentId: newTeam.id,
                userId: currentUser.id,
            }
            const newRole = await saveRole(roleJSON);
            const teams = await this.getTeamsByOrganization(organization.id);
            const plan = organization.subscription ? this.getPlanByOrgTypeAndSize(organization.type, teams.length) : plans[0];
            if(organization.subscription && organization.subscription.plan != plan){
                try{
                    organization.subscription.plan = plan.id;
                    organization.subscription.price = plan.price;
                    const updatedOrganization = await this.updateOrganization(organization);
                    const newSubscription = await this.subscribe();
                }
                catch(e){
                    console.log('problem updating subscription => ', e)
                }
            }
            this.setState({message:"Your Team is saved now."}, () => this.props.setCurrentTeamById(newTeam.id));
            const updatedSession = await this.setSessionInfo(currentUser.username);
            setTimeout(() => {
                this.setState({isLoading:false});
                this.props.history.push('../')
            }, 2000);
        }
        catch(e){
            console.log("e");
            this.setState({isLoading: false, message:"We ran into a problem. Please try again later."});
        }
    }

    handleChange = name => event => {
        this.setState({
        [name]: event.target.value,
        });
    };

    renderTeamInputs(){
        const { team } = this.state;
        const { classes } = this.props;
        const sports = ['Baseball', 'Basketball', 'Bowling', 'Cheer', 'Cricket', 'Cross Country', 'Football', 'Golf', 'Hockey', 'Lacrosse', 'Racquetball', 'Ski', 'Soccer', 'Softball', 'Strength and Conditioning', 'Swim', 'Tennis', 'Track and Field', 'Training', 'Volleyball', 'Wrestling', 'Band', 'Marching Band','Other'];
        return(
            <Grid container spacing={24} style={{paddingLeft:50, paddingRight:50}}>
                <Grid item xs={12}>
                    <TextField
                        id="name"
                        margin="normal"
                        label="Team Name"
                        className={classes.textField}
                        onChange={this.handleChange('name')}
                        value={this.state.name ? this.state.name : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.textField}>
                        <InputLabel htmlFor="sport">Team Sport</InputLabel>
                        <Select
                            input={<Input id="sport" />}
                            style={{ textAlign: 'left' }}
                            onChange={this.handleChange("sport")}
                            value={this.state.sport ? this.state.sport : ""}
                        >
                            {sports.map(sport => (
                                <MenuItem
                                    key={sport}
                                    value={sport}
                                >
                                    {sport}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { classes, theme, appContext, userContext } = this.props;
        const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
        const { error, message, errorMessage, isLoading, plan } = this.state;

        return (
        <PageWrapper title="New Team" {...this.props}>
        <div className={"newTeamOuterContainer"}>
            {isLoading ? <div className="loadingOverlay">
                <div className="overlayMessage1">{message}</div>
            </div> : null}
            <div className={"pageTitleContainer"}>
            <div className={"avatarNameContainer"}>
            <h1 className={"pageTitle"}></h1>
            </div>
            <div className={"titleButtonContainer"}>
                <Button
                    mini
                    color="primary"
                    variant="contained"
                    title="Submit"
                    className={"styledButton"}
                    onClick={this.handleSubmit}
                >
                    Submit
                </Button>
            </div>
            </div>

              <div className={"innerContainer"}>
                {/* {(TENANT != tenantMap.se && TENANT != tenantMap.wcs) &&  <div style={{ width: '100%', textAlign: 'left', marginTop: 10, borderBottom: '1px solid lightgray' }}>
                    {plan ? <p style={{ marginLeft: 15 }}>You will be upgraded to the {plan.name} plan for ${plan.price}</p>: null}
                </div>} */}
                {this.renderTeamInputs()}
              </div>

        </div>
        </PageWrapper>
        );
    }
}
const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        userContext: userContext,
        currentUser: userContext.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext)),
    setCurrentTeamById: (id) => dispatch(setCurrentTeamById(id)),
});

NewTeam.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewTeam));
