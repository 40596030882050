import React, { Component } from "react";
import { connect } from "react-redux";
import SendRSVPComponent from "../components/SendRSVP";
import {
  sendRSVP,
  getEventsByTeamIdsAndUserId,
} from "../../../actions/nylas-calendar-actions";
import { enableFullScreenLoader } from '../../../actions/loading';

class SendRSVP extends Component {

  sendRSVP = async (status) => {
    const {
      event,
      loggedInUserId,
      enableFullScreenLoader
    } = this.props;
    enableFullScreenLoader(true, "Loading....");
    await sendRSVP(event.id, loggedInUserId, status)
    enableFullScreenLoader(false)
    this.handleClose()
  }

  handleClose = () => {
    const { handleClose } = this.props
    handleClose()
  }

  render() {
    return (
      <SendRSVPComponent
        sendRSVP={this.sendRSVP}
        handleClose={this.handleClose}
      />
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId },
  teams: { byId: teamsById, currentTeamId },
  nylasCalendar: { startDate, eventById, instanceById, selectedTeams },
}) => ({
  startDate,
  eventById,
  teamsById,
  instanceById,
  currentTeamId,
  selectedTeams,
  loggedInUserId,
});

export default connect(mapStateToProps, {
  getEventsByTeamIdsAndUserId,
  enableFullScreenLoader,
})(SendRSVP);
