import React, { Component } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Button, IconButton } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import "./events.css";
import { withStyles } from '@material-ui/core/styles';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';

const styles = theme => ({

    plusButton: {
        backgroundColor: "#1354F9",
        color: 'white'
    },
    textButton: {
          color: 'white',
          marginRight: '3px',
          backgroundColor: "#1354F9",
    },
    secondaryTextButton: {
        height: 40,
        marginRight: '3px',
        backgroundColor: "#27AE60",
        color:"#ffffff",
        '&:disabled': {
            opacity:"0.3",
            color:"#ffffff"
        }
    },
    textField: {
        flexGrow: 1,
        width:'100%'
    },
    selectField: {
        flexGrow: 1,
        width:'100%',
        textAlign: 'left',
        paddingTop: '5px'
    },
    button: {
        width: 80,
        height: 40,
        marginRight: 10,
        zIndex: 99
    }
});

class NewEventModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            value: 0
        };
    }


    handleTabValueChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes, open, close, event, freeEventFound, paidEventFound, message } = this.props;
        const { value } = this.state;

        return (
            <LegacyModalContainer
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={close}
                containerClassName={"newEventPaper"}>
                    <div className={"modalHeaderContainer"}>
                        <div style={{ width: 690, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <h2 className={"modalHeaderText"}>{event.name ? event.name : 'New Camp/Event'}</h2>
                        </div>
                    </div>
                    {this.state.errorMessage !== ""?<Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>:null}
                    <div  className={"formContainer"}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabValueChange}
                        className={"tabContainer"}>
                        <Tab label="Basics" value={0} />
                        <Tab label="Pricing" value={1} />
                        <Tab label="Disclaimer" value={2} />
                    </Tabs>
                    <SwipeableViews
                        index={value}
                        onChangeIndex={this.handleChangeIndex}>
                        <div>
                            <Grid container spacing={8}>
                                <Grid item xs={12} className={"inputSectionTitle"}>
                                    <h2 style={{ fontSize: '1.1em' }}>Basics</h2>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        className={classes.textField}
                                        value={event.name ? event.name : ""}
                                        onChange={this.props.handleChange("name")}
                                        margin={"dense"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="level"
                                        label="Level"
                                        className={classes.textField}
                                        value={event.level ? event.level : ""}
                                        onChange={this.props.handleChange("level")}
                                        margin={"dense"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="description"
                                        label="Description"
                                        className={classes.textField}
                                        value={event.description ? event.description : ""}
                                        onChange={this.props.handleChange("description")}
                                        margin={"dense"}
                                    />
                                </Grid>
                                <Grid item xs={12} className={"inputSectionTitle"}>
                                    <h2 style={{ fontSize: '1.1em' }}>When &amp; Where</h2>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="date"
                                        id="startDate"
                                        margin={"dense"}
                                        label="Start Date"
                                        className={classes.textField}
                                        onChange={this.props.handleChange("startDate")}
                                        value={event.startDate ? moment(event.startDate).format("YYYY-MM-DD") : moment().startOf("day").format("YYYY-MM-DD")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="date"
                                        id="endDate"
                                        label="End Date"
                                        margin={"dense"}
                                        className={classes.textField}
                                        onChange={this.props.handleChange("endDate")}
                                        value={event.endDate ? moment(event.endDate).format("YYYY-MM-DD") : moment().startOf("day").format("YYYY-MM-DD")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        id="time"
                                        label="Time"
                                        margin={"dense"}
                                        className={classes.textField}
                                        onChange={this.props.handleChange("time")}
                                        value={event.time ? event.time : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="location"
                                        label="Location"
                                        className={classes.textField}
                                        value={event.location ? event.location : ""}
                                        onChange={this.props.handleChange("location")}
                                        margin={"dense"}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div >
                            <Grid container spacing={8}>
                                <Grid item xs={12} className={"inputSectionTitle"}>
                                    <h2>Pricing</h2>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="price"
                                        label="Price"
                                        type={"number"}
                                        className={classes.textField}
                                        value={event.price ? event.price : ""}
                                        onChange={this.props.handleChange("price")}
                                        margin={"dense"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.selectField}>
                                        <InputLabel htmlFor="collectShirtSize">Collect Shirt Size?</InputLabel>
                                        <Select
                                            value={event.collectShirtSize == true || event.collectShirtSize == 'true' ? 'true' : 'false'}
                                            onChange={this.props.handleChange("collectShirtSize")}
                                            input={<Input id="collectShirtSize" />}>
                                            <MenuItem
                                                value={'false'}>
                                                No
                                            </MenuItem>
                                            <MenuItem
                                                value={'true'}>
                                                Yes
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="inviteCode"
                                        label="Invite Code"
                                        helperText={message}
                                        error={paidEventFound}
                                        className={classes.textField}
                                        value={event.inviteCode ? event.inviteCode : ""}
                                        onChange={this.props.handleChange("inviteCode")}
                                        margin={"dense"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="freeCode"
                                        label="Free Code"
                                        helperText={message}
                                        error={freeEventFound}
                                        className={classes.textField}
                                        value={event.zeroCostInviteCode ? event.zeroCostInviteCode : ""}
                                        onChange={this.props.handleChange("zeroCostInviteCode")}
                                        margin={"dense"}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div >
                            <Grid container spacing={8}>
                                <Grid item xs={12} className={"inputSectionTitle"}>
                                    <h2>Disclaimer</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="disclaimer"
                                        margin={"dense"}
                                        label="Disclaimer"
                                        helperText={"optional"}
                                        className={classes.textField}
                                        value={event.disclaimer ? event.disclaimer : ""}
                                        onChange={this.props.handleChange("disclaimer")}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </SwipeableViews>
                    </div>
                    <div className={"modalFooterContainer"}>
                        {event.id ? 
                            <div>
                                <IconButton
                                    title="Delete Event"
                                    onClick={this.deleteEvent}
                                    variant="contained">
                                    <DeleteIcon className={"styledIcon"} />
                                </IconButton>
                            </div>
                        :
                            <div/>
                        }
                        <div>
                            <Button
                                variant="contained"
                                className={"cancelButton"}
                                onClick={close}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.secondaryTextButton}
                                onClick={() => this.props.handleSubmit()}>
                                Save
                            </Button>
                        </div>
                    </div>
            </LegacyModalContainer>
        );
    }
}

NewEventModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewEventModal);
