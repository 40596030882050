import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FlowerIcon = (props) => (
  <SvgIcon width="24" height="17" viewBox="0 0 24 17" {...props}>
<path d="M23.9738 11.1788C23.6283 9.92949 22.8876 8.82568 21.8625 8.0325C22.2421 6.50215 22.0319 4.88498 21.2737 3.5025L21.2213 3.42L21.0862 3.28125L21 3.225C20.2134 2.7989 19.3468 2.54117 18.4552 2.46822C17.5636 2.39527 16.6666 2.50868 15.8212 2.80125C15.0029 1.45218 13.7072 0.460258 12.1912 0.0225002L12.0938 0H11.8987L11.8087 0.0225002C10.3327 0.451626 9.06607 1.40862 8.25 2.71125C7.44371 2.47096 6.59784 2.39265 5.76115 2.48082C4.92445 2.569 4.11348 2.82192 3.375 3.225L3.285 3.28125L3.14625 3.42L3.0975 3.5025C2.37876 4.80755 2.1538 6.32772 2.46375 7.785C1.28024 8.59808 0.417009 9.79812 0.0225 11.1788L0 11.2725V11.4675L0.0225 11.5613C0.7725 14.4525 3.9825 16.5525 7.63875 16.5525C9.15963 16.5602 10.6585 16.1891 12 15.4725C13.3408 16.1917 14.8397 16.5654 16.3613 16.56C20.0175 16.56 23.22 14.46 23.9775 11.5688L24 11.4675V11.2725L23.9738 11.1788ZM16.5 4.1475C17.6773 3.79172 18.945 3.888 20.055 4.4175C21.0975 6.4125 20.3962 9.2925 18.345 11.3438C17.463 12.2321 16.378 12.8924 15.1838 13.2675C16.4923 11.6758 17.1963 9.67288 17.1713 7.6125C17.1725 6.42507 16.9446 5.24856 16.5 4.1475ZM6 11.3588C3.94875 9.30375 3.2475 6.42375 4.29 4.42875C5.28873 3.94918 6.42158 3.82611 7.5 4.08C7.03546 5.20363 6.79716 6.40788 6.79875 7.62375C6.77834 9.60457 7.42563 11.5345 8.63625 13.1025C7.64868 12.7054 6.75187 12.1122 6 11.3588V11.3588ZM12 13.7325C9.85125 13.0575 8.31375 10.5263 8.31375 7.62375C8.31375 4.72125 9.85125 2.19 12 1.5225C14.1488 2.1975 15.6863 4.72875 15.6863 7.63125C15.6863 10.5338 14.1488 13.0575 12 13.7325V13.7325Z" fill="#222127"/>
 </SvgIcon>
);

export default FlowerIcon;
