import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import weareacademyWhiteLogo from '../assets/images/themes/weareacademy/weareacademy-logo.png';
import homeImage from '../assets/images/themes/upward/upward-welcome.png';


// Used in App.js for mostly Nav Components.

export default createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:"5px"
      },
      fab:{
        borderRadius:"5px"
      },
      containedPrimary:{
        "background-color":"#1354F9",
        color:"#ffffff"
      }
    },
    MuiTab:{
      selected:{
        color: "#1354F9 !important"
      }
    },
  },
  product: {
    name: 'We Are Academy',
    appUrl: 'm1-weareacademy://',
    domain: 'weareacademy.gomaxone.com',
    link: 'https://apps.apple.com/us/app/id1518324426',
    onAppleAppStore: 'https://apps.apple.com/us/app/id1518324426',
    onGooglePlay: 'https://play.google.com/store/apps/details?id=com.maxone.weareacademy' // replace with real link
  },
  image: {
    home: homeImage,
    logo: weareacademyWhiteLogo,
    loginLogo: weareacademyWhiteLogo
  },
  palette: {
    type: "light",
    primary: {
      main: "#102f1e"
    },
    secondary: {
      main: "#dd9608",
      isDarkMode: true,
    },
    white: "white",
    error: red,
  },
  paletteSecondary: {
    type: "dark",
    primary: {
      main: "#102f1e"
    },
    secondary: green,
    error: red,
  }

});
