import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import ReactMarkdown from 'react-markdown'
import BaseModal from '../BaseModal';
import DeleteIcon from '../../icons/DeleteIcon';
import PencilIcon from '../../icons/PencilIcon';
import CancelIcon from '../../buttons/CancelButton';
import NewWorkoutActivityModal from '../../../modals/newWorkoutActivityModal';

export default function ActivityViewerModal({
	open,
	deleteFunction,
	activity,
	closeFunction,
	//amit props
	modalTags,
	editActivitySaveFunction,
	currentActivityToState,
	currentTeamId,
	workoutModalOnChangeFunction,
	addTag,
	removeTag,
	resetActivity,
	currentActivity,
	handleFileSelect,
	handleVideoUrlChange,
	removeActivityVideo,
	handleThumbnailSelect,
	cancelUpload,
	displaySuccessOverlay,
	uploadingThumbnailFile,
	videoFileUploadProgress,
	thumbnailUploadProgress,
	overlayMessage,
	uploadingVideoFile,
	invalidVideoUrl,
}) {
	const [closed, setClosed] = useState(false);
	const [newWorkoutModalOpen, setNewWorkoutModalOpen] = useState(false);
	const startingActivity = activity;
	const modalContent = (
		<div className='activity-viewer-modal'>
			<div className='modal-header'>
				<h3>{activity.title}</h3>
			</div>
			<div className='modal-body'>
				<div className='media'>
					{activity.media && activity.media.type ? (
						activity.media.type === 'IMAGE' ? (
							<img src={activity.media.url} className='media-image' />
						) : (
							<div>
								<iframe
									src={
										activity.media.source === 'YOUTUBE'
											? activity.media.url.replace('.be/', 'be.com/embed/')
											: activity.media.url
									}
									title={activity.title}
									width='620px'
									height='450px'
								/>
							</div>
						)
					) : (
						<h2>no media for this activity</h2>
					)}
				</div>
				<span className='open-in-tab'>
					{activity.media && activity.media.url ? (
						<a
							href={activity.media.url}
							target='_blank'
							rel='noopener noreferrer'>
							Open the{' '}
							{activity.media.type
								? activity.media.type.toLowerCase()
								: 'blank'}{' '}
							in a new Tab
						</a>
					) : null}
				</span>
				<span className='description'><ReactMarkdown linkTarget={"_blank"}>{activity.description}</ReactMarkdown></span>
			</div>
			<div className='modal-footer'>
				<button
					onClick={() => {
						if (
							window.confirm('Are you sure you want to delete this activity?')
						) {
							deleteFunction(activity.id);
							setClosed(true);
						}
					}}
					className='footer-icon-button'
					id='first-icon'>
					<DeleteIcon className='delete-icon' />
				</button>
				<button
					onClick={() => {
						currentActivityToState(activity);
						setNewWorkoutModalOpen(true);
					}}
					className='footer-icon-button'
					id='second-icon'>
					<PencilIcon className='edit-icon' />
				</button>
				<CancelIcon onClick={closeFunction} className='cancel-button' />
			</div>
		</div>
	);

	return (
		<div>
			<BaseModal open={!closed ? open : false} modalContent={modalContent} />
			<NewWorkoutActivityModal
				activity={currentActivity}
				tags={modalTags}
				handleChange={(name) => workoutModalOnChangeFunction(name)}
				save={() => {
					editActivitySaveFunction();
					setNewWorkoutModalOpen(false);
					closeFunction();
				}}
				open={newWorkoutModalOpen}
				close={() => {
					setNewWorkoutModalOpen(false);
				}}
				resetActivity={() => {resetActivity()}}
				addTag={(tag) => addTag(tag)}
				removeTag={(tag) => removeTag(tag)}
				currentTeam={currentTeamId}
				handleFileSelect={handleFileSelect}
				handleVideoUrlChange={handleVideoUrlChange}
				removeActivityVideo={removeActivityVideo}
				handleThumbnailSelect={handleThumbnailSelect}
				cancelUpload={cancelUpload}
				// displaySuccessOverlay={displaySuccessOverlay}
				uploadingThumbnailFile={uploadingThumbnailFile}
				videoFileUploadProgress={videoFileUploadProgress}
				thumbnailUploadProgress={thumbnailUploadProgress}
				// overlayMessage={overlayMessage}
				uploadingVideoFile={uploadingVideoFile}
				invalidVideoUrl={invalidVideoUrl}
			/>
		</div>
	);
}

ActivityViewerModal.propTypes = {
	open: PropTypes.bool,
	editFunction: PropTypes.func,
	deleteFunction: PropTypes.func,
	activity: {
		id: PropTypes.string,
		title: PropTypes.string,
		media: { type: PropTypes.string, url: PropTypes.string },
		description: PropTypes.string,
	},
};

ActivityViewerModal.defaultProps = {
	open: false,
	editFunction: null,
	deleteFunction: null,
	activity: {
		id: null,
		title: 'no title given',
		media: { type: null, url: null },
		description: null,
	},
};
