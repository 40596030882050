import React, { useRef } from "react";
import {
  Chip,
  Grid,
  List,
  Radio,
  Avatar,
  ListItem,
  Collapse,
  RadioGroup,
  ListItemText,
  FormControlLabel,
  ListItemSecondaryAction,
} from "@material-ui/core";
import classNames from "classnames";
import ModalContainer from "../../../components/modalContainer";
import { ExpandLess, ExpandMore, Person } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import ShadowContainer from "../../../components/shadowContainer";
import { TimeInput, DateInput, InputField } from "maxone-react-ui";
import BorderedInput from "../../../components/borderedInput";
import { MdGroups } from "react-icons/md";
import CheckboxWithLabel from "../../../components/checkboxWithLabel";
import { colors, convertToRGB } from "../../../components/v2/utils/theme-utils";
import { PrimaryButton, BorderedButton } from "../../../components/v2/buttons";
import { formatDate } from "../../../utils/date-utils";
import ConfirmationPopup from "./ConfirmationPopup";
import SuccessCalender from "../icons/SuccessCalender";
import LocationInput from "./shared/LocationInput";
import SendRSVP from "../containers/SendRSVP";
import tinycolor from "tinycolor2";

const viewOptions = {
  addTeam: "addTeam",
  rsvpPopup: "rsvpPopup",
  createTag: "createTag",
  confirmationPopup: "confirmationPopup",
  createTagConfirmationPopup: "createTagConfirmationPopup",
  editRecurringEvent: "editRecurringEvent",
};

const styles = (theme) => ({
  showContainer: {
    borderRadius: 5,
    padding: 35,
    minHeight: "85vh",
  },
  teamContainer: {
    height: "84vh",
    overflow: "scroll",
  },
  nameTextField: {
    height: 49,
    fontSize: 16,
  },
  nameFocused: {
    transform: "translate(0, 1.5px) scale(0.50);",
    top: "8px",
  },
  nameInput: {
    fontSize: 16,
  },
  nameFieldLabel: {
    fontSize: 16,
  },
  addTeamBtn: {
    width: "20%",
    height: 52,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.isDarkMode ? "#ffffff" : "black",
  },
  tagContainer: {
    width: "100%",
    padding: "0px 0px 10px 0px",
    flexWrap: "wrap",
    overflow: "visible",
  },
  createTag: {
    color: `${colors.PRIMARY} !important`,
    fontSize: 12,
    cursor: "pointer",
  },
  deleteTag: {
    color: `${colors.BLACK} !important`,
    fontSize: 12,
    cursor: "pointer",
    marginLeft: 20,
  },
  teamsTitle: {
    color: colors.GREY_40,
    fontSize: 14,
    margin: 0,
  },
  errorMessage: {
    color: colors.ERROR,
    fontSize: 12,
  },
  group: {
    display: "flex",
    flexDirection: "row",
    width: "fit-content",
  },
  dayButton: {
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3.5,
    margin: 5.5,
  },
  greyDayButton: {
    backgroundColor: colors.GREY_20,
  },
  selectDayButton: {
    backgroundColor: theme.palette.primary.main,
  },
  selectedDayButtonText: {
    color: tinycolor(theme.palette.primary.main).isDark() ? colors.WHITE :  colors.BLACK,
    fontSize: 14,
    margin: 0,
  },
  greyDayButtonText: {
    color: colors.BLACK,
    fontSize: 14,
    margin: 0,
  },
});

const CreateEvent = (props) => {
  const {
    id,
    urls,
    tags,
    title,
    object,
    errors,
    classes,
    message,
    endDate,
    endTime,
    allEvent,
    location,
    startDate,
    startTime,
    isTBDEvent,
    isDirector,
    externalURL,
    currentView,
    handleClose,
    selectedTag,
    canDeleteTag,
    selectedDays,
    handleSubmit,
    handleChange,
    isAllDayEvent,
    selectedTeams,
    tagToBeDeleted,
    deleteSchedule,
    collapsedIndex,
    isMultidayEvent,
    isRecurringEvent,
    handleFileChange,
    handleDeleteFile,
    handleFileDownload,
    handleDaySelection,
    handleEventTypeChange,
    sendEmailNotifications,
    event,
  } = props;
  const backgroundColor = selectedTag.id
    ? convertToRGB(selectedTag.color, 0.4)
    : "#fff";

  let participants = {};
  (event?.teams || []).forEach((item) => {
    Object.values(item.participants.participantsById || {}).forEach(
      (participant) => {
        participants[participant.id] = true;
      }
    );
    Object.values((item.participants || {}).groupsById || {}).forEach((group) =>
      Object.values(group.participantsById || {}).forEach((participant) => {
        participants[participant.id] = true;
      })
    );
  });
  let total = Object.keys(participants).length;

  const fileRef = useRef(null);
  return (
    <ShadowContainer className={classes.showContainer}>
      <Grid container spacing={24} className={classes.teamContainer}>
        <Grid item xs={12}>
          <InputField
            required
            id="title"
            label="Event Name"
            value={title || ""}
            onChange={(value) => handleChange("title", value)}
            error={errors?.title}
          />
          {errors?.title?.length > 0 && (
            <span className={classes.errorMessage}>{errors?.title}</span>
          )}
        </Grid>
        <Grid item xs={4}>
          <DateInput
            id="startDate"
            disablePast={true}
            handleChange={(value) => handleChange("startDate", value)}
            date={startDate ? formatDate(startDate, "MM/DD/YYYY") : ""}
            label={"Pick a Date"}
            error={errors.startDate}
            dateFormat={"MM/DD/YYYY"}
            style={{ marginBottom: 0 }}
          />
          {errors?.startDate?.length > 0 && (
            <span className={classes.errorMessage}>{errors?.startDate}</span>
          )}
        </Grid>
        {object === "timespan" ? (
          <Grid item xs={4}>
            <TimeInput
              id="startTime"
              handleChange={(value) => {
                handleChange("startTime", value);
              }}
              disabled={isAllDayEvent || isTBDEvent}
              date={startTime || ""}
              label={"Start Time"}
              error={errors.startTime}
              style={{ marginBottom: 0 }}
            />
            {errors?.startTime?.length > 0 && (
              <span className={classes.errorMessage}>{errors?.startTime}</span>
            )}
          </Grid>
        ) : null}
        {object === "timespan" ? (
          <Grid item xs={4}>
            <TimeInput
              id="endTime"
              handleChange={(value) => handleChange("endTime", value)}
              date={endTime || ""}
              label={"End Time"}
              error={errors.endTime}
              style={{ marginBottom: 0 }}
            />
            {errors?.endTime?.length > 0 && (
              <span className={classes.errorMessage}>{errors?.endTime}</span>
            )}
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <RadioGroup
            name="object"
            className={classes.group}
            value={isAllDayEvent ? "allDay" : isTBDEvent ? "tbd" : ""}
            onMouseDown={(event) =>
              isMultidayEvent
                ? {}
                : handleEventTypeChange("", event.target.value)
            }
          >
            <FormControlLabel
              value="allDay"
              disabled={isMultidayEvent}
              control={<Radio />}
              label="All Day"
              style={{ marginBottom: 0 }}
            />
            <FormControlLabel
              value="tbd"
              disabled={isMultidayEvent || isRecurringEvent}
              control={<Radio />}
              label="TBD"
              style={{ marginBottom: 0 }}
            />
          </RadioGroup>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ paddingTop: 0, paddingBottom: 0, width: "40%" }}
        >
          <RadioGroup
            name="object"
            className={classes.group}
            value={
              isRecurringEvent
                ? "recurrence"
                : isMultidayEvent
                ? "multiday"
                : ""
            }
            onMouseDown={(event) =>
              (isRecurringEvent && (isTBDEvent || id)) ||
              (isMultidayEvent && (isTBDEvent || !isAllDayEvent || id))
                ? {}
                : handleEventTypeChange("type", event.target.value)
            }
          >
            <FormControlLabel
              value="recurrence"
              control={<Radio />}
              disabled={isTBDEvent || id}
              label="Recurrent event"
              style={{ marginBottom: 0 }}
            />
            <FormControlLabel
              value="multiday"
              control={<Radio />}
              disabled={isTBDEvent || !isAllDayEvent || id}
              label="Multi-day event"
              style={{ marginBottom: 0 }}
            />
          </RadioGroup>
        </Grid>

        {isRecurringEvent ? (
          <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
              }}
            >
              {[
                { label: "SU", value: "SU" },
                { label: "MO", value: "MO" },
                { label: "TU", value: "TU" },
                { label: "WE", value: "WE" },
                { label: "TH", value: "TH" },
                { label: "FR", value: "FR" },
                { label: "SA", value: "SA" },
              ].map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(classes.dayButton, {
                      [classes.greyDayButton]: !selectedDays[item.value],
                      [classes.selectDayButton]: selectedDays[item.value],
                    })}
                    onClick={() => handleDaySelection("", item.value)}
                  >
                    <p
                      className={classNames({
                        [classes.greyDayButtonText]: !selectedDays[item.value],
                        [classes.selectedDayButtonText]:
                          selectedDays[item.value],
                      })}
                    >
                      {item.label}
                    </p>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 30,
                }}
              >
                <CheckboxWithLabel
                  label={"Every day"}
                  checked={Object.keys(selectedDays).length === 7}
                  onChange={() => handleDaySelection("all")}
                />
              </div>
            </div>
          </Grid>
        ) : null}
        <Grid xs={12}></Grid>
        {isRecurringEvent || isMultidayEvent ? (
          <Grid item xs={4}>
            <DateInput
              id="endDate"
              disablePast={true}
              handleChange={(value) => handleChange("endDate", value)}
              date={endDate ? formatDate(endDate, "MM/DD/YYYY") : ""}
              label={"End Date"}
              error={errors.endDate}
              dateFormat={"MM/DD/YYYY"}
            />
            {errors?.endDate?.length > 0 && (
              <span className={classes.errorMessage}>{errors?.endDate}</span>
            )}
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          className={"d-flex align-items-center justify-content-between"}
        >
          <div>
            <p style={{ margin: 0 }}>Event type</p>
          </div>
          {selectedTag.id ? (
            <div>
              <Chip
                variant={"filled"}
                label={selectedTag.name}
                handleClick={() => {}}
                style={{
                  backgroundColor: backgroundColor,
                  color: colors.GREY_90,
                  margin: 5,
                }}
              />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.tagContainer}>
            {tags
              .sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              })
              .map((tag, index) => {
                const backgroundColor = convertToRGB(tag.color, 0.4);
                return canDeleteTag && selectedTag.id !== tag.id ? (
                  <Chip
                    key={index}
                    variant={"filled"}
                    label={tag.name}
                    clickable={true}
                    onDelete={() => {
                      handleChange("tagToBeDeleted", tag);
                      handleChange(
                        "currentView",
                        viewOptions.createTagConfirmationPopup
                      );
                    }}
                    onClick={() => {}}
                    style={{
                      backgroundColor: backgroundColor,
                      color: colors.GREY_90,
                      margin: 5,
                    }}
                  />
                ) : (
                  <Chip
                    key={index}
                    variant={"filled"}
                    label={tag.name}
                    clickable={true}
                    onClick={() => handleChange("selectedTag", tag)}
                    style={{
                      backgroundColor: backgroundColor,
                      color: colors.GREY_90,
                      margin: 5,
                    }}
                  />
                );
              })}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a
              className={classes.createTag}
              onClick={() =>
                handleChange("additionalView", viewOptions.createTag)
              }
            >
              Create tag
            </a>
            {isDirector ? (
              <a
                className={classes.deleteTag}
                onClick={() => handleChange("canDeleteTag", !canDeleteTag)}
              >
                {canDeleteTag ? "Done" : "Delete Tag"}
              </a>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12}>
          <LocationInput
            location={location}
            handleLocationSelect={(location) =>
              handleChange("location", location)
            }
          />
        </Grid>
        {Object.keys(selectedTeams).length > 0 && (
          <Grid item xs={12}>
            <p className={classes.teamsTitle}>TEAMS</p>
          </Grid>
        )}
        {Object.keys(selectedTeams).length ? (
          <Grid item xs={12}>
            {Object.values(selectedTeams).map((item, index) => {
              return (
                <List component="div" disablePadding>
                  <ListItem
                    style={{ padding: 5 }}
                    disableGutters
                    onClick={() =>
                      handleChange(
                        "collapsedIndex",
                        collapsedIndex === index ? -1 : index
                      )
                    }
                  >
                    {item?.logo ? (
                      <Avatar src={item?.logo || ""} />
                    ) : (
                      <Avatar>
                        <MdGroups />
                      </Avatar>
                    )}
                    <ListItemText primary={item.customName || item.name} />
                    {collapsedIndex === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    key={index}
                    in={collapsedIndex === index}
                    timeout={{ enter: 200, exit: 200 }}
                  >
                    <List component="div" disablePadding>
                      {Object.values(
                        (item.participants || {}).participantsById || {}
                      )
                        .sort(function (a, b) {
                          return a.firstName
                            .toLowerCase()
                            .localeCompare(b.firstName.toLowerCase());
                        })
                        .map((participant, childIndex) => {
                          return (
                            <ListItem
                              key={childIndex}
                              disableGutters
                              className={classes.nested}
                            >
                              {participant?.avatarUrl ? (
                                <Avatar src={participant?.avatarUrl || ""} />
                              ) : (
                                <Avatar>
                                  <Person />
                                </Avatar>
                              )}
                              <ListItemText
                                style={{ fontSize: 12 }}
                                primary={`${participant.firstName} ${participant.lastName}`}
                                secondary={participant.role}
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                    {Object.values(
                      (item.participants || {}).groupsById || {}
                    ).map((group, groupIndex) => {
                      return (
                        <List
                          key={groupIndex}
                          component="div"
                          disablePadding
                          style={{ padding: "0px 20px" }}
                        >
                          <ListItem
                            style={{ padding: 5 }}
                            disableGutters
                            onClick={() =>
                              handleChange(
                                "collapsedIndex",
                                collapsedIndex === index ? -1 : index
                              )
                            }
                          >
                            <Avatar>
                              <MdGroups />
                            </Avatar>

                            <ListItemText primary={group.name} />
                            {collapsedIndex === index ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            key={index}
                            in={collapsedIndex === index}
                            timeout={{ enter: 200, exit: 200 }}
                          >
                            <List component="div" disablePadding>
                              {Object.values(group.participantsById || {}).map(
                                (participant, childIndexGroup) => {
                                  return (
                                    <ListItem
                                      key={childIndexGroup}
                                      disableGutters
                                      className={classes.nested}
                                    >
                                      {participant?.avatarUrl ? (
                                        <Avatar
                                          src={participant?.avatarUrl || ""}
                                        />
                                      ) : (
                                        <Avatar>
                                          <Person />
                                        </Avatar>
                                      )}
                                      <ListItemText
                                        primary={`${participant.firstName} ${participant.lastName}`}
                                        secondary={participant.role}
                                      />
                                    </ListItem>
                                  );
                                }
                              )}
                            </List>
                          </Collapse>
                        </List>
                      );
                    })}
                  </Collapse>
                </List>
              );
            })}
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {errors?.teams && (
            <div class="alert alert-danger" role="alert">
              {errors.teams}
            </div>
          )}
          <PrimaryButton
            onClick={() => handleChange("additionalView", viewOptions.addTeam)}
            style={{ height: 38 }}
          >
            {Object.keys(selectedTeams).length ? "Edit Teams" : "Add Teams"}
          </PrimaryButton>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: 0, fontSize: 12, color: colors.GREY_40 }}>
            MESSAGE CONTENT
          </p>
          <BorderedInput
            id="message"
            textArea={true}
            style={{ fontSize: 16 }}
            placeholder="Type your message here"
            value={message || ""}
            onChange={({ target: { value } }) => handleChange("message", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            id="externalURL"
            label="External URL"
            value={externalURL || ""}
            onChange={(value) => handleChange("externalURL", value)}
          />
        </Grid>
        {urls.length ? (
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <p style={{ margin: 0, fontSize: 12, color: colors.GREY_40 }}>
              ATTACHED FILES
            </p>
            {urls.map((item, index) => {
              let filename =
                typeof item === "string"
                  ? item.substring(item.lastIndexOf("/") + 1)
                  : item.name;
              return (
                <Chip
                  key={index}
                  variant={"filled"}
                  label={filename}
                  onClick={() =>
                    typeof item === "string"
                      ? handleFileDownload(item, filename)
                      : {}
                  }
                  onDelete={() => handleDeleteFile(index)}
                  className={classes.chip}
                  style={{
                    margin: 5,
                  }}
                />
              );
            })}
          </Grid>
        ) : null}

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <div>
            <input
              id="teamInput"
              type="file"
              ref={fileRef}
              accept={"application/pdf, image/*"}
              onChange={handleFileChange}
              style={{ display: "none" }}
              multiple={false}
            />
            <a
              className={classes.createTag}
              onClick={
                urls.length === 3 ? () => "" : () => fileRef.current.click()
              }
            >
              Attach File(Only 3 attachment are allowed)
            </a>
          </div>
        </Grid>

        <Grid item xs={12} className={"d-flex"}>
          <PrimaryButton
            onClick={() =>
              isRecurringEvent && id
                ? handleChange("currentView", viewOptions.editRecurringEvent)
                : handleSubmit()
            }
            style={{ height: 52, marginRight: 20 }}
          >
            {id ? "Confirm Changes" : "Create"}
          </PrimaryButton>
          <BorderedButton onClick={handleClose} style={{ height: 49 }}>
            Cancel
          </BorderedButton>
        </Grid>
      </Grid>
      {currentView === viewOptions.confirmationPopup && (
        <ConfirmationPopup
          eventDescription={`Event edited successfully ${total} participants will be notified`}
          currentAction={"edit"}
          handleConfirmation={sendEmailNotifications}
          handleClose={handleClose}
          icon={SuccessCalender}
        />
      )}
      {currentView === viewOptions.createTagConfirmationPopup && (
        <ConfirmationPopup
          eventDescription={`This tag will be removed from all events. Are you sure you want to delete this tag?`}
          handleClose={() => {
            handleChange("currentView", "");
            handleChange("tagToBeDeleted", {});
          }}
          currentAction={"deleteTag"}
          handleConfirmation={deleteSchedule}
        />
      )}
      {currentView === viewOptions.rsvpPopup && (
        <SendRSVP event={event} handleClose={handleClose} />
      )}
      {currentView === viewOptions.editRecurringEvent ? (
        <ModalContainer
          open={true}
          showFooter={false}
          showCloseIcon={false}
          onClose={() => handleChange("currentView", "")}
          width={"300px"}
          customPadding={25}
          backgroundColor={"#fff"}
          height={"280px"}
          heading={""}
          customButton={""}
        >
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <List
                style={{ marginBottom: 20 }}
                subheader={
                  <p style={{ margin: 0, fontWeight: 600 }}>
                    {"This is a recurrent event"}
                  </p>
                }
                component="div"
              >
                {/* <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemText primary={"Update this event"} />
                  <ListItemSecondaryAction onClick={() => {}}>
                    <Radio
                      color="primary"
                      checked={!allEvent}
                      onChange={() => handleChange("allEvent", false)}
                      value="true"
                      name="removeEvent"
                    />
                  </ListItemSecondaryAction>
                </ListItem> */}
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemText primary={"Update all events"} />
                  <ListItemSecondaryAction
                    onClick={() => {}}
                    style={{ paddingRight: "40px" }}
                  >
                    <Radio
                      color="primary"
                      checked={allEvent}
                      onChange={() => handleChange("allEvent", true)}
                      value="true"
                      name="allEvent"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${classes.okButtonWrapper} d-flex justify-content-center`}
            >
              <PrimaryButton
                onClick={handleSubmit}
                style={{ height: 52, width: "100%" }}
                className={classes.okButton}
              >
                OK
              </PrimaryButton>
            </Grid>
          </Grid>
        </ModalContainer>
      ) : null}
    </ShadowContainer>
  );
};
export default withStyles(styles)(CreateEvent);
