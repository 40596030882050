import React, { Component } from "react";
import PropTypes from 'prop-types';
import { API } from "aws-amplify";
import SwipeableViews from 'react-swipeable-views';
import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import moment from 'moment';

import MaskedInput from 'react-text-mask';

import shortid from "shortid";

import { withStyles } from '@material-ui/core/styles';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';

const styles = theme => ({
    textField: {
        flexGrow: 1,
        width:'100%'
    },
    selectField: {
        flexGrow: 1,
        width:'100%',
        textAlign: 'left',
        paddingTop: '5px'
    },
    button: {
        width: 80,
        height: 40,
        marginRight: 10,
        zIndex: 99
    }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={["+", /[1-9]/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class TrueLacrosseImportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0
        };
    }

    async componentWillReceiveProps(nextProps){
    }
    handleTabValueChange = (user, value) => {
        console.log('HERE IS THE VALUE', value);
        this.setState({ value });
    };

    render() {
        const { classes, open, close, user, importData = {} } = this.props;
        const { value } = this.state;

        const heights = [
          "3 ft. 0 in.", "3 ft. 1 in.", "3 ft. 2 in.", "3 ft. 3 in.", "3 ft. 4 in.", "3 ft. 5 in.", "3 ft. 6 in.","3 ft. 7 in.","3 ft. 8 in.","3 ft. 9 in.","3 ft. 10 in.","3 ft. 11 in.",
          "4 ft. 0 in.", "4 ft. 1 in.", "4 ft. 2 in.", "4 ft. 3 in.", "4 ft. 4 in.", "4 ft. 5 in.", "4 ft. 6 in.","4 ft. 7 in.","4 ft. 8 in.","4 ft. 9 in.","4 ft. 10 in.","4 ft. 11 in.",
          "5 ft. 0 in.", "5 ft. 1 in.", "5 ft. 2 in.", "5 ft. 3 in.", "5 ft. 4 in.", "5 ft. 5 in.", "5 ft. 6 in.","5 ft. 7 in.","5 ft. 8 in.","5 ft. 9 in.","5 ft. 10 in.","5 ft. 11 in.",
          "6 ft. 0 in.", "6 ft. 1 in.", "6 ft. 2 in.", "6 ft. 3 in.", "6 ft. 4 in.", "6 ft. 5 in.", "6 ft. 6 in.","6 ft. 7 in.","6 ft. 8 in.","6 ft. 9 in.","6 ft. 10 in.","6 ft. 11 in.",
          "7 ft. 0 in.", "7 ft. 1 in.", "7 ft. 2 in.", "7 ft. 3 in.", "7 ft. 4 in.", "7 ft. 5 in.", "7 ft. 6 in.","7 ft. 7 in.","7 ft. 8 in.","7 ft. 9 in.","7 ft. 10 in.","7 ft. 11 in."
        ]

        return (
            <LegacyModalContainer
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={close}
                containerClassName={"userConfigModal"}>
                {this.state.errorMessage !== ""?<Typography style={{color:"red"}}>{this.state.errorMessage}</Typography>:null}
                <div  className={"formContainer"}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabValueChange}
                    className={"tabContainer"}>
                    <Tab label="Info" value={0} />
                </Tabs>
                <SwipeableViews
                    axis={'x-reverse'}
                    index={value}
                    onChangeIndex={this.handleChangeIndex}>
                    <div dir={"right"}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} className={"inputSectionTitle"} style={{marginBottom:20}}>
                                <h2>Info</h2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p><b>Created At: </b> {importData && importData.createdAt ? moment(importData.createdAt).format("MM/DD/YYYY hh:ss a") : ''}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p><b>Created By: </b> {importData && importData.createdBy ? importData.createdBy.nameFirst : ''} {importData && importData.createdBy ? importData.createdBy.nameLast : ''} ({importData && importData.createdBy ? importData.createdBy.username : ''})</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p><b># of Athletes Created: </b> {importData ? importData.athletesCreated: ''}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p><b># of Parents Created: </b> {importData ? importData.parentsCreated : ''}</p>
                            </Grid>
                        </Grid>
                    </div>
                    <div dir={"right"}>
                        <Grid container spacing={8}>

                        </Grid>
                    </div>
                </SwipeableViews>
                </div>
                <div className={"modalFooterContainer"}>
                    <div style={{float:"right", width:"100%", justifyContent:"end", alignItems:"end", textAlign:"end"}}>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={"outlinedButton"}
                            onClick={close}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </LegacyModalContainer>
        );
    }
}

TrueLacrosseImportModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrueLacrosseImportModal);
