import _ from 'lodash'
import short from "short-uuid"
import moment from 'moment'
import { saveMultipleTags } from '../services/taggingService'
import { saveWorkout, getWorkout, getTeamWorkouts, createWorkoutActivity } from '../services/workoutsService'
import { surveysClient, workoutClient, tagClient } from '../clients';

export async function resolveSavedWorkout(name, workoutId, description, workoutBlocks, currentTeamId, tags, userId,
                                          returnActivityCount = false, createdForWorkoutProgramId, deletedQuestionIds, isCoachesOnly){

    let newWorkout = {}
    if(workoutId){
        newWorkout = await getWorkout(workoutId)
    }
    newWorkout.createdForWorkoutProgramId = newWorkout.createdForWorkoutProgramId || createdForWorkoutProgramId
    newWorkout.name = name
    newWorkout.isTemplate = 'true'
    newWorkout.workoutSegments = {}
    newWorkout.teamId = currentTeamId
    newWorkout.description = description || null
    newWorkout.isCoachesOnly = isCoachesOnly || false
    newWorkout.legacyId = newWorkout.legacyId || 0
    let workoutActivitiesCount = 0;
   // await Promise.all((deletedQuestionIds||[]).map(id => surveysClient.deleteQuestionById(id)));
    await Promise.all(workoutBlocks.map(async block => {
        let surveyId = block.surveyId; //Temporarily need it because we are creating questions from here
        const position = parseInt(block.id)
        let workoutActivities = {};
        if(block.type == 'circuit'){
            let braidedActivities = await braidBlockActivities(block.activities)
            braidedActivities.forEach((activity, index) => workoutActivities[index+1] = activity)
        }
        else if(block.isQuestionBlock){
            if(!surveyId){
                let {data:surveyDataResponse} = await surveysClient.createSurvey(userId, currentTeamId, `${name} survey`);
                surveyId = surveyDataResponse.id;
            }
            await surveysClient.addOrUpdateQuestionsToSurvey(surveyId, block.questions||[]);
        }
        else{
            let counter = 1;
            block.activities.forEach((activity, index) => {
                activity.groups.forEach((groupActivity, gaIndex) => { // cascade tempo and description to each workout activity
                    groupActivity.description = activity.description || null
                    if(groupActivity.repsOrTime == 'time'){
                        groupActivity.reps = null;
                    }
                    else{
                        groupActivity.time = null;
                    }
                    workoutActivities[counter] = groupActivity;
                    if (returnActivityCount) {
                        workoutActivitiesCount++;
                    }
                    counter++;
                })
            })
        }
        let workoutSegment = {
            order: position,
            type: block.type || 'series',
            workoutActivities: workoutActivities,
            isQuestionBlock: block.isQuestionBlock||false,
        }
        if(!!surveyId){
            workoutSegment.surveyId = surveyId;
        }
        newWorkout.workoutSegments[position] = workoutSegment
    }))
    const savedWorkout = await saveWorkout(newWorkout)
    if(!workoutId)
        await saveTags(tags, savedWorkout.id)
    if (returnActivityCount) {
        return [savedWorkout, workoutActivitiesCount]
    }
    return savedWorkout
}

async function braidBlockActivities(activities){
    let activityArrays = []
    let standAloneActivities = []
    let activitiesForMapping = []
    activities.forEach(a => {
        a.groups.forEach(g => {
            g.description = a.description;
            if(g.repsOrTime == 'time'){
                g.reps = null;
            }
            else{
                g.time = null;
            }
        }) // cascade tempo and description to each workout activity
        activitiesForMapping = activitiesForMapping.concat(a.groups)
    })
    let noStandAloneActivities = _.find(activitiesForMapping, a => a.sets > 1)
    await Promise.all(activitiesForMapping.map(async activity => {
        if(noStandAloneActivities){
            const arr = await createArray(activity)
            activityArrays.push(arr)
        }
        else standAloneActivities.push(activity)
    }))
    activitiesForMapping = standAloneActivities.length ?
        await braidArrays(_.values(_.groupBy(standAloneActivities, 'activityId'))) :
        await braidArrays(activityArrays)
    return activitiesForMapping
}

async function braidArrays(arrays){
    const braided = []
    arrays.sort((a, b) => { return b.length - a.length })
    for (let i = 0; i < Math.max(...arrays.map(a => a.length)); i++) {
        await Promise.all(arrays.map((array) => {
            if (array[i] !== undefined) braided.push(array[i])
        }))
    }
    return braided
}

async function createArray(activity){
    let arr = []
    const activitySets = activity.sets
    for (var i = 0; i < activitySets; i++) {
        activity.sets = 1
        activity.id = null
        activity.isPercentOfMax = String(activity.isPercentOfMax).toLowerCase() == true ? true : false
        const newWorkoutActivity = await buildWorkoutActivity(activity)
        arr = arr.concat(newWorkoutActivity)
    }
    return arr
}

export async function resolveWorkoutList(entityId, params = {
    limit: 20,
    offset: 0
}) {
    let workouts = []
    let [{ data: { total, results, meta: { distinctTags } } }, { data: { results: availableTags } }] = await Promise.all([workoutClient.searchWorkoutsByTeamId(entityId, params), tagClient.getAvailableTagsByTeamId(entityId)]);
    workouts = results || [];
    let slugAndTagMap = {};
    availableTags.forEach((tag) => {
        slugAndTagMap[tag.slug] = tag.label
    })
    let allTags = distinctTags.map(item => { return { name: slugAndTagMap[item.slug], id: item.slug } });
    workouts.forEach((w) => {
        w.details = `${w.totalActivities||0} drills`;
        let tags = new Set((w.tags || []).map(t => t.label));
        w.subtitle = Array.from(tags).join(",");
        w.name = w.title;
    }
    )
    return { workouts, total, allTags }
}

// export async function resolveFilteredWorkoutList(workoutList, filter, tagFilter){
//     if(filter.target){
//         if(tagFilter.name){
//             workoutList = workoutList.filter(w => w.subtitle.toLowerCase().includes(tagFilter.name.toLowerCase()))
//         }
//         return workoutList.filter(w => w.name.toLowerCase().includes(filter.target.value.toLowerCase()))
//     }
//     else if (filter.name)
//         return workoutList.filter(w => w.subtitle.toLowerCase().includes(filter.name.toLowerCase()))
//     else
//         return workoutList
// }

export async function resolveActivityDrop(data){
    const { questions, activities, workoutBlocks, result = {} } = data
    const { destination, source, draggableId } = result
    if(!workoutBlocks || !destination)
        return {} // get outta here
    if(destination.droppableId === source.droppableId && destination.index === source.index)
        return {} // get outta here
    if(destination.droppableId === 'activityList' && source.droppableId === 'activityList')
        return {} // get outta here
    let newBlockActivity = {}
    const isDroppedFromActivityList = source.droppableId === 'activityList';
    const workoutBlock = isDroppedFromActivityList ?
    workoutBlocks.find(wb => wb.id === destination.droppableId) :
    workoutBlocks.find(wb => wb.id === source.droppableId)
    if(!workoutBlock || (workoutBlock.isQuestionBlock && source.droppableId === 'activityList'))
        return {} // get outta here

    const blockItems = (workoutBlock.isQuestionBlock? workoutBlock.questions: workoutBlock.activities) || [];
    const draggableActivity = isDroppedFromActivityList ?
        activities.find(a => a.id == draggableId) :
        blockItems.find(a => workoutBlock.isQuestionBlock? a.droppableId == draggableId : a.id == draggableId)

    if(!isDroppedFromActivityList){
        blockItems.splice(source.index, 1)
    }
    if(isDroppedFromActivityList){
        newBlockActivity = await buildWorkoutActivity(draggableActivity)
    }
    if(isDroppedFromActivityList){
        const activityToMove = newBlockActivity.id ? newBlockActivity : draggableActivity // handle a drop from the filterable list
        activityToMove.sets = activityToMove.sets ? activityToMove.sets : 1
        activityToMove.reps = activityToMove.reps ? activityToMove.reps : 10
        //Why is hasleaderboard string. Weird.
        let newActivity = {
            id: activityToMove.id,
            groups: [activityToMove],
            name: activityToMove.name,
            description: activityToMove.description || null,
            hasLeaderboard: activityToMove.hasLeaderboard
        }
        blockItems.splice(destination.index, 0, newActivity)
    }
    else{
        blockItems.splice(destination.index, 0, draggableActivity)
    }
    return { blockItems, workoutBlock }
}

export function stripDescription(description){
    description = description || "";
    if(description.includes('blocks')){ // NEED BETTER WAY TO DO THIS
        return JSON.parse(description).blocks[0].text
    }
    return description.removeHTMLString();;
}

export async function resolveNewBlocks(workoutBlocks, isQuestionBlock){
    const index = `${parseInt(_.last(workoutBlocks).id) + 1}`
    let newWorkoutBlock = { id: index, activities: [] }
    if(isQuestionBlock){
        newWorkoutBlock.isQuestionBlock = true;
        newWorkoutBlock.type = "question"
    }
    return workoutBlocks.concat([newWorkoutBlock])
}

export async function resolveWorkoutBlocks(workout){
    let workoutBlocks = []
    await Promise.all(Object.values(workout.workoutSegments).map(async (segment, index) => {
        let workoutBlockActivityGroups = [];
        (segment.questions||[]).forEach(question => question.droppableId = question.id);
        const workoutBlock = {
            questions:segment.questions,
            activities: [],
            id: `${index}`,
            type: segment.type,
            isQuestionBlock: segment.isQuestionBlock,
            surveyId: segment.surveyId
        }
        let counter = 0;
        if(!segment.isQuestionBlock) {
            await Promise.all(Object.values(segment.workoutActivities).map((activity, activityIndex) => {
                let sameWorkoutActivity = Object.values(segment.workoutActivities)[activityIndex-1] ?
                    Object.values(segment.workoutActivities)[activityIndex-1].activityId == activity.activityId :
                    false
                if(sameWorkoutActivity){
                    workoutBlockActivityGroups[counter-1].groups.push(activity)
                }
                else if (!sameWorkoutActivity) {
                    workoutBlockActivityGroups[counter] = {
                        id: activity.id,
                        groups: [activity],
                        name: activity.name,
                        tempo: activity.tempo,
                        description: activity.description,
                        hasLeaderboard: activity.hasLeaderboard === "true" || activity.hasLeaderboard === true
                    }
                    counter++;
                }
            }))
            workoutBlock.activities = workoutBlockActivityGroups
        }
        workoutBlocks[index] = workoutBlock
    }))
    return workoutBlocks
}

export async function updateWorkoutActivity(workoutBlocks, activityId, property, value){
    const workoutBlock = workoutBlocks.find(block => block.activities.find(a => a.groups.find(g => g.id == activityId)))
    const workoutActivity = workoutBlock.activities.find(a => a.groups.find(g => g.id == activityId))
    const groups = workoutActivity.groups
    let groupActivity = groups.find(g => g.id == activityId)
    groupActivity[property] = value
    return workoutBlocks
}

export async function updateActivity(workoutBlocks, activityId, property, value){
    const workoutBlock = workoutBlocks.find(block => block.activities.find(a => a.groups.find(g => g.id == activityId)))
    const workoutActivity = workoutBlock.activities.find(a => a.groups.find(g => g.id == activityId))
    workoutActivity[property] = value
    return workoutBlocks
}

export async function deleteWorkoutActivity(workoutBlocks, workoutActivity){
    let workoutBlock = workoutActivity.groups ? workoutBlocks.find(wb => wb.activities.find(wa => wa.id == workoutActivity.id)) :
        workoutBlocks.find(block => block.activities.find(a => a.groups.find(g => g.id == workoutActivity.id)))

    if(workoutActivity.groups){
        workoutBlock.activities = _.without(workoutBlock.activities, workoutActivity)
    }
    else { // this is a set group activity
        const foundWorkoutActivity = workoutBlock.activities.find(a => a.groups.find(g => g.id == workoutActivity.id))
        foundWorkoutActivity.groups = _.without(foundWorkoutActivity.groups, workoutActivity)
        if(!foundWorkoutActivity.groups.length)
            workoutBlock.activities = _.without(workoutBlock.activities, foundWorkoutActivity)
    }
    return workoutBlocks
}

export async function duplicateWorkoutActivity(workoutBlocks, activity){
    let workoutBlock = workoutBlocks.find(wb => wb.activities.find(wa => wa.id == activity.id))
    const index = _.findIndex(workoutBlock.activities, activity)
    const workoutActivityJSON = activity.groups[0]
    const newWorkoutActivity = await buildWorkoutActivity(workoutActivityJSON)
    workoutBlock.activities[index].groups.push(newWorkoutActivity)
    return workoutBlocks
}

export async function addNewWorkoutActivity(workoutBlocks, activity){
    let workoutBlock = workoutBlocks.find(wb => wb.activities.find(wa => wa.id == activity.id))
    const index = _.findIndex(workoutBlock.activities, activity)
    let workoutActivityJSON = _.clone(activity.groups[0]);
    //Reset some fields
    workoutActivityJSON.tempo = [null,null,null,null,null,null,null];
    workoutActivityJSON.notes = null;
    const newWorkoutActivity = await buildWorkoutActivity(workoutActivityJSON)
    workoutBlock.activities[index].groups.push(newWorkoutActivity)
    return workoutBlocks
}

export async function deleteWorkoutBlock(workoutBlocks, workoutBlock){
    if(workoutBlocks.length > 1){
        workoutBlocks = _.without(workoutBlocks, workoutBlock)
        workoutBlocks.forEach(block => { if(block !== workoutBlock && block.id >= workoutBlock.id) block.id = `${parseInt(block.id) - 1}` })
        return workoutBlocks
    }
    else {
        return workoutBlocks
    }
}

export async function duplicateWorkoutBlock(workoutBlocks, workoutBlock){
    const index = parseInt(workoutBlock.id)
    let newWorkoutBlock = Object.assign({}, workoutBlock)
    newWorkoutBlock.id = `${index + 1}`
    const activities = await buildDuplicateWorkoutBlockActvities(newWorkoutBlock)
    newWorkoutBlock.activities = activities
    workoutBlocks.splice(index + 1, 0, newWorkoutBlock)
    workoutBlocks.forEach(block => { if(block !== newWorkoutBlock && block.id >= newWorkoutBlock.id) block.id = `${parseInt(block.id) + 1}` })
    return workoutBlocks
}

async function buildDuplicateWorkoutBlockActvities(workoutBlock){
    let activities = []
    await Promise.all(workoutBlock.activities.map(async (activity, activityIndex) => {
        let activityGroup = { name: activity.name, groups: [] }
        await Promise.all(activity.groups.map(async (groupActivity, index) => {
            const newWorkoutActivity = await buildWorkoutActivity(groupActivity)
            if(!activityGroup.id)
                activityGroup.id = newWorkoutActivity.id
            activityGroup.groups[index] = newWorkoutActivity
        }))
        activities[activityIndex] = activityGroup
    }))
    return activities
}

export async function buildWorkoutActivity(activity){
    let workoutActivityJSON = {}
    if(activity.activityId) {
        workoutActivityJSON = Object.assign({}, activity)
        workoutActivityJSON.hasLeaderboard = activity.hasLeaderboard === "true" || activity.hasLeaderboard === true
        workoutActivityJSON.id = null
    }
    else {
        workoutActivityJSON = {
            sets: 1,
            reps: 10,
            name: activity.name,
            tempo: activity.tempo,
            activityId: activity.id,
            category: activity.category,
            thumbnail: activity.thumbnail,
            scoreType: activity.scoreType,
            attachment: activity.attachment,
            description: activity.description || null,
            repsOrTime: activity.repsOrTime || (activity.scoreType == 'time'? 'time' : 'reps'),
            maxValue: activity.isPercentOfMax && (activity.isPercentOfMax == true || activity.isPercentOfMax == 'true') ? 75 : null,
            isPercentOfMax: activity.isPercentOfMax && (activity.isPercentOfMax == true || activity.isPercentOfMax == 'true') ? true : false,
            hasLeaderboard: activity.hasLeaderboard === "true" || activity.hasLeaderboard === true
        }
    }
    let newId = short.generate();
    workoutActivityJSON.id = newId;
    workoutActivityJSON.overrideId = newId;
    workoutActivityJSON.createdAt = moment().format();
    createWorkoutActivity(workoutActivityJSON);
    delete workoutActivityJSON.overrideId;
    return workoutActivityJSON
}

export function resolveTagsForModal(workout){
    let tags = Object.values(workout.taggings).map(t => {return { name: t.tag.label }})
    return _.uniqBy(tags, 'name')
}

export async function saveTags(tags, workoutId){
    let taggingJson = {
        parentId: workoutId,
        parentType: 'workout',
        labels: (tags||[]).map(item => item.name)
    }
    await saveMultipleTags(taggingJson);
}
