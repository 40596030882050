import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let SoccerIcon = (props) => (
  <SvgIcon width="20" height="18" viewBox="0 0 20 18" {...props}>
  	<path d="M12.3913 0.418959C11.5877 0.189253 11.1526 0.10564 10.408 0.0359934C9.26526 -0.0753681 7.79057 0.077708 6.66944 0.411793C6.33769 0.509372 6.02792 0.6347 5.96897 0.69038C5.88043 0.76701 5.71835 1.67922 5.7847 1.7349C5.79915 1.74854 6.39067 2.02794 7.11921 2.37206L7.17085 2.39645C8.35795 2.96043 8.557 3.03007 8.71928 2.96741L8.73945 2.95927C8.86238 2.90967 9.18781 2.77838 9.49349 2.64711C10.2087 2.35455 11.0641 2.13863 12.1479 1.98555L12.9589 1.86721L13.0032 0.592985L12.3913 0.418959Z" />
<path d="M17.6705 4.31842C16.9332 3.15555 15.7313 2.02062 14.5001 1.32415C14.2643 1.18486 14.043 1.07349 14.0059 1.07331C13.9765 1.07331 13.9543 1.31698 13.9543 1.60954V2.15275L16.7929 4.83369H17.3607C17.6779 4.83369 17.9359 4.81274 17.9359 4.77801C17.9359 4.75008 17.818 4.54132 17.6705 4.31842Z" />
<path d="M4.77509 1.50513C4.73092 1.77674 4.59803 1.92301 3.32259 3.13458C2.06914 4.32537 1.88488 4.47845 1.61948 4.51336C1.4572 4.5413 1.30971 4.54809 1.29512 4.52733C1.1992 4.43673 2.06194 3.38523 2.82136 2.668C3.53662 1.99248 4.58363 1.21258 4.77528 1.21258C4.80447 1.21276 4.80447 1.34507 4.77509 1.50513Z" />
<path d="M8.02663 3.8867C8.02663 3.87971 7.4219 3.58734 6.69205 3.24609C5.9622 2.89786 5.35007 2.61229 5.32808 2.61229C5.3059 2.60549 4.71614 3.14851 4.01567 3.81007L2.73265 5.02181L3.41833 6.31699C4.01567 7.43813 4.12619 7.61234 4.25889 7.58441C4.34003 7.56346 5.07007 7.47287 5.88106 7.37547C6.69205 7.27808 7.4073 7.16653 7.46626 7.13878C7.5474 7.09688 7.63573 6.66522 7.8054 5.48839C7.93071 4.61091 8.03403 3.8867 8.02663 3.8867Z" />
<path d="M14.6771 4.15835L16.0929 5.50241L15.9823 6.29627C15.8275 7.43139 15.4367 8.69165 15.068 9.25581L14.9353 9.47173L11.2192 9.94492L9.88456 8.67768L8.54278 7.41724L8.79339 5.66247C8.81456 5.51584 8.83366 5.38102 8.85123 5.25693C8.98111 4.33988 9.02801 4.00875 9.21762 3.82501C9.32473 3.72122 9.47739 3.66443 9.71628 3.57556C9.77446 3.55392 9.83775 3.53037 9.90674 3.50378C10.6072 3.23218 11.4552 3.0372 12.4062 2.91187C12.6486 2.88428 12.8696 2.85668 13.0197 2.83795C13.1345 2.82362 13.2077 2.81448 13.2172 2.81448C13.2392 2.81429 13.8955 3.42017 14.6771 4.15835Z" />
<path d="M3.21924 8.09268L2.51877 6.76957L1.82608 5.45363L1.38362 5.49535C0.719926 5.56499 0.705139 5.57198 0.535664 6.00364C0.122777 7.04816 -0.076273 8.48263 0.0268516 9.70816C0.0932015 10.502 0.15955 10.8363 0.432344 11.7068L0.616606 12.2847H1.98777L2.06891 11.6371C2.19422 10.676 2.54075 9.46448 2.90208 8.74027L3.21924 8.09268Z" />
<path d="M18.6291 6.31002C18.8871 7.12465 18.9535 7.42419 19.0346 8.2458C19.1525 9.44358 18.9535 10.9477 18.5258 12.0201C18.3563 12.4518 18.3415 12.4588 17.6778 12.5284L17.2354 12.5702L16.5423 11.254L15.8418 9.93093L16.1444 9.31127C16.5795 8.427 16.8597 7.43816 17.0291 6.19168L17.0881 5.7738L18.4447 5.73208L18.6291 6.31002Z" />
<path d="M10.5187 10.6064L9.19886 9.35996C8.38768 8.59402 7.82011 8.10649 7.73177 8.10631C7.37784 8.10631 4.17787 8.54496 4.09673 8.60762C3.98602 8.69124 3.57313 9.62439 3.40366 10.1954C3.25617 10.6619 3.0865 11.5602 3.02015 12.1868L2.97579 12.542L5.81463 15.2092L6.65519 15.1048C7.11964 15.0491 7.81291 14.9236 8.18883 14.8262C8.85972 14.6592 9.91412 14.2413 9.98786 14.123C10.01 14.0882 10.1427 13.2806 10.2754 12.3335L10.5187 10.6064Z" />
<path d="M15.6356 11.7066L16.3287 13.0018L15.0385 14.2136L13.7554 15.4321L12.3841 14.7775C11.374 14.304 11.0127 14.102 11.0347 14.0256C11.0569 13.9699 11.16 13.2596 11.2707 12.4588C11.3812 11.6509 11.5067 10.9616 11.5509 10.9197C11.5951 10.878 12.3251 10.7597 13.1805 10.6551C14.0284 10.5506 14.7805 10.4532 14.8394 10.4322C14.9204 10.4115 15.1342 10.7527 15.6356 11.7066Z" />
<path d="M5.10683 15.8709L2.26837 13.19H1.7006C1.38344 13.19 1.12544 13.2107 1.12544 13.2387C1.12544 13.3221 1.63425 14.0952 1.88486 14.4085C2.25339 14.8611 3.18988 15.7524 3.66911 16.1146C4.10418 16.4419 4.94455 16.9501 5.04787 16.9501C5.08464 16.9501 5.10683 16.7065 5.10683 16.4139V15.8709Z" />
<path d="M17.7886 13.5034C17.7886 13.6844 16.9628 14.6732 16.2476 15.3487C15.4882 16.066 14.3748 16.8808 14.2789 16.7902C14.2567 16.7762 14.2641 16.6369 14.2937 16.4838C14.3304 16.2332 14.4927 16.059 15.7536 14.8754C17.0364 13.6706 17.1913 13.5453 17.4715 13.5035C17.6485 13.4754 17.7886 13.4754 17.7886 13.5034Z" />
<path d="M13.276 16.2886C13.2614 16.2746 12.6567 15.9823 11.9268 15.641C11.197 15.293 10.5554 15.0074 10.5037 15.0072C10.4521 15.0004 10.2089 15.0978 9.9655 15.2162C9.13233 15.6131 7.98959 15.8987 6.50752 16.0936L6.10203 16.1493L6.05766 17.4305L6.69918 17.6185C7.05311 17.7161 7.61348 17.8484 7.93784 17.9041C8.72684 18.0364 10.3564 18.0294 11.1674 17.8971C11.9342 17.7648 12.9516 17.4724 13.0917 17.3331C13.1801 17.2565 13.3423 16.3443 13.276 16.2886Z" />
  </SvgIcon>
);

export default SoccerIcon;