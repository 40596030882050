import _ from "lodash";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from "react";
import SwipeableViews from 'react-swipeable-views';
import { SwatchesPicker, BlockPicker, CirclePicker } from 'react-color';
import ical from 'ical-generator';
import { http } from 'http';
import { ROUTE_PATH } from '../../routeUtil';
import zoomWhiteLogo from "../../assets/images/zoom-white.png"
import zoomBlueLogo from "../../assets/images/zoom-blue.png"
import { ZoomIcon } from '../../components/icons';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline'
import { MeetingIcon } from '../../components/icons';
import { SERVICES, TRAINING_OPTIONS } from '../../constants'
import {
  Tab,
  Tabs,
  Grid,
  Card,
  Modal,
  Input,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  CardContent,
  FormControl,
  CircularProgress
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import { API, Auth } from "aws-amplify";

import CopyModal from './components/howToModal'

// DATE PICKER
import 'react-datepicker/dist/react-datepicker.css';
// //////// //

import "./calendar.css";

import ContextService from '../../services/context';
import { getTenant } from '../../utils/commonUtil';
import { getLocalizedString } from '../../utils/locale-utils';
import { analyticsService, currentTimestamp } from '../../services/analyticsService';
import PageWrapper from '../nav/pageWrapper';
import CustomDraggableCalendar from '../../components/customDraggableCalendar'
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import { liveEventsClient, zoomClient } from '../../clients'
import { TimeInput, InputField, DateInput } from 'maxone-react-ui';
import FloatingSelect from '../../components/floatingSelect';

const contextService = new ContextService()
const TENANT = getTenant()
const reminderOptions = [{ id: 0, label: 'No reminder' }, { id: 10, label: '10 minutes before' }, { id: 15, label: '15 minutes before' }, { id: 30, label: '30 minutes before' }, { id: 60, label: '1 hour before' }, { id: 120, label: '2 hours before' }, { id: 1440, label: '1 day before' }]
const defaultReminderValue = (reminderOptions.find(item => item.id == 30)||{}).id;
const visibilityOptions = TENANT !== "grdrive" && TENANT !== "kickid"  ? [{ id: 1, label: 'everyone' }, { id: 2, label: 'coaches' }, { id: 3, label: 'coaches & athletes' }, { id: 4, label: 'parents & coaches' }] :
  TENANT === "kickid" ? [{ id: 1, label: 'everyone' }, { id: 2, label: 'coaches' }, { id: 3, label: 'coaches & athletes' }] : [{ id: 2, label: 'coaches' }, { id: 3, label: 'coaches && athletes' }];
const styles = theme => ({
  plusButton: {
    backgroundColor: "#1354F9",
    color: 'white'
  },
  textButton: {
    color: 'white',
    marginRight: '15px',
    paddingTop: '6px',
    paddingBottom: '6px',
    backgroundColor: "#1354F9",
  },
  secondaryTextButton: {
      height: 40,
      width: 100,
      marginRight: '15px',
      backgroundColor: "#27AE60",
      color:"#ffffff",
      '&:disabled': {
        opacity:"0.3",
        color:"#ffffff"
    }
  },
  textField: {
      flexGrow: 1,
      width:'100%'
  },
  selectField: {
      marginTop: 5,
      flexGrow: 1,
      width: '100%',
      textAlign: 'left'
  },
  teamCard: {
      cursor: 'pointer',
      borderRadius: 0,
      height: 30,
      display: 'flex',
      backgroundColor: '#28272C'
  },
  card: {
      cursor: 'pointer',
      borderRadius: 0,
      maxHeight: 60,
      display: 'flex',
      overflow:"hidden",
      "& > div, & h3":{
        paddingBottom:"0px !important",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        wordBreak: "break-word",
      }
  },
  details: {
      display: 'flex',
      flexDirection: 'column',
  },
  content: {
    paddingTop: 5,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    justifyContent: 'center'
},
  color: {
    width: '100%',
    height: '20px',
    borderRadius: '2px',
  },
  swatch: {
    width: '50%',
    height: 20,
    padding: '5px',
    background: '#fff',
    borderRadius: '0px',
    border: '1px solid lightgray',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '9999',
    padding: 40,
    backgroundColor: 'white'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  readOnlyInfo: {
    color:'#888888',
    alignSelf: 'flex-start'
  },
  zoomContainer: {
    marginBottom:"8px",
    cursor:"pointer",
    width: "100%",
    background: "#296ED2",
    color: "white",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    padding: "9px",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "20px",
    '& svg':{
      width:"22px",
      height:"19px",
      marginRight:"10px"
    },
    "& img":{
      width:"66.75px",
      height:"15px",
      marginLeft: "auto"
    }
  },
  zoomEnabled:{
    background:"#F8F8F8",
    color:"#296ED2",
    fontWeight: "500",
    fontSize: "15px",
    "& svg":{
      color: "#000000"
    }
  },
  zoomDelIcnContainer:{
    width:"28px",
    height:"28px",
    background:"#EB0028",
    borderRadius:"5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    "& svg":{
      color:"#ffffff",
      marginRight:"0"
    }
  },
  zoomIcon:{
    marginLeft: "auto",
    marginRight: "10px",
    color: "white",
    "& svg":{
      width: "22px",
      height: "18.78px"
    }
  },
  zoomTxt:{
    '& a':{
      color:"#296ED2"
    }
  },
  zoomConflictMsg:{
    color:"#EB0028",
    width:"80%",
    marginBottom: "5px"
  },
  meetingsContainer: {
    flexGrow: '0 !important',
    // marginTop: "-40px !important"
  }
});

// BigCalendar.momentLocalizer(moment);

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      event: {
        reminderTime:defaultReminderValue
      },
      events: [],
      open: false,
      errors:{
        startDate: "",
        endDate: "",
        zoomConflict:false
      },
      schedule: {},
      schedules: [],
      anchorEl: null,
      expanded: false,
      isLoading: true,
      viewingEvents: [],
      showingMore: false,
      showSchedules: true,
      snackbarOpen: false,
      showCopyModal: false,
      detailsExpanded: false,
      scheduleModalOpen: false,
      Transition: TransitionUp,
      showScheduleWarning: true,
      startDate: moment(),
      endDate: moment(),
      viewingScheduleIds: [],
      startDateWithBuffer:moment().subtract(2, 'months').startOf('month').startOf('week'),
      endDateWithBuffer:moment().add(2, 'months').startOf('month').startOf('week'),
      liveEventsVisiblilty: {},
      userZoomData: {},
      roomType: ''
    };
  }

  toggleScheduleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  toggleModal = async (event, schedule, value) => {
    //User can't edit live event from this screen.
    if(!!event && event.isLiveEvent){
      return;
    }
    let newEvent = {
      value: 0,
      reminderTime:defaultReminderValue
    }
    if(this.state.showingMore && !event.id)
      return this.setState({ showingMore: false })
    else if(event && event.id)
      this.setState({ showingMore: false })
    const { schedules } = this.state;
    const { currentTeam } = this.props;
    if(!schedules.length){
      const schedules = await this.getSchedules(currentTeam.id);
      this.setState({ schedules })
    }    
    if(value) {
      if(!schedule || !schedule.id) {
        this.setState({
          value: value,
          event: newEvent,
          open: !this.state.open
        })
      }
      else {
        this.setState({
          value: value,
          schedule: schedule,
          open: !this.state.open
        })
      }
    }
    else {
      if (!event.id) {
        let startDate = moment.utc(event.start).local();
        let currentDate = moment.utc().local();
        startDate.set({
          hour:   currentDate.get('hour'),
          minute: currentDate.get('minute'),
          second: currentDate.get('second')
        });
        let endDate = moment.utc(startDate).local().add(30, 'minutes');
        this.setState({
          value: 0,
          event:newEvent,
          open: !this.state.open,
          startDate: startDate,
          endDate: endDate,
        })
      }
      else {
        this.setState({
          event: this.state.open? {} : _.cloneDeep(event),
          open: !this.state.open,
          endDate: moment.utc(event.endDate).local(),
          startDate: moment.utc(event.startDate).local(),
          schedule: _.find(this.state.schedules, s => s.id == event.scheduleId)
        })
      }
    }
    //Reset error state
    this.setState({
      detailsExpanded:false,
      errors:{
          startDate:'',
          endDate:'',
          zoomConflict: false
      }
    })
  }

  getSchedules = async (parentId) => {
    return API.get("schedules", `/schedules/parent/${parentId}`);
  }

  getScheduleEvents(id) {
    return Auth.currentSession()
    .then(auth =>{
      const options = {
        headers: {
          Authorization: auth.idToken.jwtToken
        }
      }
      return API.get("schedules", `/schedules/${id}/scheduleEvents`, options);
    })
  }

  saveSchedule(){
    const { schedule } = this.state;
    const { currentTeam = {} } = this.props;
    if(schedule.id){
      return API.put("schedules", `/schedules/${schedule.id}`, {
        body: schedule
      });
    }

    return API.post("schedules", `/teams/${currentTeam.id}/schedules`, {
      body: schedule
    });
  }

  saveEvent(event){
    let { currentUser } = this.props;
    event.senderUserId = currentUser.id;
    if(event.id){
      return API.put("schedules", `/scheduleEvents/${event.id}`, {
        body: event
      });
    }
    return API.post("schedules", `/schedules/${event.scheduleId}/scheduleEvents`, {
      body: event
    });
  }

  deleteEvent = event => {
    return API.del("schedules", `/scheduleEvents/${event.id}`);
  }

  deleteSchedule = schedule => {
    return API.del("schedules", `/schedules/${schedule.id}`);
  }

  saveUser = user => {
    return API.put("users", `/users/${user.id}`, {
      body: user
    });
  }

  handleDeleteEvent = async ee => {
    const { event } = this.state;
    const deletedEvent = await this.deleteEvent(event);
    this.setState({
      events: _.uniqBy(_.without(this.state.events, event), 'id'),
      viewingEvents: _.uniqBy(_.without(this.state.viewingEvents, event), 'id'),
    })
    this.resetModal();
  }

  handleDeleteSchedule = async ee => {
    const { schedule } = this.state;

    if(schedule.id) {
      const deleted = await this.deleteSchedule(schedule)
      this.setState({
        schedules: _.without(this.state.schedules, schedule),
      })
      this.resetModal();
    }
  }

  createSchedule = (schedule) => {
    const { currentTeam } = this.props;
    return API.post("schedules", `/teams/${currentTeam.organizationId}/schedules`, {
      body: schedule
    });
  }

  componentDidMount = () => {
    let { currentUser, isZoomConnected } = this.props;

    // load zoom data of user
    if(isZoomConnected) {
      zoomClient.getAccountStatus(currentUser.id).then((response) => {
          let { data } = response;
          this.setState({ userZoomData: data })
      }).finally(() => {
          this.setState({ isLoading: false });
      })
    }
    this.loadSchedules()
  }

  loadSchedules = async () => {
    const { currentTeam, currentUser, appContext, isZoomConnected, isUpliftConnected } = this.props;
    this.setState({
        isLoading: true,
        loadingMessage: 'grabbing your schedules...',
    })
    let promises = [this.getSchedules(currentTeam.id), this.getSchedules(currentTeam.organizationId)];
    if(isZoomConnected || isUpliftConnected){
      promises.push(this.loadLiveEvents())
    }
    const [schedules, directorSchedules] = await Promise.all(promises);
    let showingSchedules = schedules
    _.each(showingSchedules, schedule => {
      if(schedule && schedule.visibility == 'coaches' && !appContext.isAdmin && !appContext.isCoach && !appContext.isHeadCoach && !appContext.isOwner){
        showingSchedules = _.without(showingSchedules, schedule)
      }
      else if(schedule && schedule.visibility == 'parents & coaches' && !appContext.isGuardian && !appContext.isAdmin && !appContext.isCoach && !appContext.isHeadCoach && !appContext.isOwner){
        showingSchedules = _.without(showingSchedules, schedule)
      }
      else if(schedule && schedule.visibility == 'team' && !appContext.isAthlete && !appContext.isCoach && !appContext.isHeadCoach && !appContext.isOwner && !appContext.isAdmin){
        showingSchedules = _.without(showingSchedules, schedule)
      }
    })

    if(directorSchedules && directorSchedules.length){
      this.setState({
        directorSchedule: directorSchedules[0]
      })
    }
    else {
      const directorScheduleJSON = {
        color: '#28272C',
        name: "Director Schedule",
        parentId: currentTeam.organizationId
      }
      const directorSchedule = await this.createSchedule(directorScheduleJSON);
      this.setState({
        directorSchedule: directorSchedule
      })
    }

    if(schedules.length){
      let events = [];
      let viewingEvents = [];
      if(currentUser.viewingScheduleIds && currentUser.viewingScheduleIds.length){
        this.setState({ loadingMessage: 'grabbing your events...' });
        await Promise.all(currentUser.viewingScheduleIds.map(async scheduleId => {
          const schedule = _.find(schedules, s => s.id === scheduleId);
          if(schedule) {
            const scheduleEvents = await this.getScheduleEvents(scheduleId);
            await Promise.all(scheduleEvents.map((event) => {
              event.startDate = new Date(event.startDate);
              event.endDate = new Date(event.endDate);
              if(event.isTBD){
                event.startDate.setMinutes(event.startDate.getMinutes() + event.startDate.getTimezoneOffset());
                event.endDate.setMinutes(event.endDate.getMinutes() + event.endDate.getTimezoneOffset());
              }
              return _.extend(event, { color: schedule.color })
            }));
            events = _.concat(events, scheduleEvents);
            viewingEvents = _.concat(viewingEvents, scheduleEvents);
          }
        }))
      }
      this.setState({
          schedules: showingSchedules,
          events: _.uniqBy(events, 'id'),
          viewingEvents: _.uniqBy(viewingEvents, 'id'),
          viewingScheduleIds: _.uniq(currentUser.viewingScheduleIds || [])
      })
    }
    this.setState({
      isLoading: false
    })
  }

  hideSnackBar() {
    setTimeout(() => this.setState({snackbarOpen: false}), 2000);
  }

  handleScheduleSelection = async (schedule, event, refreshData) => {
    if(event){
      event.stopPropagation();
    }
    const { currentUser } = this.props;
    const { events, viewingEvents } = this.state;
    let user = currentUser.viewingScheduleIds ? currentUser : _.merge(currentUser, {viewingScheduleIds : []});
    user.viewingScheduleIds = _.uniq(user.viewingScheduleIds);
    schedule.viewing = schedule.viewing ? false : true;

    const scheduleIsLoaded = !refreshData && _.find(events, event => event.scheduleId === schedule.id);
    const loadedButNotViewing = scheduleIsLoaded && !_.find(viewingEvents, event => event.scheduleId === schedule.id);
    const loadedAndViewing = scheduleIsLoaded && _.find(viewingEvents, event => event.scheduleId === schedule.id);
    if(loadedButNotViewing){
      user.viewingScheduleIds.push(schedule.id);
      const savedUser = await this.saveUser(user);
      let scheduleEvents = _.filter(events, event => event.scheduleId === schedule.id);
      if(!scheduleEvents.length) {
        this.setState({snackbarOpen: true, message: "Create some events for that schedule", viewingScheduleIds: user.viewingScheduleIds});
        return this.hideSnackBar();
      }
      this.setState({ viewingEvents: [...viewingEvents, ...scheduleEvents], viewingScheduleIds: user.viewingScheduleIds });
    }
    else if(loadedAndViewing){
      user.viewingScheduleIds = _.without(user.viewingScheduleIds, schedule.id);
      const savedUser = await this.saveUser(user);
      this.setState({ viewingEvents: _.filter(viewingEvents, event => event.scheduleId !== schedule.id),viewingScheduleIds: user.viewingScheduleIds });
    }
    else {
      let isDeselected = false;
      if(user.viewingScheduleIds.indexOf(schedule.id) == -1){
        user.viewingScheduleIds.push(schedule.id);
      }
      else{
        user.viewingScheduleIds = _.without(user.viewingScheduleIds, schedule.id);
        isDeselected = true;
      }
      const savedUser = await this.saveUser(user);
      const scheduleEvents = await this.getScheduleEvents(schedule.id);
      if(!scheduleEvents.length && !isDeselected) {
        this.setState({snackbarOpen: true, message: "Create some events for that schedule", viewingScheduleIds: user.viewingScheduleIds});
        return this.hideSnackBar();
      }
      await Promise.all(scheduleEvents.map(event => {
        event.startDate = new Date(event.startDate);
        event.endDate = new Date(event.endDate);
        if(event.isTBD){
          event.startDate.setMinutes(event.startDate.getMinutes() + event.startDate.getTimezoneOffset());
          event.endDate.setMinutes(event.endDate.getMinutes() + event.endDate.getTimezoneOffset());
        }
        return _.extend(event, { color: schedule.color })
      }));
      this.setState({ viewingEvents: _.uniq([...scheduleEvents,...viewingEvents]), events: _.uniq([...scheduleEvents,...events]),viewingScheduleIds: user.viewingScheduleIds });
    }
  }

  handleDeleteClick() {
      this.props.delete(this.props.activity);
  }

  handleEventPropertyChange = name => ee => {
    let { event } = this.state;
    let clonedEvent = {...event};
    clonedEvent[name] = ee.target.value
    this.setState({event: clonedEvent});
  };

  handleEventTimeChange = name => e => {
    let value = e;
    if(e && !!e.target){
      value = e.target.value;
    }
    if(name == "startDate"){
      let { startDate, endDate } = this.state;
      let duration = moment(value).diff(moment(startDate), 'milliseconds');
      endDate = moment(endDate).add(duration, "milliseconds");
      this.setState({ endDate: endDate});
    }
    this.setState({
      [name]: value
    })
  };

  handleSchedulePropertyChange = name => event => {
    let { schedule } = this.state;
    let clonedSchedule = {...schedule};
    clonedSchedule[name] = event.target.value
    this.setState({schedule:clonedSchedule});
  };

  handleScheduleColorChange = (name, color) => {
    let { schedule } = this.state;
    let clonedSchedule = {...schedule};
    clonedSchedule[name] = color.hex;
    this.setState({
      schedule: clonedSchedule,
      displayColorPicker: false
    });
  }

  resetModal = () => {
    this.setState({
      open: false,
      schedule: {},
      event: {},
      value: 0,
      roomType: ""
    })
  }

  handleScheduleSubmit = async event => {
    let { enableFullScreenLoader } = this.props;
    enableFullScreenLoader(true, "Saving...");
    event.preventDefault();
    const { schedules, schedule } = this.state;

    schedule.color = schedule.color ? schedule.color : '#dce775'

    try {
      let savedSchedule = await this.saveSchedule();
      if (!schedule.id){
        analyticsService.trackEvent('Schedule Created', {
          id: savedSchedule.id,
          name: savedSchedule.name,
          visibility: (savedSchedule.visibility)? savedSchedule.visibility : 'everyone',
          description: savedSchedule.description,
          color: savedSchedule.color,
        });
      }
      else{
        _.extend(savedSchedule,schedule);
      }

      this.setState({
        open: false,
        value: 0,
        schedule: {},
        schedules: schedule.id ? schedules : [...schedules, savedSchedule],
      });
      enableFullScreenLoader(false);
      await this.loadSchedules();
      await this.handleScheduleSelection(savedSchedule,null, true);
    } catch (e) {
      alert(e.message);
      enableFullScreenLoader(false);
    }
  }

  onEventDrop = async ({ event, start, end }) => {
    const { events, viewingEvents } = this.state
    const { enableFullScreenLoader } = this.props;
    let eventData = event
    eventData.id = null
    eventData.startDate = moment(start, "YYYY-MM-DDTHH:mm").utc();
    eventData.endDate = moment(end, "YYYY-MM-DDTHH:mm").utc();    
    try{
      enableFullScreenLoader(true, "Saving...")
      const copiedEvent = await this.saveEvent(eventData)

      analyticsService.trackEvent('Event Created', {
        director: false,
        dropped: true,
        id: copiedEvent.id,
        schedule_id: copiedEvent.scheduleId,
        title: copiedEvent.title,
        start_time: copiedEvent.startDate,
        end_time: copiedEvent.endDate,
        description: (copiedEvent.description)? copiedEvent.description : '',
      });

      const scheduleEvents = await this.getScheduleEvents(event.scheduleId);
      await Promise.all(scheduleEvents.map(event => {
        event.startDate = new Date(event.startDate);
        event.endDate = new Date(event.endDate);
        return _.extend(event, { color: eventData.color })
      }));
      this.setState({
        events: events.concat(scheduleEvents),
        viewingEvents: _.filter(scheduleEvents.concat(this.state.viewingEvents), e => e.id),
      })
    }
    catch(e){
      if(!!e && !!e.response && e.response.status == 409){
        alert('Oops, SCHEDULING CONFLICT! The connected Zoom account shows a conflict with the time you are attempting to schedule. Please choose another time to continue.');
      }
       console.log(e)
    }
    enableFullScreenLoader(false)
  }

  onEventResize({event, start, end}){
    console.log('resizing')
  }

  handleSubmit = async () => {
    const { events, viewingEvents, event, schedules, startDate, endDate, viewingScheduleIds } = this.state;
    const { isZoomPaidPlan, enableFullScreenLoader } = this.props;
    const schedule = _.find(schedules, s => s.id == event.scheduleId);
    const newEndDate = endDate ? moment(endDate, "YYYY-MM-DDTHH:mm").utc() :
                      event.id ? moment(event.endDate).utc() : moment(endDate, "YYYY-MM-DDTHH:mm").utc();
    const newStartDate = startDate ? moment(startDate , "YYYY-MM-DDTHH:mm").utc() :
                      event.id ? moment(event.startDate).utc() : moment(startDate, "YYYY-MM-DDTHH:mm").utc();
    const timeRegex = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/g;
    let valid = true;
    let errors = {};
    if(newEndDate.isBefore(newStartDate)){
      errors.endDate = "your end date must be after your start date"
      this.setState({detailsExpanded: false});
    }

    if(!errors.endDate && !errors.startDate && !isZoomPaidPlan && event.zoomMeeting && moment.duration(newEndDate.diff(newStartDate)).asMinutes() > 40){
      errors.endTime = "Zoom meeting cannot be greater than 40 minutes for a free account";
    }

    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );

    if(!valid){
      return this.setState({
          errors
        })
    }
    enableFullScreenLoader(true, "Saving...");
    event.endDate = newEndDate
    event.startDate = newStartDate
    try {
      let savedEvent = await this.saveEvent(event);
      if (!event.id){
        analyticsService.trackEvent('Event Created', {
          director: false,
          dropped: false,
          id: savedEvent.id,
          schedule_id: savedEvent.scheduleId,
          title: savedEvent.title,
          start_time: savedEvent.startDate,
          end_time: savedEvent.endDate,
          description: (savedEvent.description)? savedEvent.description : '',
        });

        _.extend(savedEvent, {
          color: schedule.color,
          startDate: new Date(savedEvent.startDate),
          endDate: new Date(savedEvent.endDate) // big calendar requires date objects for rendering
        })
      }
      else{
        _.extend(savedEvent, event, {
          color: schedule.color,
          startDate: new Date(event.startDate),
          endDate: new Date(event.endDate) // big calendar requires date objects for rendering
        });

      }

      this.setState({
        event: {},
        open: false,
        errors:{
          startDate: "",
          endDate: "",
          zoomConflict: false
        },
        schedule: {},
        startDate: null,
        endDate: null,
        viewingScheduleIds: _.uniq(viewingScheduleIds.concat(schedule.id)),
        events: [savedEvent, ...events],
        viewingEvents: [savedEvent,...viewingEvents],
        roomType: ''
      })
    } catch (e) {
      if(!!e && !!e.response && e.response.status == 409){
        this.setState({errors: {zoomConflict: true}});
      }
      else{
        alert('Oops, something went wrong! Try that again.');
      }
        console.log(e.message)
    }
    enableFullScreenLoader(false);
}

  validateEventForm(){
    return(
      this.state.event.title &&
      this.state.event.scheduleId
    )
  }

  validateScheduleForm(){
    return(
      this.state.schedule.name
      // this.state.schedule.color
    )
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleTabValueChange = (product, value) => {
    this.setState({ value });
  };

  showScheduleWarning = () => {
    const { schedules, value } = this.state;
    return !schedules.length && value != 1;
  }

  toggleShowCopyModal = () => {
    this.setState({ showCopyModal: !this.state.showCopyModal })
  }

  toggleShowSchedules = async () => {
    const { schedules } = this.state;
    const { currentTeam, appContext } = this.props;

    this.setState({ showSchedules: !this.state.showSchedules });

    if(!schedules.length){
      this.setState({ isLoading: true })
      const schedules = await this.getSchedules(currentTeam.id);
      let showingSchedules = schedules
      _.each(showingSchedules, schedule => {
        if(schedule && schedule.visibility == 'coaches' && !appContext.isAdmin && !appContext.isCoach && !appContext.isHeadCoach && !appContext.isOwner){
          showingSchedules = _.without(showingSchedules, schedule)
        }
        else if(schedule && schedule.visibility == 'parents & coaches' && !appContext.isGuardian && !appContext.isAdmin && !appContext.isCoach && !appContext.isHeadCoach && !appContext.isOwner){
          showingSchedules = _.without(showingSchedules, schedule)
        }
        else if(schedule && schedule.visibility == 'team' && !appContext.isAthlete && !appContext.isCoach && !appContext.isHeadCoach && !appContext.isOwner && !appContext.isAdmin){
          showingSchedules = _.without(showingSchedules, schedule)
        }
      })
      this.setState({
        isLoading: false,
        schedules: schedules
      })
    }

  }

  syncCalendarEvents = async () => {
    const { schedule } = this.state;
    const { currentTeam } = this.props;
    const scheduleEvents = await this.getScheduleEvents(schedule.id);

    const cal = ical({
      timezone: 'UTC',
      name: schedule.name,
      domain: currentTeam.sport == 'Softball' ? 'onesoftball.com' : 'gomaxone.com'
    })

    scheduleEvents.map(event => {
      cal.createEvent({
        start: event.startDate,
        end: event.endDate,
        summary: event.title,
        description: event.description,
        location: event.location
      });
    })

    cal.saveSync('./filename.ics')
  }

  navigateToLiveTraining = () => {
    const { history } = this.props;
    history.push(ROUTE_PATH.WORKOUT_SCHEDULES, { expandGroupTraining: true});
  }

  toggleVideoMeeting = (currentStatus) => {
    this.handleEventPropertyChange("zoomMeeting")({target:{value:!currentStatus}})
  }

  openZoomLink = (link) => {
    const win = window.open(link, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  loadLiveEvents = async (referredDate) => {
    if (this.navigationDataFetchTimeout) {
        clearTimeout(this.navigationDataFetchTimeout);
    }
    this.navigationDataFetchTimeout = setTimeout(async () => {
        let { currentTeam } = this.props;
        let { startDateWithBuffer, endDateWithBuffer, liveEvents: existingEvents, liveEventsVisiblilty, viewingEvents } = this.state;
        let newStartDate = startDateWithBuffer;
        let newEndDate = endDateWithBuffer;
        let dataFilters = {startDate: startDateWithBuffer.format(), endDate: endDateWithBuffer.format()};
        if(referredDate){
          //This will fetch data for 2 more months
          let startingDate = moment(referredDate).startOf('month').startOf('week')
          if(startingDate.isBefore(startDateWithBuffer)){            
            newStartDate = moment(startingDate).subtract(3, 'months');
            dataFilters = {startDate: newStartDate.format(), endDate: startingDate.format()};
          }
          else if(startingDate.isAfter(endDateWithBuffer)){            
            newEndDate =  moment(startingDate).add(3, 'months');
            dataFilters = {startDate: endDateWithBuffer.format(), endDate: newEndDate.format()};
          }
          else {
            //Data is already present. Don't fetch
            return
          }
        }
        try {
            let computedData = [];
            let { data:{results:liveEvents} } = await liveEventsClient.getLiveEventsByTeamId(currentTeam.id, dataFilters);
             (liveEvents||[]).forEach((event) => {
                computedData.push({ ...event, color: "#0047FF", isLiveEvent: true });
            })
            liveEvents = _.uniqBy(computedData.concat(existingEvents), 'id').filter(i => !!i);
            //Append data also in viewingEvents
            //Have a zoom or uplift check
            if(!!liveEventsVisiblilty){
              let filteredLiveEvents = (liveEvents||[]).filter(liveEvent => liveEventsVisiblilty[liveEvent.type]);
              viewingEvents = viewingEvents.filter(i=> !i.isLiveEvent && !liveEventsVisiblilty[i.type]).concat(filteredLiveEvents).filter(i => !!i);
            }
            this.setState({ liveEvents, startDateWithBuffer: newStartDate, endDateWithBuffer: newEndDate, viewingEvents});
        }
        catch (e) {
            console.log("Exception");
            console.log(e);
        }
       // this.setState({ isLoading: false });
    }, !referredDate ? 0 : 500);
  }

  toggleLiveEvents = ({type}) => {
    let { liveEventsVisiblilty, liveEvents, viewingEvents } = this.state;    
    if(!liveEventsVisiblilty[type]){
      viewingEvents = viewingEvents.concat(liveEvents.filter(item => item.type == type));
    }
    else {
      viewingEvents = viewingEvents.filter(item => !item.isLiveEvent && item.type == type);
    }
    this.setState({liveEventsVisiblilty: {...liveEventsVisiblilty, [type]: !liveEventsVisiblilty[type]}, viewingEvents})
  }

  handleCalendarNavigate = (date, view, action) => {
   let { isZoomConnected, isUpliftConnected } = this.props;
   if(isZoomConnected|| isUpliftConnected){
    this.loadLiveEvents(date)
   }
  }

  handleRoomTypeChange = e => {
    const { event, userZoomData } = this.state;
    let roomType = e.target.value;
    if(roomType === 'PERSONAL_MEETING_ROOM' && userZoomData && userZoomData.personal_meeting_url) {
      event["zoomMeetingUrl"] = userZoomData.personal_meeting_url; 
    }
    event["zoomMeeting"] =  true;
    event["roomType"] = roomType;
    this.setState({ roomType, event });
  }

  render() {
    const { viewingEvents, open, snackbarOpen, scheduleModalOpen, schedules, anchorEl, event, schedule, isLoading, value, 
      showSchedules, directorSchedule, displayColorPicker = false, message, errors, loadingMessage, viewingScheduleIds, liveEventsVisiblilty, userZoomData } = this.state;
    let {startDate, endDate} = this.state;
    const { classes, appContext, currentTeam, isZoomConnected, isUpliftConnected } = this.props;   
    const disableAllInputs = event.isReadOnly || (appContext.isStaff && !contextService.isStaffPermitted(currentTeam, 'canEditSchedules'));
    const hasZoomMeeting = !!event.zoomMeeting && !!event.zoomMeetingUrl;
    const liveTrainingOptionsStatus = { [SERVICES.ZOOM]:isZoomConnected, [SERVICES.UPLIFT]:isUpliftConnected };
    const trainingOptions = TRAINING_OPTIONS.filter(options => liveTrainingOptionsStatus[options.feature]);
    return (
      <PageWrapper title={getLocalizedString("CALENDARS")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage} isInBeta={true}>
      <div className={"calendarOuterContainer"}>
        <CopyModal open={this.state.showCopyModal} toggleModal={this.toggleShowCopyModal} />
        <div>
          <Snackbar
            open={snackbarOpen}
            TransitionComponent={this.state.Transition}
            ContentProps={{
              'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{this.state.message}</span>}
            action={[
              <IconButton key="close" className={classes.close} onClick={() => this.setState({snackbarOpen:false, message:""})}>
                <CloseIcon className={classes.icon}/>
              </IconButton>
            ]}
          />
          <div className={"pageTitleContainer"}>
            <div className={"titleButtonContainer"}>
              <Button
                title="How To Copy"
                onClick={this.toggleShowCopyModal}
                aria-label="add"
                className={classes.textButton}
                variant="contained"
              >
                  Copying
              </Button>
              <Button
                title="View Calendars"
                onClick={this.toggleShowSchedules}
                aria-label="add"
                className={classes.textButton}
                variant="contained"
              >
                  {showSchedules ? "Hide" : "Show"} Schedules
              </Button>
              {!appContext.isGuardian && !appContext.isAthlete && (!appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditSchedules')) ?
              <Button
                mini
                variant="fab"
                aria-label="add"
                title="Add Schedules"
                className={classes.plusButton}
                onClick={() => this.toggleModal(null, null, 1)}>
                  <AddIcon className={"icon"} style={{ color: 'white' }} />
              </Button>
              : null}
            </div>
           {(isZoomConnected || isUpliftConnected) && <div className={classes.assignBtn}>
              <Button
                  title="Assign Live Training"
                  onClick={this.navigateToLiveTraining}
                  color="primary"
                  className={"openButton"}
                  variant="contained"
              >
                  Assign Live Training
              </Button>
            </div>}
          </div>
          <div className={"calendarContainer"}>
            {
                showSchedules
            ?
                <div className={"programContainer"}>
                    <Grid
                        className={"programListContainer"}
                        container
                        spacing={8}
                    >
                      <div className={"teamsContainer"}>
                          <Grid item xs={12}>
                            <p style={{ fontWeight: 'bold', marginTop: 5 }}>Master Schedules</p>
                          </Grid>
                          <Grid item xs={12}>
                              <Card
                                  style={{ marginTop: 5, backgroundColor: directorSchedule && directorSchedule.color ? directorSchedule.color : '#28272C' }}
                                  onClick={() => this.handleScheduleSelection(directorSchedule)}
                                  className={classNames(classes.card, {"selectedCard": !!_.find(viewingEvents, e => !!e && directorSchedule && e.scheduleId === directorSchedule.id)})}
                              >
                                  <CardContent className={classNames(classes.content, "d-flex align-items-center")}>
                                      <Typography variant="subheading" style={{color: 'white', textOverflow: 'ellipsis' }}>
                                          Director
                                      </Typography>
                                      {!!_.find(viewingEvents, e => !!e && directorSchedule && e.scheduleId === directorSchedule.id) ? <VisibilityIcon style={{ color: 'white', marginLeft: 5 }} /> : null}
                                  </CardContent>
                              </Card>
                          </Grid>
                          <Grid item xs={12} style={{ borderTop: '1px solid lightgray', marginTop: 10 }}>
                            <p style={{ fontWeight: 'bold', marginTop: 5 }}>Team Schedules</p>
                          </Grid>
                          {schedules.map((schedule, index) => (
                              <Grid
                                  item
                                  xs={12}
                                  key={schedule.id}
                                  style={{marginTop: 5}}>
                                  <ScheduleCard
                                    classes={classes}
                                    schedule={schedule}
                                    appContext={appContext}
                                    onClick={(event) => this.handleScheduleSelection(schedule,event)}
                                    toggleModal={() => this.toggleModal(null, schedule, 1)}
                                    isSelected={viewingScheduleIds.indexOf(schedule.id)>-1}
                                  />
                              </Grid>
                          ))}
                          {/* Check zoom account status. If present show Large Groups Training*/}
                       {(trainingOptions||[]).map(trainingOption => <Grid
                          item
                          xs={12}
                          key={schedule.id}
                          style={{ marginTop: 5 }}>
                          <ScheduleCard
                            classes={classes}
                            schedule={{ name: trainingOption.name, color: "#0047FF" }}
                            appContext={appContext}
                            onClick={() => this.toggleLiveEvents(trainingOption)}
                            toggleModal={() => this.navigateToLiveTraining()}
                            isSelected={liveEventsVisiblilty[trainingOption.type]}
                          />
                        </Grid>)}
                      </div>
                    </Grid>
                </div>
            :
                null
            }
            <CustomDraggableCalendar
              resizable
              selectable
              popup={true}
              showMultiDayTimes
              views={['month']}
              className={'mozilla'}
              endAccessor='endDate'
              startAccessor='startDate'
              onSelectSlot={this.toggleModal}
              onSelectEvent={this.toggleModal}
              onEventDrop={this.onEventDrop}
              onEventResize={this.onEventResize}
              events={_.uniqBy(viewingEvents, 'id')}
              style={showSchedules ? { width: '80%', minHeight: 600 } : { width: '100%', minHeight: 600 }}
              titleAccessor={(event) => { return <div className="event-title d-flex align-items-center justify-content-between"><div className="text-truncate">{event.isTBD? "TBD:": moment.utc(event.startDate).local().format('h:mma')} {event.title || event.name}</div>{event.zoomMeeting && <div><ZoomIcon /></div>}{event.upliftMeeting && <div><PlayCircleOutline style={{color:"white"}}/></div>}</div> }}
              eventPropGetter={(event) => {
                let style = { backgroundColor: event.color, fontSize: 10 };
                return { style };
              }}
              messages={{
                showMore: total => (
                  <div
                    onClick={() => this.setState({ showingMore: true })}
                    style={{ cursor: 'pointer' }}
                    onMouseOver={e => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >{`+${total} mores`}
                  </div>
                ),
              }}
              onNavigate={this.handleCalendarNavigate}
            />
            <LegacyModalContainer
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={this.toggleModal}
              disableEnforceFocus={true}
              containerClassName={"calendarPaper"}>
                <div className={"modalHeaderContainer"}>
                  {
                    event.title && value == 0 ?
                    <h2 className={"modalHeaderText"}>{event.title}</h2>
                  :
                    schedule.name && value == 1 ?
                    <h2 className={"modalHeaderText"}>{schedule.name}</h2>
                  :
                    <h2 className={"modalHeaderText"}>New {value == 0 ? "Event" : "Schedule"}</h2>
                  }
                </div>
                {event.isReadOnly?<div className={classes.readOnlyInfo}>Read Only</div>:null}
                {/* <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabValueChange}
                    className={"tabContainer"}>
                    <Tab label="Event" value={0} />
                    <Tab label="Schedule" value={1} className={this.showScheduleWarning() ? classNames("animated", "pulse") : null} style={this.showScheduleWarning() ? {color: '#CDDD00'} : null} />
                </Tabs> */}
                <SwipeableViews index={value}>
                  <div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => this.setState({ detailsExpanded: !this.state.detailsExpanded })}>
                    {
                      errors.zoomConflict && <div className={classes.zoomConflictMsg}>SCHEDULING CONFLICT! The connected Zoom account shows 
                      a conflict with the time you are attempting to schedule. Please
                      choose another time to continue. 
                      </div>
                    }
                    <div className={"justify-content-end d-flex ml-auto"}>
                      <p style={{ margin: 0 }}>{this.state.detailsExpanded ? 'Hide' : 'More'} Details</p>
                      {this.state.detailsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </div>
                    </div>
                    {!this.state.detailsExpanded ?
                      <Grid container spacing={16}>
                        {directorSchedule && event && event.scheduleId !== directorSchedule.id ?
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.selectField} disabled={this.showScheduleWarning()}>
                            <InputLabel htmlFor="scheduleId">Schedule</InputLabel>
                            <Select
                                disabled={disableAllInputs}
                                value={event.scheduleId ? event.scheduleId : ""}
                                onChange={this.handleEventPropertyChange("scheduleId")}
                                input={<Input id="scheduleId" />}>
                                {schedules.map(schedule => (
                                <MenuItem
                                    key={schedule.id}
                                    value={schedule.id}>
                                    {schedule.name}
                                </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        :
                        null}
                        <Grid item xs={12} sm={6}>
                          <TextField
                              id="title"
                              label="Title"
                              className={classes.textField}
                              value={event.title ? event.title : ""}
                              onChange={this.handleEventPropertyChange("title")}
                              margin={"dense"}
                              disabled={disableAllInputs}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DateInput
                                useOldDesign={true}
                                id="startDate"
                                handleChange={this.handleEventTimeChange("startDate")}
                                date={startDate}
                                label={'Start Date'}
                                error={!!errors.startDate}
                                disabled={disableAllInputs}
                                helperText={errors.startDate}
                                dateFormat={'MM/DD/YYYY'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                          <TimeInput
                            useOldDesign={true}
                            id="startTime"
                            handleChange={this.handleEventTimeChange("startDate")}
                            date={event.isTBD ? null : startDate}
                            label={'Start Time'}
                            error={!!errors.startTime}
                            helperText={errors.startTime}
                            disabled={disableAllInputs}
                            inputlabel={event.isTBD ? "TBD" : null}
                          />

                        </Grid>
                        <Grid item xs={6}>
                            <DateInput
                                useOldDesign={true}
                                id="endDate"
                                handleChange={this.handleEventTimeChange("endDate")}
                                date={endDate}
                                label={'End Date'}
                                disabled={disableAllInputs}
                                helperText={errors.endDate}
                                error={!!errors.endDate}
                                dateFormat={'MM/DD/YYYY'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                          <TimeInput
                            useOldDesign={true}
                            id="endTime"
                            handleChange={this.handleEventTimeChange("endDate")}
                            date={event.isTBD ? null : endDate}
                            label={'End Time'}
                            error={!!errors.endTime}
                            helperText={errors.endTime}
                            disabled={disableAllInputs}
                            inputlabel={event.isTBD ? "TBD" : null}
                          />
                        </Grid>
                      </Grid>
                    : null}
                    {this.state.detailsExpanded ?
                      <Grid container spacing={16}>
                        <Grid item xs={12}>
                          <TextField
                              id="location"
                              label="Location"
                              className={classes.textField}
                              value={event.location ? event.location : ""}
                              onChange={this.handleEventPropertyChange("location")}
                              helperText="optional"
                              margin={"dense"}
                              disabled={disableAllInputs}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.selectField}>
                            <InputLabel htmlFor="reminderTime">Remind</InputLabel>
                            <Select
                                disabled={event.isReadOnly}
                                value={event.reminderTime}
                                onChange={this.handleEventPropertyChange("reminderTime")}
                                input={<Input id="reminderTime" />}>
                                {reminderOptions.map(option => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="description"
                              label="Description"
                              className={classes.textField}
                              value={event.description ? event.description : ""}
                              onChange={this.handleEventPropertyChange("description")}
                              helperText="optional"
                              margin={"dense"}
                              disabled={disableAllInputs}
                          />
                        </Grid>
                      </Grid>
                    : null}
                  {value == 0 && isZoomConnected && <FloatingSelect
                    overrideContainerCss={classes.meetingsContainer}
                    label={"Room Type to Schedule"}
                    value={event.roomType ? event.roomType : this.state.roomType}
                    onChange={e => this.handleRoomTypeChange(e)}
                    style={{flexGrow: 0, marginTop: '-40px !important', marginBottom: '0px !important'}}
                    >
                        <MenuItem
                          key={'personalMeeting'}
                          value={'PERSONAL_MEETING_ROOM'}
                          >
                              {'Personal Meeting Room'}
                        
                        </MenuItem>
                        <MenuItem
                          key={'zoomLink'}
                          value={'CREATE_ZOOM_LINK'}
                          >
                              {'Create Zoom Link'}
                        </MenuItem>
                 
                </FloatingSelect>}
                  </div>
                  <div>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <TextField
                            id="name"
                            label="Name"
                            className={classes.textField}
                            value={schedule && schedule.name ? schedule.name : ""}
                            onChange={this.handleSchedulePropertyChange("name")}
                            margin="dense"
                            disabled={disableAllInputs}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.selectField}>
                          <InputLabel htmlFor="visibility">Visibility</InputLabel>
                          <Select
                              value={schedule && schedule.visibility ? schedule.visibility : "everyone"}
                              onChange={this.handleSchedulePropertyChange("visibility")}
                              input={<Input id="visibility" />}
                              disabled={disableAllInputs}
                          >
                              {visibilityOptions.map(option => (
                              <MenuItem
                                  key={option.id}
                                  value={option.label}>
                                  {option.label}
                              </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="description"
                            label="Description"
                            className={classes.textField}
                            value={schedule && schedule.description ? schedule.description : ""}
                            onChange={this.handleSchedulePropertyChange("description")}
                            margin="dense"
                            helperText="optional"
                            disabled={disableAllInputs}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ display:'flex', alignItems:'flex-end', marginTop:17 }}>
                        <p style={{ marginRight: 15, marginBottom: 0, color: 'rgba(0, 0, 0, 0.57)' }}>Color</p>
                        <div style={{padding:0, border:0}} className={classes.swatch}
                          onClick={() => {
                            if(!appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditSchedules')) {
                              this.setState({ displayColorPicker: !this.state.displayColorPicker })
                            }
                          }}
                        >
                          <div className={classes.color} style={{ backgroundColor: schedule && schedule.color ? schedule.color : '#dce775' }} />
                        </div>
                        {
                          this.state.displayColorPicker
                        ?
                          <div className={classes.popover}>
                            <div className={classes.cover} onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker })} />
                            <CirclePicker
                              color={schedule && schedule.color ? schedule.color : '#dce775'}
                              onChange={color => this.handleScheduleColorChange("color", color)}
                            />
                          </div>
                        :
                          null
                        }
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Button
                          type="submit"
                          className={classes.secondaryTextButton}
                          onClick={this.syncCalendarEvents}
                        >
                            Sync
                        </Button>
                      </Grid> */}
                    </Grid>
                  </div>
                </SwipeableViews>

                
                {/* {value == 0 && isZoomConnected && 
                 <div className={classNames(classes.zoomContainer,{[classes.zoomEnabled]: event.zoomMeeting})} onClick={hasZoomMeeting? null : ()=>this.toggleVideoMeeting(event.zoomMeeting)}>
                    <MeetingIcon />
                    <div className={classes.zoomTxt}>
                      {hasZoomMeeting ? <a target="_blank" href={event.zoomMeetingUrl}>Zoom link</a> :
                      ((event.zoomMeeting ? "Remove":"Add") + " Zoom Video Call")}
                    </div>
                    <img className={classes.logoImage} src={event.zoomMeeting? zoomBlueLogo: zoomWhiteLogo} />
                    {event.zoomMeeting && <div onClick={()=>this.toggleVideoMeeting(event.zoomMeeting)} className={classes.zoomDelIcnContainer}><DeleteIcon className={classes.zoomDelIcon} /></div>}
                </div>} */}
                <div className={"modalFooterContainer"}>
                  {
                    !event.isReadOnly && ((schedule && schedule.id) || (event && event.id)) && !appContext.isGuardian && !appContext.isAthlete && directorSchedule && event && event.scheduleId !== directorSchedule.id &&
                    (!appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditSchedules'))
                  ?
                    <div>
                      <IconButton
                        style={{marginLeft: 5}}
                        title="Delete Event"
                        onClick={value == 0 ? this.handleDeleteEvent : this.handleDeleteSchedule}
                        variant="contained">
                        <DeleteIcon className={"styledIcon"} />
                      </IconButton>
                    </div>
                    :
                    <div/>
                  }
                  <div>
                    <Button
                      variant="contained"
                      className={"cancelButton"}
                      onClick={this.resetModal}>
                        Cancel
                    </Button>
                    {
                      !event.isReadOnly && !appContext.isGuardian && !appContext.isAthlete && directorSchedule && event && event.scheduleId !== directorSchedule.id &&
                      (!appContext.isStaff || appContext.isStaff && contextService.isStaffPermitted(currentTeam, 'canEditSchedules')) ?
                      <Button
                        type="submit"
                        className={classes.secondaryTextButton}
                        onClick={value == 0 ? this.handleSubmit : this.handleScheduleSubmit}
                        disabled={value == 0 ? !this.validateEventForm() : !this.validateScheduleForm()}>
                          Save
                      </Button>
                    :
                      null
                    }
                  </div>
                </div>
            </LegacyModalContainer>
          </div>
        </div>
      </div>
      </PageWrapper>
    );
  }
}

class ScheduleCard extends Component {
  render(){
      const { classes, workout, onClick, selectedWorkout, isSelected, schedule, appContext, toggleModal } = this.props;

      return(
          <div>
              <Card onClick={event => onClick(event)} className={classNames({"selectedCard": isSelected}, classes.card)} style={{ backgroundColor: schedule.color }}>
                  <CardContent className={classNames(classes.content, "d-flex align-items-center")}>
                      <Typography variant="subheading" style={{ color: 'white' }} onClick={event => onClick(event)}>
                          {schedule ? schedule.name : ""}
                      </Typography>
                      {isSelected ?
                        <div style={{ display: 'flex', flexWrap: 'no-wrap' }}>
                          <VisibilityIcon style={{ fontSize: 20, color: 'white', marginLeft: 5 }} onClick={event => onClick(event)} />
                          {!appContext.isGuardian && !appContext.isAthlete && <EditIcon onClick={toggleModal} style={{ fontSize: 20, color: 'white', marginLeft: 5 }} />}
                        </div>
                      : null}
                  </CardContent>
              </Card>
          </div>
      )
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { session: { userContext, appContext } } = state;
  const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
  return {
    appContext: appContext,
    currentTeam: currentTeam||{},
    currentUser: userContext.user,
    isZoomConnected: appContext.isZoomConnected,
    isZoomPaidPlan: appContext.isZoomPaidPlan,
    isUpliftConnected: appContext.isUpliftConnected
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Calendar));
