import { API } from 'aws-amplify';

export async function saveTeam(team){
    return API.post('programs', `/programs`, {body:team})
}
export async function getTeam(teamId){
    return API.get('programs', `/programs/${teamId}`)
}
export async function listCoaches(teamId){
    return API.get('programs', `/programs/${teamId}/coaches`)
}
