import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import moment from 'moment';
import { API } from "aws-amplify";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  Grid,
  TextField,
  Modal,
  Input,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import "./calendar.css";

import Snackbar from '@material-ui/core/Snackbar';

import CopyModal from './components/howToModal'
import PageWrapper from '../nav/pageWrapper';
import {analyticsService, currentTimestamp} from "../../services/analyticsService";
import CustomDraggableCalendar from '../../components/customDraggableCalendar';
import {getLocalizedString } from '../../utils/locale-utils';
import ModalContainer from '../../components/modalContainer';
import { TimeInput, InputField, DateInput } from 'maxone-react-ui';
import ThemeButton from '../../components/themeButton';
import { ROUTE_PATH } from "../../routeUtil";
import zoomWhiteLogo from "../../assets/images/zoom-white.png"
import zoomBlueLogo from "../../assets/images/zoom-blue.png"
import { MeetingIcon, ZoomIcon } from '../../components/icons';

const styles = theme => ({
    textField: {
        flexGrow: 1,
        width:'100%'
    },
    selectField: {
        marginTop: 5,
        flexGrow: 1,
        width: '100%',
        textAlign: 'left'
    },
    teamCard: {
        cursor: 'pointer',
        borderRadius: 0,
        height: 30,
        display: 'flex',
        backgroundColor: '#28272C'
    },
    card: {
        cursor: 'pointer',
        borderRadius: 0,
        height: 30,
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        paddingTop: 5,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        justifyContent: 'center'
    },
    cover: {
        width: 100,
        height: 30,
    },
    secondaryTextButton: {
        height: 40,
        width: 100,
        marginRight: '15px',
        backgroundColor: "#27AE60",
        color:"#ffffff",
        '&:disabled': {
            opacity:"0.3",
            color:"#ffffff"
        }
    },
    readOnlyInfo: {
        color:'#888888',
        alignSelf: 'flex-start'
    },
    zoomContainer: {
        marginBottom:"8px",
        cursor:"pointer",
        width: "100%",
        background: "#296ED2",
        color: "white",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        padding: "9px",
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "20px",
        '& svg':{
          width:"22px",
          height:"19px",
          marginRight:"10px"
        },
        "& img":{
          width:"66.75px",
          height:"15px",
          marginLeft: "auto"
        }
      },
      zoomEnabled:{
        background:"#F8F8F8",
        color:"#296ED2",
        fontWeight: "500",
        fontSize: "15px",
        "& svg":{
          color: "#000000"
        }
      },
      zoomDelIcnContainer:{
        width:"28px",
        height:"28px",
        background:"#EB0028",
        borderRadius:"5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "10px",
        "& svg":{
          color:"#ffffff",
          marginRight:"0"
        }
      },
      zoomIcon:{
        marginLeft: "auto",
        marginRight: "10px",
        color: "white",
        "& svg":{
          width: "22px",
          height: "18.78px"
        }
      },
      zoomTxt:{
        '& a':{
          color:"#296ED2"
        }
      },
      zoomConflictMsg:{
        color:"#EB0028",
        width:"80%",
        marginBottom: "5px"
      }
});

class DirectorCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: {},
            teams: [],
            events: [],
            message: "",
            open: false,
            schedule: {},
            schedules: [],
            anchorEl: null,
            isExpanded: false,
            isLoading: false,
            viewingEvents: [],
            selectedTeam: null,
            showingMore: false,
            snackbarOpen: false,
            showSchedules: true,
            showCopyModal: false,
            detailsExpanded: false,
            scheduleModalOpen: false,
            startDate: moment(),
            endDate: moment(),
            viewingScheduleIds:[],
            errors:{}
        };
    }

    getSchedules = async (parentId) => {
        return API.get("schedules", `/schedules/parent/${parentId}`);
    }

    getScheduleEvents(id) {
        return API.get("schedules", `/schedules/${id}/scheduleEvents`);
    }

    saveEvent(event){
        let { currentUser } = this.props;
        event.senderUserId = currentUser.id;
        if(event.id){
            return API.put("schedules", `/scheduleEvents/${event.id}`, {
                body: event
            });
        }
        return API.post("schedules", `/schedules/${event.scheduleId}/scheduleEvents`, {
            body: event
        });
    }

    getTeams(){
        const { currentTeam } = this.props;
        return API.get("programs", `/programs/organization/${currentTeam.organizationId}`);
    }

    createSchedule = (schedule) => {
        const { currentTeam } = this.props;
        return API.post("schedules", `/teams/${currentTeam.organizationId}/schedules`, {
            body: schedule
        });
    }

    saveUser = user => {
        return API.put("users", `/users/${user.id}`, {
            body: user
        });
    }

    componentDidMount = async () => {
        const { currentTeam, currentUser} = this.props;
        this.setState({
            isPageLoading: true,
            loadingMessage: 'grabbing your schedules...',
        })
        const schedules = await this.getSchedules(currentTeam.organizationId);
        if(schedules.length){
            this.setState({
                directorSchedule: schedules[0]
            })
        }
        else {
            const directorScheduleJSON = {
                color: "#28272C",
                name: "Director Schedule",
                parentId: currentTeam.organizationId
            }
            const directorSchedule = await this.createSchedule(directorScheduleJSON);
            this.setState({
                directorSchedule: directorSchedule
            })
        }

        const teams = await this.getTeams()
        this.setState({
            teams: teams,
            isPageLoading: false,
            currentTeam: this.props.currentTeam.id,
            viewingScheduleIds: _.uniq(currentUser.viewingScheduleIds || [])
        })
    }

    toggleScheduleExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    toggleModal = event => {
        const { directorSchedule } = this.state;

        if(this.state.showingMore && !event.id) 
            return this.setState({ showingMore: false })
        else if(event && event.id)
            this.setState({ showingMore: false })

        if(!event) {
            let newEvent = {
                scheduleId: directorSchedule.id,
            }
            this.setState({
                event: newEvent,
                open: !this.state.open
            })
        }
        else if(!event.id) {
            let startDate = moment.utc(event.start).local();
            let currentDate = moment.utc().local();
            startDate.set({
              hour:   currentDate.get('hour'),
              minute: currentDate.get('minute'),
              second: currentDate.get('second')
            });
            let endDate = moment.utc(startDate).local().add(30, 'minutes');
            this.setState({
                open: !this.state.open,
                startDate: startDate,
                endDate: endDate
            })
        }
        else {
            this.setState({
                event: event,
                open: !this.state.open,
                endDate: moment.utc(event.endDate).local(),
                startDate: moment.utc(event.startDate).local(),
            })
        }        
        //Reset error state
        this.setState({
            errors:{
                startDate:'',
                endDate:''
            }
        })
    }

    hideSnackBar(){
        setTimeout(() => this.setState({snackbarOpen:false}), 2000);
    }

    handleScheduleSelection = async (schedule, event) => {
        if(event){
            event.stopPropagation();
        }
        const { currentUser } = this.props;
        const { events, viewingEvents } = this.state;
        let user = currentUser.viewingScheduleIds ? currentUser : _.merge(currentUser, {viewingScheduleIds : []});
        user.viewingScheduleIds = _.uniq(user.viewingScheduleIds);
        schedule.viewing = schedule.viewing ? false : true;

        const scheduleIsLoaded =  _.find(events, event => event.scheduleId === schedule.id);
        const loadedButNotViewing = scheduleIsLoaded && !_.find(viewingEvents, event => event.scheduleId === schedule.id);
        const loadedAndViewing = scheduleIsLoaded && _.find(viewingEvents, event => event.scheduleId === schedule.id);

        if(loadedButNotViewing){
          user.viewingScheduleIds.push(schedule.id);
          const savedUser = await this.saveUser(user);
          let scheduleEvents = _.filter(events, event => event.scheduleId === schedule.id);
          if(!scheduleEvents.length) {
            this.setState({snackbarOpen: true, message: "Create some events for that schedule", viewingScheduleIds: user.viewingScheduleIds});
            return this.hideSnackBar();
          }
          this.setState({ viewingEvents: [...viewingEvents, ...scheduleEvents], viewingScheduleIds: user.viewingScheduleIds });
        }
        else if(loadedAndViewing){
          user.viewingScheduleIds = _.without(user.viewingScheduleIds, schedule.id);
          const savedUser = await this.saveUser(user);
          this.setState({ viewingEvents: _.filter(viewingEvents, event => event.scheduleId !== schedule.id), viewingScheduleIds: user.viewingScheduleIds });
        }
        else {
            let isDeselected = false;
            if (user.viewingScheduleIds.indexOf(schedule.id) == -1) {
                user.viewingScheduleIds.push(schedule.id);
            }
            else {
                user.viewingScheduleIds = _.without(user.viewingScheduleIds, schedule.id);
                isDeselected = true;
          }
          const savedUser = await this.saveUser(user);
          const scheduleEvents = await this.getScheduleEvents(schedule.id);
          if(!scheduleEvents.length && !isDeselected) {
            this.setState({snackbarOpen: true, message: "Create some events for that schedule",viewingScheduleIds: user.viewingScheduleIds});
            return this.hideSnackBar();
          }
          await Promise.all(scheduleEvents.map(event => {
            event.startDate = new Date(event.startDate);
            event.endDate = new Date(event.endDate);
            if(event.isTBD){
                event.startDate.setMinutes(event.startDate.getMinutes() + event.startDate.getTimezoneOffset());
                event.endDate.setMinutes(event.endDate.getMinutes() + event.endDate.getTimezoneOffset());
            }
            return _.extend(event, { color: schedule.color })
          }));
          this.setState({ viewingEvents: [...viewingEvents, ...scheduleEvents], events: [...events, ...scheduleEvents],viewingScheduleIds: user.viewingScheduleIds });
        }
      }

    handleEventPropertyChange = name => value => {
        let { event } = this.state;
        event[name] = value
        this.setState(event);
    };

    handleEventTimeChange = name => value => {
        if(name == "startDate"){
            let { startDate, endDate } = this.state;
            let duration = moment(value).diff(moment(startDate), 'milliseconds');
            endDate = moment(endDate).add(duration, "milliseconds");
            this.setState({ endDate: endDate});
        }
        this.setState({
          [name]: value
        })
      };

    resetModal = () => {
            this.setState({
            event: {},
            open: false,
            schedule: {},
            scheduleModalOpen: false
            })
    }

    handleSubmit = async () => {
        const { currentTeam, isZoomPaidPlan, enableFullScreenLoader} = this.props;
        const { events, viewingEvents, event, schedules, directorSchedule, startDate, endDate } = this.state;
        const newEndDate = endDate ? moment(endDate, "YYYY-MM-DDTHH:mm").utc() : 
                            event.id ? moment(event.endDate).utc() : moment(endDate, "YYYY-MM-DDTHH:mm").utc();
        const newStartDate = startDate ? moment(startDate, "YYYY-MM-DDTHH:mm").utc() : 
                            event.id ? moment(event.startDate).utc() : moment(startDate, "YYYY-MM-DDTHH:mm").utc();
        const timeRegex = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/g;
        let valid = true;
        let errors = {};
        if(newEndDate.isBefore(newStartDate)){
            errors.endDate = "your end date must be after your start date"
        }

        if(!errors.endDate && !errors.startDate && !isZoomPaidPlan && event.zoomMeeting && moment.duration(newEndDate.diff(newStartDate)).asMinutes() > 40){
            errors.endTime = "Zoom meeting cannot be greater than 40 minutes for a free account";
        }
      

        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
      
        if (!valid) {
            return this.setState({
                errors
            })
        }

        event.endDate = newEndDate
        event.startDate = newStartDate
        event.scheduleId = directorSchedule.id

        try {
            enableFullScreenLoader(true, "Saving....")
            let savedEvent = await this.saveEvent(event)
            if (!event.id) {
                analyticsService.trackEvent('Event Created', {
                    event_director: true,
                    event_dropped: false,
                    id: savedEvent.id,
                    schedule_id: savedEvent.scheduleId,
                    title: savedEvent.title,
                    start_date: savedEvent.startDate,
                    end_date: savedEvent.endDate,
                    description: savedEvent.description,
                });
                _.extend(savedEvent, {
                    color: directorSchedule.color,
                    startDate: new Date(savedEvent.startDate),
                    endDate: new Date(savedEvent.endDate) // big calendar requires date objects for rendering
                })
            }
            else {
                _.extend(savedEvent, {
                    color: directorSchedule.color,
                    startDate: new Date(savedEvent.startDate),
                    endDate: new Date(savedEvent.endDate) // big calendar requires date objects for rendering
                })
            }
            this.setState({
                event: {},
                open: false,
                message: "",
                errors: {
                    startDate:'',
                    endDate:'',
                },
                endDate:null,
                startDate:null,
                events: [...events, savedEvent],
                viewingEvents: [...viewingEvents, savedEvent],
            })
        } catch (e) {
            if(!!e && !!e.response && e.response.status == 409){
                this.setState({errors: {zoomConflict: true}});
            }
            console.log(e.message);
        }
        enableFullScreenLoader(false);
    }

    validateForm(){
        return this.state.event.title;
    }

    deleteEvent = event => {
        return API.del("schedules", `/scheduleEvents/${event.id}`);
    }

    handleDeleteEvent = async ee => {
        const { event } = this.state;
        const { enableFullScreenLoader } = this.props;
        enableFullScreenLoader(true, "Deleting...");
        try{
            const deletedEvent = await this.deleteEvent(event);
            this.setState({
                events: _.without(this.state.events, event),
                viewingEvents: _.without(this.state.viewingEvents, event),
            })
        }
        catch(e){
            console.log(e);
        }
        enableFullScreenLoader(false);
        this.resetModal();
    }

    deleteSchedule = async ee => {
        const { schedule } = this.state;
        if(schedule.id) {
            try{
                const deleteAction = await API.del("schedules", `/schedules/${schedule.id}`)
                this.setState({
                schedules: _.without(this.state.schedules, schedule),
                })
                this.resetModal();
            } catch(e){
                console.log('schedule delete err => ', e)
            }
        }
    }

    handleClick = async (event, team) => {
        const { schedules } = this.state;
        const alreadyLoaded = _.find(schedules, s => s.parentId == team.id)

        this.setState({
            selectedTeam: team == this.state.selectedTeam ? null : team
        })

        if(!schedules.length || !alreadyLoaded){
            this.setState({ isLoading: true })
            const schedules = await this.getSchedules(team.id);
            this.setState({
                isLoading: false,
                schedules: schedules
            })
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    toggleShowSchedules = async () => {
        const { teams } = this.state;
        if(!teams.length){
            const teams = await this.getTeams()
            this.setState({
                teams: teams,
                showSchedules: !this.state.showSchedules
            })
        }
        else {
            this.setState({ showSchedules: !this.state.showSchedules });
        }
    }

    toggleShowCopyModal = () => {
        this.setState({ showCopyModal: !this.state.showCopyModal })
    }

    onEventDrop = async ({ event, start, end }) => {
        const { events, viewingEvents } = this.state
        let eventData = event
        eventData.id = null
        eventData.startDate = moment(start, "YYYY-MM-DDTHH:mm").utc();
        eventData.endDate = moment(end, "YYYY-MM-DDTHH:mm").utc();
        const copiedEvent = await this.saveEvent(eventData)

        analyticsService.trackEvent('Event Created', {
            event_director: true,
            event_dropped: true,
            id: copiedEvent.id,
            schedule_id: copiedEvent.scheduleId,
            title: copiedEvent.title,
            start_date: copiedEvent.startDate,
            end_date: copiedEvent.endDate,
            description: copiedEvent.description,
        });

        const scheduleEvents = await this.getScheduleEvents(event.scheduleId);
        await Promise.all(scheduleEvents.map(event => {
          event.startDate = new Date(event.startDate);
          event.endDate = new Date(event.endDate);
          return _.extend(event, { color: eventData.color })
        }));
        this.setState({
          viewingEvents: scheduleEvents,
          events: events.concat(scheduleEvents),
        })
      }
    
      onEventResize({event, start, end}){
        console.log('resizing')
      }

    navigateToLiveTraining = () => {
        const { history } = this.props;
        history.push(ROUTE_PATH.WORKOUT_SCHEDULES);
    }

    toggleVideoMeeting = (currentStatus) => {
        this.handleEventPropertyChange("zoomMeeting")(!currentStatus)
    }
    

    render() {
        const { viewingEvents, snackbarOpen, open, schedules, anchorEl, event, schedule, isLoading, showSchedules, teams, directorSchedule, selectedTeam, errors, message, viewingScheduleIds, 
            loadingMessage, isPageLoading, startDate, endDate } = this.state;
        let { isZoomConnected } = this.props;
        const { classes } = this.props;
        const itemHeight = 48;
        const reminderOptions = [{ id: '0', label: 'No reminder' }, { id: 10, label: '10 minutes before' }, { id: 15, label: '15 minutes before' }, { id: 30, label: '30 minutes before' }, { id: 60, label: '1 hour before' }, { id: 120, label: '2 hours before' }, { id: 1440, label: '1 day before' }]
        const visibilityOptions = [{ id: 1, label: 'Private' }, { id: 2, label: 'Coaches' }, { id: 3, label: 'Program' }, { id: 4, label: 'Everyone' }];
        const disableAllInputs = event.isReadOnly;
        const hasZoomMeeting = !!event.zoomMeeting && !!event.zoomMeetingUrl;
        return (
            <PageWrapper title={"Master "+getLocalizedString("CALENDAR")} {...this.props} isPageLoading={isPageLoading} loadingMessage={loadingMessage} isInBeta={true}>
            <div className={"calendarOuterContainer"}>
                <CopyModal open={this.state.showCopyModal} toggleModal={this.toggleShowCopyModal} />
                <div>
                    <Snackbar
                        open={snackbarOpen}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                            }}
                        message={<span id="message-id">{this.state.message}</span>}
                        action={[
                            <IconButton key="close" className={classes.close} onClick={() => this.setState({snackbarOpen:false, message:""})}>
                            <CloseIcon className={classes.icon}/>
                            </IconButton>
                        ]}
                    />
                    <div className={"pageTitleContainer"}>                  
                        <div className={"titleButtonContainer"}>
                            <Button
                                title="How To Copy"
                                onClick={this.toggleShowCopyModal}
                                color="primary"
                                className={"openButton"}
                                variant="contained"
                            >
                                Copying
                            </Button>
                            <Button
                            title="View Calendars"
                            onClick={this.toggleShowSchedules}
                            aria-label="add"
                            color="primary"
                            className={"openButton"}
                            variant="contained">
                                {showSchedules ? "Hide" : "Show"} Schedules
                            </Button>
                            <Button
                                mini
                                variant="fab"
                                color="primary"
                                aria-label="add"
                                title="Add Schedules"
                                className={"openButton"}
                                // className={classes.plusButton}
                                onClick={() => this.toggleModal(null)}
                            >
                                <AddIcon className={"icon"}  />
                            </Button>
                        </div>
                        </div>
                    <div className={"calendarContainer"}>
                    {
                        showSchedules
                    ?
                        <div className={"programContainer"}>
                            <Grid
                                className={"programListContainer"}
                                container
                                spacing={8}
                            >
                                {teams.length ?
                                    <div className={"teamsContainer"}>
                                        <Grid item xs={12}>
                                            <Card
                                                style={{ marginTop: 5, backgroundColor: directorSchedule.color ? directorSchedule.color : '#28272C' }}
                                                onClick={() => this.handleScheduleSelection(directorSchedule)}
                                                className={!!_.find(viewingEvents, e => e.scheduleId === directorSchedule.id) ? classNames("selectedCard", classes.card) : classes.card}
                                            >
                                                <div className={classes.content}>
                                                    <Typography variant="subheading" style={{color: 'white' }}>
                                                        Director Schedule
                                                    </Typography>
                                                    {!!_.find(viewingEvents, e => e.scheduleId === directorSchedule.id) ? <VisibilityIcon  style={{ color: 'white', marginLeft: 5 }} /> : null}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 10 }} onClick={() => this.setState({ viewingTeams: !this.state.viewingTeams })}>
                                            <p style={{ marginBottom: 0 }}>Teams</p>
                                            {this.state.viewingTeams ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </div>
                                        <div style={!!this.state.viewingTeams ? {height: 524, overflowY: 'auto'} : {}}>
                                        {!!this.state.viewingTeams && teams.map((team, index) => (
                                            <Grid
                                                item
                                                xs={12}
                                                key={team.id}>
                                                    <TeamCard
                                                        team={team}
                                                        classes={classes}
                                                        isLoading={isLoading}
                                                        viewingEvents={viewingEvents}
                                                        isSelected={selectedTeam == team}
                                                        click={(e) => this.handleClick(e, team)}
                                                        schedules={_.filter(schedules, s => s.parentId == team.id)}
                                                        onClickSchedule={(schedule, event) => this.handleScheduleSelection(schedule, event)}
                                                        viewingScheduleIds={viewingScheduleIds}
                                                    />
                                            </Grid>
                                        ))}
                                        </div>
                                    </div>
                                : null}
                            </Grid>
                        </div>
                    :
                        null
                    }
                        <CustomDraggableCalendar
                            step={60}
                            resizable
                            selectable
                            popup={true}
                            showMultiDayTimes
                            drilldownView='day'
                            endAccessor='endDate'
                            className={'mozilla'}
                            events={viewingEvents}
                            startAccessor='startDate'
                            onSelectSlot={this.toggleModal}
                            onSelectEvent={this.toggleModal}
                            onEventDrop={this.onEventDrop}
                            onEventResize={this.onEventResize}
                            views={['month']}
                            eventPropGetter={(event) => {
                                let style = { backgroundColor: event.color, fontSize: 10 };
                                return { style };
                            }}
                            style={showSchedules ? { width: '80%', minHeight: 600 } : { width: '100%', minHeight: 600 }}
                            titleAccessor={(event) => { return <div className="event-title d-flex align-items-center justify-content-between"><div className="text-truncate">{event.isTBD? "TBD:": moment.utc(event.startDate).local().format('h:mma')} {event.title || event.name}</div>{event.zoomMeeting && <div><ZoomIcon /></div>}</div> }}
                            messages={{
                                showMore: total => (
                                <div
                                    onClick={() => this.setState({ showingMore: true })}
                                    style={{ cursor: 'pointer' }}
                                    onMouseOver={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    }}
                                >{`+${total} mores`}
                                </div>
                                ),
                            }}
                        />
                            <ModalContainer
                                open={open}
                                onClose={()=>this.toggleModal(null)}
                                heading={event.title ? event.title : "New Director Event"}
                                useDefaultLeftBtn={true}
                                defaultLeftBtnProps={{
                                    onClick: ()=>this.toggleModal(null)
                                }}
                                disableEnforceFocus={true}                         
                                rightBtn={
                                    <div className="d-flex">
                                        {!event.isReadOnly && event.id  &&
                                            <ThemeButton
                                                width={"112px"}
                                                color={"#1354F9"}
                                                height={"44px"}
                                                theme="small"
                                                text="Delete Event"
                                                onClick={this.handleDeleteEvent}
                                            />}
                                        {!event.isReadOnly &&
                                            <ThemeButton
                                                width={"96px"}
                                                color={"#27AE60"}
                                                height={"44px"}
                                                theme="small"
                                                text="Save"
                                                onClick={this.handleSubmit}
                                                disabled={!this.validateForm()}
                                            />
                                        }
                                    </div>
                                }>
                                {event.isReadOnly ? <div className={classes.readOnlyInfo}>Read Only</div> : null}
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <InputField
                                            required
                                            id="title"
                                            disabled={disableAllInputs}
                                            label="Title"
                                            value={event.title || ""}
                                            onChange={this.handleEventPropertyChange("title")}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            id="startDate"
                                            handleChange={this.handleEventTimeChange("startDate")}
                                            date={startDate}
                                            label={'Start Date'}
                                            disabled={disableAllInputs}
                                            helperText={errors.startDate}
                                            error={!!errors.startDate}
                                            dateFormat={'MM/DD/YYYY'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeInput
                                            id="startTime"
                                            handleChange={this.handleEventTimeChange("startDate")}
                                            date={event.isTBD ? null : startDate}
                                            label={'Start Time'}
                                            disabled={disableAllInputs}
                                            inputlabel={event.isTBD ? "TBD" : null}
                                            helperText={errors.startTime}
                                            error={!!errors.startTime}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            id="endDate"
                                            handleChange={this.handleEventTimeChange("endDate")}
                                            date={endDate}
                                            label={'End Date'}
                                            disabled={disableAllInputs}
                                            helperText={errors.endDate}
                                            error={!!errors.endDate}
                                            dateFormat={'MM/DD/YYYY'}
                                        />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeInput
                                            id="endTime"
                                            handleChange={this.handleEventTimeChange("endDate")}
                                            date={event.isTBD ? null : endDate}
                                            label={'End Time'}
                                            disabled={disableAllInputs}
                                            inputlabel={event.isTBD ? "TBD" : null}
                                            helperText={errors.endTime}
                                            error={!!errors.endTime}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <InputField
                                            id="location"
                                            disabled={disableAllInputs}
                                            label="Location"
                                            value={event.location || ""}
                                            onChange={this.handleEventPropertyChange("location")}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputField
                                            id="description"
                                            disabled={disableAllInputs}
                                            label="Description"
                                            value={event.description || ""}
                                            onChange={this.handleEventPropertyChange("description")}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isZoomConnected && <div className={classNames(classes.zoomContainer, { [classes.zoomEnabled]: event.zoomMeeting })} onClick={hasZoomMeeting ? null : () => this.toggleVideoMeeting(event.zoomMeeting)}>
                                            <MeetingIcon />
                                            <div className={classes.zoomTxt}>
                                                {hasZoomMeeting ? <a target="_blank" href={event.zoomMeetingUrl}>Zoom link</a> :
                                                    ((event.zoomMeeting ? "Remove" : "Add") + " Zoom Video Call")}
                                            </div>
                                            <img className={classes.logoImage} src={event.zoomMeeting ? zoomBlueLogo : zoomWhiteLogo} />
                                            {event.zoomMeeting && <div onClick={() => this.toggleVideoMeeting(event.zoomMeeting)} className={classes.zoomDelIcnContainer}><DeleteIcon className={classes.zoomDelIcon} /></div>}
                                        </div>}
                                    </Grid>
                                </Grid>
                                {
                                    errors.zoomConflict && <div className={classes.zoomConflictMsg}>SCHEDULING CONFLICT! The connected Zoom account shows
                                        a conflict with the time you are attempting to schedule. Please
                                        choose another time to continue.
                                    </div>
                                }
                            </ModalContainer>
                    </div>
                </div>
            </div>
            </PageWrapper>
        );
    }
}

class TeamCard extends Component {
    constructor(props) {
        super(props);
        this.state = { isExpanded: false };
    }

    handleClick = (e) => {
        this.props.click(e)
    }

    render() {
        const { team, classes, schedules, viewingEvents, isSelected, isLoading, viewingScheduleIds } = this.props;

        return(
            <div className={"teamCardContainer"}>
                <div onClick={(e) => this.handleClick(e)}>
                    <Card className={classes.teamCard}>
                        <CardContent className={classes.content}>
                            <Typography variant="subheading" style={{ color: 'white' }}>
                                {`${team.name} ${team.sport}`}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <Grid container spacing={0}>
                    {
                        isSelected && !isLoading
                    ?
                        schedules.length ? schedules.map((schedule, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                style={{marginTop: 5}}
                            >
                                    <ScheduleCard
                                        classes={classes}
                                        schedule={schedule}
                                        onClick={(event) => this.props.onClickSchedule(schedule,event)}
                                        isSelected={viewingScheduleIds && viewingScheduleIds.indexOf(schedule.id)>-1}
                                    />
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <p>no schedules</p>
                        </Grid>
                    :
                        isSelected && isLoading
                    ?
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    :
                        null
                    }
                </Grid>
            </div>
        )
    }
}

class ScheduleCard extends Component {
    render(){
        const { classes, workout, onClick, selectedWorkout, isSelected, schedule = {} } = this.props;

        return(
            <div onClick={event => onClick(event)}>
                <Card className={isSelected ? classNames("selectedCard", classes.card) : classes.card} style={{ backgroundColor: schedule.color }}>
                    <div className={classes.content}>
                        <Typography variant="subheading" style={{color: 'white' }}>
                            {schedule ? schedule.name : ""}
                        </Typography>
                        {isSelected ? <VisibilityIcon  style={{ color: 'white', marginLeft: 5 }} /> : null}
                    </div>
                </Card>
            </div>
        )
    }
}

DirectorCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
      currentTeam: currentTeam||{},
      currentUser: userContext.user,
      isZoomConnected: appContext.isZoomConnected,
      isZoomPaidPlan: appContext.isZoomPaidPlan,
    };
  }

export default withStyles(styles)(connect(mapStateToProps)(DirectorCalendar));
