import React, { Component } from 'react';
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FilterList from '@material-ui/icons/FilterList'
import InfiniteScroll from 'react-infinite-scroller';
let styles = {
    dropDownContainer: {
        height: "48px",
        position: 'relative',
        width: "100%"
    },
    outsideClickDiv: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%"
    },
    searchBox: {
        border: "1px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px",
        height: "100%",
        width: "100%",
        paddingLeft: "15px",
        background: "#ffffff",
        display: "flex",
        alignItems: "center",
        "& input": {
            border: "none",
            flex: 1,
            height: "100%",
            "&:focus": {
                outline: "none"
            }
        },
        "& svg": {
            fill: "rgba(11,11,11,0.3)"
        }
    },
    icon: {
        top: -3
    },
    dropDown: {
        position: 'absolute',
        top: '48px',
        left: '0px',
        width: "100%",
        maxHeight: "362px",
        overflow: "scroll",
        background: "#FFFFFF",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
        borderRadius: "0px 0px 4px 4px",
        zIndex: 5,
        overscrollBehavior: "contain"
    },
    dropDownItem: {
        cursor: "pointer",
        color: "#222127",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        '&:hover': {
            background: "#DDE2E5"
        }
    },
    dropDownInnerContainer: {
        padding: "10px 0px",
        margin: "0 20px",
        borderBottom: "1px solid #DDE2E5",
    },
    filterIconContainer: {
        minWidth: "48px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#DDE2E5",
        borderRadius: "0px 4px 4px 0px",
        "& svg": {
            width: "28px",
            height: "28px",
            fill: "white"
        }
    },
    notAllowed: {
        cursor: 'not-allowed'
    }
}

class DropDownWithSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownEnabled: false,
            searchTerm: ""
        }
    }
    componentDidMount() {
        let { menuItemData, value } = this.props;
        let selectedItem = (menuItemData || []).find(item => item.value == value) || {};
        this.setState({ searchTerm: selectedItem.displayValue });
    }

    componentDidUpdate(prevProps) {
        let { value, menuItemData } = this.props;
        if (prevProps.value != value) {
            let selectedItem = (menuItemData || []).find(item => item.value == value) || {};
            this.setState({ searchTerm: selectedItem.displayValue, selectOptionLabel: selectedItem.displayValue });
        }
    }

    handleSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    toggleDropDown = (value) => {
        let { dropDownEnabled, selectOptionLabel } = this.state;
        let newValue = value == false ? value : value || !dropDownEnabled;
        let searchTerm = "";
        if (newValue) {
            searchTerm = "";
        }
        else {
            searchTerm = selectOptionLabel;
        }
        this.setState({ dropDownEnabled: newValue, searchTerm })
    }

    render() {
        let { customClass, placeholder, classes, menuItemData, onChange, disabled, enableInfiniteLoading, hasMore, loadMore, dropdownHeight, page = 0 } = this.props;
        let { dropDownEnabled, searchTerm } = this.state;
        let filteredData = menuItemData.filter(item => searchTerm ? item.displayValue.toLowerCase().includes(searchTerm.toLowerCase()) : true);
        return (
            <div className={classes.dropDownContainer}>
                <div className={classnames({ "d-none": !dropDownEnabled }, { [classes.outsideClickDiv]: dropDownEnabled })} onWheel={() => this.toggleDropDown(false)} onClick={() => disabled ? null : this.toggleDropDown(false)}></div>
                <div className={classnames(classes.searchBox, customClass)}>
                    <input
                        disabled={disabled}
                        onClick={this.toggleDropDown}
                        placeholder={placeholder}
                        value={searchTerm || ""}
                        className={disabled ? `${classes.search, classes.notAllowed}` : `${classes.search}`}
                        onChange={(e) => this.handleSearch(e.target.value)} />
                    <div className={classes.filterIconContainer} onClick={() => disabled ? null : this.toggleDropDown()}>
                        {!dropDownEnabled ? <FilterList className={'icon'} /> : <CancelIcon className={'icon'} />}
                    </div>
                </div>
                {dropDownEnabled &&
                    <div className={classes.dropDown} style={{ maxHeight: dropdownHeight }} ref={(ref) => this.scrollParentRef = ref}>
                        <InfiniteScroll
                            pageStart={page}
                            loadMore={loadMore || (() => { })}
                            hasMore={enableInfiniteLoading && hasMore}
                            useWindow={false}
                            getScrollParent={() => this.scrollParentRef}
                            loader={<div className={classes.dropDownInnerContainer}>Loading ...</div>}
                        >
                            {filteredData.map(data => <div key={data.value} className={classes.dropDownItem}>
                                <div className={classes.dropDownInnerContainer} onClick={() => {
                                    this.toggleDropDown(false);
                                    onChange(data.value)
                                }}>
                                    {data.displayValue}
                                </div>
                            </div>
                            )}
                        </InfiniteScroll>
                    </div>
                }
            </div>
        )
    }
}

export default withStyles(styles)(DropDownWithSearch)