import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API, Storage } from "aws-amplify";
import _ from "lodash"
import shortid from "shortid";
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';
import urlParser from "../../js-video-url-parser";
import ReactPlayer from 'react-player';
import WistiaVideo from "../../containers/video/wistia";
import classNames from 'classnames';
import PDF from 'react-pdf-js-infinite';
import { getTagsByParentId } from '../../services/taggingService'
import { indexClient } from '../../clients';
import {
    Tabs,
    Tab,
    Button,
    Grid,
    Paper,
    TextField,
    Hidden,
    Modal,
    Typography,
    Card,
    CardMedia,
    Collapse,
    IconButton,
    withStyles,
    Input,
    MenuItem,
    Select,
    CircularProgress,
    InputAdornment
} from '@material-ui/core';
import { getThemeByTenant } from '../../themes';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import LabelIcon from '@material-ui/icons/Label';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import "./activities.css";

// SLICK CAROUSEL //
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// ////////////// //

import NewWorkoutActivityModal from '../../components/modals/newWorkoutActivityModal'

import {
    saveActivity,
    getFileTypeSupportStatus,
    uploadActivityAttachment,
    correctLegacyActivityData
} from '../../utils/activityUtils'
import { getLocalizedString } from '../../utils/locale-utils';

import { FileType, fetchWistiaVideoThumbnail, fetchVimeoVideoThumbnail, isVideo} from '../../utils/commonUtil'
import { getActivitiesByOrganisationId } from '../../services/activitiesService'
import NewActivityModule from '../newActivityModule';
import VideoPlayer from './videoPlayer';

import { Editor, EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML, RichUtils } from 'draft-js';
import PageWrapper from '../nav/pageWrapper';
import LegacyModalContainer from '../../components/modalContainer/legacyModalContainer';
import { getLinkDecorator } from '../../components/draftjsEntities';
const styles = theme => ({
    plusButton: {
        backgroundColor: "#1354F9",
        color: 'white'
    },
    primaryTextButton: {
        backgroundColor: "#1354F9",
    },
    textButton: {
        color: 'white',
        height: '40px',
        marginRight: '3px',
        backgroundColor: "#1354F9",
    },
    button: {
        top: 80,
        right: 20,
        width: 50,
        position: 'absolute',
    },
    pageTitle: {
        marginLeft: 60,
        textAlign: 'left',
    },
    activitiesContainer: {
        paddingLeft: 40,
        paddingRight: 40
    },
    tabIndicator:{
        'background-color':"#1354F9"
    }
});

function NextArrow(props) {
    const { className, style, onClick } = props
    return (
        <IconButton
            style={{ ...style }}
            onClick={onClick}
        >
            <ChevronRightIcon style={{ fontSize: '2em' }} />
        </IconButton>
    );
};

function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <IconButton
            style={{ ...style }}
            onClick={onClick}
        >
            <ChevronLeftIcon style={{ fontSize: '2em' }} />
        </IconButton>
    );
};

class DirectorListActivities extends NewActivityModule {
    constructor(props) {
        super(props);

        this.state = {
            tag: {},
            value: (props.libraries[0]||{}).value,
            files: [],
            teams: [],
            score: null,
            open: false,
            activity: {},
            seconds: null,
            minutes: null,
            appLogo: null,
            isLoading: false,
            isSliding: false,
            activityTags: [],
            originalTeams: [],
            category: 'drill',
            selectedTeams: [],
            loadingTags: false,
            searchString: null,
            overlayMessage: '',
            activitiesToAdd: [],
            showActivities: true,
            activeLabelFilter: 'all',
            uploadingVideoFile: false,
            videoFileUploadProgress: 0,
            thumbnailUploadProgress: 0,
            isActivityModalOpen: false,
            allActivitiesSelected: false,
            displaySuccessOverlay: false,
            uploadingThumbnailFile: false,
            filterableTags: new Set(),
            taggedGroupings:{}
        };
    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array)
        }
    }

    componentDidMount = async () => {
        this.setState({
            isLoading: true,
            loadingMessage: 'grabbing your activities...'
        })
        const appLogo = getThemeByTenant().image.logo;
        this.setState({ appLogo })
        this.refreshActivities();
    }

    getActivities() {
        const { currentTeam } = this.props;
        return API.get("activities", `/organizations/${currentTeam.organizationId}/activities`);
    }

    getTeams() {
        const { currentTeam } = this.props;
        return API.get("programs", `/programs/organization/${currentTeam.organizationId}`);
    }

    deleteActivity = activityId => {
        return API.del("activities", `/activities/${activityId}`)
    }

    handleDelete = async activity => {
        const deletedActivity = await this.deleteActivity(activity.id);
        this.setState({ isLoading: true, loadingMessage: 'refreshing your activities...' })
        this.refreshActivities()
    }

    handleOpen = activity => {
        this.setState({
            open: true,
            activity: activity
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            activity: {},
            page: 1
        });
    };

    close = () => {
        this.setState({
            files: [],
            open: false,
            activity: {},
            isActivityModalOpen: false
        })
    }

    toggleActivityProgramSelector = async bool => {
        if (bool) {
            const teams = await this.getTeams();
            await Promise.all(teams.map(async team => {
                if (team.logo) {
                    if (team.logo.includes("https://")) {
                        team.logo = team.logo
                    } else {
                        var logo = `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${team.legacyId ? team.legacyId : team.id}/${team.logo}`
                        team.logo = logo
                    }
                }
            }))
            this.setState({
                teams: teams,
                originalTeams: teams,
                showActivities: !this.state.showActivities
            })
        }
        else {
            this.setState({ showActivities: !this.state.showActivities })
        }
    }

    saveActivity = activity => {
        if (activity.id) {
            return API.put("activities", `/activities/${activity.id}`, {
                body: activity
            });
        }
        else if (!isActivityModalOpen) {
            this.setState({ loadingTags: true });
            getTagsByParentId(activity.id).then((tags) => {
                activityTags = tags.map(tag => {
                    return { name: tag.label }
                });
                this.setState({ activityTags });
            }).finally(() => {
                this.setState({ loadingTags: false });
            });
        }
        this.setState({
            activityTags,
            activity: activity || {},
            uploadingVideoFile: false,
            displaySuccessOverlay: false,
            uploadingThumbnailFile: null,
            isActivityModalOpen: addingAnother || !isActivityModalOpen
        })
    }

    refreshActivities = async () => {
        const { category } = this.state;
        const { appContext, currentTeam } = this.props;
        const activities = await getActivitiesByOrganisationId(currentTeam.organizationId);

        await this.asyncForEach(activities, async (activity) => {
            correctLegacyActivityData(activity)
        })

        let { filterableTags, taggedGroupings } = this.getActivityTaggings(activities);
        this.setState({
            activity: {},
            isLoading: false,
            category: category,
            loadingMessage: null,
            activities: activities,
            filterableTags,
            taggedGroupings
        })
    }

    handleTagChange = name => event => {
        const { tag } = this.state;
        tag[name] = event.target.value;
        this.setState({ tag: tag });
    }

    getActivityTaggings = (activities) => {
        let { libraries } = this.props;
        let filterableTags = new Set(['untagged']);
        let taggedGroupings = {};
        libraries.forEach(item => {
            taggedGroupings[item.value]={'untagged': []};
        });
        activities.map(async activity => {
            const taggings = _.values(activity.taggings);
            if (taggings.length === 0) {
                if(!taggedGroupings[activity.category].untagged){
                    taggedGroupings[activity.category].untagged = [];
                }
                taggedGroupings[activity.category].untagged.push(activity);
            }
            taggings.map(tag => {
                if (!_.isEmpty(tag)) {
                    filterableTags.add(tag)
                    if(!((taggedGroupings[activity.category])[tag])){
                        taggedGroupings[activity.category][tag] = [];
                    }
                    taggedGroupings[activity.category][tag].push(activity);
                }
                else {
                    if(!taggedGroupings[activity.category].untagged){
                        taggedGroupings[activity.category].untagged = [];
                    }
                    taggedGroupings[activity.category].untagged.push(activity);
                }
            });
        })
        return {
            filterableTags,
            taggedGroupings
        }
    }

    handleTabValueChange = (product, value) => {
        const { activities } = this.state;
        const category = value;
        this.setState({
            value: value,
            category: category
        });
    };

    searchActivities = event => {
        this.setState({
            searchString: event.target.value,
        }, () => this.computeTaggedData(true))
    }

    computeTaggedData = (isSearch) => {
        let { searchString, activeLabelFilter, activities,
            filterableTags: existingTags  } = this.state;
        //TODO: Can be improved
        if(activeLabelFilter && activeLabelFilter != 'all'){
            activities = (activities||[]).filter(item => (item.taggings||[]).some(tag=> tag == activeLabelFilter));
        }
        if(!!searchString){
            activities = (activities||[]).filter(a => a.name.toLowerCase().includes(searchString.toLowerCase()));
        }
        let { filterableTags, taggedGroupings } = this.getActivityTaggings(activities);

        this.setState({filterableTags: (isSearch? filterableTags:existingTags), taggedGroupings});
    }

    searchTeams = (event) => {
        this.setState({
            searchString: event.target.value,
            teams: event.target.value? _.filter(originalTeams, a => a.customName ? a.customName.toLowerCase().includes(event.target.value.toLowerCase()) : `${a.name} ${a.sport}`.toLowerCase().includes(event.target.value.toLowerCase())):originalTeams
        })
    }

    activityIncluded = activity => {
        return _.find(this.state.activitiesToAdd, a => a.id === activity.id);
    }

    addActivityToList = activity => {
        let included = _.find(this.state.activitiesToAdd, a => a.id === activity.id);

        if (included)
            this.setState({ activitiesToAdd: _.without(this.state.activitiesToAdd, activity) });
        else
            this.setState({ activitiesToAdd: [...this.state.activitiesToAdd, activity] });
    }

    teamIncluded = team => {
        return _.find(this.state.selectedTeams, a => a.id === team.id);
    }

    addTeamToList = team => {
        let included = _.find(this.state.selectedTeams, t => t.id === team.id);

        if (!included) {
            this.setState({ selectedTeams: [...this.state.selectedTeams, team] });
            const sharedActivities = this.shareActivities(team);
        }
    }

    batchShareActivities = async (list, team, index = 0) => {
        const { currentTeam, currentUser } = this.props;
        await Promise.all(
            list[index].map(async activity => {
                let teamActivityJSON = activity;
                let formattedTaggingData = (_.uniq(teamActivityJSON.taggings||[])).map(tag =>  { return {name: tag}});
                teamActivityJSON.isNewActivity = true;
                teamActivityJSON.id = shortid.generate();
                teamActivityJSON.parentId = team.id;
                const newTeamActivity = await saveActivity(teamActivityJSON, currentUser, team, formattedTaggingData);
            })
        );
        if (list[index + 1]) {
            return this.batchShareActivities(list, team, index + 1);
        }
    };

    shareActivities = async (team) => {
        const { activitiesToAdd } = this.state;
        const chunk = _.chunk(activitiesToAdd, 25);
        await this.batchShareActivities(chunk, team);

        try {
            await indexClient.reindexActivitiesByTeamId(team.id);
        } catch (e) {
            console.log(`Error reindexing activities by team ID ${team.id}`);
        }

        // await Promise.all(activitiesToAdd.map(async activity => {
        //     let teamActivityJSON = activity;
        //     let formattedTaggingData = (_.uniq(teamActivityJSON.taggings||[])).map(tag =>  { return {name: tag}});
        //     teamActivityJSON.isNewActivity = true;
        //     teamActivityJSON.id = shortid.generate();
        //     teamActivityJSON.parentId = team.id;
        //     const newTeamActivity = await saveActivity(teamActivityJSON, currentUser, team, formattedTaggingData);
        // }))


        this.setState({
            isLoading: false,
        })
    }

    filterByLabel = event => {
        let newFilterLabel = event.target.value;
        this.setState({
            activeLabelFilter: newFilterLabel,
        }, this.computeTaggedData)
    }

    handleSwipe = () => {
        this.setState({ isSliding: true });
    }

    handleAfterChange = () => {
        this.setState({ isSliding: false })
    }

    selectAll = () => {
        const { allActivitiesSelected, activities, category } = this.state;
        if (allActivitiesSelected) this.setState({ activitiesToAdd: [], allActivitiesSelected: false })
        else this.setState({ activitiesToAdd: _.filter(activities, a => a.category == category), allActivitiesSelected: true })
    }

    /* ######################################################* Activity Edit Modal Methods START ######################################################*/

    //TODO: Move to activityModule
    handleActivitySave = async (addingAnother) => {
        const { currentTeam, currentUser } = this.props;
        const { activity, activityTags, category } = this.state;
        let clonedActivity = {...activity};
        let isNewActivity = clonedActivity.isNewActivity;
        this.setState({ displaySuccessOverlay: true, overlayMessage: (isNewActivity ? "Saving Activity" : "Updating Activity") })
        clonedActivity.id = await saveActivity(clonedActivity, currentUser, currentTeam, _.uniq(activityTags), true, this.uploadingFile);
        let newActivities = this.state.activities || [];
        if (isNewActivity) {
            clonedActivity.isNewActivity = false;
            newActivities = newActivities.concat(clonedActivity);
        }
        else {
            let existingActivityIndex = newActivities.findIndex(element => element.id === clonedActivity.id);
            newActivities[existingActivityIndex] = clonedActivity;
        }
        let { filterableTags,
            taggedGroupings } = this.getActivityTaggings(newActivities);

        this.setState({
            activities: newActivities,
            filterableTags,
            taggedGroupings
        });
        this.setState({ overlayMessage: isNewActivity ? "Activity Created" : "Activity Updated" })
        setTimeout(() => {
            this.toggleActivityModal(null, addingAnother);
        }, 2000);
    }

    /* ######################################################* Activity Edit Modal Methods END ######################################################*/

    render() {
        const { classes, appContext, currentTeam, tenant, libraries } = this.props;
        const {
            teams,
            value,
            category,
            isLoading,
            loadingTags,
            activityTags,
            searchString,
            open = false,
            originalTeams,
            activity = {},
            overlayMessage,
            loadingMessage,
            activities = [],
            invalidVideoUrl,
            activeLabelFilter,
            isSliding = false,
            uploadingVideoFile,
            filterableTags,
            isActivityModalOpen,
            activitiesToAdd = [],
            showActivities = true,
            allActivitiesSelected,
            displaySuccessOverlay,
            uploadingThumbnailFile,
            videoFileUploadProgress,
            thumbnailUploadProgress,
            taggedGroupings
        } = this.state;

        const groupings = taggedGroupings[category] || {};

        const carouselSettings = {
            arrows: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 3,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            onSwipe: this.handleSwipe,
            afterChange: this.handleAfterChange,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        };

        return (
            <PageWrapper title={getLocalizedString("CONTENT_SHARING")} isPageLoading={isLoading} loadingMessage={loadingMessage}  {...this.props}>
                <div className={"activityListOuterContainer"}>
                        <React.Fragment>
                            <div className={"pageTitleContainer"}>
                                <div className={"titleButtonContainer"}>
                                    {showActivities ?
                                        <Button
                                            aria-label="add"
                                            variant="contained"
                                            title="Select Teams"
                                            className={classes.textButton}
                                            onClick={() => this.selectAll()}
                                        >
                                            {allActivitiesSelected ? 'Unselect All' : 'Select All'}
                                        </Button>
                                        :
                                        null
                                    }
                                    {activitiesToAdd.length && showActivities ?
                                        <Button
                                            aria-label="add"
                                            variant="contained"
                                            title="Select Teams"
                                            className={classes.textButton}
                                            onClick={() => this.toggleActivityProgramSelector(true)}
                                        >
                                            Share {activitiesToAdd.length} {activitiesToAdd.length == 1 ? "Activity" : "Activities"}
                                        </Button>
                                        : activitiesToAdd.length && !showActivities ?
                                            <Button
                                                aria-label="add"
                                                variant="contained"
                                                title="Select Teams"
                                                className={classes.textButton}
                                                onClick={() => this.setState({ showActivities: true, selectedTeams: [], allActivitiesSelected: false, selectedActivities: false, activitiesToAdd: false })}
                                            >
                                                Back
                        </Button>
                                            :
                                            null
                                    }
                                    <Button
                                        mini
                                        variant="fab"
                                        aria-label="add"
                                        title="Add Activity"
                                        className={classes.plusButton}
                                        onClick={() => this.toggleActivityModal()}
                                    >
                                        <AddIcon className={"icon"} />
                                    </Button>
                                </div>
                            </div>
                            <div className={"tabSeachContainer"}>
                                {showActivities ? <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleTabValueChange}
                                    classes={{
                                        indicator:classes.tabIndicator
                                    }
                                    }
                                    className={"tabContainer"}>
                                    {libraries.map(item =>  <Tab label={item.name} value={item.value} />)}
                                </Tabs>
                                    :
                                    null}
                            </div>
                            <div className={"selectContainer"}>
                                <div className={"searchFilterContainer"}>
                                    <TextField
                                        id="search"
                                        style={{ marginRight: 20 }}
                                        className={"searchTextField"}
                                        onChange={showActivities? this.searchActivities: this.searchTeams}
                                        value={searchString || ''}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon onClick={() => { this.setState({ searchString: '', teams: originalTeams }); this.filterByLabel({ target: { value: 'all' } }) }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {showActivities ? <Select
                                        onChange={this.filterByLabel}
                                        className={"searchTextField"}
                                        input={<Input style={{ textAlign: 'left' }} id="scoreType" startAdornment={<InputAdornment position="start"><LabelIcon /></InputAdornment>} />}
                                        value={activeLabelFilter ? activeLabelFilter : ""}
                                    >
                                        <MenuItem value={'all'}>
                                            {'all'}
                                        </MenuItem>
                                        {_.orderBy(Array.from(filterableTags)).map((tag, index) => (
                                            <MenuItem
                                                key={index}
                                                value={tag}>
                                                {tag}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                        : null}
                                </div>
                                {showActivities ? _.orderBy(Object.keys(groupings)).map((group, index) => {
                                    return <Grid
                                        container
                                        key={index}
                                        spacing={16}
                                        style={{ paddingBottom: 10 }}
                                    >
                                        <Grid item xs={12} style={{ textAlign: 'left', backgroundColor: '#28272C', padding: 0, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <LabelIcon style={{ color: 'white' }} />
                                            <h2 style={{ fontSize: '1.1em', color: 'white', paddingLeft: 10 }}>{(group||"").captialize()||"Unlabeled"} <span style={{ color: 'gray', fontSize: '0.8em', fontWeight: 'lighter', marginLeft: 5 }}>{`${(groupings[group]||[]).length} activities`}</span></h2>
                                        </Grid>
                                        <Grid item xs={12} className={groupings[group].length ? "left-align-slick" : "center-align-slick"}>
                                            <Hidden smDown>
                                                <Slider {...carouselSettings} className={groupings[group].length > 5 ? "multipleElements" : null}>
                                                    {groupings[group].length ? _.orderBy(groupings[group]).map((activity, index) => {
                                                        return <div key={index}>
                                                            <ActivityCard
                                                                logo={this.state.appLogo}
                                                                included={this.activityIncluded(activity)}
                                                                activity={activity}
                                                                isSliding={isSliding}
                                                                view={() => this.handleOpen(activity)}
                                                                currentTeam={currentTeam}
                                                                delete={this.handleDelete}
                                                                edit={() => this.toggleActivityModal(activity)}
                                                                add={activity => this.addActivityToList(activity)}
                                                            />
                                                        </div>
                                                    }) :
                                                        category ? <h2>Hey coach, add some {category == 'drill' ? 'skills' : category == 'exercise' ? 'exercises' : category == 'education' ? 'education materials' : category == 'coaches' ? 'resources' : 'activities'} for your {category == 'coaches' ? 'coaches' : 'athletes'}...</h2> : null}
                                                </Slider>
                                            </Hidden>
                                            <Hidden mdUp>
                                                <Slider {...carouselSettings} className={"multipleElements"}>
                                                    {groupings[group].length ? _.orderBy(groupings[group]).map((activity, index) => {
                                                        return <div key={index}>
                                                            <ActivityCard
                                                                logo={this.state.appLogo}
                                                                included={this.activityIncluded(activity)}
                                                                activity={activity}
                                                                isSliding={isSliding}
                                                                view={() => this.handleOpen(activity)}
                                                                currentTeam={currentTeam}
                                                                delete={this.handleDelete}
                                                                edit={() => this.toggleActivityModal(activity)}
                                                                add={activity => this.addActivityToList(activity)}
                                                            />
                                                        </div>
                                                    }) :
                                                        category ? <h2>Hey coach, add some {category == 'drill' ? 'skills' : category == 'exercise' ? 'exercises' : category == 'education' ? 'education materials' : category == 'coaches' ? 'resources' : 'activities'} for your {category == 'coaches' ? 'coaches' : 'athletes'}...</h2> : null}
                                                </Slider>
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                })
                                : teams.length ?
                                    <Grid
                                        container
                                        spacing={16}
                                    >
                                        {teams.map(team => {
                                            return <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                key={team.id}>
                                                <TeamCard
                                                    team={team}
                                                    logo={team.logo}
                                                    included={this.teamIncluded(team)}
                                                    add={activity => this.addTeamToList(team)}
                                                />
                                            </Grid>
                                        })}
                                    </Grid>
                                    : null}
                            </div>
                            <ActivityModal
                                open={open}
                                appLogo={this.state.appLogo}
                                activity={activity}
                                close={this.handleClose}
                                appContext={appContext}
                                delete={this.handleDelete}
                                save={this.handleResultSave}
                                edit={() => this.toggleActivityModal(activity)}
                            />
                            <NewWorkoutActivityModal
                                tags={activityTags}
                                activity={activity}
                                activities={activities}
                                loadingTags={loadingTags}
                                open={isActivityModalOpen}
                                save={this.handleActivitySave}
                                addTag={this.handleActivityTag}
                                overlayMessage={overlayMessage}
                                currentTeam={this.props.currentTeam}
                                close={() => this.toggleActivityModal(open ? activity : null)}
                                handleFileSelect={this.handleFileSelect}
                                uploadingVideoFile={uploadingVideoFile}
                                removeActivityVideo={this.removeActivityVideo}
                                removeTag={tag => this.removeActivityTag(tag)}
                                displaySuccessOverlay={displaySuccessOverlay}
                                handleVideoUrlChange={this.handleVideoUrlChange}
                                uploadingThumbnailFile={uploadingThumbnailFile}
                                videoFileUploadProgress={videoFileUploadProgress}
                                thumbnailUploadProgress={thumbnailUploadProgress}
                                handleThumbnailSelect={this.handleThumbnailSelect}
                                handleChange={name => this.handleActivityChange(name)}
                                cancelUpload={this.cancelUpload}
                                invalidVideoUrl={invalidVideoUrl}
                            />
                        </React.Fragment>
                </div>
            </PageWrapper>
        );
    }
}

class ActivityCard extends Component {

    state = { expanded: false };

    componentDidMount = async () => {
        this.loadThumbnail();
    };

    loadThumbnail = async () => {
        this.setState({ thumbnailLoading: true });
        if (this.props.activity.videoType === "vimeo") {
            const res = await fetchVimeoVideoThumbnail(this.props.activity);
            this.setState({ vimeo_thumb: res });
        }

        if (this.props.activity.videoType === "wistia") {
            const res = await fetchWistiaVideoThumbnail(this.props.activity);
            this.setState({ wistia_thumb: res });
        }
        this.setState({ thumbnailLoading: false });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { activity } = this.props;
        const { thumbnailLoading, vimeo_thumb, wistia_thumb } = this.state;
        if ((activity.videoType === 'vimeo' || activity.videoType === 'wistia') && !thumbnailLoading && !vimeo_thumb && !wistia_thumb) {
            this.loadThumbnail();
        }
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleDeleteClick() {
        this.props.delete(this.props.activity);
    }

    handleAddClick = () => {
        const { isSliding } = this.props;
        if (!isSliding) this.props.add(this.props.activity);
    }

    getCardThumbnail = () => {
        const { logo, activity, currentTeam } = this.props;
        const { wistia_thumb , vimeo_thumb} = this.state;
        let thumbnailImage = logo;
        if((currentTeam.tenant == 'vnn' || currentTeam.tenant == 'm1vnn') && (currentTeam.organization||{}).logoUrl ){
            thumbnailImage = currentTeam.organization.logoUrl
        }
        if(!!activity.thumbnail || !!activity.thumbnailBase64){
            thumbnailImage = activity.thumbnailBase64 || activity.thumbnail
        }
        else if (!!activity.attachment && (activity.attachment.includes('png') || activity.attachment.includes('jpg'))){
            thumbnailImage = activity.attachment;
        }
        else if(!!activity.videoType){
            switch(activity.videoType){
                case 'youtube': thumbnailImage = `http://img.youtube.com/vi/${activity.videoId}/0.jpg`; break;
                case 'vimeo': thumbnailImage = vimeo_thumb; break;
                case 'wistia':  thumbnailImage = wistia_thumb; break;
            }
        }
        return thumbnailImage;
    }

    render() {

        const { included, currentTeam, logo } = this.props;

        return (
            <div style={{ marginRight: 5 }}>
                <Card className={included ? classNames("activityListingCard", "includedActivity") : "activityListingCard"}>
                    <div className={included ? "selectedOverlay" : null}>
                        <div>
                            <div>
                                <CardMedia
                                    className={"media"}
                                    onClick={this.handleAddClick}
                                    image={this.getCardThumbnail()}
                                />
                                <div className={"contentActionContainer"}>
                                    <Typography style={{ color: 'white', fontSize: '0.75em', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 10 }} variant="headline" component="h2">
                                        {this.props.activity.name}
                                    </Typography>
                                    <IconButton
                                        onClick={this.handleExpandClick}
                                        aria-expanded={this.state.expanded}
                                        aria-label="Show more">
                                        {this.state.expanded ? <ExpandLessIcon style={{ color: "white" }} /> : <ExpandMoreIcon style={{ color: "white" }} />}
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <Collapse
                            in={this.state.expanded}
                            className={"descriptionContainer"}
                        >
                            <IconButton
                                onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteClick() }}
                                aria-label="Delete"
                                className={"deleteIcon"}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => this.props.view()}
                                aria-label="Edit"
                                className={"editIcon"}
                            >
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => this.props.edit()}
                                aria-label="Edit"
                                className={"editIcon"}
                            >
                                <EditIcon />
                            </IconButton>
                        </Collapse>
                    </div>
                </Card>
            </div>
        )
    }
}

class TeamCard extends Component {

    handleAddClick = () => {
        this.props.add(this.props.activity);
    }

    render() {
        const { team, included, logo } = this.props;
        var teamLogo = logo;
        if (team.logo) {
            if (!team.logo.includes('http://') && !team.logo.includes('http://')) {
                teamLogo = `https://s3.amazonaws.com/programax-videos-production/uploads/program/logo/${team.legacyId ? team.legacyId : team.id}/${logo}`
            }
            else {
                teamLogo = team.logo
            }
        }
        return (
            <div>
                <Card className={included ? classNames("teamSelectionCard", "includedTeam") : "teamSelectionCard"}>
                    <div onClick={this.handleAddClick} className={included ? "selectedOverlay" : null}>
                        <div>
                            <CardMedia image={team.logo ? team.logo : logo} className={"media"} />
                            {included ?
                                <div className={"contentActionContainer"}>
                                    <Typography style={{ color: 'white', fontSize: '0.8em', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="headline" component="h2">
                                        Added!
                                    </Typography>
                                </div>
                                :
                                <div className={"contentActionContainer"}>
                                    <Typography style={{ color: 'white', fontSize: '0.8em', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="headline" component="h2">
                                        {team.customName ? team.customName : `${team.name} ${team.sport}`}
                                    </Typography>
                                </div>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

//TODO: Move activity modal of team and director screen to common place.
class ActivityModal extends Component {
    constructor(props){
        super(props);
        this.decorator = getLinkDecorator();
        this.state = { editorState: EditorState.createEmpty(this.decorator), activityId: null, value: 0, page: 1, pages: 1 };
    }


    componentWillReceiveProps(nextProps) {
        // this is to catch legacy descriptions and format them
        this.setState({ editorState: EditorState.createEmpty(this.decorator) })
        if (nextProps.activity.description && nextProps.activity.description.split('{').length == 1 && this.state.activityId != nextProps.activity.id) { // this is to catch legacy descriptions and format them
            const descriptionToRender = `<div>${nextProps.activity.description}</div>`
            const blocksFromHTML = convertFromHTML(descriptionToRender);
            let newState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            this.setState({
                editorState: EditorState.createWithContent(newState, this.decorator),
                activityId: nextProps.activity.id
            })
        }
        else if (nextProps.activity.description && this.state.activityId != nextProps.activity.id) { // this is for editing descriptions created with our new text editor
            try {
                let newState = convertFromRaw(JSON.parse(nextProps.activity.description))
                this.setState({
                    editorState: EditorState.createWithContent(newState, this.decorator),
                    activityId: nextProps.activity.id
                })
            }
            catch (e) {

            }
        }
    }

    handleDeleteClick() {
        this.props.delete(this.props.activity);
    }

    handleEditClick() {
        this.props.edit();
    }

    handleTabValueChange = (product, value) => {
        this.setState({ value });
    };

    onDocumentComplete = (pages) => {
        this.setState({ page: 1, pages });
    }

    handlePrevious = () => {
        this.setState({ page: this.state.page - 1 });
    }

    handleNext = () => {
        this.setState({ page: this.state.page + 1 });
    }

    render() {
        const { value } = this.state;
        const { activity, open, appContext, appLogo } = this.props;
        const showVideoPlayer = isVideo(activity.attachment) || (!!activity.videoType && (activity.legacyVideoId || activity.videoId));
        return (
            <div>
                <LegacyModalContainer
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.props.close}
                    containerClassName={"listViewActivityModalPaper"}
                    >
                    <div className={"modalContent"}>
                        <div className={"modalHeaderContainer"}>
                            <h2 className={"modalHeaderText"}>{activity.name}</h2>
                        </div>
                        {/* <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleTabValueChange}
                            className={"activityTabContainer"}
                        >
                            <Tab label="Media" value={0} />
                            <Tab label="Description" value={1} />
                        </Tabs> */}
                        <div>
                            {showVideoPlayer
                                ? <VideoPlayer activity={activity} />
                                : activity.attachment && activity.attachment.includes('.pdf') ?
                                    <div>
                                        <form className="fileDownloadButton" method="get" action={activity.attachment}>
                                            <button type="submit">Download</button>
                                        </form>
                                        <div className={"pdfContainer"}>
                                            <PDF style={{ width: '100%', height: '100%' }} scale={0.6} file={activity.attachment} onDocumentComplete={this.onDocumentComplete} page={this.state.page} />
                                        </div>
                                    </div>
                                    : <div className={"imageContainer"}>
                                        <img className={"image"} src={activity.attachment ? activity.attachment : appLogo} />
                                    </div>}
                        </div>
                        <div id={"content"}>
                            <div className={"editor"} onClick={this.focus}>
                                <Editor
                                    ref={"editor"}
                                    editorState={this.state.editorState}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"modalFooterContainer"}>
                        <div>
                            {appContext.isOwner || appContext.isHeadCoach || appContext.isCoach || appContext.isAdmin ?
                                <div>
                                    <IconButton
                                        title="Delete Event"
                                        onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteClick() }}
                                        aria-label="edit"
                                        variant="contained">
                                        <DeleteIcon className={"editIcon"} />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => this.handleEditClick()}
                                        aria-label="Edit"
                                        className={"editIcon"}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                : null}
                            {appContext.isAthlete || appContext.isAdmin ?
                                <div>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        className={"outlinedButton"}
                                        onClick={this.props.close}
                                    >
                                        Cancel
                                </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={"filledButton"}
                                        onClick={this.props.save}
                                    >
                                        Save
                                </Button>
                                </div>
                                : null}
                        </div>
                    </div>
                </LegacyModalContainer>
            </div>
        )
    }
}

DirectorListActivities.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
        appContext: appContext,
        currentTeam: currentTeam||{},
        currentUser: userContext.user,
        libraries: appContext.libraries || []
    };
}

export default withStyles(styles)(connect(mapStateToProps)(DirectorListActivities));
