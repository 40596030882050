export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGatewayActivities: {
    REGION: "us-east-1",
    URL: "https://6ttwgkmn6i.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayChat: {
    REGION: "us-east-1",
    URL: "https://enbrb5v9s8.execute-api.us-east-1.amazonaws.com/test"
  },
  apiGatewayAdmin: {
    REGION: "us-east-1",
    URL: "https://17i6lzby0c.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayAuth: {
    REGION: "us-east-1",
    URL: "https://iam1meeifk.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayEvents: {
    REGION: "us-east-1",
    URL: "https://391c6j85bb.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayFeedItems: {
    REGION: "us-east-1",
    URL: "https://391c6j85bb.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayGroups: {
    REGION: "us-east-1",
    URL: "https://9ukw9yutsf.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayLeaderboards: {
    REGION: "us-east-1",
    URL: "https://8vji2z0tcj.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayMessages: {
    REGION: "us-east-1",
    URL: "https://kjqjvia8y1.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayOrganizations: {
    REGION: "us-east-1",
    URL: "https://ggm1rm4655.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayProducts: {
    REGION: "us-east-1",
    URL: "https://oawn6nkrth.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayPrograms: {
    REGION: "us-east-1",
    URL: "https://eifpe123zi.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewaySchedules: {
    REGION: "us-east-1",
    URL: "https://x1gi6silmf.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayUsers: {
    REGION: "us-east-1",
    URL: "https://3nkepkrwdi.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayWorkoutPrograms: {
    REGION: "us-east-1",
    URL: "https://z9iwtdf4fk.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayWorkouts: {
    REGION: "us-east-1",
    URL: "https://rf975yqh18.execute-api.us-east-1.amazonaws.com/prod"
  },
  apiGatewayWorkoutSchedules: {
    REGION: "us-east-1",
    URL: "https://0wv885yb8b.execute-api.us-east-1.amazonaws.com/prod"
  },
  // apiGatewayBilling: {
  //   REGION: "us-east-1",
  //   URL: "https://0wv885yb8b.execute-api.us-east-1.amazonaws.com/prod"
  // },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_bdsrShXUY",
    APP_CLIENT_ID: "36hj7kpk7j1putuafekhvdqc5s",
    IDENTITY_POOL_ID: "us-east-1:ba2bbba1-4dd7-49d2-9c6a-52542e72201c"
  },
  version: "0.0.23"
};
