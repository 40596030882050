import devConfig from "../configs/us-east-1/config-dev"; // If working on the Dev Account, use this
import devWestConfig from "../configs/eu-west-1/config-dev"; // If working on the Dev Account, use this
import tstConfig from "../configs/us-east-1/config-tst"; // If working on the TEST , use this
import tstWestConfig from "../configs/eu-west-1/config-tst"; // If working on the TEST , use this
// import config from "./config-dev"; // If working on the DEV , use this
import stgConfig from "../configs/us-east-1/config-stg"; // If working on the STAGING , use this
import stgWestConfig from "../configs/eu-west-1/config-stg"; // If working on the STAGING , use this
// import config from "./config-prod"; // If working on the STAGING , use this
import opsConfig from "../configs/us-east-1/config-ops"; // If working on the STAGING , use this
import opsTESTConfig from "../configs/us-east-1/config-ops-apis"; // If working on the STAGING , use this
import opsWestConfig from "../configs/eu-west-1/config-ops"; // If working on the STAGING , use this
import { getTenant as getTenantName } from "./commonUtil";
import { setEnvironment } from "maxone-react-ui";

export function getRegion(){
  // const REGION = "eu-west-1";
  const REGION = "us-east-1";
  const AWS_REGION = window.location.host.includes(".io") ? "eu-west-1" :  REGION;
  return AWS_REGION;
}
export function getEnvironment(){

    if (window.location.host.includes('localhost')) {
      const ENV = "stg"
      setEnvironment(ENV)
      return ENV;
    }
    const ENV = window.location.host.includes("maxone-dev") ? "dev" : window.location.host.includes("maxone-tst") ? "tst" : window.location.host.includes("gomaxone-stg") || window.location.host.includes("maxone-stg") ? "stg" : "ops"  ;
    setEnvironment(ENV)
    return ENV
}

export function getTenant(){
    const TENANT = getTenantName();
    console.log("TENANT ======= ", TENANT)
    return TENANT
}

export function getApiUrl(ENV, region){
    const tld = region.includes("eu-") ? "io" : "com"
    const apiUrl = "https://" + ENV + "-api." + (ENV === "tst" || ENV === "dev" ? ("maxone-dev." + tld)  : tld === "io" ? ("maxone." + tld) : "gomaxone." + tld)
    return apiUrl
}
export function getConfigMap(region){
   const configMap = {
    "us-east-1":{
      "dev":devConfig,
      "tst":tstConfig,
      "stg":stgConfig,
      "ops": opsTESTConfig
    },
    "eu-west-1":{
      "dev":devWestConfig,
      "tst":tstWestConfig,
      "stg":stgWestConfig,
      "ops":opsWestConfig
    }
  }
    return configMap[region]
}
