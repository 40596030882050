export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  s3Legacy: {
    REGION: "us-east-1",
    BUCKET: "programax-videos-production"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://stg-api.gomaxone.eu"
  },
  apiGatewayActivities: {
    URL: "https://stg-api.gomaxone.com/activities",
    REGION:"us-east-1"

  },
  apiGatewayUsers: {
    URL: "https://stg-api.maxone.io/users"
  },
  apiGatewayWorkoutPrograms: {
    URL: "https://stg-api.gomaxone.com/workoutPrograms",
    REGION:"us-east-1"

  },
  apiGatewayWorkouts: {
    URL:"https://stg-api.gomaxone.com/workouts",
    REGION:"us-east-1"

  },
  apiGatewayWorkoutSchedules: {
    URL: "https://stg-api.gomaxone.com/workoutSchedules",
    REGION:"us-east-1"

  },
  apiGatewayTaggings: {
    URL:"https://v-api.gomaxone.com/taggings",
    REGION:"us-east-1"

  },
  apiGatewayPrograms: {
    URL: "https://stg-api.gomaxone.com/programs",
    REGION:"us-east-1"

  },
  cognito: {
    USER_POOL_ID: "eu-west-1_QgIRQOV3c",
    APP_CLIENT_ID: "13hgqder05tn23nmv1ksauh6pe",
    IDENTITY_POOL_ID: "us-east-1:0a19b97b-b8b1-4b79-bdf4-d9a15bb73737"
  },
  segment:{
    KEY: "8B9GGFwd6bUADVj4jTUp1XKfelYqQC6u"
  }
}
