import React, { Component } from "react";
import OptionsSelector from '../optionsSelector';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const styles = {
    selectOptionContainer: {
        background: "#ffffff",
        borderRadius: "8px",
        minHeight: "42px",
        fontSize: "16px",
        lineHeight: "120%",
        display: "flex",
        alignItems: "center",
        padding: "6px 12px",
        border: "1px solid #DDE2E5",
        cursor:"pointer",
        position:"relative"
    },
    label:{
        color:"#d3d3d3"
    },
    hasValueLabel:{
        fontSize: "11px",
        position: "absolute",
        top: "1px",
        color: "#A0A7AB"
    },
    selectedOption:{
        marginTop:"8px"
    }
}

class FilterDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openOptionMenu: false
        }
    }

    toggleFilterMenu = () => {
        let { openOptionMenu } = this.state;
        this.setState({ openOptionMenu: !openOptionMenu });
    }

    render() {
        let { classes, menuItemData, value, label, onChange, height } = this.props;
        let { openOptionMenu } = this.state; 
        const selectedOptionValue = ((menuItemData||[]).find(item => item.value == value) ||{}).displayValue;
        let formattedMenuItemData = menuItemData.map(item => { return { name:item.displayValue, id:item.value }});
        return (
            <div className={classnames(classes.filterOption, 'position-relative')}>
                <div className={classes.selectOptionContainer} style={{minHeight:height}} onClick={this.toggleFilterMenu}>
                    <div className={classnames(classes.label, {[classes.hasValueLabel]:!!selectedOptionValue})}>{label}</div>
                    {!!selectedOptionValue && <div className={classnames(classes.selectedOption)}>{selectedOptionValue}</div>}
                    <ArrowDropDownIcon className={classes.label}/>
                </div>
               {openOptionMenu && <OptionsSelector
                    options={formattedMenuItemData}
                    close={this.toggleFilterMenu}
                    className={'optionsSelector'}
                    selectedOption={{id:value}}
                    onClickOption={(option)=> { this.toggleFilterMenu(); onChange(option.id)}}
                />}
            </div>
        )
    }
}

export default withStyles(styles)(FilterDropDown);