import React, { Component, useState } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ProgressStep from '../progressStep';
import FloatingSelectWithCustomChild from '../../../components/floatingSelectWithCustomChild';
import HeaderWrapper from "../headerWrapper";
import BodyWrapper from "../../../components/bodyWrapper";
import CheckboxWithLabel from '../../../components/checkboxWithLabel';
import FloatingInput from '../../../components/floatingInput';
import SportsEngineBtn from '../../../components/themeButton';
import ModalContainer from '../../../components/modalContainer';
import { PencilIcon, PlusIcon } from '../../../components/icons'
import { userImportClient } from '../../../clients'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getTenant } from "../../../utils/environmentUtils"
import { connect } from 'react-redux';
import ContextService from '../../../services/context';
import { setUserContext, setAppContext } from '../../../actions/session';
import { SPORTS } from '../../../constants';
import { testId } from '../../../utils/test-utils';

const GROUP_TYPE = Object.freeze({
    TYPE1: 'type1',
    TYPE2: "type2"
})

const styles = {
    groupBox: {
        flexDirection: "column",
        padding: "20px 0",
        display: 'flex',
        alignItems: 'center',
        width: "340px",
        minWidth: "340px",
        background: "#FFFFFF",
        borderRadius: "8px",
        marginRight: "20px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        minHeight: "319px"
    },
    groupList: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "16px",
        alignSelf: "start",
        marginTop: "16px",
        borderTop: "1px solid #DDE2E5",
        width: "100%",
        minHeight: "215px",
        display: "flex",
        flexDirection: "column"
    },
    dropDownContainer: {
        paddingLeft: "20px",
        paddingRight: "20px",
        width: "100%"
    },
    checkBoxContainer: {
        marginBottom: "12px"
    },
    groupSrv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "auto"
    },
    reset: {
        fontWight: "600",
        fontSize: "12px",
        lineHeight: "150%",
        textAlign: "right",
        color: "#F93F3F",
        cursor: "pointer"
    },
    noteContainer: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "150%",
        color: "#75797B",
        marginBottom: "16px",
        width: "calc(100% - 384px)"
    },
    note2Container: {
        padding: "20px",
        border: "2px solid #DDE2E5",
        boxSizing: "border-box",
        borderRadius: "8px"
    },
    groupContainer: {
        marginBottom: "30px",
        width: "calc(100% - 384px)"
    },
    quickTeamPreviewContainer: {
        position: "absolute",
        width: "390px",
        height: "calc(100vh - 60px)",
        right: "0px",
        top: "0px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.12)",
        padding: "30px",
        textAlign: 'left',
        fontFamily: "Poppins !important",
        fontStyle: "normal",
        overflow:"scroll"
    },
    previewHead: {
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "120%",
        color: "#000000",
        marginBottom: "8px"
    },
    previewSummaryTxt: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#000000",
        paddingBottom: "12px",
        borderBottom: "1px solid #DDE2E5"
    },
    previewSummary: {
        paddingTop: "12px",
        paddingBottom: "12px",
        borderBottom: "1px solid #DDE2E5",
        marginBottom: "12px",
        alignItems: "center",
        justifyContent: "space-between"
    },
    summaryInfo: {
        color: "#5B6062",
        fontSize: "14px",
        lineHeight: "150%"
    },
    groupsCount: {
        fontSize: "40px",
        lineHeight: "80%",
        color: "#222127",
        width: "61px",
        textAlign: "center"
    },
    summaryHead: {
        marginBottom: "8px",
    },
    groupSummaryCount: {
        marginBottom: "24px"
    },
    column2: {
        width: "61px",
        textAlign: "center"
    },
    groupSummaryInfo: {
        marginBottom: "10px",
        "& .column1": {
            color: "#1354F9"
        }
    },
    headerAdditionalInfo: {
        marginLeft: "110px",
        textAlign: "left",
        paddingBottom: "30px",
        marginTop: "10px",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#27292A",
        width: "calc(100% - 514px)"
    },
    editGroupName: {
        "& svg": {
            marginLeft: "8px",
            width: "20px",
            height: "20px",
            fill: "#DDE2E5",
            cursor: "pointer"
        }
    },
    mergeField: {
        marginTop: "5px",
        marginBottom: "5px",
        "&:before":{
            content: "'\\2022'",
            color: "#1354F9",
            fontWeight: "bold",
            display: "inline-block",
            width: "1em",
            marginLeft: "0em"
        }
    },
    emptyClckElement: {
        "& svg": {
            width: "50px",
            height: "50px",
            marginBottom: "16px"
        }
    },
    type2msg2: {
        color: "#5B6062",
        fontSize: "14px",
        fontWeight: "500"
    },
    skipTxt: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "150%",
        color: "#5B6062"
    },
    skipGroupContainer:{
        borderBottom:"none"
    },
    mergeTxt:{
        fontWeight: "500",
        fontSize:"14px",
        lineHeight: "150%",
        color: "#27292A"
    },
    strikeOut:{
        textDecorationLine: "line-through",        
    },
    inActiveTxt:{
        color: "#0B0B0B !important",
        opacity: "0.3"
    },
    teamSelectionContainer:{
        borderTop:"1px solid #DDE2E5",
        paddingTop:"20px",
        paddingBottom:"20px"
    },
    sportsSelectionInfo:{
        marginTop:"8px",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "140%",
        color: "#75797B"
    }
};

class GenerateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            [GROUP_TYPE.TYPE1]: [],
            [GROUP_TYPE.TYPE2]: [],
            menuItemData: [],
            openMergeFieldModal: false,
            levelSelected: GROUP_TYPE.TYPE1,
            type1GroupTypeSelectedName: "",
            type2GroupTypeSelectedName: "",
            type1SelectedGroupType: [],
            type2SelectedGroupType: [],
            groups: [],
            groupsCount:0,
            isLoading:true,
            selectedSports:SPORTS[0]

        }
    }

    componentDidMount = () => {
        this.fetchGroupMap();
    }

    toggleGroupSelection = (level, name) => {        
        let selectedGroupIdType = `${level}SelectedGroupType`
        let selectedGroupInfo = this.state[selectedGroupIdType];
        const elementIndex = selectedGroupInfo.findIndex(item => item.name == name)
        let newGroupInfo = [...selectedGroupInfo];
        newGroupInfo[elementIndex] = {
            ...newGroupInfo[elementIndex],
            isSelected: !newGroupInfo[elementIndex].isSelected            
        }
        this.setState({ [selectedGroupIdType]: newGroupInfo }, this.computeGroupsData);
    }

    getGroupModel = (count, name, criteria, type, fields) => {
        let group = {
            count,
            name,
            type,
            fields,
            criteria         
        };
        return group;
    }

    computeGroupsData = async () => {
        let { type1SelectedGroupType, type2SelectedGroupType } = this.state;
        let { importProcessId, enableGroups } = this.props;
        this.setState({loadingQuickPreview: true});
        let selectedType1GroupType = type1SelectedGroupType.filter(item => item.isSelected);
        let selectedType2GroupType = type2SelectedGroupType.filter(item => item.isSelected);
        let groups = [];
        let type1Groups = [];
        selectedType1GroupType.forEach(data => {
            let group = this.getGroupModel(0, data.name, [{ [data.type]: data.fields }], data.type, data.fields);
            type1Groups.push(group);
        });
        if (selectedType2GroupType.length) {
            selectedType2GroupType.forEach(data => {
                if (type1Groups.length) {
                    type1Groups.forEach(existingGroup => {
                        let group = this.getGroupModel(0, existingGroup.name + " " + data.name,
                         [{ [existingGroup.type]: existingGroup.fields }, { [data.type]: data.fields }]);
                        groups.push(group);
                    })
                }
                else {
                    let group = this.getGroupModel(0, data.name,  [{ [data.type]: data.fields }]);;
                    groups.push(group);
                }
            });
        }
        else {
            groups = type1Groups;
        }
        let groupsCount = 0;
        try {
            if (groups.length) {
                let data = null;
                if(enableGroups){
                    data = (await userImportClient.getImportGroupCount(importProcessId, { groupData: groups })).data;
                }
                else{
                    data = (await userImportClient.getImportTeamCount(importProcessId, { teamData: groups })).data;  
                }                            
                if ((data.numAthletes || []).length) {
                    let athleteGroupNameMap = {};
                    data.numAthletes.forEach(item => {
                        athleteGroupNameMap[item.name] = item.count;
                    })
                    groups.forEach(item => {
                        item.count = athleteGroupNameMap[item.name] || 0;
                        if(item.count != 0){
                            groupsCount++;
                        }
                    });
                }
            }            
        }
        catch(e){
            console.log("Exception")
            console.log(e)
        }
        this.setState({loadingQuickPreview: false});
        this.setState({ groups: groups, groupsCount: groupsCount })
    }

    mergeGroups = (level, type, mergedFields, mergeFieldsName) => {
        let groupTypeName = `${level}SelectedGroupType`;
        let currentValue = this.state[groupTypeName];
        let newMergedValue = {
            name: mergeFieldsName,
            type: type,
            fields: mergedFields
        }
        let remainingValue = currentValue.filter(value => !value.fields.some(r => mergedFields.includes(r)))
        let newGroups = [
            newMergedValue, ...remainingValue
        ];
        this.setState({ [groupTypeName]: newGroups }, this.computeGroupsData)
    }

    selectGroupType = (level, name) => {
        let { groupingFields } = this.state;
        let selectedGroupType = (groupingFields.find(item => item.name == name) || {});
        let mappedData = selectedGroupType.values.map(value => {
            return {
                name: value,
                type: selectedGroupType.name,
                fields: [value],
                isSelected: false
            }
        });
        this.setState({
            [`${level}GroupTypeSelectedName`]: selectedGroupType.name,
            [`${level}SelectedGroupType`]: mappedData,    
        },this.computeGroupsData);
    }

    setSessionInfo = async (username) => {
        const contextService = new ContextService();
        const {userContext, appContext} = await contextService.buildUserContext(username);
        this.props.setUserContext(userContext);
        this.props.setAppContext(appContext);
    }

    createTeamsOrGroups = async () => {
        let { goToNextStep, importProcessId, setGroupOrTeamCount, enableGroups, currentUser, currentTeam, tenant } = this.props;
        let { groups, selectedSports } = this.state;
        let filteredGroups  = groups.filter(item => item.count !=0);
        let sport = selectedSports;
        const organizationId = (currentTeam||{}).organizationId;
        this.setState({isLoading:true, loadingMessage1: `Generating ${enableGroups?'Groups':'Teams'}`});
        try{
            let queryParams = {
                status:"invite_pending"
            }
            let responses = await Promise.all([
                userImportClient.searchUserImportsByProcessId(importProcessId, queryParams),
                new Promise((res, rej) => {
                    try {
                        enableGroups ? userImportClient.createUserImportGroup(importProcessId, filteredGroups, currentTeam.id, sport, organizationId, tenant, currentTeam.name) :
                            userImportClient.createUserImportTeam(importProcessId, filteredGroups, currentTeam.id, sport, organizationId, tenant, currentTeam.name)
                    }
                    catch (e) {
                        console.log("Exception")
                        console.log(e)
                    }
                    res();
                })
            ])            
            let data = responses[0].data;
            if(!enableGroups){
                await this.setSessionInfo(currentUser.username);
            }
            setGroupOrTeamCount(data.total, filteredGroups.length)
        }
        catch(e){
            console.log("Exception")
            console.log(e)
        }
        this.setState({isLoading:false});        
        goToNextStep();
    }

    renameField = (level, oldFieldName, newName) => {
        let selectedGroupIdType = `${level}SelectedGroupType`
        let selectedGroupInfo = this.state[selectedGroupIdType];        
        let newGroupInfo = [...selectedGroupInfo];
        let selectedField = newGroupInfo.find(item => item.name == oldFieldName);
        selectedField.name = newName;
        this.setState({ [selectedGroupIdType]: newGroupInfo }, this.computeGroupsData);
    }

    fetchGroupMap = () => {
        let { importProcessId } = this.props;
        userImportClient.getGroupingFieldsByProcessId(importProcessId).then(({ data }) => {
            let menuItemData = data.map(item => {
                return {
                    value: item.name,
                    displayValue: item.name,
                    count: item.count
                }
            })
            this.setState({ groupingFields: data, menuItemData: menuItemData });
        }).catch((e) => {
            console.log("Exception")
            console.log(e)
        }).finally(()=>{
            this.setState({isLoading:false})
        });
    }

    toggleMergeFieldsModal = (level) => {
        let { openMergeFieldModal } = this.state;
        this.setState({ openMergeFieldModal: !openMergeFieldModal, levelSelected: level })
    }

    toggleRenameModal = (level, field) => {
        let { openRenameModal } = this.state;
        this.setState({ openRenameModal: !openRenameModal, fieldSelectedForEdit: field, levelSelected: level })
    }

    enableType2Grouping = () => {
        this.setState({ type2GroupEnabled: true });
    }

    changeSports = (sports) => {
        this.setState({selectedSports: sports})
    }

    render() {
        const { classes, enableGroups, currentStep, totalSteps } = this.props;
        let { menuItemData,
            openMergeFieldModal,
            type1SelectedGroupType,
            type2SelectedGroupType,
            levelSelected,
            openRenameModal,
            type1GroupTypeSelectedName,
            fieldSelectedForEdit,
            type2GroupTypeSelectedName,
            type2GroupEnabled,
            groups,
            isLoading,
            loadingQuickPreview,
            groupsCount,
            loadingMessage1, 
            selectedSports,
        } = this.state;
        const typeDataMap = {
            [GROUP_TYPE.TYPE1]: {
                selectedTopTierData: type1SelectedGroupType,
                selectedTopTierName: type1GroupTypeSelectedName
            },
            [GROUP_TYPE.TYPE2]: {
                selectedTopTierData: type2SelectedGroupType,
                selectedTopTierName: type2GroupTypeSelectedName
            }
        }
               
        const type2MenuItemData = menuItemData.filter(item => item.value != type1GroupTypeSelectedName);
        menuItemData = menuItemData.filter(item => item.value != type2GroupTypeSelectedName);
        const hasGroups = groupsCount != 0;
        let userHasSelectedGroups = groups.length != 0;
        const sportsMenuData = SPORTS.map(item => {
            return {
                value: item,
                displayValue: item,
            }
        });

        return (
            <React.Fragment>
                <HeaderWrapper>
                    <ProgressStep stepDetails={{
                        "title": `Generate Athlete ${enableGroups?"Groups":"Teams"}`,
                        "nextStep": "Review and invite Athletes"
                    }} currentStep={currentStep} totalSteps={totalSteps} />
                    <div className={classes.headerAdditionalInfo}>
                        You can automatically generate athlete groups from the data we imported earlier. Select the imported fields to use. After selecting we will show you a quick preview of your group structure.
                        <span className="font-weight-bold"> You can edit this at a later time as well.</span>
                    </div>
                </HeaderWrapper>
                <div className={classes.quickTeamPreviewContainer}>
                    <div className={classes.previewHead}>Quick {enableGroups?"Groups":"Teams"} Preview</div>
                    <div className={classes.previewSummaryTxt}>This is a preview of how your {enableGroups?"group":"team"} structure will be setup after the import process is completed</div>
                    {
                        loadingQuickPreview ?
                        <div className={classes.quickPreviewLoading,"d-flex align-items-center justify-content-center h-100"}>
                            <CircularProgress />
                        </div>
                        :
                        <React.Fragment>
                            <div className={classnames("d-flex flex-direction-row", classes.previewSummary, {[classes.skipGroupContainer]: !hasGroups})}>
                            {!hasGroups ? 
                                <div className={classes.skipTxt}>
                                    By Skipping and not selecting a group tier you will be placing all of the athletes into a single default group.
                                </div>:
                                <React.Fragment>
                                    <div className={classes.summaryInfo}>
                                        Total Automatically <br />
                                        Generated {enableGroups?"Groups":"Teams"}:
                                    </div>
                                    <div className={classes.groupsCount}>
                                    {groupsCount}
                                </div>
                                </React.Fragment>
                                }
                            </div>

                            {userHasSelectedGroups && <div className={classes.groupSummaryCount}>
                                <div className={classnames("d-flex align-items-center justify-content-between font-weight-bold", classes.summaryHead)}>
                                    <span className={classes.column1}>{enableGroups?"Group":"Team"} Names</span>
                                    <span className={classes.column2}>Athletes</span>
                                </div>
                                {groups.map(group => { return (<div className={classnames("d-flex align-items-center justify-content-between", classes.groupSummaryInfo)}>
                                    <span className={classnames("column1", classes.column1,  {[classes.inActiveTxt]:!group.count}, {[classes.strikeOut]:!group.count})}>{group.name}</span>
                                    <span className={classnames(classes.column2, {[classes.inActiveTxt]:!group.count})}>{group.count}</span>
                                </div>)})}
                            </div>}
                            {
                             !enableGroups &&  <div className={classes.teamSelectionContainer}>
                                    <FloatingSelectWithCustomChild
                                        value={selectedSports}
                                        label="Set Team Sport"
                                        onChange={(value) => this.changeSports(value)}
                                        dropDownLabel="Set Team Sport"
                                        menuItemData={sportsMenuData}
                                    />
                                    <div className={classes.sportsSelectionInfo}>
                                        Note: All the teams created in the import will be set to the selected sports. If multiple sports are needed, this can be changed after import in Team Settings.
                                    </div>
                                </div>
                            }
                            <div className={classes.createGrpBtn} {...testId(`submit-button`)}>
                                <SportsEngineBtn
                                    theme="leftAlign"
                                    width={hasGroups?"292px":"284px"}
                                    height={"50px"}
                                    iconName="ChevronRight"
                                    color={hasGroups?"#27AE60":"#ffffff"}
                                    textColor={hasGroups?"#ffffff":"#5B6062"}
                                    onClick={this.createTeamsOrGroups}
                                    text={hasGroups ? `Generate ${groupsCount} ${enableGroups?"Groups":"Teams"}`: `Skip Generating ${enableGroups?"Groups":"Teams"}`} />
                            </div>
                        </React.Fragment>
                    }
                </div>
                <BodyWrapper isLoading={isLoading} loadingMessage1={loadingMessage1}>
                    <div className={classes.importInfo}>
                        <div className={classnames(classes.groupContainer, "d-flex justify-content-start align-items-start flex-direction-column")}>
                            {Object.values(GROUP_TYPE).map((type) => (type == GROUP_TYPE.TYPE2 && (!!typeDataMap[GROUP_TYPE.TYPE1].selectedTopTierData.length == 0) ?
                                null :
                                type == GROUP_TYPE.TYPE2 && !type2GroupEnabled ?
                                    <div onClick={this.enableType2Grouping} className={classnames("d-flex align-items-center justify-content-center", classes.groupBox, classes.emptyClckElement)}>
                                        <PlusIcon />
                                        <div className={classes.type2msg}>
                                            Add 2nd Tier
                                        </div>
                                        <div className={classes.type2msg2}>
                                            (Optional)
                                        </div>
                                    </div> :
                                    <div className={classes.groupBox}>
                                        <div className={classes.dropDownContainer}>
                                            <FloatingSelectWithCustomChild
                                                value={typeDataMap[type].selectedTopTierName}
                                                label="Select Top Group Tier"
                                                onChange={(value) => this.selectGroupType(type, value)}
                                                dropDownLabel="Select Top Group Tier"
                                                menuItemData={ type == GROUP_TYPE.TYPE2? type2MenuItemData: menuItemData}
                                            />
                                        </div>
                                        {!!typeDataMap[type].selectedTopTierData.length &&
                                            <div className={classnames(classes.groupList, "align-self-start")}>
                                                {typeDataMap[type].selectedTopTierData.map(item => {
                                                    return (
                                                        <div className={classes.checkBoxContainer}>
                                                            <CheckboxWithLabel
                                                                label={item.name}
                                                                checked={item.isSelected}
                                                                onChange={() => this.toggleGroupSelection(type, item.name)}
                                                            />
                                                            <span className={classes.editGroupName} onClick={() => this.toggleRenameModal(type, item.name)}>
                                                                <PencilIcon />
                                                            </span>
                                                        </div>)
                                                })}
                                                <div className={classes.groupSrv}>
                                                    <SportsEngineBtn
                                                        disabled={(typeDataMap[type].selectedTopTierData.filter(item => item.isSelected) || []).length < 2}
                                                        color={"#1354F9"}
                                                        width={"200px"}
                                                        height={"37px"}
                                                        position={"left"}
                                                        iconName="Merge"
                                                        theme="small"
                                                        onClick={() => this.toggleMergeFieldsModal(type)}
                                                        text="Merge Selected Fields"
                                                    />
                                                    <span className={classes.reset} onClick={() => this.selectGroupType(type, typeDataMap[type].selectedTopTierName)}>
                                                        Reset
                                            </span>
                                                </div>
                                            </div>
                                        }
                                    </div>))}
                        </div>
                        {!!type1SelectedGroupType.length && <div className={classes.noteContainer}>
                            <span className="font-weight-bold">Note:</span>
                           You can lower the total amount of teams by merging same/similar teams together. You can merge fields by selecting 2 or more items from the same tier and clicking <span className="font-weight-bold">merge selected fields</span>
                        </div>}
                        <div className={classnames(classes.noteContainer, classes.note2Container)}>
                            You will select and invite your athletes in the following step. <br />
                            <span className="font-weight-bold">No invites will be sent until all settings are confirmed in the final Step (5/5).</span>)
                        </div>
                    </div>
                    <MergeFieldsModal
                        classes={classes}
                        isOpen={openMergeFieldModal}
                        level={levelSelected}
                        groupTypeData={levelSelected == GROUP_TYPE.TYPE1 ? type1SelectedGroupType : type2SelectedGroupType}
                        closePopup={this.toggleMergeFieldsModal}
                        mergeGroups={this.mergeGroups} />
                    {openRenameModal && <RenameGroupNameModal classes={classes} isOpen={openRenameModal} level={levelSelected} fieldDetail={fieldSelectedForEdit} closePopup={this.toggleRenameModal} renameField={this.renameField} />}
                </BodyWrapper>
            </React.Fragment>
        );
    }
}

function MergeFieldsModal({ classes, isOpen, level, closePopup, mergeGroups, groupTypeData }) {
    const [fieldName, setFieldName] = useState("");
    const selectedValues = groupTypeData.filter(item => item.isSelected);
    const type = (groupTypeData[0] || {}).type;
    return (
        <ModalContainer
            heading={"Merge Selected Fields"}
            open={isOpen}
            width={"518px"}
            onClose={closePopup}
            leftBtn={<SportsEngineBtn
                width={"103px"}
                iconColor={"#75797B"}
                textColor={"#0B0B0B"}
                color={"#ffffff"}
                height={"44px"}
                position="left"
                iconName="Cancel"
                theme="small"
                onClick={closePopup}
                text="Cancel"
            />}
            rightBtn={<SportsEngineBtn
                color={"#1354F9"}
                width={"97px"}
                height={"44px"}
                iconName="CheckMark"
                theme="small"
                onClick={() => { mergeGroups(level, type, selectedValues.map(r => r.fields).flat(), fieldName); closePopup(); }}
                text="Merge"
            />}
        >
            <div className={classes.mergeFieldsInfo}>
                <div className={classes.mergeTxt}>Merge the following fields together:</div>
                {selectedValues.map(item => {
                    return (
                        <div className={classes.mergeField}>
                            <span className={classes.bullet}></span>
                            <span>{item.name}</span>
                        </div>)
                })}
                <FloatingInput
                    label={"Merged Tier Name"}
                    className={"textField"}
                    value={fieldName}
                    removeBottomMargin={true}
                    onChange={(e) => setFieldName(e.target.value)}
                />
            </div>
        </ModalContainer>
    );
}

function RenameGroupNameModal({ classes, fieldDetail, isOpen, closePopup, renameField, level }) {
    const [fieldName, setFieldName] = useState("");
    return (
        <ModalContainer
            heading={<span>Rename: <span className="font-weight-bold">{fieldDetail}</span></span>}
            open={isOpen}
            width={"518px"}
            onClose={closePopup}
            leftBtn={<SportsEngineBtn
                width={"103px"}
                iconColor={"#75797B"}
                textColor={"#0B0B0B"}
                color={"#ffffff"}
                height={"44px"}
                position="left"
                iconName="Cancel"
                theme="small"
                onClick={closePopup}
                text="Cancel"
            />}
            rightBtn={<SportsEngineBtn
                color={"#1354F9"}
                width={"111px"}
                height={"44px"}
                iconName="CheckMark"
                theme="small"
                onClick={() => { renameField(level, fieldDetail, fieldName); closePopup(); }}
                text="Rename"
            />}
        >
            <div className={classes.mergeFieldsInfo}>
                <FloatingInput
                    label={"New Name"}
                    className={"textField"}
                    value={fieldName}
                    removeBottomMargin={true}
                    onChange={(e) => setFieldName(e.target.value)}
                />
            </div>
        </ModalContainer>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setUserContext: (userContext) => dispatch(setUserContext(userContext)),
    setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

const mapStateToProps = state =>  {
    const { session: { userContext, appContext }, tenant } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam||{},
        userContext,
        appContext,
        currentUser: userContext.user,
        tenant: tenant.id
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GenerateGroup));