import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
  <SvgIcon width="18" height="19" viewBox="0 0 18 19" {...props}>
    <path d="M5.52739 13.5307C4.93417 12.2508 5.49306 10.7141 6.77042 10.1083C7.98596 9.53254 9.6302 10.1157 10.1978 11.3489C10.8426 12.7523 10.1999 14.218 8.98441 14.7937C7.68612 15.4138 6.1373 14.8477 5.52739 13.5307Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9992 3.03513C16.2978 2.10566 15.5954 1.17757 14.888 0.251828C14.6584 -0.0484643 14.3972 -0.0778055 14.0948 0.151017C13.7091 0.4427 13.3247 0.736009 12.9403 1.02926L12.94 1.0295L12.666 1.23852C11.8463 1.86362 11.0258 2.48747 10.2053 3.11131C8.1984 4.63713 6.19163 6.16288 4.19821 7.70669C2.22155 9.23697 1.43551 11.2618 1.86199 13.7379C1.90834 14.0073 1.98312 14.2711 2.08678 14.5247C2.14361 14.6629 2.13963 14.7513 1.95384 14.7424C1.88573 14.7394 1.81595 14.7501 1.74699 14.7607L1.72054 14.7647C0.301312 14.9743 -0.41437 16.4867 0.250633 17.7283C0.670873 18.5133 1.39075 18.8702 2.27011 18.797C3.11691 18.7263 3.7027 18.256 3.98915 17.4458C4.04012 17.3015 4.08927 17.292 4.20457 17.3766C4.52536 17.6121 4.86433 17.8148 5.22966 17.9781C6.92746 18.7383 8.62035 18.7286 9.97904 18.1283C11.1932 17.5515 12.0377 16.8679 12.5311 16.1898C13.3612 15.0468 13.7908 13.7737 13.7825 12.3595C13.7778 11.4952 13.6001 10.6654 13.2203 9.88713C13.0176 9.47205 12.753 9.09236 12.4597 8.73233C12.0938 8.283 12.1312 7.79403 12.5446 7.38894C12.605 7.3301 12.6691 7.27379 12.7335 7.21992C14.1271 6.04707 15.5215 4.87389 16.9158 3.7007C17.1458 3.50704 17.1801 3.2746 16.9992 3.03513ZM2.51 17.7443C1.98213 17.99 1.28716 17.7276 1.03787 17.1885C0.7869 16.6459 1.03588 15.9702 1.58036 15.7158C2.11848 15.4643 2.78625 15.7076 3.03487 16.2453C3.31018 16.843 3.08976 17.4749 2.51 17.7443ZM9.29376 15.6243C7.58233 16.4219 5.43162 15.6385 4.67248 13.9261C3.86404 12.2137 4.61473 10.1581 6.34728 9.33752C8.08619 8.51392 10.1152 9.28502 10.9756 11.0817C11.7708 12.7424 11.0278 14.816 9.29376 15.6243Z" />
    <path d="M4.38894 4.48166C4.54112 4.67333 4.83657 4.70375 5.02563 4.57529C5.24095 4.42921 5.30315 4.18443 5.17432 3.91425C5.17214 3.91063 5.16919 3.90486 5.16552 3.89768C5.15555 3.87817 5.14023 3.84817 5.12035 3.82221C5.03652 3.71282 4.95277 3.6034 4.86902 3.49399L4.86745 3.49194C4.49028 2.99919 4.11316 2.50649 3.73042 2.01774C3.52658 1.75715 3.27808 1.72179 3.03214 1.89448C2.80854 2.05227 2.75165 2.35939 2.93486 2.60381C3.41009 3.23655 3.89773 3.86081 4.38894 4.48166Z" />
    <path d="M7.07814 3.6741C7.13671 3.89454 7.09236 4.08936 6.91299 4.23831C6.7621 4.36339 6.59029 4.37126 6.41684 4.2917C6.26834 4.22281 6.16701 4.10564 6.12394 3.94531C6.08804 3.81293 6.05207 3.68059 6.0161 3.54825C5.85594 2.95895 5.69582 2.36985 5.54228 1.77878C5.45316 1.43359 5.62989 1.1458 5.9385 1.09995C6.17102 1.06505 6.33948 1.16513 6.47411 1.4585C6.56788 1.7989 6.67819 2.19977 6.78779 2.60099C6.88539 2.95859 6.98299 3.3162 7.07814 3.6741Z" />
    <path d="M3.70564 6.27791C3.96298 6.42323 4.2068 6.35013 4.36375 6.10457C4.50409 5.88418 4.45622 5.60419 4.21252 5.45846C3.50871 5.03689 2.79728 4.62844 2.08473 4.22311C1.90512 4.1214 1.6984 4.17607 1.55074 4.32989C1.40416 4.48232 1.36505 4.69369 1.46577 4.90454C1.49997 4.97308 1.57799 5.05201 1.67468 5.10825C1.8235 5.19433 1.97226 5.28053 2.12101 5.36672C2.6483 5.67226 3.17578 5.97791 3.70564 6.27791Z" />
  </SvgIcon>
);