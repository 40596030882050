import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let TennisIcon = (props) => (
  <SvgIcon width="25" height="25" viewBox="0 0 25 25" {...props}>
  	<path d="M16.5002 14.6705C16.9953 13.7689 17.2098 12.6697 16.986 11.4026C16.3371 7.75538 13.9111 7.64992 11.3845 7.54009C9.45533 7.45623 7.46748 7.36982 6.16718 5.70229C4.72051 7.06561 3.73188 8.85047 3.33779 10.7957C5.46925 10.9735 6.62076 12.6144 7.73819 14.2068C9.19197 16.2785 10.5881 18.268 14.0094 16.8463C15.1953 16.35 16.005 15.5761 16.4984 14.6737L16.5002 14.6705Z" />
    <path d="M3.11342 11.8664C3.06552 12.854 3.17525 13.8623 3.46927 14.8658C4.87895 19.6771 9.92345 22.4375 14.7356 21.0262C19.5494 19.6158 22.3066 14.5695 20.8969 9.75826C19.4856 4.9461 14.4427 2.18656 9.62893 3.59698C8.66567 3.87921 7.78656 4.30884 7.00524 4.85319C7.81315 5.90957 9.15236 6.07158 11.2132 6.23107C13.8025 6.42823 17.3462 6.69945 18.1446 11.1955C18.3968 12.6155 18.1904 13.9895 17.5441 15.1716L17.5432 15.1732C16.8968 16.3553 15.8505 17.2722 14.519 17.8262C10.3047 19.5784 8.16403 16.743 6.59986 14.6712L6.59887 14.6699C5.33843 12.9964 4.47208 11.9503 3.11342 11.8664Z" />
  </SvgIcon>
);

export default TennisIcon;