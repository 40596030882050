import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal, Button } from '@material-ui/core';
import classNames from 'classnames';
import "./vnnPaywall.css";
import ShareIcon from '@material-ui/icons/Share';
import { Calendar, PaperPlaneIcon, LeaderboardsIcon } from '../../components/icons';
import { analyticsService } from '../../services/analyticsService';
import LegacyModalContainer from '../modalContainer/legacyModalContainer';
class VNNPaywall extends Component {

    render() {
        const { title, open, toggle } = this.props;
        return(
            <div>
                <LegacyModalContainer
                    open={open}
                    onClose={toggle}
                    containerClassName={"paywallModal"}
                >
                    <div className={"learnMoreButtonContainer"}>
                        <a
                            href={'https://vnnteamelite.lpages.co/vnnteam/'}
                            target={'_blank'}
                            className={'learnMoreButton'}
                            onClick={() => analyticsService.trackScreenView('https://vnnteamelite.lpages.co/vnnteam/')}
                        >
                            Learn More
                        </a>
                    </div>
                    {this.renderContent()}
                </LegacyModalContainer>
            </div>
        )
    }

    renderContent(){
        const { title } = this.props
        switch(title){
            case 'director':
                return(
                    <div>
                        <div className={'titleTextContainer'}>
                            <p className={'directorTitleText'}>VNN Team Elite</p>
                            <p className={'directorSubtitleText'}>For Athletic Directors</p>
                            <p className={'text'}>
                                There's a lot more you can unlock in the VNN Team App with the Elite Upgrade
                                including school colors, messaging, master calendars, and content sharing.
                            </p>
                        </div>
                        <div className={'featureInfoOuterContainer'}>
                            <div className={'featureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Organization-Wide<br/>Messaging</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <PaperPlaneIcon className={'icon'}/>
                                </div>
                                <p className={'featureText'}>
                                    Bring all levels of communication to one, easy-to-use platform.
                                    Message your teams about changes to game times, practices, and much more.
                                </p>
                            </div>
                            <div className={'featureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Master<br/>Calendars</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'featureText'}>
                                    Master Calendars allow you to keep your whole school on the same page.
                                    View schedules and add events to any team in your organization.
                                </p>
                            </div>
                            <div className={'featureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Content<br/>Sharing</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <ShareIcon className={'icon'}/>
                                </div>
                                <p className={'featureText'}>
                                    Upload and share content with coaches and athletes.
                                    Streamline important administrative documents with the press of a button.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case 'leaderboards':
                return(
                    <div>
                        <div className={'titleTextContainer'}>
                            <p className={'titleText'}>VNN Team Elite</p>
                            <p className={'subtitleText'}>Team Leaderboards</p>
                            <p className={'text'}>
                                Customize workouts then track and record results to live leaderboards, creating
                                an atmosphere of accountability and competition.
                                <br />
                                <br />
                                See where athletes stack up for individual exercises and drills to monitor 
                                improvement over time.
                            </p>
                            <p className={'titleText'}>Additional Elite Features</p>
                        </div>
                        <div className={'featureInfoOuterContainer'}>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Workouts<br/>& Training</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Deliver sport-specific skills and strength videos to improve performance.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Training<br/>Libraries</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Store, distribute, and organize your custom training library of drills 
                                    and exercises then share them with your team.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Unlimited<br/>Messaging</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <PaperPlaneIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Send unlimited text messages and schedule messages for future delivery to save time.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>School<br/>Branding</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <ShareIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Update your VNN Team App experience with customized school colors and your school logo.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case 'activities':
                return(
                    <div>
                        <div className={'titleTextContainer'}>
                            <p className={'titleText'}>VNN Team Elite</p>
                            <p className={'subtitleText'}>Training Libraries</p>
                            <p className={'text'}>
                                Unlock access to strength and skill development activities. Borrow from the best trainers in the world
                                or create your own inside of your fully customizable activity library.
                                <br />
                                <br />
                                Store, distribute, and organize your custom training library of drills 
                                and exercises then share them with your team.
                            </p>
                            <p className={'titleText'}>Additional Elite Features</p>
                        </div>
                        <div className={'featureInfoOuterContainer'}>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Workouts<br/>& Training</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Deliver sport-specific skills and strength videos to improve performance.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Leaderboards</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <LeaderboardsIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Customize workouts, track results & record to live leaderboards to 
                                    create an atmosphere of accountability.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Unlimited<br/>Messaging</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <PaperPlaneIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Send unlimited text messages and schedule messages for future delivery to save time.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>School<br/>Branding</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <ShareIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Update your VNN Team App experience with customized school colors and your school logo.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case 'coaching':
                return(
                    <div>
                        <div className={'titleTextContainer'}>
                            <p className={'titleText'}>VNN Team Elite</p>
                            <p className={'subtitleText'}>Coaching Resources</p>
                            <p className={'text'}>
                                Create and organize your online coaching binder and access it from anywhere with 
                                with unlimited storage capacity.
                                <br />
                                <br />
                                Access schedules, assign workouts and collaborate with coaches anytime to deliver 
                                the tools to build a more effective athletic program.
                            </p>
                            <p className={'titleText'}>Additional Elite Features</p>
                        </div>
                        <div className={'featureInfoOuterContainer'}>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Workouts<br/>& Training</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Deliver sports-specific skills and strength videos to improve performance.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Training<br/>Libraries</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Store, distribute, and organize your custom training library of drills 
                                    and exercises then share them with your team.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Unlimited<br/>Messaging</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <PaperPlaneIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Send unlimited text messages and schedule messages for future delivery to save time.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>School<br/>Branding</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <ShareIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Update your VNN Team App experience with customized school colors and your school logo.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case 'workouts':
                return(
                    <div>
                        <div className={'titleTextContainer'}>
                            <p className={'titleText'}>VNN Team Elite</p>
                            <p className={'subtitleText'}>Workouts & Assignments</p>
                            <p className={'text'}>
                                You're the expert on what your athletes need. Take VNN's expert-built training
                                and customize each workout to your school's needs.
                                <br />
                                <br />
                                Athletes log each set from their intuitive VNN Team App, complete with 
                                technique videos and step by step instructions
                            </p>
                            <p className={'titleText'}>Additional Elite Features</p>
                        </div>
                        <div className={'featureInfoOuterContainer'}>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Leaderboards</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <LeaderboardsIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Customize workouts, track results & record to live leaderboards to 
                                    create an atmosphere of accountability.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Training<br/>Libraries</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <Calendar className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Store, distribute, and organize your custom training library of drills 
                                    and exercises then share them with your team.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>Unlimited<br/>Messaging</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <PaperPlaneIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Send unlimited text messages and schedule messages for future delivery to save time.
                                </p>
                            </div>
                            <div className={'smallFeatureInfoInnerContainer'}>
                                <div className={'featureTitleContainer'}>
                                    <p className={'featureTitleText'}>School<br/>Branding</p>
                                </div>
                                <div className={'iconContainer'}>
                                    <ShareIcon className={'icon'}/>
                                </div>
                                <p className={'smallFeatureText'}>
                                    Update your VNN Team App experience with customized school colors and your school logo.
                                </p>
                            </div>
                        </div>
                    </div>
                )
        }
    }
}

export default VNNPaywall;