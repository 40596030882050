import React from "react";
import moment from "moment";
import {
  ExpandLess,
  ExpandMore,
  Person,
  Edit,
  Close,
  NotInterested,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { FaUsers } from "react-icons/fa";
import {
  Chip,
  Avatar,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Grid,
} from "@material-ui/core";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { LocationIcon, RenameIcon } from "../icons";
import { colors } from "../../../components/v2/utils/theme-utils";
import { isEmpty } from "lodash";
import { mapboxAccessToken, isJson } from "../../../utils/commonUtil";
import { CircleOutlineIcon, CircleCheckIcon, CircleTimesIcon } from "../icons";
import { PrimaryButton } from "../../../components/v2/buttons";

const styles = (theme) => ({
  drawerPaper: {
    width: 500,
    backgroundColor: colors.WHITE,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 1000,
    }),
  },
  drawerPaperClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 2000,
    }),
    width: 0,
  },
  editButton: {
    padding: 0,
    color: colors.PRIMARY,
    textTransform: "capitalize",
    "& hover": {
      backgroundColor: "#fff",
    },
    "& svg": {
      width: 16,
      height: 16,
      marginRight: 5,
    },
  },
  cancelButton: {
    padding: 0,
    marginLeft: 20,
    color: colors.ERROR,
    textTransform: "capitalize",
    "& hover": {
      backgroundColor: "#fff",
    },
    "& svg": {
      width: 16,
      height: 16,
      marginRight: 5,
    },
  },
  location: {
    "& svg": {
      height: 20,
      width: 14,
    },
  },
  rename: {
    "& svg": {
      height: 20,
      width: 20,
    },
  },
  container: {
    padding: "6px 12px",
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: 700,
  },
  subtitle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  okButton: {
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    border: `2px solid ${colors.PRIMARY} !important`,
    color: `${colors.PRIMARY} !important`,
    "&:hover": {
      backgroundColor: "transparent",
      border: `2px solid ${colors.PRIMARY} !important`,
    },
    "&:focus": {
      backgroundColor: "transparent",
      border: `2px solid ${colors.PRIMARY} !important`,
    },
  },
  yesRrvp: {
    backgroundColor: colors.GREY_30,
    color: colors.WHITE,
    "&:hover": {
      backgroundColor: colors.GREY_30,
      border: `1px solid ${colors.GREY_30} !important`,
    },
  },
});

const viewOptions = {
  detail: "detail",
  cancel: "cancel",
  confirmationPopup: "confirmationPopup",
};

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const EventDetail = ({
  event,
  visible,
  classes,
  sendRSVP,
  navigateTo,
  handleEdit,
  handleClose,
  handleCancel,
  currentRole,
  handleChange,
  handleCollapse,
  handleFileDownload,
  collapsed,
}) => {
  let startTime, endTime, object, startDate, endDate;
  let color = [];
  if (event.when) {
    object = event.when.object;
    startTime = event.when.start_time;
    endTime = event.when.end_time;
    startDate = event.when.start_date || event.when.date;
    endDate = event.when.end_date;
  }
  if ((event.tag || {}).color) {
    color = hexToRgb((event.tag || {}).color);
  }
  const handleDisable = () => !Object.keys(event).length > 0;
  const place = isJson(event.location) ? JSON.parse(event.location) : {};
  const places = (place.place_name || "").split(", ");
  const title = places.shift();
  const subtitle = places.join(", ");
  let urls = [];
  urls = event.attachments ? JSON.parse(event.attachments) : null;
  return (
    <Drawer
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !visible && classes.drawerPaperClose
        ),
      }}
      elevation={3}
      anchor="right"
      open={visible}
    >
      <div style={{ padding: "20px 35px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button
              variant={"text"}
              classes={{
                root: classes.editButton,
              }}
              onClick={handleEdit}
              disabled={handleDisable()}
            >
              <Edit />
              Edit event
            </Button>
            <Button
              variant={"text"}
              classes={{
                root: classes.cancelButton,
              }}
              onClick={handleCancel}
              disabled={handleDisable()}
            >
              <NotInterested />
              Cancel event
            </Button>
          </div>
          <div onClick={handleClose}>
            <Close />
          </div>
        </div>
        {Object.keys(event).length ? (
          <div style={{ marginTop: 30 }}>
            <div style={{ paddingBottom: 20 }}>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  lineHeight: 2.5,
                  color: colors.BLACK,
                }}
              >
                {event.title}
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: 1.5,
                  color: colors.BLACK,
                }}
              >
                {object === "timespan"
                  ? `${moment(startTime * 1000).format(
                      "dddd, MMMM Do"
                    )} / ${moment(startTime * 1000).format(
                      "hh:mm A"
                    )} - ${moment(endTime * 1000).format("hh:mm A")}`
                  : event.eventType === "tbd"
                  ? "TBD"
                  : `${
                      endDate
                        ? `${moment(startDate).format(
                            "dddd, MMMM Do"
                          )} - ${moment(endDate).format(
                            "dddd, MMMM Do"
                          )} / All Day`
                        : `${moment(startDate).format(
                            "dddd, MMMM Do"
                          )} / All Day`
                    }`}
              </div>
            </div>
            {!isEmpty(event.tag) ? (
              <div
                style={{
                  paddingBottom: 10,
                }}
              >
                <Chip
                  style={{
                    backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.4)`,
                    color: colors.GREY_90,
                  }}
                  label={(event.tag || {}).name}
                />
              </div>
            ) : null}
            <div>
              <List>
                <ListItem disableGutters>
                  {event.user?.avatarUrl ? (
                    <Avatar src={event.user?.avatarUrl || ""} />
                  ) : (
                    <Avatar>
                      <Person />
                    </Avatar>
                  )}
                  <ListItemText
                    primary={`${
                      event.user?.firstName || event.user?.nameFirst
                    } ${event.user?.lastName || event.user?.nameLast}`}
                    secondary={"Organizer"}
                  />
                  <ListItemSecondaryAction>
                    {event.user?.rsvp ? (
                      event.user?.rsvp === "Yes" ? (
                        <CircleCheckIcon style={{ fontSize: 20 }} />
                      ) : (
                        <CircleTimesIcon style={{ fontSize: 20 }} />
                      )
                    ) : (
                      <CircleOutlineIcon
                        style={{ fontSize: 20, fill: colors.WHITE }}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
            <div style={{ paddingBottom: 15 }}>
              {(event.teams || []).map((item, index) => {
                return (
                  <List style={{ marginBottom: "-30px" }}>
                    <ListItem
                      disableGutters
                      onClick={() => handleCollapse(index, true)}
                    >
                      {item.logo ? (
                        <Avatar src={item.logo} />
                      ) : (
                        <Avatar>
                          <FaUsers />
                        </Avatar>
                      )}
                      <ListItemText primary={item.customName || item.name} />
                      {collapsed[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      key={index}
                      in={collapsed[index]}
                      timeout={{ enter: 200, exit: 200 }}
                    >
                      <List component="div" disablePadding>
                        {Object.values(
                          (item.participants || {}).participantsById || {}
                        )
                          .sort(function (a, b) {
                            return a.firstName
                              .toLowerCase()
                              .localeCompare(b.firstName.toLowerCase());
                          })
                          .map((participant, childIndex) => {
                            return (
                              <ListItem
                                key={childIndex}
                                disableGutters
                                className={classes.nested}
                              >
                                {participant?.avatarUrl ? (
                                  <Avatar src={participant?.avatarUrl || ""} />
                                ) : (
                                  <Avatar>
                                    <Person />
                                  </Avatar>
                                )}
                                <ListItemText
                                  primary={`${participant.firstName} ${participant.lastName}`}
                                  secondary={participant.role}
                                />
                                <ListItemSecondaryAction>
                                  {participant?.rsvp ? (
                                    participant?.rsvp === "Yes" ? (
                                      <CircleCheckIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    ) : (
                                      <CircleTimesIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    )
                                  ) : (
                                    <CircleOutlineIcon
                                      style={{
                                        fontSize: 20,
                                        fill: colors.WHITE,
                                      }}
                                    />
                                  )}
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                      </List>
                      {Object.values(
                        (item.participants || {}).groupsById || {}
                      ).map((group, groupIndex) => {
                        return (
                          <List
                            key={groupIndex}
                            component="div"
                            disablePadding
                            style={{ padding: "0px 20px" }}
                          >
                            <ListItem
                              style={{ padding: 5 }}
                              disableGutters
                              onClick={() => handleCollapse(index, true)}
                            >
                              <Avatar>
                                <FaUsers />
                              </Avatar>

                              <ListItemText primary={group.name} />
                              {collapsed[index] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              key={index}
                              in={collapsed[index]}
                              timeout={{ enter: 200, exit: 200 }}
                            >
                              <List
                                component="div"
                                disablePadding
                                style={{ padding: "0px 5px" }}
                              >
                                {Object.values(group.participantsById || {})
                                  .sort(function (a, b) {
                                    return a.firstName
                                      .toLowerCase()
                                      .localeCompare(b.firstName.toLowerCase());
                                  })
                                  .map((participant, childIndexGroup) => {
                                    return (
                                      <ListItem
                                        key={childIndexGroup}
                                        disableGutters
                                        className={classes.nested}
                                      >
                                        {participant?.avatarUrl ? (
                                          <Avatar
                                            src={participant?.avatarUrl || ""}
                                          />
                                        ) : (
                                          <Avatar>
                                            <Person />
                                          </Avatar>
                                        )}
                                        <ListItemText
                                          primary={`${participant.firstName} ${participant.lastName}`}
                                          secondary={participant.role}
                                        />
                                      </ListItem>
                                    );
                                  })}
                              </List>
                            </Collapse>
                          </List>
                        );
                      })}
                    </Collapse>
                  </List>
                );
              })}
            </div>
            {(place || {}).place_name ? (
              <div style={{ paddingBottom: 20, paddingTop: 30 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: 20,
                  }}
                  className={classes.location}
                >
                  <LocationIcon size={14} fill={colors.GREY_40} />
                  <div className={classes.container}>
                    <div
                      style={{
                        fontSize: 14,
                        lineHeight: 1.5,
                        color: colors.BLACK,
                      }}
                    >
                      {title}
                    </div>
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        color: colors.GREY_40,
                      }}
                    >
                      {subtitle}
                    </div>
                  </div>
                </div>
                <Map
                  initialViewState={{
                    longitude: (place.center || [])[0],
                    latitude: (place.center || [])[1],
                    zoom: 5,
                  }}
                  style={{
                    height: 120,
                    width: "100%",
                    borderRadius: 4,
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  mapboxAccessToken={mapboxAccessToken}
                >
                  <Marker
                    longitude={(place.center || [])[0]}
                    latitude={(place.center || [])[1]}
                    anchor="center"
                  />
                </Map>
              </div>
            ) : null}
            {event.message ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: 20,
                }}
                className={classes.rename}
              >
                <RenameIcon size={20} fill={colors.GREY_40} />
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 1.5,
                    color: colors.GREY_70,
                    paddingLeft: 10,
                  }}
                >
                  {event.message}
                </div>
              </div>
            ) : null}
            {event.externalURL ? (
              <div style={{ wordWrap: "break-word", paddingBottom: 20 }}>
                <a
                  href={event.externalURL}
                  target="_blank"
                  style={{
                    color: colors.PRIMARY,
                    marginLeft: 0,
                    fontSize: 12,
                  }}
                >
                  {event.externalURL}
                </a>
              </div>
            ) : null}
            {(urls || []).length ? (
              <div item xs={12} style={{ marginBottom: 10 }}>
                <p style={{ margin: 0, fontSize: 12, color: colors.GREY_40 }}>
                  ATTACHED FILES
                </p>
                {(urls || []).map((item, index) => {
                  let filename =
                    typeof item === "string"
                      ? item.substring(item.lastIndexOf("/") + 1)
                      : item.name;
                  return (
                    <Chip
                      key={index}
                      variant={"filled"}
                      label={filename}
                      onClick={() =>
                        typeof item === "string"
                          ? handleFileDownload(item, filename)
                          : {}
                      }
                      className={classes.chip}
                      style={{
                        margin: 5,
                      }}
                    />
                  );
                })}
              </div>
            ) : null}
            <div className="d-flex justify-content-center mt-5">
              <div className={`${classes.okButtonWrapper} m-2`}>
                <PrimaryButton
                  onClick={() => sendRSVP("No")}
                  style={{ height: 52, width: "100%" }}
                  className={classes.okButton}
                >
                  NOT GOING
                </PrimaryButton>
              </div>
              <div className={`${classes.okButtonWrapper} m-2`}>
                <PrimaryButton
                  onClick={() => sendRSVP("Yes")}
                  style={{ height: 52, width: "100%" }}
                  className={classes.okButton}
                >
                  GOING
                </PrimaryButton>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
};

export default withStyles(styles)(EventDetail);
