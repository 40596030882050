import React, { Component } from 'react'
import './styles.css'
import CheckBox from '../checkBox'

export default class FilterOption extends Component {
    render(){
        const { title, details = '(2)', onClick, checked } = this.props
        return (
            <div onClick={onClick} className={'filterOptionOuterContainer'}>
                <div className={'checkTitleContainer'}>
                    <CheckBox checked={checked} />
                    <div className={'filterOptionTitle'}>{title}</div>
                    <div className={'filterOptionDetails'}>{details}</div>
                </div>
            </div>
        )
    }
}