import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash"
import { PrimaryButton, InputField } from 'maxone-react-ui'
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Material UI
import {
    Button,
    Checkbox,
    Grid,
    Paper,
    TextField,
    Typography,
    Divider,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    MenuItem,
    FormControl,
    Select,
    Switch,
    Input,
    InputLabel,
    CircularProgress
  } from "@material-ui/core";

// Icons
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { setUserContext, setAppContext } from '../../actions/session';

import { getLocalizedString } from '../../utils/locale-utils';

import { API, Auth } from "aws-amplify";

import { withStyles } from '@material-ui/core/styles';
import { s3Upload } from "../../libs/awsLib";
import config from "../../config"


import ImageCropperModal from '../../components/modals/imageCropperModal'

import "./settings.css";

import { WhiteLabelBIABClient } from 'maxone-api-client'
import PageWrapper from '../nav/pageWrapper';
import { ORG_PLANS } from '../../constants'
import OrgMetrics from './orgMetrics';
const client = new WhiteLabelBIABClient();

const styles = theme => ({
});


function TabContainer({ children }) {
  return (
    <Typography component="div" style={{padding: 15}}>
      {children}
    </Typography>
  );
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionPlans:[],
      showAddDepositInfo:false,
      account:{
        external_accounts:{},
        requirements:{}
      },
      monthly:"",
      quarterly:"",
      yearly:"",
      checkedMonthly:false,
      checkedQuarterly:false,
      checkedYearly:false,
      teamsCanOverridePricing:true,
      openModal:false,
      openTeamModal: false,
      isSubmitting: false,
      sentInfo: false,
      sendingInvites: false,
      modalType: "athletes",
      isDirty: false,
      isLoading: false,
      tabValue:0,
      fileName: "",
      attachment: null,
      isCropperModalOpen: false,
      organization:{
        name:"",
        type:"",
        address1:"",
        address2:"",
        postalCode:"",
        city:"",
        state:"",
        postalCode:""
      },
      expMonth:"",
      expYear:"",
      cvc:"",
      number:"",
      coaches:[],
      contacts: [],
      users: [],
      teams:[],
      teamContacts:[],
      teamId:"",
      order: 'asc',
      orderBy: 'nameFirst',
      selected: "",
      page: 0,
      open: false,
      rowsPerPage: 50,
      billingMethodSelected:"card",
      Transition: TransitionUp,
      subscriptionDetailsText: ''
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { appContextList, currentTeam,  } = this.props
    this.setState({checkedMonthly:false, checkedQuarterly:false, checkedYearly:false})
    this.setState({monthly:"", quarterly:"", yearly:""})
    var teamId = this.props.currentTeam.id
    //get organization using current team.
    let orgId = currentTeam.organizationId;
    let coachDataPromise = async (res) => {
      let coaches = [];
      try{
        const coachesRaw = await this.getCoachesByOrg(orgId);
        coaches = await this.getUniqueRoles(coachesRaw);
      }
      catch(e){
        console.log(e);
      }
      return coaches
    };
    let [organization, coaches, teams] = await Promise.all([this.getOrganization(orgId), coachDataPromise(),this.getTeamsByOrganization(orgId),this.refreshSubscriptionPlans(orgId)])    
    console.log('organization ', organization)
    if(organization.isPaid){
      let account = await this.getStripeAccount(organization.id);
      console.log('account ', account)
      if(account){
        if(!account.external_accounts.total_count){
          this.setState({account, showAddDepositInfo: true, tabValue:3})
        }
        else {
          this.setState({account})
        }
      }
      else{
        organization.isPaid = false
      }
    }
    if(!organization.billing){
      organization.billing = { isLockedOut: false };
    }
    else {
      if(organization.billing.isAutomatedBilling) {
        this.setState({billingMethodSelected:"invoice"})
      }

      if(organization.billing.contacts) {
        this.setState({billing:organization.billing.contacts[0]})
      }

      if(organization.billing.methods) {
        this.setState({expMonth:organization.billing.methods[0].expMonth, expYear:organization.billing.methods[0].expYear})
      }
    }

    this.setState({
      isLoading:false,
      teams: teams ||[],
      teamId,
      coaches,
      organization,
      team:currentTeam,
      subscriptionDetailsText: organization.subscriptionDetailsText ? organization.subscriptionDetailsText : '',
      teamsCanOverridePricing: organization.teamsCanOverridePricing ? organization.teamsCanOverridePricing : false
    })
  }

  refreshSubscriptionPlans = async (organizationId) => {
    let subscriptionPlans = await this.getSubscriptionPlans(organizationId)
    if(subscriptionPlans.plans.length){
      let plans = _.filter(subscriptionPlans.plans, {product: "prod_" + organizationId})
      this.setState({subscriptionPlans:plans})
      const isMonthly = _.find(plans, {interval: "month", interval_count:1})
      if(isMonthly){
        this.setState({checkedMonthly: true, monthly: isMonthly.originalAmount / 100})
      }
      const isQuarterly = _.find(plans, {interval: "month", interval_count:3})
      if(isQuarterly){
        this.setState({checkedQuarterly: true, quarterly: isQuarterly.originalAmount / 100})
      }
      const isYearly = _.find(plans, {interval: "year", interval_count:1})
      if(isYearly){
        this.setState({checkedYearly: true, yearly: isYearly.originalAmount / 100})
      }
    }
  }

  toggleAthleteInviteModal = async() => {
      const { organization } = this.state;
      this.setState({ openModal: !this.state.openModal, modalType:"athletes", isLoading: true });

      const wcsOrgId = organization.id.split("-")[1]
      let users = [];
      let students = localStorage.getItem(`@wcs:students-${wcsOrgId}:`, null)
      console.log("students == ", students)
      if(students && JSON.parse(students).length){
        users = JSON.parse(students)
      }
      else{
        users = await this.getOrganizationAthletes(organization.id)
        localStorage.setItem(`@wcs:students-${wcsOrgId}:`, JSON.stringify(users))
      }
      this.setState({
          users: users,
          isLoading: false
      });
  }
  toggleAllAthletes = async() => {
      const { organization } = this.state;
      this.setState({ modalType:"athletes", isLoading: true, isSubmitting: true, sendingInvites: false, sentInfo:false });

      const wcsOrgId = organization.id.split("-")[1]
      let users = [];
      let students = localStorage.getItem(`@wcs:students-${wcsOrgId}:`, null)
      console.log("students == ", students)
      if(students && JSON.parse(students).length){
        users = JSON.parse(students)
      }
      else{
        users = await this.getOrganizationAthletes(organization.id)
        localStorage.setItem(`@wcs:students-${wcsOrgId}:`, JSON.stringify(users))
      }
      this.setState({
          contacts: users,
          isLoading: false
      });
  }

  toggleCoachInviteModal = async() => {
      const { organization } = this.state;
      this.setState({ users:[], openModal: !this.state.openModal, modalType:"coaches", isLoading: true });
      // if(!this.state.users){
      let wcsCoaches = [];
      wcsCoaches = await this.getOrganizationCoaches(organization.id)
      this.setState({
          users: wcsCoaches,
          isLoading: false
      });
      // }
  }
  toggleAllCoaches = async() => {
      const { organization } = this.state;
      this.setState({ modalType:"coaches", isLoading: true, isSubmitting: true, sendingInvites: false, sentInfo:false });
      // if(!this.state.users){
      let wcsCoaches = [];
      wcsCoaches = await this.getOrganizationCoaches(organization.id)
      this.setState({
          contacts: wcsCoaches,
          isLoading: false
      });
      // }
  }
  toggleParentInviteModal = async() => {
      const { organization } = this.state;
      this.setState({ users:[], openModal: !this.state.openModal, modalType:"parents", isLoading: true });
      // if(!this.state.users){
      const wcsOrgId = organization.id.split("-")[1]
      let wcsParents = [];
      let parents = localStorage.getItem(`@wcs:parents-${wcsOrgId}:`, null)
      console.log("parents == ", parents)
      if(parents && JSON.parse(parents).length){
        wcsParents = JSON.parse(parents)
      }
      else{
        wcsParents = await this.getOrganizationParents(organization.id)
        localStorage.setItem(`@wcs:parents-${wcsOrgId}:`, JSON.stringify(wcsParents))
      }
      this.setState({
          users: wcsParents,
          isLoading: false
      });
      // }
  }
  toggleAllParents = async() => {
      const { organization } = this.state;
      this.setState({ modalType:"parents", isLoading: true, isSubmitting: true, sendingInvites: false });
      // if(!this.state.users){
      const wcsOrgId = organization.id.split("-")[1]
      let wcsParents = [];
      let parents = localStorage.getItem(`@wcs:parents-${wcsOrgId}:`, null)
      console.log("parents == ", parents)
      if(parents && JSON.parse(parents).length){
        wcsParents = JSON.parse(parents)
      }
      else{
        wcsParents = await this.getOrganizationParents(organization.id)
        localStorage.setItem(`@wcs:parents-${wcsOrgId}:`, JSON.stringify(wcsParents))
      }
      this.setState({
          contacts: wcsParents,
          isLoading: false
      });
      // }
  }
  handleAddContactToList = async (user) => {
    const { contacts } = this.state;
    this.setState({contacts: [...contacts, user]})
  }
  handleAddTeamContactToList = async (team) => {
    const { teamContacts } = this.state;
    this.setState({teamContacts: [...teamContacts, team]})
  }

  removeContactFromList = contact => {
      this.setState({ contacts:  _.without(this.state.contacts, contact)});
  }
  removeTeamContactFromList = contact => {
      this.setState({ teamContacts:  _.without(this.state.teamContacts, contact)});
  }

  createSubscriptionPlans = async(organizationId, plans) => {
      try {
          return await API.post("subscriptions", `/subscriptions/plan`, {body: {organizationId, plans}});
      }
      catch(e){ return false}
  }

  updateSubscriptionPlans = async(organizationId, amountOriginal, amountNew, interval, intervalCount) => {
      try {
          return await API.post("subscriptions", `/subscriptions/plan/update`, {body: {organizationId, amountOriginal, amountNew, interval, intervalCount}});
      }
      catch(e){ return false}
  }
  deleteSubscriptionPlan = async(organizationId, planAmount) => {
      try {
          return await API.del("subscriptions", `/subscriptions/plan`, {body: {organizationId, planAmount}});
      }
      catch(e){ return false}
  }

  updateOrganization = async (organization) => {
    console.log('organization ', organization)
    try {
        return await API.post("organizations", `/organizations`, {body:organization});
    }
    catch(e){ return false}
  }

  getSubscriptionPlans = async(organizationId) => {
      try {
          return await API.get("subscriptions", `/subscriptions/organization/${organizationId}/plans`);
      }
      catch(e){ return false }
  }

  getStripeAccount = async(organizationId) => {
      try {
          return await API.get("subscriptions", `/organizations/${organizationId}/accounts`);
      }
      catch(e){ return false }
  }

  saveExternalAccount = async(accountId, accountInfo) => {
      try {
          return await API.post("subscriptions", `/accounts/${accountId}/external`, {body: {accountInfo}});
      }
      catch(e){ return false }
  }

  getOnboardingLink = async(organizationId, data) => {
      try {
          return await API.post("subscriptions", `/organizations/${organizationId}/accounts/onboarding`, {body: data});
      }
      catch(e){ return false }
  }

  formatPhoneNumber(phoneNumber) {
    let areaCode = phoneNumber.substring(0,3);
    let nextThree = phoneNumber.substring(3,6);
    let finalFour = phoneNumber.substring(6,10);
    return `${areaCode}-${nextThree}-${finalFour}`;
  }

  getNameFromParentId = (appContextList, parentId) => {
    var target = _.find(appContextList, c => c.id === parentId);
    if(!!target){
      if(target.isTeam) {
        return `${target.name} ${target.sport}`;
      } else {
        return target.name + " Organization";
      }
      return "";
    }
  }

  getUniqueRoles = async (coaches) => {
    const coachesWithUniqRoles = await _.forEach(coaches, (coach)=> {
      const uniqRoles = _.uniqBy(coach.roles, (r)=>{return r.parentId;});
      coach.roles = uniqRoles;
    })
    return coachesWithUniqRoles;
  }

  handleClick = (event, id) => {
      if(this.state.selected === id){
        this.setState({ selected: "" });
      } else {
        this.setState({ selected: id });
      }
  };

  toggleModal = () => {
    this.setState({ isCropperModalOpen: !this.state.isCropperModalOpen })
  }

  getCroppedAttachment = async (croppedAttachment) => {
    let { organization } = this.state;

    organization.logo = croppedAttachment;
    this.setState({ organization });

    let savedOrg = await this.saveOrganization();

    this.setState({ isCropperModalOpen: false });
  }

  handleFileChange = async(event) => {
    const { organization } = this.state;
    const idForS3 = organization.legacyId && organization.legacyId != 0 ? organization.legacyId : organization.id;
    this.setState({ isLoading: true, message: 'uploading your image...' })
    this.file = event.target.files[0];
    const fileStructure = `uploads/organization/logo/${idForS3}/`;
    const attachment = this.file ? await s3Upload(this.file, fileStructure) : null;

    this.setState({
        isLoading:false,
        isCropperModalOpen: true,
        attachment: attachment,
        fileName: this.file.name
    })
  }

  handleSubmitSelected = async () => {
    this.setState({isSubmitting: true, sendingInvites: true, openModal:false,})
    const { contacts, currentTeam, modalType, organization } = this.state;

    let added = []
    let response = {};

    response.failures = [];
    response.successCount = 0;
    response.failureCount = 0;
    response.existsCount = 0;

    await this.asyncForEach(contacts, async user =>{
      let userType = modalType === "athletes" ? "athlete" : modalType === "coaches" ? "coach" : modalType === "parents" ? "parent" : null;
      if(userType){
        let loginInfo = await this.sendLoginInfo(organization, "user", userType, user.id, [user.id]);
        if(loginInfo.failures){
          response.failures = response.failures.concat(loginInfo.failures)
        }
        if(loginInfo.successCount){
          response.successCount = response.successCount + loginInfo.successCount
        }
        if(loginInfo.failureCount){
          response.failureCount = response.failureCount + loginInfo.failureCount
        }
        if(loginInfo.existsCount){
          response.existsCount = response.existsCount + loginInfo.existsCount
        }
        added.push(user)
      }
    })
    this.setState({contacts: [], users:[], sentInfo: response})

  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  async sendInvitesToTeams(){
    this.setState({openTeamModal:false, isSubmitting: true, sendingInvites: false, isLoading:true})
    const { teamContacts, modalType } = this.state;

    let userType = modalType === "athletes" ? "athlete" : modalType === "coaches" ? "coach" :  null;
    let contacts = [];
    if(userType){
      await this.asyncForEach(teamContacts, async team =>{
          if(userType === "athlete"){
            let users = await this.getAthletes(team.id);
            if(users){
              contacts = contacts.concat(users)
            }
          }
          if(userType === "coach"){
            let users = await this.getCoaches(team.id);
            if(users){
              contacts = contacts.concat(users)
            }
          }
      })
    }
    // remove duplicates
    contacts = _.compact(contacts)
    contacts = _.uniqBy(contacts, 'id')
    this.setState({contacts, isLoading:false})
  }

  getAthletes(id){
      const teamId = id ? id : this.props.currentTeam.id;
      return Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
        }
        return API.get("programs", `/programs/${teamId}/players`);
      })
  }
  getCoaches(id){
      const teamId = id ? id : this.props.currentTeam.id;
      return Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
        }
        return API.get("programs", `/programs/${teamId}/coaches`);
      })
  }

  async handleDeleteOwnerRole(roles) {
    const { currentTeam } = this.props;
    const role = _.find(roles, function(result) { return result.role === "owner"; })
    const response = await this.deleteRole(role.id)
    const coaches = await this.getCoachesByOrg(currentTeam.organizationId)
    this.setState({coaches})
  };

  async handleAddOwnerRole(user) {
    const { currentTeam } = this.props;
    var role = {
      parentId:currentTeam.organizationId,
      userId:user.id,
      role:"owner"
    }
    const response = await this.createRole(role)
    const coaches = await this.getCoachesByOrg(currentTeam.organizationId)
    this.setState({coaches})
  };

  async handleDemoteCoachRole(user) {
    const { currentTeam } = this.props;
    const currentRole = _.find(user.roles, function(result) { return result.role === "head-coach"; })
    var role = {
      parentId:currentTeam.id,
      userId:user.id,
      id:currentRole.id,
      role:"coach"
    }
    if(currentRole){
      role.id = currentRole.id
    }
    const response = await this.createRole(role)
    const coaches = await this.getCoachesByOrg(currentTeam.organizationId)
    this.setState({coaches})
  };

  async handlePromoteHeadCoachRole(user) {
    const { currentTeam } = this.props;
    const currentRole = _.find(user.roles, function(result) { return result.role === "coach"; })
    var role = {
      parentId:currentTeam.id,
      userId:user.id,
      role:"head-coach"
    }
    if(currentRole){
      role.id = currentRole.id
    }
    const response = await this.createRole(role)
    const coaches = await this.getCoachesByOrg(currentTeam.organizationId)

    this.setState({coaches})
  };

  handleChangeOrganization = name => event => {
    let organization = this.state.organization;
    if(name == 'isLockedOut')
      organization.billing[name] = organization.billing.isLockedOut ? false : true;
    else{
      organization[name] = event.target.value
    }
    this.setState({ organization, isDirty:true });
  };

  handleChangeBilling = name => event => {
    const { billing } = this.state;
    billing[name] = event.target.value;
    this.setState(billing);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleChangeInput = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  hideSnackBar(){
    setTimeout(() => this.setState({open:false}), 2000)
  }

  async submitSubscriptionPlans (){
    const { monthly, quarterly, yearly } = this.state;
    const { checkedMonthly, checkedQuarterly, checkedYearly, subscriptionDetailsText } = this.state;
    if((monthly && monthly < 1 && checkedMonthly) || (quarterly && quarterly < 1 && checkedQuarterly) || (yearly && yearly < 1 && checkedYearly)){
      this.setState({
        open: true,
        message: 'Subscription value should be greater than 1 dollar.',
      })
      this.hideSnackBar();
      return;
    }
    this.setState({isLoading: true, open:true, message:'Saving your subscription. Please Wait.' })
    const { subscriptionPlans } = this.state;
    const isMonthly = _.find(subscriptionPlans, {interval: "month", interval_count:1})
    console.log('isMonthly', isMonthly)
    await client.updteConfigsByTenant(this.state.organization.tenantId, { subscriptionDetailsText })
    if(isMonthly){ // existing monthly subscription plan.
      // Check to see if checkedMonthly is true.   If not, then delete subscription plan. return
      if(!checkedMonthly){
        await this.deleteSubscriptionPlan(this.state.organization.id, isMonthly.originalAmount)
      }
      else{
        //compare new price with old price.   If new, then update price.  Return.
        const nonDecimalMonthlyPrice = parseFloat(monthly) * 100;
        if(parseInt(nonDecimalMonthlyPrice) !== isMonthly.originalAmount){
          await this.updateSubscriptionPlans(this.state.organization.id, isMonthly.originalAmount, nonDecimalMonthlyPrice, "month", 1)
        }
        else{
          // if same price, do nothing and move on.
          console.log('theyre the same price.   moving on.')
        }
      }
    }
    else{
      // if checkedMonthly is true, then create subscription plan.
      if(checkedMonthly){
        const nonDecimalMonthlyPrice = parseFloat(monthly) * 100;
        if(nonDecimalMonthlyPrice){
          const newPlan = {
            interval: "month",
            interval_count:1,
            currency:"usd",
            amount_decimal: nonDecimalMonthlyPrice
          }
          await this.createSubscriptionPlans(this.state.organization.id, [newPlan])
        }
      }
      else{
        // else move on
        console.log('nothing to do here')
      }
    }
    const isQuarterly = _.find(subscriptionPlans, {interval: "month", interval_count:3})
    console.log('isQuarterly', isQuarterly)
    if(isQuarterly){ // existing monthly subscription plan.
      // Check to see if checkedMonthly is true.   If not, then delete subscription plan. return
      if(!checkedQuarterly){
        await this.deleteSubscriptionPlan(this.state.organization.id, isQuarterly.originalAmount)
      }
      else{
        //compare new price with old price.   If new, then update price.  Return.
        const nonDecimalQuarterlyPrice = parseFloat(quarterly) * 100;
        if(parseInt(nonDecimalQuarterlyPrice) !== isQuarterly.originalAmount){
          await this.updateSubscriptionPlans(this.state.organization.id, isQuarterly.originalAmount, nonDecimalQuarterlyPrice, "month", 3)
        }
        else{
          // if same price, do nothing and move on.
          console.log('theyre the same price.   moving on.')
        }
      }
    }
    else{
      // if checkedMonthly is true, then create subscription plan.
      if(checkedQuarterly){
        const nonDecimalQuarterlyPrice = parseFloat(quarterly) * 100;
        if(nonDecimalQuarterlyPrice){
          const newPlan = {
            interval: "month",
            interval_count:3,
            currency:"usd",
            amount_decimal: nonDecimalQuarterlyPrice
          }
          await this.createSubscriptionPlans(this.state.organization.id, [newPlan])
        }
      }
      else{
        // else move on
        console.log('nothing to do here')
      }
    }

    const isYearly = _.find(subscriptionPlans, {interval: "year", interval_count:1})
    if(isYearly){ // existing monthly subscription plan.
      // Check to see if checkedMonthly is true.   If not, then delete subscription plan. return
      if(!checkedYearly){
        await this.deleteSubscriptionPlan(this.state.organization.id, isYearly.originalAmount)
      }
      else{
        //compare new price with old price.   If new, then update price.  Return.
        const nonDecimalYearlyPrice = parseFloat(yearly) * 100;
        if(parseInt(nonDecimalYearlyPrice) !== isYearly.originalAmount){
          await this.updateSubscriptionPlans(this.state.organization.id, isYearly.originalAmount, nonDecimalYearlyPrice, "year", 1)
        }
        else{
          // if same price, do nothing and move on.
          console.log('theyre the same price.   moving on.')
        }
      }
    }
    else{
      // if checkedMonthly is true, then create subscription plan.
      if(checkedYearly){
        const nonDecimalYearlyPrice = parseFloat(yearly) * 100;
        if(nonDecimalYearlyPrice){
          const newPlan = {
            interval: "year",
            interval_count:1,
            currency:"usd",
            amount_decimal: nonDecimalYearlyPrice
          }
          await this.createSubscriptionPlans(this.state.organization.id, [newPlan])
        }
      }
      else{
        // else move on
        console.log('nothing to do here')
      }
    }

    await this.refreshSubscriptionPlans(this.state.organization.id);
    this.setState({ isLoading: false, open:true, message:'Success! Your subscription prices were saved.' });
    this.hideSnackBar();
  }

  async saveOrganization() {
    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    } else {
      console.log('saveOrganization ==> file', this.file)
    }

    this.setState({ isLoading: true, loadingMessage:"Saving..." });

    let organization = this.state.organization;

    try {

      // if(this.state.attachment) {
      //   organization.logo = this.state.attachment;
      // }

      var cleanOrganization = await this.cleanObjectForDynamoDb(organization);
      var request = await this.updateOrganization(cleanOrganization);
      var userContext = this.props.userContext;
      var org = _.find(userContext.appContextList, function(data){ return data.id === organization.id})

      if(org) {
        var index = userContext.appContextList.indexOf(org)
        userContext.appContextList.splice(index, 1, organization)
        this.props.setUserContext(userContext);
      }

      this.setState({ open:true, message:`Successfully Updated ${organization.name}`, attachment:null });
      this.hideSnackBar();

    } catch(e) {
      this.setState({open:true, message:'Oops, something went wrong!'})
      this.hideSnackBar();
    }
    this.setState({ isLoading: false, loadingMessage:"" });
  }

  async saveBankInfo() {
    this.setState({ isLoading: true });
    let externalAccount = this.state.account;
    const currency = externalAccount.default_currency;
    const country = externalAccount.country;
    const object="bank_account";

    try {
      let returned = await this.saveExternalAccount(externalAccount.id, {
        currency,
        country,
        object,
        routing_number: this.state.routingNumber,
        account_number: this.state.accountNumber,
        metadata: {
          organizationId: this.state.organization.id,
          tenantId:this.state.organization.tenantId
        }
      })
      console.log('returned ',returned)
      if(returned.error){
        console.log("ERROR  ", returned.error)
        this.setState({isLoading: false, open:true, message:returned.error.message})
        this.hideSnackBar();
        return
      }
      let account = await this.getStripeAccount(this.state.organization.id);
      console.log('account ', account)
      this.setState({account})
      if(account.external_accounts.total_count){
        this.setState({ showAddDepositInfo: false})
      }
      this.setState({ isLoading: false,  open:true, message:`Successfully saved bank info`});
      this.hideSnackBar();

    } catch(e) {
      console.log("ERROR  ", e)
      this.setState({isLoading: false, open:true, message:'Oops, something went wrong!'})
      this.hideSnackBar();
    }
  }

  async verify(){
    let data = {
      origin: window.location.origin,
      path: window.location.pathname,
    }
    let link = await this.getOnboardingLink(this.state.organization.id, data)
    console.log('link ', link)
    if(link.url){
      window.open(link.url, '_blank');
    }
    else{
      this.setState({open:true, message:'Oops, something went wrong!'})
      this.hideSnackBar();
    }
  }

  handleTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  getOrganizationAthletes(id){
      const orgId = id ? id : this.props.currentTeam.organizationId;
      return Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
        }
        return API.get("organizations", `/organizations/${orgId}/athletes`);
      })
  }

  getOrganizationCoaches(id){
      const orgId = id ? id : this.props.currentTeam.organizationId;
      return Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
        }
        return API.get("organizations", `/organizations/${orgId}/coaches`);
      })
  }
  getOrganizationParents(id){
      const orgId = id ? id : this.props.currentTeam.organizationId;
      return Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
        }
        return API.get("wcs", `/wcs/organizations/${orgId}/parents`);
      })
  }

  async sendLoginInfo(organization, sendToType, userType, sendTo, userIds){
      return Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken,
          },
        }
        return API.post("wcs", `/wcs/invites`, {
          body:{
            sentBy: organization,
            sendToType: sendToType,
            userType: userType,
            sendTo: sendTo,
            userIds: userIds
          }
        });
      })
  }

  getTeamsByOrganization(organizationId) {
      return API.get("programs", `/programs/organization/${organizationId}`);
  }

  deleteRole(id) {
    return API.del("users", `/users/roles/${id}`);
  }

  createRole(role) {
    return API.post("users", `/users/${role.userId}/roles`,{
      body:role
    });
  }

  getOrganization(organizationId) {
    return API.get("organizations", `/organizations/${organizationId}`);
  }

  getCoachesByOrg(organizationId) {
    return API.get("organizations", `/organizations/${organizationId}/coaches`);
  }

  subscribe(){
    const { currentUser } = this.props;
    const { organization, billingMethodSelected } = this.state
    return API.post("billing", `/billing/subscribe`,{
      body: {
        user: currentUser,
        organization: organization.id,
        billingType: billingMethodSelected
      }
    });
  }

  createCustomer(){
    const { organization } = this.state;
    return API.post("billing", `/billing/customer`,{
      body: {
        organization: organization.id
      }
    });
  }

  async cleanObjectForDynamoDb(object) {
    const keys = Object.keys(object);
    return new Promise(function(resolve) {
      for(var i = 0; i < keys.length; i++) {
        const value = object[keys[i]];
        if(!_.isBoolean(value) && !value) {
          object[keys[i]] = null;
        }
      }
      return resolve(object);
    });
  }

  async updateOrganization(organization) {
    const cleanOrganization = await this.cleanObjectForDynamoDb(organization);
    return API.post("organizations", `/organizations`,{
      body: cleanOrganization
    });
  }

  handleSubscribe = async () => {
    const { billingMethodSelected } = this.state;
    this.setState({
      billing:{
        contactName:"",
        contactEmail:"",
        contactPhone:""
      },
      expMonth:"",
      expYear:"",
      cvc:"",
      number:"",
    })
    const organization = await this.saveOrganization();
    const stripeCustomer = billingMethodSelected == 'card' ? await this.createCustomer() : null;
    const subscription = await this.subscribe();
    console.log(subscription)  // TODO: Add analytics track code here
  }

  getPlanById(planId){
		return _.find(ORG_PLANS, p => p.id == planId);
	}

  isSelected = id => id === this.state.selected;

  render() {
    const { classes, currentTeam={}, theme, tenant, userContext } = this.props;
    const { tabValue, fileName, isCropperModalOpen, attachment, isLoading, loadingMessage="Loading" } = this.state;

    return (
      <PageWrapper title={getLocalizedString("ORGANIZATION_SETTINGS")} {...this.props} isPageLoading={isLoading} loadingMessage={loadingMessage}>
      <div className={"settingsOuterContainer"}>
        <ImageCropperModal
            fileName={fileName}
            close={this.toggleModal}
            open={isCropperModalOpen}
            title={"Crop Image"}
            attachment={attachment}
            getCroppedAttachment={this.getCroppedAttachment}
        />
        <Snackbar
            open={this.state.open}
            TransitionComponent={this.state.Transition}
            ContentProps={{
              'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{this.state.message}</span>}
            action={[
              <IconButton key="close" className={classes.close} onClick={() => this.setState({open:false, message:""})}>
                <CloseIcon className={classes.icon}/>
              </IconButton>
            ]}
          />
          <Snackbar
              open={this.state.open}
              TransitionComponent={this.state.Transition}
              ContentProps={{
                'aria-describedby': 'message-id',
                }}
              message={<span id="message-id">{this.state.message}</span>}
              action={[
                <IconButton key="close" className={classes.close} onClick={() => this.setState({open:false, message:""})}>
                  <CloseIcon className={classes.icon}/>
                </IconButton>
              ]}
            />

      {
        this.state.tabValue === 0
        ?
        <div className={"pageTitleContainer"}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={()=>{
              if(window.location.href.toLowerCase().includes('vnn')){
                return window.location = 'https://admin.getvnn.com/teams/add'
              }
              if(window.location.href.toLowerCase().includes('wcs') || userContext.isWCSAdmin){
                return this.props.history.push('/truelacrosse/new')
              }
              this.props.history.push('/newTeam')
            }}
          >
            Add Team
          </Button>
        </div>
        :
        <div className={"pageTitleContainer"}>
          <h2 className={"pageTitle"}></h2>
        </div>
      }
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper style={{ minHeight: 500 }}>
              <div style={{borderBottom:'1px solid lightgray'}}>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >

                  <Tab value={0} label={getLocalizedString("ORGANIZATION")} />
                  {/* {(tenant === 'wcs' || userContext.isWCSAdmin) && <Tab value={2} label="WCS Logins" />} */}
                  {/* {tenant !== 'coachup' && <Tab value={1} label={getLocalizedString("PERMISSIONS")} />} */}
                  {this.state.organization.isPaid && <Tab value={2} label={getLocalizedString("SUBSCRIPTION_OPTIONS")} />}
                  {this.state.organization.isPaid && <Tab value={3} style={{color:this.state.account.requirements.currently_due.length?"red":null}} label={getLocalizedString("PAYOUT_OPTIONS")} />}
                </Tabs>
              </div>
              {tabValue === 0 && <TabContainer>
                {this.renderOrganization()}
              </TabContainer>}
              {/* {tabValue === 1 && <TabContainer>
                {this.renderPermissions()}
              </TabContainer>} */}
              {tabValue === 2 && this.state.organization.isPaid && <TabContainer>
                {this.renderSubscriptionOptions()}
              </TabContainer>}
              {tabValue === 3 && this.state.organization.isPaid && <TabContainer>
                {this.renderPayoutOptions()}
              </TabContainer>}
            </Paper>
          </Grid>
        </Grid>
        <OrgMetrics />
      </div>
      </PageWrapper>
    );
  }

  renderOrganization(){
    const { classes, appContext, userContext } = this.props;
    const { organization, isDirty, currentTeam = {}, billing } = this.state;
    const organizationTypes = ['School', 'Club', 'Trainer', 'League', 'Other']

    if(organization.address2 === undefined || organization.address2 === null) {
      organization.address2 = '';
    }

    return(
      <div style={{textAlign:"left"}}>

        <Grid container spacing={16}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8}>
              <h4 className={'subHeading'} style={{textAlign:'left'}}>{organization.name} Organization</h4>
          </Grid>

          <Grid item xs={4} style={{textAlign:'right'}}>
            <Button
              style={!isDirty ? {display:'none'} : null}
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Cancel
            </Button>

            <Button
              style={{marginLeft:10, borderRadius:0}}
              variant="contained"
              color="secondary"
              onClick={() => this.saveOrganization()}
            >
              Save
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={2} style={{paddingTop:5, marginBottom:15}}>
            <p style={{marginTop:0, fontSize:12, color:'rgba(0,0,0,0.54)'}}>Logo</p>
            {
              this.state.attachment
            ?
              <div style={{
                background:'url(' + this.state.attachment+ ')',
                width:100,
                height:100,
                margin:0,
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                borderStyle:'solid'}}
              >
              </div>
            :
              <div>
              {
                organization.logo || organization.logoUrl
              ?
                <div style={{
                  background:'url(' + organization.logo || organization.logoUrl + ')',
                  width:100,
                  height:100,
                  backgroundPosition:'center',
                  backgroundRepeat:'no-repeat',
                  backgroundSize:'cover',
                  borderStyle:'solid'}}
                >
                </div>
              :
                <div style={{
                  width:100,
                  height:100,
                  marginLeft:5,
                  backgroundColor:"#EDEFF0",
                  borderStyle:"dashed",
                  textAlign: "center"}}
                  >
                    <CameraAltIcon style={{marginTop:35}} />
                </div>
              }
              </div>
            }
            <div style={{marginTop:15}}>
              <input id="orgInput" type="file" ref={(ref)=>this.orgInput=ref} onChange={this.handleFileChange} style={{ display:'none'}} />
              <Button
                style={{width:106}}
                variant="contained"
                color="primary"
                size="small"
                onClick={(e)=> !!currentTeam.isFree ? null : this.orgInput.click() }
              >
                Upload Image
              </Button>
            </div>
              {/* <p style={{fontSize:12, fontStyle:'italic', color:'rgba(0,0,0,0.54)'}}>Upload a {!organization.logo ? "" : "new"} logo for your organization here.</p> */}
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={10}>
            <Grid container
              spacing={16}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                    id="name"
                    label="Name"
                    style={{width:'100%'}}
                    disabled={!!currentTeam.isFree}
                    value={organization.name ? organization.name : ""}
                    onChange={this.handleChangeOrganization("name")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  <FormControl style={{ width: '100%' }}>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Select
                          disabled={!!currentTeam.isFree}
                          value={organization.type ? organization.type : ''}
                          onChange={this.handleChangeOrganization("type")}
                          input={<Input id="type" />}
                      >
                          {organizationTypes.map(type => (
                          <MenuItem
                              key={type}
                              value={type}
                          >
                              {type}
                          </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={12} style={{height:58, paddingTop:50}}>
                <Divider />
              </Grid>

              <Grid item style={{width:'100%'}}>
                <TextField
                    id="address1"
                    label="Address 1"
                    style={{width:'100%'}}
                    disabled={!!currentTeam.isFree}
                    value={organization.address1 ? organization.address1 : ""}
                    onChange={this.handleChangeOrganization("address1")}
                />
              </Grid>
              <Grid item style={{width:'100%'}}>
                <TextField
                    id="address2"
                    label="Address 2"
                    style={{width:'100%'}}
                    disabled={!!currentTeam.isFree}
                    value={organization.address2 ? organization.address2 : ""}
                    onChange={this.handleChangeOrganization("address2")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                    id="city"
                    label="City"
                    style={{width:'100%'}}
                    disabled={!!currentTeam.isFree}
                    value={organization.city ? organization.city : ""}
                    onChange={this.handleChangeOrganization("city")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                    id="state"
                    label="State"
                    style={{width:'100%'}}
                    disabled={!!currentTeam.isFree}
                    value={organization.state ? organization.state : ""}
                    onChange={this.handleChangeOrganization("state")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                    id="postalCode"
                    label="Postal Code"
                    style={{width:'100%'}}
                    disabled={!!currentTeam.isFree}
                    value={organization.postalCode ? organization.postalCode : ""}
                    onChange={this.handleChangeOrganization("postalCode")}
                />
              </Grid>
              {(appContext.isAdmin || userContext.isImpersonating) &&
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <p style={{ margin: 0, fontSize: 20 }}>Locked Out</p>
                    <Checkbox
                      checked={organization.billing && organization.billing.isLockedOut ? organization.billing.isLockedOut : false}
                      onChange={this.handleChangeOrganization('isLockedOut')}
                    />
                </Grid>
              }
            </Grid>
          </Grid>

          {/* <Grid item xs={12} style={{textAlign:'right', marginTop:20}}>
            <Button variant="contained" color="secondary" onClick={() => this.saveOrganization()}>Save</Button>
          </Grid> */}
        </Grid>
      </div>
    )
  }

  renderPermissions(){
    const { coaches } = this.state
    const { classes, appContextList } = this.props
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, coaches.length - page * rowsPerPage);

    return(
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>

          <Table className={classes.table} aria-labelledby="tableTitle" style={{}}>
            <TableHead>
              <TableRow>
                <TableCell className={'permissions'} style={{width:'2.5%', paddingRight:0}}></TableCell>
                <TableCell className={'permissions'} style={{width:'15%'}}>First Name</TableCell>
                <TableCell className={'permissions'} style={{width:'15%'}}>Last Name</TableCell>
                <TableCell className={'permissions'} style={{paddingRight:45}}>Phone Number</TableCell>
                <TableCell className={'permissions'} style={{minWidth:115, paddingRight: 1}}>Role</TableCell>
                <TableCell className={'permissions'} style={{width:'37.5%'}}>Team</TableCell>
              </TableRow>
            </TableHead>

            {
                coaches
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((coach, index) => {
                  const isSelected = this.isSelected(coach.id);
                  return (
                    <TableBody key={coach.id}>
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, coach.id)}
                      tabIndex={-1}
                      className={isSelected ? 'selectedRow' : ''}
                      selected={isSelected}
                    >
                      <TableCell className={'permissions'}>{index+1}</TableCell>
                      <TableCell className={'permissions'}>{coach.nameFirst}</TableCell>
                      <TableCell className={'permissions'}>{coach.nameLast}</TableCell>
                      <TableCell className={'permissions'}>{coach.phoneNumber ? this.formatPhoneNumber(coach.phoneNumber) : "-"}</TableCell>

                      <TableCell className={'permissions checkboxContainer'}>
                        {coach.roles.map(role => {
                          return(
                            <div className={'roleCheckbox'} key={role.id}>
                              <Checkbox checked={isSelected}/> {role.role}
                            </div>)
                        })}
                      </TableCell>

                      <TableCell className={'permissions'}>
                        {coach.roles.map(role => {
                          return(
                            <div key={role.id} style={{height:48}}>
                              {this.getNameFromParentId(appContextList, role.parentId)}
                            </div>
                          )
                        })}
                      </TableCell>

                    </TableRow>
                    {
                      isSelected
                      ?
                      <TableRow style={{backgroundColor:'white'}}>
                        <TableCell colSpan={6} style={{paddingRight:0}}>
                          <div style={{paddingTop:0, paddingBottom:10}}>
                            <Grid container
                              spacing={8}
                              direction="row"
                              justify="flex-end"
                              alignItems="flex-start"
                            >
                              <Grid item>
                                <Button variant="contained"  color="secondary" className={classes.button}>Remove</Button>
                              </Grid>
                              <Grid item>
                                {
                                  _.find(coach.roles, function(result) { return result.role === "owner"; })
                                  ?
                                  <Button variant="contained"  color="secondary" className={classes.button} onClick={()=>this.handleDeleteOwnerRole(coach.roles)}>Remove Owner Status</Button>
                                  :
                                  <Button variant="contained"  color="secondary" className={classes.button} onClick={()=>this.handleAddOwnerRole(coach)}>Promote to Owner</Button>
                                }
                              </Grid>
                              <Grid item>
                                {
                                  _.find(coach.roles, function(result) { return result.role === "head-coach"; })
                                  ?
                                  <Button variant="contained"  color="secondary" className={classes.button} onClick={()=>this.handleDemoteCoachRole(coach)}>Demote to Coach</Button>
                                  :
                                  <Button variant="contained"  color="secondary" className={classes.button} onClick={()=>this.handlePromoteHeadCoachRole(coach)}>Promote to Head Coach</Button>
                                }
                              </Grid>
                            </Grid>
                          </div>
                        </TableCell>
                      </TableRow>
                    :
                      null
                    }
                    </TableBody>
                  )
                })
            }

          </Table>

          </Grid>
        </Grid>
      </div>
    )
  }

  renderSubscriptionOptions(){
    const { subscriptionDetailsText } = this.state
    return(
      <div>
        <Grid container spacing={8} justify="center"
        alignItems="center" alignContent={"center"}>
          <Grid item xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                  <p style={{textAlign:"left", fontWeight:500, fontSize:26,marginTop:25}}>Settings</p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{marginLeft:5}}>
                <p style={{textAlign:"left"}}>Allow teams to set their own prices? (They will be able to override any prices set here)
                <Switch name="teamsCanOverridePricing" checked={this.state.teamsCanOverridePricing} onChange={()=>{
                  const teamsCanOverridePricing = !this.state.teamsCanOverridePricing
                  console.log('teamsCanOverridePricing ', teamsCanOverridePricing)
                  this.setState({teamsCanOverridePricing})
                  this.updateOrganization({...this.state.organization, teamsCanOverridePricing})
                }}/>
                </p>
              </Grid>
              <Divider flexItem />
              <p style={{textAlign:"left", fontWeight:500, fontSize:26,marginTop:25}}>Payment Options and SKUs</p>
              <Grid container
                spacing={16}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{marginLeft:5, marginTop:20, paddingBottom:25}}
              >
                <Grid item  style={{borderRight:'1px solid #DDE2E5', height:110, width:250, marginRight:50}}>
                  <Grid container direction="row" alignItems="flex-start" justify="space-between" style={{maxWidth:200, marginTop:-30}}>
                    <Grid item xs={12} sm={6} md={6}><h4 style={{flex:1,flexDirection:"row", fontWeight:"bold", fontSize:20, letterspacing: "-0.01em", fontStyle:"normal", textAlign:"left"}}>Monthly</h4></Grid>
                    <Grid item xs={12} sm={6} md={6} style={{marginTop:20}}><Switch checked={this.state.checkedMonthly} onChange={() => this.setState({'checkedMonthly': !this.state.checkedMonthly})}/></Grid>
                  </Grid>
                  <div style={{maxWidth:180}}>
                  <InputField
                      id="monthly"
                      label="Monthly Price"
                      value={this.state.monthly}
                      name="monthly"
                      onChange={(value) => this.handleChangeInput('monthly', value)}
                      style={{width:'100%'}}
                      disabled={!this.state.checkedMonthly}

                  />
                  </div>
                </Grid>
                <Grid item  style={{borderRight:'1px solid #DDE2E5', height:110, width:250, marginRight:50}}>
                  <Grid container direction="row" alignItems="flex-start" justify="space-between" style={{maxWidth:200, marginTop:-30}}>
                    <Grid item xs={12} sm={6} md={6}><h4 style={{ fontWeight:"bold", fontSize:20, letterspacing: "-0.01em", fontStyle:"normal", textAlign:"left"}}>Quarterly</h4></Grid>
                    <Grid item xs={12} sm={6} md={6} style={{marginTop:20}}><Switch checked={this.state.checkedQuarterly} onChange={() => this.setState({'checkedQuarterly': !this.state.checkedQuarterly})}/></Grid>
                  </Grid>
                  <div style={{maxWidth:180}}>
                    <InputField
                        id="quarterly"
                        label="Quarterly Price"
                        value={this.state.quarterly}
                        onChange={(value) => this.handleChangeInput('quarterly', value)}
                        style={{width:'100%'}}
                        disabled={!this.state.checkedQuarterly}
                    />
                  </div>

                </Grid>
                <Grid item  style={{ height:110, width:250, marginRight:50}}>
                  <Grid container direction="row" alignItems="flex-start" justify="space-between" style={{maxWidth:200, marginTop:-30}}>
                    <Grid item xs={12} sm={6} md={6}><h4 style={{flex:1,flexDirection:"row", fontWeight:"bold", fontSize:20, letterspacing: "-0.01em", fontStyle:"normal", textAlign:"left"}}>Yearly</h4></Grid>
                    <Grid item xs={12} sm={6} md={6} style={{marginTop:20}}><Switch checked={this.state.checkedYearly} onChange={() => this.setState({'checkedYearly': !this.state.checkedYearly})}/></Grid>
                  </Grid>
                  <div style={{maxWidth:180}}>
                  <InputField
                      id="yearly"
                      label="Yearly Price"
                      value={this.state.yearly}
                      onChange={(value) => this.handleChangeInput('yearly', value)}
                      style={{width:'100%'}}
                      disabled={!this.state.checkedMonthly}
                  />
                  </div>
                </Grid>
              </Grid>
            <Divider flexItem />
            <p style={{textAlign:"left", fontWeight:500, fontSize:26,paddingTop:25}}>Subscription Information</p>
            <Grid container
              spacing={16}
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{padding: '15px 5px'}}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start'
                }}
              >
                <h4 style={{ margin: 0, paddingRight: 20, fontWeight:"bold", fontSize:20, letterspacing: "-0.01em", fontStyle:"normal", textAlign:"left"}}>Subscription Detail Text</h4>
                <InputField
                    id="details"
                    textarea={true}
                    value={subscriptionDetailsText}
                    label="Subscription Detail Text"
                    onChange={(value) => this.handleChangeInput('subscriptionDetailsText', value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={16} direction={"row-reverse"} alignItems={'baseline'} style={{marginTop:50, padding:10}}>
              <Grid item >
                <PrimaryButton style={{marginRight:20, marginLeft:20}} text={'Save'} height={44} width={100} onClick={() => this.submitSubscriptionPlans()} />
              </Grid>

              <Grid item >
                <PrimaryButton
                style={{marginRight:20, marginLeft:20}}
                text={'Reset'}
                borderColor={'#DDE2E5'}
                backgroundColor={'#F8F8F8'}
                textColor={'black'} height={44} width={100} onClick={() =>this.componentDidMount()} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
  renderPayoutOptions(){
    return(
      <div>
        <Grid container spacing={8} justify="center"
        alignItems="center" alignContent={"center"}>
          <Grid item xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                  <p style={{textAlign:"left", fontWeight:500, fontSize:26,marginTop:25}}>Stripe Payout Options</p>
              </Grid>
              {
                this.state.account.requirements.currently_due.length
                ?
                <Grid container  alignItems="center" direction="row" xs={12} sm={12} md={12} style={{marginLeft:5,backgroundColor:"#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4, padding:5}} >
                  <WarningIcon color="error" style={{marginLeft:15, marginRight:15}}/>
                  <p style={{textAlign:"left",color:"red", fontSize: 16, fontWeight:'bold', marginTop:20, marginRight:15}}> Payouts have been disabled for this account until the account has been verified  </p>
                  <PrimaryButton style={{marginRight:20, marginLeft:20}} text={'Verify'} height={30} width={65} onClick={() => this.verify()} />
                </Grid>
                :
                null
              }
              {
                !this.state.account.external_accounts.total_count
                ?
                <Grid container  alignItems="center" direction="row" xs={12} sm={12} md={12} style={{marginLeft:5,backgroundColor:"#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4, padding:5}} >
                  <WarningIcon color="error" style={{marginLeft:15, marginRight:15}}/>
                  <p style={{textAlign:"left",color:"red", fontSize: 16, fontWeight:'bold', marginTop:20, marginRight:15}}> Add a bank account to enable payouts  </p>
                </Grid>
                :
                null
              }
              {
                // !this.state.account.tos_acceptance.ip
                // ?
                // <Grid container  alignItems="center" direction="row" xs={12} sm={12} md={12} style={{marginLeft:5,backgroundColor:"#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4, padding:5}} >
                //   <WarningIcon color="error" style={{marginLeft:15, marginRight:15}}/>
                //   <p style={{textAlign:"left",color:"red", fontSize: 16, fontWeight:'bold', marginTop:20, marginRight:15}}> Accept the terms of service, you agree to the <a href="https://stripe.com/legal">Services Agreement</a> and the <a href="https://stripe.com/connect-account/legal">Stripe Connected Account Agreement</a>.  </p>
                // </Grid>
                // :
                // null
              }
              <Grid container
                spacing={16}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                style={{marginLeft:5, marginTop:20, backgroundColor:"#F8F8F8", border: "1px solid #DDE2E5", borderRadius: 4,}}
              >
              <p style={{textAlign:"left", fontWeight:500, fontSize:20,marginTop:25, marginLeft:10}}>Deposit Information</p>

              {
                this.state.account.external_accounts.total_count
                ?
                <Grid container
                  spacing={16}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{marginLeft:5}}
                >
                  <Grid item xs={12}>
                    <p style={{textAlign:"left", fontWeight:500, marginTop:10, marginLeft:5}}><b>Bank / Acct. Number:</b> {this.state.account.external_accounts.data[0].bank_name } x{this.state.account.external_accounts.data[0].last4}</p>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 15}}>
                    {
                      !this.state.showAddDepositInfo
                      &&
                      <PrimaryButton
                      style={{marginRight:20, marginLeft:20}}
                      text={'Update Bank Info'}
                      borderColor={'#1354F9'}
                      backgroundColor={'#1354F9'}
                      height={44} width={157} onClick={() => this.setState({showAddDepositInfo:true})} />
                    }
                  </Grid>
                </Grid>
                :
                null
              }
              {
                this.state.showAddDepositInfo ?
                <Grid container
                  spacing={16}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  style={{marginLeft:5}}
                >
                  <Grid item xs={12}>
                    <div style={{maxWidth:400}}>
                      <InputField
                          id="routingNumber"
                          label="Routing Number"
                          value={this.state.routingNumber}
                          onChange={(value) => this.handleChangeInput('routingNumber', value)}
                          style={{width:'100%'}}
                          rightIcon={this.state.routingNumber && this.state.routingNumber.length === 9 ? <CheckCircleIcon style={{ color: "#27AE60" }}/> : this.state.routingNumber && this.state.routingNumber.length > 9 ? <WarningIcon color="error" /> : null}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{maxWidth:400}}>
                      <InputField
                          id="accountNumber"
                          label="Account Number"
                          value={this.state.accountNumber}
                          onChange={(value) => this.handleChangeInput('accountNumber', value)}
                          style={{width:'100%'}}
                          rightIcon={this.state.accountNumber ? <CheckCircleIcon style={{ color: "#27AE60" }}/> :  null}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{maxWidth:400}}>
                      <InputField
                          id="accountNumberConfirmed"
                          label="Re-confirm Account Number"
                          value={this.state.accountNumberConfirmed}
                          onChange={(value) => this.handleChangeInput('accountNumberConfirmed', value)}
                          rightIcon={this.state.accountNumberConfirmed && (this.state.accountNumberConfirmed === this.state.accountNumber) ? <CheckCircleIcon style={{ color: "#27AE60" }}/> : this.state.accountNumberConfirmed ? <WarningIcon color="error" /> : null}
                          style={{width:'100%'}}
                      />
                    </div>
                  </Grid>
                  <Grid container spacing={16} style={{marginTop:10,marginBottom:15, padding:10}}>
                      <Grid item >
                        <PrimaryButton
                        style={{marginRight:20, marginLeft:20}}
                        text={'Save Bank Info'}
                        borderColor={'#1354F9'}
                        backgroundColor={'#1354F9'}
                        disabled={!this.state.routingNumber || this.state.routingNumber.length !== 9 || !this.state.accountNumber || !this.state.accountNumberConfirmed || (this.state.accountNumber !== this.state.accountNumberConfirmed)}
                        height={44} width={157} onClick={() => this.saveBankInfo()} />
                      </Grid>

                      <Grid item >
                      {
                        this.state.account.external_accounts.total_count
                        ?
                        <PrimaryButton
                        style={{marginRight:20, marginLeft:20}}
                        text={'Cancel'}
                        borderColor={'#DDE2E5'}
                        backgroundColor={'#F8F8F8'}
                        textColor={'black'}
                        height={44} width={157} onClick={() => this.setState({showAddDepositInfo:false, accountNumber:"", accountNumberConfirmed:"",routingNumber:""})} />
                        : null
                      }
                    </Grid>
                  </Grid>
                </Grid>
                :
                null
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Settings.propTypes = {
  classes: PropTypes.object,
};


function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);

    return {
      appContext:appContext,
      currentTeam: currentTeam||{},
      userContext: userContext,
      currentUser: userContext.user,
      appContextList: userContext.appContextList,
    };
}
const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Settings));
