import React, { Component } from "react";

class WistiaVideo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      videoId: props.videoId || props.match.params.videoId 
    }
    window._wq = window._wq || [];
    window._wq.push({
      id: this.state.videoId,
      onEmbedded: (video) => {
        this.handle = video;
      }
    });
  }

  componentDidMount() {
    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script");
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      document.body.appendChild(wistiaScript);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: this.state.videoId,
      onEmbedded: (video) => {
        this.handle = video;
      }
    });
  }

  componentWillUnmount() {
    this.handle && this.handle.remove();
  }

  render() {
    var { videoId } = this.state;
    return (
      <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <div className="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}>
          <div className={`wistia_embed wistia_async_${videoId} videoFoam=true`} style={{ height: '100%', width: '100%' }}>
            &nbsp;
            {/* <div className="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
              <img src="https://fast.wistia.com/embed/medias/oms02d1dfx/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" onload="this.parentNode.style.opacity=1;" />
            </div> */}
          </div>
        </div>
      </div>
    )
  }

}

export default WistiaVideo