import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    importSteps: {
        fontFamily: "Poppins !important",
        background: "#F8F8F8",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.12)",
        paddingTop: "30px",
        minHeight: "128px",
        width: "100%"
    }
};

function HeaderWrapper(props) {
    let { classes } = props;
    return (
        <div className={classes.importSteps}>
                {props.children}
        </div>);
}

HeaderWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderWrapper);