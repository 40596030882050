import { combineReducers } from "redux";

import defaultReducer from "./default-reducer";
import sidebarReducer from "./sidebar";
import sessionReducer from "./session";
import tenantReducer from "./tenant";
import loaderReducer from "./loader";
import chat from "./chat";
import GroupReducer from "./group-reducer";
import UsersReducer from "./users-reducer";
import TeamsReducer from "./teams-reducer";
import OrganizationsReducer from "./organization-reducer";
import SchedulesReducer from "./schedules-reducer";
import traningsReducer from "./training-reducer";
import NylasCalendarReducer from "./nylas-calendar-reducer";

const rootReducers = combineReducers({
  default: defaultReducer,
  sidebar: sidebarReducer,
  session: sessionReducer,
  tenant: tenantReducer,
  chat: chat,
  loader: loaderReducer,
  users: UsersReducer,
  teams: TeamsReducer,
  organizations: OrganizationsReducer,
  trainings: traningsReducer,
  nylasCalendar: NylasCalendarReducer,
  group: GroupReducer,
  schedules: SchedulesReducer,
});

export default rootReducers;
