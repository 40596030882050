import { combineReducers } from "redux";
import { uniqBy } from "lodash";

import {
  TEAMS_GET_BY_ID_LOADING,
  TEAMS_GET_BY_ID_SUCCESS,
  TEAMS_GET_BY_ID_ERROR,
  TEAMS_SET_CURRENT_BY_ID_LOADING,
  TEAMS_SET_CURRENT_BY_ID_SUCCESS,
  TEAMS_SET_CURRENT_BY_ID_ERROR,
  GET_ATHLETES_BY_TEAM_ID_LOADING,
  GET_ATHLETES_BY_TEAM_ID_SUCCESS,
  GET_ATHLETES_BY_TEAM_ID_ERROR,
  GET_MEMBERS_BY_TEAM_ID_SUCCESS,
  GET_MEMBERS_BY_TEAM_ID_LOADING,
  GET_MEMBERS_BY_TEAM_ID_ERROR,
} from "../actions/team-actions";
import { userRoleTypes } from "../models/enums";

const getUser = (user, teamId) => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    role: (
      ((user.teams || {}).results || []).find(
        (element) => element.id === teamId
      ) || {}
    ).role,
    avatarUrl: (user || {}).avatarUrl,
  };
};

const getMembersRoleBased = (data, teamId) => {
  const members = data
    .filter((item) => item.firstName && item.lastName)
    .map((item) => ({
      ...getUser(item, teamId),
    }))
    .reduce((obj, item) => {
      if (
        item.role === userRoleTypes.HEAD_COACH
          ? !obj[userRoleTypes.COACH.toLowerCase()]
          : !obj[(item.role || "").toLowerCase()]
      ) {
        obj[
          item.role === userRoleTypes.HEAD_COACH
            ? userRoleTypes.COACH.toLowerCase()
            : (item.role || "").toLowerCase()
        ] = [];
      }
      obj[
        item.role === userRoleTypes.HEAD_COACH
          ? userRoleTypes.COACH.toLowerCase()
          : (item.role || "").toLowerCase()
      ].push(item);
      return obj;
    }, {});
  return members;
};

const loading = (state = false, action) => {
  switch (action.type) {
    case TEAMS_GET_BY_ID_LOADING:
    case TEAMS_SET_CURRENT_BY_ID_LOADING:
      return true;
    case TEAMS_GET_BY_ID_SUCCESS:
    case TEAMS_SET_CURRENT_BY_ID_SUCCESS:
    case TEAMS_GET_BY_ID_ERROR:
    case TEAMS_SET_CURRENT_BY_ID_ERROR:
      return false;
  }
  return state;
};

const error = (state = false, action) => {
  switch (action.type) {
    case TEAMS_GET_BY_ID_ERROR:
    case TEAMS_SET_CURRENT_BY_ID_ERROR:
      return true;
    case TEAMS_GET_BY_ID_LOADING:
    case TEAMS_SET_CURRENT_BY_ID_LOADING:
    case TEAMS_GET_BY_ID_SUCCESS:
    case TEAMS_SET_CURRENT_BY_ID_SUCCESS:
      return false;
  }
  return state;
};

const byId = (state = {}, action) => {
  if (action.type === TEAMS_GET_BY_ID_SUCCESS) {
    const { team } = action.data;
    return [team].reduce((list, item) => {
      list[item.id] = item;
      return list;
    }, state);
  }
  return state;
};

const currentTeamId = (state = null, action) => {
  if (action.type === TEAMS_SET_CURRENT_BY_ID_SUCCESS) {
    const { id } = action.data;
    return id;
  }
  return state;
};

const athletes = (state = { loading: false, list: [], error: "" }, action) => {
  switch (action.type) {
    case GET_ATHLETES_BY_TEAM_ID_LOADING: {
      return {
        loading: true,
        list: state.list,
        total: state.total,
        error: "",
      };
    }
    case GET_ATHLETES_BY_TEAM_ID_SUCCESS: {
      const { results, total, offset } = action.data;
      return {
        ...state,
        loading: false,
        list: offset === 0 ? results : uniqBy(state.list.concat(results), "id"),
        total: total,
        error: "",
      };
    }

    case GET_ATHLETES_BY_TEAM_ID_ERROR: {
      return {
        ...state,
        loading: false,
        list: [],
        total: 0,
        error: "Error in getting athletes",
      };
    }
  }
  return state;
};

const membersById = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_MEMBERS_BY_TEAM_ID_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_MEMBERS_BY_TEAM_ID_SUCCESS: {
      const {
        data: { meta, results, total },
        teamId,
      } = action.data;
      const members = {
        ...state.data,
        [teamId]: {
          meta,
          total,
          results: results.reduce((obj, item) => {
            obj[item.id] = getUser(item, teamId);
            return obj;
          }, {}),
        },
      };
      return {
        ...state,
        loading: false,
        data: members,
        error: "",
      };
    }

    case GET_MEMBERS_BY_TEAM_ID_ERROR: {
      return {
        ...state,
        loading: false,
        data: state.data,
        error: "Error in getting teams",
      };
    }
  }
  return state;
};

const membersByRole = (
  state = { loading: false, data: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_MEMBERS_BY_TEAM_ID_LOADING: {
      return {
        loading: true,
        data: state.data,
        error: "",
      };
    }
    case GET_MEMBERS_BY_TEAM_ID_SUCCESS: {
      const {
        data: { meta, results, total },
        teamId,
      } = action.data;
      const membersByRole = getMembersRoleBased(results, teamId);
      const members = {
        ...state.data,
        [teamId]: {
          meta,
          total,
          results: membersByRole,
        },
      };
      return {
        ...state,
        loading: false,
        data: members,
        error: "",
      };
    }

    case GET_MEMBERS_BY_TEAM_ID_ERROR: {
      return {
        ...state,
        loading: false,
        data: state.data,
        error: "Error in getting teams",
      };
    }
  }
  return state;
};

export default combineReducers({
  loading,
  error,
  byId,
  currentTeamId,
  athletes,
  membersById,
  membersByRole
});
