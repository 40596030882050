import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckIcon = (props) => (
  <SvgIcon width="16" height="14" viewBox="0 0 18 15" fill="none"{...props}>
<path d="M6.052 12.3555C6.00217 12.3555 5.95234 12.3364 5.91438 12.2984L2.05709 8.44114C1.98097 8.36503 1.98097 8.242 2.05709 8.16588C2.1332 8.08977 2.25623 8.08977 2.33234 8.16588L6.052 11.8855L15.5248 2.41255C15.6009 2.33644 15.7239 2.33644 15.8001 2.41255C15.8762 2.48867 15.8762 2.6117 15.8001 2.68781L6.18963 12.2984C6.15167 12.3366 6.10184 12.3555 6.052 12.3555Z" fill="#3F9E1D" stroke="#3F9E1D" stroke-width="3"/>
 </SvgIcon>
);

export default CheckIcon;
