import React, { Component } from 'react'
import {
    FormControl,
    InputAdornment,
    Input,
    InputLabel
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    formControl: {
        border: "1px solid #DDE2E5",
        borderRadius: "4px",
        height: "48px",
        paddingLeft: "15px",
        flexGrow: 1,
        width: "100%",
        textAlign: "left",
        marginBottom: "24px",
        fontFamily: 'Poppins'
    },
    input: {
        fontFamily: "Poppins"
    },
    adormentPositionEnd: {
        marginRight: "5px",
        marginTop: "-5px"
    },
    labelRoot: {
        top: "17px",
        transform: "translate(0, 0px) scale(1)",
        left: "15px",
        color: "#0B0B0B !important",
        opacity: "0.3",
        fontFamily: 'Poppins',
        fontSize:'14px'
    },
    labelFocused: {
        transform: "translate(0, 1.5px) scale(0.75)",
        top: "4px",
        color: "#0B0B0B !important",
        opacity: "0.3"
    },
});


const FloatingInputWithAdorment = (props) => {
    const { id, label, value, className, overrideContainerCss, required, classes, disabled, adormentRootClass } = props
    return (
        <FormControl disabled={disabled} className={classNames(classes.formControl, overrideContainerCss)}>
            <InputLabel classes={{
                root: classes.labelRoot,
                shrink: classes.labelFocused
            }} htmlFor="fileUrl">{label}</InputLabel>
            <Input
                required={required}
                disableUnderline
                id={id}
                className={classNames(classes.input, className)}
                value={value}
                endAdornment={
                    <InputAdornment classes={{ positionEnd: classes.adormentPositionEnd , root: adormentRootClass}} position="end">
                        {props.children}
                    </InputAdornment>
                } />
        </FormControl>
    )
}

export default withStyles(styles)(FloatingInputWithAdorment);