import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import CancelEventComponent from "../components/CancelEvent";
import { enableFullScreenLoader } from '../../../actions/loading';
import {
  updateEvent,
  getEventById,
  deleteEventById,
  cancelEventById,
  sendEmailNotifications,
  getEventsByTeamIdsAndUserId,
  getRecurringEventInstanceByInstanceId,
} from "../../../actions/nylas-calendar-actions";

const viewOptions = {
  confirmationPopup: "confirmationPopup",
};

class EventDetail extends Component {
  constructor(props) {
    super(props);

    const {
      teamsById,
      currentTeamId,
      eventById,
      instanceById,
      match: { params },
    } = props;
    const currentTeam = teamsById[currentTeamId];
    let event = {};
    const masterEventId = (params || {}).masterEventId
    if (masterEventId) {
      event = instanceById.data[(params || {}).id] || {};
    } else {
      event = eventById.data[(params || {}).id] || {};
    }
    this.state = {
      removeEvent: event.recurrence ? true : false,
      allEvent: false,
      currentTeam: currentTeam,
      currentView: "",
      masterEventId: masterEventId,
      isRecurringEvent: event.recurrence ? true : false,
      event: event,
    };
  }

  componentDidMount = () => {
    const { currentTeam, masterEventId } = this.state;
    const {
      getRecurringEventInstanceByInstanceId,
      match: { params },
      instanceById,
      getEventById,
      eventById,
    } = this.props;
    if (masterEventId) {
      if (!instanceById.data[(params || {}).id]) {
        getRecurringEventInstanceByInstanceId(
          currentTeam.organizationId,
          masterEventId,
          (params || {}).id,
        );
      }
    } else if (!eventById.data[(params || {}).id]) {
      getEventById(currentTeam.organizationId, (params || {}).id);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { masterEventId } = this.state
    const {
      match: { params },
      instanceById,
      eventById,
    } = this.props;
    if (
      (!isEqual(prevProps.eventById.data, eventById.data) &&
        eventById.data[(params || {}).id]) ||
      (!isEqual(prevProps.instanceById.data, instanceById.data) &&
        instanceById.data[(params || {}).id])
    ) {
      let event = {};
      if (masterEventId) {
        event = instanceById.data[(params || {}).id] || {};
      } else {
        event = eventById.data[(params || {}).id] || {};
      }
      this.setState({
        event: event,
        removeEvent: event.recurrence ? true : false,
        isRecurringEvent: event.recurrence ? true : false,
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleConfirmation = async () => {
    const {
      match: { params },
      enableFullScreenLoader,
    } = this.props;
    const { masterEventId } = this.state
    enableFullScreenLoader(true, "Loading....");
    const { removeEvent, allEvent, currentTeam, event } = this.state;
    if (masterEventId) {
      if (allEvent) {
        if (removeEvent) {
          await deleteEventById(
            currentTeam.organizationId,
            masterEventId
          );
        } else {
          await cancelEventById(
            currentTeam.organizationId,
            masterEventId
          );
        }
      } else {
        if (removeEvent) {
          let deletedRecurrenceInstanceIds = event.deletedRecurrenceInstanceIds;
          if (!(deletedRecurrenceInstanceIds || []).length) {
            deletedRecurrenceInstanceIds = [];
          }
          deletedRecurrenceInstanceIds = [
            ...new Set([...deletedRecurrenceInstanceIds, (params || {}).id]),
          ];
          const data = {
            organizationId: currentTeam.organizationId,
            eventId: masterEventId,
            userId: event.user.id,
            title: event.title,
            location: event.location,
            message: event.message,
            startDate:
              (event.when || {}).object === "date"
                ? (event.when || {}).date
                : (event.when || {}).object === "datespan"
                ? (event.when || {}).start_date
                : (event.when || {}).start_time
                ? (event.when || {}).start_time
                : "",
            endDate:
              (event.when || {}).object === "datespan"
                ? (event.when || {}).end_date
                : (event.when || {}).end_time
                ? (event.when || {}).end_time
                : "",
            object: (event.when || {}).object,
            scheduleId: (event.tag || {}).id,
            teamIds: (event.teams || []).map((item) => item.id),
            participants: event.participants,
            externalURL: event.externalURL,
            eventType: event.eventType,
            ...event,
            eventId: masterEventId,
            organizationId: currentTeam.organizationId,
            deletedRecurrenceInstanceIds: deletedRecurrenceInstanceIds,
          };
          await updateEvent(data);
        } else {
          await cancelEventById(currentTeam.organizationId, (params || {}).id);
        }
      }
    } else {
      if (removeEvent) {
        await deleteEventById(currentTeam.organizationId, event.id);
      } else {
        await cancelEventById(currentTeam.organizationId, event.id);
      }
    }
    
    this.setState({ currentView: viewOptions.confirmationPopup });
    this.loadData();
    enableFullScreenLoader(false);
  };

  loadData = async () => {
    const { currentTeam } = this.state;
    const {
      startDate,
      selectedTeams,
      loggedInUserId,
      getEventsByTeamIdsAndUserId,
    } = this.props;
    getEventsByTeamIdsAndUserId({
      organizationId: currentTeam.organizationId,
      loggedInUserId,
      selectedTeams,
      limit: 100,
      startDate,
      offset: 0,
    });
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  sendEmailNotifications = () => {
    const { event, currentTeam, removeEvent, masterEventId } = this.state;
    const{  enableFullScreenLoader } = this.props
    enableFullScreenLoader(true, "Loading....");
    sendEmailNotifications(currentTeam.organizationId, masterEventId, event.id, removeEvent ? "Deleted" : "Cancelled");
    enableFullScreenLoader(false);
    this.setState({ currentView: "" }, () => {
      this.handleBack();
    });
  };

  render() {
    const { event, currentView, removeEvent, allEvent, isRecurringEvent } =
      this.state;

    return (
      <CancelEventComponent
        event={event || {}}
        allEvent={allEvent}
        currentView={currentView}
        removeEvent={removeEvent}
        handleBack={() => {
          this.setState({ currentView: "" }, () => {
            this.handleBack();
          });
        }}
        handleChange={this.handleChange}
        isRecurringEvent={isRecurringEvent}
        handleConfirmation={this.handleConfirmation}
        sendEmailNotifications={this.sendEmailNotifications}
      />
    );
  }
}

const mapStateToProps = ({
  users: { loggedInUserId },
  teams: { byId: teamsById, currentTeamId },
  nylasCalendar: { startDate, eventById, instanceById, selectedTeams },
}) => ({
  startDate,
  eventById,
  teamsById,
  instanceById,
  currentTeamId,
  selectedTeams,
  loggedInUserId,
});

export default connect(mapStateToProps, {
  getEventById,
  enableFullScreenLoader,
  getEventsByTeamIdsAndUserId,
  getRecurringEventInstanceByInstanceId,
})(EventDetail);
