import {
  WhiteLabelUserClient,
  WhiteLabelAuthenticationClient,
  WhiteLabelSportsEngineClient,
  WhiteLabelOrganizationClient,
  WhiteLabelUserImportClient,
  WhiteLabelWorkoutClient,
  WhiteLabelCoppaClient,
  WhiteLabelBIABClient,
  WhiteLabelSurveysClient,
  WhiteLabelActivityClient,
  WhiteLabelTenantClient,
  WhiteLabelZoomClient,
  WhiteLabelGoalsClient,
  WhiteLabelIndexClient,
  WhiteLabelLiveEventsClient,
  WhiteLabelQuestionClient,
  WhiteLabelTagsClient,
  WhiteLabelGroupClient,
  WhiteLabelTicketsClient,
  WhiteLabelDynamicLibraryClient,
  WhiteLabelMarketplaceClient,
  WhiteLabelContentCopyClient,
  WhiteLabelUpliftClient,
  WhiteLabelTeamClient,
  WhiteLabelOnboardingClient,
  WhiteLabelEmailClient,
  WhiteLabelMessageClient,
  WhiteLabelServiceIntegrationClient,
  WhiteLabelConfigClient,
  WhiteLabelMediaClient,
  TaggingClient,
  WhiteLabelHRVClient,
  WhiteLabelHabitResultsClient,
  WhiteLabelSchedulesClient,
  WhiteLabelNylasCalendarClient,
} from "maxone-api-client";
import { getEnvironment, getRegion } from "../utils/environmentUtils";

const environment = getEnvironment();
const region = getRegion();

export const userClient = new WhiteLabelUserClient({ environment, region });
export const mediaClient = new WhiteLabelMediaClient({ environment, region });
export const authClient = new WhiteLabelAuthenticationClient({
  environment,
  region,
});
export const sportsEngineClient = new WhiteLabelSportsEngineClient({
  environment,
  region,
});
export const organizationClient = new WhiteLabelOrganizationClient({
  environment,
  region,
});
export const userImportClient = new WhiteLabelUserImportClient({
  environment,
  region,
});
export const workoutClient = new WhiteLabelWorkoutClient({
  environment,
  region,
});
export const coppaClient = new WhiteLabelCoppaClient({ environment, region });
export const biabClient = new WhiteLabelBIABClient({ environment, region });
export const surveysClient = new WhiteLabelSurveysClient({
  environment,
  region,
});
export const activityClient = new WhiteLabelActivityClient({
  environment,
  region,
});
export const tenantClient = new WhiteLabelTenantClient({ environment, region });
export const zoomClient = new WhiteLabelZoomClient({ environment, region });
export const goalsClient = new WhiteLabelGoalsClient({ environment, region });
export const indexClient = new WhiteLabelIndexClient({ environment, region });
export const liveEventsClient = new WhiteLabelLiveEventsClient({
  environment,
  region,
});
export const questionClient = new WhiteLabelQuestionClient({
  environment,
  region,
});
export const tagClient = new WhiteLabelTagsClient({ environment, region });
export const tagsClient = new WhiteLabelTagsClient({ environment, region });
export const taggingClient = new TaggingClient({ environment, region });
export const groupClient = new WhiteLabelGroupClient({ environment, region });
export const freshdeskClient = new WhiteLabelTicketsClient({
  environment,
  region,
});
export const dynamicLibraryClient = new WhiteLabelDynamicLibraryClient({
  environment,
  region,
});
export const marketplaceClient = new WhiteLabelMarketplaceClient({
  environment,
  region,
});
export const contentCopyClient = new WhiteLabelContentCopyClient({
  environment,
  region,
});
export const upliftClient = new WhiteLabelUpliftClient({ environment, region });
export const teamClient = new WhiteLabelTeamClient({ environment, region });
export const onboardingClient = new WhiteLabelOnboardingClient({
  environment,
  region,
});
export const emailClient = new WhiteLabelEmailClient({ environment, region });
export const configClient = new WhiteLabelConfigClient({ environment, region });
export const messagesClient = new WhiteLabelMessageClient({
  environment,
  region,
});
export const serviceIntegrationClient = new WhiteLabelServiceIntegrationClient({
  environment,
  region,
});
export const hrvClient = new WhiteLabelHRVClient({ environment, region });
export const habitResultClient = new WhiteLabelHabitResultsClient({
  environment,
  region,
});
export const schedulesClient = new WhiteLabelSchedulesClient({
  environment,
  region,
});
export const nylasCalendarClient = new WhiteLabelNylasCalendarClient({
  environment,
  region,
});
