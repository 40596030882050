import React, { Component } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core'
import ModalContainer from '../../modalContainer';
import SearchInput from '../../searchInput';
import { PlusIcon } from '../../icons';

const styles = {
    topSectionAdditionalContent: {
        marginTop: "6px"
    },
    subHeadTxt: {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom: "12px"
    },
    questionCard: {
        verticalAlign: "top",
        cursor: "pointer",
        width: "277px",
        height: "70px",
        marginBottom: "15px",
        display: "inline-block",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        padding: "0 12px",
        "&.selectedTeamSquare": {
            border: "2px solid #27AE60"
        },
        "&:nth-child(even)": {
            marginLeft: "14px",
        }
    },
    questionName: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        color: "#0B0B0B",
        marginLeft: "10px"
    },
    questionTitleLmt: {
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        overflow: "hidden"
    },
    searchBox: {
        height: "44px",
        marginBottom: "17px"
    },
    questionCardContainer: {
        height: "390px",
        overflow: "scroll",
        paddingTop: "20px",
        paddingBottom: "20px",
        justifyContent: "space-between",
        position: "relative"
    },
    marketplaceModalPaper: {
        marginRight: "-30px",
        marginLeft: "-30px",
        marginBottom: "-30px",
        background: "#F8F8F8",
        paddingLeft: "30px",
        paddingRight: "30px"
    },
    inputDarkBorder: {
        border: "2px solid #A0A7AB"
    },
    questionFilters: {
        paddingBottom: "20px"
    },
    filterSelect: {
        border: "1px solid #DDE2E5",
        borderRadius: "5px",
        fontSize: "11px",
        padding: "5px",
        marginRight: "5px",
        cursor: "pointer",
        marginBottom: "5px"
    },
    filterSelected: {
        background: "#27AE60",
        color: "white",
        borderColor: "transparent",
    },
    titleContainer: {
        lineHeight: "120%"
    },
    delIcon: {
        marginLeft: "auto",
        marginRight: "10px",
        "& svg": {
            color: "#DDE2E5",
            cursor: "pointer"
        }
    },
    questionTitle: {
        fontWeight: 500,
        fontSize: "18px",
        color: "#222127"
    },
    goalType: {
        fontSize: "12px",
        color: "rgba(34, 33, 39, 0.6)"
    },
    helperTxt: {
        color: "#F93F3F",
        fontSize: "10px",
        marginTop: "-20px",
        marginBottom: "5px"
    },
    head: {
        marginBottom: "5px",
        marginRight: "5px"
    }
}

class AddQuestionModal extends Component {
    constructor(props) {
        super();
        this.state = {
            menuEnabled: false,
            selectedQuestionId: null,
            currentPage: 0,
            searchTerm: "",
            loadingMessage: "",
            filteredData: []
        }
    }

    componentDidMount() {
        let { questions } = this.props;
        this.setState({ filteredData: questions });
    }

    select = (question) => {
        let { selectedQuestionId } = this.state;
        this.setState({ selectedQuestionId: question.parentQuestionId == selectedQuestionId ? null : question.parentQuestionId });
    }

    handleSearch = (searchTerm) => {
        let { questions } = this.props;
        let { activeQuickFilter } = this.state;
        let filteredData = this.getFilteredData(searchTerm, activeQuickFilter, questions)
        this.setState({ searchTerm, filteredData,  selectedQuestionId: null });
    }

    getFilteredData = (searchTerm, tag, questions) => {
        let formattedSearchTerm = (searchTerm || "").toUpperCase();
        let filteredData = (questions || []).filter(item => tag ? item.tags.some(item => (item || "").toUpperCase().indexOf(tag.toUpperCase()) != -1) : true
            && ((item.title || "").toUpperCase().indexOf(formattedSearchTerm) != -1 ||
                (item.description || "").toUpperCase().indexOf(formattedSearchTerm) != -1));
        return filteredData;
    }

    selectQuickFilter = (filterName) => {
        let { questions } = this.props;
        let { activeQuickFilter, searchTerm } = this.state;
        let filterValue = filterName == activeQuickFilter ? null : filterName;
        let filteredData = this.getFilteredData(searchTerm, filterValue, questions)
        this.setState({ activeQuickFilter: filterValue, selectedQuestionId: null, filteredData });
    }


    render() {
        let { classes, displayValue, onChange, questionTags, toggleQuestionModal } = this.props;
        const { menuEnabled, selectedQuestionId, searchTerm, filteredData, activeQuickFilter } = this.state;
        return (
            <ModalContainer
                customPadding={"30px"}
                heading={`Add a Question`}
                open={true}
                width={"630px"}
                onClose={toggleQuestionModal}
                useDefaultLeftBtn={true}
                useDefaultRightBtn={true}
                defaultLeftBtnProps={{ onClick: toggleQuestionModal }}
                defaultRightBtnProps={{
                    disabled: !selectedQuestionId,
                    onClick: () => onChange(filteredData.find(question=> question.parentQuestionId == selectedQuestionId)),
                    text: "Add Question",
                    iconName: "Plus"
                }}
                additionalTopSection={
                    <div className={classes.topSectionAdditionalContent}>
                        <React.Fragment>
                            <div className={classes.subHeadTxt}>
                                Search and select a question
                            </div>
                            <SearchInput placeholder={"Search Question"} customClass={classnames(classes.searchBox, { [classes.inputDarkBorder]: !!searchTerm })} searchTerm={searchTerm} handleSearch={this.handleSearch} />
                            <div className={classnames(classes.tabContainer, "d-flex")}>
                                <div className={classes.head}>Tags: </div>
                                <div className={classnames(classes.questionFilters, "d-flex align-items-center flex-wrap")}>
                                    {questionTags.map(tag => <div onClick={() => this.selectQuickFilter(tag)} className={classnames(classes.filterSelect, { [classes.filterSelected]: tag == activeQuickFilter })}>{tag}</div>)}
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                }
            >
                <div className={classes.marketplaceModalPaper}>
                    <div className={classes.questionCardContainer}>
                        {filteredData.map(question => {
                            return (
                                <div onClick={() => this.select(question)} key={question.parentQuestionId}
                                    className={classnames(classes.questionCard, { 'selectedTeamSquare': selectedQuestionId == question.parentQuestionId })}>
                                    <div className={"d-flex align-items-center h-100"}>
                                        <div className={classes.questionName}>
                                            <div className={classes.questionTitleLmt} title={question.title}>{question.title}</div>
                                            <div className={classnames(classes.goalType, 'text-truncate')} title={question.inputInfo}>{question.inputInfo}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ModalContainer>
        );
    }
}

export default withStyles(styles)(AddQuestionModal);