import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SearchIcon = (props) => (
  <SvgIcon width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path d="M9.50611 2.26572C7.50989 0.2695 4.26194 0.2695 2.26572 2.26572C0.2695 4.26194 0.2695 7.50989 2.26572 9.5064C4.19821 11.4389 7.29987 11.4945 9.30796 9.68571L13.8505 14.2283L14.2286 13.8505L9.68542 9.30767C11.4945 7.30045 11.4389 4.19821 9.50611 2.26572ZM9.12865 9.12836C7.34071 10.9166 4.43112 10.9166 2.64347 9.12836C0.855533 7.34071 0.855533 4.43141 2.64347 2.64347C4.43112 0.855533 7.34071 0.855533 9.12865 2.64347C10.9166 4.43141 10.9166 7.34071 9.12865 9.12836Z" fill="white" stroke="#222127" stroke-width="0.2"/>
  </SvgIcon>
);

export default SearchIcon;
