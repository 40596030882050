import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LeafIcon = (props) => (
  <SvgIcon width="20" height="19" viewBox="0 0 20 19" {...props}>
<path d="M14.9375 5.5C6.5 7.375 4.53125 13.1594 2.58125 18.0063L4.35313 18.625L5.24375 16.4688C5.69375 16.6281 6.1625 16.75 6.5 16.75C16.8125 16.75 19.625 0.8125 19.625 0.8125C18.6875 2.6875 12.125 2.92188 7.4375 3.85938C2.75 4.79688 0.875 8.78125 0.875 10.6562C0.875 12.5312 2.51562 14.1719 2.51562 14.1719C5.5625 5.5 14.9375 5.5 14.9375 5.5Z" fill="#222127"/>

 </SvgIcon>
);

export default LeafIcon;
