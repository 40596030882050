import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PageWrapper from '../nav/pageWrapper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classnames from 'classnames';
import Switch from '../../components/switch';
import FloatingSelectWithCustomChild from '../../components/floatingSelectWithCustomChild';
import { setUserContext, setAppContext } from '../../actions/session';

import { Auth, API } from "aws-amplify";
import { workoutClient, biabClient } from '../../../src/clients';
import { saveTeam, getTeam, listCoaches } from '../../services/teamService';
import { copyNew } from '../../services/productsService';
import { saveRole, getRoles } from '../../services/rolesService';
import { getTenant } from '../../utils/commonUtil';

const styles = theme => ({
    container: {
        background: "white",
        padding: "15px",
        borderRadius: "5px",
        marginTop: "15px"
    },
    radioRoot: {
        width: "24px",
        height: "24px",
        marginRight: "16px",
        paddingLeft: "25px",
    },
    labelTxt: {
        color: "#27292A",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "120%",
        opacity: 1,
        "&.labelSelected": {
            color: "#27292A",
            opacity: 1,
            "& .regBox": {
                background: "#1354F9"
            }
        }
    },
    radioChecked: {
        color: "#1354F9 !important"
    },
    options : {
        marginTop:"10px"
    }
});



class FreemiumSettings extends Component {
    constructor(props) {
        super(props),
            this.menuItemData = [
                { displayValue: "3-Day", value: 3 },
                { displayValue: "7-Day", value: 7 },
                { displayValue: "14-Day", value: 14 },
                { displayValue: "30-Day", value: 30 },
                { displayValue: "60-Day", value: 60 },
                { displayValue: "90-Day", value: 90 },]
        this.state = {
            isLoading: true,
            computedWorkoutProgramMenuItem: [],
            business:{}
        }
    }

    async componentDidMount() {
        let { currentTeam, userContext: {user:{id: userId}} } = this.props;
        this.getProgramsByTeamId(currentTeam.id);
        let team = await getTeam(currentTeam.id)
        let tenant = await getTenant()
        console.log('userId ', userId)
        console.log('tenant ', tenant)
        const { data } = await biabClient.getConfigsByTeamId(tenant, currentTeam.id);
        console.log("business ---> ", data)
        this.setState({currentTeam: team, business: data ? data : {}})
    }

    getProgramsByTeamId = async (teamId) => {
        try {
            const { data } = await workoutClient.searchWorkoutProgramsByTeamId(teamId, { limit: 1000 });
            let computedWorkoutProgramMenuItem = (data.results || []).map(item => {
                return {
                    value: item.id,
                    displayValue: item.title
                }
            }) || [];
            this.setState({ computedWorkoutProgramMenuItem });
        }
        catch (e) {
            console.log("Exception")
            console.log(e)
        }
        this.setState({ isLoading: false })
    };

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleEnableFreemium = async() => {
      this.setState({isLoading: true})
      const { currentTeam: team, business } = this.state;
      console.log('team ', team)
      // Create a new free team with the name of this name as the MaxPay team + " - Free Trial"
      // full copy of paid team.
      // paid team hasFreeTrial: true, freeTrialLive: isLive
      // id: free_TEAMID, isFreeTrialTeam: true, isPaid: true
      let currentBusiness = {...business};
      let currentTeam = {...team};
      let freeTeam = {...team,
        id:"free_" + currentTeam.id,
        name: (currentTeam.customName ? currentTeam.customName : currentTeam.name) + " - Free Trial",
        customName: (currentTeam.customName ? currentTeam.customName : currentTeam.name) + " - Free Trial",
        isNameLocked: true,
        isPaid: true,
        isFreeTrialTeam: true,
      };
      delete freeTeam.playerCode
      delete freeTeam.coachCode
      delete freeTeam.parentCode

      let tenant = await getTenant()
      currentBusiness.freeTrialEnabled = true
      currentBusiness.freeTrialLive = false
      currentBusiness.freeTrialDays = 7
      currentBusiness.freeTrialProgramId = null

      console.log('free team ', freeTeam)
      console.log('currentTeam ', currentTeam)
      console.log('business ', business)
      console.log('saving currentBusiness: biabClient.updateConfigsByTeamId --> ', currentBusiness)
      // Save both teams.
      await saveTeam(freeTeam)
      let { data, status } = await biabClient.updateConfigsByTeamId(
        tenant,
        currentBusiness.teamId,
        currentBusiness.subscriptionDetailsText,
        currentBusiness.packages,
        currentBusiness.description,
        currentBusiness.headerText,
        currentBusiness.freeTrialEnabled,
        currentBusiness.freeTrialLive,
        currentBusiness.freeTrialDays,
        currentBusiness.freeTrialProgramId,
      )

      console.log('biabClient.updateConfigsByTeamId data ', data)
      console.log('biabClient.updateConfigsByTeamId status ', status)
      const { data: updatedBusiness } = await biabClient.getConfigsByTeamId(tenant, currentTeam.id);
      console.log("business after update: biabClient.getConfigsByTeamId ---> ", updatedBusiness)
      // await saveTeam(currentTeam)
      var userContext = this.props.userContext;
      var updatedTeam = _.find(userContext.appContextList, function(data){ return data.id === currentTeam.id});
      var index = userContext.appContextList.indexOf(updatedTeam);
      userContext.appContextList.splice(index, 1, currentTeam);
      this.props.setUserContext(userContext);

      // get all coaches for this team
      let coaches = await listCoaches(currentTeam.id)
      if(coaches){
        await _.map(coaches, async(coach, i) => {
          console.log('coach ', coach)
          // // copy current user as coach for team.
          let newRole = {
            id: freeTeam.id + "-" + coach.id,
            userId: coach.id,
            parentId: freeTeam.id,
            role: "coach",
            isTeam: true,
            sport: freeTeam.sport
          }
          console.log('newRole ', newRole)
          // refresh users userContext.
          await saveRole(newRole)
        });

      }
      // copy current user as coach for team.
      let newRole = {
        id: freeTeam.id + "-" + userContext.user.id,
        userId: userContext.user.id,
        parentId: freeTeam.id,
        role: "coach",
        isTeam: true,
        sport: freeTeam.sport
      }
      console.log('newRole ', newRole)
      // refresh users userContext.
      await saveRole(newRole)
      // Update user context
      var context = await Auth.currentSession()
      .then(auth =>{
        const options = {
          headers: {
            Authorization: auth.idToken.jwtToken
          },
          body:{
            platform: "web"
          }
        }
        return API.post("auth", `/auth/userContext`, options);
      })
      this.props.setUserContext(context.userContext);

      this.setState({ currentTeam, business: updatedBusiness, freeTeam, enableFreemium: !this.state.enableFreemium, isLoading: false });
    }

    handleChangeIsLive = async() =>{
      this.setState({isLoading: true})
      const { currentTeam, business } = this.state;
      let currentBusiness = {...business, freeTrialLive: !business.freeTrialLive};
      let tenant = await getTenant()
      let { data, status } = await biabClient.updateConfigsByTeamId(
        tenant,
        currentBusiness.teamId,
        currentBusiness.subscriptionDetailsText,
        currentBusiness.packages,
        currentBusiness.description,
        currentBusiness.headerText,
        currentBusiness.freeTrialEnabled,
        currentBusiness.freeTrialLive,
        currentBusiness.freeTrialDays,
        currentBusiness.freeTrialProgramId)

      console.log('data ', data)
      console.log('status ', status)
      const { data: updatedBusiness } = await biabClient.getConfigsByTeamId(tenant, currentTeam.id);
      console.log("business after update: biabClient.getConfigsByTeamId ---> ", updatedBusiness)
      this.setState({business: updatedBusiness, isLoading: false})

    }

    handleChangeFreeTrialLength = async(value) =>{
      console.log('value ', value)
      this.setState({isLoading: true})
      const { currentTeam, business } = this.state;
      let currentBusiness = {...business, freeTrialDays: value};
      let tenant = await getTenant()
      let { data, status } = await biabClient.updateConfigsByTeamId(
        tenant,
        currentBusiness.teamId,
        currentBusiness.subscriptionDetailsText,
        currentBusiness.packages,
        currentBusiness.description,
        currentBusiness.headerText,
        currentBusiness.freeTrialEnabled,
        currentBusiness.freeTrialLive,
        currentBusiness.freeTrialDays,
        currentBusiness.freeTrialProgramId)

      console.log('data ', data)
      console.log('status ', status)
      const { data: updatedBusiness } = await biabClient.getConfigsByTeamId(tenant, currentTeam.id);
      console.log("business after update: biabClient.getConfigsByTeamId ---> ", updatedBusiness)
      this.setState({business: updatedBusiness, isLoading: false})
    }

    handleChangeDefaultWorkoutProgram = async(value) =>{
      console.log('value ', value)
      this.setState({isLoading: true})
      const { currentTeam: team, business } = this.state;
      let currentBusiness = {...business, freeTrialProgramId: value};
      let tenant = await getTenant()
      let { data, status } = await biabClient.updateConfigsByTeamId(
        tenant,
        currentBusiness.teamId,
        currentBusiness.subscriptionDetailsText,
        currentBusiness.packages,
        currentBusiness.description,
        currentBusiness.headerText,
        currentBusiness.freeTrialEnabled,
        currentBusiness.freeTrialLive,
        currentBusiness.freeTrialDays,
        currentBusiness.freeTrialProgramId)

      console.log('data ', data)
      console.log('status ', status)

      // Copy workoutProgram to free team
      let body = {
          teamIds: [`free_${team.id}`],
          product:{
          name: team.name,
          id: "0",
          isAutomatic: true,
          workoutPrograms: [
            `${value}`
          ]
        }
      }
      console.log('body ', body)
      let response = await copyNew(body);
      console.log('response ', response);

      const { data: updatedBusiness } = await biabClient.getConfigsByTeamId(tenant, team.id);
      console.log("business after update: biabClient.getConfigsByTeamId ---> ", updatedBusiness)

      this.setState({currentTeam: team, business: updatedBusiness, isLoading: false})
    }

    render() {
        let { business, isLive, enableFreemium, trialLength, currentTeam = {}, isLoading, computedWorkoutProgramMenuItem, workoutProgramId } = this.state;
        let { classes } = this.props;
        let disable = !(business.freeTrialEnabled ? business.freeTrialEnabled : false);
        return (
            <PageWrapper {...this.props} title={"Free Trial Settings"} isPageLoading={isLoading}>
                <div className={classes.container}>
                    <div className={classes.options}>
                        <Switch
                            label={business.freeTrialEnabled ? `Free Trial offering is ON` : `Turn Free Trail offering ON`}
                            checked={business.freeTrialEnabled ? business.freeTrialEnabled : false}
                            disable={business.freeTrialEnabled}
                            handleChange={(value) => this.handleEnableFreemium()}
                        />
                    </div>
                    <div className={classes.options}>
                        <Switch
                            disable={disable}
                            label="Live"
                            checked={business.freeTrialLive ? business.freeTrialLive : false}
                            handleChange={(value) => this.handleChangeIsLive()}
                        />
                    </div>
                    <div className={classes.options}>
                        <FloatingSelectWithCustomChild
                            disable={disable}
                            value={business.freeTrialDays ? business.freeTrialDays : null}
                            dropDownWidth="100%"
                            label="Trial Length"
                            onChange={value => this.handleChangeFreeTrialLength(value)}
                            dropDownLabel="-Select-"
                            menuItemData={this.menuItemData}
                            showDropDownBorder={true}
                        />
                    </div>
                    <div className={classes.options}>
                        <FloatingSelectWithCustomChild
                            value={business.freeTrialProgramId ? business.freeTrialProgramId : null}
                            dropDownWidth="100%"
                            disable={disable}
                            label="Set a default workout program"
                            onChange={value => this.handleChangeDefaultWorkoutProgram(value)}
                            dropDownLabel="-Select-"
                            menuItemData={computedWorkoutProgramMenuItem}
                            showDropDownBorder={true}
                        />
                    </div>
                </div>
            </PageWrapper>
        )
    }
}

function mapStateToProps(state) {
    const { session: { userContext, appContext } } = state;
    const currentTeam = _.find(userContext.appContextList, c => c.id === appContext.id);
    return {
        currentTeam: currentTeam || {},
        userContext: userContext,
        appContext: appContext
    };
}
const mapDispatchToProps = (dispatch) => ({
  setUserContext: (userContext) => dispatch(setUserContext(userContext)),
  setAppContext: (appContext) => dispatch(setAppContext(appContext))
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FreemiumSettings));
