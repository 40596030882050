import React, { Component } from 'react'
import './styles.css'
import IconButton from '../../iconButton'
import ActivityGroup from '../activityGroup'
import EditIcon from '@material-ui/icons/Edit'
import { Draggable } from 'react-beautiful-dnd'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import { getTeamLogo, getActivityThumbnail, fetchVimeoVideoThumbnail, fetchWistiaVideoThumbnail, } from '../../../utils/commonUtil.js'
import DescriptionModal from '../../modals/descriptionModal'
import { API } from "aws-amplify";
import _ from "lodash"
import { stripDescription } from '../../../utils/workoutUtils'
import { withStyles } from '@material-ui/core'
import { TrophyIcon } from "../../icons";

const styles = {
    subDetails: {
        marginTop: "2px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: "#A0A7AB",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "100%"
    },
    questionContainer: {
        display:"flex",
        flexDirection:"row",
        justifyContent: "space-between",
        textAlign:"left",
        width:"100%",
        alignItems:"center"
    },
    questionBlockOuterContainer:{
        display:"flex",
        alignItems:"center",
        padding:"20px 15px"
    },
    questionBlock:{
        flex:"1"
    },
    deleteIconContainer:{
        marginLeft:"30px",
        marginRight:"10px",
        color:"#DDE2E5"
    },
    trpIcn:{
        color:"#DDE2E5",
        marginLeft:"5px",
        fontSize:"20px"
    }
}
class ActivityBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            originalActivity: {},
            showDeleteConfirm: false,
            isActivityBlockOpened: false,
            isDescriptionModalOpen: false,
            vimeo_thumb: null,
            wistia_thumb: null,
        }
    }

    componentDidMount = async () => {
      const { activity } = this.props;

      if (!!activity.groups && !!activity.groups[0] && (!activity.groups[0].name || !activity.groups[0].thumbnail)) {
        const originalActivity = await this.getActivity(activity.groups[0].activityId)

        if (!!originalActivity) {
          const thumbnailImage = getActivityThumbnail(originalActivity)
          originalActivity.thumbnail = thumbnailImage

          if (originalActivity.videoType === "vimeo") {
            fetchVimeoVideoThumbnail(originalActivity).then(res =>
              { this.setState({ vimeo_thumb: res })
            })
          }

          if (originalActivity.videoType === "wistia") {
            fetchWistiaVideoThumbnail(originalActivity).then(res =>
              { this.setState({ wistia_thumb: res })
            })
          }

          if (!_.isEqual(this.state.originalActivity, originalActivity)) {
            this.setState({originalActivity})
          }
        }
      }
    }

    getActivity(activityId){
        return API.get("activities", `/activities/${activityId}`);
    }

    toggleActivityBlock = () => {
        const { isActivityBlockOpened } = this.state
        this.setState({ isActivityBlockOpened: !isActivityBlockOpened })
    }

    resolveDetails = (activityGroup) => {
        const { activity = {} } = this.props
        const isPercentOfMax = activityGroup.isPercentOfMax == true || activityGroup.isPercentOfMax == 'true'
        const maxValue = activityGroup.maxValue ? activityGroup.maxValue : 75
        const tempoText = (activityGroup.tempo||[]).filter(x=>x !== null).join(':')
        const isTimeActivity = activityGroup.repsOrTime == 'time' || (!activity.repsOrTime && activity.scoreType == 'time')
        if(isTimeActivity){
            let timeValue = { minutes:2, seconds:0 };
            if(activityGroup.time !== undefined){
                timeValue.minutes = Math.floor(activityGroup.time / 60);
                timeValue.seconds = (activityGroup.time - timeValue.minutes* 60);
            }
            return `${activityGroup.sets} sets x ${timeValue.minutes} min ${timeValue.seconds} sec ${isPercentOfMax ? `@ ${maxValue}% max` : ''}`
        }
        return `${activityGroup.sets} sets x ${activityGroup.reps||0} reps  ${isPercentOfMax ? `@ ${maxValue}% max` : ''} ${tempoText ? ` @ Tempo ${tempoText}` : ''}`
    }

    handleDeletePress = () => {
        const { handleDeleteForBlockItem } = this.props
        const { showDeleteConfirm } = this.state
        if(!showDeleteConfirm)
            return this.setState({ showDeleteConfirm: true })
        handleDeleteForBlockItem()
        this.handleDeleteCancel()
    }

    handleDeleteCancel = () => {
        this.setState({ showDeleteConfirm: false })
    }

    toggleDescriptionModal = activity => {
        const { isDescriptionModalOpen } = this.state
        this.setState({ isDescriptionModalOpen: !isDescriptionModalOpen })
    }

    handleSaveDescription = description => {
        const { handleActivityChange } = this.props
        handleActivityChange('description', description)
    }

    render() {
        const { originalActivity, isActivityBlockOpened, showDeleteConfirm, isDescriptionModalOpen, vimeo_thumb, wistia_thumb } = this.state
        const { activity, index, currentTeam, handleScoreType, handleDuplicate, handleWorkoutActivityChange, handleDeleteActivitySetGroup, handleAddSetGroup, isQuestionBlock, classes } = this.props
        const teamLogo = getTeamLogo(currentTeam)
        const activityImage = () => {
          const activityGroupThumbnail = activity?.groups[0].thumbnail
          const activityThumbnail = vimeo_thumb || wistia_thumb || originalActivity.thumbnail || teamLogo
          const thumbnailUrl = activityGroupThumbnail ? activityGroupThumbnail : activityThumbnail          
          return `url(${thumbnailUrl})`
        }

        return (
            !!activity && !!activity.id ?
                <Draggable key={activity.id} draggableId={activity.id.toString()} index={index} >
                    {(provided) => (
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={'activityOuterBlockContainer'}
                        >
                            { isQuestionBlock ?
                                <div className={classes.questionBlockOuterContainer}>
                                        <div className={classes.questionContainer}>
                                            <div className={classes.questionBlock}>
                                                <div>
                                                    {activity.displayValue}
                                                </div>
                                                <div className={classes.subDetails}>
                                                    <div className={classes.dropDownDesc}>
                                                        {activity.description}
                                                    </div>
                                                    <div className={classes.dropDownDesc}>
                                                        {activity.inputInfo}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.deleteIconContainer}>
                                                <DeleteIcon className={'icon'} onClick={this.handleDeletePress} />
                                            </div>
                                        </div>
                                </div> :
                                <div className={'activityBlockContainer'}>
                                    <div
                                        className={'activityImageContainer'} style={{ borderBottomLeftRadius: !!isActivityBlockOpened ? 0 : 4, backgroundImage: activityImage() }}>
                                    </div>
                                    <div className={'activityBlockInnerContainer'}>
                                        <div className={'activityBlockInformationContainer'}>
                                            {(!showDeleteConfirm || isActivityBlockOpened) ? <div className="d-flex"><p className={'activityText'}>{activity.name || originalActivity.name}</p>{activity.hasLeaderboard && <TrophyIcon className={classes.trpIcn} />}</div> :
                                                <p className={'removeActivityText'}>{`Remove ${activity.name || originalActivity.name}?`}</p>
                                            }
                                            {!showDeleteConfirm && !isActivityBlockOpened &&
                                                activity.groups.map(group => { return <p key={group.id} className={'setRepDetailText'}>{this.resolveDetails(group)}</p> })
                                            }
                                            {isActivityBlockOpened && <span className={'activityDescriptionText'}>{stripDescription(activity.description)}</span>}
                                        </div>
                                        <div className={'activityBlockButtonContainer'}>

                                            {showDeleteConfirm && !isActivityBlockOpened ?
                                                <div className={'activityConfirmDeleteContainer'}>
                                                    <p className={'cancelText'} onClick={this.handleDeleteCancel}>Cancel</p>
                                                    <IconButton
                                                        width={111}
                                                        color={'white'}
                                                        position={'right'}
                                                        textColor={'#F93F3F'}
                                                        text={'Confirm'}
                                                        onClick={this.handleDeletePress}
                                                        icon={<DeleteIcon className={'deleteIcon'} />}
                                                    />
                                                </div> : null}

                                            {isActivityBlockOpened &&
                                                <IconButton
                                                    color={'white'}
                                                    position={'left'}
                                                    textColor={'black'}
                                                    text={'Edit Description'}
                                                    onClick={this.toggleDescriptionModal}
                                                    icon={<EditIcon className={'icon'} />}
                                                />

                                            }

                                            {!isActivityBlockOpened && !showDeleteConfirm && <EditIcon className={'icon'} onClick={() => this.toggleActivityBlock()} />}
                                            {!isActivityBlockOpened && !showDeleteConfirm && <FilterNoneIcon className={'icon'} onClick={handleDuplicate} />}
                                            {!isActivityBlockOpened && !showDeleteConfirm && <DeleteIcon className={'icon'} onClick={this.handleDeletePress} />}
                                        </div>
                                    </div>
                                </div>}
                            {isActivityBlockOpened &&
                                <div>
                                    <div className={'openedActivityBlockContainer'}>
                                        {activity.groups.map((a, index) => {
                                            return (
                                                <ActivityGroup
                                                    currentTeam={currentTeam}
                                                    key={a.id}
                                                    activity={a}
                                                    index={index}
                                                    handleScoreType={option => handleScoreType(option)}
                                                    handleDeleteActivitySetGroup={() => handleDeleteActivitySetGroup(a)}
                                                    handleWorkoutActivityChange={handleWorkoutActivityChange}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                            {isActivityBlockOpened &&
                                <div className={'activityBlockFooter'}>
                                    {showDeleteConfirm ?
                                        <p className={'removeActivityText'}>{`Remove ${activity.name ? activity.name : originalActivity && originalActivity.name ? originalActivity.name : ""}?`}</p>
                                        :
                                        <IconButton
                                            width={150}
                                            color={'white'}
                                            textColor={'black'}
                                            text={'Add Set Group'}
                                            onClick={handleAddSetGroup}
                                        />
                                    }
                                    {showDeleteConfirm ?
                                        <div className={'activityConfirmDeleteContainer'}>
                                            <p className={'cancelText'} onClick={this.handleDeleteCancel}>Cancel</p>
                                            <IconButton
                                                width={111}
                                                color={'white'}
                                                position={'right'}
                                                textColor={'#F93F3F'}
                                                text={'Confirm'}
                                                onClick={this.handleDeletePress}
                                                icon={<DeleteIcon className={'deleteIcon'} />}
                                            />
                                        </div>
                                        :
                                        <div className={'activityBlockButtonContainer'}>
                                            <IconButton
                                                width={167}
                                                color={'white'}
                                                position={'right'}
                                                textColor={'black'}
                                                text={'Remove Activity'}
                                                onClick={this.handleDeletePress}
                                                icon={<DeleteIcon className={'deleteIcon'} />}
                                            />
                                            <IconButton
                                                width={150}
                                                color={'#27AE60'}
                                                textColor={'white'}
                                                text={'Save Activity'}
                                                onClick={this.toggleActivityBlock}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <DescriptionModal
                                open={isDescriptionModalOpen}
                                description={activity.description}
                                toggleModal={() => this.toggleDescriptionModal()}
                                handleSave={description => this.handleSaveDescription(description)}
                            />
                        </div>
                    )}
                </Draggable>
                :
                <div className={'emptyActivityBlockContainer'}>
                    <p className={'emptyActivityBlockText'}>Drag activities here</p>
                </div>
        )
    }
}

export default withStyles(styles)(ActivityBlock);
